import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { convert12hToSpokenString } from '../../../../utils/time';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import Clock from '../../../../components/question/representations/Clock';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bk3',
  description: 'bk3',
  keywords: ['To the hour', 'Past the hour', 'Analogue clock', 'Time'],
  schema: z.object({
    clockAHours: z.number().int().min(0).max(11),
    clockAMinutes: z.number().int().min(5).max(25),
    clockAFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const clockAHours = randomIntegerInclusive(0, 11);

    const clockAMinutes = randomIntegerInclusiveStep(5, 25, 5);

    const clockAFirst = getRandomBoolean();

    return { clockAHours, clockAMinutes, clockAFirst };
  },
  Component: props => {
    const {
      question: { clockAHours, clockAMinutes, clockAFirst },
      translate
    } = props;

    const random = seededRandom(props.question);

    const clockBHours = clockAHours === 0 ? 11 : clockAHours - 1;

    const clockBMinutes = 60 - clockAMinutes;

    const correctTimeA = convert12hToSpokenString(translate, clockAHours, clockAMinutes);

    const correctTimeB = convert12hToSpokenString(translate, clockBHours, clockBMinutes);

    const incorrectTimeA = convert12hToSpokenString(translate, clockAHours, clockAMinutes / 5);

    const incorrectTimeB = convert12hToSpokenString(translate, clockBHours, 60 - clockAMinutes / 5);

    const options = shuffle([correctTimeA, correctTimeB, incorrectTimeA, incorrectTimeB], {
      random
    });

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragTheCardsToMatchTheWordsToThePictures()}
        pdfTitle={translate.ks1PDFInstructions.matchTheTimesToTheClocks()}
        items={options}
        sentence="<ans/> <ans/>"
        sentenceStyle={{ justifyContent: 'space-evenly', width: '100%' }}
        testCorrect={clockAFirst ? [correctTimeA, correctTimeB] : [correctTimeB, correctTimeA]}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
            <Clock
              time={{
                hours: clockAFirst ? clockAHours : clockBHours,
                minutes: clockAFirst ? clockAMinutes : clockBMinutes
              }}
              width={dimens.width / 3}
              interactive={false}
            />
            <Clock
              time={{
                hours: clockAFirst ? clockBHours : clockAHours,
                minutes: clockAFirst ? clockBMinutes : clockAMinutes
              }}
              width={dimens.width / 3}
              interactive={false}
            />
          </View>
        )}
        questionHeight={1400}
      />
    );
  },
  questionHeight: 1400
});

const Question2 = newQuestionContent({
  uid: 'bk4',
  description: 'bk4',
  keywords: ['To the hour', 'Past the hour', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutes: numberEnum([5, 10, 20, 25, 35, 40, 50, 55])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);

    const minutes = getRandomFromArray([5, 10, 20, 25, 35, 40, 50, 55] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeDoesTheClockShow()}
        sentence={
          minutes > 30
            ? translate.ks1AnswerSentences.ansMinutesToAns()
            : translate.ks1AnswerSentences.ansMinutesPastAns()
        }
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={
          minutes > 30
            ? [(60 - minutes).toString(), (hours === 12 ? 1 : hours + 1).toString()]
            : [minutes.toString(), hours.toString()]
        }
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours === 12 ? 0 : hours, minutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bk5',
  description: 'bk5',
  keywords: ['To the hour', 'Past the hour', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: z.number().int().min(5).max(55).multipleOf(5)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = randomIntegerInclusiveStep(5, 55, 5);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.dragTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.ks1PDFInstructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
        snapMinutes={5}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TellTheTimeTo5MinutesY2',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
