import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { getRandomFromArray, randomIntegerInclusiveStep } from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { BarModel } from '../../../../components/question/representations/BarModel';
import { filledArray } from '../../../../utils/collections';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { numberEnum } from '../../../../utils/zod';
import { arrayOfObjectColorsSchema, getArrayOfObjectsColors } from '../../../../utils/arrayObjects';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkL',
  description: 'bkL',
  keywords: ['Equal groups', 'Group', 'Three-quarters'],
  schema: z.object({
    total: z.number().int().min(4).max(20).step(4),
    color: arrayOfObjectColorsSchema
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusiveStep(4, 20, 4);
    const color = getArrayOfObjectsColors();

    return { total, color };
  },
  Component: props => {
    const {
      question: { total, color },
      translate,
      displayMode
    } = props;

    const rows = 4;
    const columns = total / rows;

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.fracOfYEquals(`<frac n='3' d='4' />`, total)}
        title={translate.ks1Instructions.findXofYUseTheArrayToHelp(`<frac n='3' d='4' />`, total)}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[(columns * 3).toString()]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(dimens.width / (columns + 1), dimens.height / (rows + 1))}
            dimens={dimens}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bkM',
  description: 'bkM',
  keywords: ['Whole', 'Part', 'Bar model', 'Three-quarters'],
  schema: z.object({
    total: z.number().int().min(4).max(20).step(4)
  }),
  simpleGenerator: () => ({ total: randomIntegerInclusiveStep(4, 20, 4) }),
  Component: props => {
    const {
      question: { total },
      translate,
      displayMode
    } = props;

    const rows = 4;
    const columns = total / rows;

    return (
      <QF1ContentAndSentence
        sentence={translate.ks1AnswerSentences.fracOfYEquals(`<frac n='3' d='4' />`, total)}
        title={translate.ks1Instructions.whatIsFracOfX(`<frac n='3' d='4' />`, total)}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[(columns * 3).toString()]}
        Content={({ dimens }) => (
          <BarModel
            numbers={[[total], filledArray(total / 4, 4)]}
            total={total}
            dimens={dimens}
            strings={[[total.toLocaleString()], filledArray('', 4)]}
            sameRowColor
          />
        )}
        pdfDirection="column"
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkN',
  description: 'bkN',
  keywords: ['Whole', 'Part', 'Bar model', 'Half', 'Three-quarters'],
  schema: z.object({
    total: numberEnum([4, 8, 16, 20, 24, 40, 60, 80, 100])
  }),
  simpleGenerator: () => ({
    total: getRandomFromArray([4, 8, 16, 20, 24, 40, 60, 80, 100] as const)
  }),
  Component: props => {
    const {
      question: { total },
      translate
    } = props;

    const rows = 4;
    const columns = total / rows;

    return (
      <QF2AnswerBoxManySentences
        sentences={[
          translate.ks1AnswerSentences.fracOfYEquals(`<frac n='1' d='4' />`, total),
          translate.ks1AnswerSentences.fracOfYEquals(`<frac n='3' d='4' />`, total)
        ]}
        title={translate.ks1Instructions.fillInTheMissingNumbers()}
        testCorrect={[[columns.toString()], [(columns * 3).toString()]]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindThreeQuarters',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
