import { newBlockContent } from '../../../Block';

import NumbersTo20 from './1NumbersTo20';
import CountObjectsTo100ByMaking10s from './2CountObjectsTo100ByMaking10s';
import RecogniseTensAndOnes from './3RecogniseTensAndOnes';
import UseAPlaceValueChart from './4UseAPlaceValueChart';
import PartitionNumbersTo100 from './5PartitionNumbersTo100';
import WriteNumbersTo100 from './6WriteNumbersTo100InWords';
import FlexiblyPartitionNumbersTo100 from './7FlexiblyPartitionNumbersTo100';
import WriteNumbersTo100InExpandedForm from './8WriteNumbersTo100InExpandedForm';
import TensOnTheNumberLineTo100 from './9TensOnTheNumberLineTo100';
import TensAndOnesOnTheNumberLineTo100 from './10TensAndOnesOnTheNumberLineTo100';
import EstimateNumbersOnANumberLine from './11EstimateNumbersOnANumberLine';
import CompareObjects from './12CompareObjects';
import CompareNumbers from './13CompareNumbers';
import OrderObjectsAndNumbers from './14OrderObjectsAndNumbers';
import CountIn2s5sAnd10s from './15CountIn2s5sAnd10s';
import CountIn3s from './16CountIn3s';

const Block = newBlockContent({
  block: 'PlaceValue',
  field: 'Number',
  weeks: [1, 4],
  smallSteps: [
    NumbersTo20, // Step 1
    CountObjectsTo100ByMaking10s, // Step 2
    RecogniseTensAndOnes, // Step 3
    UseAPlaceValueChart, // Step 4
    PartitionNumbersTo100, // Step 5
    WriteNumbersTo100, // Step 6
    FlexiblyPartitionNumbersTo100, // Step 7
    WriteNumbersTo100InExpandedForm, // Step 8
    TensOnTheNumberLineTo100, // Step 9
    TensAndOnesOnTheNumberLineTo100, // Step 10
    EstimateNumbersOnANumberLine, // Step 11
    CompareObjects, // Step 12
    CompareNumbers, // Step 13
    OrderObjectsAndNumbers, // Step 14
    CountIn2s5sAnd10s, // Step 15
    CountIn3s // Step 16
  ]
});
export default Block;
