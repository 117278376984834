import { useContext, useMemo } from 'react';
import { StyleProp, StyleSheet, TextStyle, Pressable, ViewStyle } from 'react-native';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../utils/react';
import Text from '../typography/Text';
import AutoScaleText from '../typography/AutoScaleText';
import { Theme, useTheme } from '../../theme';
import { DisplayMode } from '../../contexts/displayMode';
import { AssetSvg } from '../../assets/svg';
import { getPlayer } from '../../utils/Audio';

type Props = {
  // State control
  selected?: boolean;
  toggleSelected?: () => void;
  /** Default: false */
  disabled?: boolean;
  children: string | ElementOrRenderFunction;
  variant?: 'circle' | 'oval';
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  selectableContainerStyle?: StyleProp<ViewStyle>;
  textVariant?: keyof Theme['fonts'];
  /** If you want auto text scaling, provide this or textSizeAutoScaleGroup, but not both. */
  textScaleToLongestLength?: number;
  /** If you want auto text scaling, provide this or textScaleToLongestLength, but not both. */
  textSizeAutoScaleGroup?: number | string;
  textLetterEmWidth?: number;
  maxLines?: number;
  useAutoScaleFont?: boolean;
  markschemeTickPosition?: { top?: number; right?: number };
  pdfShowBorder?: boolean;
};

/**
 * Selectable item.
 */
export function Selectable({
  selected = false,
  toggleSelected = () => {
    /* Do nothing */
  },
  disabled = false,
  children,
  variant = 'oval',
  style,
  textStyle,
  textVariant = 'WRN700',
  textScaleToLongestLength,
  textSizeAutoScaleGroup,
  textLetterEmWidth = 0.7,
  maxLines = 1,
  selectableContainerStyle,
  markschemeTickPosition = { top: 15, right: 30 },
  pdfShowBorder = false
}: Props) {
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(variant, pdfShowBorder);
  const player = getPlayer();

  return (
    <Pressable
      disabled={disabled}
      onPress={() => {
        if (selected) {
          player.playSound('unselect');
        } else {
          player.playSound('select');
        }
        toggleSelected();
      }}
      style={[styles.selectable, selected && styles.selected, style]}
    >
      {displayMode === 'markscheme' && selected && (
        <AssetSvg
          name={'True'}
          width={50}
          style={[{ zIndex: 999, position: 'absolute' }, markschemeTickPosition]}
        />
      )}
      {(() => {
        if (typeof children === 'string') {
          if (textScaleToLongestLength !== undefined || textSizeAutoScaleGroup !== undefined) {
            // Use auto-scale text. Only one of these props should be defined.
            return (
              <AutoScaleText
                variant={textVariant}
                longestInGroup={textScaleToLongestLength}
                group={textSizeAutoScaleGroup}
                maxLines={maxLines}
                letterEmWidth={textLetterEmWidth}
                minFontSize={displayMode === 'digital' ? 22 : 28}
                maxFontSize={displayMode === 'digital' ? 40 : 50}
                containerStyle={[styles.container, selectableContainerStyle]}
                textStyle={[selected && styles.textSelected, textStyle]}
                shrinkOnly
              >
                {children}
              </AutoScaleText>
            );
          } else {
            return (
              <Text
                variant={textVariant}
                numberOfLines={maxLines}
                style={[selected && styles.textSelected, textStyle]}
              >
                {children}
              </Text>
            );
          }
        } else {
          return resolveElementOrRenderFunction(children);
        }
      })()}
    </Pressable>
  );
}

Selectable.OVAL_WIDTH = 330 as const;
Selectable.CIRCLE_WIDTH = 96 as const;
Selectable.HEIGHT = 96 as const;
Selectable.PDF_OVAL_WIDTH = 600 as const;
Selectable.PDF_CIRCLE_WIDTH = 144 as const;
Selectable.PDF_HEIGHT = 175 as const;

function useStyles(variant: 'circle' | 'oval', pdfShowBorder: boolean) {
  const displayMode = useContext(DisplayMode);
  const theme = useTheme();

  return useMemo(
    () =>
      StyleSheet.create({
        selectable: {
          width:
            displayMode === 'digital'
              ? variant === 'circle'
                ? Selectable.CIRCLE_WIDTH
                : Selectable.OVAL_WIDTH
              : variant === 'circle'
              ? Selectable.PDF_CIRCLE_WIDTH
              : Selectable.PDF_OVAL_WIDTH,
          height: displayMode === 'digital' ? Selectable.HEIGHT : Selectable.PDF_HEIGHT,
          borderRadius: theme.buttonBorderRadius,
          borderWidth: displayMode === 'digital' || pdfShowBorder ? theme.buttonBorderWidth : 0,
          backgroundColor: theme.colors.background,
          borderColor: displayMode === 'digital' ? theme.colors.tertiary : theme.colors.pdfPrimary,
          alignItems: 'center',
          justifyContent: 'center'
        },
        selected: {
          backgroundColor:
            displayMode === 'digital' ? theme.colors.tertiary : theme.colors.background,
          borderColor: displayMode === 'digital' ? theme.colors.tertiary : 'black',
          borderWidth: displayMode === 'markscheme' ? theme.buttonBorderWidth : 0
        },
        textSelected: {
          color: displayMode === 'digital' ? theme.colors.onTertiary : 'black'
        },
        container: { width: displayMode === 'digital' ? '90%' : '100%', height: '95%' }
      }),
    [
      displayMode,
      variant,
      theme.buttonBorderRadius,
      theme.buttonBorderWidth,
      theme.colors.background,
      theme.colors.tertiary,
      theme.colors.pdfPrimary,
      theme.colors.onTertiary,
      pdfShowBorder
    ]
  );
}
