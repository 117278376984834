import { Pressable, View } from 'react-native';
import { SetState } from 'common/src/utils/react';
import { range } from 'common/src/utils/collections';
import Table from 'common/src/components/molecules/Table';
import { useCallback, useContext } from 'react';
import Text from 'common/src/components/typography/Text';
import { colors } from 'common/src/theme/colors';
import { RightAngleGridTriangles } from './DisplayShapeOnGrid';
import { withStateHOC } from '../../../stateTree';
import { DisplayMode } from '../../../contexts/displayMode';
import { Dimens } from '../../../theme/scaling';
import Svg, { Circle } from 'react-native-svg';

type Props = {
  /**
   * The first number to show on the number grid.
   */
  startNumber: number;
  /**
   * The last number to show on the number grid.
   */
  finishNumber: number;
  /**
   * Numbers that should be preshaded on the number grid.
   * These are shaded a different color to the selected numbers.
   * If a preshaded number is selected, it is shaded with both colors (split diagonally)
   */
  preshaded?: number[];
  /**
   * The user answer, i.e. which numbers are highlighted.
   */
  userAnswer: number[];
  /**
   * Set user answer
   */
  setUserAnswer: SetState<number[]>;
  dimens: Dimens;
  /**
   * Whether to allow multiselect or not. Defaults to true
   */
  multiSelect?: boolean;
  /** Whether to circle or shade the correct answer(s) on the mark scheme. Default: 'shade' */
  circleOrShaded?: 'circle' | 'shade';
};

export const NumberGridInteractive = ({
  startNumber,
  finishNumber,
  userAnswer,
  setUserAnswer,
  preshaded,
  dimens,
  circleOrShaded = 'shade',
  multiSelect = true
}: Props) => {
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';

  const numbers = range(startNumber, finishNumber);

  const numbersSplitIntoRows = ((): number[][] => {
    const result = [];
    const length = numbers.length;
    let startIndex = 0;

    while (startIndex < length) {
      const endIndex = Math.min(startIndex + 10, length);
      const subarray = numbers.slice(startIndex, endIndex);
      result.push(subarray);
      startIndex += 10;
    }

    return result;
  })();

  const handleOnPress = useCallback(
    (selectedNumber: number) => {
      const isNumberSelected = userAnswer.includes(selectedNumber);

      setUserAnswer(prevAnswer => {
        if (isNumberSelected) {
          return prevAnswer.filter(num => num !== selectedNumber);
        }

        if (!multiSelect) {
          return [selectedNumber];
        }

        return [...prevAnswer, selectedNumber];
      });
    },
    [userAnswer, setUserAnswer, multiSelect]
  );

  // Firstly, calculate various layout dimensions.
  // Default to 96
  const squareDimens = Math.max(
    Math.min(dimens.height / numbersSplitIntoRows.length, dimens.width / 10),
    96
  );

  const gridWidth = numbersSplitIntoRows[0].length * squareDimens;
  const gridHeight = numbersSplitIntoRows.length * squareDimens;

  const getBackground = (number: number): string | undefined => {
    if (preshaded?.includes(number)) {
      return isPdf ? colors.pdfShading : colors.pacificBlue;
    } else if (userAnswer?.includes(number) && circleOrShaded === 'shade') {
      return isPdf ? colors.pdfShading : colors.burntSienna;
    } else return 'white';
  };

  // Now return a table
  return (
    <Table
      style={{ width: gridWidth, height: gridHeight }}
      rowStyle={{ flex: 1 }} // Rows share the table height
      cellStyle={{ flex: 1 }} // Cells share the row width
      items={numbersSplitIntoRows.map((row, rowIndex) =>
        row.map((number, columnIndex) => {
          return (
            <Pressable
              key={`${rowIndex}-${columnIndex}`}
              style={{
                flex: 1,
                alignSelf: 'center',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              onPress={() => handleOnPress(number)}
            >
              <View
                style={{
                  backgroundColor: getBackground(number),
                  width: squareDimens,
                  height: squareDimens,
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {userAnswer.includes(number) &&
                  displayMode === 'markscheme' &&
                  circleOrShaded === 'circle' && (
                    <Svg
                      width={squareDimens}
                      height={squareDimens}
                      viewBox={`0 0 ${squareDimens} ${squareDimens}`}
                      style={{ position: 'absolute' }}
                    >
                      <Circle
                        cx={squareDimens / 2}
                        cy={squareDimens / 2}
                        r={50}
                        strokeWidth={6}
                        fill="transparent"
                        stroke={colors.burntSienna}
                      />
                    </Svg>
                  )}
                {preshaded?.includes(number) && userAnswer.includes(number) ? (
                  <RightAngleGridTriangles
                    dimens={squareDimens}
                    color={colors.burntSienna}
                    orientation={'topRight'}
                    styles={{ position: 'absolute', zIndex: -1 }}
                  />
                ) : null}
                <Text
                  variant="WRN700"
                  style={{
                    fontSize: isPdf ? 50 : 32,
                    color:
                      (userAnswer?.includes(number) || preshaded?.includes(number)) &&
                      circleOrShaded === 'shade'
                        ? colors.white
                        : isPdf
                        ? colors.black
                        : colors.prussianBlue
                  }}
                >
                  {number.toLocaleString()}
                </Text>
              </View>
            </Pressable>
          );
        })
      )}
    />
  );
};

export const NumberGridInteractiveWithState = withStateHOC(NumberGridInteractive, {
  stateProp: 'userAnswer',
  setStateProp: 'setUserAnswer',
  defaults: { defaultState: [], testComplete: answer => answer.length > 0 }
});
