import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';
import {
  counterVariantSchema,
  counterVariants
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import { integerToWord } from '../../../../utils/math';
import { filledArray } from '../../../../utils/collections';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcf',
  description: 'bcf',
  keywords: ['More', 'Less', 'Counters', 'Ten frame'],
  schema: z
    .object({
      num: z.number().int().min(0).max(20),
      numeralOrWord: z.enum(['numeral', 'word']),
      moreOrLess: z.enum(['more', 'less']),
      counterColor: counterVariantSchema
    })
    .refine(
      ({ num, moreOrLess }) => isInRange(1, 20)(moreOrLess === 'more' ? num + 1 : num - 1),
      'Answer must be within range [0, 20] (inclusive)'
    ),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const num =
      moreOrLess === 'more' ? randomIntegerInclusive(0, 19) : randomIntegerInclusive(2, 20);

    const numeralOrWord = num === 0 ? 'word' : getRandomFromArray(['numeral', 'word'] as const);
    const counterColor = getRandomFromArray(counterVariants);

    return { num, moreOrLess, numeralOrWord, counterColor };
  },
  Component: props => {
    const {
      question: { num, moreOrLess, numeralOrWord, counterColor },
      translate
    } = props;

    const number = numeralOrWord === 'word' ? integerToWord(num) : num.toLocaleString();

    const instruction =
      moreOrLess === 'more'
        ? translate.ks1Instructions.dragCountersToShowXMoreThanY(1, number)
        : translate.ks1Instructions.dragCountersToShowXLessThanY(1, number);
    const pdfInstruction =
      moreOrLess === 'more'
        ? translate.ks1PDFInstructions.drawXMoreThanY(1, number)
        : translate.ks1PDFInstructions.drawXLessThanY(1, number);

    const correctAnswer = num + (moreOrLess === 'more' ? 1 : -1);

    return (
      <QF21DraggableTenFrames
        title={instruction}
        pdfTitle={pdfInstruction}
        items={[counterColor]}
        numberOfTenFrames={2}
        tenFrameFlexDirection="column"
        testCorrect={totalCountersIs(correctAnswer)}
        exampleCorrectAnswer={[[...filledArray(counterColor, correctAnswer)]]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcg',
  description: 'bcg',
  keywords: ['Table', 'Addition', 'More', 'Less'],
  schema: z
    .object({
      startNumber: z.number().int().min(1).max(14),
      numberOfCells: z.number().int().min(6).max(8),
      moreOrLess: z.enum(['more', 'less']),
      numeralOrWord: z.enum(['numeral', 'word']),
      num: z.number().int().min(1).max(20)
    })
    .refine(
      ({ num, moreOrLess, startNumber, numberOfCells }) =>
        isInRange(
          startNumber,
          startNumber + numberOfCells
        )(moreOrLess === 'more' ? num + 1 : num - 1),
      'Answer must be within range displayed on number grid'
    ),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const numberOfCells = randomIntegerInclusive(6, 8);

    const startNumber = randomIntegerInclusive(1, 20 - numberOfCells);

    const num =
      moreOrLess === 'more'
        ? randomIntegerInclusive(startNumber, startNumber + numberOfCells - 1)
        : randomIntegerInclusive(startNumber + 1, startNumber + numberOfCells);

    const numeralOrWord = getRandomFromArray(['numeral', 'word'] as const);

    return { startNumber, numberOfCells, moreOrLess, num, numeralOrWord };
  },
  Component: props => {
    const {
      question: { startNumber, numberOfCells, moreOrLess, num, numeralOrWord },
      translate
    } = props;

    const number = numeralOrWord === 'word' ? integerToWord(num) : num.toLocaleString();

    const [title, pdfTitle] =
      moreOrLess === 'more'
        ? [
            translate.ks1Instructions.tapXMoreThanY(1, number),
            translate.ks1PDFInstructions.circleXMoreThanY(1, number)
          ]
        : [
            translate.ks1Instructions.tapXLessThanY(1, number),
            translate.ks1PDFInstructions.circleXLessThanY(1, number)
          ];

    const correctAnswer = moreOrLess === 'more' ? num + 1 : num - 1;

    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + numberOfCells}
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={[correctAnswer]}
        questionHeight={500}
        multiSelect={false}
        customMarkSchemeAnswer={{ circleOrShade: 'circle' }}
      />
    );
  },
  questionHeight: 500
});

const Question3 = newQuestionContent({
  uid: 'bch',
  description: 'bch',
  keywords: ['Addition', 'More than', 'Less than'],
  schema: z
    .object({
      num: z.number().int().min(1).max(20),
      moreOrLess: z.enum(['more', 'less']),
      ansIndex: numberEnum([0, 1])
    })
    .refine(
      ({ num, moreOrLess }) => isInRange(1, 20)(moreOrLess === 'more' ? num + 1 : num - 1),
      'Answer must be within range [0, 20] (inclusive)'
    ),
  simpleGenerator: () => {
    const moreOrLess = getRandomFromArray(['more', 'less'] as const);
    const num =
      moreOrLess === 'more' ? randomIntegerInclusive(1, 19) : randomIntegerInclusive(2, 20);
    const ansIndex = getRandomFromArrayWithWeights([0, 1] as const, [1, 3]);

    return { num, moreOrLess, ansIndex };
  },
  Component: props => {
    const {
      question: { num, moreOrLess, ansIndex },
      translate
    } = props;

    const [sentence, correctAnswer] =
      moreOrLess === 'more'
        ? ansIndex === 0
          ? [translate.ks1AnswerSentences.xMoreThanAnsIsY(1, num + 1), num]
          : [translate.ks1AnswerSentences.xMoreThanYIsAns(1, num), num + 1]
        : ansIndex === 1
        ? [translate.ks1AnswerSentences.xLessThanAnsIsY(1, num - 1), num]
        : [translate.ks1AnswerSentences.xLessThanYIsAns(1, num), num - 1];

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={sentence}
        testCorrect={[correctAnswer.toString()]}
        questionHeight={500}
      />
    );
  },
  questionHeight: 500
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OneMoreAndOneLess',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
