import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF45CompletePolygonOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45CompletePolygonOnSquareDottedPaper';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import {
  isValidShapeWithRequiredVertices,
  isValidSquareOrRectangleWithFilteredVertices
} from '../../../../utils/shapes';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhI',
  description: 'bhI',
  keywords: ['Shape', '2-D shape', 'Square', 'Rectangle'],
  schema: z.object({
    shape: z.enum(['triangle', 'square', 'rectangle', 'pentagon', 'hexagon', 'heptagon', 'octagon'])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'triangle',
      'square',
      'rectangle',
      'pentagon',
      'hexagon',
      'heptagon',
      'octagon'
    ] as const);

    return { shape };
  },
  Component: ({ question, translate }) => {
    const { shape } = question;

    const [shapeStringInstruction, shapeStringMarkScheme, shapeVertices] = (() => {
      switch (shape) {
        case 'triangle':
          return [translate.shapes.aTriangle(), translate.shapes.triangles(1), 3];
        case 'square':
          return [translate.shapes.aSquare(), translate.shapes.squares(1), 4];
        case 'rectangle':
          return [translate.shapes.aRectangle(), translate.shapes.rectangles(1), 4];
        case 'pentagon':
          return [translate.shapes.aPentagon(), translate.shapes.pentagons(1), 5];
        case 'hexagon':
          return [translate.shapes.aHexagon(), translate.shapes.hexagons(1), 6];
        case 'heptagon':
          return [translate.shapes.aHeptagon(), translate.shapes.heptagons(1), 7];
        case 'octagon':
          return [translate.shapes.anOctagon(), translate.shapes.octagons(1), 8];
      }
    })();

    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapDotsToCreateXByMarkingTheVertices(
          shapeStringInstruction
        )}
        pdfTitle={translate.ks1PDFInstructions.drawX(shapeStringInstruction)}
        testCorrect={userAnswer => {
          return shape === 'square' || shape === 'rectangle'
            ? isValidSquareOrRectangleWithFilteredVertices(userAnswer, shape) &&
                isValidShapeWithRequiredVertices(userAnswer, shapeVertices)
            : isValidShapeWithRequiredVertices(userAnswer, shapeVertices);
        }}
        closeShape
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidXWithNumVertices(
            shapeStringMarkScheme,
            shapeVertices
          )
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bhJ',
  description: 'bhJ',
  keywords: ['Shape', '2-D shape', 'Square', 'Rectangle'],
  schema: z.object({
    width: z.number().int().min(1).max(10),
    height: z.number().int().min(1).max(5),
    missingPoint: z.enum(['bottomLeft', 'bottomRight', 'topLeft', 'topRight']),
    xOffset: z.number().int().min(0).max(10),
    yOffset: z.number().int().min(0).max(5)
  }),
  simpleGenerator: () => {
    const isSquare = getRandomBoolean();

    const width = randomIntegerInclusive(1, isSquare ? 5 : 10);
    const height = isSquare ? width : randomIntegerInclusive(1, 5);
    const xOffset = randomIntegerInclusive(0, 10, { constraint: x => x + width <= 10 });
    const yOffset = randomIntegerInclusive(0, 5, { constraint: x => x + height <= 5 });

    const missingPoint = getRandomFromArray([
      'bottomLeft',
      'bottomRight',
      'topLeft',
      'topRight'
    ] as const);

    return { width, height, missingPoint, xOffset, yOffset };
  },
  Component: ({ question, translate }) => {
    const { width, height, missingPoint, xOffset, yOffset } = question;

    const isSquare = width === height;

    const missingIndex = (() => {
      switch (missingPoint) {
        case 'bottomLeft':
          return 0;
        case 'topLeft':
          return 1;
        case 'topRight':
          return 2;
        case 'bottomRight':
          return 3;
      }
    })();

    const coords = [
      { x: xOffset, y: yOffset },
      { x: xOffset, y: yOffset + height },
      { x: xOffset + width, y: yOffset + height },
      { x: xOffset + width, y: yOffset }
    ];

    const shapeDisplayString = isSquare
      ? translate.shapes.squares(1)
      : translate.shapes.rectangles(1);

    return (
      <QF45CompletePolygonOnSquareDottedPaper
        title={translate.ks1Instructions.tapWhereTheFinalVertexOfTheXShouldBe(shapeDisplayString)}
        pdfTitle={translate.ks1PDFInstructions.completeTheString(shapeDisplayString)}
        polygon={coords}
        missingIndex={missingIndex}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhK',
  description: 'bhK',
  keywords: ['Shape', '2-D shape', 'Vertex', 'Vertices'],
  schema: z.object({
    vertices: z.number().int().min(3).max(8)
  }),
  simpleGenerator: () => {
    const vertices = randomIntegerInclusive(3, 8);

    return { vertices };
  },
  Component: ({ question, translate }) => {
    const { vertices } = question;
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapTheDotsToCreateAShapeWithNumVertices(vertices)}
        pdfTitle={translate.ks1PDFInstructions.drawAShapeWithNumVertices(vertices)}
        testCorrect={userAnswer => isValidShapeWithRequiredVertices(userAnswer, vertices)}
        closeShape
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidShapeWithNumVertices(vertices)
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Draw2DShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
