import { View, StyleSheet } from 'react-native';
import { arraysHaveSameContents, countRange, filledArray } from 'common/src/utils/collections';
import { useContext } from 'react';
import BaseLayout, { ActionPanelVariant } from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import { isNotEqual } from '../../../utils/matchers';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { CounterVariant, renderTenFrameCounter } from '../representations/TenFrame/TenFrameLayout';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { colors } from '../../../theme/colors';
import Text from '../../typography/Text';
import { horizontalArrow } from '../representations/LineSvgs';
import { CounterBoxArrangement } from '../representations/CounterBoxArrangement/CounterBoxArrangement';
import { MeasureView } from '../../atoms/MeasureView';
import { renderMarkSchemeProp } from './utils/markSchemeRender';

type CounterVariantA = { type: 'color'; value: CounterVariant[][] };
type CounterVariantB = { type: 'random'; value: (boolean[][] | '<ans/>')[] };

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * Initial pre-filled boxes with counters. Provided as an array of:
   * - Colors for normal ordering.
   * - Boolean 2D array for each box that will be rendered using {@link CounterBoxArrangement} or '<ans/>' for an interactive answer box.
   *      - In order to fit in the box, this should have a maximum of 5 rows and 4 columns
   *
   * Randomly arranged counters are unable to be interactive.
   */
  prefilledBoxes: CounterVariantA | CounterVariantB;
  /**
   * The correct grouping of values. The 2D array goes in order.
   *
   * Alternatively, you can provide the correct answer as a function.
   */
  testCorrect:
    | (CounterVariant | string | number)[][]
    | ((userAnswer: CounterVariant[][]) => boolean);
  /**
   * Optional custom mark scheme answer (with items). If you provide testCorrect as a function, you _must_ provide
   * this. If you provide testCorrect as an array, you don't need to provide this.
   *
   * Either a text string to display, or an array of rows, each with an array of columns, each with an array of
   * correct answers to go in that zone.
   */
  markSchemeAnswer?: { answerToDisplay?: CounterVariant[][]; answerText?: string };
  items: (CounterVariant | { component: JSX.Element; value: string | number })[];
  questionHeight?: number;
  /**
   * Number of drop zones to show.
   * Maximum number of groups is 10
   */
  numberOfGroups: number;
  /** Optional override for max capacity of each group. Default: numberOfGroups >= 5 ? 6 : 9 */
  maxCapacity?: number;
  /** Labels at either end of the arrow below the draggable boxes. Required to render arrow. */
  leftLabel?: string;
  rightLabel?: string;
  /** Length of arrow with labels. Defaults to full width of content container. */
  arrowLength?: number;
  actionPanelVariant?: ActionPanelVariant;
};

/**
 * Question Format 60: Drag counters into groups of up to 10 Groups
 *
 * Title at the top, draggables on the right, and dropzone positions to drag them into on the left.
 * Draggable is "copy" by default.
 *
 * This is a many-(up to 10) drag and drop.
 */
export default function QF60DragCountersIntoGroups({
  title,
  pdfTitle,
  prefilledBoxes,
  testCorrect,
  markSchemeAnswer,
  items: itemsProp,
  questionHeight,
  numberOfGroups,
  maxCapacity = numberOfGroups >= 5 ? 6 : 9,
  leftLabel,
  rightLabel,
  arrowLength,
  actionPanelVariant = 'end',
  ...props
}: Props) {
  if (numberOfGroups > 10) {
    throw new Error('Maximum number of groups is 10');
  }

  if (typeof testCorrect === 'function' && markSchemeAnswer === undefined) {
    throw new Error('testCorrect is a function, so you must provide the markSchemeAnswer prop');
  }

  const displayMode = useContext(DisplayMode);

  const styles = getStyles(displayMode);

  const draggables = itemsProp.map(item => {
    return typeof item === 'object'
      ? item
      : {
          component: renderTenFrameCounter({
            size: 'large',
            variant: item,
            showShadow: false
          }),
          value: item
        };
  });

  const numberOfAnsBoxes =
    prefilledBoxes.value.filter(i => i === '<ans/>').length ?? numberOfGroups;

  const initialState =
    prefilledBoxes.type === 'color'
      ? (prefilledBoxes.value satisfies CounterVariant[][])
      : filledArray([], numberOfAnsBoxes);

  // Will need to add DragAndDropSectionPDF if required for PDF variation of question
  const dropSource = (
    <DragAndDropSection
      style={{ alignSelf: 'center', justifyContent: 'center', flex: 1 }}
      itemsStyle={{ gap: 8, justifyContent: 'flex-start' }}
    >
      {itemsProp.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  let ansIdx = 0;
  const dragZones = (
    <View style={styles.dropZoneContainer}>
      {prefilledBoxes.type === 'color'
        ? countRange(numberOfGroups).map((_, index) => (
            <EasyDragAndDrop.ZoneMultiple
              key={index}
              id={index}
              capacity={maxCapacity ?? (numberOfGroups >= 5 ? 4 : 9)}
              style={[
                styles.dropZone,
                {
                  width: numberOfGroups >= 5 ? '18%' : `${94 / numberOfGroups}%`,
                  height: numberOfGroups >= 5 ? '45%' : '75%'
                }
              ]}
              droppedStyle={styles.counter}
            />
          ))
        : prefilledBoxes.value.map((box, idx) =>
            box === '<ans/>' ? (
              <EasyDragAndDrop.ZoneMultiple
                key={idx}
                id={ansIdx++}
                capacity={numberOfGroups <= 5 ? 20 : 4}
                style={[
                  styles.dropZone,
                  {
                    width: numberOfGroups >= 5 ? '18%' : `${94 / numberOfGroups}%`,
                    height: numberOfGroups >= 5 ? '45%' : '80%'
                  }
                ]}
                droppedStyle={styles.counter}
              />
            ) : (
              <MeasureView key={idx}>
                {dimens => (
                  <CounterBoxArrangement
                    counters={5}
                    color={typeof itemsProp[0] === 'object' ? 'grey' : itemsProp[0]}
                    dimens={dimens}
                    seed={Math.random() * 10000}
                    arrangement={box}
                    // counter size to match the draggable size which is tenFrame size 'large' aka 64px
                    counterSize={64}
                  />
                )}
              </MeasureView>
            )
          )}
    </View>
  );

  const arrow = (
    <View style={styles.labelContainer}>
      {leftLabel && rightLabel && (
        <>
          <Text style={styles.label}>{leftLabel}</Text>
          {horizontalArrow(arrowLength ?? (displayMode === 'digital' ? 600 : 1200))}
          <Text style={styles.label}>{rightLabel}</Text>
        </>
      )}
    </View>
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const defaultState =
      typeof testCorrect !== 'function' ? testCorrect : markSchemeAnswer?.answerToDisplay;

    return (
      <>
        <EasyDragAndDrop.ProviderWithState
          id="QF60"
          moveOrCopy="copy"
          items={draggables}
          defaultState={displayMode === 'markscheme' ? defaultState : undefined}
          draggableStyle={{
            borderWidth: 0,
            shadowColor: 'transparent',
            backgroundColor: 'transparent'
          }}
        >
          <BaseLayoutPDF
            title={pdfTitle ?? title}
            mainPanelContents={
              <View style={styles.container}>
                {dragZones}
                {arrow}
              </View>
            }
            questionHeight={questionHeight}
            {...props}
          />
        </EasyDragAndDrop.ProviderWithState>
        {displayMode === 'markscheme' &&
          markSchemeAnswer?.answerText &&
          renderMarkSchemeProp(markSchemeAnswer.answerText)}
      </>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="QF60"
      items={draggables}
      moveOrCopy={actionPanelVariant !== 'end' ? 'move' : 'copy'}
      defaultState={initialState}
      // Complete if at least one zone has had a change.
      testComplete={isNotEqual(initialState)}
      hideBackground
      testCorrect={
        typeof testCorrect === 'function'
          ? testCorrect
          : state => state.every((zone, index) => arraysHaveSameContents(zone, testCorrect[index]))
      }
      draggableStyle={{
        borderWidth: 0,
        shadowColor: 'transparent',
        backgroundColor: 'transparent'
      }}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={actionPanelVariant}
        actionPanelContents={dropSource}
        mainPanelContents={
          <View style={styles.container}>
            {dragZones}
            {arrow}
          </View>
        }
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}

const getStyles = (displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return StyleSheet.create({
    counter: {
      width: 60,
      height: 60
    },
    labelContainer: {
      flexDirection: 'row',
      gap: 32,
      alignItems: 'center',
      justifyContent: 'center'
    },
    label: {
      color: colors.grey,
      fontSize: displayMode === 'digital' ? 32 : 40,
      textAlign: 'center'
    },
    container: {
      flex: 1
    },
    dropZone: {
      alignItems: 'center',
      borderStyle: 'solid',
      borderRadius: 24,
      gap: displayMode === 'digital' ? 10 : 32
    },
    dropZoneContainer: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: displayMode === 'digital' ? 16 : 32,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center'
    }
  });
};
