import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom
} from '../../../../utils/random';
import { barModelColors } from '../../../../theme/colors';
import { filledArray, range, sortNumberArray } from '../../../../utils/collections';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { fractionToDecimal } from '../../../../utils/fractions';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { BarModelInteractiveWithState } from '../../../../components/question/representations/BarModelInteractive';
import { trueCount } from '../../../../utils/shapes';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aH8',
  description: 'aH8',
  keywords: ['Non-unit fraction', 'Compare', 'Greater than', 'Less than'],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    numeratorA: z.number().int().min(1).max(9),
    numeratorB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const [numeratorA, numeratorB] = randomUniqueIntegersInclusive(1, denominator - 1, 2);

    return {
      denominator,
      numeratorA,
      numeratorB
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, numeratorA);
    const remainder1 = filledArray('white', denominator - numeratorA);

    const numeratorColorArray2 = filledArray(numeratorColor, numeratorB);
    const remainder2 = filledArray('white', denominator - numeratorB);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    const answerOptions = [translate.operations.greaterThan(), translate.operations.lessThan()];

    const fractionDecimal1 = fractionToDecimal(numeratorA, denominator);
    const fractionDecimal2 = fractionToDecimal(numeratorB, denominator);

    const correctInequality =
      lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2) === '>'
        ? translate.operations.greaterThan()
        : translate.operations.lessThan();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragCardCompleteSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        items={answerOptions}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={denominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={denominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`${translate.answerSentences.fracIsAnsFrac(
          `<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/>`,
          `<frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/>`
        )} `}
        testCorrect={[correctInequality]}
        questionHeight={900}
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'aH9',
  description: 'aH9',
  keywords: ['Compare', 'Non-unit fraction', 'Denominator', 'Greater than', 'Less than'],
  schema: z.object({
    denominator: z.number().int().min(8).max(19),
    headingNumerator: z.number().int().min(4).max(16),
    answerNumeratorA: z.number().int().min(1).max(15),
    answerNumeratorB: z.number().int().min(1).max(15),
    answerNumeratorC: z.number().int().min(5).max(18),
    answerNumeratorD: z.number().int().min(5).max(18),
    answerNumeratorE: z.number().int().min(1).max(18),
    answerNumeratorF: z.number().int().min(1).max(18)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(9, 19);
    const headingNumerator = randomIntegerInclusive(4, denominator - 3);

    const [answerNumeratorA, answerNumeratorB] = randomUniqueIntegersInclusive(
      2,
      headingNumerator - 1,
      2
    );
    const [answerNumeratorC, answerNumeratorD] = randomUniqueIntegersInclusive(
      headingNumerator + 1,
      denominator - 1,
      2
    );

    const remainingNumbers: number[] = range(2, denominator - 1).filter(
      el =>
        el !== headingNumerator &&
        el !== answerNumeratorA &&
        el !== answerNumeratorB &&
        el !== answerNumeratorC &&
        el !== answerNumeratorD
    );

    const [answerNumeratorE, answerNumeratorF] = getRandomSubArrayFromArray(remainingNumbers, 2);

    return {
      denominator,
      headingNumerator,
      answerNumeratorA,
      answerNumeratorB,
      answerNumeratorC,
      answerNumeratorD,
      answerNumeratorE,
      answerNumeratorF
    };
  },
  Component: props => {
    const {
      question: {
        denominator,
        headingNumerator,
        answerNumeratorA,
        answerNumeratorB,
        answerNumeratorC,
        answerNumeratorD,
        answerNumeratorE,
        answerNumeratorF
      },
      translate,
      displayMode
    } = props;

    const arrayOfAnswerNumerators = [
      answerNumeratorA,
      answerNumeratorB,
      answerNumeratorC,
      answerNumeratorD,
      answerNumeratorE,
      answerNumeratorF
    ].map(el => {
      return { value: el.toString() };
    });

    const greaterThan: string[] = [];
    const lessThan: string[] = [];
    arrayOfAnswerNumerators.forEach(item => {
      if (lessThanGreaterThanOrEqualTo(headingNumerator, Number(item.value)) === '<') {
        greaterThan.push(item.value);
      } else {
        lessThan.push(item.value);
      }
    });
    const correctAnswers = [greaterThan, lessThan];
    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortNonUnitFractionsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsSortNonUnitFractions()}
        zoneNames={[
          translate.tableHeaders.greaterThan(`<frac n='${headingNumerator}' d='${denominator}' />`),
          translate.tableHeaders.lessThan(`<frac n='${headingNumerator}' d='${denominator}' />`)
        ]}
        items={arrayOfAnswerNumerators.map(({ value }) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${value}' d='${denominator}' />`}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value
          };
        })}
        pdfItemVariant="pdfSquare"
        testCorrect={correctAnswers}
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aIa',
  description: 'aIa',
  keywords: ['Non-unit fraction', 'Compare', 'Greater than', 'Less than', 'Equal to', 'Bar model'],
  schema: z.object({
    denominator: z.number().int().min(3).max(10),
    numeratorA: z.number().int().min(1).max(9),
    numeratorB: z.number().int().min(1).max(9)
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(3, 10);
    const [numeratorA, numeratorB] = randomUniqueIntegersInclusive(1, denominator - 1, 2);

    return {
      denominator,
      numeratorA,
      numeratorB
    };
  },
  Component: props => {
    const {
      question: { denominator, numeratorA, numeratorB },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numeratorColorArray1 = filledArray(numeratorColor, numeratorA);
    const remainder1 = filledArray('white', denominator - numeratorA);

    const numeratorColorArray2 = filledArray(numeratorColor, numeratorB);
    const remainder2 = filledArray('white', denominator - numeratorB);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    const answerOptions = ['>', '<', '='];

    const fractionDecimal1 = fractionToDecimal(numeratorA, denominator);
    const fractionDecimal2 = fractionToDecimal(numeratorB, denominator);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompareTheFractions()}
        pdfTitle={translate.instructions.useInequalitySymbolsToCompareTheFractions()}
        items={answerOptions}
        itemVariant="square"
        mainPanelStyle={{ alignItems: 'center' }}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={denominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              fractionDividerStyle={{ marginVertical: 2 }}
              preBarText={`<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={denominator}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              fractionDividerStyle={{ marginVertical: 2 }}
              preBarText={`<frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`<frac n='${numeratorA.toLocaleString()}' d='${denominator.toLocaleString()}'/> <ans/> <frac n='${numeratorB.toLocaleString()}' d='${denominator.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2)]}
        questionHeight={900}
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsHidden"
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aIb',
  description: 'aIb',
  keywords: ['Non-unit fraction', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    numeratorA: z.number().min(1).max(9),
    numeratorB: z.number().min(1).max(9),
    numeratorC: z.number().min(1).max(23),
    numeratorD: z.number().min(1).max(23),
    denominatorA: z.number().min(5).max(10),
    denominatorB: z.number().min(11).max(24)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(5, 10);
    const denominatorB = randomIntegerInclusive(11, 24);

    const [numeratorA, numeratorB] = randomUniqueIntegersInclusive(1, denominatorA - 1, 2);
    const [numeratorC, numeratorD] = randomUniqueIntegersInclusive(1, denominatorB - 1, 2);

    return { denominatorA, denominatorB, numeratorA, numeratorB, numeratorC, numeratorD };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, numeratorA, numeratorB, numeratorC, numeratorD },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-end' }}>
            <TextStructure sentence={`<frac n='${numeratorA}' d='${denominatorA}'/>`} />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-start' }}>
            <TextStructure sentence={`<frac n='${numeratorB}' d='${denominatorA}' />`} />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(numeratorA, denominatorA),
          fractionToDecimal(numeratorB, denominatorA)
        )
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-end' }}>
            <TextStructure sentence={`<frac n='${numeratorC}' d='${denominatorB}'/>`} />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-start' }}>
            <TextStructure sentence={`<frac n='${numeratorD}' d='${denominatorB}'/>`} />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(numeratorC, denominatorB),
          fractionToDecimal(numeratorD, denominatorB)
        )
      }
    ];
    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragACardToCompareTheFractions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareFractions()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ alignSelf: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aIb2',
  description: 'aIb',
  keywords: ['Non-unit fraction', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z
    .object({
      numeratorA: z.number().min(1).max(23),
      numeratorB: z.number().min(1).max(23),
      denominatorA: z.number().min(5).max(24)
    })
    .refine(
      val => val.numeratorA < val.denominatorA && val.numeratorB < val.denominatorA,
      'numerators must be less than denominators'
    ),
  simpleGenerator: () => {
    const denominatorA = getRandomBoolean()
      ? randomIntegerInclusive(5, 10)
      : randomIntegerInclusive(11, 24);

    const [numeratorA, numeratorB] = randomUniqueIntegersInclusive(1, denominatorA - 1, 2);

    return { denominatorA, numeratorA, numeratorB };
  },
  Component: props => {
    const {
      question: { denominatorA, numeratorA, numeratorB },
      translate,
      displayMode
    } = props;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-end' }}>
            <TextStructure sentence={`<frac n='${numeratorA}' d='${denominatorA}'/>`} />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-start' }}>
            <TextStructure sentence={`<frac n='${numeratorB}' d='${denominatorA}' />`} />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(numeratorA, denominatorA),
          fractionToDecimal(numeratorB, denominatorA)
        )
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragACardToCompareTheFractions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareFractions()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ alignSelf: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aIc2',
  description: 'aIc',
  keywords: [
    'Compare',
    'Non-unit fraction',
    'Greater than',
    'Less than',
    'Numerator',
    'Denominator'
  ],
  schema: z.object({
    numerator: z.number().int().min(3).max(10),
    denominator: z.number().int().min(5).max(12),
    inequality: z.enum([GREATER_THAN, LESS_THAN])
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(5, 12);
    const numerator = randomIntegerInclusive(3, denominator - 2);
    const inequality = getRandomFromArray([GREATER_THAN, LESS_THAN] as const);
    return { numerator, denominator, inequality };
  },
  Component: props => {
    const {
      question: { numerator, denominator, inequality },
      displayMode,
      translate
    } = props;

    const sentences = [
      {
        sentence: `<frac n='${numerator}' d='${denominator}'/>  ${inequality}  <frac nAns='' d='${denominator}' />`
      },
      {
        sentence: `<frac n='${numerator}' d='${denominator}'/>  ${inequality}  <frac nAns='' d='${denominator}' />`
      }
    ];
    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.whatCouldMissingNumeratorsBe()}
        testCorrect={answer => {
          return (
            ((inequality === GREATER_THAN && numerator > Number(answer[0])) ||
              (inequality === LESS_THAN && numerator < Number(answer[0]))) &&
            ((inequality === GREATER_THAN && numerator > Number(answer[1])) ||
              (inequality === LESS_THAN && numerator < Number(answer[1]))) &&
            Number(answer[1]) !== Number(answer[0])
          );
        }}
        inputMaxCharacters={2}
        pdfDirection="row"
        fractionContainerStyle={{ height: 96 }}
        sentenceStyle={{
          alignItems: 'center',
          marginHorizontal: displayMode !== 'digital' ? 128 : undefined
        }}
        sentences={sentences.map(sentence => sentence.sentence)}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.numeratorMustBeLessThanX(numerator)
        }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aIc',
  description: 'aIc',
  keywords: ['Equivalent', 'Fractions', 'Bar model'],
  schema: z
    .object({
      denominatorA: z.number().int().min(2).max(6),
      denominatorB: z.number().int().min(4).max(12)
    })
    .refine(
      val => val.denominatorB % val.denominatorA === 0,
      'denominatorB must be a multiple of denominatorA'
    ),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 6);
    const denominatorB = randomIntegerInclusive(4, 12, {
      constraint: x => x % denominatorA === 0 && x !== denominatorA
    });
    return { denominatorA, denominatorB };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB },
      translate
    } = props;

    const numerator = denominatorB / denominatorA;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.shadeBarModelsToShowEquivalentFractions()}
        pdfTitle={translate.instructions.useBarModelsToShowEquivalentFractions()}
        sentence={`<frac n='1' d='${denominatorA}' /> = <frac n='${numerator}' d='${denominatorB}' />`}
        testCorrect={() => true}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens: { width } }) => (
          <View>
            <BarModelInteractiveWithState
              id="barmodel1"
              numberOfRows={1}
              numberOfCols={denominatorA}
              tableHeight={100}
              tableWidth={width}
              testCorrect={userAnswer => trueCount(userAnswer) === 1}
            />
            <BarModelInteractiveWithState
              id="barmodel2"
              numberOfRows={1}
              numberOfCols={denominatorB}
              tableHeight={100}
              tableWidth={width}
              testCorrect={userAnswer => trueCount(userAnswer) === numerator}
            />
          </View>
        )}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptEquivalentFractions()
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aId',
  description: 'aId',
  keywords: [
    'Non-unit fraction',
    'Order',
    'Compare',
    'Numerators',
    'Denominator',
    'Greatest',
    'Smallest'
  ],
  schema: z.object({
    numerator: z.number().min(2).max(9),
    arrayOfDenominators: z.array(z.number().min(3).max(20)),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const numerator = randomIntegerInclusive(2, 9);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const arrayOfDenominators = randomUniqueIntegersInclusive(numerator + 1, 20, 5);

    return {
      numerator,
      arrayOfDenominators,
      ordering
    };
  },
  Component: props => {
    const {
      question: { numerator, arrayOfDenominators, ordering },
      translate,
      displayMode
    } = props;

    const correctOrder = sortNumberArray(arrayOfDenominators, ordering);

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions.dragCardsToOrderUnitFractions(
          `${
            ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
          }`
        )}
        pdfTitle={translate.instructions.useCardsToOrderFractions(
          `${
            ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
          }`
        )}
        testCorrect={correctOrder}
        items={arrayOfDenominators.map(number => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${numerator}' d='${number}' />`}
                fractionDividerStyle={{ marginVertical: 2 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value: number
          };
        })}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        moveOrCopy="move"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderNonUnitFractions',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5v2, Question6],
  unpublishedQuestionTypes: [Question4v2],
  archivedQuestionTypes: [Question5, Question4]
});
export default SmallStep;
