import type { BaseTranslation } from '../i18n-types';
import descriptions from './descriptions';
import answerSentences from './answerSentences';
import instructions from './instructions';
import quiz from './quiz';
import markScheme from './markScheme';
import keywords from './keywords';
import smallSteps from './smallSteps';
import ks1Instructions from './ks1Instructions';
import ks1MiscStrings from './ks1MiscStrings';
import ks1AnswerSentences from './ks1AnswerSentences';
import ks1PDFInstructions from './ks1PDFInstructions';
import { MULT } from '../../constants';
import numbersAsWords from './numbersAsWords';

/**
 * Strings displayed to the user, from the whole app, in English.
 *
 * See https://github.com/ivanhofer/typesafe-i18n/tree/main/packages/runtime#syntax.
 * In particular, {0:myType|myFormatter} references a type in ../custom-types.ts and a formatter in ../formatters.ts.
 *
 * When updating this, remember to run `yarn run typesafe-i18n` to generate new types.
 */
const en: BaseTranslation = {
  descriptions,
  instructions,
  ks1Instructions,
  ks1AnswerSentences,
  ks1PDFInstructions,
  ks1MiscStrings,
  numbersAsWords,
  answerSentences,
  quiz,
  markScheme,
  keywords,
  smallSteps,
  /** Must be URL-safe (avoid spaces, punctuation, etc.). */
  years: {
    Year1: 'Year 1',
    Year2: 'Year 2',
    Year3: 'Year 3',
    Year4: 'Year 4',
    Year5: 'Year 5',
    Year6: 'Year 6'
  },

  /** Must be URL-safe (avoid spaces, punctuation, etc.). */
  terms: {
    Autumn: 'Autumn',
    Spring: 'Spring',
    Summer: 'Summer'
  },

  /** Must be URL-safe (avoid spaces, punctuation, etc.). */
  // Generated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts translations-blocks
  blocks: {
    // Year 1
    LengthAndHeight: 'Length and height',
    MassAndVolume: 'Mass and volume',
    MultiplicationAndDivision: 'Multiplication and division',

    // Year 2
    MassCapacityAndTemperature: 'Mass, capacity and temperature',

    // Year 3
    PlaceValue: 'Place value',
    AdditionAndSubtraction: 'Addition and subtraction',
    MultiplicationAndDivisionA: 'Multiplication and division A',
    MultiplicationAndDivisionB: 'Multiplication and division B',
    LengthAndPerimeter: 'Length and perimeter',
    FractionsA: 'Fractions A',
    MassAndCapacity: 'Mass and capacity',
    FractionsB: 'Fractions B',
    Money: 'Money',
    Time: 'Time',
    Shape: 'Shape',
    Statistics: 'Statistics',

    // Year 4
    Area: 'Area',
    Fractions: 'Fractions',
    DecimalsA: 'Decimals A',
    DecimalsB: 'Decimals B',
    PositionAndDirection: 'Position and direction',

    // Year 5
    DecimalsAndPercentages: 'Decimals and percentages',
    PerimeterAndArea: 'Perimeter and area',
    Decimals: 'Decimals',
    NegativeNumbers: 'Negative numbers',
    ConvertingUnits: 'Converting units',
    Volume: 'Volume',

    // Year 6
    AdditionSubtractionMultiplicationAndDivision:
      'Addition, subtraction, multiplication and division',
    Ratio: 'Ratio',
    Algebra: 'Algebra',
    FractionsDecimalsAndPercentages: 'Fractions, decimals and percentages',
    AreaPerimeterAndVolume: 'Area, perimeter and volume'
  },

  // Generated with: yarn ts-node scripts/generateSolDataCodeAndFiles.ts translations-fields
  fields: {
    Number: 'Number',
    Measurement: 'Measurement',
    Geometry: 'Geometry'
  },

  enums: {
    Base10RepresentationVariant: {
      Balloons: 'Balloons',
      'Blue pens': 'Blue pens',
      Bricks: 'Bricks',
      Crayons: 'Crayons',
      Cubes: 'Cubes',
      Counters: 'Counters',
      Lollipops: 'Lollipops',
      Marbles: 'Marbles',
      Nails: 'Nails',
      'Red pens': 'Red pens',
      Sweets: 'Sweets',
      Straws: 'Straws'
    }
  },

  extraContentModal: {
    characterFactA: 'I will multiply the numerator and denominator by the same number.',
    characterFactB: 'I will use the relationship between the numerator and the denominator.',
    characterMethod: "{0}'s Method",
    defaultButtonText: 'Show Hint',
    defaultModalTitle: 'Hint',
    fractionWall: 'Fraction Wall',
    viewCharacterMethod: "View {0}'s method",
    viewFractionWall: 'View fraction wall'
  },

  informationStrings: {
    tapTheNumbersAndUseWorkingSpace: 'Tap the numbers and use the working space to help',
    raTriangleAreaFormula: `<frac n="1" d="2"/> ${MULT} base ${MULT} perpendicular height`,
    useWorkingSpaceToAddExchanges: 'Use the working space to add exchanges',
    useWorkingSpaceToHelp: 'Use the working space to help'
  },

  tableHeaders: {
    exchangeA: 'Exchange a {0}',
    doesNotExchangeA: 'Does not exchange a {0}',
    exchangeAcrossA: 'Exchange across a {0}',
    greaterThan: 'Greater than {0}',
    groupNum: 'Group {0:number|numberFormat}',
    lessThan: 'Less than {0}',
    lessThanNumberVertices: 'Less than {0:number|numberFormat} vertices',
    noExchange: 'No exchange',
    noRemainder: 'Divisions with no remainder',
    nonUnitFractions: 'Non-unit fractions',
    notX: 'Not {0:string|lower}',
    numberOrMoreVertices: '{0:number|numberFormat} or more vertices',
    remainder: 'Divisions with a remainder',
    remainderIsOdd: 'Remainder is odd',
    remainderIsEven: 'Remainder is even',
    remainderIsGreaterThanX: 'Remainder is greater than {0:number|numberFormat}',
    remainderIsLessThanX: 'Remainder is less than {0:number|numberFormat}',
    unitFractions: 'Unit fractions',
    closerTo: 'Closer to {0}',
    Animal: 'Animal',
    Mass: 'Mass',
    Capacity: 'Capacity',
    Length: 'Length',
    Volume: 'Volume',
    Fraction: 'Fraction',
    Decimal: 'Decimal',
    Percentage: 'Percentage',
    DivisionWithRemainders: 'Division with remainders',
    DivisionWithoutRemainders: 'Division without remainders',
    EqualTo: 'Equal to {0}',
    numberOfShapes: 'Number of shapes',
    numberOfSiblings: 'Number of siblings',
    numberOfLollySticks: 'Number of lolly sticks',
    numberOfX: 'Number of {0:string|lower}',
    Fruit: 'Fruit',
    People: 'People',
    Flavours: 'Flavours',
    NumberOfChildren: 'Number of children',
    NumberOfPeople: 'Number of people',
    NameOfDog: 'Name of dog',
    City: 'City',
    TemperatureC: 'Temperature (°C)',
    NumberOfHouses: 'Number of houses',
    Material: 'Material',
    Flavour: 'Flavour',
    IceCreamsSold: 'Number of ice creams sold',
    Number: 'Number',
    Pet: 'Pet',
    Points: 'Points',
    Shape: 'Shape',
    Team: 'Team',
    Colour: 'Colour',
    Tally: 'Tally',
    Total: 'Total',
    EquivalentToX: 'Equivalent to {0}',
    frequency: 'Frequency',
    divisionsWithExchange: 'Divisions with exchange',
    divisonsWithNoExchange: 'Divisions with no exchange',
    fractionsThatAreLessThanHalfWhole: 'Fractions that are less than half of the whole',
    fractionsThatAreMoreThanHalfWhole: 'Fractions that are more than half of the whole',
    numberOfCakesSold: 'Number of cakes sold',
    numberOfCarsInCarPark: 'Number of cars in car park',
    numberOfChildren: 'Number of children',
    numberOfCards: 'Number of cards',
    numberOfCookiesSold: 'Number of cookies sold',
    numberOfDegrees: 'Number of degrees',
    numberOfDrinksSold: 'Number of drinks sold',
    numberOfMuffinsSold: 'Number of muffins sold',
    numberOfFruits: 'Number of fruits',
    numberOfPets: 'Number of pets',
    Year: 'Year',
    fractionOfTotal: 'Fraction of total',
    percentageOfTotal: '% of total',
    Pets: 'Pets',
    sport: 'Sport',
    notATriangle: 'Not a triangle',
    oneLineOfSymmetry: '1 line of symmetry',
    oneOrMoreLineOfSymmetry: '1 or more lines of symmetry',
    moreThanOneLineOfSymmetry: 'More than 1 line of symmetry',
    noLinesOfSymmetry: 'No lines of symmetry',
    reflexAngle: 'Reflex angle',
    acuteOrObtuseAngle: 'Acute or obtuse angle',
    lessThanARightAngle: 'Less than a right angle',
    greaterThanARightAngle: 'Greater than a right angle',
    notAPolygon: 'Not a polygon',
    lessThan2RightAngles: 'Less than 2 right angles',
    twoOrMoreRightAngles: '2 or more right angles',
    quater: 'Quarter',
    threeQuarters: 'Three-quarters',
    shape: 'Shape',
    faces: 'Faces',
    edges: 'Edges',
    vertices: 'Vertices',
    xLess: '{0:number|numberFormat} less',
    xMore: '{0:number|numberFormat} more',
    curvedSurface: 'Curved surface',
    noCurvedSurface: 'No curved surface',
    evenNumberOfVertices: 'Even number of vertices',
    oddNumberOfVertices: 'Odd number of vertices',
    evenNumberOfFaces: 'Even number of faces',
    oddNumberOfFaces: 'Odd number of faces',
    rectangularFaces: 'Rectangular faces',
    noRectangularFaces: 'No rectangular faces',
    triangularFaces: 'Triangular faces',
    noTriangularFaces: 'No triangular faces',
    circularFaces: 'Circular faces',
    noCircularFaces: 'No circular faces',
    fewerThan6Edges: 'Fewer than 6 edges',
    sixOrMoreEdges: '6 or more edges',
    fewerThan6Faces: 'Fewer than 6 faces',
    sixOrMoreFaces: '6 or more faces'
  },

  keys: {
    numCakes: '{0:number|numberFormat} cake{{s}}',
    numCars: '{0:number|numberFormat} car{{s}}',
    numChildren: '{0:number|numberFormat} child{{ren}}',
    numCookies: '{0:number|numberFormat} cookie{{s}}',
    numDrinks: '{0:number|numberFormat} drink{{s}}',
    numIceCreams: '{0:number|numberFormat} ice cream{{s}}',
    numMuffins: '{0:number|numberFormat} muffin{{s}}'
  },

  graphLabels: {
    averageRainfallMm: 'average monthly rainfall (ml)',
    distanceTravelledMiles: 'Distance travelled (miles)',
    game: 'game',
    heightMetres: 'Height (metres)',
    minutes: 'minutes',
    monthsOfTheYear: 'months of the year',
    pointsScored: 'points scored',
    population: 'population',
    rainfallMm: 'rainfall (mm)',
    waterConsumptionLitres: 'Water consumption (litres)',
    year: 'year'
  },

  powersOfTen: {
    ones: 'one{{s}}',
    tens: 'ten{{s}}',
    hundreds: 'hundred{{s}}',
    thousands: 'thousand{{s}}',
    tenThousands: 'ten thousand{{s}}',
    hundredThousands: 'hundred thousand{{s}}',
    millions: 'million{{s}}'
  },

  ordinals: {
    /**
     * Translations for writing a numeral ordinal, e.g. "21st".
     * See https://www.unicode.org/cldr/charts/43/supplemental/language_plural_rules.html for more information about
     * which ordinal rules your language uses.
     * If your locale doesn't use a particular rule (e.g. English only uses one, two, few, other), then leave those
     * blank, e.g. `zeroOrdinal: ''`. (We can't do this for the 'en' locale for technical reasons.)
     */
    numeral: {
      zeroOrdinal: '{0:number|numberFormat}th',
      oneOrdinal: '{0:number|numberFormat}st',
      twoOrdinal: '{0:number|numberFormat}nd',
      fewOrdinal: '{0:number|numberFormat}rd',
      manyOrdinal: '{0:number|numberFormat}th',
      otherOrdinal: '{0:number|numberFormat}th'
    },

    first: 'first',
    second: 'second',
    third: 'third',
    fourth: 'fourth',
    fifth: 'fifth',
    sixth: 'sixth',
    seventh: 'seventh',
    eighth: 'eighth',
    ninth: 'ninth',
    tenth: 'tenth',
    eleventh: 'eleventh',
    twelfth: 'twelfth',
    thirteenth: 'thirteenth',
    fourteenth: 'fourteenth',
    fifteenth: 'fifteenth',
    sixteenth: 'sixteenth',
    seventeenth: 'seventeenth',
    eighteenth: 'eighteenth',
    nineteenth: 'nineteenth',
    twentieth: 'twentieth'
  },

  operations: {
    Add: 'Add',
    dividedBy: 'divided by',
    Subtract: 'Subtract',
    lessThan: 'less than',
    moreThan: 'more than',
    lessThanCaps: 'Less than',
    lighter: 'lighter',
    moreThanCaps: 'More than',
    multipliedBy: 'multiplied by',
    greaterThan: 'greater than',
    heavier: 'heavier',
    heavierThan: 'heavier than',
    lighterThan: 'lighter than',
    equalTo: 'equal to',
    theSameAs: 'the same as',
    timesTheSizeOf: 'times the size of',
    xTheSizeOf: '{0} the size of'
  },

  pronouns: {
    maleObject: 'He',
    maleSubject: 'Him',
    maleDeterminer: 'His',
    malePossessive: 'His',
    femaleObject: 'She',
    femaleSubject: 'Her',
    femaleDeterminer: 'Her',
    femalePossessive: 'Hers'
  },

  units: {
    numberOfCentimetres: '{0:number|numberFormat} centimetre{{s}}',
    numberOfCm: '{0:number|numberFormat} cm',
    numberOfCm2: '{0:number|numberFormat} cm²',
    numberOfCm3: '{0:number|numberFormat} cm³',
    numberOfDegrees: '{0:number|numberFormat}°',
    numberOfDegreesC: '{0} °C',
    numberOfFeet: '{0:number|numberFormat} {{foot | feet}}',
    numberOfGallons: '{0:number|numberFormat} gallon{{s}}',
    numberOfInches: '{0:number|numberFormat} inch{{es}}',
    numberOfLb: '{0:number|numberFormat} lb',
    numberOfLitres: '{0:number|numberFormat} litre{{s}}',
    numberOfLitresAndMl: '{0:number|numberFormat} litre{{s}} and {1:number|numberFormat} ml',
    numberOfLMl: '{0:number|numberFormat} l {1:number|numberFormat} ml',
    numberOfMetres: '{0:number|numberFormat} metre{{s}}',
    numberOfMiles: '{0:number|numberFormat} mile{{s}}',
    numberOfMillilitres: '{0:number|numberFormat} millilitre{{s}}',
    numberOfMillimetres: '{0:number|numberFormat} millimetre{{s}}',
    numberOfP: '{0:number|numberFormat}p',
    numberOfPence: '{0:number|numberFormat} pence',
    numberOfPenny: '{0:number|numberFormat} {{penny | pennies}}',
    numberOfPints: '{0:number|numberFormat} pint{{s}}',
    numberOfPounds: '{0:number|numberFormat} pound{{s}}',
    numberOfStone: '{0:number|numberFormat} stone',
    numberOfG: '{0:number|numberFormat} g',
    numberOfKg: '{0:number|numberFormat} kg',
    numberOfKm: '{0:number|numberFormat} km',
    numberOfL: '{0:number|numberFormat} l',
    numberOfM: '{0:number|numberFormat} m',
    numberOfM2: '{0:number|numberFormat} m²',
    numberOfMl: '{0:number|numberFormat} ml',
    numberOfMm: '{0:number|numberFormat} mm',
    numberOfMm2: '{0:number|numberFormat} mm²',
    numberOfCmAndMm: '{0:number|numberFormat} cm and {1:number|numberFormat} mm',
    centilitres: 'centilitre{{s}}',
    centimetres: 'centimetre{{s}}',
    feet: '{{foot | feet}}',
    gallons: 'gallon{{s}}',
    grams: 'gram{{s}}',
    inches: 'inch{{es}}',
    kilograms: 'kilogram{{s}}',
    kilometres: 'kilometre{{s}}',
    litres: 'litre{{s}}',
    metres: 'metre{{s}}',
    miles: 'mile{{s}}',
    milligrams: 'milligram{{s}}',
    millilitres: 'millilitre{{s}}',
    millimetres: 'millimetre{{s}}',
    months: 'month{{s}}',
    ounces: 'ounce{{s}}',
    pints: 'pint{{s}}',
    pounds: 'pound{{s}}',
    stones: 'stone{{s}}',
    tonnes: 'tonne{{s}}',
    years: 'year{{s}}',
    lb: 'lb',
    cm: 'cm',
    cm2: 'cm²',
    mm: 'mm',
    mm2: 'mm²',
    m: 'm',
    m2: 'm²',
    km: 'km',
    mg: 'mg',
    g: 'g',
    kg: 'kg',
    t: 't',
    ml: 'ml',
    cl: 'cl',
    l: 'l',
    celsius: '°C',
    stringCentimetre: '{0:string} centimetre{{1:s}}',
    stringCm: '{0:string} cm',
    stringDegreesC: '{0} °C',
    stringG: '{0:string} g',
    stringKg: '{0:string} kg',
    stringKm: '{0:string} km',
    stringL: '{0:string} l',
    stringLitres: '{0:string} litre{{1:s}}',
    stringM: '{0:string} m',
    stringM2: '{0:string} m²',
    stringMetre: '{0:string} metre{{1:s}}',
    stringMiles: '{0:string} miles',
    stringMl: '{0:string} ml',
    stringMm: '{0:string} mm'
  },

  time: {
    aQuarterOfAnHour: 'a quarter of an hour',
    am: 'am',
    anHour: 'an hour',
    decadePlural: '{0}s',
    digitalTime: '{hours}:{minutes}',
    digitalTimeAm: '{hours}:{minutes} am',
    digitalTimePm: '{hours}:{minutes} pm',
    halfAnHour: 'half an hour',
    halfPast: 'half past {0}',
    hours: 'hour{{s}}',
    hoursMinutesAm: '{0}:{1} am',
    hoursMinutesPm: '{0}:{1} pm',
    Midnight: 'Midnight',
    midnight: 'midnight',
    minutes: 'minute{{s}}',
    minutesPast: '{0} minute{{s}} past {1}',
    minutesTo: '{0} minute{{s}} to {1}',
    Month: 'Month',
    month: 'month',
    Noon: 'Noon',
    noon: 'noon',
    numAm: '{0} am',
    numNoon: '{0} noon',
    numPm: '{0} pm',
    numDays: '{0:number|numberFormat} day{{s}}',
    numHours: '{0:number|numberFormat} hour{{s}}',
    numHoursAndNumMinutes:
      '{0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minute{{s}}',
    numMinutes: '{0:number|numberFormat} minute{{s}}',
    numMonths: '{0:number|numberFormat} month{{s}}',
    numSeconds: '{0:number|numberFormat} second{{s}}',
    numWeeks: '{0:number|numberFormat} week{{s}}',
    numYears: '{0:number|numberFormat} year{{s}}',
    oClock: "o'clock",
    XTimeOClock: "{0} o'clock",
    oneAndAHalfHours: 'one and a half hours',
    pm: 'pm',
    quarterOfAnHour: 'quarter of an hour',
    quarterPast: 'quarter past {0}',
    quarterTo: 'quarter to {0}',
    seconds: 'second{{s}}',
    threeQuartersOfAnHour: 'three-quarters of an hour',
    wordHour: '{0} hour',
    wordHours: '{0} hours',

    // Days of the week
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday',

    // Months of the year
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',

    monthAbbreviations: {
      January: 'Jan',
      February: 'Feb',
      March: 'Mar',
      April: 'Apr',
      May: 'May',
      June: 'Jun',
      July: 'Jul',
      August: 'Aug',
      September: 'Sep',
      October: 'Oct',
      November: 'Nov',
      December: 'Dec'
    }
  },

  calendar: {
    'Months in a year': 'Months in a year',
    'Days in a week': 'Days in a week',
    'Hours in a day': 'Hours in a day',
    'Days in a non-leap year': 'Days in a non-leap year',
    'Days in a leap year': 'Days in a leap year',
    'Hours in a week': 'Hours in a week',
    'Days in a school week': 'Days in a school week',
    'Maximum number of days in a month': 'Maximum number of days in a month',
    'Minimum numbers of days in a month': 'Minimum numbers of days in a month'
  },

  timePeriod: {
    hours: 'hour{{s}}',
    minutes: 'minute{{s}}',
    seconds: 'second{{s}}',
    days: 'Days',
    week: 'week',
    weeks: 'Weeks',
    numberOfWeeks: '{0:number|numberFormat} week{{s}} {{has|have}}',
    weeksToDays: 'weeks have {0:number|numberFormat} day{{s}}',
    Morning: 'Morning',
    Midday: 'Midday',
    Afternoon: 'Afternoon',
    Evening: 'Evening',
    year: 'year',
    dayX: 'day {0:number|numberFormat}'
  },

  activities: {
    aSchoolDay: 'A school day',
    'bike ride': 'bike ride',
    boilAKettle: 'Boil a kettle',
    blink: 'Blink',
    brushYourTeeth: 'Brush your teeth',
    buildAHouse: 'Build a house',
    clap: 'Clap',
    cookDinner: 'Cook dinner',
    countBack: 'Count back from 20 to 0',
    decorateAHouse: 'Decorate a house',
    eatAGrape: 'Eat a grape',
    eatAnApple: 'Eat an apple',
    eatASweet: 'Eat a sweet',
    flyFromBritainToUSA: 'Fly from Great Britain to the USA',
    getDressed: 'Get dressed',
    goToASwimmingLesson: 'Go to a swimming lesson',
    haveAShower: 'Have a shower',
    jump3Times: 'Jump 3 times',
    kickABall: 'Kick a ball',
    listenToASong: 'Listen to a song',
    makeASandwich: 'Make a sandwich',
    nameCountries: 'Name 5 different countries',
    painting: 'painting',
    paintARoom: 'Paint a room',
    party: 'party',
    putOnYourCoat: 'Put on your coat',
    readABook: 'Read a book',
    run5km: 'Run 5 kilometres',
    runABath: 'Run a bath',
    runAMarathon: 'Run a marathon',
    sleepAtNight: 'Sleep at night',
    snapAPhoto: 'Snap a photo',
    sneeze: 'Sneeze',
    takeABreath: 'Take a breath',
    tenStarJumps: 'Do 10 star jumps',
    tieYourShoes: 'Tie your shoes',
    travelToMoon: 'Travel to the moon',
    travelToSchool: 'Travel to school',
    watchAFilm: 'Watch a film',
    watchATVShow: 'Watch a TV show',
    washDryLaundry: 'Wash and dry laundry',
    washLaundry: 'Wash laundry',
    writeAddress: 'Write your address',
    writeASentence: 'Write a sentence',
    writeAStory: 'Write a story',
    writeFirstName: 'Write your first name',
    writeTheDate: 'Write the date',
    reading: 'reading',
    Reading: 'Reading',
    Cricket: 'Cricket',
    shopping: 'shopping',
    Swimming: 'Swimming',
    swimming: 'swimming',
    Dancing: 'Dancing',
    Football: 'Football',
    Art: 'Art',
    Basketball: 'Basketball',
    Running: 'Running',
    Cycling: 'Cycling',
    Rowing: 'Rowing',
    Archery: 'Archery',
    Tennis: 'Tennis',
    Hockey: 'Hockey',
    Netball: 'Netball',
    Sunrise: 'Sunrise',
    'Wake up': 'Wake up',
    'Walk to school': 'Walk to school',
    'Eat breakfast': 'Eat breakfast',
    'Breakfast club': 'Breakfast club',
    Lunch: 'Lunch',
    'After-school club': 'After-school club',
    'Football match': 'Football match',
    'Gymnastics practice': 'Gymnastics practice',
    'Alarm goes off': 'Alarm goes off',
    'Go to school': 'Go to school',
    'School play rehearsal': 'School play rehearsal',
    'Go home from school': 'Go home from school',
    'Walk home from school': 'Walk home from school'
  },

  beforeAfterschoolActivities: {
    wakeUp: 'wake up',
    breakfast: 'breakfast',
    getDressed: 'get dressed',
    bedtimeStory: 'bedtime story',
    goToAParty: 'go to a party',
    goToSleep: 'go to sleep',
    dinner: 'dinner',
    brushTeeth: 'brush teeth',
    readABook: 'read a book',
    play: 'play'
  },

  objects: {
    aBagOfCrisps: 'A bag of crisps',
    aBagOfMarbles: 'A bag of marbles',
    aBalloon: 'A balloon',
    aBarOfChocolate: 'A bar of chocolate',
    aBook: 'A book',
    aBread: 'A bread',
    aCandle: 'A candle',
    aCookie: 'A cookie',
    aCrayonBox: 'A crayon box',
    aDrink: 'A drink',
    aFizzyDrink: 'A fizzy drink',
    aFootball: 'A football',
    aJuiceBottle: 'A juice bottle',
    aHat: 'A hat',
    aHulahoop: 'A hulahoop',
    aJumper: 'A jumper',
    aKettle: 'A kettle',
    aKeyChain: 'A key chain',
    aLemonade: 'A lemonade',
    aLollipop: 'A lollipop',
    aMagazine: 'A magazine',
    aMug: 'A mug',
    aPackOfCards: 'A pack of cards',
    aPackOfPens: 'A pack of pens',
    aPen: 'A pen',
    aPencil: 'A pencil',
    aPencilCase: 'A pencil case',
    aRucksack: 'A rucksack',
    aScarf: 'A scarf',
    aSheetOfStickers: 'A sheet of stickers',
    aStickerBook: 'A sticker book',
    aTubeOfCrisps: 'A tube of crisps',
    anIceCream: 'An ice cream',
    Apple: 'Apple',
    Apples: 'Apples',
    Bag: 'Bag',
    Bags: 'Bags',
    Ball: 'Ball',
    Balls: 'Balls',
    Balloon: 'Balloon',
    Balloons: 'Balloons',
    Banana: 'Banana',
    Bananas: 'Bananas',
    Barrel: 'Barrel',
    Bath: 'Bath',
    Bead: 'Bead',
    Beaker: 'Beaker',
    Bike: 'Bike',
    Bikes: 'Bikes',
    Birch: 'Birch',
    'Blue pens': 'Blue pens',
    Blueberries: 'Blueberries',
    Bolts: 'Bolts',
    Bone: 'Bone',
    Book: 'Book',
    Books: 'Books',
    bottle: 'bottle',
    Box: 'Box',
    boxesOfEggs: 'Boxes of eggs',
    Bread: 'Bread',
    Bucket: 'Bucket',
    bunchesOfFlowers: 'Bunches of flowers',
    Bus: 'Bus',
    Button: 'Button',
    Boxes: 'Boxes',
    Cake: 'Cake',
    Cakes: 'Cakes',
    Car: 'Car',
    Cars: 'Cars',
    Carrot: 'Carrot',
    Carrots: 'Carrots',
    cartonsOfMilk: 'Cartons of milk',
    Cedar: 'Cedar',
    Child: 'Child',
    ChocolateBar: 'Chocolate bar',
    ChocolateBars: 'Chocolate bars',
    CinemaTicket: 'Cinema ticket',
    Clips: 'Clips',
    Coat: 'Coat',
    Computer: 'Computer',
    Comic: 'Comic',
    Cookie: 'Cookie',
    Cookies: 'Cookies',
    Counters: 'Counter{{s}}',
    Crate: 'Crate',
    Crates: 'Crates',
    CrayonBox: 'Crayon box',
    CrayonBoxes: 'Crayon boxes',
    Crayon: 'Crayon',
    Crayons: 'Crayons',
    Cube: 'Cube',
    Cubes: 'Cubes',
    cup: 'cup',
    Egg: 'Egg',
    Eggs: 'Eggs',
    Elm: 'Elm',
    FeltTip: 'Felt tip',
    FeltTips: 'Felt tips',
    FishTank: 'Fish tank',
    Fish: 'Fish',
    Fir: 'Fir',
    FizzyDrink: 'Fizzy drink',
    FizzyDrinks: 'Fizzy drinks',
    Flower: 'Flower',
    Flowers: 'Flowers',
    Game: 'Game',
    GamesConsole: 'Games console',
    GamesConsoles: 'Games consoles',
    Glass: 'Glass',
    Hat: 'Hat',
    Jug: 'Jug',
    JuiceBottle: 'Juice bottle',
    JuiceBottles: 'Juice bottles',
    JuiceCarton: 'Juice carton',
    Jumper: 'Jumper',
    Kettle: 'Kettle',
    Kettles: 'Kettles',
    Laptop: 'Laptop',
    Laptops: 'Laptops',
    Lemonade: 'Lemonade',
    Lemonades: 'Lemonades',
    Lemons: 'Lemons',
    Limes: 'Limes',
    LollySticks: 'Lolly sticks',
    Man: 'Man',
    Mangoes: 'Mangoes',
    Maple: 'Maple',
    Marble: 'Marble',
    Marbles: 'Marble{{s}}',
    MilkCarton: 'Milk carton',
    Mug: 'Mug',
    Mugs: 'Mugs',
    Nails: 'Nails',
    Oak: 'Oak',
    Onions: 'Onions',
    Orange: 'Orange',
    Oranges: 'Oranges',
    'Pairs of shoes': 'Pairs of shoes',
    'Pair of shoes': 'Pair of shoes',
    Pear: 'Pear',
    Pears: 'Pears',
    Pebble: 'Pebble',
    Pencil: 'Pencil',
    PencilCase: 'Pencil case',
    PencilCases: 'Pencil cases',
    Pencils: 'Pencils',
    Pen: 'Pen',
    Pens: 'Pens',
    Phone: 'Phone',
    Phones: 'Phones',
    Pie: 'Pie',
    Pies: 'Pies',
    Pineapple: 'Pineapple',
    Pineapples: 'Pineapples',
    Pins: 'Pins',
    Pizza: 'Pizza',
    Pizzas: 'Pizzas',
    Plate: 'Plate',
    Plates: 'Plates',
    Pond: 'Pond',
    Pot: 'Pot',
    Potatoes: 'Potatoes',
    Pots: 'Pots',
    'Red pens': 'Red pens',
    Ribbon: 'Ribbon',
    Ribbons: 'Ribbons',
    Rubber: 'Rubber',
    Rubbers: 'Rubbers',
    Rucksack: 'Rucksack',
    Rucksacks: 'Rucksacks',
    Ruler: 'Ruler',
    Rulers: 'Rulers',
    Scooter: 'Scooter',
    Scooters: 'Scooters',
    Shell: 'Shell',
    someSweets: 'some sweets',
    Strawberry: 'Strawberry',
    Strawberries: 'Strawberries',
    Sweet: 'Sweet',
    Sweets: 'Sweets',
    SwimmingPool: 'Swimming pool',
    Tablespoon: 'Tablespoon',
    Tablet: 'Tablet',
    Television: 'Television',
    Televisions: 'Televisions',
    theAnt: 'The ant',
    theApple: 'The apple',
    theBalloon: 'The balloon',
    theBanana: 'The banana',
    theBee: 'The bee',
    theBike: 'The bike',
    theBoat: 'The boat',
    theBox: 'The box',
    theCoin: 'The coin',
    theCone: 'the cone',
    theCookie: 'The cookie',
    theCrayon: 'The crayon',
    theCube: 'The cube',
    theCuboid: 'the cuboid',
    theCylinder: 'the cylinder',
    theDice: 'The dice',
    theDinosaur: 'The dinosaur',
    theElephant: 'The elephant',
    theFishTank: 'The fish tank',
    theGiraffe: 'The giraffe',
    theJuiceBottle: 'The juice bottle',
    theKettle: 'The kettle',
    theLollipop: 'The lollipop',
    theMuffin: 'The muffin',
    theMug: 'The mug',
    theOrange: 'The orange',
    thePear: 'The pear',
    thePencil: 'The pencil',
    thePlane: 'The plane',
    theShoppingCart: 'The shopping cart',
    theSphere: 'the sphere',
    theStick: 'The stick',
    theStraw: 'The straw',
    theStrawberry: 'The strawberry',
    theSuitcase: 'The suitcase',
    theToyCar: 'The toy car',
    tinsOfBeans: 'Tins of beans',
    Tower: 'Tower',
    towerOfCubes: 'Tower of cubes',
    'T-shirt': 'T-shirt',
    Tree: 'Tree',
    Trees: 'Trees',
    Vase: 'Vase',
    Vases: 'Vases',
    Watch: 'Watch',
    Watches: 'Watches',
    WaterBottle: 'Water bottle',
    Willow: 'Willow',
    Woman: 'Woman',
    Worm: 'Worm',
    xCars: '{0} cars'
  },

  weightObjects: {
    apple: 'apple',
    apples: 'apples',
    ball: 'ball',
    banana: 'banana',
    bananas: 'bananas',
    'blue cube': 'blue cube',
    brick: 'brick',
    'green cube': 'green cube',
    iceCube: 'ice cube',
    muffin: 'muffin',
    muffins: 'muffins',
    orange: 'orange',
    'orange cube': 'orange cube',
    oranges: 'oranges',
    pear: 'pear',
    pears: 'pears',
    present: 'present',
    'purple cube': 'purple cube',
    'red cube': 'red cube',
    rubixCube: 'rubix cube',
    spoon: 'spoon',
    strawberry: 'strawberry',
    strawberries: 'strawberries'
  },

  animals: {
    ants: 'Ant{{s}}',
    bees: 'Bee{{s}}',
    birds: 'Bird{{s}}',
    cats: 'cat{{s}}',
    cows: 'cow{{s}}',
    Cow: 'Cow{{s}}',
    Cows: 'Cows',
    cowsParentheses: 'cow(s)',
    dogs: 'dog{{s}}',
    fish: 'fish',
    frogs: 'Frog{{s}}',
    gerbils: 'gerbil{{s}}',
    Goats: 'Goats',
    hamsters: 'hamster{{s}}',
    horses: 'horse{{s}}',
    Horses: 'Horses',
    Horse: 'Horse{{s}}',
    horsesParentheses: 'horse(s)',
    lions: 'Lion{{s}}',
    owls: 'Owl{{s}}',
    Pigs: 'Pigs',
    rabbits: 'rabbit{{s}}',
    sheep: 'sheep',
    Sheep: 'Sheep'
  },

  pets: {
    birds: 'bird{{s}}',
    cats: 'cat{{s}}',
    dogs: 'dog{{s}}'
  },

  clothing: {
    Jumpers: 'Jumper{{s}}',
    'Pairs of shorts': 'Pair{{s}} of shorts',
    'Pairs of trousers': 'Pair{{s}} of trousers',
    Shirts: 'Shirt{{s}}',
    'T-shirts': 'T-shirt{{s}}'
  },

  places: {
    Bakery: 'Bakery',
    Bank: 'Bank',
    Cafe: 'Café',
    College: 'College',
    Farm: 'Farm',
    Field: 'Field',
    Florist: 'Florist',
    Garden: 'Garden',
    HighStreet: 'High street',
    Hospital: 'Hospital',
    House: 'House',
    Library: 'Library',
    Market: 'Market',
    Museum: 'Museum',
    Park: 'Park',
    Pharmacy: 'Pharmacy',
    Playground: 'Playground',
    PoliceStation: 'Police station',
    Pool: 'Pool',
    PostOffice: 'Post office',
    School: 'School',
    Shop: 'Shop',
    ThemePark: 'Theme Park',
    Trees: 'Trees',
    Zoo: 'Zoo'
  },

  countries: {
    Spain: 'Spain',
    France: 'France',
    USA: 'USA',
    Italy: 'Italy',
    Egypt: 'Egypt',
    Japan: 'Japan',
    India: 'India',
    Belgium: 'Belgium',
    Germany: 'Germany'
  },

  cities: {
    Paris: 'Paris',
    Berlin: 'Berlin',
    Brussels: 'Brussels',
    Zurich: 'Zurich',
    Madrid: 'Madrid',
    Barcelona: 'Barcelona',
    Porto: 'Porto',
    Rome: 'Rome',
    Athens: 'Athens',
    London: 'London',
    Manchester: 'Manchester',
    Liverpool: 'Liverpool',
    Edinburgh: 'Edinburgh',
    Glasgow: 'Glasgow',
    Birmingham: 'Birmingham',
    Bristol: 'Bristol',
    Newcastle: 'Newcastle',
    Leeds: 'Leeds',
    Sheffield: 'Sheffield',
    Cardiff: 'Cardiff',
    Belfast: 'Belfast',
    Nottingham: 'Nottingham',
    Southampton: 'Southampton',
    Leicester: 'Leicester',
    Coventry: 'Coventry',
    Plymouth: 'Plymouth',
    Aberdeen: 'Aberdeen',
    Dundee: 'Dundee',
    Hull: 'Hull',
    Stockholm: 'Stockholm',
    Dublin: 'Dublin',
    Oslo: 'Oslo',
    Copenhagen: 'Copenhagen',
    Moscow: 'Moscow'
  },

  fruits: {
    aBanana: 'A banana',
    aLemon: 'A lemon',
    aLime: 'A lime',
    aMango: 'A mango',
    anApple: 'An apple',
    anOrange: 'An orange',
    aPear: 'A pear',
    aPlum: 'A plum',
    Apple: 'Apple',
    apple: 'apple',
    Apples: 'Apples',
    Banana: 'Banana',
    banana: 'banana',
    Bananas: 'Bananas',
    Cherry: 'Cherry',
    Grape: 'Grape',
    Kiwi: 'Kiwi',
    Lemon: 'Lemon',
    lemon: 'lemon',
    Lemons: 'Lemons',
    Lime: 'Lime',
    lime: 'lime',
    Limes: 'Limes',
    Mango: 'Mango',
    mango: 'mango',
    Mangos: 'Mangos',
    Orange: 'Orange',
    orange: 'orange',
    Oranges: 'Oranges',
    Pear: 'Pear',
    pear: 'pear',
    Pears: 'Pears',
    Pineapple: 'Pineapple',
    Plum: 'Plum',
    plum: 'plum',
    Plums: 'Plums',
    Strawberry: 'Strawberry',
    Watermelon: 'Watermelon'
  },

  acronyms: {
    // These are used for labelling charts so should be kept unique where possible
    // Colors
    White: 'W',
    Pink: 'P',
    Blue: 'B',
    Green: 'G',
    Orange: 'O',
    Red: 'R',
    Yellow: 'Y',
    Purple: 'Pur',
    Grey: 'Gr',
    // Fruit
    Apple: 'A',
    Banana: 'B',
    Cherry: 'C',
    Grape: 'G',
    Pear: 'P',
    Mango: 'M',
    Watermelon: 'W',
    Kiwi: 'K',
    Pineapple: 'Pi',
    Strawberry: 'S',
    // Activities
    Reading: 'R',
    Cricket: 'C',
    Swimming: 'S',
    Dancing: 'D',
    Football: 'F',
    Art: 'A',
    // Flavours
    Vanilla: 'V',
    Chocolate: 'Ch',
    Lemon: 'L',
    Coconut: 'C',
    'Mint choc chip': 'M',
    Pistachio: 'P',
    Bubblegum: 'Bu',
    Hazelnut: 'H',
    Raspberry: 'R'
  },

  woods: {
    Oak: 'Oak',
    Beech: 'Beech',
    Ash: 'Ash',
    Willow: 'Willow',
    Pine: 'Pine',
    Elm: 'Elm',
    Cedar: 'Cedar'
  },

  sports: {
    Badminton: 'Badminton',
    Basketball: 'Basketball',
    Basketballs: 'Basketballs',
    Cricket: 'Cricket',
    'Cricket ball': 'Cricket ball',
    'Cricket balls': 'Cricket balls',
    Football: 'Football',
    Footballs: 'Footballs',
    Hockey: 'Hockey',
    Netball: 'Netball',
    Netballs: 'Netballs',
    Rugby: 'Rugby',
    'Rugby ball': 'Rugby ball',
    'Rugby balls': 'Rugby balls',
    Tennis: 'Tennis',
    'Tennis ball': 'Tennis ball',
    'Tennis balls': 'Tennis balls',
    Volleyball: 'Volleyball'
  },

  fractions: {
    wholes: 'whole{{s}}',
    halves: '{{half|halves}}',
    thirds: 'third{{s}}',
    quarters: 'quarter{{s}}',
    fifths: 'fifth{{s}}',
    sixths: 'sixth{{s}}',
    sevenths: 'seventh{{s}}',
    eighths: 'eighth{{s}}',
    ninths: 'ninth{{s}}',
    tenths: 'tenth{{s}}',
    hundredths: 'hundredth{{s}}',
    thousandths: 'thousandth{{s}}',
    toFindXFracOfAnAmountYou: 'To find {0} of an amount you',
    aThird: 'a third',
    aQuarter: 'a quarter',
    aFifth: 'a fifth'
  },

  letters: {
    A: 'A',
    B: 'B',
    C: 'C',
    D: 'D',
    E: 'E',
    F: 'F',
    G: 'G',
    H: 'H',
    I: 'I',
    J: 'J',
    K: 'K',
    L: 'L',
    M: 'M',
    N: 'N',
    O: 'O',
    P: 'P',
    Q: 'Q',
    R: 'R',
    S: 'S',
    T: 'T',
    U: 'U',
    V: 'V',
    W: 'W',
    X: 'X',
    Y: 'Y',
    Z: 'Z'
  },

  multiples: {
    doubled: 'doubled',
    tripled: 'tripled',
    quadrupled: 'quadrupled'
  },

  shapes: {
    aCircle: 'a cirle',
    aDecagon: 'a decagon',
    aHeptagon: 'a heptagon',
    AHeptagon: 'A heptagon',
    aHexagon: 'a hexagon',
    AHexagon: 'A hexagon',
    aNonagon: 'a nonagon',
    aParallelogram: 'a parallelogram',
    aPentagon: 'a pentagon',
    APentagon: 'A pentagon',
    aQuadrilateral: 'a quadrilateral',
    aRectangle: 'a rectangle',
    ARectangle: 'A rectangle',
    aRightAngledTriangle: 'a right angled triangle',
    aSquare: 'a square',
    ASquare: 'A square',
    aTrapezium: 'a trapezium',
    aTriangle: 'a triangle',
    ATriangle: 'A triangle',
    anIsoscelesTriangle: 'an isosceles triangle',
    anOctagon: 'an octagon',
    AnOctagon: 'An octagon',
    arrow: 'arrow',
    centre: 'centre',
    circles: 'circle{{s}}',
    Circles: 'Circle{{s}}',
    circumference: 'circumference',
    Cones: 'Cone{{s}}',
    cones: 'cone{{s}}',
    crosses: 'cross{{es}}',
    cube: 'cube',
    cuboid: 'cuboid',
    Cubes: 'Cube{{s}}',
    cubes: 'cube{{s}}',
    Cuboids: 'Cuboid{{s}}',
    cuboids: 'cuboid{{s}}',
    Cylinders: 'Cylinder{{s}}',
    cylinders: 'cylinder{{s}}',
    decagons: 'decagon{{s}}',
    diameter: 'diameter',
    equilateralTriangles: 'equilateral triangle{{s}}',
    Heptagons: 'Heptagon{{s}}',
    heptagons: 'heptagon{{s}}',
    Hexagons: 'Hexagon{{s}}',
    hexagons: 'hexagon{{s}}',
    hexagonal: 'hexagonal',
    HexagonalPrisms: 'Hexagonal prism{{s}}',
    hexagonalPrisms: 'hexagonal prism{{s}}',
    hexagonalPyramids: 'hexagonal pyramid{{s}}',
    isoscelesTriangles: 'isosceles triangle{{s}}',
    kites: 'kite{{s}}',
    nonagons: 'nonagon{{s}}',
    numberCircularFacesAndNumberCurvedSurfaces:
      '{0:number|numberFormat} circular face{{s}} and<br/>{1:number|numberFormat} curved surface{{s}}',
    numberHexagonalFacesAndNumberRectangularFaces:
      '{0:number|numberFormat} hexagonal face{{s}} and<br/>{1:number|numberFormat} rectangular face{{s}}',
    numberPentagonalFacesAndNumberRectangularFaces:
      '{0:number|numberFormat} pentagonal face{{s}} and<br/>{1:number|numberFormat} rectangular face{{s}}',
    numberRectangularFaces: '{0:number|numberFormat} rectangular face{{s}}',
    numberSquareFaces: '{0:number|numberFormat} square face{{s}}',
    numberSquareFacesAndNumberTriangularFaces:
      '{0:number|numberFormat} square face{{s}} and<br/>{1:number|numberFormat} triangular face{{s}}',
    numberTriangularFaces: '{0:number|numberFormat} triangular face{{s}}',
    numberTriangularFacesAndNumberRectangularFaces:
      '{0:number|numberFormat} triangular face{{s}} and<br/>{1:number|numberFormat} rectangular face{{s}}',
    Octagons: 'Octagon{{s}}',
    octagons: 'octagon{{s}}',
    octagonal: 'octagonal',
    octagonalPrisms: 'octagonal prism{{s}}',
    octagonalPyramids: 'octagonal pyramid{{s}}',
    parallelograms: 'parallelogram{{s}}',
    pentagonal: 'pentagonal',
    PentagonalPrisms: 'Pentagonal prism{{s}}',
    pentagonalPrisms: 'pentagonal prism{{s}}',
    pentagonalPyramids: 'pentagonal pyramid{{s}}',
    Pentagons: 'Pentagon{{s}}',
    pentagons: 'pentagon{{s}}',
    points: 'point{{s}}',
    prisms: 'prism{{s}}',
    pyramids: 'pyramid{{s}}',
    quadrilaterals: 'quadrilateral{{s}}',
    radius: 'radius',
    rectangles: 'rectangle{{s}}',
    Rectangles: 'Rectangle{{s}}',
    rectangular: 'rectangular',
    rectilinear: 'rectilinear',
    regularDecagons: 'regular decagon{{s}}',
    regularHeptagons: 'regular heptagon{{s}}',
    regularHexagons: 'regular hexagon{{s}}',
    regularNonagons: 'regular nonagon{{s}}',
    regularOctagons: 'regular octagon{{s}}',
    regularPentagons: 'regular pentagon{{s}}',
    rhombuses: 'rhombus{{es}}',
    rightAngledTrapeziums: 'right-angled trapezium{{s}}',
    rightAngledTriangles: 'right-angled triangle{{s}}',
    scaleneTriangles: 'scalene triangle{{s}}',
    spheres: 'sphere{{s}}',
    squares: 'square{{s}}',
    Squares: 'Square{{s}}',
    SquareBasedPyramids: 'Square-based pyramid{{s}}',
    squareBasedPyramids: 'square-based pyramid{{s}}',
    stars: 'star{{s}}',
    tetrahedrons: 'tetrahedron{{s}}',
    trapeziums: 'trapezium{{s}}',
    triangles: 'triangle{{s}}',
    Triangles: 'Triangle{{s}}',
    triangular: 'triangular',
    TriangularBasedPyramids: 'Triangular-based pyramid{{s}}',
    triangularBasedPyramids: 'triangular-based pyramid{{s}}',
    TriangularPrisms: 'Triangular prism{{s}}',
    triangularPrisms: 'triangular prism{{s}}',
    properties: {
      nCircularFaces: '{0} circular face{{s}}',
      nHexagonalFaces: '{0} hexagonal face{{s}}',
      nPentagonalFaces: '{0} pentagonal face{{s}}',
      nRectangularFaces: '{0} rectangular face{{s}}',
      nSquareFaces: '{0} square face{{s}}',
      nTriangularFaces: '{0} triangular face{{s}}'
    }
  },

  symmetry: {
    horizontal: 'horizontal',
    vertical: 'vertical',
    diagonal: 'diagonal',
    neither: 'neither'
  },

  directions: {
    antiClockwise: 'anti-clockwise',
    clockwise: 'clockwise',
    down: 'down',
    east: 'east',
    fullTurn: 'full turn',
    fullTurnAntiClockwise: 'full turn anti-clockwise',
    fullTurnClockwise: 'full turn clockwise',
    halfTurn: 'half turn',
    halfTurnAntiClockwise: 'half turn anti-clockwise',
    halfTurnClockwise: 'half turn clockwise',
    left: 'left',
    north: 'north',
    northEast: 'north-east',
    northWest: 'north-west',
    quarterTurn: 'quarter turn',
    quarterTurnAntiClockwise: 'quarter turn anti-clockwise',
    quarterTurnClockwise: 'quarter turn clockwise',
    right: 'right',
    south: 'south',
    southEast: 'south-east',
    southWest: 'south-west',
    threeQuarterTurn: 'three-quarter turn',
    threeQuarterTurnAntiClockwise: 'three quarter turn anti-clockwise',
    threeQuarterTurnClockwise: 'three quarter turn clockwise',
    up: 'up',
    west: 'west'
  },

  angles: {
    aRightAngle: 'a right angle',
    rightAngle: 'right angle',
    acuteAngle: 'acute angle',
    obtuseAngle: 'obtuse angle',
    acute: 'acute',
    obtuse: 'obtuse',
    reflexAngle: 'reflex angle',
    anglesAtApointOnAStraightLine: 'angles at a point on a straight line'
  },

  prepositions: {
    in: 'In',
    into: 'Into',
    on: 'On',
    onto: 'Onto'
  },

  misc: {
    adults: 'adults',
    and: 'and',
    animals: 'animal{{s}}',
    bears: 'bear{{s}}',
    'Bus A': 'Bus A',
    'Bus B': 'Bus B',
    'Bus C': 'Bus C',
    children: 'children',
    colour: 'colour',
    costPence: 'cost (p)',
    countryVisited: 'Country visited',
    double: 'double',
    empty: 'empty',
    has: 'has',
    have: 'have',
    is: 'is',
    'is not': 'is not',
    loadingEllipsis: 'Loading…',
    jugA: 'Jug A',
    jugB: 'Jug B',
    numberOfAnimals: 'number of animals',
    numberOfExchanges: '{0:number|numberFormat} exchange{{s}}',
    numberOfChildren: 'number of children',
    numberOfQuestions: '{0:number|numberFormat} question{{s}}',
    numberOfQuestionsFraction: '{0:number|numberFormat}/{1:number|numberFormat} question{{s}}',
    numberOfOnes: '{0:number|numberFormat} one{{s}}',
    numberOfTens: '{0:number|numberFormat} ten{{s}}',
    numberOfHundreds: '{0:number|numberFormat} hundred{{s}}',
    numberOfThousands: '{0:number|numberFormat} thousand{{s}}',
    numberOfVisitors: 'Number of visitors',
    numberOfVisitorsLower: 'number of visitors',
    numberOfFruits: '{0:number|numberFormat} fruit{{s}}',
    tens: 'tens',
    of: 'of',
    ones: 'ones',
    nearlyEmpty: 'nearly empty',
    nearlyFull: 'nearly full',
    No: 'No',
    Yes: 'Yes',
    packsOf: 'Packs of',
    remainderSymbol: 'r',
    True: 'true',
    False: 'false',
    full: 'full',
    OK: 'OK',
    Cancel: 'Cancel',
    Coldest: 'Coldest',
    Continue: 'Continue',
    IKnowThat: 'I know that {0}',
    Input: 'Input',
    Output: 'Output',
    Correct: 'Correct',
    Incorrect: 'Incorrect',
    Agree: 'Agree',
    Disagree: 'Disagree',
    Shortest: 'Shortest',
    Long: 'Long',
    Longer: 'Longer',
    LongerThan: 'Longer than',
    Longest: 'Longest',
    less: 'less',
    greater: 'greater',
    smaller: 'smaller',
    startTime: 'Start time',
    finishTime: 'Finish time',
    Name: 'Name',
    Date: 'Date',
    RoundN: 'Round {0}',
    Heavier: 'Heavier',
    Heaviest: 'Heaviest',
    Lighter: 'Lighter',
    Lightest: 'Lightest',
    XChildren: `{0} children`,
    XHouses: `{0} houses`,
    XTimes: `{0} time{{s}}`,
    XPoints: `{0} points`,
    temperatureC: 'temperature (°C)',
    time: 'time',
    massKg: 'mass (kg)',
    heightCm: 'height (cm)',
    population: 'population',
    theCapacity: 'The capacity',
    theLength: 'The length',
    theSameMassAs: 'the same mass as',
    theMass: 'The mass',
    theVolume: 'The volume',
    times: 'times',
    nTimes: '{0:number|numberFormat} time{{s}}',
    twice: 'twice',
    showings: 'showings',
    more: 'more',
    siblings: 'siblings',
    pets: 'pets',
    pet: 'pet',
    day: 'day',
    numberOfPets: 'number of pets',
    teamA: 'team A',
    teamB: 'team B',
    teamC: 'team C',
    gameA: 'game A',
    gameB: 'game B',
    gameC: 'game C',
    typeOfPet: 'type of pet',
    Warmest: 'Warmest',
    yearN: 'Year {0}',
    Shorter: 'Shorter',
    ShorterThan: 'Shorter than',
    Tall: 'Tall',
    Taller: 'Taller',
    Tallest: 'Tallest',
    distances: 'distances',
    weights: 'weights',
    parallel: 'parallel',
    perpendicular: 'perpendicular',
    shape: 'shape',
    shapes: 'shapes',
    angle: 'angle',
    closed: 'closed',
    open: 'open',
    curved: 'curved',
    straight: 'straight',
    twoD: '2-D',
    ThreeD: '3-D',
    notCubes: 'not cubes',
    halfAMillion: 'half a million',
    inside: 'inside',
    around: 'around',
    space: 'space',
    schoolDinner: 'school dinner',
    packedLunch: 'packed lunch',
    masses: 'masses'
  },

  food: {
    Cakes: 'Cake{{s}}',
    Cookies: 'Cookie{{s}}',
    Churro: 'Churro{{s}}',
    Donuts: 'Donut{{s}}',
    EtonMess: 'Eton mess{{es}}',
    FairyBread: 'Fairy bread{{s}}',
    IceCreams: 'Ice cream{{s}}',
    JamTart: 'Jam tart{{s}}',
    Muffins: 'Muffin{{s}}',
    Omelette: 'Omelette{{s}}',
    Pancake: 'Pancake{{s}}',
    RockCake: 'Rock cake{{s}}',
    Scone: 'Scone{{s}}',
    Snacks: 'Snack{{s}}',
    Waffle: 'Waffle{{s}}',
    units: {
      name: 'Units',
      g: 'g',
      ml: 'ml',
      slices: 'Slices',
      nests: 'Nests',
      eggs: 'Eggs'
    },
    ingredients: {
      Fillings: 'Fillings',
      Pastry: 'Pastry',
      Butter: 'Butter',
      Jam: 'Jam',
      Bread: 'Bread',
      Sprinkles: 'Sprinkles',
      Strawberries: 'Strawberries',
      Meringue: 'Meringue',
      Cream: 'Cream',
      Scone: 'Scone',
      Flour: 'Flour',
      Eggs: 'Eggs',
      Milk: 'Milk',
      ChocolateSauce: 'Chocolate sauce',
      Sugar: 'Sugar'
    },
    bread: {
      Name: 'Bread',
      White: 'White',
      Brown: 'Brown',
      Rye: 'Rye',
      Ciabatta: 'Ciabatta',
      Baguette: 'Baguette',
      fillings: {
        Cheese: 'Cheese',
        Tuna: 'Tuna',
        Chicken: 'Chicken',
        Ham: 'Ham',
        Turkey: 'Turkey'
      },
      breadRolls: 'bread rolls'
    },
    iceCream: {
      flavours: {
        Vanilla: 'Vanilla',
        Chocolate: 'Chocolate',
        Strawberry: 'Strawberry',
        Lemon: 'Lemon',
        Banana: 'Banana',
        Coconut: 'Coconut',
        'Mint choc chip': 'Mint choc chip',
        Pistachio: 'Pistachio',
        Bubblegum: 'Bubblegum',
        Hazelnut: 'Hazelnut',
        Raspberry: 'Raspberry'
      },
      toppings: {
        Sauce: 'Sauce',
        Wafer: 'Wafer',
        Honey: 'Honey',
        WhippedCream: 'Whipped Cream',
        MapleSyrup: 'Maple Syrup',
        Sprinkles: 'Sprinkles'
      }
    },
    mass: {
      Cake: 'Cake{{s}}',
      Pie: 'Pie{{s}}',
      BoxOfDoughnuts: 'Box of doughnuts',
      CrateOfApples: 'Crate of apples',
      CrateOfBananas: 'Crate of bananas',
      CrateOfPears: 'Crate of pears'
    }
  },

  drink: {
    Drinks: 'Drink{{s}}',
    juice: 'juice',
    milk: 'milk',
    water: 'water',
    smoothie: 'smoothie'
  },

  shops: {
    schoolSupplies: 'School Supplies',
    stationeryStore: 'Stationery Store'
  },

  programmes: {
    programme: 'Programme',
    startTime: 'Start time',
    finishTime: 'Finish time',
    duration: 'Duration',
    anAdventure: 'An Adventure',
    dennisTheScientist: 'Dennis the Scientist',
    pals: 'Pals',
    theFootballShow: 'The Football Show'
  },

  items: {
    'a book': 'a book',
    'a checkers board': 'a checkers board',
    'a box of marbles': 'a box of marbles',
    'a cap': 'a cap',
    'a football': 'a football',
    'a rugby ball': 'a rugby ball',
    'a magazine': 'a magazine',
    'a pack of crayons': 'a pack of crayons',
    'a pair of socks': 'a pair of socks',
    'a yo-yo': 'a yo-yo',
    ant: 'ant{{s}}',
    apple: 'apple{{s}}',
    balloon: 'balloon{{s}}',
    balls: 'ball{{s}}',
    beads: 'bead{{s}}',
    bee: 'bee{{s}}',
    bench: 'Bench{{s}}',
    bike: 'bike{{s}}',
    boat: 'boat{{s}}',
    book: 'Book{{s}}',
    bookOfSticker: 'Book of stickers{{s}}',
    bookshelf: 'Bookshelf{{s}}',
    bottleOfJuice: 'Bottle of juice{{s}}',
    buttons: 'button{{s}}',
    checkersBoard: 'checkers board{{s}}',
    chocolates: 'chocolate{{s}}',
    colouringBook: 'Colouring book{{s}}',
    cookie: 'cookie{{s}}',
    counters: 'counter{{s}}',
    crayons: 'crayon{{s}}',
    dice: '{{dice|dice}}',
    dinosaur: 'dinosaur{{s}}',
    door: 'Door{{s}}',
    elephant: 'elephant{{s}}',
    flowers: 'flower{{s}}',
    fish_bowl: 'fish bowl{{s}}',
    football: 'football{{s}}',
    game: 'game',
    giraffe: 'giraffe{{s}}',
    glueStick: 'Glue stick{{s}}',
    guitar: 'Guitar{{s}}',
    headphones: 'headphones',
    juice_bottle: 'juice bottle{{s}}',
    kettle: 'kettle{{s}}',
    lampPost: 'Lamp post{{s}}',
    magazine: 'magazine{{s}}',
    mug: 'mug{{s}}',
    orange: 'orange{{s}}',
    pairOfSocks: 'pair{{s}} of socks',
    paperclip: 'Paperclip{{s}}',
    pencils: 'pencil{{s}}',
    pens: 'pen{{s}}',
    pennies: '{{penny | pennies}}',
    pieceOfRibbon: 'Piece of ribbon{{s}}',
    plane: 'plane{{s}}',
    pound_coin: 'pound coin{{s}}',
    ribbons: 'ribbon{{s}}',
    rod: 'Rod{{s}}',
    rubbers: 'rubbers{{s}}',
    rug: 'Rug{{s}}',
    rugbyBall: 'rugby ball{{s}}',
    sharpener: 'Sharpener{{s}}',
    shopping_cart: 'shopping cart{{s}}',
    smallToy: 'Small toy{{s}}',
    straws: 'straw{{s}}',
    suitcase: 'suitcase{{s}}',
    sweets: 'sweet{{s}}',
    swing: 'swing{{s}}',
    table: 'Table{{s}}',
    teddy: 'teddy{{s}}',
    Teddy: 'Teddy{{s}}',
    toyCar: 'Toy car{{s}}',
    toyElephant: 'Toy Elephant{{s}}',
    toyGiraffe: 'Toy Giraffe{{s}}',
    toyTrain: 'Toy train{{s}}',
    tractor: 'Tractor{{s}}',
    trains: 'train{{s}}',
    tree: 'Tree{{s}}',
    yoyo: 'yo-yo{{s}}'
  },

  schoolSubjects: {
    Art: 'art',
    English: 'english',
    Maths: 'maths',
    Music: 'music',
    PE: 'PE',
    Phonics: 'phonics',
    Science: 'science',
    Spanish: 'spanish'
  },

  colors: {
    Black: 'Black',
    black: 'black',
    Blue: 'Blue',
    blue: 'blue',
    Green: 'Green',
    green: 'green',
    Grey: 'Grey',
    grey: 'grey',
    Orange: 'Orange',
    orange: 'orange',
    Pink: 'Pink',
    pink: 'pink',
    Purple: 'Purple',
    purple: 'purple',
    Red: 'Red',
    red: 'red',
    Silver: 'Silver',
    silver: 'silver',
    White: 'White',
    white: 'white',
    Yellow: 'Yellow',
    yellow: 'yellow'
  },

  measurements: {
    Length: 'Length',
    Width: 'Width',
    Height: 'Height'
  },

  stations: {
    Alnmouth: 'Alnmouth',
    Bristol: 'Bristol',
    Cardiff: 'Cardiff',
    Darlington: 'Darlington',
    Doncaster: 'Doncaster',
    Durham: 'Durham',
    Edinburgh: 'Edinburgh',
    Grantham: 'Grantham',
    Leeds: 'Leeds',
    London: 'London',
    Manchester: 'Manchester',
    Newcastle: 'Newcastle',
    Newport: 'Newport',
    Nuneaton: 'Nuneaton',
    Reading: 'Reading',
    Stockport: 'Stockport',
    'Stoke-on-Trent': 'Stoke-on-Trent',
    Stevenage: 'Stevenage',
    Swindon: 'Swindon',
    Wakefield: 'Wakefield',
    York: 'York'
  },

  streetNames: {
    'Abbey Road': 'Abbey Road',
    'Baker Street': 'Baker Street',
    'Brick Lane': 'Brick Lane',
    'Bond Street': 'Bond Street',
    'Forest Drive': 'Forest Drive',
    'Green Park Road': 'Green Park Road',
    'King Street': 'King Street',
    'Oxford Street': 'Oxford Street',
    'Penny Bridge': 'Penny Bridge',
    'Summerville Street': 'Summerville Street'
  },

  transport: {
    bus: 'Bus',
    car: 'Car',
    cycle: 'Cycle',
    walk: 'Walk',
    walking: 'Walking',
    busN: 'Bus {0:number|numberFormat}'
  },

  tvShows: {
    CatchIt: 'Catch it!',
    Buzz: 'Buzz',
    Starbite: 'Starbite',
    Zoos: 'Zoos',
    CodeBlue: 'Code blue'
  },

  pdfFooter: {
    contentCreatedBy:
      'White Rose Education grant {0} a licence to use this content only, subject to our standard terms and conditions.'
  },

  // Convenience, e.g. `translate.year(yearKey)` instead of `translate.years[yearKey]()`
  // These are used in cases where we pass around the key to this translation table, rather than the final translated
  // string.
  year: '{0:YearKey|Year}',
  term: '{0:TermKey|Term}',
  termWithWordTerm: '{0:TermKey|Term} term',
  block: '{0:BlockKey|Block}',
  smallStep: '{0:SmallStepKey|SmallStep}',
  field: '{0:FieldKey|Field}',
  description: '{0:DescriptionKey|Description}',
  keyword: '{0:KeywordKey|Keyword}'
};

export default en;
