import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { SvgName } from '../../../../assets/svg';
import { isInRange } from '../../../../utils/matchers';
import { Point2d } from '../../../../utils/vectors';
import { GridWithArrowsBetweenChildren } from '../../../../components/question/representations/GridWithArrowsBetweenChildren';
import Text from '../../../../components/typography/Text';
import { countRange, range } from '../../../../utils/collections';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';

////
// Questions
////

const getDirections = (spaces: number[]): ('left' | 'right')[] => {
  // if only one turn
  if (spaces.length === 2) {
    if (spaces[1] > 0) {
      return ['right'];
    } else {
      return ['left'];
    }
  } else if (spaces.length === 3) {
    if (spaces[1] > 0 && spaces[2] > 0) return ['right', 'left'];
    else if (spaces[1] > 0 && spaces[2] < 0) return ['right', 'right'];
    else if (spaces[1] < 0 && spaces[2] > 0) return ['left', 'right'];
    // if (spaces[1] < 0 && spaces[2] < 0)
    else return ['left', 'left'];
  } else throw new Error('Unhandled case');
};

const Question1 = newQuestionContent({
  uid: 'blG',
  description: 'blG',
  keywords: ['Forwards', 'Left', 'Right'],
  schema: z.object({
    startSpaceX: z.number().int().min(0).max(3),
    startSpaceY: z.number().int().min(0).max(2),
    spaces: z
      .number()
      .int()
      .min(-3)
      .max(3)
      .refine(x => x !== 0)
      .array(),
    image: z.enum(['Bee', 'Ant'])
  }),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);
    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 2);

    const numberOfForwards = randomIntegerInclusive(2, 3);

    let currentPoint = new Point2d(startSpaceX, startSpaceY);
    const spaces = countRange(numberOfForwards).map(val => {
      const isY = val % 2 === 0;
      const startSpace = isY ? currentPoint.y : currentPoint.x;
      const min = val === 0 ? 1 : 1 - trackSize;
      const space = randomIntegerInclusive(min, trackSize - 1, {
        constraint: cell => isInRange(0, trackSize - 1)(startSpace + cell) && cell !== 0
      });
      currentPoint = currentPoint.add(new Point2d(isY ? 0 : space, isY ? space : 0));
      return space;
    });

    return { image, startSpaceX, startSpaceY, spaces };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spaces },
      translate
    } = props;
    const svgName: SvgName = (() => {
      switch (image) {
        case 'Ant':
          return 'Ant';
        case 'Bee':
          return 'Bee_from_above';
      }
    })();

    const points = [new Point2d(startSpaceX, startSpaceY)];

    spaces.forEach((val, i) => {
      const isY = i % 2 === 0;
      const prev = points[i];
      points.push(prev.add(new Point2d(isY ? 0 : val, isY ? val : 0)));
    });

    const directions = getDirections(spaces);

    const sentences: string[] = [];
    spaces.forEach((space, id) => {
      sentences.push(translate.ks1AnswerSentences.goForwardsAnsSpaces(space));
      if (id !== spaces.length - 1) {
        directions[id] === 'left'
          ? sentences.push(translate.ks1AnswerSentences.turnLeft())
          : sentences.push(translate.ks1AnswerSentences.turnRight());
      }
    });

    return (
      <QF36ContentAndSentencesDrag
        title={translate.ks1Instructions.dragTheCardsToDescribeTheMovement()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToDescribeTheMovement()}
        questionHeight={1100}
        mainPanelStyle={{ flexDirection: 'row' }}
        pdfLayout="itemsAboveContent"
        Content={({ dimens }) => (
          <GridWithArrowsBetweenChildren
            dimens={dimens}
            xMax={4}
            yMax={4}
            points={points}
            svgNames={[svgName]}
            imageStyle={{ transform: `rotate(${image === 'Ant' ? -90 : 0}deg)` }}
            arrowYOffset={0.42}
            squareGrid
          />
        )}
        items={range(1, 4)}
        moveOrCopy="copy"
        actionPanelVariant="end"
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentences={sentences}
        testCorrect={countRange(sentences.length).map(val =>
          val % 2 === 0 ? [Math.abs(spaces[val / 2])] : []
        )}
      />
    );
  },
  questionHeight: 1100
});

const Question2 = newQuestionContent({
  uid: 'blH',
  description: 'blH',
  keywords: ['Forwards', 'Left', 'Right'],
  schema: z.object({
    startSpaceX: z.number().int().min(0).max(3),
    startSpaceY: z.number().int().min(0).max(2),
    spaces: z
      .number()
      .int()
      .min(-3)
      .max(3)
      .refine(x => x !== 0)
      .array(),
    image: z.enum(['Bee', 'Ant'])
  }),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);
    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 2);

    const numberOfForwards = randomIntegerInclusive(2, 3);

    let currentPoint = new Point2d(startSpaceX, startSpaceY);
    const spaces = countRange(numberOfForwards).map(val => {
      const isY = val % 2 === 0;
      const startSpace = isY ? currentPoint.y : currentPoint.x;
      const min = val === 0 ? 1 : 1 - trackSize;
      const space = randomIntegerInclusive(min, trackSize - 1, {
        constraint: cell => isInRange(0, trackSize - 1)(startSpace + cell) && cell !== 0
      });
      currentPoint = currentPoint.add(new Point2d(isY ? 0 : space, isY ? space : 0));
      return space;
    });

    return { image, startSpaceX, startSpaceY, spaces };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spaces },
      translate,
      displayMode
    } = props;
    const svgName: SvgName = (() => {
      switch (image) {
        case 'Ant':
          return 'Ant';
        case 'Bee':
          return 'Bee_from_above';
      }
    })();

    const points = [new Point2d(startSpaceX, startSpaceY)];

    spaces.forEach((val, i) => {
      const isY = i % 2 === 0;
      const prev = points[i];
      points.push(prev.add(new Point2d(isY ? 0 : val, isY ? val : 0)));
    });

    const sentences: string[] = [];
    spaces.forEach((space, id) => {
      sentences.push(translate.ks1AnswerSentences.goForwardsXSpaces(Math.abs(space)));
      if (id !== spaces.length - 1) {
        sentences.push(translate.ks1AnswerSentences.turnAns());
      }
    });

    const answers = getDirections(spaces);

    const testCorrect: ('left' | 'right')[][] = countRange(sentences.length).map(_ => []);
    answers.forEach((val, i) => testCorrect[i * 2 + 1].push(val));

    return (
      <QF36ContentAndSentencesDrag
        title={translate.ks1Instructions.dragTheCardsToDescribeTheMovement()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToDescribeTheMovement()}
        questionHeight={1100}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <GridWithArrowsBetweenChildren
            dimens={dimens}
            xMax={4}
            yMax={4}
            points={points}
            svgNames={[svgName]}
            imageStyle={{ transform: `rotate(${image === 'Ant' ? -90 : 0}deg)` }}
            arrowYOffset={0.42}
            squareGrid
          />
        )}
        items={(['left', 'right'] as const).map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsAboveContent"
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentences={sentences}
        testCorrect={testCorrect}
      />
    );
  },
  questionHeight: 1100
});

const Question3 = newQuestionContent({
  uid: 'blI',
  description: 'blI',
  keywords: ['Forwards', 'Left', 'Right'],
  schema: z.object({
    startSpaceX: z.number().int().min(0).max(3),
    startSpaceY: z.number().int().min(0).max(2),
    spaces: z
      .number()
      .int()
      .min(-3)
      .max(3)
      .refine(x => x !== 0)
      .array(),
    image: z.enum(['Bee', 'Ant']),
    answerIndexes: z.number().int().min(0).max(4).array(),
    options: z.number().int().min(1).max(4).array().length(2)
  }),
  simpleGenerator: () => {
    const image = getRandomFromArray(['Bee', 'Ant'] as const);
    const trackSize = 4;
    const startSpaceX = randomIntegerInclusive(0, trackSize - 1);
    const startSpaceY = randomIntegerInclusive(0, trackSize - 2);

    const numberOfForwards = randomIntegerInclusive(2, 3);

    let currentPoint = new Point2d(startSpaceX, startSpaceY);
    const spaces = countRange(numberOfForwards).map(val => {
      const isY = val % 2 === 0;
      const startSpace = isY ? currentPoint.y : currentPoint.x;
      const min = val === 0 ? 1 : 1 - trackSize;
      const space = randomIntegerInclusive(min, trackSize - 1, {
        constraint: cell => isInRange(0, trackSize - 1)(startSpace + cell) && cell !== 0
      });
      currentPoint = currentPoint.add(new Point2d(isY ? 0 : space, isY ? space : 0));
      return space;
    });

    const numberOfAnswers = randomIntegerInclusive(2, 3);
    const [turns, forwards] = numberOfForwards === 2 ? [1, 2] : shuffle([1, numberOfAnswers - 1]);

    const forwardsIndexes = randomUniqueIntegersInclusive(0, numberOfForwards - 1, forwards);
    const turnsIndexes = randomUniqueIntegersInclusive(0, numberOfForwards - 2, turns);

    const answerIndexes = [
      ...forwardsIndexes.map(val => val * 2),
      ...turnsIndexes.map(val => val * 2 + 1, turns)
    ];

    const options = spaces.filter((_, i) => forwardsIndexes.includes(i)).map(val => Math.abs(val));
    const uniqueOptions = [...new Set([...options])];
    if (uniqueOptions.length === 1) {
      const num =
        uniqueOptions[0] === 1
          ? 2
          : uniqueOptions[0] === 4
          ? 3
          : uniqueOptions[0] + (getRandomBoolean() ? 1 : -1);
      uniqueOptions.push(num);
    }

    return {
      image,
      startSpaceX,
      startSpaceY,
      spaces,
      answerIndexes,
      options: shuffle(uniqueOptions)
    };
  },
  Component: props => {
    const {
      question: { image, startSpaceX, startSpaceY, spaces, answerIndexes, options },
      translate,
      displayMode
    } = props;
    const svgName: SvgName = (() => {
      switch (image) {
        case 'Ant':
          return 'Ant';
        case 'Bee':
          return 'Bee_from_above';
      }
    })();

    const directions = getDirections(spaces);
    const points = [new Point2d(startSpaceX, startSpaceY)];

    spaces.forEach((val, i) => {
      const isY = i % 2 === 0;
      const prev = points[i];
      points.push(prev.add(new Point2d(isY ? 0 : val, isY ? val : 0)));
    });

    const sentences = countRange(spaces.length * 2 - 1).map(id => {
      if (id % 2 !== 0) {
        return answerIndexes.includes(id)
          ? translate.ks1AnswerSentences.turnAns()
          : directions[id - 1] === 'left'
          ? translate.ks1AnswerSentences.turnLeft()
          : translate.ks1AnswerSentences.turnRight();
      } else {
        const space = Math.abs(spaces[id / 2]);
        return answerIndexes.includes(id)
          ? translate.ks1AnswerSentences.goForwardsAnsSpaces(space)
          : translate.ks1AnswerSentences.goForwardsXSpaces(space);
      }
    });

    const testCorrect: ('left' | 'right' | number)[][] = countRange(sentences.length).map(_ => []);
    directions.forEach((val, i) => {
      if (answerIndexes.includes(i * 2 + 1)) {
        testCorrect[i * 2 + 1].push(val);
      }
    });
    spaces.forEach((val, i) => {
      if (answerIndexes.includes(i * 2)) {
        testCorrect[i * 2].push(Math.abs(val));
      }
    });

    return (
      <QF36ContentAndSentencesDrag
        title={translate.ks1Instructions.dragTheCardsToDescribeTheMovement()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToDescribeTheMovement()}
        questionHeight={1100}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <GridWithArrowsBetweenChildren
            dimens={dimens}
            xMax={4}
            yMax={4}
            points={points}
            svgNames={[svgName]}
            imageStyle={{ transform: `rotate(${image === 'Ant' ? -90 : 0}deg)` }}
            arrowYOffset={0.42}
            squareGrid
          />
        )}
        items={[...options, ...(['left', 'right'] as const)].map(val => ({
          value: val,
          component: (
            <Text variant="WRN700" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {typeof val === 'number'
                ? val.toLocaleString()
                : translate.ks1MiscStrings.directions[val]()}
            </Text>
          )
        }))}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsAboveContent"
        sentencesStyle={{ alignItems: 'flex-start' }}
        sentences={sentences}
        testCorrect={testCorrect}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DescribeMovementAndTurns',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
