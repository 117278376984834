import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange
} from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import { binOpEquationToSentenceString } from '../../../../utils/fourOperations';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { ADD } from '../../../../constants';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';

////
// Questions
////

const Question1 = newQuestionContent({
  questionHeight: 1200,
  uid: 'baZ',
  description: 'baZ',
  keywords: ['Part-whole model', 'Number sentence', 'Add'],
  schema: z
    .object({
      partA: z.number().int().min(1).max(6),
      partB: z.number().int().min(1).max(6),
      flipped: z.boolean(),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft']),
      color: z.enum(['red', 'yellow', 'blue', 'green'])
    })
    .refine(({ partA, partB }) => isInRange(2, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(1, 6);
    const partB = randomIntegerInclusive(1, 6, {
      constraint: x => isInRange(2, 10)(partA + x)
    });

    const flipped = getRandomFromArrayWithWeights([false, true] as const, [3, 1]);

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );
    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    return { partA, partB, flipped, variation, color };
  },
  Component: props => {
    const {
      question: { partA, partB, flipped, variation, color },
      translate
    } = props;
    const total = partA + partB;

    const sentence = flipped
      ? `${total.toLocaleString()} = <ans/> ${ADD} <ans/>`
      : `<ans/> ${ADD} <ans/> = ${total.toLocaleString()}`;

    return (
      <QF1ContentAndSentence
        questionHeight={1200}
        title={translate.ks1Instructions.whatAdditionIsShown()}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            variation={variation}
            partition={[partA, partB]}
            representation="counters"
            color={color}
            dimens={{
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9,
              height: dimens.height
            }}
          />
        )}
        inputMaxCharacters={2}
        sentence={sentence}
        pdfDirection="column"
        testCorrect={userAnswer =>
          arraysHaveSameContentsUnordered(userAnswer, [partA.toString(), partB.toString()])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [partA.toString(), partB.toString()],
          answerText: translate.markScheme.xAndYCanBeInEitherOrder(partA, partB)
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'ba0',
  description: 'ba0',
  keywords: ['Number sentence', 'Counters', 'Add'],
  schema: z
    .object({
      partA: z.number().int().min(0).max(9),
      partB: z.number().int().min(0).max(9),
      colors: z.array(z.enum(['red', 'green', 'blue', 'yellow'])).length(2),
      flipped: z.boolean(),
      switchNumberOrder: z.boolean()
    })
    .refine(({ partA, partB }) => isInRange(2, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(0, 9);
    const partB = randomIntegerInclusive(0, 9, {
      constraint: x => isInRange(2, 10)(partA + x)
    });

    const colorOptions = ['red', 'green', 'blue', 'yellow'] as const;
    const colors = getRandomSubArrayFromArray(colorOptions, 2);

    const flipped = getRandomFromArrayWithWeights([false, true], [3, 1]);
    const switchNumberOrder = getRandomBoolean();
    return { partA, partB, colors, flipped, switchNumberOrder };
  },
  Component: props => {
    const {
      question: { partA, partB, colors, flipped, switchNumberOrder },
      translate
    } = props;
    const total = partA + partB;

    const partASvg = `Circles/circle_${colors[0]}` as const;
    const partBSvg = `Circles/circle_${colors[1]}` as const;

    const groupedArray = ({ width, height }: { width: number; height: number }) => [
      ...countRange(partA).map((_, i) => (
        <AssetSvg key={`${partASvg}_${i}`} name={partASvg} height={height} width={width * 0.09} />
      )),
      ...countRange(partB).map((_, i) => (
        <AssetSvg key={`${partBSvg}_${i}`} name={partBSvg} height={height} width={width * 0.09} />
      ))
    ];

    const [left, right] = switchNumberOrder ? [partB, partA] : [partA, partB];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => {
          return (
            <View
              style={{
                ...dimens,
                columnGap: 8,
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {groupedArray(dimens)}
            </View>
          );
        }}
        sentence={binOpEquationToSentenceString({
          left,
          right,
          result: total,
          sign: 'add',
          answer: 'result',
          flipped
        })}
        testCorrect={[total.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'ba1',
  description: 'ba1',
  keywords: ['Add', 'Number sentence'],
  schema: z.object({
    total: z.number().int().min(3).max(10),
    flipped: z.boolean(),
    options: z.array(z.number().int().min(0).max(10)).length(6)
  }),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(3, 10);

    const [option1a, option2a] = randomUniqueIntegersInclusive(0, Math.floor(total / 2), 2);
    const correctOptions = [option1a, option2a, total - option1a, total - option2a];

    const randomOptions = countRange(2).map(_ =>
      randomIntegerInclusive(0, 10, {
        constraint: x => arrayHasNoDuplicates([x, ...new Set(correctOptions)])
      })
    );

    const options = shuffle([...correctOptions, ...randomOptions]);

    const flipped = getRandomFromArrayWithWeights([false, true], [3, 1]);

    return { total, flipped, options };
  },
  Component: props => {
    const {
      question: { total, flipped, options },
      translate
    } = props;

    const sentence = flipped
      ? `${total.toLocaleString()} = <ans/> ${ADD} <ans/>`
      : `<ans/> ${ADD} <ans/> = ${total.toLocaleString()}`;

    const items = options.map(num => {
      return { value: num, component: num.toLocaleString() };
    });

    const exampleAns1 = options.find((x, i) => options.slice(i + 1).includes(total - x))!;

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTwoCardsToMakeAdditionCorrect()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToMakeTheAdditionCorrect()}
        items={items}
        sentence={sentence}
        testCorrect={([ans1, ans2]) =>
          ans1 !== undefined && ans2 !== undefined && ans1 + ans2 === total
        }
        moveOrCopy="move"
        customMarkSchemeAnswer={{
          answersToDisplay: [[exampleAns1], [total - exampleAns1]],
          answerText: translate.markScheme.acceptAnyCombinationThatAddUpToX(total.toLocaleString())
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'WriteNumberSentences',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
