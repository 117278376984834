import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { get3DShapeFullColorsSVGPath } from '../../../../utils/threeDShapes';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import deepEqual from 'react-fast-compare';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbL',
  description: 'bbL',
  keywords: ['3-D shape'],
  schema: z
    .object({
      shapeA: z.enum(['Cylinder', 'Cube', 'Cone']),
      shapeB: z.enum(['Cuboid', 'Cylinder', 'Sphere', 'Triangle_pyramid', 'Square_pyramid']),
      oddOneOut: z.enum(['A', 'B']),
      color: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'])
    })
    .refine(val => val.shapeA !== val.shapeB, 'shapeA and shapeB cannot be the same.'),
  simpleGenerator: () => {
    const [shapeA, shapeB] = getRandomFromArray([
      ['Cylinder', 'Cuboid'],
      ['Cube', 'Cuboid'],
      ['Cube', 'Cylinder'],
      ['Cylinder', 'Sphere'],
      ['Cone', 'Triangle_pyramid'],
      ['Cone', 'Square_pyramid']
    ] as const);

    const oddOneOut = getRandomFromArray(['A', 'B'] as const);

    const color = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'pink',
      'purple',
      'red',
      'yellow'
    ] as const);

    return { shapeA, shapeB, oddOneOut, color };
  },
  Component: ({ question, translate }) => {
    const { shapeA, shapeB, oddOneOut, color } = question;

    const shapeASvg = get3DShapeFullColorsSVGPath(color, shapeA);

    const shapeBSvg = get3DShapeFullColorsSVGPath(color, shapeB);

    const items = shuffle(
      [
        {
          value: 1,
          svgName: oddOneOut === 'A' ? shapeBSvg : shapeASvg
        },
        {
          value: 2,
          svgName: oddOneOut === 'A' ? shapeASvg : shapeBSvg
        },
        {
          value: 3,
          svgName: oddOneOut === 'A' ? shapeASvg : shapeBSvg
        },
        {
          value: 4,
          svgName: oddOneOut === 'A' ? shapeASvg : shapeBSvg
        }
      ],
      {
        random: seededRandom(question)
      }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapeThatIsTheOddOneOut()}
        pdfTitle={translate.ks1PDFInstructions.circleTheOddOneOut()}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(({ value, svgName }) => ({
            component: (
              <AssetSvg
                name={svgName}
                width={svgName.includes('Cuboid') ? dimens.height * 0.7 : dimens.width * 0.8}
                height={svgName.includes('Cuboid') ? dimens.width * 0.8 : dimens.height * 0.8}
                style={svgName.includes('Cuboid') && { transform: 'rotate(270deg) scaleX(1.2)' }}
              />
            ),
            value
          }))
        }
        testCorrect={[1]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const q2Shapes = ['Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid', 'Triangle_pyramid'] as const;
const q2Colors = ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;

export type q2Shapes = (typeof q2Shapes)[number];

const Question2 = newQuestionContent({
  uid: 'bbM',
  description: 'bbM',
  keywords: ['Sort', '3-D shapes'],
  schema: z.object({
    /* List of objects to show on the right. This must consist of 2 distinct shapes and 2 distinct colors. */
    items: z
      .array(
        z.object({
          shape: z.enum(q2Shapes),
          color: z.enum(q2Colors),
          scaleX: z.number().min(0.5).max(2).optional(),
          scaleY: z.number().min(0.5).max(2).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .length(9)
      .refine(
        items =>
          arrayHasNoDuplicates(
            items.filter(val => val.shape !== 'Sphere'),
            deepEqual
          ),
        'items must not have duplicates other than spheres'
      )
      .refine(
        items => new Set<string>(items.map(item => item.shape)).size === 2,
        'items must consist of exactly two shapes'
      )
      .refine(
        items => new Set<string>(items.map(item => item.color)).size === 2,
        'items must consist of exactly two colors'
      )
  }),
  simpleGenerator: () => {
    const [shapeA, shapeB] = getRandomSubArrayFromArray(q2Shapes, 2);

    const colorA = getRandomFromArray(q2Colors);

    // Normally we could just use getRandomSubArrayFromArray to assign two colors to colorA and colorB,
    // but colors need to be distinct in this Q,so we can't just pick any two colors at all as some are too similar,
    // e.g. red and pink, orange and yellow. The following cases should prevent any similar colours being selected:
    const colorBChoices = (() => {
      switch (colorA) {
        case 'blue':
          return ['green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'green':
          return ['blue', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'orange':
          return ['blue', 'green', 'pink', 'purple'] as const;
        case 'pink':
          return ['blue', 'green', 'orange', 'yellow'] as const;
        case 'purple':
          return ['blue', 'green', 'orange', 'red', 'yellow'] as const;
        case 'red':
          return ['blue', 'green', 'purple', 'yellow'] as const;
        case 'yellow':
          return ['blue', 'green', 'pink', 'purple', 'red'] as const;
      }
    })();

    const colorB = getRandomFromArray(colorBChoices);

    const scalingAndRotationChoices = [
      {},
      { scaleX: 0.7 },
      { scaleY: 0.7 },
      { scaleX: 0.7, scaleY: 0.7 },
      { rotation: randomIntegerInclusiveStep(90, 270, 90) }
    ];

    const getTransforms = (shape: q2Shapes, count: number) =>
      getRandomSubArrayFromArray(
        shape === 'Sphere'
          ? [{ scaleX: 0.7, scaleY: 0.7 }, {}, {}, { scaleX: 0.7, scaleY: 0.7 }]
          : scalingAndRotationChoices,
        count
      );

    const shapeAColorAChoices = getTransforms(shapeA, 3);
    const shapeAColorBChoices = getTransforms(shapeA, 2);
    const shapeBColorAChoices = getTransforms(shapeB, 2);
    const shapeBColorBChoices = getTransforms(shapeB, 2);

    const items = shuffle([
      ...shapeAColorAChoices.map(choice => ({ ...choice, shape: shapeA, color: colorA })),
      ...shapeAColorBChoices.map(choice => ({ ...choice, shape: shapeA, color: colorB })),
      ...shapeBColorAChoices.map(choice => ({ ...choice, shape: shapeB, color: colorA })),
      ...shapeBColorBChoices.map(choice => ({ ...choice, shape: shapeB, color: colorB }))
    ]);

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    // The schema's refine checks that these sets are of size 2
    const [shapeA, shapeB] = [...new Set(items.map(item => item.shape))];
    const [colorA, colorB] = [...new Set(items.map(item => item.color))];

    const correctAnswer = (userAnswer: (typeof items)[number][][]) => {
      return (
        // Sort by shapes:
        (userAnswer[0].every(item => item.shape === shapeA) &&
          userAnswer[1].every(item => item.shape === shapeB)) ||
        (userAnswer[0].every(item => item.shape === shapeB) &&
          userAnswer[1].every(item => item.shape === shapeA)) ||
        // Sort by colors:
        (userAnswer[0].every(item => item.color === colorA) &&
          userAnswer[1].every(item => item.color === colorB)) ||
        (userAnswer[0].every(item => item.color === colorB) &&
          userAnswer[1].every(item => item.color === colorA))
      );
    };

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToSortTheShapes()}
        zoneNames={[translate.tableHeaders.groupNum(1), translate.tableHeaders.groupNum(2)]}
        testCorrect={userAnswer => correctAnswer(userAnswer)}
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView
                key={index}
                style={
                  shape.scaleX || shape.scaleY || shape.rotation
                    ? {
                        transform: [
                          ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                          ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                          ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                        ]
                      }
                    : undefined
                }
              >
                {dimens => (
                  <AssetSvg
                    name={get3DShapeFullColorsSVGPath(shape.color, shape.shape)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            items.filter(shape => shape.shape === shapeA),
            items.filter(shape => shape.shape === shapeB)
          ],
          answerText: translate.markScheme.shapesCanBeSortedByEitherShapeOrColour()
        }}
      />
    );
  },
  questionHeight: 1000
});

const q3Shapes = [
  'Cone',
  'Cube',
  'Cuboid',
  'Cylinder',
  'Sphere',
  'Square_pyramid',
  'Triangle_pyramid'
] as const;
const q3ShapesThatRoll: (typeof q3Shapes)[number][] = ['Cone', 'Cylinder', 'Sphere'];
const q3Colors = ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const;

const Question3 = newQuestionContent({
  uid: 'bbN',
  description: 'bbN',
  keywords: ['3-D shape', 'Roll'],
  schema: z.object({
    items: z
      .array(
        z.object({
          shape: z.enum(q3Shapes),
          color: z.enum(q3Colors)
        })
      )
      .length(4)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must have no duplicates')
      .refine(
        items => items.filter(item => q3ShapesThatRoll.includes(item.shape)).length >= 2,
        'must be at least two correct answers'
      )
  }),
  simpleGenerator: () => {
    // We can have duplicate shapes, but it is probably best that we make the two definitely-correct answers be different:
    const [shapeA, shapeB] = getRandomSubArrayFromArray(q3ShapesThatRoll, 2);

    const shapeC = getRandomFromArray(q3Shapes);

    const shapeDChoices =
      arrayHasNoDuplicates([shapeA, shapeC]) || arrayHasNoDuplicates([shapeB, shapeC])
        ? // In this case, we cannot ever produce three of the same shape, so all shapes are valid:
          q3Shapes
        : // In this case, shapeC is already a duplicate of shapeA or shapeB, so shapeD cannot also be this same shape.
          q3Shapes.filter(shape => shape !== shapeC);

    const shapeD = getRandomFromArray(shapeDChoices)!;

    const [colorA, colorB, colorC, colorD] = getRandomSubArrayFromArray(q3Colors, 4);

    const items = shuffle([
      { shape: shapeA, color: colorA },
      { shape: shapeB, color: colorB },
      { shape: shapeC, color: colorC },
      { shape: shapeD, color: colorD }
    ]);

    return { items };
  },
  Component: ({ question: { items }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapesThatRoll()}
        pdfTitle={translate.ks1PDFInstructions.circleTheShapesThatRoll()}
        numItems={4}
        renderItems={({ dimens }) =>
          items.map(item => ({
            component: (
              <AssetSvg
                name={get3DShapeFullColorsSVGPath(item.color, item.shape)}
                width={dimens.width * 0.8}
                height={dimens.height * 0.8}
              />
            ),
            value: item
          }))
        }
        testCorrect={items.filter(item => q3ShapesThatRoll.includes(item.shape))}
        multiSelect
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const SmallStep = newSmallStepContent({
  smallStep: 'Sort3DShapes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
