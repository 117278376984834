import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import {
  createRectangleFromSquares,
  equalQuarterShadedShapes,
  equalQuarterShadedShapesSvgNameSchema,
  getRandomEqualQuarterShadedShapesSvgName,
  getRandomUnequalQuarterShadedShapesSvgName,
  trueCount,
  unequalQuarterShadedShapes,
  unequalQuarterShadedShapesSvgNameSchema
} from '../../../../utils/shapes';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg } from '../../../../assets/svg';
import { numberEnum } from '../../../../utils/zod';
import getShadedCounterArrangements from '../../../../components/question/representations/CounterBoxArrangement/shadedCounterArrangements';
import {
  ShadedCounterArrangements,
  ShadedCounterBoxArrangement
} from '../../../../components/question/representations/CounterBoxArrangement/ShadedCounterBoxArrangement';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bkk',
  description: 'bkk',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z.object({
    options: z
      .array(
        z.object({
          shape: z.union([
            equalQuarterShadedShapesSvgNameSchema,
            unequalQuarterShadedShapesSvgNameSchema
          ]),
          isEqual: z.boolean()
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2, 3]);

    const correctShapeSvgs = getRandomSubArrayFromArray(
      equalQuarterShadedShapes,
      numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomEqualQuarterShadedShapesSvgName(shape),
        isEqual: true
      };
    });

    const incorrectShapesSvgs = getRandomSubArrayFromArray(
      unequalQuarterShadedShapes,
      4 - numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomUnequalQuarterShadedShapesSvgName(shape),
        isEqual: false
      };
    });

    const options = shuffle([...correctShapeSvgs, ...incorrectShapesSvgs]);

    return { options };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    const correctAnswers = options.filter(it => it.isEqual).map(({ shape }) => shape);
    const numberOfCorrectAnswers = correctAnswers.length;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheDiagramsThatShowAQuarter(numberOfCorrectAnswers)}
        pdfTitle={translate.ks1PDFInstructions.circleTheDiagramsThatShowAQuarter(
          numberOfCorrectAnswers
        )}
        testCorrect={options.flatMap((el, index) => (el.isEqual ? [index] : []))}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map((option, index) => ({
            value: index,
            component: <AssetSvg height={dimens.height * 0.7} name={option.shape} />
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bkl',
  description: 'bkl',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  questionHeight: 1000,
  schema: z.object({
    options: z
      .array(
        z.object({
          arrangement: z.array(z.array(numberEnum([0, 1, 2]))),
          isQuarter: z.boolean()
        })
      )
      .length(4)
      .refine(x => arrayHasNoDuplicates(x, deepEqual), 'all options should be different')
  }),
  simpleGenerator: () => {
    const numOfCorrect = getRandomFromArray([2, 3]);

    const quarterOptions = getRandomSubArrayFromArray(
      [
        [2, 2, 'rows'],
        [1, 4, 'columns'],
        [4, 4, 'rows'],
        [4, 4, 'columns'],
        [4, 4, 'quadrants'],
        [2, 4, 'columns'],
        [2, 4, 'quadrants'],
        [4, 3, 'columns']
      ] as const,
      numOfCorrect
    );
    const correctOptions = quarterOptions.map(([rows, cols, shadedVariation]) => {
      return {
        arrangement: getShadedCounterArrangements({
          rows,
          cols,
          shadedVariation,
          numShaded: (rows * cols) / 4
        }),
        isQuarter: true
      };
    });

    const wrongArrangements: ShadedCounterArrangements[] = getRandomSubArrayFromArray(
      [
        getShadedCounterArrangements({
          rows: 1,
          cols: 5,
          shadedVariation: 'columns',
          numShaded: 1
        }),
        [
          [2, 0, 0, 0],
          [1, 1, 1, 1]
        ],
        [[2], [1, 1, 1, 1]]
      ],
      4 - numOfCorrect
    );

    const wrongOptions = wrongArrangements.map(arrangement => {
      return {
        arrangement,
        isQuarter: false
      };
    });

    return { options: shuffle([...correctOptions, ...wrongOptions]) };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectThePicturesThatHaveQuarterShaded()}
        pdfTitle={translate.ks1PDFInstructions.circleThePicturesThatHaveQuarterShaded()}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={({ dimens }) =>
          options.map(({ arrangement }, index) => ({
            value: index,
            component: (
              <ShadedCounterBoxArrangement dimens={dimens} arrangement={arrangement} scale={4.5} />
            )
          }))
        }
        testCorrect={options.flatMap((el, index) => (el.isQuarter ? [index] : []))}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bkm',
  description: 'bkm',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z
    .object({
      rows: z.number().int().min(1).max(5),
      cols: z.number().int().min(1).max(8)
    })
    .refine(
      ({ cols, rows }) => (rows * cols) % 4 === 0,
      'grid should have a multiple of 4 squares'
    ),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 5);
    const cols = rows === 4 ? randomIntegerInclusive(1, 8) : getRandomFromArray([4, 8] as const);

    return { rows, cols };
  },
  Component: ({ question: { rows, cols }, translate }) => {
    const answer = (rows * cols) / 4;
    const [shadedCols, shadedRows] = rows === 4 ? [cols, rows / 4] : [cols / 4, rows];

    return (
      <QF24CreateShapeFromSquares
        title={translate.ks1Instructions.tapToShadeAQuarterOfTheGrid()}
        pdfTitle={translate.ks1PDFInstructions.shadeAQuarterOfTheGrid()}
        numberOfRows={rows}
        numberOfCols={cols}
        testCorrect={userAnswer => trueCount(userAnswer) === answer}
        customMarkSchemeAnswer={{
          answerToDisplay: createRectangleFromSquares(shadedCols, shadedRows),
          answerText: translate.markScheme.anyXSquaresShadedOnTheGrid(answer)
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAQuarter',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
