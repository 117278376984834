import { throwError } from './flowControl';
import * as math from 'mathjs';

export class Point2d {
  readonly x: number;
  readonly y: number;

  /** Various convenient constructors, using typescript's function signature overload feature. */
  constructor(x: number, y: number);
  constructor(vector: [number, number]);
  constructor(vector: { x: number; y: number });
  constructor(xOrVector: number | [number, number] | { x: number; y: number }, yOptional?: number) {
    let x, y: number;

    if (typeof xOrVector === 'number') {
      x = xOrVector;
      y = yOptional ?? throwError('if the first argument is a number, two arguments are required');
    } else if (Array.isArray(xOrVector)) {
      [x, y] = xOrVector;
    } else {
      x = xOrVector.x;
      y = xOrVector.y;
    }

    this.x = x;
    this.y = y;
  }

  copy({ x = this.x, y = this.y }): Point2d {
    return new Point2d({ x, y });
  }

  toArray(): [number, number] {
    return [this.x, this.y];
  }

  toObject(): { x: number; y: number } {
    return { x: this.x, y: this.y };
  }

  negate(): Point2d {
    return new Point2d(-this.x, -this.y);
  }

  add(...others: Point2d[]): Point2d {
    return new Point2d(
      others.reduce((sum, v) => sum + v.x, this.x),
      others.reduce((sum, v) => sum + v.y, this.y)
    );
  }

  subtract(...others: Point2d[]): Point2d {
    return this.add(...others.map(other => other.negate()));
  }

  multiply(scalar: number): Point2d {
    return new Point2d(this.x * scalar, this.y * scalar);
  }

  rotate(angleRadians: number): Point2d {
    return new Point2d(math.rotate(this.toArray(), angleRadians));
  }

  equals(other: Point2d, relativeTolerance = 10e-6, absoluteTolerance = 10e-6): boolean {
    // Note: we don't just use math-js's equals function for this, because at the version we're using at time of
    // writing you cannot customize the absolute tolerance, and it's far too small. 1 millionth is plenty small enough.
    const numberEquals = (a: number, b: number) =>
      // Both zero
      (Math.abs(a) <= absoluteTolerance && Math.abs(b) <= absoluteTolerance) ||
      // Neither zero - within tolerance of each other
      Math.abs(a - b) <=
        Math.max(relativeTolerance * Math.max(Math.abs(a), Math.abs(b)), absoluteTolerance);

    return numberEquals(this.x, other.x) && numberEquals(this.y, other.y);
  }
}

export const DIRECTIONS = ['up', 'down', 'left', 'right'] as const;
export type Direction = (typeof DIRECTIONS)[number];

/**
 * Get a vector indicating movement in the given direction.
 *  This uses math coordinates not graphics coordinates, so +y points up.
 */
export function directionToVector(direction: Direction) {
  switch (direction) {
    case 'up':
      return new Point2d(0, 1);
    case 'down':
      return new Point2d(0, -1);
    case 'left':
      return new Point2d(-1, 0);
    case 'right':
      return new Point2d(1, 0);
  }
}

export type PointFlexible = Point2d | { x: number; y: number } | [number, number];

/** We have too many ways to convey a 2d point! Convert all of them to one type. */
export function normalizePointFlexible(p: PointFlexible): Point2d {
  return p instanceof Point2d ? p : Array.isArray(p) ? new Point2d(p) : new Point2d(p);
}
