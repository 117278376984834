import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { integerToWord } from '../../../../utils/math';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbX',
  description: 'bbX',
  keywords: ['Count', 'Forwards', 'Backwards'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(16),
    missingIndex: z.number().int().min(0).max(4),
    incorrectAnswers: z.number().int().min(0).max(91).array().length(3)
  }),
  simpleGenerator: () => {
    // Get 4 random numbers
    const startingNumber = randomIntegerInclusive(0, 16);

    const missingIndex = randomIntegerInclusive(0, 4);

    const correctAnswer = startingNumber + missingIndex;

    const incorrectAnswers = [startingNumber + 5];

    if (correctAnswer > 11 && correctAnswer < 20)
      incorrectAnswers.push(parseInt(correctAnswer.toString().split('').reverse().join('')));
    if (correctAnswer >= 10) incorrectAnswers.push(correctAnswer - 10);
    if (startingNumber >= 1) incorrectAnswers.push(startingNumber - 1);

    //filter out duplicates
    const uniqueIncorrectAnswers = [...new Set(incorrectAnswers)];

    while (uniqueIncorrectAnswers.length < 3) {
      uniqueIncorrectAnswers.push(
        randomIntegerInclusive(0, 20, {
          constraint: x =>
            (x < startingNumber || x > startingNumber + 4) &&
            arrayHasNoDuplicates([x, ...uniqueIncorrectAnswers])
        })
      );
    }

    return { startingNumber, missingIndex, incorrectAnswers: uniqueIncorrectAnswers.splice(0, 3) };
  },
  Component: ({
    question: { startingNumber, missingIndex, incorrectAnswers },
    translate,
    question
  }) => {
    const correctOrder = countRange(5, startingNumber);

    const correctAnswer = correctOrder[missingIndex];

    const answers = [correctAnswer, ...incorrectAnswers].map(number => ({
      component: number.toLocaleString(),
      value: number
    }));

    const shuffledItems = shuffle(answers, {
      random: seededRandom(question)
    });

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardToFillInTheMissingNumber()}
        pdfTitle={translate.ks1PDFInstructions.fillInTheMissingNumber()}
        sentence={correctOrder
          .map((number, index) => (index === missingIndex ? '<ans/>' : number.toLocaleString()))
          .join(', ')}
        items={shuffledItems}
        moveOrCopy="move"
        pdfLayout="itemsTop"
        testCorrect={[correctAnswer]}
        questionHeight={900}
      />
    );
  }
});

export const Question2 = newQuestionContent({
  uid: 'bbY',
  description: 'bbY',
  keywords: ['Number track', 'Count', 'Forwards', 'Backwards'],
  schema: z.object({
    numberArray: z.number().int().min(0).max(20).array().length(8),
    missingIndices: z.number().min(0).max(7).array()
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusive(0, 12);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const numberArray = sortNumberArray(
      [
        startingNumber,
        startingNumber + 1,
        startingNumber + 2,
        startingNumber + 3,
        startingNumber + 4,
        startingNumber + 5,
        startingNumber + 6,
        startingNumber + 7
      ],
      ordering
    );

    const missingIndices = rejectionSample(
      () => {
        return sortNumberArray(
          randomUniqueIntegersInclusive(0, 7, randomIntegerInclusive(1, 5)),
          'ascending'
        );
      },
      x => x.some((num, i, arr) => arr[i + 1] - num >= 2)
    );

    return { numberArray, missingIndices };
  },
  Component: ({ question: { numberArray, missingIndices }, translate }) => {
    // Create array to pass to NumberTrack

    const answerArray = missingIndices.map(x => numberArray[x].toString());
    const boxValues = numberArray.map((x, i) =>
      missingIndices.indexOf(i) === -1 ? x.toLocaleString() : '<ans/>'
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.ks1Instructions.completeTheNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={boxValues}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bbZ',
  description: 'bbZ',
  keywords: ['Word', 'Numeral'],
  schema: z.object({
    startingNumber: z.number().int().min(0).max(16),
    wordIndices: z
      .number()
      .int()
      .min(0)
      .max(4)
      .array()
      .refine(x => x.length <= 2)
  }),
  simpleGenerator: () => {
    // Get 4 random numbers
    const startingNumber = randomIntegerInclusive(0, 16);

    const choices = countRange(4, 0);
    const wordIndices = getRandomSubArrayFromArray(choices, randomIntegerInclusive(0, 2));

    return { startingNumber, wordIndices };
  },
  Component: ({ question: { startingNumber, wordIndices }, translate, question }) => {
    const correctOrder = [
      startingNumber,
      startingNumber + 1,
      startingNumber + 2,
      startingNumber + 3,
      startingNumber + 4
    ];

    const items = correctOrder.slice(1).map((number, index) => ({
      component: wordIndices.includes(index + 1) ? integerToWord(number) : number.toLocaleString(), // we increase index by 1 here because we removed the first number from the items
      value: number
    }));

    const shuffledItems = shuffle(items, { random: seededRandom(question) });

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToPutThemInOrder()}
        pdfTitle={translate.ks1PDFInstructions.writeNumbersInOrder()}
        sentence={`${
          wordIndices.includes(0) ? integerToWord(startingNumber) : startingNumber.toLocaleString()
        } , <ans/> , <ans/> , <ans/> , <ans/>`}
        items={shuffledItems}
        itemVariant="shortRectangle"
        pdfItemVariant="shortRectangle"
        moveOrCopy="move"
        pdfLayout="itemsTop"
        testCorrect={items.map(value => value.value)}
        questionHeight={900}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountWithin20',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
