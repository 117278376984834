import React from 'react';
import { useMemo, useContext } from 'react';
import { StyleSheet, View } from 'react-native';
import { Dimens, ScaleFactorContext, containAspectRatio } from 'common/src/theme/scaling';
import { AssetSvg, getSvgInfo, SvgName } from '../../../assets/svg';
import { colors } from '../../../theme/colors';
import { Line, Rect, Svg } from 'react-native-svg';
import { range } from '../../../utils/collections';
import { useI18nContext } from '../../../i18n/i18n-react';
import { DisplayMode } from '../../../contexts/displayMode';
import { Gesture, GestureDetector } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedProps,
  useAnimatedStyle,
  useSharedValue
} from 'react-native-reanimated';
import { withStateHOC } from 'common/src/stateTree';
import { SetState } from '../../../utils/react';
import { decimalToFraction, formatFractionToMarkup } from '../../../utils/fractions';
import TextStructure from '../../molecules/TextStructure';

const VERTICAL_STROKE_WIDTH = 3;
const MAJOR_TICK_STROKE = 2;

const MINOR_TICK_STROKE = 1;

export type ContainerType = 'jug' | 'glass' | 'labelledGlass' | 'bottle' | 'barrel' | 'bucket';

type JugWithScaleProps = {
  /**
   * Actual jug height.
   */
  jugHeight: number;
  /**
   * Actual jug width.
   */
  jugWidth: number;
  /**
   * Total capacity of the jug SVG, in millilitres.
   */
  jugCapacity: number;
  /**
   * Value of each  major tick on the jug SVG, in millilitres.
   */
  tickValue: number;
  /**
   * What units the label should be in. Defaults to litres.
   */
  labelUnits?: 'litres' | 'l' | 'ml' | 'fractionLitres';
  /**
   * Which major labels to display. "all" shows all major labels, "first" shows the first at the bottom and top label. "top" shows only the top.
   * Defaults to "all"
   */
  displayMajorLabels?: 'all' | 'first' | 'top' | 'none';
  /** Default "jug". Other options: "labelledGlass", "glass", "bottle"
   * Note: Labels are not supported on “bottle” or “glass”
   * The only difference between "glass" and "labelledGlass" is which point is treated as the max.
   */
  containerType?: ContainerType;
  /**
   * How many minor ticks between major ticks, these ticks cannot be labelled.
   */
  unitsPerMajorTick?: number;
  /**
   * Boolean to determine whether to hide all labels and the spine. Defaults to false.
   */
  hideAllLabels?: boolean;
  /**
   * Boolean to determine whether to give the jug a light blue background to display empty.
   */
  emptyJug: boolean;
  fontSize?: number;
  scaleFactor: number;
  scaleHeight: number;
  pixelsPerUnit: number;
  startOffset: number;
};

/**
 * This component renders a jug SVG with its scale.
 * It arranges the ticks and labels along the jug scale.
 */
const JugWithScale = (props: JugWithScaleProps) => {
  const {
    jugHeight,
    jugWidth,
    jugCapacity,
    tickValue,
    labelUnits = 'litres',
    unitsPerMajorTick,
    hideAllLabels,
    displayMajorLabels = 'all',
    containerType,
    emptyJug,
    scaleFactor,
    scaleHeight,
    pixelsPerUnit,
    startOffset,
    fontSize
  } = props;
  if (jugCapacity === 0) throw `Jug capacity cannot be 0`;
  const translate = useI18nContext().LL;
  const displayMode = useContext(DisplayMode);
  const styles = useStyles(jugWidth, jugHeight);
  const MAJOR_TICK_LENGTH = displayMode === 'digital' ? 25 : 35;
  const MINOR_TICK_LENGTH = displayMode === 'digital' ? 15 : 25;

  const getLabel = (value: number) => {
    switch (labelUnits) {
      case 'ml':
        return translate.units['numberOfMl'](value);
      case 'fractionLitres': {
        const x =
          value % 1000 === 0
            ? (value / 1000).toString()
            : formatFractionToMarkup(...decimalToFraction(value / 1000), 'fraction');
        // If the value is 1 litre use singular, otherwise plural litres
        return translate.units['stringLitres'](x, value / 1000 === 1 ? 1 : 2);
      }
      case 'l':
        return translate.units['numberOfL'](value / 1000);
      default:
        return translate.units['numberOfLitres'](value / 1000);
    }
  };

  const LABEL_FONT_SIZE = fontSize || Math.max(20, 28 * scaleFactor);
  // X position of the scale (from the right)
  const xScalePosition = containerType === 'barrel' ? jugWidth * 0.18 : jugWidth * 0.3;

  const ticks = (() => {
    return (
      <>
        <Svg viewBox={`0 0 ${jugWidth} ${jugHeight}`} height={jugHeight} width={jugWidth}>
          {/* Vertical scale line */}
          <Line
            x1={xScalePosition}
            x2={xScalePosition}
            y1={startOffset}
            y2={scaleHeight}
            strokeWidth={VERTICAL_STROKE_WIDTH * scaleFactor}
            stroke="black"
          />
          {/* Ticks */}
          {range(1, jugCapacity / tickValue).map((t, i) => {
            const y = startOffset + pixelsPerUnit * tickValue * t;
            return (
              // Minor ticks
              <React.Fragment key={`ticks_${i}`}>
                {unitsPerMajorTick &&
                  range(1, unitsPerMajorTick).map(x => {
                    const yMinorTick =
                      y - (tickValue / (unitsPerMajorTick + 1)) * x * pixelsPerUnit;
                    return (
                      <Line
                        key={`tick-${x}`}
                        strokeWidth={MINOR_TICK_STROKE * scaleFactor}
                        stroke="black"
                        x1={xScalePosition - VERTICAL_STROKE_WIDTH * scaleFactor * 0.5}
                        x2={MINOR_TICK_LENGTH + xScalePosition}
                        y1={yMinorTick}
                        y2={yMinorTick}
                      />
                    );
                  })}
                {/* Major ticks */}
                <Line
                  key={`tick-${i}`}
                  strokeWidth={MAJOR_TICK_STROKE * scaleFactor}
                  stroke="black"
                  x1={xScalePosition - VERTICAL_STROKE_WIDTH * scaleFactor * 0.5}
                  x2={MAJOR_TICK_LENGTH * scaleFactor + xScalePosition}
                  y1={y}
                  y2={y}
                />
              </React.Fragment>
            );
          })}
        </Svg>
        {/* Label text */}
        {displayMajorLabels !== 'none' && (
          <View
            style={{
              height: jugHeight,
              width: jugWidth,
              position: 'absolute',
              left: (MAJOR_TICK_LENGTH + 10) * scaleFactor + xScalePosition
            }}
            pointerEvents="none"
          >
            {range(1, jugCapacity / tickValue).map((t, i) => {
              if (
                t === jugCapacity / tickValue ||
                displayMajorLabels === 'all' ||
                (displayMajorLabels === 'first' && i === 0)
              ) {
                const y = startOffset + pixelsPerUnit * tickValue * t;
                return (
                  <View
                    key={i}
                    style={{
                      position: 'absolute',
                      height: LABEL_FONT_SIZE * 2,
                      top: y - LABEL_FONT_SIZE * 0.85,
                      justifyContent: 'center',
                      zIndex: 3
                    }}
                  >
                    <TextStructure
                      sentence={getLabel(tickValue * t)}
                      style={{
                        transform: [{ rotate: '180deg' }]
                      }}
                      textStyle={{ fontSize: LABEL_FONT_SIZE }}
                      fractionTextStyle={{ fontSize: LABEL_FONT_SIZE * 0.8 }}
                      fractionDividerStyle={{ marginVertical: 0, minWidth: 30 }}
                    />
                  </View>
                );
              }
            })}
          </View>
        )}
      </>
    );
  })();

  const jugSvgPath = (() => {
    switch (containerType) {
      case 'glass':
      case 'labelledGlass':
        return 'glass_empty';
      case 'bottle':
        return 'Water_bottle_glass_empty';
      case 'barrel':
        return 'Transparent_barrel';
      case 'bucket':
        return 'Empty_bucket_rectangular_blue';
      case 'jug':
        return emptyJug ? 'Capacity_images/Empty_jug_blue' : 'Capacity_images/Empty_jug';
    }
  })();

  return (
    <View style={[styles.imageWrapper]}>
      <AssetSvg name={jugSvgPath as SvgName} height={jugHeight} style={{ maxWidth: jugWidth }} />
      <View
        style={{
          position: 'absolute',
          zIndex: 2,
          // Flip it so it starts at the bottom
          transform: [{ rotate: `180deg` }]
        }}
      >
        <View>{!hideAllLabels && ticks}</View>
      </View>
    </View>
  );
};

const useStyles = (width: number, height: number) => {
  return useMemo(
    () =>
      StyleSheet.create({
        imageWrapper: {
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          width,
          height
        }
      }),
    [width, height]
  );
};

const getSizingInfo = (
  jugCapacity: number,
  jugHeight: number,
  scaleFactor: number,
  emptyJugWidth: number,
  containerType: ContainerType
) => {
  const BOTTOM_OFFSET = 3;
  const TOP_OFFSET = containerType === 'glass' ? 0 : containerType === 'barrel' ? 20 : 55;
  const width = emptyJugWidth;
  const usableHeightForTicks = jugHeight - (BOTTOM_OFFSET + TOP_OFFSET) * scaleFactor;
  const startOffset = containerType === 'bucket' ? 2 : 0;
  const height = usableHeightForTicks + startOffset;
  const pixelsPerUnit = usableHeightForTicks / jugCapacity;
  return {
    naturalWidth: width,
    naturalHeight: height,
    pixelsPerUnit,
    startOffset
  };
};

type JugWithLiquidProps = {
  /**
   * Total capacity of the jug SVG, in millilitres.
   */
  jugCapacity: number;
  /**
   * Value of each major tick on the jug SVG, in millilitres.
   */
  tickValue: number;
  /**
   * Number of minor ticks per major tick.
   */
  unitsPerMajorTick?: number;
  /**
   * @param dimens - Usable dimensions for the question content.
   */
  dimens: Dimens;
  /** Current amount, in millilitres */
  liquidAmount?: number;
  /** Callback for when amount is changed by a gesture. */
  setAmount?: SetState<number>;
  /**
   * Which major labels to display. "first" shows the first at the bottom and top label. "top" shows only the top.
   * Defaults to "all"
   */
  displayMajorLabels?: 'all' | 'first' | 'top' | 'none';
  /**
   * What units the label should be in. Defaults to litres.
   */
  labelUnits?: 'litres' | 'l' | 'ml' | 'fractionLitres';
  /**
   * Type of liquid to show in the jug.
   * Optional prop, defaults to 'water'.
   */
  liquidType?: 'water' | 'orange' | 'sand';
  /**
   * Amount in ml for the arrow to incrementally snap to.
   * Optional prop, if left undefined, the arrow will simply snap to the minor tick values.
   */
  snapToNearest?: number;
  /**
   * Boolean to determine whether to give the jug a light blue background to display empty.
   */
  emptyJug?: boolean;
  fontSize?: number;
  isInteractive?: boolean;
  containerType?: ContainerType;
  hideAllLabels?: boolean;
  drawLiquidLine?: boolean;
};

const AnimatedRect = Animated.createAnimatedComponent(Rect);

/**
 * A component that displays a jug with a draggable slider arrow to change the liquid amount.
 * Non-interactive version shows a static jug and no arrow.
 */
export const JugWithLiquid = ({
  jugCapacity,
  liquidAmount = 0,
  setAmount,
  tickValue,
  unitsPerMajorTick,
  dimens: { width, height },
  displayMajorLabels = 'all',
  labelUnits = 'litres',
  liquidType = 'water',
  snapToNearest,
  fontSize,
  emptyJug = false,
  isInteractive = false,
  containerType = 'jug',
  hideAllLabels = false,
  drawLiquidLine = false
}: JugWithLiquidProps) => {
  const svgPath = (() => {
    switch (containerType) {
      case 'jug':
        return 'Capacity_images/Empty_jug';
      case 'labelledGlass':
      case 'glass':
        return 'glass_empty';
      case 'bottle':
        return 'Water_bottle_glass_empty';
      case 'barrel':
        return 'Transparent_barrel';
      case 'bucket':
        return 'Empty_bucket_rectangular_blue';
    }
  })();
  const { height: EMPTY_JUG_HEIGHT, width: EMPTY_JUG_WIDTH } = getSvgInfo(svgPath);

  const scaleFactor = useContext(ScaleFactorContext);
  if (jugCapacity < liquidAmount) throw `Liquid amount cannot be more than jug capacity`;
  const fill =
    liquidType === 'water'
      ? containerType === 'barrel'
        ? colors.barrelWater
        : colors.jugWater
      : liquidType === 'orange'
      ? colors.jugOrangeJuice
      : colors.jugSand;

  const minorTickValue = unitsPerMajorTick ? tickValue / (unitsPerMajorTick + 1) : tickValue;

  const { width: jugWidth, height: jugHeight } = containAspectRatio(
    { width, height },
    EMPTY_JUG_WIDTH / EMPTY_JUG_HEIGHT
  );

  const scaleFactorJug = jugHeight / EMPTY_JUG_HEIGHT;

  const {
    naturalHeight: scaleHeight,
    pixelsPerUnit,
    startOffset
  } = getSizingInfo(jugCapacity, jugHeight, scaleFactorJug, EMPTY_JUG_WIDTH, containerType);

  const unitsPerPixel = 1 / pixelsPerUnit;
  const snapToNearestPixels =
    snapToNearest !== undefined ? pixelsPerUnit * snapToNearest : pixelsPerUnit * minorTickValue;

  const maxAmountPixels = jugCapacity * pixelsPerUnit;

  const amountPixels = Math.round(liquidAmount * pixelsPerUnit);

  const animatedAmount = useSharedValue(amountPixels);

  const setAmountPixels: ((x: number) => void) | undefined = useMemo(() => {
    if (setAmount === undefined) {
      return undefined;
    }
    return x => setAmount(Math.round(x * unitsPerPixel));
  }, [setAmount, unitsPerPixel]);

  const beginPagePosition = useSharedValue<[number, number] | null>(null);
  const beginAmount = useSharedValue<number | null>(null);
  const updateParent = setAmountPixels !== undefined;

  const panGesture = useMemo(
    () =>
      Gesture.Pan()
        .onBegin(event => {
          beginPagePosition.value = [event.absoluteX, event.absoluteY];
          beginAmount.value = animatedAmount.value;
        })
        .onUpdate(event => {
          const translation = (event.absoluteY - beginPagePosition.value![1]) / scaleFactor;
          // Negative to 'fill' liquid upwards
          let currentLength = beginAmount.value! - translation;
          // Clamp to stay in bounds of the jug:
          currentLength = Math.min(maxAmountPixels, Math.max(0, currentLength));
          // Snapping behaviour
          currentLength = Math.round(currentLength / snapToNearestPixels) * snapToNearestPixels;
          animatedAmount.value = currentLength;
        })
        .onFinalize(() => {
          updateParent && runOnJS(setAmountPixels)(animatedAmount.value);
        }),
    [
      animatedAmount,
      beginAmount,
      beginPagePosition,
      maxAmountPixels,
      scaleFactor,
      setAmountPixels,
      snapToNearestPixels,
      updateParent
    ]
  );

  // 96 is the minimum answer box size, and feels about right for grabbing the arrow on mobile. Smaller can feel difficult to drag.
  const ARROW_HEIGHT = 96;

  const animatedArrowStyle = useAnimatedStyle(
    () => ({ bottom: startOffset + animatedAmount.value - ARROW_HEIGHT / 2 }),
    [animatedAmount, startOffset]
  );

  const animatedLiquidProps = useAnimatedProps(
    () => ({
      height: animatedAmount.value,
      y: height - animatedAmount.value - startOffset
    }),
    [animatedAmount, height, startOffset]
  );

  const [animationSvgWidth, animationLeftOffset, animationWidth] = (() => {
    switch (containerType) {
      case 'glass':
        return [jugWidth * 0.97, 14 * scaleFactorJug, jugWidth * 0.97];
      case 'labelledGlass':
        return [jugWidth, 0, jugWidth];
      case 'bottle':
        return [jugWidth * 0.97, 5 * scaleFactorJug, jugWidth * 0.97];
      case 'jug':
        return [jugWidth * 0.74, 42 * scaleFactorJug, jugWidth * 0.8];
      case 'barrel':
        return [jugWidth * 0.97, 3 * scaleFactorJug, jugWidth * 0.97];
      case 'bucket':
        return [jugWidth * 0.81, 64 * scaleFactorJug, jugWidth * 0.82];
    }
  })();

  return (
    <View style={{ width, height, alignItems: 'center' }}>
      {isInteractive && (
        <GestureDetector gesture={panGesture}>
          <Animated.View
            style={[
              {
                width: 110,
                height: ARROW_HEIGHT,
                position: 'absolute',
                alignItems: 'center',
                justifyContent: 'center',
                right: (width - jugWidth) * 0.5 + jugWidth
              },
              animatedArrowStyle
            ]}
          >
            <AssetSvg
              name="SliderArrowRightCustomizable"
              height={26}
              svgProps={{ fill: colors.burntSienna }}
            />
          </Animated.View>
        </GestureDetector>
      )}
      <View
        style={{
          width: jugWidth,
          height: jugHeight,
          position: 'relative'
        }}
      >
        <JugWithScale
          jugHeight={jugHeight}
          jugWidth={jugWidth}
          jugCapacity={jugCapacity}
          scaleFactor={scaleFactorJug}
          tickValue={tickValue}
          unitsPerMajorTick={unitsPerMajorTick}
          displayMajorLabels={displayMajorLabels}
          labelUnits={labelUnits}
          fontSize={fontSize}
          emptyJug={emptyJug}
          startOffset={startOffset}
          pixelsPerUnit={pixelsPerUnit}
          scaleHeight={scaleHeight}
          hideAllLabels={hideAllLabels}
          containerType={containerType}
        />

        <Svg
          width={animationSvgWidth}
          height={jugHeight}
          style={{
            position: 'absolute',
            zIndex: -2,
            alignSelf: 'center',
            left: animationLeftOffset
          }}
        >
          {drawLiquidLine ? (
            <Line
              x1={0}
              x2={jugWidth}
              y1={jugHeight - animatedAmount.value}
              y2={jugHeight - animatedAmount.value}
              strokeWidth={5}
              stroke={colors.greys700}
              strokeDasharray={[8, 8]}
            />
          ) : (
            <>
              <Rect
                pointerEvents="none"
                fill={colors.white}
                width={animationWidth}
                height={jugHeight}
              />
              <AnimatedRect
                pointerEvents="none"
                width={animationWidth}
                x={0}
                animatedProps={animatedLiquidProps}
                fill={fill}
              />
            </>
          )}
        </Svg>
      </View>
    </View>
  );
};

/** StateTree version of {@link JugWithLiquid}. */
export const JugWithDraggableLiquidWithState = withStateHOC(JugWithLiquid, {
  stateProp: 'liquidAmount',
  setStateProp: 'setAmount'
});

/**
 * Converts how many minor ticks are needed for invervals in ml.
 */
export const getNumberOfIntervals = (intervalInMl: number, totalMl = 1000) =>
  totalMl / intervalInMl - 1;
