import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { rangeAsString } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import Text from '../../../../components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import { isInRange, isInRangeExclusive } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdj',
  description: 'bdj',
  keywords: ['Number line', 'Estimate', 'Halfway'],
  schema: z.object({
    startingNumber: numberEnum([20, 30, 40]),
    number: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(number => number % 5 !== 0)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([20, 30, 40] as const);

    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9, {
      constraint: x => x % 5 !== 0
    });

    return { startingNumber, number };
  },
  Component: props => {
    const {
      question: { startingNumber, number },
      translate
    } = props;
    const tickInterval = 5;
    const midPoint = startingNumber + 5;
    const endNumber = startingNumber + 10;

    const tickArray = rangeAsString(startingNumber, endNumber, tickInterval, true);

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragArrowToEstimateWhereXIsOnNumberLine(number)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowEstimateNumberIsOnNumberLine(number)}
        testCorrect={(userAnswer: number) =>
          number < midPoint
            ? isInRangeExclusive(startingNumber, midPoint)(userAnswer)
            : isInRangeExclusive(midPoint, endNumber)(userAnswer)
        }
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickArray}
        customMarkSchemeAnswer={{
          answerToDisplay: number,
          answerText: translate.markScheme.answersMayVaryTheyAreEstimates()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdk',
  description: 'bdk',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    startingNumber: numberEnum([20, 30, 40]),
    number: z.number().int().min(21).max(49)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([20, 30, 40] as const);

    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);

    return { startingNumber, number };
  },
  Component: props => {
    const {
      question: { startingNumber, number },
      translate
    } = props;
    const endNumber = startingNumber + 10;

    const tickValues = [startingNumber.toLocaleString(), endNumber.toLocaleString()];

    const testCorrect = (userAnswer: number) => {
      return (
        isInRange(number - 1, number + 1)(userAnswer) &&
        userAnswer !== endNumber &&
        userAnswer !== startingNumber
      );
    };

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragArrowToEstimateWhereXIsOnNumberLine(number)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowEstimateNumberIsOnNumberLine(number)}
        testCorrect={testCorrect}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answerToDisplay: number,
          answerText: translate.markScheme.allowMarginOfErrorOfXWithoutCrossingATen(2)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdl',
  description: 'bdl',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    startingNumber: numberEnum([20, 30, 40]),
    number: z.number().int().min(21).max(49),
    answers: z.number().int().min(0).max(60).array().length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([20, 30, 40] as const);

    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);

    const incorrectOptions = [
      getRandomFromArray([startingNumber, startingNumber + 10]), // start or end number
      getRandomFromArray([number + 10, number - 10]), // 10 less or 10 more than answer
      parseFloat(number.toString().split('')[1]) // remove 10s from answer
    ];

    if (number < startingNumber + 5)
      incorrectOptions.push(randomIntegerInclusive(startingNumber + 6, startingNumber + 10));
    // Number from the other side of the midpoint
    else if (number > startingNumber + 5)
      incorrectOptions.push(randomIntegerInclusive(startingNumber, startingNumber + 4)); // Number from the other side of the midpoint

    const betweenTicks = [
      startingNumber + 1,
      startingNumber + 2,
      startingNumber + 3,
      startingNumber + 4,
      //doesn't include the midpoint
      startingNumber + 6,
      startingNumber + 7,
      startingNumber + 8,
      startingNumber + 9
    ];

    if (betweenTicks.includes(number)) incorrectOptions.push(startingNumber + 5); //midpoint if answer is not the midpoint
    if (number === startingNumber + 5) getRandomFromArray(betweenTicks); // any value on the numberline that's not the midpoint if the answer is the midpoint

    const incorrectAnswers = rejectionSample(
      () => getRandomSubArrayFromArray(incorrectOptions, 2),
      x => {
        const givenValues = [startingNumber, startingNumber + 10];

        if (x[0] === x[1] || x[0] === number || x[1] === number) return false;

        const firstIncorrectAnswerValid =
          givenValues.includes(x[0]) ||
          (x[0] !== x[1] - 1 && x[0] !== x[1] + 1 && x[0] !== number - 1 && x[0] !== number + 1);

        const secondIncorrectAnswerValid =
          givenValues.includes(x[1]) ||
          (x[1] !== x[0] - 1 && x[1] !== x[0] + 1 && x[1] !== number - 1 && x[1] !== number + 1);

        return firstIncorrectAnswerValid && secondIncorrectAnswerValid;
      }
    );

    const answers = shuffle([number, ...incorrectAnswers]);

    return { startingNumber, number, answers };
  },
  Component: props => {
    const {
      question: { startingNumber, number, answers },
      translate
    } = props;
    const endNumber = startingNumber + 10;

    const tickValues = [startingNumber.toLocaleString(), endNumber.toLocaleString()];

    const renderItems = shuffle(
      answers.map(number => ({
        component: <Text variant="WRN700">{number.toLocaleString()}</Text>,
        value: number
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.estimateTheNumberArrowPointingToSelectAnswer()}
        pdfTitle={translate.ks1PDFInstructions.estimateTheNumberArrowPointingToTickAnswer()}
        itemLayout="row"
        testCorrect={[number]}
        numItems={3}
        Content={({ dimens }) => (
          <NumberLine
            dimens={dimens}
            tickValues={tickValues}
            focusNumber={number}
            extendFocusArrow
          />
        )}
        renderItems={renderItems}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateOnANumberLineTo50',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
