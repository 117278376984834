import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import {
  randomIntegerInclusive,
  getRandomFromArray,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { integerToWord, lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { countRange, sortNumberArray } from '../../../../utils/collections';
import Text from 'common/src/components/typography/Text';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { colors } from '../../../../theme/colors';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import DigitalStopwatch from '../../../../components/question/representations/DigitalStopwatch';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfD',
  description: 'bfD',
  keywords: ['Seconds', 'Minutes', 'Hours', 'Time'],
  schema: z.object({
    number: z.number().int().min(0).max(50),
    timeTypeA: z.enum(['seconds', 'hours', 'minutes']),
    timeTypeB: z.enum(['seconds', 'hours', 'minutes'])
  }),
  simpleGenerator: () => {
    const [timeTypeA, timeTypeB] = countRange(2).map(() => {
      return getRandomFromArray(['seconds', 'hours', 'minutes'] as const);
    });

    const number =
      timeTypeA === timeTypeB ? randomIntegerInclusive(0, 10) : randomIntegerInclusive(0, 50);

    return {
      number,
      timeTypeA,
      timeTypeB
    };
  },

  Component: ({ question: { number, timeTypeA, timeTypeB }, translate }) => {
    const times = {
      timeA: {
        label: translate.time[timeTypeA](number),
        value: number
      },
      timeB: {
        label: translate.time[timeTypeB](number),
        value: timeTypeA === timeTypeB ? integerToWord(number) : number
      }
    };

    const timeAInSeconds =
      timeTypeA === 'hours' ? number * 3600 : timeTypeA === 'minutes' ? number * 60 : number;
    const timeBInSeconds =
      timeTypeB === 'hours' ? number * 3600 : timeTypeB === 'minutes' ? number * 60 : number;

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompare()}
        pdfTitle={translate.ks1PDFInstructions.writeLessThanGreaterThanOrEqualSymbolsToCompare()}
        items={['<', '>', '=']}
        itemVariant="square"
        actionPanelVariant="end"
        sentence={`${times['timeA'].value} ${times['timeA'].label} <ans /> ${times['timeB'].value} ${times['timeB'].label}`}
        testCorrect={[lessThanGreaterThanOrEqualTo(timeAInSeconds, timeBInSeconds)]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bfE',
  description: 'bfE',
  keywords: ['Seconds', 'Minutes', 'Hours', 'Stopwatch', 'Time'],
  schema: z.object({
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('seconds'),
        seconds: z.number().int().min(1).max(59)
      }),
      z.object({
        variant: z.literal('minutesAndSeconds'),
        minutes: z.number().int().min(1).max(59),
        seconds: z.number().int().min(1).max(59)
      }),
      z.object({
        variant: z.literal('hoursAndMinutesAndSeconds'),
        hours: z.number().int().min(1).max(6),
        minutes: z.number().int().min(1).max(59),
        seconds: z.number().int().min(1).max(59)
      })
    ])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 6);
    const minutes = randomIntegerInclusive(1, 59);
    const seconds = randomIntegerInclusive(1, 59);
    const variant = getRandomFromArray([
      'seconds',
      'minutesAndSeconds',
      'hoursAndMinutesAndSeconds'
    ] as const);

    const subQuestion =
      variant === 'hoursAndMinutesAndSeconds'
        ? { variant, hours, minutes, seconds }
        : variant === 'minutesAndSeconds'
        ? { variant, minutes, seconds }
        : { variant, seconds };

    return { subQuestion };
  },
  Component: props => {
    const {
      question: { subQuestion },
      translate
    } = props;

    const { seconds, variant } = subQuestion;

    const answer =
      subQuestion.variant === 'seconds'
        ? [subQuestion.seconds.toString()]
        : variant === 'minutesAndSeconds'
        ? [subQuestion.minutes.toString(), seconds.toString()]
        : [subQuestion.hours.toString(), subQuestion.minutes.toString(), seconds.toString()];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeIsShownOnTheStopwatch()}
        sentence={
          variant === 'seconds'
            ? translate.ks1AnswerSentences.ansSeconds()
            : variant === 'minutesAndSeconds'
            ? translate.ks1AnswerSentences.ansMinutesAnsSeconds()
            : translate.ks1AnswerSentences.ansHoursAnsMinutesAnsSeconds()
        }
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="column"
        testCorrect={answer}
        Content={
          <DigitalStopwatch
            seconds={seconds}
            minutes={
              subQuestion.variant === 'minutesAndSeconds' ||
              subQuestion.variant === 'hoursAndMinutesAndSeconds'
                ? subQuestion.minutes
                : 0
            }
            hours={subQuestion.variant === 'hoursAndMinutesAndSeconds' ? subQuestion.hours : 0}
          />
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bfF',
  description: 'bfF',
  keywords: ['Seconds', 'Time'],
  schema: z.object({
    seconds: z.array(z.number().int().min(8).max(59)).length(3),
    names: z.array(ks1NameSchema).length(3)
  }),

  simpleGenerator: () => {
    const seconds = randomUniqueIntegersInclusive(8, 59, 3);
    const names = getRandomUniqueKs1Names(3);

    return { seconds: seconds, names };
  },
  Component: props => {
    const {
      question: { seconds, names },
      translate,
      displayMode
    } = props;

    const [nameA, nameB, nameC] = names;

    const [secondsA, secondsB, secondsC] = seconds;

    const statements = [
      {
        name: nameA,
        value: secondsA
      },
      {
        name: nameB,
        value: secondsB
      },
      {
        name: nameC,
        value: secondsC
      }
    ];

    const answer = sortNumberArray(seconds)[0];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.threeChildrenRunARaceSelectWhichChildComesFirst()}
        pdfTitle={translate.ks1PDFInstructions.threeChildrenRunARaceTickTheChildWhoComesFirst()}
        testCorrect={[answer]}
        numItems={3}
        itemLayout="row"
        Content={({ dimens }) => {
          return (
            <CustomizableTable
              tableStyle={{ marginTop: 64, width: dimens.width / 1.5 }}
              cellHeaders={names.map(name => {
                return {
                  label: name.toLocaleString(),
                  containerStyle: {
                    backgroundColor: colors.seaGreenCrayola
                  },
                  textStyle: {
                    color: colors.black,
                    fontSize: displayMode === 'digital' ? 32 : 48
                  }
                };
              })}
              tableData={countRange(1).map(() =>
                [secondsA, secondsB, secondsC].map((seconds, idx) => (
                  <Text
                    key={idx}
                    variant="WRN400"
                    style={{
                      textAlign: 'center',
                      color: colors.black,
                      fontSize: displayMode === 'digital' ? 32 : 48
                    }}
                  >
                    {seconds.toLocaleString()} {translate.time.seconds(seconds)}
                  </Text>
                ))
              )}
            />
          );
        }}
        renderItems={statements.map(statement => ({
          value: statement.value,
          component: <Text variant="WRN700">{statement.name}</Text>
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'HoursMinutesAndSeconds',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
