import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusiveStep,
  randomNumberPairs,
  randomUniqueIntegersInclusiveStep,
  shuffle
} from '../../../../utils/random';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { ADD } from '../../../../constants';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange
} from '../../../../utils/collections';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import Text from '../../../../components/typography/Text';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bgH',
  description: 'bgH',
  keywords: ['Number bonds', 'Add', 'Rekenrek'],
  schema: z.object({
    numberBond: z.array(z.number().int().min(10).max(90)).length(2),
    ansIndex: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusiveStep(10, 90, 10);
    const number2 = 100 - number1;
    const ansIndex = getRandomFromArray([0, 1] as const);

    return { numberBond: [number1, number2], ansIndex };
  },
  Component: props => {
    const {
      question: { numberBond, ansIndex },
      translate
    } = props;

    const [partA, partB] = numberBond;
    const { sentence, answer } = buildSimpleNumberSentence([partA, partB, 100], ADD, ansIndex);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheNumberBondToX(100)}
        sentence={sentence}
        pdfSentenceStyle={{ justifyContent: 'flex-end', alignContent: 'flex-end' }}
        testCorrect={[answer.toString()]}
        Content={({ dimens }) => <Rekenrek dimens={dimens} rows={10} numberShown={partA} />}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bgI',
  description: 'bgI',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    numbers: z
      .array(z.number().min(0).max(100))
      .length(6)
      .refine(val => {
        const fiftyCount = val.filter(x => x === 50).length;
        const arrayWithoutFifties = val.filter(x => x !== 50);
        return fiftyCount <= 2 && arrayHasNoDuplicates(arrayWithoutFifties);
      }, 'numbers must only have duplicates if we have 50 + 50'),
    reversed: z.boolean()
  }),
  simpleGenerator: () => {
    const reversed = getRandomBoolean();

    const [nb1, ...index1s] = randomUniqueIntegersInclusiveStep(0, 50, 10, 5);
    const index2s = index1s.map(val => 100 - val);

    const numberBond = [nb1, 100 - nb1];
    const numbers = countRange(4).map(
      val => [index1s[val], index2s[val]][getRandomFromArray([0, 1] as const)]
    );

    return { numbers: shuffle([...numberBond, ...numbers]), reversed };
  },
  Component: props => {
    const {
      question: { numbers, reversed },
      translate
    } = props;

    const items = numbers.map(num => ({
      component: <Text variant="WRN700">{num.toLocaleString()}</Text>,
      value: num
    }));

    const sentence = reversed
      ? `${(100).toLocaleString()} = <ans/> ${ADD} <ans/>`
      : `<ans/> ${ADD} <ans/> = ${(100).toLocaleString()}`;

    // Find correct answer
    let correctAnswer: number[] = [];
    numbers.forEach((val, i) => {
      if (correctAnswer.length > 0) {
        return;
      } else {
        const indexOfBond = numbers.indexOf(100 - val);
        if (indexOfBond !== -1 && indexOfBond !== i) {
          correctAnswer = [val, 100 - val];
        }
      }
    });

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheNumberBondToX(100)}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheNumberBondToX(100)}
        sentence={sentence}
        items={items}
        testCorrect={userAnswer => arraysHaveSameContentsUnordered(correctAnswer, userAnswer)}
        customMarkSchemeAnswer={{ answersToDisplay: [correctAnswer] }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgJ',
  description: 'bgJ',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    numberBond: z.array(z.number().int().min(0).max(100).multipleOf(10)).length(2),
    reversed: z.boolean(),
    ansIndex: numberEnum([0, 1])
  }),
  simpleGenerator: () => {
    const reversed = getRandomFromArrayWithWeights([true, false], [1, 3]);
    const ansIndex = getRandomFromArray([0, 1] as const);

    const [numberBond] = randomNumberPairs(100, 1, { constraint: x => x % 10 === 0 });

    return { reversed, ansIndex, numberBond };
  },
  Component: props => {
    const {
      question: { reversed, ansIndex, numberBond },
      translate
    } = props;

    const [partA, partB] = numberBond;
    const { sentence, answer } = buildSimpleNumberSentence([partA, partB, 100], ADD, ansIndex, {
      reversed
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheNumberBondToX(100)}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'BondsTo100Tens',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
