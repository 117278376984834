import { StyleSheet, View } from 'react-native';
import { arraysHaveSameContents, filledArray } from 'common/src/utils/collections';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import EasyDragAndDropWithSingleZones from '../../draganddrop/EasyDragAndDropWithSingleZones';
import { Theme } from '../../../theme';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import DragAndDropSectionPDF from '../../molecules/DragAndDropSectionPDF';
import Text from '../../typography/Text';
import { colors } from '../../../theme/colors';
import { DisplayMode } from '../../../contexts/displayMode';
import { MINIMUM_QUESTION_HEIGHT } from '../../../theme/scaling';
import ContentBox from '../../molecules/ContentBox';
import { DRAGGABLE_DIMENS } from '../../draganddrop/utils';
import { AssetSvg } from '../../../assets/svg';
import { verticalArrow } from '../representations/LineSvgs';

type ItemInfo<T> = { component: string | JSX.Element; value: T };

type Props<T> = TitleStyleProps & {
  title: string;
  pdfTitle?: string;

  /**
   * Either an array of the correct order of values or an object containing a test correct function and a
   * length property (length of user answer).
   */
  testCorrect: T[];

  /** The items to show as draggables. Each item can be passed as a plain string or number. */
  items: ((T & (string | number)) | ItemInfo<T>)[];
  // used if items contains plain strings or numbers
  itemsTextVariant?: keyof Theme['fonts'];
  itemsLetterEmWidth?: number;
  itemsMaxLines?: number;

  /** Default: 'move', i.e. one-to-one. 'copy' is many-to-one. */
  moveOrCopy?: 'move' | 'copy';

  topLabel: string;
  bottomLabel: string;
  /** Props for extra content modal */
  promptButton?: string;
  modalTitle?: string;
  modalContent?: JSX.Element;
  /** Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: { answersToDisplay?: T[]; answerText?: string };
  draggableVariant?: 'rectangle' | 'square' | 'shortRectangle' | 'tallRectangle';
  pdfItemVariant?: 'rectangle' | 'pdfSquare' | 'square' | 'shortRectangle' | 'tallRectangle';
  contentBox?: string;
  /** Where to position the items on pdf.  Defaults to endColumn */
  pdfItemsLayout?: 'topRow' | 'endColumn';
};

/**
 * Question Format 4: Order Vertical (drag and drop)
 *
 * Title at the top, draggables on the right, and positions to drag them into on the left.
 *
 * This is one-to-one by default, but can be made many-to-one with moveOrCopy='copy'.
 *
 * At most 4 draggables.
 */
export default function QF4DragOrderVertical<T>({
  title,
  pdfTitle,
  testCorrect,
  items: itemsProp,
  itemsTextVariant = 'WRN700',
  itemsLetterEmWidth = 0.7,
  itemsMaxLines = 2,
  moveOrCopy = 'move',
  topLabel,
  bottomLabel,
  promptButton,
  modalTitle,
  modalContent,
  questionHeight = MINIMUM_QUESTION_HEIGHT,
  customMarkSchemeAnswer,
  draggableVariant = 'rectangle',
  pdfItemVariant = 'tallRectangle',
  contentBox,
  pdfItemsLayout = 'endColumn',
  ...props
}: Props<T>) {
  const displayMode = useContext(DisplayMode);

  const styles = useStyles(displayMode);

  const answerLength = testCorrect.length;

  const items: ItemInfo<T>[] = itemsProp.map(item =>
    typeof item === 'object' ? item : { component: item.toLocaleString(), value: item }
  );

  const longestItemTextLength = items.reduce(
    (max, item) => Math.max(max, typeof item.component === 'string' ? item.component.length : 0),
    0
  );

  // get the height of each draggable to dictate the length of the vertical arrow
  const draggableHeight =
    DRAGGABLE_DIMENS[
      displayMode === 'digital' ? draggableVariant : pdfItemVariant ?? draggableVariant
    ].height;

  const offset =
    (items.length === 3 && draggableVariant !== 'tallRectangle') || displayMode !== 'digital'
      ? 1.5
      : 1.2;
  const arrowLength = draggableHeight * (items.length - offset);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const correctItemOrder = testCorrect.map(ans => {
      const [temp] = items.filter(it => it.value === ans);
      return temp.value;
    });

    return (
      <EasyDragAndDropWithSingleZones.ProviderWithState
        id="draganddrop"
        items={items}
        variant={pdfItemVariant ?? draggableVariant}
        textVariant={itemsTextVariant}
        textAutoScale={longestItemTextLength}
        textLetterEmWidth={itemsLetterEmWidth}
        maxLines={itemsMaxLines}
        moveOrCopy={displayMode === 'pdf' ? moveOrCopy : 'copy'}
        defaultState={displayMode === 'markscheme' ? correctItemOrder : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <View
              style={[
                styles.container,
                pdfItemsLayout === 'topRow' && {
                  flexDirection: 'column'
                }
              ]}
            >
              {pdfItemsLayout === 'topRow' && (
                <View
                  style={[
                    styles.draggableZonePdf,
                    {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      gap: 32,
                      padding: 32
                    }
                  ]}
                >
                  {items.map((_item, index) => (
                    <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
                  ))}
                </View>
              )}
              {contentBox && (
                <ContentBox containerStyle={styles.contentBox}>
                  <Text variant="WRN400">{contentBox}</Text>
                </ContentBox>
              )}
              <View style={[styles.mainPanelPDF]}>
                <DragAndDropSectionPDF>
                  <View style={styles.dropZoneColumn}>
                    {items.map((_item, index) => (
                      <View key={index} style={styles.dropZoneItem}>
                        <View style={[styles.dropZoneItemColA, { width: '40%' }]}>
                          {/* If the first dropzone render top label and arrow down so it can be positioned absolutely */}
                          {index === 0 && (
                            <View>
                              <Text variant="WRN400" style={styles.labelPDF}>
                                {topLabel}
                              </Text>
                              {
                                // Completely hide the arrow on PDFs if only two items.
                                items.length > 2 && (
                                  <View style={[styles.arrowDown]}>
                                    {verticalArrow(arrowLength, 6)}
                                  </View>
                                )
                              }
                            </View>
                          )}
                          {/* If the last dropzone render the bottom label */}
                          {index + 1 === items.length && (
                            <Text variant="WRN400" style={styles.labelPDF}>
                              {bottomLabel}
                            </Text>
                          )}
                        </View>
                        <View style={styles.dropZoneItemColB}>
                          {displayMode === 'markscheme' && (
                            <AssetSvg
                              name="True"
                              width={50}
                              style={{ zIndex: 999, position: 'absolute', top: -10, right: -10 }}
                            />
                          )}
                          <EasyDragAndDropWithSingleZones.ZoneSingle id={index} />
                        </View>
                      </View>
                    ))}
                  </View>
                </DragAndDropSectionPDF>
              </View>

              {pdfItemsLayout === 'endColumn' && (
                <DragAndDropSectionPDF>
                  <View style={styles.draggableZonePdf}>
                    {items.map((_item, index) => (
                      <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
                    ))}
                  </View>
                </DragAndDropSectionPDF>
              )}
            </View>
          }
          questionHeight={questionHeight}
          modalContent={modalContent}
          {...props}
        />
      </EasyDragAndDropWithSingleZones.ProviderWithState>
    );
  }

  return (
    <EasyDragAndDropWithSingleZones.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      variant={draggableVariant}
      defaultState={filledArray(undefined, answerLength)}
      testComplete={answer => answer.every(it => it !== undefined)}
      testCorrect={userAnswer => arraysHaveSameContents(userAnswer, testCorrect)}
      textVariant={itemsTextVariant}
      textAutoScale={longestItemTextLength}
      textLetterEmWidth={itemsLetterEmWidth}
      maxLines={itemsMaxLines}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="endWide"
        actionPanelContents={
          <DragAndDropSection>
            {items.map((_item, index) => (
              <EasyDragAndDropWithSingleZones.Source key={index} id={index} />
            ))}
          </DragAndDropSection>
        }
        mainPanelContents={
          <View style={styles.mainPanel}>
            {contentBox && (
              <ContentBox containerStyle={styles.contentBox}>
                <Text style={{ fontSize: 32 }}>{contentBox}</Text>
              </ContentBox>
            )}
            <View style={styles.dropZoneColumn}>
              {filledArray(undefined, answerLength).map((_, dropZoneIndex) => (
                <View key={dropZoneIndex} style={styles.dropZoneItem}>
                  <View style={styles.dropZoneItemColA}>
                    {/* If the first dropzone render top label and arrow down so it can be positioned absolutely */}
                    {dropZoneIndex === 0 && (
                      <View>
                        <Text style={styles.label}>{topLabel}</Text>
                        <View
                          style={[styles.arrowDown, items.length === 2 && styles.arrowDownSmall]}
                        >
                          {verticalArrow(arrowLength)}
                        </View>
                      </View>
                    )}
                    {/* If the last dropzone render the bottom label */}
                    {dropZoneIndex + 1 === items.length && (
                      <Text style={styles.label}>{bottomLabel}</Text>
                    )}
                  </View>
                  <View style={styles.dropZoneItemColB}>
                    <EasyDragAndDropWithSingleZones.ZoneSingle id={dropZoneIndex} />
                  </View>
                </View>
              ))}
            </View>
          </View>
        }
        promptButton={promptButton}
        modalTitle={modalTitle}
        modalContent={modalContent}
        {...props}
      />
    </EasyDragAndDropWithSingleZones.ProviderWithState>
  );
}

const useStyles = (displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return StyleSheet.create({
    container: {
      alignSelf: 'stretch',
      justifyContent: 'center',
      flexDirection: 'row'
    },
    contentBox: {
      justifyContent: 'center',
      alignSelf: 'center'
    },
    mainPanel: {
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignContent: 'center'
    },
    mainPanelPDF: {
      flex: 1,
      rowGap: 32,
      alignSelf: 'stretch',
      justifyContent: 'center',
      alignContent: 'center'
    },
    dropZoneColumn: {
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 16
    },
    dropZoneItem: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row'
    },
    dropZoneItemColA: {
      width: displayMode !== 'digital' ? '50%' : '33.333%',
      position: 'relative'
    },
    dropZoneItemColB: {
      paddingLeft: 32
    },
    label: {
      color: colors.grey,
      fontSize: 32,
      textAlign: 'center'
    },
    labelPDF: {
      color: colors.grey,
      textAlign: 'center'
    },
    arrowDown: {
      alignSelf: 'center',
      position: 'absolute',
      top: displayMode !== 'digital' ? 85 : 60
    },
    arrowDownSmall: {
      top: 50
    },
    draggableZonePdf: {
      gap: 20
    }
  });
};
