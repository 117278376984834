import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomNumberInclusive,
  shuffle
} from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import deepEqual from 'react-fast-compare';
import { View } from 'react-native';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbR',
  description: 'bbR',
  keywords: ['Sort', 'Shape', 'Colour'],
  schema: z.object({
    isSortedByShape: z.boolean(),
    items: z.array(
      z.array(
        z.object({
          shapeName: z.enum([
            'Circle',
            'Square',
            'Rectangle',
            'Triangle',
            'RATriangle',
            'ScaleneTriangle'
          ]),
          color: z.enum(['purple', 'blue', 'yellow', 'green', 'red', 'pink']),
          scale: z.number().min(0.9).max(1.2),
          rotation: z.number().int().min(0).max(360)
        })
      )
    )
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const isSortedByShape = getRandomBoolean();

    const [shapeA, shapeB] = getRandomSubArrayFromArray(
      ['Circle', 'Square', 'Rectangle', 'Triangle'] as const,
      2
    );

    const [colorA, colorB] = getRandomSubArrayFromArray(
      ['purple', 'blue', 'yellow', 'green', 'red', 'pink'] as const,
      2
    );

    const itemsA = countRange(6)
      .map(num => ({
        // If isSortedByShape is true, always use shapeA. If false, ensure both shapeA and shapeB are used, then fill the remaining slots randomly with either shape.
        shapeName: isSortedByShape
          ? shapeA
          : num === 0
          ? shapeA
          : num === 1
          ? shapeB
          : getRandomFromArray([shapeA, shapeB] as const),
        color: colorA,
        scale: randomNumberInclusive(0.9, 1.2, 1),
        rotation: randomIntegerInclusive(0, 360)
      }))
      .map(item => {
        return {
          ...item,
          shapeName:
            item.shapeName === 'Triangle'
              ? getRandomFromArray(['Triangle', 'RATriangle', 'ScaleneTriangle'] as const)
              : item.shapeName
        };
      });

    const itemsB = countRange(6)
      .map(num => ({
        // If isSortedByShape is true, always use shapeB. If false, ensure both shapeA and shapeB are used, then fill the remaining slots randomly with either shape.
        shapeName: isSortedByShape
          ? shapeB
          : num === 0
          ? shapeA
          : num === 1
          ? shapeB
          : getRandomFromArray([shapeA, shapeB] as const),
        color: isSortedByShape ? colorA : colorB,
        scale: randomNumberInclusive(0.9, 1.2, 1),
        rotation: randomIntegerInclusive(0, 360)
      }))
      .map(item => {
        return {
          ...item,
          shapeName:
            item.shapeName === 'Triangle'
              ? getRandomFromArray(['Triangle', 'RATriangle', 'ScaleneTriangle'] as const)
              : item.shapeName
        };
      });

    const items = [shuffle(itemsA), shuffle(itemsB)];

    return { isSortedByShape, items };
  },
  Component: props => {
    const {
      translate,
      displayMode,
      question: { isSortedByShape, items }
    } = props;

    const getShapeName = (
      shapeName: 'Circle' | 'Square' | 'Rectangle' | 'Triangle' | 'RATriangle' | 'ScaleneTriangle',
      color: 'purple' | 'blue' | 'yellow' | 'green' | 'red' | 'pink'
    ) => {
      switch (shapeName) {
        case 'Circle':
          return `Circles/circle_${color}` as const;
        case 'Square':
          return `Square/square_${color}` as const;
        case 'Rectangle':
          return `Rectangle/rectangle_${color}` as const;
        case 'Triangle':
          return `Equilateral_triangles/triangle_equal_${color}` as const;
        case 'RATriangle':
          return `Right_angled_triangles/triangle_RA_${color}` as const;
        case 'ScaleneTriangle':
          return `Scalene_triangles/triangle_scalene_${color}` as const;
      }
    };

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.howAreTheShapesSorted()}
        items={[translate.misc.shape(), translate.misc.colour()]}
        questionHeight={1200}
        pdfItemVariant="tallRectangle"
        itemVariant="rectangle"
        pdfLayout="itemsHidden"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        Content={({ dimens }) => (
          <View
            style={{ width: dimens.width, justifyContent: 'space-evenly', flexDirection: 'row' }}
          >
            {items.map((item, containerIndex) => (
              <View
                key={`container_${containerIndex}`}
                style={{
                  width: dimens.width * 0.45,
                  height: dimens.height,
                  borderColor: 'black',
                  borderWidth: 3,
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {item.map(({ shapeName, rotation, scale, color }, shapeIndex) => (
                  <View
                    style={{
                      paddingHorizontal: displayMode === 'digital' ? 10 : 40,
                      height: dimens.height * 0.33,
                      width: dimens.width * 0.18,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                    key={`container_${containerIndex}_shape_${shapeIndex}`}
                  >
                    <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
                      <AssetSvg
                        name={getShapeName(shapeName, color)}
                        height={
                          (displayMode === 'digital' ? (shapeName === 'Square' ? 65 : 70) : 120) *
                          scale
                        }
                        width={
                          (displayMode === 'digital' ? (shapeName === 'Square' ? 65 : 70) : 120) *
                          scale
                        }
                      />
                    </View>
                  </View>
                ))}
              </View>
            ))}
          </View>
        )}
        sentence={translate.ks1AnswerSentences.theShapesAreSortedByAns()}
        testCorrect={[isSortedByShape ? translate.misc.shape() : translate.misc.colour()]}
      />
    );
  }
});

const q2And3Shapes = ['circle', 'rectangle', 'square', 'triangle'] as const;
const q2And3Colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const Question2 = newQuestionContent({
  uid: 'bbS',
  description: 'bbS',
  keywords: ['Sort', '2-D shapes'],
  schema: z.object({
    /* List of objects to show on the right. This must consist of 3 distinct shapes and 2 distinct colors. */
    items: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.5).max(1).optional(),
          scaleY: z.number().min(0.5).max(1).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .length(9)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
      .refine(
        items => new Set<string>(items.map(item => item.shape)).size === 3,
        'items must consist of exactly three shapes'
      )
      .refine(
        items => new Set<string>(items.map(item => item.color)).size === 2,
        'items must consist of exactly two colors'
      )
  }),
  simpleGenerator: () => {
    const shapeA = getRandomFromArray(q2And3Shapes);

    const [shapeB, shapeC] = (() => {
      switch (shapeA) {
        case 'circle':
        case 'triangle':
          return getRandomSubArrayFromArray(
            q2And3Shapes.filter(shape => shape !== shapeA),
            2
          );
        case 'rectangle':
        case 'square':
          return shuffle(
            // We need to make sure squares are not a choice when rectangle is the selected shape of the table and vice versa, as squares are rectangles.
            ['circle', 'triangle'] as const
          );
      }
    })()!;

    const colorA = getRandomFromArray(q2And3Colors);

    // Normally we could just use getRandomSubArrayFromArray to assign two colors to colorA and colorB,
    // but colors need to be distinct in this Q, so we can't just pick any two colors at all as some are too similar,
    // e.g. red and pink. The following cases should prevent any similar colours being selected:
    const colorBChoices = (() => {
      switch (colorA) {
        case 'blue':
          return ['green', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'green':
          return ['blue', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'pink':
          return ['blue', 'green', 'yellow'] as const;
        case 'purple':
          return ['blue', 'green', 'red', 'yellow'] as const;
        case 'red':
          return ['blue', 'green', 'purple', 'yellow'] as const;
        case 'yellow':
          return ['blue', 'green', 'pink', 'purple', 'red'] as const;
      }
    })();

    const colorB = getRandomFromArray(colorBChoices);

    const scalingChoices = [
      {},
      { scaleX: 0.5, scaleY: 0.5 },
      { scaleX: 0.6, scaleY: 0.6 },
      { scaleX: 0.7, scaleY: 0.7 },
      { scaleX: 0.8, scaleY: 0.8 },
      { scaleX: 0.9, scaleY: 0.9 }
    ] as const;

    const rectangleRotationChoices = [{}, { rotation: 90 }] as const;

    const scalingAndRotationChoicesRectangle = scalingChoices.flatMap(scale =>
      rectangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const triangleScalingChoices = [
      ...scalingChoices,
      { scaleX: 0.5 },
      { scaleY: 0.5 },
      { scaleX: 0.6 },
      { scaleY: 0.6 },
      { scaleX: 0.7 },
      { scaleY: 0.7 },
      { scaleX: 0.8 },
      { scaleY: 0.8 },
      { scaleX: 0.9 },
      { scaleY: 0.9 }
    ] as const;

    const triangleRotationChoices = [
      ...rectangleRotationChoices,
      { rotation: 180 },
      { rotation: 270 }
    ] as const;

    const scalingAndRotationChoicesTriangle = triangleScalingChoices.flatMap(scale =>
      triangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const shapeAAmount = randomIntegerInclusive(2, 5);

    const shapeAColorAAmount = randomIntegerInclusive(1, Math.min(4, shapeAAmount - 1));
    const shapeAColorBAmount = shapeAAmount - shapeAColorAAmount;

    // shapeBAmount must leave at least 2 for shapeCAmount:
    const shapeBAmount = randomIntegerInclusive(2, 7 - shapeAAmount);

    const shapeBColorAAmount = randomIntegerInclusive(1, shapeBAmount - 1);
    const shapeBColorBAmount = shapeBAmount - shapeBColorAAmount;

    const shapeCAmount = 9 - (shapeAAmount + shapeBAmount);

    const shapeCColorAAmount = randomIntegerInclusive(1, shapeCAmount - 1);
    const shapeCColorBAmount = shapeCAmount - shapeCColorAAmount;

    const [
      shapeAColorAChoices,
      shapeAColorBChoices,
      shapeBColorAChoices,
      shapeBColorBChoices,
      shapeCColorAChoices,
      shapeCColorBChoices
    ] = [
      { count: shapeAColorAAmount, shape: shapeA },
      { count: shapeAColorBAmount, shape: shapeA },
      { count: shapeBColorAAmount, shape: shapeB },
      { count: shapeBColorBAmount, shape: shapeB },
      { count: shapeCColorAAmount, shape: shapeC },
      { count: shapeCColorBAmount, shape: shapeC }
    ].map(({ shape, count }) =>
      getRandomSubArrayFromArray(
        shape === 'circle' || shape === 'square'
          ? scalingChoices
          : shape === 'rectangle'
          ? scalingAndRotationChoicesRectangle
          : scalingAndRotationChoicesTriangle,
        count
      )
    );

    const items = shuffle([
      ...shapeAColorAChoices.map(choice => ({ ...choice, shape: shapeA, color: colorA })),
      ...shapeAColorBChoices.map(choice => ({ ...choice, shape: shapeA, color: colorB })),
      ...shapeBColorAChoices.map(choice => ({ ...choice, shape: shapeB, color: colorA })),
      ...shapeBColorBChoices.map(choice => ({ ...choice, shape: shapeB, color: colorB })),
      ...shapeCColorAChoices.map(choice => ({ ...choice, shape: shapeC, color: colorA })),
      ...shapeCColorBChoices.map(choice => ({ ...choice, shape: shapeC, color: colorB }))
    ]);

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    // The schema's refine checks that these sets are of size 3
    const [shapeA, shapeB, shapeC] = [...new Set(items.map(item => item.shape))];

    const shapeAString = (() => {
      switch (shapeA) {
        case 'circle':
          return translate.shapes.Circles(2);
        case 'rectangle':
          return translate.shapes.Rectangles(2);
        case 'square':
          return translate.shapes.Squares(2);
        case 'triangle':
          return translate.shapes.Triangles(2);
      }
    })();

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToSortTheShapes()}
        zoneNames={[shapeAString, translate.tableHeaders.notX(shapeAString)]}
        testCorrect={[
          items.filter(({ shape }) => shape === shapeA),
          items.filter(({ shape }) => shape === shapeB || shape === shapeC)
        ]}
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView
                key={index}
                style={
                  shape.scaleX || shape.scaleY || shape.rotation
                    ? {
                        transform: [
                          ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                          ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                          ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                        ]
                      }
                    : undefined
                }
              >
                {dimens => (
                  <AssetSvg
                    name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bbT',
  description: 'bbT',
  keywords: ['Sort', '2-D shapes'],
  schema: z.object({
    /* List of objects to show on the right. This must consist of 2 distinct shapes and 2 distinct colors. */
    items: z
      .array(
        z.object({
          shape: z.enum(q2And3Shapes),
          color: z.enum(q2And3Colors),
          scaleX: z.number().min(0.55).max(1).optional(),
          scaleY: z.number().min(0.55).max(1).optional(),
          rotation: z.number().int().min(90).max(270).multipleOf(90).optional()
        })
      )
      .length(9)
      .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
      .refine(
        items => new Set<string>(items.map(item => item.shape)).size === 2,
        'items must consist of exactly two shapes'
      )
      .refine(
        items => new Set<string>(items.map(item => item.color)).size === 2,
        'items must consist of exactly two colors'
      )
  }),
  simpleGenerator: () => {
    const shapeA = getRandomFromArray(q2And3Shapes);

    const shapeB = (() => {
      switch (shapeA) {
        case 'circle':
        case 'triangle':
          return getRandomFromArray(q2And3Shapes.filter(shape => shape !== shapeA));
        case 'square':
          return getRandomFromArray(
            // We need to make sure rectangles are not a choice when square is the selected shape of the table, as squares are rectangles.
            q2And3Shapes.filter(shape => shape !== shapeA && shape !== 'rectangle')
          );
        case 'rectangle':
          return getRandomFromArray(
            // We need to make sure squares are not a choice when rectangle is the selected shape of the table, as squares are rectangles.
            q2And3Shapes.filter(shape => shape !== shapeA && shape !== 'square')
          );
      }
    })()!;

    const colorA = getRandomFromArray(q2And3Colors);

    // Normally we could just use getRandomSubArrayFromArray to assign two colors to colorA and colorB,
    // but colors need to be distinct in this Q, so we can't just pick any two colors at all as some are too similar,
    // e.g. red and pink. The following cases should prevent any similar colours being selected:
    const colorBChoices = (() => {
      switch (colorA) {
        case 'blue':
          return ['green', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'green':
          return ['blue', 'pink', 'purple', 'red', 'yellow'] as const;
        case 'pink':
          return ['blue', 'green', 'yellow'] as const;
        case 'purple':
          return ['blue', 'green', 'red', 'yellow'] as const;
        case 'red':
          return ['blue', 'green', 'purple', 'yellow'] as const;
        case 'yellow':
          return ['blue', 'green', 'pink', 'purple', 'red'] as const;
      }
    })();

    const colorB = getRandomFromArray(colorBChoices);

    const scalingChoices = [
      {},
      { scaleX: 0.55, scaleY: 0.55 },
      { scaleX: 0.6, scaleY: 0.6 },
      { scaleX: 0.65, scaleY: 0.65 },
      { scaleX: 0.7, scaleY: 0.7 },
      { scaleX: 0.75, scaleY: 0.75 },
      { scaleX: 0.8, scaleY: 0.8 },
      { scaleX: 0.85, scaleY: 0.85 },
      { scaleX: 0.9, scaleY: 0.9 },
      { scaleX: 0.95, scaleY: 0.95 }
    ] as const;

    const rectangleRotationChoices = [{}, { rotation: 90 }] as const;

    const scalingAndRotationChoicesRectangle = scalingChoices.flatMap(scale =>
      rectangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const triangleScalingChoices = [
      ...scalingChoices,
      { scaleX: 0.55 },
      { scaleY: 0.55 },
      { scaleX: 0.6 },
      { scaleY: 0.6 },
      { scaleX: 0.65 },
      { scaleY: 0.65 },
      { scaleX: 0.7 },
      { scaleY: 0.7 },
      { scaleX: 0.75 },
      { scaleY: 0.75 },
      { scaleX: 0.8 },
      { scaleY: 0.8 },
      { scaleX: 0.85 },
      { scaleY: 0.85 },
      { scaleX: 0.9 },
      { scaleY: 0.9 },
      { scaleX: 0.95 },
      { scaleY: 0.95 }
    ] as const;

    const triangleRotationChoices = [
      ...rectangleRotationChoices,
      { rotation: 180 },
      { rotation: 270 }
    ] as const;

    const scalingAndRotationChoicesTriangle = triangleScalingChoices.flatMap(scale =>
      triangleRotationChoices.map(rotation => ({ ...scale, ...rotation }))
    );

    const shapeAAmount = randomIntegerInclusive(2, 7);

    const shapeAColorAAmount = randomIntegerInclusive(1, Math.min(4, shapeAAmount - 1));
    const shapeAColorBAmount = shapeAAmount - shapeAColorAAmount;

    const shapeBAmount = 9 - shapeAAmount;

    const shapeBColorAAmount = randomIntegerInclusive(1, shapeBAmount - 1);
    const shapeBColorBAmount = shapeBAmount - shapeBColorAAmount;

    const shapeAColorAChoices = getRandomSubArrayFromArray(
      shapeA === 'circle' || shapeA === 'square'
        ? scalingChoices
        : shapeA === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeAColorAAmount
    );

    const shapeAColorBChoices = getRandomSubArrayFromArray(
      shapeA === 'circle' || shapeA === 'square'
        ? scalingChoices
        : shapeA === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeAColorBAmount
    );

    const shapeBColorAChoices = getRandomSubArrayFromArray(
      shapeB === 'circle' || shapeB === 'square'
        ? scalingChoices
        : shapeB === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeBColorAAmount
    );

    const shapeBColorBChoices = getRandomSubArrayFromArray(
      shapeB === 'circle' || shapeB === 'square'
        ? scalingChoices
        : shapeB === 'rectangle'
        ? scalingAndRotationChoicesRectangle
        : scalingAndRotationChoicesTriangle,
      shapeBColorBAmount
    );

    const items = shuffle([
      ...shapeAColorAChoices.map(choice => ({ ...choice, shape: shapeA, color: colorA })),
      ...shapeAColorBChoices.map(choice => ({ ...choice, shape: shapeA, color: colorB })),
      ...shapeBColorAChoices.map(choice => ({ ...choice, shape: shapeB, color: colorA })),
      ...shapeBColorBChoices.map(choice => ({ ...choice, shape: shapeB, color: colorB }))
    ]);

    return { items };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    // The schema's refine checks that these sets are of size 2
    const [shapeA, shapeB] = [...new Set(items.map(item => item.shape))];
    const [colorA, colorB] = [...new Set(items.map(item => item.color))];

    const correctAnswer = (userAnswer: (typeof items)[number][][]) => {
      return (
        // Sort by shapes:
        (userAnswer[0].every(item => item.shape === shapeA) &&
          userAnswer[1].every(item => item.shape === shapeB)) ||
        (userAnswer[0].every(item => item.shape === shapeB) &&
          userAnswer[1].every(item => item.shape === shapeA)) ||
        // Sort by colors:
        (userAnswer[0].every(item => item.color === colorA) &&
          userAnswer[1].every(item => item.color === colorB)) ||
        (userAnswer[0].every(item => item.color === colorB) &&
          userAnswer[1].every(item => item.color === colorA))
      );
    };

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToSortTheShapes()}
        zoneNames={[translate.tableHeaders.groupNum(1), translate.tableHeaders.groupNum(2)]}
        testCorrect={userAnswer => correctAnswer(userAnswer)}
        items={items.map((shape, index) => {
          return {
            value: shape,
            component: (
              <MeasureView
                key={index}
                style={
                  shape.scaleX || shape.scaleY || shape.rotation
                    ? {
                        transform: [
                          ...(shape.scaleX ? [{ scaleX: shape.scaleX }] : []),
                          ...(shape.scaleY ? [{ scaleY: shape.scaleY }] : []),
                          ...(shape.rotation ? [{ rotate: `${shape.rotation}deg` }] : [])
                        ]
                      }
                    : undefined
                }
              >
                {dimens => (
                  <AssetSvg
                    name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                    width={dimens.width * 0.9}
                    height={dimens.height * 0.9}
                  />
                )}
              </MeasureView>
            )
          };
        })}
        pdfItemVariant="pdfSquare"
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            items.filter(({ shape }) => shape === shapeA),
            items.filter(({ shape }) => shape === shapeB)
          ],
          answerText: translate.markScheme.shapesCanBeSortedByEitherShapeOrColour()
        }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Sort2DShapes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
