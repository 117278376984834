import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  displayMoney,
  moneyScaledSizes,
  moneyStringToPence,
  moneyToHighestDenominations,
  numToCurrency,
  PossibleMoneyDenominations
} from '../../../../utils/money';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  sumNumberArray
} from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import QF16DragMoneyIntoABox from '../../../../components/question/questionFormats/QF16DragMoneyIntoABox';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bii',
  description: 'bii',
  keywords: ['Money', 'Pounds', 'Pence', 'Coins', 'Notes'],
  schema: z.object({
    poundsOrPence: z.enum(['pounds', 'pence']),
    amountToMake: z.number().int().min(1).max(100)
  }),
  simpleGenerator: () => {
    const poundsOrPence = getRandomFromArray(['pounds', 'pence'] as const);

    const amountToMake = randomIntegerInclusive(1, poundsOrPence === 'pounds' ? 100 : 99);

    return { poundsOrPence, amountToMake };
  },

  Component: props => {
    const {
      question: { poundsOrPence, amountToMake },
      translate
    } = props;

    const draggables: PossibleMoneyDenominations[] =
      poundsOrPence === 'pounds' ? [5000, 2000, 1000, 500, 200, 100] : [50, 20, 10, 5, 2, 1];

    return (
      <QF16DragMoneyIntoABox
        title={
          poundsOrPence === 'pounds'
            ? translate.ks1Instructions.dragTheMoneyToMakeNumPounds(amountToMake)
            : translate.ks1Instructions.dragTheMoneyToMakeNumP(amountToMake)
        }
        pdfTitle={
          poundsOrPence === 'pounds'
            ? translate.ks1PDFInstructions.drawMoneyToMakeNumPounds(amountToMake)
            : translate.ks1PDFInstructions.drawMoneyToMakeNumP(amountToMake)
        }
        amountInPenceToMake={poundsOrPence === 'pounds' ? amountToMake * 100 : amountToMake}
        draggablesToShow={draggables}
        questionHeight={1000}
        customMarkSchemeAnswer={
          poundsOrPence === 'pounds'
            ? translate.markScheme.acceptAnyValidDenominationsThatMakeNumPounds(amountToMake)
            : translate.markScheme.acceptAnyValidDenominationsThatMakeNumP(amountToMake)
        }
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bij',
  description: 'bij',
  keywords: ['Money', 'Pounds', 'Pence', 'Coins', 'Notes'],
  schema: z.object({
    correctPounds: z.number().int().min(1).max(99),
    correctPence: z.number().int().min(1).max(99),
    isAnswerShown: z.boolean(),
    items: z.array(
      z.object({
        value: z.enum(['A', 'B', 'C', 'D']),
        combinationOfMoney: z.array(
          z.enum(['1p', '2p', '5p', '10p', '20p', '50p', '£1', '£2', '£5', '£10', '£20', '£50'])
        )
      })
    )
  }),
  simpleGenerator: () => {
    const { items, correctPounds, correctPence } = rejectionSample(
      () => {
        const correctPounds = randomIntegerInclusive(1, 99);
        const correctPence = randomIntegerInclusive(1, 99);

        const correctCombinationOfMoney = [
          ...moneyToHighestDenominations(correctPounds, 'pounds'),
          ...moneyToHighestDenominations(correctPence, 'pence')
        ] as (
          | '1p'
          | '2p'
          | '5p'
          | '10p'
          | '20p'
          | '50p'
          | '£1'
          | '£2'
          | '£5'
          | '£10'
          | '£20'
          | '£50'
        )[];

        const correctItem = {
          value: 'A' as const,
          combinationOfMoney: correctCombinationOfMoney
        };

        const incorrectItemB = {
          value: 'B' as const,
          combinationOfMoney: shuffle(correctCombinationOfMoney)
            .slice(1)
            .sort((a, b) => moneyStringToPence[b] - moneyStringToPence[a])
        };

        const extraCoin = getRandomFromArray(
          correctCombinationOfMoney.filter(
            denom => denom !== '£5' && denom !== '£10' && denom !== '£20' && denom !== '£50'
          )
        )!;

        const incorrectItemC = {
          value: 'C' as const,
          combinationOfMoney: [...correctCombinationOfMoney, extraCoin].sort(
            (a, b) => moneyStringToPence[b] - moneyStringToPence[a]
          )
        };

        const coins = ['1p', '2p', '5p', '10p', '20p', '50p', '£1', '£2'];

        const incorrectCombinationOfMoney = shuffle(correctCombinationOfMoney);

        // Find the first coin after shuffling:
        const indexToReplace = incorrectCombinationOfMoney.findIndex(denom =>
          coins.includes(denom)
        );

        const denominationToReplace = incorrectCombinationOfMoney[indexToReplace];

        // Get a new denomination, ensuring it's not the same as the current one:
        const possibleCoins = coins.filter(denom => denom !== denominationToReplace);

        const newDenomination = getRandomFromArray(possibleCoins) as
          | '1p'
          | '2p'
          | '5p'
          | '10p'
          | '20p'
          | '50p'
          | '£1'
          | '£2';

        // Replace the selected coin with the one:
        incorrectCombinationOfMoney[indexToReplace] = newDenomination;

        const incorrectItemD = {
          value: 'D' as const,
          combinationOfMoney: incorrectCombinationOfMoney.sort(
            (a, b) => moneyStringToPence[b] - moneyStringToPence[a]
          )
        };

        const items = shuffle([correctItem, incorrectItemB, incorrectItemC, incorrectItemD]);

        return { items, correctPounds, correctPence };
      },
      ({ items }) => items.every(item => item.combinationOfMoney.length <= 8)
    );

    const isAnswerShown = getRandomBoolean();

    return {
      correctPounds,
      correctPence,
      isAnswerShown,
      items
    };
  },
  Component: props => {
    const {
      question: { correctPounds, correctPence, isAnswerShown, items },
      translate,
      displayMode
    } = props;

    const flattenedMoneyArray = items.map(item => item.combinationOfMoney).flat();

    // We need to get moneyScales here, so we can use a uniform scale across money in both the items and statements:
    const moneyScales = moneyScaledSizes(flattenedMoneyArray);

    const baseHeight = displayMode === 'digital' ? 72 : 120;

    return (
      <QF11SelectImagesUpTo4
        title={
          isAnswerShown
            ? translate.ks1Instructions.selectThePictureThatShowsPoundXAndYPence(
                correctPounds,
                correctPence
              )
            : translate.ks1Instructions.selectThePicturesThatDoNotShowPoundXAndYPence(
                correctPounds,
                correctPence
              )
        }
        pdfTitle={
          isAnswerShown
            ? translate.ks1PDFInstructions.tickTheBoxThatShowsPoundXAndYPence(
                correctPounds,
                correctPence
              )
            : translate.ks1PDFInstructions.tickTheBoxesThatDoNotShowPoundXAndYPence(
                correctPounds,
                correctPence
              )
        }
        testCorrect={userAnswer =>
          isAnswerShown
            ? userAnswer[0] === 'A'
            : arraysHaveSameContentsUnordered(userAnswer, ['B', 'C', 'D'])
        }
        numItems={4}
        multiSelect={!isAnswerShown}
        customMarkSchemeAnswer={{ answerToDisplay: isAnswerShown ? ['A'] : ['B', 'C', 'D'] }}
        renderItems={items.map(({ value, combinationOfMoney }) => {
          return {
            value,
            component: (
              <View
                style={{
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 12,
                  padding: 8
                }}
              >
                {combinationOfMoney.map(denom =>
                  displayMoney(
                    [denom],
                    baseHeight * moneyScales[denom],
                    baseHeight * moneyScales[denom]
                  )
                )}
              </View>
            )
          };
        })}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bik',
  description: 'bik',
  keywords: ['Notes', 'Coins', 'Pounds', 'Pence', 'Money', 'Total'],
  schema: z.object({
    pounds: z
      .number()
      .int()
      .min(5)
      .max(38)
      .refine(pounds => pounds % 10 !== 4 && pounds % 10 !== 9),
    pence: z
      .number()
      .int()
      .min(5)
      .max(85)
      .step(5)
      .refine(pounds => Math.floor(pounds / 10) !== 4),
    moneyObjects: z.array(
      z.object({
        currency: z.enum(['5p', '10p', '20p', '50p', '£1', '£2', '£5', '£10', '£20']),
        value: numberEnum([5, 10, 20, 50, 100, 200, 500, 1000, 2000])
      })
    )
  }),
  simpleGenerator: () => {
    const { pounds, pence } = rejectionSample(
      () => {
        const pounds = randomIntegerInclusive(5, 38, {
          constraint: x => x % 10 !== 4 && x % 10 !== 9
        });

        const pence = randomIntegerInclusiveStep(5, 85, 5, {
          constraint: x => Math.floor(x / 10) !== 4
        });
        return { pounds, pence };
      },

      ({ pounds, pence }) =>
        arrayHasNoDuplicates(moneyToHighestDenominations(pounds, 'pounds')) &&
        arrayHasNoDuplicates(moneyToHighestDenominations(pence, 'pence'))
    );

    const moneyObjects = shuffle([
      { currency: '10p' as const, value: 10 as const },
      { currency: '£1' as const, value: 100 as const },
      { currency: '£10' as const, value: 1000 as const },
      { currency: '20p' as const, value: 20 as const },
      { currency: '£2' as const, value: 200 as const },
      { currency: '£20' as const, value: 2000 as const },
      { currency: '5p' as const, value: 5 as const },
      { currency: '50p' as const, value: 50 as const },
      { currency: '£5' as const, value: 500 as const }
    ]);

    return {
      pounds,
      pence,
      moneyObjects
    };
  },

  Component: props => {
    const {
      question: { pounds, pence, moneyObjects },
      translate,
      locale
    } = props;

    const items = moneyObjects.map(({ value, currency }) => {
      return {
        value: value,
        component: displayMoney([currency], value > 200 ? 170 : 80, 80)[0],
        currency
      };
    });

    const moneyString = numToCurrency({ amount: pounds + pence / 100, locale });

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectNotesAndCoinsToMakeXPoundsAndYPence(pounds, pence)}
        pdfTitle={translate.ks1PDFInstructions.circleXPoundsAndYPence(pounds, pence)}
        testCorrect={userAnswer =>
          moneyString ===
          numToCurrency({
            amount: sumNumberArray(userAnswer.map(num => Number(num))) / 100,
            locale
          })
        }
        multiSelect
        items={items.map(selectable => ({
          value: selectable.value,
          component: selectable.component as JSX.Element
        }))}
        customMarkSchemeAnswer={{
          answerToDisplay: [
            ...moneyToHighestDenominations(pence, 'pence'),
            ...moneyToHighestDenominations(pounds, 'pounds')
          ].flatMap(currency => {
            const moneyObject = moneyObjects.find(obj => obj.currency === currency);
            if (!moneyObject) {
              console.error("moneyToHighestDenominations produced a value that wasn't present");
              return [];
            }
            return [moneyObject.value];
          })
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'ChooseNotesAndCoins',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
