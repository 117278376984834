import { ADD, DEGREES, DIV, MULT, SUB } from '../../constants';

export default {
  aIsAnswerThanB: '{0} is <ans/> {1}',
  aToBIsTranslationOfAnsXAnsY:
    '{0} to {1} is a translation <ans/> square{{2:s}} {3} and <ans/> square{{4:s}} {5}.',
  aToBIsTranslationOfXAnsYAns:
    'A to B is a translation {0:number|numberFormat} square{{s}} <ans/> and {1:number|numberFormat} square{{s}} <ans/>.',
  allSidesAndAnglesEqual: 'All sides and angles equal',
  anEquilateralTriangleCanHaveRA: 'An equilateral triangle can have a right angle.',
  anEquilateralTriangleCanHaveThreeAcuteAngles:
    'An equilateral triangle can have three acute angles.',
  anEquilateralTriangleCanHaveThreeObtuseAngles:
    'An equilateral triangle can have three obtuse angles.',
  anglesAroundAPointSumTo360: 'Angles around a point sum to 360°',
  anglesInTriangleSumTo180: 'Angles in a triangle sum to 180°',
  anglesOnStraightLineSumTo180: 'Angles on a straight line sum to 180°',
  anIsosTriangleCanHaveTwoAcuteAngles: 'An isosceles triangle can have two acute angles.',
  anIsosTriangleCanHaveTwoObtuseAngles: 'An isosceles triangle can have two obtuse angles.',
  aPoundsAndBPenceSubtractXPoundsAndYPence: `£{poundsA:number|numberFormat} and {penceA:number|numberFormat}p ${SUB} £{poundsB:number|numberFormat} and {penceB:number|numberFormat}p = £<ans/> and <ans/>p`,
  aRATriangleCanBeEquilateral: 'A right-angled triangle can be equilateral.',
  aRATriangleCanBeIsos: 'A right-angled triangle can be isosceles.',
  aToB: 'A to B',
  anAcuteAngleIsAnsThanAnsTurn: 'An acute angle is <ans/> than a <ans/> turn.',
  anObtuseAngleIsAnsThanAnsButAnsThanAnsTurn:
    'An obtuse angle is <ans/> than a <ans/> turn, but <ans/> than a <ans/> turn.',
  aRightAngleIsTheSameAsAnsTurn: 'A right angle is the same as a <ans/> turn.',
  adaptCharactersMethodToWorkOut: "Adapt {0}'s method to work out",
  addXAndSubtractY: 'Add {0} and subtract {1}',
  aFullTurnIsAnsRightAngles: 'A full turn is equal to <ans/> right angles.',
  aHalfTurnIsAnsRightAngles: 'A half turn is equal to <ans/> right angles.',
  allEvenNumbers: 'All even numbers',
  andAnsSquares: 'and <ans/> square{{0:s}}',
  ansAndAnsAreCommonMultiplesOfXandY: `<ans/> and <ans/> are common multiples of {0} and {1}`,
  ansAndAnsAreFactorsOfNum: '<ans/> and <ans/> are factors of {0}',
  ansAnsPm: '<ans/> : <ans/> pm',
  ansAnsAndAnsAreCommonMultiplesOfXandY: `<ans/> <ans/> and <ans/> are common multiples of {0} and {1}`,
  ansBalesOfHay: '<ans/> bales of hay',
  ansBoxesOfNumber: '<ans/> boxes of {0:number|numberFormat}',
  ansCDegrees: '<ans/> °C',
  ansCentimetresIsEqualTo1Metre: '<ans/> centimetres is equal to 1 metre.',
  ansCm: '<ans/> cm',
  ansCmCubed: '<ans/> cm³',
  ansCmPlusAnsCmPlusAnsCmPlusAnsCmEqualsAns: `<ans/>cm ${ADD} <ans/>cm ${ADD} <ans/>cm ${ADD} <ans/>cm = <ans/>cm`,
  ansCmSquared: '<ans/> cm²',
  ansCmAnsMm: '<ans/> cm <ans/> mm',
  ansCmAndAnsMm: '<ans/> cm and <ans/> mm',
  ansCmEqualsNumMAndNumCm: '<ans/> cm = {0:number|numberFormat} m and {1:number|numberFormat} cm',
  ansCubes: '<ans/> cubes',
  ansDays: '<ans/> days',
  ansDaysEqualsXHours: '<ans/> days = {0:number|numberFormat} hours',
  ansDeg: '<ans/> °',
  ansDegrees: '<ans/> degrees',
  ansDegC: '<ans/> °C',
  ansDivBy10Equals: '<ans/> ÷ 10 = {0}',
  ansEdges: '<ans/> edges',
  ansEggs: '<ans/> eggs',
  ansEqualsXHundreds: '<ans /> = {0:string|lower} hundreds',
  ansEqualsXPercentOfY: '<ans/> = {0:number|numberFormat}% of {1:number|numberFormat}',
  ansEqualsFracOfNum: `<ans/> = {0} of {1:number|numberFormat}`,
  ansFaces: '<ans/> faces',
  ansG: '<ans/> g',
  ansGPlusNumGEqualsNumKg: '<ans/> g + {0} g = {1} kg',
  ansGPlusNumKgEqualsNumKg: '<ans/> g + {0} kg = {1} kg',
  ansHours: '<ans/> hours',
  ansHoursAndAnsMinutes: '<ans/> hour{{0:s}} and <ans/> minutes',
  ansIsAmountOfSpaceAns2DShape: '<ans/> is the amount of space <ans/> a two-dimensional shape.',
  ansIsAmountOfSpaceInside2DCanBeMeasuredAns:
    '<ans/> is the amount of space inside a shape. It can be measured in units such as <ans/>',
  ansIsDistanceAns2DShape: '<ans/> is the distance <ans/> a two-dimensional shape.',
  ansIsDistanceAnsAround2DCanBeMeasuredAns:
    '<ans/> is the distance around a shape. It can be measured in units such as <ans/>',
  ansIsEquivalentToX: '<ans/> is equivalent to {0}',
  ansJump: '<ans/> jump',
  ansJumps: '<ans/> jumps',
  ansKg: '<ans/> kg',
  ansKgG: '<ans/> kg <ans/> g',
  ansKgAndG: '<ans/> kg and <ans/> g',
  ansKm: '<ans/> km',
  ansKmAnsM: '<ans/> km <ans/> m',
  ansL: '<ans/> l',
  ansLAndAnsMl: '<ans/> l and <ans/> ml',
  ansLAnsMl: '<ans/> l <ans/> ml',
  ansMAndAnsCm: '<ans/> m and <ans/> cm',
  ansMiles: '<ans/> miles',
  ansMillimetresIsEqualTo1Centimetres: '<ans/> millimetres is equal to 1 centimetre.',
  ansMinutesAndSeconds: '<ans/> minute{{0:s}} <ans/> second{{1:s}}',
  ansMinutesToYInTheAfternoon: '<ans /> minute{{0:s}} to <ans /> in the afternoon',
  ansMinutesToYInTheMorning: '<ans /> minute{{0:s}} to <ans /> in the morning',
  ansMinutesPastYInTheAfternoon: '<ans /> minute{{0:s}} past <ans /> in the afternoon',
  ansMinutesPastYInTheMorning: '<ans /> minute{{0:s}} past <ans /> in the morning',
  ansMl: '<ans/> ml',
  ansOneTenthSizeOfX: '<ans /> is one-tenth the size of {0}',
  ansOnesAnsTenthsAnsHundredthsAnsThousandths:
    '<ans/> ones <ans/> tenths <ans/> hundredths and <ans/> thousandths. The number is <ans/>',
  ansInches: '<ans/> inches',
  ansIsACommonFactorOfNumAndNum: '<ans/> is a common factor of {0} and {1}',
  ansIsACommonMultiplesOfXandY: `<ans/> is a common multiple of {0} and {1}`,
  ansIsASquareNumber: '<ans/> is a square number.',
  ansIsXTimesTheSizeOfY: '<ans/> is {0} times the size of {1}',
  ansLAndAndMl: '<ans/> l <ans/> ml',
  ansLitres: '<ans/> litres',
  ansMinutes: '<ans/> minutes',
  ansMinutesPastHours: '<ans/> minutes past <ans/>',
  ansMinutesToHours: '<ans/> minutes to <ans/>',
  ansM: '<ans/> m',
  ansMSquared: '<ans/> m²',
  ansMm: '<ans/> mm',
  ansMmSquared: '<ans/> mm²',
  ansMmEqualsNumCmAndNumMm: '<ans/> mm = {0:number|numberFormat} cm and {1:number|numberFormat} mm',
  ansMonths: '<ans/> months',
  ansMultAnsEqualsAnsObjects: `<ans/> ${MULT} <ans/> = <ans/> {0:string|lower}`,
  ansObject: '<ans/> {0:string|lower}',
  ansP: '<ans/>p',
  ansParcels: '<ans/> parcels',
  ansPeople: '<ans/> people',
  ansPercentOfHundredSquareIsNotShaded: '<ans/>% of the hundred square is <b>not</b> shaded.',
  ansPercentOfHundredSquareIsShaded: '<ans/>% of the hundred square is shaded.',
  ansPercentOfXEqualsY: '<ans/> % of {0:number|numberFormat} = {1:number|numberFormat}',
  ansPortions: '<ans/> portions',
  ansSeconds: '<ans/> seconds',
  ansSquares: '<ans/> squares',
  ansTeamsHaveVarPlayers: '<ans/> teams have {0} players.',
  ansTenthsAnsHundrethsEquivalentToX: '<ans/> tenths and <ans/> hundredths is equivalent to {0}.',
  ansTimes10Equals: `<ans/> ${MULT} 10 = {0}`,
  ansTimes180EqualsAns: `<ans/> ${MULT} 180 = <ans/>`,
  ansUnitOrAnsUnit: '<ans/> {0} or <ans/> {1}',
  ansVertices: '<ans/> vertices',
  ansWeeksEqualsXDays: '<ans/> weeks = {0:number|numberFormat} days',
  ansWeeksHaveNumDays: '<ans/> weeks have {0:number|numberFormat} days.',
  ansYearsAnsMonths: '<ans/> years <ans/> months',
  ansYellowBalloons: '<ans/> yellow balloons',
  answerIsGreaterThanNum: 'Answer is greater than {0:number|numberFormat}',
  answerIsLessThanNum: 'Answer is less than {0:number|numberFormat}',
  ansXOfChildrenChoseYAsFavouriteFruit:
    '<ans/> {0: string|lower} of the children chose {1:string|lower} as their favourite fruit.',
  ansWeeksAnsDays: '<ans/> weeks and <ans/> days',
  approximatelyAnsGallons: 'Approximately <ans/> gallons',
  approximatelyAnsLb: 'Approximately <ans/> lb',
  approximatelyAnsPints: 'Approximately <ans/> pints',
  approximatelyAnsStoneAnsLb: 'Approximately <ans/> stone <ans/> lb',
  aQuarterTurnIsAnsRightAngles: 'A quarter turn is equal to <ans/> right angle.',
  areaAnsCm2: 'Area = <ans/> cm²',
  areaCanBeMeasuredInUnitsAnsAns: 'Area can be measured in units such as <ans/> and <ans/>',
  areaEqualsAnsCm2: 'Area = <ans/> cm²',
  areaEqualsAnsM2: 'Area = <ans/> m²',
  areaEqualsAnsMm2: 'Area = <ans/> mm²',
  areaEqualsNumM2: 'area = {0:number|numberFormat} m²',
  areaEqualsHalfAnsMultAns: `area = <frac n='1' d= '2'/> ${MULT} <ans/> ${MULT} <ans/>`,
  areaIsAnsCm2: 'The area is <ans/> cm²',
  areaIsAnsSquares: 'Area = <ans/> squares.',
  areaOfCompoundShapeIsAnsY: 'The area of the compound shape is <ans/> {0}.',
  areaOfXIsAnsY: 'The area of {0} is <ans/> {1}.',
  aRegularShapeWithASideLengthOfX:
    'A regular {0} with a side length of <g>{1:number|numberFormat} cm</g>',
  aShopHasNumXOfY1: 'A shop has {0} {1:string|lower}.',
  aShopHasNumXOfY2: '{0} {1:string|lower} are sold.',
  aShopHasNumXOfY3: '{0} new {1:string|lower} arrive.',
  aShopHasNumXOfY4: 'How many {0:string|lower} are in the shop now?',
  aSquareWithASideLengthOfX: 'A square with a side length of <g>{0:number|numberFormat} cm</g>',
  aThreeQuarterTurnIsAnsRightAngles: 'A three quarter turn is equal to <ans/> right angles.',
  xToYIsATranslationAnsSquaresDirectionZ: '{0} to {1} is a translation <ans/> square{{2:s}} {3}.',
  xToYIsATranslationAnsXSquaresDirectionXAndAnsYSquaresDirectionY:
    '{0} to {1} is a translation <ans/> square{{2:s}} {3} and <ans/> square{{4:s}} {5}.',
  xToYIsATranslationXSquaresDirectionAnsAndYSquaresDirectionAns:
    '{0} to {1} is a translation {2} square{{s}} <ans/> and {3} square{{s}} <ans/>',
  bToA: 'B to A',
  barBIsAnsTimesTheSizeOfTheBarA: 'Bar B is <ans/> times the size of bar A.',
  calcEqualsAnsRemainderAns: '{0} = <ans/> r <ans/>',
  char1IsXFootYInchesTallChar2IsZcmTallWhosIsShorter:
    '{0} is {1:number|numberFormat} feet {2:number|numberFormat} inches tall.<br/>{3} is {4:number|numberFormat}cm tall.<br/>Who is shorter?',
  char1IsXFootYInchesTallChar2IsZcmTallWhosIsTaller:
    '{0} is {1:number|numberFormat} feet {2:number|numberFormat} inches tall.<br/>{3} is {4:number|numberFormat}cm tall.<br/>Who is taller?',
  characterAddsXToNumber:
    '{0} adds {1:number|numberFormat} to the number.<br/>Complete the addition that {0} has done.',
  characterBuysXYZItems: '{name} buys {itemA}, {itemB} and {itemC}.',
  characterChoosesFourOfTheCoins: '{0} chooses four of the coins.',
  characterDoesNumSitups: '{0} does <ans/> sit-ups.',
  characterHasAnsObjectAltogether: '{0} has <ans/> {1:string|lower} altogether.',
  characterHasNumApples: '{name} has {numObj} apples.',
  characterHasNumBags: '{name} has {numGroups} bags.',
  characterHasNumBoxes: '{name} has {numGroups} boxes.',
  characterHasNumCookies: '{name} has {numObj} cookies.',
  characterHasNumEggs: '{name} has {numObj} eggs.',
  characterHasNumFlowers: '{name} has {numObj} flowers.',
  characterHasNumPencils: '{name} has {numObj} pencils.',
  characterHasNumPlates: '{name} has {numGroups} plates.',
  characterHasNumPots: '{name} has {numGroups} pots.',
  characterHasNumVases: '{name} has {numGroups} vases.',
  characterHasXPoundsAndYPence: '{0} has £{1:number|numberFormat} and {2:number|numberFormat}p.',
  characterHasXOfYAAndOfBWhatIsMaxNumberOfZCharacterCanMake:
    '{character} has {foodAmount1} of {food1: string|lower} and {foodAmount2} of {food2: string|lower}.<br/>What is the maximum number of {food4: string|lower} {character} can make?',
  characterHasXOfYAOfBAndCofDWhatIsMaxNumberOfZCharacterCanMake:
    '{character} has {foodAmount1} of {food1: string|lower}, {foodAmount2} of {food2: string|lower}, and {foodAmount3} of {food3: string|lower}.<br/>What is the maximum number of {food4: string|lower} {character} can make?',
  characterHasThisMoney: '{name} has this money.',
  characterHasTotalDrink: '{0} has {1} litres of {2} altogether.',
  characterPaysWithAFiftyPoundNote: '{name} pays with a £50 note.',
  characterPutsInBags: '{name} puts them in {numGroups} bags.',
  characterPutsInBoxes: '{name} puts them in {numGroups} boxes.',
  characterPutsInPots: '{name} puts them in {numGroups} pots.',
  characterPutsInVases: '{name} puts them in {numGroups} vases.',
  characterPutsOnPlates: '{name} puts them on {numGroups} plates.',
  characterPutsNumApples: '{name} puts {numObjPerGroup} apples in each bag.',
  characterPutsNumCookies: '{name} puts {numObjPerGroup} cookies on each plate.',
  characterPutsNumEggs: '{name} puts {numObjPerGroup} eggs in each box.',
  characterPutsNumFlowers: '{name} puts {numObjPerGroup} flowers in each vase.',
  characterPutsNumPencils: '{name} puts {numObjPerGroup} pencils in each pot.',
  characterHasNumApplesAndSomeBags: '{name} has {numObj} apples and some bags.',
  characterHasNumCookiesAndSomePlates: '{name} has {numObj} cookies and some plates.',
  characterHasNumEggsAndSomeBoxes: '{name} has {numObj} eggs and some boxes.',
  characterHasNumFlowersAndSomeVases: '{name} has {numObj} flowers and some vases.',
  characterHasNumObjectCharacterPutsNumObjectInEachContainer:
    '{name} has {totalObject:number|numberFormat} {objectPlural:string|lower}.<br/>{name} puts {objectPerContainer:number|numberFormat} {preposition:string|lower} {objectPlural:string|lower} each {containerSingular:string|lower}.<br/>{name} needs <ans/> {containerPlural:string|lower} altogether.',
  characterHasNumPencilsAndSomePots: '{name} has {numObj} pencils and some pots.',
  characterHasNumStarsAndNumHearts:
    '{0} has {1:number|numberFormat} stars and {2:number|numberFormat} hearts.',
  characterHasXPenceHowManySweetsCanCharacterBuy:
    '{name} has {pence}p.<br/>How many sweets can {name} buy?<br/><ans/>',
  characterIsAnsInchesTall: '{0} is <ans/> inches tall.',
  characterIsApproximatelyAnsCentimetresTall: '{0} is approximately <ans/> centimetres tall.',
  characterIsAtSchoolFor6XADay: '{0} is at school for 6 ________ a day.',
  characterIsGivenAnotherXPence: '{name} is given another {pence}p.',
  charactersFractionIs: "{name}'s fraction is {frac}",
  characterSewsNumStarsAndNumHearts:
    '{0} sews {1:number|numberFormat} stars and {2:number|numberFormat} hearts onto each flag.',
  characterSleepsFor8XEachNight: '{0} sleeps for 8 ________ each night.',
  characterSpendsPoundAnsAndPenceAns: '{0} spends £<ans/> and <ans/> p',
  characterSpends20XOnTheirHomework: '{0} spends 20 ________ on their homework.',
  characterSpendsXPence: '{name} spends {pence}p.',
  characterScoresXOutOfY:
    '{0} scores <g>{1:number|numberFormat} out of {2:number|numberFormat}</g>',
  characterStartsWithTheNumberX1: '{0} starts with the number {1}',
  characterStartsWithTheNumberX2: '{0} adds {1}',
  characterStartsWithTheNumberX3: '{0} subtracts {1}',
  characterStartsWithTheNumberX4: '{0} adds {1}',
  characterStartsWithTheNumberX5: 'What number does {0} end with?',
  characterSubtractsXFromNumber:
    '{0} subtracts {1:number|numberFormat} from the number.<br/>Complete the subtraction that {0} has done.',
  characterSubtractsXOnesFromNumber:
    '{0} subtracts {1:number|numberFormat} ones from the number.<br/>Complete the subtraction that {0} has done.',
  characterTakes5XToPaintTheirBedroom: '{0} takes 5 ________ to paint their bedroom.',
  characterTakes10XToWriteTheirName: '{0} takes 10 ________ to write their name.',
  characterWatchesTVFor30XADay: '{0} watches TV for 30 ________ a day.',
  completeTheImproperFractionAndIntegerShownByTheCounters:
    'Complete the improper fraction and integer shown by the counters.',
  completeTheImproperFractionAndMixedNumberShownByTheCounters:
    'Complete the improper fraction and mixed number shown by the counters.',
  circleTheCorrectAnswer: 'Circle the correct answer.',
  cost: `{0} cost`,
  costOfXis: `The cost of {0: string|lower} is <ans/>p`,
  completeDivision: 'Complete the division.',
  colourFractionAns: `{0} <frac nAns='' dAns='' />`,
  coordAnsAndcoordAns: '(<ans/>, <ans/>) and (<ans/>, <ans/>)',
  createEquationToCalcPerimeter: 'Create an equation to calculate the perimeter.',
  cubeHasAnsSquareFaces: 'A cube has <ans/> square faces.',
  daysIn1Week: 'Days in 1 week',
  daysInXWeeks: 'Days in {0} weeks',
  decimalEqualsAns: 'decimal = <ans/>',
  decimalAns: 'Decimal <ans/>',
  differenceBetweenXandYisAns: 'The difference between {0} and {1} is <ans/>',
  divideByX: 'Divide by {0}',
  double: 'Double',
  doubleNumThenDoubleItAgain: 'Double {0:number|numberFormat}, then double it again',
  doubleNumThenDoubleItAgainAndAgain:
    'Double {0:number|numberFormat}, then double it again and again',
  doubleX: 'double {0}',
  eachEqualPartIsWorth: `Each equal part is worth <frac n='1' dAns='' />`,
  eachGroupHasAnsObjects: 'Each {0:string|lower} has <ans/> {1:string|lower}.',
  eachNewspaperNeedNumSheets: 'Each newspaper needs {0:number|numberFormat} sheets of paper.',
  eachTableSeatsNumPeople: 'Each table seats {0:number|numberFormat} people.',
  eggBoxContains12Eggs: 'An egg box contains 12 eggs.',
  endsIn5Or0: 'Ends in 5 or 0',
  evenNumber: 'Even Number',
  everyChairHasFourLegs: 'Every chair has 4 chair legs.',
  everyEquilateralTriangleIsIsos: 'Every equilateral triangle is isosceles.',
  everyIsosTriangleIsEquilateral: 'Every isosceles triangle is equilateral.',
  everyTableHasNumChairs: 'Every table has {0} chairs.',
  everyoneIsInvitedToASpecialMeal: 'Everyone is invited to a special meal.',
  factorsOfNum: 'Factors of {0}:',
  footballTeamHas11Players: 'A football team has 11 players.',
  feetAndInches: '{0} feet and {1} inches',
  findValueOfX: 'Find the value of {0}.',
  forEveryAnsColor1CubesThereAreAnsColor1Cubes:
    'For every <ans/> {0:string|lower} cubes, there are <ans/> {1:string|lower} cubes.',
  forEveryAnsXThereAreAnsY: 'For every <ans/> {0:string|lower}, there are <ans/> {1:string|lower}.',
  forEveryXAnsThereAreYAns:
    'For every {0:number|numberFormat} <ans/>, there are {1:number|numberFormat} <ans/>',
  forEveryXAnsThereIsYAns:
    'For every {0:number|numberFormat} <ans/>, there is {1:number|numberFormat} <ans/>',
  fracAnsEquivalentToFrac: '{0} <ans/> equivalent to {1}',
  fracIsAnsFrac: '{0} is <ans/> {1}',
  fracIsEquivalentTo: `<frac n='{0}' d='{1}' /> is equivalent to <frac nAns='' d='{2}' />`,
  fracEqualsAnsEqualsAnsPercent: `<frac n='{0:number|numberFormat}' d='{1:number|numberFormat}' /> = <ans /> = <ans />%`,
  fracEqualsOverX: `fraction = <frac nAns='' d='{0}' />`,
  fracKgEqualsAnsG: '{0} kg = <ans/> g',
  fracKmEqualsAnsM: '{0} km = <ans/> m',
  fracOfXNumberIsEqualToAns: '{frac} of {number:number|numberFormat} is equal to <ans/>',
  fracOfAnsEqualsNum: `{0} of <ans/> = {1:number|numberFormat}`,
  fracOfNumEqualsAns: `{0} of {1:number|numberFormat} = <ans/>`,
  fracOfNumCmEqualsAns: `{0} of {1:number|numberFormat} cm = <ans/>`,
  fracOfNumKgEqualsAns: `{0} of {1:number|numberFormat} kg = <ans/>`,
  fracOfNumMEqualsAns: `{0} of {1:number|numberFormat} m = <ans/>`,
  fracOfNumMlEqualsAns: `{0} of {1:number|numberFormat} ml = <ans/>`,
  fractionMoreThanCharactersFractionIsAWhole: "{frac} more than {name}'s fraction is 1 whole.",
  fractionOfColour: 'The fraction of {0:string|lower} cubes is {1}',
  fractionfXtoYCountersIs: `The fraction of counters that are {0:string|lower} is <frac nAns='' dAns='' />`,
  fractionOfCountersInTheBag: '{frac} of the counters in the bag are {color:string|lower}.',
  fractionOfCountersAreX: '{frac} of the counters are {color:string|lower}.',
  fractionOverX: `Fraction <frac nAns='' d='{0}' />`,
  fractionOfNumberEqualsAns: '{frac} of {number:number|numberFormat} = <ans/>',
  fractionOfPoundsEqualsAns: '{frac} of £{number:number|numberFormat} = £<ans/>',
  fractionOfMetresEqualsAns: '{frac} of {number:number|numberFormat} m = <ans/> m',
  fractionOfKilometresEqualsAns:
    '{frac} of {number:number|numberFormat} kg = {answer:number|numberFormat} kg',
  fractionShadedIsAns: `The fraction shaded is <frac nAns='' dAns='' />`,
  fromTimeToTimeIsAnsMins: 'From {0} to {1} is <ans/> minutes.',
  giveXEquivalentFractionsToFraction: 'Give {0:number|numberFormat} equivalent fractions to {1}',
  greaterThanAngleDegrees: 'greater than {0:number|numberFormat}°',
  greaterThan90DegreesButLessThan180Degrees: 'greater than 90° but less than 180°',
  greaterThanXMultY: `Greater than {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  fractionOfShapeIsShaded: '{frac} of the shape is shaded.',
  fractionOfShapeNeedsShadingToCompleteWhole: '{frac} needs to be shaded to complete the whole.',
  halfSquaresAns: 'Half squares <ans/>',
  halfwayBetween: 'halfway between {0} and {1}',
  halve: 'Halve',
  halveAndAnswerEven: 'Halve and the answer is even',
  halveNumThenHalveItAgain: 'Halve {0:number|numberFormat}, then halve it again',
  halveNumThenHalveItAgainAndAgain: 'Halve {0:number|numberFormat}, then halve it again and again',
  hereIsXOfYsRibbon: "Here is {0} of {1}'s ribbon.",
  howFarDidTheBusesTravelInTotal: 'How far did the buses travel in total? <ans/> miles',
  howLongDidCharSpendAtPark: 'How long did {0} spend at the park?',
  howManyApplesAreInEach: 'How many apples are in each bag? <ans/>',
  howManyBagsAreThere: 'How many bags are there? <ans/>',
  howManyBoxesAreThere: 'How many boxes are there? <ans/>',
  howManyChildrenHaveABrotherOrSister: 'How many children have a brother or sister? <ans/>',
  howManyChildrenDoNotHaveABrotherOrSister:
    'How many children do not have a brother or sister? <ans/>',
  howManyCmAreThereInNumM: 'How many centimetres are there in {0} m? <ans/>',
  howManyCookiesAreInEach: 'How many cookies are on each plate? <ans/>',
  howManyEggsAreInEach: 'How many eggs are in each box? <ans/>',
  howManyEmptySeatsOnFinalTable:
    'How many empty seats will there be on<br/>the final table? <ans/>',
  howManyFlagsCanCharacterComplete: 'How many flags can {0} complete?',
  howManyFlowersAreInEach: 'How many flowers are in each vase? <ans/>',
  howManyGramsAreThereInNumKg: 'How many grams are there in {0} kg? <ans/>',
  howManyGroupsOfNumAreInArray: 'How many groups of {0} are in the array? <ans/>',
  howManyHundredthsAreShaded: 'How many hundredths are shaded? <ans/>',
  howManyTenthsAreShaded: 'How many tenths are shaded? <ans/>',
  howManyNewspapersCanMsSmithPrint:
    'How many newspapers can Ms Smith print with {0:number|numberFormat} packets of paper?',
  howManyPagesDoesCharHaveLeftAns: 'How many pages does {0} have left to read? <ans/>',
  howManyPagesHasCharReadAns: 'How many pages has {0} read? <ans/>',
  howManyPencilsAreInEach: 'How many pencils are in each pot? <ans/>',
  howManyPlatesAreThere: 'How many plates are there? <ans/>',
  howManyPortionsCanBeMadeFromNumKgOfPasta:
    'How many portions can be made from a {0:number|numberFormat} kg bag of pasta?',
  howManyPotsAreThere: 'How many pots are there? <ans/>',
  howManySlicesAreThere: 'How many slices are there? <ans/>',
  howManyStampsAreThereOnNumSheets:
    'How many stamps are there altogether on {0:number|numberFormat} sheets?',
  howManySquaresAreNotShaded: 'How many squares are NOT shaded? <ans/>',
  howManySweetsCanCharacterBuy: 'How many sweets can {name} buy?',
  howManyTablesAreNeeded: 'How many tables are needed?',
  howManyTablesAreNeededWithAnsBox: 'How many tables are needed to<br/>seat everyone? <ans/>',
  howManyVasesAreThere: 'How many vases are there? <ans/>',
  howManyXWillBeInTheNextDiagram:
    'How many {0:string|lower} will there be in the next diagram? <ans/>',
  HowMuchChangeDoesCharacterGet: 'How much change does {name} get?',
  howMuchDidCharacterSpend: 'How much did {0} spend? <ans />p',
  howMuchMoneyCouldCharacterHave: 'How much money could {0} have?',
  howMuchMoneyDoesCharacterHaveLeft: 'How much money does {name} have left?',
  howMuchMoneyDoesCharacterHaveLeftAns: 'How much money does {name} have left?',
  howMuchMoneyDoesCharacterHaveNow: 'How much money does {name} have now?',
  howMuchMoneyDoesNameHaveLeft: 'How much money does {0} have left? £<ans/>',
  howMuchMoneyDoTheyHaveAltogether: 'How much money do they have altogether?',
  howMuchMoneyDoTheyHaveAltogetherPoundAnsAndAnsP:
    'How much money do they have altogether?<br/>£<ans/> and <ans/> p',
  howMuchMoneyDoTheyHaveInTotal: 'How much money do they have in total? £<ans/>',
  howMuchMoreDoesXItemOfYItemCostThanZItem:
    'How much more does the {0:string|lower} of {1:string|lower} cost than the {0:string|lower} of {2:string|lower}?',
  howMuchPastaIsNeededForNumPortions:
    'How much pasta is needed for {0:number|numberFormat} portions?',
  howMuchRiceIsThereInEachBowl: 'How much rice is there in each bowl?',
  howMuchWarmerIsTempInXThanInY: 'How much warmer is the temperature in {0} than in {1}?',
  hundredCentimetresIsEqualToAnsMetre: '100 centimetres is equal to <ans/> metre.',
  hundredsTensOnesAns:
    'There are <ans/> hundreds, <ans/> tens and <ans/> ones.<br/>The number is <ans/>',
  iAmFacingXITurnYToFaceZ:
    'I am facing {0:string|lower}. I turn {1:string|lower} to face {2:string|lower}.',
  iDidItInNumSeconds: 'I did it in {0:number|numberFormat} seconds.',
  iDidItInNumMinutesAndNumSeconds:
    'I did it in {0:number|numberFormat} minutes and {1:number|numberFormat} seconds.',
  iDidItNumSecondsFasterThanChar: 'I did it {0:number|numberFormat} seconds faster than {1}.',
  iGotToTheParkAtTimeAndLeftAtTime: 'I got to the park at {0}:{1} pm and left at {2}:{3} pm.',
  iHaveXOfYCounters: 'I have {0} of <g>{1} counters.</g>',
  iHaveDrawnAnIsoscelesTriangleSoTheRemainingLengthWillBeXCm:
    'I have drawn an isosceles triangle so the remaining length should be <g>{0} cm.</g>',
  iHaveDrawnRightAngledTriangleLongestSideIsXcm:
    'I have drawn a right-angled triangle. The longest side is <g>{0} cm.</g>',
  improperFracAns: `Improper <frac nAns='' dAns=''/>`,
  inIsosTriangleTwoAnglesAreEqual: 'In an isosceles triangle, two angles are equal',
  inputEqualsAns: 'Input = <ans/>',
  integerAns: 'Integer <ans/>',
  isATranslationOfAnsSquare: 'is a translation of <ans/> square',
  isATranslationOfAnsSquares: 'is a translation of <ans/> squares',
  isATranslationXSquaresAnsDirectionAndYSquaresAnsDirection:
    'is a translation {0:number|numberFormat} square{{s}} <ans/> and {1:number|numberFormat} square{{s}} <ans/>',
  isAnsSquares: 'is <ans/> square{{0:s}}',
  isEnoughToMakeAX: 'Is there enough equipment to make a {0}?',
  isEqualTo: 'is equal to',
  isXCloserToYorZ: 'Is {0} closer to {1} or {2}?',
  itHasOnlyAnsFactors: 'It has only <ans/> factors.',
  itTakesChar5XToHaveAShower: 'It takes {0} 5 ________ to have a shower.',
  itTakesChar8XToZipUpTheirCoat: 'It takes {0} 8 ________ to zip up their coat.',
  itTakesChar10XToDo5StarJumps: 'It takes {0} 10 ________ to do 5 star jumps.',
  itTakesChar20XToBakeSomeCookies: 'It takes {0} 20 ________ to bake some cookies.',
  itTookMeNumMinutesAndNumSeconds:
    'It took me {0:number|numberFormat} minute{{s}} and {1:number|numberFormat} second{{s}}.',
  itTookMeNumSeconds: 'It took me {0:number|numberFormat} seconds.',
  iWillDivideByXThenDivideByY: 'I will divide by {0}, then divide by {1}',
  jugXequalsAnsMl: 'Jug {0} = <ans/> ml',
  leapYearsHappenEveryAnsYears: 'Leap years happen every <ans/> years.',
  lengthEqualsAnsCm: 'Length = <ans/> cm',
  lengthOfSidesEqualsAnsCm: 'Length of sides = <ans/> cm',
  lengthIsAnsCm: 'The length is <ans/> cm.',
  lessThan: 'less than {0}',
  lessThanXMultY: `Less than {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  longerThanAMetre: 'Longer than a metre',
  longerThanXCm: 'Longer than {0:number|numberFormat} cm',
  longerThanXCmYMm: 'Longer than {0:number|numberFormat} cm {1:number|numberFormat} mm',
  makesNum: 'Makes {0:number|numberFormat}',
  mass: '{0} mass',
  massOfXMassYItem: 'Mass of {0} {1:string|lower}',
  massOfXMassYItemEqualsAnsKg: 'Mass of {0} {1:string|lower} = <ans/> kg',
  mixedFracAns: `Mixed <frac wAns='' nAns='' dAns=''/>`,
  moreThan: 'more than {0}',
  multiplesOfX: 'Multiples of {0:number|numberFormat}',
  multiplyNumBy10FindHalf: 'Multiply the number by 10 and find half the answer.',
  myBirthdayIsTheLastDayOfTheNthMonth: 'My birthday is the last day of the {0} month.',
  myJourneyIsXHours: 'My journey is {0} hours.',
  myJourneyIsXMinutes: 'My journey is {0} minutes.',
  myJourneyIsOneDay: 'My journey is 1 day.',
  myJourneyIsFractionOfADay: 'My journey is {0} of a day.',
  myJourneyIsFractionDays: 'My journey is {0} days.',
  myJourneyIsXHoursYLongerOrShorterThanCharacterZ:
    "My journey is {0} hour{{s}} {1:string|lower} than {2}'s.",
  myJourneyIsXAmountAsLongAsCharacterY: "My journey is {0:string|lower} as long as {1}'s.",
  myShapeHas1FaceCurvedSurface1Vertex1Edges:
    'My shape has 1 face, a curved surface, 1 vertex and 1 edge.',
  myShapeHas2FacesCurvedSurface2Edges: 'My shape has 2 faces, a curved surface and 2 edges.',
  myShapeHas6RectangularFaces8Vertices12Edges:
    'My shape has 6 rectangular faces, 8 vertices and 12 edges.',
  myShapeHas6SquareFaces8Vertices12Edges: 'My shape has 6 square faces, 8 vertices and 12 edges.',
  myShapeHasXFacesYVerticesZEdges: 'My shape has {0} faces, {1} vertices and {2} edges.',
  nearestTenthIs: 'The nearest tenth is <ans/>',
  nearestWholeNumberIsAns: 'The nearest whole number is <ans/>',
  nearestStep: 'Nearest {0: string|lower}',
  noSidesOrAnglesEqual: 'No sides or angles equal',
  numCmApproximatelyEqualsAnsInches: '{0:number|numberFormat} cm ≈ <ans/> inches',
  numCmEqualsAnsMm: '{0:number|numberFormat} cm = <ans/> mm',
  numCmEqualsXM: '{0:number|numberFormat} cm = {1} m',
  numCmEqualsAnsMAndAnsCm: '{0:number|numberFormat} cm = <ans/> m and <ans/> cm',
  numCmMinusAnsMmEqualsNumMm: `{0:number|numberFormat} cm ${SUB} <ans/> mm = {1:number|numberFormat} mm`,
  numCubedEqualsAns: '{0:number|numberFormat}³ = <ans/>',
  numCubedEqualsAnsMultAnsMultAns: `{0} cubed = <ans/> ${MULT} <ans/> ${MULT} <ans/> = <ans/>`,
  numEquals1Ans: '{0} = <ans/>',
  numEquals2Ans: '{0} = <ans/> + <ans/>',
  numEquals3Ans: '{0} = <ans/> + <ans/> + <ans/>',
  numEquals4Ans: '{0:number|numberFormat} = <ans/> + <ans/> + <ans/> + <ans/>',
  numEquals5Ans: '{0} = <ans/> + <ans/> + <ans/> + <ans/> + <ans/>',
  numEquals6Ans: '{0} = <ans/> + <ans/> + <ans/> + <ans/> + <ans/> + <ans/>',
  numEquals7Ans: '{0} = <ans/> + <ans/> + <ans/> + <ans/> + <ans/> + <ans/> + <ans/>',
  numEqualsFracOfAns: `{0:number|numberFormat} = {1} of <ans/>`,
  numEqualsHundredsTensOnes: '{0} = <ans/> hundreds, <ans/> tens and <ans/> ones.',
  numEqualsTensAndOnes: '{0} = <ans/> tens and <ans/> ones.',
  numEqualsTensOnes: '{0} = <ans/> ten{{1:s}} and <ans/> one{{2:s}}.',
  numEqualsTensOnesNumEquals2Ans: '{0} = <ans/> tens and <ans/> ones.<br/>{0} = <ans/> + <ans/>',
  numGEqualsAnsKg: '{0:number|numberFormat} g = <ans/> kg',
  numGPlusAnsEqualsNumKg: '{0} g + <ans/> g = {1} kg',
  numGroupsOperation: '{0} {1:string|lower}: {2}',
  numHasAns1000s: '{0:number|numberFormat} has <ans/> thousands.',
  numInchesApproximatelyEqualsAnsCm: '{0:number|numberFormat} inches ≈ <ans/> cm',
  numIs: '{0} is',
  numIsAPrimeNumber: '{0} is a prime number.',
  numIsCloseToAns: '{0:number|numberFormat} is close to <ans/>',
  numIsEquivalentToAns100s: '{0:number|numberFormat} is equivalent to <ans/> hundreds.',
  numIsToThe: '{0} is to the',
  numKgApproximatelyEqualsAnsLb: '{0:number|numberFormat} kg ≈ <ans/> lb',
  numKgEqualsAnsG: '{0} kg = <ans/> g',
  numKmEqualsAnsM: '{0:number|numberFormat} km = <ans/> m',
  numLEqualsAnsMl: '{0:number|numberFormat} l = <ans/> ml',
  numLbApproximatelyEqualsAnsKg: '{0:number|numberFormat} lb ≈ <ans/> kg',
  numLotsOfX: '{0:number|numberFormat} lots of {1}',
  numMAndNumCmEqualsAnsCm: '{0:number|numberFormat} m and {1:number|numberFormat} cm  = <ans/> cm',
  numMEqualsAnsKm: '{0:number|numberFormat} m = <ans/> km',
  numMEqualsAnsMm: '{0:number|numberFormat} m = <ans/> mm',
  numMlApproximatelyEqualsAnsPints: '{0:number|numberFormat} ml ≈ <ans/> pints',
  numMlEqualsAnsL: '{0:number|numberFormat} ml = <ans/> l',
  numMMinusAnsCmEqualsNumCm: `{0:number|numberFormat} m ${SUB} <ans/> cm = {1:number|numberFormat} cm`,
  numMmEqualsAnsCm: '{0:number|numberFormat} mm = <ans/> cm',
  numMmEqualsAnsCmAndAnsMm: '{0:number|numberFormat} mm = <ans/> cm and <ans/> mm',
  numMmEqualsAnsM: '{0:number|numberFormat} mm = <ans/> m',
  numMmMinusAnsCmEqualsNumMm: `{0:number|numberFormat} mm ${SUB} <ans/> cm = {1:number|numberFormat} mm`,
  numMMinusNumCmEqualsAnsCm: `{0:number|numberFormat} m ${SUB} {1:number|numberFormat} cm = <ans/> cm`,
  numMmMinusNumCmEqualsAnsMm: `{0:number|numberFormat} mm ${SUB} {1:number|numberFormat} cm = <ans/> mm`,
  numObjAddNumObjEqualsNumObj:
    '{0} {1:string|lower} + {2} {1:string|lower} = <ans/> {1:string|lower}',
  numPercent: '{0:number|numberFormat}%',
  numPintsApproximatelyEqualsAnsMl: '{0:number|numberFormat} pints ≈ <ans/> ml',
  numTimes10Equals: `{0} ${MULT} 10 = <ans/>`,
  numOnesTimesNumEqualsAnsOnes: `{0:number|numberFormat} ones ${MULT} {1:number|numberFormat} = <ans/> ones`,
  numRoundedToTheNearestAmountIs: '{0} rounded to the nearest {1:number|numberFormat} is <ans />',
  numRoundedToTheNearestTenthIs: '{0:number|numberFormat} rounded to the nearest tenth is <ans />',
  numRoundedToTheNearestWholeNumberIsAns:
    '{0:number|numberFormat} rounded to the nearest whole number is <ans />',
  numRoundedToTheNearestStepIs: '{0} rounded to the nearest {1: string|lower} is <ans />',
  numSquaredEqualsAns: '{0:number|numberFormat}² = <ans/>',
  numTensTimesNumEqualsAnsTens: `{0:number|numberFormat} tens ${MULT} {1:number|numberFormat} = <ans/> tens`,
  numWeeksHaveAnsDays: '{0:number|numberFormat} week{{s}} {{has|have}} <ans/> days.',
  ofHalfway: 'of halfway.',
  oneXIsApproximatelyEqualToY: '{0} is approximately equal to {1}<br/>{0} ≈ {1}',
  onesDivideByTenIsEqualTo: 'one{{0:s}} divided by ten is equal to',
  oneTurnEqualsAnsRAEqualsDegrees: `1 complete turn = <ans/> right angles = <ans/> ${DEGREES}`,
  paperComesInPacketsOfNum: 'Paper comes in packets of {0:number|numberFormat} sheets.',
  percent: '%',
  percentageEqualsAnsPercent: 'percentage = <ans/>%',
  perimeterAnsCm: 'Perimeter = <ans/> cm',
  perimeterCanBeMeasuredInAnsAns: 'Perimeter can be measured in units such as <ans/> and <ans/>',
  perimeterEqualsAnsCm: 'Perimeter = <ans/> cm',
  perimeterEqualsAnsM: 'Perimeter = <ans/> m',
  perimeterEqualsAnsMm: 'Perimeter = <ans/> mm',
  perimeterEqualsAnsCmAndMm: 'Perimeter = <ans/> cm <ans/> mm',
  perimeterEqualsXCm: 'Perimeter = {0:number|numberFormat} cm',
  XPercentOfAnsEqualsY: '{0:number|numberFormat}% of <ans/> = {1:number|numberFormat}',
  pointXTranslatedAnsDxAndAnsDy:
    'Point {0} has been translated <ans/> to the {1:string|lower} and <ans/> {2:string|lower}.',
  polygonsAreAnsShapes: 'Polygons are <ans/> shapes.',
  polygonsAreAnsShapesWhichMeansThereAreNoGapsInTheirSides:
    'Polygons are <ans/> shapes, which means that there are no gaps in their sides.',
  polygonsAreMadeUpOfAnsSides: 'Polygons are made up of <ans/> sides.',
  poundAns: '£<ans/>',
  poundAnsAndAnsPence: '£<ans/> and <ans/>p',
  poundsAnsMinusAnsPence: `£<ans/> ${SUB} <ans/>p`,
  poundXSubtractPoundYAndZPenceEqualsPoundAnsAndPenceAns: `£{0:number|numberFormat} ${SUB} £{1:number|numberFormat} and {2:number|numberFormat}p = £<ans/> and <ans/>p`,
  poundsXAndYP: '£{0} and {1}p',
  poundsXMinusPoundsYAndZPence: `£{0:number|numberFormat} ${SUB} £{1:number|numberFormat} and {2:number|numberFormat}p`,
  poundsXMinusPoundsAnsMinusAnsPence: `£{0:number|numberFormat} ${SUB} £<ans/> ${SUB} <ans/>p`,
  questionMarkAnsKgAndG: '? = <ans/> kg and <ans/> g',
  ratioOfShadedToUnshadedIsAns: 'The ratio of shaded to unshaded is <ans/> : <ans/>',
  ratioOfXtoYIsAns: 'The ratio of {0:string|lower} to {1:string|lower} is <ans/> : <ans/>',
  ratioOfXCounterstoYCountersIsAns:
    'The ratio of {0:string|lower} counters to {1:string|lower} counters is <ans/> : <ans/>',
  ratioOfXtoYIsZ: 'The ratio of {0:string|lower} to {1:string|lower} is {2}',
  repeatedAdditionOfFracNumTimes: 'Repeated addition of {frac} {number} times',
  roundMassOfParcelTo1Dp: 'Round the mass of the parcel to 1 decimal place.',
  roundToNearest:
    '{0:number|numberFormat} rounded to to the nearest {1:number|numberFormat} is <ans/>',
  roundToNearest10: '{0:number|numberFormat} rounded to the nearest 10 is <ans/>',
  roundToNearest100: '{0:number|numberFormat} rounded to the nearest 100 is <ans/>',
  roundToNearest1000: '{0:number|numberFormat} rounded to the nearest 1,000 is <ans/>',
  roundToNearest10000: '{0:number|numberFormat} rounded to the nearest 10,000 is <ans/>',
  roundToNearest100000: '{0:number|numberFormat} rounded to the nearest 100,000 is <ans/>',
  roundToNearest1000000: '{0:number|numberFormat} rounded to the nearest 1,000,000 is <ans/>',
  roundToNearest10Equation: '{0:number|numberFormat} to the nearest 10 = <ans/>',
  roundToNearest100Equation: '{0:number|numberFormat} to the nearest 100 = <ans/>',
  roundToNearest1000Equation: '{0:number|numberFormat} to the nearest 1,000 = <ans/>',
  roundToNearest10000Equation: '{0:number|numberFormat} to the nearest 10,000 = <ans/>',
  roundToNearest100000Equation: '{0:number|numberFormat} to the nearest 100,000 = <ans/>',
  roundToNearest1000000Equation: '{0:number|numberFormat} to the nearest 1,000,000 = <ans/>',
  roundedToNearest10100And1000:
    '{0:number|numberFormat} rounded to the nearest 10 is <ans/><br/>{1:number|numberFormat} rounded to the nearest 100 is <ans/><br/>{2:number|numberFormat} rounded to the nearest 1,000 is <ans/>',
  roundedToNearest10000010000And1000:
    '{0:number|numberFormat} rounded to the nearest 100,000 is <ans/><br/>{1:number|numberFormat} rounded to the nearest 10,000 is <ans/><br/>{2:number|numberFormat} rounded to the nearest 1,000 is <ans/>',
  roundedToNearest1000And10000:
    '{0:number|numberFormat} rounded to the nearest 10,000 is <ans/><br/>{1:number|numberFormat} rounded to the nearest 1,000 is <ans/>',
  scaleIsCountingUpInAnsMl: 'The scale is counting up in <ans/> ml.',
  selectTheCorrectAnswer: 'Select the correct answer.',
  shapeA: 'Shape A',
  shapeB: 'Shape B',
  shapeHasIncreasedByXEachSideHasScaleFactor:
    'A shape in which each side has {0} in size has been enlarged by a scale factor of <ans/>',
  shapeHasNoDiagonalSymmetry: 'This shape does not have a diagonal lines of symmetry',
  shapeHasNoHorizontalSymmetry: 'This shape does not have a horizontal lines of symmetry',
  shapeHasNoVerticalSymmetry: 'This shape does not have a vertical lines of symmetry',
  shorterThanAMetre: 'Shorter than a metre',
  shorterThanXCm: 'Shorter than {0:number|numberFormat} cm',
  shorterThanXCmYMm: 'Shorter than {0:number|numberFormat} cm {1:number|numberFormat} mm',
  soEquationEqualsAns: 'So {0} = <ans/>',
  soX: 'So {0}',
  soXisAnsorAns: 'So {0} is <ans/> {1} or <ans/> {2}.',
  subtractXAndAddY: 'Subtract {0} and add {1}',
  sumOfDigitsDivisibleByX: 'Sum of digits divisible by {0:number|numberFormat}',
  shapeAHasBeenTranslatedAnsDxAndAnsDy:
    '{0} A has been translated <ans/> square{{1:s}} to the {2:string|lower} and <ans/> square{{3:s}} {4:string|lower}.',
  tenMillimetresIsEqualToAnsCentimetre: '10 millimetres is equal to <ans/> centimetre.',
  theAreaIsAnsSquare: 'The area is <ans/> square.',
  theAreaIsAnsSquares: 'The area is <ans/> squares.',
  theAreaOfTheSquareIsAns: 'The area of the square is <ans/>',
  theAreaOfTheRectangleIsAns: 'The area of the rectangle is <ans/>',
  theAreaOfTheRectangleIsXAreaOfTheTriangle:
    'The area of the rectangle is ________ the area of the triangle.',
  theAreaOfTheTriangleIsXAreaOfTheRectangle:
    'The area of the triangle is ________ the area of the rectangle.',
  theArrowIsAnsCmLong: 'The arrow is <ans/> cm long.',
  theBarModelShowsBothFracAAndFracB: 'The bar model shows both {0} and {1}',
  theCartoonLastsForNumMinutes:
    'The cartoon lasts for {0:number|numberFormat} minute{{s}}.\nWhat time will it finish?',
  theCommonFactorsAre: 'The common factors are ',
  theDecimalShadedIsAns: 'The decimal shaded is <ans/>',
  theDiameterOfOuterCircleIsAnsCm: 'The diameter of the outer circle is <ans/> cm.',
  theDiffBetweenXAndYIsZ: 'The difference between {0} and {1} is {2}',
  theDifferenceBetweenXPoundsAndYPoundsIs:
    'The difference between {poundsA} and {poundsB} is £<ans/>',
  theFactorsAreAnsAndAnd: 'The factors are <ans/> and <ans/>',
  theFactorsOfNumAre2Ans: 'The factors of {0:number|numberFormat} are <ans/> and <ans/>',
  theFactorsOfNumAre3Ans: 'The factors of {0:number|numberFormat} are <ans/>, <ans/> and <ans/>',
  theFactorsOfNumAre4Ans:
    'The factors of {0:number|numberFormat} are <ans/>, <ans/>, <ans/> and <ans/>',
  theFactorsOfNumAre5Ans:
    'The factors of {0:number|numberFormat} are <ans/>, <ans/>, <ans/>, <ans/> and <ans/>',
  theFactorsOfNumAre6Ans:
    'The factors of {0:number|numberFormat} are <ans/>, <ans/>, <ans/>, <ans/>, <ans/> and <ans/>',
  theFractionShadedIs: "The fraction shaded is <frac nAns='' dAns=''/>",
  theFractionShadedIsX: 'The fraction shaded is {0}',
  theFractionThatComesAfterFractionIsAns:
    "The fraction that comes after <frac n='{0}' d='{1}'/> is <frac nAns='' dAns=''/>",
  theFractionThatComesBeforeFractionIsAns:
    "The fraction that comes before <frac n='{0}' d='{1}'/> is <frac nAns='' dAns=''/>",
  theGreenRibbonIsAnsTimesAsLongAsTheRedRibbon:
    'The green ribbon is <ans/> times as long as the red ribbon.',
  theGreenRibbonIsXTimesAsLongAsTheRedRibbonLengthOfTheGreenRibbonIsXUnit:
    'The green ribbon is {0} times as long as the red ribbon.<br/>The length of the green ribbon is <ans/> {1}',
  theMissingLengthIsXcm: 'The missing length of the shape is {0: number|numberFormat} cm.',
  theNumberIsAns: 'The number is <ans/>',
  theNumberLineIsCountingUpInAns: 'The number line is counting up in <ans/>s.',
  theNumberLineIsCountingUpInFrac: 'The number line is counting up in {frac}s.',
  theNumberLineIsSplitIntoAnsEqualParts: 'The number line is split into <ans/> equal parts.',
  theNumberLineIsSplitIntoAnsParts: 'The number line has been split into <ans/> parts.',
  thePerimeterIsCm: 'The perimeter is <ans/> cm.',
  thePerimeterIsM: 'The perimeter is <ans/> m.',
  thePerimeterOfTheWholePatternIs:
    'The perimeter of the whole pattern is {0:number|numberFormat} cm.',
  theRadiusOfOuterCircleIsAnsCm: 'The radius of the outer circle is <ans/> cm.',
  theRatioOfXtoYIsAnsToAns: `The ratio of {0} to {1} is <ans/> : <ans/>`,
  theRatioOfXtoYToZIsAnsToAnsToAns: `The ratio of {0:string|lower} to {1:string|lower} to {2:string|lower} is<br/><ans/> : <ans/> : <ans/>`,
  theShapeHasX: 'The shape has {0}.',
  theShapeHasXAndY: 'The shape has {0} and {1}.',
  theShapesHaveDifferentAreas: 'The shapes have different areas.',
  theShapesHaveTheSameArea: 'The shapes have the same area.',
  theXShapeIsAnsCmLong: 'The {0:string|lower} is <ans /> cm long.',
  theSumOfInteriorAnglesInXIs: `The sum of the interior angles in {0} is <ans/>${DEGREES}`,
  theSumOfInteriorAnglesIs: `The sum of the interior angles is <ans/>${DEGREES}`,
  theTimeIsXMinutesPastY:
    'The time is {0:number|numberFormat} minute{{s}} past {1:number|numberFormat}',
  theTimeIsXMinutesToY:
    'The time is {0:number|numberFormat} minute{{s}} to {1:number|numberFormat}',
  theXScaleObjectIsLighterOrHeavierThanTheYScaleObject:
    'The {0:string|lower} is ________ than the {1:string|lower}.',
  theWholeHasBeenSplitInto: 'The whole has been split into',
  theWholeIs: 'The whole is <ans/>',
  theWholeIsSplitIntoXEqualParts: 'The whole is split into <ans/> equal parts.',
  thereAreAnsTensAndAnsOnes: 'There are <ans/> tens and <ans/> ones.<br/>The number is <ans/>',
  thereAreAns10sInX100: 'There are <ans/> tens in {0}',
  thereAreAnsChairLegsInTotal: 'There are <ans/> chair legs in total.',
  thereAreAnsCmInNumM: 'There are <ans/> cm in {0:number|numberFormat} m.',
  thereAreAnsCountersInEachGroup: 'There are <ans/> counters in each group.',
  thereAreAnsDaysInAWeek: 'There are <ans/> days in a week.',
  thereAreAnsDaysInALeapYear: 'There are <ans/> days in a leap year.',
  thereAreAnsDaysInANonLeapYear: 'There are <ans/> days in a non-leap year.',
  thereAreAnsDegreesInAFullTurn: 'There are <ans/> degrees in a full turn.',
  thereAreAnsDegreesInAHalfTurn: 'There are <ans/> degrees in a half turn.',
  thereAreAnsDegreesInAQuarterTurn: 'There are <ans/> degrees in a quarter turn.',
  thereAreAnsDegreesInAThreeQuarterTurn: 'There are <ans/> degrees in a three-quarter turn.',
  thereAreAnsEggsInNumberBoxes: 'There are <ans/> eggs in {0} boxes.',
  thereAreAnsEqualGroupsOfAns: 'There are <ans/> equal groups of <ans/>',
  thereAreAnsGroups: 'There are <ans/> groups.',
  thereAreAnsGroupsOf: 'There are <ans/> groups of {0}',
  thereAreAnsGroupsOfXObject: 'There are <ans/> groups of {0} {1:string|lower}.',
  thereAreAnsEqualIntervals: 'There are <ans/> equal intervals.',
  thereAreAnsMillimetresIn1Cm: 'There are <ans/> millimetres in 1 centimetre',
  thereAreAnsMinXKm: 'There are <ans/> m in {0} km.',
  thereAreAnsMonthsInAYear: 'There are <ans/> months in a year.',
  thereAreAnsX: 'There are <ans/> {0:string|lower}.',
  thereAreAnsXAndAnsY: 'There are <ans/> {0:string|lower} and <ans/> {1:string|lower}.',
  thereAreAnsGroupsOfAnsObjects: 'There are <ans/> {0:string|lower} of <ans/> {1:string|lower}.',
  thereAreAnsObjectsInTotal: 'There are <ans/> {0:string|lower} in total.',
  thereAreAnsRowsAndAnsColumns: 'There are <ans/> rows and <ans/> columns.',
  thereAreAnsRowsEachRowHasAnsSquares: 'There are <ans /> rows and each row has <ans /> squares.',
  thereAreAnsRowsOfAnsObjects: 'There are <ans/> rows of <ans/> {0:string|lower}.',
  thereAreAnsColsOfAnsObjects: 'There are <ans/> columns of <ans/> {0:string|lower}.',
  thereAreAnsXAltogether: 'There are <ans/> {0:string|lower} altogether.',
  thereAreAnsTimesAsManyXAsY:
    'There are <ans/> times as many {0:string|lower} as {1:string|lower}.',
  thereAreAnsWholesAndAnsY: 'There are <ans/> wholes and <ans/> {0}.',
  thereAreAnsXsinY: 'There are <ans/> {0}s in {1}',
  thereAreAnsXin1Y: 'There are <ans/> {0} in 1 {1}.',
  thereAreAnsXRemaining: 'There are <ans/> {0:string|lower} remaining.',
  thereAreNumCmInAnsM: 'There are {0:number|numberFormat} cm in <ans/> m.',
  thereAreNumberEggsInAnsBoxes: 'There are {0} eggs in <ans/> boxes.',
  thereAreNumberObjects:
    'There are <ans/> {0:Base10RepresentationVariantKey|Base10RepresentationVariant|lower}.',
  thereAreAnsCombinations: 'There are <ans/> combinations.',
  thereAreAnsGroupsOfXCounters: 'There are <ans/> groups of {0} counters.',
  thereAreAnsGroupsOfXInY: 'There are <ans/> groups of {0} in {1}',
  thereAreAnsMmInNumCm: 'There are <ans/> mm in {0:number|numberFormat} cm.',
  thereAreAnsOnesAndAnsTenths: 'There are <ans/> ones and <ans/> tenths.',
  thereAreAnsOnesAnsTenthsAndAnsHundredthsAnsThousandthsPlurals:
    'There are <ans/> ones, <ans/> tenth{{0:s}} <ans/> hundredth{{1:s}} and <ans/> thousandth{{2:s}}.',
  thereAreAnsOnesAnsTenthsAndAnsHundredthsPlurals:
    'There are <ans/> ones, <ans/> tenth{{0:s}} and <g><ans/> hundredth{{1:s}}.</g>',
  thereAreAnsOnesAnsTenthsAndAnsHundredths:
    'There are <ans/> ones, <ans/> tenths and <g><ans/> hundredths.</g>',
  thereAreAnsOnesAnsTenthsAndAnsHundredthsTheNumberIsAns:
    'There are <ans/> ones, <ans/> tenths and <ans/> hundredths. The number is <ans/>',
  thereAreAnsOnesAnsTenthsAndAnsHundredthsThousandthsPlurals:
    'There are <ans/> ones, <ans/> tenth{{0:s}}, <g><ans/> hundredth{{1:s}}</g> and <g><ans/> thousandth{{2:s}}.</g>',
  thereAreAnsPartsOutOfAHundredShaded: 'There are <ans/> parts out of a hundred shaded.',
  thereAreAnsPoundsThereIsAnsPenceTheTotalAmountIsPoundsAns:
    'There are <ans/> pounds.<br/>There is <ans/> pence.<br/>The total amount is £<ans/>',
  thereAreAnsTenthsAnsHundredthsAnsThousandths:
    'There are <ans/> tenths, <ans/> hundredths and <ans/> thousandths.',
  thereAreFracAndFracShaded: 'There are {frac1} and {frac2} shaded.',
  thereAreNumMmInAnsCm: 'There are {0:number|numberFormat} mm in <ans/> cm.',
  thereAreNumXPoundsInNumStone:
    'There are {0:number|numberFormat} pounds in {1:number|numberFormat} stone.',
  thereAreNumXTreesHowManyYTreesAreThere:
    'There are {0:number|numberFormat} {1:string|lower} trees.<br/>How many {2:string|lower} trees are there in total? <ans/>',
  thereAreNumberTables: 'There are {0} tables.',
  thereAreXLotsOfYInZ: 'There are {0} lots of {1} in {2}',
  thereAreXMInAnsKm: 'There are {0} m in <ans/> km.',
  thereAreXTimesAsManyYThanZ:
    'There are {0:number|numberFormat} times as many {1:string|lower} than {2:string|lower}.',
  thereIsAnsOnesAnsTenthsAndAnsHundredths:
    'There is <ans/> one, <ans/> tenth{{0:s}} and <g><ans/> hundredth{{1:s}}.</g>',
  thereIsAnsOnesAnsTenthsAndAnsHundredthsAnsThousandths:
    'There is <ans/> one, <ans/> tenth{{0:s}} <ans/> hundredth{{1:s}} and <ans/> thousandth{{2:s}}.',
  thereIsAnsPoundThereIsAnsPenceTheTotalAmountIsPoundsAns:
    'There is <ans/> pound.<br/>There is <ans/> pence.<br/>The total amount is £<ans/>',
  thereIsAnsWholeAndAnsX: 'There is <ans/> whole and <ans/> {0}.',
  thereIsAnsXAndAnsY: 'There is <ans/> {0:string|lower} and <ans/> {1:string|lower}.',
  thereAre10MillimetresInAnsCentimetre: 'There are 10 millimetres in <ans/> centimetre',
  thereAre100CentimetresInAnsMetre: 'There are 100 centimetres in <ans/> metre',
  thisIsAnsPercent: 'This is <ans/> %',
  thisShowsTheDecimalAns: 'This shows the decimal <ans/>',
  thousandsHundredsTensOnesAns:
    'There are <ans/> thousand{{0:s}}, <ans/> hundred{{1:s}}, <ans/> ten{{2:s}} and <ans/> one{{3:s}}.<br/>The number is <ans/>',
  twoSidesAndAnglesEqual: '2 sides and 2 angles equal',
  useCharactersMethodToWorkOutX: "Use {0}'s method to work out {1}",
  useNamePluralMethodToCompareFractions: "Use {name}'s method to compare the fractions.",
  useThisMethodToCompleteTheCalculation: 'Use this method to complete the calculation',
  useTheFactsToCompleteTheSentences: 'Use the facts to help complete the sentences.',
  useTheStratToWorkOutTheMult: 'Use the strategy to work out the multiplication.',
  useYourAnswerToWorkOutThePercentageOfAmount:
    'Use your answer to work out the percentage of the amount.',
  varLessThanVarIs: '{0} less than {1} is <ans/>',
  varMoreThanVarIs: '{0} more than {1} is <ans/>',
  varTeamsHaveAnsPlayers: '{0} teams have <ans/> players.',
  verticallyOppositeAnglesAreEqual: 'Vertically opposite angles are equal',
  wEquals: '<b>w</b> =',
  wEqualsAnsM: '<b>w</b> = <ans/> m',
  wEqualsXSoYEqualsZ: '{0} = {1} so {2} = {3}',
  whatFractionOfTheCardsDoesCharacterHaveLeft: 'What fraction of the cards does {0} have left?',
  whatFractionOfCardsIsGiven: 'What fraction of the cards does {0} give to {1}?',
  whatIsFracOfX: 'What is {0} of {1:number|numberFormat}? <ans/>',
  whatIsTheCostOfNumObjects: 'What is the cost of {0} {1:string|lower}? £<ans/>',
  whatIsTheDifferenceBetweenTheAmountsTheySpend:
    'What is the difference between the amounts they spend?',
  whatIsTheDifferenceBetweenTheTwoCards: 'What is the difference between the two cards? <ans/>',
  whatIsTheMathematicaNameOfTheQuadrilateral: 'What is the mathematical name of the quadrilateral?',
  whatIsThePerimeterOfEachShape: 'What is the perimeter of each {0}?',
  whatIsTheValueOfTheShadedSquares: 'What is the value of the shaded squares? <ans/>p',
  whatIsTheirCombinedAge: 'What is their combined age?',
  whatLengthOfRibbonDoesCharacterUse: 'What length of ribbon does {0} use on the trousers?',
  whatNumberIsShownWhatIsLess:
    'What number is shown? <ans/><br/>What is {0} less than the number? <ans/>',
  whatNumberIsShownWhatIsMore:
    'What number is shown? <ans/><br/>What is {0} more than the number? <ans/>',
  whereThePolygonsAnsLinesMeetTheyFormAnAns:
    'Where the polygon <ans/> lines meet, they form an <ans/>.',
  whosePieceOfRibbonIsLonger: 'Whose piece of ribbon is longer?',
  whosePieceOfRibbonIsShorter: 'Whose piece of ribbon is shorter?',
  widthIsAnsCm: 'The width is <ans/> cm.',
  workOutXPercentAndUseAnswerToWorkOutPercentageOfAnAmount:
    'Work out {0:number|numberFormat} and use the answer to work out the percentage of an amount.',
  workOutTheMissingNumber: 'Work out the missing number.',
  workOutTheValuesXY: 'Work out the values.<br/>{0} = <ans/>     {1} = <ans/>',
  workOutTheValues: 'Work out the values.',
  workOutHowMuchChangeCharacterWillGet: 'Work out how much change {name} will get.',
  xAndY: '{0} and {1}',
  xAndYSoZ: `{0} and {1} so {2}`,
  xAndYEquivalentToAnsHundredths: '{0} and {1} is equivalent to <ans/> hundredths.',
  xAnsY: `{0} <ans/> {1}`,
  XAnsYAns: '{0} = <ans/>     {1} = <ans/>',
  xCanBeExchangedForHundredths: '{0} can be exchanged for <ans/> hundredths.',
  xDaysAnsYWeeks: '{0:number|numberFormat} day{{s}} <ans/> {1:number|numberFormat} week{{s}}',
  xDaysEqualsAnsHours: '{0:number|numberFormat} day{{s}} = <ans/> hours',
  xDaysEqualsYHours: '{0:number|numberFormat} day{{s}} = {1:number|numberFormat} hour{{s}}',
  xDividedByY: '{0} divided by {1}',
  XDividedIntoYEqualGroupsIsAns: '{0} divided into {1} equal groups is <ans/>',
  xOfATurnEqualsAnsRAEqualsDegrees: `{0} of a complete turn = <ans/> right angles <g>= <ans/> ${DEGREES}</g>`,
  xOfY: '{0} of {1}',
  xOfYAns: '{0} of {1} = <ans/>',
  xOfYEqualsZ: '{0} of {1} = {2}',
  xOfYisZ: 'A {0} of {1} is {2}.',
  xByY: '{0} by {1}',
  xDateToYDate: '{0:number|numberFormat} {1} to {2:number|numberFormat} {3}',
  XDaysToHours: '{0} days = <ans/> hours',
  xDividedByYIsEqualToZ: '{0} divided by {1} is equal to {2}',
  XEqualsAns: '{0:string|lower} equals <ans />',
  XEqualsAnsHundreds: '{0:string|lower} = <ans /> hundreds',
  fractionEqualsAns: `fraction = <frac nAns='' dAns='' />`,
  fullSquaresAns: 'Full squares <ans/>',
  xEqAnsCubes: '{0} = <ans/> cubes',
  xEqualGroupsOfY: '{0:number|numberFormat} equal groups of {1:number|numberFormat}',
  xEqualParts: '<ans/> equal parts.',
  xEqualsAnsCm: '{0} = <ans/> cm',
  xEqualsAnsDegrees: '{0} = <ans/>°',
  xG: '{0:number|numberFormat} g',
  xHasAnsYFacesAndAnsZFaces: 'A {0} has <ans/> {1} face{{2:s}} and <g><ans/> {3} face{{4:s}}.</g>',
  xHours: '{0} hour{{s}}',
  xHoursAndYMinutes: '{0:number|numberFormat} hour{{s}} and {1:number|numberFormat} minute{{s}}',
  XHoursToMinutes: '{0} hours = <ans/> minutes',
  xHundredsDivYEqualsAnsHundreds: `{0:number|numberFormat} hundreds ${DIV} {1: number|numberFormat} = <ans/> hundreds`,
  xIsAnsCmLong: '{0} is <ans/> cm long.',
  xIsYAnsZ: '{0} is {1} <ans/> {2}',
  xIsAnsThanY: '{0} is <ans/> than {1}',
  xIsCloserToYThanZ: '{0} is closer to {1} than {2}',
  xIsCloserToAnsThanAns: '{0} is closer to <ans/> than <ans/>',
  xIsEqualToYLotsOfZ: '{0} is equal to {1} lots of {2}',
  xIsEquivalentToAns: '{0} is equivalent to <ans/>',
  xIsShaded: '{0} is shaded.',
  xIsToTheAnsOfMidpoint: '{0} is to the <ans/> of the midpoint.',
  xIsNotShaded: '{0} is not shaded.',
  xIsYTimesTheSizeOfAns: '{0} is {1} times the size of <ans/>',
  xKg: '{0:number|numberFormat} kg',
  xKgG: '{0:number|numberFormat} kg {1:number|numberFormat} g',
  xKgAndG: '{0:number|numberFormat} kg and {1:number|numberFormat} g',
  xKmLongerThanYMiles:
    '{numOfKms:number|numberFormat} kilometre{{numOfKms:s}} is longer than {numOfMiles:number|numberFormat} mile{{numOfMiles:s}}',
  xL: '{0: number|numberFormat} l',
  xLAndYMl: '{0:number|numberFormat} l and {1:number|numberFormat} ml',
  xLYMl: '{0: number|numberFormat} l {1:number|numberFormat} ml',
  xLater: '{0} later',
  xLessThanY: '{0} less than {1}',
  xLessThanYIsEqualToZ: '{0} less than {1} is equal to {2}',
  xLiesBetweenAnsAndAns: '{0} lies between <ans/> and <ans/>',
  xLotsOfYIsEqualToZ: '{0} lots of {1} is equal to {2}',
  xMEqualsAnsCm: '{0} m = <ans/> cm',
  xMilesApproxEqualToYKm:
    '{numOfMiles:number|numberFormat} mile{{numOfMiles:s}} is approximately equal to {numOfKms:number|numberFormat} kilometre{{numOfKms:s}}',
  xMilesLongerThanYKm:
    '{numOfMiles:number|numberFormat} mile{{numOfMiles:s}} is longer than {numOfKms:number|numberFormat} kilometre{{numOfKms:s}}',
  xMinutes: '{0} minute{{s}}',
  xMinutesXPastOrToXHoursInTheXTimeOfDay:
    '{minutes} minute{{s}} {pastOrTo} {hours} in the {timeOfDay}',
  xMinutesAndYSeconds:
    '{0:number|numberFormat} minute{{s}} and {1:number|numberFormat} second{{s}}',
  xMinutesAndYSecondsEqualsAnsSeconds:
    '{0:number|numberFormat} minute{{s}} and {1:number|numberFormat} second{{s}} = <ans /> seconds',
  xMinutesAndYSecondsAnsZSeconds:
    '{0:number|numberFormat} minute{{s}} and {1:number|numberFormat} second{{s}} <ans /> {2:number|numberFormat} seconds',
  xMinutesAndAnsSecondsEqualsYSeconds:
    '{0:number|numberFormat} minute{{s}} and <ans/> seconds = {1:number|numberFormat} second{{s}}',
  XMinutesToSeconds: '{0} minutes = <ans/> seconds',
  xMinutesPastYEqualsAnsMinutesToMinutes:
    '{0:number|numberFormat} minutes past {1:number|numberFormat} = <ans/> minutes to <ans/>',
  xMinutesPastY: '{0:number|numberFormat} minute{{s}} past {1:number|numberFormat}',
  xMinutesToY: '{0:number|numberFormat} minute{{s}} to {1:number|numberFormat}',
  xMl: '{0: number|numberFormat} ml',
  xMonthsAnsYYears: '{0:number|numberFormat} month{{s}} <ans/> {1:number|numberFormat} year{{s}}',
  xMoreThanY: '{0} more than {1}',
  xMoreThanYIsEqualToZ: '{0} more than {1} is equal to {2}',
  xMultipliedByY: '{0} multiplied by {1}',
  xAmountYObjectHasTheSameMassAsAnsZObjects:
    '{0:number|numberFormat} {1:string|lower} has the same mass as <ans/> {2:string|lower}.',
  xAmountYObjectsHaveTheSameMassAsAnsZObjects:
    '{0:number|numberFormat} {1:string|lower} have the same mass as <ans /> {2:string|lower}.',
  xOfNumYIsAnsZOrAnsY: '{0} of {1:number|numberFormat} {2} is <ans/> {3} or <ans/> {2}',
  xOneTenthSizeOfAns: '{0} is one-tenth the size of <ans />',
  xColonY: '{0:number|numberFormat} : {1:number|numberFormat}',
  xPartsAreShaded: '<ans/> of the parts are shaded.',
  xP: '{0}p',
  xPAddYPEqualsAnsP: `{0:number|numberFormat}p ${ADD} {1:number|numberFormat}p = <ans/>p`,
  xPenceEqualsPoundsandPence: '{0}p = £<ans/> and <ans/>p',
  xPercentOfAnsEqualsY: '{0:number|numberFormat}% of <ans/> = {1:number|numberFormat}',
  xPercentOfBlankEqualsY: '{0:number|numberFormat}% of ? = {1:number|numberFormat}',
  xPercentOfY: '{0:number|numberFormat}% of {1:number|numberFormat}',
  xPercentOfYEqualsAns: '{0:number|numberFormat}% of {1:number|numberFormat} = <ans/>',
  xPoundsAddYPoundsEqualsAnsPounds: `£{0:number|numberFormat} ${ADD} £{1:number|numberFormat} = £<ans/>`,
  xPoundsAndYPence: '£{0} and {1}p',
  xPercentOfYAns: '{0:number|numberFormat}% of {1:number|numberFormat} = <ans/>',
  xRoundedToTheNearestXIs: '{0} rounded to the nearest {1:string|lower} is <ans/>',
  xSeconds: '{0} second{{s}}',
  xSecondsAnsYMinutesAndZSeconds:
    '{0:number|numberFormat} second{{s}} <ans/> {1:number|numberFormat} minute{{s}} and {2:number|numberFormat} second{{s}}',
  xSharedIntoYEqualGroupsIsAns:
    '{0:number|numberFormat} shared into {1:number|numberFormat} equal groups is <ans/>',
  xSquared: '{0} squared',
  xThenY: '{0} then {1}',
  xThenYThenZ: '{0} then {1} then {2}',
  xToY: '{0:number|numberFormat} to {1:number|numberFormat}',
  xTriangles: '{0} triangle{{s}}',
  xWeeksEqualsAnsDays: '{0:number|numberFormat} week{{s}} = <ans/> days',
  xWeeksEqualsYDays: '{0:number|numberFormat} week{{s}} = {1:number|numberFormat} day{{s}}',
  xWeeksAnsYDays: '{0:number|numberFormat} week{{s}} <ans/> {1:number|numberFormat} day{{s}}',
  xWeeksAnsYMonths: '{0:number|numberFormat} week{{s}} <ans/> {1:number|numberFormat} month{{s}}',
  xYOfZ: '{0} {1} of {2}',
  xYCoordinate: `({0}, {1})`
};
