import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { numberEnum } from '../../../../utils/zod';
import { MarkupAssets } from '../../../../markup';
import { View } from 'react-native';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { isInRange } from '../../../../utils/matchers';
import QF18DragIntoABox from '../../../../components/question/questionFormats/QF18DragIntoABox';
import { renderCircleSvg } from '../../../../utils/shapeRenders';
import React from 'react';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbF',
  description: 'bbF',
  keywords: ['Add', 'Subtract', 'More', 'Less', 'Fewer'],
  schema: z
    .object({
      names: z.array(ks1NameSchema).length(2).refine(arrayHasNoDuplicates),
      initialCounters: z.number().int().min(3).max(10),
      counterDiff: z.number().int().min(1).max(2),
      diffIsMore: z.boolean(),
      counterColour: z.enum(['yellow', 'red', 'blue', 'green'])
    })
    .refine(
      ({ initialCounters, counterDiff, diffIsMore }) =>
        isInRange(1, 10)(initialCounters + (diffIsMore ? counterDiff : -counterDiff)),
      'result should be between 1 and 10'
    ),
  simpleGenerator: () => {
    const names = getRandomUniqueKs1Names(2);
    const initialCounters = randomIntegerInclusive(3, 10);
    const diffIsMore = initialCounters === 10 ? false : getRandomBoolean();
    const counterDiff = randomIntegerInclusive(1, 2, {
      constraint: x => isInRange(1, 10)(initialCounters + (diffIsMore ? x : -x))
    });
    const counterColour = getRandomFromArray(['yellow', 'red', 'blue', 'green'] as const);
    return { names, initialCounters, counterDiff, diffIsMore, counterColour };
  },
  Component: props => {
    const {
      question: { names, initialCounters, counterDiff, diffIsMore, counterColour },
      translate
    } = props;

    const [char1, char2] = names;
    const sencondStatement = diffIsMore ? 'xHasYMoreCountersThanZ' : 'xHasYFewerCountersThanZ';

    const counter = renderCircleSvg(counterColour, false);
    return (
      <MarkupAssets
        elements={{
          counters: (
            <View style={{ flexDirection: 'row', gap: 8 }}>
              {countRange(initialCounters).map(i => (
                <React.Fragment key={`${i}_counter`}>{counter}</React.Fragment>
              ))}
            </View>
          )
        }}
      >
        <QF18DragIntoABox
          title={`${translate.ks1Instructions.xHasTheseCounters(
            char1
          )}<br/><asset name='counters'/><br/>${translate.ks1Instructions[sencondStatement](
            char2,
            counterDiff,
            char1
          )}<br/>${translate.ks1Instructions.dragCountersToShowHowManyXHas(char2)}`}
          pdfTitle={`${translate.ks1Instructions.xHasTheseCounters(
            char1
          )}<br/><asset name='counters'/><br/>${translate.ks1Instructions[sencondStatement](
            char2,
            counterDiff,
            char1
          )}<br/>${translate.ks1PDFInstructions.drawCountersToShowHowManyXHas(char2)}`}
          testCorrect={initialCounters + (diffIsMore ? counterDiff : -counterDiff)}
          draggablePath={counter}
        />
      </MarkupAssets>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbG',
  description: 'bbG',
  keywords: ['Add', 'Subtract', 'More', 'Less', 'Fewer'],
  schema: z
    .object({
      initialCounters: z.number().int().min(2).max(10),
      counterDiff: z.number().int().min(1).max(2),
      diffIsMore: z.boolean(),
      counterColour: z.enum(['yellow', 'red', 'blue', 'green'])
    })
    .refine(
      ({ initialCounters, counterDiff, diffIsMore }) =>
        isInRange(1, 10)(initialCounters + (diffIsMore ? counterDiff : -counterDiff)),
      'result should be between 1 and 10'
    ),
  simpleGenerator: () => {
    const names = getRandomUniqueKs1Names(2);
    const initialCounters = randomIntegerInclusive(2, 10);
    const diffIsMore = initialCounters === 10 ? false : getRandomBoolean();
    const counterDiff = randomIntegerInclusive(1, 2, {
      constraint: x => isInRange(1, 10)(initialCounters + (diffIsMore ? x : -x))
    });
    const counterColour = getRandomFromArray(['yellow', 'red', 'blue', 'green'] as const);
    return { names, initialCounters, counterDiff, diffIsMore, counterColour };
  },
  Component: props => {
    const {
      question: { initialCounters, counterDiff, diffIsMore, counterColour },
      translate
    } = props;

    const statement = diffIsMore ? 'dragCountersToShowXMoreThanY' : 'dragCountersToShowXLessThanY';
    const pdfStatement = diffIsMore
      ? 'drawCountersToShowXMoreThanY'
      : 'drawCountersToShowXLessThanY';

    return (
      <QF18DragIntoABox
        title={translate.ks1Instructions[statement](counterDiff, initialCounters)}
        pdfTitle={translate.ks1PDFInstructions[pdfStatement](counterDiff, initialCounters)}
        testCorrect={initialCounters + (diffIsMore ? counterDiff : -counterDiff)}
        draggablePath={renderCircleSvg(counterColour, false)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbH',
  description: 'bbH',
  keywords: ['Add', 'Subtract', 'More', 'Less', 'Fewer'],
  schema: z
    .object({
      names: z.array(ks1NameSchema).length(2),
      num1: z.number().int().min(1).max(10),
      item: z.enum(['Cookie', 'Apple']),
      hasMore: z.boolean(),
      difference: numberEnum([1, 2])
    })
    .refine(
      ({ num1, hasMore, difference }) =>
        isInRange(0, 10)(hasMore ? num1 + difference : num1 - difference),
      'totals should be between 0 and 10'
    ),
  simpleGenerator: () => {
    const names = getRandomUniqueKs1Names(2);
    const item = getRandomFromArray(['Cookie', 'Apple'] as const);

    const num1 = randomIntegerInclusive(1, 10);

    const hasMore = num1 === 10 ? false : getRandomBoolean();
    const difference =
      (num1 === 9 && hasMore) || (num1 === 1 && !hasMore) ? 1 : getRandomFromArray([1, 2] as const);
    return {
      names,
      item,
      num1,
      hasMore,
      difference
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { names, item, num1, hasMore, difference } = question;
    const [name1, name2] = names;

    const num2 = hasMore ? num1 + difference : num1 - difference;

    const itemIsCookie = item === 'Cookie';
    const itemPluralString = itemIsCookie
      ? translate.objects.Cookies()
      : translate.objects.Apples();
    const itemDiffString =
      difference === 1
        ? itemIsCookie
          ? translate.objects.Cookie()
          : translate.objects.Apple()
        : itemPluralString;

    const instruction = hasMore ? 'charAHasXMoreYThanCharB' : 'charAHasXFewerYThanCharB';

    const svgNameObject = itemIsCookie ? 'Cookie' : 'Array_objects/AppleGreen';
    const assetDimen = displayMode === 'digital' ? 70 : 100;

    return (
      <MarkupAssets
        elements={{
          items: (
            <View
              style={{
                flexDirection: 'row',
                gap: 8,
                paddingVertical: 32,
                alignItems: 'center'
              }}
            >
              {countRange(num1).map(i => (
                <AssetSvg
                  key={`${i}_${item}`}
                  name={svgNameObject}
                  height={assetDimen}
                  width={assetDimen}
                />
              ))}
            </View>
          )
        }}
      >
        <QF2AnswerBoxOneSentence
          title={`${translate.ks1Instructions.charHasTheseItems(
            name1,
            itemPluralString
          )}<br/><asset name='items'/><br/>${translate.ks1Instructions[instruction](
            name2,
            difference,
            itemDiffString,
            name1
          )}<br/>${translate.ks1Instructions.howManyXDoesCharAHave(itemPluralString, name2)}`}
          sentenceStyle={{ alignSelf: 'flex-end' }}
          mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
          sentence={'<ans/>'}
          testCorrect={[num2.toString()]}
        />
      </MarkupAssets>
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddOrSubtract1Or2',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
