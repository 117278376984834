import BaseLayout from '../../molecules/BaseLayout';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { useContext, useMemo } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { StyleSheet, View } from 'react-native';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { countRange } from '../../../utils/collections';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { ElementOrRenderFunction, resolveElementOrRenderFunction } from '../../../utils/react';
import { Dimens } from '../../../theme/scaling';

type Props = {
  title: string;
  pdfTitle?: string;
  /**
   * How many of the draggables should be dragged into the dropzone.
   */
  testCorrect: number;
  /**
   * Svg Name/Path to be used for the draggable(s).
   */
  draggablePath: SvgName | JSX.Element;
  /**
   * Number of draggable cards to display.
   */
  numberOfDraggables?: number;
  /** Default: copy */
  moveOrCopy?: 'move' | 'copy';
  topContent?: ElementOrRenderFunction<{ dimens: Dimens }>;
  questionHeight?: number;
  /**
   * Optional custom mark scheme answer text.
   */
  customMarkSchemeAnswer?: { answerText?: string };
  /**
   * Boolean to determine whether to hide the background of the draggable cards.
   * Optional prop, defaults to true.
   */
  hideDraggableCardBackground?: boolean;
};

/**
 * This is a QF that returns one drag-and-drop drop-zone, with only one type of draggable.
 * This draggable is intended to be the same SVG repeated across multiple cards, or just one copy-able
 * draggable.
 * The testCorrect assumes the user will pass in a desired number that should be dropped into the dropzone.
 */
export default function QF18DragIntoABox({
  title,
  pdfTitle,
  testCorrect,
  draggablePath,
  numberOfDraggables = 1,
  moveOrCopy = 'copy',
  topContent,
  questionHeight,
  customMarkSchemeAnswer,
  hideDraggableCardBackground = true
}: Props) {
  const displayMode = useContext(DisplayMode);

  const draggable =
    typeof draggablePath === 'string' ? (
      <AssetSvg name={draggablePath} width={80} height={80} />
    ) : (
      draggablePath
    );

  const items = countRange(numberOfDraggables).map(num => {
    return { component: draggable, value: num };
  });

  const pdfTestCorrectRange = countRange(testCorrect);

  // When there is just one copy-able draggable, the layout will be 5 x 2, otherwise will be 3 x 3 - so this needs to be 10 or 9 respectively.
  const dropzoneCapacity = numberOfDraggables === 1 && moveOrCopy === 'copy' ? 10 : 9;

  const dropSource = (
    <DragAndDropSection>
      {items.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} />
      ))}
    </DragAndDropSection>
  );

  const styles = useStyles(numberOfDraggables, moveOrCopy);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View
                style={[
                  dimens,
                  {
                    flex: 1
                  }
                ]}
              >
                {topContent && (
                  <View
                    style={[
                      {
                        height: dimens.height / 3,
                        width: dimens.width,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }
                    ]}
                  >
                    {resolveElementOrRenderFunction(topContent, {
                      dimens: { height: dimens.height / 3, width: dimens.width }
                    })}
                  </View>
                )}
                {
                  <View
                    style={[
                      StyleSheet.absoluteFill,
                      {
                        alignContent: 'flex-start',
                        justifyContent: 'flex-start',
                        columnGap: 0,
                        height: topContent ? (dimens.height / 3) * 2 : dimens.height,
                        flex: 1,
                        position: 'relative',
                        flexDirection: 'row',
                        flexWrap: 'wrap'
                      }
                    ]}
                  >
                    {displayMode === 'markscheme' &&
                      pdfTestCorrectRange.map(num => (
                        <View key={num} style={styles.draggableContainer}>
                          {typeof draggablePath === 'string' ? (
                            <AssetSvg
                              name={draggablePath}
                              width={80}
                              height={80}
                              style={styles.draggable}
                            />
                          ) : (
                            draggablePath
                          )}
                        </View>
                      ))}
                  </View>
                }
                {displayMode === 'markscheme' &&
                  customMarkSchemeAnswer?.answerText &&
                  renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
              </View>
            )}
          </MeasureView>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy={moveOrCopy}
      defaultState={[[]]}
      testComplete={answer => answer[0].length > 0}
      testCorrect={answer => {
        return answer[0].length === testCorrect;
      }}
      hideBackground={hideDraggableCardBackground}
      draggableStyle={[
        hideDraggableCardBackground && {
          borderWidth: 0,
          backgroundColor: 'transparent',
          shadowOpacity: 0
        }
      ]}
    >
      <BaseLayout
        title={title}
        actionPanelVariant={numberOfDraggables > 3 ? 'endWide' : 'end'}
        actionPanelContents={dropSource}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View
                style={[
                  dimens,
                  {
                    flex: 1
                  }
                ]}
              >
                {topContent && (
                  <View
                    style={[
                      {
                        height: dimens.height / 3,
                        width: dimens.width,
                        alignItems: 'center',
                        justifyContent: 'center'
                      }
                    ]}
                  >
                    {resolveElementOrRenderFunction(topContent, {
                      dimens: { height: dimens.height / 3, width: dimens.width }
                    })}
                  </View>
                )}
                <EasyDragAndDrop.ZoneMultiple
                  style={[
                    StyleSheet.absoluteFill,
                    {
                      alignContent: 'flex-start',
                      justifyContent: 'flex-start',
                      columnGap: 0,
                      height: topContent ? (dimens.height / 3) * 2 : dimens.height,
                      flex: 1,
                      position: 'relative'
                    }
                  ]}
                  id={0}
                  droppedStyle={styles.draggable}
                  wrapperStyle={styles.draggableContainer}
                  capacity={dropzoneCapacity}
                />
              </View>
            )}
          </MeasureView>
        }
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}

const useStyles = (numberOfDraggables: number, moveOrCopy: 'move' | 'copy') => {
  return useMemo(
    () =>
      StyleSheet.create({
        draggableContainer: {
          // When there is just one copy-able draggable, should default to a 5 x 2 layout - otherwise, default to a 3 x 3 layout.
          width: numberOfDraggables === 1 && moveOrCopy === 'copy' ? '20%' : '33.3%',
          height: numberOfDraggables === 1 && moveOrCopy === 'copy' ? '50%' : '33.3%',
          alignItems: 'center',
          justifyContent: 'center'
        },

        draggable: {
          width: 112,
          height: 112,
          alignItems: 'center'
        }
      }),
    [moveOrCopy, numberOfDraggables]
  );
};
