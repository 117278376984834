import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import Pictogram from '../../../../components/question/representations/Pictogram/Pictogram';
import { colors, PictogramColors } from '../../../../theme/colors';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import {
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  getRandomFromArray,
  seededRandom,
  rejectionSample,
  shuffle,
  getRandomSubArrayFromArray,
  getRandomBoolean
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { numberEnum } from '../../../../utils/zod';
import { dayNames, daySchema, getRandomUniqueDays } from '../../../../utils/days';
import { all, create, number } from 'mathjs';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'blu',
  description: 'blu',
  keywords: ['Pictogram'],
  schema: z.object({
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('Pets'),
        zeroPets: z.number().min(1).max(6),
        onePet: z.number().min(1).max(6),
        twoPets: z.number().min(1).max(6),
        threePets: z.number().min(0).max(6),
        numberOfPets: z.number().int().min(0).max(3)
      }),
      z.object({
        variant: z.literal('Days'),
        dayA: daySchema,
        dayAAmount: z.number().min(0).max(6),
        dayB: daySchema,
        dayBAmount: z.number().min(0).max(6),
        dayC: daySchema,
        dayCAmount: z.number().min(0).max(6),
        dayD: daySchema,
        dayDAmount: z.number().min(0).max(6),
        mostOrFewest: z.enum(['most', 'fewest']),
        item: z.enum(['cakes', 'cookies', 'drinks', 'ice creams', 'muffins'])
      })
    ]),
    key: numberEnum([2, 5, 10]),
    color: z.string()
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['Pets', 'Days'] as const);

    // Schema 1
    const threePets = randomIntegerInclusive(0, 6);

    const [zeroPets, onePet, twoPets] = randomUniqueIntegersInclusive(1, 6, 3, {
      constraint: x => x !== threePets
    });

    const numberOfPets = randomIntegerInclusive(0, 3);

    // Schema 2
    const [dayA, dayB, dayC, dayD] = getRandomUniqueDays(4);

    const [dayAAmount, dayBAmount, dayCAmount, dayDAmount] = randomUniqueIntegersInclusive(0, 6, 4);

    const mostOrFewest = getRandomFromArray(['most', 'fewest'] as const);

    const item = getRandomFromArray([
      'cakes',
      'cookies',
      'drinks',
      'ice creams',
      'muffins'
    ] as const);

    const subQuestion =
      variation === 'Pets'
        ? {
            variant: variation,
            zeroPets,
            onePet,
            twoPets,
            threePets,
            numberOfPets
          }
        : {
            variant: variation,
            dayA,
            dayAAmount,
            dayB,
            dayBAmount,
            dayC,
            dayCAmount,
            dayD,
            dayDAmount,
            mostOrFewest,
            item
          };

    // Global schema
    const key = getRandomFromArray([2, 5, 10] as const);
    const color = getRandomFromArray(PictogramColors) as string;

    return {
      subQuestion,
      key,
      color
    };
  },
  Component: props => {
    const {
      question: { subQuestion, key, color },
      translate
    } = props;

    const {
      title,
      pdfTitle,
      selectables,
      correctAnswer,
      itemData = [],
      sortedData = []
    } = (() => {
      switch (subQuestion.variant) {
        case 'Pets': {
          const title = translate.ks1Instructions.selectHowManyChildrenHaveNumPets(
            subQuestion.numberOfPets
          );
          const pdfTitle = translate.ks1PDFInstructions.tickHowManyChildrenHaveNumPets(
            subQuestion.numberOfPets
          );

          const selectables = [
            (subQuestion.zeroPets * key).toLocaleString(),
            (subQuestion.onePet * key).toLocaleString(),
            (subQuestion.twoPets * key).toLocaleString(),
            (subQuestion.threePets * key).toLocaleString()
          ];

          const petValue =
            subQuestion.numberOfPets === 0
              ? subQuestion.zeroPets * key
              : subQuestion.numberOfPets === 1
              ? subQuestion.onePet * key
              : subQuestion.numberOfPets === 2
              ? subQuestion.twoPets * key
              : subQuestion.threePets * key;

          const correctAnswer = petValue;

          return {
            title,
            pdfTitle,
            selectables,
            correctAnswer
          };
        }

        case 'Days': {
          const dayAString = translate.time[subQuestion.dayA]();

          const dayBString = translate.time[subQuestion.dayB]();

          const dayCString = translate.time[subQuestion.dayC]();

          const dayDString = translate.time[subQuestion.dayD]();

          const data = [
            {
              day: subQuestion.dayA,
              string: dayAString,
              value: subQuestion.dayAAmount
            },
            {
              day: subQuestion.dayB,
              string: dayBString,
              value: subQuestion.dayBAmount
            },
            {
              day: subQuestion.dayC,
              string: dayCString,
              value: subQuestion.dayCAmount
            },
            {
              day: subQuestion.dayD,
              string: dayDString,
              value: subQuestion.dayDAmount
            }
          ];

          const sortedData = [...data].sort((a, b) => {
            const indexA = dayNames.indexOf(a.day);
            const indexB = dayNames.indexOf(b.day);
            return indexA - indexB;
          });

          const selectables = [data[0].string, data[1].string, data[2].string, data[3].string];

          const correctAnswer =
            subQuestion.mostOrFewest === 'most'
              ? data.reduce((max, obj) => (obj.value > max.value ? obj : max), data[0]).day
              : data.reduce((min, obj) => (obj.value < min.value ? obj : min), data[0]).day;

          const itemData = [];

          switch (subQuestion.item) {
            case 'cakes':
              itemData.push(
                translate.food.Cakes(0),
                translate.tableHeaders.numberOfCakesSold(),
                translate.keys.numCakes(key)
              );
            case 'cookies':
              itemData.push(
                translate.food.Cookies(0),
                translate.tableHeaders.numberOfCookiesSold(),
                translate.keys.numCookies(key)
              );
            case 'drinks':
              itemData.push(
                translate.drink.Drinks(0),
                translate.tableHeaders.numberOfDrinksSold(),
                translate.keys.numDrinks(key)
              );
            case 'ice creams':
              itemData.push(
                translate.food.IceCreams(0),
                translate.tableHeaders.IceCreamsSold(),
                translate.keys.numIceCreams(key)
              );
            case 'muffins':
              itemData.push(
                translate.food.Muffins(0),
                translate.tableHeaders.numberOfMuffinsSold(),
                translate.keys.numMuffins(key)
              );
          }

          const title =
            subQuestion.mostOrFewest === 'most'
              ? translate.ks1Instructions.selectDayOnWhichMostXSold(itemData[0])
              : translate.ks1Instructions.selectDayOnWhichFewestXSold(itemData[0]);

          const pdfTitle =
            subQuestion.mostOrFewest === 'most'
              ? translate.ks1PDFInstructions.tickDayOnWhichMostXSold(itemData[0])
              : translate.ks1PDFInstructions.tickDayOnWhichFewestXSold(itemData[0]);

          return {
            itemData,
            sortedData,
            selectables,
            title,
            pdfTitle,
            correctAnswer
          };
        }
      }
    })();

    return (
      <QF39ContentWithSelectablesOnRight
        title={title}
        pdfTitle={pdfTitle}
        correctAnswer={[correctAnswer.toString()]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => {
              return subQuestion.variant === 'Pets' ? (
                <Pictogram
                  displayValues={[
                    subQuestion.zeroPets,
                    subQuestion.onePet,
                    subQuestion.twoPets,
                    subQuestion.threePets
                  ]}
                  columnNames={[
                    translate.tableHeaders.numberOfPets(),
                    translate.tableHeaders.numberOfChildren()
                  ]}
                  rowData={filledArray(0, 4).map((_, i) => [i.toLocaleString()])}
                  dimens={dimens}
                  color={color}
                  keyValue={translate.keys.numChildren(key)}
                />
              ) : (
                sortedData && (
                  <Pictogram
                    displayValues={sortedData.slice(0, 4).map(item => item.value)}
                    columnNames={[translate.keywords.Day(), itemData[1]]}
                    rowData={sortedData.slice(0, 4).map(item => [item.string])}
                    dimens={dimens}
                    color={color}
                    keyValue={itemData[2]}
                  />
                )
              );
            }}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'blv',
  description: 'blv',
  keywords: ['Pictogram'],
  schema: z.object({
    subQuestion: z.discriminatedUnion('variant', [
      z.object({
        variant: z.literal('Pets'),
        zeroPets: z.number().min(1).max(6).step(0.5),
        onePet: z.number().min(1).max(6).step(0.5),
        twoPets: z.number().min(1).max(6).step(0.5),
        threePets: z.number().min(0).max(6).step(0.5),
        numberOfPets: z.number().int().min(0).max(3)
      }),
      z.object({
        variant: z.literal('Days'),
        dayA: daySchema,
        dayAAmount: z.number().min(0).max(6).step(0.5),
        dayB: daySchema,
        dayBAmount: z.number().min(0).max(6).step(0.5),
        dayC: daySchema,
        dayCAmount: z.number().min(0).max(6).step(0.5),
        dayD: daySchema,
        dayDAmount: z.number().min(0).max(6).step(0.5),
        mostOrFewest: z.enum(['most', 'fewest']),
        item: z.enum(['cakes', 'cookies', 'drinks', 'ice creams', 'muffins'])
      })
    ]),
    // Global schema
    key: numberEnum([2, 10]),
    color: z.string()
  }),
  simpleGenerator: () => {
    const variation = getRandomFromArray(['Pets', 'Days'] as const);

    // Schema 1
    const { threePets, zeroPets, onePet, twoPets } = rejectionSample(
      () => {
        const threePets =
          randomIntegerInclusive(0, 12) === 0
            ? 0
            : number(math.evaluate(`${randomIntegerInclusive(2, 12)} / 2`));

        const [zeroPets, onePet, twoPets] = randomUniqueIntegersInclusive(2, 12, 3).map(num =>
          number(math.evaluate(`${num} / 2`))
        );

        return { threePets, zeroPets, onePet, twoPets };
      },
      ({ threePets, zeroPets, onePet, twoPets }) => {
        const pets = [threePets, zeroPets, onePet, twoPets];
        // Ensure no duplicates and one pet will have a half circle
        return arrayHasNoDuplicates(pets) && pets.some(pet => pet % 1 !== 0);
      }
    );

    const numberOfPets = randomIntegerInclusive(0, 3);

    // Schema 2
    const [dayA, dayB, dayC, dayD] = getRandomUniqueDays(4);

    const { dayAAmount, dayBAmount, dayCAmount, dayDAmount } = rejectionSample(
      () => {
        const [dayAAmount, dayBAmount, dayCAmount, dayDAmount] = randomUniqueIntegersInclusive(
          0,
          12,
          4
        ).map(num => (num === 0 ? 0 : number(math.evaluate(`${num} / 2`))));
        return { dayAAmount, dayBAmount, dayCAmount, dayDAmount };
      },
      ({ dayAAmount, dayBAmount, dayCAmount, dayDAmount }) => {
        const days = [dayAAmount, dayBAmount, dayCAmount, dayDAmount];
        // Ensure one day will have a half circle
        return days.some(day => day % 1 !== 0);
      }
    );

    const mostOrFewest = getRandomFromArray(['most', 'fewest'] as const);

    const item = getRandomFromArray([
      'cakes',
      'cookies',
      'drinks',
      'ice creams',
      'muffins'
    ] as const);

    const subQuestion =
      variation === 'Pets'
        ? {
            variant: variation,
            zeroPets,
            onePet,
            twoPets,
            threePets,
            numberOfPets
          }
        : {
            variant: variation,
            dayA,
            dayAAmount,
            dayB,
            dayBAmount,
            dayC,
            dayCAmount,
            dayD,
            dayDAmount,
            mostOrFewest,
            item
          };

    // Global schema
    const key = getRandomFromArray([2, 10] as const);
    const color = getRandomFromArray(PictogramColors) as string;

    return {
      subQuestion,
      key,
      color
    };
  },
  Component: props => {
    const {
      question: { subQuestion, key, color },
      translate
    } = props;

    const {
      title,
      pdfTitle,
      selectables,
      correctAnswer,
      itemData = [],
      sortedData = []
    } = (() => {
      switch (subQuestion.variant) {
        case 'Pets': {
          const title = translate.ks1Instructions.selectHowManyChildrenHaveNumPets(
            subQuestion.numberOfPets
          );
          const pdfTitle = translate.ks1PDFInstructions.tickHowManyChildrenHaveNumPets(
            subQuestion.numberOfPets
          );

          const selectables = [
            (subQuestion.zeroPets * key).toLocaleString(),
            (subQuestion.onePet * key).toLocaleString(),
            (subQuestion.twoPets * key).toLocaleString(),
            (subQuestion.threePets * key).toLocaleString()
          ];

          const petValue =
            subQuestion.numberOfPets === 0
              ? subQuestion.zeroPets * key
              : subQuestion.numberOfPets === 1
              ? subQuestion.onePet * key
              : subQuestion.numberOfPets === 2
              ? subQuestion.twoPets * key
              : subQuestion.threePets * key;

          const correctAnswer = petValue;

          return {
            title,
            pdfTitle,
            selectables,
            correctAnswer
          };
        }

        case 'Days': {
          const dayAString = translate.time[subQuestion.dayA]();

          const dayBString = translate.time[subQuestion.dayB]();

          const dayCString = translate.time[subQuestion.dayC]();

          const dayDString = translate.time[subQuestion.dayD]();

          const data = [
            {
              day: subQuestion.dayA,
              string: dayAString,
              value: subQuestion.dayAAmount
            },
            {
              day: subQuestion.dayB,
              string: dayBString,
              value: subQuestion.dayBAmount
            },
            {
              day: subQuestion.dayC,
              string: dayCString,
              value: subQuestion.dayCAmount
            },
            {
              day: subQuestion.dayD,
              string: dayDString,
              value: subQuestion.dayDAmount
            }
          ];

          const sortedData = [...data].sort((a, b) => {
            const indexA = dayNames.indexOf(a.day);
            const indexB = dayNames.indexOf(b.day);
            return indexA - indexB;
          });

          const selectables = [data[0].string, data[1].string, data[2].string, data[3].string];

          const correctAnswer =
            subQuestion.mostOrFewest === 'most'
              ? data.reduce((max, obj) => (obj.value > max.value ? obj : max), data[0]).day
              : data.reduce((min, obj) => (obj.value < min.value ? obj : min), data[0]).day;

          const itemData = [];

          switch (subQuestion.item) {
            case 'cakes':
              itemData.push(
                translate.food.Cakes(0),
                translate.tableHeaders.numberOfCakesSold(),
                translate.keys.numCakes(key)
              );
            case 'cookies':
              itemData.push(
                translate.food.Cookies(0),
                translate.tableHeaders.numberOfCookiesSold(),
                translate.keys.numCookies(key)
              );
            case 'drinks':
              itemData.push(
                translate.drink.Drinks(0),
                translate.tableHeaders.numberOfDrinksSold(),
                translate.keys.numDrinks(key)
              );
            case 'ice creams':
              itemData.push(
                translate.food.IceCreams(0),
                translate.tableHeaders.IceCreamsSold(),
                translate.keys.numIceCreams(key)
              );
            case 'muffins':
              itemData.push(
                translate.food.Muffins(0),
                translate.tableHeaders.numberOfMuffinsSold(),
                translate.keys.numMuffins(key)
              );
          }

          const title =
            subQuestion.mostOrFewest === 'most'
              ? translate.ks1Instructions.selectDayOnWhichMostXSold(itemData[0])
              : translate.ks1Instructions.selectDayOnWhichFewestXSold(itemData[0]);

          const pdfTitle =
            subQuestion.mostOrFewest === 'most'
              ? translate.ks1PDFInstructions.tickDayOnWhichMostXSold(itemData[0])
              : translate.ks1PDFInstructions.tickDayOnWhichFewestXSold(itemData[0]);

          return {
            itemData,
            sortedData,
            selectables,
            title,
            pdfTitle,
            correctAnswer
          };
        }
      }
    })();

    return (
      <QF39ContentWithSelectablesOnRight
        title={title}
        pdfTitle={pdfTitle}
        correctAnswer={[correctAnswer.toString()]}
        selectables={Object.fromEntries(
          selectables.map(selectable => [selectable.toLocaleString(), selectable.toLocaleString()])
        )}
        leftContent={
          <MeasureView>
            {dimens => {
              return subQuestion.variant === 'Pets' ? (
                <Pictogram
                  displayValues={[
                    subQuestion.zeroPets,
                    subQuestion.onePet,
                    subQuestion.twoPets,
                    subQuestion.threePets
                  ]}
                  columnNames={[
                    translate.tableHeaders.numberOfPets(),
                    translate.tableHeaders.numberOfChildren()
                  ]}
                  rowData={filledArray(0, 4).map((_, i) => [i.toLocaleString()])}
                  dimens={dimens}
                  color={color}
                  keyValue={translate.keys.numChildren(key)}
                />
              ) : (
                sortedData && (
                  <Pictogram
                    displayValues={sortedData.slice(0, 4).map(item => item.value)}
                    columnNames={[translate.keywords.Day(), itemData[1]]}
                    rowData={sortedData.slice(0, 4).map(item => [item.string])}
                    dimens={dimens}
                    color={color}
                    keyValue={itemData[2]}
                  />
                )
              );
            }}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'blw',
  description: 'blw',
  keywords: ['Pictogram', 'More', 'Fewer'],
  schema: z
    .object({
      countersA: z.number().min(2).max(12),
      countersB: z.number().min(1).max(10),
      countersC: z.number().min(1).max(12),
      countersD: z.number().min(1).max(12),
      colours: z
        .array(
          z.enum(['black', 'blue', 'green', 'orange', 'pink', 'purple', 'red', 'silver', 'yellow'])
        )
        .length(4),
      amountPerCounter: numberEnum([2, 5, 10]),
      mostOrFewest: z.enum(['most', 'fewest'])
    })
    .refine(val => val.countersA > val.countersB, 'countersA must be greater than countersB.'),
  simpleGenerator: () => {
    const halfCircleVariation = getRandomBoolean();

    const { countersA, countersB, countersC, countersD } = rejectionSample(
      () => {
        const countersA = halfCircleVariation
          ? number(math.evaluate(`${randomIntegerInclusive(4, 12)} / 2`))
          : randomIntegerInclusive(2, 6);

        // Must be less than countersA:
        const countersB = halfCircleVariation
          ? number(math.evaluate(`${randomIntegerInclusive(2, 10)} / 2`))
          : randomIntegerInclusive(1, 5);

        //
        const [countersC, countersD] = halfCircleVariation
          ? randomUniqueIntegersInclusive(2, 12, 2).map(num => number(math.evaluate(`${num} / 2`)))
          : randomUniqueIntegersInclusive(1, 6, 2);

        return { halfCircleVariation, countersA, countersB, countersC, countersD };
      },
      ({ countersA, countersB, countersC, countersD }) => {
        const counters = [countersA, countersB, countersC, countersD];

        // countersB must be less than countersA
        // Counters and answer must not be equal
        // And if half circle variation ensure one row of counters has a circle circle
        return (
          countersB < countersA &&
          arrayHasNoDuplicates([
            countersA,
            countersB,
            countersC,
            countersD,
            Math.abs(countersA - countersB)
          ]) &&
          (halfCircleVariation ? counters.some(counter => counter % 1 !== 0) : true)
        );
      }
    );

    const colours = getRandomSubArrayFromArray(
      ['black', 'blue', 'green', 'orange', 'pink', 'purple', 'red', 'silver', 'yellow'] as const,
      4
    );

    const amountPerCounter = getRandomFromArray(
      halfCircleVariation ? ([2, 10] as const) : ([2, 5, 10] as const)
    );

    const mostOrFewest = getRandomFromArray(['most', 'fewest'] as const);

    return {
      countersA,
      countersB,
      countersC,
      countersD,
      colours,
      amountPerCounter,
      mostOrFewest
    };
  },
  Component: props => {
    const {
      question: {
        countersA,
        countersB,
        countersC,
        countersD,
        colours,
        amountPerCounter,
        mostOrFewest
      },
      translate
    } = props;

    const [colourA, colourB, colourC, colourD] = colours;

    const colourAString = translate.colors[colourA]();

    const colourBString = translate.colors[colourB]();

    const colourCString = translate.colors[colourC]();

    const colourDString = translate.colors[colourD]();

    const data = shuffle(
      [
        {
          colour: colors.pacificBlue,
          string: colourAString,
          counters: countersA
        },
        {
          colour: colors.pacificBlue,
          string: colourBString,
          counters: countersB
        },
        {
          colour: colors.pacificBlue,
          string: colourCString,
          counters: countersC
        },
        {
          colour: colors.pacificBlue,
          string: colourDString,
          counters: countersD
        }
      ],
      {
        random: seededRandom(props.question)
      }
    );

    const answer = Math.abs(countersA - countersB) * amountPerCounter;

    const selectables = shuffle(
      [
        ['A', answer.toLocaleString()],
        ['B', (countersA * amountPerCounter).toLocaleString()],
        ['C', (countersC * amountPerCounter).toLocaleString()],
        ['D', (countersD * amountPerCounter).toLocaleString()]
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF39ContentWithSelectablesOnRight
        title={
          mostOrFewest === 'most'
            ? translate.ks1Instructions.selectHowManyMoreCarsAreXThanY(colourAString, colourBString)
            : translate.ks1Instructions.selectHowManyFewerCarsAreXThanY(
                colourBString,
                colourAString
              )
        }
        pdfTitle={
          mostOrFewest === 'most'
            ? translate.ks1PDFInstructions.tickHowManyMoreCarsAreXThanY(
                colourAString,
                colourBString
              )
            : translate.ks1PDFInstructions.tickHowManyFewerCarsAreXThanY(
                colourBString,
                colourAString
              )
        }
        correctAnswer={['A']}
        selectables={Object.fromEntries(selectables)}
        leftContent={
          <MeasureView>
            {dimens => (
              <Pictogram
                displayValues={[
                  data[0].counters,
                  data[1].counters,
                  data[2].counters,
                  data[3].counters
                ]}
                columnNames={[
                  translate.tableHeaders.Colour(),
                  translate.tableHeaders.numberOfCarsInCarPark()
                ]}
                rowData={[[data[0].string], [data[1].string], [data[2].string], [data[3].string]]}
                dimens={dimens}
                rowColors={[
                  colors.pacificBlue,
                  colors.pacificBlue,
                  colors.pacificBlue,
                  colors.pacificBlue
                ]}
                keyValue={translate.keys.numCars(amountPerCounter)}
                keyColor={colors.pacificBlue}
              />
            )}
          </MeasureView>
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'InterpretPictograms25And10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
