import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  dayAsWord,
  dayNames,
  daySchema,
  getRandomAmountOfDaysIncludingDay
} from '../../../../utils/days';
import Text from 'common/src/components/typography/Text';
import { View } from 'react-native';
import { colors } from '../../../../theme/colors';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getRandomName, nameSchema } from '../../../../utils/names';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { CustomizableTable } from '../../../../components/question/representations/CustomizableTable';
import { countRange } from '../../../../utils/collections';

////
// Questions
////

type Activities =
  | 'Football'
  | 'Swimming'
  | 'Shopping'
  | 'Party'
  | 'Reading'
  | 'Bike ride'
  | 'Painting';

const icons: Record<Activities, SvgName> = {
  Football: 'Sports_ball_football',
  Swimming: 'full_swimming_pool',
  Shopping: 'Shopping_trolley',
  Party: 'Celebration_card',
  Reading: 'BookRed',
  'Bike ride': 'Bike',
  Painting: 'Paint_brush'
};

const Question1 = newQuestionContent({
  uid: 'bfx',
  description: 'bfx',
  keywords: ['Days of the week', 'Time'],
  schema: z.object({
    activities: z
      .enum(['Football', 'Swimming', 'Shopping', 'Party', 'Reading', 'Bike ride', 'Painting'])
      .array(),
    daysWithActivities: z.array(daySchema),
    days: z.array(daySchema).length(3),
    selectedDay: daySchema,
    name: nameSchema
  }),
  simpleGenerator: () => {
    const amountOfActivities = randomIntegerInclusive(4, 7);

    // Get random activities
    const activities = getRandomSubArrayFromArray(
      ['Football', 'Swimming', 'Shopping', 'Party', 'Reading', 'Bike ride', 'Painting'] as const,
      amountOfActivities
    );

    // Shuffle day names and assign activities to them
    const shuffledDays = shuffle(dayNames);
    const daysWithActivities = shuffledDays.slice(0, activities.length);

    // Randomly select `selectedDay` from days with activities
    const selectedDay = getRandomSubArrayFromArray(daysWithActivities, 1)[0];

    // Get two other random days excluding `selectedDay`
    const randomIncorrectDays = getRandomSubArrayFromArray(
      daysWithActivities.filter(day => day !== selectedDay),
      2
    );

    // Combine `selectedDay` and incorrect days into the `days` array
    const days = shuffle([selectedDay, ...randomIncorrectDays]);

    const name = getRandomName();

    return { activities, daysWithActivities, days, selectedDay, name };
  },
  Component: props => {
    const {
      question: { activities, daysWithActivities, days, selectedDay, name },
      translate,
      displayMode
    } = props;

    const translations: Record<Activities, string> = {
      Football: translate.items.football(1),
      Swimming: translate.activities.swimming(),
      Shopping: translate.activities.shopping(),
      Party: translate.activities.party(),
      Reading: translate.activities.reading(),
      'Bike ride': translate.activities['bike ride'](),
      Painting: translate.activities.painting()
    };

    const weekActivities: {
      [day: string]: {
        activity: Activities | '';
        translatedActivity: string;
        icon: SvgName | '';
      };
    } = {};

    // Set all days to a blank activity object
    dayNames.forEach(day => {
      const index = daysWithActivities.indexOf(day);
      const activity = activities[index];
      weekActivities[day] =
        index !== -1
          ? {
              activity: activity,
              translatedActivity: translations[activity],
              icon: icons[activity]
            }
          : { activity: '', translatedActivity: '', icon: '' };
    });

    const weekActivitiesDisplay = Object.values(weekActivities);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={days}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        sentenceStyle={{ alignSelf: 'flex-start' }}
        pdfItemVariant="rectangle"
        Content={
          <CustomizableTable
            cellHeaders={dayNames.map(day => {
              return {
                label: day.slice(0, 3).toLocaleString(),
                containerStyle: {
                  backgroundColor: colors.prussianBlue
                },
                textStyle: {
                  color: colors.white,
                  fontSize: displayMode === 'digital' ? 20 : 40
                }
              };
            })}
            tableData={countRange(1).map(() =>
              weekActivitiesDisplay.map((activity, idx) => (
                <View key={idx} style={{ paddingTop: 8, paddingBottom: 8 }}>
                  <>
                    {activity.icon && (
                      <View style={{ minHeight: 150, alignItems: 'center' }}>
                        <AssetSvg name={activity.icon} width={100} height={100} />
                      </View>
                    )}
                  </>
                  <Text
                    style={{
                      fontSize: displayMode === 'digital' ? 20 : 40,
                      textAlign: 'center'
                    }}
                  >
                    {activity.translatedActivity}
                  </Text>
                </View>
              ))
            )}
          />
        }
        sentence={
          weekActivities[selectedDay].activity === 'Reading' ||
          weekActivities[selectedDay].activity === 'Painting'
            ? translate.ks1AnswerSentences.onAnsXNameDidSomeYActivity(
                name,
                weekActivities[selectedDay].translatedActivity
              )
            : weekActivities[selectedDay].activity === 'Football'
            ? translate.ks1AnswerSentences.onAnsXNameWentToYActivity(
                name,
                weekActivities[selectedDay].translatedActivity
              )
            : weekActivities[selectedDay].activity === 'Bike ride'
            ? translate.ks1AnswerSentences.onAnsXNameWentOnAYActivity(
                name,
                weekActivities[selectedDay].translatedActivity
              )
            : weekActivities[selectedDay].activity === 'Party'
            ? translate.ks1AnswerSentences.onAnsXNameWentToAYActivity(
                name,
                weekActivities[selectedDay].translatedActivity
              )
            : translate.ks1AnswerSentences.onAnsXNameWentYActivity(
                name,
                weekActivities[selectedDay].translatedActivity
              )
        }
        testCorrect={[selectedDay]}
        questionHeight={1200}
        pdfLayout="itemsTop"
      />
    );
  },
  questionHeight: 1200
});

const Question2 = newQuestionContent({
  uid: 'bfy',
  description: 'bfy',
  keywords: ['Days of the week', 'Time'],
  schema: z.object({
    dayOptions: z.array(daySchema).length(4),
    selectedDayIdx: z.number().int().min(0).max(3)
  }),
  simpleGenerator: () => {
    const selectedDayIdx = randomIntegerInclusive(0, 3);
    const dayOptions = getRandomAmountOfDaysIncludingDay(4, dayNames[selectedDayIdx]);

    return { dayOptions, selectedDayIdx };
  },
  Component: props => {
    const {
      question: { dayOptions, selectedDayIdx },
      translate
    } = props;

    const random = seededRandom(props.question);

    const items = [dayNames[selectedDayIdx], ...dayNames.filter(day => !dayOptions.includes(day))];

    const daysShuffled = shuffle(items, { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.theDaysAreInOrderSelectTheMissingDayOfTheWeek()}
        pdfTitle={translate.ks1PDFInstructions.theDaysAreInOrderTickTheMissingDayOfTheWeek()}
        testCorrect={[dayNames[selectedDayIdx]]}
        mainPanelFlexDirection="row"
        itemLayout="column"
        numItems={4}
        itemStyle={{ height: 100 }}
        Content={({ dimens }) => (
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              rowGap: 12,
              height: dimens.height
            }}
          >
            {dayOptions.map((day, idx) => {
              return day === dayNames[selectedDayIdx] ? (
                <View
                  key={idx}
                  style={{
                    backgroundColor: colors.burntSiennaMedium,
                    borderWidth: 2,
                    borderColor: colors.black,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 480,
                    height: dimens.height / 5
                  }}
                >
                  <Text variant="WRN700" style={{ color: colors.black }}>
                    ?
                  </Text>
                </View>
              ) : (
                <View
                  key={idx}
                  style={{
                    backgroundColor: colors.burntSiennaMedium,
                    borderWidth: 2,
                    borderColor: colors.black,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 480,
                    height: dimens.height / 5
                  }}
                >
                  <Text variant="WRN700" style={{ color: colors.black }}>
                    {day}
                  </Text>
                </View>
              );
            })}
          </View>
        )}
        renderItems={daysShuffled.map(day => ({
          value: day,
          component: <Text variant="WRN700">{day}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bfz',
  description: 'bfz',
  keywords: ['Days of the week', 'Tomorrow', 'Yesterday', 'Today', 'Time'],
  schema: z.object({
    beforeOrAfter: z.enum(['Before', 'After']),
    selectedDayIdx: z.number().int().min(0).max(6)
  }),
  simpleGenerator: () => {
    const beforeOrAfter = getRandomFromArray(['Before', 'After'] as const);
    const selectedDayIdx = randomIntegerInclusive(0, 6);

    return { beforeOrAfter, selectedDayIdx };
  },
  Component: props => {
    const {
      question: { beforeOrAfter, selectedDayIdx },
      translate
    } = props;

    const selectedDay = dayNames[selectedDayIdx];

    const currentDayIdxInDays = dayNames.indexOf(selectedDay);

    const prevDayIdx = (currentDayIdxInDays - 1 + dayNames.length) % dayNames.length;
    const prevDay = dayNames[prevDayIdx];

    const nextDayIdx = (currentDayIdxInDays + 1) % dayNames.length;
    const nextDay = dayNames[nextDayIdx];

    const incorrectDayIdx = (currentDayIdxInDays + 2) % dayNames.length;
    const incorrectDay = dayNames[incorrectDayIdx];

    const items = shuffle(
      [
        { value: prevDay, component: <Text variant="WRN700">{prevDay.toLocaleString()}</Text> },
        { value: nextDay, component: <Text variant="WRN700">{nextDay.toLocaleString()}</Text> },
        {
          value: incorrectDay,
          component: <Text variant="WRN700">{incorrectDay.toLocaleString()}</Text>
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.useCardsCompleteSentence()}
        items={items}
        itemVariant="rectangle"
        sentenceStyle={{ alignItems: 'center', justifyContent: 'center' }}
        actionPanelVariant="endWide"
        sentence={translate.ks1AnswerSentences.theDayBeforeOrAfterXDayIsAns(
          beforeOrAfter === 'Before'
            ? translate.ks1MiscStrings.before()
            : translate.ks1MiscStrings.after(),
          dayAsWord(selectedDay, translate)
        )}
        pdfLayout="itemsTop"
        pdfItemVariant="tallRectangle"
        testCorrect={beforeOrAfter === 'Before' ? [prevDay] : [nextDay]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'DaysOfTheWeek',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
