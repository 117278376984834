import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  rejectionSample
} from 'common/src/utils/random';
import { z } from 'zod';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import { numbersExchangeAt, numbersOnlyExchangeAt } from 'common/src/utils/exchanges';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { SUB } from 'common/src/constants';
import { arrayHasNoDuplicates, countRange, range } from 'common/src/utils/collections';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { roundToTheNearest } from '../../../../utils/math';
import QF25JumpOnANumberLine from '../../../../components/question/questionFormats/QF25JumpOnANumberLine';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'ags',
  description: 'ags',
  keywords: ['Subtraction', '10s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(140).max(950)
    })
    .refine(
      val => val.startingNumber % 100 === 40 || val.startingNumber % 100 === 50,
      'startingNumber must end in 40 or 50.'
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(140, 950, 10, {
      constraint: x => x % 100 === 40 || x % 100 === 50
    });
    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber - 10).toLocaleString(),
      (startingNumber - 20).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber - 30).toString(),
      (startingNumber - 40).toString(),
      (startingNumber - 50).toString(),
      (startingNumber - 60).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'ags2',
  description: 'ags',
  keywords: ['Subtraction', '10s', 'Track'],
  schema: z
    .object({
      startingNumber: z.number().int().min(140).max(950).step(10)
    })
    .refine(
      val => val.startingNumber % 100 <= 50 && val.startingNumber % 100 >= 10,
      'startingNumber must end in between 10 and 50 .'
    ),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(140, 950, 10, {
      constraint: x => x % 100 <= 50 && x % 100 >= 10
    });
    return { startingNumber };
  },
  Component: ({ question: { startingNumber }, translate }) => {
    // Create array to pass to NumberTrack
    const numberArray = [
      startingNumber.toLocaleString(),
      (startingNumber - 10).toLocaleString(),
      (startingNumber - 20).toLocaleString(),
      '<ans/>',
      '<ans/>',
      '<ans/>',
      '<ans/>'
    ];

    const answerArray = [
      (startingNumber - 30).toString(),
      (startingNumber - 40).toString(),
      (startingNumber - 50).toString(),
      (startingNumber - 60).toString()
    ];

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        boxValues={numberArray}
        testCorrect={answerArray}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'agt',
  description: 'agt',
  keywords: ['Subtraction', '10s', 'Number line'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(980).multipleOf(10),
      number2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 + number2 must exchange tens'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(20, 90, 10);
    const number1 = randomIntegerInclusiveStep(110, 980, 10, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'tens')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    // Array of tick values for number line
    const tickValues = range(number3 - 90, number3 + 10, 10);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={`${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        title={translate.instructions.workOutTheSubtraction()}
        testCorrect={[number1.toString()]}
        Content={({ dimens }) => <NumberLine tickValues={tickValues} dimens={dimens} />}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'agu',
  description: 'agu',
  keywords: ['Number line', 'Subtract', '10s', 'Partition'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(990).multipleOf(10),
      number2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => val.number1 + val.number2 < 1000,
      'number1 + number2 must not sum to more than 1000'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(20, 90, 10);

    const number1 = randomIntegerInclusiveStep(110, 990, 10, {
      constraint: x =>
        // Numbers Exchange Tens -> Hundreds & prevent slipping into 1000s
        numbersExchangeAt(x, number2, 'tens') &&
        x + number2 < 1000 &&
        // Must prevent number ticks that are 1 apart - not enough space for jumps with answer boxes that are this size:
        x % 100 !== 90 &&
        (x + number2) % 100 !== 10
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const middleTick = roundToTheNearest(number3, 100, 'down');

    const answer1 = number3 - middleTick;
    const answer2 = middleTick - number1;
    const answer3 = number1;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray =
      number3 % 100 === 0
        ? [
            {
              label: '<ans/>',
              position: startingNumber
            },
            { label: endNumber.toLocaleString(), position: endNumber }
          ]
        : [
            {
              label: '<ans/>',
              position: startingNumber
            },
            { label: middleTick.toLocaleString(), position: middleTick },
            { label: endNumber.toLocaleString(), position: endNumber }
          ];

    const jumpArrowArray =
      number3 % 100 === 0
        ? [{ start: endNumber, end: startingNumber, label: `${SUB}<ans/>` }]
        : [
            { start: endNumber, end: middleTick, label: `${SUB}<ans/>` },
            { start: middleTick, end: startingNumber, label: `${SUB}<ans/>` }
          ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLineToWorkOutX(
          `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
        )}
        testCorrect={
          number3 % 100 === 0
            ? [answer2.toString(), answer3.toString()]
            : [answer1.toString(), answer2.toString(), answer3.toString()]
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        subtraction
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'agv',
  description: 'agv',
  keywords: ['Number line', 'Subtract', '10s', 'Partition'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(131)
        .max(879)
        .refine(val => val % 10 !== 0, 'number1 cannot be a multiple of 10'),
      number2: z.number().int().min(30).max(90).multipleOf(10)
    })
    .refine(
      val => val.number1 + val.number2 < 1000,
      'number1 + number2 must not sum to more than 1000'
    ),
  simpleGenerator: () => {
    const { number1, number2 } = rejectionSample(
      () => {
        const number2 = randomIntegerInclusiveStep(30, 90, 10);

        const number1 = randomIntegerInclusive(131, 879, {
          constraint: x =>
            // Should have at least 1 one:
            x % 10 !== 0 &&
            // Must be less than 8 tens to allow for a large enough jump:
            x % 100 < 80
        });

        return { number1, number2 };
      },
      // Only permit them if their products are all different.
      ({ number1, number2 }) =>
        // Must exchange:
        numbersExchangeAt(number1, number2, 'tens') &&
        // Must total to less than 1,000:
        number1 + number2 < 1000 &&
        // Total must have at least 2 tens to allow for a large enough jump:
        (number1 + number2) % 100 > 20
    );

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const middleTick = roundToTheNearest(number3, 100, 'down') + (number3 % 10);

    const answer1 = number3 - middleTick;
    const answer2 = middleTick - number1;
    const answer3 = number1;

    const startingNumber = number1;
    const endNumber = number3;

    const tickArray = [
      {
        label: '<ans/>',
        position: startingNumber
      },
      { label: middleTick.toLocaleString(), position: middleTick },
      { label: endNumber.toLocaleString(), position: endNumber }
    ];

    const jumpArrowArray = [
      { start: endNumber, end: middleTick, label: `${SUB}<ans/>` },
      { start: middleTick, end: startingNumber, label: `${SUB}<ans/>` }
    ];

    return (
      <QF25JumpOnANumberLine
        start={startingNumber}
        end={endNumber}
        title={translate.instructions.completeNumberLineToWorkOutX(
          `${number3.toLocaleString()} ${SUB} ${number2.toLocaleString()}`
        )}
        testCorrect={
          number3 % 100 === 0
            ? [answer2.toString(), answer3.toString()]
            : [answer1.toString(), answer2.toString(), answer3.toString()]
        }
        tickValues={tickArray}
        jumpArrowArray={jumpArrowArray}
        subtraction
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question5 = newQuestionContent({
  uid: 'agw',
  description: 'agw',
  keywords: ['Subtraction', '10s', 'Difference'],
  schema: z
    .object({
      number1: z.number().int().min(110).max(980).multipleOf(10),
      number2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.number1, val.number2, 'tens'),
      'number1 + number2 must only exchange at tens'
    ),
  simpleGenerator: () => {
    const number2 = randomIntegerInclusiveStep(20, 90, 10);
    const number1 = randomIntegerInclusiveStep(110, 980, 10, {
      constraint: x => numbersOnlyExchangeAt(x, number2, 'tens')
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const number3 = number1 + number2;

    const items = countRange(9, 1).map(num => {
      return { value: num * 10, component: (num * 10).toLocaleString() };
    });

    return (
      <QF37SentenceDrag
        title={translate.instructions.dragCardCompleteNumberSentence()}
        pdfTitle={translate.instructions.useCardCompleteNumberSentence()}
        items={items}
        sentence={`${number3.toLocaleString()} ${SUB} <ans/> = ${number1.toLocaleString()}`}
        testCorrect={[number2]}
        moveOrCopy="copy"
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'agx',
  description: 'agx',
  keywords: ['Subtraction', '10s'],
  schema: z
    .object({
      numberA1: z
        .number()
        .int()
        .min(110)
        .max(899)
        .refine(val => val % 100 > 9, 'numberA1 should have at least 1 ten'),
      numberA2: z.number().int().min(20).max(90).multipleOf(10),
      numberB1: z
        .number()
        .int()
        .min(110)
        .max(899)
        .refine(val => val % 100 > 9, 'numberB1 should have at least 1 ten'),
      numberB2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, val.numberA2, 'tens'),
      'numberA1 + numberA2 must exchange tens'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'tens'),
      'numberB1 + numberB2 must exchange tens'
    ),
  questionHeight: 500,
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusiveStep(20, 90, 10);
        const numberA1 = randomIntegerInclusive(110, 899, {
          constraint: x => numbersOnlyExchangeAt(x, numberA2, 'tens')
        });

        const numberB2 = randomIntegerInclusiveStep(20, 90, 10);
        const numberB1 = randomIntegerInclusive(110, 899, {
          constraint: x => numbersOnlyExchangeAt(x, numberB2, 'tens')
        });

        return { numberA1, numberA2, numberB1, numberB2 };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2])
    ),

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      right: numberA2,
      result: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'subtract',
      answer: 'right'
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.workOutTheSubtractions()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'agx2',
  description: 'agx',
  keywords: ['Subtraction', '10s'],
  schema: z
    .object({
      numberA1: z
        .number()
        .int()
        .min(110)
        .max(899)
        .refine(val => val % 100 > 9, 'numberA1 should have at least 1 ten'),
      numberA2: z.number().int().min(20).max(90).multipleOf(10),
      numberB1: z
        .number()
        .int()
        .min(110)
        .max(899)
        .refine(val => val % 100 > 9, 'numberB1 should have at least 1 ten'),
      numberB2: z.number().int().min(20).max(90).multipleOf(10)
    })
    .refine(
      val => numbersOnlyExchangeAt(val.numberA1, val.numberA2, 'tens'),
      'numberA1 + numberA2 must exchange tens'
    )
    .refine(
      val => numbersOnlyExchangeAt(val.numberB1, val.numberB2, 'tens'),
      'numberB1 + numberB2 must exchange tens'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numberA2 = randomIntegerInclusiveStep(20, 90, 10);
        const numberA1 = randomIntegerInclusive(110, 899, {
          constraint: x => numbersOnlyExchangeAt(x, numberA2, 'tens')
        });

        const numberB2 = randomIntegerInclusiveStep(20, 90, 10);
        const numberB1 = randomIntegerInclusive(110, 899, {
          constraint: x => numbersOnlyExchangeAt(x, numberB2, 'tens')
        });

        return { numberA1, numberA2, numberB1, numberB2 };
      },
      val =>
        arrayHasNoDuplicates([val.numberA1, val.numberB1]) &&
        arrayHasNoDuplicates([val.numberA2, val.numberB2])
    ),

  Component: props => {
    const {
      question: { numberA1, numberA2, numberB1, numberB2 },
      translate
    } = props;

    const eqA = getBinOpEquation({
      right: numberA2,
      result: numberA1,
      sign: 'subtract',
      answer: 'result'
    });

    const eqB = getBinOpEquation({
      right: numberB2,
      result: numberB1,
      sign: 'subtract',
      answer: 'right'
    });

    const leftSide = [
      `${eqA.left.toLocaleString()} ${SUB} ${numberA2.toLocaleString()}`,
      `${eqB.left.toLocaleString()} ${SUB} <ans/>`
    ];

    const rightSide = ['<ans/>', eqB.right.toLocaleString()];

    return (
      <QF2AlignedEquations
        title={translate.instructions.workOutTheSubtractions()}
        leftSide={leftSide}
        rightSide={rightSide}
        testCorrect={{ left: [[], [eqB.result.toString()]], right: [[numberA1.toString()], []] }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Subtract10sAcrossA100',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6v2],
  archivedQuestionTypes: [Question1, Question6],
  unpublishedQuestionTypes: [Question6v2]
});
export default SmallStep;
