import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { View } from 'react-native';
import {
  displayMoney,
  moneyFromXDenominations,
  moneyPenceToString,
  moneyScaledSizes,
  PossibleMoneyDenominations
} from '../../../../utils/money';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContentsUnordered,
  countRange,
  sumNumberArray
} from '../../../../utils/collections';
import { chunk } from '../../../../utils/chunk';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import Text from '../../../../components/typography/Text';
import { numberEnum } from '../../../../utils/zod';
import QF16DragMoneyIntoABox from '../../../../components/question/questionFormats/QF16DragMoneyIntoABox';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bil',
  description: 'bil',
  keywords: ['Match', 'Equal', 'Pounds', 'Pence', 'Coins', 'Notes'],
  schema: z.object({
    draggablesMoney: z.array(
      z.object({
        sum: z.number().int().min(1).max(4000),
        valuesInP: z.array(numberEnum([1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000]))
      })
    ),
    sentenceMoney: z.array(
      z.object({
        sum: z.number().int().min(1).max(8000),
        valuesInP: z.array(numberEnum([1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000]))
      })
    )
  }),
  simpleGenerator: () => {
    const values = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000] as const;
    type CoinValue = (typeof values)[number];

    const isPounds = getRandomBoolean();

    const valuesToUse = values.filter(num => (isPounds ? num > 99 : num < 100));

    const { pence1A, pence1B } = rejectionSample(
      () => {
        const moneyForSentence = getRandomBoolean() ? 3 : 4;

        const pence1A = moneyFromXDenominations(2, 'pence', valuesToUse);

        const pence1B = { ...moneyFromXDenominations(moneyForSentence, 'pence', valuesToUse) };

        return { pence1A, pence1B };
      },
      ({ pence1A, pence1B }) => pence1A.sum === pence1B.sum
    );

    const { pence2A, pence2B } = rejectionSample(
      () => {
        const moneyForSentence = getRandomBoolean() ? 3 : 4;

        const pence2A = moneyFromXDenominations(2, 'pence', valuesToUse);

        const pence2B = moneyFromXDenominations(moneyForSentence, 'pence', valuesToUse);

        return { pence2A, pence2B };
      },
      ({ pence2A, pence2B }) =>
        pence2A.sum === pence2B.sum &&
        arrayHasNoDuplicates([pence2A.sum, pence1A.sum]) &&
        arrayHasNoDuplicates([pence2B.sum, pence1B.sum])
    );

    const pence3 = rejectionSample(
      () => moneyFromXDenominations(2, 'pence', valuesToUse),
      x => x.sum !== pence1A.sum && x.sum !== pence2A.sum
    );

    const draggablesMoney = shuffle(
      [pence1A, pence2A, pence3].map(value => ({
        ...value,
        valuesInP: value.valuesInP as CoinValue[]
      }))
    );

    const sentenceMoney = shuffle(
      [pence1B, pence2B].map(value => ({
        ...value,
        valuesInP: value.valuesInP as CoinValue[]
      }))
    );

    return { draggablesMoney, sentenceMoney };
  },

  Component: props => {
    const {
      question: { draggablesMoney, sentenceMoney },
      translate,
      displayMode
    } = props;

    const draggablesMoneyArray = draggablesMoney.map(money =>
      money.valuesInP.map(denom => moneyPenceToString[denom])
    );

    const sentenceMoneyArray = sentenceMoney.map(money =>
      money.valuesInP.map(denom => moneyPenceToString[denom])
    );

    const flattenedMoneyArray = [...draggablesMoneyArray, ...sentenceMoneyArray].flat();

    // We need to get moneyScales here, so we can use a uniform scale across money in both the items and statements:
    const moneyScales = moneyScaledSizes(flattenedMoneyArray);

    const baseHeight = 70;

    const items = draggablesMoney.map(({ sum, valuesInP }) => {
      return {
        value: sum,
        component: (
          <View style={{ flexDirection: 'row', columnGap: 4, alignItems: 'center' }}>
            {valuesInP.map(denom => {
              const denomAsString = moneyPenceToString[denom];

              return displayMoney(
                [denomAsString],
                baseHeight * moneyScales[denomAsString],
                baseHeight * moneyScales[denomAsString]
              );
            })}
          </View>
        )
      };
    });

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheAmounts()}
        pdfTitle={translate.ks1PDFInstructions.matchTheAmounts()}
        statements={sentenceMoney.map(({ sum, valuesInP }) => {
          return {
            lhsComponent: (
              <View style={{ flexDirection: 'row' }}>
                <View
                  style={{
                    width: 350,
                    flexDirection: 'row',
                    justifyContent: 'center'
                  }}
                >
                  <View
                    style={{
                      flexDirection: 'row',
                      gap: 8,
                      width: 300,
                      flexWrap: 'wrap',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {valuesInP.map(denom => {
                      const denomAsString = moneyPenceToString[denom];

                      return displayMoney(
                        [denomAsString],
                        baseHeight * moneyScales[denomAsString],
                        baseHeight * moneyScales[denomAsString]
                      );
                    })}
                  </View>
                </View>
                {displayMode === 'digital' ? (
                  <View style={{ justifyContent: 'center' }}>
                    <Text variant="WRN400">=</Text>
                  </View>
                ) : null}
              </View>
            ),
            correctAnswer: sum
          };
        })}
        mainPanelStyle={{ alignItems: 'center' }}
        items={items}
        pdfLayout="itemsRight"
        useArrows={false}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bim',
  description: 'bim',
  keywords: ['Money', 'Equal', 'Pounds', 'Pence', 'Coins', 'Notes'],
  schema: z
    .object({
      selectables: z.array(
        z.object({ value: z.enum(['A', 'B', 'C', 'D']), denominations: z.array(z.string()) })
      ),
      answers: z
        .array(z.enum(['A', 'B', 'C', 'D']))
        .min(2)
        .max(4)
    })
    .refine(({ selectables }) => {
      const selectablesNumbers = selectables.map(({ denominations }) => {
        return denominations.map(denomination =>
          denomination.startsWith('£')
            ? Math.round(parseFloat(denomination.replace('£', '')) * 100)
            : parseInt(denomination.replace('p', ''), 10)
        );
      });

      const pence = selectablesNumbers.map(numArrays => numArrays.filter(num => num < 100));
      const pounds = selectablesNumbers.map(numArrays => numArrays.filter(num => num > 99));

      return (
        pounds.every(num => sumNumberArray(num) < 10000) &&
        pence.every(num => sumNumberArray(num) < 100)
      );
    }, 'Value of the denominations added for each of the selectable pounds should be less than 10000 (£100) and pence less than 100 (£1)'),
  simpleGenerator: () => {
    const moneyValues = [1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000];

    const variation = getRandomFromArray([
      'allAmountsPounds',
      'allAmountsPence',
      'allAmountsPoundsAndPence'
    ] as const);

    const { selectables, answers } = rejectionSample(
      () => {
        const numberOfCorrectAnswers = getRandomFromArray([2, 3, 4] as const);
        const numberOfIncorrectAnswers = 4 - numberOfCorrectAnswers;

        // Had to do way so typescript didn't complain
        const values = chunk(Array.from(['A', 'B', 'C', 'D']), numberOfCorrectAnswers);
        const answers = values[0] as ('A' | 'B' | 'C' | 'D')[];

        const correctPence =
          variation === 'allAmountsPence'
            ? randomIntegerInclusive(1, 99)
            : variation === 'allAmountsPounds'
            ? randomIntegerInclusiveStep(100, 9900, 100)
            : randomIntegerInclusive(101, 9999, { constraint: x => x % 100 !== 0 });

        const incorrectPence =
          variation === 'allAmountsPence'
            ? randomUniqueIntegersInclusive(1, 99, numberOfIncorrectAnswers, {
                constraint: x => x !== correctPence
              })
            : variation === 'allAmountsPounds'
            ? randomUniqueIntegersInclusiveStep(100, 9900, 100, numberOfIncorrectAnswers, {
                constraint: x => x !== correctPence
              })
            : randomUniqueIntegersInclusive(101, 9999, numberOfIncorrectAnswers, {
                constraint: x => x !== correctPence && x % 100 !== 0
              });

        const generateDenominations = (amount: number) => {
          let remaining = amount;
          const denominations = [];
          const filteredMoneyValues =
            variation === 'allAmountsPence'
              ? moneyValues.filter(value => value < 100)
              : variation === 'allAmountsPounds'
              ? moneyValues.filter(value => value >= 100)
              : moneyValues;

          // Generate up to 8 denominations to sum up to the given amount
          while (remaining > 0 && denominations.length < 8) {
            const denomination = getRandomFromArray(
              filteredMoneyValues.filter(value => value <= remaining)
            ) as number;
            denominations.push(denomination >= 100 ? `£${denomination / 100}` : `${denomination}p`);
            remaining -= denomination;
          }

          return denominations;
        };

        // Generate correct selectables with denominations summing to the correct total
        const correctSelectables = countRange(numberOfCorrectAnswers).map((_, index) => ({
          value: values[0][index] as 'A' | 'B' | 'C' | 'D',
          denominations: generateDenominations(correctPence)
        }));

        // Generate incorrect selectables with denominations summing to incorrect totals
        const incorrectSelectables = incorrectPence.map((amount, index) => ({
          value: values[1][index] as 'A' | 'B' | 'C' | 'D',
          denominations: generateDenominations(amount)
        }));

        const selectables = shuffle([...correctSelectables, ...incorrectSelectables]);

        return { correctSelectables, selectables, correctPence, answers };
      },
      ({ correctSelectables, selectables, correctPence }) => {
        return (
          // Ensure each selectable has at most 8 denominations
          selectables.every(({ denominations }) => denominations.length <= 8) &&
          // Ensure pounds and pence are less then 100
          selectables.every(({ denominations }) => {
            const numbers = denominations.map(denomination => {
              return denomination.startsWith('£')
                ? Math.round(parseFloat(denomination.replace('£', '')) * 100)
                : parseInt(denomination.replace('p', ''), 10);
            });

            const pounds = numbers.filter(num => num > 99);
            const pence = numbers.filter(num => num < 100);

            return sumNumberArray(pounds) < 10000 && sumNumberArray(pence) < 100;
          }) &&
          // Ensure no two correct selectables have identical denominations
          correctSelectables.every(({ denominations }, index, array) => {
            return array.every((other, otherIndex) => {
              if (index === otherIndex) return true;
              return !arraysHaveSameContentsUnordered(denominations, other.denominations);
            });
          }) &&
          // Ensure all correct selectables sum to the correct total amount
          correctSelectables
            .map(({ denominations }) =>
              denominations.reduce((total, denomination) => {
                if (denomination.startsWith('£')) {
                  // Convert pounds to pence
                  return total + Math.round(parseFloat(denomination.replace('£', '')) * 100);
                } else {
                  // Convert string pence to integer
                  return total + parseInt(denomination.replace('p', ''), 10);
                }
              }, 0)
            )
            .every(total => total === correctPence)
        );
      }
    );

    return { selectables, answers };
  },
  Component: props => {
    const {
      question: { selectables, answers },
      translate,
      displayMode
    } = props;

    const flattenedMoneyArray = selectables.map(selectable => selectable.denominations).flat();

    // We need to get moneyScales here, so we can use a uniform scale across money in both the items and statements:
    const moneyScales = moneyScaledSizes(flattenedMoneyArray);

    const baseSize = displayMode === 'digital' ? 60 : 100;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectThePicturesThatShowTheSameAmount()}
        pdfTitle={translate.ks1PDFInstructions.tickTheBoxesThatShowTheSameAmount()}
        testCorrect={answers}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={() =>
          selectables.map(selectable => ({
            value: selectable.value,
            component: (
              <View
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  columnGap: 12,
                  rowGap: 12,
                  padding: 12,
                  alignItems: 'center'
                }}
              >
                {selectable.denominations.map(denom => {
                  return displayMoney(
                    [denom],
                    baseSize * moneyScales[denom],
                    baseSize * moneyScales[denom]
                  );
                })}
              </View>
            )
          }))
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bin',
  description: 'bin',
  keywords: ['Money', 'Pounds', 'Pence', 'Coins'],
  schema: z.object({
    providedCoinsLeft: z.array(numberEnum([1, 2, 5, 10, 20, 50, 100, 200])),
    providedCoinsRight: z.array(numberEnum([1, 2, 5, 10, 20, 50, 100, 200]))
  }),
  simpleGenerator: () => {
    const { providedCoinsLeft, providedCoinsRight } = rejectionSample(
      () => {
        const providedCoinsLeftAmount = randomIntegerInclusive(4, 6);

        const providedCoinsLeft = moneyFromXDenominations(
          providedCoinsLeftAmount,
          'pence',
          [1, 2, 5, 10, 20, 50, 100, 200]
        ).valuesInP as (1 | 2 | 5 | 10 | 20 | 50 | 100 | 200)[];

        const providedCoinsRightAmount = randomIntegerInclusive(1, 2);

        const providedCoinsRight = moneyFromXDenominations(
          providedCoinsRightAmount,
          'pence',
          [1, 2, 5, 10, 20, 50, 100, 200]
        ).valuesInP as (1 | 2 | 5 | 10 | 20 | 50 | 100 | 200)[];

        return { providedCoinsLeft, providedCoinsRight };
      },
      // providedCoinsLeft must be less
      ({ providedCoinsLeft, providedCoinsRight }) => {
        const totalCoinsLeft = sumNumberArray(providedCoinsLeft);

        const totalCoinsRight = sumNumberArray(providedCoinsRight);

        return (
          // Total to make must be less than £5:
          totalCoinsLeft < 500 &&
          // Provided coins on left must be more than provided coins on right:
          totalCoinsLeft > totalCoinsRight &&
          // Cannot have every coin on the right also be on the left:
          !providedCoinsRight.every(coin => providedCoinsLeft.includes(coin))
        );
      }
    );

    return { providedCoinsLeft, providedCoinsRight };
  },

  Component: props => {
    const {
      question: { providedCoinsLeft, providedCoinsRight },
      translate
    } = props;

    const draggables: PossibleMoneyDenominations[] = [200, 100, 50, 20, 10, 5, 2, 1];

    const totalCoinsLeft = providedCoinsLeft.reduce((sum, nextCoin) => sum + nextCoin, 0);

    return (
      <QF16DragMoneyIntoABox
        title={translate.ks1Instructions.dragTheMoneySoEachBoxHasTheSameAmount()}
        pdfTitle={translate.ks1PDFInstructions.drawMoneySoThatEachBoxHasNumP(totalCoinsLeft)}
        amountInPenceToMake={totalCoinsLeft}
        initialState={providedCoinsRight}
        freezeInitialState
        draggablesToShow={draggables}
        questionHeight={1000}
        leftHandStaticMoney={providedCoinsLeft}
        customMarkSchemeAnswer={translate.markScheme.acceptAnyValidDenominationsThatMakeNumP(
          totalCoinsLeft
        )}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeTheSameAmount',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
