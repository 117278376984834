import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import { getDiceArrangementArray } from '../../../../utils/dice';
import TenFrameLayout, {
  TEN_FRAME_MEASUREMENTS
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bad',
  description: 'bad',
  keywords: ['Count', 'Table'],
  schema: z.object({
    numA: z.number().int().min(1).max(10),
    randomOrLine: z.enum(['random', 'line']),
    arrangementSeed: z.number().int().min(1).max(1000),
    fruit: z.enum(['bananas', 'pears', 'oranges', 'apples'])
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(1, 10);
    const randomOrLine = getRandomFromArray(['random', 'line'] as const);
    const arrangementSeed = randomIntegerInclusive(1, 1000);
    const fruit = getRandomFromArray(['bananas', 'pears', 'oranges', 'apples'] as const);
    return { numA, randomOrLine, arrangementSeed, fruit };
  },
  Component: ({ question: { numA, randomOrLine, arrangementSeed, fruit }, translate }) => {
    const fruitTranslations = {
      bananas: translate.fruits.Bananas(),
      pears: translate.fruits.Pears(),
      oranges: translate.fruits.Oranges(),
      apples: translate.fruits.Apples(),
      lemons: translate.fruits.Lemons()
    };

    // TODO update buttons image when we have it
    const fruitSvgPaths = {
      bananas: 'Array_objects/Banana' as const,
      pears: 'Array_objects/Pear' as const,
      oranges: 'Array_objects/Orange' as const,
      apples: 'Array_objects/AppleGreen' as const
    };

    const random = seededRandom({ arrangementSeed });
    const arrangementA =
      randomOrLine === 'random'
        ? getRandomBooleanArray(4, 10, numA, random)
        : getRandomBooleanArray(1, numA, numA, random);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyObjectsAreThere(fruitTranslations[fruit])}
        testCorrect={[numA.toString()]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            {arrangementA.map((row, rowIndex) => (
              <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                {row.map((cell, colIndex) => (
                  <View
                    key={`cell-${rowIndex}-${colIndex}`}
                    style={{
                      width: dimens.width / 10,
                      height: dimens.height / 4
                    }}
                  >
                    {cell && (
                      <AssetSvg
                        name={fruitSvgPaths[fruit]}
                        height={dimens.height / 4 - 16}
                        width={dimens.width / 10 - 16}
                      />
                    )}
                  </View>
                ))}
              </View>
            ))}
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bae',
  description: 'bae',
  keywords: ['Count', 'Ten frame'],
  schema: z.object({
    numA: z.number().int().min(1).max(10),
    animal: z.enum(['Sheep', 'Horse', 'Cow']) //TODO add pigs, goats, cats, and dogs when we have the artwork
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(1, 10);
    const animal = getRandomFromArray(['Sheep', 'Horse', 'Cow'] as const);
    return { numA, animal };
  },
  Component: ({ question: { numA, animal }, translate, displayMode }) => {
    // TODO dd pigs, goats, cats, and dogs when we have the artwork
    const animalTranslations = {
      Sheep: translate.animals.sheep(),
      Horse: translate.animals.Horses(),
      Cow: translate.animals.Cows()
    };

    // TODO add pigs, goats, cats, and dogs when we have the artwork
    const animalSvgPaths = {
      Sheep: 'Sheep' as const,
      Horse: 'Horse' as const,
      Cow: 'Cow' as const
    };

    const tenFrameArray = new Array(numA).fill(
      <AssetSvg
        name={animalSvgPaths[animal]}
        width={TEN_FRAME_MEASUREMENTS['large'].cellWidth - 6}
        height={TEN_FRAME_MEASUREMENTS['large'].cellWidth - 6}
      />
    );

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyObjectsAreThere(animalTranslations[animal])}
        pdfDirection="column"
        questionHeight={900}
        Content={
          <TenFrameLayout
            items={tenFrameArray}
            size={displayMode === 'digital' ? 'large' : 'xlarge'}
          />
        }
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[numA.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baf',
  description: 'baf',
  keywords: ['Count'],
  schema: z
    .object({
      numA: z.number().int().min(1).max(9),
      numB: z.number().int().min(1).max(9),
      colourA: z.enum(['red', 'yellow', 'green', 'blue']),
      colourB: z.enum(['red', 'yellow', 'green', 'blue'])
    })
    .refine(val => val.numA + val.numB <= 10, 'numA + numB must be less than or equal to 10'),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(1, 9);
    const numB = randomIntegerInclusive(1, 10 - numA);
    const colourOptions = ['red', 'yellow', 'green', 'blue'] as const;
    const colourA = getRandomFromArray(colourOptions);
    // We are happy for this to be the same as colourA too
    const colourB = getRandomFromArray(colourOptions);
    return { numA, numB, colourA, colourB };
  },
  Component: ({ question: { numA, numB, colourA, colourB }, translate }) => {
    const arrangementA = getDiceArrangementArray(numA);
    const arrangementB = getDiceArrangementArray(numB);
    const correctAnswer = numA + numB;
    const circleA = `Circles/circle_${colourA}` as const;
    const circleB = `Circles/circle_${colourB}` as const;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyCirclesAreThere()}
        testCorrect={[correctAnswer.toString()]}
        sentence="<ans/>"
        sentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1
              }}
            >
              {arrangementA.map((row, rowIndex) => (
                <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                  {row.map((cell, colIndex) => (
                    <View
                      key={`cell-${rowIndex}-${colIndex}`}
                      style={{
                        width: dimens.width / 6,
                        height: dimens.height / 3
                      }}
                    >
                      {cell && (
                        <AssetSvg
                          name={circleA}
                          height={dimens.height / 3 - 16}
                          width={dimens.width / 6 - 16}
                        />
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
            <View
              style={{
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                flex: 1
              }}
            >
              {arrangementB.map((row, rowIndex) => (
                <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                  {row.map((cell, colIndex) => (
                    <View
                      key={`cell-${rowIndex}-${colIndex}`}
                      style={{
                        width: dimens.width / 6,
                        height: dimens.height / 3
                      }}
                    >
                      {cell && (
                        <AssetSvg
                          name={circleB}
                          height={dimens.height / 3 - 16}
                          width={dimens.width / 6 - 16}
                        />
                      )}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountObjects',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
