import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import { isValidShapeWithVerticalLineOfSymmetry } from '../../../../utils/shapes';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import { getRandomFromArray, getRandomSubArrayFromArray, shuffle } from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import Svg, { Line } from 'react-native-svg';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const q1And2AlwaysVerticallySymmetricalShapes = [
  'circle',
  'rectangle',
  'square',
  'triangle',
  'pentagon',
  'hexagon',
  'cross',
  'narrow isosceles triangle',
  'trapezium isosceles'
] as const;

const q1And2NeverVerticallySymmetricalShapes = ['long right angle triangle', 'L-shape'] as const;

const q1And2Shapes = [
  ...q1And2AlwaysVerticallySymmetricalShapes,
  ...q1And2NeverVerticallySymmetricalShapes,
  'pentagon house'
] as const;

type Q1And2Shape = (typeof q1And2Shapes)[number];

const q1And2ShapeOrientations = (shape: Q1And2Shape) => {
  switch (shape) {
    case 'circle':
      return [0];
    case 'rectangle':
      return [0, 90];
    case 'long right angle triangle':
    case 'pentagon house':
    case 'L-shape':
      return [0, 90, 180, 270];
    case 'triangle':
    case 'pentagon':
    case 'trapezium isosceles':
    case 'narrow isosceles triangle':
      return [0, 180];
    case 'square':
    case 'cross':
      return [0, 45];
    case 'hexagon':
      return [0, 30];
  }
};

const q1And2ShapeAndOrientationToVerticalSymmetry = (shape: Q1And2Shape, rotation: number) => {
  switch (shape) {
    case 'circle':
    case 'rectangle':
    case 'square':
    case 'triangle':
    case 'pentagon':
    case 'hexagon':
    case 'cross':
    case 'narrow isosceles triangle':
    case 'trapezium isosceles':
      return true;
    case 'long right angle triangle':
    case 'L-shape':
      return false;
    case 'pentagon house':
      return rotation % 180 === 0;
  }
};

// 'red' is reserved as a colour for the dashed line in Q2 - this colour stands out best against all the other colours:
const q2Colors = ['blue', 'green', 'orange', 'pink', 'purple', 'turquoise', 'yellow'] as const;

const q1Colors = [...q2Colors, 'red'] as const;

const Question1 = newQuestionContent({
  uid: 'bhL',
  description: 'bhL',
  keywords: ['Symmetry', 'Vertical', 'Shape'],
  schema: z
    .object({
      shapes: z
        .array(
          z.object({
            shape: z.enum(q1And2Shapes),
            color: z.enum(q1Colors),
            rotation: z.number().min(0).max(270)
          })
        )
        .length(4)
        .refine(
          shapes => arrayHasNoDuplicates(shapes.map(shape => shape.shape)),
          'All shapes must be different.'
        )
    })
    .refine(
      val =>
        val.shapes.some(shape =>
          q1And2ShapeAndOrientationToVerticalSymmetry(shape.shape, shape.rotation)
        ),
      'At least one shape must have a vertical line of symmetry after its rotation is applied.'
    )
    .refine(
      val =>
        val.shapes.every(shape => q1And2ShapeOrientations(shape.shape).includes(shape.rotation)),
      'rotation of each shape must be a valid rotation.'
    ),
  simpleGenerator: () => {
    const correctShapes = getRandomSubArrayFromArray(q1And2AlwaysVerticallySymmetricalShapes, 2);

    const neverCorrectShape = getRandomFromArray(q1And2NeverVerticallySymmetricalShapes);

    const otherShape = getRandomFromArray(
      q1And2Shapes.filter(
        shape =>
          shape !== correctShapes[0] && shape !== correctShapes[1] && shape !== neverCorrectShape
      )
    );

    const selectedColors = getRandomSubArrayFromArray(q1Colors, 4);

    const allShapes = [...correctShapes, neverCorrectShape, otherShape];

    const allRotations = allShapes.map(shape => q1And2ShapeOrientations(shape!));

    const shapes = shuffle([
      {
        shape: allShapes[0]!,
        color: selectedColors[0],
        rotation: getRandomFromArray(allRotations[0])!
      },
      {
        shape: allShapes[1]!,
        color: selectedColors[1],
        rotation: getRandomFromArray(allRotations[1])!
      },
      {
        shape: allShapes[2]!,
        color: selectedColors[2],
        rotation: getRandomFromArray(allRotations[2])!
      },
      {
        shape: allShapes[3]!,
        color: selectedColors[3],
        rotation: getRandomFromArray(allRotations[3])!
      }
    ]);

    return { shapes };
  },
  Component: ({ question: { shapes }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapesWithAVerticalLineOfSymmetry()}
        pdfTitle={translate.ks1PDFInstructions.tickTheShapesWithAVerticalLineOfSymmetry()}
        testCorrect={shapes
          .filter(shape => q1And2ShapeAndOrientationToVerticalSymmetry(shape.shape, shape.rotation))
          .map(shape => shape.shape)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          shapes.map(({ shape, color, rotation }) => {
            return {
              component: (
                <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
                  <AssetSvg
                    name={getShapeSvgByShapeAndColor(shape, color)}
                    width={
                      rotation === 90 || rotation === 270
                        ? dimens.height * 0.9
                        : rotation === 30
                        ? dimens.width * 0.8
                        : rotation === 45
                        ? dimens.width * 0.65
                        : dimens.width * 0.9
                    }
                    height={
                      rotation === 90 || rotation === 270
                        ? dimens.width * 0.9
                        : rotation === 30
                        ? dimens.height * 0.8
                        : rotation === 45
                        ? dimens.height * 0.65
                        : dimens.height * 0.9
                    }
                  />
                </View>
              ),
              value: shape
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bhM',
  description: 'bhM',
  keywords: ['Symmetry', 'Vertical', 'Shape'],
  schema: z
    .object({
      shapes: z
        .array(
          z.object({
            shape: z.enum(q1And2Shapes),
            color: z.enum(q2Colors),
            rotation: z.number().min(0).max(270),
            line: z.enum(['left', 'middle', 'right'])
          })
        )
        .length(4)
        .refine(
          shapes => arrayHasNoDuplicates(shapes.map(shape => shape.shape)),
          'All shapes must be different.'
        )
    })
    .refine(
      val =>
        val.shapes.some(
          shape =>
            shape.line === 'middle' &&
            q1And2ShapeAndOrientationToVerticalSymmetry(shape.shape, shape.rotation)
        ),
      'At least one shape must have a vertical line of symmetry after its rotation is applied, with a middle line.'
    )
    .refine(
      val =>
        val.shapes.every(shape => q1And2ShapeOrientations(shape.shape).includes(shape.rotation)),
      'rotation of each shape must be a valid rotation.'
    ),
  simpleGenerator: () => {
    const correctShapes = getRandomSubArrayFromArray(q1And2AlwaysVerticallySymmetricalShapes, 2);

    const neverCorrectShape = getRandomFromArray(q1And2NeverVerticallySymmetricalShapes);

    const otherShape = getRandomFromArray(
      q1And2Shapes.filter(
        shape =>
          shape !== correctShapes[0] && shape !== correctShapes[1] && shape !== neverCorrectShape
      )
    );

    const selectedColors = getRandomSubArrayFromArray(q2Colors, 4);

    const allShapes = [...correctShapes, neverCorrectShape, otherShape];

    const allRotations = allShapes.map(shape => q1And2ShapeOrientations(shape!));

    const shapes = shuffle([
      {
        shape: allShapes[0]!,
        color: selectedColors[0],
        rotation: getRandomFromArray(allRotations[0])!,
        // This is always a correct shape, so must always have a middle line:
        line: 'middle' as const
      },
      {
        shape: allShapes[1]!,
        color: selectedColors[1],
        rotation: getRandomFromArray(allRotations[1])!,
        // This is always a correct shape, so must always have a middle line:
        line: 'middle' as const
      },
      {
        shape: allShapes[2]!,
        color: selectedColors[2],
        rotation: getRandomFromArray(allRotations[2])!,
        // This is always wrong based on the shape, so it does not matter where the line is:
        line: getRandomFromArray(['left', 'middle', 'right'] as const)
      },
      {
        shape: allShapes[3]!,
        color: selectedColors[3],
        rotation: getRandomFromArray(allRotations[3])!,
        // This shape can be correct or incorrect, so this line can be randomly selected:
        line: getRandomFromArray(['left', 'middle', 'right'] as const)
      }
    ]);

    return { shapes };
  },
  Component: ({ question: { shapes }, translate }) => {
    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheShapesWithACorrectVerticalLineOfSymmetry()}
        pdfTitle={translate.ks1PDFInstructions.tickTheShapesWithACorrectVerticalLineOfSymmetry()}
        testCorrect={shapes
          .filter(
            shape =>
              // If a shape's line is not 'middle', it is definitely an incorrect answer - no need to even call into the utility function:
              shape.line === 'middle' &&
              q1And2ShapeAndOrientationToVerticalSymmetry(shape.shape, shape.rotation)
          )
          .map(shape => shape.shape)}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) =>
          shapes.map(({ shape, color, rotation, line }) => {
            return {
              component: (
                <View
                  style={{
                    flexDirection: 'row',
                    height: dimens.height,
                    width: dimens.width,
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
                    <AssetSvg
                      name={getShapeSvgByShapeAndColor(shape, color)}
                      width={
                        rotation === 90 || rotation === 270
                          ? dimens.height * 0.9
                          : rotation === 30
                          ? dimens.width * 0.8
                          : rotation === 45
                          ? dimens.width * 0.65
                          : dimens.width * 0.9
                      }
                      height={
                        rotation === 90 || rotation === 270
                          ? dimens.width * 0.9
                          : rotation === 30
                          ? dimens.height * 0.8
                          : rotation === 45
                          ? dimens.height * 0.65
                          : dimens.height * 0.9
                      }
                    />
                  </View>
                  <Svg
                    height={dimens.height * 0.95}
                    width={4}
                    style={{
                      position: 'absolute',
                      top: dimens.height * 0.025,
                      left:
                        dimens.width * (line === 'middle' ? 0.5 : line === 'left' ? 0.43 : 0.57) - 2
                    }}
                  >
                    <Line
                      y1={0}
                      y2={dimens.height * 0.95}
                      x1={2}
                      x2={2}
                      strokeWidth={4}
                      stroke={colors.red}
                      strokeDasharray="16 4"
                    />
                  </Svg>
                </View>
              ),
              value: shape
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bhN',
  description: 'bhN',
  keywords: ['Shape', '2-D shape', 'Vertical', 'Symmetry'],
  schema: z.object({}),
  simpleGenerator: () => {
    return {};
  },
  Component: ({ translate }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapDotsToCreateAShapeWithAVerticalLineOfSymmetry()}
        pdfTitle={translate.ks1PDFInstructions.drawAClosedShapeWithAVerticalLineOfSymmetry()}
        testCorrect={userAnswer => isValidShapeWithVerticalLineOfSymmetry(userAnswer)}
        closeShape
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyValidShapeWithAVerticalLineOfSymmetry()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'LinesOfSymmetryOnShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
