import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { numberEnum } from '../../../../utils/zod';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  getRandomSubArrayFromArray,
  seededRandom,
  shuffle,
  getRandomFromArray
} from '../../../../utils/random';
import { countRange, filledArray, sortNumberArray } from '../../../../utils/collections';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { getGroupSvgName } from '../../../../utils/objectsImages';
import QF60DragCountersIntoGroups from '../../../../components/question/questionFormats/QF60DragCountersIntoGroups';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bee',
  description: 'bee',
  keywords: ['Grouping', 'Equal groups', 'Division'],
  schema: z.object({
    groups: numberEnum([2, 5, 10]),
    item: z.enum([
      'Apple',
      'Pear',
      'Banana',
      'Donut',
      'Egg',
      'Pencil',
      'Muffin',
      'Cookie',
      'Fish',
      'MuffinRack'
    ]),
    inEachGroup: numberEnum([2, 5, 10]),
    incorrectOptions: z.array(z.array(z.number().int())).length(2)
  }),
  simpleGenerator: () => {
    const groups = getRandomFromArray([2, 5, 10] as const);
    // remove 2 and 2 from being an option as not enough answers
    const inEachArray =
      groups === 10 ? ([2, 5] as const) : groups === 2 ? ([5, 10] as const) : ([2, 10] as const);
    const inEachGroup = getRandomFromArray(inEachArray);

    const item = getRandomFromArray(
      inEachGroup === 10
        ? // TODO add pencils in when we get them for the 10
          (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : groups === 10
        ? (['Apple', 'Pear', 'Muffin', 'Cookie'] as const)
        : (['Apple', 'Pear', 'Banana', 'Donut', 'Egg', 'Pencil', 'Muffin', 'Fish'] as const)
    );

    const incorrect = [];

    if (groups !== 10) {
      incorrect.push(filledArray(groups, inEachGroup), [groups, inEachGroup] as number[]);
    }

    if (groups > 2) {
      incorrect.push(filledArray(inEachGroup, groups - 1));
    }

    if (inEachGroup > 2) {
      incorrect.push(filledArray(inEachGroup - 1, groups));
    }

    if (inEachGroup === 2) {
      incorrect.push(filledArray(inEachGroup + 1, groups));
    }

    // find unique array of arrays
    const uniqueArrayOfArrays = incorrect
      .map(arr => sortNumberArray(arr))
      .map(arr => JSON.stringify(arr))
      .filter((item, index, self) => self.indexOf(item) === index)
      .map(item => JSON.parse(item));

    const incorrectOptions = getRandomSubArrayFromArray(uniqueArrayOfArrays, 2);

    return { groups, item, inEachGroup, incorrectOptions };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup, incorrectOptions },
      translate
    } = props;

    const random = seededRandom(props);

    const answers = shuffle(
      [
        { value: 'A', array: filledArray(inEachGroup, groups) },
        ...incorrectOptions.map((val, i) => ({ value: ['B', 'C'][i], array: val }))
      ],
      { random }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectThePictureThatShowsXEqualGroupsOfY(
          groups,
          inEachGroup
        )}
        pdfTitle={translate.ks1PDFInstructions.tickThePictureThatShowsXEqualGroupsOfY(
          groups,
          inEachGroup
        )}
        numItems={3}
        innerContainerStyle={{ justifyContent: 'center' }}
        renderItems={({ dimens }) => {
          return answers.map(({ value, array }) => ({
            value,
            component: (
              <View
                style={{
                  width: dimens.width,
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 10
                }}
              >
                {array.map((val, i) => (
                  <AssetSvg
                    key={i}
                    name={getGroupSvgName(item, val)}
                    height={dimens.height / 3}
                    width={dimens.width / 6}
                  />
                ))}
              </View>
            )
          }));
        }}
        testCorrect={['A']}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bef',
  description: 'bef',
  keywords: ['Array', 'Grouping', 'Division'],
  schema: z.object({
    isRows: z.boolean(),
    answerCount: numberEnum([2, 5, 10]),
    otherCount: z.number().int().min(2).max(10),
    color: z.string()
  }),
  simpleGenerator: () => {
    const isRows = getRandomBoolean();
    const answerCount = isRows ? 2 : getRandomFromArray([2, 5, 10] as const);
    const otherCount = isRows ? randomIntegerInclusive(2, 10) : randomIntegerInclusive(2, 3);
    const color = getRandomFromArray(Object.values(ArrayOfObjectsColors)) as string;

    return { isRows, answerCount, otherCount, color };
  },
  Component: props => {
    const {
      question: { isRows, answerCount, otherCount, color },
      translate
    } = props;

    const rows = isRows ? answerCount : otherCount;
    const columns = isRows ? otherCount : answerCount;

    return (
      <QF1ContentAndSentences
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsCountersAltogether(),
          translate.ks1AnswerSentences.thereAreAnsEqualGroupsOfAns()
        ]}
        title={translate.ks1Instructions.completeTheSentences()}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [(answerCount * otherCount).toLocaleString()],
            [rows.toLocaleString(), columns.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyTwoFactors()
        }}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(dimens.width / (columns + 1), dimens.height / (rows + 1))}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        inputMaxCharacters={2}
        pdfDirection="column"
        questionHeight={1000}
        testCorrect={answer =>
          answer[0][0] === (answerCount * otherCount).toString() &&
          Number(answer[1][0]) * Number(answer[1][1]) === answerCount * otherCount
        }
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'beg',
  description: 'beg',
  keywords: ['Groups', 'Equal', 'Division'],
  schema: z.object({
    numberOfGroups: numberEnum([2, 3, 4, 5, 10]),
    correctNumberOfCounters: z.number().int().min(1).max(6),
    color: z.enum(['red', 'yellow', 'blue', 'green'])
  }),
  simpleGenerator: () => {
    const numberOfGroups = getRandomFromArray([2, 3, 4, 5, 10] as const);

    const correctNumberOfCounters =
      numberOfGroups === 2
        ? randomIntegerInclusive(1, 6)
        : numberOfGroups === 10
        ? randomIntegerInclusive(1, 2)
        : randomIntegerInclusive(1, 5);

    const color = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);

    return {
      numberOfGroups,
      correctNumberOfCounters,
      color
    };
  },
  Component: props => {
    const {
      question: { correctNumberOfCounters, numberOfGroups, color },
      translate
    } = props;

    const testCorrect = countRange(numberOfGroups).map(() =>
      filledArray(color, correctNumberOfCounters)
    );

    return (
      <QF60DragCountersIntoGroups
        numberOfGroups={numberOfGroups}
        title={translate.ks1Instructions.dragCountersToMakeXEqualGroupsOfY(
          numberOfGroups,
          correctNumberOfCounters
        )}
        pdfTitle={translate.ks1PDFInstructions.drawCountersToMakeXEqualGroupsOfY(
          numberOfGroups,
          correctNumberOfCounters
        )}
        items={[color]}
        testCorrect={testCorrect}
        prefilledBoxes={{
          type: 'random',
          value: countRange(numberOfGroups).map(() => '<ans/>' as const)
        }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeEqualGroupsGrouping',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
