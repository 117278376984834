import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import {
  ShadedCounterArrangements,
  ShadedCounterBoxArrangement
} from '../../../../components/question/representations/CounterBoxArrangement/ShadedCounterBoxArrangement';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import deepEqual from 'react-fast-compare';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import QF24CreateShapeFromSquares from '../../../../components/question/questionFormats/QF24CreateShapeFromSquares';
import { trueCount } from '../../../../utils/shapes';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import getShadedCounterArrangements from '../../../../components/question/representations/CounterBoxArrangement/shadedCounterArrangements';

////
// Questions
////
const Question2 = newQuestionContent({
  uid: 'bkr',
  description: 'bkr',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Third'],
  questionHeight: 1000,
  schema: z.object({
    options: z
      .array(
        z.object({
          arrangement: z.array(z.array(numberEnum([0, 1, 2]))),
          isThirds: z.boolean()
        })
      )
      .length(4)
      .refine(x => arrayHasNoDuplicates(x, deepEqual), 'all options should be different')
  }),
  simpleGenerator: () => {
    const numOfCorrect = getRandomFromArray([2, 3]);

    const thirdOptions = getRandomSubArrayFromArray(
      [
        [1, 3, 'columns'],
        [3, 4, 'rows'],
        [3, 1, 'rows'],
        [2, 3, 'columns']
      ] as const,
      numOfCorrect
    );
    const correctOptions = thirdOptions.map(([rows, cols, shadedVariation]) => {
      return {
        arrangement: getShadedCounterArrangements({
          rows,
          cols,
          shadedVariation,
          numShaded: (rows * cols) / 3
        }),
        isThirds: true
      };
    });

    const wrongArrangements: ShadedCounterArrangements[] = getRandomSubArrayFromArray(
      [
        getShadedCounterArrangements({
          rows: 1,
          cols: 4,
          shadedVariation: 'columns',
          numShaded: 1
        }),
        [
          [2, 0, 0],
          [1, 1, 1]
        ],
        [[2], [1, 1, 1]],
        getShadedCounterArrangements({
          rows: 2,
          cols: 3,
          shadedVariation: 'columns',
          numShaded: 1
        })
      ],
      4 - numOfCorrect
    );

    const wrongOptions = wrongArrangements.map(arrangement => {
      return {
        arrangement,
        isThirds: false
      };
    });

    return { options: shuffle([...correctOptions, ...wrongOptions]) };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectThePicturesThatHaveThirdShaded()}
        pdfTitle={translate.ks1PDFInstructions.circleThePicturesThatHaveThirdShaded()}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={({ dimens }) =>
          options.map(({ arrangement }, index) => ({
            value: index,
            component: (
              <ShadedCounterBoxArrangement dimens={dimens} arrangement={arrangement} scale={4.5} />
            )
          }))
        }
        testCorrect={options.flatMap((el, index) => (el.isThirds ? [index] : []))}
        questionHeight={1000}
      />
    );
  }
});

const correctShapeNames = ['Square', 'Circle', 'Triangle', 'Rectangle', 'Arrow'] as const;
type CorrectShapeNames = (typeof correctShapeNames)[number];
const incorrectShapeNames = ['Square', 'Circle', 'Triangle', 'Rectangle', 'Hexagon'] as const;
type IncorrectShapeNames = (typeof incorrectShapeNames)[number];

const Question1 = newQuestionContent({
  uid: 'bkq',
  description: 'bkq',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Third'],
  schema: z.object({
    items: z.array(
      z.intersection(
        z.discriminatedUnion('isCorrect', [
          z.object({ isCorrect: z.literal(true), shapeName: z.enum(correctShapeNames) }),
          z.object({ isCorrect: z.literal(false), shapeName: z.enum(incorrectShapeNames) })
        ]),
        z.object({
          color: z.enum(['blue', 'green', 'yellow']),
          rotation: numberEnum([0, 90, 180, 270])
        })
      )
    )
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomBoolean() ? 2 : 3;

    const getRandomColor = () => getRandomFromArray(['blue', 'green', 'yellow'] as const);

    const getRandomRotation = (
      shape: 'Square' | 'Circle' | 'Triangle' | 'Rectangle' | 'Arrow' | 'Hexagon'
    ) =>
      ['Square', 'Circle', 'Triangle', 'Hexagon'].includes(shape)
        ? getRandomFromArray([0, 90, 180, 270] as const)
        : getRandomFromArray([0, 180] as const);

    const correctShapes = getRandomSubArrayFromArray(correctShapeNames, numberOfCorrectAnswers);

    const correctItems = correctShapes.map(shape => ({
      isCorrect: true as const,
      shapeName: shape,
      color: getRandomColor(),
      rotation: getRandomRotation(shape)
    }));

    const incorrectItems = getRandomSubArrayFromArray(
      incorrectShapeNames,
      4 - numberOfCorrectAnswers,
      { constraint: x => !(correctShapes as string[]).includes(x) }
    ).map(shape => ({
      isCorrect: false as const,
      shapeName: shape,
      color: getRandomColor(),
      rotation: getRandomRotation(shape)
    }));

    const items = shuffle([...correctItems, ...incorrectItems]);

    return {
      items
    };
  },
  Component: props => {
    const {
      question: { items },
      translate
    } = props;

    const getEqualSvgName = (shape: CorrectShapeNames, color: 'blue' | 'green' | 'yellow') => {
      return `Equal_shapes_3_parts/${shape}_equal_3-1_1_${color}` as const;
    };

    const getUnequalSvgName = (shape: IncorrectShapeNames, color: 'blue' | 'green' | 'yellow') => {
      return `Unequal_shapes_3_parts/${shape}_unequal_3-1_1_${color}` as const;
    };

    const numberOfCorrectAnswers = items.filter(item => item.isCorrect).length;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheDiagramsThatShowAThird(numberOfCorrectAnswers)}
        pdfTitle={translate.ks1PDFInstructions.circleTheDiagramsThatShowAThird(
          numberOfCorrectAnswers
        )}
        multiSelect
        questionHeight={1000}
        renderItems={items.map(({ color, rotation, shapeName, isCorrect }, index) => ({
          value: index,
          component: (
            <View style={{ transform: [{ rotate: `${rotation}deg` }] }}>
              <AssetSvg
                name={
                  isCorrect
                    ? getEqualSvgName(shapeName, color)
                    : getUnequalSvgName(shapeName, color)
                }
                height={['Rectangle', 'Arrow'].includes(shapeName) ? 300 : 200}
                width={['Rectangle', 'Arrow'].includes(shapeName) ? 300 : 200}
              />
            </View>
          )
        }))}
        numItems={4}
        testCorrect={items.flatMap((el, index) => (el.isCorrect ? [index] : []))}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bks',
  description: 'bks',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Third'],
  schema: z
    .object({
      rows: z.number().int().min(1).max(5),
      cols: z.number().int().min(1).max(8)
    })
    .refine(
      ({ cols, rows }) => (rows * cols) % 3 === 0,
      'grid should have a multiple of 3 squares'
    ),
  simpleGenerator: () => {
    const rows = randomIntegerInclusive(1, 5);
    const cols = randomIntegerInclusive(1, 8, { constraint: x => (x * rows) % 3 === 0 });
    return { rows, cols };
  },
  Component: ({ question: { rows, cols }, translate }) => {
    const answer = (rows * cols) / 3;

    return (
      <QF24CreateShapeFromSquares
        title={translate.ks1Instructions.tapToShadeAThirdOfTheGrid()}
        pdfTitle={translate.ks1PDFInstructions.shadeAThirdOfTheGrid()}
        numberOfRows={rows}
        numberOfCols={cols}
        testCorrect={userAnswer => trueCount(userAnswer) === answer}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyXSquaresShadedOnTheGrid(answer),
          answerToDisplay: (() => {
            const shadingRule =
              rows % 3 === 0
                ? // Shade first third rows
                  (row: number, _col: number) => row < rows / 3
                : // Shade first third columns
                  (_row: number, col: number) => col < cols / 3;
            return countRange(rows).map(row => countRange(cols).map(col => shadingRule(row, col)));
          })()
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAThird',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
