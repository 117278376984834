import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, range, sortNumberArray } from '../../../../utils/collections';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bc1',
  description: 'bc1',
  keywords: ['Count', 'Forwards', 'Backwards'],
  schema: z.object({
    numbers: z.array(z.number().int().min(16).max(50)).length(5),
    answerIndices: z.array(z.number().int().min(0).max(4)).min(1).max(2),
    draggables: z.array(z.number().int().min(2).max(94)).length(4)
  }),
  simpleGenerator: () => {
    const isCountingForwards = getRandomBoolean();

    const startingNumber = randomIntegerInclusive(
      isCountingForwards ? 16 : 20,
      isCountingForwards ? 45 : 50
    );

    const numberOfMissingNumbers = getRandomFromArray([1, 2] as const);

    const numbers = range(startingNumber, startingNumber + (isCountingForwards ? 4 : -4));

    const answerIndices = randomUniqueIntegersInclusive(0, 4, numberOfMissingNumbers);

    const answers = numbers.filter((_, idx) => answerIndices.includes(idx));

    const { incorrectAnswers } = rejectionSample(
      () => {
        const waysToVary = getRandomSubArrayFromArray(
          [
            'ReverseDigit',
            'TenLessThanCorrect',
            'TenMoreThanCorrect',
            'OneLessThanSmallest',
            'OneMoreThanGreatest'
          ],
          numberOfMissingNumbers === 2 ? 2 : 3
        );

        const incorrectAnswers = waysToVary.map(wayToVary => {
          switch (wayToVary) {
            case 'ReverseDigit':
              return parseFloat(
                numbers[getRandomFromArray(answerIndices) as number]
                  .toString()
                  .split('')
                  .reverse()
                  .join('')
              );
            case 'TenLessThanCorrect':
              return numbers[getRandomFromArray(answerIndices) as number] - 10;
            case 'TenMoreThanCorrect':
              return numbers[getRandomFromArray(answerIndices) as number] + 10;
            case 'OneLessThanSmallest':
              return Math.min(...numbers) - 1;
            default:
              return Math.max(...numbers) + 1;
          }
        });
        return { incorrectAnswers };
      },
      // Only permit them if all answers are different
      ({ incorrectAnswers }) => arrayHasNoDuplicates([...incorrectAnswers, ...answers])
    );

    const draggables = shuffle([...answers, ...incorrectAnswers]);

    return {
      numbers,
      answerIndices,
      incorrectAnswers,
      draggables
    };
  },
  Component: props => {
    const {
      question: { numbers, answerIndices, draggables },
      translate
    } = props;

    const answers = numbers.filter((_, index) => answerIndices.includes(index));

    const sentence = numbers
      .map((x, index) => (answerIndices.includes(index) ? `<ans/>` : x))
      .join(' ,  ');

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragTheCardsToFillInTheMissingNumbers(answers.length)}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheCount()}
        sentence={sentence}
        items={draggables.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        moveOrCopy="move"
        pdfLayout="itemsTop"
        testCorrect={answers}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bc2',
  description: 'bc2',
  keywords: ['Number track', 'Count', 'Forwards', 'Backwards'],
  schema: z.object({
    numberArray: z.number().int().min(14).max(50).array().length(8),
    missingIndices: z.number().min(0).max(7).array()
  }),
  simpleGenerator: () => {
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const startingNumber = randomIntegerInclusive(14, 43);

    const numberArray = sortNumberArray(range(startingNumber, startingNumber + 7, 1), ordering);

    const missingIndices = rejectionSample(
      () => {
        return sortNumberArray(randomUniqueIntegersInclusive(0, 7, randomIntegerInclusive(3, 5)));
      },
      // Check at least two consecutive numbers
      x => x.some((num, i, arr) => arr[i + 1] - num >= 3)
    );

    return { numberArray, missingIndices };
  },
  Component: ({ question: { numberArray, missingIndices }, translate }) => {
    const answerArray = missingIndices.map(x => numberArray[x].toString());
    const boxValues = numberArray.map((x, i) =>
      missingIndices.indexOf(i) === -1 ? x.toLocaleString() : '<ans/>'
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.ks1Instructions.completeTheNumberTrack()}
        questionHeight={600}
        testCorrect={answerArray}
        boxValues={boxValues}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bc3',
  description: 'bc3',
  keywords: ['Count', 'Forwards', 'Backwards', 'Numerals'],
  schema: z.object({
    isCountingForwards: z.boolean(),
    name: ks1NameSchema,
    numberA: z.number().int().min(20).max(43),
    numberB: z.number().int().min(26).max(50),
    numbers: z.array(z.number().int().min(1).max(94)).length(6).refine(arrayHasNoDuplicates)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const isCountingForwards = getRandomBoolean();

    const numberA = randomIntegerInclusive(20, 43);
    const numberB = randomIntegerInclusive(numberA + 6, 50);

    const numberOfCorrectAnswers = randomIntegerInclusive(2, 6);

    const correctNumbers = randomUniqueIntegersInclusive(numberA, numberB, numberOfCorrectAnswers);

    const waysToVary = getRandomSubArrayFromArray(
      ['MultipleOfTenBeforeFirstNumber', 'MultipleOfTenAfterLastNumber', 'ReverseNumber'],
      Math.min(3, 6 - numberOfCorrectAnswers)
    );

    const incorrectNumbers = waysToVary.map(wayToVary => {
      switch (wayToVary) {
        case 'MultipleOfTenBeforeFirstNumber':
          return Math.floor((numberA - 1) / 10) * 10;
        case 'MultipleOfTenAfterLastNumber':
          return Math.ceil(numberB / 10 + 1) * 10;
        default: {
          const number = getRandomFromArray(correctNumbers) as number;
          return parseFloat(number.toString().split('').reverse().join(''));
        }
      }
    });

    const numbers = [...new Set([...correctNumbers, ...incorrectNumbers])];

    while (numbers.length < 6) {
      numbers.push(randomIntegerInclusive(1, 50, { constraint: x => !numbers.includes(x) }));
    }

    return {
      isCountingForwards,
      name,
      numberA,
      numberB,
      numbers: shuffle(numbers)
    };
  },

  Component: props => {
    const {
      question: { isCountingForwards, name, numberA, numberB, numbers },
      translate
    } = props;

    const [orderedNumberA, orderedNumberB] = isCountingForwards
      ? [Math.min(numberA, numberB), Math.max(numberA, numberB)]
      : [Math.max(numberA, numberB), Math.min(numberA, numberB)];

    const [title, pdfTile] = isCountingForwards
      ? [
          translate.ks1Instructions.characterIsCountingForwardsFromXToYSelectTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          ),
          translate.ks1PDFInstructions.characterIsCountingForwardsFromXToYTickTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          )
        ]
      : [
          translate.ks1Instructions.characterIsCountingBackwardsFromXToYSelectTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          ),
          translate.ks1PDFInstructions.characterIsCountingBackwardsFromXToYTickTheNumbersCharacterWillSay(
            { character: name, numberA: orderedNumberA, numberB: orderedNumberB }
          )
        ];

    const answer = numbers.filter(num =>
      isInRange(
        Math.min(orderedNumberA, orderedNumberB),
        Math.max(orderedNumberA, orderedNumberB)
      )(num)
    );

    return (
      <QF10SelectNumbers
        title={title}
        pdfTitle={pdfTile}
        testCorrect={answer}
        multiSelect
        items={numbers.map(number => ({
          value: number,
          component: number.toLocaleString()
        }))}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountFrom20To50',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
