import { Fragment, useCallback, useContext } from 'react';
import { countRange, readonlyArrayEntry } from '../../../utils/collections';
import {
  ElementOrRenderFunction,
  resolveElementOrRenderFunction,
  type SetState
} from '../../../utils/react';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import TenFrameLayout, {
  type CounterVariant,
  renderTenFrameCounter,
  TEN_FRAME_MEASUREMENTS
} from '../representations/TenFrame/TenFrameLayout';
import {
  type AnimationState,
  Draggable,
  type DraggableEventData,
  Droppable,
  type DroppableEventData
} from '../../draganddrop/draganddrop';
import { withStateHOC } from '../../../stateTree';
import { useAnimatedStyle, useSharedValue } from 'react-native-reanimated';
import { tenFrameCounterColors } from '../../../theme/colors';
import { isEqual, isNotEqual } from '../../../utils/matchers';
import { StyleProp, View, ViewStyle } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { Dimens } from '../../../theme/scaling';
import { MeasureView } from '../../atoms/MeasureView';

const CHANNEL = 'QF21';
const largeMeasurements = TEN_FRAME_MEASUREMENTS['large'];
const xlargeMeasurements = TEN_FRAME_MEASUREMENTS['xlarge'];

type Payload =
  | {
      /** Ten frame that the draggable came from. */
      tenFrameIndex: number;
      /** Drop zone that the draggable came from. */
      dropZoneIndex: number;
    }
  | undefined;

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  items: CounterVariant[];
  /** See TenFrameLayout.tsx for itemOrdering info */
  itemOrdering?: 'rowFirst' | 'columnFirst';
  /** Ten frames are laid out with flex wrap. Default: 1 */
  numberOfTenFrames?: number;
  /** Default: row */
  tenFrameFlexDirection?: 'row' | 'column';
  /**
   * Specify which cells are interactive. Use 'notInitial' to make all cells but the initial cells interactive.
   * Default: 'all'.
   */
  interactiveCells?:
    | 'all'
    | 'notInitial'
    | boolean[][]
    | ((tenFrameIndex: number, index: number) => boolean);
  /** Default: an array of empty arrays, each of size 10, one for each ten frame. */
  initialState?:
    | (CounterVariant | null)[][]
    | ((tenFrameIndex: number, index: number) => CounterVariant | null | undefined);
  /** Default: anything different to initial state. */
  testComplete?: (state: (CounterVariant | null)[][]) => boolean;
  /** Answer to show on mark scheme */
  exampleCorrectAnswer:
    | (CounterVariant | null)[][]
    | ((tenFrameIndex: number, index: number) => CounterVariant | null | undefined);
  /**
   * Function to evaluate correctness. Defaults to checking equal to `exampleCorrectAnswer`.
   * See also {@link totalCountersIs}
   */
  testCorrect: (state: (CounterVariant | null)[][]) => boolean;
  customMarkSchemeAnswerText?: string;
  leftContent?: ElementOrRenderFunction<{ dimens: Dimens }>;
  mainPanelStyle?: StyleProp<ViewStyle>;
  questionHeight?: number;
};

/**
 * Draggable ten frames.
 *
 * Shows multiple ten frames, and some colored counters in the action panel. Each cell in the ten-frames is a drop
 * zone holding at most one counter. The drop zones are highlighted when a counter is hovered over them, with the
 * color of the counter.
 */
export default function QF21DraggableTenFrames({
  title,
  pdfTitle = title,
  items,
  itemOrdering = 'rowFirst',
  numberOfTenFrames = 1,
  tenFrameFlexDirection = 'row',
  interactiveCells: interactiveCellsProp = () => true,
  initialState: initialStateProp = () => null,
  testComplete: testCompleteProp,
  exampleCorrectAnswer: exampleCorrectAnswerProp,
  testCorrect: testCorrectProp,
  customMarkSchemeAnswerText,
  leftContent,
  mainPanelStyle,
  questionHeight
}: Props) {
  // Set defaults
  const initialState = countRange(numberOfTenFrames).map(tenFrameIndex =>
    countRange(10).map(index =>
      typeof initialStateProp === 'function'
        ? initialStateProp(tenFrameIndex, index) ?? null
        : initialStateProp[tenFrameIndex]?.[index] ?? null
    )
  );

  const interactiveCellsPropArrayOrFunction =
    interactiveCellsProp === 'all'
      ? () => true
      : interactiveCellsProp === 'notInitial'
      ? (tenFrameIndex: number, index: number) =>
          (initialState[tenFrameIndex]?.[index] ?? null) === null
      : interactiveCellsProp;
  const interactiveCells =
    typeof interactiveCellsPropArrayOrFunction === 'function'
      ? countRange(numberOfTenFrames).map(tenFrameIndex =>
          countRange(10).map(index => interactiveCellsPropArrayOrFunction(tenFrameIndex, index))
        )
      : interactiveCellsPropArrayOrFunction;

  const testComplete = testCompleteProp ?? isNotEqual(initialState);

  const exampleCorrectAnswer =
    typeof exampleCorrectAnswerProp === 'function'
      ? countRange(numberOfTenFrames).map(tenFrameIndex =>
          countRange(10).map(index => exampleCorrectAnswerProp(tenFrameIndex, index) ?? null)
        )
      : exampleCorrectAnswerProp;

  const testCorrect = testCorrectProp ?? isEqual(exampleCorrectAnswer);

  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            <View
              style={[
                {
                  maxWidth: '100%',
                  flexWrap: 'wrap',
                  flexDirection: tenFrameFlexDirection,
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 40,
                  rowGap: 25,
                  paddingRight: 400 // Just to force max 2 ten frames per row
                },
                mainPanelStyle
              ]}
            >
              {leftContent && (
                <MeasureView>
                  {dimens =>
                    resolveElementOrRenderFunction(leftContent, {
                      dimens
                    })
                  }
                </MeasureView>
              )}
              {countRange(numberOfTenFrames).map(i => (
                <TenFrameLayout
                  key={i}
                  size="xlarge"
                  colorBlindMode
                  items={index =>
                    (displayMode === 'markscheme'
                      ? exampleCorrectAnswer[i]?.[index]
                      : initialState[i]?.[index]) ?? undefined
                  }
                  itemOrdering={itemOrdering}
                />
              ))}
            </View>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswerText &&
              renderMarkSchemeProp(customMarkSchemeAnswerText)}
          </>
        }
      />
    );
  }

  return (
    <DragAndDropWithState
      id="QF21"
      defaultState={initialState}
      testComplete={testComplete}
      testCorrect={testCorrect}
      items={items}
    >
      {(source, dropZone) => (
        <BaseLayout
          title={title}
          actionPanelVariant="end"
          actionPanelContents={
            <DragAndDropSection style={{ padding: 0 }}>
              {items.map(item => (
                <Fragment key={item}>{source(item)}</Fragment>
              ))}
            </DragAndDropSection>
          }
          mainPanelContents={
            <View
              style={[
                {
                  flexWrap: 'wrap',
                  flexDirection: tenFrameFlexDirection,
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  alignContent: 'center',
                  columnGap: 40,
                  rowGap: 25
                },
                mainPanelStyle
              ]}
            >
              {leftContent && (
                <MeasureView>
                  {dimens =>
                    resolveElementOrRenderFunction(leftContent, {
                      dimens
                    })
                  }
                </MeasureView>
              )}
              {countRange(numberOfTenFrames).map(i => (
                <TenFrameLayout
                  key={i}
                  size="large"
                  items={index => dropZone(i, index, interactiveCells[i]?.[index] ?? false)}
                  itemOrdering={itemOrdering}
                />
              ))}
            </View>
          }
        />
      )}
    </DragAndDropWithState>
  );
}

/**
 * Helper component for powering the drag and drop zones in this question.
 *
 * Uses the base draggable and droppable components directly, rather than using EasyDragAndDrop.
 */
function DragAndDrop({
  items,
  state,
  setState,
  children
}: {
  items: CounterVariant[];
  state: (CounterVariant | null)[][];
  setState: SetState<(CounterVariant | null)[][]>;
  children: (
    source: (itemName: CounterVariant) => JSX.Element,
    dropZone: (
      tenFrameIndex: number,
      dropZoneIndex: number,
      /** Default: true */
      isInteractive?: boolean
    ) => JSX.Element
  ) => JSX.Element;
}) {
  return children(
    item => <Source item={item} />,
    (tenFrameIndex, dropZoneIndex, isInteractive = true) => {
      if (isInteractive) {
        return (
          <DropZone
            tenFrameIndex={tenFrameIndex}
            index={dropZoneIndex}
            items={items}
            state={state}
            setState={setState}
          />
        );
      }

      const item = state[tenFrameIndex]?.[dropZoneIndex] ?? null;
      if (item !== null) {
        return renderTenFrameCounter({ size: 'large', variant: item });
      } else {
        return <></>;
      }
    }
  );
}

const DragAndDropWithState = withStateHOC(DragAndDrop);

function Source({ item }: { item: CounterVariant }) {
  return (
    <Draggable
      id={item}
      payload={undefined}
      channel={CHANNEL}
      style={{
        width: xlargeMeasurements.counterWidth,
        height: xlargeMeasurements.counterWidth,
        shadowColor: 'black',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.15,
        shadowRadius: 16,
        elevation: 8,
        borderRadius: 999,
        backgroundColor: 'transparent'
      }}
      styleWorklet={() => {
        'worklet';
        return { shadowOffset: { width: 0, height: 2, elevation: 2 } };
      }}
    >
      {renderTenFrameCounter({ size: 'xlarge', variant: item, showShadow: false })}
    </Draggable>
  );
}

function DropZone({
  tenFrameIndex,
  index,
  state,
  setState
}: {
  tenFrameIndex: number;
  index: number;
  items: CounterVariant[];
  state: (CounterVariant | null)[][];
  setState: SetState<(CounterVariant | null)[][]>;
}) {
  const draggable: CounterVariant | null = state[tenFrameIndex]?.[index] ?? null;

  // Make the drop zone background change as draggables are hovered over it
  const draggableHoveredOver = useSharedValue<CounterVariant | null>(null);
  const hoveredOverStyle = useAnimatedStyle(() => {
    if (draggableHoveredOver.value === null) {
      return { backgroundColor: 'transparent' };
    } else {
      const color = tenFrameCounterColors[draggableHoveredOver.value];
      return { backgroundColor: `${color}66` /* 40% opacity */ };
    }
  }, [draggableHoveredOver]);
  const onEntered = useCallback(
    (event: { draggable: DraggableEventData }) => {
      draggableHoveredOver.value = event.draggable.id as CounterVariant;
    },
    [draggableHoveredOver]
  );
  const onExited = useCallback(() => {
    draggableHoveredOver.value = null;
  }, [draggableHoveredOver]);

  // Make the drop zone respond to draggables being dropped into it
  const onDropInto = useCallback(
    ({ draggable: newDraggable }: { draggable: DraggableEventData }) => {
      setState(state => {
        // 1. At the index of this droppable, put the new one in
        state = readonlyArrayEntry(state, tenFrameIndex, tenFrameState =>
          readonlyArrayEntry(tenFrameState, index, () => newDraggable.id as CounterVariant)
        );

        const payload = newDraggable.payload as Payload;
        if (payload !== undefined) {
          // 2. At the index the draggable came from (if any), *swap* the current contents (if any) to that one
          state = readonlyArrayEntry(state, payload.tenFrameIndex, tenFrameState =>
            readonlyArrayEntry(tenFrameState, payload.dropZoneIndex, () => draggable)
          );
        }

        return state;
      });
      draggableHoveredOver.value = null;
    },
    [draggable, draggableHoveredOver, index, setState, tenFrameIndex]
  );

  // When dragging a draggable from within a drop zone, it uses "move" behaviour - the original disappears.
  const draggableStyleWorklet = useCallback(
    (state: AnimationState) => {
      'worklet';
      let opacity;
      if (draggable === null) {
        opacity = 0;
      } else {
        // By default, you drag a copy around. We want to hide the original when the copy is being dragged.
        opacity = state.home ? 1 : 0;
      }

      // Also reduce shadow when on the ground
      return { opacity, shadowOffset: { width: 0, height: 2 }, elevation: 2 };
    },
    [draggable]
  );

  // When item is dragged out of drop zone it
  const onDragEnd = useCallback(
    (event: { droppable?: DroppableEventData }) => {
      if (event.droppable === undefined && draggable !== undefined) {
        // Draggable was dropped nowhere in particular.
        setState(state =>
          readonlyArrayEntry(state, tenFrameIndex, tenFrameState =>
            readonlyArrayEntry(tenFrameState, index, () => null)
          )
        );
      }
    },
    [draggable, index, setState, tenFrameIndex]
  );

  return (
    <Droppable
      channel={CHANNEL}
      onDropInto={onDropInto}
      onEntered={onEntered}
      onExited={onExited}
      style={[
        {
          width: largeMeasurements.cellWidth,
          height: largeMeasurements.cellWidth,
          alignItems: 'center',
          justifyContent: 'center'
        },
        hoveredOverStyle
      ]}
    >
      {draggable !== null && (
        // Show draggable in this dropzone
        <Draggable
          id={draggable}
          payload={{ tenFrameIndex: tenFrameIndex, dropZoneIndex: index }}
          styleWorklet={draggableStyleWorklet}
          onDragEnd={onDragEnd}
          channel={CHANNEL}
          style={{
            width: largeMeasurements.counterWidth,
            height: largeMeasurements.counterWidth,
            shadowColor: 'black',
            shadowOffset: { width: 0, height: 8 },
            shadowOpacity: 0.15,
            shadowRadius: 16,
            elevation: 9,
            borderRadius: 999,
            backgroundColor: 'transparent'
          }}
        >
          {renderTenFrameCounter({ size: 'large', variant: draggable, showShadow: false })}
        </Draggable>
      )}
    </Droppable>
  );
}

/** Check whether the state of a QF21 has this many total counters */
export function totalCountersIs(total: number) {
  return (state: (CounterVariant | null)[][]) =>
    state.reduce((sum, x) => sum + x.reduce((sum, x) => sum + (x !== null ? 1 : 0), 0), 0) ===
    total;
}

export function getTotalCounters(state: (CounterVariant | null)[][]) {
  return state.reduce((sum, x) => sum + x.reduce((sum, x) => sum + (x !== null ? 1 : 0), 0), 0);
}

/** Checks that each counter mentioned in the input record has the given total. Doesn't check counters that aren't mentioned. */
export function totalCountersByColorIs(...totals: [CounterVariant, number][]) {
  return (state: (CounterVariant | null)[][]) => {
    const variantStateTotals = state.flat().reduce(
      (countMap, color) => {
        if (color !== null) {
          countMap[color] = (countMap[color] || 0) + 1;
        }
        return countMap;
      },
      {} as Record<CounterVariant, number>
    );

    return totals.every(([variant, total]) => {
      if (variantStateTotals[variant]) {
        return variantStateTotals[variant] === total;
      } else if (total === 0) return true;
      else return false;
    });
  };
}
