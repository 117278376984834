import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { colors } from '../../../../theme/colors';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { View } from 'react-native';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { numberEnum } from '../../../../utils/zod';
import { countRange } from '../../../../utils/collections';
import { PartWholeModelWithState } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { chunk } from '../../../../utils/chunk';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bf4',
  description: 'bf4',
  keywords: ['Tens', 'Ones', 'Flexible partition'],
  schema: z.object({
    partition: numberEnum([10, 20]),
    variation: z.enum(['Straws', 'Cubes']),
    number: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(num => num % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 99, { constraint: x => x % 10 !== 0 });
    const partition = number < 30 ? 10 : getRandomFromArray([10, 20] as const);
    const variation = getRandomFromArray(['Straws', 'Cubes'] as const);

    return { partition, number, variation };
  },
  Component: props => {
    const {
      question: { partition, number, variation },
      translate,
      displayMode
    } = props;

    const numberOfPartitionOnes = number - Math.floor((number - partition) / 10) * 10;
    const numberOfPartitionTens = Math.floor((number - partition) / 10) * 10;

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={translate.ks1AnswerSentences.numCanBePartitionedIntoNumAndAns(
          number,
          numberOfPartitionTens
        )}
        testCorrect={[numberOfPartitionOnes.toString()]}
        Content={({ dimens }) => {
          const scale = BaseTenRepCalcGridsAndScale(
            dimens.width * 2,
            dimens.height * 2,
            { ones: number - partition, tens: partition },
            'Cubes'
          ).scale;

          const images = countRange(numberOfPartitionOnes).map(i => (
            <BaseTenRepresentation
              key={`ones_${i}`}
              b10Rep={{
                variant: variation,
                numbers: { ones: 1 },
                arrangement: 'ltr'
              }}
              usableWidth={displayMode === 'digital' ? 60 : 90}
              usableHeight={displayMode === 'digital' ? 60 : 90}
              scale={scale * 2}
              align="center"
            />
          ));

          const imagesChunked = chunk(images, 10);

          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {variation === 'Cubes' ? (
                <SimpleBaseTenWithCrossOut
                  tens={numberOfPartitionTens / 10}
                  ones={numberOfPartitionOnes}
                  dimens={{ width: dimens.width, height: dimens.height }}
                  splitOnesIntoGroupsOfTen
                />
              ) : (
                <View style={{ flexDirection: 'row' }}>
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: variation,
                      numbers: { tens: numberOfPartitionTens / 10 },
                      arrangement: 'ltr'
                    }}
                    usableWidth={dimens.width * 0.4}
                    usableHeight={dimens.height * 0.9}
                    scale={numberOfPartitionOnes > 4 ? scale * 2 : scale * 0.9}
                    align="center"
                  />
                  <View
                    style={{
                      flexDirection: 'column',
                      width: dimens.width / 2,
                      flexWrap: 'wrap',
                      alignSelf: 'center',
                      rowGap: scale > 0.3 ? (displayMode === 'digital' ? 80 : 180) : 30
                    }}
                  >
                    {imagesChunked.map((image, key) => (
                      <View key={key} style={{ display: 'flex', flexDirection: 'row' }}>
                        {image}
                      </View>
                    ))}
                  </View>
                </View>
              )}
            </View>
          );
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bf5',
  description: 'bf5',
  keywords: ['Tens', 'Ones', 'Flexible partition'],
  schema: z.object({
    number: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(num => num % 10 !== 0),
    isAnswerBoxLast: z.boolean(),
    isNumberShown: z.boolean(),
    variation: z.enum(['Straws', 'Cubes']),
    tensPartition: z.number().int().min(10).max(80)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 99, { constraint: x => x % 10 !== 0 });
    const isNumberShown = getRandomFromArrayWithWeights([true, false], [3, 1]);
    const isAnswerBoxLast = getRandomBoolean();
    const variation = getRandomFromArray(['Straws', 'Cubes'] as const);

    const tensPartition = randomIntegerInclusiveStep(10, number - 10, 10);

    return { number, isAnswerBoxLast, isNumberShown, variation, tensPartition };
  },
  Component: props => {
    const {
      question: { number, isAnswerBoxLast, isNumberShown, variation, tensPartition },
      translate,
      displayMode
    } = props;

    const color = displayMode === 'digital' ? colors.prussianBlue : 'black';
    const borderWidth = displayMode === 'digital' ? 2 : 4;

    const numberOfTens = Math.floor(number / 10);
    const numberOfOnes = number % 10;

    const sentence = isNumberShown
      ? translate.ks1AnswerSentences.numCanBePartitionedIntoAnsAndAns(number)
      : isAnswerBoxLast
      ? translate.ks1AnswerSentences.ansCanBePartitionedIntoNumAndAns(tensPartition)
      : translate.ks1AnswerSentences.ansCanBePartitionedIntoAnsAndNum(
          Math.floor(number - tensPartition)
        );

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentences()}
        sentence={sentence}
        inputMaxCharacters={2}
        testCorrect={userAnswer => {
          if (isNumberShown) {
            return (
              (userAnswer[0] === tensPartition.toString() &&
                userAnswer[1] === Math.floor(number - tensPartition).toString()) ||
              (userAnswer[0] === Math.floor(number - tensPartition).toString() &&
                userAnswer[1] === (tensPartition * 10).toString())
            );
          }
          return isAnswerBoxLast
            ? userAnswer[0] === number.toString() &&
                userAnswer[1] === Math.floor(number - tensPartition).toString()
            : userAnswer[0] === number.toString() && userAnswer[1] === tensPartition.toString();
        }}
        pdfDirection="column"
        Content={({ dimens }) => {
          const scale = BaseTenRepCalcGridsAndScale(
            dimens.width * 0.9,
            dimens.height * 0.9,
            { ones: numberOfOnes, tens: numberOfTens },
            'Cubes'
          ).scale;

          const simpleBaseTenScale = Scale(dimens.width * 0.4, dimens.height, {
            ones: numberOfOnes,
            tens: numberOfTens
          });

          return (
            <View style={{ flexDirection: 'row', alignItems: 'center', columnGap: 20 }}>
              <View
                style={{
                  borderRadius: 50,
                  borderWidth,
                  borderColor: color,
                  width: dimens.width * 0.4,
                  height: dimens.height,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {variation === 'Cubes' ? (
                  <SimpleBaseTenWithCrossOut
                    tens={tensPartition / 10}
                    dimens={{ width: dimens.width * 0.4, height: dimens.height }}
                    scale={simpleBaseTenScale}
                  />
                ) : (
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: variation,
                      numbers: { tens: tensPartition / 10 },
                      arrangement: 'ltr'
                    }}
                    usableWidth={dimens.width * 0.9}
                    usableHeight={dimens.height * 0.9}
                    scale={numberOfTens > 5 ? scale * 1.75 : scale}
                    align="center"
                  />
                )}
              </View>
              <View
                style={{
                  borderRadius: 50,
                  borderWidth,
                  borderColor: color,
                  width: dimens.width * 0.4,
                  height: dimens.height,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {variation === 'Cubes' ? (
                  <SimpleBaseTenWithCrossOut
                    ones={numberOfOnes}
                    tens={Math.floor((number - tensPartition) / 10)}
                    dimens={{ width: dimens.width * 0.4, height: dimens.height }}
                    scale={simpleBaseTenScale}
                  />
                ) : (
                  <BaseTenRepresentation
                    b10Rep={{
                      variant: variation,
                      numbers: {
                        tens: Math.floor((number - tensPartition) / 10),
                        ones: numberOfOnes
                      },
                      arrangement: 'ltr'
                    }}
                    usableWidth={dimens.width * 0.9}
                    usableHeight={dimens.height * 0.9}
                    scale={numberOfTens > 5 ? scale * 1.75 : scale}
                    align="center"
                  />
                )}
              </View>
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: isNumberShown
            ? [tensPartition.toLocaleString(), Math.floor(number - tensPartition).toLocaleString()]
            : [
                number.toLocaleString(),
                isAnswerBoxLast
                  ? Math.floor(number - tensPartition).toLocaleString()
                  : tensPartition.toLocaleString()
              ],
          answerText: isNumberShown ? translate.markScheme.acceptAnyOrder() : ''
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bf6',
  description: 'bf6',
  keywords: ['Part-whole model', 'Flexible partition'],
  schema: z.object({
    tensPartition: z.number().int().min(10).max(80),
    isTensLHS: z.boolean(),
    unitOfAnswer: z.enum(['tens', 'other']),
    number: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(num => num % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 99, { constraint: x => x % 10 !== 0 });
    const tensPartition = randomIntegerInclusiveStep(10, number - 10, 10);

    const isTensLHS = getRandomBoolean();

    const unitOfAnswer = getRandomFromArray(['tens', 'other'] as const);

    return { number, tensPartition, isTensLHS, unitOfAnswer };
  },
  Component: props => {
    const {
      question: { number, tensPartition, isTensLHS, unitOfAnswer },
      translate,
      displayMode
    } = props;

    let partition: (string | '$ans')[];
    let correctAnswer: number;

    if (unitOfAnswer === 'tens') {
      partition = isTensLHS
        ? ['$ans', (number - tensPartition).toLocaleString()]
        : [(number - tensPartition).toLocaleString(), '$ans'];
      correctAnswer = tensPartition;
    } else {
      partition = isTensLHS
        ? [tensPartition.toLocaleString(), '$ans']
        : ['$ans', tensPartition.toLocaleString()];
      correctAnswer = number - tensPartition;
    }

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.completeThePartWholeModel()}
        Content={({ dimens }) => (
          <PartWholeModelWithState
            id="part-whole-model"
            top={number}
            partition={partition}
            isInteractive
            dimens={dimens}
            testCorrect={isEqual([correctAnswer.toString()])}
            defaultState={displayMode === 'markscheme' ? [correctAnswer.toLocaleString()] : ['']}
          />
        )}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FlexiblyPartitionNumbersTo100',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
