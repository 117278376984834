import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomBoolean, randomIntegerInclusive } from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF21DraggableTenFrames, {
  totalCountersIs
} from '../../../../components/question/questionFormats/QF21DraggableTenFrames';
import {
  counterVariantSchema,
  getRandomUniqueCounters
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { NumberGridInteractiveWithState } from '../../../../components/question/representations/NumberGridInteractive';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bay',
  description: 'bay',
  keywords: ['Count', 'Ten frame', 'Less'],
  schema: z.object({
    num: z.number().int().min(2).max(10),
    colour: counterVariantSchema
  }),
  simpleGenerator: () => {
    const num = randomIntegerInclusive(2, 10);
    const [colour] = getRandomUniqueCounters(1);
    return { num, colour };
  },
  Component: props => {
    const {
      question: { num, colour },
      translate
    } = props;

    const correctAnswer = num - 1;

    return (
      <QF21DraggableTenFrames
        title={translate.ks1Instructions.dragCountersToShowXLessThanY(1, num.toLocaleString())}
        pdfTitle={translate.ks1PDFInstructions.drawXLessThanY(1, num.toLocaleString())}
        numberOfTenFrames={1}
        tenFrameFlexDirection="row"
        items={[colour]}
        exampleCorrectAnswer={(tenFrameIndex, index) => {
          if (tenFrameIndex === 0 && index < correctAnswer) return 'red';
        }}
        testCorrect={totalCountersIs(correctAnswer)}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'baz',
  description: 'baz',
  keywords: ['Table', 'Addition', 'More'],
  schema: z.object({
    cells: z.number().int().min(6).max(8),
    number: z.number().int().min(1).max(10),
    increment: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const increment = randomIntegerInclusive(0, 2);
    const cells = randomIntegerInclusive(6, 8);
    const number = randomIntegerInclusive(1, 10, {
      constraint: x => x > 1 + increment && x < cells + increment
    });

    return { cells, number, increment };
  },
  Component: props => {
    const {
      question: { number, cells, increment },
      translate,
      displayMode
    } = props;

    return (
      <QF3Content
        title={translate.ks1Instructions.tapXLessThanY(1, number)}
        pdfTitle={translate.ks1PDFInstructions.circleXLessThanY(1, number)}
        Content={({ dimens }) => (
          <NumberGridInteractiveWithState
            startNumber={1 + increment}
            finishNumber={cells + increment}
            testCorrect={userAnswer => userAnswer[0] === number - 1}
            dimens={{
              width: dimens.width,
              height: dimens.height
            }}
            id={'number grid interactive'}
            defaultState={displayMode === 'markscheme' ? [number - 1] : []}
            multiSelect={false}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'baA',
  description: 'baA',
  keywords: ['Less'],
  schema: z.object({
    number: z.number().int().min(0).max(10),
    variant: z.boolean()
  }),
  simpleGenerator: () => {
    const variant = getRandomBoolean();
    const number = randomIntegerInclusive(0, variant ? 10 : 9);
    return { number, variant };
  },

  Component: props => {
    const {
      question: { number, variant },
      translate
    } = props;

    const answer = variant ? number + 1 : number - 1;
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          variant
            ? translate.ks1AnswerSentences.xLessThanAnsIsY(1, number)
            : translate.ks1AnswerSentences.xLessThanYIsAns(1, number)
        }
        testCorrect={[answer.toString()]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'baA2',
  description: 'baA',
  keywords: ['Less'],
  schema: z
    .object({
      number: z.number().int().min(0).max(10),
      ansIs: z.boolean()
    })
    .refine(
      ({ number, ansIs }) => isInRange(0, 10)(number + (ansIs ? +1 : -1)),
      'answers should be in range 0,10'
    ),
  simpleGenerator: () => {
    const ansIs = getRandomBoolean();
    const [min, max] = ansIs ? [0, 9] : [1, 10];

    const number = randomIntegerInclusive(min, max);

    return { number, ansIs };
  },

  Component: props => {
    const {
      question: { number, ansIs },
      translate
    } = props;

    const answer = ansIs ? number + 1 : number - 1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={
          ansIs
            ? translate.ks1AnswerSentences.xLessThanAnsIsY(1, number)
            : translate.ks1AnswerSentences.xLessThanYIsAns(1, number)
        }
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OneLess',
  questionTypes: [Question1, Question2, Question3v2],
  unpublishedQuestionTypes: [Question3v2],
  archivedQuestionTypes: [Question3]
});
export default SmallStep;
