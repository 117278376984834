import { countRange, filledArray, to2dArray } from '../../../../utils/collections';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { ShadedCounterArrangements } from './ShadedCounterBoxArrangement';

/**
 * Create 2D patterns of shaded & non-shaded counters (and empty spaces).
 */
export default function getShadedCounterArrangements({
  cols,
  rows,
  numShaded,
  numNonShaded = 0,
  shadedVariation
}: {
  cols: number;
  rows: number;
  numShaded: number;
  /** Only used for the random shaded variation */
  numNonShaded?: number;
  /**
   * The pattern which the shaded counters follow.
   * rows, columns and quadrants will return full box arrangements i.e only shaded and non-shaded counters
   * random can random arrangement of shaded, non-shaded and empty counters
   */
  shadedVariation: 'rows' | 'columns' | 'random' | 'quadrants';
}): ShadedCounterArrangements {
  const total = cols * rows;
  if (numShaded > total) throw new Error('Cannot have more shaded than total counters');

  switch (shadedVariation) {
    case 'rows': {
      // get indices of full rows
      const fullRowsIndices = getRandomSubArrayFromArray(
        countRange(rows),
        Math.floor(numShaded / cols)
      );
      // get index of partial row
      const partialRowIndex =
        numShaded % cols !== 0
          ? randomIntegerInclusive(0, rows - 1, {
              constraint: x => !fullRowsIndices.includes(x)
            })
          : undefined;
      const partialRowCounters = numShaded % cols;
      return countRange(rows).map(r =>
        countRange(cols).map(c =>
          fullRowsIndices.includes(r) ||
          (partialRowIndex === r && countRange(partialRowCounters).includes(c))
            ? 2
            : 1
        )
      );
    }
    case 'columns': {
      // get indices of full columns
      const fullColsIndices = getRandomSubArrayFromArray(
        countRange(cols),
        Math.floor(numShaded / rows)
      );
      // get index of partial columns
      const partialColsIndex =
        numShaded % rows !== 0
          ? randomIntegerInclusive(0, cols - 1, {
              constraint: x => !fullColsIndices.includes(x)
            })
          : undefined;
      const partialColsCounters = numShaded % rows;
      return countRange(rows).map(r =>
        countRange(cols).map(c =>
          fullColsIndices.includes(c) ||
          (partialColsIndex === c && countRange(partialColsCounters).includes(r))
            ? 2
            : 1
        )
      );
    }
    case 'random': {
      const shuffled = shuffle([
        ...filledArray(2, numShaded),
        ...filledArray(1, numNonShaded),
        ...filledArray(0, total - numNonShaded - numShaded)
      ] as const);

      // check if the shuffled array can be converted to a valid 2d array
      if (
        shuffled.length !== total ||
        shuffled.length % cols !== 0 ||
        shuffled.length / cols !== rows
      )
        throw new Error('Invalid shape given');
      return to2dArray(shuffled, cols, rows) as ShadedCounterArrangements;
    }
    case 'quadrants': {
      if (total % 4 !== 0) throw new Error('area should be divisible by 4');

      const quadrantSize = [rows / 2, cols / 2];
      const quadrantToShade = randomUniqueIntegersInclusive(0, 3, (numShaded / total) * 4);

      const startShadeIndices = [
        [0, 0],
        [0, cols / 2],
        [rows / 2, 0],
        [rows / 2, cols / 2]
      ];

      // create empty unshaded 2d counter array
      const array: ShadedCounterArrangements = countRange(rows).map(_ =>
        countRange(cols).map(_ => 1)
      );

      // replace quadrants with shaded counters
      quadrantToShade.forEach(i => {
        for (let r = startShadeIndices[i][0]; r < startShadeIndices[i][0] + quadrantSize[0]; r++) {
          for (
            let c = startShadeIndices[i][1];
            c < startShadeIndices[i][1] + quadrantSize[1];
            c++
          ) {
            array[r][c] = 2;
          }
        }
      });
      return array;
    }
  }
}
