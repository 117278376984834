import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  randomNumberPairs,
  shuffle
} from '../../../../utils/random';
import { countRange, filledArray } from '../../../../utils/collections';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';

////
// Questions
////

//Also used in:
//bb1
const Question1 = newQuestionContent({
  uid: 'bgy',
  description: 'bgy',
  keywords: ['Number bonds', 'Add', 'Ten frames', 'Counters'],
  schema: z.object({
    tenFrameCounters: z.number().int().min(1).max(9),
    counterColor: z.enum(['red', 'yellow', 'blue', 'green']),
    rowOrColumn: z.enum(['rowFirst', 'columnFirst'])
  }),
  simpleGenerator: () => {
    const tenFrameCounters = randomIntegerInclusive(1, 9);
    const counterColor = getRandomFromArray(['red', 'yellow', 'blue', 'green'] as const);
    const rowOrColumn = getRandomFromArray(['rowFirst', 'columnFirst'] as const);

    return { tenFrameCounters, counterColor, rowOrColumn };
  },
  Component: ({ question: { tenFrameCounters, counterColor, rowOrColumn }, translate }) => {
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyMoreToMakeX(10)}
        Content={
          <TenFrameLayout
            items={filledArray(counterColor, tenFrameCounters)}
            itemOrdering={rowOrColumn}
          />
        }
        sentence="<ans/>"
        testCorrect={[(10 - tenFrameCounters).toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

export const bgy = Question1;

const Question2 = newQuestionContent({
  uid: 'bgz',
  description: 'bgz',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    bonds: z.array(z.array(z.number().min(0).max(12)).length(2)).length(9)
  }),
  simpleGenerator: () => {
    const numOfCorrect = randomIntegerInclusive(3, 9);
    const numOfIncorrect = 9 - numOfCorrect;

    // Constraint for all numbers to be max 1-digit
    const constraint = (x: number) => x.toString().length < 2 && (10 - x).toString().length < 2;

    const correctPairs: [number, number][] = randomNumberPairs(10, numOfCorrect, { constraint });

    // Incorrect pairs
    // First choose the incorrect numbers to sum to
    const incorrectSumNums = countRange(numOfIncorrect).map(() =>
      getRandomFromArray([8, 9, 11, 12])
    );
    const incorrectPairs: [number, number][] = incorrectSumNums.map(
      num => randomNumberPairs(num, 1, { constraint })[0]
    );

    return { bonds: shuffle([...correctPairs, ...incorrectPairs]) };
  },
  Component: ({ question: { bonds }, translate }) => {
    const items = bonds.map(([num1, num2], idx) => ({
      component: `${num1.toLocaleString()} ${ADD} ${num2.toLocaleString()}`,
      value: idx,
      isCorrect: num1 + num2 === 10
    }));

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectAllTheNumberBondsToNum(10)}
        pdfTitle={translate.ks1PDFInstructions.tickAllTheNumberBondsToNum(10)}
        pdfShowBorder
        items={items}
        multiSelect
        testCorrect={items.filter(it => it.isCorrect).map(it => it.value)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bgA',
  description: 'bgA',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    bond: z.array(z.number().int().min(0).max(10)),
    sumOnRight: z.boolean(),
    ansIndex: numberEnum([0, 1, 2])
  }),
  simpleGenerator: () => {
    const sumOnRight = getRandomFromArrayWithWeights([true, false], [3, 1]);
    const ansIndex = getRandomFromArray([0, 1, 2] as const);

    const [bond] = randomNumberPairs(10, 1);

    return { bond, sumOnRight, ansIndex };
  },
  Component: ({ question: { bond, sumOnRight, ansIndex }, translate }) => {
    const [sentenceParts, answer] = (() => {
      switch (ansIndex) {
        case 0:
          return [['<ans/>', bond[1].toLocaleString(), (10).toLocaleString()], bond[0]];
        case 1:
          return [[bond[0].toLocaleString(), '<ans/>', (10).toLocaleString()], bond[1]];
        case 2:
          return [[bond[0].toLocaleString(), bond[1].toLocaleString(), '<ans/>'], 10];
      }
    })();

    const sentence = sumOnRight
      ? `${sentenceParts[0]} ${ADD} ${sentenceParts[1]} = ${sentenceParts[2]}`
      : `${sentenceParts[2]} = ${sentenceParts[1]} + ${sentenceParts[0]}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'BondsTo10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
