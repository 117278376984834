import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { nestedArraysHaveSameContentsUnordered } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'baa',
  description: 'baa',
  keywords: ['Sort', 'Group'],
  schema: z.object({
    fruitA: z
      .array(z.enum(['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry']))
      .min(2)
      .max(7),
    fruitB: z
      .array(z.enum(['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry']))
      .min(2)
      .max(7)
  }),
  simpleGenerator: () => {
    const fruits = ['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry'] as const;
    const numCards = getRandomBoolean() ? 6 : 9;
    const numFruitA = randomIntegerInclusive(2, numCards - 2);
    const fruitAChoice = getRandomFromArray(fruits);

    const numFruitB = numCards - numFruitA;
    const remaingFruits = fruits.filter(item => item !== fruitAChoice);
    const fruitBChoice = getRandomFromArray(remaingFruits);

    const fruitA = new Array(numFruitA).fill(fruitAChoice);
    const fruitB = new Array(numFruitB).fill(fruitBChoice);

    return { fruitA, fruitB };
  },
  Component: ({ question: { fruitA, fruitB }, translate }) => {
    const correctOrder = [fruitA, fruitB];
    const items = shuffle([...fruitA, ...fruitB], {
      random: seededRandom({ fruitA, fruitB })
    });

    const fruitSvgPaths = {
      Banana: 'Array_objects/Banana',
      Pear: 'Array_objects/Pear',
      Orange: 'Array_objects/Orange',
      'Green apple': 'Array_objects/AppleGreen',
      Strawberry: 'Array_objects/Strawberry'
    };

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheFruitIntoGroups()}
        pdfTitle={translate.ks1PDFInstructions.sortTheFruitIntoGroups()}
        zoneNames={[
          translate.ks1Instructions.groupN({ numberA: 1 }),
          translate.ks1Instructions.groupN({ numberA: 2 })
        ]}
        testCorrect={userAnswer => {
          return nestedArraysHaveSameContentsUnordered(userAnswer, correctOrder);
        }}
        items={items.map((val, index) => ({
          value: val,
          component: (
            <AssetSvg key={index} name={fruitSvgPaths[val] as SvgName} width={80} height={80} />
          )
        }))}
        actionPanelVariant="endWide"
        itemVariant="square"
        pdfItemVariant="square"
        itemsMaxLines={3}
        itemsLetterEmWidth={0.6}
        questionHeight={800}
        customMarkSchemeAnswer={{ answerToDisplay: correctOrder }}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bab',
  description: 'bab',
  keywords: ['Sort', 'Group'],
  schema: z.object({
    fruitA: z
      .array(z.enum(['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry']))
      .min(1)
      .max(7),
    fruitB: z
      .array(z.enum(['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry']))
      .min(1)
      .max(7),
    fruitC: z
      .array(z.enum(['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry']))
      .min(1)
      .max(7)
  }),
  simpleGenerator: () => {
    const fruits = ['Banana', 'Pear', 'Orange', 'Green apple', 'Strawberry'] as const;
    const numCards = getRandomBoolean() ? 6 : 9;
    const numFruitA = randomIntegerInclusive(1, numCards - 2);
    const fruitAChoice = getRandomFromArray(fruits);

    const numFruitB = randomIntegerInclusive(1, numCards - numFruitA - 1);
    let remaingFruits = fruits.filter(item => item !== fruitAChoice);
    const fruitBChoice = getRandomFromArray(remaingFruits);

    const numFruitC = numCards - numFruitA - numFruitB;
    remaingFruits = fruits.filter(item => item !== fruitAChoice && item !== fruitBChoice);
    const fruitCChoice = getRandomFromArray(remaingFruits);

    const fruitA = new Array(numFruitA).fill(fruitAChoice);
    const fruitB = new Array(numFruitB).fill(fruitBChoice);
    const fruitC = new Array(numFruitC).fill(fruitCChoice);

    return { fruitA, fruitB, fruitC };
  },
  Component: ({ question: { fruitA, fruitB, fruitC }, translate }) => {
    const correctOrder = [fruitA, fruitB, fruitC];
    const items = shuffle([...fruitA, ...fruitB, ...fruitC], {
      random: seededRandom({ fruitA, fruitB, fruitC })
    });

    const fruitSvgPaths = {
      Banana: 'Array_objects/Banana',
      Pear: 'Array_objects/Pear',
      Orange: 'Array_objects/Orange',
      'Green apple': 'Array_objects/AppleGreen',
      Strawberry: 'Array_objects/Strawberry'
    };

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheFruitIntoGroups()}
        pdfTitle={translate.ks1PDFInstructions.sortTheFruitIntoGroups()}
        zoneNames={[
          translate.ks1Instructions.groupN({ numberA: 1 }),
          translate.ks1Instructions.groupN({ numberA: 2 }),
          translate.ks1Instructions.groupN({ numberA: 3 })
        ]}
        testCorrect={userAnswer => {
          return nestedArraysHaveSameContentsUnordered(userAnswer, correctOrder);
        }}
        items={items.map((val, index) => ({
          value: val,
          component: (
            <AssetSvg key={index} name={fruitSvgPaths[val] as SvgName} width={80} height={80} />
          )
        }))}
        actionPanelVariant="endWide"
        itemVariant="square"
        pdfItemVariant="square"
        itemsMaxLines={3}
        itemsLetterEmWidth={0.6}
        questionHeight={800}
        customMarkSchemeAnswer={{ answerToDisplay: correctOrder }}
        zoneCapacity={8}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bac',
  description: 'bac',
  keywords: ['Sort', 'Group'],
  schema: z.object({
    shapeA: z
      .array(
        z.enum([
          'square_blue',
          'square_red',
          'square_yellow',
          'square_green',
          'triangle_blue',
          'triangle_red',
          'triangle_yellow',
          'triangle_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green',
          'star_green',
          'star_yellow',
          'star_blue',
          'star_red'
        ])
      )
      .min(1)
      .max(7),
    shapeB: z
      .array(
        z.enum([
          'square_blue',
          'square_red',
          'square_yellow',
          'square_green',
          'triangle_blue',
          'triangle_red',
          'triangle_yellow',
          'triangle_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green',
          'star_green',
          'star_yellow',
          'star_blue',
          'star_red'
        ])
      )
      .min(1)
      .max(7),
    colourA: z
      .array(
        z.enum([
          'square_blue',
          'square_red',
          'square_yellow',
          'square_green',
          'triangle_blue',
          'triangle_red',
          'triangle_yellow',
          'triangle_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green',
          'star_green',
          'star_yellow',
          'star_blue',
          'star_red'
        ])
      )
      .min(1)
      .max(7),
    colourB: z
      .array(
        z.enum([
          'square_blue',
          'square_red',
          'square_yellow',
          'square_green',
          'triangle_blue',
          'triangle_red',
          'triangle_yellow',
          'triangle_green',
          'circle_blue',
          'circle_red',
          'circle_yellow',
          'circle_green',
          'star_green',
          'star_yellow',
          'star_blue',
          'star_red'
        ])
      )
      .min(1)
      .max(7)
  }),
  simpleGenerator: () => {
    const colours = ['blue', 'red', 'yellow', 'green'] as const;
    const shapes = ['square', 'triangle', 'circle', 'star'] as const;
    const numCards = getRandomBoolean() ? 6 : 9;

    const numShapeA = randomIntegerInclusive(2, numCards - 2);
    const shapeAChoice = getRandomFromArray(shapes);

    const numShapeB = numCards - numShapeA;
    const shapeBChoice = getRandomFromArray(shapes.filter(item => item !== shapeAChoice));

    const colourAChoice = getRandomFromArray(colours);
    const colourBChoice = getRandomFromArray(colours.filter(item => item !== colourAChoice));

    const numShapeAColourA = randomIntegerInclusive(1, numShapeA - 1);
    const numShapeAColourB = numShapeA - numShapeAColourA;
    const numShapeBColourA = randomIntegerInclusive(1, numShapeB - 1);
    const numShapeBColourB = numShapeB - numShapeBColourA;

    const shapeAColourA = new Array(numShapeAColourA).fill(`${shapeAChoice}_${colourAChoice}`);
    const shapeAColourB = new Array(numShapeAColourB).fill(`${shapeAChoice}_${colourBChoice}`);
    const shapeBColourA = new Array(numShapeBColourA).fill(`${shapeBChoice}_${colourAChoice}`);
    const shapeBColourB = new Array(numShapeBColourB).fill(`${shapeBChoice}_${colourBChoice}`);

    const shapeA = [...shapeAColourA, ...shapeAColourB];
    const shapeB = [...shapeBColourA, ...shapeBColourB];
    const colourA = [...shapeAColourA, ...shapeBColourA];
    const colourB = [...shapeAColourB, ...shapeBColourB];

    return { shapeA, shapeB, colourA, colourB };
  },
  Component: ({ question: { shapeA, shapeB, colourA, colourB }, translate }) => {
    const correctOrder = [shapeA, shapeB];
    const correctOrder2 = [colourA, colourB];
    const items = shuffle([...shapeA, ...shapeB], {
      random: seededRandom({ shapeA, shapeB })
    });

    const shapeColourSvgPaths = {
      square_blue: 'Square/square_blue',
      square_red: 'Square/square_red',
      square_green: 'Square/square_green',
      square_yellow: 'Square/square_yellow',
      circle_blue: 'Circles/circle_blue',
      circle_red: 'Circles/circle_red',
      circle_green: 'Circles/circle_green',
      circle_yellow: 'Circles/circle_yellow',
      triangle_blue: 'Equilateral_triangles/triangle_equal_blue',
      triangle_red: 'Equilateral_triangles/triangle_equal_red',
      triangle_green: 'Equilateral_triangles/triangle_equal_green',
      triangle_yellow: 'Equilateral_triangles/triangle_equal_yellow',
      star_green: 'Star_green',
      star_blue: 'Star_blue',
      star_red: 'Star_red',
      star_yellow: 'Star_yellow'
    };

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheObjectsIntoGroups()}
        pdfTitle={translate.ks1PDFInstructions.sortTheObjectsIntoGroups()}
        zoneNames={[
          translate.ks1Instructions.groupN({ numberA: 1 }),
          translate.ks1Instructions.groupN({ numberA: 2 })
        ]}
        testCorrect={userAnswer => {
          return (
            nestedArraysHaveSameContentsUnordered(userAnswer, correctOrder) ||
            nestedArraysHaveSameContentsUnordered(userAnswer, correctOrder2)
          );
        }}
        items={items.map((val, index) => ({
          value: val,
          component: (
            <AssetSvg
              key={index}
              name={shapeColourSvgPaths[val] as SvgName}
              width={80}
              height={80}
            />
          )
        }))}
        actionPanelVariant="endWide"
        itemVariant="square"
        pdfItemVariant="square"
        itemsMaxLines={3}
        itemsLetterEmWidth={0.6}
        questionHeight={800}
        customMarkSchemeAnswer={{ answerToDisplay: correctOrder }}
        zoneCapacity={8}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SortObjects',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
