import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { View } from 'react-native';
import TextStructure from '../../../../components/molecules/TextStructure';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { ADD } from '../../../../constants';
import { numberEnum } from '../../../../utils/zod';
import { getRandomBoolean, getRandomFromArray } from '../../../../utils/random';
import { countRange } from '../../../../utils/collections';
import { numberToBase10Object } from '../../../../utils/math';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { colors } from '../../../../theme/colors';
import { horizontalArrow } from '../../../../components/question/representations/LineSvgs';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'beb',
  description: 'beb',
  keywords: ['Double', 'Ten frame', 'Counters'],
  schema: z.object({
    number: z.number().int().min(1).max(10),
    colors: z.enum(['red', 'yellow', 'blue', 'green']).array().length(2)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 10);
    const colors = getRandomSubArrayFromArray(['red', 'yellow', 'blue', 'green'] as const, 2);

    return { number, colors };
  },
  Component: props => {
    const {
      question: { number, colors },
      translate,
      displayMode
    } = props;

    const double = number * 2;
    const numberOfTenFrames = double > 10 ? 2 : 1;
    let isColorOne = false;
    const arrays = [
      countRange(Math.max(double - 10, double)).map(() => {
        isColorOne = !isColorOne;
        return isColorOne ? colors[0] : colors[1];
      }),
      countRange(Math.max(double - 10, 0)).map(() => {
        isColorOne = !isColorOne;
        return isColorOne ? colors[0] : colors[1];
      })
    ];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatDoubleIsShown()}
        Content={
          <View
            style={{
              maxWidth: '100%',
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              columnGap: 40,
              rowGap: 25
            }}
          >
            {countRange(numberOfTenFrames).map(i => (
              <TenFrameLayout
                key={i}
                items={arrays[i]}
                itemOrdering="columnFirst"
                size={displayMode === 'digital' ? 'xsmall' : 'medium'}
              />
            ))}
          </View>
        }
        pdfDirection="column"
        sentence={translate.ks1AnswerSentences.doubleAnsIsAns()}
        testCorrect={[number.toString(), double.toString()]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bec',
  description: 'bec',
  keywords: ['Double', 'Repeated addition'],
  schema: z.object({
    numbers: z.array(z.number().min(1).max(10)).length(2)
  }),
  simpleGenerator: () => {
    // remove 2 so we have unique answer options
    const numbers = randomUniqueIntegersInclusive(1, 10, 2, { constraint: x => x !== 2 });

    return { numbers };
  },
  Component: props => {
    const {
      question: { numbers },
      translate,
      displayMode
    } = props;

    const statements = numbers.map((val, i) => ({
      lhsComponent: (
        <View style={{ width: displayMode === 'digital' ? 200 : 300, alignItems: 'flex-end' }}>
          <TextStructure
            textVariant="WRN400"
            sentence={translate.ks1AnswerSentences.doubleX(val)}
          />
        </View>
      ),
      correctAnswer: ['A', 'B'][i]
    }));

    const items = shuffle(
      [
        {
          value: 'A',
          component: (
            <TextStructure
              textVariant="WRN700"
              sentence={`${numbers[0].toLocaleString()} ${ADD} ${numbers[0].toLocaleString()}`}
            />
          )
        },
        {
          value: 'B',
          component: (
            <TextStructure
              textVariant="WRN700"
              sentence={`${numbers[1].toLocaleString()} ${ADD} ${numbers[1].toLocaleString()}`}
            />
          )
        },
        {
          value: 'C',
          component: (
            <TextStructure
              textVariant="WRN700"
              sentence={`${numbers[0].toLocaleString()} ${ADD} ${(2).toLocaleString()}`}
            />
          )
        },
        {
          value: 'D',
          component: (
            <TextStructure
              textVariant="WRN700"
              sentence={`${(2).toLocaleString()} ${ADD} ${numbers[1].toLocaleString()}`}
            />
          )
        }
      ],
      { random: seededRandom(props) }
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheDoublesToAdditions()}
        pdfTitle={translate.ks1PDFInstructions.matchTheDoublesToAdditions()}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={items}
        useArrows={false}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bed',
  description: 'bed',
  keywords: ['Double', 'Repeated addition', 'Base 10'],
  schema: z.object({
    number: numberEnum([11, 12, 13, 14, 15, 20, 21, 22, 23, 24]),
    normalArrangement: z.boolean()
  }),
  simpleGenerator: () => {
    const number = getRandomFromArray([11, 12, 13, 14, 15, 20, 21, 22, 23, 24] as const);

    const normalArrangement = getRandomBoolean();

    return { number, normalArrangement };
  },
  Component: props => {
    const {
      question: { number, normalArrangement },
      translate,
      displayMode
    } = props;

    const color = displayMode === 'digital' ? colors.prussianBlue : 'black';
    const borderWidth = displayMode === 'digital' ? 2 : 4;

    const baseTen = numberToBase10Object(number);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsDoubleX(number)}
        sentence={`${number.toLocaleString()} ${ADD} ${number.toLocaleString()} = <ans/>`}
        testCorrect={[(number * 2).toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View
              style={{
                borderRadius: 50,
                borderWidth,
                borderColor: color,
                width: dimens.width * 0.4,
                height: dimens.height,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SimpleBaseTenWithCrossOut
                ones={baseTen.ones}
                tens={baseTen.tens}
                dimens={{ width: dimens.width * 0.35, height: dimens.height * 0.9 }}
              />
            </View>
            {horizontalArrow(dimens.width * 0.1, borderWidth, color)}
            <View
              style={{
                borderRadius: 50,
                borderWidth,
                borderColor: color,
                width: dimens.width * 0.4,
                height: dimens.height,
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {normalArrangement ? (
                <SimpleBaseTenWithCrossOut
                  ones={(baseTen.ones ?? 0) * 2}
                  tens={(baseTen.tens ?? 0) * 2}
                  dimens={{ width: dimens.width * 0.35, height: dimens.height * 0.9 }}
                />
              ) : (
                <View style={{ height: 'auto', flexDirection: 'row', gap: 10 }}>
                  {countRange(2).map(i => (
                    <SimpleBaseTenWithCrossOut
                      key={i}
                      ones={baseTen.ones}
                      tens={baseTen.tens}
                      dimens={{ width: dimens.width * 0.175, height: dimens.height * 0.9 }}
                    />
                  ))}
                </View>
              )}
            </View>
          </View>
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeDoubles',
  questionTypes: [Question1, Question2, Question3]
});

export default SmallStep;
