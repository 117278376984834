import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { CustomizableTableWithState } from '../../../../components/question/representations/CustomizableTable';
import { colors } from '../../../../theme/colors';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { View } from 'react-native';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import {
  containerOfObject,
  getRandomObject,
  objectAsWord,
  objectSchema
} from '../../../../utils/objects';
import { ADD, DIV, MULT, SUB } from '../../../../constants';
import QF28FunctionMachines from '../../../../components/question/questionFormats/QF28FunctionMachines';
import { parseToSUB } from '../../../../utils/parse';
import QF28aFunctionMachinesDraggable from '../../../../components/question/questionFormats/QF28aFunctionMachinesDraggable';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTK',
  description: 'aTK',
  keywords: ['Table', 'Function'],
  schema: z.object({
    shape: z.enum(['triangle', 'square', 'pentagon', 'hexagon'])
  }),
  questionHeight: 850,
  simpleGenerator: () => {
    const shape = getRandomFromArray(['triangle', 'square', 'pentagon', 'hexagon'] as const);

    return { shape };
  },
  Component: props => {
    const {
      question: { shape },
      translate,
      displayMode
    } = props;

    const tableData = [
      [translate.tableHeaders.numberOfLollySticks(), '<ans/>', '<ans/>', '<ans/>']
    ];

    const [numberPerSide, shapeAssetPath] = (() => {
      switch (shape) {
        case 'triangle':
          return [3, 'Lolly_sticks/ThreeLollyStickTriangle'];
        case 'square':
          return [4, 'Lolly_sticks/FourLollyStickSquare'];
        case 'pentagon':
          return [5, 'Lolly_sticks/FiveLollyStickPentagon'];
        case 'hexagon':
          return [6, 'Lolly_sticks/SixLollyStickHexagon'];
      }
    })();

    return (
      <QF3Content
        title={translate.instructions.aPatternOfShapesIsMadeUsingLollySticksCompleteTheTable()}
        inputType="numpad"
        questionHeight={850}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <ArrayOfObjects
              rows={1}
              columns={3}
              customImage={
                <AssetSvg name={shapeAssetPath as SvgName} width={dimens.width / 3} height={144} />
              }
              dimens={{ width: dimens.width, height: 144 }}
            />
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              textStyle={{ fontSize: 32 }}
              cellHeaders={[
                {
                  label: translate.tableHeaders.numberOfShapes(),
                  containerStyle: {
                    backgroundColor: colors.white,
                    justifyContent: 'center'
                  },
                  textStyle: {
                    color: colors.prussianBlue
                  }
                },
                {
                  label: (1).toLocaleString(),
                  containerStyle: {
                    backgroundColor: colors.white,
                    justifyContent: 'center'
                  },
                  textStyle: {
                    color: colors.prussianBlue
                  }
                },
                {
                  label: (2).toLocaleString(),
                  containerStyle: {
                    backgroundColor: colors.white,
                    justifyContent: 'center'
                  },
                  textStyle: {
                    color: colors.prussianBlue
                  }
                },
                {
                  label: (3).toLocaleString(),
                  containerStyle: {
                    backgroundColor: colors.white,
                    justifyContent: 'center'
                  },
                  textStyle: {
                    color: colors.prussianBlue
                  }
                }
              ]}
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      numberPerSide.toLocaleString(),
                      (numberPerSide * 2).toLocaleString(),
                      (numberPerSide * 3).toLocaleString()
                    ]
                  : ['', '', '']
              }
              tableData={tableData}
              tableStyle={{ width: dimens.width }}
              testCorrect={userAnswer =>
                userAnswer[0] === numberPerSide.toString() &&
                userAnswer[1] === (numberPerSide * 2).toString() &&
                userAnswer[2] === (numberPerSide * 3).toString()
              }
            />
          </View>
        )}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aTL',
  description: 'aTL',
  keywords: ['Table', 'Function'],
  schema: z.object({
    numberPerGroup: z.number().int().min(2).max(6),
    numberOfGroupsA: z.number().int().min(2).max(8),
    numberOfGroupsB: z.number().int().min(8).max(16),
    object: objectSchema
  }),
  questionHeight: 850,
  simpleGenerator: () => {
    const numberPerGroup = randomIntegerInclusive(2, 6);

    const numberOfGroupsA = randomIntegerInclusive(2, 8);

    const numberOfGroupsB = randomIntegerInclusive(9, 16);

    const object = getRandomObject();

    return { numberPerGroup, numberOfGroupsA, numberOfGroupsB, object };
  },
  Component: props => {
    const {
      question: { numberPerGroup, numberOfGroupsA, numberOfGroupsB, object },
      translate,
      displayMode
    } = props;

    const objectPlural = objectAsWord(object, translate, true);

    const containerSingle = containerOfObject(object, translate, false);
    const containerPlural = containerOfObject(object, translate, true);

    const tableData = [
      [
        translate.tableHeaders.numberOfX(containerPlural),
        (1).toLocaleString(),
        '<ans/>',
        numberOfGroupsB.toLocaleString()
      ],
      [
        translate.tableHeaders.numberOfX(objectPlural),
        '<ans/>',
        (numberOfGroupsA * numberPerGroup).toLocaleString(),
        '<ans/>'
      ]
    ];

    const objectAssetPath = (() => {
      switch (object) {
        case 'Apple':
          return `Equal_groups/Apples${numberPerGroup}`;
        case 'Cookie':
          return `Equal_groups/Cookies${numberPerGroup}`;
        case 'Egg':
          return `Equal_groups/Eggs${numberPerGroup}`;
        case 'Flower':
          return `Equal_groups/Flowers${numberPerGroup}`;
        case 'Pencil':
          return `Equal_groups/Pencils${numberPerGroup}`;
      }
    })();

    return (
      <QF3Content
        title={translate.instructions.eachGroupHasNumObjectsCompleteTheTable(
          containerSingle,
          numberPerGroup,
          objectPlural
        )}
        inputType="numpad"
        questionHeight={850}
        Content={({ dimens }) => (
          <View style={{ height: dimens.height, justifyContent: 'space-between' }}>
            <ArrayOfObjects
              rows={1}
              columns={3}
              customImage={
                <AssetSvg name={objectAssetPath as SvgName} width={dimens.width / 3} height={144} />
              }
              dimens={{ width: dimens.width, height: 144 }}
            />
            <CustomizableTableWithState
              id="table"
              inputMaxCharacters={2}
              textStyle={{ fontSize: 32 }}
              defaultState={
                displayMode === 'markscheme'
                  ? [
                      numberOfGroupsA.toLocaleString(),
                      numberPerGroup.toLocaleString(),
                      (numberPerGroup * numberOfGroupsB).toLocaleString()
                    ]
                  : ['', '', '']
              }
              tableData={tableData}
              testCorrect={userAnswer =>
                userAnswer[0] === numberOfGroupsA.toString() &&
                userAnswer[1] === numberPerGroup.toString() &&
                userAnswer[2] === (numberPerGroup * numberOfGroupsB).toString()
              }
              tableStyle={{ width: dimens.width }}
            />
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aTM',
  description: 'aTM',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z
    .object({
      operationA: z.enum([ADD, SUB, MULT, DIV]),
      numberA1: z.number().int().min(1).max(10),
      numberA2: z.number().int().min(2).max(50),
      operationB: z.enum([ADD, SUB, MULT, DIV]),
      numberB1: z.number().int().min(1).max(10),
      numberB2: z.number().int().min(2).max(50)
    })
    .refine(
      val => val.operationA !== val.operationB,
      'operationA and operationB must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [operationA, operationB] = getRandomSubArrayFromArray([ADD, SUB, MULT, DIV] as const, 2);

    const numberA1 =
      operationA === ADD || operationA === SUB
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(2, 10);

    const numberA2 =
      operationA === ADD || operationA === SUB
        ? randomIntegerInclusive(11, 50)
        : randomIntegerInclusive(2, 12);

    const numberB1 =
      operationB === ADD || operationB === SUB
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(2, 10);

    const numberB2 =
      operationB === ADD || operationB === SUB
        ? randomIntegerInclusive(11, 50)
        : randomIntegerInclusive(2, 12);

    return { operationA, numberA1, numberA2, operationB, numberB1, numberB2 };
  },
  Component: props => {
    const {
      question: { operationA, numberA1, numberA2, operationB, numberB1, numberB2 },
      translate
    } = props;

    const functionMachineRow = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ): string[] => {
      switch (operation) {
        case ADD:
        case SUB:
        case MULT:
          return [
            `${number2.toLocaleString()}`,
            `${operation} ${number1.toLocaleString()}`,
            '<ans/>'
          ];
        case DIV:
          return [
            `${(number1 * number2).toLocaleString()}`,
            `${operation} ${number1.toLocaleString()}`,
            '<ans/>'
          ];
      }
    };

    const expectedAnswer = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ) => {
      switch (operation) {
        case ADD:
          return (number2 + number1).toString();
        case SUB:
          return (number2 - number1).toString();
        case MULT:
          return (number2 * number1).toString();
        case DIV:
          return number2.toString();
      }
    };

    return (
      <QF28FunctionMachines
        title={translate.instructions.calcOutputsForFunctionMachines()}
        actionPanelVariant="endWide"
        questionHeight={1000}
        rowsOfBoxes={[
          functionMachineRow(operationA, numberA1, numberA2),
          functionMachineRow(operationB, numberB1, numberB2)
        ]}
        testCorrect={[
          [expectedAnswer(operationA, numberA1, numberA2)],
          [expectedAnswer(operationB, numberB1, numberB2)]
        ]}
      />
    );
  }
});

const Question3v2 = newQuestionContent({
  uid: 'aTM2',
  description: 'aTM2',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    operation: z.enum([ADD, SUB, MULT, DIV]),
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(2).max(50)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const operation = getRandomFromArray([ADD, SUB, MULT, DIV] as const);

    const number1 =
      operation === ADD || operation === SUB
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(2, 10);

    const number2 =
      operation === ADD || operation === SUB
        ? randomIntegerInclusive(11, 50)
        : randomIntegerInclusive(2, 12);

    return { operation, number1, number2 };
  },
  Component: props => {
    const {
      question: { operation, number1, number2 },
      translate
    } = props;

    const functionMachineRow = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ): string[] => {
      switch (operation) {
        case ADD:
        case SUB:
        case MULT:
          return [
            `${number2.toLocaleString()}`,
            `${operation} ${number1.toLocaleString()}`,
            '<ans/>'
          ];
        case DIV:
          return [
            `${(number1 * number2).toLocaleString()}`,
            `${operation} ${number1.toLocaleString()}`,
            '<ans/>'
          ];
      }
    };

    const expectedAnswer = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ) => {
      switch (operation) {
        case ADD:
          return (number2 + number1).toString();
        case SUB:
          return (number2 - number1).toString();
        case MULT:
          return (number2 * number1).toString();
        case DIV:
          return number2.toString();
      }
    };

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheOutputForTheFunctionMachine()}
        rowsOfBoxes={[functionMachineRow(operation, number1, number2)]}
        testCorrect={[[expectedAnswer(operation, number1, number2)]]}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aTN',
  description: 'aTN',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z
    .object({
      operationA: z.enum([ADD, SUB, MULT, DIV]),
      numberA1: z.number().int().min(1).max(10),
      numberA2: z.number().int().min(2).max(50),
      operationB: z.enum([ADD, SUB, MULT, DIV]),
      numberB1: z.number().int().min(1).max(10),
      numberB2: z.number().int().min(2).max(50)
    })
    .refine(
      val => val.operationA !== val.operationB,
      'operationA and operationB must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [operationA, operationB] = getRandomSubArrayFromArray([ADD, SUB, MULT, DIV] as const, 2);

    const numberA1 =
      operationA === ADD || operationA === SUB
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(2, 10);

    const numberA2 =
      operationA === ADD || operationA === SUB
        ? randomIntegerInclusive(11, 50)
        : randomIntegerInclusive(2, 12);

    const numberB1 =
      operationB === ADD || operationB === SUB
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(2, 10);

    const numberB2 =
      operationB === ADD || operationB === SUB
        ? randomIntegerInclusive(11, 50)
        : randomIntegerInclusive(2, 12);

    return { operationA, numberA1, numberA2, operationB, numberB1, numberB2 };
  },
  Component: props => {
    const {
      question: { operationA, numberA1, numberA2, operationB, numberB1, numberB2 },
      translate
    } = props;

    const functionMachineRow = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ): string[] => {
      switch (operation) {
        case ADD:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${(number2 + number1).toLocaleString()}`
          ];
        case SUB:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${(number2 - number1).toLocaleString()}`
          ];
        case MULT:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${(number2 * number1).toLocaleString()}`
          ];
        case DIV:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${number2.toLocaleString()}`
          ];
      }
    };

    const expectedAnswer = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ) => {
      switch (operation) {
        case ADD:
        case SUB:
        case MULT:
          return number2.toString();
        case DIV:
          return (number2 * number1).toString();
      }
    };

    return (
      <QF28FunctionMachines
        title={translate.instructions.calcInputsForFunctionMachines()}
        actionPanelVariant="endWide"
        questionHeight={1000}
        rowsOfBoxes={[
          functionMachineRow(operationA, numberA1, numberA2),
          functionMachineRow(operationB, numberB1, numberB2)
        ]}
        testCorrect={[
          [expectedAnswer(operationA, numberA1, numberA2)],
          [expectedAnswer(operationB, numberB1, numberB2)]
        ]}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aTN2',
  description: 'aTN2',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    operation: z.enum([ADD, SUB, MULT, DIV]),
    number1: z.number().int().min(1).max(10),
    number2: z.number().int().min(2).max(50)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const operation = getRandomFromArray([ADD, SUB, MULT, DIV] as const);

    const number1 =
      operation === ADD || operation === SUB
        ? randomIntegerInclusive(1, 9)
        : randomIntegerInclusive(2, 10);

    const number2 =
      operation === ADD || operation === SUB
        ? randomIntegerInclusive(11, 50)
        : randomIntegerInclusive(2, 12);

    return { operation, number1, number2 };
  },
  Component: props => {
    const {
      question: { operation, number1, number2 },
      translate
    } = props;

    const functionMachineRow = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ): string[] => {
      switch (operation) {
        case ADD:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${(number2 + number1).toLocaleString()}`
          ];
        case SUB:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${(number2 - number1).toLocaleString()}`
          ];
        case MULT:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${(number2 * number1).toLocaleString()}`
          ];
        case DIV:
          return [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${number2.toLocaleString()}`
          ];
      }
    };

    const expectedAnswer = (
      operation: ADD | SUB | MULT | DIV,
      number1: number,
      number2: number
    ) => {
      switch (operation) {
        case ADD:
        case SUB:
        case MULT:
          return number2.toString();
        case DIV:
          return (number2 * number1).toString();
      }
    };

    return (
      <QF28FunctionMachines
        title={translate.instructions.workOutTheInputForTheFunctionMachine()}
        rowsOfBoxes={[functionMachineRow(operation, number1, number2)]}
        testCorrect={[[expectedAnswer(operation, number1, number2)]]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aTO',
  description: 'aTO',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z.object({
    operation: z.enum([ADD, SUB]),
    number1: z.number().int().min(3).max(9),
    numberA2: z.number().int().min(-9).max(10),
    numberB2: z.number().int().min(-9).max(9),
    numberC2: z.number().int().min(-9).max(9)
  }),
  questionHeight: 1280,
  simpleGenerator: () => {
    const operation = getRandomFromArray([ADD, SUB] as const);

    const number1 = randomIntegerInclusive(3, 9);

    const numberA2 =
      operation === ADD
        ? randomIntegerInclusive(0, 10)
        : randomIntegerInclusive(number1 * -1 + 1, number1 * -1 + 10);

    const numberB2 =
      operation === ADD
        ? randomIntegerInclusive(number1 * -1, 0, {
            constraint: x => x !== numberA2
          })
        : randomIntegerInclusive(0, number1, {
            constraint: x => x !== numberA2
          });

    const numberC2 =
      operation === ADD
        ? randomIntegerInclusive(number1 * -1, 0, {
            constraint: x => x !== numberA2 && x !== numberB2
          })
        : randomIntegerInclusive(0, number1, {
            constraint: x => x !== numberA2 && x !== numberB2
          });

    return { operation, number1, numberA2, numberB2, numberC2 };
  },
  Component: props => {
    const {
      question: { operation, number1, numberA2, numberB2, numberC2 },
      translate
    } = props;

    const numberA3 = operation === ADD ? numberA2 + number1 : numberA2 - number1;
    const numberB3 = operation === ADD ? numberB2 + number1 : numberB2 - number1;
    const numberC3 = operation === ADD ? numberC2 + number1 : numberC2 - number1;

    return (
      <QF28FunctionMachines
        title={translate.instructions.completeFunctionMachine()}
        actionPanelVariant="endWide"
        questionHeight={1280}
        extraSymbol="minus"
        sharedColumns={[1]}
        rowsOfBoxes={[
          [`${parseToSUB(numberA2.toLocaleString())}`, '', '<ans/>'],
          [`${parseToSUB(numberB2.toLocaleString())}`, '', '<ans/>'],
          [
            '<ans/>',
            `${operation} ${number1.toLocaleString()}`,
            `${parseToSUB(numberC3.toLocaleString())}`
          ]
        ]}
        testCorrect={[
          [parseToSUB(numberA3.toString())],
          [parseToSUB(numberB3.toString())],
          [parseToSUB(numberC2.toString())]
        ]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTP',
  description: 'aTP',
  keywords: ['Function machine', 'Input', 'Output'],
  schema: z
    .object({
      operation: z.enum([ADD, SUB, MULT, DIV]),
      number1: z.number().int().min(3).max(9),
      number4: z.number().int().min(3).max(9)
    })
    .refine(val => val.number1 !== val.number4, 'number1 and number4 must be different.'),

  simpleGenerator: () => {
    const operation = getRandomFromArray([ADD, SUB, MULT, DIV] as const);

    const [number1, number4] = randomUniqueIntegersInclusive(3, 9, 2);

    return { operation, number1, number4 };
  },
  Component: props => {
    const {
      question: { operation, number1, number4 },
      translate
    } = props;

    // Setup
    const [numberA2, numberB2, numberA3, numberB3, number5] = (() => {
      switch (operation) {
        case ADD:
          return [number4 * number1, number1, number4 * number1 + number1, number1 * 2, 2];
        case SUB:
          return [number4 * number1 + number1, 2 * number1, number4 * number1, number1, 2];
        case MULT:
          return [number4, 2, number4 * number1, number1 * 2, number1 * 2 - 2];
        case DIV:
          return [number4 * number1, number1 * 2, number4, 2, number1 * 2 - 2];
      }
    })();

    const shuffledNumbers = shuffle(
      [number1.toLocaleString(), number5.toLocaleString(), number4.toLocaleString()],
      { random: seededRandom(props.question) }
    );

    return (
      <QF28aFunctionMachinesDraggable
        title={translate.instructions.dragCardsToFillInMissingFunction()}
        pdfTitle={translate.instructions.useCardsToFillInMissingFunction()}
        sharedColumns={[1]}
        questionHeight={1100}
        rowsOfBoxes={[
          [
            `${parseToSUB(numberA2.toLocaleString())}`,
            '',
            `${parseToSUB(numberA3.toLocaleString())}`
          ],
          [
            `${parseToSUB(numberB2.toLocaleString())}`,
            '<ans/> <ans/>',
            `${parseToSUB(numberB3.toLocaleString())}`
          ]
        ]}
        testCorrect={[operation, number1.toLocaleString()]}
        items={[ADD, SUB, MULT, DIV, ...shuffledNumbers]}
      />
    );
  },
  questionHeight: 1100
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Num1StepFunctionMachines',
  questionTypes: [Question1, Question2, Question3v2, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question3, Question4],
  unpublishedQuestionTypes: [Question3v2, Question4v2, Question6]
});
export default SmallStep;
