import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { lessThanGreaterThanOrEqualTo } from '../../../../utils/math';
import { barModelColors } from '../../../../theme/colors';
import { filledArray, sortNumberArray } from '../../../../utils/collections';
import { fractionToDecimal } from '../../../../utils/fractions';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import ShadedFractionBarModel from '../../../../components/question/representations/ShadedFractionBarModel';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import { GREATER_THAN, LESS_THAN } from '../../../../constants';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import TextStructure from '../../../../components/molecules/TextStructure';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aHQ',
  description: 'aHQ',
  keywords: ['Unit fraction', 'Compare', 'Greater than', 'Less than'],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(8),
    denominatorB: z.number().int().min(2).max(8)
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 8, 2);

    const reverse = getRandomBoolean();

    return {
      denominatorA: reverse ? denominatorA : denominatorB,
      denominatorB: reverse ? denominatorB : denominatorA
    };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numerator = 1;

    const numeratorColorArray1 = filledArray(numeratorColor, numerator);
    const remainder1 = filledArray('white', denominatorA - numerator);

    const numeratorColorArray2 = filledArray(numeratorColor, numerator);
    const remainder2 = filledArray('white', denominatorB - numerator);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    const answerOptions = [translate.operations.greaterThan(), translate.operations.lessThan()];

    const fractionDecimal1 = fractionToDecimal(numerator, denominatorA);
    const fractionDecimal2 = fractionToDecimal(numerator, denominatorB);

    const correctInequality =
      lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2) === '>'
        ? translate.operations.greaterThan()
        : translate.operations.lessThan();

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompleteSentence()}
        pdfTitle={translate.instructions.useACardToCompleteSentence()}
        items={answerOptions}
        itemVariant="rectangle"
        actionPanelVariant="endWide"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={denominatorA}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              fractionDividerStyle={{ marginVertical: 2 }}
              preBarText={`<frac n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={denominatorB}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              fractionDividerStyle={{ marginVertical: 2 }}
              preBarText={`<frac n='${numerator.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`${translate.answerSentences.fracIsAnsFrac(
          `<frac n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`,
          `<frac n='${numerator.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`
        )} `}
        fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
        testCorrect={[correctInequality]}
        questionHeight={1000}
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsBottom"
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'aHR',
  description: 'aHR',
  keywords: ['Compare', 'Unit fraction', 'Denominator', 'Greater than', 'Less than'],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(10),
    denominatorB: z.number().int().min(2).max(10),
    denominatorC: z.number().int().min(2).max(10),
    denominatorD: z.number().int().min(2).max(10),
    denominatorE: z.number().int().min(3).max(8)
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const denominatorE = randomIntegerInclusive(3, 8);
    const denominatorC = randomIntegerInclusive(2, denominatorE - 1);
    const denominatorD = randomIntegerInclusive(denominatorE + 1, 10);
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 10, 2, {
      constraint: x => x !== denominatorE && x !== denominatorC && x !== denominatorD
    });
    return { denominatorA, denominatorB, denominatorC, denominatorD, denominatorE };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, denominatorC, denominatorD, denominatorE },
      translate,
      displayMode
    } = props;

    const numerator = 1;

    const items = shuffle(
      [
        {
          value: denominatorA.toLocaleString()
        },
        {
          value: denominatorB.toLocaleString()
        },
        {
          value: denominatorC.toLocaleString()
        },
        {
          value: denominatorD.toLocaleString()
        }
      ],
      { random: seededRandom(props.question) }
    );

    const greaterThan: string[] = [];
    const lessThan: string[] = [];

    items.forEach(item => {
      if (lessThanGreaterThanOrEqualTo(denominatorE, Number(item.value)) === '>') {
        greaterThan.push(item.value);
      } else {
        lessThan.push(item.value);
      }
    });

    const correctAnswers = [greaterThan, lessThan];

    return (
      <QF8DragIntoUpTo3Groups
        title={translate.instructions.dragCardsToSortUnitFractionsIntoTheTable()}
        pdfTitle={translate.instructions.useCardsSortUnitFractions()}
        zoneNames={[
          translate.tableHeaders.greaterThan(
            `<frac n='${numerator.toLocaleString()}' d='${denominatorE.toLocaleString()}' />`
          ),
          translate.tableHeaders.lessThan(
            `<frac n='${numerator.toLocaleString()}' d='${denominatorE.toLocaleString()}' />`
          )
        ]}
        items={items.map(({ value }) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${numerator.toLocaleString()}' d='${value.toLocaleString()}' />`}
                fractionDividerStyle={{ marginVertical: 1 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 32 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value
          };
        })}
        pdfItemVariant="pdfSquare"
        testCorrect={correctAnswers}
        questionHeight={800}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHS',
  description: 'aHS',
  keywords: ['Unit fraction', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominatorA: z.number().int().min(2).max(10),
    denominatorB: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const denominatorA = randomIntegerInclusive(2, 10);
    const denominatorB = randomIntegerInclusive(2, 10);
    const reverse = getRandomBoolean();

    return {
      denominatorA: reverse ? denominatorA : denominatorB,
      denominatorB: reverse ? denominatorB : denominatorA
    };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB },
      translate,
      displayMode
    } = props;

    const numeratorColor = getRandomFromArray(Object.values(barModelColors), {
      random: seededRandom(props.question)
    }) as string;

    const numerator = 1;

    const numeratorColorArray1 = filledArray(numeratorColor, numerator);
    const remainder1 = filledArray('white', denominatorA - numerator);

    const numeratorColorArray2 = filledArray(numeratorColor, numerator);
    const remainder2 = filledArray('white', denominatorB - numerator);

    const customColorMap = [
      [...numeratorColorArray1, ...remainder1],
      [...numeratorColorArray2, ...remainder2]
    ];

    const answerOptions = ['>', '<', '='];

    const fractionDecimal1 = fractionToDecimal(1, denominatorA);
    const fractionDecimal2 = fractionToDecimal(1, denominatorB);

    return (
      <QF36ContentAndSentenceDrag
        title={translate.instructions.dragACardToCompareUnitFractions()}
        pdfTitle={translate.instructions.useInequalitySymbolsToCompareTheUnitFractions()}
        items={answerOptions}
        itemVariant="square"
        pdfLayout="itemsHidden"
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View style={{ rowGap: 16 }}>
            <ShadedFractionBarModel
              totalSubSections={denominatorA}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[0]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`}
            />
            <ShadedFractionBarModel
              totalSubSections={denominatorB}
              width={dimens.width * 0.9}
              height={dimens.height * 0.25}
              customColorMap={customColorMap[1]}
              fractionDividerStyle={{ marginVertical: 2 }}
              fractionTextStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
              preBarText={`<frac n='${numerator.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`}
            />
          </View>
        )}
        sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}'/> <ans/> <frac n='${numerator.toLocaleString()}' d='${denominatorB.toLocaleString()}'/>`}
        testCorrect={[lessThanGreaterThanOrEqualTo(fractionDecimal1, fractionDecimal2)]}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question4 = newQuestionContent({
  uid: 'aHT',
  description: 'aHT',
  keywords: ['Unit fraction', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominatorA: z.number().min(2).max(9),
    denominatorB: z.number().min(2).max(9),
    denominatorC: z.number().min(11).max(249),
    denominatorD: z.number().min(11).max(249)
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = randomUniqueIntegersInclusive(2, 9, 2);
    const [denominatorC, denominatorD] = randomUniqueIntegersInclusive(11, 249, 2);

    return { denominatorA, denominatorB, denominatorC, denominatorD };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, denominatorC, denominatorD },
      translate,
      displayMode
    } = props;

    const numerator = 1;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-start' }}>
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorB.toLocaleString()}' />`}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(1, denominatorA),
          fractionToDecimal(1, denominatorB)
        )
      },
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorC.toLocaleString()}'/>`}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-start' }}>
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorD.toLocaleString()}'/>`}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(1, denominatorC),
          fractionToDecimal(1, denominatorD)
        )
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragTheCardsToCompareTheUnitFractions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareUnitFractions()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'aHT2',
  description: 'aHT',
  keywords: ['Unit fraction', 'Compare', 'Greater than', 'Less than', 'Equal to'],
  schema: z.object({
    denominatorA: z.number().min(2).max(249),
    denominatorB: z.number().min(2).max(249)
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB] = getRandomBoolean()
      ? randomUniqueIntegersInclusive(2, 9, 2)
      : randomUniqueIntegersInclusive(11, 249, 2);

    return { denominatorA, denominatorB };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB },
      translate,
      displayMode
    } = props;

    const numerator = 1;

    const statements = [
      {
        lhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-end' }}>
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorA.toLocaleString()}'/>`}
            />
          </View>
        ),
        rhsComponent: (
          <View style={{ width: displayMode === 'digital' ? 140 : 200, alignItems: 'flex-start' }}>
            <TextStructure
              sentence={`<frac n='${numerator.toLocaleString()}' d='${denominatorB.toLocaleString()}' />`}
            />
          </View>
        ),
        correctAnswer: lessThanGreaterThanOrEqualTo(
          fractionToDecimal(1, denominatorA),
          fractionToDecimal(1, denominatorB)
        )
      }
    ];

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragACardToCompareUnitFractions()}
        pdfTitle={translate.instructions.useGreaterLessThanOrEqualsToCompareUnitFractions()}
        itemVariant="square"
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        items={['>', '<', '=']}
        moveOrCopy="copy"
        actionPanelVariant="end"
        pdfLayout="itemsHidden"
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aHU',
  description: 'aHU',
  keywords: ['Compare', 'Unit fraction', 'Greater than', 'Less than', 'Denominator'],
  schema: z.object({
    denominator: z.number().int().min(4).max(8),
    inequality: z.enum([GREATER_THAN, LESS_THAN])
  }),
  simpleGenerator: () => {
    const denominator = randomIntegerInclusive(4, 8);
    const inequality = getRandomFromArray([GREATER_THAN, LESS_THAN] as const);

    return { denominator, inequality };
  },

  Component: props => {
    const {
      question: { denominator, inequality },
      displayMode,
      translate
    } = props;

    const numerator = 1;

    const sentences = [
      {
        sentence: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>  ${inequality}  <frac n='${numerator.toLocaleString()}' dAns='' />`
      },
      {
        sentence: `<frac n='${numerator.toLocaleString()}' d='${denominator.toLocaleString()}'/>  ${inequality}  <frac n='${numerator.toLocaleString()}' dAns='' />`
      }
    ];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.whatCouldMissingDenominatorsBe()}
        testCorrect={answer => {
          return (
            ((inequality === GREATER_THAN && Number(answer[0]) > denominator) ||
              (inequality === LESS_THAN && Number(answer[0]) < denominator)) &&
            ((inequality === GREATER_THAN && Number(answer[1]) > denominator) ||
              (inequality === LESS_THAN && Number(answer[1]) < denominator)) &&
            Number(answer[1]) !== Number(answer[0])
          );
        }}
        inputMaxCharacters={2}
        pdfDirection="row"
        sentenceStyle={{
          alignItems: 'center',
          marginHorizontal: displayMode !== 'digital' ? 128 : undefined
        }}
        fractionContainerStyle={{ height: 96 }}
        sentences={sentences.map(sentence => sentence.sentence)}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.denominatorMustBeGreaterThanX(denominator)
        }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aHV',
  description: 'aHV',
  keywords: ['Unit fraction', 'Order', 'Compare', 'Denominator', 'Greatest', 'Smallest'],
  schema: z.object({
    denominatorA: z.number().min(2).max(24),
    denominatorB: z.number().min(2).max(24),
    denominatorC: z.number().min(2).max(24),
    denominatorD: z.number().min(2).max(24),
    denominatorE: z.number().min(2).max(24),
    ordering: z.enum(['ascending', 'descending'])
  }),
  simpleGenerator: () => {
    const [denominatorA, denominatorB, denominatorC, denominatorD, denominatorE] =
      randomUniqueIntegersInclusive(2, 24, 5);
    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    return { denominatorA, denominatorB, denominatorC, denominatorD, denominatorE, ordering };
  },
  Component: props => {
    const {
      question: { denominatorA, denominatorB, denominatorC, denominatorD, denominatorE, ordering },
      translate,
      displayMode
    } = props;

    const numerator = 1;

    const items = [
      {
        value: denominatorA
      },
      {
        value: denominatorB
      },
      {
        value: denominatorC
      },
      {
        value: denominatorD
      },
      {
        value: denominatorE
      }
    ];

    const correctOrder = sortNumberArray(
      [denominatorA, denominatorB, denominatorC, denominatorD, denominatorE],
      ordering
    );

    return (
      <QF5DragOrderHorizontal
        title={translate.instructions.dragCardsToOrderUnitFractions(
          `${
            ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
          }`
        )}
        pdfTitle={translate.instructions.useCardsToOrderFractions(
          `${
            ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
          }`
        )}
        testCorrect={correctOrder}
        items={items.map(({ value }) => {
          return {
            component: (
              <TextStructure
                sentence={`<frac n='${numerator.toLocaleString()}' d='${value.toLocaleString()}' />`}
                fractionDividerStyle={{ marginVertical: 2 }}
                fractionTextStyle={{
                  fontSize: displayMode === 'digital' ? 30 : 50,
                  fontWeight: '700'
                }}
              />
            ),
            value
          };
        })}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Greatest() : translate.keywords.Smallest()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Smallest() : translate.keywords.Greatest()
        }
        moveOrCopy="move"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareAndOrderUnitFractions',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [Question4v2],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
