import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { range, rangeAsString, sortNumberArray } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { integerToWord } from '../../../../utils/math';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bci',
  description: 'bci',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    numbers: z.array(z.number().int().min(0).max(20))
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);

    const numbers = randomUniqueIntegersInclusive(
      startingNumber,
      startingNumber + 10,
      randomIntegerInclusive(4, 6)
    );

    return { startingNumber, numbers: sortNumberArray(Array.from(numbers)) };
  },
  Component: props => {
    const {
      question: { startingNumber, numbers },
      translate
    } = props;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, startingNumber + 10, 1, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.completeTheNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
        answerPositions="bottom"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcj',
  description: 'bcj',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(1).max(12),
    numberLineLength: z.number().int().min(8).max(12),
    numbers: z.array(z.number().int().min(0).max(20))
  }),
  simpleGenerator: () => {
    const numberLineLength = randomIntegerInclusive(8, 12);
    const startingNumber = randomIntegerInclusive(1, 20 - numberLineLength);

    const numbers = randomUniqueIntegersInclusive(
      startingNumber,
      startingNumber + numberLineLength,
      randomIntegerInclusive(3, 5)
    );

    return { startingNumber, numberLineLength, numbers: sortNumberArray(Array.from(numbers)) };
  },
  Component: props => {
    const {
      question: { startingNumber, numberLineLength, numbers },
      translate
    } = props;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, startingNumber + numberLineLength, 1, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bck',
  description: 'bck',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(1).max(12),
    numberLineLength: z.number().int().min(8).max(12),
    number: z.number().int().min(0).max(20)
  }),
  simpleGenerator: () => {
    const numberLineLength = randomIntegerInclusive(8, 12);
    const startingNumber = randomIntegerInclusive(1, 20 - numberLineLength);

    const choices = range(startingNumber, startingNumber + numberLineLength, 1);
    const number = getRandomSubArrayFromArray(choices, 1)[0];

    return { startingNumber, numberLineLength, number };
  },
  Component: props => {
    const {
      question: { startingNumber, numberLineLength, number },
      translate
    } = props;

    // Create array of empty strings
    const tickValues = rangeAsString(startingNumber, startingNumber + numberLineLength, 1, true);

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragTheArrowToNumberAsWord(integerToWord(number))}
        pdfTitle={translate.ks1PDFInstructions.circleNumberAsWord(number.toLocaleString())}
        testCorrect={[number, number]}
        min={startingNumber}
        max={startingNumber + numberLineLength}
        sliderStep={1}
        tickValues={tickValues}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TheNumberLineTo20',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
