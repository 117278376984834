import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from 'common/src/components/question/representations/Base Ten/BaseTenRepresentations';
import { randomIntegerInclusive } from 'common/src/utils/random';
import { numberToBase10Object } from '../../../../utils/math';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  PartWholeModel,
  TextPartition
} from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcA',
  description: 'bcA',
  keywords: ['Add', 'Number bonds', 'Base 10'],
  schema: z
    .object({
      numberA: z.number().int().min(1).max(9),
      numberB: z.number().int().min(1).max(9),
      tensAOrB: z.enum(['A', 'B'])
    })
    .refine(
      val => val.numberA + val.numberB <= 10,
      'numberA + numberB must be less than or equal to 10'
    ),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 9);
    const numberB = randomIntegerInclusive(1, 10 - numberA);

    const tensAOrB = getRandomFromArray(['A', 'B'] as const);

    return { numberA, numberB, tensAOrB };
  },
  Component: props => {
    const {
      question: { numberA, numberB, tensAOrB },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheAdditions()}
        sentences={[
          `${numberA.toLocaleString()} + ${numberB.toLocaleString()} = <ans/>`,
          `${(tensAOrB === 'A' ? numberA + 10 : numberA).toLocaleString()} + ${(tensAOrB === 'B'
            ? numberB + 10
            : numberB
          ).toLocaleString()} = <ans/>`
        ]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        style={{ justifyContent: 'space-around' }}
        pdfSentenceStyle={{ justifyContent: 'space-around' }}
        mainPanelStyle={{ flexDirection: 'row' }}
        testCorrect={[[(numberA + numberB).toString()], [(numberA + numberB + 10).toString()]]}
        Content={({ dimens }) => {
          const scales = [numberA, numberB, tensAOrB === 'A' ? numberA + 10 : numberB + 10].map(
            part =>
              BaseTenRepCalcGridsAndScale(
                dimens.width / 4,
                dimens.height / 2,
                numberToBase10Object(part),
                'Cubes'
              ).scale
          );

          const sharedScale = Math.min(...scales);

          return (
            <View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: dimens.width / 2,
                  height: dimens.height / 2
                }}
              >
                <BaseTenRepresentation
                  b10Rep={{ variant: 'Cubes', numbers: { ones: numberA }, arrangement: 'ltr' }}
                  usableWidth={dimens.width / 4}
                  usableHeight={dimens.height / 2}
                  scale={sharedScale}
                  containerStyle={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginRight: (sharedScale * dimens.width) / 4
                  }}
                />
                <Text variant="WRN400">+</Text>
                <BaseTenRepresentation
                  b10Rep={{ variant: 'Cubes', numbers: { ones: numberB }, arrangement: 'ltr' }}
                  usableWidth={dimens.width / 4}
                  usableHeight={dimens.height / 2}
                  scale={sharedScale}
                  containerStyle={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginLeft: tensAOrB === 'B' ? (sharedScale * dimens.width) / 4 : undefined
                  }}
                />
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: dimens.width / 2,
                  height: dimens.height / 2
                }}
              >
                <BaseTenRepresentation
                  b10Rep={{
                    variant: 'Cubes',
                    numbers: { ones: numberA, tens: tensAOrB === 'A' ? 1 : 0 },
                    arrangement: 'ltr'
                  }}
                  usableWidth={dimens.width / 4}
                  usableHeight={dimens.height / 2}
                  scale={sharedScale}
                  containerStyle={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginRight: (sharedScale * dimens.width) / 4
                  }}
                />
                <Text variant="WRN400">+</Text>
                <BaseTenRepresentation
                  b10Rep={{
                    variant: 'Cubes',
                    numbers: { ones: numberB, tens: tensAOrB === 'B' ? 1 : 0 },
                    arrangement: 'ltr'
                  }}
                  usableWidth={dimens.width / 4}
                  usableHeight={dimens.height / 2}
                  scale={sharedScale}
                  containerStyle={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    marginLeft: tensAOrB === 'B' ? (sharedScale * dimens.width) / 4 : undefined
                  }}
                />
              </View>
            </View>
          );
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcB',
  description: 'bcB',
  keywords: ['Part-whole model', 'Add'],
  questionHeight: 1200,
  schema: z
    .object({
      numberA: z.number().int().min(1).max(9),
      numberB: z.number().int().min(10).max(19),
      startWithNumberA: z.boolean(),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(
      val => val.numberA + val.numberB <= 20,
      'numberA + numberB must be less than or equal to 20'
    ),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 9);

    const numberB = randomIntegerInclusive(10, 20 - numberA);

    const startWithNumberA = getRandomBoolean();

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { numberA, numberB, startWithNumberA, variation };
  },
  Component: ({ question, translate, displayMode }) => {
    const { numberA, numberB, startWithNumberA, variation } = question;

    const total = numberA + numberB;

    const partition: TextPartition = startWithNumberA ? [numberB, numberA] : [numberA, numberB];

    return (
      <QF3InteractiveContent
        title={translate.ks1Instructions.completeThePartWholeModel()}
        initialState={displayMode === 'markscheme' ? [total.toLocaleString()] : ['']}
        testComplete={answer => answer.every(it => it !== '')}
        testCorrect={userAnswer => userAnswer[0] === total.toString()}
        inputType="numpad"
        questionHeight={1200}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={'$ans'}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            variation={variation}
            partition={partition}
            isInteractive
            dimens={dimens}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcC',
  description: 'bcC',
  keywords: ['Number bonds', 'Add'],
  schema: z
    .object({
      onesA: z.number().int().min(1).max(9),
      onesB: z.number().int().min(1).max(9),
      correctAnswers: z
        .enum(['onesA, onesB + 10', 'onesB + 10, onesA', 'onesB, onesA + 10', 'onesA + 10, onesB'])
        .array()
        .refine(val => val.length >= 2, 'correctAnswers must be at least 2 in length.')
    })
    .refine(val => val.onesA !== val.onesB, 'onesA and onesB cannot be the same.')
    .refine(val => val.onesA + val.onesB <= 10, 'onesA + onesB must be less than or equal to 10'),
  simpleGenerator: () => {
    const onesA = randomIntegerInclusive(1, 9);

    // onesA + onesB must be <= 10:
    const onesB = randomIntegerInclusive(1, 10 - onesA, {
      // onesA !== onesB - otherwise we will end up with duplicate answers.
      constraint: x => x !== onesA
    });

    const correctAnswersLength = randomIntegerInclusive(2, 4);

    const correctAnswers = getRandomSubArrayFromArray(
      ['onesA, onesB + 10', 'onesB + 10, onesA', 'onesB, onesA + 10', 'onesA + 10, onesB'] as const,
      correctAnswersLength
    );

    return { onesA, onesB, correctAnswers };
  },
  Component: props => {
    const {
      question: { onesA, onesB, correctAnswers },
      translate
    } = props;

    const correctAnswerItems = [];

    // A + B + 10
    if (correctAnswers.includes('onesA, onesB + 10')) {
      correctAnswerItems.push(`${onesA.toLocaleString()} ${ADD} ${(onesB + 10).toLocaleString()}`);
    }

    // B + 10 + A
    if (correctAnswers.includes('onesB + 10, onesA')) {
      correctAnswerItems.push(`${(onesB + 10).toLocaleString()} ${ADD} ${onesA.toLocaleString()}`);
    }

    // B + A + 10
    if (correctAnswers.includes('onesB, onesA + 10')) {
      correctAnswerItems.push(`${onesB.toLocaleString()} ${ADD} ${(onesA + 10).toLocaleString()}`);
    }

    // A + 10 + B
    if (correctAnswers.includes('onesA + 10, onesB')) {
      correctAnswerItems.push(`${(onesA + 10).toLocaleString()} ${ADD} ${onesB.toLocaleString()}`);
    }

    const random = seededRandom(props.question);

    const incorrectAnswers = [
      // Only adding ones:
      `${onesA.toLocaleString()} ${ADD} ${onesB.toLocaleString()}`,
      `${onesB.toLocaleString()} ${ADD} ${onesA.toLocaleString()}`,

      // One less than the correct answer:
      `${onesA.toLocaleString()} ${ADD} ${(onesB + 9).toLocaleString()}`,
      `${(onesA - 1).toLocaleString()} ${ADD} ${(onesB + 10).toLocaleString()}`,
      `${(onesB + 9).toLocaleString()} ${ADD} ${onesA.toLocaleString()}`,
      `${(onesB + 10).toLocaleString()} ${ADD} ${(onesA - 1).toLocaleString()}`,
      `${onesB.toLocaleString()} ${ADD} ${(onesA + 9).toLocaleString()}`,
      `${(onesB - 1).toLocaleString()} ${ADD} ${(onesA + 10).toLocaleString()}`,
      `${(onesA + 9).toLocaleString()} ${ADD} ${onesB.toLocaleString()}`,
      `${(onesA + 10).toLocaleString()} ${ADD} ${(onesB - 1).toLocaleString()}`,

      // One more than the correct answer:
      `${onesA.toLocaleString()} ${ADD} ${(onesB + 11).toLocaleString()}`,
      `${(onesA + 1).toLocaleString()} ${ADD} ${(onesB + 10).toLocaleString()}`,
      `${(onesB + 11).toLocaleString()} ${ADD} ${onesA.toLocaleString()}`,
      `${(onesB + 10).toLocaleString()} ${ADD} ${(onesA + 1).toLocaleString()}`,
      `${onesB.toLocaleString()} ${ADD} ${(onesA + 11).toLocaleString()}`,
      `${(onesB + 1).toLocaleString()} ${ADD} ${(onesA + 10).toLocaleString()}`,
      `${(onesA + 11).toLocaleString()} ${ADD} ${onesB.toLocaleString()}`,
      `${(onesA + 10).toLocaleString()} ${ADD} ${(onesB + 1).toLocaleString()}`
    ];

    // Set needed to prevent duplicate incorrect answers being selected, which could happen with some combinations above:
    const incorrectAnswerItems = getRandomSubArrayFromArray(
      [...new Set(incorrectAnswers)],
      6 - correctAnswers.length,
      {
        random
      }
    );

    const items = shuffle([...correctAnswerItems, ...incorrectAnswerItems], { random });

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectTheAdditionsThatAreEqualToNum(onesA + onesB + 10)}
        pdfTitle={translate.ks1PDFInstructions.tickTheAdditionsThatAreEqualToNum(
          onesA + onesB + 10
        )}
        testCorrect={correctAnswerItems}
        items={items}
        multiSelect
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddOnesUsingNumberBonds',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
