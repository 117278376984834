import { useContext } from 'react';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../constants';
import { DisplayMode } from '../../../contexts/displayMode';
import { colors } from '../../../theme/colors';
import { isEqual } from '../../../utils/matchers';
import { MeasureView } from '../../atoms/MeasureView';
import BaseLayout from '../../molecules/BaseLayout';
import { type TitleStyleProps } from '../../molecules/TitleRow';
import BarChart, { BarChartWithState } from '../representations/Coordinates/BarChart';
import TableWithLeftHeaders from '../representations/TableWithLeftHeaders';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle: string;

  correctAnswer: number[];

  /** Default: all bars at 0. */
  initialState?: number[];

  /**
   * The label for each bar. Must be the same length as `bars`.
   *
   * Also defines how many bars there are.
   */
  barLabels: string[];

  /**
   * The color that each bar should be.
   *
   * Must be the same length as `barLabels`.
   */
  barColors: string[];

  /** Which bars are interactive. Default: all of them */
  interactiveIndices?: number[];

  /**
   * Whether items should snap to the nearest multiple of some number. 'grid' means snap to nearest grid point.
   * Default: no snapping
   */
  snapToNearest?: number | 'grid';

  /** (PDF-only) how high is the overall question. Must agree with question type definition. */
  questionHeight?: number;

  ////
  // Grid props
  ////

  /** Distance between two grid lines, in math coordinates. Default: 1. */
  yStepSize?: number;
  /** Value of last point on x axis. */
  yMax: number;

  /** Null for no label. Default: '𝑦'. */
  yAxisArrowLabel?: string | null;

  /** Null for no label. Default: null. */
  xAxisLabel?: string | null;
  /** Null for no label. Default: null. */
  yAxisLabel?: string | null;
  /** Null for no labels. Default: numbers yMin to yMax. */
  yLabels?: string[] | null;
  /** If provided, y-axis labels are given with this fixed number of decimal places. */
  yDecimalPlaces?: number;
};

/**
 * @deprecated
 * Question Format 62: Draw Bar Charts
 *
 * Draggable bar charts.
 * Only supports one bar per category. Use QF62b going forward
 */
export default function QF62DrawBarCharts({
  title,
  pdfTitle,
  correctAnswer,
  initialState,
  barLabels,
  barColors,
  interactiveIndices = [...correctAnswer.keys()],
  snapToNearest,
  questionHeight,
  ...barChartProps
}: Props) {
  const displayMode = useContext(DisplayMode);

  const table = (
    <TableWithLeftHeaders
      headers={[barChartProps.xAxisLabel ?? ALGEBRAIC_X, barChartProps.yAxisLabel ?? ALGEBRAIC_Y]}
      items={[barLabels, correctAnswer.map(it => it.toLocaleString())]}
      style={{ width: '80%', alignSelf: 'center', marginBottom: 20 }}
      cellStyle={{ minWidth: 150 }}
      headerCellStyle={{ backgroundColor: colors.tableHeaderBackground }}
      textStyle={displayMode === 'digital' && { fontSize: 21.667, lineHeight: 35 }}
    />
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        questionHeight={questionHeight}
        mainPanelContents={
          <>
            {table}
            <MeasureView>
              {dimens => (
                <BarChart
                  barValues={
                    displayMode === 'markscheme'
                      ? correctAnswer
                      : initialState ?? barLabels.map(() => 0)
                  }
                  width={dimens.width}
                  height={dimens.height}
                  barLabels={barLabels}
                  barColors={barColors}
                  squareGrid
                  {...barChartProps}
                />
              )}
            </MeasureView>
          </>
        }
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <>
          {table}
          <MeasureView>
            {dimens => (
              <BarChartWithState
                id="barchart"
                defaultState={initialState}
                testCorrect={isEqual(correctAnswer)}
                width={dimens.width}
                height={dimens.height}
                barLabels={barLabels}
                barColors={barColors}
                interactive={interactiveIndices}
                snapToNearest={snapToNearest}
                squareGrid
                {...barChartProps}
              />
            )}
          </MeasureView>
        </>
      }
    />
  );
}
