import { View, StyleSheet } from 'react-native';
import { Dimens } from '../../../../theme/scaling';
import { colors } from '../../../../theme/colors';

export type ShadedCounterArrangements = (2 | 1 | 0)[][];

type Props = {
  /**
   * How counters will be arranged. 2 as shaded cells, 1 as non-shaded cells, 0 as empty spaces
   */
  arrangement: ShadedCounterArrangements;
  /**
   * Wrapper dimensions
   */
  dimens: Dimens;
  /**
   * To be used to specifically set the scale of images. Useful when rendering more than one on a page
   */
  scale?: number;
};

export function ShadedCounterBoxArrangement({ dimens, scale, arrangement }: Props) {
  const rows = arrangement.length;
  const columns = Math.max(...arrangement.map(val => val.length));

  const viewWidth = dimens.width / (scale ?? columns + 1);
  const viewHeight = dimens.height / (scale ?? rows + 1);
  const counterDimens = Math.min(viewWidth, viewHeight);
  const counterScale = 0.9;

  const styles = getStyles(dimens, counterDimens);

  return (
    <View style={styles.mainContainer}>
      <View style={styles.wrapper}>
        {arrangement.map((row, rowIndex) => (
          <View key={`row-${rowIndex}`} style={styles.counterWrapper}>
            {row.map((cell, colIndex) => (
              <View key={`cell-${rowIndex}-${colIndex}`} style={styles.counter}>
                {cell ? (
                  <View
                    style={{
                      width: counterDimens * counterScale,
                      height: counterDimens * counterScale,
                      borderRadius: 999,
                      borderWidth: 2.33,
                      backgroundColor: cell === 2 ? colors.prussianBlue : colors.white,
                      borderColor: 'black',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  />
                ) : null}
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  );
}

const getStyles = (dimens: Dimens, counterDimens: number) =>
  StyleSheet.create({
    wrapper: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      ...dimens
    },
    counterWrapper: { flexDirection: 'row', justifyContent: 'center' },
    mainContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'row',
      ...dimens
    },
    counter: {
      width: counterDimens,
      height: counterDimens,
      alignItems: 'center',
      justifyContent: 'center'
    }
  });
