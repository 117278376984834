import { useContext, useMemo } from 'react';
import { StyleSheet, View, TextStyle, StyleProp, ViewStyle } from 'react-native';
import { Dimens } from 'common/src/theme/scaling';
import { colors } from '../../../../theme/colors';
import { FontVariantKey } from '../../../../theme';
import TextStructure from '../../../molecules/TextStructure';
import { parseMarkup } from '../../../../markup';
import { DisplayMode } from '../../../../contexts/displayMode';

type Props = {
  /**
   * Input box for answer box on the number track, must be specified to know what input
   * is available to the user and how to handle the answer
   */
  inputBox: (
    ansIndex: number,
    boxWidth: number,
    boxHeight: number,
    style?: StyleProp<ViewStyle>
  ) => JSX.Element;
  /**
   * An array of strings to hold the values that go in the
   * number track. $ans is used to indicate where text input fields should be placed. The entire string should equal
   * '$ans' in this case. (This doesn't use the full markup language with <ans/> and other tags used elsewhere.)
   */
  boxValues: string[];
  contentTextVariant?: FontVariantKey;
  textStyle?: StyleProp<TextStyle>;
  fractionTextStyle?: StyleProp<TextStyle>;
  fractionDividerStyle?: StyleProp<ViewStyle>;
  customBoxWidth?: number;
  customBoxHeight?: number;
  /** Usable dimensions for the question */
  dimens: Dimens;
  /** Shades the cells with the matching values. (Will ignore shadedCellsByIndex if both passed) */
  shadedCellsByValue?: string[];
  /** Shades the cells with the corresponding index. (Will be ignored if also shadedCellsByValue is also passed) */
  shadedCellsByIndex?: number[];
  /** whether the whole number track box should be answer box. Defaults to true */
  isWholeAnswerBox?: boolean;
  isDraggable?: boolean;
  pdfAnswerBoxStyle?: StyleProp<ViewStyle>;
};

/**
 * This component renders a number track manipulative.
 * Number tracks are lines made up of square or rectangular cells, each of which contains a number.
 * These are laid put horizontally across the screen.
 */
export function NumberTrack({
  inputBox,
  boxValues,
  contentTextVariant = 'WRN400',
  fractionTextStyle,
  fractionDividerStyle,
  textStyle,
  customBoxWidth,
  customBoxHeight,
  dimens: { width, height },
  shadedCellsByValue,
  shadedCellsByIndex,
  isWholeAnswerBox = true,
  isDraggable = false,
  pdfAnswerBoxStyle
}: Props) {
  const displayMode = useContext(DisplayMode);
  const boxValuesParsed = boxValues.map(val => parseMarkup(val));
  const someFractionAnswer =
    boxValuesParsed.filter(val => val.tokens[0].type === 'frac' && val.numberOfAns > 0).length > 1;

  const answerBoxWidth = displayMode === 'digital' ? 96 : 150;
  const answerBoxHeight = displayMode === 'digital' ? 96 : 150;
  const answerPadding = 50;
  const boxWidth = customBoxWidth ?? width / boxValues.length;
  const boxHeight =
    customBoxHeight ?? (someFractionAnswer ? 2 * answerBoxHeight + answerPadding : answerBoxHeight);

  const containerWidth = customBoxWidth ? customBoxWidth * boxValues.length : width;

  const styles = useStyles(containerWidth, height);

  let ansIndex = -1;

  const isShaded = (number: string, index: number): boolean => {
    if (shadedCellsByValue !== undefined) return shadedCellsByValue.includes(number);
    if (shadedCellsByIndex !== undefined) return shadedCellsByIndex.includes(index);
    return false;
  };

  // Numbers
  const numberComponents = boxValuesParsed.map((number, idx) => {
    const currentIsFractionAns = number.tokens[0].type === 'frac' && number.numberOfAns > 0;
    const prev = boxValuesParsed[idx - 1];
    const prevIsSameType =
      (prev !== undefined && number.numberOfAns > 0 && prev.numberOfAns > 0) ||
      (number.numberOfAns === 0 && prev !== undefined && prev.numberOfAns === 0);

    const unparsedValue = boxValues[idx];
    const ansCount = number.numberOfAns;

    // input height/width depends on if we are using standard 96x96 or if its the full box
    const inputHeight = currentIsFractionAns || !isWholeAnswerBox ? answerBoxHeight : boxHeight;
    const inputWidth = currentIsFractionAns || !isWholeAnswerBox ? answerBoxWidth : boxWidth;

    // we want to overlap borders so need to position to the left by borderWidth apart from the first box in the track.
    const boxToLeft = idx !== 0;
    const borderWidth = isWholeAnswerBox && number.numberOfAns > 0 ? 3 : 2;
    const leftPositionOffset = boxToLeft ? borderWidth : 0;
    // to help with overlap, when we have an input box we can extend the width. However with draggables we can't because of draggable pill sizes
    const widthOffset = isDraggable ? 0 : leftPositionOffset;
    // With draggables we dont want to start altering the width so each box needs to be positioned one further to the left
    const leftPosition = isDraggable
      ? boxWidth * idx - leftPositionOffset * idx
      : boxWidth * idx - leftPositionOffset;

    // Add number of answers for this tick to index
    ansIndex += number.numberOfAns;
    // Create scoped copy, otherwise all answer indices will equal final index
    const i = ansIndex;

    // If the whole box is an answer then we can simply return the input box
    if (isWholeAnswerBox && number.numberOfAns > 0) {
      return inputBox(
        ansIndex,
        boxWidth,
        boxHeight,
        // we absolutely position to overlap borders. With draggables we can't change width so apply margin instead
        isDraggable
          ? [{ marginLeft: -3 }, displayMode !== 'digital' && pdfAnswerBoxStyle]
          : [
              {
                position: 'absolute',
                left: leftPosition,
                height: boxHeight,
                width: boxWidth + widthOffset
              },
              displayMode !== 'digital' && pdfAnswerBoxStyle
            ]
      );
    }
    // else we return a view with a text structure so that the answer box can sit inside a 'cell'
    return (
      <View
        key={'box' + idx}
        style={[
          {
            height: boxHeight,
            width: boxWidth + leftPositionOffset
          },
          prevIsSameType && isDraggable && { marginLeft: -3 },
          !isDraggable && {
            position: 'absolute',
            left: leftPosition
          },
          styles.cell,
          number.numberOfAns === 0 && isShaded(unparsedValue, idx) && styles.shadedCell
        ]}
      >
        <TextStructure
          sentence={unparsedValue}
          inputBox={({ index }) => {
            return inputBox(
              i - ansCount + (index + 1),
              inputWidth + leftPositionOffset,
              inputHeight
            );
          }}
          textVariant={contentTextVariant}
          fractionTextStyle={fractionTextStyle}
          fractionDividerStyle={fractionDividerStyle}
          textStyle={textStyle}
          fractionContainerStyle={{ height: ansCount > 0 ? 96 : 48 }}
        />
      </View>
    );
  }, ansIndex);

  return <View style={styles.container}>{numberComponents}</View>;
}

const useStyles = (width: number, height: number) => {
  const displayMode = useContext(DisplayMode);

  return useMemo(
    () =>
      StyleSheet.create({
        container: {
          width: width,
          height: height,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        },
        shadedCell: {
          backgroundColor: colors.gridBlue
        },
        cell: {
          justifyContent: 'center',
          alignItems: 'center',
          borderWidth: displayMode === 'digital' ? 2 : 4,
          borderColor: displayMode === 'digital' ? colors.prussianBlue : colors.black
        },
        noBorderCell: {
          justifyContent: 'center',
          alignItems: 'center'
        }
      }),
    [width, height, displayMode]
  );
};
