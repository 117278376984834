import { getRandomFromArray, getRandomSubArrayFromArray } from './random';
import { z } from 'zod';

// TODO: Add 'Dora' back into female array if her .ai file/SVG becomes available.
// Do not have this SVG as of launch.
export const names = {
  male: ['Amir', 'Dexter', 'Jack', 'Max', 'Mo', 'Ron', 'Teddy', 'Tommy'],
  female: ['Alex', 'Annie', 'Eva', 'Jo', 'Kim', 'Rosie', 'Sam', 'Whitney']
} as const;

export const dogNames = [
  'Buster',
  'Misty',
  'Titch',
  'Henry',
  'Ralph',
  'Margot',
  'Autumn',
  'Millie',
  'Ruby',
  'Marlo',
  'Daphne',
  'Mabel',
  'Buddy',
  'Rudy',
  'Agnes'
] as const;

export const nameSchema = z.enum([...names.male, ...names.female]);
export type Gender = keyof typeof names;
export type Name = (typeof names)[Gender][number];

export const dogNamesSchema = z.enum(dogNames);

export function getRandomName() {
  return getRandomFromArray([...names.male, ...names.female] as const);
}

export function getMaleName() {
  return getRandomFromArray([...names.male] as const);
}

export function getRandomUniqueMaleNames(numberOfNames: number) {
  return getRandomSubArrayFromArray([...names.male] as const, numberOfNames);
}

export function getFemaleName() {
  return getRandomFromArray([...names.female] as const);
}

export function getRandomUniqueFemaleNames(numberOfNames: number) {
  return getRandomSubArrayFromArray([...names.female] as const, numberOfNames);
}

export function getRandomUniqueNames(numberOfNames: number, random?: () => number) {
  return getRandomSubArrayFromArray([...names.male, ...names.female] as const, numberOfNames, {
    random
  });
}

export function getGenderFromName(name: Name): Gender {
  return names.male.some(it => it === name) ? 'male' : 'female';
}

// KS1 names
export const ks1Names = {
  male: ['Mo', 'Max', 'Jack', 'Ron'],
  female: ['Sam', 'Kim', 'Jo']
} as const;
const allKs1Names = [...ks1Names.male, ...ks1Names.female] as const;

export const ks1NameSchema = z.enum(allKs1Names);

export type Ks1Names = (typeof allKs1Names)[number];

export function getRandomKs1Name() {
  return getRandomFromArray(allKs1Names);
}

export function getRandomUniqueKs1Names(numberOfNames: number, random?: () => number) {
  return getRandomSubArrayFromArray(allKs1Names, numberOfNames, {
    random
  });
}

export function getGenderFromKs1Name(name: Name): Gender {
  return ks1Names.male.some(it => it === name) ? 'male' : 'female';
}
