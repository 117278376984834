import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive
} from '../../../../utils/random';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF3InteractiveContent from '../../../../components/question/questionFormats/QF3InteractiveContent';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import { MarkupAssets } from '../../../../markup';
import { View } from 'react-native';
import { countRange } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbn',
  description: 'bbn',
  keywords: ['Subtract', 'Part'],
  schema: z
    .object({
      initial: z.number().int().min(3).max(10),
      removed: z.number().int().min(1).max(9),
      object: z.enum(['Marble', 'circle_red', 'circle_yellow', 'circle_green', 'circle_blue'])
    })
    .refine(({ initial, removed }) => initial - removed > 0),
  simpleGenerator: () => {
    const initial = randomIntegerInclusive(3, 10);
    const removed = randomIntegerInclusive(1, initial - 1);
    const object = getRandomFromArray([
      'Marble',
      'circle_red',
      'circle_yellow',
      'circle_green',
      'circle_blue'
    ] as const);

    return {
      initial,
      removed,
      object
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { initial, removed, object } = question;
    const objIsMarble = object === 'Marble';

    const objPluralString = objIsMarble
      ? translate.objects.Marbles(2)
      : translate.objects.Counters(2);

    const svgNameObject = objIsMarble ? object : (`Circles/${object}` as const);

    const assetDimen = displayMode === 'digital' ? 60 : 100;

    const cupSvg = 'cup_red';

    return (
      <MarkupAssets
        elements={{
          assetA: (
            <View
              style={{
                flexDirection: 'row',
                gap: 16,
                paddingVertical: 32,
                alignItems: 'flex-end'
              }}
            >
              <AssetSvg name={cupSvg} height={assetDimen + 70} width={assetDimen + 70} />
              <View style={{ flexDirection: 'row', gap: 8 }}>
                {countRange(removed).map(i => (
                  <AssetSvg
                    key={objIsMarble ? `${i}_marbles` : `${i}_counters`}
                    name={svgNameObject}
                    height={assetDimen}
                    width={assetDimen}
                  />
                ))}
              </View>
            </View>
          )
        }}
      >
        <QF2AnswerBoxOneSentence
          title={`${translate.ks1Instructions.thereAreXObjectsAltogether(
            initial,
            objPluralString
          )}<br/><asset name='assetA'/><br/>${translate.ks1Instructions.howManyObjectsAreInTheCup(
            objPluralString
          )}`}
          sentenceStyle={{ alignSelf: 'flex-end' }}
          mainPanelContainerStyle={{ justifyContent: 'flex-end' }}
          sentence={'<ans/>'}
          testCorrect={[(initial - removed).toString()]}
        />
      </MarkupAssets>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbo',
  description: 'bbo',
  keywords: ['Part-whole model', 'Subtract'],
  questionHeight: 1000,
  schema: z
    .object({
      whole: z.number().int().min(3).max(10),
      givenPart: z.number().int().min(1).max(9),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft']),
      givenPartOnLeft: z.boolean()
    })
    .refine(({ whole, givenPart }) => whole > givenPart),
  simpleGenerator: () => {
    const whole = randomIntegerInclusive(3, 10);
    const givenPart = randomIntegerInclusive(1, whole - 1);
    const givenPartOnLeft = getRandomBoolean();

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { whole, givenPart, variation, givenPartOnLeft };
  },
  Component: ({ question, translate }) => {
    const { whole, variation, givenPart, givenPartOnLeft } = question;

    const answerPart = whole - givenPart;

    const partition = givenPartOnLeft
      ? [givenPart.toLocaleString(), '$ans']
      : ['$ans', givenPart.toLocaleString()];

    return (
      <QF3InteractiveContent
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.completeThePartWholeModel()}
        Content={({ userAnswer, setUserAnswer, dimens }) => (
          <PartWholeModel
            top={whole}
            userAnswer={userAnswer}
            onTextInput={(answer, index) => {
              const newArr = [...userAnswer];
              newArr[index] = answer;
              setUserAnswer(newArr);
            }}
            variation={variation}
            partition={partition}
            isInteractive
            dimens={{
              height: dimens.height,
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9
            }}
          />
        )}
        initialState={['']}
        testComplete={userAnswer => userAnswer.every(it => it !== '')}
        testCorrect={([userAnswer]) => userAnswer === answerPart.toString()}
        customMarkSchemeAnswer={{
          answersToDisplay: [answerPart.toLocaleString()]
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbp',
  description: 'bbp',
  keywords: ['Subtract', 'Number sentence'],
  schema: z
    .object({
      num1: z.number().int().min(1).max(9),
      num2: z.number().int().min(1).max(9),
      flipped: z.boolean(),
      answer: z.enum(['left', 'right'])
    })
    .refine(({ num1, num2 }) => isInRange(3, 10)(num1 + num2)),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(1, 9);
    const num2 = randomIntegerInclusive(1, 9, {
      constraint: x => isInRange(3, 10)(x + num1)
    });
    const answer = getRandomFromArray(['left', 'right'] as const);
    const flipped = getRandomBoolean();

    return {
      num1,
      num2,
      answer,
      flipped
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, answer, flipped } = question;
    const sentence = getBinOpEquation({
      left: num1,
      right: num2,
      result: num1 + num2,
      sign: 'add',
      flipped,
      answer
    });
    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheNumberSentence()}
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAPart',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question1]
});
export default SmallStep;
