import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import Clock from '../../../../components/question/representations/Clock';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { convert12hToSpokenString } from '../../../../utils/time';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkX',
  description: 'bkX',
  keywords: ['Past the hour', 'Analogue clock', 'Time'],
  schema: z
    .object({
      hours: z.number().int().min(0).max(11),
      minutes: z.number().int().min(5).max(30).multipleOf(5)
    })
    .refine(
      val => val.hours !== val.minutes / 5,
      'hour and minute hands cannot be near the same position on a clock face.'
    ),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = randomIntegerInclusiveStep(5, 30, 5, {
      // Needed to prevent duplicate answers:
      constraint: x => x / 5 !== hours
    });

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctTime = {
      string: convert12hToSpokenString(translate, hours, minutes),
      value: 'correct'
    };

    // Swap minute and hour hands:
    const incorrectTimeA = {
      string: convert12hToSpokenString(
        translate,
        // This makes this incorrect time feel more plausible as a misconception, rather than just always
        // making the hours be minutes / 5:
        hours > 6 ? minutes / 5 - 1 : minutes / 5,
        hours * 5
      ),
      value: 'incorrectA'
    };

    // Incorrect reading of minute hand:
    const incorrectTimeB = {
      string: convert12hToSpokenString(translate, hours, minutes / 5),
      value: 'incorrectB'
    };

    const options = shuffle([correctTime, incorrectTimeA, incorrectTimeB], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTimeShown()}
        pdfTitle={translate.ks1PDFInstructions.tickTheTimeShown()}
        testCorrect={['correct']}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours, minutes }}
            width={Math.min(dimens.width * 0.9, dimens.height * 0.9)}
            interactive={false}
          />
        )}
        renderItems={options.map(({ string, value }) => ({
          value,
          component: <Text variant="WRN700">{string}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bkY',
  description: 'bkY',
  keywords: ['Past the hour', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutes: numberEnum([5, 10, 20, 25])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);

    const minutes = getRandomFromArray([5, 10, 20, 25] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeDoesTheClockShow()}
        sentence={translate.ks1AnswerSentences.ansMinutesPastAns()}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[minutes.toString(), hours.toString()]}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours === 12 ? 0 : hours, minutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bkZ',
  description: 'bkZ',
  keywords: ['Past the hour', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: z.number().int().min(5).max(30).multipleOf(5)
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = randomIntegerInclusiveStep(5, 30, 5);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.dragTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.ks1PDFInstructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
        snapMinutes={5}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TellTimePastTheHour',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
