import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation, {
  BaseTenRepCalcGridsAndScale
} from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { Dimens } from '../../../../theme/scaling';
import { View } from 'react-native';
import { numberToBase10Object } from '../../../../utils/math';
import { isInRange } from '../../../../utils/matchers';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfS',
  description: 'bfS',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    numberOfOnes: z.number().int().min(1).max(9),
    numberOfTens: z.number().int().min(2).max(9),
    variation: z.enum(['Straws', 'Cubes', 'rekenrek', 'tenFrame']),
    isBase10Jumbled: z.boolean()
  }),
  simpleGenerator: () => {
    const numberOfOnes = randomIntegerInclusive(1, 9);
    const numberOfTens = randomIntegerInclusive(2, 9);
    const variation =
      numberOfTens < 6
        ? getRandomFromArray(['Straws', 'Cubes', 'rekenrek', 'tenFrame'] as const)
        : getRandomFromArray(['Straws', 'Cubes', 'rekenrek'] as const);

    const isBase10Jumbled = getRandomFromArrayWithWeights([false, true], [3, 1]);

    return {
      numberOfOnes,
      numberOfTens,
      variation,
      isBase10Jumbled
    };
  },
  Component: props => {
    const {
      question: { numberOfOnes, numberOfTens, variation, isBase10Jumbled },
      translate
    } = props;

    const total = numberOfTens * 10 + numberOfOnes;

    const getBase10Representations = (variation: 'Cubes' | 'Straws', dimens: Dimens) => {
      if (isBase10Jumbled) {
        const scale = BaseTenRepCalcGridsAndScale(
          dimens.width,
          dimens.height,
          { ones: numberOfOnes, tens: numberOfTens },
          variation
        ).scale;

        const total = numberOfTens + numberOfOnes;

        const onesBase = countRange(numberOfOnes).map((_, index) => (
          <BaseTenRepresentation
            key={`ones_${index}`}
            b10Rep={{
              variant: variation,
              numbers: { ones: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height}
            align="center"
            scale={scale}
          />
        ));

        const tensBase = countRange(numberOfTens).map((_, index) => (
          <BaseTenRepresentation
            key={`tens${index}`}
            b10Rep={{
              variant: variation,
              numbers: { tens: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height}
            align="center"
            scale={scale}
          />
        ));

        const reps = shuffle([...onesBase, ...tensBase], { random: seededRandom(props.question) });

        return (
          <View>
            <View
              style={{
                flexDirection: 'row',
                height: dimens.height,
                columnGap: 16,
                alignSelf: 'center'
              }}
            >
              {reps}
            </View>
          </View>
        );
      } else
        return (
          <BaseTenRepresentation
            b10Rep={{
              variant: variation,
              numbers: { tens: numberOfTens, ones: numberOfOnes },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        );
    };

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) =>
          variation === 'rekenrek' ? (
            <Rekenrek dimens={dimens} rows={10} numberShown={total} />
          ) : variation === 'Straws' || variation === 'Cubes' ? (
            getBase10Representations(variation, dimens)
          ) : (
            <View style={{ flexDirection: 'row', gap: 20 }}>
              {isBase10Jumbled
                ? shuffle(
                    countRange(Math.ceil(total / 10)).map(i => (
                      <TenFrameLayout
                        items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                        orientation="vertical"
                        size="xsmall"
                        key={i}
                      />
                    )),
                    { random: seededRandom(props.question) }
                  )
                : countRange(Math.ceil(total / 10)).map(i => (
                    <TenFrameLayout
                      items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                      orientation="vertical"
                      size="xsmall"
                      key={i}
                    />
                  ))}
            </View>
          )
        }
        sentence={translate.ks1AnswerSentences.thereAreAnsTensAndAnsOnesTheNumberIsAns(
          numberOfTens,
          numberOfOnes
        )}
        testCorrect={[numberOfTens.toString(), numberOfOnes.toString(), total.toString()]}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bfT',
  description: 'bfT',
  keywords: ['Tens', 'Ones'],
  schema: z.object({
    variant: z.enum(['Balloons', 'Bricks', 'Marbles', 'Sweets', 'Crayons']),
    ones: z.number().int().min(1).max(9),
    tens: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const variant = getRandomFromArray([
      'Balloons',
      'Bricks',
      'Marbles',
      'Sweets',
      'Crayons'
    ] as const);
    const ones = randomIntegerInclusive(1, 9);
    const tens = randomIntegerInclusive(2, 9);
    return {
      variant,
      ones,
      tens
    };
  },
  Component: props => {
    const {
      question: { ones, tens, variant },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={translate.answerSentences.thereAreNumberObjects(variant)}
        title={translate.instructions.howManyObjectsAreThere(variant)}
        testCorrect={[(tens * 10 + ones).toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <BaseTenRepresentation
            b10Rep={{ variant: variant, numbers: { ones, tens }, arrangement: 'ltr' }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bfU',
  description: 'bfU',
  keywords: ['Base 10', 'Tens', 'Ones'],
  schema: z.object({
    options: z.array(z.number().int().min(11).max(99)).length(4),
    isJumbled: z.boolean(),
    correctOption: z
      .number()
      .int()
      .min(21)
      .max(99)
      .refine(num => num % 10 !== 0, 'Correct option cannot be a multiple of 10')
  }),
  simpleGenerator: () => {
    const { options, correctOption } = rejectionSample(
      () => {
        const correctOption = randomIntegerInclusive(21, 99, { constraint: x => x % 10 !== 0 });
        const incorrectOptionA = parseInt(correctOption.toString().split('').reverse().join(''));
        const incorrectOptionB =
          correctOption <= 89
            ? getRandomBoolean()
              ? correctOption + 10
              : correctOption - 10
            : correctOption - 10;
        const incorrectOptionC =
          correctOption < 99
            ? getRandomBoolean()
              ? correctOption + 1
              : correctOption - 1
            : correctOption - 1;

        const options = [correctOption, incorrectOptionA, incorrectOptionB, incorrectOptionC];

        return { options, correctOption };
      },
      ({ options }) => arrayHasNoDuplicates(options) && options.every(number => number <= 99)
    );

    const isJumbled = getRandomBoolean();

    return {
      options: shuffle(options),
      isJumbled,
      correctOption
    };
  },
  Component: props => {
    const {
      question: { options, correctOption, isJumbled },
      translate
    } = props;

    const getBase10Representations = (number: number, dimens: Dimens) => {
      const tens = Math.floor(number / 10);
      const ones = number % 10;

      if (isJumbled) {
        const scale = BaseTenRepCalcGridsAndScale(
          dimens.width,
          dimens.height,
          { ones, tens },
          'Cubes'
        ).scale;

        const total = tens + ones > 11 ? 11 : tens + ones;

        const onesBase = countRange(ones).map((_, index) => (
          <BaseTenRepresentation
            key={`ones_${index}`}
            b10Rep={{
              variant: 'Cubes',
              numbers: { ones: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height}
            align={tens + ones <= 11 ? 'center' : 'flex-start'}
            scale={isInRange(0.22, 0.25)(scale) ? scale / 2 : scale}
          />
        ));
        const tensBase = countRange(tens).map((_, index) => (
          <BaseTenRepresentation
            key={`tens${index}`}
            b10Rep={{
              variant: 'Cubes',
              numbers: { tens: 1 },
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width / total}
            usableHeight={dimens.height}
            align={tens + ones <= 11 ? 'center' : 'flex-start'}
            scale={isInRange(0.22, 0.28)(scale) ? scale / 2 : scale}
          />
        ));

        const reps = shuffle([...onesBase, ...tensBase], { random: seededRandom(props.question) });
        return (
          <View>
            <View
              style={{
                height: dimens.height
              }}
            >
              {reps.length < 11 ? (
                <View style={{ flexDirection: 'row', height: dimens.height }}>{reps}</View>
              ) : (
                <View style={{ gap: 2 }}>
                  <View style={{ flexDirection: 'row', height: (dimens.height / 2) * 0.9 }}>
                    {countRange(Math.min(11, reps.length)).map((_, index) => reps[index])}
                  </View>
                  <View style={{ flexDirection: 'row', height: (dimens.height / 2) * 0.9 }}>
                    {countRange(Math.max(0, reps.length - 11)).map((_, index) => reps[index + 11])}
                  </View>
                </View>
              )}
            </View>
          </View>
        );
      } else
        return (
          <BaseTenRepresentation
            b10Rep={{
              variant: 'Cubes',
              numbers: numberToBase10Object(number),
              arrangement: 'ltr'
            }}
            usableWidth={dimens.width}
            usableHeight={dimens.height}
          />
        );
    };

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheBase10ThatShowsX(correctOption)}
        pdfTitle={translate.ks1PDFInstructions.tickTheBase10ThatShowsX(correctOption)}
        testCorrect={[correctOption]}
        numItems={4}
        renderItems={({ dimens }) =>
          options.map(value => ({
            value,
            component: getBase10Representations(value, dimens)
          }))
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseTensAndOnes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
