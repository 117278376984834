import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { getCounterArrangementScale } from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { numberEnum } from '../../../../utils/zod';
import { countRange, filledArray } from '../../../../utils/collections';
import { ADD } from '../../../../constants';
import { View } from 'react-native';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { getGroupSvgName } from '../../../../utils/objectsImages';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biJ',
  description: 'biJ',
  keywords: ['Equal groups', 'Altogether', 'Repeated addition', 'Counters'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    inEachGroup: z.number().int().min(2).max(6),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    rotation: numberEnum([90, 180, 270, 0]),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const inEachGroup = randomIntegerInclusive(2, 6);
    const groups =
      inEachGroup === 5 || inEachGroup === 2
        ? randomIntegerInclusive(2, 5)
        : randomIntegerInclusive(2, 3);

    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    const rotation = inEachGroup > 3 ? getRandomFromArray([90, 180, 270, 0] as const) : 0;

    return { groups, inEachGroup, seed, color, rotation };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, seed, color, rotation },
      translate
    } = props;

    const scale = getCounterArrangementScale(inEachGroup);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) => {
          const boxDimen = Math.min(dimens.width * 0.18, dimens.height * 0.9);
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20
              }}
            >
              {countRange(groups).map(idx => (
                <CounterBoxArrangement
                  key={`${idx}`}
                  counters={inEachGroup}
                  color={color}
                  dimens={{ height: boxDimen, width: boxDimen }}
                  seed={seed}
                  rotation={rotation}
                  scale={scale}
                />
              ))}
            </View>
          );
        }}
        testCorrect={[
          [groups.toString()],
          [inEachGroup.toString()],
          [(inEachGroup * groups).toString()]
        ]}
        pdfDirection="column"
        questionHeight={1000}
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsGroups(),
          translate.ks1AnswerSentences.thereAreAnsCountersInEachGroup(),
          translate.ks1AnswerSentences.thereAreAnsCountersAltogether()
        ]}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biK',
  description: 'biK',
  keywords: ['Equal groups', 'Altogether', 'Repeated addition', 'Counters'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    inEachGroup: z.number().int().min(2).max(6),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    rotation: numberEnum([90, 180, 270, 0]),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const inEachGroup = randomIntegerInclusive(2, 6);
    const groups =
      inEachGroup === 5 || inEachGroup === 2
        ? randomIntegerInclusive(2, 5)
        : randomIntegerInclusive(2, 3);

    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    const rotation = inEachGroup > 3 ? getRandomFromArray([90, 180, 270, 0] as const) : 0;

    return { groups, inEachGroup, seed, color, rotation };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, seed, color, rotation },
      translate
    } = props;

    const scale = getCounterArrangementScale(inEachGroup);
    const sentence3 = filledArray(inEachGroup, groups).join(` ${ADD} `);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) => {
          const boxDimen = Math.min(dimens.width * 0.18, dimens.height * 0.9);
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20
              }}
            >
              {countRange(groups).map(idx => (
                <CounterBoxArrangement
                  key={`${idx}`}
                  counters={inEachGroup}
                  color={color}
                  dimens={{ height: boxDimen, width: boxDimen }}
                  seed={seed}
                  rotation={rotation}
                  scale={scale}
                />
              ))}
            </View>
          );
        }}
        testCorrect={[
          [groups.toString()],
          [inEachGroup.toString()],
          [(inEachGroup * groups).toString()]
        ]}
        pdfDirection="column"
        questionHeight={1000}
        sentences={[
          translate.ks1AnswerSentences.thereAreAnsGroups(),
          translate.ks1AnswerSentences.thereAreAnsCountersInEachGroup(),
          `${sentence3} = <ans/>`
        ]}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'biL',
  description: 'biL',
  keywords: ['Equal groups', 'Repeated addition'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    item: z.enum([
      'Apple',
      'Pear',
      'Banana',
      'Donut',
      'Egg',
      'Pencil',
      'Muffin',
      'Fish',
      'Cookie',
      'MuffinRack'
    ]),
    inEachGroup: numberEnum([2, 3, 4, 5, 6, 10]),
    incorrectOptionsIndex: z.array(z.number().int().min(0).max(4)),
    seed: z.number()
  }),
  simpleGenerator: () => {
    const inEachGroup = getRandomFromArray([2, 3, 4, 5, 6, 10] as const);
    // added constraint to remove duplicate selectables
    const max = [3, 4, 6].includes(inEachGroup) ? 3 : 5;
    const groups = randomIntegerInclusive(2, max, { constraint: x => inEachGroup !== x });
    const item = getRandomFromArray(
      inEachGroup === 10
        ? (['Fish', 'Donut', 'Egg', 'MuffinRack', 'Pencil'] as const)
        : inEachGroup > 5
        ? (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : ([
            'Apple',
            'Pear',
            'Banana',
            'Donut',
            'Egg',
            'Pencil',
            'Cookie',
            'MuffinRack',
            'Muffin',
            'Fish'
          ] as const)
    );
    const incorrectOptionsIndex = randomUniqueIntegersInclusive(
      0,
      groups === 2 || groups > 3 ? 3 : 4,
      3
    );
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    return { groups, item, inEachGroup, incorrectOptionsIndex, seed };
  },
  Component: props => {
    const {
      question: { groups, item, inEachGroup, incorrectOptionsIndex, seed },
      translate,
      displayMode
    } = props;

    const svgName: SvgName =
      item === 'Pencil' && inEachGroup === 10
        ? 'Pack_of_10_crayons'
        : getGroupSvgName(item, inEachGroup);

    const random = seededRandom(seed);
    const incorrectMultiplier = getRandomFromArray(
      [2, 5, 10].filter(i => i !== inEachGroup),
      { random }
    ) as number;
    const incorrectItems = [
      [
        'C',
        `${inEachGroup.toLocaleString()} ${ADD} ${groups.toLocaleString()} = ${(
          inEachGroup + groups
        ).toLocaleString()}`
      ],
      [
        'D',
        `${filledArray((1).toLocaleString(), groups).join(` ${ADD} `)} = ${groups.toLocaleString()}`
      ],
      [
        'E',
        `${filledArray(incorrectMultiplier.toLocaleString(), groups).join(` ${ADD} `)} = ${(
          groups * incorrectMultiplier
        ).toLocaleString()}`
      ]
    ];
    // if groups > 2 then add -1 as well
    if (groups > 2) {
      incorrectItems.push([
        'F',
        `${filledArray(inEachGroup.toLocaleString(), groups - 1).join(` ${ADD} `)} = ${(
          inEachGroup *
          (groups - 1)
        ).toLocaleString()}`
      ]);
    }
    // if groups < 4 then add +1 as well
    if (groups < 4) {
      incorrectItems.push([
        'B',
        `${filledArray(inEachGroup.toLocaleString(), groups + 1).join(` ${ADD} `)} = ${(
          inEachGroup *
          (groups + 1)
        ).toLocaleString()}`
      ]);
    }
    const answers = shuffle(
      [
        [
          'A',
          `${filledArray(inEachGroup.toLocaleString(), groups).join(` ${ADD} `)} = ${(
            inEachGroup * groups
          ).toLocaleString()}`
        ],
        ...incorrectItems.filter((_, i) => incorrectOptionsIndex.includes(i))
      ],
      { random }
    );
    const rows = Math.ceil(groups / 2);
    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.ks1Instructions.selectTheNumberSentenceThatMatchesPicture()}
        pdfTitle={translate.ks1PDFInstructions.tickTheNumberSentenceThatMatchesPicture()}
        selectableTextStyle={{ fontSize: displayMode === 'digital' ? 30 : 50 }}
        leftContent={
          <MeasureView>
            {dimens => (
              <View style={{ flexDirection: 'column', gap: 25 }}>
                {countRange(rows).map(rowId => (
                  <View
                    key={rowId}
                    style={{
                      flexDirection: 'row',
                      gap: 25,
                      alignItems: rowId === rows - 1 ? 'flex-start' : undefined
                    }}
                  >
                    {countRange(Math.min(2, groups - 2 * rowId)).map(i => (
                      <AssetSvg
                        key={`${rowId}_${i}`}
                        name={svgName}
                        width={dimens.width / 3}
                        height={dimens.height / 4}
                      />
                    ))}
                  </View>
                ))}
              </View>
            )}
          </MeasureView>
        }
        questionHeight={900}
        selectables={Object.fromEntries(answers)}
        selectableStyle={{ width: displayMode === 'digital' ? 400 : 700 }}
        correctAnswer={['A']}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddEqualGroups',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
