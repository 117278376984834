import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import { ADD, SUB } from '../../../../constants';
import {
  Scale,
  SimpleBaseTenWithCrossOut
} from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF37SentencesDrag from '../../../../components/question/questionFormats/QF37SentencesDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhb',
  description: 'bhb',
  keywords: ['Add', 'Base 10', 'Tens'],
  schema: z.object({
    numA: z
      .number()
      .int()
      .min(11)
      .max(89)
      .refine(val => val % 10 !== 0, 'numA must not be a multiple of 10'),
    numB: z.number().int().min(10).max(80).multipleOf(10),
    reversed: z.boolean(),
    flipEq: z.boolean()
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 89, { constraint: x => x % 10 !== 0 });
    const numB = randomIntegerInclusiveStep(10, 80, 10, { constraint: x => x + numA <= 99 });

    const reversed = getRandomBoolean();
    const flipEq = getRandomBoolean();

    return { numA, numB, reversed, flipEq };
  },
  Component: props => {
    const {
      question: { numA, numB, reversed, flipEq },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence(
      flipEq ? [numB, numA, numA + numB] : [numA, numB, numA + numB],
      ADD,
      2,
      { reversed }
    );

    const numTensA = Math.floor(numA / 10);
    const numOnesA = numA % 10;
    const numTensB = Math.floor(numB / 10);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => {
          const scale = Scale(dimens.width * 0.4, dimens.height * 0.8, {
            tens: Math.max(numTensA, numTensB)
          });
          return (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <SimpleBaseTenWithCrossOut
                ones={flipEq ? 0 : numOnesA}
                tens={flipEq ? numTensB : numTensA}
                scale={scale}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
              />
              <Text variant="WRN400" style={{ width: 80, textAlign: 'center' }}>
                {ADD}
              </Text>
              <SimpleBaseTenWithCrossOut
                ones={flipEq ? numOnesA : 0}
                tens={flipEq ? numTensA : numTensB}
                scale={scale}
                dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
              />
            </View>
          );
        }}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bhc',
  description: 'bhc',
  keywords: ['Subtract', 'Base 10', 'Tens'],
  schema: z.object({
    numA: z
      .number()
      .int()
      .min(11)
      .max(99)
      .refine(val => val % 10 !== 0, 'numA must not be a multiple of 10'),
    numB: z.number().int().min(10).max(90).multipleOf(10),
    reversed: z.boolean()
  }),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 99, { constraint: x => x % 10 !== 0 });
    const numB = randomIntegerInclusiveStep(10, 90, 10, { constraint: x => numA - x >= 1 });

    const reversed = getRandomBoolean();

    return { numA, numB, reversed };
  },
  Component: props => {
    const {
      question: { numA, numB, reversed },
      translate
    } = props;

    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA - numB], SUB, 2, {
      reversed
    });

    const numTens = Math.floor(numA / 10);
    const numOnes = numA % 10;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        pdfDirection="column"
        Content={({ dimens }) => (
          <SimpleBaseTenWithCrossOut
            ones={numOnes}
            tens={numTens}
            crossedOutIndices={{ tens: range(numTens - 1, numTens - numB / 10), ones: [] }}
            dimens={{ width: dimens.width, height: dimens.height * 0.8 }}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bhd',
  description: 'bhd',
  keywords: ['Subtract', 'Add', 'Tens'],
  schema: z.object({
    startingNumber: z
      .number()
      .int()
      .min(11)
      .max(89)
      .refine(val => val % 10 !== 0, 'startingNumber must not be a multiple of 10'),
    otherNums: z
      .array(
        z.object({
          addOrSubtract: z.enum([ADD, SUB]),
          value: z.number().int().min(10).max(90).multipleOf(10)
        })
      )
      .length(4),
    answers: z
      .array(z.number().int().min(1).max(100))
      .length(4)
      .refine(arrayHasNoDuplicates, 'answers must not have duplicates')
  }),
  simpleGenerator: () => {
    const numOfAdditions = randomIntegerInclusive(0, 4);
    const numOfSubtractions = 4 - numOfAdditions;

    const startingNumber = randomIntegerInclusive(11, 89, {
      constraint: x =>
        x % 10 !== 0 && x + numOfAdditions * 10 <= 99 && x - numOfSubtractions * 10 >= 1
    });

    const addends = randomUniqueIntegersInclusiveStep(10, 90, 10, numOfAdditions, {
      constraint: x => startingNumber + x <= 99
    });
    const subtrahends = randomUniqueIntegersInclusiveStep(10, 90, 10, numOfSubtractions, {
      constraint: x =>
        startingNumber - x >= 1 && !addends.map(val => startingNumber + val).includes(x)
    });

    const addendObjects: { addOrSubtract: ADD; value: number }[] = addends.map(addend => ({
      addOrSubtract: ADD,
      value: addend
    }));
    const subtrahendObjects: { addOrSubtract: SUB; value: number }[] = subtrahends.map(
      subtrahend => ({ addOrSubtract: SUB, value: subtrahend })
    );
    const otherNums = shuffle([...addendObjects, ...subtrahendObjects]);

    // Calculate answers now to shuffle them in generator
    const additionAnswers = addends.map(addend => startingNumber + addend);
    const subtractionAnswers = subtrahends.map(subtrahend => startingNumber - subtrahend);

    return {
      startingNumber,
      otherNums,
      answers: shuffle([...additionAnswers, ...subtractionAnswers])
    };
  },
  Component: props => {
    const {
      question: { startingNumber, otherNums, answers },
      translate
    } = props;

    // Map on otherNums and if else on ADD/SUB
    const sentencesAndAnswers = otherNums.map(({ value, addOrSubtract }) => {
      if (addOrSubtract === ADD) {
        return buildSimpleNumberSentence([startingNumber, value, startingNumber + value], ADD, 2);
      } else {
        return buildSimpleNumberSentence([startingNumber, value, startingNumber - value], SUB, 2);
      }
    });

    return (
      <QF37SentencesDrag
        title={translate.ks1Instructions.dragTheCardsToMatchTheCorrectAnswersToTheCalculations()}
        pdfTitle={translate.ks1PDFInstructions.matchTheCorrectAnswersToTheCalculations()}
        items={answers}
        actionPanelVariant="end"
        pdfLayout="itemsRight"
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        pdfSentencesStyle={{ alignItems: 'flex-end', alignSelf: 'center' }}
        sentences={sentencesAndAnswers.map(({ sentence }) => sentence)}
        testCorrect={sentencesAndAnswers.map(({ answer }) => [answer])}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddAndSubtract10s',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
