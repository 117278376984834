import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { countRange, filledArray } from '../../../../utils/collections';
import { View } from 'react-native';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { ADD } from '../../../../constants';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcJ',
  description: 'bcJ',
  keywords: ['Add', 'Near doubles', 'Ten frame'],
  schema: z.object({
    number1: z.number().int().min(1).max(10),
    isAdd: z.boolean(),
    counterColors: z.array(z.enum(['red', 'yellow', 'blue', 'green'])).length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 10);
    const isAdd = number1 === 1 ? true : number1 === 10 ? false : getRandomBoolean();
    const counterColors = getRandomSubArrayFromArray(
      ['red', 'yellow', 'blue', 'green'] as const,
      2
    );

    return { number1, isAdd, counterColors };
  },
  Component: props => {
    const {
      question: { number1, isAdd, counterColors },
      translate
    } = props;

    const number2 = number1 + (isAdd ? 1 : -1);
    const numberOfFrames = number1 + number2 > 10 ? 2 : 1;
    const [color1, color2] = counterColors;

    const color1A = [
      ...filledArray(color1, Math.min(number1, 5)),
      ...filledArray(undefined, 5 - Math.min(number1, 5))
    ];
    const color1B = [
      ...filledArray(color1, Math.max(number1 - 5, 0)),
      ...filledArray(undefined, 5 - Math.max(number1 - 5, 0))
    ];

    const color2A = [
      ...filledArray(color2, Math.min(number2, 5)),
      ...filledArray(undefined, 5 - Math.min(number2, 5))
    ];
    const color2B = [
      ...filledArray(color2, Math.max(number2 - 5, 0)),
      ...filledArray(undefined, 5 - Math.max(number2 - 5, 0))
    ];

    const tenFrames = [
      [...color1A, ...color2A],
      [...color1B, ...color2B]
    ];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        actionPanelVariant="endWide"
        title={translate.ks1Instructions.workOutTheNearDouble()}
        sentence={`${number1.toLocaleString()} ${ADD} ${number2.toLocaleString()} = <ans/>`}
        testCorrect={[(number1 + number2).toString()]}
        Content={
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {countRange(numberOfFrames).map(i => (
              <TenFrameLayout items={tenFrames[i]} size="small" key={i} />
            ))}
          </View>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcK',
  description: 'bcK',
  keywords: ['Add', 'Near doubles', 'Ten frame'],
  schema: z.object({
    number1: z.number().int().min(1).max(9),
    counterColors: z.array(z.enum(['red', 'yellow', 'blue', 'green'])).length(2)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(1, 9);
    const counterColors = getRandomSubArrayFromArray(
      ['red', 'yellow', 'blue', 'green'] as const,
      2
    );

    return { number1, counterColors };
  },
  Component: props => {
    const {
      question: { number1, counterColors },
      translate
    } = props;

    const number2 = number1 + 1;
    const numberOfFrames = number1 * 2 > 10 ? 2 : 1;
    const [color1, color2] = counterColors;

    const tenFrames = countRange(numberOfFrames).map(val =>
      countRange(Math.min(number1 * 2 - val * 10), 10).map(cell => (cell % 2 ? color1 : color2))
    );

    return (
      <QF1ContentAndSentences
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSentences()}
        sentences={[
          translate.ks1AnswerSentences.doubleNumIsAns(number1),
          translate.ks1AnswerSentences.soXAddYEqualsAns(number1, number2)
        ]}
        testCorrect={[[(number1 * 2).toString()], [(number1 + number2).toString()]]}
        Content={
          <View style={{ flexDirection: 'row', gap: 20 }}>
            {countRange(numberOfFrames).map(i => (
              <TenFrameLayout
                items={tenFrames[i]}
                size="small"
                key={i}
                itemOrdering="columnFirst"
              />
            ))}
          </View>
        }
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcL',
  description: 'bcL',
  keywords: ['Add', 'Near doubles'],
  schema: z.object({
    lowerNumber: z.number().int().min(1).max(9),
    lowerNumberFirst: z.boolean()
  }),
  simpleGenerator: () => {
    const lowerNumber = randomIntegerInclusive(1, 9);

    const lowerNumberFirst = getRandomBoolean();
    return { lowerNumber, lowerNumberFirst };
  },

  Component: props => {
    const {
      question: { lowerNumber, lowerNumberFirst },
      translate
    } = props;

    const higherNumber = lowerNumber + 1;

    const sentence = lowerNumberFirst
      ? `${lowerNumber.toLocaleString()} + ${higherNumber.toLocaleString()} = <ans/>`
      : `${higherNumber.toLocaleString()} + ${lowerNumber.toLocaleString()} = <ans/>`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[(lowerNumber + higherNumber).toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'NearDoubles',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
