import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { createCompleteSentenceQuestion, createShownNumberQuestion } from './3Understand1112And13';
import { bfO } from '../../../Year 2/Autumn/PlaceValue/1NumbersTo20';
import { z } from 'zod';
import { getRandomBoolean, getRandomFromArray } from '../../../../utils/random';

////
// Questions
////

const Question1 = createShownNumberQuestion({ min: 17, max: 19 }, 'bb9');

const Question2 = createCompleteSentenceQuestion({ min: 17, max: 19 }, 'bca');

const Question3 = {
  ...bfO,
  uid: 'bcb',
  description: 'bcb' as const,
  schema: z.object({
    number: z.number().int().min(17).max(19),
    numOrWord: z.enum(['numeral', 'word']),
    isBase10: z.boolean(),
    counterVariant: z.enum(['red', 'blue', 'green', 'grey', 'yellow'])
  }),
  generator: () => {
    const number = getRandomFromArray([17, 18, 19] as const);

    const numOrWord = getRandomFromArray(['numeral', 'word'] as const);

    const isBase10 = getRandomBoolean();

    const counterVariant = getRandomFromArray(['red', 'blue', 'green', 'grey', 'yellow'] as const);

    return { number, numOrWord, isBase10, counterVariant };
  }
};

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Understand1718And19',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
