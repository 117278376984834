import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { z } from 'zod';
import Clock from '../../../../components/question/representations/Clock';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import { convert12hToSpokenString } from '../../../../utils/time';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF40SetTheClockFace from '../../../../components/question/questionFormats/QF40SetTheClockFace';
import { isEqual } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bkR',
  description: 'bkR',
  keywords: ["O'clock", 'Half past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: numberEnum([0, 30])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = getRandomFromArray([0, 30] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctTime = {
      string: convert12hToSpokenString(translate, hours, minutes),
      value: 'C'
    };

    const incorrectTimeA =
      minutes === 0
        ? {
            string: convert12hToSpokenString(translate, hours, 30),
            value: 'A'
          }
        : {
            string: convert12hToSpokenString(translate, hours === 11 ? 0 : hours + 1, minutes),
            value: 'A'
          };

    const incorrectTimeB =
      minutes === 0
        ? {
            string: convert12hToSpokenString(translate, hours === 0 ? 11 : hours - 1, minutes),
            value: 'B'
          }
        : {
            string: convert12hToSpokenString(translate, hours, 0),
            value: 'B'
          };

    const options = shuffle([correctTime, incorrectTimeA, incorrectTimeB], { random });

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.selectTheTimeShown()}
        pdfTitle={translate.ks1PDFInstructions.tickTheTimeShown()}
        testCorrect={['C']}
        mainPanelFlexDirection={'row'}
        itemLayout={'column'}
        numItems={3}
        Content={({ dimens }) => (
          <Clock
            time={{ hours, minutes }}
            width={Math.min(dimens.width * 0.9, dimens.height * 0.9)}
            interactive={false}
          />
        )}
        renderItems={options.map(({ string, value }) => ({
          value,
          component: <Text variant="WRN700">{string}</Text>
        }))}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question2 = newQuestionContent({
  uid: 'bkS',
  description: 'bkS',
  keywords: ["O'clock", 'Half past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(1).max(12),
    minutes: numberEnum([0, 30])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(1, 12);

    const minutes = getRandomFromArray([0, 30] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatTimeDoesTheClockShow()}
        sentence={
          minutes === 30
            ? translate.ks1AnswerSentences.halfPastAns()
            : translate.ks1AnswerSentences.ansOClock()
        }
        sentenceStyle={{ alignSelf: 'flex-end' }}
        testCorrect={[hours.toString()]}
        Content={({ dimens }) => (
          <Clock
            time={{ hours: hours === 12 ? 0 : hours, minutes }}
            width={Math.min(dimens.width, dimens.height)}
            interactive={false}
          />
        )}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Question3 = newQuestionContent({
  uid: 'bkT',
  description: 'bkT',
  keywords: ["O'clock", 'Half past', 'Analogue clock', 'Time'],
  schema: z.object({
    hours: z.number().int().min(0).max(11),
    minutes: numberEnum([0, 30])
  }),
  simpleGenerator: () => {
    const hours = randomIntegerInclusive(0, 11);

    const minutes = getRandomFromArray([0, 30] as const);

    return { hours, minutes };
  },
  Component: props => {
    const {
      question: { hours, minutes },
      translate
    } = props;

    const translatedTime = convert12hToSpokenString(translate, hours, minutes);

    return (
      <QF40SetTheClockFace
        title={translate.ks1Instructions.dragTheHandsOfClockToShowX(translatedTime)}
        pdfTitle={translate.ks1PDFInstructions.drawHandsOnClockToShowX(translatedTime)}
        testCorrect={isEqual({ hours, minutes })}
        exampleCorrectAnswer={{ hours, minutes }}
        startTime={{ hours: hours === 0 && minutes === 0 ? 6 : 0, minutes: 0 }}
        snapMinutes={5}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OClockAndHalfPast',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
