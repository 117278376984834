import { View } from 'react-native';
import { AssetSvg } from '../../../assets/svg';
import Text from '../../../components/typography/Text';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';

type Time = {
  seconds: number;
  minutes: number;
  hours: number;
};

const DigitalStopwatch = ({ seconds = 0, minutes = 0, hours = 0 }: Time) => {
  if (seconds < 0 || seconds > 59) {
    throw Error(`Seconds should be between 0 and 59`);
  }

  if (minutes < 0 || minutes > 59) {
    throw Error(`Minutes should be between 0 and 59`);
  }

  if (hours < 0 || hours > 99) {
    throw Error(`Hours should be between 0 and 99`);
  }

  const times = [
    { value: hours, suffix: 'h' },
    { value: minutes, suffix: 'm' },
    { value: seconds, suffix: 's' }
  ];

  const displayMode = useContext(DisplayMode);

  return (
    <View>
      <AssetSvg name="DigitalStopwatch" width={displayMode === 'digital' ? 300 : 480} />
      <View
        style={{
          position: 'absolute',
          top: displayMode === 'digital' ? 105 : 180,
          left: displayMode === 'digital' ? 58 : 148
        }}
      >
        <View style={{ flexDirection: 'row', width: 185, justifyContent: 'center' }}>
          {times.map((item, index) => (
            <View key={index}>
              <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 48 : 72 }}>
                {item.value.toString().padStart(2, '0')}
                {item.suffix !== 's' && ':'}
              </Text>
              <Text
                style={{
                  alignSelf: 'center',
                  fontSize: displayMode === 'digital' ? 20 : 30,
                  position: 'absolute',
                  bottom: displayMode === 'digital' ? -20 : -40
                }}
              >
                {item.suffix}
              </Text>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
};

export default DigitalStopwatch;
