import { View, type StyleProp, type ViewStyle, StyleSheet, Platform } from 'react-native';
import { G, Line, Path, Svg, Text as SvgText } from 'react-native-svg';
import { countRange, range } from '../../../../utils/collections';
import { ALGEBRAIC_X, ALGEBRAIC_Y } from '../../../../constants';
import { colors } from '../../../../theme/colors';
import {
  type ElementOrRenderFunction,
  resolveElementOrRenderFunction
} from '../../../../utils/react';
import { type ComponentProps, useContext, forwardRef, useCallback } from 'react';
import { ScaleFactorContext } from '../../../../theme/scaling';
import { DisplayMode } from '../../../../contexts/displayMode';
import Text from '../../../typography/Text';

export type SizingProps = {
  /**
   * Which props we're going to provide to define the size of this grid. The props for each sizing method are:
   *
   * - dimens:
   *   - width (required)
   *   - height (required)
   *   - squareGrid (optional: default false)
   * - gridScale:
   *   - xScale (required)
   *   - yScale (required)
   * - gridDimens:
   *   - gridWidth (required)
   *   - gridHeight (required)
   *
   * This prop defaults to 'dimens'.
   */
  sizingMethod?: 'dimens' | 'gridScale' | 'gridDimens';

  // sizingMethod === 'dimens'
  /** Whether to force the grid to be square. Default: false. */
  squareGrid?: boolean;
  /** Width of available space in pixels. */
  width?: number;
  /** Height of available space in pixels. */
  height?: number;

  // sizingMethod === 'gridScale'
  /** Width of each grid cell. */
  xScale?: number;
  /** Height of each grid cell. */
  yScale?: number;

  // sizingMethod === 'gridDimens'
  /** Width of the grid, excluding all annotations. */
  gridWidth?: number;
  /** Height of the grid, excluding all annotations. */
  gridHeight?: number;

  /** Default: 21.667, or 40 for PDFs */
  fontSize?: number;
};

export type DescriptionProps = {
  /** Length in grid cells. Must be integer. */
  xLength: number;
  /** Height in grid cells. Must be integer. */
  yLength: number;
  /**
   * Where the X axis is positioned in Y. Must be integer between 0 and yLength-1, inclusive. Default: 0.
   * To hide the axis, pass in 'null'.
   */
  xAxis?: number | null;
  /**
   * Where the Y axis is positioned in X. Must be integer between 0 and yLength-1, inclusive. Default: 0.
   * To hide the axis, pass in 'null'.
   */
  yAxis?: number | null;

  /** Whether to hide continuation grid lines on the sides of the grid without axes. Default: false */
  hideContinuationLines?: boolean;
  /** Whether to hide the grid lines altogether (implies hideContinuationLines). Default: false. */
  hideGridLines?: boolean;
  /** Null for no labels. Default: numbers 0 to xLength. */
  xLabels?: string[] | null;
  /** Null for no labels. Default: numbers 0 to yLength. */
  yLabels?: string[] | null;

  /** Null for no label. Default: '𝑥'. */
  xAxisArrowLabel?: string | null;
  /** Null for no label. Default: '𝑦'. */
  yAxisArrowLabel?: string | null;
  /** Null for no label. Default: null. */
  xAxisLabel?: string | null;
  /** Null for no label. Default: null. */
  yAxisLabel?: string | null;
  /**
   * Offset for moving the y-axis label. Positive numbers move label right, negative numbers move label left.
   * Optional prop, defaults to 0
   */
  yAxisLabelOffset?: number;
  /**
   * Label to assign to the top-right Cell.
   * Used to denote the size of each Cell to the user, with arrows. Optional prop, defaults to undefined.
   */
  cellSizeLabel?: string;
  /**
   * Boolean to determine whether to make the grid lines for y-axis labels be darker.
   * Intended to be used for when there are a lot of lines in the y-axis, where only some are labelled.
   * Defaults to false.
   */
  darkGridLinesForYLabels?: boolean;
  /**
   * Whether to add the label in the middle of the cell rather than the
   */
  xLabelCenteredInMiddle?: boolean;
  gridLineWidth?: number;
};

type UnitGridProps = SizingProps &
  DescriptionProps & {
    /** Children. These are given access to coordinate mapping functions, to help them with their positioning. */
    children?: ElementOrRenderFunction<{
      mapX: (x: number) => number;
      mapY: (y: number) => number;
      unmapX: (mappedX: number) => number;
      unmapY: (mappedY: number) => number;
      svgWidth: number;
      svgHeight: number;
      gridLineWidth: number;
      axisLineWidth: number;
    }>;

    onLayout?: ComponentProps<typeof View>['onLayout'];

    /** Additional style. */
    style?: StyleProp<ViewStyle>;
  };

/**
 * Gridlines, axes and a mapping from mathematical numbers to relative coordinates.
 * For this special unit case, each cell is worth 1.
 */
export default forwardRef<View, UnitGridProps>(function UnitGrid(
  {
    children,
    onLayout,
    style,
    xLength,
    yLength,
    xAxis = 0,
    yAxis = 0,
    hideGridLines = false,
    hideContinuationLines = false,
    squareGrid = false,
    xLabels = range(0, xLength).map(it => it.toLocaleString()),
    yLabels = range(0, yLength).map(it => it.toLocaleString()),
    xAxisArrowLabel = ALGEBRAIC_X,
    yAxisArrowLabel = ALGEBRAIC_Y,
    xAxisLabel = null,
    yAxisLabel = null,
    yAxisLabelOffset = 0,
    sizingMethod = 'dimens',
    width,
    height,
    xScale: xScaleProp,
    yScale: yScaleProp,
    gridWidth,
    gridHeight,
    fontSize: labelFontSize,
    cellSizeLabel,
    darkGridLinesForYLabels,
    xLabelCenteredInMiddle,
    gridLineWidth: gridLineWidthProp
  }: UnitGridProps,
  ref
) {
  if (hideGridLines) {
    hideContinuationLines = true;
  }
  const displayMode = useContext(DisplayMode);
  const isPdf = displayMode === 'pdf' || displayMode === 'markscheme';
  labelFontSize = labelFontSize ?? (isPdf ? 40 : 21.667);

  const scaleFactor = useContext(ScaleFactorContext);

  // Some things scale with font size, and are based on a font size of 21.667.
  const fontSizeScaling = labelFontSize / 21.667;
  const arrowLength = 40 * fontSizeScaling;
  const arrowSideWidth = 5 * fontSizeScaling;
  const gridLineFactor = gridLineWidthProp ?? 0.616;
  let gridLineWidth = isPdf
    ? 1.2 * gridLineFactor * fontSizeScaling
    : gridLineFactor * fontSizeScaling;
  // Avoid making gridLineWidth any smaller than the hairlineWidth (i.e. one screen pixel) on native
  // This is a bit of a hack, and seems to only be required because we put our SVG (and entire question) in a big
  // transform: scale.
  gridLineWidth =
    Platform.OS === 'web'
      ? gridLineWidth
      : Math.max(gridLineWidth, StyleSheet.hairlineWidth / scaleFactor);
  const axisLineWidth = 2 * gridLineWidth;
  const labelMargin = 6.5 * fontSizeScaling;
  const axisLabelMargin = 10 * fontSizeScaling;

  // Calculate any extra padding outside the grid in pixels
  // This is either due to the axis having an arrow extending that way,
  const xAxisLeftArrow = xAxis !== null && yAxis !== 0;
  const xAxisRightArrow = xAxis !== null;
  const yAxisBottomArrow = yAxis !== null && xAxis !== 0;
  const yAxisTopArrow = yAxis !== null;
  const gridContinuationLeft = !hideContinuationLines && yAxis !== 0;
  const gridContinuationRight = !hideContinuationLines;
  const gridContinuationBottom = !hideContinuationLines && xAxis !== 0;
  const gridContinuationTop = !hideContinuationLines;

  // Text positioning
  const longestYLabelString = yLabels !== null ? Math.max(...yLabels.map(it => it.length)) : 0;

  const paddingLeft = Math.max(
    gridLineWidth / 2,
    // Maybe y-axis labels
    yAxis === 0
      ? (yLabels !== null ? labelFontSize * longestYLabelString + labelMargin : 0) +
          (yAxisLabel !== null ? labelFontSize + labelMargin : 0)
      : 0,
    // Maybe an arrow on the left
    xAxisLeftArrow ? arrowLength : 0,
    // Maybe an arrow on the top/bottom
    yAxis === 0 ? arrowSideWidth : 0,
    // Maybe some continuation lines
    gridContinuationLeft ? arrowLength / 2 : 0
  );
  const paddingRight = Math.max(
    gridLineWidth / 2,
    // Maybe an arrow on the right
    xAxisRightArrow
      ? arrowLength +
          (xAxisArrowLabel !== null
            ? axisLabelMargin + labelFontSize * xAxisArrowLabel.length
            : axisLineWidth / 2)
      : 0,
    cellSizeLabel ? (isPdf ? 140 : 80) : 0,
    // Maybe some continuation lines
    gridContinuationRight ? arrowLength / 2 : 0
  );
  const paddingBottom = Math.max(
    gridLineWidth / 2,
    // Maybe x-axis labels
    xAxis === 0
      ? (xLabels !== null ? labelFontSize + labelMargin : 0) +
          (xAxisLabel !== null ? labelFontSize * 1.5 + labelMargin : 0)
      : 0,
    // Maybe an arrow on the bottom
    yAxisBottomArrow ? arrowLength : 0,
    // Maybe an arrow on the left/right
    xAxis === 0 ? arrowSideWidth : 0,
    // Maybe some continuation lines
    gridContinuationBottom ? arrowLength / 2 : 0
  );
  const paddingTop = Math.max(
    gridLineWidth / 2,
    // Maybe an arrow on the top
    yAxisTopArrow
      ? arrowLength +
          (yAxisArrowLabel !== null ? axisLabelMargin + labelFontSize : axisLineWidth / 2)
      : 0,
    cellSizeLabel ? (isPdf ? 80 : 40) : 0,
    // Maybe some continuation lines
    gridContinuationTop ? arrowLength / 2 : 0
  );

  let xScale: number;
  let yScale: number;

  switch (sizingMethod) {
    case 'dimens': {
      if (width === undefined || height === undefined) {
        throw new Error('sizingMethod dimens but not all of width, height are defined');
      }

      xScale = (width - paddingLeft - paddingRight) / xLength;
      yScale = (height - paddingBottom - paddingTop) / yLength;

      // If squareGrid, we need to use the smaller of these scales for both.
      if (squareGrid) {
        xScale < yScale ? (yScale = xScale) : (xScale = yScale);
      }
      break;
    }
    case 'gridScale': {
      if (xScaleProp === undefined || yScaleProp === undefined) {
        throw new Error('sizingMethod gridScale but not all of xScale, yScale are defined');
      }
      xScale = xScaleProp;
      yScale = yScaleProp;
      break;
    }
    case 'gridDimens': {
      if (gridWidth === undefined || gridHeight === undefined) {
        throw new Error('sizingMethod gridDimens but not all of gridWidth, gridHeight are defined');
      }
      xScale = gridWidth / xLength;
      yScale = gridHeight / yLength;
      break;
    }
  }

  const xOffset = paddingLeft;
  // Graphics coordinates have Y going the opposite direction
  yScale = -yScale;
  const yOffset = paddingTop + yLength * -yScale;

  const xToCoordinates = useCallback(
    (x: number) => {
      'worklet';
      return xOffset + xScale * x;
    },
    [xOffset, xScale]
  );
  const yToCoordinates = useCallback(
    (y: number) => {
      'worklet';
      return yOffset + yScale * y;
    },
    [yOffset, yScale]
  );
  const xFromCoordinates = useCallback(
    (coordX: number) => {
      'worklet';
      return (coordX - xOffset) / xScale;
    },
    [xOffset, xScale]
  );
  const yFromCoordinates = useCallback(
    (coordY: number) => {
      'worklet';
      return (coordY - yOffset) / yScale;
    },
    [yOffset, yScale]
  );

  const svgWidth = xLength * Math.abs(xScale) + paddingLeft + paddingRight;
  const svgHeight = yLength * Math.abs(yScale) + paddingTop + paddingBottom;

  const cellDimens = Math.abs(xScale);

  /** Whether we show a single number label where the axis cross */
  const sharedOriginLabel =
    xAxis !== null &&
    yAxis !== null &&
    xLabels !== null &&
    yLabels !== null &&
    xLabels[yAxis] === yLabels[xAxis];

  return (
    <View ref={ref} onLayout={onLayout} style={style}>
      {cellSizeLabel && (
        <>
          <View
            style={{
              position: 'absolute',
              alignItems: 'flex-end',
              alignSelf: 'flex-end',
              right: paddingRight
            }}
          >
            <Text
              style={{
                fontSize: labelFontSize,
                alignSelf: 'center',
                lineHeight: isPdf ? 48 : 20
              }}
            >
              {cellSizeLabel}
            </Text>
            <Svg width={cellDimens} height={20}>
              <Path d={'M0,10 L10,5 L10,15 Z'} fill={isPdf ? colors.black : colors.prussianBlue} />
              <Path
                d={`M5,10 L${cellDimens - 5},10`}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={2}
              />
              <Path
                d={`M${cellDimens},10
                    L${cellDimens - 10},5
                    L${cellDimens - 10},15
                    Z`}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            </Svg>
          </View>
          <View
            style={{
              position: 'absolute',
              flexDirection: 'row',
              left: isPdf ? xLength * Math.abs(xScale) + 10 : xLength * Math.abs(xScale),
              height: cellDimens,
              top: paddingTop
            }}
          >
            <Svg width={20} height={cellDimens}>
              <Path d={'M10,0 L5,10 L15,10 Z'} fill={isPdf ? colors.black : colors.prussianBlue} />
              <Path
                d={`M10,5 L10,${cellDimens - 5}`}
                stroke={colors.prussianBlue}
                strokeWidth={2}
              />
              <Path
                d={`M10,${cellDimens}
                    L5,${cellDimens - 10}
                    L15,${cellDimens - 10}
                    Z`}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            </Svg>
            <Text style={{ fontSize: labelFontSize, alignSelf: 'center', lineHeight: 20 }}>
              {cellSizeLabel}
            </Text>
          </View>
        </>
      )}
      <Svg
        width={svgWidth}
        height={svgHeight}
        viewBox={`0 0 ${svgWidth} ${svgHeight}`}
        style={styles.svg}
      >
        {/* Draw the vertical grid lines */}
        {!hideGridLines &&
          countRange(xLength + 1).map(x => (
            <Line
              key={x}
              x1={xToCoordinates(x)}
              x2={xToCoordinates(x)}
              y1={yToCoordinates(0) + (gridContinuationBottom ? arrowLength / 2 : 0)}
              y2={yToCoordinates(yLength) - (gridContinuationTop ? arrowLength / 2 : 0)}
              stroke={isPdf ? colors.black : colors.prussianBlue}
              strokeWidth={gridLineWidth}
            />
          ))}

        {/* Draw the horizontal grid lines */}
        {!hideGridLines &&
          countRange(yLength + 1).map(y => (
            <Line
              key={y}
              y1={yToCoordinates(y)}
              y2={yToCoordinates(y)}
              x1={xToCoordinates(0) - (gridContinuationLeft ? arrowLength / 2 : 0)}
              x2={xToCoordinates(xLength) + (gridContinuationRight ? arrowLength / 2 : 0)}
              stroke={
                darkGridLinesForYLabels && yLabels && yLabels[y] === ''
                  ? colors.greys400
                  : isPdf
                  ? colors.black
                  : colors.prussianBlue
              }
              strokeWidth={gridLineWidth}
            />
          ))}

        {yAxis !== null && (
          <>
            {/* Draw the y-axis with a thicker line */}
            <Line
              x1={xToCoordinates(yAxis)}
              x2={xToCoordinates(yAxis)}
              y1={yToCoordinates(0) + (yAxisBottomArrow ? arrowLength : 0) + gridLineWidth / 2}
              y2={yToCoordinates(yLength) - (yAxisTopArrow ? arrowLength : 0) - gridLineWidth / 2}
              stroke={isPdf ? colors.black : colors.prussianBlue}
              strokeWidth={axisLineWidth}
            />
            {/* Draw the arrow heads */}
            {yAxisTopArrow && (
              <Path
                d={[
                  `M${xToCoordinates(yAxis)},${yToCoordinates(yLength) - arrowLength}`,
                  `m${-arrowSideWidth},${arrowSideWidth}`,
                  `l${arrowSideWidth},${-arrowSideWidth}`,
                  `l${arrowSideWidth},${arrowSideWidth}`
                ].join(' ')}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={axisLineWidth}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            )}
            {yAxisBottomArrow && (
              <Path
                d={[
                  `M${xToCoordinates(yAxis)},${yToCoordinates(0) + arrowLength}`,
                  `m${-arrowSideWidth},${-arrowSideWidth}`,
                  `l${arrowSideWidth},${arrowSideWidth}`,
                  `l${arrowSideWidth},${-arrowSideWidth}`
                ].join(' ')}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={axisLineWidth}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            )}
            {/* Label on the top arrow */}
            {yAxisTopArrow && yAxisArrowLabel !== null && (
              <SvgText
                y={yToCoordinates(yLength) - arrowLength - axisLabelMargin}
                x={xToCoordinates(yAxis)}
                fontSize={labelFontSize}
                fontFamily="White_Rose_Noto-Regular"
                textAnchor="middle"
                alignmentBaseline="bottom"
                fill={isPdf ? colors.black : colors.prussianBlue}
              >
                {yAxisArrowLabel}
              </SvgText>
            )}
          </>
        )}
        {xAxis !== null && (
          <>
            {/* Draw the x-axis with a thicker line */}
            <Line
              y1={yToCoordinates(xAxis)}
              y2={yToCoordinates(xAxis)}
              x1={xToCoordinates(0) - (xAxisLeftArrow ? arrowLength : 0) - gridLineWidth / 2}
              x2={xToCoordinates(xLength) + (xAxisRightArrow ? arrowLength : 0) + gridLineWidth / 2}
              stroke={isPdf ? colors.black : colors.prussianBlue}
              strokeWidth={axisLineWidth}
            />
            {/* Draw the arrow heads */}
            {xAxisRightArrow && (
              <Path
                d={[
                  `M${xToCoordinates(xLength) + arrowLength},${yToCoordinates(xAxis)}`,
                  `m${-arrowSideWidth},${-arrowSideWidth}`,
                  `l${arrowSideWidth},${arrowSideWidth}`,
                  `l${-arrowSideWidth},${arrowSideWidth}`
                ].join(' ')}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={axisLineWidth}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            )}
            {xAxisLeftArrow && (
              <Path
                d={[
                  `M${xToCoordinates(0) - arrowLength},${yToCoordinates(xAxis)}`,
                  `m${arrowSideWidth},${-arrowSideWidth}`,
                  `l${-arrowSideWidth},${arrowSideWidth}`,
                  `l${arrowSideWidth},${arrowSideWidth}`
                ].join(' ')}
                stroke={isPdf ? colors.black : colors.prussianBlue}
                strokeWidth={axisLineWidth}
                fill={isPdf ? colors.black : colors.prussianBlue}
              />
            )}
            {/* Label on the right arrow */}
            {xAxisRightArrow && xAxisArrowLabel !== null && (
              <SvgText
                y={yToCoordinates(xAxis)}
                x={xToCoordinates(xLength) + arrowLength + axisLabelMargin}
                fontSize={labelFontSize}
                fontFamily="White_Rose_Noto-Regular"
                textAnchor="start"
                alignmentBaseline="middle"
                fill={isPdf ? colors.black : colors.prussianBlue}
              >
                {xAxisArrowLabel}
              </SvgText>
            )}
          </>
        )}

        {/* Add the number labels */}
        {/* X axis */}
        {xAxis !== null &&
          xLabels !== null &&
          xLabels.map(
            (label, index) =>
              !(sharedOriginLabel && index === yAxis) && (
                <SvgText
                  key={index}
                  y={yToCoordinates(xAxis) + labelMargin}
                  x={xToCoordinates(index + (xLabelCenteredInMiddle ? 0.5 : 0))}
                  fontSize={labelFontSize}
                  fontFamily="White_Rose_Noto-Regular"
                  textAnchor={'middle'}
                  alignmentBaseline="hanging"
                  fill={isPdf ? colors.black : colors.prussianBlue}
                >
                  {label}
                </SvgText>
              )
          )}
        {/* Y axis */}
        {yAxis !== null &&
          yLabels !== null &&
          yLabels.map(
            (label, index) =>
              !(sharedOriginLabel && index === xAxis) && (
                <SvgText
                  key={index}
                  y={yToCoordinates(index)}
                  x={xToCoordinates(yAxis) - labelMargin}
                  fontSize={labelFontSize}
                  fontFamily="White_Rose_Noto-Regular"
                  textAnchor="end"
                  alignmentBaseline={'middle'}
                  fill={isPdf ? colors.black : colors.prussianBlue}
                >
                  {label}
                </SvgText>
              )
          )}

        {/* Shared origin, in the case where both coordinates are below-left and they agree */}
        {sharedOriginLabel && (
          <SvgText
            y={yToCoordinates(xAxis) + labelMargin}
            x={xToCoordinates(yAxis) - labelMargin}
            fontSize={labelFontSize}
            fontFamily="White_Rose_Noto-Regular"
            textAnchor="end"
            alignmentBaseline="hanging"
            fill={isPdf ? colors.black : colors.prussianBlue}
          >
            {xLabels[yAxis]}
          </SvgText>
        )}

        {/* Add the wordy labels */}
        {yAxis !== null && yAxisLabel !== null && (
          <G
            transform={`translate(${
              xToCoordinates(yAxis) +
              yAxisLabelOffset -
              labelMargin -
              (yLabels !== null ? labelMargin + labelFontSize * longestYLabelString : 0) -
              labelFontSize / 2
            }, ${yToCoordinates(yLength / 2)})`}
          >
            <SvgText
              fontSize={labelFontSize}
              fontFamily="White_Rose_Noto-Regular"
              textAnchor="middle"
              alignmentBaseline="middle"
              transform="rotate(-90)"
              fill={isPdf ? colors.black : colors.prussianBlue}
            >
              {yAxisLabel}
            </SvgText>
          </G>
        )}
        {xAxis !== null && xAxisLabel !== null && (
          <SvgText
            x={xToCoordinates(xLength / 2)}
            y={
              yToCoordinates(xAxis) +
              labelMargin +
              (xLabels !== null ? labelMargin + labelFontSize : 0)
            }
            fontSize={labelFontSize}
            fontFamily="White_Rose_Noto-Regular"
            textAnchor="middle"
            alignmentBaseline="hanging"
            fill={isPdf ? colors.black : colors.prussianBlue}
          >
            {xAxisLabel}
          </SvgText>
        )}
      </Svg>
      <View style={StyleSheet.absoluteFill} pointerEvents="box-none">
        {/* Any passed-in children */}
        {resolveElementOrRenderFunction(children, {
          mapX: xToCoordinates,
          mapY: yToCoordinates,
          unmapX: xFromCoordinates,
          unmapY: yFromCoordinates,
          svgWidth,
          svgHeight,
          gridLineWidth,
          axisLineWidth
        })}
      </View>
    </View>
  );
});

const styles = StyleSheet.create({
  svg: { flexShrink: 0, pointerEvents: 'none', userSelect: 'none' }
});
