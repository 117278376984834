import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import AngleFromLines from '../../../../components/question/representations/AngleFromLines';
import { numberEnum } from '../../../../utils/zod';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import Text from '../../../../components/typography/Text';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { countRange } from '../../../../utils/collections';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, ALGEBRAIC_D } from '../../../../constants';
import { View } from 'react-native';
import { roundToTheNearest } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'az0',
  description: 'az0',
  keywords: ['Angles', 'Estimate', 'Acute', 'Obtuse'],
  schema: z.object({
    angle: z.number().int().min(35).max(145),
    difference: numberEnum([20, -20, 30, -30])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(35, 145);
    const difference = getRandomFromArray([20, -20, 30, -30] as const);
    return {
      angle,
      difference
    };
  },
  Component: ({ question, translate }) => {
    const { angle, difference } = question;
    const answer = 5 * Math.round(angle / 5);

    const wrongAns = answer + difference;

    const selectables = shuffle(
      [
        {
          text: translate.units.numberOfDegrees(answer),
          value: 'A'
        },
        {
          text: translate.units.numberOfDegrees(wrongAns),
          value: 'B'
        }
      ],
      {
        random: seededRandom(question)
      }
    );
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectMostAppropriateEstimateForTheSizeOfTheAngle()}
        pdfTitle={translate.instructions.selectMostAppropriateEstimateForTheSizeOfTheAnglePDF()}
        Content={({ dimens }) => (
          <AngleFromLines
            degrees={[90 - angle, 90]}
            dimens={{ height: dimens.height * 0.7, width: dimens.width }}
          />
        )}
        numItems={2}
        renderItems={selectables.map(({ text, value }) => ({
          component: <Text variant="WRN700">{text}</Text>,
          value
        }))}
        innerContainerStyle={{ gap: 64 }}
        itemLayout="row"
        testCorrect={['A']}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

// Question2 exported to Q aDZ
const Question2 = newQuestionContent({
  uid: 'az1',
  description: 'az1',
  keywords: ['Angles', 'Estimate', 'Reflex'],
  schema: z.object({
    angle: z.number().int().min(185).max(318),
    difference: numberEnum([20, -20, 30, -30])
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(185, 318);
    const difference = getRandomFromArray([20, -20, 30, -30] as const);
    return {
      angle,
      difference
    };
  },
  Component: ({ question, translate }) => {
    const { angle, difference } = question;
    const answer = 5 * Math.round(angle / 5);

    const wrongAns = answer + difference;

    const selectables = shuffle(
      [
        {
          text: translate.units.numberOfDegrees(answer),
          value: 'A'
        },
        {
          text: translate.units.numberOfDegrees(wrongAns),
          value: 'B'
        }
      ],
      {
        random: seededRandom(question)
      }
    );
    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectMostAppropriateEstimateForTheSizeOfTheAngle()}
        pdfTitle={translate.instructions.selectMostAppropriateEstimateForTheSizeOfTheAnglePDF()}
        Content={({ dimens }) => (
          <AngleFromLines
            degrees={[-90, angle - 90]}
            dimens={{ height: dimens.height * 0.7, width: dimens.width }}
          />
        )}
        numItems={2}
        renderItems={selectables.map(({ text, value }) => ({
          component: <Text variant="WRN700">{text}</Text>,
          value
        }))}
        itemLayout="row"
        testCorrect={['A']}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});
export const az1 = Question2;

// Question3 exported to Q aD0
const Question3 = newQuestionContent({
  uid: 'az2',
  description: 'az2',
  keywords: ['Angles', 'Estimate', 'Right angles'],
  schema: z.object({
    angle: z
      .number()
      .int()
      .min(35)
      .max(55)
      .refine(x => x !== 45, 'The angle should not be 45deg'),
    difference: z.number().int().min(10).max(15)
  }),
  simpleGenerator: () => {
    const angle = randomIntegerInclusive(35, 55, { constraint: x => x !== 45 });
    const difference = randomIntegerInclusive(10, 15);

    return {
      angle,
      difference
    };
  },
  Component: ({ question, translate }) => {
    const { angle, difference } = question;

    const wrongAns = angle > 45 ? angle - difference : angle + difference;

    const selectables = shuffle(
      [
        {
          text: translate.units.numberOfDegrees(angle),
          value: 'A'
        },
        {
          text: translate.units.numberOfDegrees(wrongAns),
          value: 'B'
        }
      ],
      {
        random: seededRandom(question)
      }
    );
    return (
      <QF11SelectImagesUpTo4WithContent
        title={`${translate.instructions.dashedLineCutsTheRightAnglesInHalf()}<br/>${translate.instructions.selectMostAppropriateEstimateForTheSizeOfTheAngle()}`}
        pdfTitle={`${translate.instructions.dashedLineCutsTheRightAnglesInHalf()}<br/>${translate.instructions.selectMostAppropriateEstimateForTheSizeOfTheAnglePDF()}`}
        Content={({ dimens }) => (
          <AngleFromLines
            degrees={[90 - angle, 90]}
            dimens={{ height: dimens.height * 0.7, width: dimens.width }}
            showGuide
          />
        )}
        numItems={2}
        renderItems={selectables.map(({ text, value }) => ({
          component: <Text variant="WRN700">{text}</Text>,
          value
        }))}
        itemLayout="row"
        testCorrect={['A']}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});
export const az2 = Question3;

const Question4 = newQuestionContent({
  uid: 'az3',
  description: 'az3',
  keywords: ['Angles', 'Estimate'],
  schema: z.object({
    acute1: z.number().int().min(1).max(41),
    acute2: z.number().int().min(50).max(85),
    obtuse: z.number().int().min(95).max(130),
    reflex: z.number().int().min(140).max(175).multipleOf(5),
    showGuides: z.array(z.boolean()).length(3)
  }),
  simpleGenerator: () => {
    const acute1 = randomIntegerInclusive(1, 41);
    const acute2 = randomIntegerInclusive(50, 85);
    const obtuse = randomIntegerInclusive(95, 130);
    const reflex = randomIntegerInclusiveStep(140, 175, 5);
    const showGuides = countRange(3).map(_ => getRandomBoolean());

    return { acute1, acute2, obtuse, reflex, showGuides };
  },
  Component: props => {
    const {
      question: { acute1, acute2, obtuse, reflex, showGuides },
      translate,
      displayMode
    } = props;

    const acute1estimate = 5 * Math.round(acute1 / 5);
    const acute2estimate = 5 * Math.round(acute2 / 5);
    const obtuseestimate = 5 * Math.round(obtuse / 5);

    const random = { random: seededRandom(props.question) };

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 150 : 250, alignItems: 'flex-end' }}>
              <AngleFromLines
                degrees={[90 - acute1, 90]}
                lineLength={150}
                showGuide={showGuides[0]}
              />
            </View>
          ),
          correctAnswer: translate.units.numberOfDegrees(acute1estimate)
        },
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 150 : 250, alignItems: 'flex-end' }}>
              <AngleFromLines
                degrees={[90 - acute2, 90]}
                lineLength={150}
                showGuide={showGuides[1]}
              />
            </View>
          ),
          correctAnswer: translate.units.numberOfDegrees(acute2estimate)
        },
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 150 : 250, alignItems: 'flex-end' }}>
              <AngleFromLines
                degrees={[-90, obtuse - 90]}
                lineLength={150}
                showGuide={showGuides[2]}
              />
            </View>
          ),
          correctAnswer: translate.units.numberOfDegrees(obtuseestimate)
        }
      ],
      random
    );

    const items = shuffle(
      [
        ...statements.map(({ correctAnswer }) => correctAnswer),
        translate.units.numberOfDegrees(reflex)
      ],
      random
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchEachAngleToAnAppropriateEstimateOfItsSize()}
        pdfTitle={translate.instructions.useCardsToMatchEachAngleToAnAppropriateEstimateOfItsSize()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        mainPanelStyle={{ rowGap: 8 }}
        useArrows={false}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question4v2 = newQuestionContent({
  uid: 'az32',
  description: 'az3',
  keywords: ['Angles', 'Estimate'],
  schema: z.object({
    acute: z.number().int().min(1).max(85),
    obtuse: z.number().int().min(95).max(130),
    reflex: z.number().int().min(140).max(175).multipleOf(5),
    showGuides: z.array(z.boolean()).length(2)
  }),
  simpleGenerator: () => {
    const acute = randomIntegerInclusive(1, 85);
    const obtuse = randomIntegerInclusive(95, 130);
    const reflex = randomIntegerInclusiveStep(140, 175, 5);
    const showGuides = countRange(2).map(_ => getRandomBoolean());

    return { acute, obtuse, reflex, showGuides };
  },
  Component: props => {
    const {
      question: { acute, obtuse, reflex, showGuides },
      translate,
      displayMode
    } = props;

    const acuteEstimate = 5 * Math.round(acute / 5);
    const obtuseEstimate = 5 * Math.round(obtuse / 5);

    const random = { random: seededRandom(props.question) };

    const statements = shuffle(
      [
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 150 : 250, alignItems: 'flex-end' }}>
              <AngleFromLines
                degrees={[90 - acute, 90]}
                lineLength={150}
                showGuide={showGuides[0]}
              />
            </View>
          ),
          correctAnswer: translate.units.numberOfDegrees(acuteEstimate)
        },
        {
          lhsComponent: (
            <View style={{ width: displayMode === 'digital' ? 150 : 250, alignItems: 'flex-end' }}>
              <AngleFromLines
                degrees={[-90, obtuse - 90]}
                lineLength={150}
                showGuide={showGuides[1]}
              />
            </View>
          ),
          correctAnswer: translate.units.numberOfDegrees(obtuseEstimate)
        }
      ],
      random
    );

    const items = shuffle(
      [
        ...statements.map(({ correctAnswer }) => correctAnswer),
        translate.units.numberOfDegrees(reflex)
      ],
      random
    );

    return (
      <QF6DragMatchStatements
        title={translate.instructions.dragCardsToMatchEachAngleToAnAppropriateEstimateOfItsSize()}
        pdfTitle={translate.instructions.useCardsToMatchEachAngleToAnAppropriateEstimateOfItsSize()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        mainPanelStyle={{ rowGap: 8 }}
        useArrows={false}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question5 = newQuestionContent({
  uid: 'az4',
  description: 'az4',
  keywords: ['Angles', 'Estimate'],
  schema: z.object({
    correctAngleSize: z.number().int().min(46).max(315),
    incorrectAngleOffsetA: numberEnum([-20, 20]),
    incorrectAngleOffsetB: numberEnum([-30, 30]),
    incorrectAngleOffsetC: numberEnum([-40, 40])
  }),
  simpleGenerator: () => {
    const correctAngleSize = randomIntegerInclusive(46, 315);

    const incorrectAngleOffsetA = getRandomFromArray([-20, 20] as const);

    const incorrectAngleOffsetB = getRandomFromArray([-30, 30] as const);

    const incorrectAngleOffsetC = getRandomFromArray([-40, 40] as const);

    return {
      correctAngleSize,
      incorrectAngleOffsetA,
      incorrectAngleOffsetB,
      incorrectAngleOffsetC
    };
  },
  Component: props => {
    const {
      question: {
        correctAngleSize,
        incorrectAngleOffsetA,
        incorrectAngleOffsetB,
        incorrectAngleOffsetC
      },
      translate
    } = props;

    const correctRoundedAngle = roundToTheNearest(correctAngleSize, 5);

    const incorrectAngleA = correctRoundedAngle + incorrectAngleOffsetA;

    const incorrectAngleB = correctRoundedAngle + incorrectAngleOffsetB;

    const incorrectAngleC = correctRoundedAngle + incorrectAngleOffsetC;

    // Randomly order these options
    const options = shuffle(
      [
        { degrees: correctRoundedAngle, value: 'correctAngle', isCorrect: true },
        { degrees: incorrectAngleA, value: 'incorrectAngleA', isCorrect: false },
        { degrees: incorrectAngleB, value: 'incorrectAngleB', isCorrect: false },
        { degrees: incorrectAngleC, value: 'incorrectAngleC', isCorrect: false }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4
        title={translate.instructions.selectAngleThatIsApproxNumDegrees(correctAngleSize)}
        pdfTitle={translate.instructions.circleAngleThatIsApproxNumDegrees(correctAngleSize)}
        testCorrect={options.filter(shape => shape.isCorrect).map(shape => shape.value)}
        numItems={4}
        renderItems={({ dimens }) =>
          options.map(({ degrees, value }) => {
            return {
              component: (
                <AngleFromLines
                  dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                  degrees={[90, degrees + 90]}
                  strokeWidth={3}
                />
              ),
              value
            };
          })
        }
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question6 = newQuestionContent({
  uid: 'az5',
  description: 'az5',
  keywords: ['Angles', 'Estimate'],
  schema: z.object({
    shape: z.enum(['quadrilateral_1', 'quadrilateral_2', 'quadrilateral_3', 'quadrilateral_4']),
    angle: z.number().int().min(35).max(230),
    answer: z.enum([ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, ALGEBRAIC_D])
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'quadrilateral_1',
      'quadrilateral_2',
      'quadrilateral_3',
      'quadrilateral_4'
    ] as const);

    let angle = 0;
    let answer = ALGEBRAIC_A as ALGEBRAIC_A | ALGEBRAIC_B | ALGEBRAIC_C | ALGEBRAIC_D;
    switch (shape) {
      case 'quadrilateral_1': {
        angle = randomIntegerInclusive(35, 55);
        answer = ALGEBRAIC_A;
        break;
      }
      case 'quadrilateral_2': {
        answer = getRandomFromArray([ALGEBRAIC_B, ALGEBRAIC_D] as const);
        angle =
          answer === ALGEBRAIC_B
            ? randomIntegerInclusive(210, 230)
            : randomIntegerInclusive(95, 120);
        break;
      }
      case 'quadrilateral_3': {
        answer = ALGEBRAIC_A;
        angle = randomIntegerInclusive(125, 145);
        break;
      }
      case 'quadrilateral_4': {
        answer = ALGEBRAIC_D;
        angle = randomIntegerInclusive(125, 145);
        break;
      }
    }

    return { shape, angle, answer };
  },
  Component: ({ question, translate }) => {
    const { shape, angle, answer } = question;

    const labels = [ALGEBRAIC_A, ALGEBRAIC_B, ALGEBRAIC_C, ALGEBRAIC_D];

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.selectTheAngleThatIsApproxXDeg(angle)}
        pdfTitle={translate.instructions.circleTheAngleThatIsApproxXDeg(angle)}
        Content={({ dimens }) => (
          <LabelledShape dimens={dimens} shapeName={shape} angleLabels={labels} hasMinLabelWidth />
        )}
        numItems={4}
        itemLayout="row"
        renderItems={labels.map(label => ({
          component: <Text variant="WRN700">{label}</Text>,
          value: label
        }))}
        innerContainerStyle={{ gap: 32 }}
        testCorrect={[answer]}
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateAngles',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  archivedQuestionTypes: [Question4],
  unpublishedQuestionTypes: []
});
export default SmallStep;
