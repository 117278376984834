import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomFromArray, randomIntegerInclusive } from '../../../../utils/random';
import QF18DragIntoABox from '../../../../components/question/questionFormats/QF18DragIntoABox';
import BaseLayoutPDF from '../../../../components/molecules/BaseLayoutPDF';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { StyleSheet, View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';
import {
  arraysHaveSameContentsUnordered,
  filledArray,
  nestedArraysHaveSameContentsUnordered,
  range
} from '../../../../utils/collections';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import {
  BarModelInteractiveWithState,
  shadedAtLeastOneCell
} from '../../../../components/question/representations/BarModelInteractive';
import { CreateArrayOfObjectsWithState } from '../../../../components/question/representations/CreateArrayOfObjects';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bag',
  description: 'bag',
  keywords: ['Count', 'Group'],
  schema: z.object({
    number: z.number().int().min(1).max(8),
    object: z.enum(['Ball', 'Banana', 'Car', 'Flower', 'Orange', 'Pear', 'Red apple', 'Sweet'])
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(1, 8);

    const object = getRandomFromArray([
      'Ball',
      'Banana',
      'Car',
      'Flower',
      'Orange',
      'Pear',
      'Red apple',
      'Sweet'
    ] as const);

    return { number, object };
  },
  Component: ({ question: { number, object }, translate, displayMode }) => {
    const objectSvgPaths = {
      Ball: '3D_shapes_full_colors/Spheres/Sphere_red',
      Flower: 'Flowers/Flower_Purple',
      Car: 'Car',
      Banana: 'Array_objects/Banana',
      Orange: 'Array_objects/Orange',
      Pear: 'Array_objects/Pear',
      'Red apple': 'Array_objects/AppleRed',
      Sweet: 'Base_Ten/Sweets1'
    } as const;

    const objectString = (() => {
      switch (object) {
        case 'Ball':
          return number === 1 ? translate.objects.Ball() : translate.objects.Balls();
        case 'Banana':
          return number === 1 ? translate.objects.Banana() : translate.objects.Bananas();
        case 'Car':
          return number === 1 ? translate.objects.Car() : translate.objects.Cars();
        case 'Flower':
          return number === 1 ? translate.objects.Flower() : translate.objects.Flowers();
        case 'Orange':
          return number === 1 ? translate.objects.Orange() : translate.objects.Oranges();
        case 'Pear':
          return number === 1 ? translate.objects.Pear() : translate.objects.Pears();
        case 'Red apple':
          return number === 1 ? translate.objects.Apple() : translate.objects.Apples();
        case 'Sweet':
          return number === 1 ? translate.objects.Sweet() : translate.objects.Sweets();
      }
    })();

    return displayMode === 'digital' ? (
      <QF18DragIntoABox
        title={translate.ks1Instructions.dragNumObjectIntoTheBox(number, objectString)}
        testCorrect={number}
        draggablePath={objectSvgPaths[object]}
        numberOfDraggables={9}
        moveOrCopy="move"
        hideDraggableCardBackground={false}
      />
    ) : (
      // This Q requires a fairly custom PDF version below, whereby the user has to circle the correct number of items, rather than
      // drawing the correct number of items, as most other draggable-to-PDF Qs tend to be.
      <BaseLayoutPDF
        title={translate.ks1PDFInstructions.circleNumberObject(number, objectString)}
        mainPanelContents={
          <MeasureView>
            {dimens => (
              <View
                style={[
                  dimens,
                  {
                    flex: 1,
                    justifyContent: 'center'
                  }
                ]}
              >
                {
                  <View
                    style={[
                      StyleSheet.absoluteFill,
                      {
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }
                    ]}
                  >
                    {range(1, 9).map(num => (
                      <View
                        key={num}
                        style={[
                          { padding: 20 },
                          displayMode === 'markscheme' &&
                            num <= number && {
                              borderColor: 'black',
                              borderWidth: 4,
                              borderRadius: 72
                            }
                        ]}
                      >
                        <AssetSvg name={objectSvgPaths[object]} width={80} height={80} />
                      </View>
                    ))}
                  </View>
                }
              </View>
            )}
          </MeasureView>
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bah',
  description: 'bah',
  keywords: ['Count'],
  schema: z
    .object({
      cells: z.number().int().min(4).max(10),
      cellsShaded: z.number().int().min(2).max(9)
    })
    .refine(val => val.cells > val.cellsShaded, 'cells must be greater than cellsShaded'),
  simpleGenerator: () => {
    const cells = randomIntegerInclusive(4, 10);
    const cellsShaded = randomIntegerInclusive(2, cells - 1);

    return { cells, cellsShaded };
  },
  Component: ({ question, translate, displayMode }) => {
    const { cells, cellsShaded } = question;

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.tapToColourXSquares(cellsShaded)}
        pdfTitle={translate.ks1PDFInstructions.colourXSquares(cellsShaded)}
        Content={({ dimens }) => (
          <BarModelInteractiveWithState
            numberOfRows={1}
            numberOfCols={cells}
            tableHeight={dimens.height}
            tableWidth={dimens.width}
            isSquareGrid
            id={'number grid interactive'}
            testComplete={shadedAtLeastOneCell}
            defaultState={
              displayMode !== 'markscheme'
                ? [filledArray(false, cells)]
                : [[...filledArray(true, cellsShaded), ...filledArray(false, cells - cellsShaded)]]
            }
            testCorrect={userAnswer =>
              nestedArraysHaveSameContentsUnordered(userAnswer, [
                [...filledArray(true, cellsShaded), ...filledArray(false, cells - cellsShaded)]
              ])
            }
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bai',
  description: 'bai',
  keywords: ['Count'],
  schema: z
    .object({
      circles: z.number().int().min(1).max(9),
      rows: z.number().int().min(2).max(5),
      cols: z.number().int().min(2).max(5)
    })
    .refine(val => val.circles < val.cols * val.rows),
  simpleGenerator: () => {
    const circles = randomIntegerInclusive(1, 9);

    const arrayArrangements = getRandomFromArray(
      [
        [2, 2],
        [2, 3],
        [2, 4],
        [2, 5],
        [4, 2],
        [3, 2],
        [3, 3],
        [5, 2]
      ].filter(([cols, rows]) => rows * cols > circles)
    ) as number[];

    const cols = arrayArrangements[0];
    const rows = arrayArrangements[1];

    return { circles, rows, cols };
  },
  Component: props => {
    const {
      question: { circles, rows, cols },
      translate,
      displayMode
    } = props;

    const answer = [...filledArray(true, circles), ...filledArray(false, rows * cols - circles)];

    const markscheme = filledArray(0, rows).map((_, rowIndex) =>
      filledArray(0, cols).map((_, colIndex) => answer[rowIndex * cols + colIndex] || false)
    );

    const defaultState = filledArray(0, rows).map(() => filledArray(0, cols).map(() => false));

    return (
      <QF3Content
        title={translate.ks1Instructions.tapToColourXCircles(circles)}
        pdfTitle={translate.ks1PDFInstructions.colourXCircles(circles)}
        Content={
          <CreateArrayOfObjectsWithState
            dimens={{
              width: 100 * cols,
              height: 100 * rows
            }}
            numberOfRows={rows}
            numberOfCols={cols}
            id={'array of objects'}
            showArrayOnPDF
            testComplete={shadedAtLeastOneCell}
            defaultState={displayMode === 'markscheme' ? markscheme : defaultState}
            testCorrect={userAnswer => arraysHaveSameContentsUnordered(answer, userAnswer.flat())}
          />
        }
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountObjectsFromALargerGroup',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
