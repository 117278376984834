import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample
} from '../../../../utils/random';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { numberEnum } from '../../../../utils/zod';
import {
  countRange,
  filledArray,
  sortNumberArray,
  arrayHasNoDuplicates,
  sumNumberArray
} from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import { View } from 'react-native';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import { displayMoney, totalPenceToPoundsAndPence } from '../../../../utils/money';
import { isEqual } from '../../../../utils/matchers';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { getSumCombinations } from '../../../../utils/math';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bfi',
  description: 'bfi',
  keywords: ['Counters', 'Money', 'Coins', 'Unitise'],
  schema: z.object({
    variant: z.enum(['number', 'coin']),
    answerIndexes: z.number().int().min(0).max(4).array().length(2),
    options: numberEnum([1, 2, 5, 10]).array().refine(arrayHasNoDuplicates)
  }),
  simpleGenerator: () => {
    const variant = getRandomFromArray(['number', 'coin'] as const);
    const answerIndexes = randomUniqueIntegersInclusive(0, variant === 'coin' ? 2 : 3, 2);
    const options = getRandomSubArrayFromArray([1, 2, 5, 10] as const, variant === 'coin' ? 3 : 4);

    return { variant, answerIndexes, options };
  },
  Component: props => {
    const {
      question: { variant, answerIndexes, options },
      translate
    } = props;

    const lhsOptions = options.filter((_, i) => answerIndexes.includes(i));

    const arrangements = (variant === 'coin' ? options : lhsOptions).map(number =>
      number > 5 ? filledArray(filledArray(true, 2), 5) : filledArray(filledArray(true, 1), number)
    );
    // always scale the same. To if there is 5 rows
    const scale = 6;
    const lhsDimens = { width: 180, height: 180 };
    const optionDimens = { width: 120, height: 120 };

    const coins = displayMoney(
      lhsOptions.map(number => totalPenceToPoundsAndPence(number)[0]),
      lhsDimens.width,
      lhsDimens.height,
      true
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheCorrectValueToTheCounters()}
        pdfTitle={translate.ks1PDFInstructions.matchTheCountersToTheCorrectValues()}
        items={options.map((option, i) => ({
          value: option,
          component:
            variant === 'number' ? (
              <Text variant="WRN700">{option.toLocaleString()}</Text>
            ) : (
              <CounterBoxArrangement
                scale={scale}
                counters={option}
                color={'grey'}
                dimens={optionDimens}
                arrangement={arrangements[i]}
                isCircle
                counterGap={2}
              />
            )
        }))}
        statementStyle={{ justifyContent: 'center' }}
        statements={lhsOptions.map((x, i) => ({
          lhsComponent: (
            <>
              <View
                style={{
                  ...lhsDimens,
                  flexDirection: 'row',
                  gap: 10,
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {variant === 'number' ? (
                  <CounterBoxArrangement
                    scale={scale}
                    counters={x}
                    color={'grey'}
                    dimens={lhsDimens}
                    arrangement={arrangements[i]}
                    isCircle
                    counterGap={6}
                  />
                ) : (
                  coins[i] ?? <></>
                )}
              </View>
              <Text variant="WRN400">{'='}</Text>
            </>
          ),
          correctAnswer: x
        }))}
        itemVariant={variant === 'coin' ? 'tallRectangle' : 'rectangle'}
        actionPanelVariant="endWide"
        useArrows={false}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bfj',
  description: 'bfj',
  keywords: ['Counters', 'Money', 'Unitise', 'Count', 'Multiply', 'Add'],
  schema: z.object({
    counterValues: numberEnum([1, 2, 5, 10])
      .array()
      .refine(val => sumNumberArray(val) <= 50 && val.length <= 12)
  }),
  simpleGenerator: () => {
    const values = getRandomFromArray([1, 2, 5, 10, 'mixed'] as const);
    const maxCounters = values === 10 || values === 'mixed' ? 4 : 10;
    const numOfCounters = randomIntegerInclusive(2, maxCounters);

    const counterValues =
      values === 'mixed'
        ? rejectionSample(
            () =>
              sortNumberArray(
                countRange(numOfCounters).map(_ => getRandomFromArray([1, 2, 5, 10] as const)),
                'descending'
              ),
            val => sumNumberArray(val) <= 25
          )
        : filledArray(values, numOfCounters);

    return { counterValues };
  },
  Component: props => {
    const {
      question: { counterValues },
      translate
    } = props;

    const arrangements = counterValues.map(number =>
      number > 5 ? filledArray(filledArray(true, 2), 5) : filledArray(filledArray(true, 1), number)
    );
    // always scale the same. To if there is 5 rows
    const scale = 5.5;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsTheTotalValueOfTheCounters()}
        Content={({ dimens }) => {
          const widthHeight = Math.min(
            dimens.height / Math.ceil(counterValues.length / 5),
            dimens.width / Math.min(5.5, counterValues.length + 0.5)
          );
          return (
            <View
              style={{
                flexDirection: 'row',
                gap: 10,
                flexWrap: 'wrap',
                justifyContent: 'center',
                // force it to have only 5 on a row
                width: widthHeight * 6
              }}
            >
              {counterValues.map((x, i) => (
                <CounterBoxArrangement
                  key={i}
                  scale={scale}
                  counters={x}
                  color={'grey'}
                  dimens={{
                    height: widthHeight,
                    width: widthHeight
                  }}
                  counterGap={5}
                  arrangement={arrangements[i]}
                  isCircle
                />
              ))}
            </View>
          );
        }}
        sentence={'<ans/>'}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={900}
        testCorrect={[sumNumberArray(counterValues).toString()]}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bfk',
  description: 'bfk',
  keywords: ['Counters', 'Money', 'Unitise', 'Count', 'Multiply', 'Add'],
  schema: z
    .object({
      total: z
        .number()
        .int()
        .min(3)
        .max(25)
        .refine(x => ![1, 2, 5, 10].includes(x)),
      options: numberEnum([1, 2, 5, 10]).array().length(9)
    })
    .refine(
      val => getSumCombinations(val.options, val.total).length > 0,
      'there is at least one valid answer'
    ),
  simpleGenerator: () => {
    const total = randomIntegerInclusive(3, 25, { constraint: x => ![1, 2, 5, 10].includes(x) });
    const options = rejectionSample(
      () => [
        ...countRange(5).map(_ => getRandomFromArray([1, 2, 5, 10] as const)),
        1 as const,
        2 as const,
        5 as const,
        10 as const
      ],
      val => getSumCombinations(val, total).length > 0
    );

    return { options, total };
  },
  Component: props => {
    const {
      question: { options, total },
      translate
    } = props;

    const arrangements = options.map(number =>
      number > 5 ? filledArray(filledArray(true, 2), 5) : filledArray(filledArray(true, 1), number)
    );
    // Always scale to minimum size (when there are 5 rows)
    const scale = 5.5;
    const items = options.map((x, i) => ({
      value: i,
      total: x,
      component: (
        <CounterBoxArrangement
          key={i}
          scale={scale}
          counters={x}
          color={'grey'}
          dimens={{
            height: 130,
            width: 130
          }}
          arrangement={arrangements[i]}
          isCircle
        />
      )
    }));

    const combination = getSumCombinations(options, total);
    const markSchemeAnswer: number[] = [];
    combination.forEach(value => {
      const option = items.filter(
        item => item.total === value && !markSchemeAnswer.includes(item.value)
      );
      markSchemeAnswer.push(option[0].value);
    });

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectCountersThatHaveTotalValueOfX(total)}
        items={items}
        style={{ height: 150 }}
        testCorrect={userAnswer => {
          const userAnswerSum = sumNumberArray(
            items.filter(val => userAnswer.includes(val.value)).map(val => val.total)
          );
          return isEqual(total)(userAnswerSum);
        }}
        multiSelect
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answerToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Unitising',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
