import { StyleProp, StyleSheet, ViewStyle } from 'react-native';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { arraysHaveSameContentsUnordered } from 'common/src/utils/collections';
import { NumberGridInteractiveWithState } from '../representations/NumberGridInteractive';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { View } from 'react-native';
import { Selectable } from '../../atoms/Selectable';
import { SetState } from '../../../utils/react';
import { withStateHOC } from '../../../stateTree';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import TextStructure from '../../molecules/TextStructure';
import { MeasureView } from '../../atoms/MeasureView';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { useI18nContext } from '../../../i18n/i18n-react';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question.
   */
  title: string;
  pdfTitle?: string;
  /**
   * The first number to show on the hundreds square.
   */
  startNumber: number;
  /**
   * The last number to show on the hundreds square.
   */
  finishNumber: number;
  /**
   * The items to show. Each item will be put in a circle selectable in a row below the number grid.
   */
  items: number[];
  /**
   * The numbers expected to be highlighted as true on the row of circle selectables below the number grid.
   */
  testCorrect: number[];
  /**
   * The numbers to initially highlight on the number grid.
   * These are shaded with a different color (blue) which cannot be removed.
   * Preshaded cells are still interactive: they can still be shaded by the user,
   * in which case they show both the user color (orange) and the preshaded color (blue).
   */
  preshaded?: number[];
  /**
   * Sentence to be displayed between the grid and selectables.
   * Optional prop, defaults to undefined.
   */
  sentence?: string;
  /** PDF Question Height */
  questionHeight?: number;
  /**
   * Optional custom mark scheme answer
   * Note: answerText defaults to use translation: numberGridDoesNotNeedToBeShaded
   */
  customMarkSchemeAnswer?: { answersToDisplay?: number[]; answerText?: string };
  /**
   * Whether to allow multiselect or not. Defaults to true
   */
  multiSelect?: boolean;
};

export default function QF31aInteractiveNumberGridAndSelectables({
  title,
  pdfTitle,
  startNumber,
  finishNumber,
  items,
  testCorrect,
  preshaded = [],
  sentence,
  questionHeight,
  customMarkSchemeAnswer,
  multiSelect = true,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);
  const translate = useI18nContext().LL;

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    // Use custom mark scheme answer if available
    const markSchemeAnswer = customMarkSchemeAnswer?.answersToDisplay ?? testCorrect;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <NumberGridInteractiveWithState
                  id="numbergrid"
                  startNumber={startNumber}
                  finishNumber={finishNumber}
                  preshaded={preshaded}
                  dimens={dimens}
                />
              )}
            </MeasureView>

            {sentence && (
              <TextStructure textVariant="WRN400" style={{ paddingTop: 50 }} sentence={sentence} />
            )}
            <NumberSelectablesWithState
              id="numberselect"
              items={items}
              defaultState={displayMode === 'markscheme' ? markSchemeAnswer : undefined}
            />
            {displayMode === 'markscheme' &&
              renderMarkSchemeProp(
                customMarkSchemeAnswer?.answerText ??
                  translate.markScheme.numberGridDoesNotNeedToBeShaded()
              )}
          </>
        }
        questionHeight={questionHeight}
        {...props}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <>
          <MeasureView>
            {dimens => (
              <NumberGridInteractiveWithState
                id="numbergrid"
                startNumber={startNumber}
                finishNumber={finishNumber}
                preshaded={preshaded}
                dimens={dimens}
                // Override testComplete as we aren't marking the number grid in this QF
                testComplete={() => true}
                multiSelect={multiSelect}
              />
            )}
          </MeasureView>

          {sentence && (
            <TextStructure
              textVariant="WRN400"
              textStyle={{ paddingVertical: 20 }}
              sentence={sentence}
            />
          )}
          <NumberSelectablesWithState
            id="numberselect"
            items={items}
            testCorrect={answer => arraysHaveSameContentsUnordered(testCorrect, answer)}
            style={{ paddingTop: sentence ? 0 : undefined }}
          />
        </>
      }
      {...props}
    />
  );
}

const styles = StyleSheet.create({
  selectableContainer: {
    flexDirection: 'row',
    paddingTop: 40,
    gap: 16
  }
});

/** Circle multiselect selectables in a row. */
function NumberSelectables({
  items,
  state,
  setState,
  style
}: {
  items: number[];
  state: number[];
  setState: SetState<number[] | null>;
  style?: StyleProp<ViewStyle>;
}) {
  return (
    <View style={[styles.selectableContainer, style]}>
      {items.map((item, i) => (
        <Selectable
          key={i}
          variant="circle"
          textVariant="WRN700"
          textSizeAutoScaleGroup={'numbers'}
          selected={state?.includes(item)}
          toggleSelected={() => {
            state.includes(item)
              ? setState(state.filter(answerValue => answerValue !== item))
              : setState([...state, item]);
          }}
        >
          {item.toString()}
        </Selectable>
      ))}
    </View>
  );
}

/** See {@link NumberSelectables}. */
const NumberSelectablesWithState = withStateHOC(NumberSelectables, {
  defaults: { testComplete: ans => ans.length > 0, defaultState: [] }
});
