import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { getRandomBoolean, getRandomFromArray } from '../../../../utils/random';
import { bfO } from '../../../Year 2/Autumn/PlaceValue/1NumbersTo20';
import { createCompleteSentenceQuestion, createShownNumberQuestion } from './3Understand1112And13';

////
// Questions
////

const Question1 = createShownNumberQuestion({ min: 14, max: 16 }, 'bb6');

const Question2 = createCompleteSentenceQuestion({ min: 14, max: 16 }, 'bb7');

const Question3 = {
  ...bfO,
  uid: 'bb8',
  description: 'bb8' as const,
  schema: z.object({
    number: z.number().int().min(14).max(16),
    numOrWord: z.enum(['numeral', 'word']),
    isBase10: z.boolean(),
    counterVariant: z.enum(['red', 'blue', 'green', 'grey', 'yellow'])
  }),
  generator: () => {
    const number = getRandomFromArray([14, 15, 16] as const);

    const numOrWord = getRandomFromArray(['numeral', 'word'] as const);

    const isBase10 = getRandomBoolean();

    const counterVariant = getRandomFromArray(['red', 'blue', 'green', 'grey', 'yellow'] as const);

    return { number, numOrWord, isBase10, counterVariant };
  }
};

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Understand1415And16',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
