import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import QF31NumberGridInteractive from '../../../../components/question/questionFormats/QF31NumberGridInteractive';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bja',
  description: 'bja',
  keywords: ['Odd', 'Even'],
  schema: z.object({
    numbers: z.array(z.number().int().min(1).max(24)).length(6).refine(arrayHasNoDuplicates),
    evenFirst: z.boolean()
  }),
  simpleGenerator: () => ({
    numbers: randomUniqueIntegersInclusive(1, 24, 6),
    evenFirst: getRandomBoolean()
  }),
  Component: ({ question: { numbers, evenFirst }, translate }) => {
    const evens: number[] = [];
    const odds: number[] = [];
    numbers.forEach(i => {
      if (i % 2 === 0) {
        evens.push(i);
      } else {
        odds.push(i);
      }
    });
    return (
      <QF8DragIntoUpTo3Groups
        title={translate.ks1Instructions.dragTheCardsToSortTheNumbers()}
        pdfTitle={translate.ks1PDFInstructions.sortTheNumbersIntoTheTable()}
        zoneNames={
          evenFirst
            ? [translate.ks1MiscStrings.EvenNumbers(), translate.ks1MiscStrings.OddNumbers()]
            : [translate.ks1MiscStrings.OddNumbers(), translate.ks1MiscStrings.EvenNumbers()]
        }
        testCorrect={evenFirst ? [evens, odds] : [odds, evens]}
        items={numbers.map(value => ({
          value,
          component: value.toLocaleString()
        }))}
        actionPanelVariant="endMid"
        zoneCapacity={6}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bjb',
  description: 'bjb',
  keywords: ['Odd', 'Even'],
  schema: z.object({
    startingNumber: z
      .number()
      .int()
      .min(1)
      .max(71)
      .refine(x => x % 10 === 1),
    isOdd: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = randomIntegerInclusiveStep(1, 71, 10);
    const isOdd = getRandomBoolean();
    return { startingNumber, isOdd };
  },
  Component: props => {
    const {
      question: { startingNumber, isOdd },
      translate
    } = props;

    const answer = isOdd
      ? range(startingNumber, startingNumber + 29, 2)
      : range(startingNumber + 1, startingNumber + 29, 2);

    return (
      <QF31NumberGridInteractive
        startNumber={startingNumber}
        finishNumber={startingNumber + 29}
        title={
          isOdd
            ? translate.ks1Instructions.selectAllTheOddNumbers()
            : translate.ks1Instructions.selectAllTheEvenNumbers()
        }
        pdfTitle={
          isOdd
            ? translate.ks1PDFInstructions.circleAllTheOddNumbers()
            : translate.ks1PDFInstructions.circleAllTheEvenNumbers()
        }
        testCorrect={answer}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjc',
  description: 'bjc',
  keywords: ['Odd', 'Even'],
  schema: z.object({
    numbers: z.array(z.number().int().min(0).max(9)).length(5).refine(arrayHasNoDuplicates),
    isEven: z.boolean()
  }),
  simpleGenerator: () => {
    const isEven = getRandomBoolean();
    const odd = randomIntegerInclusiveStep(1, 9, 2);
    const even = randomIntegerInclusiveStep(0, 8, 2);
    const numbers = randomUniqueIntegersInclusive(0, 9, 3, {
      constraint: x => ![odd, even].includes(x)
    });

    return { isEven, numbers: shuffle([...numbers, odd, even]) };
  },
  Component: props => {
    const {
      question: { isEven, numbers },
      translate
    } = props;

    return (
      <QF37SentenceDrag
        title={
          isEven
            ? translate.ks1Instructions.dragTheCardsToMakeA2DigitEvenNumber()
            : translate.ks1Instructions.dragTheCardsToMakeA2DigitOddNumber()
        }
        pdfTitle={
          isEven
            ? translate.ks1PDFInstructions.useTheCardsToMakeA2DigitEvenNumber()
            : translate.ks1PDFInstructions.useTheCardsToMakeA2DigitOddNumber()
        }
        items={numbers}
        sentence="<ans/><ans/>"
        testCorrect={([ans1, ans2]) =>
          ans1 !== undefined &&
          ans2 !== undefined &&
          ans1 !== 0 &&
          (isEven ? ans2 % 2 === 0 : ans2 % 2 !== 0)
        }
        moveOrCopy="move"
        customMarkSchemeAnswer={{
          answerText: isEven
            ? translate.markScheme.acceptAnyEvenNumber()
            : translate.markScheme.acceptAnyOddNumber()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'OddAndEvenNumbers',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
