import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbk',
  description: 'bbk',
  keywords: ['Number sentence', 'Add'],
  schema: z
    .object({
      num1: z.number().int().min(0).max(10),
      num2: z.number().int().min(0).max(10),
      flipped: z.boolean(),
      answer: z.enum(['left', 'right', 'result'])
    })
    .refine(({ num1, num2 }) => isInRange(2, 10)(num1 + num2)),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(0, 10);
    const num2 = randomIntegerInclusive(0, 10, { constraint: x => isInRange(2, 10)(x + num1) });
    const answer = getRandomFromArray(['left', 'right', 'result'] as const);
    const flipped = getRandomBoolean();

    return {
      num1,
      num2,
      answer,
      flipped
    };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, answer, flipped } = question;

    const sentence = getBinOpEquation({
      left: num1,
      right: num2,
      result: num1 + num2,
      sign: 'add',
      flipped,
      answer
    });

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={binOpEquationToSentenceString(sentence)}
        testCorrect={binOpEquationsToTestCorrect([sentence])[0]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbl',
  description: 'bbl',
  keywords: ['Add', 'Altogether'],
  schema: z
    .object({
      partA: z.number().int().min(0).max(9),
      partB: z.number().int().min(0).max(9)
    })
    .refine(({ partA, partB }) => isInRange(2, 10)(partA + partB)),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(0, 9);
    const partB = randomIntegerInclusive(0, 9 - partA, {
      constraint: x => isInRange(2, 10)(partA + x)
    });

    return { partA, partB };
  },
  Component: props => {
    const {
      question: { partA, partB },
      translate,
      displayMode
    } = props;
    const total = partA + partB;

    const random = seededRandom(props.question);
    const arrangements = [
      getRandomBooleanArray(3, 3, partA, random),
      getRandomBooleanArray(3, 3, partB, random)
    ];

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManyMarblesAltogether()}
        Content={({ dimens }) => (
          <View style={{ ...dimens, flexDirection: 'row', justifyContent: 'center' }}>
            {countRange(2).map(val => {
              const handWidth = displayMode === 'digital' ? dimens.width * 0.4 : dimens.width * 0.2;
              const handHeight = dimens.height;
              return (
                <View
                  key={`hand_${val}`}
                  style={{ alignItems: 'center', justifyContent: 'center' }}
                >
                  <AssetSvg
                    name="Hand_open_fingers"
                    style={{ transform: `scaleX(${val === 1 ? 1 : -1})` }}
                    width={handWidth}
                    height={handHeight}
                  />
                  <View
                    style={{
                      position: 'absolute',
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingTop: 30,
                      paddingLeft: val === 0 ? 18 : 0,
                      paddingRight: val === 0 ? 0 : 18,
                      transform: `rotate(${val === 0 ? 15 : -15}deg)`
                    }}
                  >
                    {arrangements[val].map((row, rowIndex) => (
                      <View key={`row-${rowIndex}`} style={{ flexDirection: 'row' }}>
                        {row.map((cell, colIndex) => (
                          <View
                            key={`cell-${rowIndex}-${colIndex}`}
                            style={{
                              width: handWidth / 9,
                              height: handHeight / 9
                            }}
                          >
                            {cell && (
                              <AssetSvg
                                name="Marble"
                                width={handWidth / 9}
                                height={handHeight / 9}
                              />
                            )}
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>
              );
            })}
          </View>
        )}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        testCorrect={[total.toString()]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bbm',
  description: 'bbm',
  keywords: ['Add', 'Word problem', 'Multi-step'],
  schema: z
    .object({
      names: z.array(ks1NameSchema).length(2),
      sweetsA: z.number().int().min(1).max(4),
      diffSweets: z.number().int().min(1).max(7),
      options: z
        .array(z.number().int().min(1).max(10))
        .length(4)
        .refine(x => arrayHasNoDuplicates(x), 'Options should be different')
    })
    .refine(
      ({ sweetsA, diffSweets }) => isInRange(4, 10)(2 * sweetsA + diffSweets),
      'Total should be between 4 and 10'
    ),
  simpleGenerator: () => {
    const names = getRandomUniqueKs1Names(2);

    const sweetsA = randomIntegerInclusive(1, 4);
    const diffSweets = randomIntegerInclusive(1, 7, {
      constraint: x => isInRange(4, 10)(2 * sweetsA + x)
    });

    const total = 2 * sweetsA + diffSweets;

    const possibleOptions = [
      ...new Set([total, total - 1, sweetsA + diffSweets, total + 1 < 10 ? total + 1 : total])
    ];

    const extraOptions = randomUniqueIntegersInclusive(1, 10, 4 - possibleOptions.length, {
      constraint: x => arrayHasNoDuplicates([...possibleOptions, x])
    });

    return {
      names,
      sweetsA,
      diffSweets,
      options: shuffle([...possibleOptions, ...extraOptions])
    };
  },
  Component: ({ question, translate }) => {
    const { names, sweetsA, diffSweets, options } = question;
    const total = 2 * sweetsA + diffSweets;

    const [nameA, nameB] = names;

    const items = options.map(n => ({
      value: n,
      component: <Text variant="WRN400">{n.toLocaleString()}</Text>
    }));

    return (
      <QF11SelectImagesUpTo4
        title={`${translate.ks1Instructions.charAHasXSweetsCharBHasYSweetsMoreThanHowManySweetsDoTheyHaveTogether(
          { nameA, nameB, sweetsA, diffSweets }
        )}<br/>${translate.ks1Instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.ks1Instructions.charAHasXSweetsCharBHasYSweetsMoreThanHowManySweetsDoTheyHaveTogether(
          { nameA, nameB, sweetsA, diffSweets }
        )}<br/>${translate.ks1PDFInstructions.tickYourAnswer()}`}
        renderItems={items}
        numItems={4}
        itemLayout="row"
        innerContainerStyle={{ alignItems: 'flex-end' }}
        itemStyle={{ height: 100 }}
        testCorrect={[total]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AdditionProblems',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
