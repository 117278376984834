import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import { randomIntegerInclusive } from 'common/src/utils/random';
import QF2AnswerBoxManySentences from '../../../../components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { arrayHasNoDuplicates, range } from '../../../../utils/collections';
import { ADD } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcD',
  description: 'bcD',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    leftNumber: z.number().int().min(1).max(9),
    addTenTo: z.enum(['leftNumber', 'rightNumber']),
    answerBoxPosition: z.enum(['left', 'right', 'result']),
    flipEquation: z.boolean()
  }),
  simpleGenerator: () => {
    const leftNumber = randomIntegerInclusive(1, 9);

    const addTenTo = getRandomFromArray(['leftNumber', 'rightNumber'] as const);

    const answerBoxPosition = getRandomFromArray(['left', 'right', 'result'] as const);

    const flipEquation = getRandomFromArrayWithWeights([true, false] as const, [1, 3]);

    return { leftNumber, addTenTo, answerBoxPosition, flipEquation };
  },
  Component: props => {
    const {
      question: { leftNumber, addTenTo, answerBoxPosition, flipEquation },
      translate
    } = props;

    const rightNumber = 10 - leftNumber;

    const eqA = getBinOpEquation({
      left: leftNumber,
      right: rightNumber,
      sign: 'add',
      answer: answerBoxPosition,
      flipped: flipEquation
    });

    const eqB = getBinOpEquation({
      left: addTenTo === 'leftNumber' ? leftNumber + 10 : leftNumber,
      right: addTenTo === 'rightNumber' ? rightNumber + 10 : rightNumber,
      sign: 'add',
      answer: answerBoxPosition,
      flipped: flipEquation
    });

    const eqs = [eqA, eqB];

    return (
      <QF2AnswerBoxManySentences
        title={translate.ks1Instructions.completeTheNumberBonds()}
        sentenceStyle={{
          // Answer boxes do not align correctly by default if they are in the left-most position, so need specific styling in these cases:
          alignSelf:
            (answerBoxPosition === 'left' && !flipEquation) ||
            (answerBoxPosition === 'result' && flipEquation)
              ? 'flex-start'
              : 'flex-end'
        }}
        sentences={eqs.map(binOpEquationToSentenceString)}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcE',
  description: 'bcE',
  keywords: ['Number bonds', 'Add', 'Bar model'],
  schema: z.object({
    numberA: z.number().int().min(1).max(19),
    answerBoxPosition: z.enum(['numberA', 'numberB', 'sum']),
    flippedBarModel: z.boolean()
  }),
  simpleGenerator: () => {
    const numberA = randomIntegerInclusive(1, 19);

    const answerBoxPosition = getRandomFromArray(['numberA', 'numberB', 'sum'] as const);

    const flippedBarModel = getRandomBoolean();

    return { numberA, answerBoxPosition, flippedBarModel };
  },

  Component: props => {
    const {
      question: { numberA, answerBoxPosition, flippedBarModel },
      translate
    } = props;

    const numberB = 20 - numberA;

    const numbers = flippedBarModel ? [[20], [numberA, numberB]] : [[numberA, numberB], [20]];

    const answerIndices = (() => {
      switch (answerBoxPosition) {
        case 'numberA':
          return flippedBarModel ? [[], [0]] : [[0], []];
        case 'numberB':
          return flippedBarModel ? [[], [1]] : [[1], []];
        case 'sum':
          return flippedBarModel ? [[0], []] : [[], [0]];
      }
    })();

    // In cases where the answer box requires an answer of between 1 and 3, the bar model must not be proportional
    // to keep the answer box wide enough to be easily touchable by the user:
    const isNotProportional =
      (answerBoxPosition === 'numberA' && numberA <= 3) ||
      (answerBoxPosition === 'numberB' && numberA >= 17);

    return (
      <QF20CompleteTheBarModel
        title={translate.ks1Instructions.completeTheBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={20}
        proportional={!isNotProportional}
        oneFontSize
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcF',
  description: 'bcF',
  keywords: ['Number bonds', 'Add'],
  schema: z.object({
    numbersToUse: z
      .array(z.number().int().min(0).max(19))
      .length(9)
      .refine(val => arrayHasNoDuplicates(val), 'All numbers in numbersToUse must be different.'),
    numberOfCorrectAnswers: z.number().int().min(3).max(8)
  }),
  simpleGenerator: () => {
    const numbersToUse = getRandomSubArrayFromArray(range(0, 19), 9);

    const numberOfCorrectAnswers = randomIntegerInclusive(3, 8);

    return { numbersToUse, numberOfCorrectAnswers };
  },
  Component: props => {
    const {
      question: { numbersToUse, numberOfCorrectAnswers },
      translate
    } = props;

    const random = seededRandom(props.question);

    const correctNumbers = getRandomSubArrayFromArray(numbersToUse, numberOfCorrectAnswers);

    const incorrectNumbers = numbersToUse.filter(num => !correctNumbers.includes(num));

    const correctAnswerItems = correctNumbers.map(
      num => `${num.toLocaleString()} + ${(20 - num).toLocaleString()}`
    );

    const incorrectNumberBondsTo10 = incorrectNumbers
      .map(num => {
        if (num <= 10) {
          return `${num.toLocaleString()} ${ADD} ${(10 - num).toLocaleString()}`;
        }
      })
      .filter(str => str); // Need to filter out potential undefined elements.

    const incorrectNumberBondsTo18 = incorrectNumbers
      .map(num => {
        if (num <= 18) {
          return `${num.toLocaleString()} ${ADD} ${(18 - num).toLocaleString()}`;
        }
      })
      .filter(str => str); // Need to filter out potential undefined elements.

    const incorrectNumberBondsTo19 = incorrectNumbers
      .map(num => {
        if (num <= 19) {
          return `${num.toLocaleString()} ${ADD} ${(19 - num).toLocaleString()}`;
        }
      })
      .filter(str => str); // Need to filter out potential undefined elements.

    const incorrectAnswerItems = getRandomSubArrayFromArray(
      [...incorrectNumberBondsTo10, ...incorrectNumberBondsTo18, ...incorrectNumberBondsTo19],
      9 - numberOfCorrectAnswers
    ) as string[];

    const items = shuffle([...correctAnswerItems, ...incorrectAnswerItems], {
      random
    });

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.selectTheNumberBondsToNum(20)}
        pdfTitle={translate.ks1PDFInstructions.tickTheNumberBondsToNum(20)}
        testCorrect={correctAnswerItems}
        items={items}
        multiSelect
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAndMakeNumberBondsTo20',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
