/** Get the intersection between two intervals. Each input interval must satisfy left<=right. */
export function intervalIntersection(
  a: [left: number, right: number],
  b: [left: number, right: number]
): [number, number] | null {
  const [min, max] = [a, b].sort((x, y) => x[0] - y[0]);

  if (min[1] < max[0]) {
    // they don't intersect
    return null;
  } else {
    return [max[0], Math.min(min[1], max[1])];
  }
}
