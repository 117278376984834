import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  shuffle
} from '../../../../utils/random';
import { countRange, filledArray, sortNumberArray } from '../../../../utils/collections';
import Text from 'common/src/components/typography/Text';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { isInRange } from '../../../../utils/matchers';
import { getRandomUniqueKs1Names, ks1NameSchema } from '../../../../utils/names';
import { BarModelVerticalBrace } from '../../../../components/question/representations/BarModelVerticalCurlyBrace';
import TextStructure from '../../../../components/molecules/TextStructure';
import { View } from 'react-native';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';
import { BarModelCurlyBrace } from '../../../../components/question/representations/BarModelCurlyBrace';
import { AssetSvg } from '../../../../assets/svg';
import { horizontalDoubleEndedArrow } from '../../../../components/question/representations/LineSvgs';
import { colors } from '../../../../theme/colors';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcS',
  description: 'bcS',
  keywords: ['Difference', 'More', 'Subtract'],
  schema: z
    .object({
      numberA: z.number().int().min(2).max(9),
      numberB: z.number().int().min(1).max(8),
      object: z.enum([
        'greenApples',
        'redApples',
        'bananas',
        'oranges',
        'redSweets',
        'greenSweets',
        'cookies'
      ]),
      characterNameA: ks1NameSchema,
      characterNameB: ks1NameSchema,
      isMore: z.boolean()
    })
    .refine(
      ({ numberA, numberB }) => isInRange(1, numberA - 1)(numberB),
      'numberB must be a integer between 1 and (numberA - 1), inclusive.'
    ),
  simpleGenerator: () => {
    const isMore = getRandomBoolean();
    const [characterNameA, characterNameB] = getRandomUniqueKs1Names(2);
    const object = getRandomFromArray([
      'greenApples',
      'redApples',
      'bananas',
      'oranges',
      'redSweets',
      'greenSweets',
      'cookies'
    ] as const);
    const numberA = randomIntegerInclusive(2, 9);
    const numberB = randomIntegerInclusive(1, numberA - 1);

    return { isMore, characterNameA, characterNameB, object, numberA, numberB };
  },
  Component: props => {
    const {
      question: { isMore, characterNameA, characterNameB, object, numberA, numberB },
      translate,
      displayMode
    } = props;

    const [objectString, objectSvgName] = (() => {
      switch (object) {
        case 'greenApples':
          return [translate.objects.Apples(), 'Array_objects/AppleRed' as const];
        case 'redApples':
          return [translate.objects.Apples(), 'Array_objects/AppleGreen' as const];
        case 'bananas':
          return [translate.objects.Bananas(), 'Array_objects/Banana' as const];
        case 'oranges':
          return [translate.objects.Oranges(), 'Array_objects/Orange' as const];
        case 'redSweets':
          return [translate.objects.Sweets(), 'Sweets/SweetRed' as const];
        case 'greenSweets':
          return [translate.objects.Sweets(), 'Sweets/SweetGreen' as const];
        case 'cookies':
          return [translate.objects.Cookies(), 'Cookie' as const];
      }
    })();

    const sentence = isMore
      ? translate.ks1Instructions.howManyMoreObjectsDoesCharAHaveThanCharB(
          objectString,
          characterNameA,
          characterNameB
        )
      : translate.ks1Instructions.howManyFewerObjectsDoesCharAHaveThanCharB(
          objectString,
          characterNameB,
          characterNameA
        );

    return (
      <QF1ContentAndSentence
        sentence="<ans/>"
        title={sentence}
        testCorrect={[(numberA - numberB).toString()]}
        pdfDirection="column"
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View
            style={{
              width: dimens.width,
              height: dimens.height,
              justifyContent: 'space-around'
            }}
          >
            <View
              style={{
                width: dimens.width,
                height: dimens.height * 0.3,
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: dimens.width * 0.15 }}>
                <Text variant="WRN400">{characterNameA}</Text>
              </View>
              <View style={{ flexDirection: 'row', columnGap: 4 }}>
                {countRange(numberA).map((_, index) => (
                  <AssetSvg
                    key={index}
                    name={objectSvgName}
                    height={displayMode === 'digital' ? 70 : 100}
                    width={displayMode === 'digital' ? 70 : 100}
                  />
                ))}
              </View>
            </View>
            <View
              style={{
                width: dimens.width,
                height: dimens.height * 0.3,
                alignItems: 'center',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: dimens.width * 0.15 }}>
                <Text variant="WRN400">{characterNameB}</Text>
              </View>
              <View style={{ flexDirection: 'row', columnGap: 4 }}>
                {countRange(numberB).map((_, index) => (
                  <AssetSvg
                    key={index}
                    name={objectSvgName}
                    height={displayMode === 'digital' ? 70 : 100}
                    width={displayMode === 'digital' ? 70 : 100}
                  />
                ))}
                <View style={{ alignSelf: 'center' }}>
                  {horizontalDoubleEndedArrow(
                    (displayMode === 'digital' ? 70 : 100) * (numberA - numberB),
                    3,
                    colors.black
                  )}
                </View>
              </View>
            </View>
          </View>
        )}
        sentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcT',
  description: 'bcT',
  keywords: ['Difference', 'Bar model'],
  schema: z
    .object({
      isMore: z.boolean(),
      isMoreOnTop: z.boolean(),
      sectionsA: z.number().int().min(2).max(12),
      sectionsB: z.number().int().min(1).max(11),
      object: z.enum(['apples', 'oranges', 'sweets', 'cookies']),
      characterNameA: ks1NameSchema,
      characterNameB: ks1NameSchema
    })
    .refine(
      ({ sectionsA, sectionsB }) => isInRange(1, sectionsA - 2)(sectionsB),
      'sectionsB must be a integer between 1 and (sectionsA - 2), inclusive.'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const isMore = getRandomBoolean();
    const isMoreOnTop = getRandomBoolean();
    const [characterNameA, characterNameB] = getRandomUniqueKs1Names(2);
    const object = getRandomFromArray(['apples', 'oranges', 'sweets', 'cookies'] as const);
    const sectionsA = randomIntegerInclusive(3, 12);
    const sectionsB = randomIntegerInclusive(1, sectionsA - 2);

    return { isMore, isMoreOnTop, characterNameA, characterNameB, object, sectionsA, sectionsB };
  },
  Component: props => {
    const {
      question: {
        isMore,
        isMoreOnTop,
        characterNameA,
        characterNameB,
        object,
        sectionsA,
        sectionsB
      },
      translate,
      displayMode
    } = props;

    const sentence = isMore
      ? translate.ks1Instructions.charAHasNumXCharBHasNumXHowManyMoreXDoesCharHaveThanCharCompleteTheBarModel(
          characterNameA,
          sectionsA,
          object,
          characterNameB,
          sectionsB,
          object
        )
      : translate.ks1Instructions.charAHasNumXCharBHasNumXHowManyFewerXDoesCharHaveThanCharCompleteTheBarModel(
          characterNameA,
          sectionsA,
          object,
          characterNameB,
          sectionsB,
          object
        );

    const numbers = [
      filledArray(1, sectionsA),
      [...filledArray(1, sectionsB), sectionsA - sectionsB]
    ];
    const strings = [filledArray('', sectionsA), filledArray('', sectionsB + 1)];
    const markSchemeAnswer = [
      filledArray('', sectionsA),
      [...filledArray('', sectionsB), (sectionsA - sectionsB).toLocaleString()]
    ];

    return (
      <QF3Content
        title={sentence}
        questionHeight={800}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ rowGap: 8, flexDirection: 'row' }}>
            <View
              style={{ width: dimens.width * 0.1, justifyContent: 'space-evenly', height: 245 }}
            >
              <TextStructure sentence={isMoreOnTop ? characterNameA : characterNameB} />
              <TextStructure sentence={isMoreOnTop ? characterNameB : characterNameA} />
            </View>
            <View style={{ width: dimens.width * 0.9 }}>
              {!isMoreOnTop && (
                <View style={{ width: ((dimens.width * 0.9 - 40) / sectionsA) * sectionsB }}>
                  <BarModelCurlyBrace braceText={sectionsB} topOrBottomBrace="top" />
                </View>
              )}
              <BarModelWithState
                numbers={isMoreOnTop ? numbers : [...numbers].reverse()}
                strings={isMoreOnTop ? strings : [...strings].reverse()}
                total={sectionsA}
                dimens={{ height: dimens.height, width: dimens.width * 0.9 }}
                inputMaxCharacters={2}
                sameRowColor
                arrowIndices={isMoreOnTop ? [[], [sectionsB]] : [[sectionsB]]}
                proportional
                topBraceText={isMoreOnTop ? sectionsA : undefined}
                bottomBraceText={isMoreOnTop ? undefined : sectionsA}
                id={'bar-model'}
                arrowAnsweBoxPosition={isMoreOnTop ? 'below' : 'above'}
                defaultState={
                  displayMode === 'markscheme'
                    ? isMoreOnTop
                      ? markSchemeAnswer
                      : [...markSchemeAnswer].reverse()
                    : [[], []]
                }
                testCorrect={userAnswer =>
                  userAnswer[isMoreOnTop ? 1 : 0][sectionsB] === (sectionsA - sectionsB).toString()
                }
              />
              {isMoreOnTop && (
                <View style={{ width: ((dimens.width * 0.9 - 40) / sectionsA) * sectionsB }}>
                  <BarModelCurlyBrace braceText={sectionsB} topOrBottomBrace="bottom" />
                </View>
              )}
            </View>
          </View>
        )}
      />
    );
  }
});

const Question2v2 = newQuestionContent({
  uid: 'bcT2',
  description: 'bcT',
  keywords: ['Difference', 'Bar model'],
  schema: z
    .object({
      isMore: z.boolean(),
      isMoreOnTop: z.boolean(),
      sectionsA: z.number().int().min(2).max(12),
      sectionsB: z.number().int().min(1).max(11),
      object: z.enum(['apple', 'orange', 'sweets', 'cookie']),
      characterNameA: ks1NameSchema,
      characterNameB: ks1NameSchema
    })
    .refine(
      ({ sectionsA, sectionsB }) => isInRange(1, sectionsA - 2)(sectionsB),
      'sectionsB must be a integer between 1 and (sectionsA - 2), inclusive.'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const isMore = getRandomBoolean();
    const isMoreOnTop = getRandomBoolean();
    const [characterNameA, characterNameB] = getRandomUniqueKs1Names(2);
    const object = getRandomFromArray(['apple', 'orange', 'sweets', 'cookie'] as const);
    const sectionsA = randomIntegerInclusive(3, 12);
    const sectionsB = randomIntegerInclusive(1, sectionsA - 2);

    return { isMore, isMoreOnTop, characterNameA, characterNameB, object, sectionsA, sectionsB };
  },
  Component: props => {
    const {
      question: {
        isMore,
        isMoreOnTop,
        characterNameA,
        characterNameB,
        object,
        sectionsA,
        sectionsB
      },
      translate,
      displayMode
    } = props;

    const objectA = translate.items[object](sectionsA);
    const objectB = translate.items[object](sectionsB);

    const sentence = isMore
      ? translate.ks1Instructions.charAHasNumXCharBHasNumXHowManyMoreXDoesCharHaveThanCharCompleteTheBarModel(
          characterNameA,
          sectionsA,
          objectA,
          characterNameB,
          sectionsB,
          objectB
        )
      : translate.ks1Instructions.charAHasNumXCharBHasNumXHowManyFewerXDoesCharHaveThanCharCompleteTheBarModel(
          characterNameA,
          sectionsA,
          objectA,
          characterNameB,
          sectionsB,
          objectB
        );

    const numbers = [
      filledArray(1, sectionsA),
      [...filledArray(1, sectionsB), sectionsA - sectionsB]
    ];
    const strings = [filledArray('', sectionsA), filledArray('', sectionsB + 1)];
    const markSchemeAnswer = [
      filledArray('', sectionsA),
      [...filledArray('', sectionsB), (sectionsA - sectionsB).toLocaleString()]
    ];

    return (
      <QF3Content
        title={sentence}
        questionHeight={800}
        inputType="numpad"
        Content={({ dimens }) => (
          <View style={{ rowGap: 8, flexDirection: 'row' }}>
            <View
              style={{ width: dimens.width * 0.1, justifyContent: 'space-evenly', height: 245 }}
            >
              <TextStructure sentence={isMoreOnTop ? characterNameA : characterNameB} />
              <TextStructure sentence={isMoreOnTop ? characterNameB : characterNameA} />
            </View>
            <View style={{ width: dimens.width * 0.9 }}>
              {!isMoreOnTop && (
                <View style={{ width: ((dimens.width * 0.9 - 40) / sectionsA) * sectionsB }}>
                  <BarModelCurlyBrace braceText={sectionsB} topOrBottomBrace="top" />
                </View>
              )}
              <BarModelWithState
                numbers={isMoreOnTop ? numbers : [...numbers].reverse()}
                strings={isMoreOnTop ? strings : [...strings].reverse()}
                total={sectionsA}
                dimens={{ height: dimens.height, width: dimens.width * 0.9 }}
                inputMaxCharacters={2}
                sameRowColor
                arrowIndices={isMoreOnTop ? [[], [sectionsB]] : [[sectionsB]]}
                proportional
                topBraceText={isMoreOnTop ? sectionsA : undefined}
                bottomBraceText={isMoreOnTop ? undefined : sectionsA}
                id={'bar-model'}
                arrowAnsweBoxPosition={isMoreOnTop ? 'below' : 'above'}
                defaultState={
                  displayMode === 'markscheme'
                    ? isMoreOnTop
                      ? markSchemeAnswer
                      : [...markSchemeAnswer].reverse()
                    : [[], []]
                }
                testCorrect={userAnswer =>
                  userAnswer[isMoreOnTop ? 1 : 0][sectionsB] === (sectionsA - sectionsB).toString()
                }
              />
              {isMoreOnTop && (
                <View style={{ width: ((dimens.width * 0.9 - 40) / sectionsA) * sectionsB }}>
                  <BarModelCurlyBrace braceText={sectionsB} topOrBottomBrace="bottom" />
                </View>
              )}
            </View>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcU',
  description: 'bcU',
  keywords: ['Difference', 'Bar model'],
  schema: z
    .object({
      isMore: z.boolean(),
      number1: z.number().int().min(1).max(20),
      number2: z.number().int().min(1).max(20),
      names: ks1NameSchema.array().length(2),
      object: z.enum(['Apples', 'Bananas', 'Oranges', 'Sweets', 'Cookies'])
    })
    .refine(val => {
      const max = Math.max(val.number1, val.number2);
      return isInRange(1, max - 1)(Math.abs(val.number1 - val.number2));
    }, 'number1 and number2 must have a difference between 1 and largest number -1'),
  simpleGenerator: () => {
    const largerNumber = randomIntegerInclusive(5, 20);
    const smallerNumber = randomIntegerInclusive(1, largerNumber - 1);

    const isMore = getRandomBoolean();

    const [number1, number2] = shuffle([largerNumber, smallerNumber]);

    const names = getRandomUniqueKs1Names(2);
    const object = getRandomFromArray([
      'Apples',
      'Bananas',
      'Oranges',
      'Sweets',
      'Cookies'
    ] as const);

    return {
      number1,
      number2,
      isMore,
      names,
      object
    };
  },
  Component: ({ question, translate }) => {
    const { number1, number2, isMore, names, object } = question;

    const isSmallestFirst = number1 < number2;
    const numbers = isSmallestFirst
      ? [[number1, number2 - number1], [number2]]
      : [[number1], [number2, number1 - number2]];
    const strings = isSmallestFirst
      ? [[number1.toLocaleString(), ''], [number2.toLocaleString()]]
      : [[number1.toLocaleString()], [number2.toLocaleString(), '']];

    const orderedNums = sortNumberArray([number1, number2], 'descending');
    const answer = orderedNums[0] - orderedNums[1];

    const translatedObject = translate.objects[object]();

    const titleObject = {
      nameA: names[0],
      nameB: names[1],
      count1: number1,
      count2: number2,
      nameFewer: isSmallestFirst ? names[0] : names[1],
      nameMore: isSmallestFirst ? names[1] : names[0],
      object: translatedObject
    };

    return (
      <QF1ContentAndSentence
        title={
          isMore
            ? translate.ks1Instructions.nameAHasXObjectNameBHasYHowManyMore(titleObject)
            : translate.ks1Instructions.nameAHasXObjectNameBHasYHowManyFewer(titleObject)
        }
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <BarModelVerticalBrace
            numbers={numbers}
            strings={strings}
            total={orderedNums[0]}
            arrowIndices={isSmallestFirst ? [[1], []] : [[], [1]]}
            dimens={dimens}
            preBarText={names}
          />
        )}
        sentence={'<ans/>'}
        testCorrect={[answer.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionFindingTheDifference',
  questionTypes: [Question1, Question2v2, Question3],
  unpublishedQuestionTypes: [Question2v2],
  archivedQuestionTypes: [Question2]
});
export default SmallStep;
