import { View } from 'react-native';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep
} from 'common/src/utils/random';
import { cheapObjectAsWord } from 'common/src/utils/objects';
import QF7InteractiveTable from 'common/src/components/question/questionFormats/QF7InteractiveTable';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import ShadedFractionBarModel from 'common/src/components/question/representations/ShadedFractionBarModel';
import { AssetSvg } from 'common/src/assets/svg';
import EasyDragAndDrop from 'common/src/components/draganddrop/EasyDragAndDrop';
import BaseLayout from 'common/src/components/molecules/BaseLayout';
import DragAndDropSection from 'common/src/components/molecules/DragAndDropSection';
import { arrayHasNoDuplicates, countRange } from 'common/src/utils/collections';
import { animalSchema, getRandomAnimal } from 'common/src/utils/animals';
import BaseLayoutPDF from 'common/src/components/molecules/BaseLayoutPDF';
import { numberEnum } from 'common/src/utils/zod';
import { MeasureView } from 'common/src/components/atoms/MeasureView';
import { fruitAsWord, fruitsSchema, getRandomUniqueFruits } from 'common/src/utils/fruits';
import { renderMarkSchemeProp } from 'common/src/components/question/questionFormats/utils/markSchemeRender';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aTs',
  description: 'aTs',
  keywords: ['Ratio', 'Problem'],
  schema: z
    .object({
      numberOfCircles: z.number().int().min(1).max(3),
      numberOfTriangles: z.number().int().min(2).max(5),
      multiplier: z.number().int().min(1).max(5)
    })
    .refine(
      val => val.numberOfCircles < val.numberOfTriangles,
      'numberOfCircles must be less than numberOfTriangles'
    ),
  questionHeight: 800,
  simpleGenerator: () => {
    const numberOfTriangles = randomIntegerInclusive(2, 5);
    const numberOfCircles = randomIntegerInclusive(1, 3, {
      constraint: x => x < numberOfTriangles
    });
    const multiplier = randomIntegerInclusive(1, 3);

    return { numberOfTriangles, numberOfCircles, multiplier };
  },
  Component: props => {
    const {
      question: { numberOfTriangles, numberOfCircles, multiplier },
      translate,
      displayMode
    } = props;

    const draggableTriangles = numberOfTriangles + multiplier;

    const circle = (key: number, width: number) => (
      <View
        key={key}
        style={{
          width: width + 20,
          height: width + 20
        }}
      >
        {displayMode === 'pdf' || displayMode === 'markscheme' ? (
          <AssetSvg name="Circles/circle_black" width={width} />
        ) : (
          <AssetSvg name="Circles/circle_blue" width={width} />
        )}
      </View>
    );

    const triangle = (key: number, width: number) => (
      <View
        key={key}
        style={{
          width: width + 20,
          height: width + 20
        }}
      >
        <AssetSvg name="Triangle" width={width} />
      </View>
    );

    if (displayMode === 'pdf' || displayMode === 'markscheme') {
      // PDF or mark scheme. Just show a rectangle containing some shapes
      return (
        <BaseLayoutPDF
          title={translate.instructions.drawTrianglesMakeRatioOfCirclesToTrianglesXtoY(
            numberOfCircles,
            numberOfTriangles
          )}
          mainPanelContents={
            <MeasureView>
              {dimens => (
                <>
                  <View
                    style={{
                      borderWidth: 4,
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                      padding: 8,
                      width: dimens.width,
                      height: dimens.height
                    }}
                  >
                    {countRange(numberOfCircles).map(idx => circle(idx, 140))}
                    {displayMode === 'markscheme' &&
                      countRange(numberOfTriangles).map(idx => triangle(idx, 140))}
                  </View>
                  <View>
                    {displayMode === 'markscheme' &&
                      renderMarkSchemeProp(translate.answerSentences.xTriangles(numberOfTriangles))}
                  </View>
                </>
              )}
            </MeasureView>
          }
        />
      );
    }

    return (
      <EasyDragAndDrop.ProviderWithState
        id="draganddrop"
        items={countRange(draggableTriangles).map(i => ({
          component: <AssetSvg name="Triangle" width={72} />,
          value: i
        }))}
        variant="square"
        moveOrCopy="move"
        defaultState={[[]]}
        testComplete={state => state[0].length > 0}
        testCorrect={state => state[0].length === numberOfTriangles}
      >
        <BaseLayout
          title={translate.instructions.dragCardsMakeRatioOfCirclesToTrianglesXtoY(
            numberOfCircles,
            numberOfTriangles
          )}
          actionPanelVariant="bottom"
          actionPanelContents={
            <DragAndDropSection>
              {countRange(draggableTriangles).map(i => (
                <EasyDragAndDrop.Source key={i} id={i} />
              ))}
            </DragAndDropSection>
          }
          mainPanelContents={
            <MeasureView>
              {dimens => (
                <EasyDragAndDrop.ZoneMultiple
                  id={0}
                  capacity={12}
                  style={{
                    alignContent: 'flex-start',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: dimens.width,
                    height: dimens.height
                  }}
                >
                  {countRange(numberOfCircles).map(idx => circle(idx, 72))}
                </EasyDragAndDrop.ZoneMultiple>
              )}
            </MeasureView>
          }
        />
      </EasyDragAndDrop.ProviderWithState>
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aTt',
  description: 'aTt',
  keywords: ['Ratio', 'Table', 'Problem'],
  schema: z
    .object({
      colourCounters1: z.number().int().min(2).max(5),
      colourCounters2: z.number().int().min(2).max(6),
      counterColours: z
        .array(z.enum(['Red', 'Blue', 'Green', 'Pink', 'Orange', 'Yellow']))
        .length(2),
      multiplier: z.number().int().min(1).max(5)
    })
    .refine(
      val => val.colourCounters1 !== val.colourCounters2,
      'colorCounters1 must not be equal to colorCounters2'
    ),
  simpleGenerator: () => {
    const colourCounters1 = randomIntegerInclusive(2, 5);
    const colourCounters2 = randomIntegerInclusive(2, 6, {
      constraint: x => x !== colourCounters1
    });
    const counterColours = getRandomSubArrayFromArray(
      ['Red', 'Blue', 'Green', 'Pink', 'Orange', 'Yellow'] as const,
      2
    );
    const multiplier = randomIntegerInclusive(1, 5);

    return { colourCounters1, colourCounters2, counterColours, multiplier };
  },
  Component: props => {
    const {
      question: { colourCounters1, colourCounters2, counterColours, multiplier },
      translate
    } = props;

    const number4 = colourCounters1 * 2;
    const number5 = colourCounters1 * multiplier;

    const [colour1, colour2] = counterColours;

    const translatedColourOne = translate.colors[colour1]();
    const translatedColourTwo = translate.colors[colour2]();

    // Table data
    const data = [
      [`${colourCounters1}`, `${colourCounters2}`],
      [`${number4}`, '<ans/>'],
      [`${number5}`, '<ans/>']
    ];

    // Answers
    const ans1 = colourCounters2 * 2;
    const ans2 = colourCounters2 * multiplier;

    return (
      <QF7InteractiveTable
        title={translate.instructions.forEveryXColourCountersThereAreYColourCountersCompleteTheTable(
          {
            colour1: translatedColourOne,
            colour2: translatedColourTwo,
            colourCountersAmount1: colourCounters1,
            colourCountersAmount2: colourCounters2
          }
        )}
        cellHeaders={[`${translatedColourOne} counters`, `${translatedColourTwo} counters`]}
        tableData={data}
        testCorrect={[ans1.toString(), ans2.toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aTu',
  description: 'aTu',
  keywords: ['Ratio', 'Problem'],
  schema: z.object({
    children: z.number().int().min(3).max(5),
    adults: z.number().int().min(2).max(6),
    fruits: z
      .array(z.object({ name: fruitsSchema, amount: z.number().int().min(2).max(12) }))
      .length(2)
      .refine(arrayHasNoDuplicates, 'fruits must be different'),
    random: z.boolean()
  }),
  simpleGenerator: () => {
    const children = randomIntegerInclusive(3, 5);
    const adults = randomIntegerInclusive(2, 6);
    const [fruitName1, fruitName2] = getRandomUniqueFruits(2);
    const fruits = [
      {
        name: fruitName1,
        amount: randomIntegerInclusive(6, 12)
      },
      {
        name: fruitName2,
        amount: randomIntegerInclusive(2, 6)
      }
    ];

    const random = getRandomBoolean();

    return { children, adults, fruits, random };
  },
  Component: props => {
    const {
      question: { children, adults, fruits, random },
      translate,
      displayMode
    } = props;

    // Fruits
    const [fruit1, fruit2] = fruits;

    // Answer
    const ans = random ? adults * children : fruit1.amount * fruit2.amount;

    const fruit1Word = fruitAsWord(fruit1.name, translate, true).toLowerCase();
    const fruit2Word = fruitAsWord(fruit2.name, translate, true).toLowerCase();

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={
          random
            ? translate.instructions.forEveryOneAdultOnSchoolTripThereAreXChildrenThereAreYAdultsOnTripHowManyChildrenAreThere(
                children,
                adults
              )
            : translate.instructions.forEveryOneFruit1InBoxThereAreFruit2HowManyFruit2AreThere({
                fruit1: fruit1.name.toLowerCase(),
                fruit1Amount: fruit1.amount,
                fruit2: fruit2.name.toLowerCase(),
                fruit2Amount: fruit2.amount
              })
        }
        testCorrect={[ans.toString()]}
        Content={
          <View style={{ display: 'flex', rowGap: 12 }}>
            <ShadedFractionBarModel
              preBarText={random ? translate.keywords.Adults().toLowerCase() : fruit1Word}
              preBarTextStyle={{ width: displayMode === 'digital' ? 150 : 250, paddingRight: 12 }}
              totalSubSections={1}
              width={100}
              containerStyle={{ alignItems: 'flex-start' }}
            />
            <ShadedFractionBarModel
              preBarText={random ? translate.keywords.Children().toLowerCase() : fruit2Word}
              preBarTextStyle={{ width: displayMode === 'digital' ? 150 : 250, paddingRight: 12 }}
              totalSubSections={random ? children : fruit2.amount}
              width={random ? 100 * children : 100 * fruit2.amount}
              containerStyle={{ alignItems: 'flex-start' }}
            />
          </View>
        }
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aTv',
  description: 'aTv',
  keywords: ['Ratio', 'Problem'],
  schema: z.object({
    animal: animalSchema,
    hayPerAnimal: numberEnum([3, 5]),
    animalTotal: z.number().int().min(4).max(12)
  }),
  simpleGenerator: () => {
    const animal = getRandomAnimal();
    const hayPerAnimal = getRandomFromArray([3, 5] as const);
    const animalTotal = randomIntegerInclusiveStep(4, 12, 2);

    return { animal, hayPerAnimal, animalTotal };
  },

  Component: props => {
    const {
      question: { animal, hayPerAnimal, animalTotal },
      translate
    } = props;

    const answer = (animalTotal / 2) * hayPerAnimal;

    const translatedAnimal = translate.animals[animal]();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.forEveryTwoAnimalsFarmerMustPutOutXBalesHowManyBalesAreNeededForYAnimals(
          hayPerAnimal,
          animalTotal,
          translatedAnimal
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansBalesOfHay()}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aTw',
  description: 'aTw',
  keywords: ['Ratio', 'Problem'],
  schema: z.object({
    animal: animalSchema,
    hayPerAnimal: numberEnum([3, 5]),
    hayMultiplier: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const animal = getRandomAnimal();
    const hayPerAnimal = getRandomFromArray([3, 5] as const);
    const hayMultiplier = randomIntegerInclusive(2, 6);

    return { animal, hayPerAnimal, hayMultiplier };
  },

  Component: props => {
    const {
      question: { animal, hayPerAnimal, hayMultiplier },
      translate
    } = props;

    const hayTotal = hayPerAnimal * hayMultiplier;
    const answer = hayMultiplier * 2;
    const translatedAnimal = translate.animals[animal]();

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.forEveryTwoAnimalsXBalesPutOutYBalesInAFieldWhatIsMaxInField(
          hayPerAnimal,
          hayTotal,
          translatedAnimal
        )}
        testCorrect={[answer.toString()]}
        sentence={translate.answerSentences.ansObject(animal)}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aTx',
  description: 'aTx',
  keywords: ['Ratio', 'Problem'],
  schema: z.object({
    colour1: z.enum(['Red', 'Blue', 'Green', 'Orange', 'Pink', 'Yellow']),
    colour2: z.enum(['Red', 'Blue', 'Green', 'Orange', 'Pink', 'Yellow']),
    answerColour: z.number().int().min(1).max(2),
    balloon1: z.number().int().min(1).max(5),
    balloon2: z.number().int().min(1).max(6),
    balloonMultiplier: z.number().int().min(2).max(6)
  }),
  simpleGenerator: () => {
    const [colour1, colour2] = getRandomSubArrayFromArray(
      ['Red', 'Blue', 'Green', 'Orange', 'Pink', 'Yellow'] as const,
      2
    );

    const answerColour = randomIntegerInclusive(1, 2);

    const balloon1 = randomIntegerInclusive(1, 5);
    const balloon2 = randomIntegerInclusive(1, 6, { constraint: x => x !== balloon1 });
    const balloonMultiplier = randomIntegerInclusive(2, 6);

    return { colour1, colour2, answerColour, balloon1, balloon2, balloonMultiplier };
  },

  Component: props => {
    const {
      question: { colour1, colour2, answerColour, balloon1, balloon2, balloonMultiplier },
      translate
    } = props;

    const translatedColourOne = translate.colors[colour1]();
    const translatedColourTwo = translate.colors[colour2]();

    const balloonTotal = (balloon1 + balloon2) * balloonMultiplier;
    const answer =
      answerColour === 1
        ? (balloonTotal / (balloon1 + balloon2)) * balloon1
        : (balloonTotal / (balloon1 + balloon2)) * balloon2;

    const titleObj = {
      amount1: balloon1,
      amount2: balloon2,
      colour1: translatedColourOne,
      colour2: translatedColourTwo,
      object1: cheapObjectAsWord('Balloon', translate, balloon1 > 1),
      object2: cheapObjectAsWord('Balloon', translate, balloon2 > 1),
      total: balloonTotal,
      colour3: answerColour === 1 ? colour1 : colour2
    };

    return (
      <QF2AnswerBoxOneSentence
        title={
          balloon2 > 1
            ? translate.instructions.forEveryXBalloonThereAreYBalloonsHowManyZBalloons(titleObj)
            : translate.instructions.forEveryXBalloonThereIsYBalloonHowManyZBalloons(titleObj)
        }
        testCorrect={[answer.toString()]}
        sentence={`<ans/>`}
        mainPanelContainerStyle={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RatioProblems',
  questionTypes: [Question1, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: []
});
export default SmallStep;
