import { all, create, equal, number } from 'mathjs';
import { intervalIntersection } from './intervals';
import { greatest } from './collections';
import { type Point2d, PointFlexible, normalizePointFlexible } from './vectors';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

/** returns the gradient between two points */
export function getLineGradient(point1: [number, number], point2: [number, number]) {
  const x = 0;
  const y = 1;
  const dy = Math.abs(point1[y] - point2[y]);
  const dx = Math.abs(point1[x] - point2[x]);
  return dx === 0 ? 0 : number(math.evaluate(`${dy} / ${dx}`));
}

/** returns the line intercept for given gradient */
export function getLineIntercept(point1: [number, number], gradient: number) {
  const x = 0;
  const y = 1;
  return number(math.evaluate(`${point1[y]} - ${gradient} * ${point1[x]}`));
}

/** returns the y value of a given x for between two points on a line */
export function getYValueOfPoint(
  point1: [number, number],
  point2: [number, number],
  xNew: number,
  round = true
) {
  const gradient = getLineGradient(point1, point2);
  const intercept = getLineIntercept(point1, gradient);
  const value = number(math.evaluate(`${gradient} * ${xNew} + ${intercept}`));
  return round ? Math.round(value) : value;
}

/** returns boolean to check if two sets of coordinates pass through another set of coordinates */
export const isOnLine = ({
  coordinatesA,
  coordinatesB,
  x,
  y
}: {
  coordinatesA: { x: number; y: number };
  coordinatesB: { x: number; y: number };
  x: number;
  y: number;
}): boolean =>
  // Equation of the line they formed is (y-py)/(qy-py) = (x-px)/(qx-px)
  // This equation breaks for horizontal or vertical lines, so we have to check them separately.
  ((x === coordinatesA.x && x === coordinatesB.x) ||
    (y === coordinatesA.y && y === coordinatesB.y) ||
    // casting needed for mathjs library
    (equal(
      (y - coordinatesA.y) / (coordinatesB.y - coordinatesA.y),
      (x - coordinatesA.x) / (coordinatesB.x - coordinatesA.x)
    ) as boolean)) &&
  // Additionally check that the point is between p and q
  x >= Math.min(coordinatesA.x, coordinatesB.x) &&
  x <= Math.max(coordinatesA.x, coordinatesB.x) &&
  y >= Math.min(coordinatesA.y, coordinatesB.y) &&
  y <= Math.max(coordinatesA.y, coordinatesB.y);

/**
 * Get the intersection of two line segments.
 *
 * This is either a point, a line segment (if the two line segments overlap), or null.
 *
 * Adapted from here: https://github.com/pgkelley4/line-segments-intersect/blob/master/js/line-segments-intersect.js
 */
export function getIntersection(
  p: [PointFlexible, PointFlexible],
  q: [PointFlexible, PointFlexible]
): Point2d | [Point2d, Point2d] | null {
  // Implementation notes:
  //
  // This method doesn't require calculating the gradiant of either line
  //
  // Essentially we write line segments P as p1+tr and line Q as q1+us for some parameters t and u between 0 and 1.
  // (r and s are the vectors: r=p2-p1 and s=q2-q1)
  //
  // Then, the lines cross when p1+tr = q1+us, so we just need to solve for t and u. Skipping some steps, we end up with:
  //
  // t = ((q1−p1)×s) / (r×s), where × is the "vector cross product": a×b = a.x*b.y - a.y*b.x
  //
  // u = ((q1−p1)×r) / (r×s)
  //
  // If r×s=0, then the lines are parallel, which means they either don't cross or they're co-linear. If they're
  // co-linear, they could intersect at a point or on a whole line segment. Calculate and return that.
  //
  // Otherwise, we just need to check t and u are between 0 and 1, and return p1+tr for the t we found.
  const [p1, p2, q1, q2] = [p[0], p[1], q[0], q[1]].map(normalizePointFlexible);

  const r = p2.subtract(p1);
  const s = q2.subtract(q1);

  const crossProduct = (p: Point2d, q: Point2d) => p.x * q.y - p.y * q.x;

  const uNumerator = crossProduct(q1.subtract(p1), r);
  const denominator = crossProduct(r, s);

  if (denominator === 0) {
    // The lines are parallel (because s is some scalar multiple of r, hence s is the same direction as r)

    if (uNumerator !== 0) {
      // The lines are not co-linear (because q1-p1 is not in the same direction as r). They must never meet.
      return null;
    }

    // lines are co-linear. This means they either don't intersect, overlap, or just barely touch at one point.
    // This means that we can write q1=p1+ar, and s=br for some numbers a and b
    // Once we calculate a and b, then we can write the line Q as p1+(a+bu)r, for 0<=u<=1.
    // Because t=a+bu and 0<=u<=1 and 0<=t<=1, this gives us the allowed values of t.

    // First, calculate a and b. We use either the x coordinate or the y coordinate, whichever isn't zero.
    // (In fact we use whichever is furthest from 0, for precision).
    const xOrY = greatest(['x', 'y'] as const, coord => Math.abs(r[coord]));
    const a = (q1[xOrY] - p1[xOrY]) / r[xOrY];
    const b = s[xOrY] / r[xOrY];

    // Find the range of t, i.e. the intersection of [0, 1] and [a, a+b].
    // (or [a+b, a], depending on whether b is +ve or -ve)
    const tInterval = intervalIntersection([0, 1], [Math.min(a, a + b), Math.max(a, a + b)]);
    if (tInterval === null) return null; // They don't intersect

    const [tMin, tMax] = tInterval;

    // Use our formula p1+tr to recover the points that these t values correspond to
    const [intersection1, intersection2] = [p1.add(r.multiply(tMin)), p1.add(r.multiply(tMax))];

    if (intersection1.equals(intersection2)) {
      return intersection1; // They just barely touch at one point
    } else {
      return [intersection1, intersection2]; // They overlap
    }
  }

  const u = uNumerator / denominator;
  const t = crossProduct(q1.subtract(p1), s) / denominator;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    return p1.add(r.multiply(t)); // They intersect
  } else {
    return null; // They don't intersect
  }
}
