import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  arrayHasNoDuplicates,
  arraysHaveSameContents,
  arraysHaveSameContentsUnordered,
  countRange,
  filledArray
} from '../../../../utils/collections';
import { View } from 'react-native';
import { CounterBoxArrangement } from '../../../../components/question/representations/CounterBoxArrangement/CounterBoxArrangement';
import { getCounterArrangementScale } from '../../../../components/question/representations/CounterBoxArrangement/counterArrangements';
import { numberEnum } from '../../../../utils/zod';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { ADD, MULT } from '../../../../constants';
import QF36ContentAndSentencesDrag from '../../../../components/question/questionFormats/QF36ContentAndSentencesDrag';
import { AssetSvg } from '../../../../assets/svg';
import { getGroupSvgName } from '../../../../utils/objectsImages';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biP',
  description: 'biP',
  keywords: ['Multiplication', 'Lots of', 'Groups of'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    inEachGroup: numberEnum([1, 2, 3, 4, 5, 6, 10]),
    item: z.enum([
      'Apple',
      'Pear',
      'Banana',
      'Donut',
      'Egg',
      'Pencil',
      'Muffin',
      'Cookie',
      'Fish',
      'MuffinRack'
    ]),
    isLotsOf: z.boolean(),
    options: z
      .array(z.number().int())
      .length(4)
      .refine(val => arrayHasNoDuplicates(val), 'must have no duplicate values')
  }),
  simpleGenerator: () => {
    const inEachGroup = getRandomFromArray([1, 2, 3, 4, 5, 6, 10] as const);
    const groups =
      inEachGroup === 4 || inEachGroup === 6
        ? getRandomFromArray([2, 3, 5] as const)
        : randomIntegerInclusive(2, 6, { constraint: x => x !== inEachGroup });
    const isLotsOf = getRandomBoolean();

    const item = getRandomFromArray(
      inEachGroup > 5
        ? // TODO add pencils in when we get them for the 10
          (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : (['Apple', 'Pear', 'Banana', 'Donut', 'Egg', 'Pencil', 'Muffin', 'Fish'] as const)
    );

    const incorrectOptions = [inEachGroup + 1, inEachGroup - 1, groups + 1, groups - 1];

    const options = rejectionSample(
      () => {
        const incorrectOptions2 = getRandomSubArrayFromArray([...incorrectOptions] as const, 2);
        return [inEachGroup, groups, ...incorrectOptions2];
      },
      val => arrayHasNoDuplicates(val)
    );

    return { groups, inEachGroup, item, isLotsOf, options: shuffle(options) };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, item, isLotsOf, options },
      translate
    } = props;

    const svgName = getGroupSvgName(item, inEachGroup);

    return (
      <QF36ContentAndSentencesDrag
        title={translate.ks1Instructions.dragTheCardsToCompleteTheSentences()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheSentences()}
        actionPanelVariant="end"
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 20,
              ...dimens
            }}
          >
            {countRange(groups).map(idx => (
              <AssetSvg
                key={idx}
                name={svgName}
                height={dimens.height * 0.8}
                width={dimens.width / 7}
              />
            ))}
          </View>
        )}
        testCorrect={userAnswer =>
          arraysHaveSameContents(userAnswer[0], [groups, inEachGroup]) &&
          arraysHaveSameContentsUnordered(userAnswer[1], [groups, inEachGroup])
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [groups, inEachGroup],
            [groups, inEachGroup]
          ],
          answerText: translate.markScheme.factorsInAnyOrder()
        }}
        sentencesStyle={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        questionHeight={1000}
        sentences={[
          isLotsOf
            ? translate.ks1AnswerSentences.ansLotsOfAnsEqualsX(groups * inEachGroup)
            : translate.ks1AnswerSentences.ansGroupsOfAnsEqualsX(groups * inEachGroup),
          `<ans/> ${MULT} <ans/> = ${(groups * inEachGroup).toLocaleString()}`
        ]}
        items={options}
        pdfLayout="itemsTop"
        moveOrCopy="copy"
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biQ',
  description: 'biQ',
  keywords: ['Multiplication', 'Repeated addition', 'Lots of', 'Groups of', 'Counters'],
  schema: z.object({
    groups: z.number().int().min(2).max(5),
    inEachGroup: z.number().int().min(2).max(6),
    color: z.enum(['blue', 'green', 'red', 'yellow']),
    rotation: numberEnum([90, 180, 270, 0]),
    seed: z.number(),
    isLotsOf: z.boolean()
  }),
  simpleGenerator: () => {
    const inEachGroup = randomIntegerInclusive(2, 6);
    const groups =
      inEachGroup === 5 || inEachGroup === 2
        ? randomIntegerInclusive(2, 5)
        : getRandomFromArray([2, 3, 5]);

    const color = getRandomFromArray(['blue', 'green', 'red', 'yellow'] as const);
    const seed = randomIntegerInclusive(1, 10) * Math.random() * 10000;
    const rotation = inEachGroup > 3 ? getRandomFromArray([90, 180, 270, 0] as const) : 0;
    const isLotsOf = getRandomBoolean();

    return { groups, inEachGroup, seed, color, rotation, isLotsOf };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, seed, color, rotation, isLotsOf },
      translate
    } = props;

    const scale = getCounterArrangementScale(inEachGroup);
    const sentence1 = filledArray(inEachGroup, groups).join(` ${ADD} `);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) => {
          const boxDimen = Math.min(dimens.width * 0.18, dimens.height * 0.9);
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 20
              }}
            >
              {countRange(groups).map(idx => (
                <CounterBoxArrangement
                  key={`${idx}`}
                  counters={inEachGroup}
                  color={color}
                  dimens={{ height: boxDimen, width: boxDimen }}
                  seed={seed}
                  rotation={rotation}
                  scale={scale}
                />
              ))}
            </View>
          );
        }}
        testCorrect={[
          [(inEachGroup * groups).toString()],
          [(inEachGroup * groups).toString()],
          [(inEachGroup * groups).toString()]
        ]}
        style={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        pdfSentenceStyle={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        pdfDirection="column"
        questionHeight={1000}
        sentences={[
          `${sentence1} = <ans/>`,
          isLotsOf
            ? translate.ks1AnswerSentences.xLotsOfYEquals(groups, inEachGroup)
            : translate.ks1AnswerSentences.xGroupsOfYEquals(groups, inEachGroup),
          `${groups.toLocaleString()} ${MULT} ${inEachGroup.toLocaleString()} = <ans/>`
        ]}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'biR',
  description: 'biR',
  keywords: ['Multiplication', 'Lots of', 'Groups of'],
  schema: z.object({
    groups: z.number().int().min(2).max(6),
    inEachGroup: numberEnum([1, 2, 3, 4, 5, 6, 10]),
    item: z.enum([
      'Apple',
      'Pear',
      'Banana',
      'Donut',
      'Egg',
      'Pencil',
      'Muffin',
      'Cookie',
      'Fish',
      'MuffinRack'
    ]),
    isLotsOf: z.boolean()
  }),
  simpleGenerator: () => {
    const inEachGroup = getRandomFromArray([1, 2, 3, 4, 5, 6, 10] as const);
    const groups =
      inEachGroup === 4 || inEachGroup === 6
        ? getRandomFromArray([2, 3, 5])
        : randomIntegerInclusive(2, 6);
    const isLotsOf = getRandomBoolean();

    const item = getRandomFromArray(
      inEachGroup > 5
        ? // TODO add pencils in when we get them for the 10
          (['Fish', 'Donut', 'Egg', 'MuffinRack'] as const)
        : (['Apple', 'Pear', 'Banana', 'Donut', 'Egg', 'Pencil', 'Muffin', 'Fish'] as const)
    );
    return { groups, inEachGroup, item, isLotsOf };
  },
  Component: props => {
    const {
      question: { groups, inEachGroup, item, isLotsOf },
      translate
    } = props;

    const svgName = getGroupSvgName(item, inEachGroup);

    return (
      <QF1ContentAndSentences
        title={translate.ks1Instructions.completeTheSentences()}
        Content={({ dimens }) => (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 20,
              ...dimens
            }}
          >
            {countRange(groups).map(idx => (
              <AssetSvg
                key={idx}
                name={svgName}
                height={dimens.height * 0.8}
                width={dimens.width / 7}
              />
            ))}
          </View>
        )}
        testCorrect={[[(groups * inEachGroup).toString()], [(groups * inEachGroup).toString()]]}
        style={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        pdfSentenceStyle={{ alignItems: 'flex-end', alignSelf: 'flex-start' }}
        questionHeight={1000}
        pdfDirection="column"
        sentences={[
          isLotsOf
            ? translate.ks1AnswerSentences.xLotsOfYEquals(groups, inEachGroup)
            : translate.ks1AnswerSentences.xGroupsOfYEquals(groups, inEachGroup),
          `${groups.toLocaleString()} ${MULT} ${inEachGroup.toLocaleString()} = <ans/>`
        ]}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MultiplicationSentences',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
