import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { isEqual, isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { SUB } from '../../../../constants';
import { CrossOutBaseTen } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { BarModelWithState } from '../../../../components/question/representations/BarModel';
import { View } from 'react-native';
import { numbersExchange } from '../../../../utils/exchanges';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhn',
  description: 'bhn',
  keywords: ['Subtract', 'Base 10', 'Cross out'],
  schema: z
    .object({
      number1: z.number().int().min(22).max(98),
      number2: z.number().int().min(10).max(89)
    })
    .refine(
      ({ number1, number2 }) => isInRange(12, 79)(number1 - number2),
      'subtraction must be between 12 and 79'
    )
    .refine(
      ({ number1, number2 }) => number1 % 10 < number2 % 10,
      'number2 ones are larger than number1 ones'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        // 95 is the max we can fit in the width
        const number1 = randomIntegerInclusive(22, 95, { constraint: x => x % 10 !== 9 });
        const number2 = randomIntegerInclusive(10, 79, {
          constraint: x => number1 % 10 < x % 10
        });
        return { number1, number2 };
      },
      val => isInRange(12, 79)(val.number1 - val.number2)
    ),
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        title={`${translate.ks1Instructions.completeTheSubtraction()}<br/>${translate.ks1Instructions.youCanTapTheBase10ToCrossThemOut()}`}
        pdfTitle={translate.ks1Instructions.completeTheSubtraction()}
        inputMaxCharacters={2}
        pdfDirection="column"
        Content={({ dimens }) => (
          <CrossOutBaseTen
            dimens={dimens}
            tens={Math.floor(number1 / 10 - 1)}
            ones={(number1 % 10) + 10}
            // This will only be used in pdf
            scale={number1 > 50 ? 0.5 : undefined}
          />
        )}
        questionHeight={1000}
        sentence={`${number1.toLocaleString()} ${SUB} ${number2.toLocaleString()} = <ans/>`}
        testCorrect={[(number1 - number2).toString()]}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bho',
  description: 'bho',
  keywords: ['Subtract'],
  schema: z
    .object({
      number1: z
        .number()
        .int()
        .min(22)
        .max(98)
        .refine(val => val % 10 !== 0 && val % 10 !== 9),
      number2: z.number().int().min(11).max(98),
      options: z.number().int().min(1).max(99).array().length(4).refine(arrayHasNoDuplicates)
    })
    .refine(
      val => numbersExchange(val.number1, -val.number2),
      'number1 must have greater than or equal to the number of number2 tens and less than number2 ones'
    )
    .refine(val => isInRange(1, 88)(val.number1 - val.number2)),
  simpleGenerator: () => {
    // cant end in 9 because number2 needs to have greater ones
    const number1 = randomIntegerInclusive(22, 98, {
      constraint: x => x % 10 !== 0 && x % 10 !== 9
    });
    const number2 = randomIntegerInclusive(11, number1 - 1, {
      constraint: x =>
        Math.floor(number1 / 10) >= Math.floor(x / 10) &&
        number1 % 10 < x % 10 &&
        isInRange(1, 88)(number1 - x)
    });

    const answer = number1 - number2;

    const differenceInTens = Math.floor(number1 / 10) - Math.floor(number2 / 10);
    const differenceInOnes = (number1 % 10) - (number2 % 10);

    const options = [
      answer,
      ...getRandomSubArrayFromArray(
        [
          ...new Set(
            [
              answer + 10,
              answer - 10,
              answer + 1,
              answer - 1,
              differenceInTens * 10 + differenceInOnes
            ].filter(val => val > 0 && val !== answer)
          )
        ],
        3
      )
    ];
    return {
      number1,
      number2,
      options: shuffle(options)
    };
  },
  Component: ({ question, translate }) => {
    const { number1, number2, options } = question;

    return (
      <QF10SelectNumbers
        title={`${translate.ks1Instructions.whatIsXOperationY(
          number1,
          SUB,
          number2
        )}<br/>${translate.ks1Instructions.selectYourAnswer()}`}
        pdfTitle={`${translate.ks1Instructions.whatIsXOperationY(
          number1,
          SUB,
          number2
        )}<br/>${translate.ks1PDFInstructions.tickTheCorrectAnswer()}`}
        items={options}
        testCorrect={[number1 - number2]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhp',
  description: 'bhp',
  keywords: ['Subtract', 'Bar model'],
  schema: z
    .object({
      isAnsLeft: z.boolean(),
      number1: z
        .number()
        .int()
        .min(32)
        .max(99)
        .refine(val => val % 10 !== 9),
      number2: z.number().int().min(11).max(98),
      isArrow: z.boolean()
    })
    .refine(
      val => numbersExchange(val.number1, -val.number2),
      'number1 must have greater than the number of tens in number2 and less than number2s ones'
    )
    .refine(val => isInRange(12, 79)(val.number1 - val.number2)),
  simpleGenerator: () => {
    // cant end in 9 because number2 needs to have greater ones
    const number1 = randomIntegerInclusive(32, 99, {
      constraint: x => x % 10 !== 9
    });
    const number2 = randomIntegerInclusive(11, number1 - 1, {
      constraint: x =>
        Math.floor(number1 / 10) > Math.floor(x / 10) &&
        number1 % 10 < x % 10 &&
        isInRange(12, 79)(number1 - x)
    });

    const isAnsLeft = getRandomBoolean();
    const isArrow = isAnsLeft ? getRandomBoolean() : false;

    return {
      number1,
      number2,
      isAnsLeft,
      isArrow
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { number1, number2, isAnsLeft, isArrow } = question;

    const [topRow, bottomRow] = [[number1], [number2, number1 - number2]];

    const answerIndices = isAnsLeft ? [[], [0]] : [[], [1]];
    const arrowIndices = isAnsLeft ? [[1]] : [[0]];

    return (
      <QF3Content
        title={translate.ks1Instructions.completeTheBarModel()}
        actionPanelVariant="endWide"
        inputType="numpad"
        Content={({ dimens }) => {
          return isArrow ? (
            <View style={{ gap: 50 }}>
              <BarModelWithState
                numbers={[topRow]}
                total={number1}
                dimens={dimens}
                answerIndices={[answerIndices[0]]}
                maxFontSize={displayMode === 'digital' ? 32 : 50}
                id={'barModel-B'}
              />
              <BarModelWithState
                id="bar-model-Arrow"
                numbers={[bottomRow]}
                answerIndices={[answerIndices[1]]}
                total={number1}
                dimens={dimens}
                maxFontSize={displayMode === 'digital' ? 32 : 50}
                oneFontSize
                testCorrect={userAnswer =>
                  isEqual(bottomRow[isAnsLeft ? 0 : 1].toString())(userAnswer[0][isAnsLeft ? 0 : 1])
                }
                arrowIndices={arrowIndices}
                testComplete={userAnswer =>
                  answerIndices[1].every((_, rowIndex) => userAnswer[0][rowIndex] !== '')
                }
                defaultState={
                  displayMode === 'markscheme'
                    ? [bottomRow.map(val => val.toLocaleString())]
                    : [['', '']]
                }
              />
            </View>
          ) : (
            <BarModelWithState
              id="bar-model-A"
              numbers={[topRow, bottomRow]}
              answerIndices={answerIndices}
              total={number1}
              dimens={dimens}
              maxFontSize={displayMode === 'digital' ? 32 : 50}
              oneFontSize
              testCorrect={userAnswer =>
                isEqual(bottomRow[isAnsLeft ? 0 : 1].toString())(userAnswer[1][isAnsLeft ? 0 : 1])
              }
              testComplete={userAnswer =>
                answerIndices.every((row, rowIndex) =>
                  row.every(pos => {
                    const userString = (userAnswer[rowIndex] ?? [])[pos] ?? '';
                    return userString !== '';
                  })
                )
              }
              defaultState={
                displayMode === 'markscheme'
                  ? [[''], bottomRow.map(val => val.toLocaleString())]
                  : [[''], ['', '']]
              }
            />
          );
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractTwo2DigitNumbersAcrossA10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
