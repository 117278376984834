import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import { countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { LinesOfSymmetry } from '../../../../components/question/representations/LinesOfSymmetry';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  shuffle
} from '../../../../utils/random';
import {
  equalQuarterShadedShapes,
  equalQuarterShadedShapesSvgNameSchema,
  getRandomEqualQuarterShadedShapesSvgName,
  getRandomUnequalQuarterShadedShapesSvgName,
  unequalQuarterShadedShapes,
  unequalQuarterShadedShapesSvgNameSchema
} from '../../../../utils/shapes';
import { DIAGONAL_LEFT, DIAGONAL_RIGHT, HORIZONTAL, VERTICAL } from '../../../../constants';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bew',
  description: 'bew',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z.object({
    options: z
      .array(
        z.object({
          offset: z.number(),
          linesOfSymmetry: z.array(z.enum([HORIZONTAL, VERTICAL, DIAGONAL_LEFT, DIAGONAL_RIGHT]))
        })
      )
      .length(4),
    object: z.enum(['chocolateBar', 'pizza'])
  }),
  simpleGenerator: () => {
    const object = getRandomFromArray(['chocolateBar', 'pizza'] as const);

    const numbers = randomUniqueIntegersInclusive(5, 13, 3, {
      constraint: x => x !== 10
    });

    const offsets = numbers.map(num => num / 10);

    const numberOfObjectsHalved = getRandomFromArray([1, 2] as const);

    const correctWaysToQuarter = [
      [HORIZONTAL, VERTICAL] as const,
      [DIAGONAL_LEFT, DIAGONAL_RIGHT] as const
    ];

    const incorrectWaysToQuarter = getRandomSubArrayFromArray(
      [
        [HORIZONTAL, VERTICAL] as const,
        [HORIZONTAL, DIAGONAL_LEFT] as const,
        [HORIZONTAL, DIAGONAL_RIGHT] as const,
        [VERTICAL, DIAGONAL_LEFT] as const,
        [VERTICAL, DIAGONAL_RIGHT] as const
      ],
      4 - numberOfObjectsHalved
    );

    const waysToQuarter = getRandomSubArrayFromArray(correctWaysToQuarter, numberOfObjectsHalved);

    const correctOptions = waysToQuarter.map(symmetry => {
      return {
        linesOfSymmetry: [...symmetry],
        offset: 1
      };
    });

    const incorrectOptions = countRange(4 - numberOfObjectsHalved).map((_, index) => {
      return {
        linesOfSymmetry: [...incorrectWaysToQuarter[index]],
        offset: offsets[index]
      };
    });

    const options = shuffle([...correctOptions, ...incorrectOptions]);
    return { object, options };
  },
  Component: props => {
    const {
      question: { object, options },
      translate
    } = props;

    const objectsHalved = options.filter(option => option.offset === 1);
    const numberOfObjectsHalved = objectsHalved.length;

    const objectTranslated = (() => {
      switch (object) {
        case 'chocolateBar':
          return numberOfObjectsHalved > 1
            ? translate.objects.ChocolateBars()
            : translate.objects.ChocolateBar();
        default:
          return numberOfObjectsHalved > 1 ? translate.objects.Pizzas() : translate.objects.Pizza();
      }
    })();

    const title =
      numberOfObjectsHalved > 1
        ? translate.ks1Instructions.selectTheXThatAreCutIntoYEqualParts(objectTranslated, 4)
        : translate.ks1Instructions.selectTheXThatIsCutIntoYEqualParts(objectTranslated, 4);

    const pdfTitle =
      numberOfObjectsHalved > 1
        ? translate.ks1PDFInstructions.circleTheXThatAreCutIntoYEqualParts(objectTranslated, 4)
        : translate.ks1PDFInstructions.circleTheXThatIsCutIntoYEqualParts(objectTranslated, 4);
    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={objectsHalved}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map(option => ({
            value: option,
            component: (
              <LinesOfSymmetry
                shape={object}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                linesOfSymmetry={option.linesOfSymmetry}
                offset={option.offset}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bex',
  description: 'bex',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z.object({
    options: z
      .array(
        z.object({
          offset: z.number(),
          linesOfSymmetry: z.array(z.enum([HORIZONTAL, VERTICAL, DIAGONAL_LEFT, DIAGONAL_RIGHT]))
        })
      )
      .length(4),
    object: z.enum(['circle', 'square'])
  }),
  simpleGenerator: () => {
    const numbers = randomUniqueIntegersInclusive(5, 13, 3, {
      constraint: x => x !== 10
    });

    const offsets = numbers.map(num => num / 10);

    const object = getRandomFromArray(['circle', 'square'] as const);

    const numberOfObjectsHalved = getRandomFromArray([1, 2] as const);

    const correctWaysToQuarter = [
      [HORIZONTAL, VERTICAL] as const,
      [DIAGONAL_LEFT, DIAGONAL_RIGHT] as const
    ];

    const incorrectWaysToQuarter = [
      [HORIZONTAL, VERTICAL] as const,
      [HORIZONTAL, DIAGONAL_LEFT] as const,
      [HORIZONTAL, DIAGONAL_RIGHT] as const,
      [VERTICAL, DIAGONAL_LEFT] as const,
      [VERTICAL, DIAGONAL_RIGHT] as const
    ];

    const waysToQuarter = getRandomSubArrayFromArray(correctWaysToQuarter, numberOfObjectsHalved);

    const correctOptions = waysToQuarter.map(symmetry => {
      return {
        linesOfSymmetry: [...symmetry],
        offset: 1
      };
    });

    const incorrectOptions = countRange(4 - numberOfObjectsHalved).map((_, index) => {
      return {
        linesOfSymmetry: [...incorrectWaysToQuarter[index]],
        offset: offsets[index]
      };
    });

    const options = shuffle([...correctOptions, ...incorrectOptions]);
    return { object, options };
  },
  Component: props => {
    const {
      question: { object, options },
      translate
    } = props;

    const objectsHalved = options.filter(option => option.offset === 1);
    const numberOfObjectsHalved = objectsHalved.length;

    const objectTranslated =
      object === 'circle'
        ? translate.shapes.circles(numberOfObjectsHalved)
        : translate.shapes.squares(numberOfObjectsHalved);

    const title =
      numberOfObjectsHalved > 1
        ? translate.ks1Instructions.selectTheShapesThatAreCutIntoQuarters(objectTranslated)
        : translate.ks1Instructions.selectTheShapeThatIsCutIntoQuarters(objectTranslated);

    const pdfTitle =
      numberOfObjectsHalved > 1
        ? translate.ks1PDFInstructions.tickTheShapesThatAreCutIntoQuarters(objectTranslated)
        : translate.ks1PDFInstructions.tickTheShapeThatIsCutIntoQuarters(objectTranslated);

    return (
      <QF11SelectImagesUpTo4
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={objectsHalved}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map(option => ({
            value: option,
            component: (
              <LinesOfSymmetry
                shape={object}
                dimens={{ height: dimens.height * 0.8, width: dimens.width * 0.8 }}
                linesOfSymmetry={option.linesOfSymmetry}
                offset={option.offset}
              />
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bey',
  description: 'bey',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z.object({
    options: z
      .array(
        z.object({
          shape: z.union([
            equalQuarterShadedShapesSvgNameSchema,
            unequalQuarterShadedShapesSvgNameSchema
          ]),
          isEqual: z.boolean(),
          rotation: numberEnum([0, 90, 180, 270])
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2, 3]);

    const correctShapeSvgs = getRandomSubArrayFromArray(
      equalQuarterShadedShapes,
      numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomEqualQuarterShadedShapesSvgName(shape),
        isEqual: true,
        rotation: shape === 'Rectangle' ? 0 : getRandomFromArray([0, 90, 180, 270] as const)
      };
    });

    const incorrectShapesSvgs = getRandomSubArrayFromArray(
      unequalQuarterShadedShapes,
      4 - numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomUnequalQuarterShadedShapesSvgName(shape),
        isEqual: false,
        rotation: shape === 'Rectangle' ? 0 : getRandomFromArray([0, 90, 180, 270] as const)
      };
    });

    const options = shuffle([...correctShapeSvgs, ...incorrectShapesSvgs]);

    return { options };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    const numberOfCorrectAnswers = options.filter(it => it.isEqual).length;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheDiagramsThatShowAQuarter(numberOfCorrectAnswers)}
        pdfTitle={translate.ks1PDFInstructions.circleTheDiagramsThatShowAQuarter(
          numberOfCorrectAnswers
        )}
        testCorrect={options.flatMap((el, index) => (el.isEqual ? [index] : []))}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map((option, index) => ({
            value: index,
            component: (
              <View style={{ transform: `rotate(${option.rotation}deg)` }}>
                <AssetSvg height={dimens.height * 0.7} name={option.shape} />
              </View>
            )
          }));
        }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 900
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAQuarterOfAnObjectOrAShape',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
