import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  rejectionSample
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { JugWithLiquid } from '../../../../components/question/representations/JugWithLiquid';
import { getRandomKs1Name, ks1NameSchema } from '../../../../utils/names';
import QF13DragLiquidInJug from '../../../../components/question/questionFormats/QF13DragLiquidInJug';
import { numbersDoNotExchange } from '../../../../utils/exchanges';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bj2',
  description: 'bj2',
  keywords: ['Volume', 'Measure', 'Millilitres', 'Four operations'],
  schema: z
    .object({
      intervals: numberEnum([2, 5, 10]),
      volumes: z.array(z.number().int().min(2).max(100)).length(2)
    })
    .refine(
      ({ volumes: [volA, volB] }) => volA < volB && numbersDoNotExchange(volB, -volA),
      'second volume should greater than first and getting the difference should not include an exchange'
    )
    .refine(
      ({ intervals, volumes }) => volumes.map(vol => vol % intervals === 0),
      'volumes should be at an interval'
    ),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([2, 5, 10] as const);

    const capacity = intervals === 10 ? 100 : intervals === 5 ? 50 : 24;

    const volumes = rejectionSample(
      () => {
        const volB = randomIntegerInclusiveStep(intervals * 2, capacity, intervals);

        const volA = randomIntegerInclusiveStep(intervals, volB - intervals, intervals);

        return [volA, volB];
      },
      ([volA, volB]) => numbersDoNotExchange(volB, -volA)
    );

    return { volumes, intervals };
  },
  Component: props => {
    const {
      question: { volumes, intervals },
      translate
    } = props;

    const difference = volumes[1] - volumes[0];

    const A = translate.letters.A();
    const B = translate.letters.B();
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howMuchMoreWaterIsInJugXThanJugY(B, A)}
        sentence={translate.ks1AnswerSentences.ansMl()}
        testCorrect={[difference.toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            {volumes.map((vol, i) => (
              <View key={i}>
                <Text variant="WRN400" style={{ textAlign: 'center' }}>
                  {[A, B][i]}
                </Text>
                <JugWithLiquid
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
                  jugCapacity={intervals === 2 ? 24 : intervals * 10}
                  liquidAmount={vol}
                  liquidType="water"
                  tickValue={intervals}
                  labelUnits="ml"
                />
              </View>
            ))}
          </View>
        )}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bj3',
  description: 'bj3',
  keywords: ['Volume', 'Measure', 'Millilitres', 'Four operations'],
  schema: z
    .object({
      intervals: numberEnum([2, 5, 10]),
      volumes: z.array(z.number().int().min(2).max(100))
    })
    .refine(
      ({ volumes: [volA, volB] }) => numbersDoNotExchange(volA, volB) && volA + volB <= 100,
      'getting the total should not include an exchange'
    )
    .refine(
      ({ intervals, volumes }) => volumes.map(vol => vol % intervals === 0),
      'volumes should be at an interval'
    ),
  simpleGenerator: () => {
    const intervals = getRandomFromArray([2, 5, 10] as const);

    const capacity = intervals === 10 ? 100 : intervals === 5 ? 50 : 24;

    const volumes = rejectionSample(
      () => randomUniqueIntegersInclusiveStep(intervals, capacity - intervals, intervals, 2),
      ([volA, volB]) => numbersDoNotExchange(volA, volB)
    );

    return { volumes, intervals };
  },
  Component: props => {
    const {
      question: { volumes, intervals },
      translate
    } = props;

    const total = volumes[0] + volumes[1];

    const A = translate.letters.A();
    const B = translate.letters.B();
    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsTheTotalVolumeOfWater()}
        sentence={translate.ks1AnswerSentences.ansMl()}
        testCorrect={[total.toString()]}
        sentenceStyle={{ alignSelf: 'flex-end' }}
        pdfDirection="row"
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        questionHeight={900}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly'
            }}
          >
            {volumes.map((vol, i) => (
              <View key={i}>
                <Text variant="WRN400" style={{ textAlign: 'center' }}>
                  {[A, B][i]}
                </Text>
                <JugWithLiquid
                  dimens={{ width: dimens.width * 0.5, height: dimens.height * 0.8 }}
                  jugCapacity={intervals === 2 ? 24 : intervals * 10}
                  liquidAmount={vol}
                  liquidType="water"
                  tickValue={intervals}
                  labelUnits="ml"
                />
              </View>
            ))}
          </View>
        )}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'bj4',
  description: 'bj4',
  keywords: ['Volume', 'Measure', 'Millilitres'],
  schema: z
    .object({
      name: ks1NameSchema,
      intervals: numberEnum([5, 10]),
      spoonCapacity: numberEnum([5, 10]),
      numberOfSpoons: z.number().int().min(2).max(10)
    })
    .refine(
      ({ spoonCapacity, numberOfSpoons, intervals }) =>
        numberOfSpoons * spoonCapacity <= 10 * intervals,
      'total amount should not exceed beaker capacity'
    ),
  simpleGenerator: () => {
    const name = getRandomKs1Name();
    const intervals = getRandomFromArray([5, 10] as const);
    const spoonCapacity = getRandomFromArray([5, 10] as const);

    const numberOfSpoons = randomIntegerInclusive(
      2,
      Math.min(10, (intervals / spoonCapacity) * 10)
    );

    return { name, intervals, spoonCapacity, numberOfSpoons };
  },
  Component: props => {
    const {
      question: { name, intervals, spoonCapacity, numberOfSpoons },
      translate
    } = props;

    const title =
      spoonCapacity === 10 ? 'aSpoonHoldsXYPoursZIntoBeaker' : 'aTeaspoonHoldsXYPoursZIntoBeaker';

    const spoonCapacityString = translate.units.numberOfMl(spoonCapacity);

    return (
      <QF13DragLiquidInJug
        title={`${translate.ks1Instructions[title](
          spoonCapacityString,
          name,
          numberOfSpoons
        )}<br/>${translate.ks1Instructions.dragTheArrowToShowWhereTheWaterReaches()}`}
        pdfTitle={`${translate.ks1Instructions[title](
          spoonCapacityString,
          name,
          numberOfSpoons
        )}<br/>${translate.ks1PDFInstructions.drawALineToShowWhereTheWaterReaches()}`}
        testCorrect={spoonCapacity * numberOfSpoons}
        jugCapacity={intervals * 10}
        tickValue={intervals}
        liquidType="water"
        labelUnits="ml"
        unitsPerMajorTick={intervals === 10 ? 1 : 0}
        containerType="labelledGlass"
        drawLiquidLine
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FourOperationsWithVolumeAndCapacity',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
