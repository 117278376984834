import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from '../../../../utils/random';
import { filledArray, rangeAsString, sortNumberArray } from '../../../../utils/collections';
import QF17CompleteTheNumberLine from '../../../../components/question/questionFormats/QF17CompleteTheNumberLine';
import { numberEnum } from '../../../../utils/zod';
import { integerToWord } from '../../../../utils/math';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import { isInRange } from '../../../../utils/matchers';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdg',
  description: 'bdg',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: z.number().int().min(20).max(42),
    numberLineLength: z.number().int().min(8).max(12),
    numbers: z.array(z.number().int().min(20).max(50))
  }),
  simpleGenerator: () => {
    const numberLineLength = randomIntegerInclusive(8, 12);
    const startingNumber = randomIntegerInclusive(20, 50 - numberLineLength);

    const numbers = randomUniqueIntegersInclusive(
      startingNumber,
      startingNumber + numberLineLength,
      randomIntegerInclusive(3, 5)
    );

    return { startingNumber, numberLineLength, numbers: sortNumberArray(numbers) };
  },
  Component: props => {
    const {
      question: { startingNumber, numberLineLength, numbers },
      translate
    } = props;

    // Create array to pass to Number Line
    const tickValues = rangeAsString(startingNumber, startingNumber + numberLineLength, 1, true);

    // Set where the answers should go
    numbers.forEach(number => {
      tickValues[tickValues.indexOf(number.toLocaleString())] = '<ans/>';
    });

    return (
      <QF17CompleteTheNumberLine
        title={translate.instructions.completeNumberLine()}
        testCorrect={numbers.map(it => it.toString())}
        tickValues={tickValues}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdh',
  description: 'bdh',
  keywords: ['Number line'],
  schema: z.object({
    startingNumber: numberEnum([20, 30, 40]),
    number: z.number().int().min(21).max(49)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([20, 30, 40] as const);
    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);

    return { startingNumber, number };
  },
  Component: props => {
    const {
      question: { startingNumber, number },
      translate
    } = props;

    // Create array of empty strings
    const tickValues: (number | null)[] = filledArray(null, 11);
    // Set start and end numbers
    tickValues[0] = startingNumber;
    tickValues[tickValues.length - 1] = startingNumber + 10;

    return (
      <QF17CompleteTheNumberLine
        title={translate.ks1Instructions.whatNumberIsTheArrowPointingTo()}
        testCorrect={[number.toString()]}
        freeNumberLineAnswer={[number]}
        tickValues={tickValues}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdi',
  description: 'bdi',
  keywords: ['Number line', 'More', 'Less', 'Greater than', 'Less than'],
  schema: z.object({
    startingNumber: numberEnum([20, 30, 40]),
    number: z.number().int().min(21).max(49),
    direction: z.enum(['higher', 'lower', 'greater', 'less']),
    useNumbers: z.boolean()
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([20, 30, 40] as const);
    const direction = getRandomFromArray(['higher', 'lower', 'greater', 'less'] as const);
    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);
    const useNumbers = getRandomBoolean();

    return { startingNumber, number, direction, useNumbers };
  },
  Component: props => {
    const {
      question: { startingNumber, number, direction, useNumbers },
      translate
    } = props;

    // Create array of empty strings
    const tickValues = rangeAsString(startingNumber, startingNumber + 10, 1, true);
    const baseNumber = direction === 'higher' ? number - 1 : number + 1;

    const { title, pdfTitle, lowAnswer, highAnswer } = (() => {
      switch (direction) {
        case 'higher':
          return {
            title: useNumbers
              ? translate.ks1Instructions.dragTheArrowTo1MoreThanNumberAsNumber(baseNumber)
              : translate.ks1Instructions.dragTheArrowToOneMoreThanNumberAsWord(
                  integerToWord(baseNumber)
                ),
            pdfTitle: useNumbers
              ? translate.ks1PDFInstructions.circle1MoreThanNumberAsNumber(baseNumber)
              : translate.ks1PDFInstructions.circleOneMoreThanNumberAsWord(
                  integerToWord(baseNumber)
                ),
            lowAnswer: number,
            highAnswer: number
          };
        case 'lower':
          return {
            title: useNumbers
              ? translate.ks1Instructions.dragTheArrowTo1LessThanNumberAsNumber(baseNumber)
              : translate.ks1Instructions.dragTheArrowToOneLessThanNumberAsWord(
                  integerToWord(baseNumber)
                ),
            pdfTitle: useNumbers
              ? translate.ks1PDFInstructions.circle1LessThanNumberAsNumber(baseNumber)
              : translate.ks1PDFInstructions.circleOneLessThanNumberAsWord(
                  integerToWord(baseNumber)
                ),
            lowAnswer: number,
            highAnswer: number
          };
        case 'greater': {
          const lowAnswer = number;
          const highAnswer = startingNumber + 10;

          return {
            title: useNumbers
              ? translate.ks1Instructions.dragTheArrowGreaterThanNumberAsNumber(lowAnswer - 1)
              : translate.ks1Instructions.dragTheArrowGreaterThanNumberAsWord(
                  integerToWord(lowAnswer - 1)
                ),
            pdfTitle: useNumbers
              ? translate.ks1PDFInstructions.circleGreaterThanNumberAsNumber(lowAnswer - 1)
              : translate.ks1PDFInstructions.circleGreaterThanNumberAsWord(
                  integerToWord(lowAnswer - 1)
                ),
            lowAnswer,
            highAnswer
          };
        }
        case 'less': {
          const lowAnswer = startingNumber;
          const highAnswer = number;

          return {
            title: useNumbers
              ? translate.ks1Instructions.dragTheArrowLessThanNumberAsNumber(highAnswer + 1)
              : translate.ks1Instructions.dragTheArrowLessThanNumberAsWord(
                  integerToWord(highAnswer + 1)
                ),
            pdfTitle: useNumbers
              ? translate.ks1PDFInstructions.circleLessThanNumberAsNumber(highAnswer + 1)
              : translate.ks1PDFInstructions.circleLessThanNumberAsWord(
                  integerToWord(highAnswer + 1)
                ),
            lowAnswer,
            highAnswer
          };
        }
      }
    })();

    return (
      <QF19NumberLineDragArrow
        title={title}
        pdfTitle={pdfTitle}
        testCorrect={isInRange(lowAnswer, highAnswer)}
        min={startingNumber}
        max={startingNumber + 10}
        sliderStep={1}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answerToDisplay: number,
          answerText: translate.markScheme.answersMayVaryTheyAreEstimates()
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'TheNumberLineTo50',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
