import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import { sortNumberArray } from '../../../../utils/collections';
import {
  areCollinear,
  isIntersecting,
  isValidRectangle,
  isValidSquare
} from '../../../../utils/shapes';
import QF45aDrawShapeOnSquareDottedPaper from '../../../../components/question/questionFormats/QF45aDrawShapeOnSquareDottedPaper';
import { GridPolygon } from '../../../../utils/gridUtils';
import { colors } from '../../../../theme/colors';
import QF3Content from '../../../../components/question/questionFormats/QF3Content';
import { PieChartInteractiveWithState } from '../../../../components/question/representations/PieChartInteractive';
import { isEqual } from '../../../../utils/matchers';
import { numberEnum } from '../../../../utils/zod';
import { CreateShapeFromSquaresWithState } from '../../../../components/question/representations/CreateShapeFromSquares';
import { View } from 'react-native';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import { AssetSvg } from '../../../../assets/svg';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bez',
  description: 'bez',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  questionHeight: 1000,
  schema: z
    .object({
      shape: z.enum([
        'circle',
        'squareDiagonal',
        'squareGrid',
        'rectangleGrid',
        'rectangle',
        'square'
      ]),
      startingAngle: numberEnum([0, 45, 90])
    })
    .refine(
      val =>
        (['circle', 'squareDiagonal'].includes(val.shape) && val.startingAngle % 45 === 0) ||
        (!['circle', 'squareDiagonal'].includes(val.shape) && val.startingAngle % 90 === 0)
    ),
  simpleGenerator: () => {
    const shape = getRandomFromArray([
      'circle',
      'squareDiagonal',
      'squareGrid',
      'rectangleGrid',
      'rectangle',
      'square'
    ] as const);

    const startingAngle = ['circle', 'squareDiagonal'].includes(shape)
      ? getRandomFromArray([0, 45] as const)
      : getRandomFromArray([0, 90] as const);

    return { shape, startingAngle };
  },
  Component: ({ question, translate, displayMode }) => {
    const { shape, startingAngle } = question;

    return (
      <QF3Content
        inputType="numpad"
        questionHeight={1000}
        title={translate.ks1Instructions.tapShapeToShadeInAQuarterOfTheShape()}
        pdfTitle={translate.ks1PDFInstructions.shadeInAQuarterOfTheShape()}
        Content={({ dimens }) => {
          switch (shape) {
            case 'circle':
            case 'squareDiagonal':
              return (
                <PieChartInteractiveWithState
                  numberOfSlices={4}
                  radius={Math.min(dimens.width, dimens.height) * 0.5}
                  options={[{ color: colors.burntSienna, label: '' }]}
                  id={'pieChart'}
                  noCurve={shape === 'squareDiagonal'}
                  testCorrect={userAnswer => isEqual(userAnswer.filter(val => val === 1).length)(1)}
                  testComplete={userAnswer => userAnswer.some(it => it !== 0)}
                  borderWidth={3}
                  startAngle={startingAngle}
                  defaultState={displayMode === 'markscheme' ? [1, 0, 0, 0] : undefined}
                  showShadingInMarkScheme
                />
              );
            case 'squareGrid':
            case 'rectangleGrid':
            case 'rectangle':
            case 'square': {
              const squareDimens = Math.min(dimens.width, dimens.height);
              const usableDimens =
                shape === 'square'
                  ? { width: squareDimens, height: squareDimens }
                  : startingAngle === 90
                  ? { width: dimens.height * 0.8, height: dimens.width * 0.8 }
                  : { width: dimens.width * 0.8, height: dimens.height * 0.8 };
              return (
                <View style={{ transform: `rotate(${startingAngle}deg)` }}>
                  <CreateShapeFromSquaresWithState
                    dimens={usableDimens}
                    numberOfRows={['squareGrid', 'rectangleGrid'].includes(shape) ? 2 : 1}
                    numberOfCols={['squareGrid', 'rectangleGrid'].includes(shape) ? 2 : 4}
                    id={'squareGridA'}
                    isSquare={shape === 'squareGrid'}
                    testCorrect={userAnswer =>
                      isEqual(userAnswer.flat().filter(val => val).length)(1)
                    }
                    defaultState={
                      displayMode === 'markscheme' ? [[true, false, false, false]] : undefined
                    }
                  />
                </View>
              );
            }
          }
        }}
        customMarkSchemeAnswer={{ answerText: translate.markScheme.anyXPartsShaded(1) }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'beA',
  description: 'beA',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter', 'Rectangle'],
  schema: z.object({
    x1: z.number(),
    x2: z.number(),
    y1: z.number(),
    y2: z.number()
  }),
  simpleGenerator: () => {
    const { x1, x2, y1, y2 } = rejectionSample(
      () => {
        const xCoords = randomUniqueIntegersInclusive(1, 9, 2);
        const yCoords = randomUniqueIntegersInclusive(1, 5, 2);

        const [x1, x2] = sortNumberArray(xCoords, 'ascending');
        const [y1, y2] = sortNumberArray(yCoords, 'ascending');

        return {
          x1,
          x2,
          y1,
          y2
        };
      },
      ({ x1, x2, y1, y2 }) => {
        return (
          isValidRectangle(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          !isValidSquare(
            [x1, y1] as [number, number],
            [x1, y2] as [number, number],
            [x2, y2] as [number, number],
            [x2, y1] as [number, number]
          ) &&
          (y2 - y1) % 2 === 0 &&
          (x2 - x1) % 2 === 0
        );
      }
    );

    return { x1, x2, y1, y2 };
  },
  Component: ({ translate, question: { x1, x2, y1, y2 }, displayMode }) => {
    return (
      <QF45aDrawShapeOnSquareDottedPaper
        title={translate.ks1Instructions.tapFourDotsToSplitTheShapeInQuarters()}
        pdfTitle={translate.ks1PDFInstructions.drawTwoStraightLinesToSplitTheShapeIntoQuarters()}
        numPoints={4}
        drawMultipleLines
        gridChildren={
          <GridPolygon
            points={[
              [x1, y1],
              [x1, y2],
              [x2, y2],
              [x2, y1]
            ]}
            color={displayMode === 'digital' ? `${colors.prussianBlue}40` : `${colors.grey}60`}
            showBorder
          />
        }
        testCorrect={userAnswer => {
          const [point1, point2, point3, point4] = userAnswer;

          if (!point1 || !point2 || !point3 || !point4) return false;

          const rectangleLength = x2 - x1 + 1;
          const rectangleHeight = y2 - y1 + 1;

          const xHalfWay = x1 + Math.floor(rectangleLength / 2);
          const yHalfWay = y1 + Math.floor(rectangleHeight / 2);

          const lines = [
            [point1, point2],
            [point3, point4]
          ];

          // check its split vertically
          const isVerticallySplit = lines.some(
            ([p1, p2]) =>
              p1.x === xHalfWay &&
              p2.x === xHalfWay &&
              Math.min(p1.y, p2.y) <= y1 &&
              Math.max(p1.y, p2.y) >= y2
          );

          // check its split horizontally
          const isHorizontallySplit = lines.some(
            ([p1, p2]) =>
              p1.y === yHalfWay &&
              p2.y === yHalfWay &&
              Math.min(p1.x, p2.x) <= x1 &&
              Math.max(p1.x, p2.x) >= x2
          );

          // shape points
          const points = [
            { x: x1, y: y1 },
            { x: x1, y: y2 },
            { x: x2, y: y2 },
            { x: x2, y: y1 }
          ];

          const isLineADiagonal =
            point1.x > point2.x
              ? areCollinear(
                  [point1.x, point1.y],
                  [point2.x, point2.y],
                  [points[0].x, points[0].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear([point1.x, point1.y], [point2.x, point2.y], [points[1].x, points[1].y])
              : areCollinear(
                  [point2.x, point2.y],
                  [point1.x, point1.y],
                  [points[0].x, points[0].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear(
                  [point2.x, point2.y],
                  [point1.x, point1.y],
                  [points[1].x, points[1].y]
                );

          const isLineBDiagonal =
            point3.x > point4.x
              ? areCollinear(
                  [point3.x, point3.y],
                  [point4.x, point4.y],
                  [points[1].x, points[1].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear([point3.x, point3.y], [point4.x, point4.y], [points[0].x, points[0].y])
              : areCollinear(
                  [point4.x, point4.y],
                  [point3.x, point3.y],
                  [points[1].x, points[1].y]
                ) ||
                // Either line can pass through either set of corners
                areCollinear(
                  [point4.x, point4.y],
                  [point3.x, point3.y],
                  [points[0].x, points[0].y]
                );

          const isOutsideRectangle = ({ x, y }: { x: number; y: number }) =>
            x <= x1 || x >= x2 || y <= y1 || y >= y2;

          const isDiagonal = isLineADiagonal && isLineBDiagonal;

          const arePointsOutsideRectangle = points.every(point => isOutsideRectangle(point));

          const doLinesIntersect = isIntersecting(point1, point2, point3, point4);

          // Check if lines isIntersect and all lines are outside the rectangle
          const doLinesPassOtherChecks = arePointsOutsideRectangle && doLinesIntersect;

          if (!doLinesPassOtherChecks) {
            return false;
          }

          return isDiagonal || (isVerticallySplit && isHorizontallySplit);
        }}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyValidAnswer(),
          answersToDisplay: [
            // vertical line
            { x: (x2 + x1) / 2, y: y1 },
            { x: (x2 + x1) / 2, y: y2 },
            // horizontal line
            { x: x1, y: (y1 + y2) / 2 },
            { x: x2, y: (y1 + y2) / 2 }
          ]
        }}
      />
    );
  }
});

const contentSvgNames = [
  'Circles/Circle_quarter_white',
  'Rectangle/rectangle_white',
  'Square/square_white',
  'Equilateral_triangles/triangle_equal_white',
  'Right_angled_triangles/triangle_RA_white'
] as const;

const compoundShapesSvgNames = [
  ...contentSvgNames,
  'CompoundShapes/QuarterCircle_2_parts_1',
  'CompoundShapes/QuarterCircle_3_parts_1',
  'CompoundShapes/QuarterCircle_4_parts_1',
  'CompoundShapes/QuarterCircle_5_parts_1',
  'CompoundShapes/RATriangle_2_parts_1',
  'CompoundShapes/RATriangle_3_parts_1',
  'CompoundShapes/RATriangle_4_parts_1',
  'CompoundShapes/RATriangle_4_parts_2',
  'CompoundShapes/RATriangle_4_parts_3',
  'CompoundShapes/RATriangle_5_parts_1',
  'CompoundShapes/EquilateralTriangle_2_parts_1',
  'CompoundShapes/EquilateralTriangle_3_parts_1',
  'CompoundShapes/EquilateralTriangle_4_parts_1',
  'CompoundShapes/EquilateralTriangle_4_parts_2',
  'CompoundShapes/EquilateralTriangle_5_parts_1',
  'CompoundShapes/Rectangle_2_parts_1',
  'CompoundShapes/Rectangle_3_parts_1',
  'CompoundShapes/Rectangle_4_parts_1',
  'CompoundShapes/Rectangle_4_parts_2',
  'CompoundShapes/Rectangle_5_parts_1',
  'CompoundShapes/Square_2_parts_1',
  'CompoundShapes/Square_3_parts_1',
  'CompoundShapes/Square_4_parts_1',
  'CompoundShapes/Square_4_parts_2',
  'CompoundShapes/Square_5_parts_1'
] as const;

type CompoundShapesSvgNames = (typeof compoundShapesSvgNames)[number];

const Question3 = newQuestionContent({
  uid: 'beB',
  description: 'beB',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Quarter'],
  schema: z.object({
    items: z.array(
      z.object({
        svgName: z.enum(compoundShapesSvgNames),
        value: z.enum(['A', 'B', 'C', 'D'])
      })
    ),
    correctValues: z.array(z.enum(['A', 'B', 'C', 'D'])),
    contentSvg: z.enum(contentSvgNames)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const shapes = [
      'QuarterCircle',
      'RATriangle',
      'EquilateralTriangle',
      'Rectangle',
      'Square'
    ] as const;

    const [quarterShape, otherShape] = getRandomSubArrayFromArray(shapes, 2);
    const contentSvgMap = {
      QuarterCircle: 'Circles/Circle_quarter_white' as const,
      Rectangle: 'Rectangle/rectangle_white' as const,
      Square: 'Square/square_white' as const,
      EquilateralTriangle: 'Equilateral_triangles/triangle_equal_white' as const,
      RATriangle: 'Right_angled_triangles/triangle_RA_white' as const
    };

    const numberOfCorrectAnswers =
      quarterShape === 'QuarterCircle'
        ? 1
        : quarterShape === 'RATriangle'
        ? getRandomFromArray([1, 2, 3])
        : getRandomFromArray([1, 2]);

    const numberOfIncorrectAnswers = 4 - numberOfCorrectAnswers;

    const values = ['A', 'B', 'C', 'D'] as const;

    const correctValues = values.slice(0, numberOfCorrectAnswers);
    const incorrectValues = values.filter(v => !correctValues.includes(v));

    const incorrectOptions =
      quarterShape === 'QuarterCircle'
        ? getRandomSubArrayFromArray(
            ['OnePart', 'TwoPart', 'ThreePart', 'OtherShape'] as const,
            numberOfIncorrectAnswers
          )
        : getRandomSubArrayFromArray(
            ['OnePart', 'TwoPart', 'ThreePart', 'FivePart', 'OtherShape'] as const,
            numberOfIncorrectAnswers
          );

    const correctSvgNames = (() => {
      switch (quarterShape) {
        case 'QuarterCircle':
          return ['CompoundShapes/QuarterCircle_4_parts_1'] as const;
        case 'RATriangle':
          return shuffle([
            'CompoundShapes/RATriangle_4_parts_1',
            'CompoundShapes/RATriangle_4_parts_2',
            'CompoundShapes/RATriangle_4_parts_3'
          ] as const);
        case 'EquilateralTriangle':
          return shuffle([
            'CompoundShapes/EquilateralTriangle_4_parts_1',
            'CompoundShapes/EquilateralTriangle_4_parts_2'
          ] as const);
        case 'Rectangle':
          return shuffle([
            'CompoundShapes/Rectangle_4_parts_1',
            'CompoundShapes/Rectangle_4_parts_2'
          ] as const);
        case 'Square':
          return shuffle([
            'CompoundShapes/Square_4_parts_1',
            'CompoundShapes/Square_4_parts_2'
          ] as const);
      }
    })();

    const correctItems = correctValues.map((value, index) => {
      return {
        value,
        svgName: correctSvgNames[index]
      };
    });

    const contentSvg = contentSvgMap[quarterShape];

    const getIncorrectItem = (
      IncorrectOption: 'OnePart' | 'TwoPart' | 'ThreePart' | 'FivePart' | 'OtherShape'
    ) => {
      switch (IncorrectOption) {
        case 'OnePart':
          return contentSvg;
        case 'TwoPart':
          return `CompoundShapes/${quarterShape}_2_parts_1` as const;
        case 'ThreePart':
          return `CompoundShapes/${quarterShape}_3_parts_1` as const;
        case 'FivePart':
          return `CompoundShapes/${quarterShape}_5_parts_1` as const;
        case 'OtherShape': {
          return `CompoundShapes/${otherShape}_4_parts_1` as const;
        }
      }
    };

    const incorrectItems = incorrectValues.map((value, index) => ({
      value,
      svgName: getIncorrectItem(incorrectOptions[index])
    }));

    const items = shuffle([...correctItems, ...incorrectItems]);

    return { contentSvg, items, correctValues };
  },

  Component: props => {
    const {
      translate,
      question: { contentSvg, items, correctValues },
      displayMode
    } = props;

    const svgSize = (shapeSvg: CompoundShapesSvgNames) => {
      switch (shapeSvg) {
        case 'Circles/Circle_quarter_white':
        case 'Square/square_white':
        case 'Right_angled_triangles/triangle_RA_white':
        case 'CompoundShapes/RATriangle_2_parts_1':
          return 90;
        case 'Equilateral_triangles/triangle_equal_white':
          return 100;
        case 'Rectangle/rectangle_white':
        case 'CompoundShapes/Rectangle_2_parts_1':
          return 120;
        case 'CompoundShapes/QuarterCircle_2_parts_1':
        case 'CompoundShapes/QuarterCircle_3_parts_1':
        case 'CompoundShapes/QuarterCircle_4_parts_1':
        case 'CompoundShapes/RATriangle_3_parts_1':
        case 'CompoundShapes/RATriangle_4_parts_3':
        case 'CompoundShapes/EquilateralTriangle_2_parts_1':
        case 'CompoundShapes/Square_2_parts_1':
        case 'CompoundShapes/Square_3_parts_1':
        case 'CompoundShapes/Square_4_parts_1':
          return 150;
        case 'CompoundShapes/RATriangle_4_parts_1':
          return 160;
        case 'CompoundShapes/EquilateralTriangle_3_parts_1':
        case 'CompoundShapes/EquilateralTriangle_4_parts_2':
          return 170;
        case 'CompoundShapes/EquilateralTriangle_4_parts_1':
        case 'CompoundShapes/EquilateralTriangle_5_parts_1':
        case 'CompoundShapes/Rectangle_3_parts_1':
        case 'CompoundShapes/Rectangle_4_parts_1':
        case 'CompoundShapes/Rectangle_4_parts_2':
        case 'CompoundShapes/QuarterCircle_5_parts_1':
          return 200;
        case 'CompoundShapes/Square_4_parts_2':
        case 'CompoundShapes/Square_5_parts_1':
        case 'CompoundShapes/RATriangle_5_parts_1':
          return 210;
        case 'CompoundShapes/Rectangle_5_parts_1':
          return 215;
        case 'CompoundShapes/RATriangle_4_parts_2':
          return 220;
      }
    };

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.hereIsAQuarterOfAShapeSelectWhatTheWholeCouldLookLike()}
        pdfTitle={translate.ks1PDFInstructions.hereIsAQuarterOfAShapeCircleWhatTheWholeCouldLookLike()}
        testCorrect={correctValues}
        numItems={4}
        itemLayout="row"
        multiSelect
        itemStyle={{
          height: displayMode === 'digital' ? 200 : 400,
          width: displayMode === 'digital' ? 240 : 480
        }}
        questionHeight={1000}
        Content={({ dimens }) => (
          <View
            style={{
              height: dimens.height * 0.5,
              width: dimens.width,
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <AssetSvg
              name={contentSvg}
              height={
                displayMode === 'digital' ? (contentSvg.includes('Rectangle') ? 150 : 100) : 200
              }
              width={
                displayMode === 'digital' ? (contentSvg.includes('Rectangle') ? 150 : 100) : 200
              }
            />
          </View>
        )}
        renderItems={() => {
          return items.map(({ svgName, value }) => {
            const size = svgSize(svgName);
            return {
              value,
              component: (
                <AssetSvg
                  name={svgName}
                  height={displayMode === 'digital' ? size : size * 2}
                  width={displayMode === 'digital' ? size : size * 2}
                />
              )
            };
          });
        }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FindAQuarterOfAnObjectOrAShape',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question3]
});
export default SmallStep;
