import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample
} from 'common/src/utils/random';
import { z } from 'zod';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { QuadrilateralWithDimens } from '../../../../components/question/representations/QuadrilateralWithDimens';
import { LabelledPentagon } from '../../../../components/question/representations/LabelledPentagon';
import { LabelledHexagon } from '../../../../components/question/representations/LabelledHexagon';
import { View } from 'react-native';
import Text from '../../../../components/typography/Text';
import { LabelledShape } from '../../../../components/question/representations/LabelledShape';
import { getDimenShapeSvgName } from '../../../../utils/shapeImages/shapes';
import { LabelledTriangle } from '../../../../components/question/representations/LabelledTriangle';
import { ShapeNames } from '../../../../utils/labelPositions';

////
// Questions
////

// Question1 & Question1v2 used to be Question6, however they were rearranged following a rewrite for v2.
// So the UID will not be in alphabetical order.
const Question1 = newQuestionContent({
  uid: 'aHJ',
  description: 'aHJ',
  keywords: ['Centimetres', 'Perimeter', 'Pentagon', 'Hexagon'],
  schema: z.object({
    shape: z.enum(['pentagon', 'hexagon']),
    sideLength: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['pentagon', 'hexagon'] as const);

    const sideLength = randomIntegerInclusive(2, 10);

    return { shape, sideLength };
  },
  Component: props => {
    const {
      question: { shape, sideLength },
      translate,
      displayMode
    } = props;

    const shapeString =
      shape === 'pentagon' ? translate.shapes.pentagons(1) : translate.shapes.hexagons(1);

    const perimeter = shape === 'pentagon' ? sideLength * 5 : sideLength * 6;

    return (
      <QF1ContentAndSentence
        title={translate.instructions.thePerimeterOfShapeIsNumAllSidesAreEqualLengthWhatIsLengthOfOneSide(
          shapeString,
          perimeter
        )}
        sentence={translate.answerSentences.lengthEqualsAnsCm()}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center' }
            ]}
          >
            {shape === 'pentagon' ? (
              <LabelledPentagon
                labels={[translate.units.stringCm('?')]}
                dimens={dimens}
                allArrows
              />
            ) : (
              <LabelledHexagon
                labels={['', translate.units.stringCm('?')]}
                dimens={dimens}
                allArrows
              />
            )}
            <Text variant={'WRN400'} style={{ fontSize: displayMode === 'digital' ? 32 : 40 }}>
              {translate.instructions.perimeterEqualsXCm(perimeter)}
            </Text>
          </View>
        )}
        testCorrect={[sideLength.toString()]}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aHJ2',
  description: 'aHJ2',
  keywords: ['Centimetres', 'Perimeter', 'Rectangle'],
  questionHeight: 900,
  schema: z.object({
    width: z.number().int().min(20).max(80),
    height: z.number().int().min(10).max(50)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(20, 80);
    const height = randomIntegerInclusive(10, 50, {
      constraint: x => 2 * (x + width) <= 200
    });
    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsThePerimeterOfTheRectangle()}
        sentence={translate.answerSentences.perimeterEqualsAnsMm()}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width - 246, height: dimens.height - 128 }}
            x={width}
            y={height}
            widthLabel={translate.units.numberOfMm(width)}
            heightLabel={translate.units.numberOfMm(height)}
            labelAllSides={true}
            containerStyle={{ alignSelf: 'center', marginLeft: 0, marginTop: 0 }}
          />
        )}
        pdfDirection="column"
        testCorrect={[(2 * (width + height)).toString()]}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aHE',
  description: 'aHE',
  keywords: ['Centimetres', 'Perimeter', 'Rectangle'],
  schema: z.object({
    width: z.number().int().min(20).max(80),
    height: z.number().int().min(10).max(50)
  }),
  simpleGenerator: () => {
    const width = randomIntegerInclusive(20, 80);

    const height = randomIntegerInclusive(10, 50, {
      constraint: x => 2 * (x + width) <= 200
    });

    return { width, height };
  },
  Component: props => {
    const {
      question: { width, height },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsThePerimeterOfTheRectangle()}
        sentence={translate.answerSentences.perimeterEqualsAnsMm()}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width - 128, height: dimens.height - 128 }}
            x={width}
            y={height}
            widthLabel={translate.units.numberOfMm(width)}
            heightLabel={translate.units.numberOfMm(height)}
            containerStyle={{ alignSelf: 'center', marginLeft: 0, marginTop: 0 }}
          />
        )}
        testCorrect={[(2 * (width + height)).toString()]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aHF',
  description: 'aHF',
  keywords: ['Centimetres', 'Millimetres', 'Perimeter', 'Triangle'],
  schema: z.object({
    length1: z.number().int().min(2).max(10),
    length2: z.number().int().min(2).max(10),
    length3: z.number().int().min(2),
    triangle: z.enum(['scaleneTriangles', 'isoscelesTriangles', 'raTriangles']),
    mmLabelIndex: z.number().int().min(0).max(2)
  }),
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'scaleneTriangles',
      'isoscelesTriangles',
      'raTriangles'
    ] as const);

    let length1;
    let length2;
    let length3;

    switch (triangle) {
      case 'scaleneTriangles':
        length2 = randomIntegerInclusive(6, 10);
        length1 = randomIntegerInclusive(length2 - 2, length2 - 1);
        length3 = randomIntegerInclusive(2, length1 - 2);
        break;
      case 'isoscelesTriangles':
        length1 = randomIntegerInclusive(4, 10);
        length2 = randomIntegerInclusive(2, length1 - 1);
        length3 = length1;
        break;
      case 'raTriangles':
        length1 = randomIntegerInclusive(4, 10);
        length2 = randomIntegerInclusive(Math.max(length1 - 3, 2), Math.min(length1 + 3, 10));
        length3 = Math.round(Math.sqrt(Math.pow(length1, 2) + Math.pow(length2, 2)));
        break;
    }

    const mmLabelIndex = randomIntegerInclusive(0, 2);

    return { length1, length2, length3, triangle, mmLabelIndex };
  },
  Component: props => {
    const {
      question: { length1, length2, length3, triangle, mmLabelIndex },
      translate
    } = props;

    const labels = [length1, length2, length3];

    const shapeName = getDimenShapeSvgName(triangle, props.question);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsThePerimeterOfTheTriangle()}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        Content={({ dimens }) =>
          triangle === 'isoscelesTriangles' ? (
            <LabelledTriangle
              labels={labels.map((val, i) =>
                i === mmLabelIndex
                  ? translate.units.numberOfMm(val * 10)
                  : translate.units.numberOfCm(val)
              )}
              dimens={dimens}
              assetSvgName={shapeName}
            />
          ) : (
            <LabelledShape
              dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
              shapeName={shapeName as ShapeNames}
              labels={labels.map((val, i) =>
                i === mmLabelIndex
                  ? translate.units.numberOfMm(val * 10)
                  : translate.units.numberOfCm(val)
              )}
            />
          )
        }
        pdfDirection="column"
        questionHeight={1000}
        testCorrect={[(length1 + length2 + length3).toString()]}
      />
    );
  },
  questionHeight: 1000
});

const Question4 = newQuestionContent({
  uid: 'aHG',
  description: 'aHG',
  keywords: ['Centimetres', 'Perimeter', 'Triangle'],
  schema: z.object({
    length1: z.number().int().min(2).max(10),
    length2: z.number().int().min(2).max(10),
    length3: z.number().int().min(2),
    triangle: z.enum(['scaleneTriangles', 'isoscelesTriangles', 'raTriangles']),
    missingLengthIndex: z.number().int().min(0).max(2)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const triangle = getRandomFromArray([
      'scaleneTriangles',
      'isoscelesTriangles',
      'raTriangles'
    ] as const);

    const { length1, length2, length3 } = rejectionSample(
      () => {
        let length1;
        let length2;
        let length3;
        switch (triangle) {
          case 'scaleneTriangles':
            length2 = randomIntegerInclusive(6, 10);
            length1 = randomIntegerInclusive(length2 - 2, length2 - 1);
            length3 = randomIntegerInclusive(2, length1 - 2);
            break;
          case 'isoscelesTriangles':
            length1 = randomIntegerInclusive(4, 10);
            length2 = randomIntegerInclusive(2, length1 - 1);
            length3 = length1;
            break;
          case 'raTriangles':
            length1 = randomIntegerInclusive(4, 10);
            length2 = randomIntegerInclusive(Math.max(length1 - 3, 2), Math.min(length1 + 3, 10));
            length3 = Math.round(Math.sqrt(Math.pow(length1, 2) + Math.pow(length2, 2)));
            break;
        }
        return { length1, length2, length3 };
      },
      val => {
        const perim = val.length1 + val.length2 + val.length3;
        return perim >= 15 && perim <= 30;
      }
    );

    const missingLengthIndex = randomIntegerInclusive(0, 2);

    return { length1, length2, length3, triangle, missingLengthIndex };
  },
  Component: props => {
    const {
      question: { length1, length2, length3, triangle, missingLengthIndex },
      translate
    } = props;

    const labels = [length1, length2, length3];

    const shapeName = getDimenShapeSvgName(triangle, props.question);

    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsUnknownLength()}
        sentence={translate.answerSentences.ansCm()}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={({ dimens }) => (
          <View style={{ alignItems: 'center' }}>
            <Text variant="WRN400">
              {translate.answerSentences.perimeterEqualsXCm(length1 + length2 + length3)}
            </Text>
            {triangle === 'isoscelesTriangles' ? (
              <LabelledTriangle
                labels={labels.map((val, i) =>
                  i === missingLengthIndex ? '' : translate.units.numberOfCm(val)
                )}
                dimens={{ width: dimens.width * 0.8, height: dimens.height * 0.8 }}
                assetSvgName={shapeName}
              />
            ) : (
              <LabelledShape
                dimens={{ width: dimens.width * 0.7, height: dimens.height * 0.7 }}
                shapeName={shapeName as ShapeNames}
                labels={labels.map((val, i) =>
                  i === missingLengthIndex ? '' : translate.units.numberOfCm(val)
                )}
              />
            )}
          </View>
        )}
        pdfDirection="column"
        questionHeight={1000}
        testCorrect={[labels[missingLengthIndex].toString()]}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aHH',
  description: 'aHH',
  keywords: ['Centimetres', 'Perimeter', 'Square'],
  schema: z.object({
    squareWidth: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const squareWidth = randomIntegerInclusive(2, 10);

    return { squareWidth };
  },
  Component: props => {
    const {
      question: { squareWidth },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.whatIsThePerimeterOfTheSquare()}
        sentence={translate.answerSentences.perimeterEqualsAnsCm()}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width - 128, height: dimens.height - 128 }}
            x={squareWidth}
            y={squareWidth}
            widthLabel={translate.units.numberOfCm(squareWidth)}
            containerStyle={{ alignSelf: 'center', marginLeft: 0, marginTop: 0 }}
          />
        )}
        testCorrect={[(squareWidth * 4).toString()]}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aHI',
  description: 'aHI',
  keywords: ['Centimetres', 'Perimeter', 'Square'],
  schema: z.object({
    squareWidth: z.number().int().min(2).max(10)
  }),
  simpleGenerator: () => {
    const squareWidth = randomIntegerInclusive(2, 10);

    return { squareWidth };
  },
  Component: props => {
    const {
      question: { squareWidth },
      translate
    } = props;
    return (
      <QF1ContentAndSentence
        title={translate.instructions.theSquareHasAPerimeterOfXCmWhatIsLengthOfOneSide(
          4 * squareWidth
        )}
        sentence={translate.answerSentences.lengthEqualsAnsCm()}
        Content={({ dimens }) => (
          <QuadrilateralWithDimens
            dimens={{ width: dimens.width - 128, height: dimens.height - 128 }}
            x={squareWidth}
            y={squareWidth}
            widthLabel={translate.units.stringCm('?')}
            containerStyle={{ alignSelf: 'center', marginLeft: 0, marginTop: 0 }}
          />
        )}
        testCorrect={[squareWidth.toString()]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CalculatePerimeter',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5, Question6],
  unpublishedQuestionTypes: [],
  archivedQuestionTypes: [Question1]
});
export default SmallStep;
