import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { numbersExchange } from '../../../../utils/exchanges';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { ADD } from '../../../../constants';
import { buildSimpleNumberSentence } from '../../../../utils/strings';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import MultiRowPlaceValueChartWithOperator from '../../../../components/question/representations/Place Value Chart/MultiRowPlaceValueChartWithOperator';
import { ScientificNotation } from '../../../../utils/math';
import QF20CompleteTheBarModel from '../../../../components/question/questionFormats/QF20CompleteTheBarModel';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhh',
  description: 'bhh',
  keywords: ['Add', 'Exchange'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(79),
      numB: z.number().int().min(11).max(88),
      options: z.array(z.number().int().min(11).max(900))
    })
    .refine(
      ({ numA, numB }) => numbersExchange(numA, numB) && numA + numB < 100,
      'numbers must exchange, but sum to less than 100'
    ),
  simpleGenerator: () => {
    const mustNotSumToMultipleOfTen = getRandomBoolean();

    const { numA, numB } = rejectionSample(
      () => {
        const numA = randomIntegerInclusive(11, 79, {
          constraint: x => x % 10 !== 0
        });
        const numB = randomIntegerInclusive(11, 88, {
          constraint: x => x % 10 !== 0 && x !== numA && numbersExchange(numA, x) && x + numA < 100
        });

        return { numA, numB };
      },
      // 50% of the time make sure the sum of the ones do not equal 10 and the rest any
      ({ numA, numB }) => mustNotSumToMultipleOfTen || (numA + numB) % 10 !== 0
    );

    const correctAnswer = numA + numB;
    const incorrectA = correctAnswer - 10;
    const incorrectB = getRandomFromArray([
      correctAnswer + 10,
      correctAnswer + 1,
      correctAnswer - 1
    ]);
    // Sum tens digits and concat with sum of ones digits
    const incorrectC = parseInt(
      `${Math.floor(numA / 10) + Math.floor(numB / 10)}${(numA % 10) + (numB % 10)}`
    );

    return { numA, numB, options: shuffle([incorrectA, incorrectB, incorrectC, correctAnswer]) };
  },
  Component: ({ question: { numA, numB, options }, translate }) => {
    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions.whatIsXOperationY(numA, ADD, numB)}
        pdfTitle={`${translate.ks1Instructions.whatIsXOperationY(
          numA,
          ADD,
          numB
        )}<br/>${translate.ks1PDFInstructions.tickTheCorrectAnswer()}`}
        items={options}
        testCorrect={[numA + numB]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bhi',
  description: 'bhi',
  keywords: ['Add', 'Base 10', 'Place value chart', 'Exchange'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(59),
      numB: z.number().int().min(11).max(58)
    })
    .refine(
      ({ numA, numB }) => numbersExchange(numA, numB) && numA + numB < 100,
      'numbers must exchange, but sum to less than 100'
    ),
  simpleGenerator: () =>
    rejectionSample(
      () => {
        const numA = randomIntegerInclusive(11, 59, {
          constraint: x => x % 10 !== 0
        });
        const numB =
          // 50% of the time make sure the sum of the ones do not equal 10 and the rest any
          randomIntegerInclusive(11, 58, {
            constraint: x =>
              x % 10 !== 0 && x !== numA && numbersExchange(numA, x) && x + numA < 100
          });

        return { numA, numB };
      },
      ({ numA, numB }) => getRandomBoolean() || (numA % 10) + (numB % 10) !== 10
    ),
  Component: ({ question: { numA, numB }, translate }) => {
    const { sentence, answer } = buildSimpleNumberSentence([numA, numB, numA + numB], ADD, 2);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.completeTheAddition()}
        sentence={sentence}
        testCorrect={[answer.toString()]}
        pdfSentenceStyle={{ alignSelf: 'flex-end' }}
        Content={({ dimens }) => (
          <MultiRowPlaceValueChartWithOperator
            numOfRows={2}
            columnsToShow={[1, 0]}
            dimens={dimens}
            numbersPerRow={[
              ScientificNotation.fromNumber(numA),
              ScientificNotation.fromNumber(numB)
            ]}
            operator={ADD}
          />
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bhj',
  description: 'bhj',
  keywords: ['Add', 'Bar model'],
  schema: z
    .object({
      numA: z.number().int().min(11).max(79),
      numB: z.number().int().min(11).max(88),
      ansTopOrBottom: z.enum(['top', 'bottom'])
    })
    .refine(
      ({ numA, numB }) => numbersExchange(numA, numB) && numA + numB < 100,
      'numbers must exchange, but sum to less than 100'
    ),
  simpleGenerator: () => {
    const numA = randomIntegerInclusive(11, 79, {
      constraint: x => x % 10 !== 0
    });
    const numB = randomIntegerInclusive(11, 88, {
      constraint: x => x % 10 !== 0 && x !== numA && numbersExchange(numA, x) && x + numA < 100
    });

    const ansTopOrBottom = getRandomFromArray(['top', 'bottom'] as const);

    return { numA, numB, ansTopOrBottom };
  },

  Component: props => {
    const {
      question: { numA, numB, ansTopOrBottom },
      translate
    } = props;

    const total = numA + numB;
    const numbers = ansTopOrBottom === 'top' ? [[numA, numB], [total]] : [[total], [numA, numB]];
    const answerIndices = ansTopOrBottom === 'top' ? [[], [0]] : [[0], []];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={total}
        oneFontSize
        questionHeight={600}
      />
    );
  },
  questionHeight: 600
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'AddTwo2DigitNumbersAcrossA10',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
