import * as math from 'mathjs';
import { Point2d, PointFlexible, normalizePointFlexible } from './vectors';
import { filterVerticesFromCoordinates, isSelfIntersecting } from './shapes';
import { countRange } from './collections';
import { getIntersection } from './lines';

type PolygonLazyProperties = { signedArea?: number; isSimple?: boolean; centroid?: Point2d };

/**
 * Class for a polygon, i.e. list of vertices in 2D, where each is joined to the next with an edge (including the
 * last to the first).
 */
export class Polygon {
  readonly vertices: Point2d[];

  /** Backings for lazy properties. */
  private lazy: PolygonLazyProperties = {};

  /**
   * Private constructor for setting fields directly without checking.
   * Accepts values for the lazy properties, if these can be known without re-calculating them.
   */
  private constructor(vertices: Point2d[], lazy: PolygonLazyProperties = {}) {
    this.vertices = vertices;
    this.lazy = lazy;
  }

  /** Public constructor. */
  static create(vertices: PointFlexible[]) {
    return new Polygon(vertices.map(normalizePointFlexible));
  }

  /**
   * (Lazy property) Signed area of the polygon. If negative, then this is oriented anti-clockwise.
   * (Non-simple polygons may still have some sections oriented clockwise.)
   */
  get signedArea(): number {
    if (this.lazy.signedArea !== undefined) return this.lazy.signedArea;
    this.lazy.signedArea = getSignedArea(this.vertices);
    return this.lazy.signedArea;
  }

  /** (Lazy property) Whether this is a simple polygon, i.e. has no self intersections. */
  get isSimple(): boolean {
    if (this.lazy.isSimple !== undefined) return this.lazy.isSimple;
    this.lazy.isSimple = !isSelfIntersecting(this.vertices);
    return this.lazy.isSimple;
  }

  /** The average of all the vertices. */
  get centroid(): Point2d {
    if (this.lazy.centroid !== undefined) return this.lazy.centroid;
    const sum = this.vertices.reduce((acc, v) => acc.add(v));
    this.lazy.centroid = sum.multiply(1 / this.vertices.length);
    return this.lazy.centroid;
  }

  /** Translate the polygon by a vector. */
  translate(translation: Point2d): Polygon {
    return new Polygon(
      this.vertices.map(v => v.add(translation)),
      {
        signedArea: this.signedArea,
        isSimple: this.isSimple,
        // centroid is translated the same amount
        centroid: this.lazy.centroid !== undefined ? this.lazy.centroid.add(translation) : undefined
      }
    );
  }

  reflect(direction: 'x' | 'y'): Polygon {
    return new Polygon(
      this.vertices.map(v => (direction === 'x' ? new Point2d(-v.x, v.y) : new Point2d(v.x, -v.y))),
      {
        // signedArea changes sign
        signedArea: this.lazy.signedArea !== undefined ? -this.lazy.signedArea : undefined,
        isSimple: this.isSimple,
        // centroid is reflected
        centroid:
          this.lazy.centroid !== undefined
            ? direction === 'x'
              ? new Point2d(-this.lazy.centroid.x, this.lazy.centroid.y)
              : new Point2d(this.lazy.centroid.x, -this.lazy.centroid.y)
            : undefined
      }
    );
  }

  /**
   * Maybe reorient polygon so that its signed area is negative, i.e. it's anti-clockwise oriented.
   * (Non-simple polygons may still have some sections oriented clockwise.)
   */
  reorient(): Polygon {
    return this.signedArea <= 0
      ? this
      : new Polygon([...this.vertices].reverse(), {
          // Orientation change will change the signedArea's sign
          signedArea: -this.signedArea,
          isSimple: this.isSimple,
          centroid: this.centroid
        });
  }

  /** Calculate whether this polygon is congruent with another polygon. */
  isCongruent(other: Polygon): boolean {
    return polygonsAreCongruent(this, other);
  }

  /** Split a simple polygon into many polygons with a splitting line. */
  splitByLine(line: [PointFlexible, PointFlexible]): Polygon[] | 'not simple' {
    if (this.isSimple) {
      return splitPolygonWithLine(this, line.map(normalizePointFlexible) as [Point2d, Point2d]);
    } else {
      return 'not simple';
    }
  }

  /** Return whether the given line splits this polygon into exactly two congruent pieces. */
  isSplitInHalfByLine(line: [PointFlexible, PointFlexible]): boolean {
    const pieces = this.splitByLine(line);
    if (pieces === 'not simple') return false;
    return pieces.length === 2 && pieces[0].isCongruent(pieces[1]);
  }

  /** Filter out any vertices which have 0 exterior angle, i.e. they are in the interior of a straight edge. */
  filterOutZeroAngleVertices(): Polygon {
    return new Polygon(filterVerticesFromCoordinates(this.vertices), this.lazy);
  }
}

enum LineSide {
  Left,
  On,
  Right
}

/**
 * Node of a linked list which encodes a polygon's vertices.
 * Some types are optional, whilst this is being constructed.
 */
type SplitVertexOpt = {
  /** Vertex */
  readonly start: Point2d;
  /** Side of the splitting line that the vertex sits on */
  readonly startSide: LineSide;
  next?: SplitVertexOpt;
  prev?: SplitVertexOpt;
  /**
   * Signed distance from the first vertex on the splitting line.
   * This is defined when startSide is {@link LineSide.On}
   */
  distanceAlongLine?: number;
};

/** Node of a linked list which encodes a polygon's vertices. */
type SplitVertex = {
  readonly start: Point2d;
  next: SplitVertex;
  prev: SplitVertex;
  visited?: boolean;
} & (
  | { startSide: LineSide.On; distanceAlongLine: number }
  | { startSide: LineSide.Left | LineSide.Right; distanceAlongLine?: undefined }
);

/** Doesn't actually check anything (because that uses CPU cycles), so use with caution. */
function assertSplitVertexArray(_arr: SplitVertexOpt[]): asserts _arr is SplitVertex[] {}

/**
 * Split a simple polygon into many polygons with a splitting line.
 *
 * This throws an exception if the polygon wasn't simple.
 *
 * Algorithm adapted from https://github.com/geidav/concave-poly-splitter
 * See also https://geidav.wordpress.com/2015/03/21/splitting-an-arbitrary-polygon-by-a-line for explanation of source
 * and destination points, with diagrams.
 */
function splitPolygonWithLine(poly: Polygon, line: [Point2d, Point2d]): Polygon[] {
  // First, make sure the polygon is simple and orientated anti-clockwise.
  if (!poly.isSimple) throw new Error('Polygon was not simple');
  poly = poly.reorient();

  const vertices: SplitVertexOpt[] = [];

  // 1. Iterate through all the vertices, categorizing the edges and adding in intersection points
  poly.vertices.forEach((p, i, arr) => {
    const edge: [Point2d, Point2d] = [p, arr[math.mod(i + 1, arr.length)]];
    const edgeStartSide = getSideOfLine(line, edge[0]);
    const edgeEndSide = getSideOfLine(line, edge[1]);
    vertices.push({ start: p, startSide: edgeStartSide });

    // Proceed only if the edge isn't co-linear with the line
    if (edgeStartSide !== edgeEndSide && edgeEndSide !== LineSide.On) {
      // We have an intersect.
      const intersection = getIntersection(edge, line);
      if (Array.isArray(intersection)) {
        // Should be impossible since we already checked the edge isn't co-linear
        throw new Error('Intersection cannot overlap');
      } else if (intersection === null) {
        // If extended infinitely, the line would intersect, but it doesn't.
      } else if (!intersection.equals(edge[0]) && !intersection.equals(edge[1])) {
        // Intersect was in the edge. Add to vertices
        vertices.push({ start: intersection, startSide: LineSide.On });
      }
    }
  });

  // 2. Add in some other properties and calculate some values
  //   - a) the doubly-linked list properties, so each vertex knows the one before and the one after
  //        This is useful later to redirect some vertices to point at other vertices, to make cycles (sub-polygons).
  vertices.forEach((v, i, arr) => {
    v.next = arr[math.mod(i + 1, arr.length)];
    v.prev = arr[math.mod(i - 1, arr.length)];
  });

  //   - b) Sort the vertices which lie on the split line, according to distance along the split line.
  // Get all the vertices which lie on the *extended* line segment.
  let verticesOnLine: SplitVertexOpt[] = vertices.filter(v => v.startSide === LineSide.On);
  // For each vertex on the line, calculate its distance along the line
  verticesOnLine.forEach(v => (v.distanceAlongLine = calcSignedDistance(line, v.start)));
  // Filter out the ones that don't actually lie on the line segment
  verticesOnLine = verticesOnLine.filter(
    v => v.distanceAlongLine! >= 0 && v.distanceAlongLine! <= 1
  );
  // Sort by this distance long the line
  verticesOnLine.sort((a, b) => a.distanceAlongLine! - b.distanceAlongLine!);

  // 3. Bridge between source and destination vertices along the split line.
  // This is the difficult step as there's a lot of cases to check, depending on which side the start and end of each
  // edge is.
  assertSplitVertexArray(vertices); // Just to make the types nicer
  assertSplitVertexArray(verticesOnLine); // Just to make the types nicer

  let sourceVertex: SplitVertex | null = null;
  let destVertex: SplitVertex | null = null;
  let priorDestVertexLeft: SplitVertex | null = null;
  let priorDestVertexRight: SplitVertex | null = null;
  let i = 0;

  while (i < verticesOnLine.length) {
    const v = verticesOnLine[i];

    if (!sourceVertex) {
      // Is v a source vertex?
      if (v.startSide !== LineSide.On) throw new Error('v must be on line');

      if (
        (v.prev.startSide === LineSide.Left && v.next.startSide === LineSide.Right) ||
        // Awkward case: Some vertices need to be skipped as a source if another point also on the line, and further
        // along
        (v.prev.startSide === LineSide.Left &&
          v.next.startSide === LineSide.On &&
          v.next.distanceAlongLine < v.distanceAlongLine) ||
        (v.prev.startSide === LineSide.On &&
          v.next.startSide === LineSide.Right &&
          v.prev.distanceAlongLine < v.distanceAlongLine)
      ) {
        // v is a valid source.
        sourceVertex = v;
      } else if (
        // Awkward case: Some vertices can only be a source if they were a dest just prior
        priorDestVertexLeft !== null &&
        v.prev.startSide === LineSide.Left &&
        v.next.startSide === LineSide.Left
      ) {
        sourceVertex = priorDestVertexLeft;
      } else if (
        // Awkward case: Some vertices can only be a source if they were a dest just prior
        priorDestVertexRight !== null &&
        v.prev.startSide === LineSide.Right &&
        v.next.startSide === LineSide.Right
      ) {
        sourceVertex = priorDestVertexRight;
      }

      // If we just found the source, increment i and find the dest in the next loop.
      // If we didn't, increment i and find the source in the next loop.
      i++;
      priorDestVertexLeft = null;
      priorDestVertexRight = null;
      continue;
    }

    if (!destVertex) {
      // Is v a dest vertex?
      if (v.startSide !== LineSide.On) throw new Error('v must be on line');

      if (
        (v.prev.startSide === LineSide.Right && v.next.startSide === LineSide.Left) ||
        (v.prev.startSide === LineSide.On && v.next.startSide === LineSide.Left) ||
        (v.prev.startSide === LineSide.Right && v.next.startSide === LineSide.On) ||
        (v.prev.startSide === LineSide.Right && v.next.startSide === LineSide.Right) ||
        (v.prev.startSide === LineSide.Left && v.next.startSide === LineSide.Left)
      ) {
        // v is a valid dest.
        destVertex = v;
      } else {
        // v wasn't the dest - try the next one
        i++;
        continue;
      }
    }

    // Just found the dest vertex! Let's bridge them. (i.e. add a new edge both ways between source and dest)
    // Add another copy of each vertex, and re-jig the links
    const sourceVertexCopy = { ...sourceVertex };
    const destVertexCopy = { ...destVertex };

    // Bridge from source to dest
    sourceVertexCopy.next = destVertex;
    sourceVertexCopy.prev = sourceVertex.prev;

    // Bridge from dest to source
    destVertexCopy.next = sourceVertex;
    destVertexCopy.prev = destVertex.prev;

    // Make those links mutual - being careful about the order
    sourceVertex.prev.next = sourceVertexCopy;
    sourceVertex.prev = destVertexCopy;
    destVertex.prev.next = destVertexCopy;
    destVertex.prev = sourceVertexCopy;

    vertices.push(sourceVertexCopy);
    vertices.push(destVertexCopy);

    // Set up the next loop. Don't increment i because there's a chance that we need to re-use this dest (or its copy)
    priorDestVertexLeft = destVertexCopy;
    priorDestVertexRight = destVertex;
    sourceVertex = null;
    destVertex = null;
  }

  // 4. Finally we can collect our linked list into several cycles, which are individual sub-polygons
  const polygons: Polygon[] = [];

  vertices.forEach(v => {
    if (v.visited) return;

    const polygonVertices: Point2d[] = [];
    let curVertex = v;
    do {
      curVertex.visited = true;
      polygonVertices.push(curVertex.start);
      curVertex = curVertex.next;
    } while (curVertex !== v);

    polygons.push(Polygon.create(polygonVertices));
  });

  // Phew!
  return polygons;
}

/**
 * Calculate whether two polygons are congruent - i.e. they differ only by translation, rotation or reflection.
 *
 * This will check forwards and backwards orderings of the points. Any points within a straight line are ignored.
 *
 * @param ignoreZeroAngleVertices whether to ignore vertices of each polygon with zero exterior angle.
 */
function polygonsAreCongruent(a: Polygon, b: Polygon, ignoreZeroAngleVertices = false): boolean {
  if (ignoreZeroAngleVertices) {
    a = a.filterOutZeroAngleVertices();
    b = b.filterOutZeroAngleVertices();
  }

  if (a.vertices.length !== b.vertices.length) return false;

  // Reorient both, so we can assume they're both anti-clockwise
  a = a.reorient();
  b = b.reorient();

  // Center both polygons at 0
  a = a.translate(a.centroid.negate());
  b = b.translate(b.centroid.negate());

  const shiftStart = (a: Point2d[], shift: number) =>
    a.map((_, i) => a[math.mod(i + shift, a.length)]);

  const differsOnlyByRotation = (a: Point2d[], b: Point2d[]) => {
    const rotation = math.atan2(b[0].y, b[0].x) - math.atan2(a[0].y, a[0].x);
    return a.every((p, i) => {
      return p.rotate(rotation).equals(b[i]);
    });
  };

  const len = a.vertices.length;

  // Need to check a couple of cases:
  // - every starting point
  // - every starting point, with one of the polygons point orders reversed
  if (countRange(len).some(n => differsOnlyByRotation(shiftStart(a.vertices, n), b.vertices))) {
    return true;
  }

  const aRef = a.reflect('x').reorient();
  if (countRange(len).some(n => differsOnlyByRotation(shiftStart(aRef.vertices, n), b.vertices))) {
    return true;
  }

  return false;
}

/**
 * Get the signed area of a polygon.
 *
 * If this is -ve, then the polygon is mostly oriented anti-clockwise. (For simple polygons where the orientation
 * cannot change, this means it's wholly oriented anti-clockwise.)
 */
function getSignedArea(polygon: Point2d[]): number {
  return polygon.reduce((area, p, i, arr) => {
    const q = arr[math.mod(i + 1, arr.length)]; // next point
    return area + ((q.x - p.x) * (q.y + p.y)) / 2;
  }, 0);
}

/** Get which side of the *extended* line segment a point is on. */
function getSideOfLine([a, b]: [Point2d, Point2d], pt: Point2d): LineSide {
  const d = (pt.x - a.x) * (b.y - a.y) - (pt.y - a.y) * (b.x - a.x);
  return Math.abs(d) < 10e-6 ? LineSide.On : d > 0 ? LineSide.Right : LineSide.Left;
}

/**
 * For a point on an extended line segment, get how far along the line it is.
 *
 * - `calcSignedDistance([a, b], a)` always gives 0
 * - `calcSignedDistance([a, b], b)` always gives 1
 * - other points on the line segment are given distances between the two
 * - points outside the line segment will be <0 or >1
 */
function calcSignedDistance([a, b]: [Point2d, Point2d], pt: Point2d): number {
  return (
    ((pt.x - a.x) * (b.x - a.x) + (pt.y - a.y) * (b.y - a.y)) /
    ((b.x - a.x) ** 2 + (b.y - a.y) ** 2)
  );
}
