import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { integerToWord } from '../../../../utils/math';
import ContentBox from '../../../../components/molecules/ContentBox';
import Text from '../../../../components/typography/Text';
import { arrayHasNoDuplicates } from '../../../../utils/collections';
import QF6DragMatchStatements from '../../../../components/question/questionFormats/QF6DragMatchStatements';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { View } from 'react-native';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bf1',
  description: 'bf1',
  keywords: ['Match', 'Word', 'Base 10'],
  schema: z.object({
    correctNumbers: z.number().int().min(10).max(100).array().length(3),
    incorrectNumber: z.number().int().min(10).max(100)
  }),
  simpleGenerator: () => {
    const correctNumbers = randomUniqueIntegersInclusiveStep(10, 100, 10, 3);

    const numberTens = getRandomFromArray(correctNumbers) as number;

    const incorrectNumber = getRandomBoolean()
      ? randomIntegerInclusiveStep(10, 100, 10, { constraint: x => !correctNumbers.includes(x) })
      : Math.floor(numberTens / 10) + 10;

    return { correctNumbers, incorrectNumber };
  },
  Component: props => {
    const {
      question: { correctNumbers, incorrectNumber },
      translate
    } = props;

    const items = shuffle(
      [...correctNumbers, incorrectNumber].map(number => {
        return {
          component: integerToWord(number),
          value: number.toString()
        };
      }),
      { random: seededRandom(props.question) }
    );

    const statements = [...correctNumbers].map(number => {
      const parts = number > 50 ? [50, number - 50] : [number];

      return {
        lhsComponent: (
          <View style={{ flexDirection: 'row', width: 230 }}>
            {parts.map((part, index) => (
              <View key={`base10_${index}`}>
                <BaseTenRepresentation
                  b10Rep={{
                    variant: 'Cubes',
                    numbers: {
                      tens: Math.floor(part / 10)
                    },
                    arrangement: 'ltr'
                  }}
                  usableWidth={150}
                  usableHeight={150}
                />
              </View>
            ))}
          </View>
        ),
        correctAnswer: number.toString()
      };
    });

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheBaseTenToTheWords()}
        pdfTitle={translate.ks1PDFInstructions.matchTheNumbersToTheBaseTen()}
        items={items}
        statements={statements}
        questionHeight={900}
        statementStyle={{ justifyContent: 'center' }}
        pdfLayout="itemsRight"
        useArrows={false}
      />
    );
  },
  questionHeight: 900
});

const Question2 = newQuestionContent({
  uid: 'bf2',
  description: 'bf2',
  keywords: ['Match', 'Words', 'Numeral'],
  schema: z.object({
    numbers: z.number().int().min(0).max(100).array().length(4),
    wordOrNum: z.enum(['word', 'numeral'])
  }),
  simpleGenerator: () => {
    const wordOrNum = getRandomFromArray(['word', 'numeral'] as const);

    const numberA = randomIntegerInclusive(0, 100);

    const { numberB, numberC, numberD, numberE } = rejectionSample(
      () => {
        // Number that can be reversed
        const numberB = randomIntegerInclusive(10, 98, {
          constraint: x => x % 11 !== 0 && x !== numberA
        });

        // Reversed numberB
        const numberC = parseInt(numberB.toString().split('').reverse().join(''));

        const numberDTens = getRandomBoolean()
          ? Math.floor(numberB / 10)
          : Math.floor(numberC / 10);

        // Number in the same tens as numberB or numberC
        const numberD = randomIntegerInclusive(numberDTens * 10, numberDTens * 10 + 9);

        // Random digit of any number generated so we can multiple by 10
        const numbersETens = getRandomFromArray([
          ...numberB.toString().split(''),
          ...numberC.toString().split(''),
          ...numberD.toString().split('')
        ]) as string;

        const numberE = parseInt(numbersETens) * 10;

        return { numberB, numberC, numberD, numberE };
      },
      ({ numberB, numberC, numberD, numberE }) =>
        arrayHasNoDuplicates([numberA, numberB, numberC, numberD, numberE])
    );

    const numbers = getRandomSubArrayFromArray([numberA, numberB, numberC, numberD, numberE], 4);

    return { numbers, wordOrNum };
  },
  Component: props => {
    const {
      question: { numbers, wordOrNum },
      translate
    } = props;

    const items = shuffle(
      numbers.map(number => {
        return {
          component: wordOrNum === 'numeral' ? integerToWord(number) : number.toLocaleString(),
          value: number.toString()
        };
      }),
      { random: seededRandom(props.question) }
    );

    const statements = numbers.map(number => ({
      lhsComponent: (
        <Text variant="WRN400" style={{ textAlign: 'center', width: 300 }}>
          {wordOrNum === 'numeral' ? number.toLocaleString() : integerToWord(number)}
        </Text>
      ),
      correctAnswer: number.toString()
    }));

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheNumeralsToTheWords()}
        pdfTitle={translate.ks1PDFInstructions.matchTheNumeralsToTheWords()}
        items={items}
        statements={statements}
        statementStyle={{ justifyContent: 'center' }}
        questionHeight={900}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bf3',
  description: 'bf3',
  keywords: ['Word', 'Numeral'],
  schema: z.object({
    number: z.number().int().min(0).max(100)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(0, 100);
    return { number };
  },
  Component: props => {
    const {
      question: { number },
      translate
    } = props;

    const numberAsWord = integerToWord(number);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.whatIsTheNumber()}
        testCorrect={[number.toString()]}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        Content={
          <ContentBox>
            <Text variant="WRN400">{numberAsWord}</Text>
          </ContentBox>
        }
        pdfDirection="column"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'WriteNumbersTo100InWords',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
