import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { newSmallStepContent } from '../../../SmallStep';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { randomIntegerInclusive, seededRandom, shuffle } from 'common/src/utils/random';
import { range } from '../../../../utils/collections';
import { NumberLineVariableTick } from '../../../../components/question/representations/Number Line/NumberLineVariableTick';
import { SUB } from '../../../../constants';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bcP',
  description: 'bcP',
  keywords: ['Subtract', 'Number line'],
  schema: z
    .object({
      minuend: z.number().int().min(3).max(20),
      subtrahend: z.number().int().min(1).max(18)
    })
    .refine(
      val => val.minuend >= val.subtrahend + 2,
      'minuend must be at least 2 more than subtrahend'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(3, 20);

    const subtrahend =
      minuend > 15
        ? // Difference of at least 5 is needed if the 5-20 number line is generated:
          randomIntegerInclusive(5, minuend - 5)
        : // Difference of at least 5 is needed if the 0-15 number line is generated:
          randomIntegerInclusive(1, minuend - 2);

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate,
      displayMode
    } = props;

    const difference = minuend - subtrahend;

    const numberLineMinMax = minuend > 15 ? [5, 20] : [0, 15];

    const tickValues = range(numberLineMinMax[0], numberLineMinMax[1]).map(num => ({
      position: num,
      label: num.toLocaleString()
    }));

    const jumpArrowArray = range(minuend, difference + 1).map(num => ({
      start: num,
      end: num - 1,
      label: ''
    }));

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={`${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = <ans/>`}
        testCorrect={[difference.toString()]}
        Content={({ dimens }) => (
          <NumberLineVariableTick
            tickValues={tickValues}
            dimens={dimens}
            customFontSize={displayMode === 'digital' ? 32 : 50}
            start={numberLineMinMax[0]}
            end={numberLineMinMax[1]}
            jumpArrowArray={jumpArrowArray}
            subtraction
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bcQ',
  description: 'bcQ',
  keywords: ['Subtract', 'Number line'],
  schema: z
    .object({
      minuend: z.number().int().min(5).max(20),
      subtrahend: z.number().int().min(2).max(18)
    })
    .refine(
      val => val.minuend >= val.subtrahend + 2,
      'minuend must be at least 2 more than subtrahend'
    )
    .refine(
      val => val.minuend - val.subtrahend !== val.subtrahend + 1,
      'Difference must not equal subtrahend + 1'
    )
    .refine(val => val.minuend / 2 !== val.subtrahend, 'subtrahend must not be half of minuend.'),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(5, 20);

    const subtrahend =
      minuend > 15
        ? // Difference of at least 5 is needed if the 5-20 number line is generated:
          randomIntegerInclusive(5, minuend - 5, {
            constraint: x =>
              // Need to prevent the incorrect answer being the same number as the difference:
              minuend - x !== x + 1 &&
              // Need to prevent the difference being the same number as the subtrahend:
              minuend / 2 !== x
          })
        : // Difference of at least 5 is needed if the 0-15 number line is generated:
          randomIntegerInclusive(2, minuend - 2, {
            constraint: x =>
              // Need to prevent the incorrect answer being the same number as the difference:
              minuend - x !== x + 1 &&
              // Need to prevent the difference being the same number as the subtrahend:
              minuend / 2 !== x
          });

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate,
      displayMode
    } = props;

    const random = seededRandom(props.question);

    const difference = minuend - subtrahend;

    const numberLineMinMax = minuend > 15 ? [5, 20] : [0, 15];

    const tickValues = range(numberLineMinMax[0], numberLineMinMax[1]).map(num => ({
      position: num,
      label: num.toLocaleString()
    }));

    const jumpArrowArray = range(minuend, difference + 1).map(num => ({
      start: num,
      end: num - 1,
      label: ''
    }));

    const items = shuffle([minuend, subtrahend, difference, subtrahend + 1], { random });

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.whatSubtractionIsShownOnTheNumberLineDragTheCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.whatSubtractionIsShownOnTheNumberLineUseTheCardsToCompleteTheNumberSentence()}
        pdfLayout="itemsTop"
        actionPanelVariant="end"
        sentence={`<ans/> ${SUB} <ans/> = <ans/>`}
        items={items.map(item => ({ value: item.toString(), component: item.toLocaleString() }))}
        testCorrect={userAnswer =>
          userAnswer[0] === minuend.toString() &&
          // minuend - subtrahend = difference
          ((userAnswer[1] === subtrahend.toString() && userAnswer[2] === difference.toString()) ||
            // minuend - difference = subtrahend
            (userAnswer[1] === difference.toString() && userAnswer[2] === subtrahend.toString()))
        }
        Content={({ dimens }) => (
          <NumberLineVariableTick
            tickValues={tickValues}
            dimens={dimens}
            customFontSize={displayMode === 'digital' ? 32 : 50}
            start={numberLineMinMax[0]}
            end={numberLineMinMax[1]}
            jumpArrowArray={jumpArrowArray}
            subtraction
          />
        )}
        questionHeight={1200}
        customMarkSchemeAnswer={{
          answersToDisplay: [[minuend.toString(), subtrahend.toString(), difference.toString()]],
          answerText: translate.markScheme.alsoAcceptX(
            `${minuend.toLocaleString()} ${SUB} ${difference.toLocaleString()} = ${subtrahend.toLocaleString()}`
          )
        }}
      />
    );
  },
  questionHeight: 1200
});

const Question3 = newQuestionContent({
  uid: 'bcR',
  description: 'bcR',
  keywords: ['Subtract', 'Number line'],
  schema: z
    .object({
      minuend: z.number().int().min(3).max(20),
      subtrahend: z.number().int().min(1).max(18)
    })
    .refine(
      val => val.minuend >= val.subtrahend + 2,
      'minuend must be at least 2 more than subtrahend'
    ),
  simpleGenerator: () => {
    const minuend = randomIntegerInclusive(3, 20);

    const subtrahend =
      minuend > 15
        ? // Difference of at least 5 is needed if the 5-20 number line is generated:
          randomIntegerInclusive(5, minuend - 5)
        : // Difference of at least 5 is needed if the 0-15 number line is generated:
          randomIntegerInclusive(1, minuend - 2);

    return { minuend, subtrahend };
  },
  Component: props => {
    const {
      question: { minuend, subtrahend },
      translate,
      displayMode
    } = props;

    const difference = minuend - subtrahend;

    const numberLineMinMax = minuend > 15 ? [5, 20] : [0, 15];

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSubtraction()}
        sentence={`${minuend.toLocaleString()} ${SUB} ${subtrahend.toLocaleString()} = <ans/>`}
        testCorrect={[difference.toString()]}
        Content={({ dimens }) => (
          <NumberLine
            tickValues={range(numberLineMinMax[0], numberLineMinMax[1])}
            dimens={dimens}
            customFontSize={displayMode === 'digital' ? 32 : 50}
          />
        )}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'SubtractionCountingBack',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
