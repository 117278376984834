import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { BarModel } from 'common/src/components/question/representations/BarModel';
import { countRange, filledArray } from 'common/src/utils/collections';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusiveStep,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import { View } from 'react-native';
import Text from 'common/src/components/typography/Text';
import { all, create, isInteger, number } from 'mathjs';
import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import NumberLine from 'common/src/components/question/representations/Number Line/NumberLine';
import { numberEnum } from 'common/src/utils/zod';
import { compareFloats } from 'common/src/utils/math';
import ContentBox from 'common/src/components/molecules/ContentBox';
import QF11SelectImagesUpTo4WithContent from 'common/src/components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import QF2AnswerBoxOneSentence from 'common/src/components/question/questionFormats/QF2AnswerBoxOneSentence';
import { barModelColors, barModelColorsArray } from '../../../../theme/colors';

// Setup mathjs with custom precision to avoid problems like 0.07 * 72 = 5.04000001 by using BigNumber in the calculation step
const math = create(all, { precision: 14, number: 'BigNumber' });

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'aWm',
  description: 'aWm',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Tenths', 'Missing value'],
  schema: z.object({
    number1: z.number().int().min(11).max(999)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 999);

    return { number1 };
  },
  questionHeight: 800,
  Component: props => {
    const {
      question: { number1 },
      translate
    } = props;

    const selectedColors = getRandomSubArrayFromArray(barModelColorsArray, 2, {
      random: seededRandom(props.question)
    });

    const coloredSections = [
      ...filledArray(barModelColors[selectedColors[0]], 1),
      ...filledArray('white', 9)
    ];

    const numbers = [[10], filledArray(1, 10)];

    // Answer
    const ans = number(math.evaluate(`${number1} * 10`));

    return (
      <QF1ContentAndSentence
        title={translate.instructions.ifYouKnow10PercentOfANumberYouCanMultiplyBy10ToFindTheWhole()}
        Content={({ dimens }) => (
          <>
            <BarModel
              total={10}
              dimens={dimens}
              numbers={numbers}
              strings={[['100%'], filledArray(`10%`, 10)]}
              cellColors={[[barModelColors[selectedColors[1]]], coloredSections]}
            />
            <Text
              variant="WRN400"
              style={{ alignSelf: 'flex-start', fontSize: 32, paddingTop: 32 }}
            >
              {translate.answerSentences.workOutTheMissingNumber()}
            </Text>
          </>
        )}
        inputMaxCharacters={4}
        fractionContainerStyle={{ height: 96 }}
        sentence={translate.answerSentences.XPercentOfAnsEqualsY(10, number1)}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], ans)}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        questionHeight={800}
      />
    );
  }
});

const Question1v2 = newQuestionContent({
  uid: 'aWm2',
  description: 'aWm',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Tenths', 'Missing value'],
  schema: z.object({
    number1: z.number().int().min(11).max(999)
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(11, 999);

    return { number1 };
  },
  questionHeight: 1000,
  Component: props => {
    const {
      question: { number1 },
      translate,
      displayMode
    } = props;

    const coloredSections = filledArray('white', 10);

    const numbers = [filledArray(1, 10)];

    // Answer
    const ans = number(math.evaluate(`${number1} * 10`));

    const tickValues = countRange(11).map(num => `${num * 10}%`);

    return (
      <QF1ContentAndSentence
        title={`${translate.instructions.workOutTheMissingNumber()} <br/>${translate.instructions.youCanUseTheBarModelToHelpYou()}`}
        Content={({ dimens }) => (
          <View
            style={{
              height: dimens.height,
              justifyContent: 'center',
              rowGap: displayMode === 'digital' ? 0 : 20
            }}
          >
            <View style={{ left: displayMode === 'digital' ? 39 : 38 }}>
              <BarModel
                total={10}
                dimens={{
                  width: displayMode === 'digital' ? dimens.width * 0.953 : dimens.width * 0.929,
                  height: dimens.height
                }}
                numbers={numbers}
                strings={[[number1.toLocaleString(), ...filledArray('', 9)]]}
                cellColors={[coloredSections]}
                topBraceText={'?'}
              />
            </View>
            <View>
              <NumberLine
                scaleFactor={3.5}
                tickValues={tickValues}
                dimens={{
                  width: dimens.width,
                  height: dimens.height / 3
                }}
              />
            </View>
          </View>
        )}
        inputMaxCharacters={4}
        fractionContainerStyle={{ height: 96 }}
        sentence={translate.answerSentences.XPercentOfAnsEqualsY(10, number1)}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
        extraSymbol="decimalPoint"
        testCorrect={userAnswer => compareFloats(userAnswer[0], ans)}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString()]
        }}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'aWn',
  description: 'aWn',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    number1: numberEnum([2, 4, 5, 10]),
    number2: z.number().int().min(100).max(300)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 4, 5, 10] as const);
    const number2 = randomIntegerInclusive(100, 300);

    return { number1, number2 };
  },
  questionHeight: 900,
  Component: props => {
    const {
      question: { number1, number2 },
      translate,
      displayMode
    } = props;

    const number3 = number(math.evaluate(`100 / ${number1}`));
    const number4 = number(math.evaluate(`${number2} * ${number1}`));

    const barModelNumbers = filledArray(1, number1);
    const barModelStrings = [`${number2}`, ...filledArray('', number1 - 1)];
    const barModelCellColors = filledArray('', number1);

    const tickValues = [
      translate.answerSentences.numPercent(0),
      translate.answerSentences.numPercent(10),
      translate.answerSentences.numPercent(20),
      translate.answerSentences.numPercent(30),
      translate.answerSentences.numPercent(40),
      translate.answerSentences.numPercent(50),
      translate.answerSentences.numPercent(60),
      translate.answerSentences.numPercent(70),
      translate.answerSentences.numPercent(80),
      translate.answerSentences.numPercent(90),
      translate.answerSentences.numPercent(100)
    ];

    return (
      <QF1ContentAndSentence
        sentence={`${translate.answerSentences.xPercentOfAnsEqualsY(number3, number2)}`}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        title={`${translate.instructions.workOutTheMissingNumber()} <br/>${translate.instructions.youCanUseTheBarModelToHelpYou()}`}
        extraSymbol="decimalPoint"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        inputMaxCharacters={4}
        testCorrect={userAnswer => compareFloats(userAnswer[0], number4)}
        customMarkSchemeAnswer={{
          answersToDisplay: [number4.toLocaleString()]
        }}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => (
          <View
            style={{
              height: dimens.height,
              justifyContent: 'center',
              rowGap: displayMode === 'digital' ? 0 : 20
            }}
          >
            <View style={{ left: displayMode === 'digital' ? 39 : 38 }}>
              <BarModel
                total={number1}
                numbers={[barModelNumbers]}
                strings={[barModelStrings]}
                cellColors={[barModelCellColors]}
                dimens={{
                  width: displayMode === 'digital' ? dimens.width * 0.953 : dimens.width * 0.929,
                  height: dimens.height
                }}
                topBraceText={'?'}
              />
            </View>
            <View>
              <NumberLine
                scaleFactor={3.5}
                tickValues={tickValues}
                dimens={{
                  width: dimens.width,
                  height: dimens.height / 3
                }}
              />
            </View>
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'aWo',
  description: 'aWo',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    numbers: z.array(numberEnum([1, 2, 4, 5, 10, 20, 25, 50])).length(4),
    number5: z.number().min(0.1).max(0.9)
  }),
  simpleGenerator: () => {
    const numbers = getRandomSubArrayFromArray([1, 2, 4, 5, 10, 20, 25, 50] as const, 4);
    const number5 = randomIntegerInclusive(1, 9) / 10;

    return {
      numbers,
      number5
    };
  },

  Component: props => {
    const {
      question: { numbers, number5 },
      translate,
      displayMode
    } = props;

    const [number1, number2, number3, number4] = numbers;

    // Items
    const item1 = number(math.evaluate(`(${number5} * 100) / ${number1}`));
    const item2 = number(math.evaluate(`(${number5} * 100) / ${number2}`));
    const item3 = number(math.evaluate(`(${number5} * 100) / ${number3}`));
    const item4 = number(math.evaluate(`(${number5} * 100) / ${number4}`));

    const statements = shuffle(
      [
        {
          component: item1.toLocaleString(),
          value: item1
        },
        {
          component: item2.toLocaleString(),
          value: item2
        },
        {
          component: item3.toLocaleString(),
          value: item3
        },
        {
          component: item4.toLocaleString(),
          value: item4
        }
      ],
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.instructions.workOutTheMissingNumber()}
        testCorrect={[item1]}
        numItems={4}
        Content={({ dimens }) => (
          <View style={[dimens, { justifyContent: 'space-evenly' }]}>
            <ContentBox
              containerStyle={{
                alignSelf: 'center',
                width: dimens.width / 3,
                height: displayMode === 'digital' ? 100 : null
              }}
            >
              <Text variant="WRN400">
                {translate.answerSentences.xPercentOfBlankEqualsY(number1, number5)}
              </Text>
            </ContentBox>
            <Text variant="WRN400" style={{ fontSize: displayMode === 'digital' ? 32 : 50 }}>
              {displayMode === 'digital'
                ? translate.answerSentences.selectTheCorrectAnswer()
                : translate.answerSentences.circleTheCorrectAnswer()}
            </Text>
          </View>
        )}
        renderItems={statements.map(({ value, component }) => ({
          value,
          component: <Text variant="WRN700">{component.toLocaleString()}</Text>
        }))}
      />
    );
  }
});

const Question4 = newQuestionContent({
  uid: 'aWp',
  description: 'aWp',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    number1: numberEnum([5, 10, 20, 25, 50]),
    number2: z.number().int().min(10).max(90).multipleOf(10),
    random: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([5, 10, 20, 25, 50] as const);
    const number2 = randomIntegerInclusiveStep(10, 90, 10);
    const random = getRandomBoolean();

    return { number1, number2, random };
  },

  Component: props => {
    const {
      question: { number1, number2, random },
      translate
    } = props;

    const number3 = random
      ? number(math.evaluate(`${number2} * 2`))
      : number(math.evaluate(`${number2} * 0.5`));

    // Sentences
    const sentences = [
      translate.answerSentences.xPercentOfAnsEqualsY(number1, number2),
      translate.answerSentences.xPercentOfAnsEqualsY(number1, number3)
    ];

    // Answers
    const ans1 = number(math.evaluate(`(${number2} * 100) / ${number1}`));
    const ans2 = number(math.evaluate(`(${number3} * 100) / ${number1}`));

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) && compareFloats(userAnswer[1][0], ans2)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [[ans1.toLocaleString()], [ans2.toLocaleString()]],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentences={sentences}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'aWq',
  description: 'aWq',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    number1: numberEnum([5, 20, 25, 50]),
    number2: z.number().min(5).max(250)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([5, 20, 25, 50] as const);
    const number2 = randomIntegerInclusiveStep(5, number1 * 5, 5);

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const ans = number(math.evaluate(`(${number2} * 100) / ${number1}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.ifXPercentOfNumberIsYWhatIsTheNumber(number1, number2)}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer => compareFloats(userAnswer[0], ans)}
        customMarkSchemeAnswer={{
          answersToDisplay: [ans.toLocaleString()],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question5v2 = newQuestionContent({
  uid: 'aWq2',
  description: 'aWq',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    number1: numberEnum([5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90]),
    number2: z.number().min(5).max(450)
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90] as const);
    const number2 = randomIntegerInclusiveStep(5, number1 * 5, 5, {
      constraint: x => isInteger((x * 100) / number1)
    });

    return { number1, number2 };
  },
  Component: props => {
    const {
      question: { number1, number2 },
      translate
    } = props;

    const ans = number(math.evaluate(`(${number2} * 100) / ${number1}`));

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.ifXPercentOfNumberIsYWhatIsTheNumber(number1, number2)}
        inputMaxCharacters={4}
        testCorrect={[ans.toString()]}
        sentence={'<ans/>'}
        mainPanelContainerStyle={{ alignItems: 'flex-end', justifyContent: 'flex-end' }}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'aWr',
  description: 'aWr',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    percentages: z.array(numberEnum([5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90])).length(3),
    numbers: z.array(z.number().int().min(100).max(1000).multipleOf(100)).length(3),
    randomA: z.enum(['left', 'center', 'right']),
    randomB: z.enum(['left', 'center', 'right']),
    randomC: z.enum(['left', 'center', 'right'])
  }),
  questionHeight: 800,
  simpleGenerator: () => {
    const percentages = getRandomSubArrayFromArray(
      [5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90] as const,
      3
    );

    const numbers = randomUniqueIntegersInclusiveStep(100, 1000, 100, 3);

    const randomA = getRandomFromArray(['left', 'center', 'right'] as const);
    const randomB = getRandomFromArray(['left', 'center', 'right'] as const);
    const randomC = getRandomFromArray(['left', 'center', 'right'] as const);

    return { percentages, numbers, randomA, randomB, randomC };
  },
  Component: props => {
    const {
      question: { percentages, numbers, randomA, randomB, randomC },
      translate
    } = props;

    const [percentage1, percentage2, percentage3] = percentages;
    const [number1, number2, number3] = numbers;

    const calc1 = number(math.evaluate(`${percentage1} * ${number1} / 100`));
    const calc2 = number(math.evaluate(`${percentage2} * ${number2} / 100`));
    const calc3 = number(math.evaluate(`${percentage3} * ${number3} / 100`));

    // Sentences
    const sentences = [
      randomA === 'left'
        ? translate.answerSentences.ansPercentOfXEqualsY(number1, calc1)
        : randomA === 'center'
        ? translate.answerSentences.xPercentOfAnsEqualsY(percentage1, calc1)
        : translate.answerSentences.xPercentOfYEqualsAns(percentage1, number1),
      randomB === 'left'
        ? translate.answerSentences.ansPercentOfXEqualsY(number2, calc2)
        : randomB === 'center'
        ? translate.answerSentences.xPercentOfAnsEqualsY(percentage2, calc2)
        : translate.answerSentences.xPercentOfYEqualsAns(percentage2, number2),
      randomC === 'left'
        ? translate.answerSentences.ansPercentOfXEqualsY(number3, calc3)
        : randomC === 'center'
        ? translate.answerSentences.xPercentOfAnsEqualsY(percentage3, calc3)
        : translate.answerSentences.xPercentOfYEqualsAns(percentage3, number3)
    ];

    // Answers
    const ans1 = randomA === 'left' ? percentage1 : randomA === 'center' ? number1 : calc1;
    const ans2 = randomB === 'left' ? percentage2 : randomB === 'center' ? number2 : calc2;
    const ans3 = randomC === 'left' ? percentage3 : randomC === 'center' ? number3 : calc3;

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeCalculations()}
        extraSymbol="decimalPoint"
        inputMaxCharacters={4}
        testCorrect={userAnswer =>
          compareFloats(userAnswer[0][0], ans1) &&
          compareFloats(userAnswer[1][0], ans2) &&
          compareFloats(userAnswer[2][0], ans3)
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [ans1.toLocaleString()],
            [ans2.toLocaleString()],
            [ans3.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptEquivalentDecimals()
        }}
        sentences={sentences}
        questionHeight={800}
      />
    );
  }
});

const Question6v2 = newQuestionContent({
  uid: 'aWr2',
  description: 'aWr2',
  keywords: ['Percentage', 'Amount', 'Division', 'Multiplication', 'Missing value'],
  schema: z.object({
    percentage: numberEnum([5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90]),
    numberA: z.number().int().min(100).max(1000).multipleOf(100),
    randomA: z.enum(['left', 'center'])
  }),
  simpleGenerator: () => {
    const percentage = getRandomFromArray([5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90] as const);

    const numberA = randomIntegerInclusiveStep(100, 1000, 100);

    const randomA = getRandomFromArray(['left', 'center'] as const);

    return { percentage, numberA, randomA };
  },
  Component: props => {
    const {
      question: { percentage, numberA, randomA },
      translate
    } = props;

    const calc1 = number(math.evaluate(`${percentage} * ${numberA} / 100`));

    // Sentences
    const sentence =
      randomA === 'left'
        ? translate.answerSentences.ansPercentOfXEqualsY(numberA, calc1)
        : translate.answerSentences.xPercentOfAnsEqualsY(percentage, calc1);
    // Answers
    const ans1 = randomA === 'left' ? percentage : randomA === 'center' ? numberA : calc1;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.instructions.completeCalculation()}
        inputMaxCharacters={4}
        testCorrect={[ans1.toString()]}
        sentence={sentence}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'PercentagesMissingValues',
  questionTypes: [Question1v2, Question2, Question3, Question4, Question5v2, Question6v2],
  unpublishedQuestionTypes: [Question1v2, Question2, Question5v2, Question6v2],
  archivedQuestionTypes: [Question1, Question5, Question6]
});
export default SmallStep;
