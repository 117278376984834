import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { AssetSvg } from '../../../../assets/svg';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import WoodenBalanceScale from '../../../../components/question/representations/WoodenBalanceScale';
import {
  scaleObjectsSchema,
  getRandomUniqueScaleObjectNames,
  scaleObjects,
  scaleObjectAsArticleWord
} from '../../../../utils/objects';
import { getRandomFromArray, rejectionSample } from '../../../../utils/random';
import { newQuestionContent } from '../../../Question';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bdE',
  description: 'bdE',
  keywords: ['Measure', 'Mass', 'Cubes', 'Balance scales', 'Compare'],
  schema: z
    .object({
      scaleObjectNames: z.array(scaleObjectsSchema).length(2),
      lighterOrHeavier: z.enum(['lighter', 'heavier']),
      scaleCubes: numberEnum([1, 2]),
      cube: z.enum(['green cube', 'orange cube', 'purple cube', 'red cube'])
    })
    .refine(
      ({ scaleObjectNames, scaleCubes }) =>
        scaleObjectNames.map(obj => scaleObjects[obj].weight * scaleCubes <= 10),
      'objects should be a max of 10 cubes'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const lighterOrHeavier = getRandomFromArray(['lighter', 'heavier'] as const);
    const cube = getRandomFromArray([
      'green cube',
      'orange cube',
      'purple cube',
      'red cube'
    ] as const);

    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val =>
        val.filter(
          name => name.toLowerCase().includes('cube') || name.toLowerCase().includes('muffin')
        ).length === 0
    );

    const scaleCubes = getRandomFromArray([1, 2] as const);

    return { scaleObjectNames, lighterOrHeavier, scaleCubes, cube };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, lighterOrHeavier, scaleCubes, cube },
      translate,
      displayMode
    } = props;

    const title =
      lighterOrHeavier === 'lighter' ? 'selectTheLighterObject' : 'selectTheHeavierObject';
    const pdfTitle =
      lighterOrHeavier === 'lighter' ? 'tickTheLighterObject' : 'tickTheHeavierObject';

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const [heavierItemName, lighterItemName] = [scaleObjectNameA, scaleObjectNameB].sort(
      (a, b) => scaleObjects[b].weight - scaleObjects[a].weight
    );

    // Correct answer depends on the weight
    const correctAnswer = lighterOrHeavier === 'lighter' ? lighterItemName : heavierItemName;

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        testCorrect={[correctAnswer]}
        numItems={2}
        questionHeight={1200}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <WoodenBalanceScale
              amount={[null, scaleObjects[scaleObjectNameA].weight * scaleCubes]}
              items={[scaleObjectNameA, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={[null, displayMode === 'digital' ? 40 : 80]}
            />
            <WoodenBalanceScale
              amount={[null, scaleObjects[scaleObjectNameB].weight * scaleCubes]}
              items={[scaleObjectNameB, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={[null, displayMode === 'digital' ? 40 : 80]}
            />
          </View>
        )}
        renderItems={({ dimens }) => {
          return scaleObjectNames.map(item => ({
            value: item,
            component: (
              <AssetSvg
                name={scaleObjects[item].name}
                height={dimens.height * 0.3}
                width={dimens.width * 0.3}
              />
            )
          }));
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bdF',
  description: 'bdF',
  keywords: ['Heavier', 'Lighter', 'Balance scales', 'Mass', 'Compare'],
  schema: z
    .object({
      scaleObjectNames: z.array(scaleObjectsSchema).length(2),
      scaleCubes: numberEnum([1, 2]),
      cube: z.enum(['green cube', 'orange cube', 'purple cube', 'red cube'])
    })
    .refine(
      ({ scaleObjectNames, scaleCubes }) =>
        scaleObjectNames.map(obj => scaleObjects[obj].weight * scaleCubes <= 10),
      'objects should be a max of 10 cubes'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val =>
        val.filter(
          name => name.toLowerCase().includes('cube') || name.toLowerCase().includes('muffin')
        ).length === 0
    );

    const cube = getRandomFromArray([
      'green cube',
      'orange cube',
      'purple cube',
      'red cube'
    ] as const);

    const scaleCubes = getRandomFromArray([1, 2] as const);

    return { scaleObjectNames, scaleCubes, cube };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, scaleCubes, cube },
      translate,
      displayMode
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    const translatedScaleObjectNameA = scaleObjectAsArticleWord(scaleObjectNameA, translate);
    const translatedScaleObjectNameB = scaleObjectAsArticleWord(scaleObjectNameB, translate);

    const more = translate.operations.heavier();
    const less = translate.operations.lighter();
    const same = translate.operations.theSameAs();

    // Correct answer depends on the weight
    const correctAnswer =
      scaleObjects[scaleObjectNameA].weight > scaleObjects[scaleObjectNameB].weight ? more : less;

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        sentence={translate.ks1AnswerSentences.xIsAnsY(
          translatedScaleObjectNameA,
          translatedScaleObjectNameB
        )}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <WoodenBalanceScale
              amount={[null, scaleObjects[scaleObjectNameA].weight * scaleCubes]}
              items={[scaleObjectNameA, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={[null, displayMode === 'digital' ? 40 : 80]}
            />
            <WoodenBalanceScale
              amount={[null, scaleObjects[scaleObjectNameB].weight * scaleCubes]}
              items={[scaleObjectNameB, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={[null, displayMode === 'digital' ? 40 : 80]}
            />
          </View>
        )}
        itemVariant="rectangle"
        pdfItemVariant="tallRectangle"
        pdfLayout="itemsTop"
        actionPanelVariant="endWide"
        sentencesStyle={{ alignItems: 'flex-start' }}
        questionHeight={1200}
        items={[more, same, less]}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bdG',
  description: 'bdG',
  keywords: ['Measure', 'Mass', 'Cubes', 'Balance scales', 'Compare'],
  schema: z
    .object({
      scaleObjectNames: z.array(scaleObjectsSchema).length(2),
      scaleCubes: numberEnum([1, 2]),
      cube: z.enum(['green cube', 'orange cube', 'purple cube', 'red cube'])
    })
    .refine(
      ({ scaleObjectNames, scaleCubes }) =>
        scaleObjectNames.map(obj => scaleObjects[obj].weight * scaleCubes <= 10),
      'objects should be a max of 10 cubes'
    ),
  questionHeight: 1200,
  simpleGenerator: () => {
    const scaleObjectNames = rejectionSample(
      () => getRandomUniqueScaleObjectNames(2),
      val =>
        val.filter(
          name => name.toLowerCase().includes('cube') || name.toLowerCase().includes('muffin')
        ).length === 0
    );

    const cube = getRandomFromArray([
      'green cube',
      'orange cube',
      'purple cube',
      'red cube'
    ] as const);

    const scaleCubes = getRandomFromArray([1, 2] as const);

    return { scaleObjectNames, scaleCubes, cube };
  },
  Component: props => {
    const {
      question: { scaleObjectNames, scaleCubes, cube },
      translate,
      displayMode
    } = props;

    const [scaleObjectNameA, scaleObjectNameB] = scaleObjectNames;

    // Correct answer depends on the weight
    const correctAnswer =
      scaleObjects[scaleObjectNameA].weight > scaleObjects[scaleObjectNameB].weight ? '>' : '<';

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.instructions.useCardsCompleteSentence()}
        sentence={'<ans />'}
        Content={({ dimens }) => (
          <View
            style={{
              ...dimens,
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center'
            }}
          >
            <WoodenBalanceScale
              amount={[scaleObjects[scaleObjectNameA].weight * scaleCubes, null]}
              items={[cube, scaleObjectNameA]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={[displayMode === 'digital' ? 40 : 80, null]}
            />
            <WoodenBalanceScale
              amount={[null, scaleObjects[scaleObjectNameB].weight * scaleCubes]}
              items={[scaleObjectNameB, cube]}
              dimens={{ height: dimens.height, width: dimens.width * 0.55 }}
              containerStyle={{ marginTop: 50 }}
              stackInfront
              iconWidth={[null, displayMode === 'digital' ? 40 : 80]}
            />
            <View
              style={{
                flexDirection: 'row',
                columnGap: displayMode === 'digital' ? 180 : 200,
                alignItems: 'center',
                position: 'absolute',
                top: displayMode === 'digital' ? 395 : 900,
                left: displayMode === 'digital' ? 340 : undefined
              }}
            >
              <AssetSvg name={scaleObjects[scaleObjectNameA].name} width={80} />
              <AssetSvg name={scaleObjects[scaleObjectNameB].name} width={80} />
            </View>
          </View>
        )}
        itemVariant="square"
        actionPanelVariant="end"
        pdfLayout="itemsTop"
        sentencesStyle={{ alignItems: 'center' }}
        questionHeight={1200}
        items={['<', '>', '=']}
        testCorrect={[correctAnswer]}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CompareMass',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
