import { View, StyleSheet } from 'react-native';
import { countRange, filledArray } from 'common/src/utils/collections';
import { useContext } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import DragAndDropSection from '../../molecules/DragAndDropSection';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { DisplayMode } from '../../../contexts/displayMode';
import { isEqual } from '../../../utils/matchers';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import { colors } from '../../../theme/colors';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { AssetSvg, SvgName } from '../../../assets/svg';
import { MeasureView } from '../../atoms/MeasureView';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  /**
   * An array of numbers representing the total expected for each tray.
   * Alternatively, you can provide the correct answer as a function.
   */
  testCorrect: number[] | ((userAnswer: number[][]) => boolean);
  /**
   * Optional custom mark scheme answer (with items). If you provide testCorrect as a function, you _must_ provide
   * this. If you provide testCorrect as an array, you don't need to provide this.
   *
   * An array of numbers to be shown as the total per grouping
   */
  customMarkSchemeAnswer?: { answersToDisplay?: number[]; answerText?: string };
  draggableItem: SvgName;
  /**
   * Number of draggables to show
   */
  draggableCount: number;
  questionHeight?: number;
  /**
   * Number of drop zones to show.
   * Maximum number of groups is 6
   */
  numberOfGroups: number;
  maxCapacity?: number;
  /** Show "trays", optionally render a View around the zones to act as "trays" */
  showTrays?: boolean;
  /** Optional component to display on PDF instead of the same as the digital */
  pdfComponent?: JSX.Element;
};

/**
 * Question Format 68: Drag SVGs into groups.
 *
 * Title at the top, draggables at the top of the page.
 * Draggable is "move" by default.
 *
 * This is a many-(up to 20) drag and drop.
 */
export default function QF68DragSvgsIntoGroups({
  title,
  pdfTitle,
  testCorrect,
  customMarkSchemeAnswer,
  draggableItem,
  draggableCount,
  questionHeight,
  numberOfGroups,
  maxCapacity,
  showTrays = false,
  pdfComponent
}: Props) {
  if (numberOfGroups > 6) {
    throw new Error('Maximum number of groups is 6');
  }

  if (numberOfGroups > 5 && draggableCount > 10) {
    throw new Error('Maximum number of groups is 5 when there is two rows of draggables');
  }

  if (typeof testCorrect === 'function' && customMarkSchemeAnswer === undefined) {
    throw new Error('testCorrect is a function, so you must provide the markSchemeAnswer prop');
  }

  const displayMode = useContext(DisplayMode);
  const styles = getStyles(displayMode);

  const draggables = countRange(draggableCount).map(i => {
    const component =
      displayMode === 'digital' ? (
        <AssetSvg name={draggableItem} />
      ) : (
        pdfComponent ?? <AssetSvg name={draggableItem} />
      );

    return {
      component,
      value: i
    };
  });

  const initialState = filledArray([], numberOfGroups);

  const capacity = maxCapacity ?? (draggableCount > 10 ? 6 : numberOfGroups > 3 ? 3 : 6);

  const dropSource = (
    <DragAndDropSection itemsStyle={{ gap: 6, justifyContent: 'center' }}>
      {draggables.map((_item, index) => (
        <EasyDragAndDrop.Source key={index} id={index} componentStyle={{ height: 96, width: 96 }} />
      ))}
    </DragAndDropSection>
  );

  const dragZones = (
    <MeasureView style={[styles.dropZoneContainer]}>
      {dimens => {
        return countRange(numberOfGroups).map((_, index) => (
          <View
            key={index}
            style={{
              borderWidth: showTrays ? 2 : 0,
              borderColor: colors.black,
              width:
                // For 5 groups, if there are more than 10 draggables, make the width a minimum of 18% (to fit them all onto one row)
                // Else 30% for a maximum of 3 columns split over 2 rows (center aligned)
                numberOfGroups >= 5
                  ? draggableCount > 10
                    ? '18%'
                    : '30%'
                  : (dimens.width * 0.94) / numberOfGroups,
              height:
                // Scale height for account for multiple rows of drop zones
                numberOfGroups >= 5
                  ? draggableCount > 10
                    ? dimens.height * 0.9
                    : dimens.height * 0.45
                  : dimens.height * 0.9,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 8
            }}
          >
            <EasyDragAndDrop.ZoneMultiple
              id={index}
              capacity={capacity}
              style={[
                styles.dropZone,
                {
                  width: showTrays ? '94%' : '100%',
                  height: showTrays ? '94%' : '100%'
                }
              ]}
              droppedStyle={styles.droppedStyle}
            />
          </View>
        ));
      }}
    </MeasureView>
  );

  const getDefaultState = (array: number[]) => {
    let count = 0;
    return array.map(val => {
      const array = countRange(val, count);
      count += val - 1;
      return array;
    });
  };

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    const defaultState =
      typeof testCorrect !== 'function'
        ? getDefaultState(testCorrect)
        : getDefaultState(customMarkSchemeAnswer?.answersToDisplay ?? []);

    return (
      <>
        <EasyDragAndDrop.ProviderWithState
          id="QF68"
          moveOrCopy="copy"
          items={draggables}
          defaultState={displayMode === 'markscheme' ? defaultState : undefined}
          draggableStyle={{
            borderWidth: 0,
            shadowColor: 'transparent',
            backgroundColor: 'white'
          }}
        >
          <BaseLayoutPDF
            title={pdfTitle ?? title}
            mainPanelContents={
              <>
                <View style={{ width: '100%', alignItems: 'center' }}>
                  <View style={{ width: 96 * 11 }}>{dropSource}</View>
                </View>
                <View style={styles.container}>{dragZones}</View>
              </>
            }
            questionHeight={questionHeight}
          />
        </EasyDragAndDrop.ProviderWithState>
        {displayMode === 'markscheme' &&
          customMarkSchemeAnswer?.answerText &&
          renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
      </>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="QF68"
      items={draggables}
      defaultState={initialState}
      // Complete if at least one in each zone
      testComplete={state => state.every(zone => zone.length > 0)}
      hideBackground
      testCorrect={
        typeof testCorrect === 'function'
          ? testCorrect
          : state => state.every((zone, index) => isEqual(zone.length)(testCorrect[index]))
      }
      draggableStyle={{
        borderWidth: 0,
        shadowColor: 'transparent',
        backgroundColor: 'transparent'
      }}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        mainPanelContents={
          <>
            <View>{dropSource}</View>
            <View style={styles.container}>{dragZones}</View>
          </>
        }
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}

const getStyles = (displayMode: 'digital' | 'pdf' | 'markscheme') => {
  return StyleSheet.create({
    container: { flex: 1 },
    droppedStyle: {
      width: 65,
      height: 65
    },
    labelContainer: {
      flexDirection: 'row',
      gap: 32,
      alignItems: 'center',
      justifyContent: 'center'
    },
    dropZone: {
      alignItems: 'center',
      borderStyle: 'solid',
      gap: displayMode === 'digital' ? 16 : 32
    },
    dropZoneContainer: {
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      gap: displayMode === 'digital' ? 16 : 32,
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'center'
    }
  });
};
