import { newQuestionContent } from 'common/src/SchemeOfLearning/Question';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { z } from 'zod';
import { numberEnum } from 'common/src/utils/zod';
import {
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive
} from 'common/src/utils/random';
import QF2AnswerBoxManySentences from 'common/src/components/question/questionFormats/QF2AnswerBoxManySentences';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from 'common/src/utils/fourOperations';
import { arrayHasNoDuplicates, range } from 'common/src/utils/collections';
import QF20CompleteTheBarModel from 'common/src/components/question/questionFormats/QF20CompleteTheBarModel';
import { filledArray } from 'common/src/utils/collections';
import QF1ContentAndSentences from 'common/src/components/question/questionFormats/QF1ContentAndSentences';
import { ArrayOfObjects } from 'common/src/components/question/representations/ArrayOfObjects';
import { MULT, DIV } from 'common/src/constants';
import { View } from 'react-native';
import QF1ContentAndSentence from 'common/src/components/question/questionFormats/QF1ContentAndSentence';
import { objectSchema, getRandomObject } from 'common/src/utils/objects';
import { getObjectImage } from 'common/src/utils/objectsImages';
import QF31NumberGridInteractive from 'common/src/components/question/questionFormats/QF31NumberGridInteractive';
import QF2AlignedEquations from '../../../../components/question/questionFormats/QF2AlignedEquations';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'akj',
  description: 'akj',
  keywords: ['Times-table', '3', 'Object', 'Multiply'],
  schema: z.object({
    groups: z.number().int().min(2).max(12),
    object: objectSchema
  }),
  questionHeight: 900,
  simpleGenerator: () => {
    const groups = randomIntegerInclusive(2, 12);

    const object = getRandomObject();

    return { groups, object };
  },
  Component: props => {
    const {
      question: { groups, object },
      translate
    } = props;

    return (
      <QF1ContentAndSentence
        sentence={`<ans/> ${MULT} <ans/> = <ans/>`}
        title={translate.instructions.completeMultiplication()}
        testCorrect={answer =>
          ((answer[0] === '3' && answer[1] === groups.toString()) ||
            (answer[0] === groups.toString() && answer[1] === '3')) &&
          answer[2] === (groups * 3).toString()
        }
        inputMaxCharacters={2}
        pdfDirection="column"
        questionHeight={900}
        Content={({ dimens }) => {
          const topLine = groups <= 4 ? groups : Math.ceil(groups / 2);
          const botLine = groups - topLine;
          return (
            <View
              style={{
                alignSelf: 'center',
                rowGap: 8
              }}
            >
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {range(1, topLine).map(index => (
                  <View key={index}>
                    {getObjectImage(object, 3, dimens.height / 2, dimens.width / topLine)}
                  </View>
                ))}
              </View>
              <View style={{ flexDirection: 'row', columnGap: 8 }}>
                {botLine !== 0 &&
                  range(1, botLine).map(index => (
                    <View key={index}>
                      {getObjectImage(object, 3, dimens.height / 2, dimens.width / topLine)}
                    </View>
                  ))}
              </View>
            </View>
          );
        }}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            groups.toLocaleString(),
            (3).toLocaleString(),
            (groups * 3).toLocaleString()
          ],
          answerText: translate.markScheme.acceptReversedMultiplication()
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'akk',
  description: 'akk',
  keywords: ['Times-table', '3', 'Array', 'Multiply', 'Fact family'],
  schema: z.object({
    columnsOrRows: z.number().int().min(2).max(10)
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const columnsOrRows = randomIntegerInclusive(2, 10, {
      constraint: x => x !== 3
    });

    return { columnsOrRows };
  },
  Component: props => {
    const {
      question: { columnsOrRows },
      translate
    } = props;

    return (
      <QF1ContentAndSentences
        mainPanelStyle={{ flexDirection: 'row' }}
        sentences={[
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${MULT} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`,
          `<ans/> ${DIV} <ans/> = <ans/>`
        ]}
        title={translate.instructions.writeTwoMultAndTwoDivFactsShownByArray()}
        testCorrect={answer =>
          ((answer[0][0] === columnsOrRows.toString() &&
            answer[0][1] === '3' &&
            answer[1][0] === '3' &&
            answer[1][1] === columnsOrRows.toString()) ||
            (answer[0][0] === '3' &&
              answer[0][1] === columnsOrRows.toString() &&
              answer[1][0] === columnsOrRows.toString() &&
              answer[1][1] === '3')) &&
          answer[0][2] === (3 * columnsOrRows).toString() &&
          answer[1][2] === (3 * columnsOrRows).toString() &&
          ((answer[2][1] === columnsOrRows.toString() &&
            answer[2][2] === '3' &&
            answer[3][1] === '3' &&
            answer[3][2] === columnsOrRows.toString()) ||
            (answer[2][1] === '3' &&
              answer[2][2] === columnsOrRows.toString() &&
              answer[3][1] === columnsOrRows.toString() &&
              answer[3][2] === '3')) &&
          answer[2][0] === (3 * columnsOrRows).toString() &&
          answer[3][0] === (3 * columnsOrRows).toString()
        }
        inputMaxCharacters={2}
        {...props}
        Content={({ dimens }) => (
          <ArrayOfObjects
            // columnsOrRows always displays in a tall form - columns is always less than or equal to rows.
            rows={columnsOrRows > 3 ? columnsOrRows : 3}
            columns={columnsOrRows > 3 ? 3 : columnsOrRows}
            dimens={
              columnsOrRows === 2 ? { height: dimens.height * 0.76, width: dimens.width } : dimens
            }
          />
        )}
        questionHeight={1000}
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [
              columnsOrRows.toLocaleString(),
              (3).toLocaleString(),
              (3 * columnsOrRows).toLocaleString()
            ],
            [
              (3).toLocaleString(),
              columnsOrRows.toLocaleString(),
              (3 * columnsOrRows).toLocaleString()
            ],
            [
              (3 * columnsOrRows).toLocaleString(),
              (3).toLocaleString(),
              columnsOrRows.toLocaleString()
            ],
            [
              (3 * columnsOrRows).toLocaleString(),
              columnsOrRows.toLocaleString(),
              (3).toLocaleString()
            ]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

// Question3 exported to Q ak6
const Question3 = newQuestionContent({
  uid: 'akl',
  description: 'akl',
  keywords: ['Times-table', '3', '100 square'],
  schema: z.object({
    startNumber: numberEnum([1, 11, 21, 31, 41])
  }),
  simpleGenerator: () => {
    const startNumber = getRandomFromArray([1, 11, 21, 31, 41] as const);

    return { startNumber };
  },
  Component: props => {
    const {
      question: { startNumber },
      translate
    } = props;

    const correctNumbers = range(startNumber, startNumber + 49).filter(number => number % 3 === 0);

    return (
      <QF31NumberGridInteractive
        startNumber={startNumber}
        finishNumber={startNumber + 49}
        title={translate.instructions.selectAllMultiplesOfNum(3)}
        pdfTitle={translate.instructions.shadeMultiplesOfX(3)}
        testCorrect={correctNumbers}
        initialState={[correctNumbers[0]]}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});
export const akl = Question3;

const Question4 = newQuestionContent({
  uid: 'akm',
  description: 'akm',
  keywords: ['Times-table', '3', 'Fact family', 'Inverse', 'Multiply', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 3, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 3, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ result: numberC1, right: 3, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ result: numberD1, right: 3, sign: 'divide', answer: 'left' });

    const eqs = [eqA, eqB, eqC, eqD];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.completeNumberSentences()}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={1000}
      />
    );
  }
});

const Question4v2 = newQuestionContent({
  uid: 'akm2',
  description: 'akm',
  keywords: ['Times-table', '3', 'Fact family', 'Inverse', 'Multiply', 'Divide'],
  schema: z
    .object({
      numberA1: z.number().int().min(2).max(12),
      numberB1: z.number().int().min(2).max(12),
      numberC1: z.number().int().min(2).max(12),
      numberD1: z.number().int().min(2).max(12)
    })
    .refine(
      val => arrayHasNoDuplicates([val.numberA1, val.numberB1, val.numberC1, val.numberD1]),
      'All numbers must be different.'
    ),
  questionHeight: 1000,
  simpleGenerator: () => {
    const [numberA1, numberB1, numberC1, numberD1] = randomUniqueIntegersInclusive(2, 12, 4);
    return { numberA1, numberB1, numberC1, numberD1 };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1, numberC1, numberD1 },
      translate
    } = props;

    const eqA = getBinOpEquation({ left: numberA1, right: 3, sign: 'multiply', answer: 'result' });

    const eqB = getBinOpEquation({ left: numberB1, right: 3, sign: 'multiply', answer: 'left' });

    const eqC = getBinOpEquation({ result: numberC1, right: 3, sign: 'divide', answer: 'result' });

    const eqD = getBinOpEquation({ result: numberD1, right: 3, sign: 'divide', answer: 'left' });

    const left = [
      `${eqA.left.toLocaleString()} ${MULT} ${eqA.right.toLocaleString()}`,
      `<ans/> ${MULT} ${eqB.right.toLocaleString()}`,
      `${eqC.left.toLocaleString()} ${DIV} ${eqC.right.toLocaleString()}`,
      `<ans/> ${DIV} ${eqD.right.toLocaleString()}`
    ];
    const right = ['<ans/>', eqB.result.toLocaleString(), '<ans/>', eqD.result.toLocaleString()];

    const leftAnswer = [[], [eqB.left.toString()], [], [eqD.left.toString()]];
    const rightAnswers = [[eqA.result.toString()], [], [eqC.result.toString()], []];

    return (
      <QF2AlignedEquations
        title={translate.instructions.completeNumberSentences()}
        testCorrect={{ left: leftAnswer, right: rightAnswers }}
        leftSide={left}
        rightSide={right}
        questionHeight={1000}
        inputMaxCharacters={2}
      />
    );
  }
});

const Question5 = newQuestionContent({
  uid: 'akn',
  description: 'akn',
  keywords: ['Times-table', '3', 'Bar model'],
  schema: z.object({
    number1: z.number().int().min(2).max(10),
    variant: z.enum(['completeBottomRow', 'splitBottomRowBy3', 'fillBottomRowWith3'])
  }),
  simpleGenerator: () => {
    const number1 = randomIntegerInclusive(2, 10);
    const variant = getRandomFromArray([
      'completeBottomRow',
      'splitBottomRowBy3',
      'fillBottomRowWith3'
    ] as const);

    return { number1, variant };
  },

  Component: ({ question: { number1, variant }, translate, ...props }) => {
    const multiplier = 3;
    const number3 = number1 * multiplier;

    let answerBoxes: number[];
    let answerIndices: number[][];
    let barArray: number[];

    // Three variantions of bar model
    switch (variant) {
      case 'fillBottomRowWith3':
        answerIndices = [[0], []];
        barArray = filledArray(multiplier, number1);
        break;
      case 'splitBottomRowBy3':
        answerIndices = [[0], []];
        barArray = filledArray(number1, multiplier);
        break;
      case 'completeBottomRow':
        answerBoxes = [...Array(multiplier).keys()];
        answerIndices = [[], answerBoxes];
        barArray = filledArray(number1, multiplier);
        break;
    }

    const numbers = [[number3], barArray];

    return (
      <QF20CompleteTheBarModel
        title={translate.instructions.completeBarModel()}
        numbers={numbers}
        answerIndices={answerIndices}
        total={number3}
        oneFontSize
        sameRowColor
        {...props}
      />
    );
  }
});

const Question6 = newQuestionContent({
  uid: 'ako',
  description: 'ako',
  keywords: ['Times-table', '3', 'Given one find other'],
  schema: z.object({
    numberA1: z.number().int().min(2).max(12),
    numberB1Relative: numberEnum([-1, 1])
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const numberA1 = randomIntegerInclusive(2, 12);

    const numberB1Relative = getRandomFromArray([-1, 1] as const);

    return { numberA1, numberB1Relative };
  },
  Component: props => {
    const {
      question: { numberA1, numberB1Relative },
      translate
    } = props;

    const numberB1 = numberA1 + numberB1Relative;
    const numberC1 = numberA1 + 10;

    const eqB = getBinOpEquation({ left: numberB1, right: 3, sign: 'multiply', answer: 'result' });

    const eqC = getBinOpEquation({ left: numberC1, right: 3, sign: 'multiply', answer: 'result' });

    const eqs = [eqB, eqC];

    return (
      <QF2AnswerBoxManySentences
        title={translate.instructions.useTheFactThatToWorkOut(numberA1, 3, numberA1 * 3)}
        testCorrect={binOpEquationsToTestCorrect(eqs)}
        sentences={eqs.map(binOpEquationToSentenceString)}
        questionHeight={500}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'The3TimesTable',
  questionTypes: [Question1, Question2, Question3, Question4v2, Question5, Question6],
  unpublishedQuestionTypes: [Question1, Question2, Question4v2],
  archivedQuestionTypes: [Question4]
});
export default SmallStep;
