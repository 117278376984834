import z from 'zod';
import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { numberEnum } from '../../../../utils/zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { ArrayOfObjects } from '../../../../components/question/representations/ArrayOfObjects';
import { AssetSvg, SvgName } from '../../../../assets/svg';
import { ArrayOfObjectsColors } from '../../../../theme/colors';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import QF29CreateArray from '../../../../components/question/questionFormats/QF29CreateArray';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bd8',
  description: 'bd8',
  keywords: ['Array', 'Row', 'Column'],
  schema: z
    .object({
      isRows: z.boolean(),
      answerCount: numberEnum([2, 5]),
      otherCount: z.number().int().min(2).max(5),
      item: z.enum([
        'Array_objects/AppleRed',
        'Array_objects/AppleGreen',
        'Circles/circle_blue',
        'Circles/circle_red',
        'Circles/circle_yellow',
        'Circles/circle_green',
        'Cubes_blank/Coloured_cube_unlabelled_blue',
        'Cubes_blank/Coloured_cube_unlabelled_purple',
        'Cubes_blank/Coloured_cube_unlabelled_orange',
        'Cubes_blank/Coloured_cube_unlabelled_red',
        'Cubes_blank/Coloured_cube_unlabelled_green',
        'Cubes_blank/Coloured_cube_unlabelled_yellow',
        'Marble',
        'Sweets/SweetPink',
        'Sweets/SweetRed',
        'Sweets/SweetGreen',
        'Sweets/SweetBlue'
      ])
    })
    .refine(val => val.answerCount !== val.otherCount),
  simpleGenerator: () => {
    const isRows = getRandomBoolean();
    const answerCount = getRandomFromArray([2, 5] as const);
    const otherCount = randomIntegerInclusive(2, 5, { constraint: x => x !== answerCount });
    // doing it this way so we have same chance of getting each shape
    const itemName = getRandomFromArray(['Apple', 'Marble', 'Counter', 'Cube', 'Sweet'] as const);

    let item: SvgName;
    switch (itemName) {
      case 'Apple':
        item = getRandomFromArray(['Array_objects/AppleRed', 'Array_objects/AppleGreen'] as const);
        break;
      case 'Counter':
        item = getRandomFromArray([
          'Circles/circle_blue',
          'Circles/circle_red',
          'Circles/circle_yellow',
          'Circles/circle_green'
        ] as const);
        break;
      case 'Cube':
        item = getRandomFromArray([
          'Cubes_blank/Coloured_cube_unlabelled_blue',
          'Cubes_blank/Coloured_cube_unlabelled_purple',
          'Cubes_blank/Coloured_cube_unlabelled_orange',
          'Cubes_blank/Coloured_cube_unlabelled_red',
          'Cubes_blank/Coloured_cube_unlabelled_green',
          'Cubes_blank/Coloured_cube_unlabelled_yellow'
        ] as const);
        break;
      case 'Marble':
        item = 'Marble';
        break;
      case 'Sweet':
        item = getRandomFromArray([
          'Sweets/SweetPink',
          'Sweets/SweetRed',
          'Sweets/SweetGreen',
          'Sweets/SweetBlue'
        ] as const);
        break;
    }

    return { isRows, answerCount, otherCount, item };
  },
  Component: props => {
    const {
      question: { isRows, answerCount, otherCount, item },
      translate
    } = props;

    const rows = isRows ? answerCount : otherCount;
    const columns = isRows ? otherCount : answerCount;
    return (
      <QF1ContentAndSentence
        sentence={
          isRows
            ? translate.ks1AnswerSentences.thereAreAnsRows()
            : translate.ks1AnswerSentences.thereAreAnsColumns()
        }
        title={
          isRows
            ? translate.ks1Instructions.howManyRows()
            : translate.ks1Instructions.howManyColumns()
        }
        testCorrect={[answerCount.toString()]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            customImage={
              <AssetSvg name={item} height={dimens.height / (rows + 1)} width={dimens.width / 7} />
            }
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bd9',
  description: 'bd9',
  keywords: ['Array', 'Row', 'Column', 'Altogether'],
  schema: z.object({
    isRows: z.boolean(),
    answerCount: numberEnum([2, 5, 10]),
    otherCount: z.number().int().min(2).max(10),
    color: z.string()
  }),
  simpleGenerator: () => {
    const isRows = getRandomBoolean();
    const answerCount = isRows ? 2 : getRandomFromArray([2, 5, 10] as const);
    const otherCount = isRows ? randomIntegerInclusive(2, 10) : randomIntegerInclusive(2, 3);
    const color = getRandomFromArray(Object.values(ArrayOfObjectsColors)) as string;

    return { isRows, answerCount, otherCount, color };
  },
  Component: props => {
    const {
      question: { isRows, answerCount, otherCount, color },
      translate
    } = props;

    const rows = isRows ? answerCount : otherCount;
    const columns = isRows ? otherCount : answerCount;

    return (
      <QF1ContentAndSentences
        sentences={[
          isRows
            ? translate.ks1AnswerSentences.thereAreAnsRowsOfAns()
            : translate.ks1AnswerSentences.thereAreAnsColumnsOfAns(),
          translate.ks1AnswerSentences.thereAreAnsCountersAltogether()
        ]}
        title={translate.ks1Instructions.completeTheSentences()}
        testCorrect={[
          [answerCount.toString(), otherCount.toString()],
          [(answerCount * otherCount).toString()]
        ]}
        Content={({ dimens }) => (
          <ArrayOfObjects
            color={color}
            counterSize={Math.min(dimens.width / (columns + 1), dimens.height / (rows + 1))}
            dimens={{
              height: dimens.height,
              width: dimens.width
            }}
            rowStyle={{ gap: 20 }}
            containerStyle={{ gap: 10 }}
            rows={rows}
            columns={columns}
          />
        )}
        pdfDirection="column"
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bea',
  description: 'bea',
  keywords: ['Array', 'Row', 'Column'],
  schema: z.object({
    number1: numberEnum([2, 5, 10]),
    number2: z.number().int().min(2).max(6),
    isRow: z.boolean()
  }),
  simpleGenerator: () => {
    const number1 = getRandomFromArray([2, 5, 10] as const);
    const number2 = randomIntegerInclusive(2, 6);
    const isRow = number1 === 10 ? false : getRandomBoolean();

    return { number1, number2, isRow };
  },
  Component: props => {
    const {
      question: { number1, number2, isRow },
      translate
    } = props;

    const rows = isRow ? number1 : number2;
    const columns = isRow ? number2 : number1;

    return (
      <QF29CreateArray
        numberOfRows={6}
        numberOfCols={10}
        title={translate.ks1Instructions.tapTheCirclesToMakeArrayOfXRowsAndYColumns(rows, columns)}
        pdfTitle={translate.ks1PDFInstructions.shadeTheCirclesToMakeArrayOfXRowsAndYColumns(
          rows,
          columns
        )}
        testCorrect={[rows, columns]}
        answerSpecificOrientation
        showArrayOnPDF
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.acceptAnyShadedArrayWithXRowsAndYColumns(rows, columns)
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeArrays',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
