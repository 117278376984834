import { Line, Svg } from 'react-native-svg';
import { AssetSvg, SvgName, getSvgInfo } from '../../../../assets/svg';
import { View, type StyleProp, type ViewStyle } from 'react-native';
import Ruler from './Ruler';
import { colors } from '../../../../theme/colors';
import { containAspectRatio } from '../../../../theme/scaling';
import MetreSticks from './MetreSticks';
import { Fragment } from 'react';
import MetreSticks10Part from './MetreSticks10Part';

const RULER_MARGIN = 10;

type RulerKind = 'cm' | 'mm' | 'm' | 'm10Part';
type Item = {
  /** Length in the ruler's units, in the direction of the ruler. */
  length: number;
  /** Where on the rule the left edge of the image is aligned. Default: 0 */
  start?: number;
  /** Provide SVG's info if this is an SVG provided in this package's assets. */
  svgInfo?: {
    name: SvgName;
    /** How many pixels into the SVG's natural dimensions to begin measuring from. Default: 0. */
    startOffset?: number;
    /** How many pixels into the SVG's natural dimensions to begin measuring from. Default: 0. */
    endOffset?: number;
  };
  /**
   * Provide this for any other image.
   * The pixelLength is given in the dimension we're measuring (so it depends on `orientation`).
   */
  imageInfo?: (
    /** Length of the image in pixels along the direction of the ruler. */
    pixelLength: number,
    /** Number of pixels in one of the ruler's units. */
    pixelsPerUnit: number,
    scaleFactor: number
  ) => {
    /**
     * Length of the image in the axis perpendicular to the ruler. Used as a hint to help with scaling.
     * (This hint works best if the crossAxisLength is proportional to the pixelLength.)
     */
    crossAxisLength: number;
    /** The image itself */
    image: JSX.Element;
    /** How many pixels into the image to begin measuring from. Default: 0. */
    startOffset?: number;
    /** How many pixels into the image to begin measuring from. Default: 0. */
    endOffset?: number;
  };
  /** Only applies to lines. Default: black */
  lineColor?: string;
  /** Whether to show guidelines at the beginning and end of the image. Default: false */
  guidelines?: boolean;
};

type ResolvedItem = {
  length: number;
  start: number;
  imageInfo: (
    pixelLength: number,
    pixelsPerUnit: number,
    scaleFactor: number
  ) => { crossAxisLength: number; image: JSX.Element; startOffset?: number; endOffset?: number };
  guidelines: boolean;
};

type Props = {
  /** The _available_ width for this component to work with. The component will fit within that. */
  width: number;
  /** The _available_ height for this component to work with. The component will fit within that. */
  height: number;
  /** Whether the orientation of the ruler should be vertical instead of horizontal. Default: false. */
  vertical?: boolean;
  /** Angle in degrees *clockwise* that the ruler and items are rotated. Default: 0. */
  rotation?: number;
  /**
   * Maximum scale factor that this component can grow, from the ruler's natural dimensions.
   * Defaults to 1, so that very short rulers (e.g. going up to 5cm) don't become super zoomed in.
   * Set to null for no limit.
   */
  maxScaleFactor?: number | null;
  /** Use a specified scaled factor, otherwise it is calculated for you*/
  scaleFactor?: number;
  /** Default: cm */
  rulerKind?: RulerKind;
  hideMillimetres?: boolean;
  /** Length of the ruler in the units given by `rulerKind` (NOT pixels). Default: 15 if cm, 150 if mm, 2 if m */
  rulerLength?: number;
  /**
   * Provide only one of svgName or imageInfo.
   * To indicate a line, leave svgName and imageInfo undefined.
   */
  items: Array<Item>;
  /**
   * sets the item width to be full width. This will not work when also showing guidelines.
   * Default is false
   */
  isInteractive?: boolean;
  style?: StyleProp<ViewStyle>;
};

/**
 * A component showing a Ruler, with various items placed above it.
 *
 * The ruler and items are automatically shrunk (but never grown) to fit in the width/height provided. Usually, width
 * (in horizontal orientation, or height in vertical orientation) is the limiting factor, but if the items have a very
 * large crossaxis length, or you apply rotation, it can be a bit more complicated.
 *
 * The width of the view is given by the `width` prop, and the height depends on the height of the items passed in,
 * as well as the rule's length.
 */
export default function ItemsAgainstRuler({
  width,
  height,
  vertical = false,
  rotation = 0,
  maxScaleFactor = 1,
  scaleFactor: scaleFactorProp,
  rulerKind = 'cm',
  hideMillimetres = false,
  rulerLength = rulerKind === 'cm' ? 15 : rulerKind === 'mm' ? 150 : 2,
  items: itemsProp,
  isInteractive = false,
  style
}: Props) {
  // Resolve all the items to the same format and apply defaults
  const items: Array<ResolvedItem> = resolveItems(itemsProp, vertical);

  const {
    naturalPixelsPerUnit,
    naturalStartOffset,
    rulerNaturalWidth,
    rulerNaturalHeight,
    naturalLargestItemCrossaxisLength
  } = getSizing(rulerKind, rulerLength, items);

  const totalNaturalWidth = vertical
    ? rulerNaturalHeight + RULER_MARGIN + naturalLargestItemCrossaxisLength
    : rulerNaturalWidth;
  const totalNaturalHeight = vertical
    ? rulerNaturalWidth
    : rulerNaturalHeight + RULER_MARGIN + naturalLargestItemCrossaxisLength;

  const scaleFactor =
    scaleFactorProp ??
    calculateScaling({
      width,
      height,
      vertical,
      rotation,
      maxScaleFactor,
      scaleFactor: scaleFactorProp,
      rulerKind,
      rulerLength,
      hideMillimetres,
      items: itemsProp,
      style
    });

  // With the scale factor
  const rulerWidth = rulerNaturalWidth * scaleFactor;
  const rulerHeight = rulerNaturalHeight * scaleFactor;
  const scaledPixelsPerUnit = naturalPixelsPerUnit * scaleFactor;
  const scaledStartOffset = naturalStartOffset * scaleFactor;

  return vertical ? (
    <View
      style={[
        {
          flexDirection: 'row',
          width: totalNaturalWidth * scaleFactor,
          height: totalNaturalHeight * scaleFactor,
          transform: [{ rotate: `${rotation}deg` }]
        },
        style
      ]}
    >
      <View
        style={{
          height: '100%',
          width: naturalLargestItemCrossaxisLength * scaleFactor,
          marginRight: RULER_MARGIN * scaleFactor
        }}
      >
        {items.map((item, index) => {
          const imageInfo = item.imageInfo(
            item.length * scaledPixelsPerUnit,
            scaledPixelsPerUnit,
            scaleFactor
          );
          return (
            <Fragment key={index}>
              <View
                style={{
                  position: 'absolute',
                  right: 0,
                  bottom:
                    item.start * scaledPixelsPerUnit +
                    scaledStartOffset -
                    (imageInfo.startOffset ?? 0)
                }}
              >
                {imageInfo.image}
              </View>
              {item.guidelines && (
                <>
                  <Svg
                    height={2}
                    width={10 + imageInfo.crossAxisLength}
                    style={{
                      position: 'absolute',
                      right: -RULER_MARGIN * scaleFactor,
                      bottom: item.start * scaledPixelsPerUnit + scaledStartOffset - 1
                    }}
                  >
                    <Line
                      y1={1}
                      y2={1}
                      x1={0}
                      x2={10 + imageInfo.crossAxisLength}
                      strokeWidth={2}
                      strokeDasharray="6"
                      stroke={colors.burntSiennaDark}
                    />
                  </Svg>
                  <Svg
                    height={2}
                    width={10 + imageInfo.crossAxisLength}
                    style={{
                      position: 'absolute',
                      right: -RULER_MARGIN * scaleFactor,
                      bottom:
                        (item.start + item.length) * scaledPixelsPerUnit + scaledStartOffset - 1
                    }}
                  >
                    <Line
                      y1={1}
                      y2={1}
                      x1={0}
                      x2={10 + imageInfo.crossAxisLength}
                      strokeWidth={2}
                      strokeDasharray="6"
                      stroke={colors.burntSiennaDark}
                    />
                  </Svg>
                </>
              )}
            </Fragment>
          );
        })}
      </View>
      <View style={{ transform: [{ rotate: '-90deg' }] }}>
        {getRuler({
          rulerKind,
          rulerLength,
          rulerWidth,
          rulerHeight,
          hideMillimetres,
          scaleFactor
        })}
      </View>
    </View>
  ) : (
    <View
      style={[
        {
          flexDirection: 'column',
          width: totalNaturalWidth * scaleFactor,
          height: totalNaturalHeight * scaleFactor,
          transform: [{ rotate: `${rotation}deg` }]
        },
        style
      ]}
    >
      <View
        style={{
          height: naturalLargestItemCrossaxisLength * scaleFactor,
          width: '100%',
          marginBottom: RULER_MARGIN * scaleFactor,
          zIndex: 10
        }}
      >
        {items.map((item, index) => {
          const imageInfo = item.imageInfo(
            item.length * scaledPixelsPerUnit,
            scaledPixelsPerUnit,
            scaleFactor
          );
          return (
            <View
              key={index}
              style={{
                position: 'absolute',
                bottom: 0,
                width: isInteractive ? '100%' : undefined,
                left:
                  item.start * scaledPixelsPerUnit +
                  scaledStartOffset +
                  (imageInfo.startOffset ?? 0)
              }}
            >
              {imageInfo.image}
              {item.guidelines && (
                <>
                  <Svg
                    width={2}
                    height={10 + imageInfo.crossAxisLength}
                    style={{ position: 'absolute', bottom: -RULER_MARGIN * scaleFactor, left: -1 }}
                  >
                    <Line
                      x1={1}
                      x2={1}
                      y1={0}
                      y2={10 + imageInfo.crossAxisLength}
                      strokeWidth={2}
                      strokeDasharray="6"
                      stroke={colors.burntSiennaDark}
                    />
                  </Svg>
                  <Svg
                    width={2}
                    height={10 + imageInfo.crossAxisLength}
                    style={{ position: 'absolute', bottom: -RULER_MARGIN * scaleFactor, right: -1 }}
                  >
                    <Line
                      x1={1}
                      x2={1}
                      y1={0}
                      y2={10 + imageInfo.crossAxisLength}
                      strokeWidth={2}
                      strokeDasharray="6"
                      stroke={colors.burntSiennaDark}
                    />
                  </Svg>
                </>
              )}
            </View>
          );
        })}
      </View>
      {getRuler({ rulerKind, rulerLength, rulerWidth, rulerHeight, hideMillimetres, scaleFactor })}
    </View>
  );
}

const getRuler = ({
  rulerKind,
  rulerLength,
  rulerWidth,
  rulerHeight,
  hideMillimetres,
  scaleFactor
}: {
  rulerKind: RulerKind;
  rulerLength: number;
  rulerWidth: number;
  rulerHeight: number;
  hideMillimetres: boolean;
  scaleFactor: number;
}) => {
  switch (rulerKind) {
    case 'm':
      return <MetreSticks rulerLength={rulerLength} scaleFactor={scaleFactor} />;
    case 'mm':
    case 'cm':
      return (
        <Ruler
          rulerKind={rulerKind}
          rulerLength={rulerLength}
          width={rulerWidth}
          height={rulerHeight}
          hideMillimetres={hideMillimetres}
        />
      );

    case 'm10Part':
      return <MetreSticks10Part rulerLength={rulerLength} scaleFactor={scaleFactor} />;
  }
};

export function calculateScaling({
  width,
  height,
  vertical = false,
  rotation = 0,
  maxScaleFactor = 1,
  rulerKind = 'cm',
  rulerLength = rulerKind === 'cm' ? 15 : rulerKind === 'mm' ? 150 : 2,
  items: itemsProp
}: Props) {
  // Resolve all the items to the same format and apply defaults
  const items: Array<ResolvedItem> = resolveItems(itemsProp, vertical);

  const { rulerNaturalWidth, rulerNaturalHeight, naturalLargestItemCrossaxisLength } = getSizing(
    rulerKind,
    rulerLength,
    items
  );
  // We need to fit the ruler into the available space. First calculate the natural width and height of the whole
  // component.

  const totalNaturalWidth = vertical
    ? rulerNaturalHeight + RULER_MARGIN + naturalLargestItemCrossaxisLength
    : rulerNaturalWidth;
  const totalNaturalHeight = vertical
    ? rulerNaturalWidth
    : rulerNaturalHeight + RULER_MARGIN + naturalLargestItemCrossaxisLength;

  // Now we might apply a rotation to this, which might need a different shaped rectangle to contain it.
  const theta = (rotation * Math.PI) / 180;
  const cosTheta = Math.cos(theta);
  const sinTheta = Math.sin(theta);
  const rotatePoint = ([x, y]: [number, number]) => [
    x * cosTheta + y * sinTheta,
    -y * cosTheta + x * sinTheta
  ];
  const rotatedRectangleVertices = [
    [0, 0],
    rotatePoint([0, totalNaturalHeight]),
    rotatePoint([totalNaturalWidth, 0]),
    rotatePoint([totalNaturalWidth, totalNaturalHeight])
  ];
  const xCoords = rotatedRectangleVertices.map(([x, _y]) => x);
  const yCoords = rotatedRectangleVertices.map(([_x, y]) => y);
  const rotatedNaturalHeight = Math.max(...yCoords) - Math.min(...yCoords);
  const rotatedNaturalWidth = Math.max(...xCoords) - Math.min(...xCoords);

  // Finally, we can scale down everything to fit in the actual space we were given
  const { width: actualWidth } = containAspectRatio(
    { width, height },
    rotatedNaturalWidth / rotatedNaturalHeight
  );
  return Math.min(
    actualWidth / rotatedNaturalWidth,
    maxScaleFactor === null ? Number.POSITIVE_INFINITY : maxScaleFactor
  );
}

const resolveItems = (items: Array<Item>, vertical: boolean) =>
  items.map(
    ({ length, start = 0, svgInfo, imageInfo, lineColor = 'black', guidelines = false }: Item) => {
      // This item might be described with imageInfo, svgName or it might just be a line. In each case, describe it
      // just with imageInfo to simplify the code later.
      if (imageInfo !== undefined) {
        // imageInfo
        return { length, start, imageInfo, guidelines };
      } else if (svgInfo !== undefined) {
        // svgInfo
        const svg = getSvgInfo(svgInfo.name);
        const naturalLength = vertical ? svg.height : svg.width;
        const naturalCrossAxisLength = vertical ? svg.width : svg.height;
        const naturalMeasuredLength =
          naturalLength - (svgInfo.startOffset ?? 0) - (svgInfo.endOffset ?? 0);

        return {
          length,
          start,
          imageInfo: pixelLength => {
            // Need to scale SVG so that naturalMeasuredLength becomes pixelLength.
            const svgScaleFactor = pixelLength / naturalMeasuredLength;
            const length = naturalLength * svgScaleFactor;
            const crossAxisLength = naturalCrossAxisLength * svgScaleFactor;

            return {
              crossAxisLength,
              image: vertical ? (
                <AssetSvg name={svgInfo.name} height={length} width={crossAxisLength} />
              ) : (
                <AssetSvg name={svgInfo.name} width={length} height={crossAxisLength} />
              ),
              startOffset: (svgInfo.startOffset ?? 0) * svgScaleFactor,
              endOffset: (svgInfo.endOffset ?? 0) * svgScaleFactor
            };
          },
          guidelines
        };
      } else {
        // It's a line
        return {
          length,
          start,
          imageInfo: (pixelLength, _pixelsPerUnit, scaleFactor) => ({
            crossAxisLength: 20 * scaleFactor,
            image: vertical ? (
              <Svg height={pixelLength} width={20 * scaleFactor}>
                <Line
                  y1={0}
                  y2={pixelLength}
                  x1={10 * scaleFactor}
                  x2={10 * scaleFactor}
                  strokeWidth={5 * scaleFactor}
                  stroke={lineColor}
                />
              </Svg>
            ) : (
              <Svg width={pixelLength} height={20 * scaleFactor}>
                <Line
                  x1={0}
                  x2={pixelLength}
                  y1={10 * scaleFactor}
                  y2={10 * scaleFactor}
                  strokeWidth={5 * scaleFactor}
                  stroke={lineColor}
                />
              </Svg>
            )
          }),
          guidelines
        };
      }
    }
  );

const getSizing = (rulerKind: RulerKind, rulerLength: number, items: ResolvedItem[]) => {
  const {
    pixelsPerUnit: naturalPixelsPerUnit,
    startOffset: naturalStartOffset,
    naturalWidth: rulerNaturalWidth,
    naturalHeight: rulerNaturalHeight
  } = (() => {
    switch (rulerKind) {
      case 'm':
        return MetreSticks.getRulerSizingInfo({ rulerLength });
      case 'cm':
      case 'mm':
        return Ruler.getRulerSizingInfo({
          rulerKind,
          rulerLength
        });
      case 'm10Part':
        return MetreSticks10Part.getRulerSizingInfo({ rulerLength });
    }
  })();

  // We need to fit the ruler into the available space. First calculate the natural width and height of the whole
  // component.
  const naturalLargestItemCrossaxisLength = Math.max(
    ...items.map(
      item =>
        item.imageInfo(item.length * naturalPixelsPerUnit, naturalPixelsPerUnit, 1).crossAxisLength
    )
  );

  return {
    naturalPixelsPerUnit,
    naturalStartOffset,
    rulerNaturalWidth,
    rulerNaturalHeight,
    naturalLargestItemCrossaxisLength
  };
};
