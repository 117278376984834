import { useContext } from 'react';
import { colors } from 'common/src/theme/colors';
import { Ellipse, G, Line, Path, Polygon } from 'react-native-svg';
import { GridContext } from 'common/src/components/question/representations/Coordinates/Grid';
import { TSpan, Text as SvgText } from 'react-native-svg';

export function GridPolygon({
  points,
  color,
  showBorder = false,
  opacity,
  borderWidth
}: {
  points: [number, number][];
  color?: string | null;
  showBorder?: boolean;
  opacity?: number;
  borderWidth?: number;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <Polygon
      points={points.map(([x, y]) => `${mathToSvgX(x)},${mathToSvgY(y)}`).join(' ')}
      fill={color === null ? 'none' : color ?? `${colors.pacificBlue}30`}
      opacity={opacity}
      stroke={showBorder ? colors.prussianBlue : undefined}
      strokeWidth={showBorder ? borderWidth ?? 2 : 0}
    />
  );
}

export function GridEllipse({
  center,
  rx,
  ry,
  color,
  showBorder = false,
  opacity,
  borderWidth
}: {
  center: [number, number];
  rx: number;
  ry: number;
  color?: string | null;
  showBorder?: boolean;
  opacity?: number;
  borderWidth?: number;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <Ellipse
      cx={mathToSvgX(center[0])}
      cy={mathToSvgY(center[1])}
      rx={rx * Math.abs(mathToSvgX(1) - mathToSvgX(0))}
      ry={ry * Math.abs(mathToSvgY(1) - mathToSvgY(0))}
      fill={color === null ? 'none' : color ?? `${colors.pacificBlue}30`}
      opacity={opacity}
      stroke={showBorder ? colors.prussianBlue : undefined}
      strokeWidth={showBorder ? borderWidth ?? 2 : 0}
    />
  );
}

export function GridHeart({
  center,
  width,
  color,
  showBorder = false,
  opacity,
  borderWidth
}: {
  center: [number, number];
  width: number;
  color?: string | null;
  showBorder?: boolean;
  opacity?: number;
  borderWidth?: number;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  const scale = Math.abs(mathToSvgX(1) - mathToSvgX(0)) / 475.528;
  const pixels = Math.abs(mathToSvgX(1) - mathToSvgX(0)) * width;

  return (
    <G
      transform={
        `translate(${mathToSvgX(center[0])}, ${mathToSvgY(center[1])})` + `scale(${pixels})`
      }
    >
      {/* A 1 pixel wide cardioid centered at the origin */}
      <Path
        // This path is of a 475.528 pixels wide cardioid
        d="M237.376,436.245l0.774,0.976c210.94-85.154,292.221-282.553,199.331-367.706    c-92.899-85.154-199.331,30.953-199.331,30.953h-0.774c0,0-106.44-116.107-199.331-30.953    C-54.844,154.658,26.437,351.092,237.376,436.245z"
        transform={`translate(-0.5, -0.5) scale(${1 / 475.528})`}
        fill={color === null ? 'none' : color ?? `${colors.pacificBlue}30`}
        opacity={opacity}
        stroke={showBorder ? colors.prussianBlue : undefined}
        strokeWidth={(showBorder ? borderWidth ?? 2 : 0) / (scale * width)}
      />
    </G>
  );
}

export function GridText({
  label,
  coordinates,
  fontSize = 36
}: {
  label: string;
  coordinates: [number, number];
  fontSize?: number;
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <SvgText y={mathToSvgY(coordinates[1])} x={mathToSvgX(coordinates[0])} textAnchor="middle">
      <TSpan fontSize={fontSize} fill="black" fontFamily="White_Rose_Noto-Regular">
        {label}
      </TSpan>
    </SvgText>
  );
}

export function GridLine({
  points,
  color,
  strokeWidth,
  strokeDasharray
}: {
  points: [number, number][];
  color?: string;
  strokeWidth?: number;
  strokeDasharray?: number[];
}) {
  const { mathToSvgX, mathToSvgY } = useContext(GridContext);
  return (
    <Line
      x1={mathToSvgX(points[0][0])}
      y1={mathToSvgY(points[0][1])}
      x2={mathToSvgX(points[1][0])}
      y2={mathToSvgY(points[1][1])}
      stroke={color ?? colors.prussianBlue}
      strokeWidth={strokeWidth ?? 2}
      strokeDasharray={strokeDasharray}
    />
  );
}
