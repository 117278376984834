import { useContext, useMemo } from 'react';
import BaseLayout from '../../molecules/BaseLayout';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { View } from 'react-native';
import { DisplayMode } from '../../../contexts/displayMode';
import EasyDragAndDrop from '../../draganddrop/EasyDragAndDrop';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import {
  displayMoney,
  moneyPenceToString,
  moneyStringToPence,
  moneyToHighestDenominations,
  PossibleMoneyDenominations
} from '../../../utils/money';
import { colors } from '../../../theme/colors';

type Props = TitleStyleProps & {
  title: string;
  pdfTitle?: string;
  initialState?: number[];
  /**
   * Array of PossibleMoneyDenominations to display in a static container to the left of the dropzone.
   * Optional prop.
   */
  leftHandStaticMoney?: PossibleMoneyDenominations[];
  /**
   * Boolean to determine whether the denominations in initial state should be frozen, i.e. they cannot be dragged or clicked out.
   * This is to prevent the user removing the initial state, if they need to add to it to complete a question.
   * Optional prop, defaults to false.
   */
  freezeInitialState?: boolean;
  amountInPenceToMake: number;
  draggablesToShow: PossibleMoneyDenominations[];
  /** PDF Question Height */
  questionHeight?: number;
  customMarkSchemeAnswer?: string;
};

/**
 * Question format for creating an amount of money using coins and/or notes
 * by dragging them into a single drop zone.
 */
export default function QF16DragMoneyIntoABox({
  title,
  pdfTitle,
  initialState = [],
  leftHandStaticMoney,
  freezeInitialState = false,
  amountInPenceToMake,
  draggablesToShow,
  questionHeight,
  customMarkSchemeAnswer,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  const rows = 3;

  // More images can fit on rows if there are definitely no note images used:
  const imagesPerRow = leftHandStaticMoney
    ? draggablesToShow.filter(denomination => denomination > 200).length > 0
      ? 2
      : 4
    : draggablesToShow.filter(denomination => denomination > 200).length > 0
    ? 4
    : 7;

  /** Scale of an image in the drop zone. */
  const imageScale = 0.75;

  const capacity = rows * imagesPerRow;

  const elements = displayMoney(
    draggablesToShow.map(denomination => moneyPenceToString[denomination]),
    undefined,
    192,
    true
  ) as JSX.Element[];

  const leftHandElements = leftHandStaticMoney
    ? (displayMoney(
        leftHandStaticMoney.map(denomination => moneyPenceToString[denomination]),
        undefined,
        undefined,
        true
      ) as JSX.Element[])
    : undefined;

  const DRAGGABLE_HEIGHT = 96;

  const DRAGGABLE_WIDTH =
    draggablesToShow.filter(denomination => denomination > 200).length > 0 ? 192 : 96;

  const PDF_DRAGGABLE_HEIGHT = questionHeight ? questionHeight / 3.6 : 384;

  const PDF_DRAGGABLE_WIDTH =
    draggablesToShow.filter(denomination => denomination > 200).length > 0 ? 384 : 192;

  const items = useMemo(
    () =>
      elements.map((denomination, i) => {
        const component: JSX.Element = denomination;
        return {
          component: component,
          value: draggablesToShow[i],
          width: draggablesToShow[i] > 200 ? 192 : 96
        };
      }),
    [draggablesToShow, elements]
  );

  const totalOfInitialState = initialState.reduce((sum, nextCoin) => sum + nextCoin, 0);

  const exampleAnswer: PossibleMoneyDenominations[] = moneyToHighestDenominations(
    // If initial state is provided, technically the user actually has to make amountInPenceToMake - totalOfInitialState
    initialState.length > 0 ? amountInPenceToMake - totalOfInitialState : amountInPenceToMake,
    'pence',
    draggablesToShow
  ).map(
    denomination =>
      moneyStringToPence[
        denomination as keyof typeof moneyStringToPence
      ] as PossibleMoneyDenominations
  );

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    return (
      <EasyDragAndDrop.Provider
        items={items}
        state={[
          displayMode === 'pdf'
            ? initialState.map(denom => items.findIndex(item => item.value === denom))
            : [
                ...initialState.map(denom => items.findIndex(item => item.value === denom)),
                ...exampleAnswer.map(denom => items.findIndex(item => item.value === denom))
              ]
        ]}
        draggableStyle={{
          borderWidth: 0,
          shadowOpacity: 0,
          backgroundColor: 'transparent',
          width: PDF_DRAGGABLE_HEIGHT * imageScale,
          height: PDF_DRAGGABLE_HEIGHT * imageScale
        }}
        draggablesToFreeze={freezeInitialState ? initialState.map((_, i) => i) : undefined}
      >
        <BaseLayoutPDF
          title={pdfTitle ?? title}
          mainPanelContents={
            <View style={{ gap: 12, alignItems: 'center' }}>
              <View style={{ width: '100%', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                {leftHandStaticMoney && (
                  <View
                    style={{
                      borderWidth: 2,
                      borderColor: colors.black,
                      borderRadius: 8,
                      width: '45%',
                      height: rows * (PDF_DRAGGABLE_HEIGHT * imageScale),
                      columnGap: 16,
                      rowGap: 44,
                      padding: 10,
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      alignSelf: 'center',
                      alignContent: 'center',
                      flexWrap: 'wrap'
                    }}
                  >
                    {leftHandElements}
                  </View>
                )}
                <EasyDragAndDrop.ZoneMultiple
                  id={0}
                  style={{
                    width: leftHandStaticMoney ? '45%' : '100%',
                    height: rows * (PDF_DRAGGABLE_HEIGHT * imageScale),
                    columnGap: leftHandStaticMoney ? 12 : 44,
                    rowGap: leftHandStaticMoney ? 32 : 44,
                    padding: 10
                  }}
                  droppedStyle={{
                    width: PDF_DRAGGABLE_HEIGHT * imageScale,
                    height: PDF_DRAGGABLE_HEIGHT * imageScale
                  }}
                />
              </View>
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  alignItems: 'center'
                }}
              >
                {items.map((item, index) => (
                  <View
                    key={index}
                    style={{
                      width: PDF_DRAGGABLE_WIDTH * 0.7,
                      height: PDF_DRAGGABLE_HEIGHT * 0.7,
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    {item.component}
                  </View>
                ))}
              </View>
              {displayMode === 'markscheme' &&
                customMarkSchemeAnswer &&
                renderMarkSchemeProp(customMarkSchemeAnswer)}
            </View>
          }
          questionHeight={questionHeight}
          {...{ props }}
        />
      </EasyDragAndDrop.Provider>
    );
  }

  return (
    <EasyDragAndDrop.ProviderWithState
      id="draganddrop"
      items={items}
      moveOrCopy="copy"
      defaultState={[initialState]}
      draggableStyle={{
        borderWidth: 0,
        shadowOpacity: 0,
        backgroundColor: 'transparent',
        width: DRAGGABLE_WIDTH,
        height: DRAGGABLE_HEIGHT,
        alignItems: 'center'
      }}
      testComplete={state => state[0].length > 0}
      testCorrect={state =>
        state[0].reduce((sum, nextDenom) => sum + nextDenom) === amountInPenceToMake
      }
      draggablesToFreeze={freezeInitialState ? initialState.map((_, i) => i) : undefined}
    >
      <BaseLayout
        title={title}
        actionPanelVariant="end"
        mainPanelContents={
          <View style={{ height: '100%', alignItems: 'center', justifyContent: 'space-around' }}>
            <View
              style={{
                height: '83%',
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                paddingBottom: '2%'
              }}
            >
              {leftHandStaticMoney && (
                <View
                  style={{
                    borderWidth: 2,
                    borderColor: colors.black,
                    borderRadius: 8,
                    width: '45%',
                    columnGap: 16,
                    rowGap: 44,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignSelf: 'center',
                    alignContent: 'center',
                    flexWrap: 'wrap',
                    height: '100%'
                  }}
                >
                  {leftHandElements}
                </View>
              )}
              <EasyDragAndDrop.ZoneMultiple
                id={0}
                capacity={capacity}
                style={[
                  {
                    width: leftHandStaticMoney ? '45%' : '100%',
                    columnGap: leftHandStaticMoney ? 12 : 44,
                    rowGap: leftHandStaticMoney ? 32 : 44,
                    padding: 10,
                    height: '100%'
                  }
                ]}
                droppedStyle={{
                  height: DRAGGABLE_HEIGHT * imageScale
                }}
              />
            </View>
            <View style={{ flexDirection: 'row', gap: 12 }}>
              {items.map((item, index) => (
                <EasyDragAndDrop.Source
                  key={index}
                  id={index}
                  style={{
                    shadowOpacity: 0,
                    backgroundColor: 'transparent',
                    width: item.width,
                    alignItems: 'center'
                  }}
                  // Notes need double the width of coin draggables:
                  widthOverride={item.value > 200 ? 192 : 96}
                />
              ))}
            </View>
          </View>
        }
        {...props}
      />
    </EasyDragAndDrop.ProviderWithState>
  );
}
