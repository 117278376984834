import { View } from 'react-native';
import { colors, tenFrameCounterColors } from '../theme/colors';
import Text from '../components/typography/Text';
import Svg, { Polygon } from 'react-native-svg';

/**
 * Renders a circle by the color passed.
 * In colorBlindMode, adds a letter to the middle of the circle. Defaults to true.
 * */
export function renderCircleSvg(
  color: 'yellow' | 'red' | 'blue' | 'green',
  colorBlindMode = true,
  width = 96
) {
  return (
    <View
      style={{
        width: width,
        height: width,
        borderRadius: 999,
        borderWidth: 2.33,
        backgroundColor: tenFrameCounterColors[color],
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {colorBlindMode && (
        <Text
          variant="WRN700"
          style={{
            fontSize: 30,
            color: color === 'yellow' ? colors.prussianBlue : 'white'
          }}
        >
          {color.charAt(0)}
        </Text>
      )}
    </View>
  );
}

/**
 * Renders a square by the color passed.
 * In colorBlindMode, adds a letter to the middle of the square. Defaults to true.
 * */
export function renderSquareSvg(
  color: 'yellow' | 'red' | 'blue' | 'green',
  colorBlindMode = true,
  width = 96
) {
  return (
    <View
      style={{
        width: width,
        height: width,
        borderWidth: 2.33,
        backgroundColor: tenFrameCounterColors[color],
        borderColor: 'black',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {colorBlindMode && (
        <Text
          variant="WRN700"
          style={{
            fontSize: 30,
            color: color === 'yellow' ? colors.prussianBlue : 'white'
          }}
        >
          {color.charAt(0)}
        </Text>
      )}
    </View>
  );
}

/**
 * Renders a triangle by the color passed.
 * In colorBlindMode, adds a letter to the middle of the triangle. Defaults to true.
 * */
export function renderTriangleSvg(
  color: 'yellow' | 'red' | 'blue' | 'green',
  colorBlindMode = true,
  width = 96,
  height = 96
) {
  const lineWidth = 2.33;
  return (
    <View
      style={{
        width: width,
        height: height,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Svg width={width} height={height}>
        <Polygon
          points={`${lineWidth},${height - lineWidth} ${width / 2},0 ${width - lineWidth},${
            height - lineWidth
          } `}
          fill={tenFrameCounterColors[color]}
          strokeWidth={lineWidth}
          stroke={colors.black}
        />
      </Svg>
      {colorBlindMode && (
        <Text
          variant="WRN700"
          style={{
            fontSize: 30,
            color: color === 'yellow' ? colors.prussianBlue : 'white',
            backgroundColor: 'none'
          }}
        >
          {color.charAt(0)}
        </Text>
      )}
    </View>
  );
}

/**
 * Renders a given shape by the color passed.
 * In colorBlindMode, adds a letter to the middle of the shape. Defaults to true.
 * */
export function renderShapeSvg(
  color: 'yellow' | 'red' | 'blue' | 'green',
  shape: 'square' | 'circle' | 'triangle',
  colorBlindMode = true,
  width = 96,
  height = 96
) {
  switch (shape) {
    case 'circle':
      return renderCircleSvg(color, colorBlindMode, width);
    case 'square':
      return renderSquareSvg(color, colorBlindMode, width);
    case 'triangle':
      return renderTriangleSvg(color, colorBlindMode, width, height);
  }
}
