import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import BaseTenRepresentation from '../../../../components/question/representations/Base Ten/BaseTenRepresentations';
import { View } from 'react-native';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { arrayHasNoDuplicates, countRange, filledArray } from '../../../../utils/collections';
import { chunk } from '../../../../utils/chunk';
import TenFrameLayout from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import { base10ObjectToNumber } from '../../../../utils/math';
import QF39ContentWithSelectablesOnRight from '../../../../components/question/questionFormats/QF39ContentWithSelectablesOnRight';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bc7',
  description: 'bc7',
  keywords: ['Tens and ones'],
  schema: z.object({
    variant: z.enum(['tenFrame', 'rekenrek', 'straws']),
    color: z.enum(['red', 'blue', 'green', 'yellow']),
    number: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(num => num % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const variant = getRandomFromArray(['tenFrame', 'rekenrek', 'straws'] as const);

    const color = getRandomFromArray(['red', 'blue', 'green', 'yellow'] as const);

    return { number, variant, color };
  },
  Component: props => {
    const {
      question: { number, variant, color },
      translate,
      displayMode
    } = props;

    const tens = Math.floor(number / 10);
    const ones = number % 10;

    const [stringNumberA, stringNumberB] =
      variant === 'rekenrek'
        ? [translate.items.beads(tens), translate.items.beads(ones)]
        : [translate.items.counters(tens), translate.items.counters(ones)];

    const sentence =
      variant === 'straws'
        ? translate.ks1AnswerSentences.thereAreAnsBundlesOfTenStrawsAndAnsMoreStraws(ones)
        : translate.ks1AnswerSentences.thereAreAnsGroupsOfTenXAndAnsMoreY(
            stringNumberA,
            stringNumberB
          );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSentence()}
        sentence={sentence}
        testCorrect={[tens.toString(), ones.toString()]}
        Content={({ dimens }) => {
          const chunks = chunk(countRange(number), 10);

          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 16
              }}
            >
              {variant === 'rekenrek' ? (
                <Rekenrek numberShown={number} dimens={dimens} rows={10} />
              ) : variant === 'tenFrame' ? (
                chunks.map((chunk, index) => (
                  <TenFrameLayout
                    key={index}
                    items={filledArray(color, chunk.length)}
                    size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                    orientation="vertical"
                  />
                ))
              ) : (
                <BaseTenRepresentation
                  b10Rep={{
                    variant: 'Straws',
                    numbers: { ones, tens },
                    arrangement: 'ltr'
                  }}
                  usableWidth={dimens.width}
                  usableHeight={dimens.height}
                  align="center"
                />
              )}
            </View>
          );
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'bc8',
  description: 'bc8',
  keywords: ['Tens and ones'],
  schema: z.object({
    variant: z.enum(['tenFrame', 'rekenrek', 'straws']),
    selectables: z.array(z.array(z.string())),
    correctNumberOfOnes: z.number().int().min(1).max(9),
    correctNumberOfTens: z.number().int().min(2).max(9)
  }),
  simpleGenerator: () => {
    const variant = getRandomFromArray(['tenFrame', 'rekenrek', 'straws'] as const);

    const correctNumberOfOnes = randomIntegerInclusive(1, 9);
    const correctNumberOfTens = randomIntegerInclusive(2, 4);

    const correctAnswer = base10ObjectToNumber({
      ones: correctNumberOfOnes,
      tens: correctNumberOfTens
    });

    const { incorrectOptions } = rejectionSample(
      () => {
        const optionA = parseInt(correctAnswer.toString().split('').reverse().join(''));
        const optionB = correctNumberOfOnes + correctNumberOfTens;
        const optionC = correctAnswer + 1;
        const optionD = correctAnswer - 1;
        const optionE = parseInt(
          `${(correctNumberOfTens * 10).toString()}${correctNumberOfOnes.toString()}`
        );

        const incorrectOptions = getRandomSubArrayFromArray(
          [optionA, optionB, optionC, optionD, optionE],
          3
        );

        return { incorrectOptions };
      },
      ({ incorrectOptions }) => arrayHasNoDuplicates([correctAnswer, ...incorrectOptions])
    );

    const selectables = shuffle([
      ['A', correctAnswer.toLocaleString()],
      ['B', incorrectOptions[0].toLocaleString()],
      ['C', incorrectOptions[1].toLocaleString()],
      ['D', incorrectOptions[2].toLocaleString()]
    ]);

    return { variant, selectables, correctNumberOfOnes, correctNumberOfTens };
  },
  questionHeight: 900,
  Component: props => {
    const {
      question: { variant, selectables, correctNumberOfOnes, correctNumberOfTens },
      translate,
      displayMode
    } = props;

    const total = base10ObjectToNumber({
      ones: correctNumberOfOnes,
      tens: correctNumberOfTens
    });
    const titleString =
      variant === 'rekenrek'
        ? translate.items.beads(2)
        : variant === 'straws'
        ? translate.items.straws(2)
        : translate.items.counters(2);

    return (
      <QF39ContentWithSelectablesOnRight
        title={translate.ks1Instructions.howManyXAreThereSelectYourAnswer(titleString)}
        pdfTitle={translate.ks1PDFInstructions.howManyXAreThereTickYourAnswer(titleString)}
        selectables={Object.fromEntries(selectables)}
        correctAnswer={['A']}
        pdfVariation="selectablesOnBottom"
        leftContent={
          variant === 'rekenrek' ? (
            <Rekenrek scale={0.75} rows={10} numberShown={total} />
          ) : variant === 'straws' ? (
            <BaseTenRepresentation
              b10Rep={{
                variant: 'Straws',
                numbers: { ones: correctNumberOfOnes, tens: correctNumberOfTens },
                arrangement: 'ltr'
              }}
              usableWidth={500}
              usableHeight={400}
            />
          ) : (
            <View
              style={{
                flexDirection: 'row',
                gap: 20,
                flexWrap: 'wrap',
                width: displayMode === 'digital' ? 600 : 900
              }}
            >
              {countRange(Math.ceil(total / 10)).map(i => (
                <TenFrameLayout
                  items={filledArray('red', i === Math.floor(total / 10) ? total % 10 : 10)}
                  orientation="horizontal"
                  size={displayMode === 'digital' ? 'xsmall' : 'medium'}
                  key={i}
                />
              ))}
            </View>
          )
        }
        questionHeight={900}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bc9',
  description: 'bc9',
  keywords: ['Tens and ones'],
  schema: z.object({
    variant: z.enum(['tenFrame', 'rekenrek', 'straws']),
    color: z.enum(['red', 'blue', 'green', 'yellow']),
    number: z
      .number()
      .int()
      .min(21)
      .max(49)
      .refine(num => num % 10 !== 0)
  }),
  simpleGenerator: () => {
    const number = randomIntegerInclusive(21, 49, { constraint: x => x % 10 !== 0 });
    const variant = getRandomFromArray(['tenFrame', 'rekenrek', 'straws'] as const);

    const color = getRandomFromArray(['red', 'blue', 'green', 'yellow'] as const);

    return { number, variant, color };
  },
  Component: props => {
    const {
      question: { number, variant, color },
      translate,
      displayMode
    } = props;

    const tens = Math.floor(number / 10);
    const ones = number % 10;

    const titleString =
      variant === 'rekenrek'
        ? translate.items.beads(number)
        : variant === 'straws'
        ? translate.items.straws(number)
        : translate.items.counters(number);

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.howManyXAreThere(titleString)}
        sentence={'<ans/>'}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        testCorrect={[number.toString()]}
        Content={({ dimens }) => {
          const chunks = chunk(countRange(number), 10);

          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 16
              }}
            >
              {variant === 'rekenrek' ? (
                <Rekenrek numberShown={number} dimens={dimens} rows={10} />
              ) : variant === 'tenFrame' ? (
                <View
                  style={{
                    width: dimens.width,
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    gap: 16,
                    top: number > 40 ? 50 : 0
                  }}
                >
                  {chunks.map((chunk, index) => (
                    <TenFrameLayout
                      key={index}
                      items={filledArray(color, chunk.length)}
                      size={displayMode === 'digital' ? 'small' : 'medium'}
                      orientation="horizontal"
                    />
                  ))}
                </View>
              ) : (
                <BaseTenRepresentation
                  b10Rep={{
                    variant: 'Straws',
                    numbers: { ones, tens },
                    arrangement: 'ltr'
                  }}
                  usableWidth={dimens.width}
                  usableHeight={dimens.height}
                  align="center"
                />
              )}
            </View>
          );
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountByMakingGroupsOfTens',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
