import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import { isInRange } from '../../../../utils/matchers';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomFromArrayWithWeights,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { PartWholeModel } from '../../../../components/question/representations/Part Whole Model/PartWholeModel';
import {
  binOpEquationsToTestCorrect,
  binOpEquationToSentenceString,
  getBinOpEquation
} from '../../../../utils/fourOperations';
import Text from '../../../../components/typography/Text';
import { ADD, SUB } from '../../../../constants';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import QF1ContentAndSentences from '../../../../components/question/questionFormats/QF1ContentAndSentences';
import { arrayHasNoDuplicates, arraysHaveSameContents } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  questionHeight: 1200,
  uid: 'bbt',
  description: 'bbt',
  keywords: ['Part-whole model', 'Fact families', 'Add', 'Subtract'],
  schema: z
    .object({
      partA: z.number().int().min(0).max(10),
      partB: z.number().int().min(0).max(10),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft']),
      sign: z.enum(['SUB', 'ADD']),
      missing: z.enum(['left', 'right', 'result']),
      options: z
        .array(z.number().int().min(0).max(10))
        .length(3)
        .refine(x => arrayHasNoDuplicates(x), 'options should have no duplicates')
    })
    .refine(
      ({ partA, partB }) => isInRange(3, 10)(partA + partB),
      'sum of partA and partB must be between 3 and 10'
    ),
  simpleGenerator: () => {
    const sign = getRandomFromArray(['ADD', 'SUB'] as const);
    const partA = randomIntegerInclusive(0, 10);
    const partB = randomIntegerInclusive(0, 10, {
      constraint: x => isInRange(3, 10)(partA + x)
    });
    const missing = getRandomFromArray(['left', 'right', 'result'] as const);
    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    const total = partA + partB;

    const getNonDupOptions = () => {
      const possibleOptions = [...new Set([partA, partB, total])];
      if (partA === partB) {
        const extraOption = getRandomBoolean() ? partA + 1 : partA - 1;
        possibleOptions.push(extraOption);
      } else {
        const extraOption = total === 10 ? total - 1 : getRandomBoolean() ? total + 1 : total - 1;
        possibleOptions.push(extraOption);
      }

      return possibleOptions;
    };

    const options = arrayHasNoDuplicates([partA, partB, total])
      ? [partA, partB, total]
      : getNonDupOptions();

    return { partA, partB, variation, sign, missing, options: shuffle(options) };
  },
  Component: props => {
    const {
      question: { partA, partB, variation, sign, missing, options },
      translate
    } = props;
    const total = partA + partB;
    const equation = getBinOpEquation({
      left: sign === 'ADD' ? partA : total,
      right: partB,
      sign: sign === 'ADD' ? 'add' : 'subtract',
      answer: missing
    });
    const items = options.map(num => {
      return { value: num.toString(), component: num.toLocaleString() };
    });

    return (
      <QF36ContentAndSentenceDrag
        questionHeight={1200}
        actionPanelVariant="end"
        title={translate.ks1Instructions.dragTheCardsToCompleteTheNumberSentence()}
        pdfTitle={translate.ks1PDFInstructions.useTheCardsToCompleteTheNumberSentence()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={total}
            variation={variation}
            partition={[partA, partB]}
            dimens={{
              width:
                variation === 'leftRight' || variation === 'rightLeft'
                  ? dimens.width * 0.7
                  : dimens.width * 0.9,
              height: dimens.height
            }}
          />
        )}
        items={items}
        pdfLayout="itemsAboveContent"
        sentence={binOpEquationToSentenceString(equation)}
        sentencesStyle={{ alignItems: 'flex-start' }}
        testCorrect={binOpEquationsToTestCorrect([equation])[0]}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbu',
  description: 'bbu',
  keywords: ['Part-whole model', 'Add', 'Subtract', 'Number sentence'],
  questionHeight: 1000,
  schema: z
    .object({
      partA: z.number().int().min(0).max(10),
      partB: z.number().int().min(0).max(10),
      variation: z.enum(['topDown', 'bottomUp', 'leftRight', 'rightLeft'])
    })
    .refine(
      ({ partA, partB }) => isInRange(3, 10)(partA + partB),
      'sum of partA and partB must be between 3 and 10'
    ),
  simpleGenerator: () => {
    const partA = randomIntegerInclusive(0, 10);
    const partB = randomIntegerInclusive(0, 10, { constraint: x => isInRange(3, 10)(partA + x) });

    const variation = getRandomFromArrayWithWeights(
      ['topDown', 'bottomUp', 'leftRight', 'rightLeft'] as const,
      // 75% of the time we need to use the standard orientation, otherwise choose one of the others for the remaining 25%:
      [9, 1, 1, 1]
    );

    return { partA, partB, variation };
  },
  Component: ({ question, translate }) => {
    const { partA, partB, variation } = question;
    const whole = partA + partB;

    const partAString = partA.toString();
    const partBString = partB.toString();
    const wholeString = whole.toString();

    return (
      <QF1ContentAndSentences
        questionHeight={1000}
        title={translate.ks1Instructions.completeTheNumberSentences()}
        mainPanelStyle={{ flexDirection: 'row' }}
        Content={({ dimens }) => (
          <PartWholeModel
            top={whole}
            variation={variation}
            partition={[partA, partB]}
            dimens={dimens}
          />
        )}
        inputMaxCharacters={2}
        sentences={[`<ans/> ${ADD} <ans/> = <ans/>`, `<ans/> ${SUB} <ans/> = <ans/>`]}
        testCorrect={([userAns1, userAns2]) =>
          (arraysHaveSameContents(userAns1, [partAString, partBString, wholeString]) ||
            arraysHaveSameContents(userAns1, [partBString, partAString, wholeString])) &&
          (arraysHaveSameContents(userAns2, [wholeString, partBString, partAString]) ||
            arraysHaveSameContents(userAns2, [wholeString, partAString, partBString]))
        }
        customMarkSchemeAnswer={{
          answersToDisplay: [
            [partA.toLocaleString(), partB.toLocaleString(), whole.toLocaleString()],
            [whole.toLocaleString(), partB.toLocaleString(), partB.toLocaleString()]
          ],
          answerText: translate.markScheme.acceptAnyOrder()
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbv',
  description: 'bbv',
  keywords: ['Fact families', 'Add', 'Subtract'],
  schema: z
    .object({
      num1: z.number().int().min(0).max(9),
      num2: z.number().int().min(0).max(9),
      correctAnswers: z
        .array(z.enum(['Aa', 'Ab', 'Ba', 'Bb', 'Ca', 'Cb', 'Da', 'Db']))
        .min(1)
        .max(6),
      wrongSelects: z
        .array(
          z.enum([
            'Ea',
            'Eb',
            'Fa',
            'Fb',
            'Ga',
            'Gb',
            'Ha',
            'Hb',
            'Ia',
            'Ib',
            'Ja',
            'Jb',
            'Ka',
            'Kb',
            'La',
            'Lb'
          ])
        )
        .min(0)
        .max(5)
    })
    .refine(
      ({ num1, num2 }) => isInRange(3, 9)(num1 + num2),
      'sum of num1 and num2 must be between 3 and 9'
    ),
  simpleGenerator: () => {
    const num1 = randomIntegerInclusive(0, 9);
    const num2 = randomIntegerInclusive(0, 9, {
      constraint: x => isInRange(3, 9)(num1 + x)
    });

    const [maxCorrects, correctOptions, wrongOptions] =
      num1 === num2
        ? [
            4,
            ['Aa', 'Ab', 'Ca', 'Cb'] as const,
            ['Ea', 'Eb', 'Ga', 'Gb', 'Ia', 'Ib', 'Ka', 'Kb'] as const
          ]
        : [
            6,
            ['Aa', 'Ab', 'Ba', 'Bb', 'Ca', 'Cb', 'Da', 'Db'] as const,
            num1 === 0
              ? (['Fa', 'Fb', 'Ia', 'Ib'] as const)
              : num2 === 0
              ? (['Ea', 'Eb', 'Ka', 'Kb'] as const)
              : ([
                  'Ea',
                  'Eb',
                  'Fa',
                  'Fb',
                  'Ga',
                  'Gb',
                  'Ha',
                  'Hb',
                  'Ia',
                  'Ib',
                  'Ja',
                  'Jb',
                  'Ka',
                  'Kb',
                  'La',
                  'Lb'
                ] as const)
          ];
    const minCorrects = [num1, num2].includes(0) ? 2 : 1; // Only 4 wrong possible options if num1 or num2 is 0.
    const numCorrects = randomIntegerInclusive(minCorrects, maxCorrects);
    const correctAnswers = getRandomSubArrayFromArray(correctOptions, numCorrects);
    const wrongSelects = getRandomSubArrayFromArray(wrongOptions, 6 - numCorrects);

    return { num1, num2, correctAnswers, wrongSelects };
  },
  Component: ({ question, translate }) => {
    const { num1, num2, correctAnswers, wrongSelects } = question;

    const total = num1 + num2;

    const equation = (
      sign: ADD | SUB,
      left: number,
      right: number,
      ans: number,
      flipped: boolean
    ) =>
      flipped
        ? `${ans.toLocaleString()} = ${left.toLocaleString()} ${sign} ${right.toLocaleString()}`
        : `${left.toLocaleString()} ${sign} ${right.toLocaleString()} = ${ans.toLocaleString()}`;

    const equationStatemets = {
      // correct
      Aa: equation(ADD, num1, num2, total, false),
      Ab: equation(ADD, num1, num2, total, true),
      Ba: equation(ADD, num2, num1, total, false),
      Bb: equation(ADD, num2, num1, total, true),

      Ca: equation(SUB, total, num1, num2, false),
      Cb: equation(SUB, total, num1, num2, true),
      Da: equation(SUB, total, num2, num1, false),
      Db: equation(SUB, total, num2, num1, true),

      // wrong
      Ea: equation(ADD, num1, total, num2, false),
      Eb: equation(ADD, num1, total, num2, true),
      Fa: equation(ADD, num2, total, num1, false),
      Fb: equation(ADD, num2, total, num1, true),
      Ga: equation(ADD, total, num1, num2, false),
      Gb: equation(ADD, total, num1, num2, true),
      Ha: equation(ADD, total, num2, num1, false),
      Hb: equation(ADD, total, num2, num1, true),

      Ia: equation(SUB, num1, num2, total, false),
      Ib: equation(SUB, num1, num2, total, true),
      Ja: equation(SUB, num1, total, num2, false),
      Jb: equation(SUB, num1, total, num2, true),
      Ka: equation(SUB, num2, num1, total, false),
      Kb: equation(SUB, num2, num1, total, true),
      La: equation(SUB, num2, total, num1, false),
      Lb: equation(SUB, num2, total, num1, true)
    };

    const statements = shuffle(
      [...correctAnswers, ...wrongSelects].map(x => [x, equationStatemets[x]]),
      { random: seededRandom(question) }
    );

    const title =
      correctAnswers.length === 1
        ? 'selectTheCorrectNumberSentence'
        : 'selectTheCorrectNumberSentences';

    const pdfTitle =
      correctAnswers.length === 1 ? 'tickTheCorrectNumberSentence' : 'tickTheCorrectNumberSentence';

    return (
      <QF10SelectNumbers
        title={translate.ks1Instructions[title]()}
        pdfTitle={translate.ks1PDFInstructions[pdfTitle]()}
        testCorrect={correctAnswers}
        multiSelect={true}
        items={statements.map(([x, statement]) => ({
          value: x,
          component: <Text variant="WRN700">{statement}</Text>
        }))}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FactFamiliesTheEightFacts',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
