import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from 'common/src/utils/random';
import QF6DragMatchStatements from 'common/src/components/question/questionFormats/QF6DragMatchStatements';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import { AssetSvg } from '../../../../assets/svg';
import { View } from 'react-native';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import QF36ContentAndSentenceDrag from '../../../../components/question/questionFormats/QF36ContentAndSentenceDrag';
import { get3DShapeFullColorsSVGPath, threeDShapeToString } from '../../../../utils/threeDShapes';
import { numberEnum } from '../../../../utils/zod';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bbI',
  description: 'bbI',
  keywords: ['3-D shapes', 'Shapes'],
  schema: z
    .object({
      correctShape: z.enum([
        'Cone',
        'CubeFrontFace',
        'Cuboid',
        'Cylinder',
        'Sphere',
        'Square_pyramid'
      ]),
      incorrectShapeA: z.enum([
        'Cone',
        'CubeFrontFace',
        'Cuboid',
        'Cylinder',
        'Sphere',
        'Square_pyramid'
      ]),
      incorrectShapeB: z.enum([
        'Cone',
        'CubeFrontFace',
        'Cuboid',
        'Cylinder',
        'Sphere',
        'Square_pyramid'
      ]),
      incorrectShapeC: z.enum([
        'Cone',
        'CubeFrontFace',
        'Cuboid',
        'Cylinder',
        'Sphere',
        'Square_pyramid'
      ]),
      color: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      rotation: numberEnum([0, 90, 180])
    })
    .refine(
      val =>
        arrayHasNoDuplicates([
          val.correctShape,
          val.incorrectShapeA,
          val.incorrectShapeB,
          val.incorrectShapeC
        ]),
      'All shapes must be different.'
    ),
  simpleGenerator: () => {
    const useCubes = getRandomBoolean();

    const array = useCubes
      ? (['Cone', 'CubeFrontFace', 'Cylinder', 'Sphere', 'Square_pyramid'] as const)
      : (['Cone', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid'] as const);

    const [correctShape, incorrectShapeA, incorrectShapeB, incorrectShapeC] =
      getRandomSubArrayFromArray(array, 4);

    const rotation =
      correctShape === 'Cone'
        ? getRandomFromArray([0, 180] as const)
        : correctShape === 'Cylinder'
        ? getRandomFromArray([0, 90] as const)
        : 0;

    const color = getRandomFromArray([
      'blue',
      'green',
      'orange',
      'pink',
      'purple',
      'red',
      'yellow'
    ] as const);

    return { correctShape, incorrectShapeA, incorrectShapeB, incorrectShapeC, color, rotation };
  },
  Component: props => {
    const {
      question: {
        correctShape,
        incorrectShapeA,
        incorrectShapeB,
        incorrectShapeC,
        color,
        rotation
      },
      translate,
      displayMode
    } = props;
    const random = seededRandom(props.question);

    const correctShapeString = threeDShapeToString(translate, correctShape, 1);
    const incorrectShapeStringA = threeDShapeToString(translate, incorrectShapeA, 1);
    const incorrectShapeStringB = threeDShapeToString(translate, incorrectShapeB, 1);
    const incorrectShapeStringC = threeDShapeToString(translate, incorrectShapeC, 1);

    const items = shuffle(
      [correctShapeString, incorrectShapeStringA, incorrectShapeStringB, incorrectShapeStringC],
      { random }
    );

    return (
      <QF6DragMatchStatements
        title={translate.ks1Instructions.dragTheCardsToMatchTheNameToTheShape()}
        pdfTitle={translate.ks1PDFInstructions.writeTheNameOfTheShape()}
        statements={[
          {
            lhsComponent: (
              <View style={{ width: 200 }}>
                <AssetSvg
                  name={get3DShapeFullColorsSVGPath(color, correctShape)}
                  height={displayMode === 'digital' ? 100 : 150}
                  style={{ transform: `rotate(${rotation}deg)` }}
                />
              </View>
            ),
            correctAnswer: correctShapeString
          }
        ]}
        mainPanelStyle={{ alignItems: 'center' }}
        items={items}
        pdfLayout="itemsBottom"
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bbJ',
  description: 'bbJ',
  keywords: ['3-D shapes', 'Train'],
  schema: z
    .object({
      shapeA: z.enum(['Cone', 'CubeFrontFace', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid']),
      shapeB: z.enum(['Cone', 'CubeFrontFace', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid']),
      shapeC: z.enum([
        'Cone',
        'CubeFrontFace',
        'Cuboid',
        'Cylinder',
        'Sphere',
        'Square_pyramid',
        'none'
      ]),
      colorA: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      colorB: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      colorC: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      shapeALength: z.number().int().min(1).max(5),
      shapeBLength: z.number().int().min(1).max(5),
      shapeCLength: z.number().int().min(0).max(4)
    })
    .refine(
      val => arrayHasNoDuplicates([val.shapeA, val.shapeB, val.shapeC]),
      'All shapes must be different.'
    )
    .refine(
      val =>
        val.shapeALength + val.shapeBLength + val.shapeCLength >= 4 &&
        val.shapeALength + val.shapeBLength + val.shapeCLength <= 6,
      'Total of the shape lengths must be between 4 and 6.'
    )
    .refine(
      val =>
        [val.shapeA, val.shapeB, val.shapeC].filter(val =>
          ['CubeFrontFace', 'Cuboid'].includes(val)
        ).length <= 1,
      'only show one of cube and cuboid'
    ),
  simpleGenerator: () => {
    const array = getRandomBoolean()
      ? (['Cone', 'CubeFrontFace', 'Cylinder', 'Sphere', 'Square_pyramid'] as const)
      : (['Cone', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid'] as const);
    const shapeC = getRandomFromArray([...array, 'none'] as const);

    const shapeAAndBChoices = array.filter(shape => shape !== shapeC);

    const [shapeA, shapeB] = getRandomSubArrayFromArray(shapeAAndBChoices, 2);

    const [colorA, colorB, colorC] = getRandomSubArrayFromArray(
      ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const,
      3
    );

    const shapeCLength = shapeC === 'none' ? 0 : randomIntegerInclusive(1, 4);

    const shapeALength = randomIntegerInclusive(1, 5 - shapeCLength);

    const shapeBLength = randomIntegerInclusive(1, 6, {
      constraint: x => x + shapeALength + shapeCLength >= 4 && x + shapeALength + shapeCLength <= 6
    });

    return {
      shapeA,
      shapeB,
      shapeC,
      colorA,
      colorB,
      colorC,
      shapeALength,
      shapeBLength,
      shapeCLength
    };
  },
  Component: props => {
    const {
      question: {
        shapeA,
        shapeB,
        shapeC,
        colorA,
        colorB,
        colorC,
        shapeALength,
        shapeBLength,
        shapeCLength
      },
      translate,
      displayMode
    } = props;
    const random = seededRandom(props.question);

    const shapeASVGPath = get3DShapeFullColorsSVGPath(colorA, shapeA);
    const shapeBSVGPath = get3DShapeFullColorsSVGPath(colorB, shapeB);
    const shapeCSVGPath =
      shapeC === 'none' ? undefined : get3DShapeFullColorsSVGPath(colorC, shapeC);

    const shapeAString = threeDShapeToString(translate, shapeA, shapeALength);

    const arrayOfShapeASVGPath = filledArray(shapeASVGPath, shapeALength);
    const arrayOfShapeBSVGPath = filledArray(shapeBSVGPath, shapeBLength);
    const arrayOfShapeCSVGPath = shapeCSVGPath ? filledArray(shapeCSVGPath, shapeCLength) : [];

    const shuffledSVGPaths = shuffle(
      [...arrayOfShapeASVGPath, ...arrayOfShapeBSVGPath, ...arrayOfShapeCSVGPath] as const,
      { random }
    );

    return (
      <QF1ContentAndSentence
        pdfDirection="column"
        title={translate.ks1Instructions.completeTheSentence()}
        testCorrect={[shapeALength.toString()]}
        sentence={translate.ks1AnswerSentences.theTrainHasAnsX(shapeAString)}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }
            ]}
          >
            {shuffledSVGPaths.map((svgPath, index) => (
              <AssetSvg
                key={index}
                name={svgPath}
                height={
                  svgPath?.includes('Cone') ||
                  svgPath?.includes('Cylinder') ||
                  svgPath?.includes('Square_pyramid')
                    ? undefined
                    : displayMode === 'digital'
                    ? 100
                    : 150
                }
                width={
                  svgPath?.includes('Cone') ||
                  svgPath?.includes('Cylinder') ||
                  svgPath?.includes('Square_pyramid')
                    ? displayMode === 'digital'
                      ? 100
                      : 150
                    : undefined
                }
                style={{
                  transform:
                    svgPath?.includes('Cone') ||
                    svgPath?.includes('Cylinder') ||
                    svgPath?.includes('Square_pyramid')
                      ? 'rotate(90deg)'
                      : undefined,
                  zIndex: index
                }}
              />
            ))}
          </View>
        )}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bbK',
  description: 'bbK',
  keywords: ['3-D shapes', 'Train'],
  schema: z
    .object({
      shapeA: z.enum(['Cone', 'CubeFrontFace', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid']),
      shapeB: z.enum(['Cone', 'CubeFrontFace', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid']),
      shapeC: z.enum(['Cone', 'CubeFrontFace', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid']),
      shapeD: z.enum(['Cone', 'CubeFrontFace', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid']),
      colorA: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      colorB: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      colorC: z.enum(['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow']),
      shapeALength: z.number().int().min(1).max(5),
      shapeBLength: z.number().int().min(1).max(5),
      shapeCLength: z.number().int().min(0).max(4)
    })
    .refine(
      val => arrayHasNoDuplicates([val.shapeA, val.shapeB, val.shapeC, val.shapeD]),
      'All shapes must be different.'
    )
    .refine(
      val =>
        val.shapeALength + val.shapeBLength + val.shapeCLength >= 4 &&
        val.shapeALength + val.shapeBLength + val.shapeCLength <= 6,
      'Total of the shape lengths must be between 4 and 6.'
    )
    .refine(
      val => arrayHasNoDuplicates([val.shapeALength, val.shapeBLength, val.shapeCLength]),
      'Shapes A, B and C must all have different lengths.'
    )
    .refine(
      val =>
        [val.shapeA, val.shapeB, val.shapeC, val.shapeD].filter(val =>
          ['CubeFrontFace', 'Cuboid'].includes(val)
        ).length <= 1,
      'only show one of cube and cuboid'
    ),
  simpleGenerator: () => {
    const array = getRandomBoolean()
      ? (['Cone', 'CubeFrontFace', 'Cylinder', 'Sphere', 'Square_pyramid'] as const)
      : (['Cone', 'Cuboid', 'Cylinder', 'Sphere', 'Square_pyramid'] as const);

    const [shapeA, shapeB, shapeC, shapeD] = getRandomSubArrayFromArray(array, 4);

    const [colorA, colorB, colorC] = getRandomSubArrayFromArray(
      ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'yellow'] as const,
      3
    );

    const { shapeALength, shapeBLength, shapeCLength } = rejectionSample(
      () => {
        const shapeCLength = randomIntegerInclusive(0, 4);

        const shapeALength = randomIntegerInclusive(1, 5 - shapeCLength);

        const shapeBLength = randomIntegerInclusive(1, 6, {
          constraint: x =>
            x + shapeALength + shapeCLength >= 4 && x + shapeALength + shapeCLength <= 6
        });
        return { shapeALength, shapeBLength, shapeCLength };
      },
      // Only permit them if all numbers generated are different.
      ({ shapeALength, shapeBLength, shapeCLength }) =>
        arrayHasNoDuplicates([shapeALength, shapeBLength, shapeCLength])
    );

    return {
      shapeA,
      shapeB,
      shapeC,
      shapeD,
      colorA,
      colorB,
      colorC,
      shapeALength,
      shapeBLength,
      shapeCLength
    };
  },
  Component: props => {
    const {
      question: {
        shapeA,
        shapeB,
        shapeC,
        shapeD,
        colorA,
        colorB,
        colorC,
        shapeALength,
        shapeBLength,
        shapeCLength
      },
      translate,
      displayMode
    } = props;
    const random = seededRandom(props.question);

    const shapeASVGPath = get3DShapeFullColorsSVGPath(colorA, shapeA);
    const shapeBSVGPath = get3DShapeFullColorsSVGPath(colorB, shapeB);
    const shapeCSVGPath =
      shapeCLength === 0 ? undefined : get3DShapeFullColorsSVGPath(colorC, shapeC);

    // These all need to use shapeALength, to avoid leading answers:
    const shapeAString = threeDShapeToString(translate, shapeA, shapeALength);
    const shapeBString = threeDShapeToString(translate, shapeB, shapeALength);
    const shapeCString = threeDShapeToString(translate, shapeC, shapeALength);
    const shapeDString = threeDShapeToString(translate, shapeD, shapeALength);

    const items = shuffle([shapeAString, shapeBString, shapeCString, shapeDString], { random });

    const arrayOfShapeASVGPath = filledArray(shapeASVGPath, shapeALength);
    const arrayOfShapeBSVGPath = filledArray(shapeBSVGPath, shapeBLength);
    const arrayOfShapeCSVGPath = shapeCSVGPath ? filledArray(shapeCSVGPath, shapeCLength) : [];

    const svgPaths = [...arrayOfShapeASVGPath, ...arrayOfShapeBSVGPath, ...arrayOfShapeCSVGPath];

    const shuffledSVGPaths = shuffle(svgPaths, { random });

    return (
      <QF36ContentAndSentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.writeTheNameOfTheShapeToCompleteTheSentence()}
        mainPanelStyle={{ alignItems: 'center' }}
        items={items}
        itemVariant="rectangle"
        pdfItemVariant="rectangle"
        pdfLayout="itemsHidden"
        sentence={translate.ks1AnswerSentences.theTrainHasNumAns(shapeALength)}
        sentenceStyle={{ alignSelf: 'flex-start' }}
        testCorrect={[shapeAString]}
        Content={({ dimens }) => (
          <View
            style={[
              dimens,
              { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }
            ]}
          >
            {shuffledSVGPaths.map((svgPath, index) => (
              <AssetSvg
                key={index}
                name={svgPath}
                height={
                  svgPath?.includes('Cone') ||
                  svgPath?.includes('Cylinder') ||
                  svgPath?.includes('Square_pyramid')
                    ? undefined
                    : displayMode === 'digital'
                    ? 100
                    : 150
                }
                width={
                  svgPath?.includes('Cone') ||
                  svgPath?.includes('Cylinder') ||
                  svgPath?.includes('Square_pyramid')
                    ? displayMode === 'digital'
                      ? 100
                      : 150
                    : undefined
                }
                style={{
                  transform:
                    svgPath?.includes('Cone') ||
                    svgPath?.includes('Cylinder') ||
                    svgPath?.includes('Square_pyramid')
                      ? 'rotate(90deg)'
                      : undefined,
                  zIndex: index
                }}
              />
            ))}
          </View>
        )}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAndName3DShapes',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
