import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { numberEnum } from '../../../../utils/zod';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from '../../../../utils/random';
import QF10SelectNumbers from '../../../../components/question/questionFormats/QF10SelectNumbers';
import { displayMoney } from '../../../../utils/money';
import { View } from 'react-native';
import { sumNumberArray } from '../../../../utils/collections';
import QF2AnswerBoxOneSentence from '../../../../components/question/questionFormats/QF2AnswerBoxOneSentence';
import { ADD } from '../../../../constants';
import QF16DragMoneyIntoABox from '../../../../components/question/questionFormats/QF16DragMoneyIntoABox';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'biu',
  description: 'biu',
  keywords: ['Money', 'Pound', 'Pence', 'Coins'],
  schema: z
    .object({
      poundOr100Pence: z.enum(['£1', '100p']),
      coinA: numberEnum([5, 10, 20, 50]),
      coinB: numberEnum([5, 10, 20, 50])
    })
    .refine(val => val.coinA !== val.coinB, 'coinA and coinB must be different.'),
  simpleGenerator: () => {
    const poundOr100Pence = getRandomFromArray(['£1', '100p'] as const);

    const [coinA, coinB] = getRandomSubArrayFromArray([5, 10, 20, 50] as const, 2);

    return { poundOr100Pence, coinA, coinB };
  },

  Component: props => {
    const {
      question: { poundOr100Pence, coinA, coinB },
      translate
    } = props;

    const draggables = [coinA, coinB];

    return (
      <QF16DragMoneyIntoABox
        title={
          poundOr100Pence === '£1'
            ? translate.ks1Instructions.dragTheCoinsToMakeNumPounds(1)
            : translate.ks1Instructions.dragTheCoinsToMakeNumP(100)
        }
        pdfTitle={
          poundOr100Pence === '£1'
            ? translate.ks1PDFInstructions.drawCoinsToMakeNumPounds(1)
            : translate.ks1PDFInstructions.drawCoinsToMakeNumP(100)
        }
        amountInPenceToMake={100}
        draggablesToShow={draggables}
        questionHeight={1000}
        customMarkSchemeAnswer={
          poundOr100Pence === '£1'
            ? translate.markScheme.acceptAnyValidDenominationsThatMakeNumPounds(1)
            : translate.markScheme.acceptAnyValidDenominationsThatMakeNumP(100)
        }
      />
    );
  },
  questionHeight: 1000
});

const Question2 = newQuestionContent({
  uid: 'biv',
  description: 'biv',
  keywords: ['Coins', 'Pence', 'Pound', 'Money'],
  schema: z.object({
    selectables: numberEnum([1, 2, 5, 10, 20, 50]).array().length(9),
    isPound: z.boolean()
  }),
  simpleGenerator: () => {
    const coinValues = [1, 2, 5, 10, 20, 50] as const;
    type CoinValue = (typeof coinValues)[number];

    // Copied from existing question atf -
    const curatedSelectablesChoices: CoinValue[][] = [
      [1, 2, 2, 5, 5, 10, 10, 20, 50], // e.g. 50+20+10+10+5+5
      [1, 1, 2, 2, 2, 5, 20, 20, 50], // e.g. 50+20+20+5+2+2+1
      [20, 20, 20, 20, 10, 5, 5, 2, 2], // e.g. 20+20+20+20+10+5+5
      [5, 5, 10, 10, 10, 10, 20, 20, 10], // e.g. 20+20+10+10+10+10+10+5+5
      [20, 20, 20, 20, 10, 5, 2, 2, 1], // e.g 20+20+20+20+10+5+2+2+1
      [50, 10, 10, 10, 10, 10, 5, 5, 5], // e.g 50+10+10+10+10+5+5
      [50, 5, 5, 5, 5, 20, 20, 20, 20], // e.g 50+20+20+5+5
      [20, 20, 10, 10, 10, 10, 10, 10, 5], // e.g 20+20+10+10+10+10+10+10
      [50, 20, 20, 10, 2, 2, 2, 2, 2], // e.g 50+20+20+2+2+2+2+2
      [1, 1, 10, 10, 10, 20, 20, 20, 20] // e.g 20+20+20+20+10+10
    ];

    const selectables = getRandomFromArray(curatedSelectablesChoices)!;

    const isPound = getRandomBoolean();

    return { selectables: shuffle(selectables), isPound };
  },
  Component: props => {
    const {
      question: { selectables, isPound },
      translate,
      displayMode
    } = props;

    const denominations = displayMoney(
      selectables.map(amount => `${amount}p`),
      displayMode === 'digital' ? 80 : 120,
      displayMode === 'digital' ? 80 : 120,
      true
    );

    return (
      <QF10SelectNumbers
        title={
          isPound
            ? translate.ks1Instructions.selectTheCoinsToMakeXP(100)
            : translate.ks1Instructions.selectTheCoinsToMakePoundX(1)
        }
        pdfTitle={
          isPound
            ? translate.ks1PDFInstructions.circleTheCoinsToMakeXP(100)
            : translate.ks1PDFInstructions.circleTheCoinsToMakePoundX(1)
        }
        testCorrect={answer =>
          sumNumberArray(answer.map(selectableIndex => selectables[selectableIndex])) === 100
        }
        multiSelect
        items={selectables.map((_, idx) => ({
          value: idx,
          component: <View>{denominations[idx]}</View>
        }))}
        questionHeight={900}
        customMarkSchemeAnswer={{
          answerText: translate.markScheme.anyPossibleCombinationOfCoinsThatSumsToX(100)
        }}
      />
    );
  },
  questionHeight: 900
});

const Question3 = newQuestionContent({
  uid: 'biw',
  description: 'biw',
  keywords: ['Pounds', 'Pence', 'Money', 'Add'],
  schema: z.object({
    numbers: z
      .array(z.number().min(1).max(99))
      .refine(
        numbers => sumNumberArray(numbers) === 100,
        'All numbers should add together to equal 100.'
      ),
    missingIndex: numberEnum([0, 1, 2]),
    isPound: z.boolean()
  }),
  questionHeight: 500,
  simpleGenerator: () => {
    const variation = getRandomFromArray([
      'multiplesOfTen',
      'multiplesOfFive',
      'anyTwoNumbers',
      'threeNumbersMultiplesOfTen'
    ] as const);

    const numbers = (() => {
      switch (variation) {
        case 'multiplesOfTen': {
          const numberA = randomIntegerInclusiveStep(10, 90, 10);
          const numberB = randomIntegerInclusiveStep(10, 90, 10, {
            constraint: x => x + numberA === 100
          });
          return [numberA, numberB];
        }
        case 'multiplesOfFive': {
          const numberA = randomIntegerInclusiveStep(5, 95, 5);
          const numberB = randomIntegerInclusiveStep(5, 95, 5, {
            constraint: x => x + numberA === 100
          });
          return [numberA, numberB];
        }
        case 'anyTwoNumbers': {
          const numberA = randomIntegerInclusive(1, 99);
          const numberB = randomIntegerInclusive(1, 99, {
            constraint: x => x + numberA === 100
          });
          return [numberA, numberB];
        }
        case 'threeNumbersMultiplesOfTen': {
          const numberA = randomIntegerInclusiveStep(10, 80, 10);
          const numberB = randomIntegerInclusiveStep(10, 80, 10, {
            constraint: x => x + numberA <= 90
          });
          const numberC = randomIntegerInclusiveStep(10, 80, 10, {
            constraint: x => x + numberA + numberB === 100
          });
          return [numberA, numberB, numberC];
        }
      }
    })();

    const missingIndex =
      variation === 'threeNumbersMultiplesOfTen'
        ? getRandomFromArray([0, 1, 2] as const)
        : getRandomFromArray([0, 1] as const);

    const isPound = getRandomBoolean();

    return {
      numbers,
      missingIndex,
      isPound
    };
  },
  Component: ({ question, translate, displayMode }) => {
    const { numbers, isPound, missingIndex } = question;

    const answer = numbers[missingIndex].toString();

    const updatedNumbers = numbers.map((num, index) =>
      index === missingIndex ? '<ans/>p' : `${num.toLocaleString()}p`
    );

    const sentence =
      numbers.length === 3
        ? `${updatedNumbers[0]} ${ADD} ${updatedNumbers[1]} ${ADD} ${updatedNumbers[2]} = ${
            isPound ? '£1' : '100p'
          }`
        : `${updatedNumbers[0]} ${ADD} ${updatedNumbers[1]} = ${isPound ? '£1' : '100p'}`;

    return (
      <QF2AnswerBoxOneSentence
        title={translate.ks1Instructions.workOutTheMissingValue()}
        testCorrect={[answer]}
        sentence={sentence}
        questionHeight={500}
        textStyle={{ fontSize: displayMode === 'digital' ? 32 : 50 }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'MakeAPound',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
