import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  randomUniqueIntegersInclusive,
  shuffle
} from 'common/src/utils/random';
import { AssetSvg } from '../../../../assets/svg';
import { arrayHasNoDuplicates, countRange, sortNumberArray } from '../../../../utils/collections';
import Text from '../../../../components/typography/Text';
import deepEqual from 'react-fast-compare';
import QF5DragOrderHorizontal from '../../../../components/question/questionFormats/QF5DragOrderHorizontal';
import { MeasureView } from '../../../../components/atoms/MeasureView';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import QF8DragIntoUpTo3Groups from '../../../../components/question/questionFormats/QF8DragIntoUpTo3Groups';
import BaseLayoutPDF from '../../../../components/molecules/BaseLayoutPDF';
import { StyleSheet, View } from 'react-native';
import { renderMarkSchemeProp } from '../../../../components/question/questionFormats/utils/markSchemeRender';
import { TranslationFunctions } from '../../../../i18n/i18n-types';

////
// Questions
////

const colors = ['blue', 'green', 'pink', 'purple', 'red', 'yellow'] as const;

const triangles = [
  'triangle',
  'right angle triangle',
  'long right angle triangle',
  'scalene triangle',
  'narrow isosceles triangle',
  'wide isosceles triangle'
] as const;

const quadrilaterals = ['rectangle', 'square'] as const;

const quadrilateralsQ3 = [
  'rectangle',
  'square',
  'parallelogram',
  'rhombus',
  'trapezium isosceles',
  'kite'
] as const;

const q1ShapeCategories = [
  'circle',
  'triangle',
  'rectangle',
  'square',
  'pentagon',
  'hexagon',
  'heptagon',
  'octagon'
] as const;

const pentagons = [
  'pentagon',
  'pentagon house',
  'irregular pentagon 1',
  'irregular pentagon 2',
  'irregular pentagon 3',
  'irregular pentagon 4',
  'irregular pentagon 5',
  'irregular pentagon 6'
] as const;

const hexagons = [
  'hexagon',
  'L-shape',
  'irregular hexagon 1',
  'irregular hexagon 2',
  'irregular hexagon 4',
  'irregular hexagon 5'
] as const;

const hexagons2 = [
  'hexagon',
  'L-shape',
  'irregular hexagon 1',
  'irregular hexagon 4',
  'irregular hexagon 5'
] as const;

const octagons = ['octagon', 'irregular octagon 1', 'irregular octagon 3'] as const;

const heptagons = ['heptagon', 'irregular heptagon 1', 'irregular heptagon 2'] as const;

const q1Shapes = [
  'circle',
  ...triangles,
  ...quadrilaterals,
  ...pentagons,
  ...hexagons,
  ...heptagons,
  'octagon'
] as const;

const q1Shapesv2 = [
  'circle',
  ...triangles,
  ...quadrilaterals,
  ...pentagons,
  ...hexagons2,
  ...heptagons,
  ...octagons
] as const;

const q3Shapes = [...q1Shapes, 'ellipse', 'rhombus', 'trapezium isosceles'] as const;

const q3Shapesv2 = [
  ...triangles,
  ...quadrilateralsQ3,
  ...pentagons,
  ...hexagons2,
  ...heptagons,
  ...octagons
] as const;

type q1ShapeCategories = (typeof q1ShapeCategories)[number];
type q1Shape = (typeof q1Shapes)[number];
type q1v2Shape = (typeof q1Shapesv2)[number];
type q3Shape = (typeof q3Shapes)[number];
type q3v2Shape = (typeof q3Shapesv2)[number];

const shapeToSidesVertices = (
  shape: q3Shape | q3v2Shape,
  sidesOrVertices?: 'sides' | 'vertices'
) => {
  switch (shape) {
    case 'circle':
    case 'ellipse':
      return sidesOrVertices === 'sides' ? 1 : 0;
    case 'triangle':
    case 'right angle triangle':
    case 'long right angle triangle':
    case 'scalene triangle':
    case 'narrow isosceles triangle':
    case 'wide isosceles triangle':
      return 3;
    case 'square':
    case 'rectangle':
    case 'rhombus':
    case 'trapezium isosceles':
    case 'parallelogram':
    case 'kite':
      return 4;
    case 'pentagon':
    case 'pentagon house':
    case 'irregular pentagon 1':
    case 'irregular pentagon 2':
    case 'irregular pentagon 3':
    case 'irregular pentagon 4':
    case 'irregular pentagon 5':
    case 'irregular pentagon 6':
      return 5;
    case 'hexagon':
    case 'L-shape':
    case 'irregular hexagon 1':
    case 'irregular hexagon 2':
    case 'irregular hexagon 4':
    case 'irregular hexagon 5':
      return 6;
    case 'heptagon':
    case 'irregular heptagon 1':
    case 'irregular heptagon 2':
      return 7;
    case 'octagon':
    case 'irregular octagon 1':
    case 'irregular octagon 3':
      return 8;
  }
};

const categoryToArray = (
  category: (typeof q1ShapeCategories)[number],
  version: 1 | 2
): readonly string[] => {
  switch (category) {
    case 'circle':
      return ['circle'] as const;
    case 'triangle':
      return triangles;
    case 'rectangle':
      return ['rectangle', 'square'] as const;
    case 'square':
      return ['square'] as const;
    case 'pentagon':
      return pentagons;
    case 'hexagon':
      return version === 1 ? hexagons : hexagons2;
    case 'heptagon':
      return heptagons;
    case 'octagon':
      return version === 1 ? ['octagon'] : octagons;
  }
};

const Q1Content = (
  displayMode: 'digital' | 'pdf' | 'markscheme',
  translate: TranslationFunctions,
  categoryA: q1ShapeCategories,
  categoryB: q1ShapeCategories,
  items: {
    shape: q1Shape | q1v2Shape;
    color: (typeof colors)[number];
    rotation: number;
    scale?: number;
  }[],
  version: 1 | 2
) => {
  const categoryToString = (category: q1ShapeCategories) => {
    switch (category) {
      case 'circle':
        return translate.shapes.Circles(2);
      case 'triangle':
        return translate.shapes.Triangles(2);
      case 'rectangle':
        return translate.shapes.Rectangles(2);
      case 'square':
        return translate.shapes.Squares(2);
      case 'pentagon':
        return translate.shapes.Pentagons(2);
      case 'hexagon':
        return translate.shapes.Hexagons(2);
      case 'heptagon':
        return translate.shapes.Heptagons(2);
      case 'octagon':
        return translate.shapes.Octagons(2);
    }
  };

  const categoryAArray = categoryToArray(categoryA, version);

  const categoryBArray = categoryToArray(categoryB, version);

  const categoryAItems = items.filter(item =>
    categoryAArray.includes(item.shape as q1Shape | q1v2Shape)
  );

  const categoryBItems = items.filter(item =>
    categoryBArray.includes(item.shape as q1Shape | q1v2Shape)
  );

  return displayMode === 'digital' ? (
    <QF8DragIntoUpTo3Groups
      title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
      zoneNames={[categoryToString(categoryA), categoryToString(categoryB)]}
      testCorrect={[categoryAItems, categoryBItems]}
      items={items.map((shape, index) => {
        return {
          value: shape,
          component: (
            <MeasureView
              key={index}
              style={{
                transform: [{ rotate: `${shape.rotation}deg` }]
              }}
            >
              {dimens => (
                <AssetSvg
                  name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                  width={dimens.width * 0.9}
                  height={dimens.height * 0.9}
                  style={{ transform: `scaleX(${shape.scale ?? 1}) scaleY(${shape.scale ?? 1})` }}
                />
              )}
            </MeasureView>
          )
        };
      })}
    />
  ) : (
    // This Q requires a fairly custom PDF version below, whereby the user has to circle the shapes with less than verticesToSortBy.
    <BaseLayoutPDF
      title={translate.ks1PDFInstructions.circleAllOfTheShapesWhatIsTheNamesOfTheShapesThatAreLeft(
        categoryToString(categoryA)
      )}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <>
              <View
                style={[
                  dimens,
                  {
                    flex: 1,
                    justifyContent: 'center'
                  }
                ]}
              >
                {
                  <View
                    style={[
                      StyleSheet.absoluteFill,
                      {
                        width: '100%',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      }
                    ]}
                  >
                    {items.map((shape, index) => (
                      <View
                        key={index}
                        style={[
                          { padding: 20, transform: [{ rotate: `${shape.rotation}deg` }] },
                          displayMode === 'markscheme' &&
                            categoryAItems.includes(shape) && {
                              borderColor: 'black',
                              borderWidth: 4,
                              borderRadius: 72
                            }
                        ]}
                      >
                        <AssetSvg
                          name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                          width={150}
                          height={150}
                          style={{ transform: `scaleX(${shape.scale}) scaleY(${shape.scale})` }}
                        />
                      </View>
                    ))}
                  </View>
                }
              </View>
              {displayMode === 'markscheme' &&
                renderMarkSchemeProp(
                  translate.markScheme.theRemainingShapesAreShape(categoryToString(categoryB))
                )}
            </>
          )}
        </MeasureView>
      }
    />
  );
};

const Question1 = newQuestionContent({
  uid: 'bhR',
  description: 'bhR',
  keywords: ['Sort', '2-D shapes'],
  schema: z
    .object({
      categoryA: z.enum(q1ShapeCategories),
      categoryB: z.enum(q1ShapeCategories),
      items: z
        .array(
          z.object({
            shape: z.enum(q1Shapes),
            color: z.enum(colors),
            rotation: z.number().int().min(0).max(270).multipleOf(90)
          })
        )
        .length(9)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => val.categoryA !== val.categoryB, 'categoryA and categoryB must be different.')
    .refine(val =>
      val.items.every(
        shape =>
          categoryToArray(val.categoryA, 1).includes(shape.shape) ||
          categoryToArray(val.categoryB, 1).includes(shape.shape)
      )
    ),
  simpleGenerator: () => {
    const categoryA = getRandomFromArray(q1ShapeCategories);

    const excludedCategories =
      categoryA === 'rectangle' || categoryA === 'square' ? ['rectangle', 'square'] : [categoryA];

    // categoryB cannot be any of the shapes in excludedCategories:
    const categoryB = getRandomFromArray(
      q1ShapeCategories.filter(category => !excludedCategories.includes(category))
    )!;

    // Category A shapes:

    const categoryAShapeChoices = categoryToArray(categoryA, 1);

    const totalCategoryAShapes = randomIntegerInclusive(3, 6);

    const selectedCategoryAShapes = countRange(totalCategoryAShapes).map(
      () => getRandomFromArray(categoryAShapeChoices) as q1Shape
    );

    const selectedCategoryAColors = getRandomSubArrayFromArray(colors, totalCategoryAShapes);

    const categoryAShapes = countRange(totalCategoryAShapes).map(num => {
      return {
        shape: selectedCategoryAShapes[num],
        color: selectedCategoryAColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90)
      };
    });

    // Category B shapes:

    const categoryBShapeChoices = categoryToArray(categoryB, 1);

    const totalCategoryBShapes = 9 - totalCategoryAShapes;

    const selectedCategoryBShapes = countRange(totalCategoryBShapes).map(
      () => getRandomFromArray(categoryBShapeChoices) as q1Shape
    );

    const selectedCategoryBColors = getRandomSubArrayFromArray(colors, totalCategoryBShapes);

    const categoryBShapes = countRange(totalCategoryBShapes).map(num => {
      return {
        shape: selectedCategoryBShapes[num],
        color: selectedCategoryBColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90)
      };
    });

    const items = shuffle([...categoryAShapes, ...categoryBShapes]);

    return { categoryA, categoryB, items };
  },
  Component: props => {
    const {
      question: { categoryA, categoryB, items },
      translate,
      displayMode
    } = props;

    return Q1Content(displayMode, translate, categoryA, categoryB, items, 1);
  }
});

const Question1v2 = newQuestionContent({
  uid: 'bhR2',
  description: 'bhR',
  keywords: ['Sort', '2-D shapes'],
  schema: z
    .object({
      categoryA: z.enum(q1ShapeCategories),
      categoryB: z.enum(q1ShapeCategories),
      items: z
        .array(
          z.object({
            shape: z.enum(q1Shapesv2),
            color: z.enum(colors),
            rotation: z.number().int().min(0).max(270).multipleOf(90),
            scale: z.number().min(0).max(1)
          })
        )
        .length(9)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => val.categoryA !== val.categoryB, 'categoryA and categoryB must be different.')
    .refine(val =>
      val.items.every(
        shape =>
          categoryToArray(val.categoryA, 2).includes(shape.shape) ||
          categoryToArray(val.categoryB, 2).includes(shape.shape)
      )
    ),
  simpleGenerator: () => {
    const categoryA = getRandomFromArray(q1ShapeCategories);

    const excludedCategories =
      categoryA === 'rectangle' || categoryA === 'square'
        ? ['rectangle', 'square']
        : categoryA === 'hexagon' || categoryA === 'heptagon' || categoryA === 'octagon'
        ? //not enough svgs to have a unique one
          ['octagon', 'heptagon', 'hexagon']
        : [categoryA];

    // categoryB cannot be any of the shapes in excludedCategories:
    const categoryB = getRandomFromArray(
      q1ShapeCategories.filter(category => !excludedCategories.includes(category))
    )!;

    // Category A shapes:

    const categoryAShapeChoices = categoryToArray(categoryA, 2);

    const max =
      categoryA === 'heptagon' || categoryA === 'octagon' ? 3 : categoryA === 'hexagon' ? 5 : 6;
    const totalCategoryAShapes = randomIntegerInclusive(3, max);

    const selectedCategoryAShapes =
      categoryA === 'circle' || categoryA === 'square' || categoryA === 'rectangle'
        ? countRange(totalCategoryAShapes).map(
            () => getRandomFromArray(categoryAShapeChoices) as q1v2Shape
          )
        : (getRandomSubArrayFromArray(categoryAShapeChoices, totalCategoryAShapes) as q1v2Shape[]);

    const selectedCategoryAColors = getRandomSubArrayFromArray(colors, totalCategoryAShapes);

    const scales = randomUniqueIntegersInclusive(60, 99, 9).map(val => val / 100);

    const categoryAShapes = countRange(totalCategoryAShapes).map(num => {
      return {
        shape: selectedCategoryAShapes[num],
        color: selectedCategoryAColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90),
        scale:
          // if we only have one svg option, scale them
          categoryA === 'circle' || categoryA === 'square' || categoryA === 'rectangle'
            ? scales[num]
            : 1
      };
    });

    // Category B shapes:

    const categoryBShapeChoices = categoryToArray(categoryB, 2);

    const totalCategoryBShapes = 9 - totalCategoryAShapes;

    const selectedCategoryBShapes = countRange(totalCategoryBShapes).map(
      () => getRandomFromArray(categoryBShapeChoices) as q1v2Shape
    );

    const selectedCategoryBColors = getRandomSubArrayFromArray(colors, totalCategoryBShapes);

    const categoryBShapes = countRange(totalCategoryBShapes).map(num => {
      return {
        shape: selectedCategoryBShapes[num],
        color: selectedCategoryBColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90),
        scale:
          // if we only have one svg option, scale them
          categoryB === 'circle' || categoryB === 'square' || categoryB === 'rectangle'
            ? scales[totalCategoryAShapes + num]
            : 1
      };
    });

    const items = shuffle([...categoryAShapes, ...categoryBShapes]);

    return { categoryA, categoryB, items };
  },
  Component: props => {
    const {
      question: { categoryA, categoryB, items },
      translate,
      displayMode
    } = props;

    return Q1Content(displayMode, translate, categoryA, categoryB, items, 2);
  }
});

const otherRegularShapes = ['pentagon', 'hexagon', 'heptagon', 'octagon'] as const;

const q2Shapes = [...triangles, ...quadrilaterals, ...otherRegularShapes] as const;

const Question2 = newQuestionContent({
  uid: 'bhS',
  description: 'bhS',
  keywords: ['Vertex', 'Vertices', 'Side', 'Sides', '2-D shape'],
  schema: z.object({
    ordering: z.enum(['ascending', 'descending']),
    color: z.enum(colors),
    sidesOrVertices: z.enum(['sides', 'vertices']),
    shapes: z
      .array(z.enum(q2Shapes))
      .length(5)
      .refine(
        shapes => arrayHasNoDuplicates(shapes.map(shape => shapeToSidesVertices(shape))),
        'All shapes must have different numbers of sides or vertices.'
      )
  }),
  simpleGenerator: () => {
    const possibleTriangle = getRandomFromArray(triangles);

    const possibleQuadrilateral = getRandomFromArray(quadrilaterals);

    const shapes = getRandomSubArrayFromArray(
      [possibleTriangle, possibleQuadrilateral, ...otherRegularShapes],
      5
    );

    const ordering = getRandomFromArray(['ascending', 'descending'] as const);

    const color = getRandomFromArray(colors);

    const sidesOrVertices = getRandomFromArray(['sides', 'vertices'] as const);

    return { shapes, ordering, color, sidesOrVertices };
  },
  Component: props => {
    const {
      question: { shapes, ordering, color, sidesOrVertices },
      translate,
      displayMode
    } = props;

    const correctOrder = sortNumberArray(
      shapes.map(shape => shapeToSidesVertices(shape)),
      ordering
    );

    const [instruction, pdfInstruction] = (() => {
      if (sidesOrVertices === 'sides') {
        return ordering === 'ascending'
          ? [
              translate.ks1Instructions.dragTheCardsToSortTheShapesInOrderOfTheNumberOfSidesStartWithTheFewestNumberOfSides(),
              translate.ks1PDFInstructions.sortTheShapesInOrderOfTheNumberOfSidesStartWithTheFewestNumberOfSides()
            ]
          : [
              translate.ks1Instructions.dragTheCardsToSortTheShapesInOrderOfTheNumberOfSidesStartWithTheMostNumberOfSides(),
              translate.ks1PDFInstructions.sortTheShapesInOrderOfTheNumberOfSidesStartWithTheMostNumberOfSides()
            ];
      } else {
        return ordering === 'ascending'
          ? [
              translate.ks1Instructions.dragTheCardsToSortTheShapesInOrderOfTheNumberOfVerticesStartWithTheFewestNumberOfVertices(),
              translate.ks1PDFInstructions.sortTheShapesInOrderOfTheNumberOfVerticesStartWithTheFewestNumberOfVertices()
            ]
          : [
              translate.ks1Instructions.dragTheCardsToSortTheShapesInOrderOfTheNumberOfVerticesStartWithTheMostNumberOfVertices(),
              translate.ks1PDFInstructions.sortTheShapesInOrderOfTheNumberOfVerticesStartWithTheMostNumberOfVertices()
            ];
      }
    })();

    return (
      <QF5DragOrderHorizontal
        title={instruction}
        pdfTitle={pdfInstruction}
        labelsPosition="bottom"
        testCorrect={correctOrder}
        pdfItemVariant="largeSquare"
        arrowWidth={300}
        items={shapes.map((shape, index) => {
          return {
            component: (
              <>
                <MeasureView key={shape}>
                  {dimens => (
                    <AssetSvg
                      name={getShapeSvgByShapeAndColor(shape, color)}
                      width={dimens.width * 0.9}
                      height={dimens.height * 0.9}
                    />
                  )}
                </MeasureView>
                {displayMode !== 'digital' && (
                  // This returns the strings 'A', 'B', 'C', 'D' and 'E' for indexes 0 to 4, needed to label the cards on PDF:
                  <Text variant="WRN700">{String.fromCharCode(index + 65)}</Text>
                )}
              </>
            ),
            value: shapeToSidesVertices(shape)
          };
        })}
        leftLabel={
          ordering === 'ascending' ? translate.keywords.Fewest() : translate.keywords.Most()
        }
        rightLabel={
          ordering === 'ascending' ? translate.keywords.Most() : translate.keywords.Fewest()
        }
        moveOrCopy="move"
        questionHeight={800}
      />
    );
  },
  questionHeight: 800
});

const Q3Content = (
  displayMode: 'digital' | 'pdf' | 'markscheme',
  translate: TranslationFunctions,
  verticesToSortBy: number,
  items: {
    shape: q3Shape | q3v2Shape;
    color: (typeof colors)[number];
    rotation: number;
  }[]
) => {
  const categoryAItems = items.filter(
    item => shapeToSidesVertices(item.shape, 'vertices') < verticesToSortBy
  );

  const categoryBItems = items.filter(
    item => shapeToSidesVertices(item.shape, 'vertices') >= verticesToSortBy
  );

  return displayMode === 'digital' ? (
    <QF8DragIntoUpTo3Groups
      title={translate.ks1Instructions.dragTheCardsToSortTheShapes()}
      zoneNames={[
        translate.tableHeaders.lessThanNumberVertices(verticesToSortBy),
        translate.tableHeaders.numberOrMoreVertices(verticesToSortBy)
      ]}
      testCorrect={[categoryAItems, categoryBItems]}
      items={items.map((shape, index) => {
        return {
          value: shape,
          component: (
            <MeasureView
              key={index}
              style={{
                transform: [{ rotate: `${shape.rotation}deg` }]
              }}
            >
              {dimens => (
                <AssetSvg
                  name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                  width={dimens.width * 0.9}
                  height={dimens.height * 0.9}
                />
              )}
            </MeasureView>
          )
        };
      })}
    />
  ) : (
    // This Q requires a fairly custom PDF version below, whereby the user has to circle the shapes with less than verticesToSortBy.
    <BaseLayoutPDF
      title={translate.ks1PDFInstructions.circleAllOfTheShapesWithLessThanNumberVertices(
        verticesToSortBy
      )}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <View
              style={[
                dimens,
                {
                  flex: 1,
                  justifyContent: 'center'
                }
              ]}
            >
              {
                <View
                  style={[
                    StyleSheet.absoluteFill,
                    {
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      alignItems: 'center'
                    }
                  ]}
                >
                  {items.map((shape, index) => (
                    <View
                      key={index}
                      style={[
                        { padding: 20, transform: [{ rotate: `${shape.rotation}deg` }] },
                        displayMode === 'markscheme' &&
                          categoryAItems.includes(shape) && {
                            borderColor: 'black',
                            borderWidth: 4,
                            borderRadius: 72
                          }
                      ]}
                    >
                      <AssetSvg
                        name={getShapeSvgByShapeAndColor(shape.shape, shape.color)}
                        width={150}
                        height={150}
                      />
                    </View>
                  ))}
                </View>
              }
            </View>
          )}
        </MeasureView>
      }
    />
  );
};

const Question3 = newQuestionContent({
  uid: 'bhT',
  description: 'bhT',
  keywords: ['Sort', '2-D shapes'],
  schema: z
    .object({
      verticesToSortBy: z.number().int().min(3).max(8),
      items: z
        .array(
          z.object({
            shape: z.enum(q3Shapes),
            color: z.enum(colors),
            rotation: z.number().int().min(0).max(270).multipleOf(90)
          })
        )
        .length(9)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => {
      const count = val.items.filter(
        item => shapeToSidesVertices(item.shape, 'vertices') < val.verticesToSortBy
      ).length;
      return count >= 3 && count <= 6;
    }, 'Between 3 and 6 items must have less vertices than verticesToSortBy.'),
  simpleGenerator: () => {
    const verticesToSortBy = randomIntegerInclusive(3, 8);

    // Shapes with less vertices than verticesToSortBy will fall into categoryA:
    const categoryAShapeChoices: q3Shape[] = [];

    // Shapes with the same or more vertices than verticesToSortBy will fall into categoryB:
    const categoryBShapeChoices: q3Shape[] = [];

    for (let i = 0; i < q3Shapes.length; i++) {
      if (shapeToSidesVertices(q3Shapes[i], 'vertices') < verticesToSortBy) {
        categoryAShapeChoices.push(q3Shapes[i]);
      } else {
        categoryBShapeChoices.push(q3Shapes[i]);
      }
    }

    // Category A shapes:

    const totalCategoryAShapes = randomIntegerInclusive(3, 6);

    const selectedCategoryAShapes = countRange(totalCategoryAShapes).map(
      () => getRandomFromArray(categoryAShapeChoices) as q3Shape
    );

    const selectedCategoryAColors = getRandomSubArrayFromArray(colors, totalCategoryAShapes);

    const categoryAShapes = countRange(totalCategoryAShapes).map(num => {
      return {
        shape: selectedCategoryAShapes[num],
        color: selectedCategoryAColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90)
      };
    });

    // Category B shapes:

    const totalCategoryBShapes = 9 - totalCategoryAShapes;

    const selectedCategoryBShapes = countRange(totalCategoryBShapes).map(
      () => getRandomFromArray(categoryBShapeChoices) as q3Shape
    );

    const selectedCategoryBColors = getRandomSubArrayFromArray(colors, totalCategoryBShapes);

    const categoryBShapes = countRange(totalCategoryBShapes).map(num => {
      return {
        shape: selectedCategoryBShapes[num],
        color: selectedCategoryBColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90)
      };
    });

    const items = shuffle([...categoryAShapes, ...categoryBShapes]);

    return { verticesToSortBy, items };
  },
  Component: props => {
    const {
      question: { verticesToSortBy, items },
      translate,
      displayMode
    } = props;

    return Q3Content(displayMode, translate, verticesToSortBy, items);
  }
});

const Question3v2 = newQuestionContent({
  uid: 'bhT2',
  description: 'bhT',
  keywords: ['Sort', '2-D shapes'],
  schema: z
    .object({
      verticesToSortBy: z.number().int().min(4).max(8),
      items: z
        .array(
          z.object({
            shape: z.enum(q3Shapesv2),
            color: z.enum(colors),
            rotation: z.number().int().min(0).max(270).multipleOf(90)
          })
        )
        .length(9)
        .refine(items => arrayHasNoDuplicates(items, deepEqual), 'items must not have duplicates')
    })
    .refine(val => {
      const count = val.items.filter(
        item => shapeToSidesVertices(item.shape, 'vertices') < val.verticesToSortBy
      ).length;
      return count >= 3 && count <= 6;
    }, 'Between 3 and 6 items must have less vertices than verticesToSortBy.'),
  simpleGenerator: () => {
    const verticesToSortBy = randomIntegerInclusive(4, 8);

    // Shapes with less vertices than verticesToSortBy will fall into categoryA:
    const categoryAShapeChoices: q3v2Shape[] = [];

    // Shapes with the same or more vertices than verticesToSortBy will fall into categoryB:
    const categoryBShapeChoices: q3v2Shape[] = [];

    for (let i = 0; i < q3Shapesv2.length; i++) {
      if (shapeToSidesVertices(q3Shapesv2[i], 'vertices') < verticesToSortBy) {
        categoryAShapeChoices.push(q3Shapesv2[i]);
      } else {
        categoryBShapeChoices.push(q3Shapesv2[i]);
      }
    }

    // Category A shapes:
    // only have 3 images with 8 vertices so 6 must be less than 8
    const min = verticesToSortBy === 8 ? 6 : 3;
    const totalCategoryAShapes = randomIntegerInclusive(min, 6);

    const selectedCategoryAShapes = getRandomSubArrayFromArray(
      categoryAShapeChoices,
      totalCategoryAShapes
    ) as q3v2Shape[];

    const selectedCategoryAColors = getRandomSubArrayFromArray(colors, totalCategoryAShapes);

    const categoryAShapes = countRange(totalCategoryAShapes).map(num => {
      return {
        shape: selectedCategoryAShapes[num],
        color: selectedCategoryAColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90)
      };
    });

    // Category B shapes:

    const totalCategoryBShapes = 9 - totalCategoryAShapes;

    const selectedCategoryBShapes = getRandomSubArrayFromArray(
      categoryBShapeChoices,
      totalCategoryBShapes
    ) as q3v2Shape[];

    const selectedCategoryBColors = getRandomSubArrayFromArray(colors, totalCategoryBShapes);

    const categoryBShapes = countRange(totalCategoryBShapes).map(num => {
      return {
        shape: selectedCategoryBShapes[num],
        color: selectedCategoryBColors[num],
        rotation: randomIntegerInclusiveStep(0, 270, 90)
      };
    });

    const items = shuffle([...categoryAShapes, ...categoryBShapes]);

    return { verticesToSortBy, items };
  },
  Component: props => {
    const {
      question: { verticesToSortBy, items },
      translate,
      displayMode
    } = props;

    return Q3Content(displayMode, translate, verticesToSortBy, items);
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Sort2DShapes',
  questionTypes: [Question1v2, Question2, Question3v2],
  unpublishedQuestionTypes: [Question1v2, Question3v2],
  archivedQuestionTypes: [Question1, Question3]
});
export default SmallStep;
