import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive
} from '../../../../utils/random';
import WoodenBalanceScale, {
  getScaledWeights,
  weightToDenominations
} from '../../../../components/question/representations/WoodenBalanceScale';
import { AssetSvg } from '../../../../assets/svg';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { View } from 'react-native';
import { numberEnum } from '../../../../utils/zod';
import QF65SetTheScales from '../../../../components/question/questionFormats/QF65SetTheScales';
import { isEqual } from '../../../../utils/matchers';
import { numbersDoNotExchange } from '../../../../utils/exchanges';
import { countRange, sumNumberArray } from '../../../../utils/collections';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bjQ',
  description: 'bjQ',
  keywords: ['Measure', 'Mass', 'Grams', 'Four operations', 'Balance scales'],
  schema: z.object({
    weight: numberEnum([2, 5, 10]),
    weightsToUse: numberEnum([2, 5, 10]),
    multiplier: z.number().int().min(2).max(10),
    object: z.enum(['plum', 'sharpener', 'rubber', 'strawberry', 'pencil', 'cherry'])
  }),
  questionHeight: 1000,
  simpleGenerator: () => {
    const object = getRandomFromArray([
      'plum',
      'sharpener',
      'rubber',
      'strawberry',
      'pencil',
      'cherry'
    ] as const);

    const weight = getRandomFromArray([2, 5, 10] as const);
    const multiplier = randomIntegerInclusive(2, 10);
    const weightsToUse = weight === 10 ? getRandomFromArray([2, 5, 10] as const) : weight;

    return { weight, object, multiplier, weightsToUse };
  },
  Component: props => {
    const {
      question: { weight, object, multiplier, weightsToUse },
      translate,
      displayMode
    } = props;

    const objectSvg = {
      plum: ['Plum', 90],
      sharpener: ['Pencil_sharpener', 90],
      rubber: ['Rubber', 120],
      strawberry: ['Array_objects/Strawberry', 100],
      pencil: ['Pencils/Pencil_green', 200],
      cherry: ['Cherry', 70]
    } as const;

    const weights = weightToDenominations(weight, [weightsToUse]);

    const weightComponent = weights.map((x, i) => (
      <AssetSvg
        width={(x === 10 ? 1 : x === 5 ? 0.7 : 0.6) * (displayMode === 'digital' ? 100 : 180)}
        key={`weight_${x}_${i}`}
        name={`Weights/Weights_${x}g` as const}
      />
    ));

    const title = {
      plum: 'whatIsTheMassOfXPlums',
      sharpener: 'whatIsTheMassOfXSharpeners',
      rubber: 'whatIsTheMassOfXRubbers',
      strawberry: 'whatIsTheMassOfXStrawberries',
      pencil: 'whatIsTheMassOfXPencils',
      cherry: 'whatIsTheMassOfXCherries'
    } as const;

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions[title[object]](multiplier)}
        sentence={translate.ks1AnswerSentences.ansG()}
        testCorrect={[(weight * multiplier).toString()]}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[
              <AssetSvg
                key={'object'}
                name={objectSvg[object][0]}
                width={objectSvg[object][1] * (displayMode === 'digital' ? 1 : 1.8)}
                style={{ justifyContent: 'flex-end' }}
              />,
              <View key="weights" style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                {weightComponent}
              </View>
            ]}
            containerStyle={{ marginTop: 80 }}
            dimens={dimens}
            stackInfront
          />
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bjR',
  description: 'bjR',
  keywords: ['Mass', 'Measure', 'Scales', 'Kilograms', 'Four operations'],
  schema: z.object({
    weights: z.array(numberEnum([5, 10, 20, 25, 50]))
  }),
  questionHeight: 1200,
  simpleGenerator: () => {
    const weights = countRange(2).map(_ => getRandomFromArray([5, 10, 20, 25, 50] as const));
    return { weights };
  },
  Component: ({ question: { weights }, translate, displayMode }) => {
    const answer = sumNumberArray(weights);
    const weightImages = weights.map(w => `Weights/Weights_${w}g` as const);
    const scaledWeights = getScaledWeights({ weights, unit: 'g' });
    return (
      <QF65SetTheScales
        title={translate.ks1Instructions.dragTheScaleToShowTheTotalMass()}
        pdfTitle={translate.ks1PDFInstructions.drawArrowToShowTheTotalMass()}
        weightImage={weightImages}
        weightImageWidth={scaledWeights.map(x => x * (displayMode === 'digital' ? 100 : 180))}
        dynamicScale={{
          maxScale: 100,
          majorTicks: 10,
          minorTicks: 5
        }}
        answerGrams={answer}
        questionHeight={1000}
        testCorrect={isEqual(answer)}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bjS',
  description: 'bjS',
  keywords: ['Measure', 'Mass', 'Grams', 'Four operations', 'Balance scales'],
  schema: z
    .object({
      weight: z.number().int().min(6).max(99),
      objects: z.array(z.enum(['cube', 'cylinder', 'sphere', 'cone', 'cuboid'])).length(2),
      obj1Mass: z.number().int().min(5).max(98)
    })
    .refine(({ weight, obj1Mass }) => numbersDoNotExchange(weight, -obj1Mass)),
  questionHeight: 1000,
  simpleGenerator: () => {
    const objects = getRandomSubArrayFromArray(
      ['cube', 'cylinder', 'sphere', 'cone', 'cuboid'] as const,
      2
    );

    const weight = randomIntegerInclusive(6, 99, {
      // 3 is the max weights that fit on the balance scale. Subtracting any number from 10 will include an exchange
      constraint: x => weightToDenominations(x, [50, 20, 10, 5, 2, 1]).length < 4 && x !== 10
    });
    const obj1Mass = randomIntegerInclusive(5, weight - 1, {
      constraint: x => numbersDoNotExchange(weight, -x)
    });

    return { weight, objects, obj1Mass };
  },
  Component: props => {
    const {
      question: { objects, weight, obj1Mass },
      translate,
      displayMode
    } = props;

    const objectSvg = {
      cube: 'Cubes_blank/Coloured_cube_unlabelled_red',
      cylinder: '3D_shapes_full_colors/Cylinders/Cylinder_pink',
      sphere: '3D_shapes_full_colors/Spheres/Sphere_green',
      cone: '3D_shapes_full_colors/Cones/Cone_yellow',
      cuboid: '3D_shapes_full_colors/Cuboids/Cuboid_purple'
    } as const;

    const translateObject = {
      cube: 'theCube',
      cylinder: 'theCylinder',
      sphere: 'theSphere',
      cone: 'theCone',
      cuboid: 'theCuboid'
    } as const;

    const obj1 = translate.objects[translateObject[objects[0]]]();
    const obj2 = translate.objects[translateObject[objects[1]]]();
    const obj1MassTranslated = translate.units.numberOfG(obj1Mass);

    const weights = weightToDenominations(weight, [50, 20, 10, 5, 2, 1]);
    const scaledWeights = getScaledWeights({ weights, unit: 'g' });

    const weightComponent = weights.map((x, i) => (
      <AssetSvg
        width={scaledWeights[i] * (displayMode === 'digital' ? 100 : 180)}
        key={`weight_${x}_${i}`}
        name={`Weights/Weights_${x}g` as const}
      />
    ));

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.theMassOfXIsYWhatIsTheMassOfZ(
          obj1,
          obj1MassTranslated,
          obj2
        )}
        sentence={translate.ks1AnswerSentences.ansG()}
        testCorrect={[(weight - obj1Mass).toString()]}
        Content={({ dimens }) => (
          <WoodenBalanceScale
            items={[
              <View
                key="objects"
                style={{ flexDirection: 'row', alignItems: 'flex-end', columnGap: 16 }}
              >
                {objects.map(o => (
                  <AssetSvg
                    key={`object_${o}`}
                    name={objectSvg[o]}
                    width={90 * (displayMode === 'digital' ? 1 : 1.8)}
                    style={{ justifyContent: 'flex-end' }}
                  />
                ))}
              </View>,
              <View key="weights" style={{ flexDirection: 'row', alignItems: 'flex-end' }}>
                {weightComponent}
              </View>
            ]}
            containerStyle={{ marginTop: 80 }}
            dimens={dimens}
            stackInfront
          />
        )}
        pdfDirection="column"
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        questionHeight={1000}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'FourOperationsWithMass',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
