import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomBoolean,
  getRandomFromArray,
  randomIntegerInclusive,
  randomUniqueIntegersInclusive,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import {
  NonEmptyArray,
  arrayHasNoDuplicates,
  countRange,
  filledArray,
  sortNumberArray,
  sumNumberArray
} from '../../../../utils/collections';
import QF14CompleteNumberTrack from '../../../../components/question/questionFormats/QF14CompleteNumberTrack';
import { numberEnum } from '../../../../utils/zod';
import { getSumCombinations } from '../../../../utils/math';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import TenFrameLayout, {
  counterVariantSchema,
  getRandomUniqueCounters
} from '../../../../components/question/representations/TenFrame/TenFrameLayout';
import isEqual from 'react-fast-compare';
import { AssetSvg, getSvgInfo } from '../../../../assets/svg';
import Rekenrek from '../../../../components/question/representations/Rekenrek/Rekenrek';
import { View } from 'react-native';
import { SimpleBaseTenWithCrossOut } from '../../../../components/question/representations/Base Ten/SimpleBaseTenWithCrossOut';
import { type Dimens } from '../../../../theme/scaling';

////
// Questions
////

const q1ItemSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('tenframe'),
    counters: counterVariantSchema.or(z.null()).array().max(10).array().min(1).max(2)
  }),
  z.object({
    type: z.enum(['base10', 'straws']),
    tens: z.number().int().min(0),
    ones: z.number().int().min(0)
  }),
  z.object({
    type: z.literal('rekenrek'),
    numbers: z.number().int().min(0).max(10).array().length(2)
  }),
  z.object({
    type: z.literal('hands'),
    numbers: numberEnum([1, 2, 3, 4, 5]).array().min(1)
  }),
  z.object({
    type: z.literal('dice'),
    numbers: numberEnum([1, 2, 3, 4, 5, 6]).array().min(1)
  })
]);

type Q1Item = z.infer<typeof q1ItemSchema>;

function q1GetCorrectness(item: Q1Item): boolean {
  switch (item.type) {
    case 'tenframe':
      return (
        item.counters.length === 2 &&
        item.counters.every(arr => arr.length === 10 && arr.every(counter => counter !== null))
      );
    case 'base10':
    case 'straws':
      return item.tens * 10 + item.ones === 20;
    case 'rekenrek':
      return item.numbers[0] === 10 && item.numbers[1] === 10;
    case 'hands':
    case 'dice':
      return sumNumberArray(item.numbers) === 20;
  }
}

function q1GenerateItem(type: Q1Item['type'], isCorrect: boolean): Q1Item {
  switch (type) {
    case 'tenframe': {
      let counters: ('red' | 'yellow' | null)[][];
      if (isCorrect) {
        // Either red,red or red,yellow
        counters = [
          filledArray('red', 10),
          filledArray(getRandomFromArray(['yellow', 'red'] as const), 10)
        ];
      } else {
        const variant = getRandomFromArray(['10', '2v1', '2v2', '12', '19'] as const);
        counters = (() => {
          switch (variant) {
            case '10':
              return [filledArray('red', 10)];
            case '2v1':
              return [['red'], ['red']];
            case '2v2':
              return [['red', 'yellow']];
            case '12':
              return [filledArray('red', 10), filledArray('red', 2)];
            case '19': {
              const counters: ('red' | null)[][] = [filledArray('red', 10), filledArray('red', 10)];
              counters[randomIntegerInclusive(0, 1)][randomIntegerInclusive(0, 9)] = null;
              return counters;
            }
          }
        })();
      }
      return { type, counters };
    }
    case 'base10':
    case 'straws': {
      let numbers: { tens: number; ones: number };
      if (isCorrect) {
        numbers = getRandomFromArray([
          { tens: 2, ones: 0 },
          { tens: 1, ones: 10 },
          ...(type === 'base10' ? [{ tens: 0, ones: 20 }] : [])
        ] as const);
      } else {
        numbers = getRandomFromArray([
          { tens: 1, ones: 0 },
          { tens: 0, ones: 2 },
          { tens: 1, ones: 2 },
          { tens: 1, ones: 9 }
        ] as const);
      }
      return { type, ...numbers };
    }
    case 'rekenrek': {
      let choices: NonEmptyArray<[number, number]>;
      if (isCorrect) {
        choices = [[10, 10]];
      } else {
        choices = [
          [10, 0],
          [10, 0],
          [2, 0],
          [10, 2],
          [1, 1]
        ];
      }
      return { type, numbers: getRandomFromArray(choices) };
    }
    case 'hands': {
      let choices: NonEmptyArray<(1 | 2 | 3 | 4 | 5)[]>;
      if (isCorrect) {
        choices = [[5, 5, 5, 5]];
      } else {
        choices = [[5, 5], [2], [1, 1], [5, 5, 2]];
      }
      return { type, numbers: getRandomFromArray(choices) };
    }
    case 'dice': {
      let choices: NonEmptyArray<(1 | 2 | 3 | 4 | 5 | 6)[]>;
      if (isCorrect) {
        choices = [
          [5, 5, 5, 5],
          [6, 4, 6, 4],
          [5, 5, 6, 4]
        ];
      } else {
        choices = [
          [5, 5, 5],
          [5, 5],
          [5, 5, 5, 4],
          [5, 5, 2],
          [5, 5, 1, 1],
          [5, 5, 5, 6],
          [1, 1],
          [2]
        ];
      }
      return { type, numbers: getRandomFromArray(choices) };
    }
  }
}

function q1RenderItem(item: Q1Item, dimens: Dimens) {
  switch (item.type) {
    case 'tenframe':
      return (
        <View style={{ gap: 10 }}>
          {item.counters.map((counters, i) => (
            <TenFrameLayout key={i} size="xxsmall" items={counters.map(it => it ?? undefined)} />
          ))}
        </View>
      );
    case 'base10':
      return (
        <SimpleBaseTenWithCrossOut
          ones={item.ones}
          tens={item.tens}
          dimens={{ width: dimens.width * 0.9, height: dimens.height * 0.9 }}
          onesPosition="top"
        />
      );
    case 'straws': {
      const straw10Height = 100;
      const straw1Height = straw10Height * (straw1Info.height / straw10Info.height);
      // Add a negative margin to the ones, to pack them more densely
      const straw1NegativeMargin = (straw1Height / straw1Info.aspectRatio) * 0.2;

      return (
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {countRange(item.tens).map(i => (
            <AssetSvg key={`tens-${i}`} name="Base_Ten/Straws10" height={straw10Height} />
          ))}
          {countRange(item.ones).map(i => (
            <AssetSvg
              key={`ones-${i}`}
              name="Base_Ten/Straws1"
              height={straw1Height}
              style={{ marginLeft: i > 0 ? -straw1NegativeMargin : 0 }}
            />
          ))}
        </View>
      );
    }
    case 'rekenrek':
      return <Rekenrek scale="small" rows={2} numberShown={item.numbers} />;
    case 'hands':
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
          {item.numbers.map((n, i) => (
            <View key={i} style={i % 2 === 1 && { transform: [{ scaleX: -1 }] }}>
              <AssetSvg name={`fingers/Hand_${n}`} height={100} />
            </View>
          ))}
        </View>
      );
    case 'dice':
      return (
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
          {item.numbers.map((n, i) => (
            <AssetSvg key={i} name={`Dice_faces/${n}`} height={100} />
          ))}
        </View>
      );
  }
}

const straw1Info = getSvgInfo('Base_Ten/Straws1');
const straw10Info = getSvgInfo('Base_Ten/Straws10');

const Question1 = newQuestionContent({
  uid: 'bcc',
  description: 'bcc',
  keywords: ['Counters', 'Ten frames', 'Twenty', 'Rekenrek', 'Base 10'],
  schema: z.object({
    items: z
      .array(q1ItemSchema)
      .length(4)
      .refine(arr => arrayHasNoDuplicates(arr, isEqual), 'items must not have duplicates')
      .refine(arr => arr.some(q1GetCorrectness), 'items must have at least one correct answer')
  }),
  simpleGenerator: () => {
    const numCorrect = getRandomFromArray([1, 2, 3, 4] as const);

    const items = shuffle(
      rejectionSample(
        () =>
          countRange(4).map(n =>
            q1GenerateItem(
              getRandomFromArray([
                'tenframe',
                'base10',
                'straws',
                'rekenrek',
                'hands',
                'dice'
              ] as const),
              n < numCorrect
            )
          ),
        arr => arrayHasNoDuplicates(arr, isEqual)
      )
    );

    return { items };
  },
  Component: ({ question: { items }, translate }) => {
    // use the index of the item within the `items` array as the value
    const testCorrect = items.flatMap((item, i) => (q1GetCorrectness(item) ? [i] : []));

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheBoxesThatShowX(testCorrect.length, 20)}
        pdfTitle={translate.ks1PDFInstructions.tickTheBoxesThatShowX(testCorrect.length, 20)}
        multiSelect
        numItems={4}
        testCorrect={testCorrect}
        renderItems={({ dimens }) =>
          items.map((item, index) => ({
            // use the index of the item within the `items` array as the value
            value: index,
            component: q1RenderItem(item, dimens)
          }))
        }
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcd',
  description: 'bcd',
  keywords: ['Number track', 'Count', 'Forwards', 'Backwards'],
  schema: z.object({
    answerIndexes: z.array(z.number().min(0).max(7)).min(1).refine(arrayHasNoDuplicates),
    numCells: z.number().min(6).max(8),
    isForward: z.boolean()
  }),
  simpleGenerator: () => {
    const isForward = getRandomBoolean();
    const numCells = randomIntegerInclusive(6, 8);

    const answerIndexes = rejectionSample(
      () => {
        return sortNumberArray(
          randomUniqueIntegersInclusive(0, numCells - 1, randomIntegerInclusive(2, numCells - 3))
        );
      },
      // Check at least two consecutive numbers
      x => x.some((_, i) => !x.includes(i) && !x.includes(i + 1))
    );

    return { numCells, isForward, answerIndexes };
  },
  Component: ({ question: { numCells, isForward, answerIndexes }, translate }) => {
    const startingNumber = 20;
    // Create array to pass to NumberTrack
    const numberArray = countRange(numCells).map(i =>
      isForward ? startingNumber - (numCells - 1 - i) : startingNumber - i
    );

    const ansArray = numberArray
      .filter((_val, i) => answerIndexes.includes(i))
      .map(val => val.toString());

    const stringArray = numberArray.map((val, id) =>
      answerIndexes.includes(id) ? '<ans/>' : val.toLocaleString()
    );

    return (
      <QF14CompleteNumberTrack
        title={translate.instructions.completeNumberTrack()}
        questionHeight={600}
        testCorrect={ansArray}
        boxValues={stringArray}
      />
    );
  },
  questionHeight: 600
});

const Question3 = newQuestionContent({
  uid: 'bce',
  description: 'bce',
  keywords: ['Twenty', 'Add'],
  schema: z.object({
    variant: z.discriminatedUnion('representation', [
      z.object({
        representation: z.literal('tenFrame'),
        colors: counterVariantSchema.array().length(8),
        options: z
          .number()
          .int()
          .min(1)
          .max(10)
          .array()
          .length(8)
          .refine(val => getSumCombinations(val, 20).length > 0, 'there is at least one sum to 20')
      }),
      z.object({
        representation: z.literal('dice'),
        options: numberEnum([1, 2, 3, 4, 5, 6])
          .array()
          .length(8)
          .refine(val => getSumCombinations(val, 20).length > 0, 'there is at least one sum to 20')
      })
    ])
  }),
  simpleGenerator: () => {
    const representation = getRandomFromArray(['dice', 'tenFrame'] as const);

    const variant =
      representation === 'tenFrame'
        ? {
            representation,
            colors: countRange(8).map(_ => getRandomUniqueCounters(1)[0]),
            options: rejectionSample(
              () => countRange(8).map(_ => randomIntegerInclusive(1, 10)),
              val => getSumCombinations(val, 20).length > 0
            )
          }
        : {
            representation,
            options: rejectionSample(
              () => countRange(8).map(_ => getRandomFromArray([1, 2, 3, 4, 5, 6] as const)),
              val => getSumCombinations(val, 20).length > 0
            )
          };

    return { variant };
  },
  Component: props => {
    const {
      question: { variant },
      translate
    } = props;

    const items = variant.options.map((val, i) => ({
      value: i,
      total: val,
      component:
        variant.representation === 'dice' ? (
          <AssetSvg name={`Dice_faces/${val as 1 | 2 | 3 | 4 | 5}`} height={100} width={100} />
        ) : (
          <TenFrameLayout
            orientation="vertical"
            size="xxsmall"
            items={[...filledArray(undefined, 10 - val), ...filledArray(variant.colors[i], val)]}
          />
        )
    }));

    const combination = getSumCombinations(variant.options, 20);
    const markSchemeAnswer: number[] = [];
    combination.forEach(value => {
      const option = items.filter(
        item => item.total === value && !markSchemeAnswer.includes(item.value)
      );
      markSchemeAnswer.push(option[0].value);
    });

    return (
      <QF11SelectImagesUpTo4
        title={
          variant.representation === 'tenFrame'
            ? translate.ks1Instructions.selectTheCountersToMakeX(20)
            : translate.ks1Instructions.selectTheDiceToMakeX(20)
        }
        pdfTitle={
          variant.representation === 'tenFrame'
            ? translate.ks1PDFInstructions.tickTheTenFramesThatMakeX(20)
            : translate.ks1PDFInstructions.tickTheDiceThatMakeX(20)
        }
        renderItems={items}
        customMarkSchemeAnswer={{
          answerToDisplay: markSchemeAnswer,
          answerText: translate.markScheme.acceptAnyValidAnswer()
        }}
        multiSelect
        numItems={8}
        testCorrect={userAnswer => {
          const userAnswerSum = sumNumberArray(
            variant.options.filter((_, i) => userAnswer.includes(i))
          );
          return isEqual(userAnswerSum, 20);
        }}
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'Understand20',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
