import BaseLayout from 'common/src/components/molecules/BaseLayout';
import { NumberGridInteractiveWithState } from '../representations/NumberGridInteractive';
import { TitleStyleProps } from 'common/src/components/molecules/TitleRow';
import { useContext } from 'react';
import { DisplayMode } from '../../../contexts/displayMode';
import { arraysHaveSameContentsUnordered } from '../../../utils/collections';
import BaseLayoutPDF from '../../molecules/BaseLayoutPDF';
import { renderMarkSchemeProp } from './utils/markSchemeRender';
import { MeasureView } from '../../atoms/MeasureView';

type Props = TitleStyleProps & {
  /**
   * Title at the top of the question
   */
  title: string;
  pdfTitle?: string;
  /**
   * The first number to show on the number grid
   */
  startNumber: number;
  /**
   * The last number to show on the number grid
   */
  finishNumber: number;
  /**
   * The numbers expected to be highlighted as true on the  number grid
   */
  testCorrect: number[];
  /**
   * Numbers that should be preshaded on the number grid
   * These are shaded with a different color (blue) which cannot be removed.
   * Preshaded cells are still interactive: they can still be shaded by the user,
   * in which case they show both the user color (orange) and the preshaded color (blue).
   */
  preshaded?: number[];
  /**
   * Initial State if required to start with some numbers shaded
   */
  initialState?: number[];
  /** PDF Question Height */
  questionHeight?: number;
  /** Optional custom mark scheme answer */
  customMarkSchemeAnswer?: {
    answersToDisplay?: number[];
    answerText?: string;
    /** Gets passed through to the representation to determine whether to circle or shade the correct answer(s). */
    circleOrShade?: 'circle' | 'shade';
  };
  /**
   * Whether to allow multiselect or not. Defaults to true
   */
  multiSelect?: boolean;
};

export default function QF31NumberGridInteractive({
  startNumber,
  finishNumber,
  title,
  pdfTitle,
  testCorrect,
  preshaded,
  initialState,
  questionHeight,
  customMarkSchemeAnswer,
  multiSelect = true,
  ...props
}: Props) {
  const displayMode = useContext(DisplayMode);

  if (displayMode === 'pdf' || displayMode === 'markscheme') {
    // Use custom mark scheme answer if available
    const markSchemeAnswer = customMarkSchemeAnswer?.answersToDisplay ?? testCorrect;

    return (
      <BaseLayoutPDF
        title={pdfTitle ?? title}
        mainPanelContents={
          <>
            <MeasureView>
              {dimens => (
                <NumberGridInteractiveWithState
                  id="numbergrid"
                  startNumber={startNumber}
                  finishNumber={finishNumber}
                  preshaded={preshaded}
                  dimens={dimens}
                  defaultState={displayMode === 'markscheme' ? markSchemeAnswer : initialState}
                  circleOrShaded={customMarkSchemeAnswer?.circleOrShade}
                />
              )}
            </MeasureView>
            {displayMode === 'markscheme' &&
              customMarkSchemeAnswer?.answerText &&
              renderMarkSchemeProp(customMarkSchemeAnswer.answerText)}
          </>
        }
        questionHeight={questionHeight}
      />
    );
  }

  return (
    <BaseLayout
      title={title}
      mainPanelContents={
        <MeasureView>
          {dimens => (
            <NumberGridInteractiveWithState
              id="numbergrid"
              startNumber={startNumber}
              finishNumber={finishNumber}
              preshaded={preshaded}
              defaultState={initialState}
              dimens={dimens}
              testCorrect={answer => arraysHaveSameContentsUnordered(testCorrect, answer)}
              multiSelect={multiSelect}
            />
          )}
        </MeasureView>
      }
      {...props}
    />
  );
}
