import { Ks1Names, Name } from './names';
import { colors } from '../theme/colors';
import { View } from 'react-native';
import Text from '../components/typography/Text';
import { AssetSvg, type SvgName } from '../assets/svg';
import { getRandomFromArray, getRandomSubArrayFromArray } from './random';

const characterHeadObjects: Record<Name, SvgName> = {
  Alex: 'Characters/AlexHead',
  Amir: 'Characters/AmirHead',
  Annie: 'Characters/AnnieHead',
  Dexter: 'Characters/DexterHead',
  Eva: 'Characters/EvaHead',
  Jack: 'Characters/JackHead',
  Jo: 'Characters/JoHead',
  Kim: 'Characters/KimHead',
  Max: 'Characters/MaxHead',
  Mo: 'Characters/MoHead',
  Ron: 'Characters/RonHead',
  Rosie: 'Characters/RosieHead',
  Sam: 'Characters/SamHead',
  Teddy: 'Characters/TeddyHead',
  Tommy: 'Characters/TommyHead',
  Whitney: 'Characters/WhitneyHead'
};

/**
 * Function to scale a given character heads. Takes in an array of ks1 names, finds the largest head,
 * gives it a scale of 1, calculates and returns the scaled proportions
 */
export const headScaledSizesKS1 = (names: Ks1Names[]): number[] => {
  const headSizes: Record<Ks1Names, number> = {
    Jack: 0.85,
    Jo: 1,
    Kim: 0.9,
    Max: 0.85,
    Mo: 0.85,
    Ron: 0.85,
    Sam: 0.95
  };

  // Sort in ascending order of their scale:
  const sortedHeads = [...names].sort((a, b) => headSizes[a] - headSizes[b]);

  // Determine scaling factor
  const maxScale = sortedHeads[sortedHeads.length - 1];
  const scaleFactor = headSizes[maxScale];

  // Calculate scaled sizes
  const scaledSizes: number[] = [];
  names.forEach(name => {
    if (name in headSizes) {
      const scaledSize = headSizes[name] / scaleFactor;
      scaledSizes.push(scaledSize);
    }
  });

  return scaledSizes;
};

/**
 * Get character head SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this, or use {@link getCharacterHeadImage} instead.
 */
export const getCharacterHeadSvgName = (name: Name): SvgName => {
  return characterHeadObjects[name];
};

export const displayScaledKS1Heads = (
  names: Ks1Names[],
  width: number,
  height: number
): JSX.Element[] => {
  const scales = headScaledSizesKS1(names);
  return names.map((name, i) => {
    const svgName = getCharacterHeadSvgName(name);
    return (
      <AssetSvg key={i} name={svgName} width={width * scales[i]} height={height * scales[i]} />
    );
  });
};

/**
 * Get character head image.
 * Returns an SVG which works sensibly when providing just one of the height and the width.
 * @deprecated Just use {@link AssetSvg} with {@link getCharacterHeadSvgName} directly, for better customization.
 */
export const getCharacterHeadImage = (name: Name, height?: number, width?: number): JSX.Element => {
  return <AssetSvg name={getCharacterHeadSvgName(name)} height={height} width={width} />;
};

type characterStanding = {
  [key: string]: {
    name: SvgName;
    bottomOffset: number;
    topOffset: number;
  };
};

const characterStandingObjects: characterStanding = {
  Alex: {
    name: 'Characters/AlexStanding',
    bottomOffset: 8,
    topOffset: 18
  },
  Amir: {
    name: 'Characters/AmirStanding',
    bottomOffset: 8,
    topOffset: 10
  },
  Annie: {
    name: 'Characters/AnnieStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Eva: {
    name: 'Characters/EvaStanding',
    bottomOffset: 8,
    topOffset: 50
  },
  Jack: {
    name: 'Characters/JackStanding',
    bottomOffset: 8,
    topOffset: 18
  },
  Jo: {
    name: 'Characters/JoStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Kim: {
    name: 'Characters/KimStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Max: {
    name: 'Characters/MaxStanding',
    bottomOffset: 8,
    topOffset: 28
  },
  Mo: {
    name: 'Characters/MoStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Ron: {
    name: 'Characters/RonStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Rosie: {
    name: 'Characters/RosieStanding',
    bottomOffset: 8,
    topOffset: 0
  },
  Sam: {
    name: 'Characters/SamStanding',
    bottomOffset: 8,
    topOffset: 55
  },
  Teddy: {
    name: 'Characters/TeddyStanding',
    bottomOffset: 8,
    topOffset: 28
  },
  Tommy: {
    name: 'Characters/TommyStanding',
    bottomOffset: 8,
    topOffset: 28
  },
  Whitney: {
    name: 'Characters/WhitneyStanding',
    bottomOffset: 8,
    topOffset: 0
  }
};

export const getCharacterStandingOffsets = (name: Name) => {
  if (name === 'Dexter') {
    throw Error('There is no standing image for Dexter.');
  }
  const CHARACTER_STANDING_BOTTOM_OFFSET = characterStandingObjects[name].bottomOffset;
  const CHARACTER_STANDING_TOP_OFFSET = characterStandingObjects[name].topOffset;

  return {
    characterBottomOffset: CHARACTER_STANDING_BOTTOM_OFFSET,
    characterTopOffset: CHARACTER_STANDING_TOP_OFFSET
  };
};

// These two offsets define where the character's height should be measured from and to, when placed against a ruler.
// They were found empirically for all CharacterStanding SVGs, and are luckily the same for all the SVGs.
/**
 * Get character standing SVG name.
 * This just returns the SVG name.
 * See e.g. `common/assets/svg/index.tsx` for ways to use this.
 */
export const getCharacterStandingSvgName = (name: Name): SvgName => {
  return characterStandingObjects[name].name;
};

/**
 * Helper function for consistent name label styling for different characters
 *
 * Colors have been set according to Figma designs (all genders are the same color)
 */
export const characterNameLabel = (name: Name, width: number) => {
  return (
    <View
      style={{
        borderWidth: 3,
        borderRadius: 19,
        width,
        borderColor: colors.prussianBlue,
        backgroundColor: colors.white
      }}
    >
      <Text variant="WRN400" style={{ fontSize: 32, textAlign: 'center' }}>
        {name}
      </Text>
    </View>
  );
};

export const charactersWithHeightsNames = ['Ron', 'Max', 'Mo', 'Kim', 'Jo'] as const;
export type charactersWithHeightsNames = (typeof charactersWithHeightsNames)[number];

export const charactersWithHeights: {
  name: charactersWithHeightsNames;
  icon: SvgName;
  height: number;
  scale: number;
}[] = [
  {
    name: 'Ron',
    icon: 'Characters/RonStanding',
    height: 1,
    scale: 0.5
  },
  {
    name: 'Max',
    icon: 'Characters/MaxStanding',
    height: 2,
    scale: 0.6
  },
  {
    name: 'Kim',
    icon: 'Characters/KimStanding',
    height: 3,
    scale: 0.75
  },
  {
    name: 'Mo',
    icon: 'Characters/MoStanding',
    height: 4,
    scale: 0.8
  },
  {
    name: 'Jo',
    icon: 'Characters/JoStanding',
    height: 5,
    scale: 0.9
  }
];

export const getRandomUniqueCharacterHeightObjects = (quantity: number) => {
  return getRandomSubArrayFromArray(charactersWithHeights, quantity);
};

export const getRandomCharacterHeightName = () => {
  return getRandomFromArray(charactersWithHeightsNames);
};
