import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  equalHalfShadedShapes,
  equalHalfShadedShapesSvgNameSchema,
  getRandomEqualHalfShadedShapesSvgName,
  getRandomUnequalHalfShadedShapesSvgName,
  unequalHalfShadedShapes,
  unequalHalfShadedShapesSvgNameSchema
} from '../../../../utils/shapes';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  rejectionSample,
  shuffle
} from '../../../../utils/random';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { AssetSvg } from '../../../../assets/svg';
import {
  getRandomBoolean,
  getRandomBooleanArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  seededRandom
} from '../../../../utils/random';
import QF67SelectingRandomlyArrangedShapes from '../../../../components/question/questionFormats/QF67SelectingRandomlyArrangedShapes';
import { arrayHasNoDuplicates, filledArray } from '../../../../utils/collections';
import { numberEnum } from '../../../../utils/zod';
import {
  ShadedCounterArrangements,
  ShadedCounterBoxArrangement
} from '../../../../components/question/representations/CounterBoxArrangement/ShadedCounterBoxArrangement';
import deepEqual from 'react-fast-compare';
import getShadedCounterArrangements from '../../../../components/question/representations/CounterBoxArrangement/shadedCounterArrangements';

////
// Questions
////
const Question1 = newQuestionContent({
  uid: 'bke',
  description: 'bke',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  schema: z.object({
    options: z
      .array(
        z.object({
          shape: z.union([
            equalHalfShadedShapesSvgNameSchema,
            unequalHalfShadedShapesSvgNameSchema
          ]),
          isEqual: z.boolean()
        })
      )
      .length(4)
  }),
  simpleGenerator: () => {
    const numberOfCorrectAnswers = getRandomFromArray([1, 2, 3]);

    const correctShapeSvgs = getRandomSubArrayFromArray(
      equalHalfShadedShapes,
      numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomEqualHalfShadedShapesSvgName(shape),
        isEqual: true
      };
    });

    const incorrectShapesSvgs = getRandomSubArrayFromArray(
      unequalHalfShadedShapes,
      4 - numberOfCorrectAnswers
    ).map(shape => {
      return {
        shape: getRandomUnequalHalfShadedShapesSvgName(shape),
        isEqual: false
      };
    });

    const options = shuffle([...correctShapeSvgs, ...incorrectShapesSvgs]);

    return { options };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    const correctAnswers = options.filter(it => it.isEqual).map(({ shape }) => shape);

    const numberOfCorrectAnswers = correctAnswers.length;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheDiagramsThatShowAHalf(numberOfCorrectAnswers)}
        pdfTitle={translate.ks1PDFInstructions.circleTheDiagramsThatShowAHalf(
          numberOfCorrectAnswers
        )}
        testCorrect={correctAnswers}
        numItems={4}
        multiSelect
        renderItems={({ dimens }) => {
          return options.map(({ shape }) => ({
            value: shape,
            component: <AssetSvg height={dimens.height * 0.7} name={shape} />
          }));
        }}
        questionHeight={900}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bkf',
  description: 'bkf',
  keywords: ['Equal', 'Unequal', 'Parts', 'Whole', 'Half'],
  questionHeight: 1000,
  schema: z.object({
    options: z
      .array(
        z.object({
          arrangement: z.array(z.array(numberEnum([0, 1, 2]))),
          isHalf: z.boolean()
        })
      )
      .length(4)
      .refine(x => arrayHasNoDuplicates(x, deepEqual), 'all options should be different')
  }),
  simpleGenerator: () => {
    const numOfCorrect = getRandomFromArray([2, 3]);

    const correctOptions = rejectionSample(
      () => {
        const halfOptions = getRandomSubArrayFromArray(
          [
            [2, 3, 'rows'],
            [2, 4, 'columns'],
            [2, 3, 'random'],
            [2, 4, 'random'],
            [3, 6, 'random', 3, 3]
          ] as const,
          numOfCorrect
        );
        return halfOptions.map(([rows, cols, shadedVariation, numShaded, numNonShaded]) => {
          return {
            arrangement: getShadedCounterArrangements({
              rows,
              cols,
              shadedVariation,
              numShaded: numShaded === undefined ? (rows * cols) / 2 : numShaded,
              numNonShaded: numNonShaded === undefined ? (rows * cols) / 2 : numNonShaded
            }),
            isHalf: true
          };
        });
      },
      correctOptions => arrayHasNoDuplicates(correctOptions, deepEqual)
    );

    const wrongArrangements: ShadedCounterArrangements[] = getRandomSubArrayFromArray(
      [
        [
          [2, 0],
          [1, 1]
        ],
        [
          [2, 0],
          [2, 2]
        ],
        [
          [1, 0],
          [1, 1]
        ],
        [
          [1, 0],
          [2, 2]
        ],
        [
          [2, 2, 0, 0],
          [1, 1, 1, 1]
        ],
        [
          [0, 0, 2, 2],
          [1, 1, 1, 1]
        ],
        [
          [1, 1, 2, 1],
          [1, 1, 2, 2]
        ]
      ],
      4 - numOfCorrect
    );

    const wrongOptions = wrongArrangements.map(arrangement => {
      return {
        arrangement: arrangement,
        isHalf: false
      };
    });

    return { options: shuffle([...correctOptions, ...wrongOptions]) };
  },
  Component: props => {
    const {
      question: { options },
      translate
    } = props;

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectThePicturesThatHaveHalfShaded()}
        pdfTitle={translate.ks1PDFInstructions.circleThePicturesThatHaveHalfShaded()}
        numItems={4}
        multiSelect
        pdfShowBorder
        renderItems={({ dimens }) =>
          options.map(({ arrangement }, index) => ({
            value: index,
            component: (
              <ShadedCounterBoxArrangement dimens={dimens} arrangement={arrangement} scale={4.5} />
            )
          }))
        }
        testCorrect={options.flatMap((el, index) => (el.isHalf ? [index] : []))}
        questionHeight={1000}
      />
    );
  }
});

const Question3 = newQuestionContent({
  questionHeight: 900,
  uid: 'bkg',
  description: 'bkg',
  keywords: ['Whole', 'Half', 'Group'],
  schema: z.object({
    numberOfCircles: z.number().int().min(2).max(10).step(2),
    randomSeed: z.number().int().min(1).max(1000),
    isRandom: z.boolean()
  }),
  simpleGenerator: () => {
    const numberOfCircles = randomIntegerInclusiveStep(2, 10, 2);
    const randomSeed = randomIntegerInclusive(1, 1000);
    const isRandom = getRandomBoolean();

    return { numberOfCircles, randomSeed, isRandom };
  },
  Component: props => {
    const {
      question: { numberOfCircles, randomSeed, isRandom },
      translate
    } = props;

    const randomArrangement = getRandomBooleanArray(
      4,
      4,
      numberOfCircles,
      seededRandom({ randomSeed })
    );

    return (
      <QF67SelectingRandomlyArrangedShapes
        title={translate.ks1Instructions.tapToShadeHalfOfTheCircles()}
        pdfTitle={translate.ks1PDFInstructions.shadeHalfOfTheCircles()}
        shapesArray={filledArray({ shape: 'Circle' }, numberOfCircles)}
        testCorrect={filledArray('Circle', numberOfCircles / 2)}
        arrangement={
          isRandom ? randomArrangement : filledArray(filledArray(true, numberOfCircles / 2), 2)
        }
        customMarkSchemeAnswer={{ answerText: translate.markScheme.acceptAnyValidAnswer() }}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'RecogniseAHalf',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
