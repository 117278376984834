import { MULT } from '../../constants';

export default {
  afterSchool: 'After school',
  aPatternGoesXYThenItRepeatsDragTheShapes:
    'A pattern goes: {0:string|lower}, {1:string|lower}, then it repeats.<br/>Drag the shapes to make the pattern.',
  aPatternGoesXYZThenItRepeatsDragTheShapes:
    'A pattern goes: {0:string|lower}, {1:string|lower}, {2:string|lower}, then it repeats.<br/>Drag the shapes to make the pattern.',
  aSpoonHoldsXYPoursZIntoBeaker:
    'A spoon holds {0:string|lower}.<br/>{1} pours {2:number|numberFormat} spoons of water into the beaker.',
  aTeaspoonHoldsXYPoursZIntoBeaker:
    'A teaspoon holds {0:string|lower}.<br/>{1} pours {2:number|numberFormat} teaspoons of water into the beaker.',
  aToyCarIsHeavierThenXCubesButLighterThanYCubesSelectAllTheMassesThatTheCarCouldBe:
    'A toy car is heavier than {0:number|numberFormat} cubes, but lighter than {1:number|numberFormat} cubes.<br/>Select all the masses that the car could be.',
  beforeSchool: 'Before school',
  canEachChildHave1AppleSelectYourAnswer: 'Can each child have 1 apple?<br/>Select your answer.',
  canEachChildHave1CakeSelectYourAnswer: 'Can each child have 1 cake?<br/>Select your answer.',
  canEachChildHave1CookieSelectYourAnswer: 'Can each child have 1 cookie?<br/>Select your answer.',
  canEachChildHave1PieceOfFruitSelectYourAnswer:
    'Can each child have 1 piece of fruit?<br/>Select your answer.',
  canEachHorseHave1CarrotSelectYourAnswer: 'Can each horse have 1 carrot?<br/>Select your answer.',
  canEachHorseHave1BaleOfHaySelectYourAnswer:
    'Can each horse have 1 bale of hay?<br/>Select your answer.',
  canEachSheepHave1BaleOfHaySelectYourAnswer:
    'Can each sheep have 1 bale of hay?<br/>Select your answer.',
  canEachDogHave1BallSelectYourAnswer: 'Can each dog have 1 ball?<br/>Select your answer.',
  canEachDogHave1BoneSelectYourAnswer: 'Can each dog have 1 bone?<br/>Select your answer.',
  charHasPoundsXPronounBuysXAndY:
    '{0} has £{1:number|numberFormat}<br/>{2} buys {3:string|lower} and {4:string|lower}.',
  charHasPoundsXHeSheSpendsMoneyOnAComicTheBoxThatShowsTheChangeCharGets:
    '{0} has £{1:number|numberFormat}<br/>{2} spends {3} on a comic.<br/>Select the box that shows the change {0} gets.',
  charHasPoundsXHeSheOtherCharacterGivesPronounAnotherPoundsYCharSpendsPoundsZOnItem:
    '{0} has £{1:number|numberFormat}<br/>{2} {3} gives {4:string|lower} another £{5:number|numberFormat}<br/>{6} spends £{7:number|numberFormat} on a {8:string|lower}.',
  charHasPoundsXHeSheOtherCharacterGivesPronounAnotherPoundsYCharSpendsPoundsZOnATShirt:
    '{0} has £{1:number|numberFormat}<br/>{2} {3} gives {4:string|lower} another £{5:number|numberFormat}<br/>{6} spends £{7:number|numberFormat} on a T-shirt.',
  charHasTheseItems: '{0} has these {1:string|lower}.',
  charHasThisMoney: '{0} has this money.',
  charHasXPHeSheOtherCharacterGivesPronounAnotherYPCharSpendsZPOnItem:
    '{0} has {1:number|numberFormat}p<br/>{2} {3} gives {4:string|lower} another {5:number|numberFormat}p.<br/>{6} spends {7:number|numberFormat}p on {8:string|lower}.',
  charHasXPHeSheOtherCharacterGivesPronounAnotherYPCharSpendsZPOnATShirt:
    '{0} has {1:number|numberFormat}p<br/>{2} {3} gives {4:string|lower} another {5:number|numberFormat}p.<br/>{6} spends {7:number|numberFormat}p on a T-shirt.',
  charAHasNumXCharBHasNumXHowManyFewerXDoesCharHaveThanCharCompleteTheBarModel:
    '{0} has {1:number|numberFormat} {2:string|lower}.<br/>{3} has {4:number|numberFormat} {5:string|lower}.<br/>How many <b>fewer</b> {2:string|lower} does {3} have than {0}?<br/>Complete the bar model.',
  charAHasNumXCharBHasNumXHowManyMoreXDoesCharHaveThanCharCompleteTheBarModel:
    '{0} has {1:number|numberFormat} {2:string|lower}.<br/>{3} has {4:number|numberFormat} {5:string|lower}.<br/>How many <b>more</b> {2:string|lower} does {0} have than {3}?<br/>Complete the bar model.',
  charAHasXFewerYThanCharB: '{0} has {1:number|numberFormat} fewer {2:string|lower} than {3}.',
  charAHasXMoreYThanCharB: '{0} has {1:number|numberFormat} more {2:string|lower} than {3}.',
  charAHasXSweetsCharBHasYSweetsMoreThanHowManySweetsDoTheyHaveTogether:
    '{nameA} has {sweetsA} sweet{{s}}.<br/>{nameB} has {diffSweets} more than {nameA}.<br/>How many sweets do they have altogether?',
  charAHasXY: '{0} has {1} {2:string|lower}.',
  charAGivesCharBXMoreY: '{0} gives {1} {2} more {3:string|lower}.',
  characterHasXPence: '{0} has {1:number|numberFormat}p.',
  characterHasXPounds: '{0} has £{1:number|numberFormat}.',
  characterHasXPoundsAndYPence: '{0} has £{1:number|numberFormat} and {2:number|numberFormat}p.',
  characterIsCountingBackwardsFromXToYSelectTheNumbersCharacterWillSay:
    '{character} is counting backwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Select the numbers {character} will say.',
  characterIsCountingForwardsFromXToYSelectTheNumbersCharacterWillSay:
    '{character} is counting forwards from {numberA:number|numberFormat} to {numberB:number|numberFormat}<br/>Select the numbers {character} will say.',
  characterIsCountingForwardsInXFromYSelectNumbers:
    '{0} is counting forwards in {1}s from {2}<br/>Select the numbers that {0} will say.',
  characterIsCountingBackwardsInXFromYSelectNumbers:
    '{0} is counting backwards in {1}s from {2}<br/>Select the numbers that {0} will say.',
  completeTheCalculation: 'Complete the calculation.',
  completeTheCalculations: 'Complete the calculations.',
  completeTheCalculationsToMatchArray: 'Complete the calculations to match the array.',
  completeTheNumberTrack: 'Complete the number track.',
  completeTheAddition: 'Complete the addition.',
  completeTheAdditions: 'Complete the additions.',
  completeTheBarModel: 'Complete the bar model.',
  completeTheDivision: 'Complete the division.',
  completeTheDivisionToMatchArray: 'Complete the division to match the array.',
  completeTheFactFamily: 'Complete the fact family.',
  completeTheFractionsShownInThePattern: 'Complete the fractions shown in the pattern.',
  completeTheMultiplication: 'Complete the multiplication.',
  completeTheNumberBonds: 'Complete the number bonds.',
  completeTheNumberBondToX: 'Complete the number bond to {0:number|numberFormat}',
  completeTheNumberLine: 'Complete the number line.',
  completeTheNumberLineToWorkOutEqX: 'Complete the number line to work out {0}',
  completeTheNumberBondsToXFindTwoDifferentWays:
    'Complete the number bonds to {0:number|numberFormat}<br/>Find two different ways.',
  completeTheNumberSentence: 'Complete the number sentence.',
  completeTheNumberSentences: 'Complete the number sentences.',
  completeTheNumberSentencesToMatchTheBarModel:
    'Complete the number sentences to match the bar model.',
  completeTheNumberSentencesToMatchThePicture:
    'Complete the number sentences to match the picture.',
  completeTheNumberSentenceToMatchTheArray: 'Complete the number sentence to match the array.',
  completeThePartWholeModel: 'Complete the part-whole model.',
  completeTheSentence: 'Complete the sentence.',
  completeTheSentences: 'Complete the sentences.',
  completeTheSentenceUseTheArrayToHelpYou: 'Complete the sentence.<br/>Use the array to help you.',
  completeTheStatement: 'Complete the statement.',
  completeTheSubtraction: 'Complete the subtraction.',
  completeTheSubtractions: 'Complete the subtractions.',
  completeTheTable: 'Complete the table.',
  completeTallyChart: 'Complete the tally chart.',
  createTwoDifferentMultiplicationsToMatchArray:
    'Create two different multiplications to match the array.',
  cubeIsXTallTowerIsYCubesWhatIsHeight:
    'A cube is {0} tall.<br/>A tower is made up of {1:number|numberFormat} cubes.<br/>What is the height of the tower?',
  drag1AppleForEachChildIntoTheBox: 'Drag 1 apple for each child into the box.',
  drag1MoreCounterOnToTheTenFrame: 'Drag 1 more counter on to the ten frame.',
  dragACardToCompare: 'Drag a card to compare.',
  dragACardToCompareTheAmounts: 'Drag a card to compare the amounts.',
  dragACardToCompareTheCalculations: 'Drag a card to compare the calculations.',
  dragACardToCompareTheCapacityOfTheContainers:
    'Drag a card to compare the capacity of the containers.',
  dragACardToCompareTheLengths: 'Drag a card to compare the lengths.',
  dragACardToCompareTheNumbers: 'Drag a card to compare the numbers.',
  dragACardToCompareTheObjects: 'Drag a card to compare the objects.',
  dragACardToCompareTheVolumeOfWaterInEachX:
    ' Drag a card to compare the volume of water in each {0:string|lower}.',
  dragACardToCompleteTheAddition: 'Drag a card to complete the addition.',
  dragACardToCompleteTheMultiplication: 'Drag a card to complete the multiplication.',
  dragACardToCompleteTheNumberSentence: 'Drag a card to complete the number sentence.',
  dragACardToCompleteTheSentence: 'Drag a card to complete the sentence.',
  dragACardToCompleteTheStatement: 'Drag a card to complete the statement.',
  dragACardToMatchTheNameToTheShape: 'Drag a card to match the name to the shape.',
  dragACardToMatchTheShapesToTheTurn: 'Drag a card to match the shapes to the turn.',
  dragACardToMatchThePictureToAStatement: 'Drag a card to match the picture to a statement.',
  dragArrowEstimateNumberIs: 'Drag the arrow to estimate where {0} is.',
  dragArrowToEstimateWhereXIsOnNumberLine:
    'Drag the arrow to estimate where {0:number|numberFormat} is on the number line.',
  dragCardsCompleteSentencesToMatchThePicture:
    'Drag the cards to complete the sentences to match the picture.',
  dragCardsOrderGlassesStartWithTheGreatestAmountVolume:
    'Drag the cards to order the glasses.<br/>Start with the greatest volume.',
  dragCardsOrderGlassesStartWithTheSmallestAmountVolume:
    'Drag the cards to order the glasses.<br/>Start with the smallest volume.',
  dragCardsOrderObjectsStartWithGreatestCapacity:
    'Drag the cards to order the objects.<br/>Start with the greatest capacity.',
  dragCardsOrderObjectsStartWithSmallestCapacity:
    'Drag the cards to order the objects.<br/>Start with the smallest capacity.',
  dragCardsCompleteTallyChart: 'Drag the cards to complete the tally chart.',
  dragCardsToCompleteTheCalculations: 'Drag the cards to complete the calculations.',
  dragCardsToCompleteTheMultiplication: 'Drag the cards to complete the multiplication.',
  dragCardsToCompleteTheNumberSentence: 'Drag the cards to complete the number sentence.',
  dragCardsToCompleteTheNumberTrack: 'Drag the cards to complete the number track.',
  dragCardsToFillInTheMissingNumbers: 'Drag cards to fill in the missing number{{s}}.',
  dragCardsToMatchTheNumbersToTheTallies: 'Drag the cards to match the numbers to the tallies.',
  dragCardsToMatchTheTalliesToTheNumbers: 'Drag the cards to match the tallies to the numbers.',
  dragCardsToMatchTheXObjectToTheYObject:
    'Drag the cards to match the {0:string|lower} to the {1:string|lower}.',
  dragCardsToSortThemIntoTheTable: 'Drag the cards to sort them into the table.',
  dragCirclesToCompletePictogram: 'Drag the circles to complete the pictogram.',
  dragCountersOnTheTenFrameToMatchNumberOfObject:
    'Drag counters on to the ten frame to match the number of {0:string|lower}.',
  dragCountersToMakeXEqualGroupsOfY:
    'Drag counters to make {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  dragCountersToShowXLessThanY: 'Drag counters to show {0:number|numberFormat} less than {1}',
  dragCountersToShowXMoreThanY: 'Drag counters to show {0:number|numberFormat} more than {1}',
  dragCountersToShow1LessThanNum: 'Drag counters to show 1 less than {0:number|numberFormat}',
  dragCountersToShowTheMissingBondInThePattern:
    'Drag counters to show the missing bond in the pattern.',
  dragNumCountersOnToTheTenFrame: 'Drag {0: string|lower} counter{{1:s}} on to the ten frame.',
  dragCardsToSortTheActivities: 'Drag the cards to sort the activities.',
  dragCountersToMakeX: 'Drag counters to make {0}',
  dragCountersToShowHowManyXHas: 'Drag counters to show how many {0} has.',
  dragTheCardToFillInTheMissingNumber: 'Drag the card to fill in the missing number.',
  dragTheCardsToCompleteTheSentence: 'Drag the cards to complete the sentence.',
  dragTheCardsToFillInTheMissingNumbers: 'Drag the cards to fill in the missing number{{s}}.',
  dragTheCardsToMakeA2DigitEvenNumber: 'Drag the cards to make a 2-digit <b>even</b> number.',
  dragTheCardsToMakeA2DigitOddNumber: 'Drag the cards to make a 2-digit <b>odd</b> number.',
  dragTheCardsToMatchTheCoinsToTheCorrectValue:
    'Drag the cards to match the coins to the correct value.',
  dragTheCardsToOrderTheHeights: 'Drag the cards to order the heights.',
  dragTheCardsToOrderTheLengths: 'Drag the cards to order the lengths.',
  dragTheCardsToOrderTheTemperature: 'Drag the cards to order the temperature.',
  dragTheCardsToSortTheFractions: 'Drag the cards to sort the fractions.',
  dragTheArrowTo1LessThanNum: 'Drag the arrow to 1 less than {numberA:number|numberFormat}',
  dragTheArrowTo1MoreThanNum: 'Drag the arrow to 1 more than {numberA:number|numberFormat}',
  dragTheArrowToNum: 'Drag the arrow to {numberA:number|numberFormat}',
  dragTheArrowToShowXCOnTheThermometer:
    'Drag the arrow to show {0:number|numberFormat} °C on the thermometer.',
  dragBase10ToShowXMoreThanY:
    'Drag the base 10 to show {0:number|numberFormat} more than {1:number|numberFormat}',
  dragBase10ToShowXLessThanY:
    'Drag the base 10 to show {0:number|numberFormat} less than {1:number|numberFormat}',
  dragBase10ToShowXMore:
    'Drag the base 10 to show {0:number|numberFormat} more than {1:number|numberFormat}',
  dragBase10ToShowXLess:
    'Drag the base 10 to show {0:number|numberFormat} less than {1:number|numberFormat}',
  dragTheCardsToMatchTheAmounts: 'Drag the cards to match the amounts.',
  dragCardCompleteSentence: 'Drag a card to complete the sentence.',
  dragCountersToAddXMore: 'Drag counters to add {0:number|numberFormat} more.',
  dragCountersToFillTheEmptyBox: 'Drag the counters to fill the empty box.',
  dragCountersToShowDoubleX: 'Drag counters to show double {0:number|numberFormat}',
  dragCountersToShowX: 'Drag counters to show {0}',
  dragNumObjectIntoTheBox: 'Drag {0:number|numberFormat} {1:string|lower} into the box.',
  dragNumXForEachYIntoTheBox:
    'Drag {0:number|numberFormat} {1:string|lower} for each {2:string|lower} into the box.',
  dragTheArrowToNumberAsWord: 'Drag the arrow to {0: string|lower}.',
  dragTheArrowToOneLessThanNumberAsWord: 'Drag the arrow to one less than {0: string|lower}.',
  dragTheArrowTo1LessThanNumberAsNumber: 'Drag the arrow to 1 less than {0: number}',
  dragTheArrowToOneMoreThanNumberAsWord: 'Drag the arrow to one more than {0: string|lower}.',
  dragTheArrowTo1MoreThanNumberAsNumber: 'Drag the arrow to 1 more than {0: number}',
  dragTheArrowToEstimateWhereNumBelongsOnTheNumberLine:
    'Drag the arrow to estimate where {0:number|numberFormat} belongs on the number line.',
  dragTheArrowToShowWhereNumBelongsOnTheNumberLine:
    'Drag the arrow to show where {0:number|numberFormat} belongs on the number line.',
  dragTheArrowLessThanNumberAsWord:
    'Drag the arrow to a number that is less than {0: string|lower}.',
  dragTheArrowLessThanNumberAsNumber: 'Drag the arrow to a number that is less than {0: number}',
  dragTheArrowGreaterThanNumberAsWord:
    'Drag the arrow to a number that is greater than {0: string|lower}.',
  dragTheArrowGreaterThanNumberAsNumber:
    'Drag the arrow to a number that is greater than {0: number}',
  dragTheArrowToShowWhereTheWaterReaches: 'Drag the arrow to show where the water reaches.',
  dragTheBase10ToMakeTheNumberX: 'Drag the base 10 to make the number {0:number|numberFormat}',
  dragTheBase10ToMakeX: 'Drag the base 10 to make {0}',
  dragTheCardsToCompleteTheNumberBondToX:
    'Drag the cards to complete the number bond to {0:number|numberFormat}',
  dragTheCardsToCompleteTheMultiplication: 'Drag the cards to complete the multiplication.',
  dragTheCardsToCompleteTheNumberSentence: 'Drag the cards to complete the number sentence.',
  dragTheCardsToCompleteTheNumberSentences: 'Drag the cards to complete the number sentences.',
  dragTheCardsToCompleteTheSentences: 'Drag the cards to complete the sentences.',
  dragTheCardsToDescribeTheMovement: 'Drag the cards to describe the movement.',
  dragTheCardsToLabelTheGroups: 'Drag the cards to label the groups.',
  dragTheCardsToMatchTheAdditionsToMultiplications:
    'Drag the cards to match the additions to the multiplications.',
  dragTheCardsToMatchTheNumeralsToTheWords: 'Drag the cards to match the numerals to the words.',
  dragTheCardsToMatchTheBaseTenToTheWords: 'Drag the cards to match the base 10 to the numbers.',
  dragTheCardsToMatchTheCorrectAnswersToTheCalculations:
    'Drag the cards to match the correct answers to the calculations.',
  dragTheCardsToMatchTheCorrectValueToTheCoins:
    'Drag the cards to match the correct value to the coins.',
  dragTheCardsToMatchTheCorrectValueToTheCounters:
    'Drag the cards to match the correct value to the counters.',
  dragTheCardsToMatchTheCorrectValueToTheNotes:
    'Drag the cards to match the correct value to the notes.',
  dragTheCardsToMatchTheDoublesToAdditions: 'Drag the cards to match the doubles to the additions.',
  dragTheCardsToMatchTheNameToTheShape: 'Drag the cards to match the name to the shape.',
  dragTheCardsToMatchThePicturesToStatements: 'Drag the cards to match the pictures to statements.',
  dragTheCardsToMatchThePicturesToTheNumbers:
    'Drag the cards to match the pictures to the numbers.',
  dragTheCardsToMatchTheShapesToTheTurns: 'Drag the cards to match the shapes to the turns.',
  dragTheCardsToShowIfGroupsAreEqualOrUnequal:
    'Drag the cards to show if the groups are equal or unequal.',
  dragTheCardsToMatchTheWordsToThePictures: 'Drag the cards to match the words to the pictures.',
  dragTheCardsToPutThemInOrder: 'Drag the cards to put them in order.',
  dragTheCardsToSortTheFruitIntoGroups: 'Drag the cards to sort the fruit into groups.',
  dragTheCardsToSortTheNumbers: 'Drag the cards to sort the numbers.',
  dragTheCardsToSortTheObjectsIntoGroups: 'Drag the cards to sort the objects into groups.',
  dragTheCardsToOrderTheNumbers: 'Drag the cards to order the numbers.',
  dragTheCardsToSortTheObjectsIntoTwoGroups: 'Drag the cards to sort the objects into two groups.',
  dragTheCardsToSortTheShapes: 'Drag the cards to sort the shapes.',
  dragTheCardsToSortTheShapesInOrderOfTheNumberOfSidesStartWithTheFewestNumberOfSides:
    'Drag the cards to sort the shapes in order of the number of sides.<br/>Start with the fewest number of sides.',
  dragTheCardsToSortTheShapesInOrderOfTheNumberOfSidesStartWithTheMostNumberOfSides:
    'Drag the cards to sort the shapes in order of the number of sides.<br/>Start with the most number of sides.',
  dragTheCardsToSortTheShapesInOrderOfTheNumberOfVerticesStartWithTheFewestNumberOfVertices:
    'Drag the cards to sort the shapes in order of the number of vertices.<br/>Start with the fewest number of vertices.',
  dragTheCardsToSortTheShapesInOrderOfTheNumberOfVerticesStartWithTheMostNumberOfVertices:
    'Drag the cards to sort the shapes in order of the number of vertices.<br/>Start with the most number of vertices.',
  dragTheCountersToShareThemIntoXEqualGroups:
    'Drag the counters to share them into {0:number|numberFormat} equal groups.',
  dragTheCircleAboveTheShape: 'Drag the circle <b>above</b> the {0:string|lower}.',
  dragTheCircleBelowTheShape: 'Drag the circle <b>below</b> the {0:string|lower}.',
  dragTheCircleBetweenTheShapeAAndTheShapeB:
    'Drag the circle <b>between</b> the {0:string|lower} and the {1:string|lower}.',
  dragTheCircleDyTheShape: 'Drag the circle <b>{0:string|lower}</b> the {1:string|lower}.',
  dragTheCircleToTheDxTheShape:
    'Drag the circle to the <b>{0:string|lower}</b> of the {1:string|lower}.',
  dragTheHandsOfClockToShowX: 'Drag the hands of the clock to show {0}',
  dragTheHourHandOfClockToShowX: 'Drag the hour hand of the clock to show {0}.',
  dragTheItemXSpacesDy:
    'Drag the {0:string|lower} {1:number|numberFormat} space{{s}} <b>{2:string|lower}</b>.',
  dragTheItemXSpacesToTheDx:
    'Drag the {0:string|lower} {1:number|numberFormat} space{{s}} to the <b>{2:string|lower}</b>.',
  dragTheItemYSpacesDyAndXSpacesDx:
    'Drag the {item:string|lower} {dy:number|numberFormat} space{{s}} <b>{yDirection:string|lower}</b> and {dx:number|numberFormat} space{{s}} <b>{xDirection:string|lower}</b>.',
  dragTheCoinsToMakeNumP: 'Drag the coins to make {0:number|numberFormat}p.',
  dragTheCoinsToMakeNumPounds: 'Drag the coins to make £{0:number|numberFormat}',
  dragTheMissingFractionIntoThePattern: 'Drag the missing fraction into the pattern.',
  dragTheMoneySoEachBoxHasTheSameAmount: 'Drag the money so each box has the same amount.',
  dragTheMoneyToMakeNumP: 'Drag the money to make {0:number|numberFormat}p.',
  dragTheMoneyToMakeNumPounds: 'Drag the money to make £{0:number|numberFormat}',
  dragTheNextTwoShapesToCompleteThePattern: 'Drag the next two shapes to complete the pattern.',
  dragTheNextTwoShapesToContinueThePattern: 'Drag the next two shapes to continue the pattern.',
  dragTheNumbersInOrder: 'Drag the numbers in order.',
  dragTheObjectToShareThemIntoXEqualGroups:
    'Drag the {0:string|lower} to share them into {1:number|numberFormat} equal groups.',
  dragThePencilToDrawLineThatIsXCmLong:
    'Drag the pencil to draw a line that is {0:number|numberFormat} cm long.',
  dragTheScaleToShowTheMassOfXG: 'Drag the scale to show the mass of {0:number|numberFormat} g.',
  dragTheScaleToShowTheMassOfXKg: 'Drag the scale to show the mass of {0:number|numberFormat} kg.',
  dragTheScaleToShowTheTotalMass: 'Drag the scale to show the total mass.',
  dragTheShapeSoItIsAboveTheShape:
    'Drag the {0:string|lower} so it is <b>above</b> the {1:string|lower}.',
  dragTheShapeSoItIsBelowTheShape:
    'Drag the {0:string|lower} so it is <b>below</b> the {1:string|lower}.',
  dragTheShapeToTheLeftOfTheShape:
    'Drag the {0:string|lower} to the <b>left</b> of the {1:string|lower}.',
  dragTheShapeToTheRightOfTheShape:
    'Drag the {0:string|lower} to the <b>right</b> of the {1:string|lower}.',
  dragTheShapesToCompleteThePattern: 'Drag the shapes to complete the pattern.',
  dragTheShapesToCompleteThePictogram: 'Drag the shapes to complete the pictogram.',
  dragToMatchTheNumberBondsToTheTenFrames: 'Drag to match the number bonds to the ten frames.',
  dragToMatchTheSubtractionsToTheTenFrames: 'Drag to match the subtractions to the ten frames.',
  dragToPutTheNumbersInOrder: 'Drag to put the numbers in order.',
  dragToShareAllTheObjectsEquallyBetweenTrays:
    'Drag to share all of the {0:string|lower} equally between the trays.',
  dragToShareNumCountersEquallyBetweenNumBoxes:
    'Drag to share {0:number|numberFormat} counters equally between the {1:string|lower} boxes.',
  dragTwoCardsToMakeAdditionCorrect: 'Drag two cards to make the addition correct.',
  estimateTheNumberArrowPointingToSelectAnswer:
    'Estimate the number the arrow is pointing to.<br/>Select your answer.',
  fillInTheMissingNumber: 'Fill in the missing number.',
  fillInTheMissingNumbers: 'Fill in the missing numbers.',
  findFracOfX: 'Find {0} of {1}',
  findXofYUseTheArrayToHelp: 'Find {0} of {1:number|numberFormat}<br/>Use the array to help you.',
  firstThereWereXBirdsYBirdsFlewAway:
    'First there were {0:number|numberFormat} birds.<br/>Then {1:number|numberFormat} of the birds flew away.',
  firstThereWereXCarsInCarParkYCarsDriveAway:
    'First there were {0:number|numberFormat} cars in a car park.<br/>Then {1:number|numberFormat} of the cars drive away.',
  firstThereWereXFoodObjectsOnAPlateYFoodObjectsWereEaten:
    'First there were {0:number|numberFormat} {1:string|lower} on a plate.<br/>Then {2:number|numberFormat} of the {1:string|lower} were eaten.',
  groupN: 'Group {numberA:number|numberFormat}',
  hereIsAQuarterOfAShapeSelectWhatTheWholeCouldLookLike:
    'Here is a quarter of a shape.<br/>Select what the whole could look like.',
  howManyChildrenHaveAXorAY: 'How many children have a {0:string|lower} or a {1:string|lower}?',
  howManyFewerChildrenHaveAXThanAY:
    'How many fewer children have a {0:string|lower} than a {1:string|lower}?',
  howManyFewerObjectsDoesCharAHaveThanCharB:
    'How many <b>fewer</b> {0:string|lower} does {1} have than {2}?',
  howManyMarblesAltogether: 'How many marbles altogether?',
  howManyMoreChildrenHaveAXThanAY:
    'How many more children have a {0:string|lower} than a {1:string|lower}?',
  howManyMinutesAreThereInX: 'How many minutes are there in {0}?',
  howManyChildrenHaveAX: 'How many children have a {0:string|lower}?',
  howManyCirclesAreThere: 'How many circles are there?',
  howManyColumns: 'How many <b>columns</b> are there?',
  howManyCounters: 'How many counters?',
  howManyEdgesDoesTheShapeHave: 'How many edges does the shape have?',
  howManyFacesDoesTheShapeHave: 'How many faces does the shape have?',
  howManyFewerXObjectThanYObjectAreThere:
    'How many <b>fewer</b> {0:string|lower} than {1:string|lower} are there?',
  howManyMoreObjectsDoesCharAHaveThanCharB:
    'How many <b>more</b> {0:string|lower} does {1} have than {2}?',
  howManyMoreToMakeX: 'How many more to make {0:number|numberFormat}?',
  howManyMoreXObjectThanYObjectAreThere:
    'How many <b>more</b> {0:string|lower} than {1:string|lower} are there?',
  howManyObjectsAreThere: 'How many {0:string|lower} are there?',
  howManyObjectsAreInTheCup: 'How many {0:string|lower} are there in the cup?',
  howManyRows: 'How many <b>rows</b> are there?',
  howManySides: 'How many sides?',
  howManyVertices: 'How many vertices?',
  howManyVerticesDoesTheShapeHave: 'How many vertices does the shape have?',
  howManyXAltogether: 'How many {0:string|lower} altogether?',
  howManyXAreThere: 'How many {0:string|lower} are there?',
  howManyXAreThereSelectYourAnswer: 'How many {0} are there?<br/>Select your answer.',
  howManyXCoinsAreThere: 'How many {0:string|lower} coins are there?',
  howManyXDoesCharAHave: 'How many {0:string|lower} does {1} have?',
  howManyXDoesCharAHaveNow: 'How many {0:string|lower} does {1} have now?',
  howManyXObjectAndYObjectAltogether:
    'How many {0:string|lower} and {1:string|lower} are there <b>altogether?</b>',
  howManyXObjectInTotal: 'How many {0:string|lower} are there in <b>total?</b>',
  howManyXNotesAreThere: 'How many {0:string|lower} notes are there?',
  howMuchDoesCharHaveLeftSelectYourAnswer: 'How much does {0} have left?<br/>Select your answer.',
  howMuchMilkIsThereAltogether: 'How much milk is there altogether?',
  howMuchMoneyIsThere: 'How much money is there?',
  howMuchMoreWaterIsInJugXThanJugY: 'How much <b>more</b> water is in Jug {0} than in Jug {1}?',
  howMuchWaterIsInTheBarrel: 'How much water is in the barrel?',
  howMuchWaterIsInTheBucket: 'How much water is in the bucket?',
  howMuchWaterIsInTheJug: 'How much water is in the jug?',
  measureTheLengthOfTheXObjectDragTheXObjectToHelpYou:
    'Measure the length of the {0:string|lower}.<br/>Drag the {0:string|lower} to help you.',
  nameAHasXObjectNameBHasYHowManyFewer:
    '{nameA} has {count1: number|numberFormat} {object: string|lower}.<br/>{nameB} has {count2: number|numberFormat} {object: string|lower}.<br/>How many <b>fewer</b> {object: string|lower} does {nameFewer} have than {nameMore}?',
  nameAHasXObjectNameBHasYHowManyMore:
    '{nameA} has {count1: number|numberFormat} {object: string|lower}.<br/>{nameB} has {count2: number|numberFormat} {object: string|lower}.<br/>How many <b>more</b> {object: string|lower} does {nameMore} have than {nameFewer}?',

  nameAHasNCountersNameBHasFewerCountersThanNameADragTheCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has fewer counters than {nameA}.<br/>Drag the counters to show how many counters {nameB} <b>could</b> have.',
  nameAHasNCountersNameBHasMoreCountersThanNameADragTheCounters:
    '{nameA} has {numA:number|numberFormat} counter{{s}}.<br/>{nameB} has more counters than {nameA}.<br/>Drag the counters to show how many counters {nameB} <b>could</b> have.',
  nameHasSomeTenPCoinsPronounXPenceInTotalHowManyCoinsDoesNameHave:
    '{name} has some 10p coins.<br/>{pronoun} has {pence:number|numberFormat}p in total.<br/>How many coins does {name} have?',
  nameHasSomeTenPCoinsPronounXPoundInTotalHowManyCoinsDoesNameHave:
    '{name} has some 10p coins.<br/>{pronoun} has £{pound:number|numberFormat} in total.<br/>How many coins does {name} have?',
  nameHasXObjectsPronounSharesBetweenYBagsHowManyInEachBag:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally into {containerCount:number|numberFormat} bags.<br/>How many {object: string|lower} are there in each bag?',
  nameHasXObjectsPronounSharesBetweenYBoxesHowManyInEachBoxes:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally into {containerCount:number|numberFormat} boxes.<br/>How many {object: string|lower} are there in each box?',
  nameHasXObjectsPronounSharesBetweenYPotsHowManyInEachPot:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally into {containerCount:number|numberFormat} pots.<br/>How many {object: string|lower} are there in each pot?',
  nameHasXObjectsPronounSharesBetweenYPlatesHowManyOnEachPlate:
    '{name} has {total:number|numberFormat} {object: string|lower}.<br/>{pronoun} shares them equally onto {containerCount:number|numberFormat} plates.<br/>How many {object: string|lower} are there on each plate?',
  nameIsCountingInXFillInTheMissingNumbers:
    '{0} is counting in {1}.<br/>Fill in the missing numbers.',
  objectComeInBagsOfYNameHasObjectsHowMany:
    '{object} come in bags of {perContainer:number|numberFormat}<br/>{name} has {total:number|numberFormat} {object: string|lower}.<br/>How many bags does {name} have?',
  objectComeInPotsOfYNameHasObjectsHowMany:
    '{object} come in pots of {perContainer:number|numberFormat}<br/>{name} has {total:number|numberFormat} {object: string|lower}.<br/>How many pots does {name} have?',
  objectComeOnPlatesOfYNameHasObjectsHowMany:
    '{object} come on plates of {perContainer:number|numberFormat}<br/>{name} has {total:number|numberFormat} {object: string|lower}.<br/>How many plates does {name} have?',
  oneThirdOfTotalIsXNumberWhatIsTheNumber:
    'One third of a number is {0:number|numberFormat}<br/>What is the number?',
  selectAllTheEvenNumbers: 'Select all the even numbers.',
  selectAllTheOddNumbers: 'Select all the odd numbers.',
  selectAllTheShape: 'Select all the {0:string|lower}.',
  selectAllTheNumberBondsToNum: 'Select <b>all</b> the number bonds to {0:number|numberFormat}',
  selectCountersThatHaveTotalValueOfX:
    'Select counters that have a total value of {0:number|numberFormat}',
  selectDayOnWhichFewestXSold:
    'Select the day on which the <b>fewest</b> {0:string|lower} were sold.',
  selectDayOnWhichMostXSold: 'Select the day on which the <b>most</b> {0:string|lower} were sold.',
  selectHowManyChildrenHaveNumPets:
    'Select how many children have {0:number|numberFormat} pet{{s}}.',
  selectHowManyFewerCarsAreXThanY:
    'Select how many <b>fewer</b> cars are {0:string|lower} than {1:string|lower}.',
  selectHowManyMoreCarsAreXThanY:
    'Select how many <b>more</b> cars are {0:string|lower} than {1:string|lower}.',
  selectNotesAndCoinsToMakeXPoundsAndYPence:
    'Select the notes and coins to make £{0:number|numberFormat} and {1:number|numberFormat}p.',
  selectTheAdditionsThatAreEqualToNum:
    'Select the additions that are equal to {0:number|numberFormat}',
  selectTheAdditionsThatMatchTheBarModel: 'Select the additions that match the bar model.',
  selectTheAdditionsThatMatchTheCounters: 'Select the additions that match the counters.',
  selectTheAdditionThatMatchTheBarModel: 'Select the addition that matches the bar model.',
  selectTheAdditionThatMatchTheCounters: 'Select the addition that matches the counters.',
  selectTheBase10ThatShowsX: 'Select the base 10 that shows {0}',
  selectTheBoxesThatShowX: 'Select the box{{0:es}} that show{{0:s|}} {1:number|numberFormat}',
  selectTheCoinsToMakeXP: 'Select the coins to make {0:number|numberFormat}p.',
  selectTheCoinsToMakePoundX: 'Select the coins to make £{0:number|numberFormat}',
  selectTheContainerWithTheGreaterCapacity: 'Select the container with the greater capacity.',
  selectTheContainerWithTheGreatestCapacity: 'Select the container with the greatest capacity.',
  selectTheContainerWithTheSmallerCapacity: 'Select the container with the smaller capacity.',
  selectTheContainerWithTheSmallestCapacity: 'Select the container with the smallest capacity.',
  selectTheCorrectNumberSentence: 'Select the correct number sentence.',
  selectTheCorrectNumberSentences: 'Select the correct number sentences.',
  selectTheCountersToMakeX: 'Select the counters to make {0:number|numberFormat}',
  selectTheDiagramsThatShowAHalf: 'Select the diagram{{s}} that show{{s|}} a half.',
  selectTheDiagramsThatShowAThird: 'Select the diagram{{s}} that show{{s|}} a third.',
  selectTheDiagramsThatShowAQuarter: 'Select the diagram{{s}} that show{{s|}} a quarter.',
  selectTheDiagramsThatShow3Quarters: 'Select the diagrams that show <frac n="3" d="4" />',
  selectTheDiceToMakeX: 'Select the dice to make {0:number|numberFormat}',
  selectTheGreatestAmountOfMoney: 'Select the greatest amount of money.',
  selectTheGreatestNumber: 'Select the greatest number.',
  selectTheGroupWithFewerObject: 'Select the group with fewer {0:string|lower}.',
  selectTheGroupWithMoreObject: 'Select the group with more {0:string|lower}.',
  selectTheGroupThatHasAXShaded: 'Select the group that has a {0:string|lower} shaded.',
  selectTheGroupThatMatchesTheNumberOfChildren:
    'Select the group that matches the number of children.',
  selectTheGroupThatShowsX: 'Select the group that shows {0:string|lower}.',
  selectTheGroupsThatShowsX: 'Select the groups that shows {0:string|lower}.',
  selectTheGroupsThatHaveAXShaded: 'Select the groups that have a {0:string|lower} shaded.',
  selectTheHeavierObject: 'Select the heavier object.',
  selectTheLighterObject: 'Select the lighter object.',
  selectTheLongerObject: 'Select the longer object.',
  selectTheLongerXObject: 'Select the longer {0:string|lower}.',
  selectTheLongestPencil: 'Select the longest pencil.',
  selectTheLongestRibbon: 'Select the longest ribbon.',
  selectTheLongestTrain: 'Select the longest train.',
  selectTheMissingNumberBondToX: 'Select the <b>missing</b> number bond to {0:number|numberFormat}',
  selectTheMonthsThatHaveExactlyXDays:
    'Select the months that have exactly {0:number|numberFormat} days.',
  selectTheNameOfTheShapeDescribedInTheLabel:
    'Select the name of the shape described in the label.',
  selectThePopularPetLeast: 'Select the least popular pet.',
  selectThePopularPetMost: 'Select the most popular pet.',
  selectTheNumberBondsToNum: 'Select the number bonds to {0:number|numberFormat}',
  selectTheNumberOfEdges: 'Select the number of edges.',
  selectTheNumberSentenceThatMatchesPicture: 'Select the number sentence that matches the picture.',
  selectTheNumberSidedShapes: 'Select the {0:number|numberFormat}-sided shapes.',
  selectTheOrdinalShape: 'Select the {0:string|lower} shape.',
  selectTheParts: 'Select the parts.',
  selectThePictureThatShowsDoubling: 'Select the picture that shows <b>doubling</b>.',
  selectThePictureThatShowsEqualGroups: 'Select the picture that shows <b>equal</b> groups.',
  selectThePictureThatShowsHalving: 'Select the picture that shows <b>halving</b>.',
  selectThePictureThatShowsUnequalGroups: 'Select the picture that shows <b>unequal</b> groups.',
  selectThePictureThatShowsPoundXAndYPence:
    'Select the picture that shows £{0:number|numberFormat} and {1:number|numberFormat}p.',
  selectThePicturesThatDoNotShowPoundXAndYPence:
    'Select the pictures that <b>do not</b> show £{0:number|numberFormat} and {1:number|numberFormat}p.',
  selectThePicturesThatHaveHalfShaded: 'Select the pictures that have a half shaded.',
  selectThePicturesThatHaveQuarterShaded: 'Select the pictures that have a quarter shaded.',
  selectThePicturesThatHaveThirdShaded: 'Select the pictures that have a third shaded.',
  selectThePicturesThatHaveThreeQuartersShaded:
    'Select the pictures that have three-quarters shaded.',
  selectThePicturesThatShowDoubling: 'Select the pictures that show <b>doubling</b>.',
  selectThePicturesThatShowEqualGroups: 'Select the pictures that show <b>equal</b> groups.',
  selectThePicturesThatShowHalving: 'Select the pictures that show <b>halving</b>.',
  selectThePicturesThatShowTheSameAmount: 'Select the pictures that show the same amount.',
  selectThePicturesThatShowUnequalGroups: 'Select the pictures that show <b>unequal</b> groups.',
  selectThePictureThatShowsXEqualGroupsOfY:
    'Select the picture that shows {0:number|numberFormat} equal groups of {1:number|numberFormat}',
  selectTheSetsOfCountersThatCanBeSharedIntoTwoEqualGroups:
    'Select the sets of counters that can be shared into 2 equal groups.',
  selectTheShapeThatHasOneEqualPartShaded: 'Select the shape that has 1 equal part shaded.',
  selectTheShapeThatIsCutIntoQuarters: 'Select the {0} that is cut into quarters.',
  selectTheShapesThatAreCutIntoQuarters: 'Select the {0} that are cut into quarters.',
  selectTheShapeThatIsTheOddOneOut: 'Select the shape that is the odd one out.',
  selectTheShapeWithACurvedSurface: 'Select the shape with a curved surface.',
  selectTheShapeWithNumEdges: 'Select the shape with {0:number|numberFormat} edges.',
  selectTheShapeWithNumVertices: 'Select the shape with {0:number|numberFormat} vertices.',
  selectTheShapesWithACorrectVerticalLineOfSymmetry:
    'Select the shapes with a correct vertical line of symmetry.',
  selectTheShapesWithAVerticalLineOfSymmetry: 'Select the shapes with a vertical line of symmetry.',
  selectTheShapesWithNumberVertices: 'Select the shapes with {0:number|numberFormat} vertices.',
  selectTheShapesThatRoll: 'Select the shapes that roll.',
  selectTheShapesThatHaveOneEqualPartShaded: 'Select the shapes that have 1 equal part shaded.',
  selectTheShapesWithACurvedSurface: 'Select the shapes with a curved surface.',
  selectTheShortestFlower: 'Select the shortest flower.',
  selectTheShorterObject: 'Select the shorter object.',
  selectTheShorterXObject: 'Select the shorter {0:string|lower}.',
  selectTheShortestPencil: 'Select the shortest pencil.',
  selectTheShortestRibbon: 'Select the shortest ribbon.',
  selectTheShortestTowerOfCubes: 'Select the shortest tower of cubes.',
  selectTheShortestTrain: 'Select the shortest train.',
  selectTheShortestTree: 'Select the shortest tree.',
  selectTheSmallestAmountOfMoney: 'Select the smallest amount of money.',
  selectTheSmallestNumber: 'Select the smallest number.',
  selectTheStatementsThatAreTrue: 'Select the statements that are true.',
  selectTheStatementThatIsTrue: 'Select the statement that is true.',
  selectTheTallerObject: 'Select the taller object.',
  selectTheTallerXObject: 'Select the taller {0:string|lower}.',
  selectTheTallestFlower: 'Select the tallest flower.',
  selectTheTallestTowerOfCubes: 'Select the tallest tower of cubes.',
  selectTheTallestTree: 'Select the tallest tree.',
  selectTheTenFrameThatMatchesThePicture: 'Select the ten frame that matches the picture.',
  selectTheTimeShown: 'Select the time shown.',
  selectTheUnitFractions: 'Select the unit fractions.',
  selectTheUnitFractionThatMatchesThePicture: 'Select the unit fraction that matches the picture.',
  selectTheX: 'Select the {0:string|lower}.',
  selectTheXMeasurementOfYObject: 'Select the {0:string|lower} {1:string|lower}.',
  selectTheXObjectThatIsLeastCommon: 'Select the {0:string|lower} that is least common.',
  selectTheXObjectThatIsMostCommon: 'Select the {0:string|lower} that is most common.',
  selectTheXWeightObject: 'Select the {0:string|lower} object.',
  selectTheXThatAreCutInHalf: 'Select the {0:string|lower} that are cut in half.',
  selectTheXThatIsCutInHalf: 'Select the {0:string|lower} that is cut in half.',
  selectTheXThatAreCutIntoYEqualParts:
    'Select the {0:string|lower} that are cut into {1:number|numberFormat} equal parts.',
  selectTheXThatIsCutIntoYEqualParts:
    'Select the {0:string|lower} that is cut into {1:number|numberFormat} equal parts.',
  selectWhatTheShapeWillLookLikeAfterAXTurn:
    'Select what the shape will look like after a <b>{0:string|lower}</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAXTurnAntiClockwise:
    'Select what the shape will look like after a <b>{0:string|lower}</b> turn anticlockwise.',
  selectWhatTheShapeWillLookLikeAfterAXTurnClockwise:
    'Select what the shape will look like after a <b>{0:string|lower}</b> turn clockwise.',
  selectWhatTheShapeWillLookLikeAfterAFullTurn:
    'Select what the shape will look like after a <b>full</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAHalfTurn:
    'Select what the shape will look like after a <b>half</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAQuarterTurn:
    'Select what the shape will look like after a <b>quarter</b> turn.',
  selectWhatTheShapeWillLookLikeAfterAThreeQuarterTurn:
    'Select what the shape will look like after a <b>three-quarter</b> turn.',
  selectYourAnswer: 'Select your answer.',
  startWithTheColdest: 'Start with the coldest.',
  startWithTheLongest: 'Start with the longest.',
  startWithTheShortest: 'Start with the shortest.',
  startWithTheTallest: 'Start with the tallest.',
  startWithTheWarmest: 'Start with the warmest.',
  tapDotsToCreateAClosedShapeWithNumVertices:
    'Tap dots to create a closed shape with {0:number|numberFormat} vertices.',
  tapDotsToCreateAShapeWithAVerticalLineOfSymmetry:
    'Tap dots to create a shape with a vertical line of symmetry.',
  tapDotsToCreateXByMarkingTheVertices:
    'Tap the dots to create {0:string|lower} by marking the vertices.',
  tapFourDotsToSplitTheShapeInQuarters: 'Tap 4 dots to split the shape into quarters.',
  tapShapeToShadeInAQuarterOfTheShape: 'Tap to shade in a quarter of the shape.',
  tapToColourXCircles: 'Tap to colour {0:number|numberFormat} circle{{s}}.',
  tapToColourXSquares: 'Tap to colour {0:number|numberFormat} square{{s}}.',
  tapToCompleteTheBlockDiagram: 'Tap to complete the block diagram.',
  tapToShadeAThirdOfTheGrid: 'Tap to shade a third of the grid.',
  tapToShadeAQuarterOfTheCircles: 'Tap to shade a quarter of the circles.',
  tapToShadeHalfOfTheCircles: 'Tap to shade half of the circles.',
  tapToShadeInXOfTheShape: 'Tap to shade in {0} of the shape.',
  tapToShadeThreeQuartersOfGrid: 'Tap to shade three-quarters of the grid.',
  tapToShowTheBirdsThatFlewAway: 'Tap to show the birds that flew away.',
  tapToShowCarsThatDriveAway: 'Tap to show the cars that drive away.',
  tapToShowTheXFoodObjectsThatWereEaten: 'Tap to show the {0:string|lower} that were eaten.',
  tapTheCirclesToMakeArrayOfXRowsAndYColumns:
    'Tap the circles to make an array with {0:number|numberFormat} rows and {1:number|numberFormat} columns.',
  tapTheCirclesToMakeArrayThatShowsXMultY: `Tap the circles to make an array that shows {0:number|numberFormat} ${MULT} {1:number|numberFormat}`,
  tapTheDotsToCreateAShapeWithNumVertices:
    'Tap dots to create a shape with {0:number|numberFormat} vertices.',
  tapTheDotsToCreateXByMarkingTheVertices:
    'Tap the dots to create {0:string|lower} by marking the vertices.',
  tapToShadeAQuarterOfTheGrid: 'Tap to shade a quarter of the grid.',
  tapTwoDotsToSplitTheShapeInHalf: 'Tap 2 dots to split the shape in half.',
  tapWhereTheFinalVertexOfTheXShouldBe:
    'Tap where the final vertex of the {0:string|lower} should be.',
  tapXLessThanY: 'Tap {0:number|numberFormat} less than {1}',
  tapXMoreThanY: 'Tap {0:number|numberFormat} more than {1}',
  theCapacityOfOneJugIsXCupsOfSandSelectTheCapacityOfTwoJugs:
    'The capacity of 1 jug is {0:number|numberFormat} cups of sand.<br/>Select the capacity of 2 jugs.',
  theDaysAreInOrderSelectTheMissingDayOfTheWeek:
    'The days are in order.<br/>Select the missing day of the week.',
  theDiagramShowsHalfAShapeAndALineOfSymmetryTapTheGridToCompleteTheShape:
    'The diagram shows half a shape and a line of symmetry.<br/>Tap the grid to complete the shape.',
  theMassOfTheXObjectIsYAmountThanZCubesSelectThePictureThatShowsThis:
    'The mass of the {0:string|lower} is {1:string|lower} {2:number|numberFormat} cube{{s}}.<br/>Select the picture that shows this.',
  theMassOfXIsYWhatIsTheMassOfZ:
    'The mass of {0:string|lower} is {1:string|lower}.<br/>What is the mass of {2:string|lower}?',
  theNumbersAreAllInXTimesTableSelectNumbersAlsoInYTimesTable:
    'The numbers are all in the {0:number|numberFormat} times-table.<br/>Select the numbers that are also in the {1:number|numberFormat} times-table.',
  thePictureShowsAMultiplication: 'The picture shows a multiplication.',
  thereAreXObjectsAltogether: 'There are {0:number|numberFormat} {1:string|lower} altogether.',
  thereAreXBirds: 'There are {0:number|numberFormat} birds.',
  thereAreXCarsInACarPark: 'There are {0:number|numberFormat} cars in a car park.',
  thereAreXCookiesOnAPlate: 'There are {0:number|numberFormat} cookies on a plate.',
  theTimeIsNumOClockDragTheHandsOfTheClockToShowTimeInNumHours:
    "The time is {0} o'clock.<br/>Drag the hands of the clock to show what time it will be in {1:number|numberFormat} hour{{s}}.",
  theTimeIsNumOClockHowManyHoursWillItBeUntilHourIsPointingAtNum:
    "The time is {0} o'clock.<br/>How many hours will it be until the hour hand is pointing at the {1}?",
  theTimeIsNumOClockHowManyHoursWillItBeUntilHourIsPointingAtNumAgain:
    "The time is {0} o'clock.<br/>How many hours will it be until the hour hand is pointing at the {0} again?",
  theWaterInTheGlassesFillsTheBottle: 'The water in the glasses fills the bottle.',
  theXContainerIsYAmountDragTheArrowToShowHowMuchJuiceCouldBeInTheXContainer:
    'The {0:string|lower} is {1:string|lower}.<br/>Drag the arrow to show how much juice could be in the {0:string|lower}.',
  theXObjectIsYWeightThanTheZObjectSelectThePicture:
    'The {0:string|lower} is {1:string|lower} than the {2:string|lower}.<br/>Select the picture that shows this.',
  thisIsTheWhole: 'This is the whole.',
  threeChildrenRunARaceSelectWhichChildComesFirst:
    'Three children run a race.<br/>Select which child comes first.',
  towerIsYCubesTowerIsXTallWhatIsHeightOfCube:
    'A tower is made of {0:number|numberFormat} cubes.<br/>The tower is {1} tall.<br/>What is the height of each cube?',
  usePartWholeModelToCompleteSentence: 'Use the part-whole model to complete the sentence.',
  useTheBarModelToHelp: 'Use the bar model to help.',
  useTheTenFramesToHelpYou: 'Use the ten frames to help you.',
  whatFractionIsShaded: 'What fraction is shaded?',
  whatIsFracOfX: 'What is {0} of {1:number|numberFormat}?',
  whatIsTheDifferenceInHeight: 'What is the difference in height?',
  whatIsTheDifferenceInLength: 'What is the difference in length?',
  whatIsTheHeightOfTheChair:
    'The chair is measured using metre rulers.<br/>What is the height of the chair?',
  whatIsTheHeightOfTheDoor:
    'The door is measured using metre rulers.<br/>What is the height of the door?',
  whatIsTheHeightOfTheGiraffe:
    'The giraffe is measured using metre rulers.<br/>What is the height of the giraffe?',
  whatIsTheHeightOfTheShed:
    'The shed is measured using metre rulers.<br/>What is the height of the shed?',
  whatIsTheHeightOfTheTree:
    'The tree is measured using metre rulers.<br/>What is the height of the tree?',
  whatIsTheHeightOfTheWardrobe:
    'The wardrobe is measured using metre rulers.<br/>What is the height of the wardrobe?',
  whatIsTheLengthOfTheCar:
    'The car is measured using metre rulers.<br/>What is the length of the car?',
  whatIsTheLengthOfTheCow:
    'The cow is measured using metre rulers.<br/>What is the length of the cow?',
  whatIsTheLengthOfTheLine: 'What is the length of the line?',
  whatIsTheLengthOfTheLion:
    'The lion is measured using metre rulers.<br/>What is the length of the lion?',
  whatIsTheLengthOfTheShed:
    'The shed is measured using metre rulers.<br/>What is the length of the shed?',
  whatIsTheLengthOfTheTable:
    'The table is measured using metre rulers.<br/>What is the length of the table?',
  whatIsTheMass: 'What is the mass?',
  whatIsTheMassOfTheApple: 'What is the mass of the apple?',
  whatIsTheMassOfTheBanana: 'What is the mass of the banana?',
  whatIsTheMassOfTheBarrel: 'What is the mass of the barrel?',
  whatIsTheMassOfTheBox: 'What is the mass of the box?',
  whatIsTheMassOfTheBricks: 'What is the mass of the bricks?',
  whatIsTheMassOfTheCarrot: 'What is the mass of the carrot?',
  whatIsTheMassOfTheFlour: 'What is the mass of the flour?',
  whatIsTheMassOfThePear: 'What is the mass of the pear?',
  whatIsTheMassOfThePencil: 'What is the mass of the pencil?',
  whatIsTheMassOfTheSuitcase: 'What is the mass of the suitcase?',
  whatIsTheMassOfTheToyBear: 'What is the mass of the toy bear?',
  whatIsTheMassOfTheToyElephant: 'What is the mass of the toy elephant?',
  whatIsTheMassOfTheToyGiraffe: 'What is the mass of the toy giraffe?',
  whatIsTheMassOfTheToyTrain: 'What is the mass of the toy train?',
  whatIsTheMassOfTheTyre: 'What is the mass of the tyre?',
  whatIsTheMassOfXCherries: 'What is the mass of {0:number|numberFormat} cherries?',
  whatIsTheMassOfXPencils: 'What is the mass of {0:number|numberFormat} pencils?',
  whatIsTheMassOfXPlums: 'What is the mass of {0:number|numberFormat} plums?',
  whatIsTheMassOfXRubbers: 'What is the mass of {0:number|numberFormat} rubbers?',
  whatIsTheMassOfXSharpeners: 'What is the mass of {0:number|numberFormat} sharpeners?',
  whatIsTheMassOfXStrawberries: 'What is the mass of {0:number|numberFormat} strawberries?',
  whatIsTheNumberAsANumeralSelectYourAnswer:
    'What is the word as a numeral?<br/>Select your answer.',
  whatIsTheNumeralAsANumberSelectYourAnswer:
    'What is the numeral as a word?<br/>Select your answer.',
  whatIsXLessThanTheNumberShown: 'What is {0:number|numberFormat} less than the number shown?',
  whatIsXMoreThanTheNumberShown: 'What is {0:number|numberFormat} more than the number shown?',
  whatIsXSelectYourAnswer: 'What is {0}?<br/>Select your answer.',
  whatIsXOperationY: 'What is {0:number|numberFormat} {1} {2:number|numberFormat}?',
  whatAdditionIsShown: 'What addition is shown?',
  whatAreTheParts: 'What are the parts?',
  whatDayOfTheWeekIsTheXDate: 'What day of the week is the {0:string|lower}?',
  whatDoubleIsShown: 'What double is shown?',
  whatIsDoubleX: 'What is double {0:number|numberFormat}?',
  whatIsTheTemperatureShownOnTheThermometer: 'What is the temperature shown on the thermometer?',
  whatIsTheTotalLengthOfRibbon: 'What is the total length of ribbon?',
  whatIsTheTotalValueOfTheCounters: 'What is the total value of the counters?',
  whatIsTheTotalVolumeOfWater: 'What is the total volume of water?',
  whatNumberIsShown: 'What number is shown?',
  whatNumberIsTheArrowPointingTo: 'What number is the arrow pointing to?',
  whatNumbersAreShown: 'What numbers are shown?',
  whatSubtractionIsShownOnTheNumberLineDragTheCardsToCompleteTheNumberSentence:
    'What subtraction is shown on the number line?<br/>Drag the cards to complete the number sentence.',
  whatTimeDoesTheClockShow: 'What time does the clock show?',
  whatTimeIsShownOnTheStopwatch: 'What time is shown on the stopwatch?',
  whatIsTheXLengthOfTheYObjectInCubesSelectYourAnswer:
    'What is the {0:string|lower} of the {1:string|lower} in cubes?<br/>Select your answer.',
  whatIsTheNumber: 'What is the number?',
  whatIsTheWholeFillInTheNumber: 'What is the whole?<br/>Fill in the number.',
  wholeIsX: 'The whole is {0:number|numberFormat}',
  workOutTheMissingNumber: 'Work out the missing number.',
  workOutTheMissingNumbers: 'Work out the missing numbers.',
  workOutTheMissingValue: 'Work out the missing value.',
  workOutTheNearDouble: 'Work out the near double.',
  xBirdsFlyAwayHowManyAreLeft:
    '{0:number|numberFormat} bird{{0:s}} fl{{0:ies|y}} away.<br/><br/>How many birds are left?',
  xContainerHasYMoreOrLessJuiceThanZContainerDragTheArrowToShowHowMuchJuice:
    '{0:string} {1:string} has {2:string|lower} juice than {0:string|lower} {3:string}.<br/>Drag the arrow to show how much juice could be in {0:string|lower} {3:string}.',
  xGlassesOfWaterFillOneJug: '{0:number|numberFormat} glasses of water fill 1 jug.',
  xHasTheseCounters: '{0} has these counters.',
  xHasYFewerCountersThanZ: '{0} has {1:number|numberFormat} fewer counter{{1:s}} than {2}.',
  xHasYMoreCountersThanZ: '{0} has {1:number|numberFormat} more counter{{1:s}} than {2}.',
  xObjectsAreSharedEquallyBetweenYBags:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} bags.',
  xObjectsAreSharedEquallyBetweenYBoxes:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} boxes.',
  xObjectsAreSharedEquallyBetweenYTanks:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} tanks.',
  xObjectsAreSharedEquallyBetweenYTrays:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} trays.',
  xObjectsAreSharedEquallyBetweenYPlates:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} plates.',
  xObjectsAreSharedEquallyBetweenYPots:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} pots.',
  xObjectsAreSharedEquallyBetweenYVases:
    '{total:number|numberFormat} {object:string|lower} are shared equally between {groups:number|numberFormat} vases.',
  xObjectCostsLessThanYObjectWhatIsTotalCostCombinedCompleteBarModel:
    'A {objectB:string|lower} costs £{difference} <b>less</b> than a {objectA:string|lower}.<br/>What is the total cost of a {objectA:string|lower} and a {objectB:string|lower}?<br/>Complete the bar model.',
  xObjectCostsMoreThanYObjectWhatIsTotalCostCombinedCompleteBarModel:
    'A {objectA:string|lower} costs £{difference} <b>more</b> than a {objectB:string|lower}.<br/>What is the total cost of a {objectA:string|lower} and a {objectB:string|lower}?<br/>Complete the bar model.',
  xOfTheCarsLeaveHowManyCarsAreLeftInTheCarPark:
    '{0:number|numberFormat} of the cars leave{{s|}}.<br/><br/>How many cars are left in the car park?',
  xOfTheCookiesAreEatenHowManyCookiesAreLeftOnThePlate:
    '{0:number|numberFormat} of the cookies {{0:is|are}} eaten.<br/><br/>How many cookies are left on the plate?',
  xPoursYMlOfWaterIntoTheJug: '{0} pours {1:string|lower} of water into the jug.',
  xPoursYMlOfWaterIntoTheGlass: '{0} pours {1:string|lower} of water into the glass.',
  xSpendsPoundsY: '{0} spends £{1:number|numberFormat}',
  xSpendsPoundsYAndZP: '{0} spends £{1:number|numberFormat} and {2:number|numberFormat}p.',
  xSpendsYP: '{0} spends {1:number|numberFormat}p.',
  youCanTapTheBase10ToCrossThemOut: 'You can tap the base 10 to cross them out.',
  youCanTapTheCountersToCrossThemOut: 'You can tap the counters to cross them out.',
  youCanTapThePicturesToHelpYou: 'You can tap the pictures to help you.'
};
