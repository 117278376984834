// Autogenerated with: yarn ts-node scripts/generateSvgNaturalDimens.ts
// To make changes to this file, edit that script.
// To resolve merge conflicts in this file, simply re-run that script.
// This script is also run by `yarn codegen`.
/**
 * Convenient loader functions for all SVGs. Features:
 * - provides all SVGs with their "natural" dimensions (i.e. dimensions derived from their viewBox)
 *
 * In the future, lazy-loading of SVGs could occur here. See e.g. React.lazy or @loadable/components.
 */

import { type FC } from 'react';
import { type SvgProps } from 'react-native-svg';

import ArrowRightCustomizable from './ArrowRight';
import ArrowUpCustomizable from './ArrowUp';
import CloseCustomizable from './Close';
import CoordinatesCirclePointCustomizable from './Coordinates/CirclePoint';
import CoordinatesCrossPointCustomizable from './Coordinates/CrossPoint';
import CoordinatesFilledSquarePointCustomizable from './Coordinates/FilledSquarePoint';
import CoordinatesSquarePointCustomizable from './Coordinates/SquarePoint';
import CoordinatesTrianglePointCustomizable from './Coordinates/TrianglePoint';
import CounterCustomizable from './Counter';
import SliderArrowCustomizable from './SliderArrow';
import SliderArrowRightCustomizable from './SliderArrowRight';
import SquareCustomizable from './Square';
import ZestRotateLeftCustomizable from './zest/rotate-left';

import Num3DShapesOnIsometricPaper3DOutlineShapeCone1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cone1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeCone2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cone2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeCone3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cone3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeCone4IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cone4_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeCylinder1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeCylinder2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeCylinder3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeHexagonalPrism1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeHexagonalPrism2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeHexagonalPrism3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapePentagonalPrism1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapePentagonalPrism2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapePentagonalPrism3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeSquareBasedPyramid1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeSquareBasedPyramid2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeSquareBasedPyramid3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeTriangularBasedPyramid1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeTriangularBasedPyramid2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeTriangularBasedPyramid3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeTriangularPrism1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeTriangularPrism2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DOutlineShapeTriangularPrism3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCone1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cone1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCone2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cone2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCone3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cone3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCone4IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cone4_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCylinder1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cylinder1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCylinder2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cylinder2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeCylinder3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_cylinder3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeHexagonalPrism1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeHexagonalPrism2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeHexagonalPrism3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapePentagonalPrism1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapePentagonalPrism2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapePentagonalPrism3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeSquareBasedPyramid1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeSquareBasedPyramid2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeSquareBasedPyramid3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeTriangularBasedPyramid1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeTriangularBasedPyramid2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeTriangularBasedPyramid3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid3_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeTriangularPrism1IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_triangular_prism1_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeTriangularPrism2IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_triangular_prism2_isometric_paper.svg';
import Num3DShapesOnIsometricPaper3DShapeTriangularPrism3IsometricPaper from './3-D_shapes_on_isometric_paper/3D_shape_triangular_prism3_isometric_paper.svg';
import Num3DShapesWithMultiLinkCubes3DShape10MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape10_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape11MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape11_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape12MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape12_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape13MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape13_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape14MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape14_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape15MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape15_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape16MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape16_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape17MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape17_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape18MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape18_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape19MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape19_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape1MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape1_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape20MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape20_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape21MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape21_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape22MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape22_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape23MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape23_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape24MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape24_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape25MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape25_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape26MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape26_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape27MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape27_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape28MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape28_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape29MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape29_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape2MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape2_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape30MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape30_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape31MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape31_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape32MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape32_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape33MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape33_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape34MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape34_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape35MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape35_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape36MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape36_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape37MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape37_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape38MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape38_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape39MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape39_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape3MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape3_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape40MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape40_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape41MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape41_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape42MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape42_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape43MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape43_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape44MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape44_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape45MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape45_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape46MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape46_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape47MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape47_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape4MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape4_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape5MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape5_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape6MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape6_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape7MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape7_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape8MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape8_multi_link_cubes.svg';
import Num3DShapesWithMultiLinkCubes3DShape9MultiLinkCubes from './3-D_shapes_with_multi-link_cubes/3D_shape9_multi_link_cubes.svg';
import Num3DShapesCubes3DShape10Cubes from './3D_Shapes_Cubes/3D_shape10_cubes.svg';
import Num3DShapesCubes3DShape11Cubes from './3D_Shapes_Cubes/3D_shape11_cubes.svg';
import Num3DShapesCubes3DShape12Cubes from './3D_Shapes_Cubes/3D_shape12_cubes.svg';
import Num3DShapesCubes3DShape13Cubes from './3D_Shapes_Cubes/3D_shape13_cubes.svg';
import Num3DShapesCubes3DShape14Cubes from './3D_Shapes_Cubes/3D_shape14_cubes.svg';
import Num3DShapesCubes3DShape15Cubes from './3D_Shapes_Cubes/3D_shape15_cubes.svg';
import Num3DShapesCubes3DShape16Cubes from './3D_Shapes_Cubes/3D_shape16_cubes.svg';
import Num3DShapesCubes3DShape17Cubes from './3D_Shapes_Cubes/3D_shape17_cubes.svg';
import Num3DShapesCubes3DShape18Cubes from './3D_Shapes_Cubes/3D_shape18_cubes.svg';
import Num3DShapesCubes3DShape19Cubes from './3D_Shapes_Cubes/3D_shape19_cubes.svg';
import Num3DShapesCubes3DShape1Cubes from './3D_Shapes_Cubes/3D_shape1_cubes.svg';
import Num3DShapesCubes3DShape20Cubes from './3D_Shapes_Cubes/3D_shape20_cubes.svg';
import Num3DShapesCubes3DShape21Cubes from './3D_Shapes_Cubes/3D_shape21_cubes.svg';
import Num3DShapesCubes3DShape22Cubes from './3D_Shapes_Cubes/3D_shape22_cubes.svg';
import Num3DShapesCubes3DShape23Cubes from './3D_Shapes_Cubes/3D_shape23_cubes.svg';
import Num3DShapesCubes3DShape24Cubes from './3D_Shapes_Cubes/3D_shape24_cubes.svg';
import Num3DShapesCubes3DShape25Cubes from './3D_Shapes_Cubes/3D_shape25_cubes.svg';
import Num3DShapesCubes3DShape26Cubes from './3D_Shapes_Cubes/3D_shape26_cubes.svg';
import Num3DShapesCubes3DShape27Cubes from './3D_Shapes_Cubes/3D_shape27_cubes.svg';
import Num3DShapesCubes3DShape28Cubes from './3D_Shapes_Cubes/3D_shape28_cubes.svg';
import Num3DShapesCubes3DShape29Cubes from './3D_Shapes_Cubes/3D_shape29_cubes.svg';
import Num3DShapesCubes3DShape2Cubes from './3D_Shapes_Cubes/3D_shape2_cubes.svg';
import Num3DShapesCubes3DShape30Cubes from './3D_Shapes_Cubes/3D_shape30_cubes.svg';
import Num3DShapesCubes3DShape31Cubes from './3D_Shapes_Cubes/3D_shape31_cubes.svg';
import Num3DShapesCubes3DShape32Cubes from './3D_Shapes_Cubes/3D_shape32_cubes.svg';
import Num3DShapesCubes3DShape33Cubes from './3D_Shapes_Cubes/3D_shape33_cubes.svg';
import Num3DShapesCubes3DShape34Cubes from './3D_Shapes_Cubes/3D_shape34_cubes.svg';
import Num3DShapesCubes3DShape35Cubes from './3D_Shapes_Cubes/3D_shape35_cubes.svg';
import Num3DShapesCubes3DShape36Cubes from './3D_Shapes_Cubes/3D_shape36_cubes.svg';
import Num3DShapesCubes3DShape37Cubes from './3D_Shapes_Cubes/3D_shape37_cubes.svg';
import Num3DShapesCubes3DShape38Cubes from './3D_Shapes_Cubes/3D_shape38_cubes.svg';
import Num3DShapesCubes3DShape39Cubes from './3D_Shapes_Cubes/3D_shape39_cubes.svg';
import Num3DShapesCubes3DShape3Cubes from './3D_Shapes_Cubes/3D_shape3_cubes.svg';
import Num3DShapesCubes3DShape40Cubes from './3D_Shapes_Cubes/3D_shape40_cubes.svg';
import Num3DShapesCubes3DShape41Cubes from './3D_Shapes_Cubes/3D_shape41_cubes.svg';
import Num3DShapesCubes3DShape42Cubes from './3D_Shapes_Cubes/3D_shape42_cubes.svg';
import Num3DShapesCubes3DShape43Cubes from './3D_Shapes_Cubes/3D_shape43_cubes.svg';
import Num3DShapesCubes3DShape44Cubes from './3D_Shapes_Cubes/3D_shape44_cubes.svg';
import Num3DShapesCubes3DShape45Cubes from './3D_Shapes_Cubes/3D_shape45_cubes.svg';
import Num3DShapesCubes3DShape46Cubes from './3D_Shapes_Cubes/3D_shape46_cubes.svg';
import Num3DShapesCubes3DShape47Cubes from './3D_Shapes_Cubes/3D_shape47_cubes.svg';
import Num3DShapesCubes3DShape48Cubes from './3D_Shapes_Cubes/3D_shape48_cubes.svg';
import Num3DShapesCubes3DShape49Cubes from './3D_Shapes_Cubes/3D_shape49_cubes.svg';
import Num3DShapesCubes3DShape4Cubes from './3D_Shapes_Cubes/3D_shape4_cubes.svg';
import Num3DShapesCubes3DShape50Cubes from './3D_Shapes_Cubes/3D_shape50_cubes.svg';
import Num3DShapesCubes3DShape51Cubes from './3D_Shapes_Cubes/3D_shape51_cubes.svg';
import Num3DShapesCubes3DShape52Cubes from './3D_Shapes_Cubes/3D_shape52_cubes.svg';
import Num3DShapesCubes3DShape53Cubes from './3D_Shapes_Cubes/3D_shape53_cubes.svg';
import Num3DShapesCubes3DShape54Cubes from './3D_Shapes_Cubes/3D_shape54_cubes.svg';
import Num3DShapesCubes3DShape57Cubes from './3D_Shapes_Cubes/3D_shape57_cubes.svg';
import Num3DShapesCubes3DShape58Cubes from './3D_Shapes_Cubes/3D_shape58_cubes.svg';
import Num3DShapesCubes3DShape59Cubes from './3D_Shapes_Cubes/3D_shape59_cubes.svg';
import Num3DShapesCubes3DShape5Cubes from './3D_Shapes_Cubes/3D_shape5_cubes.svg';
import Num3DShapesCubes3DShape60Cubes from './3D_Shapes_Cubes/3D_shape60_cubes.svg';
import Num3DShapesCubes3DShape61Cubes from './3D_Shapes_Cubes/3D_shape61_cubes.svg';
import Num3DShapesCubes3DShape62Cubes from './3D_Shapes_Cubes/3D_shape62_cubes.svg';
import Num3DShapesCubes3DShape63Cubes from './3D_Shapes_Cubes/3D_shape63_cubes.svg';
import Num3DShapesCubes3DShape64Cubes from './3D_Shapes_Cubes/3D_shape64_cubes.svg';
import Num3DShapesCubes3DShape65Cubes from './3D_Shapes_Cubes/3D_shape65_cubes.svg';
import Num3DShapesCubes3DShape66Cubes from './3D_Shapes_Cubes/3D_shape66_cubes.svg';
import Num3DShapesCubes3DShape67Cubes from './3D_Shapes_Cubes/3D_shape67_cubes.svg';
import Num3DShapesCubes3DShape68Cubes from './3D_Shapes_Cubes/3D_shape68_cubes.svg';
import Num3DShapesCubes3DShape69Cubes from './3D_Shapes_Cubes/3D_shape69_cubes.svg';
import Num3DShapesCubes3DShape6Cubes from './3D_Shapes_Cubes/3D_shape6_cubes.svg';
import Num3DShapesCubes3DShape70Cubes from './3D_Shapes_Cubes/3D_shape70_cubes.svg';
import Num3DShapesCubes3DShape7Cubes from './3D_Shapes_Cubes/3D_shape7_cubes.svg';
import Num3DShapesCubes3DShape8Cubes from './3D_Shapes_Cubes/3D_shape8_cubes.svg';
import Num3DShapesCubes3DShape9Cubes from './3D_Shapes_Cubes/3D_shape9_cubes.svg';
import Num3DShapesCubesOnIsoGrid3DShape10CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape10_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape11CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape11_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape12CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape12_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape13CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape13_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape1CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape1_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape2CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape2_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape3CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape3_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape4CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape4_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape5CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape5_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape6CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape6_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape7CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape7_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape8CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape8_cubes_isometric_paper.svg';
import Num3DShapesCubesOnIsoGrid3DShape9CubesIsometricPaper from './3D_Shapes_Cubes_on_Iso_Grid/3D_shape9_cubes_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape10IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape10_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape11IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape11_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape12IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape12_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape13IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape13_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape1IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape1_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape2IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape2_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape3IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape3_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape4IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape4_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape5IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape5_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape6IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape6_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape7IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape7_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape8IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape8_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape9IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape9_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape10IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape10_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape11IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape11_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape12IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape12_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape13IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape13_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape1IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape1_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape2IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape2_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape3IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape3_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape4IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape4_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape5IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape5_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape6IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape6_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape7IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape7_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape8IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape8_isometric_paper.svg';
import Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape9IsometricPaper from './3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape9_isometric_paper.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape1 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape1.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape10 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape10.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape11 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape11.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape12 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape12.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape13 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape13.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape14 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape14.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape15 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape15.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape16 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape16.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape2 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape2.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape3 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape3.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape4 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape4.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape5 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape5.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape6 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape6.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape7 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape7.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape8 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape8.svg';
import Num3DCompoundShapesOnIsometricPaper3DCompoundShape9 from './3D_compound_shapes_on_isometric_paper/3D_compound_shape9.svg';
import Num3DShapesFullColorsConesConeBlue from './3D_shapes_full_colors/Cones/Cone_blue.svg';
import Num3DShapesFullColorsConesConeGreen from './3D_shapes_full_colors/Cones/Cone_green.svg';
import Num3DShapesFullColorsConesConeOrange from './3D_shapes_full_colors/Cones/Cone_orange.svg';
import Num3DShapesFullColorsConesConePink from './3D_shapes_full_colors/Cones/Cone_pink.svg';
import Num3DShapesFullColorsConesConePurple from './3D_shapes_full_colors/Cones/Cone_purple.svg';
import Num3DShapesFullColorsConesConeRed from './3D_shapes_full_colors/Cones/Cone_red.svg';
import Num3DShapesFullColorsConesConeTransparent from './3D_shapes_full_colors/Cones/Cone_transparent.svg';
import Num3DShapesFullColorsConesConeYellow from './3D_shapes_full_colors/Cones/Cone_yellow.svg';
import Num3DShapesFullColorsCubesCubeBlue from './3D_shapes_full_colors/Cubes/Cube_blue.svg';
import Num3DShapesFullColorsCubesCubeBlue2 from './3D_shapes_full_colors/Cubes/Cube_blue_2.svg';
import Num3DShapesFullColorsCubesCubeBlue3 from './3D_shapes_full_colors/Cubes/Cube_blue_3.svg';
import Num3DShapesFullColorsCubesCubeGreen from './3D_shapes_full_colors/Cubes/Cube_green.svg';
import Num3DShapesFullColorsCubesCubeGreen2 from './3D_shapes_full_colors/Cubes/Cube_green_2.svg';
import Num3DShapesFullColorsCubesCubeGreen3 from './3D_shapes_full_colors/Cubes/Cube_green_3.svg';
import Num3DShapesFullColorsCubesCubeOrange from './3D_shapes_full_colors/Cubes/Cube_orange.svg';
import Num3DShapesFullColorsCubesCubeOrange2 from './3D_shapes_full_colors/Cubes/Cube_orange_2.svg';
import Num3DShapesFullColorsCubesCubeOrange3 from './3D_shapes_full_colors/Cubes/Cube_orange_3.svg';
import Num3DShapesFullColorsCubesCubePink from './3D_shapes_full_colors/Cubes/Cube_pink.svg';
import Num3DShapesFullColorsCubesCubePink2 from './3D_shapes_full_colors/Cubes/Cube_pink_2.svg';
import Num3DShapesFullColorsCubesCubePink3 from './3D_shapes_full_colors/Cubes/Cube_pink_3.svg';
import Num3DShapesFullColorsCubesCubePurple from './3D_shapes_full_colors/Cubes/Cube_purple.svg';
import Num3DShapesFullColorsCubesCubePurple2 from './3D_shapes_full_colors/Cubes/Cube_purple_2.svg';
import Num3DShapesFullColorsCubesCubePurple3 from './3D_shapes_full_colors/Cubes/Cube_purple_3.svg';
import Num3DShapesFullColorsCubesCubeRed from './3D_shapes_full_colors/Cubes/Cube_red.svg';
import Num3DShapesFullColorsCubesCubeRed2 from './3D_shapes_full_colors/Cubes/Cube_red_2.svg';
import Num3DShapesFullColorsCubesCubeRed3 from './3D_shapes_full_colors/Cubes/Cube_red_3.svg';
import Num3DShapesFullColorsCubesCubeTransparent from './3D_shapes_full_colors/Cubes/Cube_transparent.svg';
import Num3DShapesFullColorsCubesCubeYellow from './3D_shapes_full_colors/Cubes/Cube_yellow.svg';
import Num3DShapesFullColorsCubesCubeYellow2 from './3D_shapes_full_colors/Cubes/Cube_yellow_2.svg';
import Num3DShapesFullColorsCubesCubeYellow3 from './3D_shapes_full_colors/Cubes/Cube_yellow_3.svg';
import Num3DShapesFullColorsCuboidsCuboidBlue from './3D_shapes_full_colors/Cuboids/Cuboid_blue.svg';
import Num3DShapesFullColorsCuboidsCuboidGreen from './3D_shapes_full_colors/Cuboids/Cuboid_green.svg';
import Num3DShapesFullColorsCuboidsCuboidOrange from './3D_shapes_full_colors/Cuboids/Cuboid_orange.svg';
import Num3DShapesFullColorsCuboidsCuboidPink from './3D_shapes_full_colors/Cuboids/Cuboid_pink.svg';
import Num3DShapesFullColorsCuboidsCuboidPurple from './3D_shapes_full_colors/Cuboids/Cuboid_purple.svg';
import Num3DShapesFullColorsCuboidsCuboidRed from './3D_shapes_full_colors/Cuboids/Cuboid_red.svg';
import Num3DShapesFullColorsCuboidsCuboidTransparent from './3D_shapes_full_colors/Cuboids/Cuboid_transparent.svg';
import Num3DShapesFullColorsCuboidsCuboidYellow from './3D_shapes_full_colors/Cuboids/Cuboid_yellow.svg';
import Num3DShapesFullColorsCylindersCylinderBlue from './3D_shapes_full_colors/Cylinders/Cylinder_blue.svg';
import Num3DShapesFullColorsCylindersCylinderGreen from './3D_shapes_full_colors/Cylinders/Cylinder_green.svg';
import Num3DShapesFullColorsCylindersCylinderOrange from './3D_shapes_full_colors/Cylinders/Cylinder_orange.svg';
import Num3DShapesFullColorsCylindersCylinderPink from './3D_shapes_full_colors/Cylinders/Cylinder_pink.svg';
import Num3DShapesFullColorsCylindersCylinderPurple from './3D_shapes_full_colors/Cylinders/Cylinder_purple.svg';
import Num3DShapesFullColorsCylindersCylinderRed from './3D_shapes_full_colors/Cylinders/Cylinder_red.svg';
import Num3DShapesFullColorsCylindersCylinderTransparent from './3D_shapes_full_colors/Cylinders/Cylinder_transparent.svg';
import Num3DShapesFullColorsCylindersCylinderYellow from './3D_shapes_full_colors/Cylinders/Cylinder_yellow.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceBlue from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_blue.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceGreen from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_green.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceOrange from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_orange.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFacePink from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_pink.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFacePurple from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_purple.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceRed from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_red.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceTransparent from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_transparent.svg';
import Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceYellow from './3D_shapes_full_colors/Front_face_cubes/Cube_front_face_yellow.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismBlue from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_blue.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismGreen from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_green.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismOrange from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_orange.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismPink from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_pink.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismPurple from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_purple.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismRed from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_red.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismTransparent from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_transparent.svg';
import Num3DShapesFullColorsHexagonalPrismsHexagonalPrismYellow from './3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_yellow.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismBlue from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_blue.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismGreen from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_green.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismOrange from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_orange.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismPink from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_pink.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismPurple from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_purple.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismRed from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_red.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismTransparent from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_transparent.svg';
import Num3DShapesFullColorsPentagonalPrismsPentagonalPrismYellow from './3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_yellow.svg';
import Num3DShapesFullColorsSpheresSphereBlue from './3D_shapes_full_colors/Spheres/Sphere_blue.svg';
import Num3DShapesFullColorsSpheresSphereGreen from './3D_shapes_full_colors/Spheres/Sphere_green.svg';
import Num3DShapesFullColorsSpheresSphereOrange from './3D_shapes_full_colors/Spheres/Sphere_orange.svg';
import Num3DShapesFullColorsSpheresSpherePink from './3D_shapes_full_colors/Spheres/Sphere_pink.svg';
import Num3DShapesFullColorsSpheresSpherePurple from './3D_shapes_full_colors/Spheres/Sphere_purple.svg';
import Num3DShapesFullColorsSpheresSphereRed from './3D_shapes_full_colors/Spheres/Sphere_red.svg';
import Num3DShapesFullColorsSpheresSphereTransparent from './3D_shapes_full_colors/Spheres/Sphere_transparent.svg';
import Num3DShapesFullColorsSpheresSphereYellow from './3D_shapes_full_colors/Spheres/Sphere_yellow.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidBlue from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_blue.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidGreen from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_green.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidOrange from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_orange.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidPink from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_pink.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidPurple from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_purple.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidRed from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_red.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidTransparent from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_transparent.svg';
import Num3DShapesFullColorsSquarePyramidsSquarePyramidYellow from './3D_shapes_full_colors/Square_pyramids/Square_pyramid_yellow.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidBlue from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_blue.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidGreen from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_green.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidOrange from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_orange.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidPink from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_pink.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidPurple from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_purple.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidRed from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_red.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidTransparent from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_transparent.svg';
import Num3DShapesFullColorsTrianglePyramidsTrianglePyramidYellow from './3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_yellow.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismBlue from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_blue.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismGreen from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_green.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismOrange from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_orange.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismPink from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_pink.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismPurple from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_purple.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismRed from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_red.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismTransparent from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_transparent.svg';
import Num3DShapesFullColorsTriangularPrismsTriangularPrismYellow from './3D_shapes_full_colors/Triangular_prisms/Triangular_prism_yellow.svg';
import Num3DShapesInRealLifeObjectsConeObjectsChipsCone from './3D_shapes_in_real_life_objects/Cone_objects/Chips_cone.svg';
import Num3DShapesInRealLifeObjectsConeObjectsChristmasTree from './3D_shapes_in_real_life_objects/Cone_objects/Christmas_tree.svg';
import Num3DShapesInRealLifeObjectsConeObjectsCookingFunnel from './3D_shapes_in_real_life_objects/Cone_objects/Cooking_funnel.svg';
import Num3DShapesInRealLifeObjectsConeObjectsMegaphone from './3D_shapes_in_real_life_objects/Cone_objects/Megaphone.svg';
import Num3DShapesInRealLifeObjectsConeObjectsPartyHat from './3D_shapes_in_real_life_objects/Cone_objects/Party_hat.svg';
import Num3DShapesInRealLifeObjectsConeObjectsPineCone from './3D_shapes_in_real_life_objects/Cone_objects/Pine_cone.svg';
import Num3DShapesInRealLifeObjectsConeObjectsTrafficCone from './3D_shapes_in_real_life_objects/Cone_objects/Traffic_cone.svg';
import Num3DShapesInRealLifeObjectsConeObjectsWaffleCone from './3D_shapes_in_real_life_objects/Cone_objects/Waffle_cone.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsBrick from './3D_shapes_in_real_life_objects/Cube_objects/Brick.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsDice from './3D_shapes_in_real_life_objects/Cube_objects/Dice.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsIceCube from './3D_shapes_in_real_life_objects/Cube_objects/Ice_cube.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsOpenCardboardBox from './3D_shapes_in_real_life_objects/Cube_objects/Open_cardboard_box.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsPresent from './3D_shapes_in_real_life_objects/Cube_objects/Present.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsRubixCube from './3D_shapes_in_real_life_objects/Cube_objects/Rubix_cube.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsStackingCrate from './3D_shapes_in_real_life_objects/Cube_objects/Stacking_crate.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsWhiteSugarCube from './3D_shapes_in_real_life_objects/Cube_objects/White_sugar_cube.svg';
import Num3DShapesInRealLifeObjectsCubeObjectsWoodenLetterBlock from './3D_shapes_in_real_life_objects/Cube_objects/Wooden_letter_block.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsBrick from './3D_shapes_in_real_life_objects/Cuboid_objects/Brick.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsCardboardBoxCuboid from './3D_shapes_in_real_life_objects/Cuboid_objects/Cardboard_box_cuboid.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsCerealBox from './3D_shapes_in_real_life_objects/Cuboid_objects/Cereal_box.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsChestCuboid from './3D_shapes_in_real_life_objects/Cuboid_objects/Chest_cuboid.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsChocolateCuboid from './3D_shapes_in_real_life_objects/Cuboid_objects/Chocolate_cuboid.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsDomino from './3D_shapes_in_real_life_objects/Cuboid_objects/Domino.svg';
import Num3DShapesInRealLifeObjectsCuboidObjectsTissueBox from './3D_shapes_in_real_life_objects/Cuboid_objects/Tissue_box.svg';
import Num3DShapesInRealLifeObjectsSphereObjectsBallOfYarn from './3D_shapes_in_real_life_objects/Sphere_objects/Ball_of_yarn.svg';
import Num3DShapesInRealLifeObjectsSphereObjectsBasketball from './3D_shapes_in_real_life_objects/Sphere_objects/Basketball.svg';
import Num3DShapesInRealLifeObjectsSphereObjectsBubble from './3D_shapes_in_real_life_objects/Sphere_objects/Bubble.svg';
import Num3DShapesInRealLifeObjectsSphereObjectsColouredMarble from './3D_shapes_in_real_life_objects/Sphere_objects/Coloured_marble.svg';
import Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsCommunicationTower from './3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Communication_tower.svg';
import Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsEgyptianPyramid from './3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Egyptian_pyramid.svg';
import Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsLouvrePyramid from './3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Louvre_pyramid.svg';
import Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsMayanPyramid from './3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Mayan_pyramid.svg';
import Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsSafetySign from './3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Safety_sign.svg';
import Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsTower from './3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Tower.svg';
import Num3DShapesInRealLifeObjectsTriangularPrismObjectsCheese from './3D_shapes_in_real_life_objects/Triangular_prism_objects/Cheese.svg';
import Num3DShapesInRealLifeObjectsTriangularPrismObjectsChocolateTriangularPrism from './3D_shapes_in_real_life_objects/Triangular_prism_objects/Chocolate_triangular_prism.svg';
import Num3DShapesInRealLifeObjectsTriangularPrismObjectsTent from './3D_shapes_in_real_life_objects/Triangular_prism_objects/Tent.svg';
import Num3DShapesInRealLifeObjectsTriangularPrismObjectsWoodTriangularPrism from './3D_shapes_in_real_life_objects/Triangular_prism_objects/Wood_triangular_prism.svg';
import Num3DShapesStrawsMarshmallowsCuboidShapesStrawsMarshmallows from './3D_shapes_straws_marshmallows/Cuboid_shapes_straws_marshmallows.svg';
import Num3DShapesStrawsMarshmallowsHexagonalPrismStrawsMarshmallows from './3D_shapes_straws_marshmallows/Hexagonal_prism_straws_marshmallows.svg';
import Num3DShapesStrawsMarshmallowsPentagonalPrismStrawsMarshmallows from './3D_shapes_straws_marshmallows/Pentagonal_prism_straws_marshmallows.svg';
import Num3DShapesStrawsMarshmallowsSquareBasedPyramidStrawsMarshmallows from './3D_shapes_straws_marshmallows/Square_based_pyramid_straws_marshmallows.svg';
import Num3DShapesStrawsMarshmallowsTetrahedronShapesStrawsMarshmallows from './3D_shapes_straws_marshmallows/Tetrahedron_shapes_straws_marshmallows.svg';
import Num3DShapesStrawsMarshmallowsTriangularPrismStrawsMarshmallows from './3D_shapes_straws_marshmallows/Triangular_prism_straws_marshmallows.svg';
import Num3DShapesStrawsMarshmallowsCubeStrawsMarshmallows from './3D_shapes_straws_marshmallows/cube_straws_marshmallows.svg';
import AlexBirdsEye from './Alex_birds_eye.svg';
import AnglesInATriangleMultipleLinesIsoscelesTriangle1 from './Angles_in_a_triangle_multiple_lines/Isosceles_triangle1.svg';
import AnglesInATriangleMultipleLinesIsoscelesTriangle2 from './Angles_in_a_triangle_multiple_lines/Isosceles_triangle2.svg';
import AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle1 from './Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle1.svg';
import AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle2 from './Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle2.svg';
import AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle3 from './Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle3.svg';
import AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle4 from './Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle4.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle1 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle1.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle2 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle2.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle3 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle3.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle4 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle4.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle5 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle5.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle6 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle6.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle7 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle7.svg';
import AnglesInATriangleMultipleLinesScaleneTriangle8 from './Angles_in_a_triangle_multiple_lines/Scalene_triangle8.svg';
import AnglesOnAStraightLine2Angles1Line1 from './Angles_on_a_straight_line/2_angles_1_line1.svg';
import AnglesOnAStraightLine2Angles1Line2 from './Angles_on_a_straight_line/2_angles_1_line2.svg';
import AnglesOnAStraightLine2Angles1Line3 from './Angles_on_a_straight_line/2_angles_1_line3.svg';
import AnglesOnAStraightLine2Angles1Line4 from './Angles_on_a_straight_line/2_angles_1_line4.svg';
import AnnieBirdsEye from './Annie_birds_eye.svg';
import Ant from './Ant.svg';
import AppleJuiceCarton from './Apple_juice_carton.svg';
import ArrayObjectsAppleGreen from './Array_objects/AppleGreen.svg';
import ArrayObjectsAppleRed from './Array_objects/AppleRed.svg';
import ArrayObjectsBanana from './Array_objects/Banana.svg';
import ArrayObjectsOrange from './Array_objects/Orange.svg';
import ArrayObjectsPear from './Array_objects/Pear.svg';
import ArrayObjectsPineapple from './Array_objects/Pineapple.svg';
import ArrayObjectsStrawberry from './Array_objects/Strawberry.svg';
import ArrowDown from './ArrowDown.svg';
import ArrowDownLong from './ArrowDownLong.svg';
import ArrowDownLongPDF from './ArrowDownLongPDF.svg';
import ArrowRight from './ArrowRight.svg';
import ArrowRightLong from './ArrowRightLong.svg';
import ArrowUp from './ArrowUp.svg';
import ArrowUpShort from './ArrowUpShort.svg';
import ArrowsArrow from './Arrows/arrow.svg';
import ArrowsArrowBlue from './Arrows/arrow_blue.svg';
import ArrowsArrowGreen from './Arrows/arrow_green.svg';
import ArrowsArrowPink from './Arrows/arrow_pink.svg';
import ArrowsArrowPurple from './Arrows/arrow_purple.svg';
import ArrowsArrowWhite from './Arrows/arrow_white.svg';
import ArrowsArrowYellow from './Arrows/arrow_yellow.svg';
import Backspace from './Backspace.svg';
import BagOfFlour from './Bag_of_Flour.svg';
import BagOfPasta from './Bag_of_Pasta.svg';
import BagOfRice from './Bag_of_Rice.svg';
import BagOfCarrots from './Bag_of_carrots.svg';
import BagOfChocolateChips from './Bag_of_chocolate_chips.svg';
import BagOfPotatoes from './Bag_of_potatoes.svg';
import BagOfWhiteSugar from './Bag_of_white_sugar.svg';
import BalloonBlue from './BalloonBlue.svg';
import BarOfChocolate from './Bar_of_chocolate.svg';
import BaseTen1000cube from './Base_Ten/1000cube.svg';
import BaseTen100cube from './Base_Ten/100cube.svg';
import BaseTen10BluePens from './Base_Ten/10BluePens.svg';
import BaseTen10RedPens from './Base_Ten/10RedPens.svg';
import BaseTen10cube from './Base_Ten/10cube.svg';
import BaseTen1BluePen from './Base_Ten/1BluePen.svg';
import BaseTen1RedPen from './Base_Ten/1RedPen.svg';
import BaseTen1cube from './Base_Ten/1cube.svg';
import BaseTenBalloons1 from './Base_Ten/Balloons1.svg';
import BaseTenBalloons10 from './Base_Ten/Balloons10.svg';
import BaseTenBalloons100 from './Base_Ten/Balloons100.svg';
import BaseTenBricks1 from './Base_Ten/Bricks1.svg';
import BaseTenBricks10 from './Base_Ten/Bricks10.svg';
import BaseTenBricks100 from './Base_Ten/Bricks100.svg';
import BaseTenCrayons10 from './Base_Ten/Crayons10.svg';
import BaseTenCrayons100 from './Base_Ten/Crayons100.svg';
import BaseTenCrayons1Blue from './Base_Ten/Crayons1_blue.svg';
import BaseTenCrayons1Green from './Base_Ten/Crayons1_green.svg';
import BaseTenCrayons1Lightblue from './Base_Ten/Crayons1_lightblue.svg';
import BaseTenCrayons1Lime from './Base_Ten/Crayons1_lime.svg';
import BaseTenCrayons1Orange from './Base_Ten/Crayons1_orange.svg';
import BaseTenCrayons1Pink from './Base_Ten/Crayons1_pink.svg';
import BaseTenCrayons1Purple from './Base_Ten/Crayons1_purple.svg';
import BaseTenCrayons1Red from './Base_Ten/Crayons1_red.svg';
import BaseTenCrayons1White from './Base_Ten/Crayons1_white.svg';
import BaseTenCrayons1Yellow from './Base_Ten/Crayons1_yellow.svg';
import BaseTenLollipops1 from './Base_Ten/Lollipops1.svg';
import BaseTenLollipops10 from './Base_Ten/Lollipops10.svg';
import BaseTenLollipops100 from './Base_Ten/Lollipops100.svg';
import BaseTenMarbles1 from './Base_Ten/Marbles1.svg';
import BaseTenMarbles10 from './Base_Ten/Marbles10.svg';
import BaseTenMarbles100 from './Base_Ten/Marbles100.svg';
import BaseTenNails1 from './Base_Ten/Nails1.svg';
import BaseTenNails10 from './Base_Ten/Nails10.svg';
import BaseTenNails100 from './Base_Ten/Nails100.svg';
import BaseTenNails1000 from './Base_Ten/Nails1000.svg';
import BaseTenStraws1 from './Base_Ten/Straws1.svg';
import BaseTenStraws10 from './Base_Ten/Straws10.svg';
import BaseTenSweets1 from './Base_Ten/Sweets1.svg';
import BaseTenSweets10 from './Base_Ten/Sweets10.svg';
import BaseTenSweets100 from './Base_Ten/Sweets100.svg';
import Basketball from './Basketball.svg';
import Bath from './Bath.svg';
import Bee from './Bee.svg';
import Bee2 from './Bee_2.svg';
import BeeFromAbove from './Bee_from_above.svg';
import BeforeAndAfterImagesBalloonBlue from './Before_and_after_images/Balloon_blue.svg';
import BeforeAndAfterImagesBalloonBluePopped from './Before_and_after_images/Balloon_blue_popped.svg';
import BeforeAndAfterImagesBalloonBlueUninflated from './Before_and_after_images/Balloon_blue_uninflated.svg';
import BeforeAndAfterImagesBananaBite from './Before_and_after_images/Banana_bite.svg';
import BeforeAndAfterImagesBananaPeeled from './Before_and_after_images/Banana_peeled.svg';
import BeforeAndAfterImagesBananaSingle from './Before_and_after_images/Banana_single.svg';
import BeforeAndAfterImagesBarOfChocolateBite from './Before_and_after_images/Bar_of_chocolate_bite.svg';
import BeforeAndAfterImagesBarOfChocolateUnwrapped from './Before_and_after_images/Bar_of_chocolate_unwrapped.svg';
import BeforeAndAfterImagesBarOfChocolateWrNoto from './Before_and_after_images/Bar_of_chocolate_wr_noto.svg';
import BeforeAndAfterImagesCookieBiscuitBite from './Before_and_after_images/Cookie_biscuit_bite.svg';
import BeforeAndAfterImagesCookieBiscuitCrumbs from './Before_and_after_images/Cookie_biscuit_crumbs.svg';
import BeforeAndAfterImagesCookieBiscuitNoBite from './Before_and_after_images/Cookie_biscuit_no_bite.svg';
import BeforeAndAfterImagesDonutBite from './Before_and_after_images/Donut_bite.svg';
import BeforeAndAfterImagesDonutCrumbs from './Before_and_after_images/Donut_crumbs.svg';
import BeforeAndAfterImagesDonutNoBite from './Before_and_after_images/Donut_no_bite.svg';
import BeforeAndAfterImagesNotWrappedGift from './Before_and_after_images/Not_wrapped_gift.svg';
import BeforeAndAfterImagesPaperBlank from './Before_and_after_images/Paper_blank.svg';
import BeforeAndAfterImagesPaperDrawingHouseColour from './Before_and_after_images/Paper_drawing_house_colour.svg';
import BeforeAndAfterImagesPaperDrawingHouseLine from './Before_and_after_images/Paper_drawing_house_line.svg';
import BeforeAndAfterImagesPlantPotSoil from './Before_and_after_images/Plant_pot_soil.svg';
import BeforeAndAfterImagesPlantPotSunflower from './Before_and_after_images/Plant_pot_sunflower.svg';
import BeforeAndAfterImagesSunflowerSeed from './Before_and_after_images/Sunflower_seed.svg';
import BeforeAndAfterImagesWrappedGift from './Before_and_after_images/Wrapped_gift.svg';
import BeforeAndAfterImagesWrappedGiftWithoutBow from './Before_and_after_images/Wrapped_gift_without_bow.svg';
import Bike from './Bike.svg';
import BirdYellow from './Bird_yellow.svg';
import Bone from './Bone.svg';
import Book from './Book.svg';
import BookRed from './BookRed.svg';
import BookRedSetDown from './Book_Red_set_down.svg';
import Bouquet from './Bouquet.svg';
import BreadLoaf from './Bread_loaf.svg';
import BreadRoll from './Bread_roll.svg';
import BreadRolls10 from './Bread_rolls_10.svg';
import BrokenShapesArrowBreak1 from './Broken_shapes/arrow_break_1.svg';
import BrokenShapesArrowBreak2 from './Broken_shapes/arrow_break_2.svg';
import BrokenShapesCircleBreak1 from './Broken_shapes/circle_break_1.svg';
import BrokenShapesCircleBreak2 from './Broken_shapes/circle_break_2.svg';
import BrokenShapesCircleSemiBreak1 from './Broken_shapes/circle_semi_break_1.svg';
import BrokenShapesCircleSemiBreak2 from './Broken_shapes/circle_semi_break_2.svg';
import BrokenShapesCrossBreak1 from './Broken_shapes/cross_break_1.svg';
import BrokenShapesCrossBreak2 from './Broken_shapes/cross_break_2.svg';
import BrokenShapesHexagonBreak1 from './Broken_shapes/hexagon_break_1.svg';
import BrokenShapesHexagonBreak2 from './Broken_shapes/hexagon_break_2.svg';
import BrokenShapesKiteBreak1 from './Broken_shapes/kite_break_1.svg';
import BrokenShapesKiteBreak2 from './Broken_shapes/kite_break_2.svg';
import BrokenShapesParallelogramBreak1 from './Broken_shapes/parallelogram_break_1.svg';
import BrokenShapesParallelogramBreak2 from './Broken_shapes/parallelogram_break_2.svg';
import BrokenShapesPentagonBreak1 from './Broken_shapes/pentagon_break_1.svg';
import BrokenShapesPentagonBreak2 from './Broken_shapes/pentagon_break_2.svg';
import BrokenShapesPentagonHouseBreak1 from './Broken_shapes/pentagon_house_break_1.svg';
import BrokenShapesPentagonHouseBreak2 from './Broken_shapes/pentagon_house_break_2.svg';
import BrokenShapesRectangleBreak1 from './Broken_shapes/rectangle_break_1.svg';
import BrokenShapesRectangleBreak2 from './Broken_shapes/rectangle_break_2.svg';
import BrokenShapesRhombusBreak1 from './Broken_shapes/rhombus_break_1.svg';
import BrokenShapesRhombusBreak2 from './Broken_shapes/rhombus_break_2.svg';
import BrokenShapesSquareBreak1 from './Broken_shapes/square_break_1.svg';
import BrokenShapesSquareBreak2 from './Broken_shapes/square_break_2.svg';
import BrokenShapesTrapeziumRABreak1 from './Broken_shapes/trapezium_RA_break_1.svg';
import BrokenShapesTrapeziumRABreak2 from './Broken_shapes/trapezium_RA_break_2.svg';
import BrokenShapesTrapeziumIsoscelesBreak1 from './Broken_shapes/trapezium_isosceles_break_1.svg';
import BrokenShapesTrapeziumIsoscelesBreak2 from './Broken_shapes/trapezium_isosceles_break_2.svg';
import BrokenShapesTriangleRABreak1 from './Broken_shapes/triangle_RA_break_1.svg';
import BrokenShapesTriangleRABreak2 from './Broken_shapes/triangle_RA_break_2.svg';
import BrokenShapesTriangleBreak1 from './Broken_shapes/triangle_break_1.svg';
import BrokenShapesTriangleBreak2 from './Broken_shapes/triangle_break_2.svg';
import BrokenShapesTriangleIsosNarrowBreak1 from './Broken_shapes/triangle_isos_narrow_break_1.svg';
import BrokenShapesTriangleIsosNarrowBreak2 from './Broken_shapes/triangle_isos_narrow_break_2.svg';
import BrokenShapesTriangleIsosWideBreak1 from './Broken_shapes/triangle_isos_wide_break_1.svg';
import BrokenShapesTriangleIsosWideBreak2 from './Broken_shapes/triangle_isos_wide_break_2.svg';
import BrokenShapesTriangleScaleneBreak1 from './Broken_shapes/triangle_scalene_break_1.svg';
import BrokenShapesTriangleScaleneBreak2 from './Broken_shapes/triangle_scalene_break_2.svg';
import BrownBagOfApples from './Brown_bag_of_apples.svg';
import Bubble from './Bubble.svg';
import BunchesOfBananasBunchOfBananas1 from './Bunches_of_bananas/Bunch_of_bananas_1.svg';
import BunchesOfBananasBunchOfBananas2 from './Bunches_of_bananas/Bunch_of_bananas_2.svg';
import BunchesOfBananasBunchOfBananas3 from './Bunches_of_bananas/Bunch_of_bananas_3.svg';
import BunchesOfBananasBunchOfBananas4 from './Bunches_of_bananas/Bunch_of_bananas_4.svg';
import BunchesOfBananasBunchOfBananas5 from './Bunches_of_bananas/Bunch_of_bananas_5.svg';
import BunchesOfBananasBunchOfBananas6 from './Bunches_of_bananas/Bunch_of_bananas_6.svg';
import BundleOf10Straws from './Bundle_of_10_straws.svg';
import Bus from './Bus.svg';
import ButtonBlue from './Button_blue.svg';
import ButtonYellow from './Button_yellow.svg';
import Cake from './Cake.svg';
import CanOfFizzyDrink from './Can_of_fizzy_drink.svg';
import Candle from './Candle.svg';
import CansUnlabelledGreen from './Cans_unlabelled_Green.svg';
import CapacityImagesBeaker1000mlcap100mlint from './Capacity_images/Beaker_1000mlcap_100mlint.svg';
import CapacityImagesBeaker1000mlcap200mlint from './Capacity_images/Beaker_1000mlcap_200mlint.svg';
import CapacityImagesBeaker1000mlcap250mlint from './Capacity_images/Beaker_1000mlcap_250mlint.svg';
import CapacityImagesBeaker1000mlcap500mlint from './Capacity_images/Beaker_1000mlcap_500mlint.svg';
import CapacityImagesBeaker1000mlcap50mlint from './Capacity_images/Beaker_1000mlcap_50mlint.svg';
import CapacityImagesBeaker100mlcap10mlint from './Capacity_images/Beaker_100mlcap_10mlint.svg';
import CapacityImagesBeaker100mlcap20mlint from './Capacity_images/Beaker_100mlcap_20mlint.svg';
import CapacityImagesBeaker100mlcap25mlint from './Capacity_images/Beaker_100mlcap_25mlint.svg';
import CapacityImagesBeaker100mlcap50mlint from './Capacity_images/Beaker_100mlcap_50mlint.svg';
import CapacityImagesBeaker200mlcap100mlint from './Capacity_images/Beaker_200mlcap_100mlint.svg';
import CapacityImagesBeaker200mlcap10mlint from './Capacity_images/Beaker_200mlcap_10mlint.svg';
import CapacityImagesBeaker200mlcap20mlint from './Capacity_images/Beaker_200mlcap_20mlint.svg';
import CapacityImagesBeaker200mlcap25mlint from './Capacity_images/Beaker_200mlcap_25mlint.svg';
import CapacityImagesBeaker200mlcap50mlint from './Capacity_images/Beaker_200mlcap_50mlint.svg';
import CapacityImagesBeaker250mlcap125mlint from './Capacity_images/Beaker_250mlcap_125mlint.svg';
import CapacityImagesBeaker250mlcap25mlint from './Capacity_images/Beaker_250mlcap_25mlint.svg';
import CapacityImagesBeaker250mlcap50mlint from './Capacity_images/Beaker_250mlcap_50mlint.svg';
import CapacityImagesBeaker300mlcap100mlint from './Capacity_images/Beaker_300mlcap_100mlint.svg';
import CapacityImagesBeaker300mlcap20mlint from './Capacity_images/Beaker_300mlcap_20mlint.svg';
import CapacityImagesBeaker300mlcap25mlint from './Capacity_images/Beaker_300mlcap_25mlint.svg';
import CapacityImagesBeaker300mlcap50mlint from './Capacity_images/Beaker_300mlcap_50mlint.svg';
import CapacityImagesBeaker400mlcap100mlint from './Capacity_images/Beaker_400mlcap_100mlint.svg';
import CapacityImagesBeaker400mlcap200mlint from './Capacity_images/Beaker_400mlcap_200mlint.svg';
import CapacityImagesBeaker400mlcap20mlint from './Capacity_images/Beaker_400mlcap_20mlint.svg';
import CapacityImagesBeaker400mlcap25mlint from './Capacity_images/Beaker_400mlcap_25mlint.svg';
import CapacityImagesBeaker400mlcap50mlint from './Capacity_images/Beaker_400mlcap_50mlint.svg';
import CapacityImagesBeaker500mlcap100mlint from './Capacity_images/Beaker_500mlcap_100mlint.svg';
import CapacityImagesBeaker500mlcap250mlint from './Capacity_images/Beaker_500mlcap_250mlint.svg';
import CapacityImagesBeaker500mlcap25mlint from './Capacity_images/Beaker_500mlcap_25mlint.svg';
import CapacityImagesBeaker500mlcap50mlint from './Capacity_images/Beaker_500mlcap_50mlint.svg';
import CapacityImagesBeaker600mlcap100mlint from './Capacity_images/Beaker_600mlcap_100mlint.svg';
import CapacityImagesBeaker600mlcap200mlint from './Capacity_images/Beaker_600mlcap_200mlint.svg';
import CapacityImagesBeaker600mlcap50mlint from './Capacity_images/Beaker_600mlcap_50mlint.svg';
import CapacityImagesBeaker700mlcap100mlint from './Capacity_images/Beaker_700mlcap_100mlint.svg';
import CapacityImagesBeaker700mlcap50mlint from './Capacity_images/Beaker_700mlcap_50mlint.svg';
import CapacityImagesBeaker800mlcap100mlint from './Capacity_images/Beaker_800mlcap_100mlint.svg';
import CapacityImagesBeaker800mlcap200mlint from './Capacity_images/Beaker_800mlcap_200mlint.svg';
import CapacityImagesBeaker800mlcap50mlint from './Capacity_images/Beaker_800mlcap_50mlint.svg';
import CapacityImagesBeaker900mlcap100mlint from './Capacity_images/Beaker_900mlcap_100mlint.svg';
import CapacityImagesBeaker900mlcap50mlint from './Capacity_images/Beaker_900mlcap_50mlint.svg';
import CapacityImagesEmptyJug from './Capacity_images/Empty_jug.svg';
import CapacityImagesEmptyJugBlue from './Capacity_images/Empty_jug_blue.svg';
import CapacityImagesExtraLargeJuiceBottle from './Capacity_images/Extra_large_juice_bottle.svg';
import CapacityImagesFullBeaker100mlcap10mlint from './Capacity_images/Full_beaker_100mlcap_10mlint.svg';
import CapacityImagesFullBeaker1litrecap100mlint from './Capacity_images/Full_beaker_1litrecap_100mlint.svg';
import CapacityImagesLargeJuiceBottle from './Capacity_images/Large_juice_bottle.svg';
import CapacityImagesMediumJuiceBottle from './Capacity_images/Medium_juice_bottle.svg';
import CapacityImagesMostlyFullOrangePitcher from './Capacity_images/Mostly_full_orange_pitcher.svg';
import CapacityImagesMostlyFullWaterPitcher from './Capacity_images/Mostly_full_water_pitcher.svg';
import CapacityImagesNoneTransparentJug11000mlcap100mlint from './Capacity_images/None_Transparent/Jug1_1000mlcap_100mlint.svg';
import CapacityImagesNoneTransparentJug11000mlcap200mlint from './Capacity_images/None_Transparent/Jug1_1000mlcap_200mlint.svg';
import CapacityImagesNoneTransparentJug11000mlcap250mlint from './Capacity_images/None_Transparent/Jug1_1000mlcap_250mlint.svg';
import CapacityImagesNoneTransparentJug11000mlcap500mlint from './Capacity_images/None_Transparent/Jug1_1000mlcap_500mlint.svg';
import CapacityImagesNoneTransparentJug1200mlcap100mlint from './Capacity_images/None_Transparent/Jug1_200mlcap_100mlint.svg';
import CapacityImagesNoneTransparentJug1200mlcap20mlint from './Capacity_images/None_Transparent/Jug1_200mlcap_20mlint.svg';
import CapacityImagesNoneTransparentJug1200mlcap25mlint from './Capacity_images/None_Transparent/Jug1_200mlcap_25mlint.svg';
import CapacityImagesNoneTransparentJug1200mlcap50mlint from './Capacity_images/None_Transparent/Jug1_200mlcap_50mlint.svg';
import CapacityImagesNoneTransparentJug1500mlcap100mlint from './Capacity_images/None_Transparent/Jug1_500mlcap_100mlint.svg';
import CapacityImagesNoneTransparentJug1500mlcap250mlint from './Capacity_images/None_Transparent/Jug1_500mlcap_250mlint.svg';
import CapacityImagesNoneTransparentJug1500mlcap50mlint from './Capacity_images/None_Transparent/Jug1_500mlcap_50mlint.svg';
import CapacityImagesPartFilledBeaker1litrecap100mlint from './Capacity_images/Part_filled_beaker_1litrecap_100mlint.svg';
import CapacityImagesSmallJuiceBottle from './Capacity_images/Small_juice_bottle.svg';
import Car from './Car.svg';
import CarTyre from './Car_tyre.svg';
import CardPack from './Card_pack.svg';
import CardboardBox from './CardboardBox.svg';
import CardboardBox1 from './CardboardBox1.svg';
import CardboardBox2 from './CardboardBox2.svg';
import CardboardBox3 from './CardboardBox3.svg';
import CardboardBox4 from './CardboardBox4.svg';
import Carrot from './Carrot.svg';
import CartonOfEggs012CartonOfEggs0 from './Carton_of_eggs_0_-12/Carton_of_eggs_0.svg';
import CartonOfEggs012CartonOfEggs1 from './Carton_of_eggs_0_-12/Carton_of_eggs_1.svg';
import CartonOfEggs012CartonOfEggs10 from './Carton_of_eggs_0_-12/Carton_of_eggs_10.svg';
import CartonOfEggs012CartonOfEggs11 from './Carton_of_eggs_0_-12/Carton_of_eggs_11.svg';
import CartonOfEggs012CartonOfEggs12 from './Carton_of_eggs_0_-12/Carton_of_eggs_12.svg';
import CartonOfEggs012CartonOfEggs2 from './Carton_of_eggs_0_-12/Carton_of_eggs_2.svg';
import CartonOfEggs012CartonOfEggs3 from './Carton_of_eggs_0_-12/Carton_of_eggs_3.svg';
import CartonOfEggs012CartonOfEggs4 from './Carton_of_eggs_0_-12/Carton_of_eggs_4.svg';
import CartonOfEggs012CartonOfEggs5 from './Carton_of_eggs_0_-12/Carton_of_eggs_5.svg';
import CartonOfEggs012CartonOfEggs6 from './Carton_of_eggs_0_-12/Carton_of_eggs_6.svg';
import CartonOfEggs012CartonOfEggs7 from './Carton_of_eggs_0_-12/Carton_of_eggs_7.svg';
import CartonOfEggs012CartonOfEggs8 from './Carton_of_eggs_0_-12/Carton_of_eggs_8.svg';
import CartonOfEggs012CartonOfEggs9 from './Carton_of_eggs_0_-12/Carton_of_eggs_9.svg';
import Cat from './Cat.svg';
import CatWFoodBowl from './Cat_w_food_bowl.svg';
import CelebrationCard from './Celebration_card.svg';
import CelebrationCard1 from './Celebration_card_1.svg';
import CelebrationCard2 from './Celebration_card_2.svg';
import Cell from './Cell.svg';
import CellTop from './CellTop.svg';
import ChairBlue from './ChairBlue.svg';
import ChairPink from './ChairPink.svg';
import CharactersAlexHead from './Characters/AlexHead.svg';
import CharactersAlexStanding from './Characters/AlexStanding.svg';
import CharactersAmirHead from './Characters/AmirHead.svg';
import CharactersAmirStanding from './Characters/AmirStanding.svg';
import CharactersAnnieHead from './Characters/AnnieHead.svg';
import CharactersAnnieStanding from './Characters/AnnieStanding.svg';
import CharactersDexterHead from './Characters/DexterHead.svg';
import CharactersDexterStanding from './Characters/DexterStanding.svg';
import CharactersDirectionJackBody from './Characters/Direction/Jack_body.svg';
import CharactersDirectionKimBody from './Characters/Direction/Kim_body.svg';
import CharactersDirectionMaxBody from './Characters/Direction/Max_body.svg';
import CharactersEvaHead from './Characters/EvaHead.svg';
import CharactersEvaStanding from './Characters/EvaStanding.svg';
import CharactersJackHead from './Characters/JackHead.svg';
import CharactersJackStanding from './Characters/JackStanding.svg';
import CharactersJoHead from './Characters/JoHead.svg';
import CharactersJoStanding from './Characters/JoStanding.svg';
import CharactersKimHead from './Characters/KimHead.svg';
import CharactersKimStanding from './Characters/KimStanding.svg';
import CharactersMaxHead from './Characters/MaxHead.svg';
import CharactersMaxStanding from './Characters/MaxStanding.svg';
import CharactersMoHead from './Characters/MoHead.svg';
import CharactersMoStanding from './Characters/MoStanding.svg';
import CharactersRonHead from './Characters/RonHead.svg';
import CharactersRonStanding from './Characters/RonStanding.svg';
import CharactersRosieHead from './Characters/RosieHead.svg';
import CharactersRosieStanding from './Characters/RosieStanding.svg';
import CharactersSamHead from './Characters/SamHead.svg';
import CharactersSamStanding from './Characters/SamStanding.svg';
import CharactersTeddyHead from './Characters/TeddyHead.svg';
import CharactersTeddyStanding from './Characters/TeddyStanding.svg';
import CharactersTommyHead from './Characters/TommyHead.svg';
import CharactersTommyStanding from './Characters/TommyStanding.svg';
import CharactersWhitneyHead from './Characters/WhitneyHead.svg';
import CharactersWhitneyStanding from './Characters/WhitneyStanding.svg';
import CheckersBoard from './CheckersBoard.svg';
import Cherry from './Cherry.svg';
import ChewSweetCandyBar from './Chew_sweet_candy_bar.svg';
import CirclesCircleQuarterWhite from './Circles/Circle_quarter_white.svg';
import CirclesInnerOuterCirclesInnerOuterCircleLargeA from './Circles/Inner_outer_circles/Inner_outer_circle_large_A.svg';
import CirclesInnerOuterCirclesInnerOuterCircleLargeB from './Circles/Inner_outer_circles/Inner_outer_circle_large_B.svg';
import CirclesInnerOuterCirclesInnerOuterCircleLargeC from './Circles/Inner_outer_circles/Inner_outer_circle_large_C.svg';
import CirclesInnerOuterCirclesInnerOuterCircleMediumA from './Circles/Inner_outer_circles/Inner_outer_circle_medium_A.svg';
import CirclesInnerOuterCirclesInnerOuterCircleMediumB from './Circles/Inner_outer_circles/Inner_outer_circle_medium_B.svg';
import CirclesInnerOuterCirclesInnerOuterCircleMediumC from './Circles/Inner_outer_circles/Inner_outer_circle_medium_C.svg';
import CirclesPartsOfACircleCirclePartsCentreArrow from './Circles/Parts_of_a_circle/Circle_parts_centre_arrow.svg';
import CirclesPartsOfACircleCirclePartsCircumferenceArrow from './Circles/Parts_of_a_circle/Circle_parts_circumference_arrow.svg';
import CirclesPartsOfACircleCirclePartsDiameterArrow from './Circles/Parts_of_a_circle/Circle_parts_diameter_arrow.svg';
import CirclesPartsOfACircleCirclePartsDiameterCorrect from './Circles/Parts_of_a_circle/Circle_parts_diameter_correct.svg';
import CirclesPartsOfACircleCirclePartsDiameterIncorrectA from './Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_A.svg';
import CirclesPartsOfACircleCirclePartsDiameterIncorrectB from './Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_B.svg';
import CirclesPartsOfACircleCirclePartsDiameterIncorrectC from './Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_C.svg';
import CirclesPartsOfACircleCirclePartsDiameterIncorrectD from './Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_D.svg';
import CirclesPartsOfACircleCirclePartsDiameterIncorrectE from './Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_E.svg';
import CirclesPartsOfACircleCirclePartsDiameterIncorrectF from './Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_F.svg';
import CirclesPartsOfACircleCirclePartsRadiusArrow from './Circles/Parts_of_a_circle/Circle_parts_radius_arrow.svg';
import CirclesPartsOfACircleCirclePartsRadiusCorrect from './Circles/Parts_of_a_circle/Circle_parts_radius_correct.svg';
import CirclesPartsOfACircleCirclePartsRadiusIncorrectA from './Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_A.svg';
import CirclesPartsOfACircleCirclePartsRadiusIncorrectB from './Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_B.svg';
import CirclesPartsOfACircleCirclePartsRadiusIncorrectC from './Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_C.svg';
import CirclesPartsOfACircleCirclePartsRadiusIncorrectD from './Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_D.svg';
import CirclesPartsOfACircleCirclePartsRadiusIncorrectE from './Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_E.svg';
import CirclesPartsOfACircleCirclePartsRadiusIncorrectF from './Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_F.svg';
import CirclesCircle from './Circles/circle_.svg';
import CirclesCircleBlack from './Circles/circle_black.svg';
import CirclesCircleBlue from './Circles/circle_blue.svg';
import CirclesCircleGreen from './Circles/circle_green.svg';
import CirclesCircleGrey from './Circles/circle_grey.svg';
import CirclesCircleOrange from './Circles/circle_orange.svg';
import CirclesCircleOrangeProtractor from './Circles/circle_orange_protractor.svg';
import CirclesCirclePink from './Circles/circle_pink.svg';
import CirclesCirclePurple from './Circles/circle_purple.svg';
import CirclesCircleRed from './Circles/circle_red.svg';
import CirclesCircleTurquoise from './Circles/circle_turquoise.svg';
import CirclesCircleWhite from './Circles/circle_white.svg';
import CirclesCircleYellow from './Circles/circle_yellow.svg';
import Clock from './Clock.svg';
import ClockFaceBlue from './ClockFaceBlue.svg';
import ClockFaceBlueWithoutMinuteIntervals from './ClockFaceBlueWithoutMinuteIntervals.svg';
import ClockFaceOrange from './ClockFaceOrange.svg';
import ClockFaceOrangeWithoutMinuteIntervals from './ClockFaceOrangeWithoutMinuteIntervals.svg';
import ClockFaceRomanBlue from './ClockFaceRomanBlue.svg';
import ClockFaceRomanBlueWithoutMinuteIntervals from './ClockFaceRomanBlueWithoutMinuteIntervals.svg';
import ClockFaceRomanOrange from './ClockFaceRomanOrange.svg';
import ClockFaceRomanOrangeWithoutMinuteIntervals from './ClockFaceRomanOrangeWithoutMinuteIntervals.svg';
import ClockFaceRomanYellow from './ClockFaceRomanYellow.svg';
import ClockFaceRomanYellowWithoutMinuteIntervals from './ClockFaceRomanYellowWithoutMinuteIntervals.svg';
import ClockFaceYellow from './ClockFaceYellow.svg';
import ClockFaceYellowWithoutMinuteIntervals from './ClockFaceYellowWithoutMinuteIntervals.svg';
import ClockHour from './ClockHour.svg';
import ClockMinute from './ClockMinute.svg';
import ClockMinuteCircle from './ClockMinuteCircle.svg';
import Close from './Close.svg';
import ClosedBook from './Closed_book.svg';
import ClothesGlovesMittensBlue from './Clothes/Gloves_mittens_blue.svg';
import ClothesGlovesMittensGreen from './Clothes/Gloves_mittens_green.svg';
import ClothesGlovesMittensOrange from './Clothes/Gloves_mittens_orange.svg';
import ClothesGlovesMittensRed from './Clothes/Gloves_mittens_red.svg';
import ClothesGlovesMittensYellowAi from './Clothes/Gloves_mittens_yellow.ai.svg';
import ClothesHatBlue from './Clothes/Hat_blue.svg';
import ClothesHatCap from './Clothes/Hat_cap.svg';
import ClothesHatWoolly from './Clothes/Hat_woolly.svg';
import ClothesJumperBlue from './Clothes/Jumper_blue.svg';
import ClothesScarfPurple from './Clothes/Scarf_purple.svg';
import ClothesScarfWhite from './Clothes/Scarf_white.svg';
import ClothesShortsGrey from './Clothes/Shorts_grey.svg';
import ClothesTShirtBlue from './Clothes/T-shirt_blue.svg';
import Compass4Labels from './Compass_4_labels.svg';
import Compass8Labels from './Compass_8_labels.svg';
import CompostBag from './Compost_bag.svg';
import CompoundShapesEquilateralTriangle2Parts1 from './CompoundShapes/EquilateralTriangle_2_parts_1.svg';
import CompoundShapesEquilateralTriangle3Parts1 from './CompoundShapes/EquilateralTriangle_3_parts_1.svg';
import CompoundShapesEquilateralTriangle4Parts1 from './CompoundShapes/EquilateralTriangle_4_parts_1.svg';
import CompoundShapesEquilateralTriangle4Parts2 from './CompoundShapes/EquilateralTriangle_4_parts_2.svg';
import CompoundShapesEquilateralTriangle5Parts1 from './CompoundShapes/EquilateralTriangle_5_parts_1.svg';
import CompoundShapesQuarterCircle2Parts1 from './CompoundShapes/QuarterCircle_2_parts_1.svg';
import CompoundShapesQuarterCircle3Parts1 from './CompoundShapes/QuarterCircle_3_parts_1.svg';
import CompoundShapesQuarterCircle4Parts1 from './CompoundShapes/QuarterCircle_4_parts_1.svg';
import CompoundShapesQuarterCircle5Parts1 from './CompoundShapes/QuarterCircle_5_parts_1.svg';
import CompoundShapesRATriangle2Parts1 from './CompoundShapes/RATriangle_2_parts_1.svg';
import CompoundShapesRATriangle3Parts1 from './CompoundShapes/RATriangle_3_parts_1.svg';
import CompoundShapesRATriangle4Parts1 from './CompoundShapes/RATriangle_4_parts_1.svg';
import CompoundShapesRATriangle4Parts2 from './CompoundShapes/RATriangle_4_parts_2.svg';
import CompoundShapesRATriangle4Parts3 from './CompoundShapes/RATriangle_4_parts_3.svg';
import CompoundShapesRATriangle5Parts1 from './CompoundShapes/RATriangle_5_parts_1.svg';
import CompoundShapesRectangle2Parts1 from './CompoundShapes/Rectangle_2_parts_1.svg';
import CompoundShapesRectangle3Parts1 from './CompoundShapes/Rectangle_3_parts_1.svg';
import CompoundShapesRectangle4Parts1 from './CompoundShapes/Rectangle_4_parts_1.svg';
import CompoundShapesRectangle4Parts2 from './CompoundShapes/Rectangle_4_parts_2.svg';
import CompoundShapesRectangle5Parts1 from './CompoundShapes/Rectangle_5_parts_1.svg';
import CompoundShapesSquare2Parts1 from './CompoundShapes/Square_2_parts_1.svg';
import CompoundShapesSquare3Parts1 from './CompoundShapes/Square_3_parts_1.svg';
import CompoundShapesSquare3Parts2 from './CompoundShapes/Square_3_parts_2.svg';
import CompoundShapesSquare4Parts1 from './CompoundShapes/Square_4_parts_1.svg';
import CompoundShapesSquare4Parts2 from './CompoundShapes/Square_4_parts_2.svg';
import CompoundShapesSquare5Parts1 from './CompoundShapes/Square_5_parts_1.svg';
import CompoundShapesSquare5Parts2 from './CompoundShapes/Square_5_parts_2.svg';
import ConeDottedLines from './Cone_dotted_lines.svg';
import ContainersContainer from './Containers/container.svg';
import Cookie from './Cookie.svg';
import CookieBiscuitCookieBiscuit5 from './Cookie_biscuit/Cookie_biscuit_5.svg';
import CookieBiscuitCookieBiscuit6 from './Cookie_biscuit/Cookie_biscuit_6.svg';
import CookieBiscuitCookieBiscuit7 from './Cookie_biscuit/Cookie_biscuit_7.svg';
import CookieJar from './Cookie_jar.svg';
import CookingPot from './Cooking_pot.svg';
import CoordinatesCirclePoint from './Coordinates/CirclePoint.svg';
import CoordinatesCrossPoint from './Coordinates/CrossPoint.svg';
import CoordinatesFilledSquarePoint from './Coordinates/FilledSquarePoint.svg';
import CoordinatesSquarePoint from './Coordinates/SquarePoint.svg';
import CoordinatesTrianglePoint from './Coordinates/TrianglePoint.svg';
import CountIn50Balloons50 from './CountIn50/Balloons50.svg';
import CountIn50Bricks50 from './CountIn50/Bricks50.svg';
import CountIn50Crayons50 from './CountIn50/Crayons50.svg';
import CountIn50Lollipops50 from './CountIn50/Lollipops50.svg';
import CountIn50Marbles50 from './CountIn50/Marbles50.svg';
import CountIn50Sweets50 from './CountIn50/Sweets50.svg';
import Counter from './Counter.svg';
import CounterRed from './CounterRed.svg';
import CounterWhite from './CounterWhite.svg';
import CountersFractionsCountersUnitFractions110 from './Counters_fractions/Counters_unit_fractions1_10.svg';
import CountersFractionsCountersUnitFractions111 from './Counters_fractions/Counters_unit_fractions1_11.svg';
import CountersFractionsCountersUnitFractions112 from './Counters_fractions/Counters_unit_fractions1_12.svg';
import CountersFractionsCountersUnitFractions12 from './Counters_fractions/Counters_unit_fractions1_2.svg';
import CountersFractionsCountersUnitFractions13 from './Counters_fractions/Counters_unit_fractions1_3.svg';
import CountersFractionsCountersUnitFractions14 from './Counters_fractions/Counters_unit_fractions1_4.svg';
import CountersFractionsCountersUnitFractions15 from './Counters_fractions/Counters_unit_fractions1_5.svg';
import CountersFractionsCountersUnitFractions16 from './Counters_fractions/Counters_unit_fractions1_6.svg';
import CountersFractionsCountersUnitFractions17 from './Counters_fractions/Counters_unit_fractions1_7.svg';
import CountersFractionsCountersUnitFractions18 from './Counters_fractions/Counters_unit_fractions1_8.svg';
import CountersFractionsCountersUnitFractions19 from './Counters_fractions/Counters_unit_fractions1_9.svg';
import CountersFractionsTextOutlinedCountersUnitFractions110TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_10_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions111TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_11_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions112TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_12_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions12TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_2_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions13TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_3_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions14TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_4_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions15TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_5_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions16AiTextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_6.ai_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions17TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_7_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions18TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_8_text_outlined.svg';
import CountersFractionsTextOutlinedCountersUnitFractions19TextOutlined from './Counters_fractions/Text_outlined/Counters_unit_fractions1_9_text_outlined.svg';
import Cow from './Cow.svg';
import CrayonBox from './CrayonBox.svg';
import CrayonsBoxPackOf8 from './Crayons_box_pack_of_8.svg';
import CrossesCross from './Crosses/cross.svg';
import CrossesCrossBlue from './Crosses/cross_blue.svg';
import CrossesCrossGreen from './Crosses/cross_green.svg';
import CrossesCrossOrange from './Crosses/cross_orange.svg';
import CrossesCrossPink from './Crosses/cross_pink.svg';
import CrossesCrossPurple from './Crosses/cross_purple.svg';
import CrossesCrossRed from './Crosses/cross_red.svg';
import CrossesCrossTurquoise from './Crosses/cross_turquoise.svg';
import CrossesCrossWhite from './Crosses/cross_white.svg';
import CrossesCrossYellow from './Crosses/cross_yellow.svg';
import CubesWith1ArrowCube1Arrow from './Cubes_With_1_Arrow/cube1_arrow.svg';
import CubesWith1ArrowCube2Arrow from './Cubes_With_1_Arrow/cube2_arrow.svg';
import CubesWith1ArrowCube3Arrow from './Cubes_With_1_Arrow/cube3_arrow.svg';
import CubesWith3ArrowsCube3ArrowsBlue from './Cubes_With_3_Arrows/Cube_3_arrows_blue.svg';
import CubesWith3ArrowsCube3ArrowsGreen from './Cubes_With_3_Arrows/Cube_3_arrows_green.svg';
import CubesWith3ArrowsCube3ArrowsRed from './Cubes_With_3_Arrows/Cube_3_arrows_red.svg';
import CubesWith3ArrowsCube3ArrowsWhite from './Cubes_With_3_Arrows/Cube_3_arrows_white.svg';
import CubesWith3ArrowsCube3ArrowsYellow from './Cubes_With_3_Arrows/Cube_3_arrows_yellow.svg';
import CubesBlankColouredCubeUnlabelledBlue from './Cubes_blank/Coloured_cube_unlabelled_blue.svg';
import CubesBlankColouredCubeUnlabelledGreen from './Cubes_blank/Coloured_cube_unlabelled_green.svg';
import CubesBlankColouredCubeUnlabelledOrange from './Cubes_blank/Coloured_cube_unlabelled_orange.svg';
import CubesBlankColouredCubeUnlabelledPurple from './Cubes_blank/Coloured_cube_unlabelled_purple.svg';
import CubesBlankColouredCubeUnlabelledRed from './Cubes_blank/Coloured_cube_unlabelled_red.svg';
import CubesBlankColouredCubeUnlabelledYellow from './Cubes_blank/Coloured_cube_unlabelled_yellow.svg';
import CubesLabelledColouredCubeLabelledBlue from './Cubes_labelled/Coloured_cube_labelled_blue.svg';
import CubesLabelledColouredCubeLabelledGreen from './Cubes_labelled/Coloured_cube_labelled_green.svg';
import CubesLabelledColouredCubeLabelledOrange from './Cubes_labelled/Coloured_cube_labelled_orange.svg';
import CubesLabelledColouredCubeLabelledPurple from './Cubes_labelled/Coloured_cube_labelled_purple.svg';
import CubesLabelledColouredCubeLabelledRed from './Cubes_labelled/Coloured_cube_labelled_red.svg';
import CubesLabelledColouredCubeLabelledYellow from './Cubes_labelled/Coloured_cube_labelled_yellow.svg';
import CuboidsCuboid1 from './Cuboids/Cuboid1.svg';
import CuboidsCuboid2 from './Cuboids/Cuboid2.svg';
import CuboidsCuboid3 from './Cuboids/Cuboid3.svg';
import CuboidsWith3ArrowsCuboid13Arrows from './Cuboids_With_3_Arrows/cuboid1_3_arrows.svg';
import CuboidsWith3ArrowsCuboid23Arrows from './Cuboids_With_3_Arrows/cuboid2_3_arrows.svg';
import CuboidsWith3ArrowsCuboid33Arrows from './Cuboids_With_3_Arrows/cuboid3_3_arrows.svg';
import CuboidsWith3ArrowsCuboid43Arrows from './Cuboids_With_3_Arrows/cuboid4_3_arrows.svg';
import CuboidsWith3ArrowsCuboid53Arrows from './Cuboids_With_3_Arrows/cuboid5_3_arrows.svg';
import CuboidsWith3ArrowsCuboid63Arrows from './Cuboids_With_3_Arrows/cuboid6_3_arrows.svg';
import Desk from './Desk.svg';
import Dice from './Dice.svg';
import DiceFaces1 from './Dice_faces/1.svg';
import DiceFaces2 from './Dice_faces/2.svg';
import DiceFaces3 from './Dice_faces/3.svg';
import DiceFaces4 from './Dice_faces/4.svg';
import DiceFaces5 from './Dice_faces/5.svg';
import DiceFaces6 from './Dice_faces/6.svg';
import DigitalStopwatch from './DigitalStopwatch.svg';
import DodecagonsIrregularDodecagon1Blue from './Dodecagons/Irregular_dodecagon1_blue.svg';
import DodecagonsIrregularDodecagon1Green from './Dodecagons/Irregular_dodecagon1_green.svg';
import DodecagonsIrregularDodecagon1Pink from './Dodecagons/Irregular_dodecagon1_pink.svg';
import DodecagonsIrregularDodecagon1Purple from './Dodecagons/Irregular_dodecagon1_purple.svg';
import DodecagonsIrregularDodecagon1White from './Dodecagons/Irregular_dodecagon1_white.svg';
import DodecagonsIrregularDodecagon1Yellow from './Dodecagons/Irregular_dodecagon1_yellow.svg';
import DodecagonsIrregularDodecagon2Blue from './Dodecagons/Irregular_dodecagon2_blue.svg';
import DodecagonsIrregularDodecagon2Green from './Dodecagons/Irregular_dodecagon2_green.svg';
import DodecagonsIrregularDodecagon2Pink from './Dodecagons/Irregular_dodecagon2_pink.svg';
import DodecagonsIrregularDodecagon2Purple from './Dodecagons/Irregular_dodecagon2_purple.svg';
import DodecagonsIrregularDodecagon2White from './Dodecagons/Irregular_dodecagon2_white.svg';
import DodecagonsIrregularDodecagon2Yellow from './Dodecagons/Irregular_dodecagon2_yellow.svg';
import DogsDog1 from './Dogs/dog1.svg';
import DogsDog2 from './Dogs/dog2.svg';
import DogsDog3 from './Dogs/dog3.svg';
import DonutsInABoxDonutsInABox0 from './Donuts_in_a_box/Donuts_in_a_box_0.svg';
import DonutsInABoxDonutsInABox1 from './Donuts_in_a_box/Donuts_in_a_box_1.svg';
import DonutsInABoxDonutsInABox10 from './Donuts_in_a_box/Donuts_in_a_box_10.svg';
import DonutsInABoxDonutsInABox11 from './Donuts_in_a_box/Donuts_in_a_box_11.svg';
import DonutsInABoxDonutsInABox12 from './Donuts_in_a_box/Donuts_in_a_box_12.svg';
import DonutsInABoxDonutsInABox2 from './Donuts_in_a_box/Donuts_in_a_box_2.svg';
import DonutsInABoxDonutsInABox3 from './Donuts_in_a_box/Donuts_in_a_box_3.svg';
import DonutsInABoxDonutsInABox4 from './Donuts_in_a_box/Donuts_in_a_box_4.svg';
import DonutsInABoxDonutsInABox5 from './Donuts_in_a_box/Donuts_in_a_box_5.svg';
import DonutsInABoxDonutsInABox6 from './Donuts_in_a_box/Donuts_in_a_box_6.svg';
import DonutsInABoxDonutsInABox7 from './Donuts_in_a_box/Donuts_in_a_box_7.svg';
import DonutsInABoxDonutsInABox8 from './Donuts_in_a_box/Donuts_in_a_box_8.svg';
import DonutsInABoxDonutsInABox9 from './Donuts_in_a_box/Donuts_in_a_box_9.svg';
import DonutsIndividualDonut1 from './Donuts_individual/Donut_1.svg';
import DonutsIndividualDonut2 from './Donuts_individual/Donut_2.svg';
import DonutsIndividualDonut3 from './Donuts_individual/Donut_3.svg';
import DonutsIndividualDonut4 from './Donuts_individual/Donut_4.svg';
import DonutsIndividualDonut5 from './Donuts_individual/Donut_5.svg';
import DonutsIndividualDonut6 from './Donuts_individual/Donut_6.svg';
import Door from './Door.svg';
import DumbbellWeights from './Dumbbell_weights.svg';
import Elephant from './Elephant.svg';
import EllipsesEllipseBlue from './Ellipses/Ellipse_blue.svg';
import EllipsesEllipseGreen from './Ellipses/Ellipse_green.svg';
import EllipsesEllipseOrange from './Ellipses/Ellipse_orange.svg';
import EllipsesEllipsePink from './Ellipses/Ellipse_pink.svg';
import EllipsesEllipsePurple from './Ellipses/Ellipse_purple.svg';
import EllipsesEllipseRed from './Ellipses/Ellipse_red.svg';
import EllipsesEllipseTurquoise from './Ellipses/Ellipse_turquoise.svg';
import EllipsesEllipseYellow from './Ellipses/Ellipse_yellow.svg';
import EmptyBucket from './Empty_bucket.svg';
import EmptyBucketBlue from './Empty_bucket_blue.svg';
import EmptyBucketRectangular from './Empty_bucket_rectangular.svg';
import EmptyBucketRectangularBlue from './Empty_bucket_rectangular_blue.svg';
import EmptyBucketTransparent from './Empty_bucket_transparent.svg';
import EmptyJug from './Empty_jug.svg';
import EqualGroupsApples0 from './Equal_groups/Apples0.svg';
import EqualGroupsApples1 from './Equal_groups/Apples1.svg';
import EqualGroupsApples2 from './Equal_groups/Apples2.svg';
import EqualGroupsApples3 from './Equal_groups/Apples3.svg';
import EqualGroupsApples4 from './Equal_groups/Apples4.svg';
import EqualGroupsApples5 from './Equal_groups/Apples5.svg';
import EqualGroupsApples6 from './Equal_groups/Apples6.svg';
import EqualGroupsCookies0 from './Equal_groups/Cookies0.svg';
import EqualGroupsCookies1 from './Equal_groups/Cookies1.svg';
import EqualGroupsCookies2 from './Equal_groups/Cookies2.svg';
import EqualGroupsCookies3 from './Equal_groups/Cookies3.svg';
import EqualGroupsCookies4 from './Equal_groups/Cookies4.svg';
import EqualGroupsCookies5 from './Equal_groups/Cookies5.svg';
import EqualGroupsCookies6 from './Equal_groups/Cookies6.svg';
import EqualGroupsEggs0 from './Equal_groups/Eggs0.svg';
import EqualGroupsEggs1 from './Equal_groups/Eggs1.svg';
import EqualGroupsEggs2 from './Equal_groups/Eggs2.svg';
import EqualGroupsEggs3 from './Equal_groups/Eggs3.svg';
import EqualGroupsEggs4 from './Equal_groups/Eggs4.svg';
import EqualGroupsEggs5 from './Equal_groups/Eggs5.svg';
import EqualGroupsEggs6 from './Equal_groups/Eggs6.svg';
import EqualGroupsFlowers0 from './Equal_groups/Flowers0.svg';
import EqualGroupsFlowers1 from './Equal_groups/Flowers1.svg';
import EqualGroupsFlowers2 from './Equal_groups/Flowers2.svg';
import EqualGroupsFlowers3 from './Equal_groups/Flowers3.svg';
import EqualGroupsFlowers4 from './Equal_groups/Flowers4.svg';
import EqualGroupsFlowers5 from './Equal_groups/Flowers5.svg';
import EqualGroupsFlowers6 from './Equal_groups/Flowers6.svg';
import EqualGroupsPencils0 from './Equal_groups/Pencils0.svg';
import EqualGroupsPencils1 from './Equal_groups/Pencils1.svg';
import EqualGroupsPencils2 from './Equal_groups/Pencils2.svg';
import EqualGroupsPencils3 from './Equal_groups/Pencils3.svg';
import EqualGroupsPencils4 from './Equal_groups/Pencils4.svg';
import EqualGroupsPencils5 from './Equal_groups/Pencils5.svg';
import EqualGroupsPencils6 from './Equal_groups/Pencils6.svg';
import EqualGroupsPizza1 from './Equal_groups/Pizza1.svg';
import EqualGroupsPizza2 from './Equal_groups/Pizza2.svg';
import EqualGroupsPizza3 from './Equal_groups/Pizza3.svg';
import EqualGroupsPizza4 from './Equal_groups/Pizza4.svg';
import EqualGroupsPizza5 from './Equal_groups/Pizza5.svg';
import EqualGroupsPizza6 from './Equal_groups/Pizza6.svg';
import EqualShapes10PartsCircleEqual1010 from './Equal_shapes_10_parts/Circle_equal_10-10.svg';
import EqualShapes10PartsPentagon1010 from './Equal_shapes_10_parts/Pentagon_10-10.svg';
import EqualShapes10PartsSquareEqual1010 from './Equal_shapes_10_parts/Square_equal_10-10.svg';
import EqualShapes11PartsCircleEqual1111 from './Equal_shapes_11_parts/Circle_equal_11-11.svg';
import EqualShapes11PartsRectangleEqual1111 from './Equal_shapes_11_parts/Rectangle_equal_11-11.svg';
import EqualShapes11PartsSquareEqual1111 from './Equal_shapes_11_parts/Square_equal_11-11.svg';
import EqualShapes12PartsHexagon1212 from './Equal_shapes_12_parts/Hexagon_12-12.svg';
import EqualShapes12PartsRectangleEqual1212 from './Equal_shapes_12_parts/Rectangle_equal_12-12.svg';
import EqualShapes12PartsSquareEqual1212 from './Equal_shapes_12_parts/Square_equal_12-12.svg';
import EqualShapes2PartsCircleEqual201 from './Equal_shapes_2_parts/Circle_equal_2-0_1.svg';
import EqualShapes2PartsCircleEqual211Blue from './Equal_shapes_2_parts/Circle_equal_2-1_1_blue.svg';
import EqualShapes2PartsCircleEqual211Green from './Equal_shapes_2_parts/Circle_equal_2-1_1_green.svg';
import EqualShapes2PartsCircleEqual211Yellow from './Equal_shapes_2_parts/Circle_equal_2-1_1_yellow.svg';
import EqualShapes2PartsCircleEqual221Blue from './Equal_shapes_2_parts/Circle_equal_2-2_1_blue.svg';
import EqualShapes2PartsCircleEqual221Green from './Equal_shapes_2_parts/Circle_equal_2-2_1_green.svg';
import EqualShapes2PartsCircleEqual221Yellow from './Equal_shapes_2_parts/Circle_equal_2-2_1_yellow.svg';
import EqualShapes2PartsHexagonEqual211Blue from './Equal_shapes_2_parts/Hexagon_equal_2-1_1_blue.svg';
import EqualShapes2PartsHexagonEqual211Green from './Equal_shapes_2_parts/Hexagon_equal_2-1_1_green.svg';
import EqualShapes2PartsHexagonEqual211Yellow from './Equal_shapes_2_parts/Hexagon_equal_2-1_1_yellow.svg';
import EqualShapes2PartsHexagonEqual221Blue from './Equal_shapes_2_parts/Hexagon_equal_2-2_1_blue.svg';
import EqualShapes2PartsHexagonEqual221Green from './Equal_shapes_2_parts/Hexagon_equal_2-2_1_green.svg';
import EqualShapes2PartsHexagonEqual221Yellow from './Equal_shapes_2_parts/Hexagon_equal_2-2_1_yellow.svg';
import EqualShapes2PartsKiteEqual12 from './Equal_shapes_2_parts/Kite_equal_1-2.svg';
import EqualShapes2PartsKiteEqual211Blue from './Equal_shapes_2_parts/Kite_equal_2-1_1_blue.svg';
import EqualShapes2PartsKiteEqual211Green from './Equal_shapes_2_parts/Kite_equal_2-1_1_green.svg';
import EqualShapes2PartsKiteEqual211Yellow from './Equal_shapes_2_parts/Kite_equal_2-1_1_yellow.svg';
import EqualShapes2PartsKiteEqual22 from './Equal_shapes_2_parts/Kite_equal_2-2.svg';
import EqualShapes2PartsKiteEqual221Blue from './Equal_shapes_2_parts/Kite_equal_2-2_1_blue.svg';
import EqualShapes2PartsKiteEqual221Green from './Equal_shapes_2_parts/Kite_equal_2-2_1_green.svg';
import EqualShapes2PartsKiteEqual221Yellow from './Equal_shapes_2_parts/Kite_equal_2-2_1_yellow.svg';
import EqualShapes2PartsOctagonEqual211Blue from './Equal_shapes_2_parts/Octagon_equal_2-1_1_blue.svg';
import EqualShapes2PartsOctagonEqual211Green from './Equal_shapes_2_parts/Octagon_equal_2-1_1_green.svg';
import EqualShapes2PartsOctagonEqual211Yellow from './Equal_shapes_2_parts/Octagon_equal_2-1_1_yellow.svg';
import EqualShapes2PartsOctagonEqual221Blue from './Equal_shapes_2_parts/Octagon_equal_2-2_1_blue.svg';
import EqualShapes2PartsOctagonEqual221Green from './Equal_shapes_2_parts/Octagon_equal_2-2_1_green.svg';
import EqualShapes2PartsOctagonEqual221Yellow from './Equal_shapes_2_parts/Octagon_equal_2-2_1_yellow.svg';
import EqualShapes2PartsRectangleEqual202 from './Equal_shapes_2_parts/Rectangle_equal_2-0_2.svg';
import EqualShapes2PartsRectangleEqual211Blue from './Equal_shapes_2_parts/Rectangle_equal_2-1_1_blue.svg';
import EqualShapes2PartsRectangleEqual211Green from './Equal_shapes_2_parts/Rectangle_equal_2-1_1_green.svg';
import EqualShapes2PartsRectangleEqual211Yellow from './Equal_shapes_2_parts/Rectangle_equal_2-1_1_yellow.svg';
import EqualShapes2PartsRectangleEqual212Blue from './Equal_shapes_2_parts/Rectangle_equal_2-1_2_blue.svg';
import EqualShapes2PartsRectangleEqual212Green from './Equal_shapes_2_parts/Rectangle_equal_2-1_2_green.svg';
import EqualShapes2PartsRectangleEqual212Yellow from './Equal_shapes_2_parts/Rectangle_equal_2-1_2_yellow.svg';
import EqualShapes2PartsRectangleEqual213Blue from './Equal_shapes_2_parts/Rectangle_equal_2-1_3_blue.svg';
import EqualShapes2PartsRectangleEqual213Green from './Equal_shapes_2_parts/Rectangle_equal_2-1_3_green.svg';
import EqualShapes2PartsRectangleEqual213Yellow from './Equal_shapes_2_parts/Rectangle_equal_2-1_3_yellow.svg';
import EqualShapes2PartsRectangleEqual221Blue from './Equal_shapes_2_parts/Rectangle_equal_2-2_1_blue.svg';
import EqualShapes2PartsRectangleEqual221Green from './Equal_shapes_2_parts/Rectangle_equal_2-2_1_green.svg';
import EqualShapes2PartsRectangleEqual221Yellow from './Equal_shapes_2_parts/Rectangle_equal_2-2_1_yellow.svg';
import EqualShapes2PartsRectangleEqual222Blue from './Equal_shapes_2_parts/Rectangle_equal_2-2_2_blue.svg';
import EqualShapes2PartsRectangleEqual222Green from './Equal_shapes_2_parts/Rectangle_equal_2-2_2_green.svg';
import EqualShapes2PartsRectangleEqual222Yellow from './Equal_shapes_2_parts/Rectangle_equal_2-2_2_yellow.svg';
import EqualShapes2PartsRectangleEqual223Blue from './Equal_shapes_2_parts/Rectangle_equal_2-2_3_blue.svg';
import EqualShapes2PartsRectangleEqual223Green from './Equal_shapes_2_parts/Rectangle_equal_2-2_3_green.svg';
import EqualShapes2PartsRectangleEqual223Yellow from './Equal_shapes_2_parts/Rectangle_equal_2-2_3_yellow.svg';
import EqualShapes2PartsSquareEqual12 from './Equal_shapes_2_parts/Square_equal_1-2.svg';
import EqualShapes2PartsSquareEqual201 from './Equal_shapes_2_parts/Square_equal_2-0_1.svg';
import EqualShapes2PartsSquareEqual211Blue from './Equal_shapes_2_parts/Square_equal_2-1_1_blue.svg';
import EqualShapes2PartsSquareEqual211Green from './Equal_shapes_2_parts/Square_equal_2-1_1_green.svg';
import EqualShapes2PartsSquareEqual211Yellow from './Equal_shapes_2_parts/Square_equal_2-1_1_yellow.svg';
import EqualShapes2PartsSquareEqual212Blue from './Equal_shapes_2_parts/Square_equal_2-1_2_blue.svg';
import EqualShapes2PartsSquareEqual212Green from './Equal_shapes_2_parts/Square_equal_2-1_2_green.svg';
import EqualShapes2PartsSquareEqual212Yellow from './Equal_shapes_2_parts/Square_equal_2-1_2_yellow.svg';
import EqualShapes2PartsSquareEqual22 from './Equal_shapes_2_parts/Square_equal_2-2.svg';
import EqualShapes2PartsSquareEqual221Blue from './Equal_shapes_2_parts/Square_equal_2-2_1_blue.svg';
import EqualShapes2PartsSquareEqual221Green from './Equal_shapes_2_parts/Square_equal_2-2_1_green.svg';
import EqualShapes2PartsSquareEqual221Yellow from './Equal_shapes_2_parts/Square_equal_2-2_1_yellow.svg';
import EqualShapes2PartsSquareEqual222Blue from './Equal_shapes_2_parts/Square_equal_2-2_2_blue.svg';
import EqualShapes2PartsSquareEqual222Green from './Equal_shapes_2_parts/Square_equal_2-2_2_green.svg';
import EqualShapes2PartsSquareEqual222Yellow from './Equal_shapes_2_parts/Square_equal_2-2_2_yellow.svg';
import EqualShapes2PartsTriangleEqual12 from './Equal_shapes_2_parts/Triangle_equal_1-2.svg';
import EqualShapes2PartsTriangleEqual201 from './Equal_shapes_2_parts/Triangle_equal_2-0_1.svg';
import EqualShapes2PartsTriangleEqual211Blue from './Equal_shapes_2_parts/Triangle_equal_2-1_1_blue.svg';
import EqualShapes2PartsTriangleEqual211Green from './Equal_shapes_2_parts/Triangle_equal_2-1_1_green.svg';
import EqualShapes2PartsTriangleEqual211Yellow from './Equal_shapes_2_parts/Triangle_equal_2-1_1_yellow.svg';
import EqualShapes2PartsTriangleEqual22 from './Equal_shapes_2_parts/Triangle_equal_2-2.svg';
import EqualShapes2PartsTriangleEqual221Blue from './Equal_shapes_2_parts/Triangle_equal_2-2_1_blue.svg';
import EqualShapes2PartsTriangleEqual221Green from './Equal_shapes_2_parts/Triangle_equal_2-2_1_green.svg';
import EqualShapes2PartsTriangleEqual221Yellow from './Equal_shapes_2_parts/Triangle_equal_2-2_1_yellow.svg';
import EqualShapes3PartsArrowEqual311Blue from './Equal_shapes_3_parts/Arrow_equal_3-1_1_blue.svg';
import EqualShapes3PartsArrowEqual311Green from './Equal_shapes_3_parts/Arrow_equal_3-1_1_green.svg';
import EqualShapes3PartsArrowEqual311Yellow from './Equal_shapes_3_parts/Arrow_equal_3-1_1_yellow.svg';
import EqualShapes3PartsCircleEqual301 from './Equal_shapes_3_parts/Circle_equal_3-0_1.svg';
import EqualShapes3PartsCircleEqual311Blue from './Equal_shapes_3_parts/Circle_equal_3-1_1_blue.svg';
import EqualShapes3PartsCircleEqual311Green from './Equal_shapes_3_parts/Circle_equal_3-1_1_green.svg';
import EqualShapes3PartsCircleEqual311Yellow from './Equal_shapes_3_parts/Circle_equal_3-1_1_yellow.svg';
import EqualShapes3PartsCircleEqual321Blue from './Equal_shapes_3_parts/Circle_equal_3-2_1_blue.svg';
import EqualShapes3PartsCircleEqual321Green from './Equal_shapes_3_parts/Circle_equal_3-2_1_green.svg';
import EqualShapes3PartsCircleEqual321Yellow from './Equal_shapes_3_parts/Circle_equal_3-2_1_yellow.svg';
import EqualShapes3PartsCircleEqual33 from './Equal_shapes_3_parts/Circle_equal_3-3.svg';
import EqualShapes3PartsCircleEqual331Blue from './Equal_shapes_3_parts/Circle_equal_3-3_1_blue.svg';
import EqualShapes3PartsCircleEqual331Green from './Equal_shapes_3_parts/Circle_equal_3-3_1_green.svg';
import EqualShapes3PartsCircleEqual331Yellow from './Equal_shapes_3_parts/Circle_equal_3-3_1_yellow.svg';
import EqualShapes3PartsRectangleEqual301 from './Equal_shapes_3_parts/Rectangle_equal_3-0_1.svg';
import EqualShapes3PartsRectangleEqual311Blue from './Equal_shapes_3_parts/Rectangle_equal_3-1_1_blue.svg';
import EqualShapes3PartsRectangleEqual311Green from './Equal_shapes_3_parts/Rectangle_equal_3-1_1_green.svg';
import EqualShapes3PartsRectangleEqual311Yellow from './Equal_shapes_3_parts/Rectangle_equal_3-1_1_yellow.svg';
import EqualShapes3PartsRectangleEqual321Blue from './Equal_shapes_3_parts/Rectangle_equal_3-2_1_blue.svg';
import EqualShapes3PartsRectangleEqual321Green from './Equal_shapes_3_parts/Rectangle_equal_3-2_1_green.svg';
import EqualShapes3PartsRectangleEqual321Yellow from './Equal_shapes_3_parts/Rectangle_equal_3-2_1_yellow.svg';
import EqualShapes3PartsRectangleEqual331Blue from './Equal_shapes_3_parts/Rectangle_equal_3-3_1_blue.svg';
import EqualShapes3PartsRectangleEqual331Green from './Equal_shapes_3_parts/Rectangle_equal_3-3_1_green.svg';
import EqualShapes3PartsRectangleEqual331Yellow from './Equal_shapes_3_parts/Rectangle_equal_3-3_1_yellow.svg';
import EqualShapes3PartsSquareEqual13 from './Equal_shapes_3_parts/Square_equal_1-3.svg';
import EqualShapes3PartsSquareEqual301 from './Equal_shapes_3_parts/Square_equal_3-0_1.svg';
import EqualShapes3PartsSquareEqual311Blue from './Equal_shapes_3_parts/Square_equal_3-1_1_blue.svg';
import EqualShapes3PartsSquareEqual311Green from './Equal_shapes_3_parts/Square_equal_3-1_1_green.svg';
import EqualShapes3PartsSquareEqual311Yellow from './Equal_shapes_3_parts/Square_equal_3-1_1_yellow.svg';
import EqualShapes3PartsSquareEqual321Blue from './Equal_shapes_3_parts/Square_equal_3-2_1_blue.svg';
import EqualShapes3PartsSquareEqual321Green from './Equal_shapes_3_parts/Square_equal_3-2_1_green.svg';
import EqualShapes3PartsSquareEqual321Yellow from './Equal_shapes_3_parts/Square_equal_3-2_1_yellow.svg';
import EqualShapes3PartsSquareEqual33 from './Equal_shapes_3_parts/Square_equal_3-3.svg';
import EqualShapes3PartsSquareEqual331Blue from './Equal_shapes_3_parts/Square_equal_3-3_1_blue.svg';
import EqualShapes3PartsSquareEqual331Green from './Equal_shapes_3_parts/Square_equal_3-3_1_green.svg';
import EqualShapes3PartsSquareEqual331Yellow from './Equal_shapes_3_parts/Square_equal_3-3_1_yellow.svg';
import EqualShapes3PartsTriangleEqual13 from './Equal_shapes_3_parts/Triangle_equal_1-3.svg';
import EqualShapes3PartsTriangleEqual301 from './Equal_shapes_3_parts/Triangle_equal_3-0_1.svg';
import EqualShapes3PartsTriangleEqual311Blue from './Equal_shapes_3_parts/Triangle_equal_3-1_1_blue.svg';
import EqualShapes3PartsTriangleEqual311Green from './Equal_shapes_3_parts/Triangle_equal_3-1_1_green.svg';
import EqualShapes3PartsTriangleEqual311Yellow from './Equal_shapes_3_parts/Triangle_equal_3-1_1_yellow.svg';
import EqualShapes3PartsTriangleEqual321Blue from './Equal_shapes_3_parts/Triangle_equal_3-2_1_blue.svg';
import EqualShapes3PartsTriangleEqual321Green from './Equal_shapes_3_parts/Triangle_equal_3-2_1_green.svg';
import EqualShapes3PartsTriangleEqual321Yellow from './Equal_shapes_3_parts/Triangle_equal_3-2_1_yellow.svg';
import EqualShapes3PartsTriangleEqual33 from './Equal_shapes_3_parts/Triangle_equal_3-3.svg';
import EqualShapes3PartsTriangleEqual331Blue from './Equal_shapes_3_parts/Triangle_equal_3-3_1_blue.svg';
import EqualShapes3PartsTriangleEqual331Green from './Equal_shapes_3_parts/Triangle_equal_3-3_1_green.svg';
import EqualShapes3PartsTriangleEqual331Yellow from './Equal_shapes_3_parts/Triangle_equal_3-3_1_yellow.svg';
import EqualShapes4PartsCircleEqual401 from './Equal_shapes_4_parts/Circle_equal_4-0_1.svg';
import EqualShapes4PartsCircleEqual411Blue from './Equal_shapes_4_parts/Circle_equal_4-1_1_blue.svg';
import EqualShapes4PartsCircleEqual411Green from './Equal_shapes_4_parts/Circle_equal_4-1_1_green.svg';
import EqualShapes4PartsCircleEqual411Yellow from './Equal_shapes_4_parts/Circle_equal_4-1_1_yellow.svg';
import EqualShapes4PartsCircleEqual421Blue from './Equal_shapes_4_parts/Circle_equal_4-2_1_blue.svg';
import EqualShapes4PartsCircleEqual421Green from './Equal_shapes_4_parts/Circle_equal_4-2_1_green.svg';
import EqualShapes4PartsCircleEqual421Yellow from './Equal_shapes_4_parts/Circle_equal_4-2_1_yellow.svg';
import EqualShapes4PartsCircleEqual431Blue from './Equal_shapes_4_parts/Circle_equal_4-3_1_blue.svg';
import EqualShapes4PartsCircleEqual431Green from './Equal_shapes_4_parts/Circle_equal_4-3_1_green.svg';
import EqualShapes4PartsCircleEqual431Yellow from './Equal_shapes_4_parts/Circle_equal_4-3_1_yellow.svg';
import EqualShapes4PartsCircleEqual44 from './Equal_shapes_4_parts/Circle_equal_4-4.svg';
import EqualShapes4PartsCircleEqual441Blue from './Equal_shapes_4_parts/Circle_equal_4-4_1_blue.svg';
import EqualShapes4PartsCircleEqual441Green from './Equal_shapes_4_parts/Circle_equal_4-4_1_green.svg';
import EqualShapes4PartsCircleEqual441Yellow from './Equal_shapes_4_parts/Circle_equal_4-4_1_yellow.svg';
import EqualShapes4PartsCrossEqual14 from './Equal_shapes_4_parts/Cross_equal_1-4.svg';
import EqualShapes4PartsCrossEqual34 from './Equal_shapes_4_parts/Cross_equal_3-4.svg';
import EqualShapes4PartsCrossEqual44 from './Equal_shapes_4_parts/Cross_equal_4-4.svg';
import EqualShapes4PartsHexagonEqual411Blue from './Equal_shapes_4_parts/Hexagon_equal_4-1_1_blue.svg';
import EqualShapes4PartsHexagonEqual411Green from './Equal_shapes_4_parts/Hexagon_equal_4-1_1_green.svg';
import EqualShapes4PartsHexagonEqual411Yellow from './Equal_shapes_4_parts/Hexagon_equal_4-1_1_yellow.svg';
import EqualShapes4PartsHexagonEqual421Blue from './Equal_shapes_4_parts/Hexagon_equal_4-2_1_blue.svg';
import EqualShapes4PartsHexagonEqual421Green from './Equal_shapes_4_parts/Hexagon_equal_4-2_1_green.svg';
import EqualShapes4PartsHexagonEqual421Yellow from './Equal_shapes_4_parts/Hexagon_equal_4-2_1_yellow.svg';
import EqualShapes4PartsHexagonEqual431Blue from './Equal_shapes_4_parts/Hexagon_equal_4-3_1_blue.svg';
import EqualShapes4PartsHexagonEqual431Green from './Equal_shapes_4_parts/Hexagon_equal_4-3_1_green.svg';
import EqualShapes4PartsHexagonEqual431Yellow from './Equal_shapes_4_parts/Hexagon_equal_4-3_1_yellow.svg';
import EqualShapes4PartsHexagonEqual441Blue from './Equal_shapes_4_parts/Hexagon_equal_4-4_1_blue.svg';
import EqualShapes4PartsHexagonEqual441Green from './Equal_shapes_4_parts/Hexagon_equal_4-4_1_green.svg';
import EqualShapes4PartsHexagonEqual441Yellow from './Equal_shapes_4_parts/Hexagon_equal_4-4_1_yellow.svg';
import EqualShapes4PartsOctagonEqual411Blue from './Equal_shapes_4_parts/Octagon_equal_4-1_1_blue.svg';
import EqualShapes4PartsOctagonEqual411Green from './Equal_shapes_4_parts/Octagon_equal_4-1_1_green.svg';
import EqualShapes4PartsOctagonEqual411Yellow from './Equal_shapes_4_parts/Octagon_equal_4-1_1_yellow.svg';
import EqualShapes4PartsOctagonEqual421Blue from './Equal_shapes_4_parts/Octagon_equal_4-2_1_blue.svg';
import EqualShapes4PartsOctagonEqual421Green from './Equal_shapes_4_parts/Octagon_equal_4-2_1_green.svg';
import EqualShapes4PartsOctagonEqual421Yellow from './Equal_shapes_4_parts/Octagon_equal_4-2_1_yellow.svg';
import EqualShapes4PartsOctagonEqual431Blue from './Equal_shapes_4_parts/Octagon_equal_4-3_1_blue.svg';
import EqualShapes4PartsOctagonEqual431Green from './Equal_shapes_4_parts/Octagon_equal_4-3_1_green.svg';
import EqualShapes4PartsOctagonEqual431Yellow from './Equal_shapes_4_parts/Octagon_equal_4-3_1_yellow.svg';
import EqualShapes4PartsOctagonEqual441Blue from './Equal_shapes_4_parts/Octagon_equal_4-4_1_blue.svg';
import EqualShapes4PartsOctagonEqual441Green from './Equal_shapes_4_parts/Octagon_equal_4-4_1_green.svg';
import EqualShapes4PartsOctagonEqual441Yellow from './Equal_shapes_4_parts/Octagon_equal_4-4_1_yellow.svg';
import EqualShapes4PartsRectangleEqual402 from './Equal_shapes_4_parts/Rectangle_equal_4-0_2.svg';
import EqualShapes4PartsRectangleEqual411Blue from './Equal_shapes_4_parts/Rectangle_equal_4-1_1_blue.svg';
import EqualShapes4PartsRectangleEqual411Green from './Equal_shapes_4_parts/Rectangle_equal_4-1_1_green.svg';
import EqualShapes4PartsRectangleEqual411Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-1_1_yellow.svg';
import EqualShapes4PartsRectangleEqual412Blue from './Equal_shapes_4_parts/Rectangle_equal_4-1_2_blue.svg';
import EqualShapes4PartsRectangleEqual412Green from './Equal_shapes_4_parts/Rectangle_equal_4-1_2_green.svg';
import EqualShapes4PartsRectangleEqual412Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-1_2_yellow.svg';
import EqualShapes4PartsRectangleEqual413Blue from './Equal_shapes_4_parts/Rectangle_equal_4-1_3_blue.svg';
import EqualShapes4PartsRectangleEqual413Green from './Equal_shapes_4_parts/Rectangle_equal_4-1_3_green.svg';
import EqualShapes4PartsRectangleEqual413Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-1_3_yellow.svg';
import EqualShapes4PartsRectangleEqual421Blue from './Equal_shapes_4_parts/Rectangle_equal_4-2_1_blue.svg';
import EqualShapes4PartsRectangleEqual421Green from './Equal_shapes_4_parts/Rectangle_equal_4-2_1_green.svg';
import EqualShapes4PartsRectangleEqual421Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-2_1_yellow.svg';
import EqualShapes4PartsRectangleEqual422Blue from './Equal_shapes_4_parts/Rectangle_equal_4-2_2_blue.svg';
import EqualShapes4PartsRectangleEqual422Green from './Equal_shapes_4_parts/Rectangle_equal_4-2_2_green.svg';
import EqualShapes4PartsRectangleEqual422Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-2_2_yellow.svg';
import EqualShapes4PartsRectangleEqual431Blue from './Equal_shapes_4_parts/Rectangle_equal_4-3_1_blue.svg';
import EqualShapes4PartsRectangleEqual431Green from './Equal_shapes_4_parts/Rectangle_equal_4-3_1_green.svg';
import EqualShapes4PartsRectangleEqual431Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-3_1_yellow.svg';
import EqualShapes4PartsRectangleEqual432Blue from './Equal_shapes_4_parts/Rectangle_equal_4-3_2_blue.svg';
import EqualShapes4PartsRectangleEqual432Green from './Equal_shapes_4_parts/Rectangle_equal_4-3_2_green.svg';
import EqualShapes4PartsRectangleEqual432Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-3_2_yellow.svg';
import EqualShapes4PartsRectangleEqual441Blue from './Equal_shapes_4_parts/Rectangle_equal_4-4_1_blue.svg';
import EqualShapes4PartsRectangleEqual441Green from './Equal_shapes_4_parts/Rectangle_equal_4-4_1_green.svg';
import EqualShapes4PartsRectangleEqual441Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-4_1_yellow.svg';
import EqualShapes4PartsRectangleEqual442Blue from './Equal_shapes_4_parts/Rectangle_equal_4-4_2_blue.svg';
import EqualShapes4PartsRectangleEqual442Green from './Equal_shapes_4_parts/Rectangle_equal_4-4_2_green.svg';
import EqualShapes4PartsRectangleEqual442Yellow from './Equal_shapes_4_parts/Rectangle_equal_4-4_2_yellow.svg';
import EqualShapes4PartsSquareEqual14 from './Equal_shapes_4_parts/Square_equal_1-4.svg';
import EqualShapes4PartsSquareEqual401 from './Equal_shapes_4_parts/Square_equal_4-0_1.svg';
import EqualShapes4PartsSquareEqual411Blue from './Equal_shapes_4_parts/Square_equal_4-1_1_blue.svg';
import EqualShapes4PartsSquareEqual411Green from './Equal_shapes_4_parts/Square_equal_4-1_1_green.svg';
import EqualShapes4PartsSquareEqual411Yellow from './Equal_shapes_4_parts/Square_equal_4-1_1_yellow.svg';
import EqualShapes4PartsSquareEqual421Blue from './Equal_shapes_4_parts/Square_equal_4-2_1_blue.svg';
import EqualShapes4PartsSquareEqual421Green from './Equal_shapes_4_parts/Square_equal_4-2_1_green.svg';
import EqualShapes4PartsSquareEqual421Yellow from './Equal_shapes_4_parts/Square_equal_4-2_1_yellow.svg';
import EqualShapes4PartsSquareEqual431Blue from './Equal_shapes_4_parts/Square_equal_4-3_1_blue.svg';
import EqualShapes4PartsSquareEqual431Green from './Equal_shapes_4_parts/Square_equal_4-3_1_green.svg';
import EqualShapes4PartsSquareEqual431Yellow from './Equal_shapes_4_parts/Square_equal_4-3_1_yellow.svg';
import EqualShapes4PartsSquareEqual44 from './Equal_shapes_4_parts/Square_equal_4-4.svg';
import EqualShapes4PartsSquareEqual441Blue from './Equal_shapes_4_parts/Square_equal_4-4_1_blue.svg';
import EqualShapes4PartsSquareEqual441Green from './Equal_shapes_4_parts/Square_equal_4-4_1_green.svg';
import EqualShapes4PartsSquareEqual441Yellow from './Equal_shapes_4_parts/Square_equal_4-4_1_yellow.svg';
import EqualShapes5PartsCircleEqual511Blue from './Equal_shapes_5_parts/Circle_equal_5-1_1_blue.svg';
import EqualShapes5PartsCircleEqual511Green from './Equal_shapes_5_parts/Circle_equal_5-1_1_green.svg';
import EqualShapes5PartsCircleEqual511Yellow from './Equal_shapes_5_parts/Circle_equal_5-1_1_yellow.svg';
import EqualShapes5PartsCircleEqual521Blue from './Equal_shapes_5_parts/Circle_equal_5-2_1_blue.svg';
import EqualShapes5PartsCircleEqual521Green from './Equal_shapes_5_parts/Circle_equal_5-2_1_green.svg';
import EqualShapes5PartsCircleEqual521Yellow from './Equal_shapes_5_parts/Circle_equal_5-2_1_yellow.svg';
import EqualShapes5PartsCircleEqual531Blue from './Equal_shapes_5_parts/Circle_equal_5-3_1_blue.svg';
import EqualShapes5PartsCircleEqual531Green from './Equal_shapes_5_parts/Circle_equal_5-3_1_green.svg';
import EqualShapes5PartsCircleEqual531Yellow from './Equal_shapes_5_parts/Circle_equal_5-3_1_yellow.svg';
import EqualShapes5PartsCircleEqual541Blue from './Equal_shapes_5_parts/Circle_equal_5-4_1_blue.svg';
import EqualShapes5PartsCircleEqual541Green from './Equal_shapes_5_parts/Circle_equal_5-4_1_green.svg';
import EqualShapes5PartsCircleEqual541Yellow from './Equal_shapes_5_parts/Circle_equal_5-4_1_yellow.svg';
import EqualShapes5PartsCircleEqual55 from './Equal_shapes_5_parts/Circle_equal_5-5.svg';
import EqualShapes5PartsCircleEqual551Blue from './Equal_shapes_5_parts/Circle_equal_5-5_1_blue.svg';
import EqualShapes5PartsCircleEqual551Green from './Equal_shapes_5_parts/Circle_equal_5-5_1_green.svg';
import EqualShapes5PartsCircleEqual551Yellow from './Equal_shapes_5_parts/Circle_equal_5-5_1_yellow.svg';
import EqualShapes5PartsPentagon15 from './Equal_shapes_5_parts/Pentagon_1-5.svg';
import EqualShapes5PartsPentagon55 from './Equal_shapes_5_parts/Pentagon_5-5.svg';
import EqualShapes5PartsPentagonEqual511Blue from './Equal_shapes_5_parts/Pentagon_equal_5-1_1_blue.svg';
import EqualShapes5PartsPentagonEqual511Green from './Equal_shapes_5_parts/Pentagon_equal_5-1_1_green.svg';
import EqualShapes5PartsPentagonEqual511Yellow from './Equal_shapes_5_parts/Pentagon_equal_5-1_1_yellow.svg';
import EqualShapes5PartsPentagonEqual521Blue from './Equal_shapes_5_parts/Pentagon_equal_5-2_1_blue.svg';
import EqualShapes5PartsPentagonEqual521Green from './Equal_shapes_5_parts/Pentagon_equal_5-2_1_green.svg';
import EqualShapes5PartsPentagonEqual521Yellow from './Equal_shapes_5_parts/Pentagon_equal_5-2_1_yellow.svg';
import EqualShapes5PartsPentagonEqual531Blue from './Equal_shapes_5_parts/Pentagon_equal_5-3_1_blue.svg';
import EqualShapes5PartsPentagonEqual531Green from './Equal_shapes_5_parts/Pentagon_equal_5-3_1_green.svg';
import EqualShapes5PartsPentagonEqual531Yellow from './Equal_shapes_5_parts/Pentagon_equal_5-3_1_yellow.svg';
import EqualShapes5PartsPentagonEqual541Blue from './Equal_shapes_5_parts/Pentagon_equal_5-4_1_blue.svg';
import EqualShapes5PartsPentagonEqual541Green from './Equal_shapes_5_parts/Pentagon_equal_5-4_1_green.svg';
import EqualShapes5PartsPentagonEqual541Yellow from './Equal_shapes_5_parts/Pentagon_equal_5-4_1_yellow.svg';
import EqualShapes5PartsPentagonEqual551Blue from './Equal_shapes_5_parts/Pentagon_equal_5-5_1_blue.svg';
import EqualShapes5PartsPentagonEqual551Green from './Equal_shapes_5_parts/Pentagon_equal_5-5_1_green.svg';
import EqualShapes5PartsPentagonEqual551Yellow from './Equal_shapes_5_parts/Pentagon_equal_5-5_1_yellow.svg';
import EqualShapes5PartsSquareEqual15 from './Equal_shapes_5_parts/Square_equal_1-5.svg';
import EqualShapes5PartsSquareEqual511Blue from './Equal_shapes_5_parts/Square_equal_5-1_1_blue.svg';
import EqualShapes5PartsSquareEqual511Green from './Equal_shapes_5_parts/Square_equal_5-1_1_green.svg';
import EqualShapes5PartsSquareEqual511Yellow from './Equal_shapes_5_parts/Square_equal_5-1_1_yellow.svg';
import EqualShapes5PartsSquareEqual521Blue from './Equal_shapes_5_parts/Square_equal_5-2_1_blue.svg';
import EqualShapes5PartsSquareEqual521Green from './Equal_shapes_5_parts/Square_equal_5-2_1_green.svg';
import EqualShapes5PartsSquareEqual521Yellow from './Equal_shapes_5_parts/Square_equal_5-2_1_yellow.svg';
import EqualShapes5PartsSquareEqual531Blue from './Equal_shapes_5_parts/Square_equal_5-3_1_blue.svg';
import EqualShapes5PartsSquareEqual531Green from './Equal_shapes_5_parts/Square_equal_5-3_1_green.svg';
import EqualShapes5PartsSquareEqual531Yellow from './Equal_shapes_5_parts/Square_equal_5-3_1_yellow.svg';
import EqualShapes5PartsSquareEqual541Blue from './Equal_shapes_5_parts/Square_equal_5-4_1_blue.svg';
import EqualShapes5PartsSquareEqual541Green from './Equal_shapes_5_parts/Square_equal_5-4_1_green.svg';
import EqualShapes5PartsSquareEqual541Yellow from './Equal_shapes_5_parts/Square_equal_5-4_1_yellow.svg';
import EqualShapes5PartsSquareEqual55 from './Equal_shapes_5_parts/Square_equal_5-5.svg';
import EqualShapes5PartsSquareEqual551Blue from './Equal_shapes_5_parts/Square_equal_5-5_1_blue.svg';
import EqualShapes5PartsSquareEqual551Green from './Equal_shapes_5_parts/Square_equal_5-5_1_green.svg';
import EqualShapes5PartsSquareEqual551Yellow from './Equal_shapes_5_parts/Square_equal_5-5_1_yellow.svg';
import EqualShapes6PartsCircleEqual66 from './Equal_shapes_6_parts/Circle_equal_6-6.svg';
import EqualShapes6PartsHexagon66 from './Equal_shapes_6_parts/Hexagon_6-6.svg';
import EqualShapes6PartsSquareEqual66 from './Equal_shapes_6_parts/Square_equal_6-6.svg';
import EqualShapes7PartsCircleEqual77 from './Equal_shapes_7_parts/Circle_equal_7-7.svg';
import EqualShapes7PartsRectangleEqual77 from './Equal_shapes_7_parts/Rectangle_equal_7-7.svg';
import EqualShapes7PartsSquareEqual77 from './Equal_shapes_7_parts/Square_equal_7-7.svg';
import EqualShapes8PartsCircleEqual88 from './Equal_shapes_8_parts/Circle_equal_8-8.svg';
import EqualShapes8PartsCrossEqual88 from './Equal_shapes_8_parts/Cross_equal_8-8.svg';
import EqualShapes8PartsSquareEqual88 from './Equal_shapes_8_parts/Square_equal_8-8.svg';
import EqualShapes9PartsCircleEqual99 from './Equal_shapes_9_parts/Circle_equal_9-9.svg';
import EqualShapes9PartsRectangleEqual99 from './Equal_shapes_9_parts/Rectangle_equal_9-9.svg';
import EqualShapes9PartsSquareEqual99 from './Equal_shapes_9_parts/Square_equal_9-9.svg';
import EquilateralTrianglesTriangleEqual from './Equilateral_triangles/triangle_equal_.svg';
import EquilateralTrianglesTriangleEqualBlue from './Equilateral_triangles/triangle_equal_blue.svg';
import EquilateralTrianglesTriangleEqualGreen from './Equilateral_triangles/triangle_equal_green.svg';
import EquilateralTrianglesTriangleEqualOrange from './Equilateral_triangles/triangle_equal_orange.svg';
import EquilateralTrianglesTriangleEqualPink from './Equilateral_triangles/triangle_equal_pink.svg';
import EquilateralTrianglesTriangleEqualPurple from './Equilateral_triangles/triangle_equal_purple.svg';
import EquilateralTrianglesTriangleEqualRed from './Equilateral_triangles/triangle_equal_red.svg';
import EquilateralTrianglesTriangleEqualTurquoise from './Equilateral_triangles/triangle_equal_turquoise.svg';
import EquilateralTrianglesTriangleEqualWhite from './Equilateral_triangles/triangle_equal_white.svg';
import EquilateralTrianglesTriangleEqualYellow from './Equilateral_triangles/triangle_equal_yellow.svg';
import FactorBug from './Factor_Bug.svg';
import FlagsArmeniaHorizontalFlag from './Flags/Armenia_horizontal_flag.svg';
import FlagsAustraliaBothFlag from './Flags/Australia_both_flag.svg';
import FlagsAustriaHorizontalFlag from './Flags/Austria_horizontal_flag.svg';
import FlagsBelgiumVerticalFlag from './Flags/Belgium_vertical_flag.svg';
import FlagsBotswanaHorizontalFlag from './Flags/Botswana_horizontal_flag.svg';
import FlagsBulgariaHorizontalFlag from './Flags/Bulgaria_horizontal_flag.svg';
import FlagsCameroonVerticalFlag from './Flags/Cameroon_vertical_flag.svg';
import FlagsCanadaVerticalFlag from './Flags/Canada_vertical_flag.svg';
import FlagsChadVerticalFlag from './Flags/Chad_vertical_flag.svg';
import FlagsColumbiaHorizontalFlag from './Flags/Columbia_horizontal_flag.svg';
import FlagsDenmarkBothFlag from './Flags/Denmark_both_flag.svg';
import FlagsEstoniaHorizontalFlag from './Flags/Estonia_horizontal_flag.svg';
import FlagsFinlandBothFlag from './Flags/Finland_both_flag.svg';
import FlagsFranceVerticalFlag from './Flags/France_vertical_flag.svg';
import FlagsGermanyHorizontalFlag from './Flags/Germany_horizontal_flag.svg';
import FlagsGreeceBothFlag from './Flags/Greece_both_flag.svg';
import FlagsIcelandBothFlag from './Flags/Iceland_both_flag.svg';
import FlagsIndonesiaHorizontalFlag from './Flags/Indonesia_horizontal_flag.svg';
import FlagsIrelandVerticalFlag from './Flags/Ireland_vertical_flag.svg';
import FlagsItalyVerticalFlag from './Flags/Italy_vertical_flag.svg';
import FlagsLatviaHorizontalFlag from './Flags/Latvia_horizontal_flag.svg';
import FlagsLithuaniaHorizontalFlag from './Flags/Lithuania_horizontal_flag.svg';
import FlagsMadagascarBothFlag from './Flags/Madagascar_both_flag.svg';
import FlagsMaliVerticalFlag from './Flags/Mali_vertical_flag.svg';
import FlagsMauritiusHorizontalFlag from './Flags/Mauritius_horizontal_flag.svg';
import FlagsNetherlandsHorizontalFlag from './Flags/Netherlands_horizontal_flag.svg';
import FlagsNigeriaVerticalFlag from './Flags/Nigeria_vertical_flag.svg';
import FlagsNorwayBothFlag from './Flags/Norway_both_flag.svg';
import FlagsPolandHorizontalFlag from './Flags/Poland_horizontal_flag.svg';
import FlagsRomaniaVerticalFlag from './Flags/Romania_vertical_flag.svg';
import FlagsSenagalVerticalFlag from './Flags/Senagal_vertical_flag.svg';
import FlagsSierraLeoneHorizontalFlag from './Flags/Sierra_Leone_horizontal_flag.svg';
import FlagsSwedenBothFlag from './Flags/Sweden_both_flag.svg';
import FlagsThailandHorizontalFlag from './Flags/Thailand_horizontal_flag.svg';
import FlagsTogoHorizontalFlag from './Flags/Togo_horizontal_flag.svg';
import FlagsUAEBothFlag from './Flags/UAE_both_flag.svg';
import FlagsUKBothFlag from './Flags/UK_both_flag.svg';
import FlagsUSAHorizontalFlag from './Flags/USA_horizontal_flag.svg';
import FlowersFlowerBlue from './Flowers/Flower_Blue.svg';
import FlowersFlowerOrange from './Flowers/Flower_Orange.svg';
import FlowersFlowerPurple from './Flowers/Flower_Purple.svg';
import FlowersFlowerRed from './Flowers/Flower_Red.svg';
import FlowersFlowerWhite from './Flowers/Flower_White.svg';
import FlowersFlowerYellow from './Flowers/Flower_Yellow.svg';
import FoodFractionsCakeCakeEighthsCake1Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_1_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake2Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_2_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake3Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_3_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake4Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_4_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake5Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_5_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake6Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_6_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake7Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_7_Eighths.svg';
import FoodFractionsCakeCakeEighthsCake8Eighths from './Food_fractions/Cake/Cake_Eighths/Cake_8_Eighths.svg';
import FoodFractionsCakeCakeFifthsCake1Fifths from './Food_fractions/Cake/Cake_Fifths/Cake_1_Fifths.svg';
import FoodFractionsCakeCakeFifthsCake2Fifths from './Food_fractions/Cake/Cake_Fifths/Cake_2_Fifths.svg';
import FoodFractionsCakeCakeFifthsCake3Fifths from './Food_fractions/Cake/Cake_Fifths/Cake_3_Fifths.svg';
import FoodFractionsCakeCakeFifthsCake4Fifths from './Food_fractions/Cake/Cake_Fifths/Cake_4_Fifths.svg';
import FoodFractionsCakeCakeFifthsCake5Fifths from './Food_fractions/Cake/Cake_Fifths/Cake_5_Fifths.svg';
import FoodFractionsCakeCakeHalfCake1Half from './Food_fractions/Cake/Cake_Half/Cake_1_half.svg';
import FoodFractionsCakeCakeHalfCake2Halves from './Food_fractions/Cake/Cake_Half/Cake_2_halves.svg';
import FoodFractionsCakeCakeQuartersCake1Quarters from './Food_fractions/Cake/Cake_Quarters/Cake_1_quarters.svg';
import FoodFractionsCakeCakeQuartersCake2Quarters from './Food_fractions/Cake/Cake_Quarters/Cake_2_quarters.svg';
import FoodFractionsCakeCakeQuartersCake3Quarters from './Food_fractions/Cake/Cake_Quarters/Cake_3_quarters.svg';
import FoodFractionsCakeCakeQuartersCake4Quarters from './Food_fractions/Cake/Cake_Quarters/Cake_4_quarters.svg';
import FoodFractionsCakeCakeSeventhsCake1Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_1_Sevenths.svg';
import FoodFractionsCakeCakeSeventhsCake2Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_2_Sevenths.svg';
import FoodFractionsCakeCakeSeventhsCake3Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_3_Sevenths.svg';
import FoodFractionsCakeCakeSeventhsCake4Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_4_Sevenths.svg';
import FoodFractionsCakeCakeSeventhsCake5Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_5_Sevenths.svg';
import FoodFractionsCakeCakeSeventhsCake6Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_6_Sevenths.svg';
import FoodFractionsCakeCakeSeventhsCake7Sevenths from './Food_fractions/Cake/Cake_Sevenths/Cake_7_Sevenths.svg';
import FoodFractionsCakeCakeSixthsCake1Sixths from './Food_fractions/Cake/Cake_Sixths/Cake_1_Sixths.svg';
import FoodFractionsCakeCakeSixthsCake2Sixths from './Food_fractions/Cake/Cake_Sixths/Cake_2_Sixths.svg';
import FoodFractionsCakeCakeSixthsCake3Sixths from './Food_fractions/Cake/Cake_Sixths/Cake_3_Sixths.svg';
import FoodFractionsCakeCakeSixthsCake4Sixths from './Food_fractions/Cake/Cake_Sixths/Cake_4_Sixths.svg';
import FoodFractionsCakeCakeSixthsCake5Sixths from './Food_fractions/Cake/Cake_Sixths/Cake_5_Sixths.svg';
import FoodFractionsCakeCakeSixthsCake6Sixths from './Food_fractions/Cake/Cake_Sixths/Cake_6_Sixths.svg';
import FoodFractionsCakeCakeThirdsCake1Thirds from './Food_fractions/Cake/Cake_Thirds/Cake_1_thirds.svg';
import FoodFractionsCakeCakeThirdsCake2Thirds from './Food_fractions/Cake/Cake_Thirds/Cake_2_thirds.svg';
import FoodFractionsCakeCakeThirdsCake3Thirds from './Food_fractions/Cake/Cake_Thirds/Cake_3_thirds.svg';
import FoodFractionsCakeCakeWholeCake1Whole from './Food_fractions/Cake/Cake_Whole/Cake_1_Whole.svg';
import FoodFractionsPiePieEighthsPie1Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_1_Eighths.svg';
import FoodFractionsPiePieEighthsPie2Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_2_Eighths.svg';
import FoodFractionsPiePieEighthsPie3Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_3_Eighths.svg';
import FoodFractionsPiePieEighthsPie4Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_4_Eighths.svg';
import FoodFractionsPiePieEighthsPie5Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_5_Eighths.svg';
import FoodFractionsPiePieEighthsPie6Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_6_Eighths.svg';
import FoodFractionsPiePieEighthsPie7Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_7_Eighths.svg';
import FoodFractionsPiePieEighthsPie8Eighths from './Food_fractions/Pie/Pie_Eighths/Pie_8_Eighths.svg';
import FoodFractionsPiePieFifthsPie1Fifths from './Food_fractions/Pie/Pie_Fifths/Pie_1_Fifths.svg';
import FoodFractionsPiePieFifthsPie2Fifths from './Food_fractions/Pie/Pie_Fifths/Pie_2_Fifths.svg';
import FoodFractionsPiePieFifthsPie3Fifths from './Food_fractions/Pie/Pie_Fifths/Pie_3_Fifths.svg';
import FoodFractionsPiePieFifthsPie4Fifths from './Food_fractions/Pie/Pie_Fifths/Pie_4_Fifths.svg';
import FoodFractionsPiePieFifthsPie5Fifths from './Food_fractions/Pie/Pie_Fifths/Pie_5_Fifths.svg';
import FoodFractionsPiePieHalvesPie1Half from './Food_fractions/Pie/Pie_Halves/Pie_1_half.svg';
import FoodFractionsPiePieHalvesPie2Halves from './Food_fractions/Pie/Pie_Halves/Pie_2_halves.svg';
import FoodFractionsPiePieQuartersPie1Quarters from './Food_fractions/Pie/Pie_Quarters/Pie_1_Quarters.svg';
import FoodFractionsPiePieQuartersPie2Quarters from './Food_fractions/Pie/Pie_Quarters/Pie_2_Quarters.svg';
import FoodFractionsPiePieQuartersPie3Quarters from './Food_fractions/Pie/Pie_Quarters/Pie_3_Quarters.svg';
import FoodFractionsPiePieQuartersPie4Quarters from './Food_fractions/Pie/Pie_Quarters/Pie_4_Quarters.svg';
import FoodFractionsPiePieSeventhsPie1Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_1_Sevenths.svg';
import FoodFractionsPiePieSeventhsPie2Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_2_Sevenths.svg';
import FoodFractionsPiePieSeventhsPie3Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_3_Sevenths.svg';
import FoodFractionsPiePieSeventhsPie4Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_4_Sevenths.svg';
import FoodFractionsPiePieSeventhsPie5Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_5_Sevenths.svg';
import FoodFractionsPiePieSeventhsPie6Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_6_Sevenths.svg';
import FoodFractionsPiePieSeventhsPie7Sevenths from './Food_fractions/Pie/Pie_Sevenths/Pie_7_Sevenths.svg';
import FoodFractionsPiePieSixthsPie1Sixths from './Food_fractions/Pie/Pie_Sixths/Pie_1_sixths.svg';
import FoodFractionsPiePieSixthsPie2Sixths from './Food_fractions/Pie/Pie_Sixths/Pie_2_sixths.svg';
import FoodFractionsPiePieSixthsPie3Sixths from './Food_fractions/Pie/Pie_Sixths/Pie_3_sixths.svg';
import FoodFractionsPiePieSixthsPie4Sixths from './Food_fractions/Pie/Pie_Sixths/Pie_4_sixths.svg';
import FoodFractionsPiePieSixthsPie5Sixths from './Food_fractions/Pie/Pie_Sixths/Pie_5_sixths.svg';
import FoodFractionsPiePieSixthsPie6Sixths from './Food_fractions/Pie/Pie_Sixths/Pie_6_sixths.svg';
import FoodFractionsPiePieThirdsPie1Thirds from './Food_fractions/Pie/Pie_Thirds/Pie_1_Thirds.svg';
import FoodFractionsPiePieThirdsPie2Thirds from './Food_fractions/Pie/Pie_Thirds/Pie_2_Thirds.svg';
import FoodFractionsPiePieThirdsPie3Thirds from './Food_fractions/Pie/Pie_Thirds/Pie_3_Thirds.svg';
import FoodFractionsPiePieWholePieWhole from './Food_fractions/Pie/Pie_Whole/Pie_whole.svg';
import FoodFractionsPizzaPizzaEighthsPizza1Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_1_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza2Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_2_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza3Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_3_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza4Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_4_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza5Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_5_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza6Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_6_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza7Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_7_Eighths.svg';
import FoodFractionsPizzaPizzaEighthsPizza8Eighths from './Food_fractions/Pizza/Pizza_Eighths/Pizza_8_Eighths.svg';
import FoodFractionsPizzaPizzaFifthsPizza1Fifths from './Food_fractions/Pizza/Pizza_Fifths/Pizza_1_fifths.svg';
import FoodFractionsPizzaPizzaFifthsPizza2Fifths from './Food_fractions/Pizza/Pizza_Fifths/Pizza_2_fifths.svg';
import FoodFractionsPizzaPizzaFifthsPizza3Fifths from './Food_fractions/Pizza/Pizza_Fifths/Pizza_3_fifths.svg';
import FoodFractionsPizzaPizzaFifthsPizza4Fifths from './Food_fractions/Pizza/Pizza_Fifths/Pizza_4_fifths.svg';
import FoodFractionsPizzaPizzaFifthsPizza5Fifths from './Food_fractions/Pizza/Pizza_Fifths/Pizza_5_fifths.svg';
import FoodFractionsPizzaPizzaHalvesPizza1Halves from './Food_fractions/Pizza/Pizza_Halves/Pizza_1_halves.svg';
import FoodFractionsPizzaPizzaHalvesPizza2Halves from './Food_fractions/Pizza/Pizza_Halves/Pizza_2_halves.svg';
import FoodFractionsPizzaPizzaQuartersPizza1Quarters from './Food_fractions/Pizza/Pizza_Quarters/Pizza_1_Quarters.svg';
import FoodFractionsPizzaPizzaQuartersPizza2Quarters from './Food_fractions/Pizza/Pizza_Quarters/Pizza_2_Quarters.svg';
import FoodFractionsPizzaPizzaQuartersPizza3Quarters from './Food_fractions/Pizza/Pizza_Quarters/Pizza_3_Quarters.svg';
import FoodFractionsPizzaPizzaQuartersPizza4Quarters from './Food_fractions/Pizza/Pizza_Quarters/Pizza_4_Quarters.svg';
import FoodFractionsPizzaPizzaSeventhsPizza1Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_1_Sevenths.svg';
import FoodFractionsPizzaPizzaSeventhsPizza2Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_2_Sevenths.svg';
import FoodFractionsPizzaPizzaSeventhsPizza3Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_3_Sevenths.svg';
import FoodFractionsPizzaPizzaSeventhsPizza4Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_4_Sevenths.svg';
import FoodFractionsPizzaPizzaSeventhsPizza5Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_5_Sevenths.svg';
import FoodFractionsPizzaPizzaSeventhsPizza6Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_6_Sevenths.svg';
import FoodFractionsPizzaPizzaSeventhsPizza7Sevenths from './Food_fractions/Pizza/Pizza_Sevenths/Pizza_7_Sevenths.svg';
import FoodFractionsPizzaPizzaSixthsPizza1Sixths from './Food_fractions/Pizza/Pizza_Sixths/pizza_1_sixths.svg';
import FoodFractionsPizzaPizzaSixthsPizza2Sixths from './Food_fractions/Pizza/Pizza_Sixths/pizza_2_sixths.svg';
import FoodFractionsPizzaPizzaSixthsPizza3Sixths from './Food_fractions/Pizza/Pizza_Sixths/pizza_3_sixths.svg';
import FoodFractionsPizzaPizzaSixthsPizza4Sixths from './Food_fractions/Pizza/Pizza_Sixths/pizza_4_sixths.svg';
import FoodFractionsPizzaPizzaSixthsPizza5Sixths from './Food_fractions/Pizza/Pizza_Sixths/pizza_5_sixths.svg';
import FoodFractionsPizzaPizzaSixthsPizza6Sixths from './Food_fractions/Pizza/Pizza_Sixths/pizza_6_sixths.svg';
import FoodFractionsPizzaPizzaThirdsPizza1Thirds from './Food_fractions/Pizza/Pizza_Thirds/Pizza_1_Thirds.svg';
import FoodFractionsPizzaPizzaThirdsPizza2Thirds from './Food_fractions/Pizza/Pizza_Thirds/Pizza_2_Thirds.svg';
import FoodFractionsPizzaPizzaThirdsPizza3Thirds from './Food_fractions/Pizza/Pizza_Thirds/Pizza_3_Thirds.svg';
import FoodFractionsPizzaPizzaWholePizza from './Food_fractions/Pizza/Pizza_Whole/Pizza.svg';
import FoodOnAPlateCookiesCakesPlate1 from './Food_on_a_plate/Cookies_cakes_plate_1.svg';
import FoodOnAPlateCookiesCakesPlate2 from './Food_on_a_plate/Cookies_cakes_plate_2.svg';
import FoodOnAPlateCookiesCakesPlate3 from './Food_on_a_plate/Cookies_cakes_plate_3.svg';
import FoodOnAPlateCookiesCakesPlate4 from './Food_on_a_plate/Cookies_cakes_plate_4.svg';
import FoodOnAPlateCookiesCakesPlate5 from './Food_on_a_plate/Cookies_cakes_plate_5.svg';
import FoodOnAPlateCookiesCakesPlate6 from './Food_on_a_plate/Cookies_cakes_plate_6.svg';
import FoodOnAPlateMuffinsPlate1 from './Food_on_a_plate/Muffins_plate_1.svg';
import FoodOnAPlateMuffinsPlate2 from './Food_on_a_plate/Muffins_plate_2.svg';
import FoodOnAPlateMuffinsPlate3 from './Food_on_a_plate/Muffins_plate_3.svg';
import FoodOnAPlateMuffinsPlate4 from './Food_on_a_plate/Muffins_plate_4.svg';
import FoodOnAPlateMuffinsPlate5 from './Food_on_a_plate/Muffins_plate_5.svg';
import FoodOnAPlateMuffinsPlate6 from './Food_on_a_plate/Muffins_plate_6.svg';
import FootballStickerBook from './Football_sticker_book.svg';
import Fork from './Fork.svg';
import Frog from './Frog.svg';
import FullBathtub from './Full_bathtub.svg';
import FullBucket from './Full_bucket.svg';
import GamesConsole from './GamesConsole.svg';
import Giraffe from './Giraffe.svg';
import GlassesOfOrangeJuiceGlass1OjEighthFull from './Glasses_of_orange_juice/glass1_oj_eighth_full.svg';
import GlassesOfOrangeJuiceGlass1OjEmpty from './Glasses_of_orange_juice/glass1_oj_empty.svg';
import GlassesOfOrangeJuiceGlass1OjFull from './Glasses_of_orange_juice/glass1_oj_full.svg';
import GlassesOfOrangeJuiceGlass1OjHalfFull from './Glasses_of_orange_juice/glass1_oj_half_full.svg';
import GlassesOfOrangeJuiceGlass1OjQuarterFull from './Glasses_of_orange_juice/glass1_oj_quarter_full.svg';
import GlassesOfOrangeJuiceGlass1OjSevenEighthsFull from './Glasses_of_orange_juice/glass1_oj_seven_eighths_full.svg';
import GlassesOfOrangeJuiceGlass1OjThirdFull from './Glasses_of_orange_juice/glass1_oj_third_full.svg';
import GlassesOfOrangeJuiceGlass1OjThreeQuartersFull from './Glasses_of_orange_juice/glass1_oj_three_quarters_full.svg';
import GlassesOfOrangeJuiceGlass1OjTwoThirdsFull from './Glasses_of_orange_juice/glass1_oj_two_thirds_full.svg';
import GlassesOfOrangeJuiceGlass2OjEighthFull from './Glasses_of_orange_juice/glass2_oj_eighth_full.svg';
import GlassesOfOrangeJuiceGlass2OjEmpty from './Glasses_of_orange_juice/glass2_oj_empty.svg';
import GlassesOfOrangeJuiceGlass2OjFull from './Glasses_of_orange_juice/glass2_oj_full.svg';
import GlassesOfOrangeJuiceGlass2OjHalfFull from './Glasses_of_orange_juice/glass2_oj_half_full.svg';
import GlassesOfOrangeJuiceGlass2OjQuarterFull from './Glasses_of_orange_juice/glass2_oj_quarter_full.svg';
import GlassesOfOrangeJuiceGlass2OjSevenEighthsFull from './Glasses_of_orange_juice/glass2_oj_seven_eighths_full.svg';
import GlassesOfOrangeJuiceGlass2OjThirdFull from './Glasses_of_orange_juice/glass2_oj_third_full.svg';
import GlassesOfOrangeJuiceGlass2OjThreeQuartersFull from './Glasses_of_orange_juice/glass2_oj_three_quarters_full.svg';
import GlassesOfOrangeJuiceGlass2OjTwoThirdsFull from './Glasses_of_orange_juice/glass2_oj_two_thirds_full.svg';
import GlassesOfWaterGlassWaterFull from './Glasses_of_water/glass_water_full.svg';
import GlassesOfWaterGlassWaterHalfFull from './Glasses_of_water/glass_water_half_full.svg';
import GlassesOfWaterGlassWaterQuarterFull from './Glasses_of_water/glass_water_quarter_full.svg';
import GlassesOfWaterGlassWaterThreeQuartersFull from './Glasses_of_water/glass_water_three_quarters_full.svg';
import GoldfishInATankGoldfishInATank0 from './Goldfish_in_a_tank/Goldfish_in_a_tank_0.svg';
import GoldfishInATankGoldfishInATank1 from './Goldfish_in_a_tank/Goldfish_in_a_tank_1.svg';
import GoldfishInATankGoldfishInATank10 from './Goldfish_in_a_tank/Goldfish_in_a_tank_10.svg';
import GoldfishInATankGoldfishInATank2 from './Goldfish_in_a_tank/Goldfish_in_a_tank_2.svg';
import GoldfishInATankGoldfishInATank3 from './Goldfish_in_a_tank/Goldfish_in_a_tank_3.svg';
import GoldfishInATankGoldfishInATank4 from './Goldfish_in_a_tank/Goldfish_in_a_tank_4.svg';
import GoldfishInATankGoldfishInATank5 from './Goldfish_in_a_tank/Goldfish_in_a_tank_5.svg';
import GoldfishInATankGoldfishInATank6 from './Goldfish_in_a_tank/Goldfish_in_a_tank_6.svg';
import GoldfishInATankGoldfishInATank7 from './Goldfish_in_a_tank/Goldfish_in_a_tank_7.svg';
import GoldfishInATankGoldfishInATank8 from './Goldfish_in_a_tank/Goldfish_in_a_tank_8.svg';
import GoldfishInATankGoldfishInATank9 from './Goldfish_in_a_tank/Goldfish_in_a_tank_9.svg';
import GrapeGreen from './Grape_green.svg';
import GrapesGreenBunch from './Grapes_green_bunch.svg';
import GreyStar from './GreyStar.svg';
import GummyBearsGummybearGreen from './Gummy_bears/gummybear_green.svg';
import GummyBearsGummybearOrange from './Gummy_bears/gummybear_orange.svg';
import GummyBearsGummybearPurple from './Gummy_bears/gummybear_purple.svg';
import GummyBearsGummybearRed from './Gummy_bears/gummybear_red.svg';
import GummyBearsGummybearYellow from './Gummy_bears/gummybear_yellow.svg';
import HandOpenFingers from './Hand_open_fingers.svg';
import HandsOpenPair from './Hands_open_pair.svg';
import HayBale from './Hay_bale.svg';
import Headphones from './Headphones.svg';
import HeartPurple from './Heart_purple.svg';
import HeptagonsHeptagonBlue from './Heptagons/Heptagon_blue.svg';
import HeptagonsHeptagonGreen from './Heptagons/Heptagon_green.svg';
import HeptagonsHeptagonOrange from './Heptagons/Heptagon_orange.svg';
import HeptagonsHeptagonPink from './Heptagons/Heptagon_pink.svg';
import HeptagonsHeptagonPurple from './Heptagons/Heptagon_purple.svg';
import HeptagonsHeptagonRed from './Heptagons/Heptagon_red.svg';
import HeptagonsHeptagonTurquoise from './Heptagons/Heptagon_turquoise.svg';
import HeptagonsHeptagonYellow from './Heptagons/Heptagon_yellow.svg';
import HeptagonsIrregularHeptagon1Blue from './Heptagons/Irregular_heptagon1_blue.svg';
import HeptagonsIrregularHeptagon1Green from './Heptagons/Irregular_heptagon1_green.svg';
import HeptagonsIrregularHeptagon1Orange from './Heptagons/Irregular_heptagon1_orange.svg';
import HeptagonsIrregularHeptagon1Pink from './Heptagons/Irregular_heptagon1_pink.svg';
import HeptagonsIrregularHeptagon1Purple from './Heptagons/Irregular_heptagon1_purple.svg';
import HeptagonsIrregularHeptagon1Red from './Heptagons/Irregular_heptagon1_red.svg';
import HeptagonsIrregularHeptagon1Turquoise from './Heptagons/Irregular_heptagon1_turquoise.svg';
import HeptagonsIrregularHeptagon1Yellow from './Heptagons/Irregular_heptagon1_yellow.svg';
import HeptagonsIrregularHeptagon2Blue from './Heptagons/Irregular_heptagon2_blue.svg';
import HeptagonsIrregularHeptagon2Green from './Heptagons/Irregular_heptagon2_green.svg';
import HeptagonsIrregularHeptagon2Orange from './Heptagons/Irregular_heptagon2_orange.svg';
import HeptagonsIrregularHeptagon2Pink from './Heptagons/Irregular_heptagon2_pink.svg';
import HeptagonsIrregularHeptagon2Purple from './Heptagons/Irregular_heptagon2_purple.svg';
import HeptagonsIrregularHeptagon2Red from './Heptagons/Irregular_heptagon2_red.svg';
import HeptagonsIrregularHeptagon2Turquoise from './Heptagons/Irregular_heptagon2_turquoise.svg';
import HeptagonsIrregularHeptagon2Yellow from './Heptagons/Irregular_heptagon2_yellow.svg';
import HeptagonsIrregularHeptagon3Blue from './Heptagons/Irregular_heptagon3_blue.svg';
import HeptagonsIrregularHeptagon3Green from './Heptagons/Irregular_heptagon3_green.svg';
import HeptagonsIrregularHeptagon3Orange from './Heptagons/Irregular_heptagon3_orange.svg';
import HeptagonsIrregularHeptagon3Pink from './Heptagons/Irregular_heptagon3_pink.svg';
import HeptagonsIrregularHeptagon3Purple from './Heptagons/Irregular_heptagon3_purple.svg';
import HeptagonsIrregularHeptagon3Red from './Heptagons/Irregular_heptagon3_red.svg';
import HeptagonsIrregularHeptagon3Turquoise from './Heptagons/Irregular_heptagon3_turquoise.svg';
import HeptagonsIrregularHeptagon3Yellow from './Heptagons/Irregular_heptagon3_yellow.svg';
import HexagonsIrregularHexagon1Blue from './Hexagons/Irregular_hexagon1_blue.svg';
import HexagonsIrregularHexagon1BlueOneInteriorAngleLhs from './Hexagons/Irregular_hexagon1_blue_one_interior_angle_lhs.svg';
import HexagonsIrregularHexagon1BlueOneInteriorAngleRhs from './Hexagons/Irregular_hexagon1_blue_one_interior_angle_rhs.svg';
import HexagonsIrregularHexagon1Green from './Hexagons/Irregular_hexagon1_green.svg';
import HexagonsIrregularHexagon1GreenOneInteriorAngleLhs from './Hexagons/Irregular_hexagon1_green_one_interior_angle_lhs.svg';
import HexagonsIrregularHexagon1GreenOneInteriorAngleRhs from './Hexagons/Irregular_hexagon1_green_one_interior_angle_rhs.svg';
import HexagonsIrregularHexagon1Orange from './Hexagons/Irregular_hexagon1_orange.svg';
import HexagonsIrregularHexagon1Pink from './Hexagons/Irregular_hexagon1_pink.svg';
import HexagonsIrregularHexagon1PinkOneInteriorAngleLhs from './Hexagons/Irregular_hexagon1_pink_one_interior_angle_lhs.svg';
import HexagonsIrregularHexagon1PinkOneInteriorAngleRhs from './Hexagons/Irregular_hexagon1_pink_one_interior_angle_rhs.svg';
import HexagonsIrregularHexagon1Purple from './Hexagons/Irregular_hexagon1_purple.svg';
import HexagonsIrregularHexagon1PurpleOneInteriorAngleLhs from './Hexagons/Irregular_hexagon1_purple_one_interior_angle_lhs.svg';
import HexagonsIrregularHexagon1PurpleOneInteriorAngleRhs from './Hexagons/Irregular_hexagon1_purple_one_interior_angle_rhs.svg';
import HexagonsIrregularHexagon1Red from './Hexagons/Irregular_hexagon1_red.svg';
import HexagonsIrregularHexagon1Turquoise from './Hexagons/Irregular_hexagon1_turquoise.svg';
import HexagonsIrregularHexagon1White from './Hexagons/Irregular_hexagon1_white.svg';
import HexagonsIrregularHexagon1WhiteOneInteriorAngleLhs from './Hexagons/Irregular_hexagon1_white_one_interior_angle_lhs.svg';
import HexagonsIrregularHexagon1WhiteOneInteriorAngleRhs from './Hexagons/Irregular_hexagon1_white_one_interior_angle_rhs.svg';
import HexagonsIrregularHexagon1Yellow from './Hexagons/Irregular_hexagon1_yellow.svg';
import HexagonsIrregularHexagon1YellowOneInteriorAngleLhs from './Hexagons/Irregular_hexagon1_yellow_one_interior_angle_lhs.svg';
import HexagonsIrregularHexagon1YellowOneInteriorAngleRhs from './Hexagons/Irregular_hexagon1_yellow_one_interior_angle_rhs.svg';
import HexagonsIrregularHexagon2Blue from './Hexagons/Irregular_hexagon2_blue.svg';
import HexagonsIrregularHexagon2Green from './Hexagons/Irregular_hexagon2_green.svg';
import HexagonsIrregularHexagon2Orange from './Hexagons/Irregular_hexagon2_orange.svg';
import HexagonsIrregularHexagon2Pink from './Hexagons/Irregular_hexagon2_pink.svg';
import HexagonsIrregularHexagon2Purple from './Hexagons/Irregular_hexagon2_purple.svg';
import HexagonsIrregularHexagon2Red from './Hexagons/Irregular_hexagon2_red.svg';
import HexagonsIrregularHexagon2Turquoise from './Hexagons/Irregular_hexagon2_turquoise.svg';
import HexagonsIrregularHexagon2White from './Hexagons/Irregular_hexagon2_white.svg';
import HexagonsIrregularHexagon2Yellow from './Hexagons/Irregular_hexagon2_yellow.svg';
import HexagonsIrregularHexagon3Blue from './Hexagons/Irregular_hexagon3_blue.svg';
import HexagonsIrregularHexagon3Green from './Hexagons/Irregular_hexagon3_green.svg';
import HexagonsIrregularHexagon3Orange from './Hexagons/Irregular_hexagon3_orange.svg';
import HexagonsIrregularHexagon3Pink from './Hexagons/Irregular_hexagon3_pink.svg';
import HexagonsIrregularHexagon3Purple from './Hexagons/Irregular_hexagon3_purple.svg';
import HexagonsIrregularHexagon3Red from './Hexagons/Irregular_hexagon3_red.svg';
import HexagonsIrregularHexagon3Turquoise from './Hexagons/Irregular_hexagon3_turquoise.svg';
import HexagonsIrregularHexagon3White from './Hexagons/Irregular_hexagon3_white.svg';
import HexagonsIrregularHexagon3Yellow from './Hexagons/Irregular_hexagon3_yellow.svg';
import HexagonsIrregularHexagon4Blue from './Hexagons/Irregular_hexagon4_blue.svg';
import HexagonsIrregularHexagon4Green from './Hexagons/Irregular_hexagon4_green.svg';
import HexagonsIrregularHexagon4Orange from './Hexagons/Irregular_hexagon4_orange.svg';
import HexagonsIrregularHexagon4Pink from './Hexagons/Irregular_hexagon4_pink.svg';
import HexagonsIrregularHexagon4Purple from './Hexagons/Irregular_hexagon4_purple.svg';
import HexagonsIrregularHexagon4Red from './Hexagons/Irregular_hexagon4_red.svg';
import HexagonsIrregularHexagon4Turquoise from './Hexagons/Irregular_hexagon4_turquoise.svg';
import HexagonsIrregularHexagon4White from './Hexagons/Irregular_hexagon4_white.svg';
import HexagonsIrregularHexagon4Yellow from './Hexagons/Irregular_hexagon4_yellow.svg';
import HexagonsIrregularHexagon5Blue from './Hexagons/Irregular_hexagon5_blue.svg';
import HexagonsIrregularHexagon5Green from './Hexagons/Irregular_hexagon5_green.svg';
import HexagonsIrregularHexagon5Orange from './Hexagons/Irregular_hexagon5_orange.svg';
import HexagonsIrregularHexagon5Pink from './Hexagons/Irregular_hexagon5_pink.svg';
import HexagonsIrregularHexagon5Purple from './Hexagons/Irregular_hexagon5_purple.svg';
import HexagonsIrregularHexagon5Red from './Hexagons/Irregular_hexagon5_red.svg';
import HexagonsIrregularHexagon5Turquoise from './Hexagons/Irregular_hexagon5_turquoise.svg';
import HexagonsIrregularHexagon5White from './Hexagons/Irregular_hexagon5_white.svg';
import HexagonsIrregularHexagon5Yellow from './Hexagons/Irregular_hexagon5_yellow.svg';
import HexagonsIrregularHexagon6Blue from './Hexagons/Irregular_hexagon6_blue.svg';
import HexagonsIrregularHexagon6Green from './Hexagons/Irregular_hexagon6_green.svg';
import HexagonsIrregularHexagon6Orange from './Hexagons/Irregular_hexagon6_orange.svg';
import HexagonsIrregularHexagon6Pink from './Hexagons/Irregular_hexagon6_pink.svg';
import HexagonsIrregularHexagon6Purple from './Hexagons/Irregular_hexagon6_purple.svg';
import HexagonsIrregularHexagon6Red from './Hexagons/Irregular_hexagon6_red.svg';
import HexagonsIrregularHexagon6Turquoise from './Hexagons/Irregular_hexagon6_turquoise.svg';
import HexagonsIrregularHexagon6White from './Hexagons/Irregular_hexagon6_white.svg';
import HexagonsIrregularHexagon6Yellow from './Hexagons/Irregular_hexagon6_yellow.svg';
import HexagonsHexagon from './Hexagons/hexagon.svg';
import HexagonsHexagon1Arrow from './Hexagons/hexagon_1_arrow.svg';
import HexagonsHexagon1ArrowBlue from './Hexagons/hexagon_1_arrow_blue.svg';
import HexagonsHexagon1ArrowGreen from './Hexagons/hexagon_1_arrow_green.svg';
import HexagonsHexagon1ArrowOrange from './Hexagons/hexagon_1_arrow_orange.svg';
import HexagonsHexagon1ArrowPink from './Hexagons/hexagon_1_arrow_pink.svg';
import HexagonsHexagon1ArrowPurple from './Hexagons/hexagon_1_arrow_purple.svg';
import HexagonsHexagon1ArrowTurquoise from './Hexagons/hexagon_1_arrow_turquoise.svg';
import HexagonsHexagon1ArrowYellow from './Hexagons/hexagon_1_arrow_yellow.svg';
import HexagonsHexagonBlue from './Hexagons/hexagon_blue.svg';
import HexagonsHexagonGreen from './Hexagons/hexagon_green.svg';
import HexagonsHexagonOrange from './Hexagons/hexagon_orange.svg';
import HexagonsHexagonPink from './Hexagons/hexagon_pink.svg';
import HexagonsHexagonPurple from './Hexagons/hexagon_purple.svg';
import HexagonsHexagonRed from './Hexagons/hexagon_red.svg';
import HexagonsHexagonTurquoise from './Hexagons/hexagon_turquoise.svg';
import HexagonsHexagonWhite from './Hexagons/hexagon_white.svg';
import HexagonsHexagonYellow from './Hexagons/hexagon_yellow.svg';
import HorizontalThermoterBlank from './Horizontal_Thermoter_blank.svg';
import Horse from './Horse.svg';
import House from './House.svg';
import Hulahoop from './Hulahoop.svg';
import Icecreamcone from './Icecreamcone.svg';
import IndividualLollyStick from './Individual_lolly_stick.svg';
import Info from './Info.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrow from './Isosceles_triangles_narrow/triangle_isos_narrow.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowBlue from './Isosceles_triangles_narrow/triangle_isos_narrow_blue.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowBlueOneInteriorAngleLhs from './Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_lhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowBlueOneInteriorAngleRhs from './Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_rhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowGreen from './Isosceles_triangles_narrow/triangle_isos_narrow_green.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowGreenOneInteriorAngleLhs from './Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_lhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowGreenOneInteriorAngleRhs from './Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_rhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowOrange from './Isosceles_triangles_narrow/triangle_isos_narrow_orange.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowPink from './Isosceles_triangles_narrow/triangle_isos_narrow_pink.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowPinkOneInteriorAngleLhs from './Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_lhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowPinkOneInteriorAngleRhs from './Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_rhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowPurple from './Isosceles_triangles_narrow/triangle_isos_narrow_purple.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowPurpleOneInteriorAngleLhs from './Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_lhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowPurpleOneInteriorAngleRhs from './Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_rhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowRed from './Isosceles_triangles_narrow/triangle_isos_narrow_red.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowTurquoise from './Isosceles_triangles_narrow/triangle_isos_narrow_turquoise.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowWhite from './Isosceles_triangles_narrow/triangle_isos_narrow_white.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowWhiteOneInteriorAngleLhs from './Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_lhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowWhiteOneInteriorAngleRhs from './Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_rhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowYellow from './Isosceles_triangles_narrow/triangle_isos_narrow_yellow.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowYellowOneInteriorAngleLhs from './Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_lhs.svg';
import IsoscelesTrianglesNarrowTriangleIsosNarrowYellowOneInteriorAngleRhs from './Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_rhs.svg';
import IsoscelesTrianglesWideTriangleIsosWide from './Isosceles_triangles_wide/triangle_isos_wide.svg';
import IsoscelesTrianglesWideTriangleIsosWideBlue from './Isosceles_triangles_wide/triangle_isos_wide_blue.svg';
import IsoscelesTrianglesWideTriangleIsosWideBlueOneInteriorAngleLhs from './Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_lhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideBlueOneInteriorAngleRhs from './Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_rhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideGreen from './Isosceles_triangles_wide/triangle_isos_wide_green.svg';
import IsoscelesTrianglesWideTriangleIsosWideGreenOneInteriorAngleLhs from './Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_lhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideGreenOneInteriorAngleRhs from './Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_rhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideOrange from './Isosceles_triangles_wide/triangle_isos_wide_orange.svg';
import IsoscelesTrianglesWideTriangleIsosWidePink from './Isosceles_triangles_wide/triangle_isos_wide_pink.svg';
import IsoscelesTrianglesWideTriangleIsosWidePinkOneInteriorAngleLhs from './Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_lhs.svg';
import IsoscelesTrianglesWideTriangleIsosWidePinkOneInteriorAngleRhs from './Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_rhs.svg';
import IsoscelesTrianglesWideTriangleIsosWidePurple from './Isosceles_triangles_wide/triangle_isos_wide_purple.svg';
import IsoscelesTrianglesWideTriangleIsosWidePurpleOneInteriorAngleLhs from './Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_lhs.svg';
import IsoscelesTrianglesWideTriangleIsosWidePurpleOneInteriorAngleRhs from './Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_rhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideRed from './Isosceles_triangles_wide/triangle_isos_wide_red.svg';
import IsoscelesTrianglesWideTriangleIsosWideTurquoise from './Isosceles_triangles_wide/triangle_isos_wide_turquoise.svg';
import IsoscelesTrianglesWideTriangleIsosWideWhite from './Isosceles_triangles_wide/triangle_isos_wide_white.svg';
import IsoscelesTrianglesWideTriangleIsosWideWhiteOneInteriorAngleLhs from './Isosceles_triangles_wide/triangle_isos_wide_white_one_interior_angle_lhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideWhiteOneInteriorAngleRhs from './Isosceles_triangles_wide/triangle_isos_wide_white_one_interior_angle_rhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideYellow from './Isosceles_triangles_wide/triangle_isos_wide_yellow.svg';
import IsoscelesTrianglesWideTriangleIsosWideYellowOneInteriorAngleLhs from './Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_lhs.svg';
import IsoscelesTrianglesWideTriangleIsosWideYellowOneInteriorAngleRhs from './Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_rhs.svg';
import JackBirdsEye from './Jack_birds_eye.svg';
import Juice from './Juice.svg';
import Ketchup from './Ketchup.svg';
import Kettle from './Kettle.svg';
import Keychain1 from './Keychain1.svg';
import KiteKite from './Kite/kite.svg';
import KiteKiteBlue from './Kite/kite_blue.svg';
import KiteKiteGreen from './Kite/kite_green.svg';
import KiteKiteGrey from './Kite/kite_grey.svg';
import KiteKiteOrange from './Kite/kite_orange.svg';
import KiteKitePink from './Kite/kite_pink.svg';
import KiteKitePurple from './Kite/kite_purple.svg';
import KiteKiteRed from './Kite/kite_red.svg';
import KiteKiteTurquoise from './Kite/kite_turquoise.svg';
import KiteKiteWhite from './Kite/kite_white.svg';
import KiteKiteYellow from './Kite/kite_yellow.svg';
import KiwiFruit from './Kiwi_fruit.svg';
import LShapesLShapeBlue from './L_shapes/L_shape_blue.svg';
import LShapesLShapeGreen from './L_shapes/L_shape_green.svg';
import LShapesLShapeOrange from './L_shapes/L_shape_orange.svg';
import LShapesLShapePink from './L_shapes/L_shape_pink.svg';
import LShapesLShapePurple from './L_shapes/L_shape_purple.svg';
import LShapesLShapeRed from './L_shapes/L_shape_red.svg';
import LShapesLShapeTurquoise from './L_shapes/L_shape_turquoise.svg';
import LShapesLShapeYellow from './L_shapes/L_shape_yellow.svg';
import LabelledBoxA from './Labelled_box_A.svg';
import LabelledBoxB from './Labelled_box_B.svg';
import LabelledBoxC from './Labelled_box_C.svg';
import LabelledBoxD from './Labelled_box_D.svg';
import LabelledBoxBricks from './Labelled_box_bricks.svg';
import Laptop from './Laptop.svg';
import LargeBin from './Large_bin.svg';
import LayeredShapesLayeredCompoundShapes1 from './Layered_shapes/Layered_compound_shapes1.svg';
import LayeredShapesLayeredCompoundShapes2 from './Layered_shapes/Layered_compound_shapes2.svg';
import LayeredShapesLayeredCompoundShapes3 from './Layered_shapes/Layered_compound_shapes3.svg';
import LayeredShapesLayeredCompoundShapes4 from './Layered_shapes/Layered_compound_shapes4.svg';
import LayeredShapesLayeredCompoundShapes5 from './Layered_shapes/Layered_compound_shapes5.svg';
import LayeredShapesLayeredCompoundShapes6 from './Layered_shapes/Layered_compound_shapes6.svg';
import LegoBricksThickLego6CirclesPink from './Lego_bricks/thick_lego_6_circles_pink.svg';
import LegoBricksThickLego6CirclesRed from './Lego_bricks/thick_lego_6_circles_red.svg';
import LegoBricksThickLego6CirclesYellow from './Lego_bricks/thick_lego_6_circles_yellow.svg';
import LegoBricksThickLego8CirclesWhite from './Lego_bricks/thick_lego_8_circles_white.svg';
import LegoBricksThinLego6Circles1Purple from './Lego_bricks/thin_lego_6_circles1_purple.svg';
import LegoBricksThinLego6Circles2Green from './Lego_bricks/thin_lego_6_circles2_green.svg';
import Lemon from './Lemon.svg';
import LemonadeBottle from './Lemonade_bottle.svg';
import Lettuce from './Lettuce.svg';
import Lion from './Lion.svg';
import LionSideProfile from './Lion_side_profile.svg';
import LoafOfBread from './Loaf_of_bread.svg';
import LollyStickShapesDecagonLollySticks from './Lolly_stick_shapes/Decagon_lolly_sticks.svg';
import LollyStickShapesHeptagonLollySticks from './Lolly_stick_shapes/Heptagon_lolly_sticks.svg';
import LollyStickShapesHexagon1LollySticks from './Lolly_stick_shapes/Hexagon1_lolly_sticks.svg';
import LollyStickShapesHexagon2LollySticks from './Lolly_stick_shapes/Hexagon2_lolly_sticks.svg';
import LollyStickShapesNonagon2LollySticks from './Lolly_stick_shapes/Nonagon2_lolly_sticks.svg';
import LollyStickShapesNonagonLollySticks from './Lolly_stick_shapes/Nonagon_lolly_sticks.svg';
import LollyStickShapesOctagon1LollySticks from './Lolly_stick_shapes/Octagon1_lolly_sticks.svg';
import LollyStickShapesPentagonLollySticks from './Lolly_stick_shapes/Pentagon_lolly_sticks.svg';
import LollyStickShapesQuadrilateral1LollySticks from './Lolly_stick_shapes/Quadrilateral1_lolly_sticks.svg';
import LollyStickShapesQuadrilateral2LollySticks from './Lolly_stick_shapes/Quadrilateral2_lolly_sticks.svg';
import LollyStickShapesRectangle10LollySticks from './Lolly_stick_shapes/Rectangle10_lolly_sticks.svg';
import LollyStickShapesRectangle11LollySticks from './Lolly_stick_shapes/Rectangle11_lolly_sticks.svg';
import LollyStickShapesRectangle12LollySticks from './Lolly_stick_shapes/Rectangle12_lolly_sticks.svg';
import LollyStickShapesRectangle13LollySticks from './Lolly_stick_shapes/Rectangle13_lolly_sticks.svg';
import LollyStickShapesRectangle14LollySticks from './Lolly_stick_shapes/Rectangle14_lolly_sticks.svg';
import LollyStickShapesRectangle15LollySticks from './Lolly_stick_shapes/Rectangle15_lolly_sticks.svg';
import LollyStickShapesRectangle16LollySticks from './Lolly_stick_shapes/Rectangle16_lolly_sticks.svg';
import LollyStickShapesRectangle17LollySticks from './Lolly_stick_shapes/Rectangle17_lolly_sticks.svg';
import LollyStickShapesRectangle18LollySticks from './Lolly_stick_shapes/Rectangle18_lolly_sticks.svg';
import LollyStickShapesRectangle19LollySticks from './Lolly_stick_shapes/Rectangle19_lolly_sticks.svg';
import LollyStickShapesRectangle1LollySticks from './Lolly_stick_shapes/Rectangle1_lolly_sticks.svg';
import LollyStickShapesRectangle20LollySticks from './Lolly_stick_shapes/Rectangle20_lolly_sticks.svg';
import LollyStickShapesRectangle2LollySticks from './Lolly_stick_shapes/Rectangle2_lolly_sticks.svg';
import LollyStickShapesRectangle3LollySticks from './Lolly_stick_shapes/Rectangle3_lolly_sticks.svg';
import LollyStickShapesRectangle4LollySticks from './Lolly_stick_shapes/Rectangle4_lolly_sticks.svg';
import LollyStickShapesRectangle5LollySticks from './Lolly_stick_shapes/Rectangle5_lolly_sticks.svg';
import LollyStickShapesRectangle6LollySticks from './Lolly_stick_shapes/Rectangle6_lolly_sticks.svg';
import LollyStickShapesRectangle7LollySticks from './Lolly_stick_shapes/Rectangle7_lolly_sticks.svg';
import LollyStickShapesRectangle8LollySticks from './Lolly_stick_shapes/Rectangle8_lolly_sticks.svg';
import LollyStickShapesRectangle9LollySticks from './Lolly_stick_shapes/Rectangle9_lolly_sticks.svg';
import LollyStickShapesRectilinearShape1LollySticks from './Lolly_stick_shapes/Rectilinear_shape1_lolly_sticks.svg';
import LollyStickShapesRectilinearShape2LollySticks from './Lolly_stick_shapes/Rectilinear_shape2_lolly_sticks.svg';
import LollyStickShapesRectilinearShape3LollySticks from './Lolly_stick_shapes/Rectilinear_shape3_lolly_sticks.svg';
import LollyStickShapesRectilinearShape4LollySticks from './Lolly_stick_shapes/Rectilinear_shape4_lolly_sticks.svg';
import LollyStickShapesRectilinearShape5LollySticks from './Lolly_stick_shapes/Rectilinear_shape5_lolly_sticks.svg';
import LollyStickShapesRectilinearShape6LollySticks from './Lolly_stick_shapes/Rectilinear_shape6_lolly_sticks.svg';
import LollyStickShapesRectilinearShape7LollySticks from './Lolly_stick_shapes/Rectilinear_shape7_lolly_sticks.svg';
import LollyStickShapesRectilinearShape8LollySticks from './Lolly_stick_shapes/Rectilinear_shape8_lolly_sticks.svg';
import LollyStickShapesRectilinearShape9LollySticks from './Lolly_stick_shapes/Rectilinear_shape9_lolly_sticks.svg';
import LollyStickShapesTriangleLollySticks from './Lolly_stick_shapes/Triangle_lolly_sticks.svg';
import LollySticksFiveLollyStickPentagon from './Lolly_sticks/FiveLollyStickPentagon.svg';
import LollySticksFourLollyStickPentagon from './Lolly_sticks/FourLollyStickPentagon.svg';
import LollySticksFourLollyStickSquare from './Lolly_sticks/FourLollyStickSquare.svg';
import LollySticksLollyStickDiamond from './Lolly_sticks/LollyStickDiamond.svg';
import LollySticksLollyStickHouse from './Lolly_sticks/LollyStickHouse.svg';
import LollySticksLollyStickRectangle from './Lolly_sticks/LollyStickRectangle.svg';
import LollySticksLollyStickTriangle from './Lolly_sticks/LollyStickTriangle.svg';
import LollySticksLollySticksPatternsLollySticksDiamondPattern from './Lolly_sticks/LollySticksPatterns/LollySticksDiamondPattern.svg';
import LollySticksLollySticksPatternsLollySticksHousePatternA from './Lolly_sticks/LollySticksPatterns/LollySticksHousePatternA.svg';
import LollySticksLollySticksPatternsLollySticksHousePatternB from './Lolly_sticks/LollySticksPatterns/LollySticksHousePatternB.svg';
import LollySticksLollySticksPatternsLollySticksRectanglePattern from './Lolly_sticks/LollySticksPatterns/LollySticksRectanglePattern.svg';
import LollySticksLollySticksPatternsLollySticksSquarePattern from './Lolly_sticks/LollySticksPatterns/LollySticksSquarePattern.svg';
import LollySticksLollySticksPatternsLollySticksTrianglePattern from './Lolly_sticks/LollySticksPatterns/LollySticksTrianglePattern.svg';
import LollySticksSingleLollyStickPentagon from './Lolly_sticks/SingleLollyStickPentagon.svg';
import LollySticksSingleLollyStickSquare from './Lolly_sticks/SingleLollyStickSquare.svg';
import LollySticksSixLollyStickHexagon from './Lolly_sticks/SixLollyStickHexagon.svg';
import LollySticksThreeLollyStickPentagon from './Lolly_sticks/ThreeLollyStickPentagon.svg';
import LollySticksThreeLollyStickSquare from './Lolly_sticks/ThreeLollyStickSquare.svg';
import LollySticksThreeLollyStickTriangle from './Lolly_sticks/ThreeLollyStickTriangle.svg';
import LollySticksTwoLollyStickPentagon from './Lolly_sticks/TwoLollyStickPentagon.svg';
import LollySticksTwoLollyStickSquare from './Lolly_sticks/TwoLollyStickSquare.svg';
import Magazine from './Magazine.svg';
import Marble from './Marble.svg';
import MarbleBag from './Marble_bag.svg';
import MeasurePencil from './Measure/pencil.svg';
import MetreStick from './Metre_stick.svg';
import Microwave from './Microwave.svg';
import MilkCarton from './Milk_Carton.svg';
import MilkCarton2l from './Milk_carton_2l.svg';
import MilkCarton5l from './Milk_carton_5l.svg';
import Milkshake from './Milkshake.svg';
import MoBirdsEye from './Mo_birds_eye.svg';
import MoneyPence1 from './Money/Pence1.svg';
import MoneyPence10 from './Money/Pence10.svg';
import MoneyPence2 from './Money/Pence2.svg';
import MoneyPence20 from './Money/Pence20.svg';
import MoneyPence5 from './Money/Pence5.svg';
import MoneyPence50 from './Money/Pence50.svg';
import MoneyPounds1 from './Money/Pounds1.svg';
import MoneyPounds2 from './Money/Pounds2.svg';
import MoonWhite from './Moon_white.svg';
import MoonYellow from './Moon_yellow.svg';
import MuffinsDonutsMuffinsOnARack0 from './Muffins_donuts/Muffins_on_a_rack_0.svg';
import MuffinsDonutsMuffinsOnARack1 from './Muffins_donuts/Muffins_on_a_rack_1.svg';
import MuffinsDonutsMuffinsOnARack10 from './Muffins_donuts/Muffins_on_a_rack_10.svg';
import MuffinsDonutsMuffinsOnARack11 from './Muffins_donuts/Muffins_on_a_rack_11.svg';
import MuffinsDonutsMuffinsOnARack12 from './Muffins_donuts/Muffins_on_a_rack_12.svg';
import MuffinsDonutsMuffinsOnARack2 from './Muffins_donuts/Muffins_on_a_rack_2.svg';
import MuffinsDonutsMuffinsOnARack3 from './Muffins_donuts/Muffins_on_a_rack_3.svg';
import MuffinsDonutsMuffinsOnARack4 from './Muffins_donuts/Muffins_on_a_rack_4.svg';
import MuffinsDonutsMuffinsOnARack5 from './Muffins_donuts/Muffins_on_a_rack_5.svg';
import MuffinsDonutsMuffinsOnARack6 from './Muffins_donuts/Muffins_on_a_rack_6.svg';
import MuffinsDonutsMuffinsOnARack7 from './Muffins_donuts/Muffins_on_a_rack_7.svg';
import MuffinsDonutsMuffinsOnARack8 from './Muffins_donuts/Muffins_on_a_rack_8.svg';
import MuffinsDonutsMuffinsOnARack9 from './Muffins_donuts/Muffins_on_a_rack_9.svg';
import MuffinsIndividuallyMuffin1 from './Muffins_individually/Muffin_1.svg';
import MuffinsIndividuallyMuffin2 from './Muffins_individually/Muffin_2.svg';
import MuffinsIndividuallyMuffin3 from './Muffins_individually/Muffin_3.svg';
import MuffinsIndividuallyMuffin4 from './Muffins_individually/Muffin_4.svg';
import MuffinsIndividuallyMuffin5 from './Muffins_individually/Muffin_5.svg';
import MuffinsIndividuallyMuffin6 from './Muffins_individually/Muffin_6.svg';
import MuffinsIndividuallyMuffin7 from './Muffins_individually/Muffin_7.svg';
import Mug from './Mug.svg';
import MugYellow from './Mug_yellow.svg';
import MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledBlue from './Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_blue.svg';
import MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledGreen from './Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_green.svg';
import MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledRed from './Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_red.svg';
import MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledYellow from './Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_yellow.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalBlue from './Multi_link_cubes/Multilink_cube_individual_vertical_blue.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalGreen from './Multi_link_cubes/Multilink_cube_individual_vertical_green.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalLabelledBlue from './Multi_link_cubes/Multilink_cube_individual_vertical_labelled_blue.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalLabelledGreen from './Multi_link_cubes/Multilink_cube_individual_vertical_labelled_green.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalLabelledRed from './Multi_link_cubes/Multilink_cube_individual_vertical_labelled_red.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalLabelledYellow from './Multi_link_cubes/Multilink_cube_individual_vertical_labelled_yellow.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalRed from './Multi_link_cubes/Multilink_cube_individual_vertical_red.svg';
import MultiLinkCubesMultilinkCubeIndividualVerticalYellow from './Multi_link_cubes/Multilink_cube_individual_vertical_yellow.svg';
import MultiLinkCubesColumn1Blue from './Multi_link_cubes/column_1_blue.svg';
import MultiLinkCubesColumn1Green from './Multi_link_cubes/column_1_green.svg';
import MultiLinkCubesColumn1Orange from './Multi_link_cubes/column_1_orange.svg';
import MultiLinkCubesColumn1Purple from './Multi_link_cubes/column_1_purple.svg';
import MultiLinkCubesColumn1Red from './Multi_link_cubes/column_1_red.svg';
import MultiLinkCubesColumn2Blue from './Multi_link_cubes/column_2_blue.svg';
import MultiLinkCubesColumn2Green from './Multi_link_cubes/column_2_green.svg';
import MultiLinkCubesColumn2Orange from './Multi_link_cubes/column_2_orange.svg';
import MultiLinkCubesColumn2Purple from './Multi_link_cubes/column_2_purple.svg';
import MultiLinkCubesColumn2Red from './Multi_link_cubes/column_2_red.svg';
import MultiLinkCubesColumn3Blue from './Multi_link_cubes/column_3_blue.svg';
import MultiLinkCubesColumn3Green from './Multi_link_cubes/column_3_green.svg';
import MultiLinkCubesColumn3Orange from './Multi_link_cubes/column_3_orange.svg';
import MultiLinkCubesColumn3Purple from './Multi_link_cubes/column_3_purple.svg';
import MultiLinkCubesColumn3Red from './Multi_link_cubes/column_3_red.svg';
import MultiLinkCubesColumn4Blue from './Multi_link_cubes/column_4_blue.svg';
import MultiLinkCubesColumn4Green from './Multi_link_cubes/column_4_green.svg';
import MultiLinkCubesColumn4Orange from './Multi_link_cubes/column_4_orange.svg';
import MultiLinkCubesColumn4Purple from './Multi_link_cubes/column_4_purple.svg';
import MultiLinkCubesColumn4Red from './Multi_link_cubes/column_4_red.svg';
import MultiLinkCubesColumn5Blue from './Multi_link_cubes/column_5_blue.svg';
import MultiLinkCubesColumn5Green from './Multi_link_cubes/column_5_green.svg';
import MultiLinkCubesColumn5Orange from './Multi_link_cubes/column_5_orange.svg';
import MultiLinkCubesColumn5Purple from './Multi_link_cubes/column_5_purple.svg';
import MultiLinkCubesColumn5Red from './Multi_link_cubes/column_5_red.svg';
import MultiLinkCubesColumn6Blue from './Multi_link_cubes/column_6_blue.svg';
import MultiLinkCubesColumn6Green from './Multi_link_cubes/column_6_green.svg';
import MultiLinkCubesColumn6Orange from './Multi_link_cubes/column_6_orange.svg';
import MultiLinkCubesColumn6Purple from './Multi_link_cubes/column_6_purple.svg';
import MultiLinkCubesColumn6Red from './Multi_link_cubes/column_6_red.svg';
import MultiLinkCubesColumn7Blue from './Multi_link_cubes/column_7_blue.svg';
import MultiLinkCubesColumn7Green from './Multi_link_cubes/column_7_green.svg';
import MultiLinkCubesColumn7Orange from './Multi_link_cubes/column_7_orange.svg';
import MultiLinkCubesColumn7Purple from './Multi_link_cubes/column_7_purple.svg';
import MultiLinkCubesColumn7Red from './Multi_link_cubes/column_7_red.svg';
import MultiLinkCubesColumn8Blue from './Multi_link_cubes/column_8_blue.svg';
import MultiLinkCubesColumn8Green from './Multi_link_cubes/column_8_green.svg';
import MultiLinkCubesColumn8Orange from './Multi_link_cubes/column_8_orange.svg';
import MultiLinkCubesColumn8Purple from './Multi_link_cubes/column_8_purple.svg';
import MultiLinkCubesColumn8Red from './Multi_link_cubes/column_8_red.svg';
import MultiLinkCubesFrontColumnBlue from './Multi_link_cubes/front_column_blue.svg';
import MultiLinkCubesFrontColumnGreen from './Multi_link_cubes/front_column_green.svg';
import MultiLinkCubesFrontColumnOrange from './Multi_link_cubes/front_column_orange.svg';
import MultiLinkCubesFrontColumnPurple from './Multi_link_cubes/front_column_purple.svg';
import MultiLinkCubesFrontColumnRed from './Multi_link_cubes/front_column_red.svg';
import MultiLinkCubesFrontColumnYellow from './Multi_link_cubes/front_column_yellow.svg';
import MultilinkShapesMultilinkCubesAndCuboids1 from './Multilink_shapes/Multilink_cubes_and_cuboids1.svg';
import MultilinkShapesMultilinkCubesAndCuboids10 from './Multilink_shapes/Multilink_cubes_and_cuboids10.svg';
import MultilinkShapesMultilinkCubesAndCuboids11 from './Multilink_shapes/Multilink_cubes_and_cuboids11.svg';
import MultilinkShapesMultilinkCubesAndCuboids12 from './Multilink_shapes/Multilink_cubes_and_cuboids12.svg';
import MultilinkShapesMultilinkCubesAndCuboids13 from './Multilink_shapes/Multilink_cubes_and_cuboids13.svg';
import MultilinkShapesMultilinkCubesAndCuboids2 from './Multilink_shapes/Multilink_cubes_and_cuboids2.svg';
import MultilinkShapesMultilinkCubesAndCuboids3 from './Multilink_shapes/Multilink_cubes_and_cuboids3.svg';
import MultilinkShapesMultilinkCubesAndCuboids4 from './Multilink_shapes/Multilink_cubes_and_cuboids4.svg';
import MultilinkShapesMultilinkCubesAndCuboids5 from './Multilink_shapes/Multilink_cubes_and_cuboids5.svg';
import MultilinkShapesMultilinkCubesAndCuboids6 from './Multilink_shapes/Multilink_cubes_and_cuboids6.svg';
import MultilinkShapesMultilinkCubesAndCuboids7 from './Multilink_shapes/Multilink_cubes_and_cuboids7.svg';
import MultilinkShapesMultilinkCubesAndCuboids8 from './Multilink_shapes/Multilink_cubes_and_cuboids8.svg';
import MultilinkShapesMultilinkCubesAndCuboids9 from './Multilink_shapes/Multilink_cubes_and_cuboids9.svg';
import MultilinkShapesMultilinkShape110cubes from './Multilink_shapes/Multilink_shape1_10cubes.svg';
import MultilinkShapesMultilinkShape112cubes from './Multilink_shapes/Multilink_shape1_12cubes.svg';
import MultilinkShapesMultilinkShape14cubes from './Multilink_shapes/Multilink_shape1_4cubes.svg';
import MultilinkShapesMultilinkShape16cubes from './Multilink_shapes/Multilink_shape1_6cubes.svg';
import MultilinkShapesMultilinkShape18cubes from './Multilink_shapes/Multilink_shape1_8cubes.svg';
import MultilinkShapesMultilinkShape210cubes from './Multilink_shapes/Multilink_shape2_10cubes.svg';
import MultilinkShapesMultilinkShape212cubes from './Multilink_shapes/Multilink_shape2_12cubes.svg';
import MultilinkShapesMultilinkShape24cubes from './Multilink_shapes/Multilink_shape2_4cubes.svg';
import MultilinkShapesMultilinkShape27cubes from './Multilink_shapes/Multilink_shape2_7cubes.svg';
import MultilinkShapesMultilinkShape28cubes from './Multilink_shapes/Multilink_shape2_8cubes.svg';
import MultilinkShapesMultilinkShape310cubes from './Multilink_shapes/Multilink_shape3_10cubes.svg';
import MultilinkShapesMultilinkShape312cubes from './Multilink_shapes/Multilink_shape3_12cubes.svg';
import MultilinkShapesMultilinkShape34cubes from './Multilink_shapes/Multilink_shape3_4cubes.svg';
import MultilinkShapesMultilinkShape36cubes from './Multilink_shapes/Multilink_shape3_6cubes.svg';
import MultilinkShapesMultilinkShape38cubes from './Multilink_shapes/Multilink_shape3_8cubes.svg';
import NonagonsIrregularNonagonBlue from './Nonagons/Irregular_nonagon_blue.svg';
import NonagonsIrregularNonagonGreen from './Nonagons/Irregular_nonagon_green.svg';
import NonagonsIrregularNonagonPink from './Nonagons/Irregular_nonagon_pink.svg';
import NonagonsIrregularNonagonPurple from './Nonagons/Irregular_nonagon_purple.svg';
import NonagonsIrregularNonagonWhite from './Nonagons/Irregular_nonagon_white.svg';
import NonagonsIrregularNonagonYellow from './Nonagons/Irregular_nonagon_yellow.svg';
import NonagonsRegularNonagon1Arrow from './Nonagons/Regular_nonagon_1_arrow.svg';
import NonagonsRegularNonagon1ArrowBlue from './Nonagons/Regular_nonagon_1_arrow_blue.svg';
import NonagonsRegularNonagon1ArrowGreen from './Nonagons/Regular_nonagon_1_arrow_green.svg';
import NonagonsRegularNonagon1ArrowPink from './Nonagons/Regular_nonagon_1_arrow_pink.svg';
import NonagonsRegularNonagon1ArrowPurple from './Nonagons/Regular_nonagon_1_arrow_purple.svg';
import NonagonsRegularNonagon1ArrowYellow from './Nonagons/Regular_nonagon_1_arrow_yellow.svg';
import NonagonsRegularNonagonBlue from './Nonagons/Regular_nonagon_blue.svg';
import NonagonsRegularNonagonGreen from './Nonagons/Regular_nonagon_green.svg';
import NonagonsRegularNonagonPink from './Nonagons/Regular_nonagon_pink.svg';
import NonagonsRegularNonagonPurple from './Nonagons/Regular_nonagon_purple.svg';
import NonagonsRegularNonagonWhite from './Nonagons/Regular_nonagon_white.svg';
import NonagonsRegularNonagonYellow from './Nonagons/Regular_nonagon_yellow.svg';
import OceanSceneDepicitingDepthsOceanScene1 from './Ocean_Scene_depiciting_depths/Ocean_Scene_1.svg';
import OceanSceneDepicitingDepthsOceanScene10 from './Ocean_Scene_depiciting_depths/Ocean_Scene_10.svg';
import OceanSceneDepicitingDepthsOceanScene11 from './Ocean_Scene_depiciting_depths/Ocean_Scene_11.svg';
import OceanSceneDepicitingDepthsOceanScene12 from './Ocean_Scene_depiciting_depths/Ocean_Scene_12.svg';
import OceanSceneDepicitingDepthsOceanScene13 from './Ocean_Scene_depiciting_depths/Ocean_Scene_13.svg';
import OceanSceneDepicitingDepthsOceanScene14 from './Ocean_Scene_depiciting_depths/Ocean_Scene_14.svg';
import OceanSceneDepicitingDepthsOceanScene15 from './Ocean_Scene_depiciting_depths/Ocean_Scene_15.svg';
import OceanSceneDepicitingDepthsOceanScene16 from './Ocean_Scene_depiciting_depths/Ocean_Scene_16.svg';
import OceanSceneDepicitingDepthsOceanScene17 from './Ocean_Scene_depiciting_depths/Ocean_Scene_17.svg';
import OceanSceneDepicitingDepthsOceanScene18 from './Ocean_Scene_depiciting_depths/Ocean_Scene_18.svg';
import OceanSceneDepicitingDepthsOceanScene19 from './Ocean_Scene_depiciting_depths/Ocean_Scene_19.svg';
import OceanSceneDepicitingDepthsOceanScene2 from './Ocean_Scene_depiciting_depths/Ocean_Scene_2.svg';
import OceanSceneDepicitingDepthsOceanScene20 from './Ocean_Scene_depiciting_depths/Ocean_Scene_20.svg';
import OceanSceneDepicitingDepthsOceanScene21 from './Ocean_Scene_depiciting_depths/Ocean_Scene_21.svg';
import OceanSceneDepicitingDepthsOceanScene22 from './Ocean_Scene_depiciting_depths/Ocean_Scene_22.svg';
import OceanSceneDepicitingDepthsOceanScene23 from './Ocean_Scene_depiciting_depths/Ocean_Scene_23.svg';
import OceanSceneDepicitingDepthsOceanScene24 from './Ocean_Scene_depiciting_depths/Ocean_Scene_24.svg';
import OceanSceneDepicitingDepthsOceanScene3 from './Ocean_Scene_depiciting_depths/Ocean_Scene_3.svg';
import OceanSceneDepicitingDepthsOceanScene4 from './Ocean_Scene_depiciting_depths/Ocean_Scene_4.svg';
import OceanSceneDepicitingDepthsOceanScene5 from './Ocean_Scene_depiciting_depths/Ocean_Scene_5.svg';
import OceanSceneDepicitingDepthsOceanScene6 from './Ocean_Scene_depiciting_depths/Ocean_Scene_6.svg';
import OceanSceneDepicitingDepthsOceanScene7 from './Ocean_Scene_depiciting_depths/Ocean_Scene_7.svg';
import OceanSceneDepicitingDepthsOceanScene8 from './Ocean_Scene_depiciting_depths/Ocean_Scene_8.svg';
import OceanSceneDepicitingDepthsOceanScene9 from './Ocean_Scene_depiciting_depths/Ocean_Scene_9.svg';
import OctagonsIrregularOctagon1Blue from './Octagons/Irregular_octagon1_blue.svg';
import OctagonsIrregularOctagon1Green from './Octagons/Irregular_octagon1_green.svg';
import OctagonsIrregularOctagon1Orange from './Octagons/Irregular_octagon1_orange.svg';
import OctagonsIrregularOctagon1Pink from './Octagons/Irregular_octagon1_pink.svg';
import OctagonsIrregularOctagon1Purple from './Octagons/Irregular_octagon1_purple.svg';
import OctagonsIrregularOctagon1Red from './Octagons/Irregular_octagon1_red.svg';
import OctagonsIrregularOctagon1Turquoise from './Octagons/Irregular_octagon1_turquoise.svg';
import OctagonsIrregularOctagon1White from './Octagons/Irregular_octagon1_white.svg';
import OctagonsIrregularOctagon1Yellow from './Octagons/Irregular_octagon1_yellow.svg';
import OctagonsIrregularOctagon2Blue from './Octagons/Irregular_octagon2_blue.svg';
import OctagonsIrregularOctagon2Green from './Octagons/Irregular_octagon2_green.svg';
import OctagonsIrregularOctagon2Orange from './Octagons/Irregular_octagon2_orange.svg';
import OctagonsIrregularOctagon2Pink from './Octagons/Irregular_octagon2_pink.svg';
import OctagonsIrregularOctagon2Purple from './Octagons/Irregular_octagon2_purple.svg';
import OctagonsIrregularOctagon2Red from './Octagons/Irregular_octagon2_red.svg';
import OctagonsIrregularOctagon2Turquoise from './Octagons/Irregular_octagon2_turquoise.svg';
import OctagonsIrregularOctagon2White from './Octagons/Irregular_octagon2_white.svg';
import OctagonsIrregularOctagon2Yellow from './Octagons/Irregular_octagon2_yellow.svg';
import OctagonsIrregularOctagon3Blue from './Octagons/Irregular_octagon3_blue.svg';
import OctagonsIrregularOctagon3Green from './Octagons/Irregular_octagon3_green.svg';
import OctagonsIrregularOctagon3Orange from './Octagons/Irregular_octagon3_orange.svg';
import OctagonsIrregularOctagon3Pink from './Octagons/Irregular_octagon3_pink.svg';
import OctagonsIrregularOctagon3Purple from './Octagons/Irregular_octagon3_purple.svg';
import OctagonsIrregularOctagon3Red from './Octagons/Irregular_octagon3_red.svg';
import OctagonsIrregularOctagon3Turquoise from './Octagons/Irregular_octagon3_turquoise.svg';
import OctagonsIrregularOctagon3White from './Octagons/Irregular_octagon3_white.svg';
import OctagonsIrregularOctagon3Yellow from './Octagons/Irregular_octagon3_yellow.svg';
import OctagonsOctagonBlue from './Octagons/Octagon_blue.svg';
import OctagonsOctagonGreen from './Octagons/Octagon_green.svg';
import OctagonsOctagonOrange from './Octagons/Octagon_orange.svg';
import OctagonsOctagonPink from './Octagons/Octagon_pink.svg';
import OctagonsOctagonPurple from './Octagons/Octagon_purple.svg';
import OctagonsOctagonRed from './Octagons/Octagon_red.svg';
import OctagonsOctagonTurquoise from './Octagons/Octagon_turquoise.svg';
import OctagonsOctagonYellow from './Octagons/Octagon_yellow.svg';
import OctagonsRegularOctagon1Arrow from './Octagons/Regular_octagon_1_arrow.svg';
import OctagonsRegularOctagon1ArrowBlue from './Octagons/Regular_octagon_1_arrow_blue.svg';
import OctagonsRegularOctagon1ArrowGreen from './Octagons/Regular_octagon_1_arrow_green.svg';
import OctagonsRegularOctagon1ArrowPink from './Octagons/Regular_octagon_1_arrow_pink.svg';
import OctagonsRegularOctagon1ArrowPurple from './Octagons/Regular_octagon_1_arrow_purple.svg';
import OctagonsRegularOctagon1ArrowYellow from './Octagons/Regular_octagon_1_arrow_yellow.svg';
import OrangeJuiceCarton from './Orange_juice_carton.svg';
import OrangeJuiceCartonBlank from './Orange_juice_carton_blank.svg';
import OtherShapes4AnglesAroundAPoint from './Other_shapes/4_angles_around_a_point.svg';
import OtherShapes5AnglesAroundAPoint from './Other_shapes/5_angles_around_a_point.svg';
import OtherShapes6AnglesAroundAPoint from './Other_shapes/6_angles_around_a_point.svg';
import OtherShapes7AnglesAroundAPoint from './Other_shapes/7_angles_around_a_point.svg';
import OtherShapesAnglesOnAStraightLine from './Other_shapes/Angles_on_a_straight_line.svg';
import OtherShapesAntiClockwiseShadedDiagramFullTurn from './Other_shapes/Anti_clockwise_shaded_diagram_full_turn.svg';
import OtherShapesAntiClockwiseShadedDiagramHalfTurn from './Other_shapes/Anti_clockwise_shaded_diagram_half_turn.svg';
import OtherShapesAntiClockwiseShadedDiagramQuarterTurn from './Other_shapes/Anti_clockwise_shaded_diagram_quarter_turn.svg';
import OtherShapesAntiClockwiseShadedDiagramThreeQuarterTurn from './Other_shapes/Anti_clockwise_shaded_diagram_three_quarter_turn.svg';
import OtherShapesClockwiseShadedDiagramFullTurn from './Other_shapes/Clockwise_shaded_diagram_full_turn.svg';
import OtherShapesClockwiseShadedDiagramHalfTurn from './Other_shapes/Clockwise_shaded_diagram_half_turn.svg';
import OtherShapesClockwiseShadedDiagramQuarterTurn from './Other_shapes/Clockwise_shaded_diagram_quarter_turn.svg';
import OtherShapesClockwiseShadedDiagramThreeQuarterTurn from './Other_shapes/Clockwise_shaded_diagram_three_quarter_turn.svg';
import OtherShapesColouredArrow1 from './Other_shapes/Coloured_arrow1.svg';
import OtherShapesColouredArrow2 from './Other_shapes/Coloured_arrow2.svg';
import OtherShapesColouredArrow3 from './Other_shapes/Coloured_arrow3.svg';
import OtherShapesColouredArrow4 from './Other_shapes/Coloured_arrow4.svg';
import OtherShapesConesDottedLines from './Other_shapes/Cones_dotted_lines.svg';
import OtherShapesCube1 from './Other_shapes/Cube1.svg';
import OtherShapesCube3 from './Other_shapes/Cube3.svg';
import OtherShapesCube4 from './Other_shapes/Cube4.svg';
import OtherShapesCubeDottedlines1 from './Other_shapes/Cube_dottedlines1.svg';
import OtherShapesCubeDottedlines2 from './Other_shapes/Cube_dottedlines2.svg';
import OtherShapesCuboidDottedlines1 from './Other_shapes/Cuboid_dottedlines1.svg';
import OtherShapesCuboidDottedlines2 from './Other_shapes/Cuboid_dottedlines2.svg';
import OtherShapesCuboidDottedlines3 from './Other_shapes/Cuboid_dottedlines3.svg';
import OtherShapesCylinder1 from './Other_shapes/Cylinder1.svg';
import OtherShapesCylinder3 from './Other_shapes/Cylinder3.svg';
import OtherShapesCylinderDottedLines1 from './Other_shapes/Cylinder_dotted_lines1.svg';
import OtherShapesCylinderDottedLines2 from './Other_shapes/Cylinder_dotted_lines2.svg';
import OtherShapesDecagonNonExample1 from './Other_shapes/Decagon_non_example1.svg';
import OtherShapesDecagonNonExample2 from './Other_shapes/Decagon_non_example2.svg';
import OtherShapesDecagonNonExample3 from './Other_shapes/Decagon_non_example3.svg';
import OtherShapesEquilateraltriangleMarkedlines from './Other_shapes/Equilateraltriangle_markedlines.svg';
import OtherShapesHeptagonNonExample1 from './Other_shapes/Heptagon_non_example1.svg';
import OtherShapesHeptagonNonExample2 from './Other_shapes/Heptagon_non_example2.svg';
import OtherShapesHexagonNonExample1 from './Other_shapes/Hexagon_non_example1.svg';
import OtherShapesHexagonNonExample2 from './Other_shapes/Hexagon_non_example2.svg';
import OtherShapesHexagonNonExample4 from './Other_shapes/Hexagon_non_example4.svg';
import OtherShapesHexagonalPrism1 from './Other_shapes/Hexagonal_prism1.svg';
import OtherShapesHexagonalPrism2 from './Other_shapes/Hexagonal_prism2.svg';
import OtherShapesHexagonalprismDottedlines1 from './Other_shapes/Hexagonalprism_dottedlines1.svg';
import OtherShapesHexagonalprismDottedlines2 from './Other_shapes/Hexagonalprism_dottedlines2.svg';
import OtherShapesHexagonalpyramidDottedlines1 from './Other_shapes/Hexagonalpyramid_dottedlines1.svg';
import OtherShapesInteriorAnglesCard from './Other_shapes/Interior_angles_card.svg';
import OtherShapesIrregularDecagon1 from './Other_shapes/Irregular_decagon1.svg';
import OtherShapesIrregularDecagon2 from './Other_shapes/Irregular_decagon2.svg';
import OtherShapesIrregularDecagon2Blue from './Other_shapes/Irregular_decagon2_blue.svg';
import OtherShapesIrregularDecagon2Green from './Other_shapes/Irregular_decagon2_green.svg';
import OtherShapesIrregularDecagon2Pink from './Other_shapes/Irregular_decagon2_pink.svg';
import OtherShapesIrregularDecagon2Purple from './Other_shapes/Irregular_decagon2_purple.svg';
import OtherShapesIrregularDecagon2Yellow from './Other_shapes/Irregular_decagon2_yellow.svg';
import OtherShapesIrregularHeptagon from './Other_shapes/Irregular_heptagon.svg';
import OtherShapesIrregularHeptagonBlue from './Other_shapes/Irregular_heptagon_blue.svg';
import OtherShapesIrregularHeptagonGreen from './Other_shapes/Irregular_heptagon_green.svg';
import OtherShapesIrregularHeptagonPink from './Other_shapes/Irregular_heptagon_pink.svg';
import OtherShapesIrregularHeptagonPurple from './Other_shapes/Irregular_heptagon_purple.svg';
import OtherShapesIrregularHeptagonYellow from './Other_shapes/Irregular_heptagon_yellow.svg';
import OtherShapesIsoscelesTriangleAnglesCard from './Other_shapes/Isosceles_triangle_angles_card.svg';
import OtherShapesNonagonNonExample1 from './Other_shapes/Nonagon_non_example1.svg';
import OtherShapesNonagonNonExample2 from './Other_shapes/Nonagon_non_example2.svg';
import OtherShapesNonagonNonExample3 from './Other_shapes/Nonagon_non_example3.svg';
import OtherShapesOctagonNonExample1 from './Other_shapes/Octagon_non_example1.svg';
import OtherShapesOctagonNonExample2 from './Other_shapes/Octagon_non_example2.svg';
import OtherShapesOctagonNonExample3 from './Other_shapes/Octagon_non_example3.svg';
import OtherShapesParallelogram1MarkedLinesAndAngles from './Other_shapes/Parallelogram1_marked_lines_and_angles.svg';
import OtherShapesParallelogram2MarkedLinesAndAngles from './Other_shapes/Parallelogram2_marked_lines_and_angles.svg';
import OtherShapesParallelogram3MarkedLinesAndAngles from './Other_shapes/Parallelogram3_marked_lines_and_angles.svg';
import OtherShapesParallelogram4MarkedLinesAndAngles from './Other_shapes/Parallelogram4_marked_lines_and_angles.svg';
import OtherShapesPentagonNonExample1 from './Other_shapes/Pentagon_non_example1.svg';
import OtherShapesPentagonNonExample2 from './Other_shapes/Pentagon_non_example2.svg';
import OtherShapesPentagonNonExample3 from './Other_shapes/Pentagon_non_example3.svg';
import OtherShapesPentagonNonExample4 from './Other_shapes/Pentagon_non_example4.svg';
import OtherShapesPentagonNonExample5 from './Other_shapes/Pentagon_non_example5.svg';
import OtherShapesPentagonRegularMarkedLines from './Other_shapes/Pentagon_regular_marked_lines.svg';
import OtherShapesPentagonalprismDottedlines1 from './Other_shapes/Pentagonalprism_dottedlines1.svg';
import OtherShapesPentagonalprismDottedlines2 from './Other_shapes/Pentagonalprism_dottedlines2.svg';
import OtherShapesRectangle1MarkedLinesAndAngles from './Other_shapes/Rectangle1_marked_lines_and_angles.svg';
import OtherShapesRectangle2MarkedLinesAndAngles from './Other_shapes/Rectangle2_marked_lines_and_angles.svg';
import OtherShapesRectangle3MarkedLinesAndAngles from './Other_shapes/Rectangle3_marked_lines_and_angles.svg';
import OtherShapesRectangle4MarkedLinesAndAngles from './Other_shapes/Rectangle4_marked_lines_and_angles.svg';
import OtherShapesRegularDecagon from './Other_shapes/Regular_decagon.svg';
import OtherShapesRegularDecagon1MarkedLines from './Other_shapes/Regular_decagon1_marked_lines.svg';
import OtherShapesRegularDecagon2MarkedLines from './Other_shapes/Regular_decagon2_marked_lines.svg';
import OtherShapesRegularDecagon1Arrow from './Other_shapes/Regular_decagon_1_arrow.svg';
import OtherShapesRegularDecagon1ArrowBlue from './Other_shapes/Regular_decagon_1_arrow_blue.svg';
import OtherShapesRegularDecagon1ArrowGreen from './Other_shapes/Regular_decagon_1_arrow_green.svg';
import OtherShapesRegularDecagon1ArrowPink from './Other_shapes/Regular_decagon_1_arrow_pink.svg';
import OtherShapesRegularDecagon1ArrowPurple from './Other_shapes/Regular_decagon_1_arrow_purple.svg';
import OtherShapesRegularDecagon1ArrowYellow from './Other_shapes/Regular_decagon_1_arrow_yellow.svg';
import OtherShapesRegularDecagonBlue from './Other_shapes/Regular_decagon_blue.svg';
import OtherShapesRegularDecagonEqualSidesOneAngle from './Other_shapes/Regular_decagon_equal_sides_one_angle.svg';
import OtherShapesRegularDecagonGreen from './Other_shapes/Regular_decagon_green.svg';
import OtherShapesRegularDecagonPink from './Other_shapes/Regular_decagon_pink.svg';
import OtherShapesRegularDecagonPurple from './Other_shapes/Regular_decagon_purple.svg';
import OtherShapesRegularDecagonYellow from './Other_shapes/Regular_decagon_yellow.svg';
import OtherShapesRegularHeptagon from './Other_shapes/Regular_heptagon.svg';
import OtherShapesRegularHeptagon1Arrow from './Other_shapes/Regular_heptagon_1_arrow.svg';
import OtherShapesRegularHeptagon1ArrowBlue from './Other_shapes/Regular_heptagon_1_arrow_blue.svg';
import OtherShapesRegularHeptagon1ArrowGreen from './Other_shapes/Regular_heptagon_1_arrow_green.svg';
import OtherShapesRegularHeptagon1ArrowPink from './Other_shapes/Regular_heptagon_1_arrow_pink.svg';
import OtherShapesRegularHeptagon1ArrowPurple from './Other_shapes/Regular_heptagon_1_arrow_purple.svg';
import OtherShapesRegularHeptagon1ArrowYellow from './Other_shapes/Regular_heptagon_1_arrow_yellow.svg';
import OtherShapesRegularHeptagonBlue from './Other_shapes/Regular_heptagon_blue.svg';
import OtherShapesRegularHeptagonEqualSidesOneAngle from './Other_shapes/Regular_heptagon_equal_sides_one_angle.svg';
import OtherShapesRegularHeptagonGreen from './Other_shapes/Regular_heptagon_green.svg';
import OtherShapesRegularHeptagonMarkedLines from './Other_shapes/Regular_heptagon_marked_lines.svg';
import OtherShapesRegularHeptagonPink from './Other_shapes/Regular_heptagon_pink.svg';
import OtherShapesRegularHeptagonPurple from './Other_shapes/Regular_heptagon_purple.svg';
import OtherShapesRegularHeptagonYellow from './Other_shapes/Regular_heptagon_yellow.svg';
import OtherShapesRegularHexagonEqualSidesOneAngle from './Other_shapes/Regular_hexagon_equal_sides_one_angle.svg';
import OtherShapesRegularHexagonMarkedLines from './Other_shapes/Regular_hexagon_marked_lines.svg';
import OtherShapesRegularNonagonEqualSidesOneAngle from './Other_shapes/Regular_nonagon_equal_sides_one_angle.svg';
import OtherShapesRegularNonagonMarkedLines from './Other_shapes/Regular_nonagon_marked_lines.svg';
import OtherShapesRegularOctagon from './Other_shapes/Regular_octagon.svg';
import OtherShapesRegularOctagonBlue from './Other_shapes/Regular_octagon_blue.svg';
import OtherShapesRegularOctagonEqualSidesOneAngle from './Other_shapes/Regular_octagon_equal_sides_one_angle.svg';
import OtherShapesRegularOctagonGreen from './Other_shapes/Regular_octagon_green.svg';
import OtherShapesRegularOctagonMarkedLines from './Other_shapes/Regular_octagon_marked_lines.svg';
import OtherShapesRegularOctagonPink from './Other_shapes/Regular_octagon_pink.svg';
import OtherShapesRegularOctagonPurple from './Other_shapes/Regular_octagon_purple.svg';
import OtherShapesRegularOctagonYellow from './Other_shapes/Regular_octagon_yellow.svg';
import OtherShapesRegularPentagonEqualSidesOneAngle from './Other_shapes/Regular_pentagon_equal_sides_one_angle.svg';
import OtherShapesRhombus1MarkedLinesAndAngles from './Other_shapes/Rhombus1_marked_lines_and_angles.svg';
import OtherShapesRhombus2MarkedLinesAndAngles from './Other_shapes/Rhombus2_marked_lines_and_angles.svg';
import OtherShapesRhombusMarkedlines from './Other_shapes/Rhombus_markedlines.svg';
import OtherShapesRightAngleDottedLines from './Other_shapes/Right_angle_dotted_lines.svg';
import OtherShapesSquareBasedPyramid1 from './Other_shapes/Square_based_pyramid1.svg';
import OtherShapesSquareBasedPyramid2 from './Other_shapes/Square_based_pyramid2.svg';
import OtherShapesSquareBasedPyramid3 from './Other_shapes/Square_based_pyramid3.svg';
import OtherShapesSquareEqualSidesOneAngle from './Other_shapes/Square_equal_sides_one_angle.svg';
import OtherShapesSquareMarkedLinesAndAngles from './Other_shapes/Square_marked_lines_and_angles.svg';
import OtherShapesSquareMarkedlines1 from './Other_shapes/Square_markedlines1.svg';
import OtherShapesSquareMarkedlines2 from './Other_shapes/Square_markedlines2.svg';
import OtherShapesSquareNonExample1 from './Other_shapes/Square_non_example1.svg';
import OtherShapesSquareNonExample2 from './Other_shapes/Square_non_example2.svg';
import OtherShapesSquareNonExample3 from './Other_shapes/Square_non_example3.svg';
import OtherShapesSquareNonExample4 from './Other_shapes/Square_non_example4.svg';
import OtherShapesTetrahedron1 from './Other_shapes/Tetrahedron1.svg';
import OtherShapesTetrahedron4 from './Other_shapes/Tetrahedron4.svg';
import OtherShapesTetrehedron1DottedLines from './Other_shapes/Tetrehedron1_dotted_lines.svg';
import OtherShapesTetrehedron2DottedLines from './Other_shapes/Tetrehedron2_dotted_lines.svg';
import OtherShapesTetrehedron3DottedLines from './Other_shapes/Tetrehedron3_dotted_lines.svg';
import OtherShapesTrapezium1MarkedLinesAndAngles from './Other_shapes/Trapezium1_marked_lines_and_angles.svg';
import OtherShapesTrapezium2MarkedLinesAndAngles from './Other_shapes/Trapezium2_marked_lines_and_angles.svg';
import OtherShapesTrapezium3MarkedLinesAndAngles from './Other_shapes/Trapezium3_marked_lines_and_angles.svg';
import OtherShapesTrapezium4MarkedLinesAndAngles from './Other_shapes/Trapezium4_marked_lines_and_angles.svg';
import OtherShapesTriangle1LineExtension3Angles from './Other_shapes/Triangle1_line_extension_3_angles.svg';
import OtherShapesTriangle2LineExtension3Angles from './Other_shapes/Triangle2_line_extension_3_angles.svg';
import OtherShapesTriangle3LineExtension3Angles from './Other_shapes/Triangle3_line_extension_3_angles.svg';
import OtherShapesTriangle4LineExtension3Angles from './Other_shapes/Triangle4_line_extension_3_angles.svg';
import OtherShapesTriangle5LineExtension3Angles from './Other_shapes/Triangle5_line_extension_3_angles.svg';
import OtherShapesTriangleNonExample1 from './Other_shapes/Triangle_non_example1.svg';
import OtherShapesTriangleNonExample2 from './Other_shapes/Triangle_non_example2.svg';
import OtherShapesTriangularPrism1 from './Other_shapes/Triangular_prism1.svg';
import OtherShapesTriangularPrism2 from './Other_shapes/Triangular_prism2.svg';
import OtherShapesTriangularPrism2by3 from './Other_shapes/Triangular_prism2by3.svg';
import OtherShapesTriangularPrism2by4 from './Other_shapes/Triangular_prism2by4.svg';
import OtherShapesTriangularPrism3 from './Other_shapes/Triangular_prism3.svg';
import OtherShapesTriangularPrism3by3 from './Other_shapes/Triangular_prism3by3.svg';
import OtherShapesTriangularPrism3by4 from './Other_shapes/Triangular_prism3by4.svg';
import OtherShapesTriangularprismDottedlines1 from './Other_shapes/Triangularprism_dottedlines1.svg';
import OtherShapesTriangularprismDottedlines2 from './Other_shapes/Triangularprism_dottedlines2.svg';
import OtherShapesTriangularprismDottedlines3 from './Other_shapes/Triangularprism_dottedlines3.svg';
import OtherShapesVerticallyOppositeAnglesCard from './Other_shapes/Vertically_opposite_angles_card.svg';
import OtherShapesCube2 from './Other_shapes/cube2.svg';
import OtherShapesCuboid2 from './Other_shapes/cuboid2.svg';
import OtherShapesCuboid3 from './Other_shapes/cuboid3.svg';
import OtherShapesCylinder2 from './Other_shapes/cylinder2.svg';
import OtherShapesHexagonalprism2 from './Other_shapes/hexagonalprism2.svg';
import OtherShapesHexagonalpyramid1 from './Other_shapes/hexagonalpyramid1.svg';
import OtherShapesPentagonalprism2 from './Other_shapes/pentagonalprism2.svg';
import OtherShapesSquarebasedpyramid1 from './Other_shapes/squarebasedpyramid1.svg';
import OtherShapesSquarebasedpyramid2 from './Other_shapes/squarebasedpyramid2.svg';
import OtherShapesSquarebasedpyramid3 from './Other_shapes/squarebasedpyramid3.svg';
import OtherShapesStamp3dshape1 from './Other_shapes/stamp3dshape1.svg';
import OtherShapesStamp3dshape2 from './Other_shapes/stamp3dshape2.svg';
import OtherShapesStamp3dshape3 from './Other_shapes/stamp3dshape3.svg';
import OtherShapesStamp3dshape4 from './Other_shapes/stamp3dshape4.svg';
import OtherShapesStamp3dshape5 from './Other_shapes/stamp3dshape5.svg';
import OtherShapesStamp3dshape6 from './Other_shapes/stamp3dshape6.svg';
import OtherShapesTetrahedron2 from './Other_shapes/tetrahedron2.svg';
import OtherShapesTetrahedron3 from './Other_shapes/tetrahedron3.svg';
import OtherShapesTriangularprism2 from './Other_shapes/triangularprism2.svg';
import OtherShapesTriangularprism3 from './Other_shapes/triangularprism3.svg';
import OwlBird from './Owl_bird.svg';
import PackOf10Crayons from './Pack_of_10_crayons.svg';
import PackOfMarblesBlank from './Pack_of_marbles_blank.svg';
import PackOfSweetsUnlabelled from './Pack_of_sweets_unlabelled.svg';
import PackagedSandwich from './Packaged_sandwich.svg';
import PacksOfPinsPackOf100 from './Packs_of_pins_pack_of_100.svg';
import Paddle from './Paddle.svg';
import PaddlingPool from './Paddling_pool.svg';
import PaintBrush from './Paint_brush.svg';
import PaintPalette from './Paint_palette.svg';
import PairedsocksBlue from './PairedsocksBlue.svg';
import PairedsocksGreen from './PairedsocksGreen.svg';
import PairedsocksOrange from './PairedsocksOrange.svg';
import PairedsocksPurple from './PairedsocksPurple.svg';
import PairedsocksRed from './PairedsocksRed.svg';
import PairedsocksYellow from './PairedsocksYellow.svg';
import PaperClip from './Paper_clip.svg';
import ParallelogramParallelogram from './Parallelogram/parallelogram.svg';
import ParallelogramParallelogramBlue from './Parallelogram/parallelogram_blue.svg';
import ParallelogramParallelogramGreen from './Parallelogram/parallelogram_green.svg';
import ParallelogramParallelogramOrange from './Parallelogram/parallelogram_orange.svg';
import ParallelogramParallelogramPink from './Parallelogram/parallelogram_pink.svg';
import ParallelogramParallelogramPurple from './Parallelogram/parallelogram_purple.svg';
import ParallelogramParallelogramRed from './Parallelogram/parallelogram_red.svg';
import ParallelogramParallelogramTurquoise from './Parallelogram/parallelogram_turquoise.svg';
import ParallelogramParallelogramWhite from './Parallelogram/parallelogram_white.svg';
import ParallelogramParallelogramYellow from './Parallelogram/parallelogram_yellow.svg';
import ParenthesesLeftBlack from './ParenthesesLeftBlack.svg';
import ParenthesesLeftBlue from './ParenthesesLeftBlue.svg';
import ParenthesesRightBlack from './ParenthesesRightBlack.svg';
import ParenthesesRightBlue from './ParenthesesRightBlue.svg';
import PartFilledCylinder1000mlcap100mlint from './Part_filled_cylinder_1000mlcap_100mlint.svg';
import Peach from './Peach.svg';
import PearsInATransparentBagPearsInABag0 from './Pears_in_a_transparent_bag/Pears_in_a_bag_0.svg';
import PearsInATransparentBagPearsInABag1 from './Pears_in_a_transparent_bag/Pears_in_a_bag_1.svg';
import PearsInATransparentBagPearsInABag2 from './Pears_in_a_transparent_bag/Pears_in_a_bag_2.svg';
import PearsInATransparentBagPearsInABag3 from './Pears_in_a_transparent_bag/Pears_in_a_bag_3.svg';
import PearsInATransparentBagPearsInABag4 from './Pears_in_a_transparent_bag/Pears_in_a_bag_4.svg';
import PearsInATransparentBagPearsInABag5 from './Pears_in_a_transparent_bag/Pears_in_a_bag_5.svg';
import PenGreen from './Pen_green.svg';
import PencilCase from './Pencil_case.svg';
import PencilSharpener from './Pencil_sharpener.svg';
import PencilsPencilGreen from './Pencils/Pencil_green.svg';
import PencilsPencilRed from './Pencils/Pencil_red.svg';
import PencilsPencilYellow from './Pencils/Pencil_yellow.svg';
import PentagonHousesPentagonHouse from './Pentagon_houses/pentagon_house.svg';
import PentagonHousesPentagonHouseBlue from './Pentagon_houses/pentagon_house_blue.svg';
import PentagonHousesPentagonHouseGreen from './Pentagon_houses/pentagon_house_green.svg';
import PentagonHousesPentagonHouseOrange from './Pentagon_houses/pentagon_house_orange.svg';
import PentagonHousesPentagonHousePink from './Pentagon_houses/pentagon_house_pink.svg';
import PentagonHousesPentagonHousePurple from './Pentagon_houses/pentagon_house_purple.svg';
import PentagonHousesPentagonHouseRed from './Pentagon_houses/pentagon_house_red.svg';
import PentagonHousesPentagonHouseTurquoise from './Pentagon_houses/pentagon_house_turquoise.svg';
import PentagonHousesPentagonHouseWhite from './Pentagon_houses/pentagon_house_white.svg';
import PentagonHousesPentagonHouseYellow from './Pentagon_houses/pentagon_house_yellow.svg';
import PentagonsIrregularPentagon1Blue from './Pentagons/Irregular_pentagon1_blue.svg';
import PentagonsIrregularPentagon1BlueOneInteriorAngleLhs from './Pentagons/Irregular_pentagon1_blue_one_interior_angle_lhs.svg';
import PentagonsIrregularPentagon1BlueOneInteriorAngleRhs from './Pentagons/Irregular_pentagon1_blue_one_interior_angle_rhs.svg';
import PentagonsIrregularPentagon1Green from './Pentagons/Irregular_pentagon1_green.svg';
import PentagonsIrregularPentagon1GreenOneInteriorAngleLhs from './Pentagons/Irregular_pentagon1_green_one_interior_angle_lhs.svg';
import PentagonsIrregularPentagon1GreenOneInteriorAngleRhs from './Pentagons/Irregular_pentagon1_green_one_interior_angle_rhs.svg';
import PentagonsIrregularPentagon1Orange from './Pentagons/Irregular_pentagon1_orange.svg';
import PentagonsIrregularPentagon1Pink from './Pentagons/Irregular_pentagon1_pink.svg';
import PentagonsIrregularPentagon1PinkOneInteriorAngleLhs from './Pentagons/Irregular_pentagon1_pink_one_interior_angle_lhs.svg';
import PentagonsIrregularPentagon1PinkOneInteriorAngleRhs from './Pentagons/Irregular_pentagon1_pink_one_interior_angle_rhs.svg';
import PentagonsIrregularPentagon1Purple from './Pentagons/Irregular_pentagon1_purple.svg';
import PentagonsIrregularPentagon1PurpleOneInteriorAngleLhs from './Pentagons/Irregular_pentagon1_purple_one_interior_angle_lhs.svg';
import PentagonsIrregularPentagon1PurpleOneInteriorAngleRhs from './Pentagons/Irregular_pentagon1_purple_one_interior_angle_rhs.svg';
import PentagonsIrregularPentagon1Red from './Pentagons/Irregular_pentagon1_red.svg';
import PentagonsIrregularPentagon1Turquoise from './Pentagons/Irregular_pentagon1_turquoise.svg';
import PentagonsIrregularPentagon1White from './Pentagons/Irregular_pentagon1_white.svg';
import PentagonsIrregularPentagon1WhiteOneInteriorAngleLhs from './Pentagons/Irregular_pentagon1_white_one_interior_angle_lhs.svg';
import PentagonsIrregularPentagon1WhiteOneInteriorAngleRhs from './Pentagons/Irregular_pentagon1_white_one_interior_angle_rhs.svg';
import PentagonsIrregularPentagon1Yellow from './Pentagons/Irregular_pentagon1_yellow.svg';
import PentagonsIrregularPentagon1YellowOneInteriorAngleLhs from './Pentagons/Irregular_pentagon1_yellow_one_interior_angle_lhs.svg';
import PentagonsIrregularPentagon1YellowOneInteriorAngleRhs from './Pentagons/Irregular_pentagon1_yellow_one_interior_angle_rhs.svg';
import PentagonsIrregularPentagon2Blue from './Pentagons/Irregular_pentagon2_blue.svg';
import PentagonsIrregularPentagon2Green from './Pentagons/Irregular_pentagon2_green.svg';
import PentagonsIrregularPentagon2Orange from './Pentagons/Irregular_pentagon2_orange.svg';
import PentagonsIrregularPentagon2Pink from './Pentagons/Irregular_pentagon2_pink.svg';
import PentagonsIrregularPentagon2Purple from './Pentagons/Irregular_pentagon2_purple.svg';
import PentagonsIrregularPentagon2Red from './Pentagons/Irregular_pentagon2_red.svg';
import PentagonsIrregularPentagon2Turquoise from './Pentagons/Irregular_pentagon2_turquoise.svg';
import PentagonsIrregularPentagon2White from './Pentagons/Irregular_pentagon2_white.svg';
import PentagonsIrregularPentagon2Yellow from './Pentagons/Irregular_pentagon2_yellow.svg';
import PentagonsIrregularPentagon3Blue from './Pentagons/Irregular_pentagon3_blue.svg';
import PentagonsIrregularPentagon3Green from './Pentagons/Irregular_pentagon3_green.svg';
import PentagonsIrregularPentagon3Orange from './Pentagons/Irregular_pentagon3_orange.svg';
import PentagonsIrregularPentagon3Pink from './Pentagons/Irregular_pentagon3_pink.svg';
import PentagonsIrregularPentagon3Purple from './Pentagons/Irregular_pentagon3_purple.svg';
import PentagonsIrregularPentagon3Red from './Pentagons/Irregular_pentagon3_red.svg';
import PentagonsIrregularPentagon3Turquoise from './Pentagons/Irregular_pentagon3_turquoise.svg';
import PentagonsIrregularPentagon3White from './Pentagons/Irregular_pentagon3_white.svg';
import PentagonsIrregularPentagon3Yellow from './Pentagons/Irregular_pentagon3_yellow.svg';
import PentagonsIrregularPentagon4Blue from './Pentagons/Irregular_pentagon4_blue.svg';
import PentagonsIrregularPentagon4Green from './Pentagons/Irregular_pentagon4_green.svg';
import PentagonsIrregularPentagon4Orange from './Pentagons/Irregular_pentagon4_orange.svg';
import PentagonsIrregularPentagon4Pink from './Pentagons/Irregular_pentagon4_pink.svg';
import PentagonsIrregularPentagon4Purple from './Pentagons/Irregular_pentagon4_purple.svg';
import PentagonsIrregularPentagon4Red from './Pentagons/Irregular_pentagon4_red.svg';
import PentagonsIrregularPentagon4Turquoise from './Pentagons/Irregular_pentagon4_turquoise.svg';
import PentagonsIrregularPentagon4White from './Pentagons/Irregular_pentagon4_white.svg';
import PentagonsIrregularPentagon4Yellow from './Pentagons/Irregular_pentagon4_yellow.svg';
import PentagonsIrregularPentagon5Blue from './Pentagons/Irregular_pentagon5_blue.svg';
import PentagonsIrregularPentagon5Green from './Pentagons/Irregular_pentagon5_green.svg';
import PentagonsIrregularPentagon5Orange from './Pentagons/Irregular_pentagon5_orange.svg';
import PentagonsIrregularPentagon5Pink from './Pentagons/Irregular_pentagon5_pink.svg';
import PentagonsIrregularPentagon5Purple from './Pentagons/Irregular_pentagon5_purple.svg';
import PentagonsIrregularPentagon5Red from './Pentagons/Irregular_pentagon5_red.svg';
import PentagonsIrregularPentagon5Turquoise from './Pentagons/Irregular_pentagon5_turquoise.svg';
import PentagonsIrregularPentagon5White from './Pentagons/Irregular_pentagon5_white.svg';
import PentagonsIrregularPentagon5Yellow from './Pentagons/Irregular_pentagon5_yellow.svg';
import PentagonsIrregularPentagon6Blue from './Pentagons/Irregular_pentagon6_blue.svg';
import PentagonsIrregularPentagon6Green from './Pentagons/Irregular_pentagon6_green.svg';
import PentagonsIrregularPentagon6Orange from './Pentagons/Irregular_pentagon6_orange.svg';
import PentagonsIrregularPentagon6Pink from './Pentagons/Irregular_pentagon6_pink.svg';
import PentagonsIrregularPentagon6Purple from './Pentagons/Irregular_pentagon6_purple.svg';
import PentagonsIrregularPentagon6Red from './Pentagons/Irregular_pentagon6_red.svg';
import PentagonsIrregularPentagon6Turquoise from './Pentagons/Irregular_pentagon6_turquoise.svg';
import PentagonsIrregularPentagon6White from './Pentagons/Irregular_pentagon6_white.svg';
import PentagonsIrregularPentagon6Yellow from './Pentagons/Irregular_pentagon6_yellow.svg';
import PentagonsPentagon from './Pentagons/pentagon.svg';
import PentagonsPentagon1Arrow from './Pentagons/pentagon_1_arrow.svg';
import PentagonsPentagon1ArrowBlue from './Pentagons/pentagon_1_arrow_blue.svg';
import PentagonsPentagon1ArrowGreen from './Pentagons/pentagon_1_arrow_green.svg';
import PentagonsPentagon1ArrowPink from './Pentagons/pentagon_1_arrow_pink.svg';
import PentagonsPentagon1ArrowPurple from './Pentagons/pentagon_1_arrow_purple.svg';
import PentagonsPentagon1ArrowYellow from './Pentagons/pentagon_1_arrow_yellow.svg';
import PentagonsPentagonBlue from './Pentagons/pentagon_blue.svg';
import PentagonsPentagonGreen from './Pentagons/pentagon_green.svg';
import PentagonsPentagonOrange from './Pentagons/pentagon_orange.svg';
import PentagonsPentagonPink from './Pentagons/pentagon_pink.svg';
import PentagonsPentagonPurple from './Pentagons/pentagon_purple.svg';
import PentagonsPentagonRed from './Pentagons/pentagon_red.svg';
import PentagonsPentagonTurquoise from './Pentagons/pentagon_turquoise.svg';
import PentagonsPentagonWhite from './Pentagons/pentagon_white.svg';
import PentagonsPentagonYellow from './Pentagons/pentagon_yellow.svg';
import Phone from './Phone.svg';
import PictureBook from './Picture_book.svg';
import PlaceValue00001 from './Place_value/0.0001.svg';
import PlaceValue0001 from './Place_value/0.001.svg';
import PlaceValue001 from './Place_value/0.01.svg';
import PlaceValue01 from './Place_value/0.1.svg';
import PlaceValue01Grey from './Place_value/0.1_grey.svg';
import PlaceValue1 from './Place_value/1.svg';
import PlaceValue110 from './Place_value/1-10.svg';
import PlaceValue1100 from './Place_value/1-100.svg';
import PlaceValue11000 from './Place_value/1-1000.svg';
import PlaceValue110000 from './Place_value/1-10000.svg';
import PlaceValue10 from './Place_value/10.svg';
import PlaceValue100 from './Place_value/100.svg';
import PlaceValue1000 from './Place_value/1000.svg';
import PlaceValue10000 from './Place_value/10000.svg';
import PlaceValue100000 from './Place_value/100000.svg';
import PlaceValue1000000 from './Place_value/1000000.svg';
import PlaceValue1000cube from './Place_value/1000cube.svg';
import PlaceValue100cube from './Place_value/100cube.svg';
import PlaceValue10cube from './Place_value/10cube.svg';
import PlaceValue1Grey from './Place_value/1_grey.svg';
import PlaceValue1cube from './Place_value/1cube.svg';
import PlaceValueBlackCounter from './Place_value/BlackCounter.svg';
import PlaceValueGreyCounter from './Place_value/GreyCounter.svg';
import Plane from './Plane.svg';
import Plate from './Plate.svg';
import Plum from './Plum.svg';
import Pond from './Pond.svg';
import Protractor180 from './Protractor180.svg';
import Protractor360 from './Protractor360.svg';
import RectangleRectangleAnglesLengthArrowsBlue from './Rectangle/Rectangle_angles_length_arrows_blue.svg';
import RectangleRectangleAnglesLengthArrowsGreen from './Rectangle/Rectangle_angles_length_arrows_green.svg';
import RectangleRectangleAnglesLengthArrowsPink from './Rectangle/Rectangle_angles_length_arrows_pink.svg';
import RectangleRectangleAnglesLengthArrowsPurple from './Rectangle/Rectangle_angles_length_arrows_purple.svg';
import RectangleRectangleAnglesLengthArrowsWhite from './Rectangle/Rectangle_angles_length_arrows_white.svg';
import RectangleRectangleAnglesLengthArrowsYellow from './Rectangle/Rectangle_angles_length_arrows_yellow.svg';
import RectangleRectangle from './Rectangle/rectangle.svg';
import RectangleRectangleBlue from './Rectangle/rectangle_blue.svg';
import RectangleRectangleGreen from './Rectangle/rectangle_green.svg';
import RectangleRectangleOrange from './Rectangle/rectangle_orange.svg';
import RectangleRectanglePink from './Rectangle/rectangle_pink.svg';
import RectangleRectanglePurple from './Rectangle/rectangle_purple.svg';
import RectangleRectangleRed from './Rectangle/rectangle_red.svg';
import RectangleRectangleTurquoise from './Rectangle/rectangle_turquoise.svg';
import RectangleRectangleWhite from './Rectangle/rectangle_white.svg';
import RectangleRectangleYellow from './Rectangle/rectangle_yellow.svg';
import RectilinearShapesWithArrowsRectangleAnglesLengthArrows1 from './Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows1.svg';
import RectilinearShapesWithArrowsRectangleAnglesLengthArrows2 from './Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows2.svg';
import RectilinearShapesWithArrowsRectangleAnglesLengthArrows3 from './Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows3.svg';
import RectilinearShapesWithArrowsRectangleAnglesLengthArrows4 from './Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows4.svg';
import RectilinearShapesWithArrowsRectilinearShape102RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesBlue from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_blue.svg';
import RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesGreen from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_green.svg';
import RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesPink from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_pink.svg';
import RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesPurple from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_purple.svg';
import RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesYellow from './Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_yellow.svg';
import RectilinearShapesWithArrowsRectilinearShape11AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape11_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape12AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape12_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape13AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape13_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape14AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape14_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape15AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape15_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape16AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape16_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape17AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape17_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape18AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape18_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesBlue from './Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_blue.svg';
import RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesGreen from './Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_green.svg';
import RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesPink from './Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_pink.svg';
import RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesPurple from './Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_purple.svg';
import RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesYellow from './Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_yellow.svg';
import RectilinearShapesWithArrowsRectilinearShape12RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape1_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape1AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape1_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape20AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape20_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesBlue from './Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_blue.svg';
import RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesGreen from './Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_green.svg';
import RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesPink from './Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_pink.svg';
import RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesPurple from './Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_purple.svg';
import RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesYellow from './Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_yellow.svg';
import RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesBlue from './Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_blue.svg';
import RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesGreen from './Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_green.svg';
import RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesPink from './Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_pink.svg';
import RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesPurple from './Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_purple.svg';
import RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesYellow from './Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_yellow.svg';
import RectilinearShapesWithArrowsRectilinearShape22RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape2_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape2AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape2_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape32RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape3_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape3AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape3_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape42RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape4_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape4AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape4_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape52RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape5_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape5AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape5_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape62RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape6_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape6AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape6_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape72RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape7_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape7AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape7_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape82RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape8_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape8AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape8_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsRectilinearShape92RedArrows from './Rectilinear_shapes_with_arrows/Rectilinear_shape9_2_red_arrows.svg';
import RectilinearShapesWithArrowsRectilinearShape9AllArrowsAllInteriorAngles from './Rectilinear_shapes_with_arrows/Rectilinear_shape9_all_arrows_all_interior_angles.svg';
import RectilinearShapesWithArrowsSquareAnglesLengthArrows from './Rectilinear_shapes_with_arrows/Square_angles_length_arrows.svg';
import RedScissors from './Red_scissors.svg';
import Refrigerator from './Refrigerator.svg';
import RhombusRhombus from './Rhombus/rhombus.svg';
import RhombusRhombusBlue from './Rhombus/rhombus_blue.svg';
import RhombusRhombusGreen from './Rhombus/rhombus_green.svg';
import RhombusRhombusOrange from './Rhombus/rhombus_orange.svg';
import RhombusRhombusPink from './Rhombus/rhombus_pink.svg';
import RhombusRhombusPurple from './Rhombus/rhombus_purple.svg';
import RhombusRhombusRed from './Rhombus/rhombus_red.svg';
import RhombusRhombusTurquoise from './Rhombus/rhombus_turquoise.svg';
import RhombusRhombusWhite from './Rhombus/rhombus_white.svg';
import RhombusRhombusYellow from './Rhombus/rhombus_yellow.svg';
import RibbonGreen from './RibbonGreen.svg';
import RibbonGreenStriped from './RibbonGreenStriped.svg';
import RibbonRed from './RibbonRed.svg';
import RibbonRedStriped from './RibbonRedStriped.svg';
import Ribbons110 from './Ribbons/1-10.svg';
import Ribbons111 from './Ribbons/1-11.svg';
import Ribbons112 from './Ribbons/1-12.svg';
import Ribbons12 from './Ribbons/1-2.svg';
import Ribbons13 from './Ribbons/1-3.svg';
import Ribbons14 from './Ribbons/1-4.svg';
import Ribbons15 from './Ribbons/1-5.svg';
import Ribbons16 from './Ribbons/1-6.svg';
import Ribbons17 from './Ribbons/1-7.svg';
import Ribbons18 from './Ribbons/1-8.svg';
import Ribbons19 from './Ribbons/1-9.svg';
import RiceAndBowls from './RiceAndBowls.svg';
import RightAngledTrapeziumsTrapeziumRA from './Right_angled_trapeziums/trapezium_RA.svg';
import RightAngledTrapeziumsTrapeziumRABlue from './Right_angled_trapeziums/trapezium_RA_blue.svg';
import RightAngledTrapeziumsTrapeziumRABlueOneInteriorAngleLhs from './Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_lhs.svg';
import RightAngledTrapeziumsTrapeziumRABlueOneInteriorAngleRhs from './Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_rhs.svg';
import RightAngledTrapeziumsTrapeziumRAGreen from './Right_angled_trapeziums/trapezium_RA_green.svg';
import RightAngledTrapeziumsTrapeziumRAGreenOneInteriorAngleLhs from './Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_lhs.svg';
import RightAngledTrapeziumsTrapeziumRAGreenOneInteriorAngleRhs from './Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_rhs.svg';
import RightAngledTrapeziumsTrapeziumRAPink from './Right_angled_trapeziums/trapezium_RA_pink.svg';
import RightAngledTrapeziumsTrapeziumRAPinkOneInteriorAngleLhs from './Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_lhs.svg';
import RightAngledTrapeziumsTrapeziumRAPinkOneInteriorAngleRhs from './Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_rhs.svg';
import RightAngledTrapeziumsTrapeziumRAPurple from './Right_angled_trapeziums/trapezium_RA_purple.svg';
import RightAngledTrapeziumsTrapeziumRAPurpleOneInteriorAngleLhs from './Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_lhs.svg';
import RightAngledTrapeziumsTrapeziumRAPurpleOneInteriorAngleRhs from './Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_rhs.svg';
import RightAngledTrapeziumsTrapeziumRAWhite from './Right_angled_trapeziums/trapezium_RA_white.svg';
import RightAngledTrapeziumsTrapeziumRAWhiteOneInteriorAngleLhs from './Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_lhs.svg';
import RightAngledTrapeziumsTrapeziumRAWhiteOneInteriorAngleRhs from './Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_rhs.svg';
import RightAngledTrapeziumsTrapeziumRAYellow from './Right_angled_trapeziums/trapezium_RA_yellow.svg';
import RightAngledTrapeziumsTrapeziumRAYellowOneInteriorAngleLhs from './Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_lhs.svg';
import RightAngledTrapeziumsTrapeziumRAYellowOneInteriorAngleRhs from './Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRA from './Right_angled_triangles/triangle_RA.svg';
import RightAngledTrianglesTriangleRABlue from './Right_angled_triangles/triangle_RA_blue.svg';
import RightAngledTrianglesTriangleRAGreen from './Right_angled_triangles/triangle_RA_green.svg';
import RightAngledTrianglesTriangleRAGreenOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_green_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRAGreenOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_green_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRALongBlue from './Right_angled_triangles/triangle_RA_long_blue.svg';
import RightAngledTrianglesTriangleRALongBlueOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_long_blue_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRALongBlueOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_long_blue_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRALongGreen from './Right_angled_triangles/triangle_RA_long_green.svg';
import RightAngledTrianglesTriangleRALongGreenOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_long_green_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRALongGreenOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_long_green_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRALongOrange from './Right_angled_triangles/triangle_RA_long_orange.svg';
import RightAngledTrianglesTriangleRALongPink from './Right_angled_triangles/triangle_RA_long_pink.svg';
import RightAngledTrianglesTriangleRALongPinkOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRALongPinkOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRALongPurple from './Right_angled_triangles/triangle_RA_long_purple.svg';
import RightAngledTrianglesTriangleRALongPurpleOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRALongPurpleOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRALongRed from './Right_angled_triangles/triangle_RA_long_red.svg';
import RightAngledTrianglesTriangleRALongTurquoise from './Right_angled_triangles/triangle_RA_long_turquoise.svg';
import RightAngledTrianglesTriangleRALongWhite from './Right_angled_triangles/triangle_RA_long_white.svg';
import RightAngledTrianglesTriangleRALongWhiteOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_long_white_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRALongWhiteOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_long_white_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRALongYellow from './Right_angled_triangles/triangle_RA_long_yellow.svg';
import RightAngledTrianglesTriangleRALongYellowOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRALongYellowOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRAOrange from './Right_angled_triangles/triangle_RA_orange.svg';
import RightAngledTrianglesTriangleRAPink from './Right_angled_triangles/triangle_RA_pink.svg';
import RightAngledTrianglesTriangleRAPinkOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_pink_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRAPinkOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_pink_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRAPurple from './Right_angled_triangles/triangle_RA_purple.svg';
import RightAngledTrianglesTriangleRAPurpleOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_purple_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRAPurpleOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_purple_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRARed from './Right_angled_triangles/triangle_RA_red.svg';
import RightAngledTrianglesTriangleRATurquoise from './Right_angled_triangles/triangle_RA_turquoise.svg';
import RightAngledTrianglesTriangleRAWhite from './Right_angled_triangles/triangle_RA_white.svg';
import RightAngledTrianglesTriangleRAWhiteOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_white_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRAWhiteOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_white_one_interior_angle_rhs.svg';
import RightAngledTrianglesTriangleRAYellow from './Right_angled_triangles/triangle_RA_yellow.svg';
import RightAngledTrianglesTriangleRAYellowOneInteriorAngleLhs from './Right_angled_triangles/triangle_RA_yellow_one_interior_angle_lhs.svg';
import RightAngledTrianglesTriangleRAYellowOneInteriorAngleRhs from './Right_angled_triangles/triangle_RA_yellow_one_interior_angle_rhs.svg';
import RollerCoasterAdDuration from './Roller_coaster_ad_duration.svg';
import RoundedRectanglesRoundedRectangleBlue from './Rounded_rectangles/Rounded_rectangle_blue.svg';
import RoundedRectanglesRoundedRectangleGreen from './Rounded_rectangles/Rounded_rectangle_green.svg';
import RoundedRectanglesRoundedRectangleOrange from './Rounded_rectangles/Rounded_rectangle_orange.svg';
import RoundedRectanglesRoundedRectanglePink from './Rounded_rectangles/Rounded_rectangle_pink.svg';
import RoundedRectanglesRoundedRectanglePurple from './Rounded_rectangles/Rounded_rectangle_purple.svg';
import RoundedRectanglesRoundedRectangleRed from './Rounded_rectangles/Rounded_rectangle_red.svg';
import RoundedRectanglesRoundedRectangleTurquoise from './Rounded_rectangles/Rounded_rectangle_turquoise.svg';
import RoundedRectanglesRoundedRectangleYellow from './Rounded_rectangles/Rounded_rectangle_yellow.svg';
import RoundedSquaresRoundedSquareBlue from './Rounded_squares/Rounded_square_blue.svg';
import RoundedSquaresRoundedSquareGreen from './Rounded_squares/Rounded_square_green.svg';
import RoundedSquaresRoundedSquareOrange from './Rounded_squares/Rounded_square_orange.svg';
import RoundedSquaresRoundedSquarePink from './Rounded_squares/Rounded_square_pink.svg';
import RoundedSquaresRoundedSquarePurple from './Rounded_squares/Rounded_square_purple.svg';
import RoundedSquaresRoundedSquareRed from './Rounded_squares/Rounded_square_red.svg';
import RoundedSquaresRoundedSquareTurquoise from './Rounded_squares/Rounded_square_turquoise.svg';
import RoundedSquaresRoundedSquareYellow from './Rounded_squares/Rounded_square_yellow.svg';
import Rubber from './Rubber.svg';
import RubbishBin from './Rubbish_bin.svg';
import Rucksack from './Rucksack.svg';
import RucksackGreen from './Rucksack_green.svg';
import RucksackPurple from './Rucksack_purple.svg';
import RucksackRed from './Rucksack_red.svg';
import SandBag from './Sand_bag.svg';
import ScaleneTrianglesTriangleScalene from './Scalene_triangles/triangle_scalene.svg';
import ScaleneTrianglesTriangleScaleneBlue from './Scalene_triangles/triangle_scalene_blue.svg';
import ScaleneTrianglesTriangleScaleneBlueOneInteriorAngleLhs from './Scalene_triangles/triangle_scalene_blue_one_interior_angle_lhs.svg';
import ScaleneTrianglesTriangleScaleneBlueOneInteriorAngleRhs from './Scalene_triangles/triangle_scalene_blue_one_interior_angle_rhs.svg';
import ScaleneTrianglesTriangleScaleneGreen from './Scalene_triangles/triangle_scalene_green.svg';
import ScaleneTrianglesTriangleScaleneGreenOneInteriorAngleLhs from './Scalene_triangles/triangle_scalene_green_one_interior_angle_lhs.svg';
import ScaleneTrianglesTriangleScaleneGreenOneInteriorAngleRhs from './Scalene_triangles/triangle_scalene_green_one_interior_angle_rhs.svg';
import ScaleneTrianglesTriangleScaleneOrange from './Scalene_triangles/triangle_scalene_orange.svg';
import ScaleneTrianglesTriangleScalenePink from './Scalene_triangles/triangle_scalene_pink.svg';
import ScaleneTrianglesTriangleScalenePinkOneInteriorAngleLhs from './Scalene_triangles/triangle_scalene_pink_one_interior_angle_lhs.svg';
import ScaleneTrianglesTriangleScalenePinkOneInteriorAngleRhs from './Scalene_triangles/triangle_scalene_pink_one_interior_angle_rhs.svg';
import ScaleneTrianglesTriangleScalenePurple from './Scalene_triangles/triangle_scalene_purple.svg';
import ScaleneTrianglesTriangleScalenePurpleOneInteriorAngleLhs from './Scalene_triangles/triangle_scalene_purple_one_interior_angle_lhs.svg';
import ScaleneTrianglesTriangleScalenePurpleOneInteriorAngleRhs from './Scalene_triangles/triangle_scalene_purple_one_interior_angle_rhs.svg';
import ScaleneTrianglesTriangleScaleneRed from './Scalene_triangles/triangle_scalene_red.svg';
import ScaleneTrianglesTriangleScaleneTurquoise from './Scalene_triangles/triangle_scalene_turquoise.svg';
import ScaleneTrianglesTriangleScaleneWhite from './Scalene_triangles/triangle_scalene_white.svg';
import ScaleneTrianglesTriangleScaleneWhiteOneInteriorAngleLhs from './Scalene_triangles/triangle_scalene_white_one_interior_angle_lhs.svg';
import ScaleneTrianglesTriangleScaleneWhiteOneInteriorAngleRhs from './Scalene_triangles/triangle_scalene_white_one_interior_angle_rhs.svg';
import ScaleneTrianglesTriangleScaleneYellow from './Scalene_triangles/triangle_scalene_yellow.svg';
import ScaleneTrianglesTriangleScaleneYellowOneInteriorAngleLhs from './Scalene_triangles/triangle_scalene_yellow_one_interior_angle_lhs.svg';
import ScaleneTrianglesTriangleScaleneYellowOneInteriorAngleRhs from './Scalene_triangles/triangle_scalene_yellow_one_interior_angle_rhs.svg';
import Scales1000gScale100g from './Scales/1000g_scale_100g.svg';
import Scales1000gScale200g from './Scales/1000g_scale_200g.svg';
import Scales1000gScale20g100g from './Scales/1000g_scale_20g_100g.svg';
import Scales1000gScale250g from './Scales/1000g_scale_250g.svg';
import Scales1000gScale25g100g from './Scales/1000g_scale_25g_100g.svg';
import Scales1000gScale50g100g from './Scales/1000g_scale_50g_100g.svg';
import Scales1000gScale50g200g from './Scales/1000g_scale_50g_200g.svg';
import Scales100gScale10g from './Scales/100g_scale_10g.svg';
import Scales100gScale20g from './Scales/100g_scale_20g.svg';
import Scales1KgScale100g from './Scales/1_kg_scale_100g.svg';
import Scales1KgScale200G from './Scales/1_kg_scale_200_g.svg';
import Scales1KgScale250G from './Scales/1_kg_scale_250_g.svg';
import Scales1KgScale500G from './Scales/1_kg_scale_500_g.svg';
import Scales1KgScale50g from './Scales/1_kg_scale_50g.svg';
import Scales200gScale100g from './Scales/200g_scale_100g.svg';
import Scales200gScale10g from './Scales/200g_scale_10g.svg';
import Scales200gScale20g from './Scales/200g_scale_20g.svg';
import Scales3KgScale100G from './Scales/3_kg_scale_100_g.svg';
import Scales3KgScale200G from './Scales/3_kg_scale_200_g.svg';
import Scales3KgScale250G from './Scales/3_kg_scale_250_g.svg';
import Scales3KgScale500G from './Scales/3_kg_scale_500_g.svg';
import Scales4KgScale100G from './Scales/4_kg_scale_100_g.svg';
import Scales4KgScale200G from './Scales/4_kg_scale_200_g.svg';
import Scales4KgScale250G from './Scales/4_kg_scale_250_g.svg';
import Scales4KgScale500G from './Scales/4_kg_scale_500_g.svg';
import Scales500gScale10g from './Scales/500g_scale_10g.svg';
import Scales500gScale20g from './Scales/500g_scale_20g.svg';
import Scales500gScale25g from './Scales/500g_scale_25g.svg';
import Scales5KgScale100G from './Scales/5_kg_scale_100_g.svg';
import Scales5KgScale200G from './Scales/5_kg_scale_200_g.svg';
import Scales5KgScale250G from './Scales/5_kg_scale_250_g.svg';
import Scales5KgScale500G from './Scales/5_kg_scale_500_g.svg';
import Scales6KgScale200G from './Scales/6_kg_scale_200_g.svg';
import Scales6KgScale250G from './Scales/6_kg_scale_250_g.svg';
import Scales6KgScale500G from './Scales/6_kg_scale_500_g.svg';
import ScalesVariousKgScale10kg from './Scales/Various_kg_scale_10kg.svg';
import ScalesVariousKgScale4kg from './Scales/Various_kg_scale_4kg.svg';
import ScalesVariousKgScale5kg from './Scales/Various_kg_scale_5kg.svg';
import ScalesVariousKgScale6kg from './Scales/Various_kg_scale_6kg.svg';
import ScalesVariousKgScale7kg from './Scales/Various_kg_scale_7kg.svg';
import ScalesVariousKgScale8kg from './Scales/Various_kg_scale_8kg.svg';
import ScalesVariousKgScale9kg from './Scales/Various_kg_scale_9kg.svg';
import ScalesArrow from './Scales/arrow.svg';
import ScalesBlankScale from './Scales/blank_scale.svg';
import ScalesKgScale2 from './Scales/kg_scale_2.svg';
import ScalesKgScale3 from './Scales/kg_scale_3.svg';
import ScalesKgScale4 from './Scales/kg_scale_4.svg';
import ScalesWoodenBalanceScaleEqual from './Scales/wooden_balance_scale_equal.svg';
import ScalesWoodenBalanceScaleLeft from './Scales/wooden_balance_scale_left.svg';
import ScalesWoodenBalanceScaleRight from './Scales/wooden_balance_scale_right.svg';
import Scooter from './Scooter.svg';
import SemicirclesCircleSemi from './Semicircles/circle_semi.svg';
import SemicirclesCircleSemiBlue from './Semicircles/circle_semi_blue.svg';
import SemicirclesCircleSemiGreen from './Semicircles/circle_semi_green.svg';
import SemicirclesCircleSemiOrange from './Semicircles/circle_semi_orange.svg';
import SemicirclesCircleSemiPink from './Semicircles/circle_semi_pink.svg';
import SemicirclesCircleSemiPurple from './Semicircles/circle_semi_purple.svg';
import SemicirclesCircleSemiRed from './Semicircles/circle_semi_red.svg';
import SemicirclesCircleSemiTurquoise from './Semicircles/circle_semi_turquoise.svg';
import SemicirclesCircleSemiWhite from './Semicircles/circle_semi_white.svg';
import SemicirclesCircleSemiYellow from './Semicircles/circle_semi_yellow.svg';
import ShapeFormulae1Circle2Triangles from './Shape_formulae/1_circle_2_triangles.svg';
import ShapeFormulae1Circle4Squares from './Shape_formulae/1_circle_4_squares.svg';
import ShapeFormulae1Square2Circles from './Shape_formulae/1_square_2_circles.svg';
import ShapeFormulae1Square4Triangles from './Shape_formulae/1_square_4_triangles.svg';
import ShapeFormulae1Star2Circles from './Shape_formulae/1_star_2_circles.svg';
import ShapeFormulae1Triangle3Circles from './Shape_formulae/1_triangle_3_circles.svg';
import ShapeFormulae1Triangle3Squares from './Shape_formulae/1_triangle_3_squares.svg';
import ShapeNetsConeNet from './Shape_nets/Cone_net.svg';
import ShapeNetsConeNetNoFill from './Shape_nets/Cone_net_noFill.svg';
import ShapeNetsConeNetWithoutTabs from './Shape_nets/Cone_net_without_tabs.svg';
import ShapeNetsCubeNet from './Shape_nets/Cube_net.svg';
import ShapeNetsCubeNetNoFill from './Shape_nets/Cube_net_noFill.svg';
import ShapeNetsCuboidNet from './Shape_nets/Cuboid_net.svg';
import ShapeNetsCuboidNetNoFill from './Shape_nets/Cuboid_net_noFill.svg';
import ShapeNetsCuboidNetWithoutTabs from './Shape_nets/Cuboid_net_without_tabs.svg';
import ShapeNetsCylinderNet from './Shape_nets/Cylinder_net.svg';
import ShapeNetsCylinderNetNoFill from './Shape_nets/Cylinder_net_noFill.svg';
import ShapeNetsCylinderNetWithoutTabs from './Shape_nets/Cylinder_net_without_tabs.svg';
import ShapeNetsHexagonalPrismNet from './Shape_nets/Hexagonal_prism_net.svg';
import ShapeNetsHexagonalPrismNetNoFill from './Shape_nets/Hexagonal_prism_net_noFill.svg';
import ShapeNetsHexagonalPrismNetWithoutTabs from './Shape_nets/Hexagonal_prism_net_without_tabs.svg';
import ShapeNetsHexagonalPyramidNetWithoutTabs from './Shape_nets/Hexagonal_pyramid_net_without_tabs.svg';
import ShapeNetsOctagonalPrismNetWithoutTabs from './Shape_nets/Octagonal_prism_net_without_tabs.svg';
import ShapeNetsOctagonalPyramidNetWithoutTabs from './Shape_nets/Octagonal_pyramid_net_without_tabs.svg';
import ShapeNetsPentagonalPrismNet from './Shape_nets/Pentagonal_prism_net.svg';
import ShapeNetsPentagonalPrismNetNoFill from './Shape_nets/Pentagonal_prism_net_noFill.svg';
import ShapeNetsPentagonalPrismNetWithoutTab from './Shape_nets/Pentagonal_prism_net_without_tab.svg';
import ShapeNetsPentagonalPyramidNetWithoutTabs from './Shape_nets/Pentagonal_pyramid_net_without_tabs.svg';
import ShapeNetsSquareBasedPyramidNetWithoutTabs from './Shape_nets/Square-based_pyramid_net_without_tabs.svg';
import ShapeNetsSquaredBasedPyramidNet from './Shape_nets/Squared_based_pyramid_net.svg';
import ShapeNetsSquaredBasedPyramidNetNoFill from './Shape_nets/Squared_based_pyramid_net_noFill.svg';
import ShapeNetsTatrahedronNetWithoutTabs from './Shape_nets/Tatrahedron_net_without_tabs.svg';
import ShapeNetsTetrahedronNet from './Shape_nets/Tetrahedron_net.svg';
import ShapeNetsTetrahedronNetNoFill from './Shape_nets/Tetrahedron_net_noFill.svg';
import ShapeNetsTriangularPrismNet from './Shape_nets/Triangular_prism_net.svg';
import ShapeNetsTriangularPrismNetNoFill from './Shape_nets/Triangular_prism_net_noFill.svg';
import ShapeNetsTriangularPrismNetWithoutTabs from './Shape_nets/Triangular_prism_net_without_tabs.svg';
import ShapeNetsCorrectnetsofcubes1 from './Shape_nets/correctnetsofcubes1.svg';
import ShapeNetsCorrectnetsofcubes10 from './Shape_nets/correctnetsofcubes10.svg';
import ShapeNetsCorrectnetsofcubes11 from './Shape_nets/correctnetsofcubes11.svg';
import ShapeNetsCorrectnetsofcubes2 from './Shape_nets/correctnetsofcubes2.svg';
import ShapeNetsCorrectnetsofcubes3 from './Shape_nets/correctnetsofcubes3.svg';
import ShapeNetsCorrectnetsofcubes4 from './Shape_nets/correctnetsofcubes4.svg';
import ShapeNetsCorrectnetsofcubes5 from './Shape_nets/correctnetsofcubes5.svg';
import ShapeNetsCorrectnetsofcubes6 from './Shape_nets/correctnetsofcubes6.svg';
import ShapeNetsCorrectnetsofcubes7 from './Shape_nets/correctnetsofcubes7.svg';
import ShapeNetsCorrectnetsofcubes8 from './Shape_nets/correctnetsofcubes8.svg';
import ShapeNetsCorrectnetsofcubes9 from './Shape_nets/correctnetsofcubes9.svg';
import ShapeNetsIncorrectnetsofcubes1 from './Shape_nets/incorrectnetsofcubes1.svg';
import ShapeNetsIncorrectnetsofcubes2 from './Shape_nets/incorrectnetsofcubes2.svg';
import ShapeNetsIncorrectnetsofcubes3 from './Shape_nets/incorrectnetsofcubes3.svg';
import ShapeNetsIncorrectnetsofcubes4 from './Shape_nets/incorrectnetsofcubes4.svg';
import ShapePatternsLinkedHexagons3BlueOrangeHexagonPattern from './Shape_patterns/Linked_hexagons/3_blue_orange_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons3GreenYellowHexagonPattern from './Shape_patterns/Linked_hexagons/3_green_yellow_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons3RedBlueHexagonPattern from './Shape_patterns/Linked_hexagons/3_red_blue_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons4BlueOrangeHexagonPattern from './Shape_patterns/Linked_hexagons/4_blue_orange_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons4GreenYellowHexagonPattern from './Shape_patterns/Linked_hexagons/4_green_yellow_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons4RedBlueHexagonPattern from './Shape_patterns/Linked_hexagons/4_red_blue_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons5BlueOrangeHexagonPattern from './Shape_patterns/Linked_hexagons/5_blue_orange_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons5GreenYellowHexagonPattern from './Shape_patterns/Linked_hexagons/5_green_yellow_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons5RedBlueHexagonPattern from './Shape_patterns/Linked_hexagons/5_red_blue_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons6BlueOrangeHexagonPattern from './Shape_patterns/Linked_hexagons/6_blue_orange_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons6GreenYellowHexagonPattern from './Shape_patterns/Linked_hexagons/6_green_yellow_hexagon_pattern.svg';
import ShapePatternsLinkedHexagons6RedBlueHexagonPattern from './Shape_patterns/Linked_hexagons/6_red_blue_hexagon_pattern.svg';
import ShapePatternsLinkedOctagons3BlueOrangeOctagonPattern from './Shape_patterns/Linked_octagons/3_blue_orange_octagon_pattern.svg';
import ShapePatternsLinkedOctagons3GreenYellowOctagonPattern from './Shape_patterns/Linked_octagons/3_green_yellow_octagon_pattern.svg';
import ShapePatternsLinkedOctagons3RedBlueOctagonPattern from './Shape_patterns/Linked_octagons/3_red_blue_octagon_pattern.svg';
import ShapePatternsLinkedOctagons4BlueOrangeOctagonPattern from './Shape_patterns/Linked_octagons/4_blue_orange_octagon_pattern.svg';
import ShapePatternsLinkedOctagons4GreenYellowOctagonPattern from './Shape_patterns/Linked_octagons/4_green_yellow_octagon_pattern.svg';
import ShapePatternsLinkedOctagons4RedBlueOctagonPattern from './Shape_patterns/Linked_octagons/4_red_blue_octagon_pattern.svg';
import ShapePatternsLinkedOctagons5BlueOrangeOctagonPattern from './Shape_patterns/Linked_octagons/5_blue_orange_octagon_pattern.svg';
import ShapePatternsLinkedOctagons5GreenYellowOctagonPattern from './Shape_patterns/Linked_octagons/5_green_yellow_octagon_pattern.svg';
import ShapePatternsLinkedOctagons5RedBlueOctagonPattern from './Shape_patterns/Linked_octagons/5_red_blue_octagon_pattern.svg';
import ShapePatternsLinkedOctagons6BlueOrangeOctagonPattern from './Shape_patterns/Linked_octagons/6_blue_orange_octagon_pattern.svg';
import ShapePatternsLinkedOctagons6GreenYellowOctagonPattern from './Shape_patterns/Linked_octagons/6_green_yellow_octagon_pattern.svg';
import ShapePatternsLinkedOctagons6RedBlueOctagonPattern from './Shape_patterns/Linked_octagons/6_red_blue_octagon_pattern.svg';
import ShapesMadeFromCubesCubeCubes1 from './Shapes_made_from_cubes/Cube_cubes1.svg';
import ShapesMadeFromCubesCuboidCubes10 from './Shapes_made_from_cubes/Cuboid_cubes10.svg';
import ShapesMadeFromCubesCuboidCubes11 from './Shapes_made_from_cubes/Cuboid_cubes11.svg';
import ShapesMadeFromCubesCuboidCubes12 from './Shapes_made_from_cubes/Cuboid_cubes12.svg';
import ShapesMadeFromCubesCuboidCubes13 from './Shapes_made_from_cubes/Cuboid_cubes13.svg';
import ShapesMadeFromCubesCuboidCubes2 from './Shapes_made_from_cubes/Cuboid_cubes2.svg';
import ShapesMadeFromCubesCuboidCubes3 from './Shapes_made_from_cubes/Cuboid_cubes3.svg';
import ShapesMadeFromCubesCuboidCubes4 from './Shapes_made_from_cubes/Cuboid_cubes4.svg';
import ShapesMadeFromCubesCuboidCubes5 from './Shapes_made_from_cubes/Cuboid_cubes5.svg';
import ShapesMadeFromCubesCuboidCubes6 from './Shapes_made_from_cubes/Cuboid_cubes6.svg';
import ShapesMadeFromCubesCuboidCubes7 from './Shapes_made_from_cubes/Cuboid_cubes7.svg';
import ShapesMadeFromCubesCuboidCubes8 from './Shapes_made_from_cubes/Cuboid_cubes8.svg';
import ShapesMadeFromCubesCuboidCubes9 from './Shapes_made_from_cubes/Cuboid_cubes9.svg';
import ShapesMadeFromCubesOthershapescube1 from './Shapes_made_from_cubes/othershapescube1.svg';
import ShapesMadeFromCubesOthershapescube10 from './Shapes_made_from_cubes/othershapescube10.svg';
import ShapesMadeFromCubesOthershapescube11 from './Shapes_made_from_cubes/othershapescube11.svg';
import ShapesMadeFromCubesOthershapescube12 from './Shapes_made_from_cubes/othershapescube12.svg';
import ShapesMadeFromCubesOthershapescube13 from './Shapes_made_from_cubes/othershapescube13.svg';
import ShapesMadeFromCubesOthershapescube14 from './Shapes_made_from_cubes/othershapescube14.svg';
import ShapesMadeFromCubesOthershapescube15 from './Shapes_made_from_cubes/othershapescube15.svg';
import ShapesMadeFromCubesOthershapescube16 from './Shapes_made_from_cubes/othershapescube16.svg';
import ShapesMadeFromCubesOthershapescube17 from './Shapes_made_from_cubes/othershapescube17.svg';
import ShapesMadeFromCubesOthershapescube18 from './Shapes_made_from_cubes/othershapescube18.svg';
import ShapesMadeFromCubesOthershapescube19 from './Shapes_made_from_cubes/othershapescube19.svg';
import ShapesMadeFromCubesOthershapescube2 from './Shapes_made_from_cubes/othershapescube2.svg';
import ShapesMadeFromCubesOthershapescube20 from './Shapes_made_from_cubes/othershapescube20.svg';
import ShapesMadeFromCubesOthershapescube3 from './Shapes_made_from_cubes/othershapescube3.svg';
import ShapesMadeFromCubesOthershapescube4 from './Shapes_made_from_cubes/othershapescube4.svg';
import ShapesMadeFromCubesOthershapescube5 from './Shapes_made_from_cubes/othershapescube5.svg';
import ShapesMadeFromCubesOthershapescube6 from './Shapes_made_from_cubes/othershapescube6.svg';
import ShapesMadeFromCubesOthershapescube7 from './Shapes_made_from_cubes/othershapescube7.svg';
import ShapesMadeFromCubesOthershapescube8 from './Shapes_made_from_cubes/othershapescube8.svg';
import ShapesMadeFromCubesOthershapescube9 from './Shapes_made_from_cubes/othershapescube9.svg';
import ShapesOnGridsCompoundShape10Grid from './Shapes_on_grids/Compound_shape10_grid.svg';
import ShapesOnGridsCompoundShape11Grid from './Shapes_on_grids/Compound_shape11_grid.svg';
import ShapesOnGridsCompoundShape12Grid from './Shapes_on_grids/Compound_shape12_grid.svg';
import ShapesOnGridsCompoundShape13Grid from './Shapes_on_grids/Compound_shape13_grid.svg';
import ShapesOnGridsCompoundShape14Grid from './Shapes_on_grids/Compound_shape14_grid.svg';
import ShapesOnGridsCompoundShape15Grid from './Shapes_on_grids/Compound_shape15_grid.svg';
import ShapesOnGridsCompoundShape16Grid from './Shapes_on_grids/Compound_shape16_grid.svg';
import ShapesOnGridsCompoundShape1Grid from './Shapes_on_grids/Compound_shape1_grid.svg';
import ShapesOnGridsCompoundShape2Grid from './Shapes_on_grids/Compound_shape2_grid.svg';
import ShapesOnGridsCompoundShape3Grid from './Shapes_on_grids/Compound_shape3_grid.svg';
import ShapesOnGridsCompoundShape4Grid from './Shapes_on_grids/Compound_shape4_grid.svg';
import ShapesOnGridsCompoundShape5Grid from './Shapes_on_grids/Compound_shape5_grid.svg';
import ShapesOnGridsCompoundShape6Grid from './Shapes_on_grids/Compound_shape6_grid.svg';
import ShapesOnGridsCompoundShape7Grid from './Shapes_on_grids/Compound_shape7_grid.svg';
import ShapesOnGridsCompoundShape8Grid from './Shapes_on_grids/Compound_shape8_grid.svg';
import ShapesOnGridsCompoundShape9Grid from './Shapes_on_grids/Compound_shape9_grid.svg';
import ShapesOnGridsCurvedShape1 from './Shapes_on_grids/Curved_shape1.svg';
import ShapesOnGridsCurvedShape2 from './Shapes_on_grids/Curved_shape2.svg';
import ShapesOnGridsCurvedShape3 from './Shapes_on_grids/Curved_shape3.svg';
import ShapesOnGridsCurvedShape4 from './Shapes_on_grids/Curved_shape4.svg';
import ShapesOnGridsCurvedShape5 from './Shapes_on_grids/Curved_shape5.svg';
import ShapesOnGridsCurvedShape6 from './Shapes_on_grids/Curved_shape6.svg';
import ShapesOnGridsCurvedShape7 from './Shapes_on_grids/Curved_shape7.svg';
import ShapesOnGridsCurvedShape8 from './Shapes_on_grids/Curved_shape8.svg';
import ShapesOnGridsEquilateralTriangle1Grid from './Shapes_on_grids/Equilateral_triangle1_grid.svg';
import ShapesOnGridsEquilateralTriangle2Grid from './Shapes_on_grids/Equilateral_triangle2_grid.svg';
import ShapesOnGridsEquilateralTriangle3Grid from './Shapes_on_grids/Equilateral_triangle3_grid.svg';
import ShapesOnGridsEquilateralTriangle4Grid from './Shapes_on_grids/Equilateral_triangle4_grid.svg';
import ShapesOnGridsIslandShapeA1 from './Shapes_on_grids/Island_shape_a1.svg';
import ShapesOnGridsIslandShapeA2 from './Shapes_on_grids/Island_shape_a2.svg';
import ShapesOnGridsIslandShapeA3 from './Shapes_on_grids/Island_shape_a3.svg';
import ShapesOnGridsIslandShapeA4 from './Shapes_on_grids/Island_shape_a4.svg';
import ShapesOnGridsIslandShapeA5 from './Shapes_on_grids/Island_shape_a5.svg';
import ShapesOnGridsIslandShapeA6 from './Shapes_on_grids/Island_shape_a6.svg';
import ShapesOnGridsIslandShapeB1 from './Shapes_on_grids/Island_shape_b1.svg';
import ShapesOnGridsIslandShapeB2 from './Shapes_on_grids/Island_shape_b2.svg';
import ShapesOnGridsIslandShapeB3 from './Shapes_on_grids/Island_shape_b3.svg';
import ShapesOnGridsIslandShapeB4 from './Shapes_on_grids/Island_shape_b4.svg';
import ShapesOnGridsIslandShapeB5 from './Shapes_on_grids/Island_shape_b5.svg';
import ShapesOnGridsIslandShapeB6 from './Shapes_on_grids/Island_shape_b6.svg';
import ShapesOnGridsIsoscelesTriangle1Grid from './Shapes_on_grids/Isosceles_triangle1_grid.svg';
import ShapesOnGridsIsoscelesTriangle2Grid from './Shapes_on_grids/Isosceles_triangle2_grid.svg';
import ShapesOnGridsIsoscelesTriangle3Grid from './Shapes_on_grids/Isosceles_triangle3_grid.svg';
import ShapesOnGridsIsoscelesTriangle4Grid from './Shapes_on_grids/Isosceles_triangle4_grid.svg';
import ShapesOnGridsIsoscelesTriangle5Grid from './Shapes_on_grids/Isosceles_triangle5_grid.svg';
import ShapesOnGridsIsoscelesTriangle6Grid from './Shapes_on_grids/Isosceles_triangle6_grid.svg';
import ShapesOnGridsLeaf1Ongrid from './Shapes_on_grids/Leaf1_ongrid.svg';
import ShapesOnGridsLeaf2Ongrid from './Shapes_on_grids/Leaf2_ongrid.svg';
import ShapesOnGridsLeaf3Ongrid from './Shapes_on_grids/Leaf3_ongrid.svg';
import ShapesOnGridsLeaf4Ongrid from './Shapes_on_grids/Leaf4_ongrid.svg';
import ShapesOnGridsLeaf5Ongrid from './Shapes_on_grids/Leaf5_ongrid.svg';
import ShapesOnGridsLeaf6Ongrid from './Shapes_on_grids/Leaf6_ongrid.svg';
import ShapesOnGridsLeaf7Ongrid from './Shapes_on_grids/Leaf7_ongrid.svg';
import ShapesOnGridsLeaf8Ongrid from './Shapes_on_grids/Leaf8_ongrid.svg';
import ShapesOnGridsOpaqueRectangle1Grid from './Shapes_on_grids/Opaque_rectangle1_grid.svg';
import ShapesOnGridsOpaqueRectangle2Grid from './Shapes_on_grids/Opaque_rectangle2_grid.svg';
import ShapesOnGridsOpaqueRectangle3Grid from './Shapes_on_grids/Opaque_rectangle3_grid.svg';
import ShapesOnGridsOpaqueRectangle4Grid from './Shapes_on_grids/Opaque_rectangle4_grid.svg';
import ShapesOnGridsOpaqueRectangle5Grid from './Shapes_on_grids/Opaque_rectangle5_grid.svg';
import ShapesOnGridsOpaqueRectangle6Grid from './Shapes_on_grids/Opaque_rectangle6_grid.svg';
import ShapesOnGridsOpaqueRectangle7Grid from './Shapes_on_grids/Opaque_rectangle7_grid.svg';
import ShapesOnGridsOpaqueRectangle8Grid from './Shapes_on_grids/Opaque_rectangle8_grid.svg';
import ShapesOnGridsParallelogram10Grid from './Shapes_on_grids/Parallelogram10_grid.svg';
import ShapesOnGridsParallelogram11Grid from './Shapes_on_grids/Parallelogram11_grid.svg';
import ShapesOnGridsParallelogram12Grid from './Shapes_on_grids/Parallelogram12_grid.svg';
import ShapesOnGridsParallelogram13Grid from './Shapes_on_grids/Parallelogram13_grid.svg';
import ShapesOnGridsParallelogram14Grid from './Shapes_on_grids/Parallelogram14_grid.svg';
import ShapesOnGridsParallelogram15Grid from './Shapes_on_grids/Parallelogram15_grid.svg';
import ShapesOnGridsParallelogram16Grid from './Shapes_on_grids/Parallelogram16_grid.svg';
import ShapesOnGridsParallelogram17Grid from './Shapes_on_grids/Parallelogram17_grid.svg';
import ShapesOnGridsParallelogram18Grid from './Shapes_on_grids/Parallelogram18_grid.svg';
import ShapesOnGridsParallelogram19Grid from './Shapes_on_grids/Parallelogram19_grid.svg';
import ShapesOnGridsParallelogram1Grid from './Shapes_on_grids/Parallelogram1_grid.svg';
import ShapesOnGridsParallelogram20Grid from './Shapes_on_grids/Parallelogram20_grid.svg';
import ShapesOnGridsParallelogram2Grid from './Shapes_on_grids/Parallelogram2_grid.svg';
import ShapesOnGridsParallelogram3Grid from './Shapes_on_grids/Parallelogram3_grid.svg';
import ShapesOnGridsParallelogram4Grid from './Shapes_on_grids/Parallelogram4_grid.svg';
import ShapesOnGridsParallelogram5Grid from './Shapes_on_grids/Parallelogram5_grid.svg';
import ShapesOnGridsParallelogram6Grid from './Shapes_on_grids/Parallelogram6_grid.svg';
import ShapesOnGridsParallelogram7Grid from './Shapes_on_grids/Parallelogram7_grid.svg';
import ShapesOnGridsParallelogram8Grid from './Shapes_on_grids/Parallelogram8_grid.svg';
import ShapesOnGridsParallelogram9Grid from './Shapes_on_grids/Parallelogram9_grid.svg';
import ShapesOnGridsRightAngleTriangle1Grid from './Shapes_on_grids/Right_angle_triangle1_grid.svg';
import ShapesOnGridsRightAngleTriangle2Grid from './Shapes_on_grids/Right_angle_triangle2_grid.svg';
import ShapesOnGridsRightAngleTriangle3Grid from './Shapes_on_grids/Right_angle_triangle3_grid.svg';
import ShapesOnGridsRightAngleTriangle4Grid from './Shapes_on_grids/Right_angle_triangle4_grid.svg';
import ShapesOnGridsRightAngleTriangle5Grid from './Shapes_on_grids/Right_angle_triangle5_grid.svg';
import ShapesOnGridsRightAngleTriangle6Grid from './Shapes_on_grids/Right_angle_triangle6_grid.svg';
import ShapesOnGridsScaleneTriangle1Grid from './Shapes_on_grids/Scalene_triangle1_grid.svg';
import ShapesOnGridsScaleneTriangle2Grid from './Shapes_on_grids/Scalene_triangle2_grid.svg';
import ShapesOnGridsScaleneTriangle3Grid from './Shapes_on_grids/Scalene_triangle3_grid.svg';
import ShapesOnGridsScaleneTriangle4Grid from './Shapes_on_grids/Scalene_triangle4_grid.svg';
import ShapesOnGridsScaleneTriangle5Grid from './Shapes_on_grids/Scalene_triangle5_grid.svg';
import ShapesOnGridsScaleneTriangle6Grid from './Shapes_on_grids/Scalene_triangle6_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape10Grid from './Shapes_on_grids/Translucent_rectiliear_shape10_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelG from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelG.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelH from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelH.svg';
import ShapesOnGridsTranslucentRectiliearShape10GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape10_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape11Grid from './Shapes_on_grids/Translucent_rectiliear_shape11_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelG from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelG.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelH from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelH.svg';
import ShapesOnGridsTranslucentRectiliearShape11GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape11_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape12Grid from './Shapes_on_grids/Translucent_rectiliear_shape12_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelG from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelG.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelH from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelH.svg';
import ShapesOnGridsTranslucentRectiliearShape12GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape12_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape1Grid from './Shapes_on_grids/Translucent_rectiliear_shape1_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape1GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape1_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape2Grid from './Shapes_on_grids/Translucent_rectiliear_shape2_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape2GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape2_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape3Grid from './Shapes_on_grids/Translucent_rectiliear_shape3_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape3GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape3_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape4Grid from './Shapes_on_grids/Translucent_rectiliear_shape4_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape4GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape4_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape5Grid from './Shapes_on_grids/Translucent_rectiliear_shape5_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape5GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape5_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape6Grid from './Shapes_on_grids/Translucent_rectiliear_shape6_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape6GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape6_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape7Grid from './Shapes_on_grids/Translucent_rectiliear_shape7_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelG from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelG.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelH from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelH.svg';
import ShapesOnGridsTranslucentRectiliearShape7GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape7_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape8Grid from './Shapes_on_grids/Translucent_rectiliear_shape8_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelG from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelG.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelH from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelH.svg';
import ShapesOnGridsTranslucentRectiliearShape8GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape8_grid_nolabels.svg';
import ShapesOnGridsTranslucentRectiliearShape9Grid from './Shapes_on_grids/Translucent_rectiliear_shape9_grid.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelA from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelA.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelB from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelB.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelC from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelC.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelD from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelD.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelE from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelE.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelF from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelF.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelG from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelG.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelH from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelH.svg';
import ShapesOnGridsTranslucentRectiliearShape9GridNolabels from './Shapes_on_grids/Translucent_rectiliear_shape9_grid_nolabels.svg';
import ShapesOnGridsVariousShapes1Grid from './Shapes_on_grids/Various_shapes1_grid.svg';
import ShapesOnGridsVariousShapes2Grid from './Shapes_on_grids/Various_shapes2_grid.svg';
import ShapesOnGridsVariousShapes3Grid from './Shapes_on_grids/Various_shapes3_grid.svg';
import ShapesOnGridsVariousShapes4Grid from './Shapes_on_grids/Various_shapes4_grid.svg';
import ShapesOnGridsVariousShapes5Grid from './Shapes_on_grids/Various_shapes5_grid.svg';
import ShapesOnGridsVariousShapes6Grid from './Shapes_on_grids/Various_shapes6_grid.svg';
import ShapesOnGridsVariousShapes7Grid from './Shapes_on_grids/Various_shapes7_grid.svg';
import ShapesOnGridsVariousShapes8Grid from './Shapes_on_grids/Various_shapes8_grid.svg';
import ShapesOnGridsVariousShapes9Grid from './Shapes_on_grids/Various_shapes9_grid.svg';
import ShapesWithArrows2CirclesWithArrows from './Shapes_with_arrows/2_circles_with_arrows.svg';
import ShapesWithArrowsCompoundShape1Arrows from './Shapes_with_arrows/Compound_shape1_arrows.svg';
import ShapesWithArrowsCompoundShape2Arrows from './Shapes_with_arrows/Compound_shape2_arrows.svg';
import ShapesWithArrowsCompoundShape3Arrows from './Shapes_with_arrows/Compound_shape3_arrows.svg';
import ShapesWithArrowsCompoundShape4Arrows from './Shapes_with_arrows/Compound_shape4_arrows.svg';
import ShapesWithArrowsCompoundShape5Arrows from './Shapes_with_arrows/Compound_shape5_arrows.svg';
import ShapesWithArrowsCompoundShape6Arrows from './Shapes_with_arrows/Compound_shape6_arrows.svg';
import ShapesWithArrowsCompoundShape7Arrows from './Shapes_with_arrows/Compound_shape7_arrows.svg';
import ShapesWithArrowsEquilateralTriangle1Arrow from './Shapes_with_arrows/Equilateral_triangle_1__arrow.svg';
import ShapesWithArrowsIrregularHeptagon1AllArrows from './Shapes_with_arrows/Irregular_heptagon1_all_arrows.svg';
import ShapesWithArrowsIrregularHeptagon1AllArrowsBlue from './Shapes_with_arrows/Irregular_heptagon1_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHeptagon1AllArrowsGreen from './Shapes_with_arrows/Irregular_heptagon1_all_arrows_green.svg';
import ShapesWithArrowsIrregularHeptagon1AllArrowsPink from './Shapes_with_arrows/Irregular_heptagon1_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHeptagon1AllArrowsPurple from './Shapes_with_arrows/Irregular_heptagon1_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHeptagon1AllArrowsYellow from './Shapes_with_arrows/Irregular_heptagon1_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon1AllArrows from './Shapes_with_arrows/Irregular_hexagon1_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon1AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon1AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon1_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon1AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon1AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon1AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon2AllArrows from './Shapes_with_arrows/Irregular_hexagon2_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon2AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon2AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon2_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon2AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon2AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon2AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon3AllArrows from './Shapes_with_arrows/Irregular_hexagon3_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon3AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon3AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon3_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon3AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon3AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon3AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon4AllArrows from './Shapes_with_arrows/Irregular_hexagon4_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon4AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon4AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon4_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon4AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon4AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon4AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon5AllArrows from './Shapes_with_arrows/Irregular_hexagon5_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon5AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon5AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon5_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon5AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon5AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon5AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon6AllArrows from './Shapes_with_arrows/Irregular_hexagon6_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon6AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon6AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon6_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon6AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon6AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon6AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularHexagon7AllArrows from './Shapes_with_arrows/Irregular_hexagon7_all_arrows.svg';
import ShapesWithArrowsIrregularHexagon7AllArrowsBlue from './Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue.svg';
import ShapesWithArrowsIrregularHexagon7AllArrowsGreen from './Shapes_with_arrows/Irregular_hexagon7_all_arrows_green.svg';
import ShapesWithArrowsIrregularHexagon7AllArrowsPink from './Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink.svg';
import ShapesWithArrowsIrregularHexagon7AllArrowsPurple from './Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple.svg';
import ShapesWithArrowsIrregularHexagon7AllArrowsYellow from './Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularOctagon1AllArrows from './Shapes_with_arrows/Irregular_octagon1_all_arrows.svg';
import ShapesWithArrowsIrregularOctagon1AllArrowsBlue from './Shapes_with_arrows/Irregular_octagon1_all_arrows_blue.svg';
import ShapesWithArrowsIrregularOctagon1AllArrowsGreen from './Shapes_with_arrows/Irregular_octagon1_all_arrows_green.svg';
import ShapesWithArrowsIrregularOctagon1AllArrowsPink from './Shapes_with_arrows/Irregular_octagon1_all_arrows_pink.svg';
import ShapesWithArrowsIrregularOctagon1AllArrowsPurple from './Shapes_with_arrows/Irregular_octagon1_all_arrows_purple.svg';
import ShapesWithArrowsIrregularOctagon1AllArrowsYellow from './Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularPentagon1AllArrows from './Shapes_with_arrows/Irregular_pentagon1_all_arrows.svg';
import ShapesWithArrowsIrregularPentagon1AllArrowsBlue from './Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue.svg';
import ShapesWithArrowsIrregularPentagon1AllArrowsGreen from './Shapes_with_arrows/Irregular_pentagon1_all_arrows_green.svg';
import ShapesWithArrowsIrregularPentagon1AllArrowsPink from './Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink.svg';
import ShapesWithArrowsIrregularPentagon1AllArrowsPurple from './Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple.svg';
import ShapesWithArrowsIrregularPentagon1AllArrowsYellow from './Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularPentagon2AllArrows from './Shapes_with_arrows/Irregular_pentagon2_all_arrows.svg';
import ShapesWithArrowsIrregularPentagon2AllArrowsBlue from './Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue.svg';
import ShapesWithArrowsIrregularPentagon2AllArrowsGreen from './Shapes_with_arrows/Irregular_pentagon2_all_arrows_green.svg';
import ShapesWithArrowsIrregularPentagon2AllArrowsPink from './Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink.svg';
import ShapesWithArrowsIrregularPentagon2AllArrowsPurple from './Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple.svg';
import ShapesWithArrowsIrregularPentagon2AllArrowsYellow from './Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularPentagon3AllArrows from './Shapes_with_arrows/Irregular_pentagon3_all_arrows.svg';
import ShapesWithArrowsIrregularPentagon3AllArrowsBlue from './Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue.svg';
import ShapesWithArrowsIrregularPentagon3AllArrowsGreen from './Shapes_with_arrows/Irregular_pentagon3_all_arrows_green.svg';
import ShapesWithArrowsIrregularPentagon3AllArrowsPink from './Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink.svg';
import ShapesWithArrowsIrregularPentagon3AllArrowsPurple from './Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple.svg';
import ShapesWithArrowsIrregularPentagon3AllArrowsYellow from './Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularPentagon4AllArrows from './Shapes_with_arrows/Irregular_pentagon4_all_arrows.svg';
import ShapesWithArrowsIrregularPentagon4AllArrowsBlue from './Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue.svg';
import ShapesWithArrowsIrregularPentagon4AllArrowsGreen from './Shapes_with_arrows/Irregular_pentagon4_all_arrows_green.svg';
import ShapesWithArrowsIrregularPentagon4AllArrowsPink from './Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink.svg';
import ShapesWithArrowsIrregularPentagon4AllArrowsPurple from './Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple.svg';
import ShapesWithArrowsIrregularPentagon4AllArrowsYellow from './Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularPentagon5AllArrows from './Shapes_with_arrows/Irregular_pentagon5_all_arrows.svg';
import ShapesWithArrowsIrregularPentagon5AllArrowsBlue from './Shapes_with_arrows/Irregular_pentagon5_all_arrows_blue.svg';
import ShapesWithArrowsIrregularPentagon5AllArrowsGreen from './Shapes_with_arrows/Irregular_pentagon5_all_arrows_green.svg';
import ShapesWithArrowsIrregularPentagon5AllArrowsPink from './Shapes_with_arrows/Irregular_pentagon5_all_arrows_pink.svg';
import ShapesWithArrowsIrregularPentagon5AllArrowsPurple from './Shapes_with_arrows/Irregular_pentagon5_all_arrows_purple.svg';
import ShapesWithArrowsIrregularPentagon5AllArrowsYellow from './Shapes_with_arrows/Irregular_pentagon5_all_arrows_yellow.svg';
import ShapesWithArrowsIrregularTriangleAllArrows from './Shapes_with_arrows/Irregular_triangle_all_arrows.svg';
import ShapesWithArrowsIsoscelesTriangleWithArrowsEqual from './Shapes_with_arrows/Isosceles_triangle_with_arrows_equal.svg';
import ShapesWithArrowsIsoscelesTriangleWithArrowsShort from './Shapes_with_arrows/Isosceles_triangle_with_arrows_short.svg';
import ShapesWithArrowsIsoscelesTriangleWithArrowsTall from './Shapes_with_arrows/Isosceles_triangle_with_arrows_tall.svg';
import ShapesWithArrowsLabelledCompoundShape1 from './Shapes_with_arrows/Labelled_compound_shape1.svg';
import ShapesWithArrowsLabelledCompoundShape2 from './Shapes_with_arrows/Labelled_compound_shape2.svg';
import ShapesWithArrowsLabelledCompoundShape3 from './Shapes_with_arrows/Labelled_compound_shape3.svg';
import ShapesWithArrowsLabelledCompoundShape4 from './Shapes_with_arrows/Labelled_compound_shape4.svg';
import ShapesWithArrowsLabelledCompoundShape5 from './Shapes_with_arrows/Labelled_compound_shape5.svg';
import ShapesWithArrowsLabelledCompoundShape6 from './Shapes_with_arrows/Labelled_compound_shape6.svg';
import ShapesWithArrowsParallelogram102ArrowsRightAngle from './Shapes_with_arrows/Parallelogram10_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram103ArrowsRightAngle from './Shapes_with_arrows/Parallelogram10_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram103ArrowsRightAngleFilled from './Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled.svg';
import ShapesWithArrowsParallelogram12ArrowsRightAngle from './Shapes_with_arrows/Parallelogram1_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram13ArrowsRightAngle from './Shapes_with_arrows/Parallelogram1_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram13ArrowsRightAngleFilled from './Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled.svg';
import ShapesWithArrowsParallelogram22ArrowsRightAngle from './Shapes_with_arrows/Parallelogram2_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram23ArrowsRightAngle from './Shapes_with_arrows/Parallelogram2_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram23ArrowsRightAngleFilled from './Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled.svg';
import ShapesWithArrowsParallelogram32ArrowsRightAngle from './Shapes_with_arrows/Parallelogram3_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram33ArrowsRightAngle from './Shapes_with_arrows/Parallelogram3_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram42ArrowsRightAngle from './Shapes_with_arrows/Parallelogram4_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram43ArrowsRightAngle from './Shapes_with_arrows/Parallelogram4_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram52ArrowsRightAngle from './Shapes_with_arrows/Parallelogram5_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram53ArrowsRightAngle from './Shapes_with_arrows/Parallelogram5_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram53ArrowsRightAngleFilled from './Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled.svg';
import ShapesWithArrowsParallelogram62ArrowsRightAngle from './Shapes_with_arrows/Parallelogram6_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram63ArrowsRightAngle from './Shapes_with_arrows/Parallelogram6_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram72ArrowsRightAngle from './Shapes_with_arrows/Parallelogram7_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram73ArrowsRightAngle from './Shapes_with_arrows/Parallelogram7_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram73ArrowsRightAngleFilled from './Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled.svg';
import ShapesWithArrowsParallelogram82ArrowsRightAngle from './Shapes_with_arrows/Parallelogram8_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram83ArrowsRightAngle from './Shapes_with_arrows/Parallelogram8_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram92ArrowsRightAngle from './Shapes_with_arrows/Parallelogram9_2_arrows_right_angle.svg';
import ShapesWithArrowsParallelogram93ArrowsRightAngle from './Shapes_with_arrows/Parallelogram9_3_arrows_right_angle.svg';
import ShapesWithArrowsParallelogramAndRectangle1 from './Shapes_with_arrows/Parallelogram_and_rectangle1.svg';
import ShapesWithArrowsParallelogramAndRectangle10 from './Shapes_with_arrows/Parallelogram_and_rectangle10.svg';
import ShapesWithArrowsParallelogramAndRectangle11 from './Shapes_with_arrows/Parallelogram_and_rectangle11.svg';
import ShapesWithArrowsParallelogramAndRectangle12 from './Shapes_with_arrows/Parallelogram_and_rectangle12.svg';
import ShapesWithArrowsParallelogramAndRectangle13 from './Shapes_with_arrows/Parallelogram_and_rectangle13.svg';
import ShapesWithArrowsParallelogramAndRectangle14 from './Shapes_with_arrows/Parallelogram_and_rectangle14.svg';
import ShapesWithArrowsParallelogramAndRectangle15 from './Shapes_with_arrows/Parallelogram_and_rectangle15.svg';
import ShapesWithArrowsParallelogramAndRectangle16 from './Shapes_with_arrows/Parallelogram_and_rectangle16.svg';
import ShapesWithArrowsParallelogramAndRectangle17 from './Shapes_with_arrows/Parallelogram_and_rectangle17.svg';
import ShapesWithArrowsParallelogramAndRectangle18 from './Shapes_with_arrows/Parallelogram_and_rectangle18.svg';
import ShapesWithArrowsParallelogramAndRectangle19 from './Shapes_with_arrows/Parallelogram_and_rectangle19.svg';
import ShapesWithArrowsParallelogramAndRectangle2 from './Shapes_with_arrows/Parallelogram_and_rectangle2.svg';
import ShapesWithArrowsParallelogramAndRectangle20 from './Shapes_with_arrows/Parallelogram_and_rectangle20.svg';
import ShapesWithArrowsParallelogramAndRectangle3 from './Shapes_with_arrows/Parallelogram_and_rectangle3.svg';
import ShapesWithArrowsParallelogramAndRectangle4 from './Shapes_with_arrows/Parallelogram_and_rectangle4.svg';
import ShapesWithArrowsParallelogramAndRectangle5 from './Shapes_with_arrows/Parallelogram_and_rectangle5.svg';
import ShapesWithArrowsParallelogramAndRectangle6 from './Shapes_with_arrows/Parallelogram_and_rectangle6.svg';
import ShapesWithArrowsParallelogramAndRectangle7 from './Shapes_with_arrows/Parallelogram_and_rectangle7.svg';
import ShapesWithArrowsParallelogramAndRectangle8 from './Shapes_with_arrows/Parallelogram_and_rectangle8.svg';
import ShapesWithArrowsParallelogramAndRectangle9 from './Shapes_with_arrows/Parallelogram_and_rectangle9.svg';
import ShapesWithArrowsQuadrilateralAllArrows from './Shapes_with_arrows/Quadrilateral_all_arrows.svg';
import ShapesWithArrowsRectangle12Arrows from './Shapes_with_arrows/Rectangle1_2_arrows.svg';
import ShapesWithArrowsRectangle22Arrows from './Shapes_with_arrows/Rectangle2_2_arrows.svg';
import ShapesWithArrowsRectangle32Arrows from './Shapes_with_arrows/Rectangle3_2_arrows.svg';
import ShapesWithArrowsRectangle42Arrows from './Shapes_with_arrows/Rectangle4_2_arrows.svg';
import ShapesWithArrowsRectangle52Arrows from './Shapes_with_arrows/Rectangle5_2_arrows.svg';
import ShapesWithArrowsRectangle62Arrows from './Shapes_with_arrows/Rectangle6_2_arrows.svg';
import ShapesWithArrowsRectangle72Arrows from './Shapes_with_arrows/Rectangle7_2_arrows.svg';
import ShapesWithArrowsRectangle84Arrows from './Shapes_with_arrows/Rectangle8_4_arrows.svg';
import ShapesWithArrowsRectangleAllArrows from './Shapes_with_arrows/Rectangle_all_arrows.svg';
import ShapesWithArrowsRectilinearShape102RedArrows from './Shapes_with_arrows/Rectilinear_shape10_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape10AllArrows from './Shapes_with_arrows/Rectilinear_shape10_all_arrows.svg';
import ShapesWithArrowsRectilinearShape10AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape10_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape10AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape10_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape10AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape10_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape10AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape10_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape10AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape10_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape112RedArrows from './Shapes_with_arrows/Rectilinear_shape11_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape11AllArrows from './Shapes_with_arrows/Rectilinear_shape11_all_arrows.svg';
import ShapesWithArrowsRectilinearShape11AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape11_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape11AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape11_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape11AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape11_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape11AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape11_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape11AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape11_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape122RedArrows from './Shapes_with_arrows/Rectilinear_shape12_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape12AllArrows from './Shapes_with_arrows/Rectilinear_shape12_all_arrows.svg';
import ShapesWithArrowsRectilinearShape12AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape12_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape12AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape12_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape12AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape12_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape12AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape12_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape12AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape12_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape132RedArrows from './Shapes_with_arrows/Rectilinear_shape13_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape13AllArrows from './Shapes_with_arrows/Rectilinear_shape13_all_arrows.svg';
import ShapesWithArrowsRectilinearShape13AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape13_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape13AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape13_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape13AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape13_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape13AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape13_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape13AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape13_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape142RedArrows from './Shapes_with_arrows/Rectilinear_shape14_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape14AllArrows from './Shapes_with_arrows/Rectilinear_shape14_all_arrows.svg';
import ShapesWithArrowsRectilinearShape14AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape14_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape14AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape14_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape14AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape14_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape14AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape14_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape14AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape14_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape152RedArrows from './Shapes_with_arrows/Rectilinear_shape15_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape15AllArrows from './Shapes_with_arrows/Rectilinear_shape15_all_arrows.svg';
import ShapesWithArrowsRectilinearShape15AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape15_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape15AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape15_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape15AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape15_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape15AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape15_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape15AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape15_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape162RedArrows from './Shapes_with_arrows/Rectilinear_shape16_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape16AllArrows from './Shapes_with_arrows/Rectilinear_shape16_all_arrows.svg';
import ShapesWithArrowsRectilinearShape16AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape16_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape16AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape16_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape16AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape16_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape16AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape16_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape16AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape16_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape172RedArrows from './Shapes_with_arrows/Rectilinear_shape17_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape17AllArrows from './Shapes_with_arrows/Rectilinear_shape17_all_arrows.svg';
import ShapesWithArrowsRectilinearShape17AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape17_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape17AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape17_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape17AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape17_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape17AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape17_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape17AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape17_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape182RedArrows from './Shapes_with_arrows/Rectilinear_shape18_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape18AllArrows from './Shapes_with_arrows/Rectilinear_shape18_all_arrows.svg';
import ShapesWithArrowsRectilinearShape18AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape18_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape18AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape18_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape18AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape18_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape18AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape18_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape18AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape18_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape192RedArrows from './Shapes_with_arrows/Rectilinear_shape19_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape19AllArrows from './Shapes_with_arrows/Rectilinear_shape19_all_arrows.svg';
import ShapesWithArrowsRectilinearShape19AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape19_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape19AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape19_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape19AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape19_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape19AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape19_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape19AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape19_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape12RedArrows from './Shapes_with_arrows/Rectilinear_shape1_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape1AllArrows from './Shapes_with_arrows/Rectilinear_shape1_all_arrows.svg';
import ShapesWithArrowsRectilinearShape1AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape1_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape1AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape1_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape1AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape1_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape1AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape1_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape1AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape1_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape202RedArrows from './Shapes_with_arrows/Rectilinear_shape20_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape20AllArrows from './Shapes_with_arrows/Rectilinear_shape20_all_arrows.svg';
import ShapesWithArrowsRectilinearShape20AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape20_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape20AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape20_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape20AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape20_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape20AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape20_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape20AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape20_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape21AllArrows from './Shapes_with_arrows/Rectilinear_shape21_all_arrows.svg';
import ShapesWithArrowsRectilinearShape21AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape21_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape21AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape21_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape21AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape21_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape21AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape21_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape21AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape21_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape22AllArrows from './Shapes_with_arrows/Rectilinear_shape22_all_arrows.svg';
import ShapesWithArrowsRectilinearShape22AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape22_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape22AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape22_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape22AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape22_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape22AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape22_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape22AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape22_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape23AllArrows from './Shapes_with_arrows/Rectilinear_shape23_all_arrows.svg';
import ShapesWithArrowsRectilinearShape23AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape23_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape23AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape23_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape23AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape23_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape23AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape23_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape23AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape23_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape24AllArrows from './Shapes_with_arrows/Rectilinear_shape24_all_arrows.svg';
import ShapesWithArrowsRectilinearShape24AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape24_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape24AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape24_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape24AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape24_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape24AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape24_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape24AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape24_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape25AllArrows from './Shapes_with_arrows/Rectilinear_shape25_all_arrows.svg';
import ShapesWithArrowsRectilinearShape25AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape25_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape25AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape25_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape25AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape25_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape25AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape25_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape25AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape25_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape26AllArrows from './Shapes_with_arrows/Rectilinear_shape26_all_arrows.svg';
import ShapesWithArrowsRectilinearShape26AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape26_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape26AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape26_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape26AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape26_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape26AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape26_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape26AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape26_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape27AllArrows from './Shapes_with_arrows/Rectilinear_shape27_all_arrows.svg';
import ShapesWithArrowsRectilinearShape27AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape27_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape27AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape27_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape27AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape27_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape27AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape27_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape27AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape27_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape28AllArrows from './Shapes_with_arrows/Rectilinear_shape28_all_arrows.svg';
import ShapesWithArrowsRectilinearShape28AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape28_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape28AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape28_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape28AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape28_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape28AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape28_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape28AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape28_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape29AllArrows from './Shapes_with_arrows/Rectilinear_shape29_all_arrows.svg';
import ShapesWithArrowsRectilinearShape29AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape29_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape29AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape29_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape29AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape29_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape29AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape29_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape29AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape29_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape22RedArrows from './Shapes_with_arrows/Rectilinear_shape2_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape2AllArrows from './Shapes_with_arrows/Rectilinear_shape2_all_arrows.svg';
import ShapesWithArrowsRectilinearShape2AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape2_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape2AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape2_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape2AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape2_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape2AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape2_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape2AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape2_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape30AllArrows from './Shapes_with_arrows/Rectilinear_shape30_all_arrows.svg';
import ShapesWithArrowsRectilinearShape30AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape30_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape30AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape30_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape30AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape30_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape30AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape30_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape30AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape30_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape31AllArrows from './Shapes_with_arrows/Rectilinear_shape31_all_arrows.svg';
import ShapesWithArrowsRectilinearShape31AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape31_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape31AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape31_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape31AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape31_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape31AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape31_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape31AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape31_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape32AllArrows from './Shapes_with_arrows/Rectilinear_shape32_all_arrows.svg';
import ShapesWithArrowsRectilinearShape32AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape32_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape32AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape32_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape32AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape32_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape32AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape32_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape32AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape32_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape33AllArrows from './Shapes_with_arrows/Rectilinear_shape33_all_arrows.svg';
import ShapesWithArrowsRectilinearShape33AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape33_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape33AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape33_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape33AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape33_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape33AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape33_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape33AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape33_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape34AllArrows from './Shapes_with_arrows/Rectilinear_shape34_all_arrows.svg';
import ShapesWithArrowsRectilinearShape34AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape34_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape34AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape34_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape34AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape34_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape34AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape34_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape34AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape34_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape35AllArrows from './Shapes_with_arrows/Rectilinear_shape35_all_arrows.svg';
import ShapesWithArrowsRectilinearShape35AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape35_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape35AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape35_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape35AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape35_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape35AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape35_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape35AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape35_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape36AllArrows from './Shapes_with_arrows/Rectilinear_shape36_all_arrows.svg';
import ShapesWithArrowsRectilinearShape36AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape36_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape36AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape36_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape36AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape36_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape36AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape36_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape36AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape36_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape37AllArrows from './Shapes_with_arrows/Rectilinear_shape37_all_arrows.svg';
import ShapesWithArrowsRectilinearShape37AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape37_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape37AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape37_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape37AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape37_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape37AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape37_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape37AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape37_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape38AllArrows from './Shapes_with_arrows/Rectilinear_shape38_all_arrows.svg';
import ShapesWithArrowsRectilinearShape38AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape38_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape38AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape38_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape38AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape38_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape38AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape38_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape38AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape38_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape32RedArrows from './Shapes_with_arrows/Rectilinear_shape3_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape3AllArrows from './Shapes_with_arrows/Rectilinear_shape3_all_arrows.svg';
import ShapesWithArrowsRectilinearShape3AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape3_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape3AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape3_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape3AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape3_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape3AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape3_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape3AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape3_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape42RedArrows from './Shapes_with_arrows/Rectilinear_shape4_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape4AllArrows from './Shapes_with_arrows/Rectilinear_shape4_all_arrows.svg';
import ShapesWithArrowsRectilinearShape4AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape4_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape4AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape4_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape4AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape4_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape4AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape4_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape4AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape4_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape52RedArrows from './Shapes_with_arrows/Rectilinear_shape5_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape5AllArrows from './Shapes_with_arrows/Rectilinear_shape5_all_arrows.svg';
import ShapesWithArrowsRectilinearShape5AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape5_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape5AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape5_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape5AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape5_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape5AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape5_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape5AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape5_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape62RedArrows from './Shapes_with_arrows/Rectilinear_shape6_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape6AllArrows from './Shapes_with_arrows/Rectilinear_shape6_all_arrows.svg';
import ShapesWithArrowsRectilinearShape6AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape6_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape6AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape6_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape6AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape6_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape6AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape6_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape6AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape6_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape72RedArrows from './Shapes_with_arrows/Rectilinear_shape7_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape7AllArrows from './Shapes_with_arrows/Rectilinear_shape7_all_arrows.svg';
import ShapesWithArrowsRectilinearShape7AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape7_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape7AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape7_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape7AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape7_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape7AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape7_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape7AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape7_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape82RedArrows from './Shapes_with_arrows/Rectilinear_shape8_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape8AllArrows from './Shapes_with_arrows/Rectilinear_shape8_all_arrows.svg';
import ShapesWithArrowsRectilinearShape8AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape8_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape8AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape8_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape8AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape8_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape8AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape8_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape8AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape8_all_arrows_yellow.svg';
import ShapesWithArrowsRectilinearShape92RedArrows from './Shapes_with_arrows/Rectilinear_shape9_2_red_arrows.svg';
import ShapesWithArrowsRectilinearShape9AllArrows from './Shapes_with_arrows/Rectilinear_shape9_all_arrows.svg';
import ShapesWithArrowsRectilinearShape9AllArrowsBlue from './Shapes_with_arrows/Rectilinear_shape9_all_arrows_blue.svg';
import ShapesWithArrowsRectilinearShape9AllArrowsGreen from './Shapes_with_arrows/Rectilinear_shape9_all_arrows_green.svg';
import ShapesWithArrowsRectilinearShape9AllArrowsPink from './Shapes_with_arrows/Rectilinear_shape9_all_arrows_pink.svg';
import ShapesWithArrowsRectilinearShape9AllArrowsPurple from './Shapes_with_arrows/Rectilinear_shape9_all_arrows_purple.svg';
import ShapesWithArrowsRectilinearShape9AllArrowsYellow from './Shapes_with_arrows/Rectilinear_shape9_all_arrows_yellow.svg';
import ShapesWithArrowsRegularDecagon1Arrow from './Shapes_with_arrows/Regular_decagon_1__arrow.svg';
import ShapesWithArrowsRegularHeptagon1Arrow from './Shapes_with_arrows/Regular_heptagon_1__arrow.svg';
import ShapesWithArrowsRegularHexagon1Arrow from './Shapes_with_arrows/Regular_hexagon_1__arrow.svg';
import ShapesWithArrowsRegularNonagon1Arrow from './Shapes_with_arrows/Regular_nonagon_1__arrow.svg';
import ShapesWithArrowsRegularOctagon1Arrow from './Shapes_with_arrows/Regular_octagon_1__arrow.svg';
import ShapesWithArrowsRegularPentagon1Arrow from './Shapes_with_arrows/Regular_pentagon__1__arrow.svg';
import ShapesWithArrowsRhombusAllSidesMarked from './Shapes_with_arrows/Rhombus_all__sides_marked.svg';
import ShapesWithArrowsRightAngleTriangle103Arrows from './Shapes_with_arrows/Right_angle_triangle10_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle113Arrows from './Shapes_with_arrows/Right_angle_triangle11_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle12Arrows from './Shapes_with_arrows/Right_angle_triangle1_2_arrows.svg';
import ShapesWithArrowsRightAngleTriangle13Arrows from './Shapes_with_arrows/Right_angle_triangle1_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle22Arrows from './Shapes_with_arrows/Right_angle_triangle2_2_arrows.svg';
import ShapesWithArrowsRightAngleTriangle23Arrows from './Shapes_with_arrows/Right_angle_triangle2_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle32Arrows from './Shapes_with_arrows/Right_angle_triangle3_2_arrows.svg';
import ShapesWithArrowsRightAngleTriangle33Arrows from './Shapes_with_arrows/Right_angle_triangle3_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle42Arrows from './Shapes_with_arrows/Right_angle_triangle4_2_arrows.svg';
import ShapesWithArrowsRightAngleTriangle43Arrows from './Shapes_with_arrows/Right_angle_triangle4_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle52Arrows from './Shapes_with_arrows/Right_angle_triangle5_2_arrows.svg';
import ShapesWithArrowsRightAngleTriangle53Arrows from './Shapes_with_arrows/Right_angle_triangle5_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle62Arrows from './Shapes_with_arrows/Right_angle_triangle6_2_arrows.svg';
import ShapesWithArrowsRightAngleTriangle63Arrows from './Shapes_with_arrows/Right_angle_triangle6_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle73Arrows from './Shapes_with_arrows/Right_angle_triangle7_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle83Arrows from './Shapes_with_arrows/Right_angle_triangle8_3_arrows.svg';
import ShapesWithArrowsRightAngleTriangle93Arrows from './Shapes_with_arrows/Right_angle_triangle9_3_arrows.svg';
import ShapesWithArrowsSquare1Arrows from './Shapes_with_arrows/Square_1_arrows.svg';
import ShapesWithArrowsSquare2Arrows from './Shapes_with_arrows/Square_2_arrows.svg';
import ShapesWithArrowsSquare1Arrow from './Shapes_with_arrows/Square__1__arrow.svg';
import ShapesWithArrowsSquareAllSidesMarked from './Shapes_with_arrows/Square_all__sides_marked.svg';
import ShapesWithArrowsTrapezium1AllArrows from './Shapes_with_arrows/Trapezium1_all_arrows.svg';
import ShapesWithArrowsTrapezium2AllArrows from './Shapes_with_arrows/Trapezium2_all_arrows.svg';
import ShapesWithArrowsTriangle103ArrowsRightAngle from './Shapes_with_arrows/Triangle10_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle12Arrows from './Shapes_with_arrows/Triangle1_2_arrows.svg';
import ShapesWithArrowsTriangle13ArrowsNoRightAngle from './Shapes_with_arrows/Triangle1_3_arrows_no_right_angle.svg';
import ShapesWithArrowsTriangle13ArrowsRightAngle from './Shapes_with_arrows/Triangle1_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle14ArrowsRightAngle from './Shapes_with_arrows/Triangle1_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle22Arrows from './Shapes_with_arrows/Triangle2_2_arrows.svg';
import ShapesWithArrowsTriangle23ArrowsNoRightAngle from './Shapes_with_arrows/Triangle2_3_arrows_no_right_angle.svg';
import ShapesWithArrowsTriangle23ArrowsRightAngle from './Shapes_with_arrows/Triangle2_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle24ArrowsRightAngle from './Shapes_with_arrows/Triangle2_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle32Arrows from './Shapes_with_arrows/Triangle3_2_arrows.svg';
import ShapesWithArrowsTriangle33ArrowsNoRightAngle from './Shapes_with_arrows/Triangle3_3_arrows_no_right_angle.svg';
import ShapesWithArrowsTriangle33ArrowsRightAngle from './Shapes_with_arrows/Triangle3_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle34ArrowsRightAngle from './Shapes_with_arrows/Triangle3_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle42Arrows from './Shapes_with_arrows/Triangle4_2_arrows.svg';
import ShapesWithArrowsTriangle43ArrowsNoRightAngle from './Shapes_with_arrows/Triangle4_3_arrows_no_right_angle.svg';
import ShapesWithArrowsTriangle43ArrowsRightAngle from './Shapes_with_arrows/Triangle4_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle44ArrowsRightAngle from './Shapes_with_arrows/Triangle4_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle52Arrows from './Shapes_with_arrows/Triangle5_2_arrows.svg';
import ShapesWithArrowsTriangle53ArrowsNoRightAngle from './Shapes_with_arrows/Triangle5_3_arrows_no_right_angle.svg';
import ShapesWithArrowsTriangle53ArrowsRightAngle from './Shapes_with_arrows/Triangle5_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle54ArrowsRightAngle from './Shapes_with_arrows/Triangle5_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle62Arrows from './Shapes_with_arrows/Triangle6_2_arrows.svg';
import ShapesWithArrowsTriangle63ArrowsRightAngle from './Shapes_with_arrows/Triangle6_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle64ArrowsRightAngle from './Shapes_with_arrows/Triangle6_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle72Arrows from './Shapes_with_arrows/Triangle7_2_arrows.svg';
import ShapesWithArrowsTriangle73ArrowsRightAngle from './Shapes_with_arrows/Triangle7_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle74ArrowsRightAngle from './Shapes_with_arrows/Triangle7_4_arrows_right_angle.svg';
import ShapesWithArrowsTriangle83ArrowsRightAngle from './Shapes_with_arrows/Triangle8_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle93ArrowsRightAngle from './Shapes_with_arrows/Triangle9_3_arrows_right_angle.svg';
import ShapesWithArrowsTriangle94ArrowsRightAngle from './Shapes_with_arrows/Triangle9_4_arrows_right_angle.svg';
import ShapesWithDimensionArrowsSquareBlueArrows4 from './Shapes_with_dimension_arrows/Square_blue_arrows_4.svg';
import ShapesWithDimensionArrowsSquareGreenArrows4 from './Shapes_with_dimension_arrows/Square_green_arrows_4.svg';
import ShapesWithDimensionArrowsSquareOrangeArrows4 from './Shapes_with_dimension_arrows/Square_orange_arrows_4.svg';
import ShapesWithDimensionArrowsSquarePurpleArrows4 from './Shapes_with_dimension_arrows/Square_purple_arrows_4.svg';
import ShapesWithDimensionArrowsSquareRedArrows4 from './Shapes_with_dimension_arrows/Square_red_arrows_4.svg';
import ShapesWithDimensionArrowsHexagonBlueArrows from './Shapes_with_dimension_arrows/hexagon_blue_arrows.svg';
import ShapesWithDimensionArrowsHexagonGreenArrows from './Shapes_with_dimension_arrows/hexagon_green_arrows.svg';
import ShapesWithDimensionArrowsHexagonOrangeArrows from './Shapes_with_dimension_arrows/hexagon_orange_arrows.svg';
import ShapesWithDimensionArrowsHexagonPurpleArrows from './Shapes_with_dimension_arrows/hexagon_purple_arrows.svg';
import ShapesWithDimensionArrowsHexagonRedArrows from './Shapes_with_dimension_arrows/hexagon_red_arrows.svg';
import ShapesWithDimensionArrowsPentagonBlueArrows from './Shapes_with_dimension_arrows/pentagon_blue_arrows.svg';
import ShapesWithDimensionArrowsPentagonGreenArrows from './Shapes_with_dimension_arrows/pentagon_green_arrows.svg';
import ShapesWithDimensionArrowsPentagonOrangeArrows from './Shapes_with_dimension_arrows/pentagon_orange_arrows.svg';
import ShapesWithDimensionArrowsPentagonPurpleArrows from './Shapes_with_dimension_arrows/pentagon_purple_arrows.svg';
import ShapesWithDimensionArrowsPentagonRedArrows from './Shapes_with_dimension_arrows/pentagon_red_arrows.svg';
import ShapesWithDimensionArrowsRectangleBlueArrows2 from './Shapes_with_dimension_arrows/rectangle_blue_arrows_2.svg';
import ShapesWithDimensionArrowsRectangleBlueArrows4 from './Shapes_with_dimension_arrows/rectangle_blue_arrows_4.svg';
import ShapesWithDimensionArrowsRectangleGreenArrows2 from './Shapes_with_dimension_arrows/rectangle_green_arrows_2.svg';
import ShapesWithDimensionArrowsRectangleGreenArrows4 from './Shapes_with_dimension_arrows/rectangle_green_arrows_4.svg';
import ShapesWithDimensionArrowsRectangleOrangeArrows2 from './Shapes_with_dimension_arrows/rectangle_orange_arrows_2.svg';
import ShapesWithDimensionArrowsRectangleOrangeArrows4 from './Shapes_with_dimension_arrows/rectangle_orange_arrows_4.svg';
import ShapesWithDimensionArrowsRectanglePurpleArrows2 from './Shapes_with_dimension_arrows/rectangle_purple_arrows_2.svg';
import ShapesWithDimensionArrowsRectanglePurpleArrows4 from './Shapes_with_dimension_arrows/rectangle_purple_arrows_4.svg';
import ShapesWithDimensionArrowsRectangleRedArrows2 from './Shapes_with_dimension_arrows/rectangle_red_arrows_2.svg';
import ShapesWithDimensionArrowsRectangleRedArrows4 from './Shapes_with_dimension_arrows/rectangle_red_arrows_4.svg';
import ShapesWithDimensionArrowsTrapeziumIsoscelesBlueArrows from './Shapes_with_dimension_arrows/trapezium_isosceles_blue_arrows.svg';
import ShapesWithDimensionArrowsTrapeziumIsoscelesGreenArrows from './Shapes_with_dimension_arrows/trapezium_isosceles_green_arrows.svg';
import ShapesWithDimensionArrowsTrapeziumIsoscelesPinkArrows from './Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows.svg';
import ShapesWithDimensionArrowsTrapeziumIsoscelesPurpleArrows from './Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows.svg';
import ShapesWithDimensionArrowsTrapeziumIsoscelesWhiteArrows from './Shapes_with_dimension_arrows/trapezium_isosceles_white_arrows.svg';
import ShapesWithDimensionArrowsTrapeziumIsoscelesYellowArrows from './Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows.svg';
import ShapesWithDimensionArrowsTriangleRABlueArrows from './Shapes_with_dimension_arrows/triangle_RA_blue_arrows.svg';
import ShapesWithDimensionArrowsTriangleRAGreenArrows from './Shapes_with_dimension_arrows/triangle_RA_green_arrows.svg';
import ShapesWithDimensionArrowsTriangleRAOrangeArrows from './Shapes_with_dimension_arrows/triangle_RA_orange_arrows.svg';
import ShapesWithDimensionArrowsTriangleRAPurpleArrows from './Shapes_with_dimension_arrows/triangle_RA_purple_arrows.svg';
import ShapesWithDimensionArrowsTriangleRARedArrows from './Shapes_with_dimension_arrows/triangle_RA_red_arrows.svg';
import ShapesWithDimensionArrowsTriangleEqual1Arrow from './Shapes_with_dimension_arrows/triangle_equal_1_arrow.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowBlue from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_blue.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowGreen from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_green.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowOrange from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_orange.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowPink from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_pink.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowPurple from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_purple.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowRed from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_red.svg';
import ShapesWithDimensionArrowsTriangleEqual1ArrowYellow from './Shapes_with_dimension_arrows/triangle_equal_1_arrow_yellow.svg';
import ShapesWithDimensionArrowsTriangleEqualBlueArrows from './Shapes_with_dimension_arrows/triangle_equal_blue_arrows.svg';
import ShapesWithDimensionArrowsTriangleEqualGreenArrows from './Shapes_with_dimension_arrows/triangle_equal_green_arrows.svg';
import ShapesWithDimensionArrowsTriangleEqualOrangeArrows from './Shapes_with_dimension_arrows/triangle_equal_orange_arrows.svg';
import ShapesWithDimensionArrowsTriangleEqualPurpleArrows from './Shapes_with_dimension_arrows/triangle_equal_purple_arrows.svg';
import ShapesWithDimensionArrowsTriangleEqualRedArrows from './Shapes_with_dimension_arrows/triangle_equal_red_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosNarrowBlueArrows from './Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosNarrowGreenArrows from './Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosNarrowOrangeArrows from './Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosNarrowPurpleArrows from './Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosNarrowRedArrows from './Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosWideBlueArrows from './Shapes_with_dimension_arrows/triangle_isos_wide_blue_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosWideGreenArrows from './Shapes_with_dimension_arrows/triangle_isos_wide_green_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosWideOrangeArrows from './Shapes_with_dimension_arrows/triangle_isos_wide_orange_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosWidePurpleArrows from './Shapes_with_dimension_arrows/triangle_isos_wide_purple_arrows.svg';
import ShapesWithDimensionArrowsTriangleIsosWideRedArrows from './Shapes_with_dimension_arrows/triangle_isos_wide_red_arrows.svg';
import ShapesWithDimensionArrowsTriangleScaleneBlueArrows from './Shapes_with_dimension_arrows/triangle_scalene_blue_arrows.svg';
import ShapesWithDimensionArrowsTriangleScaleneGreenArrows from './Shapes_with_dimension_arrows/triangle_scalene_green_arrows.svg';
import ShapesWithDimensionArrowsTriangleScaleneOrangeArrows from './Shapes_with_dimension_arrows/triangle_scalene_orange_arrows.svg';
import ShapesWithDimensionArrowsTriangleScalenePurpleArrows from './Shapes_with_dimension_arrows/triangle_scalene_purple_arrows.svg';
import ShapesWithDimensionArrowsTriangleScaleneRedArrows from './Shapes_with_dimension_arrows/triangle_scalene_red_arrows.svg';
import ShapesWithInteriorAnglesEquilateralTriangle1InteriorAngle from './Shapes_with_interior_angles/Equilateral_triangle_1_interior_angle.svg';
import ShapesWithInteriorAnglesEquilateralTriangleInteriorAngles from './Shapes_with_interior_angles/Equilateral_triangle_interior_angles.svg';
import ShapesWithInteriorAnglesHexagon1InteriorAngles from './Shapes_with_interior_angles/Hexagon1_interior_angles.svg';
import ShapesWithInteriorAnglesHexagon2InteriorAngles from './Shapes_with_interior_angles/Hexagon2_interior_angles.svg';
import ShapesWithInteriorAnglesHexagon3InteriorAngles from './Shapes_with_interior_angles/Hexagon3_interior_angles.svg';
import ShapesWithInteriorAnglesHexagon4InteriorAngles from './Shapes_with_interior_angles/Hexagon4_interior_angles.svg';
import ShapesWithInteriorAnglesHexagon5InteriorAngles from './Shapes_with_interior_angles/Hexagon5_interior_angles.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle1 from './Shapes_with_interior_angles/Interior_angles_in_triangle1.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle10 from './Shapes_with_interior_angles/Interior_angles_in_triangle10.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle11 from './Shapes_with_interior_angles/Interior_angles_in_triangle11.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle12 from './Shapes_with_interior_angles/Interior_angles_in_triangle12.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle13 from './Shapes_with_interior_angles/Interior_angles_in_triangle13.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle14 from './Shapes_with_interior_angles/Interior_angles_in_triangle14.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle2 from './Shapes_with_interior_angles/Interior_angles_in_triangle2.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle3 from './Shapes_with_interior_angles/Interior_angles_in_triangle3.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle4 from './Shapes_with_interior_angles/Interior_angles_in_triangle4.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle5 from './Shapes_with_interior_angles/Interior_angles_in_triangle5.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle6 from './Shapes_with_interior_angles/Interior_angles_in_triangle6.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle7 from './Shapes_with_interior_angles/Interior_angles_in_triangle7.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle8 from './Shapes_with_interior_angles/Interior_angles_in_triangle8.svg';
import ShapesWithInteriorAnglesInteriorAnglesInTriangle9 from './Shapes_with_interior_angles/Interior_angles_in_triangle9.svg';
import ShapesWithInteriorAnglesIrregularDecagon1WithInteriorTrianglesCopy5 from './Shapes_with_interior_angles/Irregular_decagon1_with_interior_triangles_copy_5.svg';
import ShapesWithInteriorAnglesIrregularDecagon2WithInteriorTrianglesCopy5 from './Shapes_with_interior_angles/Irregular_decagon2_with_interior_triangles_copy_5.svg';
import ShapesWithInteriorAnglesIrregularDecagonOneInteriorAngle from './Shapes_with_interior_angles/Irregular_decagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesIrregularHeptagon1MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularHeptagon1WithInteriorTrianglesCopy2 from './Shapes_with_interior_angles/Irregular_heptagon1_with_interior_triangles_copy_2.svg';
import ShapesWithInteriorAnglesIrregularHeptagon2MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularHeptagon2WithInteriorTrianglesCopy2 from './Shapes_with_interior_angles/Irregular_heptagon2_with_interior_triangles_copy_2.svg';
import ShapesWithInteriorAnglesIrregularHeptagon3MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularHeptagonOneInteriorAngle from './Shapes_with_interior_angles/Irregular_heptagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesIrregularHexagon1MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularHexagon1WithInteriorTrianglesCopy from './Shapes_with_interior_angles/Irregular_hexagon1_with_interior_triangles_copy.svg';
import ShapesWithInteriorAnglesIrregularHexagon2MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularHexagon2WithInteriorTrianglesCopy from './Shapes_with_interior_angles/Irregular_hexagon2_with_interior_triangles_copy.svg';
import ShapesWithInteriorAnglesIrregularHexagon3MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularHexagonOneInteriorAngle from './Shapes_with_interior_angles/Irregular_hexagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesIrregularNonagon1WithInteriorTrianglesCopy4 from './Shapes_with_interior_angles/Irregular_nonagon1_with_interior_triangles_copy_4.svg';
import ShapesWithInteriorAnglesIrregularNonagon2WithInteriorTrianglesCopy4 from './Shapes_with_interior_angles/Irregular_nonagon2_with_interior_triangles_copy_4.svg';
import ShapesWithInteriorAnglesIrregularNonagonOneInteriorAngle from './Shapes_with_interior_angles/Irregular_nonagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesIrregularOctagon1MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularOctagon1WithInteriorTrianglesCopy3 from './Shapes_with_interior_angles/Irregular_octagon1_with_interior_triangles_copy_3.svg';
import ShapesWithInteriorAnglesIrregularOctagon2MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularOctagon2WithInteriorTrianglesCopy3 from './Shapes_with_interior_angles/Irregular_octagon2_with_interior_triangles_copy_3.svg';
import ShapesWithInteriorAnglesIrregularOctagon3MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularOctagonOneInteriorAngle from './Shapes_with_interior_angles/Irregular_octagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesIrregularPentagon1MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularPentagon1WithInteriorTriangles from './Shapes_with_interior_angles/Irregular_pentagon1_with_interior_triangles.svg';
import ShapesWithInteriorAnglesIrregularPentagon2MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularPentagon2WithInteriorTriangles from './Shapes_with_interior_angles/Irregular_pentagon2_with_interior_triangles.svg';
import ShapesWithInteriorAnglesIrregularPentagon3MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularPentagonOneInteriorAngle from './Shapes_with_interior_angles/Irregular_pentagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral1InteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral1_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral1MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral1_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral2InteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral2_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral2MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral2_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral3InteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral3_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral3MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularQuadrilateral4InteriorAngles from './Shapes_with_interior_angles/Irregular_quadrilateral4_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularTriangle1MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_triangle1_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularTriangle2MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_triangle2_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIrregularTriangle3MarkedInteriorAngles from './Shapes_with_interior_angles/Irregular_triangle3_marked_interior_angles.svg';
import ShapesWithInteriorAnglesIsoscelesTriangle1InteriorAngles from './Shapes_with_interior_angles/Isosceles_triangle1_interior_angles.svg';
import ShapesWithInteriorAnglesIsoscelesTriangle2InteriorAngles from './Shapes_with_interior_angles/Isosceles_triangle2_interior_angles.svg';
import ShapesWithInteriorAnglesIsoscelesTriangle3InteriorAngles from './Shapes_with_interior_angles/Isosceles_triangle3_interior_angles.svg';
import ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles1 from './Shapes_with_interior_angles/Kite_two_isosceles_triangles1.svg';
import ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles2 from './Shapes_with_interior_angles/Kite_two_isosceles_triangles2.svg';
import ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles3 from './Shapes_with_interior_angles/Kite_two_isosceles_triangles3.svg';
import ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles4 from './Shapes_with_interior_angles/Kite_two_isosceles_triangles4.svg';
import ShapesWithInteriorAnglesParallelogram1InteriorAngles from './Shapes_with_interior_angles/Parallelogram1_interior_angles.svg';
import ShapesWithInteriorAnglesParallelogram1WithInteriorTriangles from './Shapes_with_interior_angles/Parallelogram1_with_interior_triangles.svg';
import ShapesWithInteriorAnglesParallelogram2InteriorAngles from './Shapes_with_interior_angles/Parallelogram2_interior_angles.svg';
import ShapesWithInteriorAnglesParallelogram2WithInteriorTriangles from './Shapes_with_interior_angles/Parallelogram2_with_interior_triangles.svg';
import ShapesWithInteriorAnglesParallelogram3InteriorAngles from './Shapes_with_interior_angles/Parallelogram3_interior_angles.svg';
import ShapesWithInteriorAnglesParallelogram4InteriorAngles from './Shapes_with_interior_angles/Parallelogram4_interior_angles.svg';
import ShapesWithInteriorAnglesPentagon1InteriorAngles from './Shapes_with_interior_angles/Pentagon1_interior_angles.svg';
import ShapesWithInteriorAnglesPentagon2InteriorAngles from './Shapes_with_interior_angles/Pentagon2_interior_angles.svg';
import ShapesWithInteriorAnglesPentagon3InteriorAngles from './Shapes_with_interior_angles/Pentagon3_interior_angles.svg';
import ShapesWithInteriorAnglesPentagon4InteriorAngles from './Shapes_with_interior_angles/Pentagon4_interior_angles.svg';
import ShapesWithInteriorAnglesPentagon5InteriorAngles from './Shapes_with_interior_angles/Pentagon5_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral1InteriorAngles from './Shapes_with_interior_angles/Quadrilateral1_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral1WithInteriorTriangles from './Shapes_with_interior_angles/Quadrilateral1_with_interior_triangles.svg';
import ShapesWithInteriorAnglesQuadrilateral2InteriorAngles from './Shapes_with_interior_angles/Quadrilateral2_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral2WithInteriorTriangles from './Shapes_with_interior_angles/Quadrilateral2_with_interior_triangles.svg';
import ShapesWithInteriorAnglesQuadrilateral3InteriorAngles from './Shapes_with_interior_angles/Quadrilateral3_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral4InteriorAngles from './Shapes_with_interior_angles/Quadrilateral4_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral5InteriorAngles from './Shapes_with_interior_angles/Quadrilateral5_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral6InteriorAngles from './Shapes_with_interior_angles/Quadrilateral6_interior_angles.svg';
import ShapesWithInteriorAnglesQuadrilateral7InteriorAngles from './Shapes_with_interior_angles/Quadrilateral7_interior_angles.svg';
import ShapesWithInteriorAnglesRectangle1InteriorAngles from './Shapes_with_interior_angles/Rectangle1_interior_angles.svg';
import ShapesWithInteriorAnglesRectangle1InteriorLinesAndAngles from './Shapes_with_interior_angles/Rectangle1_interior_lines_and_angles.svg';
import ShapesWithInteriorAnglesRectangle2InteriorAngles from './Shapes_with_interior_angles/Rectangle2_interior_angles.svg';
import ShapesWithInteriorAnglesRectangle2InteriorLinesAndAngles from './Shapes_with_interior_angles/Rectangle2_interior_lines_and_angles.svg';
import ShapesWithInteriorAnglesRectangle3InteriorLinesAndAngles from './Shapes_with_interior_angles/Rectangle3_interior_lines_and_angles.svg';
import ShapesWithInteriorAnglesRectangle4InteriorLinesAndAngles from './Shapes_with_interior_angles/Rectangle4_interior_lines_and_angles.svg';
import ShapesWithInteriorAnglesRectangleWithInteriorTriangles from './Shapes_with_interior_angles/Rectangle_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRegularDecagon1InteriorAngle from './Shapes_with_interior_angles/Regular_decagon_1_interior_angle.svg';
import ShapesWithInteriorAnglesRegularDecagonOneInteriorAngle from './Shapes_with_interior_angles/Regular_decagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesRegularDecagonWithInteriorTriangles from './Shapes_with_interior_angles/Regular_decagon_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRegularHeptagon1InteriorAngle from './Shapes_with_interior_angles/Regular_heptagon_1_interior_angle.svg';
import ShapesWithInteriorAnglesRegularHeptagonOneInteriorAngle from './Shapes_with_interior_angles/Regular_heptagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesRegularHeptagonWithInteriorTriangles from './Shapes_with_interior_angles/Regular_heptagon_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRegularHexagon1InteriorAngle from './Shapes_with_interior_angles/Regular_hexagon_1_interior_angle.svg';
import ShapesWithInteriorAnglesRegularHexagonOneInteriorAngle from './Shapes_with_interior_angles/Regular_hexagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesRegularHexagonWithInteriorTriangles from './Shapes_with_interior_angles/Regular_hexagon_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRegularNonagon1InteriorAngle from './Shapes_with_interior_angles/Regular_nonagon_1_interior_angle.svg';
import ShapesWithInteriorAnglesRegularNonagonOneInteriorAngle from './Shapes_with_interior_angles/Regular_nonagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesRegularNonagonWithInteriorTriangles from './Shapes_with_interior_angles/Regular_nonagon_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRegularOctagon1InteriorAngle from './Shapes_with_interior_angles/Regular_octagon_1_interior_angle.svg';
import ShapesWithInteriorAnglesRegularOctagonOneInteriorAngle from './Shapes_with_interior_angles/Regular_octagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesRegularOctagonWithInteriorTriangles from './Shapes_with_interior_angles/Regular_octagon_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRegularPentagon1InteriorAngle from './Shapes_with_interior_angles/Regular_pentagon_1_interior_angle.svg';
import ShapesWithInteriorAnglesRegularPentagonOneInteriorAngle from './Shapes_with_interior_angles/Regular_pentagon_one_interior_angle.svg';
import ShapesWithInteriorAnglesRegularPentagonWithInteriorTriangles from './Shapes_with_interior_angles/Regular_pentagon_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRhombus1InteriorAngles from './Shapes_with_interior_angles/Rhombus1_interior_angles.svg';
import ShapesWithInteriorAnglesRhombus2InteriorAngles from './Shapes_with_interior_angles/Rhombus2_interior_angles.svg';
import ShapesWithInteriorAnglesRhombus3InteriorAngles from './Shapes_with_interior_angles/Rhombus3_interior_angles.svg';
import ShapesWithInteriorAnglesRhombus4InteriorAngles from './Shapes_with_interior_angles/Rhombus4_interior_angles.svg';
import ShapesWithInteriorAnglesRhombusWithInteriorTriangles from './Shapes_with_interior_angles/Rhombus_with_interior_triangles.svg';
import ShapesWithInteriorAnglesRightAngledTriangleInteriorAngles from './Shapes_with_interior_angles/Right_angled_triangle_interior_angles.svg';
import ShapesWithInteriorAnglesScaleneTriangle1InteriorAngles from './Shapes_with_interior_angles/Scalene_triangle1_interior_angles.svg';
import ShapesWithInteriorAnglesScaleneTriangle2InteriorAngles from './Shapes_with_interior_angles/Scalene_triangle2_interior_angles.svg';
import ShapesWithInteriorAnglesScaleneTriangle3InteriorAngles from './Shapes_with_interior_angles/Scalene_triangle3_interior_angles.svg';
import ShapesWithInteriorAnglesScaleneTriangle4InteriorAngles from './Shapes_with_interior_angles/Scalene_triangle4_interior_angles.svg';
import ShapesWithInteriorAnglesScaleneTriangle5InteriorAngles from './Shapes_with_interior_angles/Scalene_triangle5_interior_angles.svg';
import ShapesWithInteriorAnglesSquare1InteriorAngle from './Shapes_with_interior_angles/Square_1_interior_angle.svg';
import ShapesWithInteriorAnglesSquareInteriorAngles from './Shapes_with_interior_angles/Square_interior_angles.svg';
import ShapesWithInteriorAnglesSquareWithInteriorTriangles from './Shapes_with_interior_angles/Square_with_interior_triangles.svg';
import ShapesWithInteriorAnglesTrapezium1InteriorAngles from './Shapes_with_interior_angles/Trapezium1_interior_angles.svg';
import ShapesWithInteriorAnglesTrapezium1WithInteriorTriangles from './Shapes_with_interior_angles/Trapezium1_with_interior_triangles.svg';
import ShapesWithInteriorAnglesTrapezium2InteriorAngles from './Shapes_with_interior_angles/Trapezium2_interior_angles.svg';
import ShapesWithInteriorAnglesTrapezium2WithInteriorTriangles from './Shapes_with_interior_angles/Trapezium2_with_interior_triangles.svg';
import ShapesWithInteriorAnglesTrapezium3InteriorAngles from './Shapes_with_interior_angles/Trapezium3_interior_angles.svg';
import ShapesWithInteriorAnglesTrapezium4InteriorAngles from './Shapes_with_interior_angles/Trapezium4_interior_angles.svg';
import ShapesWithMarkedSidesEquilateralTriangleAllSidesBlue from './Shapes_with_marked_sides/Equilateral_triangle_all__sides_blue.svg';
import ShapesWithMarkedSidesEquilateralTriangleAllSidesGreen from './Shapes_with_marked_sides/Equilateral_triangle_all__sides_green.svg';
import ShapesWithMarkedSidesEquilateralTriangleAllSidesMarked from './Shapes_with_marked_sides/Equilateral_triangle_all__sides_marked.svg';
import ShapesWithMarkedSidesEquilateralTriangleAllSidesPink from './Shapes_with_marked_sides/Equilateral_triangle_all__sides_pink.svg';
import ShapesWithMarkedSidesEquilateralTriangleAllSidesPurple from './Shapes_with_marked_sides/Equilateral_triangle_all__sides_purple.svg';
import ShapesWithMarkedSidesEquilateralTriangleAllSidesYellow from './Shapes_with_marked_sides/Equilateral_triangle_all__sides_yellow.svg';
import ShapesWithMarkedSidesIsosceles1Markedlines from './Shapes_with_marked_sides/Isosceles1_markedlines.svg';
import ShapesWithMarkedSidesIsosceles1MarkedlinesBlue from './Shapes_with_marked_sides/Isosceles1_markedlines_blue.svg';
import ShapesWithMarkedSidesIsosceles1MarkedlinesGreen from './Shapes_with_marked_sides/Isosceles1_markedlines_green.svg';
import ShapesWithMarkedSidesIsosceles1MarkedlinesPink from './Shapes_with_marked_sides/Isosceles1_markedlines_pink.svg';
import ShapesWithMarkedSidesIsosceles1MarkedlinesPurple from './Shapes_with_marked_sides/Isosceles1_markedlines_purple.svg';
import ShapesWithMarkedSidesIsosceles1MarkedlinesYellow from './Shapes_with_marked_sides/Isosceles1_markedlines_yellow.svg';
import ShapesWithMarkedSidesIsosceles2Markedlines from './Shapes_with_marked_sides/Isosceles2_markedlines.svg';
import ShapesWithMarkedSidesIsosceles2MarkedlinesBlue from './Shapes_with_marked_sides/Isosceles2_markedlines_blue.svg';
import ShapesWithMarkedSidesIsosceles2MarkedlinesGreen from './Shapes_with_marked_sides/Isosceles2_markedlines_green.svg';
import ShapesWithMarkedSidesIsosceles2MarkedlinesPink from './Shapes_with_marked_sides/Isosceles2_markedlines_pink.svg';
import ShapesWithMarkedSidesIsosceles2MarkedlinesPurple from './Shapes_with_marked_sides/Isosceles2_markedlines_purple.svg';
import ShapesWithMarkedSidesIsosceles2MarkedlinesYellow from './Shapes_with_marked_sides/Isosceles2_markedlines_yellow.svg';
import ShapesWithMarkedSidesIsosceles3Markedlines from './Shapes_with_marked_sides/Isosceles3_markedlines.svg';
import ShapesWithMarkedSidesIsosceles3MarkedlinesBlue from './Shapes_with_marked_sides/Isosceles3_markedlines_blue.svg';
import ShapesWithMarkedSidesIsosceles3MarkedlinesGreen from './Shapes_with_marked_sides/Isosceles3_markedlines_green.svg';
import ShapesWithMarkedSidesIsosceles3MarkedlinesPink from './Shapes_with_marked_sides/Isosceles3_markedlines_pink.svg';
import ShapesWithMarkedSidesIsosceles3MarkedlinesPurple from './Shapes_with_marked_sides/Isosceles3_markedlines_purple.svg';
import ShapesWithMarkedSidesIsosceles3MarkedlinesYellow from './Shapes_with_marked_sides/Isosceles3_markedlines_yellow.svg';
import ShapesWithMarkedSidesIsosceles4Markedlines from './Shapes_with_marked_sides/Isosceles4_markedlines.svg';
import ShapesWithMarkedSidesIsosceles4MarkedlinesBlue from './Shapes_with_marked_sides/Isosceles4_markedlines_blue.svg';
import ShapesWithMarkedSidesIsosceles4MarkedlinesGreen from './Shapes_with_marked_sides/Isosceles4_markedlines_green.svg';
import ShapesWithMarkedSidesIsosceles4MarkedlinesPink from './Shapes_with_marked_sides/Isosceles4_markedlines_pink.svg';
import ShapesWithMarkedSidesIsosceles4MarkedlinesPurple from './Shapes_with_marked_sides/Isosceles4_markedlines_purple.svg';
import ShapesWithMarkedSidesIsosceles4MarkedlinesYellow from './Shapes_with_marked_sides/Isosceles4_markedlines_yellow.svg';
import ShapesWithMarkedSidesRegularDecagonAllSidesMarked from './Shapes_with_marked_sides/Regular_decagon_all__sides_marked.svg';
import ShapesWithMarkedSidesRegularDecagonAllSidesMarkedBlue from './Shapes_with_marked_sides/Regular_decagon_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesRegularDecagonAllSidesMarkedGreen from './Shapes_with_marked_sides/Regular_decagon_all__sides_marked_green.svg';
import ShapesWithMarkedSidesRegularDecagonAllSidesMarkedPink from './Shapes_with_marked_sides/Regular_decagon_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesRegularDecagonAllSidesMarkedPurple from './Shapes_with_marked_sides/Regular_decagon_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesRegularDecagonAllSidesMarkedYellow from './Shapes_with_marked_sides/Regular_decagon_all__sides_marked_yellow.svg';
import ShapesWithMarkedSidesRegularHeptagonAllSidesMarked from './Shapes_with_marked_sides/Regular_heptagon_all__sides_marked.svg';
import ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedBlue from './Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedGreen from './Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_green.svg';
import ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedPink from './Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedPurple from './Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedYellow from './Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_yellow.svg';
import ShapesWithMarkedSidesRegularHexagonAllSidesMarked from './Shapes_with_marked_sides/Regular_hexagon_all__sides_marked.svg';
import ShapesWithMarkedSidesRegularHexagonAllSidesMarkedBlue from './Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesRegularHexagonAllSidesMarkedGreen from './Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_green.svg';
import ShapesWithMarkedSidesRegularHexagonAllSidesMarkedPink from './Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesRegularHexagonAllSidesMarkedPurple from './Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesRegularHexagonAllSidesMarkedYellow from './Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_yellow.svg';
import ShapesWithMarkedSidesRegularNonagonAllSidesMarked from './Shapes_with_marked_sides/Regular_nonagon_all__sides_marked.svg';
import ShapesWithMarkedSidesRegularNonagonAllSidesMarkedBlue from './Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesRegularNonagonAllSidesMarkedGreen from './Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_green.svg';
import ShapesWithMarkedSidesRegularNonagonAllSidesMarkedPink from './Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesRegularNonagonAllSidesMarkedPurple from './Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesRegularNonagonAllSidesMarkedYellow from './Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_yellow.svg';
import ShapesWithMarkedSidesRegularOctagonAllSidesMarked from './Shapes_with_marked_sides/Regular_octagon_all__sides_marked.svg';
import ShapesWithMarkedSidesRegularOctagonAllSidesMarkedBlue from './Shapes_with_marked_sides/Regular_octagon_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesRegularOctagonAllSidesMarkedGreen from './Shapes_with_marked_sides/Regular_octagon_all__sides_marked_green.svg';
import ShapesWithMarkedSidesRegularOctagonAllSidesMarkedPink from './Shapes_with_marked_sides/Regular_octagon_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesRegularOctagonAllSidesMarkedPurple from './Shapes_with_marked_sides/Regular_octagon_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesRegularOctagonAllSidesMarkedYellow from './Shapes_with_marked_sides/Regular_octagon_all__sides_marked_yellow.svg';
import ShapesWithMarkedSidesRegularPentagonAllSidesMarked from './Shapes_with_marked_sides/Regular_pentagon_all__sides_marked.svg';
import ShapesWithMarkedSidesRegularPentagonAllSidesMarkedBlue from './Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesRegularPentagonAllSidesMarkedGreen from './Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_green.svg';
import ShapesWithMarkedSidesRegularPentagonAllSidesMarkedPink from './Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesRegularPentagonAllSidesMarkedPurple from './Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesRegularPentagonAllSidesMarkedYellow from './Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_yellow.svg';
import ShapesWithMarkedSidesSquareAllSidesMarkedBlue from './Shapes_with_marked_sides/Square_all__sides_marked_blue.svg';
import ShapesWithMarkedSidesSquareAllSidesMarkedGreen from './Shapes_with_marked_sides/Square_all__sides_marked_green.svg';
import ShapesWithMarkedSidesSquareAllSidesMarkedPink from './Shapes_with_marked_sides/Square_all__sides_marked_pink.svg';
import ShapesWithMarkedSidesSquareAllSidesMarkedPurple from './Shapes_with_marked_sides/Square_all__sides_marked_purple.svg';
import ShapesWithMarkedSidesSquareAllSidesMarkedYellow from './Shapes_with_marked_sides/Square_all__sides_marked_yellow.svg';
import ShapesWithMissingPartsMissingPartShape1 from './Shapes_with_missing_parts/Missing_part_shape1.svg';
import ShapesWithMissingPartsMissingPartShape10 from './Shapes_with_missing_parts/Missing_part_shape10.svg';
import ShapesWithMissingPartsMissingPartShape2 from './Shapes_with_missing_parts/Missing_part_shape2.svg';
import ShapesWithMissingPartsMissingPartShape3 from './Shapes_with_missing_parts/Missing_part_shape3.svg';
import ShapesWithMissingPartsMissingPartShape4 from './Shapes_with_missing_parts/Missing_part_shape4.svg';
import ShapesWithMissingPartsMissingPartShape5 from './Shapes_with_missing_parts/Missing_part_shape5.svg';
import ShapesWithMissingPartsMissingPartShape6 from './Shapes_with_missing_parts/Missing_part_shape6.svg';
import ShapesWithMissingPartsMissingPartShape7 from './Shapes_with_missing_parts/Missing_part_shape7.svg';
import ShapesWithMissingPartsMissingPartShape8 from './Shapes_with_missing_parts/Missing_part_shape8.svg';
import ShapesWithMissingPartsMissingPartShape9 from './Shapes_with_missing_parts/Missing_part_shape9.svg';
import Shed from './Shed.svg';
import Sheep from './Sheep.svg';
import ShoppingTrolley from './Shopping_trolley.svg';
import Sink from './Sink.svg';
import SliderArrow from './SliderArrow.svg';
import SoapDispenser from './Soap_dispenser.svg';
import Spider from './Spider.svg';
import Spinner from './Spinner.svg';
import SportsBallFootball from './Sports_ball_football.svg';
import SportsBallRugbyBall from './Sports_ball_rugby_ball.svg';
import Square from './Square.svg';
import SquareSquare from './Square/square.svg';
import SquareSquare1Arrow from './Square/square_1_arrow.svg';
import SquareSquare1ArrowBlue from './Square/square_1_arrow_blue.svg';
import SquareSquare1ArrowGreen from './Square/square_1_arrow_green.svg';
import SquareSquare1ArrowPink from './Square/square_1_arrow_pink.svg';
import SquareSquare1ArrowPurple from './Square/square_1_arrow_purple.svg';
import SquareSquare1ArrowYellow from './Square/square_1_arrow_yellow.svg';
import SquareSquareBlue from './Square/square_blue.svg';
import SquareSquareGreen from './Square/square_green.svg';
import SquareSquareGrey from './Square/square_grey.svg';
import SquareSquareOrange from './Square/square_orange.svg';
import SquareSquarePink from './Square/square_pink.svg';
import SquareSquarePurple from './Square/square_purple.svg';
import SquareSquareRed from './Square/square_red.svg';
import SquareSquareTurquoise from './Square/square_turquoise.svg';
import SquareSquareWhite from './Square/square_white.svg';
import SquareSquareYellow from './Square/square_yellow.svg';
import StackOfCubesIn3DShapeCubeCubes from './Stack_of_Cubes_in_3D_Shape/cube_cubes.svg';
import StackOfCubesIn3DShapeCylinderCubes from './Stack_of_Cubes_in_3D_Shape/cylinder_cubes.svg';
import StackOfCubesIn3DShapeHexagonalPrismCubes from './Stack_of_Cubes_in_3D_Shape/hexagonal_prism_cubes.svg';
import StackOfCubesIn3DShapeSquareBasedPyramidCubes from './Stack_of_Cubes_in_3D_Shape/square_based_pyramid_cubes.svg';
import StackOfCubesIn3DShapeTriangularPrismCubes from './Stack_of_Cubes_in_3D_Shape/triangular_prism_cubes.svg';
import Star from './Star.svg';
import StarBlue from './Star_blue.svg';
import StarGreen from './Star_green.svg';
import StarPink from './Star_pink.svg';
import StarPurple from './Star_purple.svg';
import StarRed from './Star_red.svg';
import StarYellow from './Star_yellow.svg';
import StickerSheet from './Sticker_sheet.svg';
import Stopwatch from './Stopwatch.svg';
import Suitcase from './Suitcase.svg';
import Sunflower from './Sunflower.svg';
import SweetsSweetBlue from './Sweets/SweetBlue.svg';
import SweetsSweetGreen from './Sweets/SweetGreen.svg';
import SweetsSweetPink from './Sweets/SweetPink.svg';
import SweetsSweetRed from './Sweets/SweetRed.svg';
import SymmetricalShapesBoth11Green from './SymmetricalShapes/both11_green.svg';
import SymmetricalShapesBoth11Pink from './SymmetricalShapes/both11_pink.svg';
import SymmetricalShapesBoth11Purple from './SymmetricalShapes/both11_purple.svg';
import SymmetricalShapesBoth11White from './SymmetricalShapes/both11_white.svg';
import SymmetricalShapesBoth11Yellow from './SymmetricalShapes/both11_yellow.svg';
import SymmetricalShapesBoth12Green from './SymmetricalShapes/both12_green.svg';
import SymmetricalShapesBoth12Pink from './SymmetricalShapes/both12_pink.svg';
import SymmetricalShapesBoth12Purple from './SymmetricalShapes/both12_purple.svg';
import SymmetricalShapesBoth12White from './SymmetricalShapes/both12_white.svg';
import SymmetricalShapesBoth12Yellow from './SymmetricalShapes/both12_yellow.svg';
import SymmetricalShapesBoth1Green from './SymmetricalShapes/both1_green.svg';
import SymmetricalShapesBoth1Pink from './SymmetricalShapes/both1_pink.svg';
import SymmetricalShapesBoth1Purple from './SymmetricalShapes/both1_purple.svg';
import SymmetricalShapesBoth1White from './SymmetricalShapes/both1_white.svg';
import SymmetricalShapesBoth1Yellow from './SymmetricalShapes/both1_yellow.svg';
import SymmetricalShapesBoth3Green from './SymmetricalShapes/both3_green.svg';
import SymmetricalShapesBoth3Pink from './SymmetricalShapes/both3_pink.svg';
import SymmetricalShapesBoth3Purple from './SymmetricalShapes/both3_purple.svg';
import SymmetricalShapesBoth3White from './SymmetricalShapes/both3_white.svg';
import SymmetricalShapesBoth3Yellow from './SymmetricalShapes/both3_yellow.svg';
import SymmetricalShapesBoth4Green from './SymmetricalShapes/both4_green.svg';
import SymmetricalShapesBoth4Pink from './SymmetricalShapes/both4_pink.svg';
import SymmetricalShapesBoth4Purple from './SymmetricalShapes/both4_purple.svg';
import SymmetricalShapesBoth4White from './SymmetricalShapes/both4_white.svg';
import SymmetricalShapesBoth4Yellow from './SymmetricalShapes/both4_yellow.svg';
import SymmetricalShapesBoth6Green from './SymmetricalShapes/both6_green.svg';
import SymmetricalShapesBoth6Pink from './SymmetricalShapes/both6_pink.svg';
import SymmetricalShapesBoth6Purple from './SymmetricalShapes/both6_purple.svg';
import SymmetricalShapesBoth6White from './SymmetricalShapes/both6_white.svg';
import SymmetricalShapesBoth6Yellow from './SymmetricalShapes/both6_yellow.svg';
import SymmetricalShapesBoth8Green from './SymmetricalShapes/both8_green.svg';
import SymmetricalShapesBoth8Pink from './SymmetricalShapes/both8_pink.svg';
import SymmetricalShapesBoth8Purple from './SymmetricalShapes/both8_purple.svg';
import SymmetricalShapesBoth8White from './SymmetricalShapes/both8_white.svg';
import SymmetricalShapesBoth8Yellow from './SymmetricalShapes/both8_yellow.svg';
import SymmetricalShapesBoth9Green from './SymmetricalShapes/both9_green.svg';
import SymmetricalShapesBoth9Pink from './SymmetricalShapes/both9_pink.svg';
import SymmetricalShapesBoth9Purple from './SymmetricalShapes/both9_purple.svg';
import SymmetricalShapesBoth9White from './SymmetricalShapes/both9_white.svg';
import SymmetricalShapesBoth9Yellow from './SymmetricalShapes/both9_yellow.svg';
import SymmetricalShapesHorizontal1Green from './SymmetricalShapes/horizontal1_green.svg';
import SymmetricalShapesHorizontal1Pink from './SymmetricalShapes/horizontal1_pink.svg';
import SymmetricalShapesHorizontal1Purple from './SymmetricalShapes/horizontal1_purple.svg';
import SymmetricalShapesHorizontal1White from './SymmetricalShapes/horizontal1_white.svg';
import SymmetricalShapesHorizontal1Yellow from './SymmetricalShapes/horizontal1_yellow.svg';
import SymmetricalShapesHorizontal2Green from './SymmetricalShapes/horizontal2_green.svg';
import SymmetricalShapesHorizontal2Pink from './SymmetricalShapes/horizontal2_pink.svg';
import SymmetricalShapesHorizontal2Purple from './SymmetricalShapes/horizontal2_purple.svg';
import SymmetricalShapesHorizontal2White from './SymmetricalShapes/horizontal2_white.svg';
import SymmetricalShapesHorizontal2Yellow from './SymmetricalShapes/horizontal2_yellow.svg';
import SymmetricalShapesHorizontal3Green from './SymmetricalShapes/horizontal3_green.svg';
import SymmetricalShapesHorizontal3Pink from './SymmetricalShapes/horizontal3_pink.svg';
import SymmetricalShapesHorizontal3Purple from './SymmetricalShapes/horizontal3_purple.svg';
import SymmetricalShapesHorizontal3White from './SymmetricalShapes/horizontal3_white.svg';
import SymmetricalShapesHorizontal3Yellow from './SymmetricalShapes/horizontal3_yellow.svg';
import SymmetricalShapesHorizontal4Green from './SymmetricalShapes/horizontal4_green.svg';
import SymmetricalShapesHorizontal4Pink from './SymmetricalShapes/horizontal4_pink.svg';
import SymmetricalShapesHorizontal4Purple from './SymmetricalShapes/horizontal4_purple.svg';
import SymmetricalShapesHorizontal4White from './SymmetricalShapes/horizontal4_white.svg';
import SymmetricalShapesHorizontal4Yellow from './SymmetricalShapes/horizontal4_yellow.svg';
import SymmetricalShapesVertical1Green from './SymmetricalShapes/vertical1_green.svg';
import SymmetricalShapesVertical1Pink from './SymmetricalShapes/vertical1_pink.svg';
import SymmetricalShapesVertical1Purple from './SymmetricalShapes/vertical1_purple.svg';
import SymmetricalShapesVertical1White from './SymmetricalShapes/vertical1_white.svg';
import SymmetricalShapesVertical1Yellow from './SymmetricalShapes/vertical1_yellow.svg';
import SymmetricalShapesVertical2Green from './SymmetricalShapes/vertical2_green.svg';
import SymmetricalShapesVertical2Pink from './SymmetricalShapes/vertical2_pink.svg';
import SymmetricalShapesVertical2Purple from './SymmetricalShapes/vertical2_purple.svg';
import SymmetricalShapesVertical2White from './SymmetricalShapes/vertical2_white.svg';
import SymmetricalShapesVertical2Yellow from './SymmetricalShapes/vertical2_yellow.svg';
import SymmetricalShapesVertical3Green from './SymmetricalShapes/vertical3_green.svg';
import SymmetricalShapesVertical3Pink from './SymmetricalShapes/vertical3_pink.svg';
import SymmetricalShapesVertical3Purple from './SymmetricalShapes/vertical3_purple.svg';
import SymmetricalShapesVertical3White from './SymmetricalShapes/vertical3_white.svg';
import SymmetricalShapesVertical3Yellow from './SymmetricalShapes/vertical3_yellow.svg';
import SymmetricalShapesWithLinesShape10IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape10IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape10LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_10_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape11IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape11IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape11LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_11_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape12IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape12IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape12LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_12_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape13IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape13IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape13LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_13_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape14IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape14IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape14LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape14LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape15IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape15IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape15LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_15_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape16IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape16IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape16LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape16LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape17IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape17IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape17LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape17LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape18IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape18IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape18LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape18LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape19IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape19IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape19LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_19_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape1IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape1IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape1LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape1LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape20IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape20IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape20LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_20_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape21IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape21IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape21LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_21_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape22LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_22_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape23LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_23_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape24LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape24LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape25LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape25LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape26LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_26_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape2IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape2IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape2LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape2LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape3IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape3IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape3LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape3LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape4IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape4IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape4LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_4_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape5IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape5IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape5LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape5LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape6IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape6IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape6LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape6LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_d.svg';
import SymmetricalShapesWithLinesShape7IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape7IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape7LineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape7LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape8IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape8IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape8LineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape8LineOfSymmetryC from './Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_c.svg';
import SymmetricalShapesWithLinesShape9IncorrectLineOfSymmetryA from './Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_a.svg';
import SymmetricalShapesWithLinesShape9IncorrectLineOfSymmetryB from './Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_b.svg';
import SymmetricalShapesWithLinesShape9LineOfSymmetryD from './Symmetrical_shapes_with_lines/shape_9_line_of_symmetry_d.svg';
import TeddyBear1 from './Teddy_bear_1.svg';
import TeddyBear2 from './Teddy_bear_2.svg';
import Television from './Television.svg';
import ThermometersHorizontalThermometerFilled from './Thermometers/Horizontal_Thermometer_filled.svg';
import ThermometersVerticalThermometerBlank from './Thermometers/Vertical_Thermometer_blank.svg';
import Tiny from './Tiny.svg';
import TomatoSauceKetchup from './Tomato_sauce_ketchup.svg';
import Toolbox from './Toolbox.svg';
import ToyCar from './Toy_car.svg';
import TransparentBarrel from './Transparent_barrel.svg';
import TransportToysTrainCarriage from './Transport_toys/Train_carriage.svg';
import TransportToysTransportToysCar from './Transport_toys/Transport_toys_Car.svg';
import TransportToysTransportToysSailboat from './Transport_toys/Transport_toys_Sailboat.svg';
import TransportToysTransportToysTrain from './Transport_toys/Transport_toys_Train.svg';
import TransportToysTransportToysPlane from './Transport_toys/Transport_toys_plane.svg';
import TrapeziumTrapeziumIsosceles from './Trapezium/trapezium_isosceles.svg';
import TrapeziumTrapeziumIsoscelesBlue from './Trapezium/trapezium_isosceles_blue.svg';
import TrapeziumTrapeziumIsoscelesBlueOneInteriorAngleLhs from './Trapezium/trapezium_isosceles_blue_one_interior_angle_lhs.svg';
import TrapeziumTrapeziumIsoscelesBlueOneInteriorAngleRhs from './Trapezium/trapezium_isosceles_blue_one_interior_angle_rhs.svg';
import TrapeziumTrapeziumIsoscelesGreen from './Trapezium/trapezium_isosceles_green.svg';
import TrapeziumTrapeziumIsoscelesGreenOneInteriorAngleLhs from './Trapezium/trapezium_isosceles_green_one_interior_angle_lhs.svg';
import TrapeziumTrapeziumIsoscelesGreenOneInteriorAngleRhs from './Trapezium/trapezium_isosceles_green_one_interior_angle_rhs.svg';
import TrapeziumTrapeziumIsoscelesOrange from './Trapezium/trapezium_isosceles_orange.svg';
import TrapeziumTrapeziumIsoscelesPink from './Trapezium/trapezium_isosceles_pink.svg';
import TrapeziumTrapeziumIsoscelesPinkOneInteriorAngleLhs from './Trapezium/trapezium_isosceles_pink_one_interior_angle_lhs.svg';
import TrapeziumTrapeziumIsoscelesPinkOneInteriorAngleRhs from './Trapezium/trapezium_isosceles_pink_one_interior_angle_rhs.svg';
import TrapeziumTrapeziumIsoscelesPurple from './Trapezium/trapezium_isosceles_purple.svg';
import TrapeziumTrapeziumIsoscelesPurpleOneInteriorAngleLhs from './Trapezium/trapezium_isosceles_purple_one_interior_angle_lhs.svg';
import TrapeziumTrapeziumIsoscelesPurpleOneInteriorAngleRhs from './Trapezium/trapezium_isosceles_purple_one_interior_angle_rhs.svg';
import TrapeziumTrapeziumIsoscelesRed from './Trapezium/trapezium_isosceles_red.svg';
import TrapeziumTrapeziumIsoscelesTurquoise from './Trapezium/trapezium_isosceles_turquoise.svg';
import TrapeziumTrapeziumIsoscelesWhite from './Trapezium/trapezium_isosceles_white.svg';
import TrapeziumTrapeziumIsoscelesYellow from './Trapezium/trapezium_isosceles_yellow.svg';
import TrapeziumTrapeziumIsoscelesYellowOneInteriorAngleLhs from './Trapezium/trapezium_isosceles_yellow_one_interior_angle_lhs.svg';
import TrapeziumTrapeziumIsoscelesYellowOneInteriorAngleRhs from './Trapezium/trapezium_isosceles_yellow_one_interior_angle_rhs.svg';
import TreeA from './Tree_A.svg';
import TreeB from './Tree_B.svg';
import Triangle from './Triangle.svg';
import TriangleMan from './TriangleMan.svg';
import TriangleWhite from './TriangleWhite.svg';
import True from './True.svg';
import TubeOfCrisps from './Tube_of_crisps.svg';
import TurnsAndAnglesFullturnAntclockwise from './Turns_and_angles/Fullturn_antclockwise.svg';
import TurnsAndAnglesFullturnClockwise from './Turns_and_angles/Fullturn_clockwise.svg';
import TurnsAndAnglesHalfturnAnticlockwise from './Turns_and_angles/Halfturn_anticlockwise.svg';
import TurnsAndAnglesHalfturnClockwise from './Turns_and_angles/Halfturn_clockwise.svg';
import TurnsAndAnglesQuarterturnAnticlockwise from './Turns_and_angles/Quarterturn_anticlockwise.svg';
import TurnsAndAnglesQuarterturnClockwise from './Turns_and_angles/Quarterturn_clockwise.svg';
import TurnsAndAnglesThreequarterturnAnticlockwise from './Turns_and_angles/Threequarterturn_anticlockwise.svg';
import TurnsAndAnglesThreequarterturnClockwise from './Turns_and_angles/Threequarterturn_clockwise.svg';
import UnbrokenShapesArrowTransparent from './Unbroken_shapes/arrow_transparent.svg';
import UnbrokenShapesCircleSemiTransparent from './Unbroken_shapes/circle_semi_transparent.svg';
import UnbrokenShapesCircleTransparent from './Unbroken_shapes/circle_transparent.svg';
import UnbrokenShapesCrossTransparent from './Unbroken_shapes/cross_transparent.svg';
import UnbrokenShapesHexagonTransparent from './Unbroken_shapes/hexagon_transparent.svg';
import UnbrokenShapesKiteTransparent from './Unbroken_shapes/kite_transparent.svg';
import UnbrokenShapesParallelogramTransparent from './Unbroken_shapes/parallelogram_transparent.svg';
import UnbrokenShapesPentagonHouseTransparent from './Unbroken_shapes/pentagon_house_transparent.svg';
import UnbrokenShapesPentagonTransparent from './Unbroken_shapes/pentagon_transparent.svg';
import UnbrokenShapesRectangleTransparent from './Unbroken_shapes/rectangle_transparent.svg';
import UnbrokenShapesRhombusTransparent from './Unbroken_shapes/rhombus_transparent.svg';
import UnbrokenShapesSquareTransparent from './Unbroken_shapes/square_transparent.svg';
import UnbrokenShapesTrapeziumRATransparent from './Unbroken_shapes/trapezium_RA_transparent.svg';
import UnbrokenShapesTrapeziumIsoscelesTransparent from './Unbroken_shapes/trapezium_isosceles_transparent.svg';
import UnbrokenShapesTriangleRATransparent from './Unbroken_shapes/triangle_RA_transparent.svg';
import UnbrokenShapesTriangleEqualTransparent from './Unbroken_shapes/triangle_equal_transparent.svg';
import UnbrokenShapesTriangleIsosNarrowTransparent from './Unbroken_shapes/triangle_isos_narrow_transparent.svg';
import UnbrokenShapesTriangleIsosWideTransparent from './Unbroken_shapes/triangle_isos_wide_transparent.svg';
import UnbrokenShapesTriangleScaleneTransparent from './Unbroken_shapes/triangle_scalene_transparent.svg';
import UnequalShapes10PartsCircleUnequal1010 from './Unequal_shapes_10_parts/Circle_unequal_10-10.svg';
import UnequalShapes10PartsPentagonUnequal1010 from './Unequal_shapes_10_parts/Pentagon_unequal_10-10.svg';
import UnequalShapes10PartsSquareUnequal1010 from './Unequal_shapes_10_parts/Square_unequal_10-10.svg';
import UnequalShapes11PartsCircleUnequal1111 from './Unequal_shapes_11_parts/Circle_unequal_11-11.svg';
import UnequalShapes11PartsRectangleUnequal1111 from './Unequal_shapes_11_parts/Rectangle_unequal_11-11.svg';
import UnequalShapes11PartsSquareUnequal1111 from './Unequal_shapes_11_parts/Square_unequal_11-11.svg';
import UnequalShapes12PartsCircleUnequal1212 from './Unequal_shapes_12_parts/Circle_unequal_12-12.svg';
import UnequalShapes12PartsRectangleUnequal1212 from './Unequal_shapes_12_parts/Rectangle_unequal_12-12.svg';
import UnequalShapes12PartsSquareUnequal1212 from './Unequal_shapes_12_parts/Square_unequal_12-12.svg';
import UnequalShapes2PartsArrowUnequal121Blue from './Unequal_shapes_2_parts/Arrow_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsArrowUnequal121Green from './Unequal_shapes_2_parts/Arrow_unequal_1_2_1_green.svg';
import UnequalShapes2PartsArrowUnequal121Yellow from './Unequal_shapes_2_parts/Arrow_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsArrowUnequal122Blue from './Unequal_shapes_2_parts/Arrow_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsArrowUnequal122Green from './Unequal_shapes_2_parts/Arrow_unequal_1_2_2_green.svg';
import UnequalShapes2PartsArrowUnequal122Yellow from './Unequal_shapes_2_parts/Arrow_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsArrowUnequal123Blue from './Unequal_shapes_2_parts/Arrow_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsArrowUnequal123Green from './Unequal_shapes_2_parts/Arrow_unequal_1_2_3_green.svg';
import UnequalShapes2PartsArrowUnequal123Yellow from './Unequal_shapes_2_parts/Arrow_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsArrowUnequal124Blue from './Unequal_shapes_2_parts/Arrow_unequal_1_2_4_blue.svg';
import UnequalShapes2PartsArrowUnequal124Green from './Unequal_shapes_2_parts/Arrow_unequal_1_2_4_green.svg';
import UnequalShapes2PartsArrowUnequal124Yellow from './Unequal_shapes_2_parts/Arrow_unequal_1_2_4_yellow.svg';
import UnequalShapes2PartsCircleUnequal121Blue from './Unequal_shapes_2_parts/Circle_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsCircleUnequal121Green from './Unequal_shapes_2_parts/Circle_unequal_1_2_1_green.svg';
import UnequalShapes2PartsCircleUnequal121Yellow from './Unequal_shapes_2_parts/Circle_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsCircleUnequal122Blue from './Unequal_shapes_2_parts/Circle_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsCircleUnequal122Green from './Unequal_shapes_2_parts/Circle_unequal_1_2_2_green.svg';
import UnequalShapes2PartsCircleUnequal122Yellow from './Unequal_shapes_2_parts/Circle_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsCircleUnequal123Blue from './Unequal_shapes_2_parts/Circle_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsCircleUnequal123Green from './Unequal_shapes_2_parts/Circle_unequal_1_2_3_green.svg';
import UnequalShapes2PartsCircleUnequal123Yellow from './Unequal_shapes_2_parts/Circle_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsCircleUnequal124Blue from './Unequal_shapes_2_parts/Circle_unequal_1_2_4_blue.svg';
import UnequalShapes2PartsCircleUnequal124Green from './Unequal_shapes_2_parts/Circle_unequal_1_2_4_green.svg';
import UnequalShapes2PartsCircleUnequal124Yellow from './Unequal_shapes_2_parts/Circle_unequal_1_2_4_yellow.svg';
import UnequalShapes2PartsCircleUnequal125Blue from './Unequal_shapes_2_parts/Circle_unequal_1_2_5_blue.svg';
import UnequalShapes2PartsCircleUnequal125Green from './Unequal_shapes_2_parts/Circle_unequal_1_2_5_green.svg';
import UnequalShapes2PartsCircleUnequal125Yellow from './Unequal_shapes_2_parts/Circle_unequal_1_2_5_yellow.svg';
import UnequalShapes2PartsCircleUnequal126Blue from './Unequal_shapes_2_parts/Circle_unequal_1_2_6_blue.svg';
import UnequalShapes2PartsCircleUnequal126Green from './Unequal_shapes_2_parts/Circle_unequal_1_2_6_green.svg';
import UnequalShapes2PartsCircleUnequal126Yellow from './Unequal_shapes_2_parts/Circle_unequal_1_2_6_yellow.svg';
import UnequalShapes2PartsHeartUnequal121Blue from './Unequal_shapes_2_parts/Heart_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsHeartUnequal121Green from './Unequal_shapes_2_parts/Heart_unequal_1_2_1_green.svg';
import UnequalShapes2PartsHeartUnequal121Yellow from './Unequal_shapes_2_parts/Heart_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsHeartUnequal122Blue from './Unequal_shapes_2_parts/Heart_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsHeartUnequal122Green from './Unequal_shapes_2_parts/Heart_unequal_1_2_2_green.svg';
import UnequalShapes2PartsHeartUnequal122Yellow from './Unequal_shapes_2_parts/Heart_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsHexagonUnequal121Blue from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsHexagonUnequal121Green from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_green.svg';
import UnequalShapes2PartsHexagonUnequal121Yellow from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsHexagonUnequal122Blue from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsHexagonUnequal122Green from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_green.svg';
import UnequalShapes2PartsHexagonUnequal122Yellow from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsHexagonUnequal123Blue from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsHexagonUnequal123Green from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_green.svg';
import UnequalShapes2PartsHexagonUnequal123Yellow from './Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsKiteUnequal12 from './Unequal_shapes_2_parts/Kite_unequal_1-2.svg';
import UnequalShapes2PartsKiteUnequal22 from './Unequal_shapes_2_parts/Kite_unequal_2-2.svg';
import UnequalShapes2PartsOctagonUnequal121Blue from './Unequal_shapes_2_parts/Octagon_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsOctagonUnequal121Green from './Unequal_shapes_2_parts/Octagon_unequal_1_2_1_green.svg';
import UnequalShapes2PartsOctagonUnequal121Yellow from './Unequal_shapes_2_parts/Octagon_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsOctagonUnequal122Blue from './Unequal_shapes_2_parts/Octagon_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsOctagonUnequal122Green from './Unequal_shapes_2_parts/Octagon_unequal_1_2_2_green.svg';
import UnequalShapes2PartsOctagonUnequal122Yellow from './Unequal_shapes_2_parts/Octagon_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsOctagonUnequal123Blue from './Unequal_shapes_2_parts/Octagon_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsOctagonUnequal123Green from './Unequal_shapes_2_parts/Octagon_unequal_1_2_3_green.svg';
import UnequalShapes2PartsOctagonUnequal123Yellow from './Unequal_shapes_2_parts/Octagon_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsOctagonUnequal124Blue from './Unequal_shapes_2_parts/Octagon_unequal_1_2_4_blue.svg';
import UnequalShapes2PartsOctagonUnequal124Green from './Unequal_shapes_2_parts/Octagon_unequal_1_2_4_green.svg';
import UnequalShapes2PartsOctagonUnequal124Yellow from './Unequal_shapes_2_parts/Octagon_unequal_1_2_4_yellow.svg';
import UnequalShapes2PartsRectangleUnequal121Blue from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsRectangleUnequal121Green from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_green.svg';
import UnequalShapes2PartsRectangleUnequal121Yellow from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsRectangleUnequal122Blue from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsRectangleUnequal122Green from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_green.svg';
import UnequalShapes2PartsRectangleUnequal122Yellow from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsRectangleUnequal123Blue from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsRectangleUnequal123Green from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_green.svg';
import UnequalShapes2PartsRectangleUnequal123Yellow from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsRectangleUnequal124Blue from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_blue.svg';
import UnequalShapes2PartsRectangleUnequal124Green from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_green.svg';
import UnequalShapes2PartsRectangleUnequal124Yellow from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_yellow.svg';
import UnequalShapes2PartsRectangleUnequal125Blue from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_blue.svg';
import UnequalShapes2PartsRectangleUnequal125Green from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_green.svg';
import UnequalShapes2PartsRectangleUnequal125Yellow from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_yellow.svg';
import UnequalShapes2PartsRectangleUnequal126Blue from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_blue.svg';
import UnequalShapes2PartsRectangleUnequal126Green from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_green.svg';
import UnequalShapes2PartsRectangleUnequal126Yellow from './Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_yellow.svg';
import UnequalShapes2PartsSquareUnequal12 from './Unequal_shapes_2_parts/Square_unequal_1-2.svg';
import UnequalShapes2PartsSquareUnequal121Blue from './Unequal_shapes_2_parts/Square_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsSquareUnequal121Green from './Unequal_shapes_2_parts/Square_unequal_1_2_1_green.svg';
import UnequalShapes2PartsSquareUnequal121Yellow from './Unequal_shapes_2_parts/Square_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsSquareUnequal122Blue from './Unequal_shapes_2_parts/Square_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsSquareUnequal122Green from './Unequal_shapes_2_parts/Square_unequal_1_2_2_green.svg';
import UnequalShapes2PartsSquareUnequal122Yellow from './Unequal_shapes_2_parts/Square_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsSquareUnequal123Blue from './Unequal_shapes_2_parts/Square_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsSquareUnequal123Green from './Unequal_shapes_2_parts/Square_unequal_1_2_3_green.svg';
import UnequalShapes2PartsSquareUnequal123Yellow from './Unequal_shapes_2_parts/Square_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsSquareUnequal124Blue from './Unequal_shapes_2_parts/Square_unequal_1_2_4_blue.svg';
import UnequalShapes2PartsSquareUnequal124Green from './Unequal_shapes_2_parts/Square_unequal_1_2_4_green.svg';
import UnequalShapes2PartsSquareUnequal124Yellow from './Unequal_shapes_2_parts/Square_unequal_1_2_4_yellow.svg';
import UnequalShapes2PartsSquareUnequal125Blue from './Unequal_shapes_2_parts/Square_unequal_1_2_5_blue.svg';
import UnequalShapes2PartsSquareUnequal125Green from './Unequal_shapes_2_parts/Square_unequal_1_2_5_green.svg';
import UnequalShapes2PartsSquareUnequal125Yellow from './Unequal_shapes_2_parts/Square_unequal_1_2_5_yellow.svg';
import UnequalShapes2PartsSquareUnequal126Blue from './Unequal_shapes_2_parts/Square_unequal_1_2_6_blue.svg';
import UnequalShapes2PartsSquareUnequal126Green from './Unequal_shapes_2_parts/Square_unequal_1_2_6_green.svg';
import UnequalShapes2PartsSquareUnequal126Yellow from './Unequal_shapes_2_parts/Square_unequal_1_2_6_yellow.svg';
import UnequalShapes2PartsSquareUnequal22 from './Unequal_shapes_2_parts/Square_unequal_2-2.svg';
import UnequalShapes2PartsStarUnequal121Blue from './Unequal_shapes_2_parts/Star_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsStarUnequal121Green from './Unequal_shapes_2_parts/Star_unequal_1_2_1_green.svg';
import UnequalShapes2PartsStarUnequal121Yellow from './Unequal_shapes_2_parts/Star_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsStarUnequal122Blue from './Unequal_shapes_2_parts/Star_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsStarUnequal122Green from './Unequal_shapes_2_parts/Star_unequal_1_2_2_green.svg';
import UnequalShapes2PartsStarUnequal122Yellow from './Unequal_shapes_2_parts/Star_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsTriangleUnequal12 from './Unequal_shapes_2_parts/Triangle_unequal_1-2.svg';
import UnequalShapes2PartsTriangleUnequal121Blue from './Unequal_shapes_2_parts/Triangle_unequal_1_2_1_blue.svg';
import UnequalShapes2PartsTriangleUnequal121Green from './Unequal_shapes_2_parts/Triangle_unequal_1_2_1_green.svg';
import UnequalShapes2PartsTriangleUnequal121Yellow from './Unequal_shapes_2_parts/Triangle_unequal_1_2_1_yellow.svg';
import UnequalShapes2PartsTriangleUnequal122Blue from './Unequal_shapes_2_parts/Triangle_unequal_1_2_2_blue.svg';
import UnequalShapes2PartsTriangleUnequal122Green from './Unequal_shapes_2_parts/Triangle_unequal_1_2_2_green.svg';
import UnequalShapes2PartsTriangleUnequal122Yellow from './Unequal_shapes_2_parts/Triangle_unequal_1_2_2_yellow.svg';
import UnequalShapes2PartsTriangleUnequal123Blue from './Unequal_shapes_2_parts/Triangle_unequal_1_2_3_blue.svg';
import UnequalShapes2PartsTriangleUnequal123Green from './Unequal_shapes_2_parts/Triangle_unequal_1_2_3_green.svg';
import UnequalShapes2PartsTriangleUnequal123Yellow from './Unequal_shapes_2_parts/Triangle_unequal_1_2_3_yellow.svg';
import UnequalShapes2PartsTriangleUnequal22 from './Unequal_shapes_2_parts/Triangle_unequal_2-2.svg';
import UnequalShapes3PartsCircleUnequal311Blue from './Unequal_shapes_3_parts/Circle_unequal_3-1_1_blue.svg';
import UnequalShapes3PartsCircleUnequal311Green from './Unequal_shapes_3_parts/Circle_unequal_3-1_1_green.svg';
import UnequalShapes3PartsCircleUnequal311Yellow from './Unequal_shapes_3_parts/Circle_unequal_3-1_1_yellow.svg';
import UnequalShapes3PartsCircleUnequal33 from './Unequal_shapes_3_parts/Circle_unequal_3-3.svg';
import UnequalShapes3PartsHexagonUnequal311Blue from './Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_blue.svg';
import UnequalShapes3PartsHexagonUnequal311Green from './Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_green.svg';
import UnequalShapes3PartsHexagonUnequal311Yellow from './Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_yellow.svg';
import UnequalShapes3PartsRectangleUnequal311Blue from './Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_blue.svg';
import UnequalShapes3PartsRectangleUnequal311Green from './Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_green.svg';
import UnequalShapes3PartsRectangleUnequal311Yellow from './Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_yellow.svg';
import UnequalShapes3PartsSquareUnequal13 from './Unequal_shapes_3_parts/Square_unequal_1-3.svg';
import UnequalShapes3PartsSquareUnequal311Blue from './Unequal_shapes_3_parts/Square_unequal_3-1_1_blue.svg';
import UnequalShapes3PartsSquareUnequal311Green from './Unequal_shapes_3_parts/Square_unequal_3-1_1_green.svg';
import UnequalShapes3PartsSquareUnequal311Yellow from './Unequal_shapes_3_parts/Square_unequal_3-1_1_yellow.svg';
import UnequalShapes3PartsSquareUnequal33 from './Unequal_shapes_3_parts/Square_unequal_3-3.svg';
import UnequalShapes3PartsTriangleUnequal13 from './Unequal_shapes_3_parts/Triangle_unequal_1-3.svg';
import UnequalShapes3PartsTriangleUnequal311Blue from './Unequal_shapes_3_parts/Triangle_unequal_3-1_1_blue.svg';
import UnequalShapes3PartsTriangleUnequal311Green from './Unequal_shapes_3_parts/Triangle_unequal_3-1_1_green.svg';
import UnequalShapes3PartsTriangleUnequal311Yellow from './Unequal_shapes_3_parts/Triangle_unequal_3-1_1_yellow.svg';
import UnequalShapes3PartsTriangleUnequal33 from './Unequal_shapes_3_parts/Triangle_unequal_3-3.svg';
import UnequalShapes4PartsArrowUnequal141Blue from './Unequal_shapes_4_parts/Arrow_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsArrowUnequal141Green from './Unequal_shapes_4_parts/Arrow_unequal_1_4_1_green.svg';
import UnequalShapes4PartsArrowUnequal141Yellow from './Unequal_shapes_4_parts/Arrow_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsArrowUnequal142Blue from './Unequal_shapes_4_parts/Arrow_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsArrowUnequal142Green from './Unequal_shapes_4_parts/Arrow_unequal_1_4_2_green.svg';
import UnequalShapes4PartsArrowUnequal142Yellow from './Unequal_shapes_4_parts/Arrow_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsArrowUnequal341Blue from './Unequal_shapes_4_parts/Arrow_unequal_3_4_1_blue.svg';
import UnequalShapes4PartsArrowUnequal341Green from './Unequal_shapes_4_parts/Arrow_unequal_3_4_1_green.svg';
import UnequalShapes4PartsArrowUnequal341Yellow from './Unequal_shapes_4_parts/Arrow_unequal_3_4_1_yellow.svg';
import UnequalShapes4PartsCircleUnequal141Blue from './Unequal_shapes_4_parts/Circle_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsCircleUnequal141Green from './Unequal_shapes_4_parts/Circle_unequal_1_4_1_green.svg';
import UnequalShapes4PartsCircleUnequal141Yellow from './Unequal_shapes_4_parts/Circle_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsCircleUnequal142Blue from './Unequal_shapes_4_parts/Circle_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsCircleUnequal142Green from './Unequal_shapes_4_parts/Circle_unequal_1_4_2_green.svg';
import UnequalShapes4PartsCircleUnequal142Yellow from './Unequal_shapes_4_parts/Circle_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsCircleUnequal341Blue from './Unequal_shapes_4_parts/Circle_unequal_3_4_1_blue.svg';
import UnequalShapes4PartsCircleUnequal341Green from './Unequal_shapes_4_parts/Circle_unequal_3_4_1_green.svg';
import UnequalShapes4PartsCircleUnequal341Yellow from './Unequal_shapes_4_parts/Circle_unequal_3_4_1_yellow.svg';
import UnequalShapes4PartsCircleUnequal44 from './Unequal_shapes_4_parts/Circle_unequal_4-4.svg';
import UnequalShapes4PartsCrossUnequal14 from './Unequal_shapes_4_parts/Cross_unequal_1-4.svg';
import UnequalShapes4PartsCrossUnequal34 from './Unequal_shapes_4_parts/Cross_unequal_3-4.svg';
import UnequalShapes4PartsCrossUnequal44 from './Unequal_shapes_4_parts/Cross_unequal_4-4.svg';
import UnequalShapes4PartsHeartUnequal141Blue from './Unequal_shapes_4_parts/Heart_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsHeartUnequal141Green from './Unequal_shapes_4_parts/Heart_unequal_1_4_1_green.svg';
import UnequalShapes4PartsHeartUnequal141Yellow from './Unequal_shapes_4_parts/Heart_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsHeartUnequal142Blue from './Unequal_shapes_4_parts/Heart_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsHeartUnequal142Green from './Unequal_shapes_4_parts/Heart_unequal_1_4_2_green.svg';
import UnequalShapes4PartsHeartUnequal142Yellow from './Unequal_shapes_4_parts/Heart_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsHeartUnequal143Blue from './Unequal_shapes_4_parts/Heart_unequal_1_4_3_blue.svg';
import UnequalShapes4PartsHeartUnequal143Green from './Unequal_shapes_4_parts/Heart_unequal_1_4_3_green.svg';
import UnequalShapes4PartsHeartUnequal143Yellow from './Unequal_shapes_4_parts/Heart_unequal_1_4_3_yellow.svg';
import UnequalShapes4PartsHeartUnequal144Blue from './Unequal_shapes_4_parts/Heart_unequal_1_4_4_blue.svg';
import UnequalShapes4PartsHeartUnequal144Green from './Unequal_shapes_4_parts/Heart_unequal_1_4_4_green.svg';
import UnequalShapes4PartsHeartUnequal144Yellow from './Unequal_shapes_4_parts/Heart_unequal_1_4_4_yellow.svg';
import UnequalShapes4PartsHeartUnequal343Blue from './Unequal_shapes_4_parts/Heart_unequal_3_4_3_blue.svg';
import UnequalShapes4PartsHeartUnequal343Green from './Unequal_shapes_4_parts/Heart_unequal_3_4_3_green.svg';
import UnequalShapes4PartsHeartUnequal343Yellow from './Unequal_shapes_4_parts/Heart_unequal_3_4_3_yellow.svg';
import UnequalShapes4PartsHexagonUnequal141Blue from './Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsHexagonUnequal141Green from './Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_green.svg';
import UnequalShapes4PartsHexagonUnequal141Yellow from './Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsHexagonUnequal142Blue from './Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsHexagonUnequal142Green from './Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_green.svg';
import UnequalShapes4PartsHexagonUnequal142Yellow from './Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsHexagonUnequal342Blue from './Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_blue.svg';
import UnequalShapes4PartsHexagonUnequal342Green from './Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_green.svg';
import UnequalShapes4PartsHexagonUnequal342Yellow from './Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_yellow.svg';
import UnequalShapes4PartsOctagonUnequal141Blue from './Unequal_shapes_4_parts/Octagon_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsOctagonUnequal141Green from './Unequal_shapes_4_parts/Octagon_unequal_1_4_1_green.svg';
import UnequalShapes4PartsOctagonUnequal141Yellow from './Unequal_shapes_4_parts/Octagon_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsOctagonUnequal142Blue from './Unequal_shapes_4_parts/Octagon_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsOctagonUnequal142Green from './Unequal_shapes_4_parts/Octagon_unequal_1_4_2_green.svg';
import UnequalShapes4PartsOctagonUnequal142Yellow from './Unequal_shapes_4_parts/Octagon_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsRectangleUnequal141Blue from './Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsRectangleUnequal141Green from './Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_green.svg';
import UnequalShapes4PartsRectangleUnequal141Yellow from './Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsRectangleUnequal142Blue from './Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsRectangleUnequal142Green from './Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_green.svg';
import UnequalShapes4PartsRectangleUnequal142Yellow from './Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsRectangleUnequal341Blue from './Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_blue.svg';
import UnequalShapes4PartsRectangleUnequal341Green from './Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_green.svg';
import UnequalShapes4PartsRectangleUnequal341Yellow from './Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_yellow.svg';
import UnequalShapes4PartsSquareUnequal14 from './Unequal_shapes_4_parts/Square_unequal_1-4.svg';
import UnequalShapes4PartsSquareUnequal141Blue from './Unequal_shapes_4_parts/Square_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsSquareUnequal141Green from './Unequal_shapes_4_parts/Square_unequal_1_4_1_green.svg';
import UnequalShapes4PartsSquareUnequal141Yellow from './Unequal_shapes_4_parts/Square_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsSquareUnequal142Blue from './Unequal_shapes_4_parts/Square_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsSquareUnequal142Green from './Unequal_shapes_4_parts/Square_unequal_1_4_2_green.svg';
import UnequalShapes4PartsSquareUnequal142Yellow from './Unequal_shapes_4_parts/Square_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsSquareUnequal341Blue from './Unequal_shapes_4_parts/Square_unequal_3_4_1_blue.svg';
import UnequalShapes4PartsSquareUnequal341Green from './Unequal_shapes_4_parts/Square_unequal_3_4_1_green.svg';
import UnequalShapes4PartsSquareUnequal341Yellow from './Unequal_shapes_4_parts/Square_unequal_3_4_1_yellow.svg';
import UnequalShapes4PartsSquareUnequal44 from './Unequal_shapes_4_parts/Square_unequal_4-4.svg';
import UnequalShapes4PartsStarUnequal141Blue from './Unequal_shapes_4_parts/Star_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsStarUnequal141Green from './Unequal_shapes_4_parts/Star_unequal_1_4_1_green.svg';
import UnequalShapes4PartsStarUnequal141Yellow from './Unequal_shapes_4_parts/Star_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsStarUnequal142Blue from './Unequal_shapes_4_parts/Star_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsStarUnequal142Green from './Unequal_shapes_4_parts/Star_unequal_1_4_2_green.svg';
import UnequalShapes4PartsStarUnequal142Yellow from './Unequal_shapes_4_parts/Star_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsTriangleUnequal141Blue from './Unequal_shapes_4_parts/Triangle_unequal_1_4_1_blue.svg';
import UnequalShapes4PartsTriangleUnequal141Green from './Unequal_shapes_4_parts/Triangle_unequal_1_4_1_green.svg';
import UnequalShapes4PartsTriangleUnequal141Yellow from './Unequal_shapes_4_parts/Triangle_unequal_1_4_1_yellow.svg';
import UnequalShapes4PartsTriangleUnequal142Blue from './Unequal_shapes_4_parts/Triangle_unequal_1_4_2_blue.svg';
import UnequalShapes4PartsTriangleUnequal142Green from './Unequal_shapes_4_parts/Triangle_unequal_1_4_2_green.svg';
import UnequalShapes4PartsTriangleUnequal142Yellow from './Unequal_shapes_4_parts/Triangle_unequal_1_4_2_yellow.svg';
import UnequalShapes4PartsTriangleUnequal341Blue from './Unequal_shapes_4_parts/Triangle_unequal_3_4_1_blue.svg';
import UnequalShapes4PartsTriangleUnequal341Green from './Unequal_shapes_4_parts/Triangle_unequal_3_4_1_green.svg';
import UnequalShapes4PartsTriangleUnequal341Yellow from './Unequal_shapes_4_parts/Triangle_unequal_3_4_1_yellow.svg';
import UnequalShapes5PartsCircleUnequal55 from './Unequal_shapes_5_parts/Circle_unequal_5-5.svg';
import UnequalShapes5PartsPentagonUnequal15 from './Unequal_shapes_5_parts/Pentagon_unequal_1-5.svg';
import UnequalShapes5PartsPentagonUnequal55 from './Unequal_shapes_5_parts/Pentagon_unequal_5-5.svg';
import UnequalShapes5PartsSquareUnequal15 from './Unequal_shapes_5_parts/Square_unequal_1-5.svg';
import UnequalShapes5PartsSquareUnequal55 from './Unequal_shapes_5_parts/Square_unequal_5-5.svg';
import UnequalShapes6PartsCircleUnequal66 from './Unequal_shapes_6_parts/Circle_unequal_6-6.svg';
import UnequalShapes6PartsHexagonUnequal66 from './Unequal_shapes_6_parts/Hexagon_unequal6-6.svg';
import UnequalShapes6PartsSquareUnequal66 from './Unequal_shapes_6_parts/Square_unequal_6-6.svg';
import UnequalShapes7PartsCircleUnequal77 from './Unequal_shapes_7_parts/Circle_unequal_7-7.svg';
import UnequalShapes7PartsRectangleUnequal77 from './Unequal_shapes_7_parts/Rectangle_unequal_7-7.svg';
import UnequalShapes7PartsSquareUnequal77 from './Unequal_shapes_7_parts/Square_unequal_7-7.svg';
import UnequalShapes8PartsCircleUnequal88 from './Unequal_shapes_8_parts/Circle_unequal_8-8.svg';
import UnequalShapes8PartsCrossUnequal88 from './Unequal_shapes_8_parts/Cross_unequal_8-8.svg';
import UnequalShapes8PartsSquareUnequal88 from './Unequal_shapes_8_parts/Square_unequal_8-8.svg';
import UnequalShapes9PartsCircleUnequal99 from './Unequal_shapes_9_parts/Circle_unequal_9-9.svg';
import UnequalShapes9PartsRectangleUnequal99 from './Unequal_shapes_9_parts/Rectangle_unequal_9-9.svg';
import UnequalShapes9PartsSquareUnequal99 from './Unequal_shapes_9_parts/Square_unequal_9-9.svg';
import Wardrobe from './Wardrobe.svg';
import WaterBottle from './Water_bottle.svg';
import WaterBottleGlass from './Water_bottle_glass.svg';
import WaterBottleGlassEmpty from './Water_bottle_glass_empty.svg';
import WaterBottlesPackOf12 from './Water_bottles_pack_of_12.svg';
import WateringCan from './Watering_can.svg';
import Watermelon from './Watermelon.svg';
import WavyCirclesWavyCircleBlue from './Wavy_circles/Wavy_circle_blue.svg';
import WavyCirclesWavyCircleGreen from './Wavy_circles/Wavy_circle_green.svg';
import WavyCirclesWavyCircleOrange from './Wavy_circles/Wavy_circle_orange.svg';
import WavyCirclesWavyCirclePink from './Wavy_circles/Wavy_circle_pink.svg';
import WavyCirclesWavyCirclePurple from './Wavy_circles/Wavy_circle_purple.svg';
import WavyCirclesWavyCircleRed from './Wavy_circles/Wavy_circle_red.svg';
import WavyCirclesWavyCircleTurquoise from './Wavy_circles/Wavy_circle_turquoise.svg';
import WavyCirclesWavyCircleYellow from './Wavy_circles/Wavy_circle_yellow.svg';
import WeightsWeight from './Weights/Weight.svg';
import WeightsWeights100g from './Weights/Weights_100g.svg';
import WeightsWeights10g from './Weights/Weights_10g.svg';
import WeightsWeights1g from './Weights/Weights_1g.svg';
import WeightsWeights1kg from './Weights/Weights_1kg.svg';
import WeightsWeights200g from './Weights/Weights_200g.svg';
import WeightsWeights20g from './Weights/Weights_20g.svg';
import WeightsWeights20kg from './Weights/Weights_20kg.svg';
import WeightsWeights25g from './Weights/Weights_25g.svg';
import WeightsWeights2g from './Weights/Weights_2g.svg';
import WeightsWeights2kg from './Weights/Weights_2kg.svg';
import WeightsWeights500g from './Weights/Weights_500g.svg';
import WeightsWeights50g from './Weights/Weights_50g.svg';
import WeightsWeights50kg from './Weights/Weights_50kg.svg';
import WeightsWeights5g from './Weights/Weights_5g.svg';
import WeightsWeights5kg from './Weights/Weights_5kg.svg';
import WhiteRoseInfinity from './WhiteRoseInfinity.svg';
import WhiteRoseInfinityDark from './WhiteRoseInfinityDark.svg';
import WhiteVan from './White_van.svg';
import WholeShapesWholeShape1 from './Whole_shapes/Whole_shape_1.svg';
import WholeShapesWholeShape2 from './Whole_shapes/Whole_shape_2.svg';
import WholeShapesWholeShape3 from './Whole_shapes/Whole_shape_3.svg';
import WholeShapesWholeShape5 from './Whole_shapes/Whole_shape_5.svg';
import WoodenBarrel from './Wooden_barrel.svg';
import WoodenCrate from './Wooden_crate.svg';
import Worm from './Worm.svg';
import WrappedGiftsBlue from './Wrapped_gifts_blue.svg';
import WrappedGiftsPink from './Wrapped_gifts_pink.svg';
import WrappedGiftsRed from './Wrapped_gifts_red.svg';
import WrmLogo from './WrmLogo.svg';
import Yoyo from './Yoyo.svg';
import BirdBlue from './bird_blue.svg';
import BowlsBowlBeans from './bowls/Bowl_Beans.svg';
import BowlsBowlCereal from './bowls/Bowl_Cereal.svg';
import BowlsBowlEmpty from './bowls/Bowl_Empty.svg';
import BowlsBowlRice from './bowls/Bowl_Rice.svg';
import CafeBirdsEyeView from './cafe_birds_eye_view.svg';
import CupOfSand from './cupOfSand.svg';
import CupRed from './cup_red.svg';
import DrinkingStraw from './drinking_straw.svg';
import FingersHand1 from './fingers/Hand_1.svg';
import FingersHand2 from './fingers/Hand_2.svg';
import FingersHand3 from './fingers/Hand_3.svg';
import FingersHand4 from './fingers/Hand_4.svg';
import FingersHand5 from './fingers/Hand_5.svg';
import FullSwimmingPool from './full_swimming_pool.svg';
import GlassEmpty from './glass_empty.svg';
import HospitalBirdsEyeView from './hospital_birds_eye_view.svg';
import Icecream from './icecream.svg';
import LollipopBall from './lollipop_ball.svg';
import LollipopStick from './lollipop_stick.svg';
import Marshmallow from './marshmallow.svg';
import ParkBirdsEyeView from './park_birds_eye_view.svg';
import ParkBirdsEyeViewWithArrows from './park_birds_eye_view_with_arrows.svg';
import PartsOfAWholeBicycleBicycle from './parts_of_a_whole/bicycle/Bicycle.svg';
import PartsOfAWholeBicycleFrame from './parts_of_a_whole/bicycle/Frame.svg';
import PartsOfAWholeBicycleHandlebar from './parts_of_a_whole/bicycle/Handlebar.svg';
import PartsOfAWholeBicycleHelmet from './parts_of_a_whole/bicycle/Helmet.svg';
import PartsOfAWholeBicyclePedal from './parts_of_a_whole/bicycle/Pedal.svg';
import PartsOfAWholeBicycleSeat from './parts_of_a_whole/bicycle/Seat.svg';
import PartsOfAWholeBicycleWheel from './parts_of_a_whole/bicycle/Wheel.svg';
import PartsOfAWholeHouseChimney from './parts_of_a_whole/house/Chimney.svg';
import PartsOfAWholeHouseDoorBlack from './parts_of_a_whole/house/Door_black.svg';
import PartsOfAWholeHouseDoorBlue from './parts_of_a_whole/house/Door_blue.svg';
import PartsOfAWholeHouseDoorRed from './parts_of_a_whole/house/Door_red.svg';
import PartsOfAWholeHouseHouse from './parts_of_a_whole/house/House.svg';
import PartsOfAWholeHouseRoofBlack from './parts_of_a_whole/house/Roof_black.svg';
import PartsOfAWholeHouseRoofBrown from './parts_of_a_whole/house/Roof_brown.svg';
import PartsOfAWholeHouseWindowBottomLeft from './parts_of_a_whole/house/Window_bottomLeft.svg';
import PartsOfAWholeHouseWindowTopLeft from './parts_of_a_whole/house/Window_topLeft.svg';
import PartsOfAWholeHouseWindowTopRight from './parts_of_a_whole/house/Window_topRight.svg';
import PartsOfAWholeIcecreamCone from './parts_of_a_whole/icecream/Cone.svg';
import PartsOfAWholeIcecreamFlake from './parts_of_a_whole/icecream/Flake.svg';
import PartsOfAWholeIcecreamIcecream from './parts_of_a_whole/icecream/Icecream.svg';
import PartsOfAWholeIcecreamScoopChocolate from './parts_of_a_whole/icecream/Scoop_chocolate.svg';
import PartsOfAWholeIcecreamScoopMint from './parts_of_a_whole/icecream/Scoop_mint.svg';
import PartsOfAWholeIcecreamScoopStrawberry from './parts_of_a_whole/icecream/Scoop_strawberry.svg';
import PartsOfAWholeIcecreamScoopVanilla from './parts_of_a_whole/icecream/Scoop_vanilla.svg';
import PartsOfAWholeIcecreamSprinkles from './parts_of_a_whole/icecream/Sprinkles.svg';
import PartsOfAWholeIcecreamTub from './parts_of_a_whole/icecream/Tub.svg';
import PencilPacksPackOfPencils10 from './pencil_packs/Pack_of_pencils_10.svg';
import PencilPacksPackOfPencils100 from './pencil_packs/Pack_of_pencils_100.svg';
import PencilPacksPackOfPencilsUnlabelled from './pencil_packs/Pack_of_pencils_unlabelled.svg';
import PencilRubber from './pencil_rubber.svg';
import PencilTip from './pencil_tip.svg';
import PoolBirdsEyeView from './pool_birds_eye_view.svg';
import Ruler15Cm from './ruler_15_cm.svg';
import Ruler8Cm from './ruler_8_cm.svg';
import SchoolBirdsEyeView from './school_birds_eye_view.svg';
import Stick from './stick.svg';
import Tablespoon from './tablespoon.svg';
import Teacup from './teacup.svg';
import TennisBall from './tennis_ball.svg';
import TreesBirdsEyeView from './trees_birds_eye_view.svg';
import TriangleGrey from './triangle_grey.svg';
import ZestRotateLeft from './zest/rotate-left.svg';

export type SvgNameCustomizable =
  | 'ArrowRightCustomizable'
  | 'ArrowUpCustomizable'
  | 'CloseCustomizable'
  | 'Coordinates/CirclePointCustomizable'
  | 'Coordinates/CrossPointCustomizable'
  | 'Coordinates/FilledSquarePointCustomizable'
  | 'Coordinates/SquarePointCustomizable'
  | 'Coordinates/TrianglePointCustomizable'
  | 'CounterCustomizable'
  | 'SliderArrowCustomizable'
  | 'SliderArrowRightCustomizable'
  | 'SquareCustomizable'
  | 'zest/rotate-leftCustomizable';

export type SvgNameFixed =
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cone1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cone2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cone3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cone4_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cone1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cone2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cone3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cone4_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cylinder1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cylinder2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_cylinder3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid3_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism1_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism2_isometric_paper'
  | '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism3_isometric_paper'
  | '3-D_shapes_with_multi-link_cubes/3D_shape10_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape11_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape12_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape13_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape14_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape15_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape16_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape17_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape18_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape19_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape1_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape20_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape21_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape22_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape23_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape24_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape25_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape26_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape27_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape28_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape29_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape2_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape30_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape31_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape32_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape33_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape34_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape35_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape36_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape37_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape38_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape39_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape3_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape40_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape41_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape42_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape43_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape44_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape45_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape46_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape47_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape4_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape5_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape6_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape7_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape8_multi_link_cubes'
  | '3-D_shapes_with_multi-link_cubes/3D_shape9_multi_link_cubes'
  | '3D_Shapes_Cubes/3D_shape10_cubes'
  | '3D_Shapes_Cubes/3D_shape11_cubes'
  | '3D_Shapes_Cubes/3D_shape12_cubes'
  | '3D_Shapes_Cubes/3D_shape13_cubes'
  | '3D_Shapes_Cubes/3D_shape14_cubes'
  | '3D_Shapes_Cubes/3D_shape15_cubes'
  | '3D_Shapes_Cubes/3D_shape16_cubes'
  | '3D_Shapes_Cubes/3D_shape17_cubes'
  | '3D_Shapes_Cubes/3D_shape18_cubes'
  | '3D_Shapes_Cubes/3D_shape19_cubes'
  | '3D_Shapes_Cubes/3D_shape1_cubes'
  | '3D_Shapes_Cubes/3D_shape20_cubes'
  | '3D_Shapes_Cubes/3D_shape21_cubes'
  | '3D_Shapes_Cubes/3D_shape22_cubes'
  | '3D_Shapes_Cubes/3D_shape23_cubes'
  | '3D_Shapes_Cubes/3D_shape24_cubes'
  | '3D_Shapes_Cubes/3D_shape25_cubes'
  | '3D_Shapes_Cubes/3D_shape26_cubes'
  | '3D_Shapes_Cubes/3D_shape27_cubes'
  | '3D_Shapes_Cubes/3D_shape28_cubes'
  | '3D_Shapes_Cubes/3D_shape29_cubes'
  | '3D_Shapes_Cubes/3D_shape2_cubes'
  | '3D_Shapes_Cubes/3D_shape30_cubes'
  | '3D_Shapes_Cubes/3D_shape31_cubes'
  | '3D_Shapes_Cubes/3D_shape32_cubes'
  | '3D_Shapes_Cubes/3D_shape33_cubes'
  | '3D_Shapes_Cubes/3D_shape34_cubes'
  | '3D_Shapes_Cubes/3D_shape35_cubes'
  | '3D_Shapes_Cubes/3D_shape36_cubes'
  | '3D_Shapes_Cubes/3D_shape37_cubes'
  | '3D_Shapes_Cubes/3D_shape38_cubes'
  | '3D_Shapes_Cubes/3D_shape39_cubes'
  | '3D_Shapes_Cubes/3D_shape3_cubes'
  | '3D_Shapes_Cubes/3D_shape40_cubes'
  | '3D_Shapes_Cubes/3D_shape41_cubes'
  | '3D_Shapes_Cubes/3D_shape42_cubes'
  | '3D_Shapes_Cubes/3D_shape43_cubes'
  | '3D_Shapes_Cubes/3D_shape44_cubes'
  | '3D_Shapes_Cubes/3D_shape45_cubes'
  | '3D_Shapes_Cubes/3D_shape46_cubes'
  | '3D_Shapes_Cubes/3D_shape47_cubes'
  | '3D_Shapes_Cubes/3D_shape48_cubes'
  | '3D_Shapes_Cubes/3D_shape49_cubes'
  | '3D_Shapes_Cubes/3D_shape4_cubes'
  | '3D_Shapes_Cubes/3D_shape50_cubes'
  | '3D_Shapes_Cubes/3D_shape51_cubes'
  | '3D_Shapes_Cubes/3D_shape52_cubes'
  | '3D_Shapes_Cubes/3D_shape53_cubes'
  | '3D_Shapes_Cubes/3D_shape54_cubes'
  | '3D_Shapes_Cubes/3D_shape57_cubes'
  | '3D_Shapes_Cubes/3D_shape58_cubes'
  | '3D_Shapes_Cubes/3D_shape59_cubes'
  | '3D_Shapes_Cubes/3D_shape5_cubes'
  | '3D_Shapes_Cubes/3D_shape60_cubes'
  | '3D_Shapes_Cubes/3D_shape61_cubes'
  | '3D_Shapes_Cubes/3D_shape62_cubes'
  | '3D_Shapes_Cubes/3D_shape63_cubes'
  | '3D_Shapes_Cubes/3D_shape64_cubes'
  | '3D_Shapes_Cubes/3D_shape65_cubes'
  | '3D_Shapes_Cubes/3D_shape66_cubes'
  | '3D_Shapes_Cubes/3D_shape67_cubes'
  | '3D_Shapes_Cubes/3D_shape68_cubes'
  | '3D_Shapes_Cubes/3D_shape69_cubes'
  | '3D_Shapes_Cubes/3D_shape6_cubes'
  | '3D_Shapes_Cubes/3D_shape70_cubes'
  | '3D_Shapes_Cubes/3D_shape7_cubes'
  | '3D_Shapes_Cubes/3D_shape8_cubes'
  | '3D_Shapes_Cubes/3D_shape9_cubes'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape10_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape11_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape12_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape13_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape1_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape2_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape3_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape4_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape5_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape6_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape7_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape8_cubes_isometric_paper'
  | '3D_Shapes_Cubes_on_Iso_Grid/3D_shape9_cubes_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape10_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape11_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape12_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape13_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape1_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape2_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape3_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape4_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape5_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape6_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape7_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape8_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape9_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape10_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape11_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape12_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape13_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape1_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape2_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape3_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape4_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape5_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape6_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape7_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape8_isometric_paper'
  | '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape9_isometric_paper'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape1'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape10'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape11'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape12'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape13'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape14'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape15'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape16'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape2'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape3'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape4'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape5'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape6'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape7'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape8'
  | '3D_compound_shapes_on_isometric_paper/3D_compound_shape9'
  | '3D_shapes_full_colors/Cones/Cone_blue'
  | '3D_shapes_full_colors/Cones/Cone_green'
  | '3D_shapes_full_colors/Cones/Cone_orange'
  | '3D_shapes_full_colors/Cones/Cone_pink'
  | '3D_shapes_full_colors/Cones/Cone_purple'
  | '3D_shapes_full_colors/Cones/Cone_red'
  | '3D_shapes_full_colors/Cones/Cone_transparent'
  | '3D_shapes_full_colors/Cones/Cone_yellow'
  | '3D_shapes_full_colors/Cubes/Cube_blue'
  | '3D_shapes_full_colors/Cubes/Cube_blue_2'
  | '3D_shapes_full_colors/Cubes/Cube_blue_3'
  | '3D_shapes_full_colors/Cubes/Cube_green'
  | '3D_shapes_full_colors/Cubes/Cube_green_2'
  | '3D_shapes_full_colors/Cubes/Cube_green_3'
  | '3D_shapes_full_colors/Cubes/Cube_orange'
  | '3D_shapes_full_colors/Cubes/Cube_orange_2'
  | '3D_shapes_full_colors/Cubes/Cube_orange_3'
  | '3D_shapes_full_colors/Cubes/Cube_pink'
  | '3D_shapes_full_colors/Cubes/Cube_pink_2'
  | '3D_shapes_full_colors/Cubes/Cube_pink_3'
  | '3D_shapes_full_colors/Cubes/Cube_purple'
  | '3D_shapes_full_colors/Cubes/Cube_purple_2'
  | '3D_shapes_full_colors/Cubes/Cube_purple_3'
  | '3D_shapes_full_colors/Cubes/Cube_red'
  | '3D_shapes_full_colors/Cubes/Cube_red_2'
  | '3D_shapes_full_colors/Cubes/Cube_red_3'
  | '3D_shapes_full_colors/Cubes/Cube_transparent'
  | '3D_shapes_full_colors/Cubes/Cube_yellow'
  | '3D_shapes_full_colors/Cubes/Cube_yellow_2'
  | '3D_shapes_full_colors/Cubes/Cube_yellow_3'
  | '3D_shapes_full_colors/Cuboids/Cuboid_blue'
  | '3D_shapes_full_colors/Cuboids/Cuboid_green'
  | '3D_shapes_full_colors/Cuboids/Cuboid_orange'
  | '3D_shapes_full_colors/Cuboids/Cuboid_pink'
  | '3D_shapes_full_colors/Cuboids/Cuboid_purple'
  | '3D_shapes_full_colors/Cuboids/Cuboid_red'
  | '3D_shapes_full_colors/Cuboids/Cuboid_transparent'
  | '3D_shapes_full_colors/Cuboids/Cuboid_yellow'
  | '3D_shapes_full_colors/Cylinders/Cylinder_blue'
  | '3D_shapes_full_colors/Cylinders/Cylinder_green'
  | '3D_shapes_full_colors/Cylinders/Cylinder_orange'
  | '3D_shapes_full_colors/Cylinders/Cylinder_pink'
  | '3D_shapes_full_colors/Cylinders/Cylinder_purple'
  | '3D_shapes_full_colors/Cylinders/Cylinder_red'
  | '3D_shapes_full_colors/Cylinders/Cylinder_transparent'
  | '3D_shapes_full_colors/Cylinders/Cylinder_yellow'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_blue'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_green'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_orange'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_pink'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_purple'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_red'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_transparent'
  | '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_yellow'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_blue'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_green'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_orange'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_pink'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_purple'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_red'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_transparent'
  | '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_yellow'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_blue'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_green'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_orange'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_pink'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_purple'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_red'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_transparent'
  | '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_yellow'
  | '3D_shapes_full_colors/Spheres/Sphere_blue'
  | '3D_shapes_full_colors/Spheres/Sphere_green'
  | '3D_shapes_full_colors/Spheres/Sphere_orange'
  | '3D_shapes_full_colors/Spheres/Sphere_pink'
  | '3D_shapes_full_colors/Spheres/Sphere_purple'
  | '3D_shapes_full_colors/Spheres/Sphere_red'
  | '3D_shapes_full_colors/Spheres/Sphere_transparent'
  | '3D_shapes_full_colors/Spheres/Sphere_yellow'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_blue'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_green'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_orange'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_pink'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_purple'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_red'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_transparent'
  | '3D_shapes_full_colors/Square_pyramids/Square_pyramid_yellow'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_blue'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_green'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_orange'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_pink'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_purple'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_red'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_transparent'
  | '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_yellow'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_blue'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_green'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_orange'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_pink'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_purple'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_red'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_transparent'
  | '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_yellow'
  | '3D_shapes_in_real_life_objects/Cone_objects/Chips_cone'
  | '3D_shapes_in_real_life_objects/Cone_objects/Christmas_tree'
  | '3D_shapes_in_real_life_objects/Cone_objects/Cooking_funnel'
  | '3D_shapes_in_real_life_objects/Cone_objects/Megaphone'
  | '3D_shapes_in_real_life_objects/Cone_objects/Party_hat'
  | '3D_shapes_in_real_life_objects/Cone_objects/Pine_cone'
  | '3D_shapes_in_real_life_objects/Cone_objects/Traffic_cone'
  | '3D_shapes_in_real_life_objects/Cone_objects/Waffle_cone'
  | '3D_shapes_in_real_life_objects/Cube_objects/Brick'
  | '3D_shapes_in_real_life_objects/Cube_objects/Dice'
  | '3D_shapes_in_real_life_objects/Cube_objects/Ice_cube'
  | '3D_shapes_in_real_life_objects/Cube_objects/Open_cardboard_box'
  | '3D_shapes_in_real_life_objects/Cube_objects/Present'
  | '3D_shapes_in_real_life_objects/Cube_objects/Rubix_cube'
  | '3D_shapes_in_real_life_objects/Cube_objects/Stacking_crate'
  | '3D_shapes_in_real_life_objects/Cube_objects/White_sugar_cube'
  | '3D_shapes_in_real_life_objects/Cube_objects/Wooden_letter_block'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Brick'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Cardboard_box_cuboid'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Cereal_box'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Chest_cuboid'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Chocolate_cuboid'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Domino'
  | '3D_shapes_in_real_life_objects/Cuboid_objects/Tissue_box'
  | '3D_shapes_in_real_life_objects/Sphere_objects/Ball_of_yarn'
  | '3D_shapes_in_real_life_objects/Sphere_objects/Basketball'
  | '3D_shapes_in_real_life_objects/Sphere_objects/Bubble'
  | '3D_shapes_in_real_life_objects/Sphere_objects/Coloured_marble'
  | '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Communication_tower'
  | '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Egyptian_pyramid'
  | '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Louvre_pyramid'
  | '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Mayan_pyramid'
  | '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Safety_sign'
  | '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Tower'
  | '3D_shapes_in_real_life_objects/Triangular_prism_objects/Cheese'
  | '3D_shapes_in_real_life_objects/Triangular_prism_objects/Chocolate_triangular_prism'
  | '3D_shapes_in_real_life_objects/Triangular_prism_objects/Tent'
  | '3D_shapes_in_real_life_objects/Triangular_prism_objects/Wood_triangular_prism'
  | '3D_shapes_straws_marshmallows/Cuboid_shapes_straws_marshmallows'
  | '3D_shapes_straws_marshmallows/Hexagonal_prism_straws_marshmallows'
  | '3D_shapes_straws_marshmallows/Pentagonal_prism_straws_marshmallows'
  | '3D_shapes_straws_marshmallows/Square_based_pyramid_straws_marshmallows'
  | '3D_shapes_straws_marshmallows/Tetrahedron_shapes_straws_marshmallows'
  | '3D_shapes_straws_marshmallows/Triangular_prism_straws_marshmallows'
  | '3D_shapes_straws_marshmallows/cube_straws_marshmallows'
  | 'Alex_birds_eye'
  | 'Angles_in_a_triangle_multiple_lines/Isosceles_triangle1'
  | 'Angles_in_a_triangle_multiple_lines/Isosceles_triangle2'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle1'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle2'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle3'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle4'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle1'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle2'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle3'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle4'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle5'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle6'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle7'
  | 'Angles_in_a_triangle_multiple_lines/Scalene_triangle8'
  | 'Angles_on_a_straight_line/2_angles_1_line1'
  | 'Angles_on_a_straight_line/2_angles_1_line2'
  | 'Angles_on_a_straight_line/2_angles_1_line3'
  | 'Angles_on_a_straight_line/2_angles_1_line4'
  | 'Annie_birds_eye'
  | 'Ant'
  | 'Apple_juice_carton'
  | 'Array_objects/AppleGreen'
  | 'Array_objects/AppleRed'
  | 'Array_objects/Banana'
  | 'Array_objects/Orange'
  | 'Array_objects/Pear'
  | 'Array_objects/Pineapple'
  | 'Array_objects/Strawberry'
  | 'ArrowDown'
  | 'ArrowDownLong'
  | 'ArrowDownLongPDF'
  | 'ArrowRight'
  | 'ArrowRightLong'
  | 'ArrowUp'
  | 'ArrowUpShort'
  | 'Arrows/arrow'
  | 'Arrows/arrow_blue'
  | 'Arrows/arrow_green'
  | 'Arrows/arrow_pink'
  | 'Arrows/arrow_purple'
  | 'Arrows/arrow_white'
  | 'Arrows/arrow_yellow'
  | 'Backspace'
  | 'Bag_of_Flour'
  | 'Bag_of_Pasta'
  | 'Bag_of_Rice'
  | 'Bag_of_carrots'
  | 'Bag_of_chocolate_chips'
  | 'Bag_of_potatoes'
  | 'Bag_of_white_sugar'
  | 'BalloonBlue'
  | 'Bar_of_chocolate'
  | 'Base_Ten/1000cube'
  | 'Base_Ten/100cube'
  | 'Base_Ten/10BluePens'
  | 'Base_Ten/10RedPens'
  | 'Base_Ten/10cube'
  | 'Base_Ten/1BluePen'
  | 'Base_Ten/1RedPen'
  | 'Base_Ten/1cube'
  | 'Base_Ten/Balloons1'
  | 'Base_Ten/Balloons10'
  | 'Base_Ten/Balloons100'
  | 'Base_Ten/Bricks1'
  | 'Base_Ten/Bricks10'
  | 'Base_Ten/Bricks100'
  | 'Base_Ten/Crayons10'
  | 'Base_Ten/Crayons100'
  | 'Base_Ten/Crayons1_blue'
  | 'Base_Ten/Crayons1_green'
  | 'Base_Ten/Crayons1_lightblue'
  | 'Base_Ten/Crayons1_lime'
  | 'Base_Ten/Crayons1_orange'
  | 'Base_Ten/Crayons1_pink'
  | 'Base_Ten/Crayons1_purple'
  | 'Base_Ten/Crayons1_red'
  | 'Base_Ten/Crayons1_white'
  | 'Base_Ten/Crayons1_yellow'
  | 'Base_Ten/Lollipops1'
  | 'Base_Ten/Lollipops10'
  | 'Base_Ten/Lollipops100'
  | 'Base_Ten/Marbles1'
  | 'Base_Ten/Marbles10'
  | 'Base_Ten/Marbles100'
  | 'Base_Ten/Nails1'
  | 'Base_Ten/Nails10'
  | 'Base_Ten/Nails100'
  | 'Base_Ten/Nails1000'
  | 'Base_Ten/Straws1'
  | 'Base_Ten/Straws10'
  | 'Base_Ten/Sweets1'
  | 'Base_Ten/Sweets10'
  | 'Base_Ten/Sweets100'
  | 'Basketball'
  | 'Bath'
  | 'Bee'
  | 'Bee_2'
  | 'Bee_from_above'
  | 'Before_and_after_images/Balloon_blue'
  | 'Before_and_after_images/Balloon_blue_popped'
  | 'Before_and_after_images/Balloon_blue_uninflated'
  | 'Before_and_after_images/Banana_bite'
  | 'Before_and_after_images/Banana_peeled'
  | 'Before_and_after_images/Banana_single'
  | 'Before_and_after_images/Bar_of_chocolate_bite'
  | 'Before_and_after_images/Bar_of_chocolate_unwrapped'
  | 'Before_and_after_images/Bar_of_chocolate_wr_noto'
  | 'Before_and_after_images/Cookie_biscuit_bite'
  | 'Before_and_after_images/Cookie_biscuit_crumbs'
  | 'Before_and_after_images/Cookie_biscuit_no_bite'
  | 'Before_and_after_images/Donut_bite'
  | 'Before_and_after_images/Donut_crumbs'
  | 'Before_and_after_images/Donut_no_bite'
  | 'Before_and_after_images/Not_wrapped_gift'
  | 'Before_and_after_images/Paper_blank'
  | 'Before_and_after_images/Paper_drawing_house_colour'
  | 'Before_and_after_images/Paper_drawing_house_line'
  | 'Before_and_after_images/Plant_pot_soil'
  | 'Before_and_after_images/Plant_pot_sunflower'
  | 'Before_and_after_images/Sunflower_seed'
  | 'Before_and_after_images/Wrapped_gift'
  | 'Before_and_after_images/Wrapped_gift_without_bow'
  | 'Bike'
  | 'Bird_yellow'
  | 'Bone'
  | 'Book'
  | 'BookRed'
  | 'Book_Red_set_down'
  | 'Bouquet'
  | 'Bread_loaf'
  | 'Bread_roll'
  | 'Bread_rolls_10'
  | 'Broken_shapes/arrow_break_1'
  | 'Broken_shapes/arrow_break_2'
  | 'Broken_shapes/circle_break_1'
  | 'Broken_shapes/circle_break_2'
  | 'Broken_shapes/circle_semi_break_1'
  | 'Broken_shapes/circle_semi_break_2'
  | 'Broken_shapes/cross_break_1'
  | 'Broken_shapes/cross_break_2'
  | 'Broken_shapes/hexagon_break_1'
  | 'Broken_shapes/hexagon_break_2'
  | 'Broken_shapes/kite_break_1'
  | 'Broken_shapes/kite_break_2'
  | 'Broken_shapes/parallelogram_break_1'
  | 'Broken_shapes/parallelogram_break_2'
  | 'Broken_shapes/pentagon_break_1'
  | 'Broken_shapes/pentagon_break_2'
  | 'Broken_shapes/pentagon_house_break_1'
  | 'Broken_shapes/pentagon_house_break_2'
  | 'Broken_shapes/rectangle_break_1'
  | 'Broken_shapes/rectangle_break_2'
  | 'Broken_shapes/rhombus_break_1'
  | 'Broken_shapes/rhombus_break_2'
  | 'Broken_shapes/square_break_1'
  | 'Broken_shapes/square_break_2'
  | 'Broken_shapes/trapezium_RA_break_1'
  | 'Broken_shapes/trapezium_RA_break_2'
  | 'Broken_shapes/trapezium_isosceles_break_1'
  | 'Broken_shapes/trapezium_isosceles_break_2'
  | 'Broken_shapes/triangle_RA_break_1'
  | 'Broken_shapes/triangle_RA_break_2'
  | 'Broken_shapes/triangle_break_1'
  | 'Broken_shapes/triangle_break_2'
  | 'Broken_shapes/triangle_isos_narrow_break_1'
  | 'Broken_shapes/triangle_isos_narrow_break_2'
  | 'Broken_shapes/triangle_isos_wide_break_1'
  | 'Broken_shapes/triangle_isos_wide_break_2'
  | 'Broken_shapes/triangle_scalene_break_1'
  | 'Broken_shapes/triangle_scalene_break_2'
  | 'Brown_bag_of_apples'
  | 'Bubble'
  | 'Bunches_of_bananas/Bunch_of_bananas_1'
  | 'Bunches_of_bananas/Bunch_of_bananas_2'
  | 'Bunches_of_bananas/Bunch_of_bananas_3'
  | 'Bunches_of_bananas/Bunch_of_bananas_4'
  | 'Bunches_of_bananas/Bunch_of_bananas_5'
  | 'Bunches_of_bananas/Bunch_of_bananas_6'
  | 'Bundle_of_10_straws'
  | 'Bus'
  | 'Button_blue'
  | 'Button_yellow'
  | 'Cake'
  | 'Can_of_fizzy_drink'
  | 'Candle'
  | 'Cans_unlabelled_Green'
  | 'Capacity_images/Beaker_1000mlcap_100mlint'
  | 'Capacity_images/Beaker_1000mlcap_200mlint'
  | 'Capacity_images/Beaker_1000mlcap_250mlint'
  | 'Capacity_images/Beaker_1000mlcap_500mlint'
  | 'Capacity_images/Beaker_1000mlcap_50mlint'
  | 'Capacity_images/Beaker_100mlcap_10mlint'
  | 'Capacity_images/Beaker_100mlcap_20mlint'
  | 'Capacity_images/Beaker_100mlcap_25mlint'
  | 'Capacity_images/Beaker_100mlcap_50mlint'
  | 'Capacity_images/Beaker_200mlcap_100mlint'
  | 'Capacity_images/Beaker_200mlcap_10mlint'
  | 'Capacity_images/Beaker_200mlcap_20mlint'
  | 'Capacity_images/Beaker_200mlcap_25mlint'
  | 'Capacity_images/Beaker_200mlcap_50mlint'
  | 'Capacity_images/Beaker_250mlcap_125mlint'
  | 'Capacity_images/Beaker_250mlcap_25mlint'
  | 'Capacity_images/Beaker_250mlcap_50mlint'
  | 'Capacity_images/Beaker_300mlcap_100mlint'
  | 'Capacity_images/Beaker_300mlcap_20mlint'
  | 'Capacity_images/Beaker_300mlcap_25mlint'
  | 'Capacity_images/Beaker_300mlcap_50mlint'
  | 'Capacity_images/Beaker_400mlcap_100mlint'
  | 'Capacity_images/Beaker_400mlcap_200mlint'
  | 'Capacity_images/Beaker_400mlcap_20mlint'
  | 'Capacity_images/Beaker_400mlcap_25mlint'
  | 'Capacity_images/Beaker_400mlcap_50mlint'
  | 'Capacity_images/Beaker_500mlcap_100mlint'
  | 'Capacity_images/Beaker_500mlcap_250mlint'
  | 'Capacity_images/Beaker_500mlcap_25mlint'
  | 'Capacity_images/Beaker_500mlcap_50mlint'
  | 'Capacity_images/Beaker_600mlcap_100mlint'
  | 'Capacity_images/Beaker_600mlcap_200mlint'
  | 'Capacity_images/Beaker_600mlcap_50mlint'
  | 'Capacity_images/Beaker_700mlcap_100mlint'
  | 'Capacity_images/Beaker_700mlcap_50mlint'
  | 'Capacity_images/Beaker_800mlcap_100mlint'
  | 'Capacity_images/Beaker_800mlcap_200mlint'
  | 'Capacity_images/Beaker_800mlcap_50mlint'
  | 'Capacity_images/Beaker_900mlcap_100mlint'
  | 'Capacity_images/Beaker_900mlcap_50mlint'
  | 'Capacity_images/Empty_jug'
  | 'Capacity_images/Empty_jug_blue'
  | 'Capacity_images/Extra_large_juice_bottle'
  | 'Capacity_images/Full_beaker_100mlcap_10mlint'
  | 'Capacity_images/Full_beaker_1litrecap_100mlint'
  | 'Capacity_images/Large_juice_bottle'
  | 'Capacity_images/Medium_juice_bottle'
  | 'Capacity_images/Mostly_full_orange_pitcher'
  | 'Capacity_images/Mostly_full_water_pitcher'
  | 'Capacity_images/None_Transparent/Jug1_1000mlcap_100mlint'
  | 'Capacity_images/None_Transparent/Jug1_1000mlcap_200mlint'
  | 'Capacity_images/None_Transparent/Jug1_1000mlcap_250mlint'
  | 'Capacity_images/None_Transparent/Jug1_1000mlcap_500mlint'
  | 'Capacity_images/None_Transparent/Jug1_200mlcap_100mlint'
  | 'Capacity_images/None_Transparent/Jug1_200mlcap_20mlint'
  | 'Capacity_images/None_Transparent/Jug1_200mlcap_25mlint'
  | 'Capacity_images/None_Transparent/Jug1_200mlcap_50mlint'
  | 'Capacity_images/None_Transparent/Jug1_500mlcap_100mlint'
  | 'Capacity_images/None_Transparent/Jug1_500mlcap_250mlint'
  | 'Capacity_images/None_Transparent/Jug1_500mlcap_50mlint'
  | 'Capacity_images/Part_filled_beaker_1litrecap_100mlint'
  | 'Capacity_images/Small_juice_bottle'
  | 'Car'
  | 'Car_tyre'
  | 'Card_pack'
  | 'CardboardBox'
  | 'CardboardBox1'
  | 'CardboardBox2'
  | 'CardboardBox3'
  | 'CardboardBox4'
  | 'Carrot'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_0'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_1'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_10'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_11'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_12'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_2'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_3'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_4'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_5'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_6'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_7'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_8'
  | 'Carton_of_eggs_0_-12/Carton_of_eggs_9'
  | 'Cat'
  | 'Cat_w_food_bowl'
  | 'Celebration_card'
  | 'Celebration_card_1'
  | 'Celebration_card_2'
  | 'Cell'
  | 'CellTop'
  | 'ChairBlue'
  | 'ChairPink'
  | 'Characters/AlexHead'
  | 'Characters/AlexStanding'
  | 'Characters/AmirHead'
  | 'Characters/AmirStanding'
  | 'Characters/AnnieHead'
  | 'Characters/AnnieStanding'
  | 'Characters/DexterHead'
  | 'Characters/DexterStanding'
  | 'Characters/Direction/Jack_body'
  | 'Characters/Direction/Kim_body'
  | 'Characters/Direction/Max_body'
  | 'Characters/EvaHead'
  | 'Characters/EvaStanding'
  | 'Characters/JackHead'
  | 'Characters/JackStanding'
  | 'Characters/JoHead'
  | 'Characters/JoStanding'
  | 'Characters/KimHead'
  | 'Characters/KimStanding'
  | 'Characters/MaxHead'
  | 'Characters/MaxStanding'
  | 'Characters/MoHead'
  | 'Characters/MoStanding'
  | 'Characters/RonHead'
  | 'Characters/RonStanding'
  | 'Characters/RosieHead'
  | 'Characters/RosieStanding'
  | 'Characters/SamHead'
  | 'Characters/SamStanding'
  | 'Characters/TeddyHead'
  | 'Characters/TeddyStanding'
  | 'Characters/TommyHead'
  | 'Characters/TommyStanding'
  | 'Characters/WhitneyHead'
  | 'Characters/WhitneyStanding'
  | 'CheckersBoard'
  | 'Cherry'
  | 'Chew_sweet_candy_bar'
  | 'Circles/Circle_quarter_white'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_large_A'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_large_B'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_large_C'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_medium_A'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_medium_B'
  | 'Circles/Inner_outer_circles/Inner_outer_circle_medium_C'
  | 'Circles/Parts_of_a_circle/Circle_parts_centre_arrow'
  | 'Circles/Parts_of_a_circle/Circle_parts_circumference_arrow'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_arrow'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_correct'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_A'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_B'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_C'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_D'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_E'
  | 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_F'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_arrow'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_correct'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_A'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_B'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_C'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_D'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_E'
  | 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_F'
  | 'Circles/circle_'
  | 'Circles/circle_black'
  | 'Circles/circle_blue'
  | 'Circles/circle_green'
  | 'Circles/circle_grey'
  | 'Circles/circle_orange'
  | 'Circles/circle_orange_protractor'
  | 'Circles/circle_pink'
  | 'Circles/circle_purple'
  | 'Circles/circle_red'
  | 'Circles/circle_turquoise'
  | 'Circles/circle_white'
  | 'Circles/circle_yellow'
  | 'Clock'
  | 'ClockFaceBlue'
  | 'ClockFaceBlueWithoutMinuteIntervals'
  | 'ClockFaceOrange'
  | 'ClockFaceOrangeWithoutMinuteIntervals'
  | 'ClockFaceRomanBlue'
  | 'ClockFaceRomanBlueWithoutMinuteIntervals'
  | 'ClockFaceRomanOrange'
  | 'ClockFaceRomanOrangeWithoutMinuteIntervals'
  | 'ClockFaceRomanYellow'
  | 'ClockFaceRomanYellowWithoutMinuteIntervals'
  | 'ClockFaceYellow'
  | 'ClockFaceYellowWithoutMinuteIntervals'
  | 'ClockHour'
  | 'ClockMinute'
  | 'ClockMinuteCircle'
  | 'Close'
  | 'Closed_book'
  | 'Clothes/Gloves_mittens_blue'
  | 'Clothes/Gloves_mittens_green'
  | 'Clothes/Gloves_mittens_orange'
  | 'Clothes/Gloves_mittens_red'
  | 'Clothes/Gloves_mittens_yellow.ai'
  | 'Clothes/Hat_blue'
  | 'Clothes/Hat_cap'
  | 'Clothes/Hat_woolly'
  | 'Clothes/Jumper_blue'
  | 'Clothes/Scarf_purple'
  | 'Clothes/Scarf_white'
  | 'Clothes/Shorts_grey'
  | 'Clothes/T-shirt_blue'
  | 'Compass_4_labels'
  | 'Compass_8_labels'
  | 'Compost_bag'
  | 'CompoundShapes/EquilateralTriangle_2_parts_1'
  | 'CompoundShapes/EquilateralTriangle_3_parts_1'
  | 'CompoundShapes/EquilateralTriangle_4_parts_1'
  | 'CompoundShapes/EquilateralTriangle_4_parts_2'
  | 'CompoundShapes/EquilateralTriangle_5_parts_1'
  | 'CompoundShapes/QuarterCircle_2_parts_1'
  | 'CompoundShapes/QuarterCircle_3_parts_1'
  | 'CompoundShapes/QuarterCircle_4_parts_1'
  | 'CompoundShapes/QuarterCircle_5_parts_1'
  | 'CompoundShapes/RATriangle_2_parts_1'
  | 'CompoundShapes/RATriangle_3_parts_1'
  | 'CompoundShapes/RATriangle_4_parts_1'
  | 'CompoundShapes/RATriangle_4_parts_2'
  | 'CompoundShapes/RATriangle_4_parts_3'
  | 'CompoundShapes/RATriangle_5_parts_1'
  | 'CompoundShapes/Rectangle_2_parts_1'
  | 'CompoundShapes/Rectangle_3_parts_1'
  | 'CompoundShapes/Rectangle_4_parts_1'
  | 'CompoundShapes/Rectangle_4_parts_2'
  | 'CompoundShapes/Rectangle_5_parts_1'
  | 'CompoundShapes/Square_2_parts_1'
  | 'CompoundShapes/Square_3_parts_1'
  | 'CompoundShapes/Square_3_parts_2'
  | 'CompoundShapes/Square_4_parts_1'
  | 'CompoundShapes/Square_4_parts_2'
  | 'CompoundShapes/Square_5_parts_1'
  | 'CompoundShapes/Square_5_parts_2'
  | 'Cone_dotted_lines'
  | 'Containers/container'
  | 'Cookie'
  | 'Cookie_biscuit/Cookie_biscuit_5'
  | 'Cookie_biscuit/Cookie_biscuit_6'
  | 'Cookie_biscuit/Cookie_biscuit_7'
  | 'Cookie_jar'
  | 'Cooking_pot'
  | 'Coordinates/CirclePoint'
  | 'Coordinates/CrossPoint'
  | 'Coordinates/FilledSquarePoint'
  | 'Coordinates/SquarePoint'
  | 'Coordinates/TrianglePoint'
  | 'CountIn50/Balloons50'
  | 'CountIn50/Bricks50'
  | 'CountIn50/Crayons50'
  | 'CountIn50/Lollipops50'
  | 'CountIn50/Marbles50'
  | 'CountIn50/Sweets50'
  | 'Counter'
  | 'CounterRed'
  | 'CounterWhite'
  | 'Counters_fractions/Counters_unit_fractions1_10'
  | 'Counters_fractions/Counters_unit_fractions1_11'
  | 'Counters_fractions/Counters_unit_fractions1_12'
  | 'Counters_fractions/Counters_unit_fractions1_2'
  | 'Counters_fractions/Counters_unit_fractions1_3'
  | 'Counters_fractions/Counters_unit_fractions1_4'
  | 'Counters_fractions/Counters_unit_fractions1_5'
  | 'Counters_fractions/Counters_unit_fractions1_6'
  | 'Counters_fractions/Counters_unit_fractions1_7'
  | 'Counters_fractions/Counters_unit_fractions1_8'
  | 'Counters_fractions/Counters_unit_fractions1_9'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_10_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_11_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_12_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_2_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_3_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_4_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_5_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_6.ai_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_7_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_8_text_outlined'
  | 'Counters_fractions/Text_outlined/Counters_unit_fractions1_9_text_outlined'
  | 'Cow'
  | 'CrayonBox'
  | 'Crayons_box_pack_of_8'
  | 'Crosses/cross'
  | 'Crosses/cross_blue'
  | 'Crosses/cross_green'
  | 'Crosses/cross_orange'
  | 'Crosses/cross_pink'
  | 'Crosses/cross_purple'
  | 'Crosses/cross_red'
  | 'Crosses/cross_turquoise'
  | 'Crosses/cross_white'
  | 'Crosses/cross_yellow'
  | 'Cubes_With_1_Arrow/cube1_arrow'
  | 'Cubes_With_1_Arrow/cube2_arrow'
  | 'Cubes_With_1_Arrow/cube3_arrow'
  | 'Cubes_With_3_Arrows/Cube_3_arrows_blue'
  | 'Cubes_With_3_Arrows/Cube_3_arrows_green'
  | 'Cubes_With_3_Arrows/Cube_3_arrows_red'
  | 'Cubes_With_3_Arrows/Cube_3_arrows_white'
  | 'Cubes_With_3_Arrows/Cube_3_arrows_yellow'
  | 'Cubes_blank/Coloured_cube_unlabelled_blue'
  | 'Cubes_blank/Coloured_cube_unlabelled_green'
  | 'Cubes_blank/Coloured_cube_unlabelled_orange'
  | 'Cubes_blank/Coloured_cube_unlabelled_purple'
  | 'Cubes_blank/Coloured_cube_unlabelled_red'
  | 'Cubes_blank/Coloured_cube_unlabelled_yellow'
  | 'Cubes_labelled/Coloured_cube_labelled_blue'
  | 'Cubes_labelled/Coloured_cube_labelled_green'
  | 'Cubes_labelled/Coloured_cube_labelled_orange'
  | 'Cubes_labelled/Coloured_cube_labelled_purple'
  | 'Cubes_labelled/Coloured_cube_labelled_red'
  | 'Cubes_labelled/Coloured_cube_labelled_yellow'
  | 'Cuboids/Cuboid1'
  | 'Cuboids/Cuboid2'
  | 'Cuboids/Cuboid3'
  | 'Cuboids_With_3_Arrows/cuboid1_3_arrows'
  | 'Cuboids_With_3_Arrows/cuboid2_3_arrows'
  | 'Cuboids_With_3_Arrows/cuboid3_3_arrows'
  | 'Cuboids_With_3_Arrows/cuboid4_3_arrows'
  | 'Cuboids_With_3_Arrows/cuboid5_3_arrows'
  | 'Cuboids_With_3_Arrows/cuboid6_3_arrows'
  | 'Desk'
  | 'Dice'
  | 'Dice_faces/1'
  | 'Dice_faces/2'
  | 'Dice_faces/3'
  | 'Dice_faces/4'
  | 'Dice_faces/5'
  | 'Dice_faces/6'
  | 'DigitalStopwatch'
  | 'Dodecagons/Irregular_dodecagon1_blue'
  | 'Dodecagons/Irregular_dodecagon1_green'
  | 'Dodecagons/Irregular_dodecagon1_pink'
  | 'Dodecagons/Irregular_dodecagon1_purple'
  | 'Dodecagons/Irregular_dodecagon1_white'
  | 'Dodecagons/Irregular_dodecagon1_yellow'
  | 'Dodecagons/Irregular_dodecagon2_blue'
  | 'Dodecagons/Irregular_dodecagon2_green'
  | 'Dodecagons/Irregular_dodecagon2_pink'
  | 'Dodecagons/Irregular_dodecagon2_purple'
  | 'Dodecagons/Irregular_dodecagon2_white'
  | 'Dodecagons/Irregular_dodecagon2_yellow'
  | 'Dogs/dog1'
  | 'Dogs/dog2'
  | 'Dogs/dog3'
  | 'Donuts_in_a_box/Donuts_in_a_box_0'
  | 'Donuts_in_a_box/Donuts_in_a_box_1'
  | 'Donuts_in_a_box/Donuts_in_a_box_10'
  | 'Donuts_in_a_box/Donuts_in_a_box_11'
  | 'Donuts_in_a_box/Donuts_in_a_box_12'
  | 'Donuts_in_a_box/Donuts_in_a_box_2'
  | 'Donuts_in_a_box/Donuts_in_a_box_3'
  | 'Donuts_in_a_box/Donuts_in_a_box_4'
  | 'Donuts_in_a_box/Donuts_in_a_box_5'
  | 'Donuts_in_a_box/Donuts_in_a_box_6'
  | 'Donuts_in_a_box/Donuts_in_a_box_7'
  | 'Donuts_in_a_box/Donuts_in_a_box_8'
  | 'Donuts_in_a_box/Donuts_in_a_box_9'
  | 'Donuts_individual/Donut_1'
  | 'Donuts_individual/Donut_2'
  | 'Donuts_individual/Donut_3'
  | 'Donuts_individual/Donut_4'
  | 'Donuts_individual/Donut_5'
  | 'Donuts_individual/Donut_6'
  | 'Door'
  | 'Dumbbell_weights'
  | 'Elephant'
  | 'Ellipses/Ellipse_blue'
  | 'Ellipses/Ellipse_green'
  | 'Ellipses/Ellipse_orange'
  | 'Ellipses/Ellipse_pink'
  | 'Ellipses/Ellipse_purple'
  | 'Ellipses/Ellipse_red'
  | 'Ellipses/Ellipse_turquoise'
  | 'Ellipses/Ellipse_yellow'
  | 'Empty_bucket'
  | 'Empty_bucket_blue'
  | 'Empty_bucket_rectangular'
  | 'Empty_bucket_rectangular_blue'
  | 'Empty_bucket_transparent'
  | 'Empty_jug'
  | 'Equal_groups/Apples0'
  | 'Equal_groups/Apples1'
  | 'Equal_groups/Apples2'
  | 'Equal_groups/Apples3'
  | 'Equal_groups/Apples4'
  | 'Equal_groups/Apples5'
  | 'Equal_groups/Apples6'
  | 'Equal_groups/Cookies0'
  | 'Equal_groups/Cookies1'
  | 'Equal_groups/Cookies2'
  | 'Equal_groups/Cookies3'
  | 'Equal_groups/Cookies4'
  | 'Equal_groups/Cookies5'
  | 'Equal_groups/Cookies6'
  | 'Equal_groups/Eggs0'
  | 'Equal_groups/Eggs1'
  | 'Equal_groups/Eggs2'
  | 'Equal_groups/Eggs3'
  | 'Equal_groups/Eggs4'
  | 'Equal_groups/Eggs5'
  | 'Equal_groups/Eggs6'
  | 'Equal_groups/Flowers0'
  | 'Equal_groups/Flowers1'
  | 'Equal_groups/Flowers2'
  | 'Equal_groups/Flowers3'
  | 'Equal_groups/Flowers4'
  | 'Equal_groups/Flowers5'
  | 'Equal_groups/Flowers6'
  | 'Equal_groups/Pencils0'
  | 'Equal_groups/Pencils1'
  | 'Equal_groups/Pencils2'
  | 'Equal_groups/Pencils3'
  | 'Equal_groups/Pencils4'
  | 'Equal_groups/Pencils5'
  | 'Equal_groups/Pencils6'
  | 'Equal_groups/Pizza1'
  | 'Equal_groups/Pizza2'
  | 'Equal_groups/Pizza3'
  | 'Equal_groups/Pizza4'
  | 'Equal_groups/Pizza5'
  | 'Equal_groups/Pizza6'
  | 'Equal_shapes_10_parts/Circle_equal_10-10'
  | 'Equal_shapes_10_parts/Pentagon_10-10'
  | 'Equal_shapes_10_parts/Square_equal_10-10'
  | 'Equal_shapes_11_parts/Circle_equal_11-11'
  | 'Equal_shapes_11_parts/Rectangle_equal_11-11'
  | 'Equal_shapes_11_parts/Square_equal_11-11'
  | 'Equal_shapes_12_parts/Hexagon_12-12'
  | 'Equal_shapes_12_parts/Rectangle_equal_12-12'
  | 'Equal_shapes_12_parts/Square_equal_12-12'
  | 'Equal_shapes_2_parts/Circle_equal_2-0_1'
  | 'Equal_shapes_2_parts/Circle_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Circle_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Circle_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Circle_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Circle_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Circle_equal_2-2_1_yellow'
  | 'Equal_shapes_2_parts/Hexagon_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Hexagon_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Hexagon_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Hexagon_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Hexagon_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Hexagon_equal_2-2_1_yellow'
  | 'Equal_shapes_2_parts/Kite_equal_1-2'
  | 'Equal_shapes_2_parts/Kite_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Kite_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Kite_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Kite_equal_2-2'
  | 'Equal_shapes_2_parts/Kite_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Kite_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Kite_equal_2-2_1_yellow'
  | 'Equal_shapes_2_parts/Octagon_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Octagon_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Octagon_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Octagon_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Octagon_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Octagon_equal_2-2_1_yellow'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-0_2'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_2_blue'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_2_green'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_2_yellow'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_3_blue'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_3_green'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-1_3_yellow'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_1_yellow'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_2_blue'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_2_green'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_2_yellow'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_3_blue'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_3_green'
  | 'Equal_shapes_2_parts/Rectangle_equal_2-2_3_yellow'
  | 'Equal_shapes_2_parts/Square_equal_1-2'
  | 'Equal_shapes_2_parts/Square_equal_2-0_1'
  | 'Equal_shapes_2_parts/Square_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Square_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Square_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Square_equal_2-1_2_blue'
  | 'Equal_shapes_2_parts/Square_equal_2-1_2_green'
  | 'Equal_shapes_2_parts/Square_equal_2-1_2_yellow'
  | 'Equal_shapes_2_parts/Square_equal_2-2'
  | 'Equal_shapes_2_parts/Square_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Square_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Square_equal_2-2_1_yellow'
  | 'Equal_shapes_2_parts/Square_equal_2-2_2_blue'
  | 'Equal_shapes_2_parts/Square_equal_2-2_2_green'
  | 'Equal_shapes_2_parts/Square_equal_2-2_2_yellow'
  | 'Equal_shapes_2_parts/Triangle_equal_1-2'
  | 'Equal_shapes_2_parts/Triangle_equal_2-0_1'
  | 'Equal_shapes_2_parts/Triangle_equal_2-1_1_blue'
  | 'Equal_shapes_2_parts/Triangle_equal_2-1_1_green'
  | 'Equal_shapes_2_parts/Triangle_equal_2-1_1_yellow'
  | 'Equal_shapes_2_parts/Triangle_equal_2-2'
  | 'Equal_shapes_2_parts/Triangle_equal_2-2_1_blue'
  | 'Equal_shapes_2_parts/Triangle_equal_2-2_1_green'
  | 'Equal_shapes_2_parts/Triangle_equal_2-2_1_yellow'
  | 'Equal_shapes_3_parts/Arrow_equal_3-1_1_blue'
  | 'Equal_shapes_3_parts/Arrow_equal_3-1_1_green'
  | 'Equal_shapes_3_parts/Arrow_equal_3-1_1_yellow'
  | 'Equal_shapes_3_parts/Circle_equal_3-0_1'
  | 'Equal_shapes_3_parts/Circle_equal_3-1_1_blue'
  | 'Equal_shapes_3_parts/Circle_equal_3-1_1_green'
  | 'Equal_shapes_3_parts/Circle_equal_3-1_1_yellow'
  | 'Equal_shapes_3_parts/Circle_equal_3-2_1_blue'
  | 'Equal_shapes_3_parts/Circle_equal_3-2_1_green'
  | 'Equal_shapes_3_parts/Circle_equal_3-2_1_yellow'
  | 'Equal_shapes_3_parts/Circle_equal_3-3'
  | 'Equal_shapes_3_parts/Circle_equal_3-3_1_blue'
  | 'Equal_shapes_3_parts/Circle_equal_3-3_1_green'
  | 'Equal_shapes_3_parts/Circle_equal_3-3_1_yellow'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-0_1'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-1_1_blue'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-1_1_green'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-1_1_yellow'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-2_1_blue'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-2_1_green'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-2_1_yellow'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-3_1_blue'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-3_1_green'
  | 'Equal_shapes_3_parts/Rectangle_equal_3-3_1_yellow'
  | 'Equal_shapes_3_parts/Square_equal_1-3'
  | 'Equal_shapes_3_parts/Square_equal_3-0_1'
  | 'Equal_shapes_3_parts/Square_equal_3-1_1_blue'
  | 'Equal_shapes_3_parts/Square_equal_3-1_1_green'
  | 'Equal_shapes_3_parts/Square_equal_3-1_1_yellow'
  | 'Equal_shapes_3_parts/Square_equal_3-2_1_blue'
  | 'Equal_shapes_3_parts/Square_equal_3-2_1_green'
  | 'Equal_shapes_3_parts/Square_equal_3-2_1_yellow'
  | 'Equal_shapes_3_parts/Square_equal_3-3'
  | 'Equal_shapes_3_parts/Square_equal_3-3_1_blue'
  | 'Equal_shapes_3_parts/Square_equal_3-3_1_green'
  | 'Equal_shapes_3_parts/Square_equal_3-3_1_yellow'
  | 'Equal_shapes_3_parts/Triangle_equal_1-3'
  | 'Equal_shapes_3_parts/Triangle_equal_3-0_1'
  | 'Equal_shapes_3_parts/Triangle_equal_3-1_1_blue'
  | 'Equal_shapes_3_parts/Triangle_equal_3-1_1_green'
  | 'Equal_shapes_3_parts/Triangle_equal_3-1_1_yellow'
  | 'Equal_shapes_3_parts/Triangle_equal_3-2_1_blue'
  | 'Equal_shapes_3_parts/Triangle_equal_3-2_1_green'
  | 'Equal_shapes_3_parts/Triangle_equal_3-2_1_yellow'
  | 'Equal_shapes_3_parts/Triangle_equal_3-3'
  | 'Equal_shapes_3_parts/Triangle_equal_3-3_1_blue'
  | 'Equal_shapes_3_parts/Triangle_equal_3-3_1_green'
  | 'Equal_shapes_3_parts/Triangle_equal_3-3_1_yellow'
  | 'Equal_shapes_4_parts/Circle_equal_4-0_1'
  | 'Equal_shapes_4_parts/Circle_equal_4-1_1_blue'
  | 'Equal_shapes_4_parts/Circle_equal_4-1_1_green'
  | 'Equal_shapes_4_parts/Circle_equal_4-1_1_yellow'
  | 'Equal_shapes_4_parts/Circle_equal_4-2_1_blue'
  | 'Equal_shapes_4_parts/Circle_equal_4-2_1_green'
  | 'Equal_shapes_4_parts/Circle_equal_4-2_1_yellow'
  | 'Equal_shapes_4_parts/Circle_equal_4-3_1_blue'
  | 'Equal_shapes_4_parts/Circle_equal_4-3_1_green'
  | 'Equal_shapes_4_parts/Circle_equal_4-3_1_yellow'
  | 'Equal_shapes_4_parts/Circle_equal_4-4'
  | 'Equal_shapes_4_parts/Circle_equal_4-4_1_blue'
  | 'Equal_shapes_4_parts/Circle_equal_4-4_1_green'
  | 'Equal_shapes_4_parts/Circle_equal_4-4_1_yellow'
  | 'Equal_shapes_4_parts/Cross_equal_1-4'
  | 'Equal_shapes_4_parts/Cross_equal_3-4'
  | 'Equal_shapes_4_parts/Cross_equal_4-4'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-1_1_blue'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-1_1_green'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-1_1_yellow'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-2_1_blue'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-2_1_green'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-2_1_yellow'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-3_1_blue'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-3_1_green'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-3_1_yellow'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-4_1_blue'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-4_1_green'
  | 'Equal_shapes_4_parts/Hexagon_equal_4-4_1_yellow'
  | 'Equal_shapes_4_parts/Octagon_equal_4-1_1_blue'
  | 'Equal_shapes_4_parts/Octagon_equal_4-1_1_green'
  | 'Equal_shapes_4_parts/Octagon_equal_4-1_1_yellow'
  | 'Equal_shapes_4_parts/Octagon_equal_4-2_1_blue'
  | 'Equal_shapes_4_parts/Octagon_equal_4-2_1_green'
  | 'Equal_shapes_4_parts/Octagon_equal_4-2_1_yellow'
  | 'Equal_shapes_4_parts/Octagon_equal_4-3_1_blue'
  | 'Equal_shapes_4_parts/Octagon_equal_4-3_1_green'
  | 'Equal_shapes_4_parts/Octagon_equal_4-3_1_yellow'
  | 'Equal_shapes_4_parts/Octagon_equal_4-4_1_blue'
  | 'Equal_shapes_4_parts/Octagon_equal_4-4_1_green'
  | 'Equal_shapes_4_parts/Octagon_equal_4-4_1_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-0_2'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_1_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_1_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_1_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_2_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_2_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_2_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_3_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_3_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-1_3_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-2_1_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-2_1_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-2_1_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-2_2_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-2_2_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-2_2_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-3_1_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-3_1_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-3_1_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-3_2_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-3_2_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-3_2_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-4_1_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-4_1_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-4_1_yellow'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-4_2_blue'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-4_2_green'
  | 'Equal_shapes_4_parts/Rectangle_equal_4-4_2_yellow'
  | 'Equal_shapes_4_parts/Square_equal_1-4'
  | 'Equal_shapes_4_parts/Square_equal_4-0_1'
  | 'Equal_shapes_4_parts/Square_equal_4-1_1_blue'
  | 'Equal_shapes_4_parts/Square_equal_4-1_1_green'
  | 'Equal_shapes_4_parts/Square_equal_4-1_1_yellow'
  | 'Equal_shapes_4_parts/Square_equal_4-2_1_blue'
  | 'Equal_shapes_4_parts/Square_equal_4-2_1_green'
  | 'Equal_shapes_4_parts/Square_equal_4-2_1_yellow'
  | 'Equal_shapes_4_parts/Square_equal_4-3_1_blue'
  | 'Equal_shapes_4_parts/Square_equal_4-3_1_green'
  | 'Equal_shapes_4_parts/Square_equal_4-3_1_yellow'
  | 'Equal_shapes_4_parts/Square_equal_4-4'
  | 'Equal_shapes_4_parts/Square_equal_4-4_1_blue'
  | 'Equal_shapes_4_parts/Square_equal_4-4_1_green'
  | 'Equal_shapes_4_parts/Square_equal_4-4_1_yellow'
  | 'Equal_shapes_5_parts/Circle_equal_5-1_1_blue'
  | 'Equal_shapes_5_parts/Circle_equal_5-1_1_green'
  | 'Equal_shapes_5_parts/Circle_equal_5-1_1_yellow'
  | 'Equal_shapes_5_parts/Circle_equal_5-2_1_blue'
  | 'Equal_shapes_5_parts/Circle_equal_5-2_1_green'
  | 'Equal_shapes_5_parts/Circle_equal_5-2_1_yellow'
  | 'Equal_shapes_5_parts/Circle_equal_5-3_1_blue'
  | 'Equal_shapes_5_parts/Circle_equal_5-3_1_green'
  | 'Equal_shapes_5_parts/Circle_equal_5-3_1_yellow'
  | 'Equal_shapes_5_parts/Circle_equal_5-4_1_blue'
  | 'Equal_shapes_5_parts/Circle_equal_5-4_1_green'
  | 'Equal_shapes_5_parts/Circle_equal_5-4_1_yellow'
  | 'Equal_shapes_5_parts/Circle_equal_5-5'
  | 'Equal_shapes_5_parts/Circle_equal_5-5_1_blue'
  | 'Equal_shapes_5_parts/Circle_equal_5-5_1_green'
  | 'Equal_shapes_5_parts/Circle_equal_5-5_1_yellow'
  | 'Equal_shapes_5_parts/Pentagon_1-5'
  | 'Equal_shapes_5_parts/Pentagon_5-5'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-1_1_blue'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-1_1_green'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-1_1_yellow'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-2_1_blue'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-2_1_green'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-2_1_yellow'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-3_1_blue'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-3_1_green'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-3_1_yellow'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-4_1_blue'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-4_1_green'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-4_1_yellow'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-5_1_blue'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-5_1_green'
  | 'Equal_shapes_5_parts/Pentagon_equal_5-5_1_yellow'
  | 'Equal_shapes_5_parts/Square_equal_1-5'
  | 'Equal_shapes_5_parts/Square_equal_5-1_1_blue'
  | 'Equal_shapes_5_parts/Square_equal_5-1_1_green'
  | 'Equal_shapes_5_parts/Square_equal_5-1_1_yellow'
  | 'Equal_shapes_5_parts/Square_equal_5-2_1_blue'
  | 'Equal_shapes_5_parts/Square_equal_5-2_1_green'
  | 'Equal_shapes_5_parts/Square_equal_5-2_1_yellow'
  | 'Equal_shapes_5_parts/Square_equal_5-3_1_blue'
  | 'Equal_shapes_5_parts/Square_equal_5-3_1_green'
  | 'Equal_shapes_5_parts/Square_equal_5-3_1_yellow'
  | 'Equal_shapes_5_parts/Square_equal_5-4_1_blue'
  | 'Equal_shapes_5_parts/Square_equal_5-4_1_green'
  | 'Equal_shapes_5_parts/Square_equal_5-4_1_yellow'
  | 'Equal_shapes_5_parts/Square_equal_5-5'
  | 'Equal_shapes_5_parts/Square_equal_5-5_1_blue'
  | 'Equal_shapes_5_parts/Square_equal_5-5_1_green'
  | 'Equal_shapes_5_parts/Square_equal_5-5_1_yellow'
  | 'Equal_shapes_6_parts/Circle_equal_6-6'
  | 'Equal_shapes_6_parts/Hexagon_6-6'
  | 'Equal_shapes_6_parts/Square_equal_6-6'
  | 'Equal_shapes_7_parts/Circle_equal_7-7'
  | 'Equal_shapes_7_parts/Rectangle_equal_7-7'
  | 'Equal_shapes_7_parts/Square_equal_7-7'
  | 'Equal_shapes_8_parts/Circle_equal_8-8'
  | 'Equal_shapes_8_parts/Cross_equal_8-8'
  | 'Equal_shapes_8_parts/Square_equal_8-8'
  | 'Equal_shapes_9_parts/Circle_equal_9-9'
  | 'Equal_shapes_9_parts/Rectangle_equal_9-9'
  | 'Equal_shapes_9_parts/Square_equal_9-9'
  | 'Equilateral_triangles/triangle_equal_'
  | 'Equilateral_triangles/triangle_equal_blue'
  | 'Equilateral_triangles/triangle_equal_green'
  | 'Equilateral_triangles/triangle_equal_orange'
  | 'Equilateral_triangles/triangle_equal_pink'
  | 'Equilateral_triangles/triangle_equal_purple'
  | 'Equilateral_triangles/triangle_equal_red'
  | 'Equilateral_triangles/triangle_equal_turquoise'
  | 'Equilateral_triangles/triangle_equal_white'
  | 'Equilateral_triangles/triangle_equal_yellow'
  | 'Factor_Bug'
  | 'Flags/Armenia_horizontal_flag'
  | 'Flags/Australia_both_flag'
  | 'Flags/Austria_horizontal_flag'
  | 'Flags/Belgium_vertical_flag'
  | 'Flags/Botswana_horizontal_flag'
  | 'Flags/Bulgaria_horizontal_flag'
  | 'Flags/Cameroon_vertical_flag'
  | 'Flags/Canada_vertical_flag'
  | 'Flags/Chad_vertical_flag'
  | 'Flags/Columbia_horizontal_flag'
  | 'Flags/Denmark_both_flag'
  | 'Flags/Estonia_horizontal_flag'
  | 'Flags/Finland_both_flag'
  | 'Flags/France_vertical_flag'
  | 'Flags/Germany_horizontal_flag'
  | 'Flags/Greece_both_flag'
  | 'Flags/Iceland_both_flag'
  | 'Flags/Indonesia_horizontal_flag'
  | 'Flags/Ireland_vertical_flag'
  | 'Flags/Italy_vertical_flag'
  | 'Flags/Latvia_horizontal_flag'
  | 'Flags/Lithuania_horizontal_flag'
  | 'Flags/Madagascar_both_flag'
  | 'Flags/Mali_vertical_flag'
  | 'Flags/Mauritius_horizontal_flag'
  | 'Flags/Netherlands_horizontal_flag'
  | 'Flags/Nigeria_vertical_flag'
  | 'Flags/Norway_both_flag'
  | 'Flags/Poland_horizontal_flag'
  | 'Flags/Romania_vertical_flag'
  | 'Flags/Senagal_vertical_flag'
  | 'Flags/Sierra_Leone_horizontal_flag'
  | 'Flags/Sweden_both_flag'
  | 'Flags/Thailand_horizontal_flag'
  | 'Flags/Togo_horizontal_flag'
  | 'Flags/UAE_both_flag'
  | 'Flags/UK_both_flag'
  | 'Flags/USA_horizontal_flag'
  | 'Flowers/Flower_Blue'
  | 'Flowers/Flower_Orange'
  | 'Flowers/Flower_Purple'
  | 'Flowers/Flower_Red'
  | 'Flowers/Flower_White'
  | 'Flowers/Flower_Yellow'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_1_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_2_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_3_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_4_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_5_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_6_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_7_Eighths'
  | 'Food_fractions/Cake/Cake_Eighths/Cake_8_Eighths'
  | 'Food_fractions/Cake/Cake_Fifths/Cake_1_Fifths'
  | 'Food_fractions/Cake/Cake_Fifths/Cake_2_Fifths'
  | 'Food_fractions/Cake/Cake_Fifths/Cake_3_Fifths'
  | 'Food_fractions/Cake/Cake_Fifths/Cake_4_Fifths'
  | 'Food_fractions/Cake/Cake_Fifths/Cake_5_Fifths'
  | 'Food_fractions/Cake/Cake_Half/Cake_1_half'
  | 'Food_fractions/Cake/Cake_Half/Cake_2_halves'
  | 'Food_fractions/Cake/Cake_Quarters/Cake_1_quarters'
  | 'Food_fractions/Cake/Cake_Quarters/Cake_2_quarters'
  | 'Food_fractions/Cake/Cake_Quarters/Cake_3_quarters'
  | 'Food_fractions/Cake/Cake_Quarters/Cake_4_quarters'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_1_Sevenths'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_2_Sevenths'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_3_Sevenths'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_4_Sevenths'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_5_Sevenths'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_6_Sevenths'
  | 'Food_fractions/Cake/Cake_Sevenths/Cake_7_Sevenths'
  | 'Food_fractions/Cake/Cake_Sixths/Cake_1_Sixths'
  | 'Food_fractions/Cake/Cake_Sixths/Cake_2_Sixths'
  | 'Food_fractions/Cake/Cake_Sixths/Cake_3_Sixths'
  | 'Food_fractions/Cake/Cake_Sixths/Cake_4_Sixths'
  | 'Food_fractions/Cake/Cake_Sixths/Cake_5_Sixths'
  | 'Food_fractions/Cake/Cake_Sixths/Cake_6_Sixths'
  | 'Food_fractions/Cake/Cake_Thirds/Cake_1_thirds'
  | 'Food_fractions/Cake/Cake_Thirds/Cake_2_thirds'
  | 'Food_fractions/Cake/Cake_Thirds/Cake_3_thirds'
  | 'Food_fractions/Cake/Cake_Whole/Cake_1_Whole'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_1_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_2_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_3_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_4_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_5_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_6_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_7_Eighths'
  | 'Food_fractions/Pie/Pie_Eighths/Pie_8_Eighths'
  | 'Food_fractions/Pie/Pie_Fifths/Pie_1_Fifths'
  | 'Food_fractions/Pie/Pie_Fifths/Pie_2_Fifths'
  | 'Food_fractions/Pie/Pie_Fifths/Pie_3_Fifths'
  | 'Food_fractions/Pie/Pie_Fifths/Pie_4_Fifths'
  | 'Food_fractions/Pie/Pie_Fifths/Pie_5_Fifths'
  | 'Food_fractions/Pie/Pie_Halves/Pie_1_half'
  | 'Food_fractions/Pie/Pie_Halves/Pie_2_halves'
  | 'Food_fractions/Pie/Pie_Quarters/Pie_1_Quarters'
  | 'Food_fractions/Pie/Pie_Quarters/Pie_2_Quarters'
  | 'Food_fractions/Pie/Pie_Quarters/Pie_3_Quarters'
  | 'Food_fractions/Pie/Pie_Quarters/Pie_4_Quarters'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_1_Sevenths'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_2_Sevenths'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_3_Sevenths'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_4_Sevenths'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_5_Sevenths'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_6_Sevenths'
  | 'Food_fractions/Pie/Pie_Sevenths/Pie_7_Sevenths'
  | 'Food_fractions/Pie/Pie_Sixths/Pie_1_sixths'
  | 'Food_fractions/Pie/Pie_Sixths/Pie_2_sixths'
  | 'Food_fractions/Pie/Pie_Sixths/Pie_3_sixths'
  | 'Food_fractions/Pie/Pie_Sixths/Pie_4_sixths'
  | 'Food_fractions/Pie/Pie_Sixths/Pie_5_sixths'
  | 'Food_fractions/Pie/Pie_Sixths/Pie_6_sixths'
  | 'Food_fractions/Pie/Pie_Thirds/Pie_1_Thirds'
  | 'Food_fractions/Pie/Pie_Thirds/Pie_2_Thirds'
  | 'Food_fractions/Pie/Pie_Thirds/Pie_3_Thirds'
  | 'Food_fractions/Pie/Pie_Whole/Pie_whole'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_1_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_2_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_3_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_4_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_5_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_6_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_7_Eighths'
  | 'Food_fractions/Pizza/Pizza_Eighths/Pizza_8_Eighths'
  | 'Food_fractions/Pizza/Pizza_Fifths/Pizza_1_fifths'
  | 'Food_fractions/Pizza/Pizza_Fifths/Pizza_2_fifths'
  | 'Food_fractions/Pizza/Pizza_Fifths/Pizza_3_fifths'
  | 'Food_fractions/Pizza/Pizza_Fifths/Pizza_4_fifths'
  | 'Food_fractions/Pizza/Pizza_Fifths/Pizza_5_fifths'
  | 'Food_fractions/Pizza/Pizza_Halves/Pizza_1_halves'
  | 'Food_fractions/Pizza/Pizza_Halves/Pizza_2_halves'
  | 'Food_fractions/Pizza/Pizza_Quarters/Pizza_1_Quarters'
  | 'Food_fractions/Pizza/Pizza_Quarters/Pizza_2_Quarters'
  | 'Food_fractions/Pizza/Pizza_Quarters/Pizza_3_Quarters'
  | 'Food_fractions/Pizza/Pizza_Quarters/Pizza_4_Quarters'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_1_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_2_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_3_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_4_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_5_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_6_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_7_Sevenths'
  | 'Food_fractions/Pizza/Pizza_Sixths/pizza_1_sixths'
  | 'Food_fractions/Pizza/Pizza_Sixths/pizza_2_sixths'
  | 'Food_fractions/Pizza/Pizza_Sixths/pizza_3_sixths'
  | 'Food_fractions/Pizza/Pizza_Sixths/pizza_4_sixths'
  | 'Food_fractions/Pizza/Pizza_Sixths/pizza_5_sixths'
  | 'Food_fractions/Pizza/Pizza_Sixths/pizza_6_sixths'
  | 'Food_fractions/Pizza/Pizza_Thirds/Pizza_1_Thirds'
  | 'Food_fractions/Pizza/Pizza_Thirds/Pizza_2_Thirds'
  | 'Food_fractions/Pizza/Pizza_Thirds/Pizza_3_Thirds'
  | 'Food_fractions/Pizza/Pizza_Whole/Pizza'
  | 'Food_on_a_plate/Cookies_cakes_plate_1'
  | 'Food_on_a_plate/Cookies_cakes_plate_2'
  | 'Food_on_a_plate/Cookies_cakes_plate_3'
  | 'Food_on_a_plate/Cookies_cakes_plate_4'
  | 'Food_on_a_plate/Cookies_cakes_plate_5'
  | 'Food_on_a_plate/Cookies_cakes_plate_6'
  | 'Food_on_a_plate/Muffins_plate_1'
  | 'Food_on_a_plate/Muffins_plate_2'
  | 'Food_on_a_plate/Muffins_plate_3'
  | 'Food_on_a_plate/Muffins_plate_4'
  | 'Food_on_a_plate/Muffins_plate_5'
  | 'Food_on_a_plate/Muffins_plate_6'
  | 'Football_sticker_book'
  | 'Fork'
  | 'Frog'
  | 'Full_bathtub'
  | 'Full_bucket'
  | 'GamesConsole'
  | 'Giraffe'
  | 'Glasses_of_orange_juice/glass1_oj_eighth_full'
  | 'Glasses_of_orange_juice/glass1_oj_empty'
  | 'Glasses_of_orange_juice/glass1_oj_full'
  | 'Glasses_of_orange_juice/glass1_oj_half_full'
  | 'Glasses_of_orange_juice/glass1_oj_quarter_full'
  | 'Glasses_of_orange_juice/glass1_oj_seven_eighths_full'
  | 'Glasses_of_orange_juice/glass1_oj_third_full'
  | 'Glasses_of_orange_juice/glass1_oj_three_quarters_full'
  | 'Glasses_of_orange_juice/glass1_oj_two_thirds_full'
  | 'Glasses_of_orange_juice/glass2_oj_eighth_full'
  | 'Glasses_of_orange_juice/glass2_oj_empty'
  | 'Glasses_of_orange_juice/glass2_oj_full'
  | 'Glasses_of_orange_juice/glass2_oj_half_full'
  | 'Glasses_of_orange_juice/glass2_oj_quarter_full'
  | 'Glasses_of_orange_juice/glass2_oj_seven_eighths_full'
  | 'Glasses_of_orange_juice/glass2_oj_third_full'
  | 'Glasses_of_orange_juice/glass2_oj_three_quarters_full'
  | 'Glasses_of_orange_juice/glass2_oj_two_thirds_full'
  | 'Glasses_of_water/glass_water_full'
  | 'Glasses_of_water/glass_water_half_full'
  | 'Glasses_of_water/glass_water_quarter_full'
  | 'Glasses_of_water/glass_water_three_quarters_full'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_0'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_1'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_10'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_2'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_3'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_4'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_5'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_6'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_7'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_8'
  | 'Goldfish_in_a_tank/Goldfish_in_a_tank_9'
  | 'Grape_green'
  | 'Grapes_green_bunch'
  | 'GreyStar'
  | 'Gummy_bears/gummybear_green'
  | 'Gummy_bears/gummybear_orange'
  | 'Gummy_bears/gummybear_purple'
  | 'Gummy_bears/gummybear_red'
  | 'Gummy_bears/gummybear_yellow'
  | 'Hand_open_fingers'
  | 'Hands_open_pair'
  | 'Hay_bale'
  | 'Headphones'
  | 'Heart_purple'
  | 'Heptagons/Heptagon_blue'
  | 'Heptagons/Heptagon_green'
  | 'Heptagons/Heptagon_orange'
  | 'Heptagons/Heptagon_pink'
  | 'Heptagons/Heptagon_purple'
  | 'Heptagons/Heptagon_red'
  | 'Heptagons/Heptagon_turquoise'
  | 'Heptagons/Heptagon_yellow'
  | 'Heptagons/Irregular_heptagon1_blue'
  | 'Heptagons/Irregular_heptagon1_green'
  | 'Heptagons/Irregular_heptagon1_orange'
  | 'Heptagons/Irregular_heptagon1_pink'
  | 'Heptagons/Irregular_heptagon1_purple'
  | 'Heptagons/Irregular_heptagon1_red'
  | 'Heptagons/Irregular_heptagon1_turquoise'
  | 'Heptagons/Irregular_heptagon1_yellow'
  | 'Heptagons/Irregular_heptagon2_blue'
  | 'Heptagons/Irregular_heptagon2_green'
  | 'Heptagons/Irregular_heptagon2_orange'
  | 'Heptagons/Irregular_heptagon2_pink'
  | 'Heptagons/Irregular_heptagon2_purple'
  | 'Heptagons/Irregular_heptagon2_red'
  | 'Heptagons/Irregular_heptagon2_turquoise'
  | 'Heptagons/Irregular_heptagon2_yellow'
  | 'Heptagons/Irregular_heptagon3_blue'
  | 'Heptagons/Irregular_heptagon3_green'
  | 'Heptagons/Irregular_heptagon3_orange'
  | 'Heptagons/Irregular_heptagon3_pink'
  | 'Heptagons/Irregular_heptagon3_purple'
  | 'Heptagons/Irregular_heptagon3_red'
  | 'Heptagons/Irregular_heptagon3_turquoise'
  | 'Heptagons/Irregular_heptagon3_yellow'
  | 'Hexagons/Irregular_hexagon1_blue'
  | 'Hexagons/Irregular_hexagon1_blue_one_interior_angle_lhs'
  | 'Hexagons/Irregular_hexagon1_blue_one_interior_angle_rhs'
  | 'Hexagons/Irregular_hexagon1_green'
  | 'Hexagons/Irregular_hexagon1_green_one_interior_angle_lhs'
  | 'Hexagons/Irregular_hexagon1_green_one_interior_angle_rhs'
  | 'Hexagons/Irregular_hexagon1_orange'
  | 'Hexagons/Irregular_hexagon1_pink'
  | 'Hexagons/Irregular_hexagon1_pink_one_interior_angle_lhs'
  | 'Hexagons/Irregular_hexagon1_pink_one_interior_angle_rhs'
  | 'Hexagons/Irregular_hexagon1_purple'
  | 'Hexagons/Irregular_hexagon1_purple_one_interior_angle_lhs'
  | 'Hexagons/Irregular_hexagon1_purple_one_interior_angle_rhs'
  | 'Hexagons/Irregular_hexagon1_red'
  | 'Hexagons/Irregular_hexagon1_turquoise'
  | 'Hexagons/Irregular_hexagon1_white'
  | 'Hexagons/Irregular_hexagon1_white_one_interior_angle_lhs'
  | 'Hexagons/Irregular_hexagon1_white_one_interior_angle_rhs'
  | 'Hexagons/Irregular_hexagon1_yellow'
  | 'Hexagons/Irregular_hexagon1_yellow_one_interior_angle_lhs'
  | 'Hexagons/Irregular_hexagon1_yellow_one_interior_angle_rhs'
  | 'Hexagons/Irregular_hexagon2_blue'
  | 'Hexagons/Irregular_hexagon2_green'
  | 'Hexagons/Irregular_hexagon2_orange'
  | 'Hexagons/Irregular_hexagon2_pink'
  | 'Hexagons/Irregular_hexagon2_purple'
  | 'Hexagons/Irregular_hexagon2_red'
  | 'Hexagons/Irregular_hexagon2_turquoise'
  | 'Hexagons/Irregular_hexagon2_white'
  | 'Hexagons/Irregular_hexagon2_yellow'
  | 'Hexagons/Irregular_hexagon3_blue'
  | 'Hexagons/Irregular_hexagon3_green'
  | 'Hexagons/Irregular_hexagon3_orange'
  | 'Hexagons/Irregular_hexagon3_pink'
  | 'Hexagons/Irregular_hexagon3_purple'
  | 'Hexagons/Irregular_hexagon3_red'
  | 'Hexagons/Irregular_hexagon3_turquoise'
  | 'Hexagons/Irregular_hexagon3_white'
  | 'Hexagons/Irregular_hexagon3_yellow'
  | 'Hexagons/Irregular_hexagon4_blue'
  | 'Hexagons/Irregular_hexagon4_green'
  | 'Hexagons/Irregular_hexagon4_orange'
  | 'Hexagons/Irregular_hexagon4_pink'
  | 'Hexagons/Irregular_hexagon4_purple'
  | 'Hexagons/Irregular_hexagon4_red'
  | 'Hexagons/Irregular_hexagon4_turquoise'
  | 'Hexagons/Irregular_hexagon4_white'
  | 'Hexagons/Irregular_hexagon4_yellow'
  | 'Hexagons/Irregular_hexagon5_blue'
  | 'Hexagons/Irregular_hexagon5_green'
  | 'Hexagons/Irregular_hexagon5_orange'
  | 'Hexagons/Irregular_hexagon5_pink'
  | 'Hexagons/Irregular_hexagon5_purple'
  | 'Hexagons/Irregular_hexagon5_red'
  | 'Hexagons/Irregular_hexagon5_turquoise'
  | 'Hexagons/Irregular_hexagon5_white'
  | 'Hexagons/Irregular_hexagon5_yellow'
  | 'Hexagons/Irregular_hexagon6_blue'
  | 'Hexagons/Irregular_hexagon6_green'
  | 'Hexagons/Irregular_hexagon6_orange'
  | 'Hexagons/Irregular_hexagon6_pink'
  | 'Hexagons/Irregular_hexagon6_purple'
  | 'Hexagons/Irregular_hexagon6_red'
  | 'Hexagons/Irregular_hexagon6_turquoise'
  | 'Hexagons/Irregular_hexagon6_white'
  | 'Hexagons/Irregular_hexagon6_yellow'
  | 'Hexagons/hexagon'
  | 'Hexagons/hexagon_1_arrow'
  | 'Hexagons/hexagon_1_arrow_blue'
  | 'Hexagons/hexagon_1_arrow_green'
  | 'Hexagons/hexagon_1_arrow_orange'
  | 'Hexagons/hexagon_1_arrow_pink'
  | 'Hexagons/hexagon_1_arrow_purple'
  | 'Hexagons/hexagon_1_arrow_turquoise'
  | 'Hexagons/hexagon_1_arrow_yellow'
  | 'Hexagons/hexagon_blue'
  | 'Hexagons/hexagon_green'
  | 'Hexagons/hexagon_orange'
  | 'Hexagons/hexagon_pink'
  | 'Hexagons/hexagon_purple'
  | 'Hexagons/hexagon_red'
  | 'Hexagons/hexagon_turquoise'
  | 'Hexagons/hexagon_white'
  | 'Hexagons/hexagon_yellow'
  | 'Horizontal_Thermoter_blank'
  | 'Horse'
  | 'House'
  | 'Hulahoop'
  | 'Icecreamcone'
  | 'Individual_lolly_stick'
  | 'Info'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_blue'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_lhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_rhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_green'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_lhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_rhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_orange'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_pink'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_lhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_rhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_purple'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_lhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_rhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_red'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_turquoise'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_white'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_lhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_rhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_yellow'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_lhs'
  | 'Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_rhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide'
  | 'Isosceles_triangles_wide/triangle_isos_wide_blue'
  | 'Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_lhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_rhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_green'
  | 'Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_lhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_rhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_orange'
  | 'Isosceles_triangles_wide/triangle_isos_wide_pink'
  | 'Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_lhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_rhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_purple'
  | 'Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_lhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_rhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_red'
  | 'Isosceles_triangles_wide/triangle_isos_wide_turquoise'
  | 'Isosceles_triangles_wide/triangle_isos_wide_white'
  | 'Isosceles_triangles_wide/triangle_isos_wide_white_one_interior_angle_lhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_white_one_interior_angle_rhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_yellow'
  | 'Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_lhs'
  | 'Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_rhs'
  | 'Jack_birds_eye'
  | 'Juice'
  | 'Ketchup'
  | 'Kettle'
  | 'Keychain1'
  | 'Kite/kite'
  | 'Kite/kite_blue'
  | 'Kite/kite_green'
  | 'Kite/kite_grey'
  | 'Kite/kite_orange'
  | 'Kite/kite_pink'
  | 'Kite/kite_purple'
  | 'Kite/kite_red'
  | 'Kite/kite_turquoise'
  | 'Kite/kite_white'
  | 'Kite/kite_yellow'
  | 'Kiwi_fruit'
  | 'L_shapes/L_shape_blue'
  | 'L_shapes/L_shape_green'
  | 'L_shapes/L_shape_orange'
  | 'L_shapes/L_shape_pink'
  | 'L_shapes/L_shape_purple'
  | 'L_shapes/L_shape_red'
  | 'L_shapes/L_shape_turquoise'
  | 'L_shapes/L_shape_yellow'
  | 'Labelled_box_A'
  | 'Labelled_box_B'
  | 'Labelled_box_C'
  | 'Labelled_box_D'
  | 'Labelled_box_bricks'
  | 'Laptop'
  | 'Large_bin'
  | 'Layered_shapes/Layered_compound_shapes1'
  | 'Layered_shapes/Layered_compound_shapes2'
  | 'Layered_shapes/Layered_compound_shapes3'
  | 'Layered_shapes/Layered_compound_shapes4'
  | 'Layered_shapes/Layered_compound_shapes5'
  | 'Layered_shapes/Layered_compound_shapes6'
  | 'Lego_bricks/thick_lego_6_circles_pink'
  | 'Lego_bricks/thick_lego_6_circles_red'
  | 'Lego_bricks/thick_lego_6_circles_yellow'
  | 'Lego_bricks/thick_lego_8_circles_white'
  | 'Lego_bricks/thin_lego_6_circles1_purple'
  | 'Lego_bricks/thin_lego_6_circles2_green'
  | 'Lemon'
  | 'Lemonade_bottle'
  | 'Lettuce'
  | 'Lion'
  | 'Lion_side_profile'
  | 'Loaf_of_bread'
  | 'Lolly_stick_shapes/Decagon_lolly_sticks'
  | 'Lolly_stick_shapes/Heptagon_lolly_sticks'
  | 'Lolly_stick_shapes/Hexagon1_lolly_sticks'
  | 'Lolly_stick_shapes/Hexagon2_lolly_sticks'
  | 'Lolly_stick_shapes/Nonagon2_lolly_sticks'
  | 'Lolly_stick_shapes/Nonagon_lolly_sticks'
  | 'Lolly_stick_shapes/Octagon1_lolly_sticks'
  | 'Lolly_stick_shapes/Pentagon_lolly_sticks'
  | 'Lolly_stick_shapes/Quadrilateral1_lolly_sticks'
  | 'Lolly_stick_shapes/Quadrilateral2_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle10_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle11_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle12_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle13_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle14_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle15_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle16_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle17_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle18_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle19_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle1_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle20_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle2_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle3_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle4_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle5_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle6_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle7_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle8_lolly_sticks'
  | 'Lolly_stick_shapes/Rectangle9_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape1_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape2_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape3_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape4_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape5_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape6_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape7_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape8_lolly_sticks'
  | 'Lolly_stick_shapes/Rectilinear_shape9_lolly_sticks'
  | 'Lolly_stick_shapes/Triangle_lolly_sticks'
  | 'Lolly_sticks/FiveLollyStickPentagon'
  | 'Lolly_sticks/FourLollyStickPentagon'
  | 'Lolly_sticks/FourLollyStickSquare'
  | 'Lolly_sticks/LollyStickDiamond'
  | 'Lolly_sticks/LollyStickHouse'
  | 'Lolly_sticks/LollyStickRectangle'
  | 'Lolly_sticks/LollyStickTriangle'
  | 'Lolly_sticks/LollySticksPatterns/LollySticksDiamondPattern'
  | 'Lolly_sticks/LollySticksPatterns/LollySticksHousePatternA'
  | 'Lolly_sticks/LollySticksPatterns/LollySticksHousePatternB'
  | 'Lolly_sticks/LollySticksPatterns/LollySticksRectanglePattern'
  | 'Lolly_sticks/LollySticksPatterns/LollySticksSquarePattern'
  | 'Lolly_sticks/LollySticksPatterns/LollySticksTrianglePattern'
  | 'Lolly_sticks/SingleLollyStickPentagon'
  | 'Lolly_sticks/SingleLollyStickSquare'
  | 'Lolly_sticks/SixLollyStickHexagon'
  | 'Lolly_sticks/ThreeLollyStickPentagon'
  | 'Lolly_sticks/ThreeLollyStickSquare'
  | 'Lolly_sticks/ThreeLollyStickTriangle'
  | 'Lolly_sticks/TwoLollyStickPentagon'
  | 'Lolly_sticks/TwoLollyStickSquare'
  | 'Magazine'
  | 'Marble'
  | 'Marble_bag'
  | 'Measure/pencil'
  | 'Metre_stick'
  | 'Microwave'
  | 'Milk_Carton'
  | 'Milk_carton_2l'
  | 'Milk_carton_5l'
  | 'Milkshake'
  | 'Mo_birds_eye'
  | 'Money/Pence1'
  | 'Money/Pence10'
  | 'Money/Pence2'
  | 'Money/Pence20'
  | 'Money/Pence5'
  | 'Money/Pence50'
  | 'Money/Pounds1'
  | 'Money/Pounds2'
  | 'Moon_white'
  | 'Moon_yellow'
  | 'Muffins_donuts/Muffins_on_a_rack_0'
  | 'Muffins_donuts/Muffins_on_a_rack_1'
  | 'Muffins_donuts/Muffins_on_a_rack_10'
  | 'Muffins_donuts/Muffins_on_a_rack_11'
  | 'Muffins_donuts/Muffins_on_a_rack_12'
  | 'Muffins_donuts/Muffins_on_a_rack_2'
  | 'Muffins_donuts/Muffins_on_a_rack_3'
  | 'Muffins_donuts/Muffins_on_a_rack_4'
  | 'Muffins_donuts/Muffins_on_a_rack_5'
  | 'Muffins_donuts/Muffins_on_a_rack_6'
  | 'Muffins_donuts/Muffins_on_a_rack_7'
  | 'Muffins_donuts/Muffins_on_a_rack_8'
  | 'Muffins_donuts/Muffins_on_a_rack_9'
  | 'Muffins_individually/Muffin_1'
  | 'Muffins_individually/Muffin_2'
  | 'Muffins_individually/Muffin_3'
  | 'Muffins_individually/Muffin_4'
  | 'Muffins_individually/Muffin_5'
  | 'Muffins_individually/Muffin_6'
  | 'Muffins_individually/Muffin_7'
  | 'Mug'
  | 'Mug_yellow'
  | 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_blue'
  | 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_green'
  | 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_red'
  | 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_yellow'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_blue'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_green'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_blue'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_green'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_red'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_yellow'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_red'
  | 'Multi_link_cubes/Multilink_cube_individual_vertical_yellow'
  | 'Multi_link_cubes/column_1_blue'
  | 'Multi_link_cubes/column_1_green'
  | 'Multi_link_cubes/column_1_orange'
  | 'Multi_link_cubes/column_1_purple'
  | 'Multi_link_cubes/column_1_red'
  | 'Multi_link_cubes/column_2_blue'
  | 'Multi_link_cubes/column_2_green'
  | 'Multi_link_cubes/column_2_orange'
  | 'Multi_link_cubes/column_2_purple'
  | 'Multi_link_cubes/column_2_red'
  | 'Multi_link_cubes/column_3_blue'
  | 'Multi_link_cubes/column_3_green'
  | 'Multi_link_cubes/column_3_orange'
  | 'Multi_link_cubes/column_3_purple'
  | 'Multi_link_cubes/column_3_red'
  | 'Multi_link_cubes/column_4_blue'
  | 'Multi_link_cubes/column_4_green'
  | 'Multi_link_cubes/column_4_orange'
  | 'Multi_link_cubes/column_4_purple'
  | 'Multi_link_cubes/column_4_red'
  | 'Multi_link_cubes/column_5_blue'
  | 'Multi_link_cubes/column_5_green'
  | 'Multi_link_cubes/column_5_orange'
  | 'Multi_link_cubes/column_5_purple'
  | 'Multi_link_cubes/column_5_red'
  | 'Multi_link_cubes/column_6_blue'
  | 'Multi_link_cubes/column_6_green'
  | 'Multi_link_cubes/column_6_orange'
  | 'Multi_link_cubes/column_6_purple'
  | 'Multi_link_cubes/column_6_red'
  | 'Multi_link_cubes/column_7_blue'
  | 'Multi_link_cubes/column_7_green'
  | 'Multi_link_cubes/column_7_orange'
  | 'Multi_link_cubes/column_7_purple'
  | 'Multi_link_cubes/column_7_red'
  | 'Multi_link_cubes/column_8_blue'
  | 'Multi_link_cubes/column_8_green'
  | 'Multi_link_cubes/column_8_orange'
  | 'Multi_link_cubes/column_8_purple'
  | 'Multi_link_cubes/column_8_red'
  | 'Multi_link_cubes/front_column_blue'
  | 'Multi_link_cubes/front_column_green'
  | 'Multi_link_cubes/front_column_orange'
  | 'Multi_link_cubes/front_column_purple'
  | 'Multi_link_cubes/front_column_red'
  | 'Multi_link_cubes/front_column_yellow'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids1'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids10'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids11'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids12'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids13'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids2'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids3'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids4'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids5'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids6'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids7'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids8'
  | 'Multilink_shapes/Multilink_cubes_and_cuboids9'
  | 'Multilink_shapes/Multilink_shape1_10cubes'
  | 'Multilink_shapes/Multilink_shape1_12cubes'
  | 'Multilink_shapes/Multilink_shape1_4cubes'
  | 'Multilink_shapes/Multilink_shape1_6cubes'
  | 'Multilink_shapes/Multilink_shape1_8cubes'
  | 'Multilink_shapes/Multilink_shape2_10cubes'
  | 'Multilink_shapes/Multilink_shape2_12cubes'
  | 'Multilink_shapes/Multilink_shape2_4cubes'
  | 'Multilink_shapes/Multilink_shape2_7cubes'
  | 'Multilink_shapes/Multilink_shape2_8cubes'
  | 'Multilink_shapes/Multilink_shape3_10cubes'
  | 'Multilink_shapes/Multilink_shape3_12cubes'
  | 'Multilink_shapes/Multilink_shape3_4cubes'
  | 'Multilink_shapes/Multilink_shape3_6cubes'
  | 'Multilink_shapes/Multilink_shape3_8cubes'
  | 'Nonagons/Irregular_nonagon_blue'
  | 'Nonagons/Irregular_nonagon_green'
  | 'Nonagons/Irregular_nonagon_pink'
  | 'Nonagons/Irregular_nonagon_purple'
  | 'Nonagons/Irregular_nonagon_white'
  | 'Nonagons/Irregular_nonagon_yellow'
  | 'Nonagons/Regular_nonagon_1_arrow'
  | 'Nonagons/Regular_nonagon_1_arrow_blue'
  | 'Nonagons/Regular_nonagon_1_arrow_green'
  | 'Nonagons/Regular_nonagon_1_arrow_pink'
  | 'Nonagons/Regular_nonagon_1_arrow_purple'
  | 'Nonagons/Regular_nonagon_1_arrow_yellow'
  | 'Nonagons/Regular_nonagon_blue'
  | 'Nonagons/Regular_nonagon_green'
  | 'Nonagons/Regular_nonagon_pink'
  | 'Nonagons/Regular_nonagon_purple'
  | 'Nonagons/Regular_nonagon_white'
  | 'Nonagons/Regular_nonagon_yellow'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_1'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_10'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_11'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_12'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_13'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_14'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_15'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_16'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_17'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_18'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_19'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_2'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_20'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_21'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_22'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_23'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_24'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_3'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_4'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_5'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_6'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_7'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_8'
  | 'Ocean_Scene_depiciting_depths/Ocean_Scene_9'
  | 'Octagons/Irregular_octagon1_blue'
  | 'Octagons/Irregular_octagon1_green'
  | 'Octagons/Irregular_octagon1_orange'
  | 'Octagons/Irregular_octagon1_pink'
  | 'Octagons/Irregular_octagon1_purple'
  | 'Octagons/Irregular_octagon1_red'
  | 'Octagons/Irregular_octagon1_turquoise'
  | 'Octagons/Irregular_octagon1_white'
  | 'Octagons/Irregular_octagon1_yellow'
  | 'Octagons/Irregular_octagon2_blue'
  | 'Octagons/Irregular_octagon2_green'
  | 'Octagons/Irregular_octagon2_orange'
  | 'Octagons/Irregular_octagon2_pink'
  | 'Octagons/Irregular_octagon2_purple'
  | 'Octagons/Irregular_octagon2_red'
  | 'Octagons/Irregular_octagon2_turquoise'
  | 'Octagons/Irregular_octagon2_white'
  | 'Octagons/Irregular_octagon2_yellow'
  | 'Octagons/Irregular_octagon3_blue'
  | 'Octagons/Irregular_octagon3_green'
  | 'Octagons/Irregular_octagon3_orange'
  | 'Octagons/Irregular_octagon3_pink'
  | 'Octagons/Irregular_octagon3_purple'
  | 'Octagons/Irregular_octagon3_red'
  | 'Octagons/Irregular_octagon3_turquoise'
  | 'Octagons/Irregular_octagon3_white'
  | 'Octagons/Irregular_octagon3_yellow'
  | 'Octagons/Octagon_blue'
  | 'Octagons/Octagon_green'
  | 'Octagons/Octagon_orange'
  | 'Octagons/Octagon_pink'
  | 'Octagons/Octagon_purple'
  | 'Octagons/Octagon_red'
  | 'Octagons/Octagon_turquoise'
  | 'Octagons/Octagon_yellow'
  | 'Octagons/Regular_octagon_1_arrow'
  | 'Octagons/Regular_octagon_1_arrow_blue'
  | 'Octagons/Regular_octagon_1_arrow_green'
  | 'Octagons/Regular_octagon_1_arrow_pink'
  | 'Octagons/Regular_octagon_1_arrow_purple'
  | 'Octagons/Regular_octagon_1_arrow_yellow'
  | 'Orange_juice_carton'
  | 'Orange_juice_carton_blank'
  | 'Other_shapes/4_angles_around_a_point'
  | 'Other_shapes/5_angles_around_a_point'
  | 'Other_shapes/6_angles_around_a_point'
  | 'Other_shapes/7_angles_around_a_point'
  | 'Other_shapes/Angles_on_a_straight_line'
  | 'Other_shapes/Anti_clockwise_shaded_diagram_full_turn'
  | 'Other_shapes/Anti_clockwise_shaded_diagram_half_turn'
  | 'Other_shapes/Anti_clockwise_shaded_diagram_quarter_turn'
  | 'Other_shapes/Anti_clockwise_shaded_diagram_three_quarter_turn'
  | 'Other_shapes/Clockwise_shaded_diagram_full_turn'
  | 'Other_shapes/Clockwise_shaded_diagram_half_turn'
  | 'Other_shapes/Clockwise_shaded_diagram_quarter_turn'
  | 'Other_shapes/Clockwise_shaded_diagram_three_quarter_turn'
  | 'Other_shapes/Coloured_arrow1'
  | 'Other_shapes/Coloured_arrow2'
  | 'Other_shapes/Coloured_arrow3'
  | 'Other_shapes/Coloured_arrow4'
  | 'Other_shapes/Cones_dotted_lines'
  | 'Other_shapes/Cube1'
  | 'Other_shapes/Cube3'
  | 'Other_shapes/Cube4'
  | 'Other_shapes/Cube_dottedlines1'
  | 'Other_shapes/Cube_dottedlines2'
  | 'Other_shapes/Cuboid_dottedlines1'
  | 'Other_shapes/Cuboid_dottedlines2'
  | 'Other_shapes/Cuboid_dottedlines3'
  | 'Other_shapes/Cylinder1'
  | 'Other_shapes/Cylinder3'
  | 'Other_shapes/Cylinder_dotted_lines1'
  | 'Other_shapes/Cylinder_dotted_lines2'
  | 'Other_shapes/Decagon_non_example1'
  | 'Other_shapes/Decagon_non_example2'
  | 'Other_shapes/Decagon_non_example3'
  | 'Other_shapes/Equilateraltriangle_markedlines'
  | 'Other_shapes/Heptagon_non_example1'
  | 'Other_shapes/Heptagon_non_example2'
  | 'Other_shapes/Hexagon_non_example1'
  | 'Other_shapes/Hexagon_non_example2'
  | 'Other_shapes/Hexagon_non_example4'
  | 'Other_shapes/Hexagonal_prism1'
  | 'Other_shapes/Hexagonal_prism2'
  | 'Other_shapes/Hexagonalprism_dottedlines1'
  | 'Other_shapes/Hexagonalprism_dottedlines2'
  | 'Other_shapes/Hexagonalpyramid_dottedlines1'
  | 'Other_shapes/Interior_angles_card'
  | 'Other_shapes/Irregular_decagon1'
  | 'Other_shapes/Irregular_decagon2'
  | 'Other_shapes/Irregular_decagon2_blue'
  | 'Other_shapes/Irregular_decagon2_green'
  | 'Other_shapes/Irregular_decagon2_pink'
  | 'Other_shapes/Irregular_decagon2_purple'
  | 'Other_shapes/Irregular_decagon2_yellow'
  | 'Other_shapes/Irregular_heptagon'
  | 'Other_shapes/Irregular_heptagon_blue'
  | 'Other_shapes/Irregular_heptagon_green'
  | 'Other_shapes/Irregular_heptagon_pink'
  | 'Other_shapes/Irregular_heptagon_purple'
  | 'Other_shapes/Irregular_heptagon_yellow'
  | 'Other_shapes/Isosceles_triangle_angles_card'
  | 'Other_shapes/Nonagon_non_example1'
  | 'Other_shapes/Nonagon_non_example2'
  | 'Other_shapes/Nonagon_non_example3'
  | 'Other_shapes/Octagon_non_example1'
  | 'Other_shapes/Octagon_non_example2'
  | 'Other_shapes/Octagon_non_example3'
  | 'Other_shapes/Parallelogram1_marked_lines_and_angles'
  | 'Other_shapes/Parallelogram2_marked_lines_and_angles'
  | 'Other_shapes/Parallelogram3_marked_lines_and_angles'
  | 'Other_shapes/Parallelogram4_marked_lines_and_angles'
  | 'Other_shapes/Pentagon_non_example1'
  | 'Other_shapes/Pentagon_non_example2'
  | 'Other_shapes/Pentagon_non_example3'
  | 'Other_shapes/Pentagon_non_example4'
  | 'Other_shapes/Pentagon_non_example5'
  | 'Other_shapes/Pentagon_regular_marked_lines'
  | 'Other_shapes/Pentagonalprism_dottedlines1'
  | 'Other_shapes/Pentagonalprism_dottedlines2'
  | 'Other_shapes/Rectangle1_marked_lines_and_angles'
  | 'Other_shapes/Rectangle2_marked_lines_and_angles'
  | 'Other_shapes/Rectangle3_marked_lines_and_angles'
  | 'Other_shapes/Rectangle4_marked_lines_and_angles'
  | 'Other_shapes/Regular_decagon'
  | 'Other_shapes/Regular_decagon1_marked_lines'
  | 'Other_shapes/Regular_decagon2_marked_lines'
  | 'Other_shapes/Regular_decagon_1_arrow'
  | 'Other_shapes/Regular_decagon_1_arrow_blue'
  | 'Other_shapes/Regular_decagon_1_arrow_green'
  | 'Other_shapes/Regular_decagon_1_arrow_pink'
  | 'Other_shapes/Regular_decagon_1_arrow_purple'
  | 'Other_shapes/Regular_decagon_1_arrow_yellow'
  | 'Other_shapes/Regular_decagon_blue'
  | 'Other_shapes/Regular_decagon_equal_sides_one_angle'
  | 'Other_shapes/Regular_decagon_green'
  | 'Other_shapes/Regular_decagon_pink'
  | 'Other_shapes/Regular_decagon_purple'
  | 'Other_shapes/Regular_decagon_yellow'
  | 'Other_shapes/Regular_heptagon'
  | 'Other_shapes/Regular_heptagon_1_arrow'
  | 'Other_shapes/Regular_heptagon_1_arrow_blue'
  | 'Other_shapes/Regular_heptagon_1_arrow_green'
  | 'Other_shapes/Regular_heptagon_1_arrow_pink'
  | 'Other_shapes/Regular_heptagon_1_arrow_purple'
  | 'Other_shapes/Regular_heptagon_1_arrow_yellow'
  | 'Other_shapes/Regular_heptagon_blue'
  | 'Other_shapes/Regular_heptagon_equal_sides_one_angle'
  | 'Other_shapes/Regular_heptagon_green'
  | 'Other_shapes/Regular_heptagon_marked_lines'
  | 'Other_shapes/Regular_heptagon_pink'
  | 'Other_shapes/Regular_heptagon_purple'
  | 'Other_shapes/Regular_heptagon_yellow'
  | 'Other_shapes/Regular_hexagon_equal_sides_one_angle'
  | 'Other_shapes/Regular_hexagon_marked_lines'
  | 'Other_shapes/Regular_nonagon_equal_sides_one_angle'
  | 'Other_shapes/Regular_nonagon_marked_lines'
  | 'Other_shapes/Regular_octagon'
  | 'Other_shapes/Regular_octagon_blue'
  | 'Other_shapes/Regular_octagon_equal_sides_one_angle'
  | 'Other_shapes/Regular_octagon_green'
  | 'Other_shapes/Regular_octagon_marked_lines'
  | 'Other_shapes/Regular_octagon_pink'
  | 'Other_shapes/Regular_octagon_purple'
  | 'Other_shapes/Regular_octagon_yellow'
  | 'Other_shapes/Regular_pentagon_equal_sides_one_angle'
  | 'Other_shapes/Rhombus1_marked_lines_and_angles'
  | 'Other_shapes/Rhombus2_marked_lines_and_angles'
  | 'Other_shapes/Rhombus_markedlines'
  | 'Other_shapes/Right_angle_dotted_lines'
  | 'Other_shapes/Square_based_pyramid1'
  | 'Other_shapes/Square_based_pyramid2'
  | 'Other_shapes/Square_based_pyramid3'
  | 'Other_shapes/Square_equal_sides_one_angle'
  | 'Other_shapes/Square_marked_lines_and_angles'
  | 'Other_shapes/Square_markedlines1'
  | 'Other_shapes/Square_markedlines2'
  | 'Other_shapes/Square_non_example1'
  | 'Other_shapes/Square_non_example2'
  | 'Other_shapes/Square_non_example3'
  | 'Other_shapes/Square_non_example4'
  | 'Other_shapes/Tetrahedron1'
  | 'Other_shapes/Tetrahedron4'
  | 'Other_shapes/Tetrehedron1_dotted_lines'
  | 'Other_shapes/Tetrehedron2_dotted_lines'
  | 'Other_shapes/Tetrehedron3_dotted_lines'
  | 'Other_shapes/Trapezium1_marked_lines_and_angles'
  | 'Other_shapes/Trapezium2_marked_lines_and_angles'
  | 'Other_shapes/Trapezium3_marked_lines_and_angles'
  | 'Other_shapes/Trapezium4_marked_lines_and_angles'
  | 'Other_shapes/Triangle1_line_extension_3_angles'
  | 'Other_shapes/Triangle2_line_extension_3_angles'
  | 'Other_shapes/Triangle3_line_extension_3_angles'
  | 'Other_shapes/Triangle4_line_extension_3_angles'
  | 'Other_shapes/Triangle5_line_extension_3_angles'
  | 'Other_shapes/Triangle_non_example1'
  | 'Other_shapes/Triangle_non_example2'
  | 'Other_shapes/Triangular_prism1'
  | 'Other_shapes/Triangular_prism2'
  | 'Other_shapes/Triangular_prism2by3'
  | 'Other_shapes/Triangular_prism2by4'
  | 'Other_shapes/Triangular_prism3'
  | 'Other_shapes/Triangular_prism3by3'
  | 'Other_shapes/Triangular_prism3by4'
  | 'Other_shapes/Triangularprism_dottedlines1'
  | 'Other_shapes/Triangularprism_dottedlines2'
  | 'Other_shapes/Triangularprism_dottedlines3'
  | 'Other_shapes/Vertically_opposite_angles_card'
  | 'Other_shapes/cube2'
  | 'Other_shapes/cuboid2'
  | 'Other_shapes/cuboid3'
  | 'Other_shapes/cylinder2'
  | 'Other_shapes/hexagonalprism2'
  | 'Other_shapes/hexagonalpyramid1'
  | 'Other_shapes/pentagonalprism2'
  | 'Other_shapes/squarebasedpyramid1'
  | 'Other_shapes/squarebasedpyramid2'
  | 'Other_shapes/squarebasedpyramid3'
  | 'Other_shapes/stamp3dshape1'
  | 'Other_shapes/stamp3dshape2'
  | 'Other_shapes/stamp3dshape3'
  | 'Other_shapes/stamp3dshape4'
  | 'Other_shapes/stamp3dshape5'
  | 'Other_shapes/stamp3dshape6'
  | 'Other_shapes/tetrahedron2'
  | 'Other_shapes/tetrahedron3'
  | 'Other_shapes/triangularprism2'
  | 'Other_shapes/triangularprism3'
  | 'Owl_bird'
  | 'Pack_of_10_crayons'
  | 'Pack_of_marbles_blank'
  | 'Pack_of_sweets_unlabelled'
  | 'Packaged_sandwich'
  | 'Packs_of_pins_pack_of_100'
  | 'Paddle'
  | 'Paddling_pool'
  | 'Paint_brush'
  | 'Paint_palette'
  | 'PairedsocksBlue'
  | 'PairedsocksGreen'
  | 'PairedsocksOrange'
  | 'PairedsocksPurple'
  | 'PairedsocksRed'
  | 'PairedsocksYellow'
  | 'Paper_clip'
  | 'Parallelogram/parallelogram'
  | 'Parallelogram/parallelogram_blue'
  | 'Parallelogram/parallelogram_green'
  | 'Parallelogram/parallelogram_orange'
  | 'Parallelogram/parallelogram_pink'
  | 'Parallelogram/parallelogram_purple'
  | 'Parallelogram/parallelogram_red'
  | 'Parallelogram/parallelogram_turquoise'
  | 'Parallelogram/parallelogram_white'
  | 'Parallelogram/parallelogram_yellow'
  | 'ParenthesesLeftBlack'
  | 'ParenthesesLeftBlue'
  | 'ParenthesesRightBlack'
  | 'ParenthesesRightBlue'
  | 'Part_filled_cylinder_1000mlcap_100mlint'
  | 'Peach'
  | 'Pears_in_a_transparent_bag/Pears_in_a_bag_0'
  | 'Pears_in_a_transparent_bag/Pears_in_a_bag_1'
  | 'Pears_in_a_transparent_bag/Pears_in_a_bag_2'
  | 'Pears_in_a_transparent_bag/Pears_in_a_bag_3'
  | 'Pears_in_a_transparent_bag/Pears_in_a_bag_4'
  | 'Pears_in_a_transparent_bag/Pears_in_a_bag_5'
  | 'Pen_green'
  | 'Pencil_case'
  | 'Pencil_sharpener'
  | 'Pencils/Pencil_green'
  | 'Pencils/Pencil_red'
  | 'Pencils/Pencil_yellow'
  | 'Pentagon_houses/pentagon_house'
  | 'Pentagon_houses/pentagon_house_blue'
  | 'Pentagon_houses/pentagon_house_green'
  | 'Pentagon_houses/pentagon_house_orange'
  | 'Pentagon_houses/pentagon_house_pink'
  | 'Pentagon_houses/pentagon_house_purple'
  | 'Pentagon_houses/pentagon_house_red'
  | 'Pentagon_houses/pentagon_house_turquoise'
  | 'Pentagon_houses/pentagon_house_white'
  | 'Pentagon_houses/pentagon_house_yellow'
  | 'Pentagons/Irregular_pentagon1_blue'
  | 'Pentagons/Irregular_pentagon1_blue_one_interior_angle_lhs'
  | 'Pentagons/Irregular_pentagon1_blue_one_interior_angle_rhs'
  | 'Pentagons/Irregular_pentagon1_green'
  | 'Pentagons/Irregular_pentagon1_green_one_interior_angle_lhs'
  | 'Pentagons/Irregular_pentagon1_green_one_interior_angle_rhs'
  | 'Pentagons/Irregular_pentagon1_orange'
  | 'Pentagons/Irregular_pentagon1_pink'
  | 'Pentagons/Irregular_pentagon1_pink_one_interior_angle_lhs'
  | 'Pentagons/Irregular_pentagon1_pink_one_interior_angle_rhs'
  | 'Pentagons/Irregular_pentagon1_purple'
  | 'Pentagons/Irregular_pentagon1_purple_one_interior_angle_lhs'
  | 'Pentagons/Irregular_pentagon1_purple_one_interior_angle_rhs'
  | 'Pentagons/Irregular_pentagon1_red'
  | 'Pentagons/Irregular_pentagon1_turquoise'
  | 'Pentagons/Irregular_pentagon1_white'
  | 'Pentagons/Irregular_pentagon1_white_one_interior_angle_lhs'
  | 'Pentagons/Irregular_pentagon1_white_one_interior_angle_rhs'
  | 'Pentagons/Irregular_pentagon1_yellow'
  | 'Pentagons/Irregular_pentagon1_yellow_one_interior_angle_lhs'
  | 'Pentagons/Irregular_pentagon1_yellow_one_interior_angle_rhs'
  | 'Pentagons/Irregular_pentagon2_blue'
  | 'Pentagons/Irregular_pentagon2_green'
  | 'Pentagons/Irregular_pentagon2_orange'
  | 'Pentagons/Irregular_pentagon2_pink'
  | 'Pentagons/Irregular_pentagon2_purple'
  | 'Pentagons/Irregular_pentagon2_red'
  | 'Pentagons/Irregular_pentagon2_turquoise'
  | 'Pentagons/Irregular_pentagon2_white'
  | 'Pentagons/Irregular_pentagon2_yellow'
  | 'Pentagons/Irregular_pentagon3_blue'
  | 'Pentagons/Irregular_pentagon3_green'
  | 'Pentagons/Irregular_pentagon3_orange'
  | 'Pentagons/Irregular_pentagon3_pink'
  | 'Pentagons/Irregular_pentagon3_purple'
  | 'Pentagons/Irregular_pentagon3_red'
  | 'Pentagons/Irregular_pentagon3_turquoise'
  | 'Pentagons/Irregular_pentagon3_white'
  | 'Pentagons/Irregular_pentagon3_yellow'
  | 'Pentagons/Irregular_pentagon4_blue'
  | 'Pentagons/Irregular_pentagon4_green'
  | 'Pentagons/Irregular_pentagon4_orange'
  | 'Pentagons/Irregular_pentagon4_pink'
  | 'Pentagons/Irregular_pentagon4_purple'
  | 'Pentagons/Irregular_pentagon4_red'
  | 'Pentagons/Irregular_pentagon4_turquoise'
  | 'Pentagons/Irregular_pentagon4_white'
  | 'Pentagons/Irregular_pentagon4_yellow'
  | 'Pentagons/Irregular_pentagon5_blue'
  | 'Pentagons/Irregular_pentagon5_green'
  | 'Pentagons/Irregular_pentagon5_orange'
  | 'Pentagons/Irregular_pentagon5_pink'
  | 'Pentagons/Irregular_pentagon5_purple'
  | 'Pentagons/Irregular_pentagon5_red'
  | 'Pentagons/Irregular_pentagon5_turquoise'
  | 'Pentagons/Irregular_pentagon5_white'
  | 'Pentagons/Irregular_pentagon5_yellow'
  | 'Pentagons/Irregular_pentagon6_blue'
  | 'Pentagons/Irregular_pentagon6_green'
  | 'Pentagons/Irregular_pentagon6_orange'
  | 'Pentagons/Irregular_pentagon6_pink'
  | 'Pentagons/Irregular_pentagon6_purple'
  | 'Pentagons/Irregular_pentagon6_red'
  | 'Pentagons/Irregular_pentagon6_turquoise'
  | 'Pentagons/Irregular_pentagon6_white'
  | 'Pentagons/Irregular_pentagon6_yellow'
  | 'Pentagons/pentagon'
  | 'Pentagons/pentagon_1_arrow'
  | 'Pentagons/pentagon_1_arrow_blue'
  | 'Pentagons/pentagon_1_arrow_green'
  | 'Pentagons/pentagon_1_arrow_pink'
  | 'Pentagons/pentagon_1_arrow_purple'
  | 'Pentagons/pentagon_1_arrow_yellow'
  | 'Pentagons/pentagon_blue'
  | 'Pentagons/pentagon_green'
  | 'Pentagons/pentagon_orange'
  | 'Pentagons/pentagon_pink'
  | 'Pentagons/pentagon_purple'
  | 'Pentagons/pentagon_red'
  | 'Pentagons/pentagon_turquoise'
  | 'Pentagons/pentagon_white'
  | 'Pentagons/pentagon_yellow'
  | 'Phone'
  | 'Picture_book'
  | 'Place_value/0.0001'
  | 'Place_value/0.001'
  | 'Place_value/0.01'
  | 'Place_value/0.1'
  | 'Place_value/0.1_grey'
  | 'Place_value/1'
  | 'Place_value/1-10'
  | 'Place_value/1-100'
  | 'Place_value/1-1000'
  | 'Place_value/1-10000'
  | 'Place_value/10'
  | 'Place_value/100'
  | 'Place_value/1000'
  | 'Place_value/10000'
  | 'Place_value/100000'
  | 'Place_value/1000000'
  | 'Place_value/1000cube'
  | 'Place_value/100cube'
  | 'Place_value/10cube'
  | 'Place_value/1_grey'
  | 'Place_value/1cube'
  | 'Place_value/BlackCounter'
  | 'Place_value/GreyCounter'
  | 'Plane'
  | 'Plate'
  | 'Plum'
  | 'Pond'
  | 'Protractor180'
  | 'Protractor360'
  | 'Rectangle/Rectangle_angles_length_arrows_blue'
  | 'Rectangle/Rectangle_angles_length_arrows_green'
  | 'Rectangle/Rectangle_angles_length_arrows_pink'
  | 'Rectangle/Rectangle_angles_length_arrows_purple'
  | 'Rectangle/Rectangle_angles_length_arrows_white'
  | 'Rectangle/Rectangle_angles_length_arrows_yellow'
  | 'Rectangle/rectangle'
  | 'Rectangle/rectangle_blue'
  | 'Rectangle/rectangle_green'
  | 'Rectangle/rectangle_orange'
  | 'Rectangle/rectangle_pink'
  | 'Rectangle/rectangle_purple'
  | 'Rectangle/rectangle_red'
  | 'Rectangle/rectangle_turquoise'
  | 'Rectangle/rectangle_white'
  | 'Rectangle/rectangle_yellow'
  | 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows1'
  | 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows2'
  | 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows3'
  | 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows4'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_blue'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_green'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_pink'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_purple'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_yellow'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape11_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape12_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape13_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape14_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape15_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape16_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape17_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape18_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_blue'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_green'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_pink'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_purple'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_yellow'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape1_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape1_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape20_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_blue'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_green'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_pink'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_purple'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_yellow'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_blue'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_green'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_pink'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_purple'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_yellow'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape2_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape2_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape3_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape3_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape4_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape4_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape5_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape5_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape6_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape6_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape7_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape7_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape8_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape8_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape9_2_red_arrows'
  | 'Rectilinear_shapes_with_arrows/Rectilinear_shape9_all_arrows_all_interior_angles'
  | 'Rectilinear_shapes_with_arrows/Square_angles_length_arrows'
  | 'Red_scissors'
  | 'Refrigerator'
  | 'Rhombus/rhombus'
  | 'Rhombus/rhombus_blue'
  | 'Rhombus/rhombus_green'
  | 'Rhombus/rhombus_orange'
  | 'Rhombus/rhombus_pink'
  | 'Rhombus/rhombus_purple'
  | 'Rhombus/rhombus_red'
  | 'Rhombus/rhombus_turquoise'
  | 'Rhombus/rhombus_white'
  | 'Rhombus/rhombus_yellow'
  | 'RibbonGreen'
  | 'RibbonGreenStriped'
  | 'RibbonRed'
  | 'RibbonRedStriped'
  | 'Ribbons/1-10'
  | 'Ribbons/1-11'
  | 'Ribbons/1-12'
  | 'Ribbons/1-2'
  | 'Ribbons/1-3'
  | 'Ribbons/1-4'
  | 'Ribbons/1-5'
  | 'Ribbons/1-6'
  | 'Ribbons/1-7'
  | 'Ribbons/1-8'
  | 'Ribbons/1-9'
  | 'RiceAndBowls'
  | 'Right_angled_trapeziums/trapezium_RA'
  | 'Right_angled_trapeziums/trapezium_RA_blue'
  | 'Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_lhs'
  | 'Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_rhs'
  | 'Right_angled_trapeziums/trapezium_RA_green'
  | 'Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_lhs'
  | 'Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_rhs'
  | 'Right_angled_trapeziums/trapezium_RA_pink'
  | 'Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_lhs'
  | 'Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_rhs'
  | 'Right_angled_trapeziums/trapezium_RA_purple'
  | 'Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_lhs'
  | 'Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_rhs'
  | 'Right_angled_trapeziums/trapezium_RA_white'
  | 'Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_lhs'
  | 'Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_rhs'
  | 'Right_angled_trapeziums/trapezium_RA_yellow'
  | 'Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_lhs'
  | 'Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA'
  | 'Right_angled_triangles/triangle_RA_blue'
  | 'Right_angled_triangles/triangle_RA_green'
  | 'Right_angled_triangles/triangle_RA_green_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_green_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_long_blue'
  | 'Right_angled_triangles/triangle_RA_long_blue_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_long_blue_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_long_green'
  | 'Right_angled_triangles/triangle_RA_long_green_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_long_green_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_long_orange'
  | 'Right_angled_triangles/triangle_RA_long_pink'
  | 'Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_long_purple'
  | 'Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_long_red'
  | 'Right_angled_triangles/triangle_RA_long_turquoise'
  | 'Right_angled_triangles/triangle_RA_long_white'
  | 'Right_angled_triangles/triangle_RA_long_white_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_long_white_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_long_yellow'
  | 'Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_orange'
  | 'Right_angled_triangles/triangle_RA_pink'
  | 'Right_angled_triangles/triangle_RA_pink_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_pink_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_purple'
  | 'Right_angled_triangles/triangle_RA_purple_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_purple_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_red'
  | 'Right_angled_triangles/triangle_RA_turquoise'
  | 'Right_angled_triangles/triangle_RA_white'
  | 'Right_angled_triangles/triangle_RA_white_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_white_one_interior_angle_rhs'
  | 'Right_angled_triangles/triangle_RA_yellow'
  | 'Right_angled_triangles/triangle_RA_yellow_one_interior_angle_lhs'
  | 'Right_angled_triangles/triangle_RA_yellow_one_interior_angle_rhs'
  | 'Roller_coaster_ad_duration'
  | 'Rounded_rectangles/Rounded_rectangle_blue'
  | 'Rounded_rectangles/Rounded_rectangle_green'
  | 'Rounded_rectangles/Rounded_rectangle_orange'
  | 'Rounded_rectangles/Rounded_rectangle_pink'
  | 'Rounded_rectangles/Rounded_rectangle_purple'
  | 'Rounded_rectangles/Rounded_rectangle_red'
  | 'Rounded_rectangles/Rounded_rectangle_turquoise'
  | 'Rounded_rectangles/Rounded_rectangle_yellow'
  | 'Rounded_squares/Rounded_square_blue'
  | 'Rounded_squares/Rounded_square_green'
  | 'Rounded_squares/Rounded_square_orange'
  | 'Rounded_squares/Rounded_square_pink'
  | 'Rounded_squares/Rounded_square_purple'
  | 'Rounded_squares/Rounded_square_red'
  | 'Rounded_squares/Rounded_square_turquoise'
  | 'Rounded_squares/Rounded_square_yellow'
  | 'Rubber'
  | 'Rubbish_bin'
  | 'Rucksack'
  | 'Rucksack_green'
  | 'Rucksack_purple'
  | 'Rucksack_red'
  | 'Sand_bag'
  | 'Scalene_triangles/triangle_scalene'
  | 'Scalene_triangles/triangle_scalene_blue'
  | 'Scalene_triangles/triangle_scalene_blue_one_interior_angle_lhs'
  | 'Scalene_triangles/triangle_scalene_blue_one_interior_angle_rhs'
  | 'Scalene_triangles/triangle_scalene_green'
  | 'Scalene_triangles/triangle_scalene_green_one_interior_angle_lhs'
  | 'Scalene_triangles/triangle_scalene_green_one_interior_angle_rhs'
  | 'Scalene_triangles/triangle_scalene_orange'
  | 'Scalene_triangles/triangle_scalene_pink'
  | 'Scalene_triangles/triangle_scalene_pink_one_interior_angle_lhs'
  | 'Scalene_triangles/triangle_scalene_pink_one_interior_angle_rhs'
  | 'Scalene_triangles/triangle_scalene_purple'
  | 'Scalene_triangles/triangle_scalene_purple_one_interior_angle_lhs'
  | 'Scalene_triangles/triangle_scalene_purple_one_interior_angle_rhs'
  | 'Scalene_triangles/triangle_scalene_red'
  | 'Scalene_triangles/triangle_scalene_turquoise'
  | 'Scalene_triangles/triangle_scalene_white'
  | 'Scalene_triangles/triangle_scalene_white_one_interior_angle_lhs'
  | 'Scalene_triangles/triangle_scalene_white_one_interior_angle_rhs'
  | 'Scalene_triangles/triangle_scalene_yellow'
  | 'Scalene_triangles/triangle_scalene_yellow_one_interior_angle_lhs'
  | 'Scalene_triangles/triangle_scalene_yellow_one_interior_angle_rhs'
  | 'Scales/1000g_scale_100g'
  | 'Scales/1000g_scale_200g'
  | 'Scales/1000g_scale_20g_100g'
  | 'Scales/1000g_scale_250g'
  | 'Scales/1000g_scale_25g_100g'
  | 'Scales/1000g_scale_50g_100g'
  | 'Scales/1000g_scale_50g_200g'
  | 'Scales/100g_scale_10g'
  | 'Scales/100g_scale_20g'
  | 'Scales/1_kg_scale_100g'
  | 'Scales/1_kg_scale_200_g'
  | 'Scales/1_kg_scale_250_g'
  | 'Scales/1_kg_scale_500_g'
  | 'Scales/1_kg_scale_50g'
  | 'Scales/200g_scale_100g'
  | 'Scales/200g_scale_10g'
  | 'Scales/200g_scale_20g'
  | 'Scales/3_kg_scale_100_g'
  | 'Scales/3_kg_scale_200_g'
  | 'Scales/3_kg_scale_250_g'
  | 'Scales/3_kg_scale_500_g'
  | 'Scales/4_kg_scale_100_g'
  | 'Scales/4_kg_scale_200_g'
  | 'Scales/4_kg_scale_250_g'
  | 'Scales/4_kg_scale_500_g'
  | 'Scales/500g_scale_10g'
  | 'Scales/500g_scale_20g'
  | 'Scales/500g_scale_25g'
  | 'Scales/5_kg_scale_100_g'
  | 'Scales/5_kg_scale_200_g'
  | 'Scales/5_kg_scale_250_g'
  | 'Scales/5_kg_scale_500_g'
  | 'Scales/6_kg_scale_200_g'
  | 'Scales/6_kg_scale_250_g'
  | 'Scales/6_kg_scale_500_g'
  | 'Scales/Various_kg_scale_10kg'
  | 'Scales/Various_kg_scale_4kg'
  | 'Scales/Various_kg_scale_5kg'
  | 'Scales/Various_kg_scale_6kg'
  | 'Scales/Various_kg_scale_7kg'
  | 'Scales/Various_kg_scale_8kg'
  | 'Scales/Various_kg_scale_9kg'
  | 'Scales/arrow'
  | 'Scales/blank_scale'
  | 'Scales/kg_scale_2'
  | 'Scales/kg_scale_3'
  | 'Scales/kg_scale_4'
  | 'Scales/wooden_balance_scale_equal'
  | 'Scales/wooden_balance_scale_left'
  | 'Scales/wooden_balance_scale_right'
  | 'Scooter'
  | 'Semicircles/circle_semi'
  | 'Semicircles/circle_semi_blue'
  | 'Semicircles/circle_semi_green'
  | 'Semicircles/circle_semi_orange'
  | 'Semicircles/circle_semi_pink'
  | 'Semicircles/circle_semi_purple'
  | 'Semicircles/circle_semi_red'
  | 'Semicircles/circle_semi_turquoise'
  | 'Semicircles/circle_semi_white'
  | 'Semicircles/circle_semi_yellow'
  | 'Shape_formulae/1_circle_2_triangles'
  | 'Shape_formulae/1_circle_4_squares'
  | 'Shape_formulae/1_square_2_circles'
  | 'Shape_formulae/1_square_4_triangles'
  | 'Shape_formulae/1_star_2_circles'
  | 'Shape_formulae/1_triangle_3_circles'
  | 'Shape_formulae/1_triangle_3_squares'
  | 'Shape_nets/Cone_net'
  | 'Shape_nets/Cone_net_noFill'
  | 'Shape_nets/Cone_net_without_tabs'
  | 'Shape_nets/Cube_net'
  | 'Shape_nets/Cube_net_noFill'
  | 'Shape_nets/Cuboid_net'
  | 'Shape_nets/Cuboid_net_noFill'
  | 'Shape_nets/Cuboid_net_without_tabs'
  | 'Shape_nets/Cylinder_net'
  | 'Shape_nets/Cylinder_net_noFill'
  | 'Shape_nets/Cylinder_net_without_tabs'
  | 'Shape_nets/Hexagonal_prism_net'
  | 'Shape_nets/Hexagonal_prism_net_noFill'
  | 'Shape_nets/Hexagonal_prism_net_without_tabs'
  | 'Shape_nets/Hexagonal_pyramid_net_without_tabs'
  | 'Shape_nets/Octagonal_prism_net_without_tabs'
  | 'Shape_nets/Octagonal_pyramid_net_without_tabs'
  | 'Shape_nets/Pentagonal_prism_net'
  | 'Shape_nets/Pentagonal_prism_net_noFill'
  | 'Shape_nets/Pentagonal_prism_net_without_tab'
  | 'Shape_nets/Pentagonal_pyramid_net_without_tabs'
  | 'Shape_nets/Square-based_pyramid_net_without_tabs'
  | 'Shape_nets/Squared_based_pyramid_net'
  | 'Shape_nets/Squared_based_pyramid_net_noFill'
  | 'Shape_nets/Tatrahedron_net_without_tabs'
  | 'Shape_nets/Tetrahedron_net'
  | 'Shape_nets/Tetrahedron_net_noFill'
  | 'Shape_nets/Triangular_prism_net'
  | 'Shape_nets/Triangular_prism_net_noFill'
  | 'Shape_nets/Triangular_prism_net_without_tabs'
  | 'Shape_nets/correctnetsofcubes1'
  | 'Shape_nets/correctnetsofcubes10'
  | 'Shape_nets/correctnetsofcubes11'
  | 'Shape_nets/correctnetsofcubes2'
  | 'Shape_nets/correctnetsofcubes3'
  | 'Shape_nets/correctnetsofcubes4'
  | 'Shape_nets/correctnetsofcubes5'
  | 'Shape_nets/correctnetsofcubes6'
  | 'Shape_nets/correctnetsofcubes7'
  | 'Shape_nets/correctnetsofcubes8'
  | 'Shape_nets/correctnetsofcubes9'
  | 'Shape_nets/incorrectnetsofcubes1'
  | 'Shape_nets/incorrectnetsofcubes2'
  | 'Shape_nets/incorrectnetsofcubes3'
  | 'Shape_nets/incorrectnetsofcubes4'
  | 'Shape_patterns/Linked_hexagons/3_blue_orange_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/3_green_yellow_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/3_red_blue_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/4_blue_orange_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/4_green_yellow_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/4_red_blue_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/5_blue_orange_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/5_green_yellow_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/5_red_blue_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/6_blue_orange_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/6_green_yellow_hexagon_pattern'
  | 'Shape_patterns/Linked_hexagons/6_red_blue_hexagon_pattern'
  | 'Shape_patterns/Linked_octagons/3_blue_orange_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/3_green_yellow_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/3_red_blue_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/4_blue_orange_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/4_green_yellow_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/4_red_blue_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/5_blue_orange_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/5_green_yellow_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/5_red_blue_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/6_blue_orange_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/6_green_yellow_octagon_pattern'
  | 'Shape_patterns/Linked_octagons/6_red_blue_octagon_pattern'
  | 'Shapes_made_from_cubes/Cube_cubes1'
  | 'Shapes_made_from_cubes/Cuboid_cubes10'
  | 'Shapes_made_from_cubes/Cuboid_cubes11'
  | 'Shapes_made_from_cubes/Cuboid_cubes12'
  | 'Shapes_made_from_cubes/Cuboid_cubes13'
  | 'Shapes_made_from_cubes/Cuboid_cubes2'
  | 'Shapes_made_from_cubes/Cuboid_cubes3'
  | 'Shapes_made_from_cubes/Cuboid_cubes4'
  | 'Shapes_made_from_cubes/Cuboid_cubes5'
  | 'Shapes_made_from_cubes/Cuboid_cubes6'
  | 'Shapes_made_from_cubes/Cuboid_cubes7'
  | 'Shapes_made_from_cubes/Cuboid_cubes8'
  | 'Shapes_made_from_cubes/Cuboid_cubes9'
  | 'Shapes_made_from_cubes/othershapescube1'
  | 'Shapes_made_from_cubes/othershapescube10'
  | 'Shapes_made_from_cubes/othershapescube11'
  | 'Shapes_made_from_cubes/othershapescube12'
  | 'Shapes_made_from_cubes/othershapescube13'
  | 'Shapes_made_from_cubes/othershapescube14'
  | 'Shapes_made_from_cubes/othershapescube15'
  | 'Shapes_made_from_cubes/othershapescube16'
  | 'Shapes_made_from_cubes/othershapescube17'
  | 'Shapes_made_from_cubes/othershapescube18'
  | 'Shapes_made_from_cubes/othershapescube19'
  | 'Shapes_made_from_cubes/othershapescube2'
  | 'Shapes_made_from_cubes/othershapescube20'
  | 'Shapes_made_from_cubes/othershapescube3'
  | 'Shapes_made_from_cubes/othershapescube4'
  | 'Shapes_made_from_cubes/othershapescube5'
  | 'Shapes_made_from_cubes/othershapescube6'
  | 'Shapes_made_from_cubes/othershapescube7'
  | 'Shapes_made_from_cubes/othershapescube8'
  | 'Shapes_made_from_cubes/othershapescube9'
  | 'Shapes_on_grids/Compound_shape10_grid'
  | 'Shapes_on_grids/Compound_shape11_grid'
  | 'Shapes_on_grids/Compound_shape12_grid'
  | 'Shapes_on_grids/Compound_shape13_grid'
  | 'Shapes_on_grids/Compound_shape14_grid'
  | 'Shapes_on_grids/Compound_shape15_grid'
  | 'Shapes_on_grids/Compound_shape16_grid'
  | 'Shapes_on_grids/Compound_shape1_grid'
  | 'Shapes_on_grids/Compound_shape2_grid'
  | 'Shapes_on_grids/Compound_shape3_grid'
  | 'Shapes_on_grids/Compound_shape4_grid'
  | 'Shapes_on_grids/Compound_shape5_grid'
  | 'Shapes_on_grids/Compound_shape6_grid'
  | 'Shapes_on_grids/Compound_shape7_grid'
  | 'Shapes_on_grids/Compound_shape8_grid'
  | 'Shapes_on_grids/Compound_shape9_grid'
  | 'Shapes_on_grids/Curved_shape1'
  | 'Shapes_on_grids/Curved_shape2'
  | 'Shapes_on_grids/Curved_shape3'
  | 'Shapes_on_grids/Curved_shape4'
  | 'Shapes_on_grids/Curved_shape5'
  | 'Shapes_on_grids/Curved_shape6'
  | 'Shapes_on_grids/Curved_shape7'
  | 'Shapes_on_grids/Curved_shape8'
  | 'Shapes_on_grids/Equilateral_triangle1_grid'
  | 'Shapes_on_grids/Equilateral_triangle2_grid'
  | 'Shapes_on_grids/Equilateral_triangle3_grid'
  | 'Shapes_on_grids/Equilateral_triangle4_grid'
  | 'Shapes_on_grids/Island_shape_a1'
  | 'Shapes_on_grids/Island_shape_a2'
  | 'Shapes_on_grids/Island_shape_a3'
  | 'Shapes_on_grids/Island_shape_a4'
  | 'Shapes_on_grids/Island_shape_a5'
  | 'Shapes_on_grids/Island_shape_a6'
  | 'Shapes_on_grids/Island_shape_b1'
  | 'Shapes_on_grids/Island_shape_b2'
  | 'Shapes_on_grids/Island_shape_b3'
  | 'Shapes_on_grids/Island_shape_b4'
  | 'Shapes_on_grids/Island_shape_b5'
  | 'Shapes_on_grids/Island_shape_b6'
  | 'Shapes_on_grids/Isosceles_triangle1_grid'
  | 'Shapes_on_grids/Isosceles_triangle2_grid'
  | 'Shapes_on_grids/Isosceles_triangle3_grid'
  | 'Shapes_on_grids/Isosceles_triangle4_grid'
  | 'Shapes_on_grids/Isosceles_triangle5_grid'
  | 'Shapes_on_grids/Isosceles_triangle6_grid'
  | 'Shapes_on_grids/Leaf1_ongrid'
  | 'Shapes_on_grids/Leaf2_ongrid'
  | 'Shapes_on_grids/Leaf3_ongrid'
  | 'Shapes_on_grids/Leaf4_ongrid'
  | 'Shapes_on_grids/Leaf5_ongrid'
  | 'Shapes_on_grids/Leaf6_ongrid'
  | 'Shapes_on_grids/Leaf7_ongrid'
  | 'Shapes_on_grids/Leaf8_ongrid'
  | 'Shapes_on_grids/Opaque_rectangle1_grid'
  | 'Shapes_on_grids/Opaque_rectangle2_grid'
  | 'Shapes_on_grids/Opaque_rectangle3_grid'
  | 'Shapes_on_grids/Opaque_rectangle4_grid'
  | 'Shapes_on_grids/Opaque_rectangle5_grid'
  | 'Shapes_on_grids/Opaque_rectangle6_grid'
  | 'Shapes_on_grids/Opaque_rectangle7_grid'
  | 'Shapes_on_grids/Opaque_rectangle8_grid'
  | 'Shapes_on_grids/Parallelogram10_grid'
  | 'Shapes_on_grids/Parallelogram11_grid'
  | 'Shapes_on_grids/Parallelogram12_grid'
  | 'Shapes_on_grids/Parallelogram13_grid'
  | 'Shapes_on_grids/Parallelogram14_grid'
  | 'Shapes_on_grids/Parallelogram15_grid'
  | 'Shapes_on_grids/Parallelogram16_grid'
  | 'Shapes_on_grids/Parallelogram17_grid'
  | 'Shapes_on_grids/Parallelogram18_grid'
  | 'Shapes_on_grids/Parallelogram19_grid'
  | 'Shapes_on_grids/Parallelogram1_grid'
  | 'Shapes_on_grids/Parallelogram20_grid'
  | 'Shapes_on_grids/Parallelogram2_grid'
  | 'Shapes_on_grids/Parallelogram3_grid'
  | 'Shapes_on_grids/Parallelogram4_grid'
  | 'Shapes_on_grids/Parallelogram5_grid'
  | 'Shapes_on_grids/Parallelogram6_grid'
  | 'Shapes_on_grids/Parallelogram7_grid'
  | 'Shapes_on_grids/Parallelogram8_grid'
  | 'Shapes_on_grids/Parallelogram9_grid'
  | 'Shapes_on_grids/Right_angle_triangle1_grid'
  | 'Shapes_on_grids/Right_angle_triangle2_grid'
  | 'Shapes_on_grids/Right_angle_triangle3_grid'
  | 'Shapes_on_grids/Right_angle_triangle4_grid'
  | 'Shapes_on_grids/Right_angle_triangle5_grid'
  | 'Shapes_on_grids/Right_angle_triangle6_grid'
  | 'Shapes_on_grids/Scalene_triangle1_grid'
  | 'Shapes_on_grids/Scalene_triangle2_grid'
  | 'Shapes_on_grids/Scalene_triangle3_grid'
  | 'Shapes_on_grids/Scalene_triangle4_grid'
  | 'Shapes_on_grids/Scalene_triangle5_grid'
  | 'Shapes_on_grids/Scalene_triangle6_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelG'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelH'
  | 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelG'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelH'
  | 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelG'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelH'
  | 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelG'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelH'
  | 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelG'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelH'
  | 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_nolabels'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelA'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelB'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelC'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelD'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelE'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelF'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelG'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelH'
  | 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_nolabels'
  | 'Shapes_on_grids/Various_shapes1_grid'
  | 'Shapes_on_grids/Various_shapes2_grid'
  | 'Shapes_on_grids/Various_shapes3_grid'
  | 'Shapes_on_grids/Various_shapes4_grid'
  | 'Shapes_on_grids/Various_shapes5_grid'
  | 'Shapes_on_grids/Various_shapes6_grid'
  | 'Shapes_on_grids/Various_shapes7_grid'
  | 'Shapes_on_grids/Various_shapes8_grid'
  | 'Shapes_on_grids/Various_shapes9_grid'
  | 'Shapes_with_arrows/2_circles_with_arrows'
  | 'Shapes_with_arrows/Compound_shape1_arrows'
  | 'Shapes_with_arrows/Compound_shape2_arrows'
  | 'Shapes_with_arrows/Compound_shape3_arrows'
  | 'Shapes_with_arrows/Compound_shape4_arrows'
  | 'Shapes_with_arrows/Compound_shape5_arrows'
  | 'Shapes_with_arrows/Compound_shape6_arrows'
  | 'Shapes_with_arrows/Compound_shape7_arrows'
  | 'Shapes_with_arrows/Equilateral_triangle_1__arrow'
  | 'Shapes_with_arrows/Irregular_heptagon1_all_arrows'
  | 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_pentagon5_all_arrows'
  | 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_blue'
  | 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_green'
  | 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_pink'
  | 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_purple'
  | 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_yellow'
  | 'Shapes_with_arrows/Irregular_triangle_all_arrows'
  | 'Shapes_with_arrows/Isosceles_triangle_with_arrows_equal'
  | 'Shapes_with_arrows/Isosceles_triangle_with_arrows_short'
  | 'Shapes_with_arrows/Isosceles_triangle_with_arrows_tall'
  | 'Shapes_with_arrows/Labelled_compound_shape1'
  | 'Shapes_with_arrows/Labelled_compound_shape2'
  | 'Shapes_with_arrows/Labelled_compound_shape3'
  | 'Shapes_with_arrows/Labelled_compound_shape4'
  | 'Shapes_with_arrows/Labelled_compound_shape5'
  | 'Shapes_with_arrows/Labelled_compound_shape6'
  | 'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled'
  | 'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled'
  | 'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled'
  | 'Shapes_with_arrows/Parallelogram3_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram3_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram4_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram4_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled'
  | 'Shapes_with_arrows/Parallelogram6_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram6_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled'
  | 'Shapes_with_arrows/Parallelogram8_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram8_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram9_2_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram9_3_arrows_right_angle'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle1'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle10'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle11'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle12'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle13'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle14'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle15'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle16'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle17'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle18'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle19'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle2'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle20'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle3'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle4'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle5'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle6'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle7'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle8'
  | 'Shapes_with_arrows/Parallelogram_and_rectangle9'
  | 'Shapes_with_arrows/Quadrilateral_all_arrows'
  | 'Shapes_with_arrows/Rectangle1_2_arrows'
  | 'Shapes_with_arrows/Rectangle2_2_arrows'
  | 'Shapes_with_arrows/Rectangle3_2_arrows'
  | 'Shapes_with_arrows/Rectangle4_2_arrows'
  | 'Shapes_with_arrows/Rectangle5_2_arrows'
  | 'Shapes_with_arrows/Rectangle6_2_arrows'
  | 'Shapes_with_arrows/Rectangle7_2_arrows'
  | 'Shapes_with_arrows/Rectangle8_4_arrows'
  | 'Shapes_with_arrows/Rectangle_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape10_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape10_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape11_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape11_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape12_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape12_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape13_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape13_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape14_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape14_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape15_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape15_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape16_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape16_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape17_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape17_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape18_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape18_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape19_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape19_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape1_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape1_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape20_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape20_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape21_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape22_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape23_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape24_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape25_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape26_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape27_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape28_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape29_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape2_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape2_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape30_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape31_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape32_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape33_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape34_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape35_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape36_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape37_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape38_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape3_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape3_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape4_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape4_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape5_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape5_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape6_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape6_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape7_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape7_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape8_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape8_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_yellow'
  | 'Shapes_with_arrows/Rectilinear_shape9_2_red_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape9_all_arrows'
  | 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_blue'
  | 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_green'
  | 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_pink'
  | 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_purple'
  | 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_yellow'
  | 'Shapes_with_arrows/Regular_decagon_1__arrow'
  | 'Shapes_with_arrows/Regular_heptagon_1__arrow'
  | 'Shapes_with_arrows/Regular_hexagon_1__arrow'
  | 'Shapes_with_arrows/Regular_nonagon_1__arrow'
  | 'Shapes_with_arrows/Regular_octagon_1__arrow'
  | 'Shapes_with_arrows/Regular_pentagon__1__arrow'
  | 'Shapes_with_arrows/Rhombus_all__sides_marked'
  | 'Shapes_with_arrows/Right_angle_triangle10_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle11_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle1_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle1_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle2_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle2_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle3_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle3_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle4_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle4_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle5_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle5_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle6_2_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle6_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle7_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle8_3_arrows'
  | 'Shapes_with_arrows/Right_angle_triangle9_3_arrows'
  | 'Shapes_with_arrows/Square_1_arrows'
  | 'Shapes_with_arrows/Square_2_arrows'
  | 'Shapes_with_arrows/Square__1__arrow'
  | 'Shapes_with_arrows/Square_all__sides_marked'
  | 'Shapes_with_arrows/Trapezium1_all_arrows'
  | 'Shapes_with_arrows/Trapezium2_all_arrows'
  | 'Shapes_with_arrows/Triangle10_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle1_2_arrows'
  | 'Shapes_with_arrows/Triangle1_3_arrows_no_right_angle'
  | 'Shapes_with_arrows/Triangle1_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle1_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle2_2_arrows'
  | 'Shapes_with_arrows/Triangle2_3_arrows_no_right_angle'
  | 'Shapes_with_arrows/Triangle2_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle2_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle3_2_arrows'
  | 'Shapes_with_arrows/Triangle3_3_arrows_no_right_angle'
  | 'Shapes_with_arrows/Triangle3_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle3_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle4_2_arrows'
  | 'Shapes_with_arrows/Triangle4_3_arrows_no_right_angle'
  | 'Shapes_with_arrows/Triangle4_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle4_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle5_2_arrows'
  | 'Shapes_with_arrows/Triangle5_3_arrows_no_right_angle'
  | 'Shapes_with_arrows/Triangle5_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle5_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle6_2_arrows'
  | 'Shapes_with_arrows/Triangle6_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle6_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle7_2_arrows'
  | 'Shapes_with_arrows/Triangle7_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle7_4_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle8_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle9_3_arrows_right_angle'
  | 'Shapes_with_arrows/Triangle9_4_arrows_right_angle'
  | 'Shapes_with_dimension_arrows/Square_blue_arrows_4'
  | 'Shapes_with_dimension_arrows/Square_green_arrows_4'
  | 'Shapes_with_dimension_arrows/Square_orange_arrows_4'
  | 'Shapes_with_dimension_arrows/Square_purple_arrows_4'
  | 'Shapes_with_dimension_arrows/Square_red_arrows_4'
  | 'Shapes_with_dimension_arrows/hexagon_blue_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_green_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_orange_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_purple_arrows'
  | 'Shapes_with_dimension_arrows/hexagon_red_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_blue_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_green_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_orange_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_purple_arrows'
  | 'Shapes_with_dimension_arrows/pentagon_red_arrows'
  | 'Shapes_with_dimension_arrows/rectangle_blue_arrows_2'
  | 'Shapes_with_dimension_arrows/rectangle_blue_arrows_4'
  | 'Shapes_with_dimension_arrows/rectangle_green_arrows_2'
  | 'Shapes_with_dimension_arrows/rectangle_green_arrows_4'
  | 'Shapes_with_dimension_arrows/rectangle_orange_arrows_2'
  | 'Shapes_with_dimension_arrows/rectangle_orange_arrows_4'
  | 'Shapes_with_dimension_arrows/rectangle_purple_arrows_2'
  | 'Shapes_with_dimension_arrows/rectangle_purple_arrows_4'
  | 'Shapes_with_dimension_arrows/rectangle_red_arrows_2'
  | 'Shapes_with_dimension_arrows/rectangle_red_arrows_4'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_blue_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_green_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_white_arrows'
  | 'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_RA_red_arrows'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_blue'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_green'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_orange'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_pink'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_purple'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_red'
  | 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_yellow'
  | 'Shapes_with_dimension_arrows/triangle_equal_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_equal_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_equal_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_equal_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_equal_red_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_wide_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_wide_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_wide_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_wide_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_isos_wide_red_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_green_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows'
  | 'Shapes_with_dimension_arrows/triangle_scalene_red_arrows'
  | 'Shapes_with_interior_angles/Equilateral_triangle_1_interior_angle'
  | 'Shapes_with_interior_angles/Equilateral_triangle_interior_angles'
  | 'Shapes_with_interior_angles/Hexagon1_interior_angles'
  | 'Shapes_with_interior_angles/Hexagon2_interior_angles'
  | 'Shapes_with_interior_angles/Hexagon3_interior_angles'
  | 'Shapes_with_interior_angles/Hexagon4_interior_angles'
  | 'Shapes_with_interior_angles/Hexagon5_interior_angles'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle1'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle10'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle11'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle12'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle13'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle14'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle2'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle3'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle4'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle5'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle6'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle7'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle8'
  | 'Shapes_with_interior_angles/Interior_angles_in_triangle9'
  | 'Shapes_with_interior_angles/Irregular_decagon1_with_interior_triangles_copy_5'
  | 'Shapes_with_interior_angles/Irregular_decagon2_with_interior_triangles_copy_5'
  | 'Shapes_with_interior_angles/Irregular_decagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_heptagon1_with_interior_triangles_copy_2'
  | 'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_heptagon2_with_interior_triangles_copy_2'
  | 'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_heptagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_hexagon1_with_interior_triangles_copy'
  | 'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_hexagon2_with_interior_triangles_copy'
  | 'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_hexagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Irregular_nonagon1_with_interior_triangles_copy_4'
  | 'Shapes_with_interior_angles/Irregular_nonagon2_with_interior_triangles_copy_4'
  | 'Shapes_with_interior_angles/Irregular_nonagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_octagon1_with_interior_triangles_copy_3'
  | 'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_octagon2_with_interior_triangles_copy_3'
  | 'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_octagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_pentagon1_with_interior_triangles'
  | 'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_pentagon2_with_interior_triangles'
  | 'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_pentagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral1_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral2_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral3_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_quadrilateral4_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_triangle1_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_triangle2_marked_interior_angles'
  | 'Shapes_with_interior_angles/Irregular_triangle3_marked_interior_angles'
  | 'Shapes_with_interior_angles/Isosceles_triangle1_interior_angles'
  | 'Shapes_with_interior_angles/Isosceles_triangle2_interior_angles'
  | 'Shapes_with_interior_angles/Isosceles_triangle3_interior_angles'
  | 'Shapes_with_interior_angles/Kite_two_isosceles_triangles1'
  | 'Shapes_with_interior_angles/Kite_two_isosceles_triangles2'
  | 'Shapes_with_interior_angles/Kite_two_isosceles_triangles3'
  | 'Shapes_with_interior_angles/Kite_two_isosceles_triangles4'
  | 'Shapes_with_interior_angles/Parallelogram1_interior_angles'
  | 'Shapes_with_interior_angles/Parallelogram1_with_interior_triangles'
  | 'Shapes_with_interior_angles/Parallelogram2_interior_angles'
  | 'Shapes_with_interior_angles/Parallelogram2_with_interior_triangles'
  | 'Shapes_with_interior_angles/Parallelogram3_interior_angles'
  | 'Shapes_with_interior_angles/Parallelogram4_interior_angles'
  | 'Shapes_with_interior_angles/Pentagon1_interior_angles'
  | 'Shapes_with_interior_angles/Pentagon2_interior_angles'
  | 'Shapes_with_interior_angles/Pentagon3_interior_angles'
  | 'Shapes_with_interior_angles/Pentagon4_interior_angles'
  | 'Shapes_with_interior_angles/Pentagon5_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral1_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral1_with_interior_triangles'
  | 'Shapes_with_interior_angles/Quadrilateral2_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral2_with_interior_triangles'
  | 'Shapes_with_interior_angles/Quadrilateral3_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral4_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral5_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral6_interior_angles'
  | 'Shapes_with_interior_angles/Quadrilateral7_interior_angles'
  | 'Shapes_with_interior_angles/Rectangle1_interior_angles'
  | 'Shapes_with_interior_angles/Rectangle1_interior_lines_and_angles'
  | 'Shapes_with_interior_angles/Rectangle2_interior_angles'
  | 'Shapes_with_interior_angles/Rectangle2_interior_lines_and_angles'
  | 'Shapes_with_interior_angles/Rectangle3_interior_lines_and_angles'
  | 'Shapes_with_interior_angles/Rectangle4_interior_lines_and_angles'
  | 'Shapes_with_interior_angles/Rectangle_with_interior_triangles'
  | 'Shapes_with_interior_angles/Regular_decagon_1_interior_angle'
  | 'Shapes_with_interior_angles/Regular_decagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Regular_decagon_with_interior_triangles'
  | 'Shapes_with_interior_angles/Regular_heptagon_1_interior_angle'
  | 'Shapes_with_interior_angles/Regular_heptagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Regular_heptagon_with_interior_triangles'
  | 'Shapes_with_interior_angles/Regular_hexagon_1_interior_angle'
  | 'Shapes_with_interior_angles/Regular_hexagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Regular_hexagon_with_interior_triangles'
  | 'Shapes_with_interior_angles/Regular_nonagon_1_interior_angle'
  | 'Shapes_with_interior_angles/Regular_nonagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Regular_nonagon_with_interior_triangles'
  | 'Shapes_with_interior_angles/Regular_octagon_1_interior_angle'
  | 'Shapes_with_interior_angles/Regular_octagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Regular_octagon_with_interior_triangles'
  | 'Shapes_with_interior_angles/Regular_pentagon_1_interior_angle'
  | 'Shapes_with_interior_angles/Regular_pentagon_one_interior_angle'
  | 'Shapes_with_interior_angles/Regular_pentagon_with_interior_triangles'
  | 'Shapes_with_interior_angles/Rhombus1_interior_angles'
  | 'Shapes_with_interior_angles/Rhombus2_interior_angles'
  | 'Shapes_with_interior_angles/Rhombus3_interior_angles'
  | 'Shapes_with_interior_angles/Rhombus4_interior_angles'
  | 'Shapes_with_interior_angles/Rhombus_with_interior_triangles'
  | 'Shapes_with_interior_angles/Right_angled_triangle_interior_angles'
  | 'Shapes_with_interior_angles/Scalene_triangle1_interior_angles'
  | 'Shapes_with_interior_angles/Scalene_triangle2_interior_angles'
  | 'Shapes_with_interior_angles/Scalene_triangle3_interior_angles'
  | 'Shapes_with_interior_angles/Scalene_triangle4_interior_angles'
  | 'Shapes_with_interior_angles/Scalene_triangle5_interior_angles'
  | 'Shapes_with_interior_angles/Square_1_interior_angle'
  | 'Shapes_with_interior_angles/Square_interior_angles'
  | 'Shapes_with_interior_angles/Square_with_interior_triangles'
  | 'Shapes_with_interior_angles/Trapezium1_interior_angles'
  | 'Shapes_with_interior_angles/Trapezium1_with_interior_triangles'
  | 'Shapes_with_interior_angles/Trapezium2_interior_angles'
  | 'Shapes_with_interior_angles/Trapezium2_with_interior_triangles'
  | 'Shapes_with_interior_angles/Trapezium3_interior_angles'
  | 'Shapes_with_interior_angles/Trapezium4_interior_angles'
  | 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_blue'
  | 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_green'
  | 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_marked'
  | 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_pink'
  | 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_purple'
  | 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_yellow'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles1_markedlines_yellow'
  | 'Shapes_with_marked_sides/Isosceles2_markedlines'
  | 'Shapes_with_marked_sides/Isosceles2_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles2_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles2_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles2_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles2_markedlines_yellow'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles3_markedlines_yellow'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_blue'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_green'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_pink'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_purple'
  | 'Shapes_with_marked_sides/Isosceles4_markedlines_yellow'
  | 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked'
  | 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_yellow'
  | 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked'
  | 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_yellow'
  | 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked'
  | 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_yellow'
  | 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked'
  | 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_yellow'
  | 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked'
  | 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_yellow'
  | 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked'
  | 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_yellow'
  | 'Shapes_with_marked_sides/Square_all__sides_marked_blue'
  | 'Shapes_with_marked_sides/Square_all__sides_marked_green'
  | 'Shapes_with_marked_sides/Square_all__sides_marked_pink'
  | 'Shapes_with_marked_sides/Square_all__sides_marked_purple'
  | 'Shapes_with_marked_sides/Square_all__sides_marked_yellow'
  | 'Shapes_with_missing_parts/Missing_part_shape1'
  | 'Shapes_with_missing_parts/Missing_part_shape10'
  | 'Shapes_with_missing_parts/Missing_part_shape2'
  | 'Shapes_with_missing_parts/Missing_part_shape3'
  | 'Shapes_with_missing_parts/Missing_part_shape4'
  | 'Shapes_with_missing_parts/Missing_part_shape5'
  | 'Shapes_with_missing_parts/Missing_part_shape6'
  | 'Shapes_with_missing_parts/Missing_part_shape7'
  | 'Shapes_with_missing_parts/Missing_part_shape8'
  | 'Shapes_with_missing_parts/Missing_part_shape9'
  | 'Shed'
  | 'Sheep'
  | 'Shopping_trolley'
  | 'Sink'
  | 'SliderArrow'
  | 'Soap_dispenser'
  | 'Spider'
  | 'Spinner'
  | 'Sports_ball_football'
  | 'Sports_ball_rugby_ball'
  | 'Square'
  | 'Square/square'
  | 'Square/square_1_arrow'
  | 'Square/square_1_arrow_blue'
  | 'Square/square_1_arrow_green'
  | 'Square/square_1_arrow_pink'
  | 'Square/square_1_arrow_purple'
  | 'Square/square_1_arrow_yellow'
  | 'Square/square_blue'
  | 'Square/square_green'
  | 'Square/square_grey'
  | 'Square/square_orange'
  | 'Square/square_pink'
  | 'Square/square_purple'
  | 'Square/square_red'
  | 'Square/square_turquoise'
  | 'Square/square_white'
  | 'Square/square_yellow'
  | 'Stack_of_Cubes_in_3D_Shape/cube_cubes'
  | 'Stack_of_Cubes_in_3D_Shape/cylinder_cubes'
  | 'Stack_of_Cubes_in_3D_Shape/hexagonal_prism_cubes'
  | 'Stack_of_Cubes_in_3D_Shape/square_based_pyramid_cubes'
  | 'Stack_of_Cubes_in_3D_Shape/triangular_prism_cubes'
  | 'Star'
  | 'Star_blue'
  | 'Star_green'
  | 'Star_pink'
  | 'Star_purple'
  | 'Star_red'
  | 'Star_yellow'
  | 'Sticker_sheet'
  | 'Stopwatch'
  | 'Suitcase'
  | 'Sunflower'
  | 'Sweets/SweetBlue'
  | 'Sweets/SweetGreen'
  | 'Sweets/SweetPink'
  | 'Sweets/SweetRed'
  | 'SymmetricalShapes/both11_green'
  | 'SymmetricalShapes/both11_pink'
  | 'SymmetricalShapes/both11_purple'
  | 'SymmetricalShapes/both11_white'
  | 'SymmetricalShapes/both11_yellow'
  | 'SymmetricalShapes/both12_green'
  | 'SymmetricalShapes/both12_pink'
  | 'SymmetricalShapes/both12_purple'
  | 'SymmetricalShapes/both12_white'
  | 'SymmetricalShapes/both12_yellow'
  | 'SymmetricalShapes/both1_green'
  | 'SymmetricalShapes/both1_pink'
  | 'SymmetricalShapes/both1_purple'
  | 'SymmetricalShapes/both1_white'
  | 'SymmetricalShapes/both1_yellow'
  | 'SymmetricalShapes/both3_green'
  | 'SymmetricalShapes/both3_pink'
  | 'SymmetricalShapes/both3_purple'
  | 'SymmetricalShapes/both3_white'
  | 'SymmetricalShapes/both3_yellow'
  | 'SymmetricalShapes/both4_green'
  | 'SymmetricalShapes/both4_pink'
  | 'SymmetricalShapes/both4_purple'
  | 'SymmetricalShapes/both4_white'
  | 'SymmetricalShapes/both4_yellow'
  | 'SymmetricalShapes/both6_green'
  | 'SymmetricalShapes/both6_pink'
  | 'SymmetricalShapes/both6_purple'
  | 'SymmetricalShapes/both6_white'
  | 'SymmetricalShapes/both6_yellow'
  | 'SymmetricalShapes/both8_green'
  | 'SymmetricalShapes/both8_pink'
  | 'SymmetricalShapes/both8_purple'
  | 'SymmetricalShapes/both8_white'
  | 'SymmetricalShapes/both8_yellow'
  | 'SymmetricalShapes/both9_green'
  | 'SymmetricalShapes/both9_pink'
  | 'SymmetricalShapes/both9_purple'
  | 'SymmetricalShapes/both9_white'
  | 'SymmetricalShapes/both9_yellow'
  | 'SymmetricalShapes/horizontal1_green'
  | 'SymmetricalShapes/horizontal1_pink'
  | 'SymmetricalShapes/horizontal1_purple'
  | 'SymmetricalShapes/horizontal1_white'
  | 'SymmetricalShapes/horizontal1_yellow'
  | 'SymmetricalShapes/horizontal2_green'
  | 'SymmetricalShapes/horizontal2_pink'
  | 'SymmetricalShapes/horizontal2_purple'
  | 'SymmetricalShapes/horizontal2_white'
  | 'SymmetricalShapes/horizontal2_yellow'
  | 'SymmetricalShapes/horizontal3_green'
  | 'SymmetricalShapes/horizontal3_pink'
  | 'SymmetricalShapes/horizontal3_purple'
  | 'SymmetricalShapes/horizontal3_white'
  | 'SymmetricalShapes/horizontal3_yellow'
  | 'SymmetricalShapes/horizontal4_green'
  | 'SymmetricalShapes/horizontal4_pink'
  | 'SymmetricalShapes/horizontal4_purple'
  | 'SymmetricalShapes/horizontal4_white'
  | 'SymmetricalShapes/horizontal4_yellow'
  | 'SymmetricalShapes/vertical1_green'
  | 'SymmetricalShapes/vertical1_pink'
  | 'SymmetricalShapes/vertical1_purple'
  | 'SymmetricalShapes/vertical1_white'
  | 'SymmetricalShapes/vertical1_yellow'
  | 'SymmetricalShapes/vertical2_green'
  | 'SymmetricalShapes/vertical2_pink'
  | 'SymmetricalShapes/vertical2_purple'
  | 'SymmetricalShapes/vertical2_white'
  | 'SymmetricalShapes/vertical2_yellow'
  | 'SymmetricalShapes/vertical3_green'
  | 'SymmetricalShapes/vertical3_pink'
  | 'SymmetricalShapes/vertical3_purple'
  | 'SymmetricalShapes/vertical3_white'
  | 'SymmetricalShapes/vertical3_yellow'
  | 'Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_10_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_11_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_12_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_13_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_15_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_19_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_20_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_21_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_22_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_23_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_26_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_4_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_d'
  | 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_c'
  | 'Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_a'
  | 'Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_b'
  | 'Symmetrical_shapes_with_lines/shape_9_line_of_symmetry_d'
  | 'Teddy_bear_1'
  | 'Teddy_bear_2'
  | 'Television'
  | 'Thermometers/Horizontal_Thermometer_filled'
  | 'Thermometers/Vertical_Thermometer_blank'
  | 'Tiny'
  | 'Tomato_sauce_ketchup'
  | 'Toolbox'
  | 'Toy_car'
  | 'Transparent_barrel'
  | 'Transport_toys/Train_carriage'
  | 'Transport_toys/Transport_toys_Car'
  | 'Transport_toys/Transport_toys_Sailboat'
  | 'Transport_toys/Transport_toys_Train'
  | 'Transport_toys/Transport_toys_plane'
  | 'Trapezium/trapezium_isosceles'
  | 'Trapezium/trapezium_isosceles_blue'
  | 'Trapezium/trapezium_isosceles_blue_one_interior_angle_lhs'
  | 'Trapezium/trapezium_isosceles_blue_one_interior_angle_rhs'
  | 'Trapezium/trapezium_isosceles_green'
  | 'Trapezium/trapezium_isosceles_green_one_interior_angle_lhs'
  | 'Trapezium/trapezium_isosceles_green_one_interior_angle_rhs'
  | 'Trapezium/trapezium_isosceles_orange'
  | 'Trapezium/trapezium_isosceles_pink'
  | 'Trapezium/trapezium_isosceles_pink_one_interior_angle_lhs'
  | 'Trapezium/trapezium_isosceles_pink_one_interior_angle_rhs'
  | 'Trapezium/trapezium_isosceles_purple'
  | 'Trapezium/trapezium_isosceles_purple_one_interior_angle_lhs'
  | 'Trapezium/trapezium_isosceles_purple_one_interior_angle_rhs'
  | 'Trapezium/trapezium_isosceles_red'
  | 'Trapezium/trapezium_isosceles_turquoise'
  | 'Trapezium/trapezium_isosceles_white'
  | 'Trapezium/trapezium_isosceles_yellow'
  | 'Trapezium/trapezium_isosceles_yellow_one_interior_angle_lhs'
  | 'Trapezium/trapezium_isosceles_yellow_one_interior_angle_rhs'
  | 'Tree_A'
  | 'Tree_B'
  | 'Triangle'
  | 'TriangleMan'
  | 'TriangleWhite'
  | 'True'
  | 'Tube_of_crisps'
  | 'Turns_and_angles/Fullturn_antclockwise'
  | 'Turns_and_angles/Fullturn_clockwise'
  | 'Turns_and_angles/Halfturn_anticlockwise'
  | 'Turns_and_angles/Halfturn_clockwise'
  | 'Turns_and_angles/Quarterturn_anticlockwise'
  | 'Turns_and_angles/Quarterturn_clockwise'
  | 'Turns_and_angles/Threequarterturn_anticlockwise'
  | 'Turns_and_angles/Threequarterturn_clockwise'
  | 'Unbroken_shapes/arrow_transparent'
  | 'Unbroken_shapes/circle_semi_transparent'
  | 'Unbroken_shapes/circle_transparent'
  | 'Unbroken_shapes/cross_transparent'
  | 'Unbroken_shapes/hexagon_transparent'
  | 'Unbroken_shapes/kite_transparent'
  | 'Unbroken_shapes/parallelogram_transparent'
  | 'Unbroken_shapes/pentagon_house_transparent'
  | 'Unbroken_shapes/pentagon_transparent'
  | 'Unbroken_shapes/rectangle_transparent'
  | 'Unbroken_shapes/rhombus_transparent'
  | 'Unbroken_shapes/square_transparent'
  | 'Unbroken_shapes/trapezium_RA_transparent'
  | 'Unbroken_shapes/trapezium_isosceles_transparent'
  | 'Unbroken_shapes/triangle_RA_transparent'
  | 'Unbroken_shapes/triangle_equal_transparent'
  | 'Unbroken_shapes/triangle_isos_narrow_transparent'
  | 'Unbroken_shapes/triangle_isos_wide_transparent'
  | 'Unbroken_shapes/triangle_scalene_transparent'
  | 'Unequal_shapes_10_parts/Circle_unequal_10-10'
  | 'Unequal_shapes_10_parts/Pentagon_unequal_10-10'
  | 'Unequal_shapes_10_parts/Square_unequal_10-10'
  | 'Unequal_shapes_11_parts/Circle_unequal_11-11'
  | 'Unequal_shapes_11_parts/Rectangle_unequal_11-11'
  | 'Unequal_shapes_11_parts/Square_unequal_11-11'
  | 'Unequal_shapes_12_parts/Circle_unequal_12-12'
  | 'Unequal_shapes_12_parts/Rectangle_unequal_12-12'
  | 'Unequal_shapes_12_parts/Square_unequal_12-12'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_blue'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_green'
  | 'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_yellow'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_4_blue'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_4_green'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_4_yellow'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_5_blue'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_5_green'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_5_yellow'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_6_blue'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_6_green'
  | 'Unequal_shapes_2_parts/Circle_unequal_1_2_6_yellow'
  | 'Unequal_shapes_2_parts/Heart_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Heart_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Heart_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Heart_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Heart_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Heart_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Kite_unequal_1-2'
  | 'Unequal_shapes_2_parts/Kite_unequal_2-2'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_blue'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_green'
  | 'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_yellow'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_blue'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_green'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_yellow'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_blue'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_green'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_yellow'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_blue'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_green'
  | 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_1-2'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_4_blue'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_4_green'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_4_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_5_blue'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_5_green'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_5_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_6_blue'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_6_green'
  | 'Unequal_shapes_2_parts/Square_unequal_1_2_6_yellow'
  | 'Unequal_shapes_2_parts/Square_unequal_2-2'
  | 'Unequal_shapes_2_parts/Star_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Star_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Star_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Star_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Star_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Star_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1-2'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_blue'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_green'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_yellow'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_blue'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_green'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_yellow'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_blue'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_green'
  | 'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_yellow'
  | 'Unequal_shapes_2_parts/Triangle_unequal_2-2'
  | 'Unequal_shapes_3_parts/Circle_unequal_3-1_1_blue'
  | 'Unequal_shapes_3_parts/Circle_unequal_3-1_1_green'
  | 'Unequal_shapes_3_parts/Circle_unequal_3-1_1_yellow'
  | 'Unequal_shapes_3_parts/Circle_unequal_3-3'
  | 'Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_blue'
  | 'Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_green'
  | 'Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_yellow'
  | 'Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_blue'
  | 'Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_green'
  | 'Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_yellow'
  | 'Unequal_shapes_3_parts/Square_unequal_1-3'
  | 'Unequal_shapes_3_parts/Square_unequal_3-1_1_blue'
  | 'Unequal_shapes_3_parts/Square_unequal_3-1_1_green'
  | 'Unequal_shapes_3_parts/Square_unequal_3-1_1_yellow'
  | 'Unequal_shapes_3_parts/Square_unequal_3-3'
  | 'Unequal_shapes_3_parts/Triangle_unequal_1-3'
  | 'Unequal_shapes_3_parts/Triangle_unequal_3-1_1_blue'
  | 'Unequal_shapes_3_parts/Triangle_unequal_3-1_1_green'
  | 'Unequal_shapes_3_parts/Triangle_unequal_3-1_1_yellow'
  | 'Unequal_shapes_3_parts/Triangle_unequal_3-3'
  | 'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Arrow_unequal_3_4_1_blue'
  | 'Unequal_shapes_4_parts/Arrow_unequal_3_4_1_green'
  | 'Unequal_shapes_4_parts/Arrow_unequal_3_4_1_yellow'
  | 'Unequal_shapes_4_parts/Circle_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Circle_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Circle_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Circle_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Circle_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Circle_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Circle_unequal_3_4_1_blue'
  | 'Unequal_shapes_4_parts/Circle_unequal_3_4_1_green'
  | 'Unequal_shapes_4_parts/Circle_unequal_3_4_1_yellow'
  | 'Unequal_shapes_4_parts/Circle_unequal_4-4'
  | 'Unequal_shapes_4_parts/Cross_unequal_1-4'
  | 'Unequal_shapes_4_parts/Cross_unequal_3-4'
  | 'Unequal_shapes_4_parts/Cross_unequal_4-4'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_3_blue'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_3_green'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_3_yellow'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_4_blue'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_4_green'
  | 'Unequal_shapes_4_parts/Heart_unequal_1_4_4_yellow'
  | 'Unequal_shapes_4_parts/Heart_unequal_3_4_3_blue'
  | 'Unequal_shapes_4_parts/Heart_unequal_3_4_3_green'
  | 'Unequal_shapes_4_parts/Heart_unequal_3_4_3_yellow'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_blue'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_green'
  | 'Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_yellow'
  | 'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_blue'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_green'
  | 'Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_yellow'
  | 'Unequal_shapes_4_parts/Square_unequal_1-4'
  | 'Unequal_shapes_4_parts/Square_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Square_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Square_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Square_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Square_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Square_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Square_unequal_3_4_1_blue'
  | 'Unequal_shapes_4_parts/Square_unequal_3_4_1_green'
  | 'Unequal_shapes_4_parts/Square_unequal_3_4_1_yellow'
  | 'Unequal_shapes_4_parts/Square_unequal_4-4'
  | 'Unequal_shapes_4_parts/Star_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Star_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Star_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Star_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Star_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Star_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_blue'
  | 'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_green'
  | 'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_yellow'
  | 'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_blue'
  | 'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_green'
  | 'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_yellow'
  | 'Unequal_shapes_4_parts/Triangle_unequal_3_4_1_blue'
  | 'Unequal_shapes_4_parts/Triangle_unequal_3_4_1_green'
  | 'Unequal_shapes_4_parts/Triangle_unequal_3_4_1_yellow'
  | 'Unequal_shapes_5_parts/Circle_unequal_5-5'
  | 'Unequal_shapes_5_parts/Pentagon_unequal_1-5'
  | 'Unequal_shapes_5_parts/Pentagon_unequal_5-5'
  | 'Unequal_shapes_5_parts/Square_unequal_1-5'
  | 'Unequal_shapes_5_parts/Square_unequal_5-5'
  | 'Unequal_shapes_6_parts/Circle_unequal_6-6'
  | 'Unequal_shapes_6_parts/Hexagon_unequal6-6'
  | 'Unequal_shapes_6_parts/Square_unequal_6-6'
  | 'Unequal_shapes_7_parts/Circle_unequal_7-7'
  | 'Unequal_shapes_7_parts/Rectangle_unequal_7-7'
  | 'Unequal_shapes_7_parts/Square_unequal_7-7'
  | 'Unequal_shapes_8_parts/Circle_unequal_8-8'
  | 'Unequal_shapes_8_parts/Cross_unequal_8-8'
  | 'Unequal_shapes_8_parts/Square_unequal_8-8'
  | 'Unequal_shapes_9_parts/Circle_unequal_9-9'
  | 'Unequal_shapes_9_parts/Rectangle_unequal_9-9'
  | 'Unequal_shapes_9_parts/Square_unequal_9-9'
  | 'Wardrobe'
  | 'Water_bottle'
  | 'Water_bottle_glass'
  | 'Water_bottle_glass_empty'
  | 'Water_bottles_pack_of_12'
  | 'Watering_can'
  | 'Watermelon'
  | 'Wavy_circles/Wavy_circle_blue'
  | 'Wavy_circles/Wavy_circle_green'
  | 'Wavy_circles/Wavy_circle_orange'
  | 'Wavy_circles/Wavy_circle_pink'
  | 'Wavy_circles/Wavy_circle_purple'
  | 'Wavy_circles/Wavy_circle_red'
  | 'Wavy_circles/Wavy_circle_turquoise'
  | 'Wavy_circles/Wavy_circle_yellow'
  | 'Weights/Weight'
  | 'Weights/Weights_100g'
  | 'Weights/Weights_10g'
  | 'Weights/Weights_1g'
  | 'Weights/Weights_1kg'
  | 'Weights/Weights_200g'
  | 'Weights/Weights_20g'
  | 'Weights/Weights_20kg'
  | 'Weights/Weights_25g'
  | 'Weights/Weights_2g'
  | 'Weights/Weights_2kg'
  | 'Weights/Weights_500g'
  | 'Weights/Weights_50g'
  | 'Weights/Weights_50kg'
  | 'Weights/Weights_5g'
  | 'Weights/Weights_5kg'
  | 'WhiteRoseInfinity'
  | 'WhiteRoseInfinityDark'
  | 'White_van'
  | 'Whole_shapes/Whole_shape_1'
  | 'Whole_shapes/Whole_shape_2'
  | 'Whole_shapes/Whole_shape_3'
  | 'Whole_shapes/Whole_shape_5'
  | 'Wooden_barrel'
  | 'Wooden_crate'
  | 'Worm'
  | 'Wrapped_gifts_blue'
  | 'Wrapped_gifts_pink'
  | 'Wrapped_gifts_red'
  | 'WrmLogo'
  | 'Yoyo'
  | 'bird_blue'
  | 'bowls/Bowl_Beans'
  | 'bowls/Bowl_Cereal'
  | 'bowls/Bowl_Empty'
  | 'bowls/Bowl_Rice'
  | 'cafe_birds_eye_view'
  | 'cupOfSand'
  | 'cup_red'
  | 'drinking_straw'
  | 'fingers/Hand_1'
  | 'fingers/Hand_2'
  | 'fingers/Hand_3'
  | 'fingers/Hand_4'
  | 'fingers/Hand_5'
  | 'full_swimming_pool'
  | 'glass_empty'
  | 'hospital_birds_eye_view'
  | 'icecream'
  | 'lollipop_ball'
  | 'lollipop_stick'
  | 'marshmallow'
  | 'park_birds_eye_view'
  | 'park_birds_eye_view_with_arrows'
  | 'parts_of_a_whole/bicycle/Bicycle'
  | 'parts_of_a_whole/bicycle/Frame'
  | 'parts_of_a_whole/bicycle/Handlebar'
  | 'parts_of_a_whole/bicycle/Helmet'
  | 'parts_of_a_whole/bicycle/Pedal'
  | 'parts_of_a_whole/bicycle/Seat'
  | 'parts_of_a_whole/bicycle/Wheel'
  | 'parts_of_a_whole/house/Chimney'
  | 'parts_of_a_whole/house/Door_black'
  | 'parts_of_a_whole/house/Door_blue'
  | 'parts_of_a_whole/house/Door_red'
  | 'parts_of_a_whole/house/House'
  | 'parts_of_a_whole/house/Roof_black'
  | 'parts_of_a_whole/house/Roof_brown'
  | 'parts_of_a_whole/house/Window_bottomLeft'
  | 'parts_of_a_whole/house/Window_topLeft'
  | 'parts_of_a_whole/house/Window_topRight'
  | 'parts_of_a_whole/icecream/Cone'
  | 'parts_of_a_whole/icecream/Flake'
  | 'parts_of_a_whole/icecream/Icecream'
  | 'parts_of_a_whole/icecream/Scoop_chocolate'
  | 'parts_of_a_whole/icecream/Scoop_mint'
  | 'parts_of_a_whole/icecream/Scoop_strawberry'
  | 'parts_of_a_whole/icecream/Scoop_vanilla'
  | 'parts_of_a_whole/icecream/Sprinkles'
  | 'parts_of_a_whole/icecream/Tub'
  | 'pencil_packs/Pack_of_pencils_10'
  | 'pencil_packs/Pack_of_pencils_100'
  | 'pencil_packs/Pack_of_pencils_unlabelled'
  | 'pencil_rubber'
  | 'pencil_tip'
  | 'pool_birds_eye_view'
  | 'ruler_15_cm'
  | 'ruler_8_cm'
  | 'school_birds_eye_view'
  | 'stick'
  | 'tablespoon'
  | 'teacup'
  | 'tennis_ball'
  | 'trees_birds_eye_view'
  | 'triangle_grey'
  | 'zest/rotate-left';

export type SvgName = SvgNameCustomizable | SvgNameFixed;

export type SvgWithNaturalDimensionsCustomizable = {
  Svg: FC<SvgProps>;
  defaultWidth?: number;
  defaultHeight?: number;
  width: number;
  height: number;
  aspectRatio: number;
};

export type SvgWithNaturalDimensionsFixed = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  asset: any;
  defaultWidth?: number;
  defaultHeight?: number;
  width: number;
  height: number;
  aspectRatio: number;
};

export type SvgWithNaturalDimensions =
  | SvgWithNaturalDimensionsCustomizable
  | SvgWithNaturalDimensionsFixed;

/**
 * Get SVG asset (result of require('foo.svg') or .tsx file using react-native-svg) with its natural dimensions
 * information.
 */
export function getSvgInfo(name: SvgNameFixed): SvgWithNaturalDimensionsFixed;
export function getSvgInfo(name: SvgNameCustomizable): SvgWithNaturalDimensionsCustomizable;
export function getSvgInfo(name: SvgName): SvgWithNaturalDimensions;
export function getSvgInfo(name: SvgName): SvgWithNaturalDimensions {
  // Note: if perfomance is an issue, consider refactoring this into an object of functions, where with `name`
  // as the keys, and each function is of type `() => SvgWithNaturalDimensions`. The idea is that this would
  // be faster because object key lookup uses hashing, whereas switch statements are evaluated in series.
  // However, JS might be clever about this, and optimize simple switch cases like this - I'm not sure.
  switch (name) {
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cone1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCone1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cone2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCone2IsometricPaper,
        defaultWidth: 395,
        defaultHeight: 362,
        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cone3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCone3IsometricPaper,
        defaultWidth: 395,
        defaultHeight: 362,
        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cone4_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCone4IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCylinder1IsometricPaper,
        defaultWidth: 395,
        defaultHeight: 362,
        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCylinder2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_cylinder3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeCylinder3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeHexagonalPrism1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeHexagonalPrism2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_hexagonal_prism3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeHexagonalPrism3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapePentagonalPrism1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapePentagonalPrism2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_pentagonal_prism3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapePentagonalPrism3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeSquareBasedPyramid1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeSquareBasedPyramid2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_square_based_pyramid3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeSquareBasedPyramid3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeTriangularBasedPyramid1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeTriangularBasedPyramid2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_based_pyramid3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeTriangularBasedPyramid3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeTriangularPrism1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeTriangularPrism2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_outline_shape_triangular_prism3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DOutlineShapeTriangularPrism3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cone1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCone1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cone2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCone2IsometricPaper,
        defaultWidth: 395,
        defaultHeight: 362,
        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cone3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCone3IsometricPaper,
        defaultWidth: 395,
        defaultHeight: 362,
        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cone4_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCone4IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cylinder1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCylinder1IsometricPaper,
        defaultWidth: 395,
        defaultHeight: 362,
        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cylinder2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCylinder2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_cylinder3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeCylinder3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeHexagonalPrism1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeHexagonalPrism2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_hexagonal_prism3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeHexagonalPrism3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapePentagonalPrism1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapePentagonalPrism2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_pentagonal_prism3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapePentagonalPrism3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeSquareBasedPyramid1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeSquareBasedPyramid2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_square_based_pyramid3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeSquareBasedPyramid3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeTriangularBasedPyramid1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeTriangularBasedPyramid2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_triangular_based_pyramid3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeTriangularBasedPyramid3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism1_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeTriangularPrism1IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism2_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeTriangularPrism2IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_on_isometric_paper/3D_shape_triangular_prism3_isometric_paper':
      return {
        asset: Num3DShapesOnIsometricPaper3DShapeTriangularPrism3IsometricPaper,

        width: 395,
        height: 362,
        aspectRatio: 1.091160220994475
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape10_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape10MultiLinkCubes,

        width: 217.45079,
        height: 285.15082,
        aspectRatio: 0.7625816751991105
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape11_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape11MultiLinkCubes,

        width: 216.4328,
        height: 216.43027,
        aspectRatio: 1.000011689677234
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape12_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape12MultiLinkCubes,

        width: 216.78065,
        height: 259.0368,
        aspectRatio: 0.8368720197284709
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape13_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape13MultiLinkCubes,

        width: 258.0628,
        height: 229.38232,
        aspectRatio: 1.1250335248156875
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape14_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape14MultiLinkCubes,

        width: 270.60345,
        height: 309.40994,
        aspectRatio: 0.8745790455212913
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape15_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape15MultiLinkCubes,

        width: 216.1192,
        height: 373.43961,
        aspectRatio: 0.5787259685709291
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape16_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape16MultiLinkCubes,

        width: 162.91279,
        height: 279.76547,
        aspectRatio: 0.5823191475345403
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape17_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape17MultiLinkCubes,

        width: 216.32546,
        height: 216.78287,
        aspectRatio: 0.9978900085601782
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape18_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape18MultiLinkCubes,

        width: 270.04785,
        height: 266.59543,
        aspectRatio: 1.012950034439825
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape19_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape19MultiLinkCubes,

        width: 162.61053,
        height: 423.52847,
        aspectRatio: 0.3839423829052153
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape1_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape1MultiLinkCubes,

        width: 154.69814,
        height: 228.79234,
        aspectRatio: 0.676150871134934
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape20_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape20MultiLinkCubes,

        width: 425.13785,
        height: 339.52661,
        aspectRatio: 1.252148837465199
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape21_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape21MultiLinkCubes,

        width: 323.91,
        height: 292.36841,
        aspectRatio: 1.1078830301809968
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape22_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape22MultiLinkCubes,

        width: 216.49039,
        height: 309.93201,
        aspectRatio: 0.698509295635517
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape23_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape23MultiLinkCubes,

        width: 269.77667,
        height: 402.76492,
        aspectRatio: 0.6698117353417969
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape24_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape24MultiLinkCubes,

        width: 269.74667,
        height: 242.3284,
        aspectRatio: 1.1131450956635707
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape25_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape25MultiLinkCubes,

        width: 269.74667,
        height: 199.38121,
        aspectRatio: 1.3529192144034032
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape26_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape26MultiLinkCubes,

        width: 162.87959,
        height: 242.14667,
        aspectRatio: 0.6726484820129883
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape27_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape27MultiLinkCubes,

        width: 216.88626,
        height: 242.1544,
        aspectRatio: 0.8956527736022967
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape28_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape28MultiLinkCubes,

        width: 215.99319,
        height: 369.65545,
        aspectRatio: 0.584309496857141
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape29_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape29MultiLinkCubes,

        width: 317.96478,
        height: 352.47693,
        aspectRatio: 0.9020867833818231
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape2_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape2MultiLinkCubes,

        width: 203.87906,
        height: 292.67486,
        aspectRatio: 0.6966059879553713
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape30_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape30MultiLinkCubes,

        width: 323.99359,
        height: 224.64574,
        aspectRatio: 1.442242305596358
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape31_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape31MultiLinkCubes,

        width: 323.99359,
        height: 224.64562,
        aspectRatio: 1.4422430760056661
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape32_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape32MultiLinkCubes,

        width: 322.85638,
        height: 225.12454,
        aspectRatio: 1.4341234411850436
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape33_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape33MultiLinkCubes,

        width: 269.74668,
        height: 268.00122,
        aspectRatio: 1.0065128807995727
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape34_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape34MultiLinkCubes,

        width: 216.44507,
        height: 377.82428,
        aspectRatio: 0.5728723151407845
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape35_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape35MultiLinkCubes,

        width: 216.44507,
        height: 377.33948,
        aspectRatio: 0.5736083327405868
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape36_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape36MultiLinkCubes,

        width: 270.32572,
        height: 403.26294,
        aspectRatio: 0.6703460526275982
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape37_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape37MultiLinkCubes,

        width: 323.11331,
        height: 361.31613,
        aspectRatio: 0.8942676043828988
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape38_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape38MultiLinkCubes,

        width: 210.71387,
        height: 343.88898,
        aspectRatio: 0.6127380702923367
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape39_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape39MultiLinkCubes,

        width: 213.66707,
        height: 440.00567,
        aspectRatio: 0.48560071964527185
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape3_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape3MultiLinkCubes,

        width: 154.08173,
        height: 268.53635,
        aspectRatio: 0.5737835119900899
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape40_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape40MultiLinkCubes,

        width: 213.10826,
        height: 305.90677,
        aspectRatio: 0.6966444711243233
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape41_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape41MultiLinkCubes,

        width: 163.61,
        height: 324.30521,
        aspectRatio: 0.5044938994350415
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape42_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape42MultiLinkCubes,

        width: 204.70706,
        height: 229.06685,
        aspectRatio: 0.8936564151469321
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape43_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape43MultiLinkCubes,

        width: 323.2944,
        height: 292.51331,
        aspectRatio: 1.105229707325113
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape44_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape44MultiLinkCubes,

        width: 376.65854,
        height: 318.43103,
        aspectRatio: 1.1828575249089262
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape45_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape45MultiLinkCubes,

        width: 255.02853,
        height: 253.56404,
        aspectRatio: 1.0057756218113578
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape46_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape46MultiLinkCubes,

        width: 269.76227,
        height: 334.78238,
        aspectRatio: 0.8057839543407272
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape47_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape47MultiLinkCubes,

        width: 376.65106,
        height: 292.5133,
        aspectRatio: 1.2876373826420882
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape4_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape4MultiLinkCubes,

        width: 163.26399,
        height: 258.32507,
        aspectRatio: 0.6320098548700674
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape5_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape5MultiLinkCubes,

        width: 162.61186,
        height: 216.03267,
        aspectRatio: 0.7527188364611704
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape6_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape6MultiLinkCubes,

        width: 204.56172,
        height: 292.92593,
        aspectRatio: 0.6983394061427065
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape7_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape7MultiLinkCubes,

        width: 204.56172,
        height: 228.92181,
        aspectRatio: 0.8935877276175652
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape8_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape8MultiLinkCubes,

        width: 270.36374,
        height: 268.51788,
        aspectRatio: 1.0068742535878803
      };
    case '3-D_shapes_with_multi-link_cubes/3D_shape9_multi_link_cubes':
      return {
        asset: Num3DShapesWithMultiLinkCubes3DShape9MultiLinkCubes,

        width: 163.21213,
        height: 258.12106,
        aspectRatio: 0.6323084602240515
      };
    case '3D_Shapes_Cubes/3D_shape10_cubes':
      return {
        asset: Num3DShapesCubes3DShape10Cubes,

        width: 113.75002,
        height: 216.09337,
        aspectRatio: 0.5263929198753299
      };
    case '3D_Shapes_Cubes/3D_shape11_cubes':
      return {
        asset: Num3DShapesCubes3DShape11Cubes,

        width: 150.75722,
        height: 237.85056,
        aspectRatio: 0.6338316798581428
      };
    case '3D_Shapes_Cubes/3D_shape12_cubes':
      return {
        asset: Num3DShapesCubes3DShape12Cubes,

        width: 150.07016,
        height: 216.3971,
        aspectRatio: 0.6934943213194631
      };
    case '3D_Shapes_Cubes/3D_shape13_cubes':
      return {
        asset: Num3DShapesCubes3DShape13Cubes,

        width: 186.51349,
        height: 237.76416,
        aspectRatio: 0.7844474541495235
      };
    case '3D_Shapes_Cubes/3D_shape14_cubes':
      return {
        asset: Num3DShapesCubes3DShape14Cubes,

        width: 150.07016,
        height: 259.41657,
        aspectRatio: 0.5784910347091552
      };
    case '3D_Shapes_Cubes/3D_shape15_cubes':
      return {
        asset: Num3DShapesCubes3DShape15Cubes,

        width: 149.17775,
        height: 215.90323,
        aspectRatio: 0.6909472822615947
      };
    case '3D_Shapes_Cubes/3D_shape16_cubes':
      return {
        asset: Num3DShapesCubes3DShape16Cubes,

        width: 186.73402,
        height: 237.34616,
        aspectRatio: 0.7867581257687084
      };
    case '3D_Shapes_Cubes/3D_shape17_cubes':
      return {
        asset: Num3DShapesCubes3DShape17Cubes,

        width: 186.51349,
        height: 259.47989,
        aspectRatio: 0.7187974759816647
      };
    case '3D_Shapes_Cubes/3D_shape18_cubes':
      return {
        asset: Num3DShapesCubes3DShape18Cubes,

        width: 186.73403,
        height: 238.28257,
        aspectRatio: 0.7836663420240935
      };
    case '3D_Shapes_Cubes/3D_shape19_cubes':
      return {
        asset: Num3DShapesCubes3DShape19Cubes,

        width: 185.84149,
        height: 152.5683,
        aspectRatio: 1.2180871780048674
      };
    case '3D_Shapes_Cubes/3D_shape1_cubes':
      return {
        asset: Num3DShapesCubes3DShape1Cubes,

        width: 76.742827,
        height: 173.26683,
        aspectRatio: 0.44291701417980583
      };
    case '3D_Shapes_Cubes/3D_shape20_cubes':
      return {
        asset: Num3DShapesCubes3DShape20Cubes,

        width: 149.17775,
        height: 237.31082,
        aspectRatio: 0.6286175657730229
      };
    case '3D_Shapes_Cubes/3D_shape21_cubes':
      return {
        asset: Num3DShapesCubes3DShape21Cubes,

        width: 187.07749,
        height: 216.09323,
        aspectRatio: 0.8657258258391529
      };
    case '3D_Shapes_Cubes/3D_shape22_cubes':
      return {
        asset: Num3DShapesCubes3DShape22Cubes,

        width: 150.7571,
        height: 259.53937,
        aspectRatio: 0.580864090099317
      };
    case '3D_Shapes_Cubes/3D_shape23_cubes':
      return {
        asset: Num3DShapesCubes3DShape23Cubes,

        width: 186.97469,
        height: 259.37537,
        aspectRatio: 0.7208652463801788
      };
    case '3D_Shapes_Cubes/3D_shape24_cubes':
      return {
        asset: Num3DShapesCubes3DShape24Cubes,

        width: 149.62416,
        height: 131.14296,
        aspectRatio: 1.1409240724778518
      };
    case '3D_Shapes_Cubes/3D_shape25_cubes':
      return {
        asset: Num3DShapesCubes3DShape25Cubes,

        width: 150.0703,
        height: 173.23377,
        aspectRatio: 0.8662877913469181
      };
    case '3D_Shapes_Cubes/3D_shape26_cubes':
      return {
        asset: Num3DShapesCubes3DShape26Cubes,

        width: 149.62416,
        height: 215.67056,
        aspectRatio: 0.6937625608242497
      };
    case '3D_Shapes_Cubes/3D_shape27_cubes':
      return {
        asset: Num3DShapesCubes3DShape27Cubes,

        width: 149.62416,
        height: 258.33991,
        aspectRatio: 0.5791755520856224
      };
    case '3D_Shapes_Cubes/3D_shape28_cubes':
      return {
        asset: Num3DShapesCubes3DShape28Cubes,

        width: 186.18495,
        height: 152.5063,
        aspectRatio: 1.2208344835590397
      };
    case '3D_Shapes_Cubes/3D_shape29_cubes':
      return {
        asset: Num3DShapesCubes3DShape29Cubes,

        width: 186.18495,
        height: 195.17563,
        aspectRatio: 0.9539354375338764
      };
    case '3D_Shapes_Cubes/3D_shape2_cubes':
      return {
        asset: Num3DShapesCubes3DShape2Cubes,

        width: 76.74283,
        height: 216.09337,
        aspectRatio: 0.3551373649270221
      };
    case '3D_Shapes_Cubes/3D_shape30_cubes':
      return {
        asset: Num3DShapesCubes3DShape30Cubes,

        width: 186.18495,
        height: 237.74162,
        aspectRatio: 0.783139906256212
      };
    case '3D_Shapes_Cubes/3D_shape31_cubes':
      return {
        asset: Num3DShapesCubes3DShape31Cubes,

        width: 186.73389,
        height: 237.0087,
        aspectRatio: 0.7878777867647896
      };
    case '3D_Shapes_Cubes/3D_shape32_cubes':
      return {
        asset: Num3DShapesCubes3DShape32Cubes,

        width: 222.40256,
        height: 174.28296,
        aspectRatio: 1.2761004288657938
      };
    case '3D_Shapes_Cubes/3D_shape33_cubes':
      return {
        asset: Num3DShapesCubes3DShape33Cubes,

        width: 223.25415,
        height: 216.70096,
        aspectRatio: 1.0302407059018106
      };
    case '3D_Shapes_Cubes/3D_shape34_cubes':
      return {
        asset: Num3DShapesCubes3DShape34Cubes,

        width: 223.25403,
        height: 259.37015,
        aspectRatio: 0.8607545239882075
      };
    case '3D_Shapes_Cubes/3D_shape35_cubes':
      return {
        asset: Num3DShapesCubes3DShape35Cubes,

        width: 222.50522,
        height: 173.00349,
        aspectRatio: 1.286131395383989
      };
    case '3D_Shapes_Cubes/3D_shape36_cubes':
      return {
        asset: Num3DShapesCubes3DShape36Cubes,

        width: 222.50522,
        height: 215.36163,
        aspectRatio: 1.0331702077106308
      };
    case '3D_Shapes_Cubes/3D_shape37_cubes':
      return {
        asset: Num3DShapesCubes3DShape37Cubes,

        width: 222.50522,
        height: 257.99789,
        aspectRatio: 0.8624303865430838
      };
    case '3D_Shapes_Cubes/3D_shape38_cubes':
      return {
        asset: Num3DShapesCubes3DShape38Cubes,

        width: 259.43576,
        height: 194.48736,
        aspectRatio: 1.3339466379717428
      };
    case '3D_Shapes_Cubes/3D_shape39_cubes':
      return {
        asset: Num3DShapesCubes3DShape39Cubes,

        width: 259.50656,
        height: 237.12377,
        aspectRatio: 1.0943928565238312
      };
    case '3D_Shapes_Cubes/3D_shape3_cubes':
      return {
        asset: Num3DShapesCubes3DShape3Cubes,

        width: 113.45162,
        height: 237.19643,
        aspectRatio: 0.4783023926624866
      };
    case '3D_Shapes_Cubes/3D_shape40_cubes':
      return {
        asset: Num3DShapesCubes3DShape40Cubes,

        width: 259.50656,
        height: 279.79309,
        aspectRatio: 0.9274945281886696
      };
    case '3D_Shapes_Cubes/3D_shape41_cubes':
      return {
        asset: Num3DShapesCubes3DShape41Cubes,

        width: 149.62402,
        height: 154.1935,
        aspectRatio: 0.9703652877715339
      };
    case '3D_Shapes_Cubes/3D_shape42_cubes':
      return {
        asset: Num3DShapesCubes3DShape42Cubes,

        width: 221.63362,
        height: 216.01669,
        aspectRatio: 1.0260022963966349
      };
    case '3D_Shapes_Cubes/3D_shape43_cubes':
      return {
        asset: Num3DShapesCubes3DShape43Cubes,

        width: 294.95041,
        height: 284.35882,
        aspectRatio: 1.0372472708952725
      };
    case '3D_Shapes_Cubes/3D_shape44_cubes':
      return {
        asset: Num3DShapesCubes3DShape44Cubes,

        width: 150.41362,
        height: 173.69936,
        aspectRatio: 0.8659422809617721
      };
    case '3D_Shapes_Cubes/3D_shape45_cubes':
      return {
        asset: Num3DShapesCubes3DShape45Cubes,

        width: 150.41376,
        height: 216.3687,
        aspectRatio: 0.6951733776650689
      };
    case '3D_Shapes_Cubes/3D_shape46_cubes':
      return {
        asset: Num3DShapesCubes3DShape46Cubes,

        width: 150.41376,
        height: 259.03815,
        aspectRatio: 0.5806625780797153
      };
    case '3D_Shapes_Cubes/3D_shape47_cubes':
      return {
        asset: Num3DShapesCubes3DShape47Cubes,

        width: 150.41362,
        height: 301.99896,
        aspectRatio: 0.49806005954457594
      };
    case '3D_Shapes_Cubes/3D_shape48_cubes':
      return {
        asset: Num3DShapesCubes3DShape48Cubes,

        width: 185.91536,
        height: 194.41496,
        aspectRatio: 0.9562811421507892
      };
    case '3D_Shapes_Cubes/3D_shape49_cubes':
      return {
        asset: Num3DShapesCubes3DShape49Cubes,

        width: 185.9155,
        height: 237.08429,
        aspectRatio: 0.7841746916254975
      };
    case '3D_Shapes_Cubes/3D_shape4_cubes':
      return {
        asset: Num3DShapesCubes3DShape4Cubes,

        width: 113.40656,
        height: 152.44603,
        aspectRatio: 0.7439128457461306
      };
    case '3D_Shapes_Cubes/3D_shape50_cubes':
      return {
        asset: Num3DShapesCubes3DShape50Cubes,

        width: 185.9155,
        height: 279.75363,
        aspectRatio: 0.6645686778041093
      };
    case '3D_Shapes_Cubes/3D_shape51_cubes':
      return {
        asset: Num3DShapesCubes3DShape51Cubes,

        width: 223.63829,
        height: 238.25656,
        aspectRatio: 0.9386448373131888
      };
    case '3D_Shapes_Cubes/3D_shape52_cubes':
      return {
        asset: Num3DShapesCubes3DShape52Cubes,

        width: 259.8559,
        height: 259.29123,
        aspectRatio: 1.0021777443070483
      };
    case '3D_Shapes_Cubes/3D_shape53_cubes':
      return {
        asset: Num3DShapesCubes3DShape53Cubes,

        width: 186.63123,
        height: 184.72603,
        aspectRatio: 1.0103136520608382
      };
    case '3D_Shapes_Cubes/3D_shape54_cubes':
      return {
        asset: Num3DShapesCubes3DShape54Cubes,

        width: 222.84855,
        height: 205.76069,
        aspectRatio: 1.0830472526117596
      };
    case '3D_Shapes_Cubes/3D_shape57_cubes':
      return {
        asset: Num3DShapesCubes3DShape57Cubes,

        width: 149.17912,
        height: 215.18984,
        aspectRatio: 0.6932442535391077
      };
    case '3D_Shapes_Cubes/3D_shape58_cubes':
      return {
        asset: Num3DShapesCubes3DShape58Cubes,

        width: 186.18632,
        height: 236.93463,
        aspectRatio: 0.7858130320586737
      };
    case '3D_Shapes_Cubes/3D_shape59_cubes':
      return {
        asset: Num3DShapesCubes3DShape59Cubes,

        width: 149.96886,
        height: 173.76851,
        aspectRatio: 0.8630381879892969
      };
    case '3D_Shapes_Cubes/3D_shape5_cubes':
      return {
        asset: Num3DShapesCubes3DShape5Cubes,

        width: 113.40656,
        height: 194.70976,
        aspectRatio: 0.5824390107614534
      };
    case '3D_Shapes_Cubes/3D_shape60_cubes':
      return {
        asset: Num3DShapesCubes3DShape60Cubes,

        width: 118.15333,
        height: 116.96022,
        aspectRatio: 1.0102009897040207
      };
    case '3D_Shapes_Cubes/3D_shape61_cubes':
      return {
        asset: Num3DShapesCubes3DShape61Cubes,

        width: 201.577,
        height: 117.13253,
        aspectRatio: 1.720930983049713
      };
    case '3D_Shapes_Cubes/3D_shape62_cubes':
      return {
        asset: Num3DShapesCubes3DShape62Cubes,

        width: 149.96886,
        height: 173.76851,
        aspectRatio: 0.8630381879892969
      };
    case '3D_Shapes_Cubes/3D_shape63_cubes':
      return {
        asset: Num3DShapesCubes3DShape63Cubes,

        width: 201.91893,
        height: 117.12449,
        aspectRatio: 1.7239684885714337
      };
    case '3D_Shapes_Cubes/3D_shape64_cubes':
      return {
        asset: Num3DShapesCubes3DShape64Cubes,

        width: 259.86661,
        height: 132.68369,
        aspectRatio: 1.958542229267214
      };
    case '3D_Shapes_Cubes/3D_shape65_cubes':
      return {
        asset: Num3DShapesCubes3DShape65Cubes,

        width: 276.02493,
        height: 106.43666,
        aspectRatio: 2.593325739458566
      };
    case '3D_Shapes_Cubes/3D_shape66_cubes':
      return {
        asset: Num3DShapesCubes3DShape66Cubes,

        width: 222.97032,
        height: 195.42317,
        aspectRatio: 1.1409615349090898
      };
    case '3D_Shapes_Cubes/3D_shape67_cubes':
      return {
        asset: Num3DShapesCubes3DShape67Cubes,

        width: 222.35658,
        height: 215.82477,
        aspectRatio: 1.0302644131162517
      };
    case '3D_Shapes_Cubes/3D_shape68_cubes':
      return {
        asset: Num3DShapesCubes3DShape68Cubes,

        width: 367.62683,
        height: 426.77588,
        aspectRatio: 0.8614048900795425
      };
    case '3D_Shapes_Cubes/3D_shape69_cubes':
      return {
        asset: Num3DShapesCubes3DShape69Cubes,

        width: 76.742827,
        height: 88.739,
        aspectRatio: 0.8648150982093555
      };
    case '3D_Shapes_Cubes/3D_shape6_cubes':
      return {
        asset: Num3DShapesCubes3DShape6Cubes,

        width: 149.17789,
        height: 172.9247,
        aspectRatio: 0.8626754304041007
      };
    case '3D_Shapes_Cubes/3D_shape70_cubes':
      return {
        asset: Num3DShapesCubes3DShape70Cubes,

        width: 294.879,
        height: 341.913,
        aspectRatio: 0.8624386905440858
      };
    case '3D_Shapes_Cubes/3D_shape7_cubes':
      return {
        asset: Num3DShapesCubes3DShape7Cubes,

        width: 149.17775,
        height: 215.18843,
        aspectRatio: 0.6932424294373075
      };
    case '3D_Shapes_Cubes/3D_shape8_cubes':
      return {
        asset: Num3DShapesCubes3DShape8Cubes,

        width: 186.73402,
        height: 194.46789,
        aspectRatio: 0.9602306067083876
      };
    case '3D_Shapes_Cubes/3D_shape9_cubes':
      return {
        asset: Num3DShapesCubes3DShape9Cubes,

        width: 149.17775,
        height: 172.92456,
        aspectRatio: 0.8626753192259098
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape10_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape10CubesIsometricPaper,

        width: 401.392,
        height: 361.71066,
        aspectRatio: 1.109704646249574
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape11_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape11CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape12_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape12CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape13_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape13CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape1_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape1CubesIsometricPaper,

        width: 392.44266,
        height: 361.71066,
        aspectRatio: 1.084962936950766
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape2_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape2CubesIsometricPaper,

        width: 390.66132,
        height: 361.71066,
        aspectRatio: 1.080038171946605
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape3_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape3CubesIsometricPaper,

        width: 393.11066,
        height: 361.71066,
        aspectRatio: 1.0868097169157247
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape4_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape4CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape5_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape5CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape6_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape6CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape7_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape7CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape8_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape8CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_on_Iso_Grid/3D_shape9_cubes_isometric_paper':
      return {
        asset: Num3DShapesCubesOnIsoGrid3DShape9CubesIsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape10_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape10IsometricPaper,

        width: 404.83331,
        height: 361.71066,
        aspectRatio: 1.1192186318202508
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape11_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape11IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape12_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape12IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape13_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape13IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape1_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape1IsometricPaper,

        width: 391.45334,
        height: 361.71066,
        aspectRatio: 1.0822278226469741
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape2_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape2IsometricPaper,

        width: 394.56534,
        height: 361.71066,
        aspectRatio: 1.0908313843998956
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape3_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape3IsometricPaper,

        width: 394.56534,
        height: 361.71066,
        aspectRatio: 1.0908313843998956
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape4_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape4IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape5_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape5IsometricPaper,

        width: 429.47333,
        height: 361.71066,
        aspectRatio: 1.1873394331259133
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape6_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape6IsometricPaper,

        width: 439.96399,
        height: 361.71066,
        aspectRatio: 1.2163423383762038
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape7_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape7IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape8_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape8IsometricPaper,

        width: 405.496,
        height: 361.71066,
        aspectRatio: 1.1210507315432727
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_outline_shape9_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DOutlineShape9IsometricPaper,

        width: 404.01999,
        height: 361.71066,
        aspectRatio: 1.1169700942736938
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape10_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape10IsometricPaper,

        width: 404.83331,
        height: 361.71066,
        aspectRatio: 1.1192186318202508
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape11_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape11IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape12_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape12IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape13_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape13IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape1_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape1IsometricPaper,

        width: 391.45334,
        height: 361.71066,
        aspectRatio: 1.0822278226469741
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape2_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape2IsometricPaper,

        width: 394.56534,
        height: 361.71066,
        aspectRatio: 1.0908313843998956
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape3_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape3IsometricPaper,

        width: 394.56534,
        height: 361.71066,
        aspectRatio: 1.0908313843998956
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape4_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape4IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape5_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape5IsometricPaper,

        width: 429.47333,
        height: 361.71066,
        aspectRatio: 1.1873394331259133
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape6_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape6IsometricPaper,

        width: 439.96399,
        height: 361.71066,
        aspectRatio: 1.2163423383762038
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape7_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape7IsometricPaper,

        width: 434.31467,
        height: 361.71066,
        aspectRatio: 1.2007239985683584
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape8_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape8IsometricPaper,

        width: 405.496,
        height: 361.71066,
        aspectRatio: 1.1210507315432727
      };
    case '3D_Shapes_Cubes_w_no_identations_on_Iso_Grid/3D_shape9_isometric_paper':
      return {
        asset: Num3DShapesCubesWNoIdentationsOnIsoGrid3DShape9IsometricPaper,

        width: 404.01999,
        height: 361.71066,
        aspectRatio: 1.1169700942736938
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape1':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape1,

        width: 333,
        height: 338,
        aspectRatio: 0.985207100591716
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape10':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape10,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape11':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape11,

        width: 335,
        height: 339,
        aspectRatio: 0.9882005899705014
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape12':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape12,

        width: 334,
        height: 339,
        aspectRatio: 0.9852507374631269
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape13':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape13,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape14':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape14,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape15':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape15,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape16':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape16,

        width: 334,
        height: 339,
        aspectRatio: 0.9852507374631269
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape2':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape2,

        width: 334,
        height: 339,
        aspectRatio: 0.9852507374631269
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape3':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape3,

        width: 333,
        height: 340,
        aspectRatio: 0.9794117647058823
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape4':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape4,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape5':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape5,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape6':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape6,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape7':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape7,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape8':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape8,

        width: 333,
        height: 339,
        aspectRatio: 0.9823008849557522
      };
    case '3D_compound_shapes_on_isometric_paper/3D_compound_shape9':
      return {
        asset: Num3DCompoundShapesOnIsometricPaper3DCompoundShape9,

        width: 334,
        height: 339,
        aspectRatio: 0.9852507374631269
      };
    case '3D_shapes_full_colors/Cones/Cone_blue':
      return {
        asset: Num3DShapesFullColorsConesConeBlue,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_green':
      return {
        asset: Num3DShapesFullColorsConesConeGreen,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_orange':
      return {
        asset: Num3DShapesFullColorsConesConeOrange,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_pink':
      return {
        asset: Num3DShapesFullColorsConesConePink,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_purple':
      return {
        asset: Num3DShapesFullColorsConesConePurple,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_red':
      return {
        asset: Num3DShapesFullColorsConesConeRed,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_transparent':
      return {
        asset: Num3DShapesFullColorsConesConeTransparent,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cones/Cone_yellow':
      return {
        asset: Num3DShapesFullColorsConesConeYellow,

        width: 283.50763,
        height: 297.01584,
        aspectRatio: 0.9545202370351695
      };
    case '3D_shapes_full_colors/Cubes/Cube_blue':
      return {
        asset: Num3DShapesFullColorsCubesCubeBlue,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_blue_2':
      return {
        asset: Num3DShapesFullColorsCubesCubeBlue2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_blue_3':
      return {
        asset: Num3DShapesFullColorsCubesCubeBlue3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cubes/Cube_green':
      return {
        asset: Num3DShapesFullColorsCubesCubeGreen,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_green_2':
      return {
        asset: Num3DShapesFullColorsCubesCubeGreen2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_green_3':
      return {
        asset: Num3DShapesFullColorsCubesCubeGreen3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cubes/Cube_orange':
      return {
        asset: Num3DShapesFullColorsCubesCubeOrange,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_orange_2':
      return {
        asset: Num3DShapesFullColorsCubesCubeOrange2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_orange_3':
      return {
        asset: Num3DShapesFullColorsCubesCubeOrange3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cubes/Cube_pink':
      return {
        asset: Num3DShapesFullColorsCubesCubePink,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_pink_2':
      return {
        asset: Num3DShapesFullColorsCubesCubePink2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_pink_3':
      return {
        asset: Num3DShapesFullColorsCubesCubePink3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cubes/Cube_purple':
      return {
        asset: Num3DShapesFullColorsCubesCubePurple,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_purple_2':
      return {
        asset: Num3DShapesFullColorsCubesCubePurple2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_purple_3':
      return {
        asset: Num3DShapesFullColorsCubesCubePurple3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cubes/Cube_red':
      return {
        asset: Num3DShapesFullColorsCubesCubeRed,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_red_2':
      return {
        asset: Num3DShapesFullColorsCubesCubeRed2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_red_3':
      return {
        asset: Num3DShapesFullColorsCubesCubeRed3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cubes/Cube_transparent':
      return {
        asset: Num3DShapesFullColorsCubesCubeTransparent,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_yellow':
      return {
        asset: Num3DShapesFullColorsCubesCubeYellow,

        width: 299.14658,
        height: 324.82065,
        aspectRatio: 0.9209592432008248
      };
    case '3D_shapes_full_colors/Cubes/Cube_yellow_2':
      return {
        asset: Num3DShapesFullColorsCubesCubeYellow2,

        width: 97,
        height: 164,
        aspectRatio: 0.5914634146341463
      };
    case '3D_shapes_full_colors/Cubes/Cube_yellow_3':
      return {
        asset: Num3DShapesFullColorsCubesCubeYellow3,

        width: 97,
        height: 223,
        aspectRatio: 0.4349775784753363
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_blue':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidBlue,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_green':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidGreen,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_orange':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidOrange,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_pink':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidPink,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_purple':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidPurple,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_red':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidRed,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_transparent':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidTransparent,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cuboids/Cuboid_yellow':
      return {
        asset: Num3DShapesFullColorsCuboidsCuboidYellow,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_blue':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderBlue,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_green':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderGreen,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_orange':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderOrange,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_pink':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderPink,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_purple':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderPurple,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_red':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderRed,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_transparent':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderTransparent,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Cylinders/Cylinder_yellow':
      return {
        asset: Num3DShapesFullColorsCylindersCylinderYellow,

        width: 260.92221,
        height: 397.89957,
        aspectRatio: 0.6557489117165922
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_blue':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceBlue,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_green':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceGreen,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_orange':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceOrange,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_pink':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFacePink,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_purple':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFacePurple,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_red':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceRed,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_transparent':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceTransparent,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Front_face_cubes/Cube_front_face_yellow':
      return {
        asset: Num3DShapesFullColorsFrontFaceCubesCubeFrontFaceYellow,

        width: 348.12506,
        height: 321.1648,
        aspectRatio: 1.083945251783508
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_blue':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismBlue,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_green':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismGreen,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_orange':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismOrange,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_pink':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismPink,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_purple':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismPurple,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_red':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismRed,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_transparent':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismTransparent,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Hexagonal_prisms/Hexagonal_prism_yellow':
      return {
        asset: Num3DShapesFullColorsHexagonalPrismsHexagonalPrismYellow,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_blue':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismBlue,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_green':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismGreen,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_orange':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismOrange,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_pink':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismPink,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_purple':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismPurple,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_red':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismRed,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_transparent':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismTransparent,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Pentagonal_prisms/Pentagonal_prism_yellow':
      return {
        asset: Num3DShapesFullColorsPentagonalPrismsPentagonalPrismYellow,

        width: 208.37843,
        height: 320.00559,
        aspectRatio: 0.651171218602775
      };
    case '3D_shapes_full_colors/Spheres/Sphere_blue':
      return {
        asset: Num3DShapesFullColorsSpheresSphereBlue,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_green':
      return {
        asset: Num3DShapesFullColorsSpheresSphereGreen,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_orange':
      return {
        asset: Num3DShapesFullColorsSpheresSphereOrange,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_pink':
      return {
        asset: Num3DShapesFullColorsSpheresSpherePink,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_purple':
      return {
        asset: Num3DShapesFullColorsSpheresSpherePurple,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_red':
      return {
        asset: Num3DShapesFullColorsSpheresSphereRed,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_transparent':
      return {
        asset: Num3DShapesFullColorsSpheresSphereTransparent,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Spheres/Sphere_yellow':
      return {
        asset: Num3DShapesFullColorsSpheresSphereYellow,

        width: 280.87155,
        height: 280.94608,
        aspectRatio: 0.9997347177792978
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_blue':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidBlue,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_green':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidGreen,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_orange':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidOrange,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_pink':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidPink,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_purple':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidPurple,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_red':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidRed,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_transparent':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidTransparent,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Square_pyramids/Square_pyramid_yellow':
      return {
        asset: Num3DShapesFullColorsSquarePyramidsSquarePyramidYellow,

        width: 321.92477,
        height: 291.74646,
        aspectRatio: 1.1034401925562354
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_blue':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidBlue,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_green':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidGreen,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_orange':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidOrange,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_pink':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidPink,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_purple':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidPurple,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_red':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidRed,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_transparent':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidTransparent,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangle_pyramids/Triangle_pyramid_yellow':
      return {
        asset: Num3DShapesFullColorsTrianglePyramidsTrianglePyramidYellow,

        width: 271.82831,
        height: 299.99765,
        aspectRatio: 0.9061014644614716
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_blue':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismBlue,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_green':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismGreen,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_orange':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismOrange,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_pink':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismPink,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_purple':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismPurple,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_red':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismRed,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_transparent':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismTransparent,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_full_colors/Triangular_prisms/Triangular_prism_yellow':
      return {
        asset: Num3DShapesFullColorsTriangularPrismsTriangularPrismYellow,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Chips_cone':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsChipsCone,

        width: 508.74263,
        height: 656.22662,
        aspectRatio: 0.7752544844950057
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Christmas_tree':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsChristmasTree,

        width: 590.14903,
        height: 834.6778,
        aspectRatio: 0.7070381289642542
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Cooking_funnel':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsCookingFunnel,

        width: 661.70311,
        height: 711.20386,
        aspectRatio: 0.9303986482863016
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Megaphone':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsMegaphone,

        width: 654.09172,
        height: 685.94183,
        aspectRatio: 0.9535673309207575
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Party_hat':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsPartyHat,

        width: 464.11147,
        height: 690.89441,
        aspectRatio: 0.6717545594268161
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Pine_cone':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsPineCone,

        width: 539.84581,
        height: 588.51709,
        aspectRatio: 0.917298442429259
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Traffic_cone':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsTrafficCone,

        width: 558.69786,
        height: 771.44397,
        aspectRatio: 0.724223510360707
      };
    case '3D_shapes_in_real_life_objects/Cone_objects/Waffle_cone':
      return {
        asset: Num3DShapesInRealLifeObjectsConeObjectsWaffleCone,

        width: 508.74263,
        height: 656.22662,
        aspectRatio: 0.7752544844950057
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Brick':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsBrick,

        width: 324.99291,
        height: 356.99146,
        aspectRatio: 0.9103660630985402
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Dice':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsDice,

        width: 221.66522,
        height: 230.69066,
        aspectRatio: 0.9608764394709348
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Ice_cube':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsIceCube,

        width: 179.31962,
        height: 193.33638,
        aspectRatio: 0.9275006597309828
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Open_cardboard_box':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsOpenCardboardBox,

        width: 571.79375,
        height: 622.63086,
        aspectRatio: 0.9183511238103426
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Present':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsPresent,

        width: 452.32396,
        height: 518.76038,
        aspectRatio: 0.871932355358364
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Rubix_cube':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsRubixCube,

        width: 388.86492,
        height: 449.38812,
        aspectRatio: 0.865320872300763
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Stacking_crate':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsStackingCrate,

        width: 452.32396,
        height: 496.94159,
        aspectRatio: 0.9102155446478126
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/White_sugar_cube':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsWhiteSugarCube,

        width: 103.72953,
        height: 112.83758,
        aspectRatio: 0.9192817676522307
      };
    case '3D_shapes_in_real_life_objects/Cube_objects/Wooden_letter_block':
      return {
        asset: Num3DShapesInRealLifeObjectsCubeObjectsWoodenLetterBlock,

        width: 452.32503,
        height: 496.93671,
        aspectRatio: 0.9102266363054563
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Brick':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsBrick,

        width: 371.50411,
        height: 356.13879,
        aspectRatio: 1.0431441910610189
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Cardboard_box_cuboid':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsCardboardBoxCuboid,

        width: 469.96965,
        height: 593.9256,
        aspectRatio: 0.7912938085174305
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Cereal_box':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsCerealBox,

        width: 353.5805,
        height: 601.78937,
        aspectRatio: 0.5875485969451405
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Chest_cuboid':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsChestCuboid,

        width: 567.62572,
        height: 544.10571,
        aspectRatio: 1.04322691265269
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Chocolate_cuboid':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsChocolateCuboid,

        width: 442.87737,
        height: 411.17285,
        aspectRatio: 1.0771075230283322
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Domino':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsDomino,

        width: 564.74266,
        height: 347.59467,
        aspectRatio: 1.6247161097148008
      };
    case '3D_shapes_in_real_life_objects/Cuboid_objects/Tissue_box':
      return {
        asset: Num3DShapesInRealLifeObjectsCuboidObjectsTissueBox,

        width: 450.55251,
        height: 453.77972,
        aspectRatio: 0.9928881572759576
      };
    case '3D_shapes_in_real_life_objects/Sphere_objects/Ball_of_yarn':
      return {
        asset: Num3DShapesInRealLifeObjectsSphereObjectsBallOfYarn,

        width: 327.33864,
        height: 327.33865,
        aspectRatio: 0.9999999694505981
      };
    case '3D_shapes_in_real_life_objects/Sphere_objects/Basketball':
      return {
        asset: Num3DShapesInRealLifeObjectsSphereObjectsBasketball,

        width: 504.4493,
        height: 504.448,
        aspectRatio: 1.0000025770743466
      };
    case '3D_shapes_in_real_life_objects/Sphere_objects/Bubble':
      return {
        asset: Num3DShapesInRealLifeObjectsSphereObjectsBubble,

        width: 321.01333,
        height: 321.01199,
        aspectRatio: 1.00000417429891
      };
    case '3D_shapes_in_real_life_objects/Sphere_objects/Coloured_marble':
      return {
        asset: Num3DShapesInRealLifeObjectsSphereObjectsColouredMarble,

        width: 220.66132,
        height: 220.66133,
        aspectRatio: 0.9999999546816835
      };
    case '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Communication_tower':
      return {
        asset: Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsCommunicationTower,

        width: 541.73064,
        height: 841.20453,
        aspectRatio: 0.6439939642265122
      };
    case '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Egyptian_pyramid':
      return {
        asset: Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsEgyptianPyramid,

        width: 1089.728,
        height: 661.46674,
        aspectRatio: 1.6474418653309766
      };
    case '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Louvre_pyramid':
      return {
        asset: Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsLouvrePyramid,

        width: 1213.7801,
        height: 542.83948,
        aspectRatio: 2.2359834623671806
      };
    case '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Mayan_pyramid':
      return {
        asset: Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsMayanPyramid,

        width: 864.49808,
        height: 729.93994,
        aspectRatio: 1.1843413856761968
      };
    case '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Safety_sign':
      return {
        asset: Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsSafetySign,

        width: 540.31865,
        height: 636.66827,
        aspectRatio: 0.8486658994329968
      };
    case '3D_shapes_in_real_life_objects/Square_based_pyramid_objects/Tower':
      return {
        asset: Num3DShapesInRealLifeObjectsSquareBasedPyramidObjectsTower,

        width: 583.37596,
        height: 864.07837,
        aspectRatio: 0.6751424179267443
      };
    case '3D_shapes_in_real_life_objects/Triangular_prism_objects/Cheese':
      return {
        asset: Num3DShapesInRealLifeObjectsTriangularPrismObjectsCheese,

        width: 401.36422,
        height: 724.73987,
        aspectRatio: 0.5538045257534955
      };
    case '3D_shapes_in_real_life_objects/Triangular_prism_objects/Chocolate_triangular_prism':
      return {
        asset: Num3DShapesInRealLifeObjectsTriangularPrismObjectsChocolateTriangularPrism,

        width: 807.46,
        height: 649.96088,
        aspectRatio: 1.242320922453056
      };
    case '3D_shapes_in_real_life_objects/Triangular_prism_objects/Tent':
      return {
        asset: Num3DShapesInRealLifeObjectsTriangularPrismObjectsTent,

        width: 1180.3786,
        height: 870.93988,
        aspectRatio: 1.3552928590203035
      };
    case '3D_shapes_in_real_life_objects/Triangular_prism_objects/Wood_triangular_prism':
      return {
        asset: Num3DShapesInRealLifeObjectsTriangularPrismObjectsWoodTriangularPrism,

        width: 439.26287,
        height: 718.79327,
        aspectRatio: 0.6111115508913989
      };
    case '3D_shapes_straws_marshmallows/Cuboid_shapes_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsCuboidShapesStrawsMarshmallows,

        width: 838.92564,
        height: 701.88104,
        aspectRatio: 1.1952533152911498
      };
    case '3D_shapes_straws_marshmallows/Hexagonal_prism_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsHexagonalPrismStrawsMarshmallows,

        width: 471.35134,
        height: 720.91962,
        aspectRatio: 0.6538195478713702
      };
    case '3D_shapes_straws_marshmallows/Pentagonal_prism_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsPentagonalPrismStrawsMarshmallows,

        width: 405.67934,
        height: 674.84961,
        aspectRatio: 0.6011403637026627
      };
    case '3D_shapes_straws_marshmallows/Square_based_pyramid_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsSquareBasedPyramidStrawsMarshmallows,

        width: 694.76677,
        height: 614.0575,
        aspectRatio: 1.131436013728356
      };
    case '3D_shapes_straws_marshmallows/Tetrahedron_shapes_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsTetrahedronShapesStrawsMarshmallows,

        width: 625.71574,
        height: 633.13177,
        aspectRatio: 0.9882867511134373
      };
    case '3D_shapes_straws_marshmallows/Triangular_prism_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsTriangularPrismStrawsMarshmallows,

        width: 838.92564,
        height: 655.20972,
        aspectRatio: 1.2803925436271002
      };
    case '3D_shapes_straws_marshmallows/cube_straws_marshmallows':
      return {
        asset: Num3DShapesStrawsMarshmallowsCubeStrawsMarshmallows,
        defaultWidth: 541.03198,
        defaultHeight: 522.96765,
        width: 541.03198,
        height: 522.96765,
        aspectRatio: 1.0345419644981864
      };
    case 'Alex_birds_eye':
      return {
        asset: AlexBirdsEye,
        defaultWidth: 274,
        defaultHeight: 214,
        width: 274,
        height: 214,
        aspectRatio: 1.280373831775701
      };
    case 'Angles_in_a_triangle_multiple_lines/Isosceles_triangle1':
      return {
        asset: AnglesInATriangleMultipleLinesIsoscelesTriangle1,

        width: 563,
        height: 541,
        aspectRatio: 1.0406654343807764
      };
    case 'Angles_in_a_triangle_multiple_lines/Isosceles_triangle2':
      return {
        asset: AnglesInATriangleMultipleLinesIsoscelesTriangle2,

        width: 563,
        height: 541,
        aspectRatio: 1.0406654343807764
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle1':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle1,

        width: 1705,
        height: 883,
        aspectRatio: 1.9309173272933182
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle2':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle2,

        width: 1704,
        height: 883,
        aspectRatio: 1.9297848244620612
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle3':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle3,

        width: 1704,
        height: 883,
        aspectRatio: 1.9297848244620612
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_Isosceles_joined_triangle4':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneIsoscelesJoinedTriangle4,

        width: 1704,
        height: 883,
        aspectRatio: 1.9297848244620612
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle1':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle1,

        width: 1012,
        height: 845,
        aspectRatio: 1.1976331360946746
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle2':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle2,

        width: 883,
        height: 766,
        aspectRatio: 1.1527415143603132
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle3':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle3,

        width: 837,
        height: 903,
        aspectRatio: 0.9269102990033222
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle4':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle4,

        width: 697,
        height: 872,
        aspectRatio: 0.7993119266055045
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle5':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle5,

        width: 1726,
        height: 1464,
        aspectRatio: 1.1789617486338797
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle6':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle6,

        width: 1121,
        height: 552,
        aspectRatio: 2.0307971014492754
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle7':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle7,

        width: 1120,
        height: 1243,
        aspectRatio: 0.9010458567980691
      };
    case 'Angles_in_a_triangle_multiple_lines/Scalene_triangle8':
      return {
        asset: AnglesInATriangleMultipleLinesScaleneTriangle8,

        width: 1120,
        height: 552,
        aspectRatio: 2.028985507246377
      };
    case 'Angles_on_a_straight_line/2_angles_1_line1':
      return {
        asset: AnglesOnAStraightLine2Angles1Line1,

        width: 1101.3759,
        height: 321.03766,
        aspectRatio: 3.430675080300548
      };
    case 'Angles_on_a_straight_line/2_angles_1_line2':
      return {
        asset: AnglesOnAStraightLine2Angles1Line2,

        width: 1101.3759,
        height: 454.88568,
        aspectRatio: 2.4212147104740693
      };
    case 'Angles_on_a_straight_line/2_angles_1_line3':
      return {
        asset: AnglesOnAStraightLine2Angles1Line3,

        width: 1101.3759,
        height: 268.12775,
        aspectRatio: 4.107653534555823
      };
    case 'Angles_on_a_straight_line/2_angles_1_line4':
      return {
        asset: AnglesOnAStraightLine2Angles1Line4,

        width: 1101.3759,
        height: 465.52985,
        aspectRatio: 2.365854520392194
      };
    case 'Annie_birds_eye':
      return {
        asset: AnnieBirdsEye,
        defaultWidth: 274,
        defaultHeight: 216,
        width: 274,
        height: 216,
        aspectRatio: 1.2685185185185186
      };
    case 'Ant':
      return {
        asset: Ant,

        width: 277.5708,
        height: 259.84494,
        aspectRatio: 1.0682170682253809
      };
    case 'Apple_juice_carton':
      return {
        asset: AppleJuiceCarton,

        width: 213.49399,
        height: 361.18252,
        aspectRatio: 0.5910972380390944
      };
    case 'Array_objects/AppleGreen':
      return {
        asset: ArrayObjectsAppleGreen,

        width: 263.2959,
        height: 274.7121,
        aspectRatio: 0.9584430390943828
      };
    case 'Array_objects/AppleRed':
      return {
        asset: ArrayObjectsAppleRed,

        width: 263.2959,
        height: 274.7121,
        aspectRatio: 0.9584430390943828
      };
    case 'Array_objects/Banana':
      return {
        asset: ArrayObjectsBanana,

        width: 425.71402,
        height: 304.8259,
        aspectRatio: 1.3965808679643037
      };
    case 'Array_objects/Orange':
      return {
        asset: ArrayObjectsOrange,

        width: 351.22803,
        height: 369.45392,
        aspectRatio: 0.9506680291820967
      };
    case 'Array_objects/Pear':
      return {
        asset: ArrayObjectsPear,

        width: 188.11447,
        height: 299.03488,
        aspectRatio: 0.6290719998951294
      };
    case 'Array_objects/Pineapple':
      return {
        asset: ArrayObjectsPineapple,

        width: 277.6145,
        height: 457.55829,
        aspectRatio: 0.6067303468591948
      };
    case 'Array_objects/Strawberry':
      return {
        asset: ArrayObjectsStrawberry,

        width: 174.89328,
        height: 207.94752,
        aspectRatio: 0.8410452791165771
      };
    case 'ArrowDown':
      return {
        asset: ArrowDown,

        width: 48,
        height: 100,
        aspectRatio: 0.48
      };
    case 'ArrowDownLong':
      return {
        asset: ArrowDownLong,

        width: 22,
        height: 256,
        aspectRatio: 0.0859375
      };
    case 'ArrowDownLongPDF':
      return {
        asset: ArrowDownLongPDF,

        width: 44,
        height: 256,
        aspectRatio: 0.171875
      };
    case 'ArrowRight':
      return {
        asset: ArrowRight,

        width: 48,
        height: 49,
        aspectRatio: 0.9795918367346939
      };
    case 'ArrowRightLong':
      return {
        asset: ArrowRightLong,

        width: 257,
        height: 23,
        aspectRatio: 11.173913043478262
      };
    case 'ArrowUp':
      return {
        asset: ArrowUp,

        width: 48,
        height: 100,
        aspectRatio: 0.48
      };
    case 'ArrowUpShort':
      return {
        asset: ArrowUpShort,

        width: 30,
        height: 48,
        aspectRatio: 0.625
      };
    case 'Arrows/arrow':
      return {
        asset: ArrowsArrow,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Arrows/arrow_blue':
      return {
        asset: ArrowsArrowBlue,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Arrows/arrow_green':
      return {
        asset: ArrowsArrowGreen,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Arrows/arrow_pink':
      return {
        asset: ArrowsArrowPink,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Arrows/arrow_purple':
      return {
        asset: ArrowsArrowPurple,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Arrows/arrow_white':
      return {
        asset: ArrowsArrowWhite,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Arrows/arrow_yellow':
      return {
        asset: ArrowsArrowYellow,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Backspace':
      return {
        asset: Backspace,
        defaultWidth: 52,
        defaultHeight: 37,
        width: 52,
        height: 37,
        aspectRatio: 1.4054054054054055
      };
    case 'Bag_of_Flour':
      return {
        asset: BagOfFlour,

        width: 177.64319,
        height: 257.98892,
        aspectRatio: 0.6885690672297089
      };
    case 'Bag_of_Pasta':
      return {
        asset: BagOfPasta,

        width: 176.31796,
        height: 206.27733,
        aspectRatio: 0.8547616938807575
      };
    case 'Bag_of_Rice':
      return {
        asset: BagOfRice,
        defaultWidth: 196.91933,
        defaultHeight: 207.98918,
        width: 196.91933,
        height: 207.98918,
        aspectRatio: 0.9467767986777005
      };
    case 'Bag_of_carrots':
      return {
        asset: BagOfCarrots,

        width: 281.95731,
        height: 304.1282,
        aspectRatio: 0.9271001834094964
      };
    case 'Bag_of_chocolate_chips':
      return {
        asset: BagOfChocolateChips,

        width: 262.48517,
        height: 341.324,
        aspectRatio: 0.7690205493900223
      };
    case 'Bag_of_potatoes':
      return {
        asset: BagOfPotatoes,

        width: 283.76395,
        height: 299.53805,
        aspectRatio: 0.9473385768519226
      };
    case 'Bag_of_white_sugar':
      return {
        asset: BagOfWhiteSugar,

        width: 176.25,
        height: 257.14813,
        aspectRatio: 0.6854026121053263
      };
    case 'BalloonBlue':
      return {
        asset: BalloonBlue,

        width: 246.33073,
        height: 565.69598,
        aspectRatio: 0.43544719904143564
      };
    case 'Bar_of_chocolate':
      return {
        asset: BarOfChocolate,

        width: 315.84586,
        height: 176.7677,
        aspectRatio: 1.7867849160225542
      };
    case 'Base_Ten/1000cube':
      return {
        asset: BaseTen1000cube,

        width: 190,
        height: 179,
        aspectRatio: 1.0614525139664805
      };
    case 'Base_Ten/100cube':
      return {
        asset: BaseTen100cube,

        width: 152,
        height: 157,
        aspectRatio: 0.9681528662420382
      };
    case 'Base_Ten/10BluePens':
      return {
        asset: BaseTen10BluePens,

        width: 93,
        height: 221,
        aspectRatio: 0.42081447963800905
      };
    case 'Base_Ten/10RedPens':
      return {
        asset: BaseTen10RedPens,
        defaultWidth: 93,
        defaultHeight: 221,
        width: 93,
        height: 221,
        aspectRatio: 0.42081447963800905
      };
    case 'Base_Ten/10cube':
      return {
        asset: BaseTen10cube,

        width: 15.9954,
        height: 156.54184,
        aspectRatio: 0.10217971118775657
      };
    case 'Base_Ten/1BluePen':
      return {
        asset: BaseTen1BluePen,

        width: 78.793006,
        height: 309.76837,
        aspectRatio: 0.2543610440278328
      };
    case 'Base_Ten/1RedPen':
      return {
        asset: BaseTen1RedPen,

        width: 78.793006,
        height: 309.76837,
        aspectRatio: 0.2543610440278328
      };
    case 'Base_Ten/1cube':
      return {
        asset: BaseTen1cube,

        width: 15.9954,
        height: 16.5541,
        aspectRatio: 0.9662500528569963
      };
    case 'Base_Ten/Balloons1':
      return {
        asset: BaseTenBalloons1,

        width: 64.368377,
        height: 34.577572,
        aspectRatio: 1.861564397870388
      };
    case 'Base_Ten/Balloons10':
      return {
        asset: BaseTenBalloons10,

        width: 66.04425,
        height: 109.24117,
        aspectRatio: 0.6045728913375791
      };
    case 'Base_Ten/Balloons100':
      return {
        asset: BaseTenBalloons100,

        width: 115.46089,
        height: 193.17212,
        aspectRatio: 0.5977099076202094
      };
    case 'Base_Ten/Bricks1':
      return {
        asset: BaseTenBricks1,

        width: 31.180504,
        height: 21.628925,
        aspectRatio: 1.4416113607125642
      };
    case 'Base_Ten/Bricks10':
      return {
        asset: BaseTenBricks10,

        width: 75.777329,
        height: 49.053333,
        aspectRatio: 1.5447947033487
      };
    case 'Base_Ten/Bricks100':
      return {
        asset: BaseTenBricks100,

        width: 120.96933,
        height: 80.262664,
        aspectRatio: 1.507168139846442
      };
    case 'Base_Ten/Crayons10':
      return {
        asset: BaseTenCrayons10,

        width: 58.809329,
        height: 90.194663,
        aspectRatio: 0.6520267058373509
      };
    case 'Base_Ten/Crayons100':
      return {
        asset: BaseTenCrayons100,

        width: 509.79172,
        height: 257.01373,
        aspectRatio: 1.983519401862305
      };
    case 'Base_Ten/Crayons1_blue':
      return {
        asset: BaseTenCrayons1Blue,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_green':
      return {
        asset: BaseTenCrayons1Green,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_lightblue':
      return {
        asset: BaseTenCrayons1Lightblue,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_lime':
      return {
        asset: BaseTenCrayons1Lime,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_orange':
      return {
        asset: BaseTenCrayons1Orange,

        width: 56,
        height: 38,
        aspectRatio: 1.4736842105263157
      };
    case 'Base_Ten/Crayons1_pink':
      return {
        asset: BaseTenCrayons1Pink,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_purple':
      return {
        asset: BaseTenCrayons1Purple,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_red':
      return {
        asset: BaseTenCrayons1Red,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_white':
      return {
        asset: BaseTenCrayons1White,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Crayons1_yellow':
      return {
        asset: BaseTenCrayons1Yellow,

        width: 58,
        height: 39,
        aspectRatio: 1.4871794871794872
      };
    case 'Base_Ten/Lollipops1':
      return {
        asset: BaseTenLollipops1,

        width: 45.228836,
        height: 122.91897,
        aspectRatio: 0.36795651639449956
      };
    case 'Base_Ten/Lollipops10':
      return {
        asset: BaseTenLollipops10,

        width: 98.870667,
        height: 177.99466,
        aspectRatio: 0.555469849488743
      };
    case 'Base_Ten/Lollipops100':
      return {
        asset: BaseTenLollipops100,

        width: 209,
        height: 356,
        aspectRatio: 0.5870786516853933
      };
    case 'Base_Ten/Marbles1':
      return {
        asset: BaseTenMarbles1,

        width: 43.216904,
        height: 43.214665,
        aspectRatio: 1.0000518111155092
      };
    case 'Base_Ten/Marbles10':
      return {
        asset: BaseTenMarbles10,

        width: 201.08304,
        height: 84.509507,
        aspectRatio: 2.3794132416368257
      };
    case 'Base_Ten/Marbles100':
      return {
        asset: BaseTenMarbles100,

        width: 501.73913,
        height: 114.71432,
        aspectRatio: 4.373814271836332
      };
    case 'Base_Ten/Nails1':
      return {
        asset: BaseTenNails1,

        width: 77.278518,
        height: 156.48961,
        aspectRatio: 0.4938252322310728
      };
    case 'Base_Ten/Nails10':
      return {
        asset: BaseTenNails10,

        width: 205.78204,
        height: 273.30166,
        aspectRatio: 0.7529483721394153
      };
    case 'Base_Ten/Nails100':
      return {
        asset: BaseTenNails100,

        width: 405.09579,
        height: 254.97342,
        aspectRatio: 1.5887765477672144
      };
    case 'Base_Ten/Nails1000':
      return {
        asset: BaseTenNails1000,

        width: 561,
        height: 389,
        aspectRatio: 1.442159383033419
      };
    case 'Base_Ten/Straws1':
      return {
        asset: BaseTenStraws1,

        width: 120.88981,
        height: 175.72894,
        aspectRatio: 0.6879334160895753
      };
    case 'Base_Ten/Straws10':
      return {
        asset: BaseTenStraws10,

        width: 167.69876,
        height: 207.72233,
        aspectRatio: 0.8073217742165707
      };
    case 'Base_Ten/Sweets1':
      return {
        asset: BaseTenSweets1,

        width: 70.184448,
        height: 48.657335,
        aspectRatio: 1.4424227714074354
      };
    case 'Base_Ten/Sweets10':
      return {
        asset: BaseTenSweets10,

        width: 89.691749,
        height: 117.19845,
        aspectRatio: 0.7652980820138834
      };
    case 'Base_Ten/Sweets100':
      return {
        asset: BaseTenSweets100,

        width: 131.9086,
        height: 172.36132,
        aspectRatio: 0.7653027953139371
      };
    case 'Basketball':
      return {
        asset: Basketball,

        width: 318.11465,
        height: 318.17792,
        aspectRatio: 0.9998011489923625
      };
    case 'Bath':
      return {
        asset: Bath,

        width: 524.28387,
        height: 369.19711,
        aspectRatio: 1.4200649349611647
      };
    case 'Bee':
      return {
        asset: Bee,

        width: 793.70135,
        height: 793.70135,
        aspectRatio: 1
      };
    case 'Bee_2':
      return {
        asset: Bee2,

        width: 241.62653,
        height: 259.75412,
        aspectRatio: 0.9302125025004416
      };
    case 'Bee_from_above':
      return {
        asset: BeeFromAbove,

        width: 534.06024,
        height: 437.93188,
        aspectRatio: 1.2195052801362625
      };
    case 'Before_and_after_images/Balloon_blue':
      return {
        asset: BeforeAndAfterImagesBalloonBlue,

        width: 246.33073,
        height: 565.69598,
        aspectRatio: 0.43544719904143564
      };
    case 'Before_and_after_images/Balloon_blue_popped':
      return {
        asset: BeforeAndAfterImagesBalloonBluePopped,

        width: 291.03967,
        height: 107.75867,
        aspectRatio: 2.700846901692458
      };
    case 'Before_and_after_images/Balloon_blue_uninflated':
      return {
        asset: BeforeAndAfterImagesBalloonBlueUninflated,

        width: 223.13855,
        height: 90.565065,
        aspectRatio: 2.463847952850252
      };
    case 'Before_and_after_images/Banana_bite':
      return {
        asset: BeforeAndAfterImagesBananaBite,

        width: 291.00165,
        height: 271.50256,
        aspectRatio: 1.0718191754803341
      };
    case 'Before_and_after_images/Banana_peeled':
      return {
        asset: BeforeAndAfterImagesBananaPeeled,

        width: 293.61078,
        height: 313.30707,
        aspectRatio: 0.9371342306447153
      };
    case 'Before_and_after_images/Banana_single':
      return {
        asset: BeforeAndAfterImagesBananaSingle,

        width: 285.52356,
        height: 204.57008,
        aspectRatio: 1.395724927125218
      };
    case 'Before_and_after_images/Bar_of_chocolate_bite':
      return {
        asset: BeforeAndAfterImagesBarOfChocolateBite,

        width: 315.84534,
        height: 250.30722,
        aspectRatio: 1.2618307214630087
      };
    case 'Before_and_after_images/Bar_of_chocolate_unwrapped':
      return {
        asset: BeforeAndAfterImagesBarOfChocolateUnwrapped,

        width: 315.84534,
        height: 250.30722,
        aspectRatio: 1.2618307214630087
      };
    case 'Before_and_after_images/Bar_of_chocolate_wr_noto':
      return {
        asset: BeforeAndAfterImagesBarOfChocolateWrNoto,

        width: 315.84586,
        height: 176.76799,
        aspectRatio: 1.7867819846794661
      };
    case 'Before_and_after_images/Cookie_biscuit_bite':
      return {
        asset: BeforeAndAfterImagesCookieBiscuitBite,

        width: 230.10933,
        height: 186.60998,
        aspectRatio: 1.233103020535129
      };
    case 'Before_and_after_images/Cookie_biscuit_crumbs':
      return {
        asset: BeforeAndAfterImagesCookieBiscuitCrumbs,

        width: 164.4844,
        height: 51.406131,
        aspectRatio: 3.1997039419286386
      };
    case 'Before_and_after_images/Cookie_biscuit_no_bite':
      return {
        asset: BeforeAndAfterImagesCookieBiscuitNoBite,

        width: 230.10933,
        height: 190.10133,
        aspectRatio: 1.210456181448073
      };
    case 'Before_and_after_images/Donut_bite':
      return {
        asset: BeforeAndAfterImagesDonutBite,

        width: 238.57463,
        height: 159.76759,
        aspectRatio: 1.4932604916929648
      };
    case 'Before_and_after_images/Donut_crumbs':
      return {
        asset: BeforeAndAfterImagesDonutCrumbs,

        width: 201.00201,
        height: 47.065464,
        aspectRatio: 4.270690075423458
      };
    case 'Before_and_after_images/Donut_no_bite':
      return {
        asset: BeforeAndAfterImagesDonutNoBite,

        width: 232.5811,
        height: 159.76759,
        aspectRatio: 1.4557464376848894
      };
    case 'Before_and_after_images/Not_wrapped_gift':
      return {
        asset: BeforeAndAfterImagesNotWrappedGift,

        width: 838.24591,
        height: 330.17676,
        aspectRatio: 2.538779258721904
      };
    case 'Before_and_after_images/Paper_blank':
      return {
        asset: BeforeAndAfterImagesPaperBlank,

        width: 292.66666,
        height: 363.33331,
        aspectRatio: 0.805504620536994
      };
    case 'Before_and_after_images/Paper_drawing_house_colour':
      return {
        asset: BeforeAndAfterImagesPaperDrawingHouseColour,

        width: 292.66666,
        height: 363.33331,
        aspectRatio: 0.805504620536994
      };
    case 'Before_and_after_images/Paper_drawing_house_line':
      return {
        asset: BeforeAndAfterImagesPaperDrawingHouseLine,

        width: 292.66666,
        height: 363.33331,
        aspectRatio: 0.805504620536994
      };
    case 'Before_and_after_images/Plant_pot_soil':
      return {
        asset: BeforeAndAfterImagesPlantPotSoil,

        width: 252.00037,
        height: 284.19198,
        aspectRatio: 0.8867258323053311
      };
    case 'Before_and_after_images/Plant_pot_sunflower':
      return {
        asset: BeforeAndAfterImagesPlantPotSunflower,

        width: 212.07147,
        height: 554.36206,
        aspectRatio: 0.3825504761274608
      };
    case 'Before_and_after_images/Sunflower_seed':
      return {
        asset: BeforeAndAfterImagesSunflowerSeed,

        width: 269.03641,
        height: 166.07866,
        aspectRatio: 1.6199336507170756
      };
    case 'Before_and_after_images/Wrapped_gift':
      return {
        asset: BeforeAndAfterImagesWrappedGift,

        width: 296.08478,
        height: 293.31207,
        aspectRatio: 1.0094531056972869
      };
    case 'Before_and_after_images/Wrapped_gift_without_bow':
      return {
        asset: BeforeAndAfterImagesWrappedGiftWithoutBow,

        width: 296.10199,
        height: 225.97867,
        aspectRatio: 1.3103094641631443
      };
    case 'Bike':
      return {
        asset: Bike,

        width: 597.49359,
        height: 350.19,
        aspectRatio: 1.7061983209115053
      };
    case 'Bird_yellow':
      return {
        asset: BirdYellow,

        width: 396.22467,
        height: 318.81748,
        aspectRatio: 1.242794686163381
      };
    case 'Bone':
      return {
        asset: Bone,

        width: 395.29864,
        height: 178.77733,
        aspectRatio: 2.2111228532163443
      };
    case 'Book':
      return {
        asset: Book,

        width: 306.75607,
        height: 221.64584,
        aspectRatio: 1.38399200273734
      };
    case 'BookRed':
      return {
        asset: BookRed,

        width: 293.01987,
        height: 376.17318,
        aspectRatio: 0.7789493924048494
      };
    case 'Book_Red_set_down':
      return {
        asset: BookRedSetDown,

        width: 411.58017,
        height: 90.847679,
        aspectRatio: 4.530442324233732
      };
    case 'Bouquet':
      return {
        asset: Bouquet,

        width: 393.77,
        height: 398.12,
        aspectRatio: 0.9890736461368431
      };
    case 'Bread_loaf':
      return {
        asset: BreadLoaf,

        width: 372.65277,
        height: 255.53428,
        aspectRatio: 1.4583279002723235
      };
    case 'Bread_roll':
      return {
        asset: BreadRoll,

        width: 71.926666,
        height: 56.047999,
        aspectRatio: 1.2833047973755496
      };
    case 'Bread_rolls_10':
      return {
        asset: BreadRolls10,

        width: 343.13891,
        height: 86.329993,
        aspectRatio: 3.9747357560888488
      };
    case 'Broken_shapes/arrow_break_1':
      return {
        asset: BrokenShapesArrowBreak1,

        width: 432.98651,
        height: 256.19489,
        aspectRatio: 1.6900669252224352
      };
    case 'Broken_shapes/arrow_break_2':
      return {
        asset: BrokenShapesArrowBreak2,

        width: 432.9858,
        height: 256.19543,
        aspectRatio: 1.6900605916350655
      };
    case 'Broken_shapes/circle_break_1':
      return {
        asset: BrokenShapesCircleBreak1,

        width: 323.93002,
        height: 329.33331,
        aspectRatio: 0.9835932478254327
      };
    case 'Broken_shapes/circle_break_2':
      return {
        asset: BrokenShapesCircleBreak2,

        width: 318.86981,
        height: 329.33414,
        aspectRatio: 0.9682257964509844
      };
    case 'Broken_shapes/circle_semi_break_1':
      return {
        asset: BrokenShapesCircleSemiBreak1,

        width: 164.66666,
        height: 329.35358,
        aspectRatio: 0.4999692427815723
      };
    case 'Broken_shapes/circle_semi_break_2':
      return {
        asset: BrokenShapesCircleSemiBreak2,

        width: 164.66719,
        height: 329.35297,
        aspectRatio: 0.4999717779985406
      };
    case 'Broken_shapes/cross_break_1':
      return {
        asset: BrokenShapesCrossBreak1,

        width: 329.33331,
        height: 329.33331,
        aspectRatio: 1
      };
    case 'Broken_shapes/cross_break_2':
      return {
        asset: BrokenShapesCrossBreak2,

        width: 329.33374,
        height: 329.33331,
        aspectRatio: 1.0000013056681087
      };
    case 'Broken_shapes/hexagon_break_1':
      return {
        asset: BrokenShapesHexagonBreak1,

        width: 378.15838,
        height: 327.49466,
        aspectRatio: 1.1547009041307728
      };
    case 'Broken_shapes/hexagon_break_2':
      return {
        asset: BrokenShapesHexagonBreak2,

        width: 378.15799,
        height: 327.49505,
        aspectRatio: 1.1546983381886229
      };
    case 'Broken_shapes/kite_break_1':
      return {
        asset: BrokenShapesKiteBreak1,

        width: 243.65067,
        height: 329.32987,
        aspectRatio: 0.7398377499131796
      };
    case 'Broken_shapes/kite_break_2':
      return {
        asset: BrokenShapesKiteBreak2,

        width: 243.65148,
        height: 329.32984,
        aspectRatio: 0.7398402768482807
      };
    case 'Broken_shapes/parallelogram_break_1':
      return {
        asset: BrokenShapesParallelogramBreak1,

        width: 585.14477,
        height: 329.33331,
        aspectRatio: 1.776755500377414
      };
    case 'Broken_shapes/parallelogram_break_2':
      return {
        asset: BrokenShapesParallelogramBreak2,

        width: 585.14477,
        height: 329.33331,
        aspectRatio: 1.776755500377414
      };
    case 'Broken_shapes/pentagon_break_1':
      return {
        asset: BrokenShapesPentagonBreak1,

        width: 343.55902,
        height: 326.74417,
        aspectRatio: 1.0514618210326445
      };
    case 'Broken_shapes/pentagon_break_2':
      return {
        asset: BrokenShapesPentagonBreak2,

        width: 343.55944,
        height: 326.7446,
        aspectRatio: 1.0514617227032979
      };
    case 'Broken_shapes/pentagon_house_break_1':
      return {
        asset: BrokenShapesPentagonHouseBreak1,

        width: 329.33331,
        height: 329.97232,
        aspectRatio: 0.9980634436246045
      };
    case 'Broken_shapes/pentagon_house_break_2':
      return {
        asset: BrokenShapesPentagonHouseBreak2,

        width: 329.33331,
        height: 329.97244,
        aspectRatio: 0.9980630806621303
      };
    case 'Broken_shapes/rectangle_break_1':
      return {
        asset: BrokenShapesRectangleBreak1,

        width: 578.66662,
        height: 329.33331,
        aspectRatio: 1.7570850030323382
      };
    case 'Broken_shapes/rectangle_break_2':
      return {
        asset: BrokenShapesRectangleBreak2,

        width: 578.66681,
        height: 329.33331,
        aspectRatio: 1.7570855799554563
      };
    case 'Broken_shapes/rhombus_break_1':
      return {
        asset: BrokenShapesRhombusBreak1,

        width: 372.74839,
        height: 329.33331,
        aspectRatio: 1.131827175331885
      };
    case 'Broken_shapes/rhombus_break_2':
      return {
        asset: BrokenShapesRhombusBreak2,

        width: 372.74768,
        height: 329.33331,
        aspectRatio: 1.1318250194612869
      };
    case 'Broken_shapes/square_break_1':
      return {
        asset: BrokenShapesSquareBreak1,

        width: 329.33331,
        height: 329.33331,
        aspectRatio: 1
      };
    case 'Broken_shapes/square_break_2':
      return {
        asset: BrokenShapesSquareBreak2,

        width: 329.33374,
        height: 329.33331,
        aspectRatio: 1.0000013056681087
      };
    case 'Broken_shapes/trapezium_RA_break_1':
      return {
        asset: BrokenShapesTrapeziumRABreak1,

        width: 484.21222,
        height: 329.33331,
        aspectRatio: 1.4702801244125594
      };
    case 'Broken_shapes/trapezium_RA_break_2':
      return {
        asset: BrokenShapesTrapeziumRABreak2,

        width: 484.2118,
        height: 329.33331,
        aspectRatio: 1.4702788491088254
      };
    case 'Broken_shapes/trapezium_isosceles_break_1':
      return {
        asset: BrokenShapesTrapeziumIsoscelesBreak1,

        width: 437.69733,
        height: 329.33331,
        aspectRatio: 1.3290405698712955
      };
    case 'Broken_shapes/trapezium_isosceles_break_2':
      return {
        asset: BrokenShapesTrapeziumIsoscelesBreak2,

        width: 437.6972,
        height: 329.33331,
        aspectRatio: 1.3290401751344254
      };
    case 'Broken_shapes/triangle_RA_break_1':
      return {
        asset: BrokenShapesTriangleRABreak1,

        width: 333.10455,
        height: 333.10455,
        aspectRatio: 1
      };
    case 'Broken_shapes/triangle_RA_break_2':
      return {
        asset: BrokenShapesTriangleRABreak2,

        width: 333.10443,
        height: 333.10455,
        aspectRatio: 0.9999996397527442
      };
    case 'Broken_shapes/triangle_break_1':
      return {
        asset: BrokenShapesTriangleBreak1,

        width: 382.17919,
        height: 331.52933,
        aspectRatio: 1.1527764074448557
      };
    case 'Broken_shapes/triangle_break_2':
      return {
        asset: BrokenShapesTriangleBreak2,

        width: 382.17923,
        height: 331.52936,
        aspectRatio: 1.152776423783402
      };
    case 'Broken_shapes/triangle_isos_narrow_break_1':
      return {
        asset: BrokenShapesTriangleIsosNarrowBreak1,

        width: 274.24481,
        height: 333.19639,
        aspectRatio: 0.8230725729051265
      };
    case 'Broken_shapes/triangle_isos_narrow_break_2':
      return {
        asset: BrokenShapesTriangleIsosNarrowBreak2,

        width: 272.25467,
        height: 330.77789,
        aspectRatio: 0.8230739666426917
      };
    case 'Broken_shapes/triangle_isos_wide_break_1':
      return {
        asset: BrokenShapesTriangleIsosWideBreak1,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Broken_shapes/triangle_isos_wide_break_2':
      return {
        asset: BrokenShapesTriangleIsosWideBreak2,

        width: 444.28607,
        height: 330.97468,
        aspectRatio: 1.3423566721176377
      };
    case 'Broken_shapes/triangle_scalene_break_1':
      return {
        asset: BrokenShapesTriangleScaleneBreak1,

        width: 449.38441,
        height: 332.43897,
        aspectRatio: 1.3517801778774614
      };
    case 'Broken_shapes/triangle_scalene_break_2':
      return {
        asset: BrokenShapesTriangleScaleneBreak2,

        width: 449.38452,
        height: 332.43808,
        aspectRatio: 1.351784127738916
      };
    case 'Brown_bag_of_apples':
      return {
        asset: BrownBagOfApples,

        width: 330.10608,
        height: 312.17261,
        aspectRatio: 1.0574472885369413
      };
    case 'Bubble':
      return {
        asset: Bubble,

        width: 73.932,
        height: 73.930665,
        aspectRatio: 1.0000180574596482
      };
    case 'Bunches_of_bananas/Bunch_of_bananas_1':
      return {
        asset: BunchesOfBananasBunchOfBananas1,

        width: 425.71402,
        height: 304.8259,
        aspectRatio: 1.3965808679643037
      };
    case 'Bunches_of_bananas/Bunch_of_bananas_2':
      return {
        asset: BunchesOfBananasBunchOfBananas2,

        width: 196.18544,
        height: 158.27194,
        aspectRatio: 1.2395465677617903
      };
    case 'Bunches_of_bananas/Bunch_of_bananas_3':
      return {
        asset: BunchesOfBananasBunchOfBananas3,

        width: 229.2173,
        height: 186.60732,
        aspectRatio: 1.228340345919978
      };
    case 'Bunches_of_bananas/Bunch_of_bananas_4':
      return {
        asset: BunchesOfBananasBunchOfBananas4,

        width: 243.05955,
        height: 186.60732,
        aspectRatio: 1.302518840096948
      };
    case 'Bunches_of_bananas/Bunch_of_bananas_5':
      return {
        asset: BunchesOfBananasBunchOfBananas5,

        width: 247.51063,
        height: 221.97311,
        aspectRatio: 1.115047809169318
      };
    case 'Bunches_of_bananas/Bunch_of_bananas_6':
      return {
        asset: BunchesOfBananasBunchOfBananas6,

        width: 275.04568,
        height: 221.97311,
        aspectRatio: 1.2390945912322444
      };
    case 'Bundle_of_10_straws':
      return {
        asset: BundleOf10Straws,
        defaultWidth: 167.7103,
        defaultHeight: 207.76112,
        width: 167.7103,
        height: 207.76112,
        aspectRatio: 0.8072265879198186
      };
    case 'Bus':
      return {
        asset: Bus,

        width: 598.84516,
        height: 226.6028,
        aspectRatio: 2.6427085631775067
      };
    case 'Button_blue':
      return {
        asset: ButtonBlue,

        width: 423.41971,
        height: 423.41971,
        aspectRatio: 1
      };
    case 'Button_yellow':
      return {
        asset: ButtonYellow,

        width: 423.41971,
        height: 423.41971,
        aspectRatio: 1
      };
    case 'Cake':
      return {
        asset: Cake,

        width: 481.33639,
        height: 377.31268,
        aspectRatio: 1.2756963004794857
      };
    case 'Can_of_fizzy_drink':
      return {
        asset: CanOfFizzyDrink,

        width: 180.25333,
        height: 328.05691,
        aspectRatio: 0.5494575011390554
      };
    case 'Candle':
      return {
        asset: Candle,

        width: 194.80533,
        height: 281.91632,
        aspectRatio: 0.6910040894404411
      };
    case 'Cans_unlabelled_Green':
      return {
        asset: CansUnlabelledGreen,

        width: 208.66933,
        height: 320.91345,
        aspectRatio: 0.6502355385852479
      };
    case 'Capacity_images/Beaker_1000mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker1000mlcap100mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_1000mlcap_200mlint':
      return {
        asset: CapacityImagesBeaker1000mlcap200mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_1000mlcap_250mlint':
      return {
        asset: CapacityImagesBeaker1000mlcap250mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_1000mlcap_500mlint':
      return {
        asset: CapacityImagesBeaker1000mlcap500mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_1000mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker1000mlcap50mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_100mlcap_10mlint':
      return {
        asset: CapacityImagesBeaker100mlcap10mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_100mlcap_20mlint':
      return {
        asset: CapacityImagesBeaker100mlcap20mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_100mlcap_25mlint':
      return {
        asset: CapacityImagesBeaker100mlcap25mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_100mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker100mlcap50mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_200mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker200mlcap100mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_200mlcap_10mlint':
      return {
        asset: CapacityImagesBeaker200mlcap10mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_200mlcap_20mlint':
      return {
        asset: CapacityImagesBeaker200mlcap20mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_200mlcap_25mlint':
      return {
        asset: CapacityImagesBeaker200mlcap25mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_200mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker200mlcap50mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_250mlcap_125mlint':
      return {
        asset: CapacityImagesBeaker250mlcap125mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_250mlcap_25mlint':
      return {
        asset: CapacityImagesBeaker250mlcap25mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_250mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker250mlcap50mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_300mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker300mlcap100mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_300mlcap_20mlint':
      return {
        asset: CapacityImagesBeaker300mlcap20mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_300mlcap_25mlint':
      return {
        asset: CapacityImagesBeaker300mlcap25mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_300mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker300mlcap50mlint,

        width: 347.08321,
        height: 546.70667,
        aspectRatio: 0.6348618537981254
      };
    case 'Capacity_images/Beaker_400mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker400mlcap100mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_400mlcap_200mlint':
      return {
        asset: CapacityImagesBeaker400mlcap200mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_400mlcap_20mlint':
      return {
        asset: CapacityImagesBeaker400mlcap20mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_400mlcap_25mlint':
      return {
        asset: CapacityImagesBeaker400mlcap25mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_400mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker400mlcap50mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_500mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker500mlcap100mlint,

        width: 515.44157,
        height: 546.70667,
        aspectRatio: 0.9428119287441653
      };
    case 'Capacity_images/Beaker_500mlcap_250mlint':
      return {
        asset: CapacityImagesBeaker500mlcap250mlint,

        width: 515.44157,
        height: 546.70667,
        aspectRatio: 0.9428119287441653
      };
    case 'Capacity_images/Beaker_500mlcap_25mlint':
      return {
        asset: CapacityImagesBeaker500mlcap25mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_500mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker500mlcap50mlint,

        width: 515.44157,
        height: 546.70667,
        aspectRatio: 0.9428119287441653
      };
    case 'Capacity_images/Beaker_600mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker600mlcap100mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_600mlcap_200mlint':
      return {
        asset: CapacityImagesBeaker600mlcap200mlint,

        width: 515.44164,
        height: 546.70667,
        aspectRatio: 0.9428120567835764
      };
    case 'Capacity_images/Beaker_600mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker600mlcap50mlint,

        width: 515.442,
        height: 546.70667,
        aspectRatio: 0.9428127152719757
      };
    case 'Capacity_images/Beaker_700mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker700mlcap100mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_700mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker700mlcap50mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_800mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker800mlcap100mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_800mlcap_200mlint':
      return {
        asset: CapacityImagesBeaker800mlcap200mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_800mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker800mlcap50mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_900mlcap_100mlint':
      return {
        asset: CapacityImagesBeaker900mlcap100mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Beaker_900mlcap_50mlint':
      return {
        asset: CapacityImagesBeaker900mlcap50mlint,

        width: 729.27549,
        height: 546.70667,
        aspectRatio: 1.3339429167747303
      };
    case 'Capacity_images/Empty_jug':
      return {
        asset: CapacityImagesEmptyJug,
        defaultWidth: 705.87225,
        defaultHeight: 781.52411,
        width: 705.87223,
        height: 781.52411,
        aspectRatio: 0.903199557080843
      };
    case 'Capacity_images/Empty_jug_blue':
      return {
        asset: CapacityImagesEmptyJugBlue,
        defaultWidth: 705.87225,
        defaultHeight: 781.52411,
        width: 705.87223,
        height: 781.52411,
        aspectRatio: 0.903199557080843
      };
    case 'Capacity_images/Extra_large_juice_bottle':
      return {
        asset: CapacityImagesExtraLargeJuiceBottle,

        width: 476.5659,
        height: 849.96399,
        aspectRatio: 0.5606895181524102
      };
    case 'Capacity_images/Full_beaker_100mlcap_10mlint':
      return {
        asset: CapacityImagesFullBeaker100mlcap10mlint,

        width: 346.33013,
        height: 545.51465,
        aspectRatio: 0.6348686144359277
      };
    case 'Capacity_images/Full_beaker_1litrecap_100mlint':
      return {
        asset: CapacityImagesFullBeaker1litrecap100mlint,

        width: 505.29405,
        height: 797.10797,
        aspectRatio: 0.6339091679136015
      };
    case 'Capacity_images/Large_juice_bottle':
      return {
        asset: CapacityImagesLargeJuiceBottle,

        width: 272.25857,
        height: 795.448,
        aspectRatio: 0.3422707329706028
      };
    case 'Capacity_images/Medium_juice_bottle':
      return {
        asset: CapacityImagesMediumJuiceBottle,

        width: 222.37768,
        height: 648.29065,
        aspectRatio: 0.3430215752764597
      };
    case 'Capacity_images/Mostly_full_orange_pitcher':
      return {
        asset: CapacityImagesMostlyFullOrangePitcher,

        width: 541.53874,
        height: 652.39862,
        aspectRatio: 0.8300733989903288
      };
    case 'Capacity_images/Mostly_full_water_pitcher':
      return {
        asset: CapacityImagesMostlyFullWaterPitcher,

        width: 541.53874,
        height: 652.39862,
        aspectRatio: 0.8300733989903288
      };
    case 'Capacity_images/None_Transparent/Jug1_1000mlcap_100mlint':
      return {
        asset: CapacityImagesNoneTransparentJug11000mlcap100mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_1000mlcap_200mlint':
      return {
        asset: CapacityImagesNoneTransparentJug11000mlcap200mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_1000mlcap_250mlint':
      return {
        asset: CapacityImagesNoneTransparentJug11000mlcap250mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_1000mlcap_500mlint':
      return {
        asset: CapacityImagesNoneTransparentJug11000mlcap500mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_200mlcap_100mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1200mlcap100mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_200mlcap_20mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1200mlcap20mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_200mlcap_25mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1200mlcap25mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_200mlcap_50mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1200mlcap50mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_500mlcap_100mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1500mlcap100mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_500mlcap_250mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1500mlcap250mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/None_Transparent/Jug1_500mlcap_50mlint':
      return {
        asset: CapacityImagesNoneTransparentJug1500mlcap50mlint,

        width: 910.5026,
        height: 910.50262,
        aspectRatio: 0.9999999780341104
      };
    case 'Capacity_images/Part_filled_beaker_1litrecap_100mlint':
      return {
        asset: CapacityImagesPartFilledBeaker1litrecap100mlint,

        width: 505.29405,
        height: 797.10797,
        aspectRatio: 0.6339091679136015
      };
    case 'Capacity_images/Small_juice_bottle':
      return {
        asset: CapacityImagesSmallJuiceBottle,

        width: 194.81997,
        height: 566.992,
        aspectRatio: 0.3436026786974067
      };
    case 'Car':
      return {
        asset: Car,

        width: 332.58392,
        height: 192.68742,
        aspectRatio: 1.7260281963399582
      };
    case 'Car_tyre':
      return {
        asset: CarTyre,

        width: 318.62467,
        height: 320.07199,
        aspectRatio: 0.9954781422766795
      };
    case 'Card_pack':
      return {
        asset: CardPack,
        defaultWidth: 138.20427,
        defaultHeight: 178.92949,
        width: 138.20427,
        height: 178.92949,
        aspectRatio: 0.7723951485023515
      };
    case 'CardboardBox':
      return {
        asset: CardboardBox,

        width: 75.18666,
        height: 48.677336,
        aspectRatio: 1.5445927443523204
      };
    case 'CardboardBox1':
      return {
        asset: CardboardBox1,
        defaultWidth: 173.14053,
        defaultHeight: 167.63533,
        width: 173.14053,
        height: 167.63533,
        aspectRatio: 1.0328403326434827
      };
    case 'CardboardBox2':
      return {
        asset: CardboardBox2,
        defaultWidth: 199.33333,
        defaultHeight: 89.230667,
        width: 199.33333,
        height: 89.230666,
        aspectRatio: 2.2339105930241514
      };
    case 'CardboardBox3':
      return {
        asset: CardboardBox3,
        defaultWidth: 237.14,
        defaultHeight: 229.6176,
        width: 237.14,
        height: 229.6176,
        aspectRatio: 1.0327605549400394
      };
    case 'CardboardBox4':
      return {
        asset: CardboardBox4,
        defaultWidth: 88.667465,
        defaultHeight: 187.26346,
        width: 88.667466,
        height: 187.26346,
        aspectRatio: 0.47349048233969404
      };
    case 'Carrot':
      return {
        asset: Carrot,

        width: 243.91688,
        height: 76.224548,
        aspectRatio: 3.1999780438186396
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_0':
      return {
        asset: CartonOfEggs012CartonOfEggs0,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_1':
      return {
        asset: CartonOfEggs012CartonOfEggs1,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_10':
      return {
        asset: CartonOfEggs012CartonOfEggs10,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_11':
      return {
        asset: CartonOfEggs012CartonOfEggs11,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_12':
      return {
        asset: CartonOfEggs012CartonOfEggs12,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_2':
      return {
        asset: CartonOfEggs012CartonOfEggs2,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_3':
      return {
        asset: CartonOfEggs012CartonOfEggs3,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_4':
      return {
        asset: CartonOfEggs012CartonOfEggs4,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_5':
      return {
        asset: CartonOfEggs012CartonOfEggs5,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_6':
      return {
        asset: CartonOfEggs012CartonOfEggs6,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_7':
      return {
        asset: CartonOfEggs012CartonOfEggs7,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_8':
      return {
        asset: CartonOfEggs012CartonOfEggs8,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Carton_of_eggs_0_-12/Carton_of_eggs_9':
      return {
        asset: CartonOfEggs012CartonOfEggs9,

        width: 527.15149,
        height: 330.1492,
        aspectRatio: 1.5967068525381856
      };
    case 'Cat':
      return {
        asset: Cat,
        defaultWidth: 763.06995,
        defaultHeight: 692.44397,
        width: 763.06994,
        height: 692.44397,
        aspectRatio: 1.101995212695693
      };
    case 'Cat_w_food_bowl':
      return {
        asset: CatWFoodBowl,
        defaultWidth: 793.70135,
        defaultHeight: 793.70135,
        width: 793.70135,
        height: 793.70135,
        aspectRatio: 1
      };
    case 'Celebration_card':
      return {
        asset: CelebrationCard,

        width: 261,
        height: 461,
        aspectRatio: 0.5661605206073753
      };
    case 'Celebration_card_1':
      return {
        asset: CelebrationCard1,

        width: 260.51205,
        height: 459.9191,
        aspectRatio: 0.5664301613044554
      };
    case 'Celebration_card_2':
      return {
        asset: CelebrationCard2,

        width: 260.51199,
        height: 459.91895,
        aspectRatio: 0.5664302155847243
      };
    case 'Cell':
      return {
        asset: Cell,
        defaultWidth: 34,
        defaultHeight: 34,
        width: 34,
        height: 34,
        aspectRatio: 1
      };
    case 'CellTop':
      return {
        asset: CellTop,
        defaultWidth: 34,
        defaultHeight: 34,
        width: 34,
        height: 34,
        aspectRatio: 1
      };
    case 'ChairBlue':
      return {
        asset: ChairBlue,

        width: 331.34979,
        height: 561.14026,
        aspectRatio: 0.5904937029469245
      };
    case 'ChairPink':
      return {
        asset: ChairPink,

        width: 214.76337,
        height: 332.64667,
        aspectRatio: 0.6456200809104748
      };
    case 'Characters/AlexHead':
      return {
        asset: CharactersAlexHead,

        width: 262.6199,
        height: 164.48204,
        aspectRatio: 1.596647877178566
      };
    case 'Characters/AlexStanding':
      return {
        asset: CharactersAlexStanding,

        width: 298.62772,
        height: 413.9158,
        aspectRatio: 0.721469728867562
      };
    case 'Characters/AmirHead':
      return {
        asset: CharactersAmirHead,

        width: 197.71199,
        height: 153.74776,
        aspectRatio: 1.285950377423385
      };
    case 'Characters/AmirStanding':
      return {
        asset: CharactersAmirStanding,

        width: 298.62772,
        height: 407.72751,
        aspectRatio: 0.7324198457935792
      };
    case 'Characters/AnnieHead':
      return {
        asset: CharactersAnnieHead,

        width: 210.54503,
        height: 169.49178,
        aspectRatio: 1.2422138111948555
      };
    case 'Characters/AnnieStanding':
      return {
        asset: CharactersAnnieStanding,

        width: 298.68427,
        height: 398.87845,
        aspectRatio: 0.7488102453266152
      };
    case 'Characters/DexterHead':
      return {
        asset: CharactersDexterHead,

        width: 189.87161,
        height: 159.78227,
        aspectRatio: 1.18831463591048
      };
    case 'Characters/DexterStanding':
      return {
        asset: CharactersDexterStanding,

        width: 270,
        height: 460,
        aspectRatio: 0.5869565217391305
      };
    case 'Characters/Direction/Jack_body':
      return {
        asset: CharactersDirectionJackBody,

        width: 156.96,
        height: 324.48,
        aspectRatio: 0.48372781065088755
      };
    case 'Characters/Direction/Kim_body':
      return {
        asset: CharactersDirectionKimBody,

        width: 146.88,
        height: 313.92,
        aspectRatio: 0.4678899082568807
      };
    case 'Characters/Direction/Max_body':
      return {
        asset: CharactersDirectionMaxBody,

        width: 136.8,
        height: 326.88,
        aspectRatio: 0.41850220264317184
      };
    case 'Characters/EvaHead':
      return {
        asset: CharactersEvaHead,

        width: 240.08499,
        height: 188.76009,
        aspectRatio: 1.2719054647621753
      };
    case 'Characters/EvaStanding':
      return {
        asset: CharactersEvaStanding,

        width: 298.68427,
        height: 446.61167,
        aspectRatio: 0.6687784714626915
      };
    case 'Characters/JackHead':
      return {
        asset: CharactersJackHead,

        width: 196.79318,
        height: 170.13109,
        aspectRatio: 1.1567149778444374
      };
    case 'Characters/JackStanding':
      return {
        asset: CharactersJackStanding,

        width: 298.62772,
        height: 417.13217,
        aspectRatio: 0.7159067112948877
      };
    case 'Characters/JoHead':
      return {
        asset: CharactersJoHead,

        width: 200.30664,
        height: 245.42926,
        aspectRatio: 0.8161481642408895
      };
    case 'Characters/JoStanding':
      return {
        asset: CharactersJoStanding,

        width: 298.62772,
        height: 399.85111,
        aspectRatio: 0.7468472952344687
      };
    case 'Characters/KimHead':
      return {
        asset: CharactersKimHead,

        width: 235.49026,
        height: 199.63081,
        aspectRatio: 1.1796288358495366
      };
    case 'Characters/KimStanding':
      return {
        asset: CharactersKimStanding,

        width: 298.62772,
        height: 400.77985,
        aspectRatio: 0.7451166020447385
      };
    case 'Characters/MaxHead':
      return {
        asset: CharactersMaxHead,

        width: 184.78198,
        height: 170.37303,
        aspectRatio: 1.084572951481816
      };
    case 'Characters/MaxStanding':
      return {
        asset: CharactersMaxStanding,

        width: 270,
        height: 417.234,
        aspectRatio: 0.6471188829290039
      };
    case 'Characters/MoHead':
      return {
        asset: CharactersMoHead,

        width: 185.05058,
        height: 139.46216,
        aspectRatio: 1.3268873793436153
      };
    case 'Characters/MoStanding':
      return {
        asset: CharactersMoStanding,

        width: 298.62772,
        height: 399.85111,
        aspectRatio: 0.7468472952344687
      };
    case 'Characters/RonHead':
      return {
        asset: CharactersRonHead,

        width: 185.34377,
        height: 152.5241,
        aspectRatio: 1.2151769458072528
      };
    case 'Characters/RonStanding':
      return {
        asset: CharactersRonStanding,

        width: 298.62772,
        height: 404.04071,
        aspectRatio: 0.7391030473142175
      };
    case 'Characters/RosieHead':
      return {
        asset: CharactersRosieHead,

        width: 197.86449,
        height: 204.79428,
        aspectRatio: 0.9661621896861573
      };
    case 'Characters/RosieStanding':
      return {
        asset: CharactersRosieStanding,

        width: 298.68427,
        height: 398.87845,
        aspectRatio: 0.7488102453266152
      };
    case 'Characters/SamHead':
      return {
        asset: CharactersSamHead,

        width: 214.11614,
        height: 210.31564,
        aspectRatio: 1.0180704582883138
      };
    case 'Characters/SamStanding':
      return {
        asset: CharactersSamStanding,

        width: 298.68427,
        height: 453.05722,
        aspectRatio: 0.6592639004847998
      };
    case 'Characters/TeddyHead':
      return {
        asset: CharactersTeddyHead,

        width: 196.18579,
        height: 170.70523,
        aspectRatio: 1.149266428450962
      };
    case 'Characters/TeddyStanding':
      return {
        asset: CharactersTeddyStanding,

        width: 270,
        height: 415.449,
        aspectRatio: 0.6498992656138298
      };
    case 'Characters/TommyHead':
      return {
        asset: CharactersTommyHead,

        width: 186.54134,
        height: 160.8021,
        aspectRatio: 1.1600678100596944
      };
    case 'Characters/TommyStanding':
      return {
        asset: CharactersTommyStanding,

        width: 298.62772,
        height: 425.15897,
        aspectRatio: 0.702390731636216
      };
    case 'Characters/WhitneyHead':
      return {
        asset: CharactersWhitneyHead,

        width: 275.90741,
        height: 155.79347,
        aspectRatio: 1.7709818646442628
      };
    case 'Characters/WhitneyStanding':
      return {
        asset: CharactersWhitneyStanding,

        width: 270,
        height: 400.223,
        aspectRatio: 0.6746238971773236
      };
    case 'CheckersBoard':
      return {
        asset: CheckersBoard,

        width: 529.09375,
        height: 549.67822,
        aspectRatio: 0.9625517816587311
      };
    case 'Cherry':
      return {
        asset: Cherry,

        width: 139.85967,
        height: 211.75256,
        aspectRatio: 0.6604863242267296
      };
    case 'Chew_sweet_candy_bar':
      return {
        asset: ChewSweetCandyBar,

        width: 535.69903,
        height: 108.75732,
        aspectRatio: 4.925636545659639
      };
    case 'Circles/Circle_quarter_white':
      return {
        asset: CirclesCircleQuarterWhite,

        width: 330,
        height: 330,
        aspectRatio: 1
      };
    case 'Circles/Inner_outer_circles/Inner_outer_circle_large_A':
      return {
        asset: CirclesInnerOuterCirclesInnerOuterCircleLargeA,

        width: 168.8058,
        height: 168.8058,
        aspectRatio: 1
      };
    case 'Circles/Inner_outer_circles/Inner_outer_circle_large_B':
      return {
        asset: CirclesInnerOuterCirclesInnerOuterCircleLargeB,

        width: 168.8058,
        height: 168.8058,
        aspectRatio: 1
      };
    case 'Circles/Inner_outer_circles/Inner_outer_circle_large_C':
      return {
        asset: CirclesInnerOuterCirclesInnerOuterCircleLargeC,

        width: 168.8058,
        height: 168.8058,
        aspectRatio: 1
      };
    case 'Circles/Inner_outer_circles/Inner_outer_circle_medium_A':
      return {
        asset: CirclesInnerOuterCirclesInnerOuterCircleMediumA,

        width: 168.8058,
        height: 168.8058,
        aspectRatio: 1
      };
    case 'Circles/Inner_outer_circles/Inner_outer_circle_medium_B':
      return {
        asset: CirclesInnerOuterCirclesInnerOuterCircleMediumB,

        width: 168.8058,
        height: 168.8058,
        aspectRatio: 1
      };
    case 'Circles/Inner_outer_circles/Inner_outer_circle_medium_C':
      return {
        asset: CirclesInnerOuterCirclesInnerOuterCircleMediumC,

        width: 168.8058,
        height: 168.8058,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_centre_arrow':
      return {
        asset: CirclesPartsOfACircleCirclePartsCentreArrow,

        width: 231.88105,
        height: 180.96187,
        aspectRatio: 1.2813807129645598
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_circumference_arrow':
      return {
        asset: CirclesPartsOfACircleCirclePartsCircumferenceArrow,

        width: 231.70316,
        height: 180.96187,
        aspectRatio: 1.2803976881980716
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_arrow':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterArrow,

        width: 229.65947,
        height: 180.96187,
        aspectRatio: 1.269104204106644
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_correct':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterCorrect,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_A':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterIncorrectA,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_B':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterIncorrectB,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_C':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterIncorrectC,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_D':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterIncorrectD,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_E':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterIncorrectE,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_diameter_incorrect_F':
      return {
        asset: CirclesPartsOfACircleCirclePartsDiameterIncorrectF,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_arrow':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusArrow,

        width: 229.65947,
        height: 180.96187,
        aspectRatio: 1.269104204106644
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_correct':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusCorrect,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_A':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusIncorrectA,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_B':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusIncorrectB,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_C':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusIncorrectC,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_D':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusIncorrectD,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_E':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusIncorrectE,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/Parts_of_a_circle/Circle_parts_radius_incorrect_F':
      return {
        asset: CirclesPartsOfACircleCirclePartsRadiusIncorrectF,

        width: 180.96187,
        height: 180.96187,
        aspectRatio: 1
      };
    case 'Circles/circle_':
      return {
        asset: CirclesCircle,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_black':
      return {
        asset: CirclesCircleBlack,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_blue':
      return {
        asset: CirclesCircleBlue,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_green':
      return {
        asset: CirclesCircleGreen,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_grey':
      return {
        asset: CirclesCircleGrey,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_orange':
      return {
        asset: CirclesCircleOrange,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_orange_protractor':
      return {
        asset: CirclesCircleOrangeProtractor,
        defaultWidth: 55,
        defaultHeight: 55,
        width: 55,
        height: 55,
        aspectRatio: 1
      };
    case 'Circles/circle_pink':
      return {
        asset: CirclesCirclePink,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_purple':
      return {
        asset: CirclesCirclePurple,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_red':
      return {
        asset: CirclesCircleRed,

        width: 329.333,
        height: 329.333,
        aspectRatio: 1
      };
    case 'Circles/circle_turquoise':
      return {
        asset: CirclesCircleTurquoise,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_white':
      return {
        asset: CirclesCircleWhite,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Circles/circle_yellow':
      return {
        asset: CirclesCircleYellow,

        width: 329.333,
        height: 329.333,
        aspectRatio: 1
      };
    case 'Clock':
      return {
        asset: Clock,

        width: 224.8,
        height: 224.8,
        aspectRatio: 1
      };
    case 'ClockFaceBlue':
      return {
        asset: ClockFaceBlue,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceBlueWithoutMinuteIntervals':
      return {
        asset: ClockFaceBlueWithoutMinuteIntervals,

        defaultHeight: 224.80002,
        width: 224.8,
        height: 224.80002,
        aspectRatio: 0.9999999110320364
      };
    case 'ClockFaceOrange':
      return {
        asset: ClockFaceOrange,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceOrangeWithoutMinuteIntervals':
      return {
        asset: ClockFaceOrangeWithoutMinuteIntervals,

        defaultHeight: 224.80002,
        width: 224.8,
        height: 224.80002,
        aspectRatio: 0.9999999110320364
      };
    case 'ClockFaceRomanBlue':
      return {
        asset: ClockFaceRomanBlue,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceRomanBlueWithoutMinuteIntervals':
      return {
        asset: ClockFaceRomanBlueWithoutMinuteIntervals,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceRomanOrange':
      return {
        asset: ClockFaceRomanOrange,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceRomanOrangeWithoutMinuteIntervals':
      return {
        asset: ClockFaceRomanOrangeWithoutMinuteIntervals,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceRomanYellow':
      return {
        asset: ClockFaceRomanYellow,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceRomanYellowWithoutMinuteIntervals':
      return {
        asset: ClockFaceRomanYellowWithoutMinuteIntervals,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceYellow':
      return {
        asset: ClockFaceYellow,

        width: 224.79998,
        height: 224.80002,
        aspectRatio: 0.9999998220640728
      };
    case 'ClockFaceYellowWithoutMinuteIntervals':
      return {
        asset: ClockFaceYellowWithoutMinuteIntervals,

        defaultHeight: 224.80002,
        width: 224.8,
        height: 224.80002,
        aspectRatio: 0.9999999110320364
      };
    case 'ClockHour':
      return {
        asset: ClockHour,

        width: 224.8,
        height: 224.8,
        aspectRatio: 1
      };
    case 'ClockMinute':
      return {
        asset: ClockMinute,

        width: 224.8,
        height: 224.8,
        aspectRatio: 1
      };
    case 'ClockMinuteCircle':
      return {
        asset: ClockMinuteCircle,

        width: 224.8,
        height: 224.8,
        aspectRatio: 1
      };
    case 'Close':
      return {
        asset: Close,

        width: 32,
        height: 32,
        aspectRatio: 1
      };
    case 'Closed_book':
      return {
        asset: ClosedBook,

        width: 306.75607,
        height: 221.64584,
        aspectRatio: 1.38399200273734
      };
    case 'Clothes/Gloves_mittens_blue':
      return {
        asset: ClothesGlovesMittensBlue,

        width: 448.67935,
        height: 493.73755,
        aspectRatio: 0.9087405849524712
      };
    case 'Clothes/Gloves_mittens_green':
      return {
        asset: ClothesGlovesMittensGreen,

        width: 448.67935,
        height: 493.73755,
        aspectRatio: 0.9087405849524712
      };
    case 'Clothes/Gloves_mittens_orange':
      return {
        asset: ClothesGlovesMittensOrange,

        width: 448.67935,
        height: 493.73755,
        aspectRatio: 0.9087405849524712
      };
    case 'Clothes/Gloves_mittens_red':
      return {
        asset: ClothesGlovesMittensRed,

        width: 448.67935,
        height: 493.73755,
        aspectRatio: 0.9087405849524712
      };
    case 'Clothes/Gloves_mittens_yellow.ai':
      return {
        asset: ClothesGlovesMittensYellowAi,

        width: 448.67935,
        height: 493.73755,
        aspectRatio: 0.9087405849524712
      };
    case 'Clothes/Hat_blue':
      return {
        asset: ClothesHatBlue,

        width: 298.54507,
        height: 199.09602,
        aspectRatio: 1.4995029533990685
      };
    case 'Clothes/Hat_cap':
      return {
        asset: ClothesHatCap,

        width: 346.522,
        height: 174.68577,
        aspectRatio: 1.983687623782979
      };
    case 'Clothes/Hat_woolly':
      return {
        asset: ClothesHatWoolly,

        width: 229.61378,
        height: 204.33066,
        aspectRatio: 1.12373630075878
      };
    case 'Clothes/Jumper_blue':
      return {
        asset: ClothesJumperBlue,

        width: 451.7449,
        height: 373.36993,
        aspectRatio: 1.2099123783214143
      };
    case 'Clothes/Scarf_purple':
      return {
        asset: ClothesScarfPurple,

        width: 611.281,
        height: 703.39611,
        aspectRatio: 0.8690423380362452
      };
    case 'Clothes/Scarf_white':
      return {
        asset: ClothesScarfWhite,

        width: 611.28112,
        height: 703.39611,
        aspectRatio: 0.8690425086371318
      };
    case 'Clothes/Shorts_grey':
      return {
        asset: ClothesShortsGrey,

        width: 195.42361,
        height: 180.27913,
        aspectRatio: 1.0840057304470017
      };
    case 'Clothes/T-shirt_blue':
      return {
        asset: ClothesTShirtBlue,

        width: 370.74267,
        height: 373.0812,
        aspectRatio: 0.9937318471153196
      };
    case 'Compass_4_labels':
      return {
        asset: Compass4Labels,

        width: 306.32266,
        height: 306.32266,
        aspectRatio: 1
      };
    case 'Compass_8_labels':
      return {
        asset: Compass8Labels,

        width: 306.32266,
        height: 306.32266,
        aspectRatio: 1
      };
    case 'Compost_bag':
      return {
        asset: CompostBag,

        width: 299.74219,
        height: 364.62903,
        aspectRatio: 0.8220469719594186
      };
    case 'CompoundShapes/EquilateralTriangle_2_parts_1':
      return {
        asset: CompoundShapesEquilateralTriangle2Parts1,

        width: 570,
        height: 332,
        aspectRatio: 1.716867469879518
      };
    case 'CompoundShapes/EquilateralTriangle_3_parts_1':
      return {
        asset: CompoundShapesEquilateralTriangle3Parts1,

        width: 756,
        height: 333,
        aspectRatio: 2.27027027027027
      };
    case 'CompoundShapes/EquilateralTriangle_4_parts_1':
      return {
        asset: CompoundShapesEquilateralTriangle4Parts1,

        width: 948,
        height: 333,
        aspectRatio: 2.8468468468468466
      };
    case 'CompoundShapes/EquilateralTriangle_4_parts_2':
      return {
        asset: CompoundShapesEquilateralTriangle4Parts2,

        width: 757,
        height: 657,
        aspectRatio: 1.15220700152207
      };
    case 'CompoundShapes/EquilateralTriangle_5_parts_1':
      return {
        asset: CompoundShapesEquilateralTriangle5Parts1,

        width: 948,
        height: 657,
        aspectRatio: 1.4429223744292237
      };
    case 'CompoundShapes/QuarterCircle_2_parts_1':
      return {
        asset: CompoundShapesQuarterCircle2Parts1,

        width: 654,
        height: 330,
        aspectRatio: 1.981818181818182
      };
    case 'CompoundShapes/QuarterCircle_3_parts_1':
      return {
        asset: CompoundShapesQuarterCircle3Parts1,

        width: 654,
        height: 654,
        aspectRatio: 1
      };
    case 'CompoundShapes/QuarterCircle_4_parts_1':
      return {
        asset: CompoundShapesQuarterCircle4Parts1,

        width: 654,
        height: 654,
        aspectRatio: 1
      };
    case 'CompoundShapes/QuarterCircle_5_parts_1':
      return {
        asset: CompoundShapesQuarterCircle5Parts1,

        width: 978,
        height: 654,
        aspectRatio: 1.4954128440366972
      };
    case 'CompoundShapes/RATriangle_2_parts_1':
      return {
        asset: CompoundShapesRATriangle2Parts1,

        width: 330,
        height: 330,
        aspectRatio: 1
      };
    case 'CompoundShapes/RATriangle_3_parts_1':
      return {
        asset: CompoundShapesRATriangle3Parts1,

        width: 658,
        height: 325,
        aspectRatio: 2.0246153846153847
      };
    case 'CompoundShapes/RATriangle_4_parts_1':
      return {
        asset: CompoundShapesRATriangle4Parts1,

        width: 646,
        height: 325,
        aspectRatio: 1.9876923076923076
      };
    case 'CompoundShapes/RATriangle_4_parts_2':
      return {
        asset: CompoundShapesRATriangle4Parts2,

        width: 990,
        height: 326,
        aspectRatio: 3.03680981595092
      };
    case 'CompoundShapes/RATriangle_4_parts_3':
      return {
        asset: CompoundShapesRATriangle4Parts3,

        width: 658,
        height: 654,
        aspectRatio: 1.0061162079510704
      };
    case 'CompoundShapes/RATriangle_5_parts_1':
      return {
        asset: CompoundShapesRATriangle5Parts1,

        width: 972,
        height: 325,
        aspectRatio: 2.9907692307692306
      };
    case 'CompoundShapes/Rectangle_2_parts_1':
      return {
        asset: CompoundShapesRectangle2Parts1,

        width: 450,
        height: 508,
        aspectRatio: 0.8858267716535433
      };
    case 'CompoundShapes/Rectangle_3_parts_1':
      return {
        asset: CompoundShapesRectangle3Parts1,

        width: 894,
        height: 508,
        aspectRatio: 1.7598425196850394
      };
    case 'CompoundShapes/Rectangle_4_parts_1':
      return {
        asset: CompoundShapesRectangle4Parts1,

        width: 894,
        height: 508,
        aspectRatio: 1.7598425196850394
      };
    case 'CompoundShapes/Rectangle_4_parts_2':
      return {
        asset: CompoundShapesRectangle4Parts2,

        width: 1010,
        height: 450,
        aspectRatio: 2.2444444444444445
      };
    case 'CompoundShapes/Rectangle_5_parts_1':
      return {
        asset: CompoundShapesRectangle5Parts1,

        width: 1337,
        height: 508,
        aspectRatio: 2.6318897637795278
      };
    case 'CompoundShapes/Square_2_parts_1':
      return {
        asset: CompoundShapesSquare2Parts1,

        width: 330,
        height: 654,
        aspectRatio: 0.5045871559633027
      };
    case 'CompoundShapes/Square_3_parts_1':
      return {
        asset: CompoundShapesSquare3Parts1,

        width: 654,
        height: 654,
        aspectRatio: 1
      };
    case 'CompoundShapes/Square_3_parts_2':
      return {
        asset: CompoundShapesSquare3Parts2,

        width: 978,
        height: 330,
        aspectRatio: 2.963636363636364
      };
    case 'CompoundShapes/Square_4_parts_1':
      return {
        asset: CompoundShapesSquare4Parts1,

        width: 654,
        height: 654,
        aspectRatio: 1
      };
    case 'CompoundShapes/Square_4_parts_2':
      return {
        asset: CompoundShapesSquare4Parts2,

        width: 1302,
        height: 330,
        aspectRatio: 3.9454545454545453
      };
    case 'CompoundShapes/Square_5_parts_1':
      return {
        asset: CompoundShapesSquare5Parts1,

        width: 978,
        height: 654,
        aspectRatio: 1.4954128440366972
      };
    case 'CompoundShapes/Square_5_parts_2':
      return {
        asset: CompoundShapesSquare5Parts2,

        width: 1626,
        height: 330,
        aspectRatio: 4.927272727272728
      };
    case 'Cone_dotted_lines':
      return {
        asset: ConeDottedLines,

        width: 590.14903,
        height: 628.5412,
        aspectRatio: 0.9389186102677121
      };
    case 'Containers/container':
      return {
        asset: ContainersContainer,

        width: 111,
        height: 165,
        aspectRatio: 0.6727272727272727
      };
    case 'Cookie':
      return {
        asset: Cookie,

        width: 115.94,
        height: 85.308,
        aspectRatio: 1.359075350494678
      };
    case 'Cookie_biscuit/Cookie_biscuit_5':
      return {
        asset: CookieBiscuitCookieBiscuit5,

        width: 230.10933,
        height: 190.10133,
        aspectRatio: 1.210456181448073
      };
    case 'Cookie_biscuit/Cookie_biscuit_6':
      return {
        asset: CookieBiscuitCookieBiscuit6,

        width: 230.10933,
        height: 190.10133,
        aspectRatio: 1.210456181448073
      };
    case 'Cookie_biscuit/Cookie_biscuit_7':
      return {
        asset: CookieBiscuitCookieBiscuit7,

        width: 230.10933,
        height: 190.10133,
        aspectRatio: 1.210456181448073
      };
    case 'Cookie_jar':
      return {
        asset: CookieJar,

        width: 190.02133,
        height: 258.20132,
        aspectRatio: 0.7359425195812322
      };
    case 'Cooking_pot':
      return {
        asset: CookingPot,

        width: 459.54959,
        height: 322.46533,
        aspectRatio: 1.4251131741821672
      };
    case 'Coordinates/CirclePoint':
      return {
        asset: CoordinatesCirclePoint,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/CrossPoint':
      return {
        asset: CoordinatesCrossPoint,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/FilledSquarePoint':
      return {
        asset: CoordinatesFilledSquarePoint,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/SquarePoint':
      return {
        asset: CoordinatesSquarePoint,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/TrianglePoint':
      return {
        asset: CoordinatesTrianglePoint,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'CountIn50/Balloons50':
      return {
        asset: CountIn50Balloons50,

        width: 115.46089,
        height: 193.17212,
        aspectRatio: 0.5977099076202094
      };
    case 'CountIn50/Bricks50':
      return {
        asset: CountIn50Bricks50,

        width: 75.777329,
        height: 49.053333,
        aspectRatio: 1.5447947033487
      };
    case 'CountIn50/Crayons50':
      return {
        asset: CountIn50Crayons50,

        width: 509.79172,
        height: 257.01373,
        aspectRatio: 1.983519401862305
      };
    case 'CountIn50/Lollipops50':
      return {
        asset: CountIn50Lollipops50,

        width: 240.54637,
        height: 438.02679,
        aspectRatio: 0.5491590365968255
      };
    case 'CountIn50/Marbles50':
      return {
        asset: CountIn50Marbles50,

        width: 501.73913,
        height: 114.71432,
        aspectRatio: 4.373814271836332
      };
    case 'CountIn50/Sweets50':
      return {
        asset: CountIn50Sweets50,

        width: 131.9086,
        height: 172.36132,
        aspectRatio: 0.7653027953139371
      };
    case 'Counter':
      return {
        asset: Counter,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'CounterRed':
      return {
        asset: CounterRed,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'CounterWhite':
      return {
        asset: CounterWhite,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_10':
      return {
        asset: CountersFractionsCountersUnitFractions110,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_11':
      return {
        asset: CountersFractionsCountersUnitFractions111,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_12':
      return {
        asset: CountersFractionsCountersUnitFractions112,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_2':
      return {
        asset: CountersFractionsCountersUnitFractions12,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_3':
      return {
        asset: CountersFractionsCountersUnitFractions13,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_4':
      return {
        asset: CountersFractionsCountersUnitFractions14,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_5':
      return {
        asset: CountersFractionsCountersUnitFractions15,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_6':
      return {
        asset: CountersFractionsCountersUnitFractions16,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_7':
      return {
        asset: CountersFractionsCountersUnitFractions17,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_8':
      return {
        asset: CountersFractionsCountersUnitFractions18,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Counters_unit_fractions1_9':
      return {
        asset: CountersFractionsCountersUnitFractions19,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_10_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions110TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_11_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions111TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_12_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions112TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_2_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions12TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_3_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions13TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_4_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions14TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_5_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions15TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_6.ai_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions16AiTextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_7_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions17TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_8_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions18TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Counters_fractions/Text_outlined/Counters_unit_fractions1_9_text_outlined':
      return {
        asset: CountersFractionsTextOutlinedCountersUnitFractions19TextOutlined,

        width: 136,
        height: 136,
        aspectRatio: 1
      };
    case 'Cow':
      return {
        asset: Cow,

        width: 403.45038,
        height: 316.41641,
        aspectRatio: 1.275061492543955
      };
    case 'CrayonBox':
      return {
        asset: CrayonBox,

        width: 111.804,
        height: 171.468,
        aspectRatio: 0.6520400307929177
      };
    case 'Crayons_box_pack_of_8':
      return {
        asset: CrayonsBoxPackOf8,

        width: 151,
        height: 259,
        aspectRatio: 0.583011583011583
      };
    case 'Crosses/cross':
      return {
        asset: CrossesCross,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_blue':
      return {
        asset: CrossesCrossBlue,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_green':
      return {
        asset: CrossesCrossGreen,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_orange':
      return {
        asset: CrossesCrossOrange,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_pink':
      return {
        asset: CrossesCrossPink,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_purple':
      return {
        asset: CrossesCrossPurple,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_red':
      return {
        asset: CrossesCrossRed,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_turquoise':
      return {
        asset: CrossesCrossTurquoise,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_white':
      return {
        asset: CrossesCrossWhite,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Crosses/cross_yellow':
      return {
        asset: CrossesCrossYellow,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Cubes_With_1_Arrow/cube1_arrow':
      return {
        asset: CubesWith1ArrowCube1Arrow,

        width: 865.06279,
        height: 814.77623,
        aspectRatio: 1.061718246247807
      };
    case 'Cubes_With_1_Arrow/cube2_arrow':
      return {
        asset: CubesWith1ArrowCube2Arrow,

        width: 826.31944,
        height: 853.04558,
        aspectRatio: 0.9686697397810795
      };
    case 'Cubes_With_1_Arrow/cube3_arrow':
      return {
        asset: CubesWith1ArrowCube3Arrow,

        width: 850.12663,
        height: 823.77568,
        aspectRatio: 1.031988016446419
      };
    case 'Cubes_With_3_Arrows/Cube_3_arrows_blue':
      return {
        asset: CubesWith3ArrowsCube3ArrowsBlue,

        width: 888.77018,
        height: 855.35405,
        aspectRatio: 1.0390670155826116
      };
    case 'Cubes_With_3_Arrows/Cube_3_arrows_green':
      return {
        asset: CubesWith3ArrowsCube3ArrowsGreen,

        width: 888.77018,
        height: 855.35405,
        aspectRatio: 1.0390670155826116
      };
    case 'Cubes_With_3_Arrows/Cube_3_arrows_red':
      return {
        asset: CubesWith3ArrowsCube3ArrowsRed,

        width: 888.77018,
        height: 855.35405,
        aspectRatio: 1.0390670155826116
      };
    case 'Cubes_With_3_Arrows/Cube_3_arrows_white':
      return {
        asset: CubesWith3ArrowsCube3ArrowsWhite,

        width: 888.77018,
        height: 855.35405,
        aspectRatio: 1.0390670155826116
      };
    case 'Cubes_With_3_Arrows/Cube_3_arrows_yellow':
      return {
        asset: CubesWith3ArrowsCube3ArrowsYellow,

        width: 888.77018,
        height: 855.35405,
        aspectRatio: 1.0390670155826116
      };
    case 'Cubes_blank/Coloured_cube_unlabelled_blue':
      return {
        asset: CubesBlankColouredCubeUnlabelledBlue,

        width: 220.69319,
        height: 201.61674,
        aspectRatio: 1.0946173913931947
      };
    case 'Cubes_blank/Coloured_cube_unlabelled_green':
      return {
        asset: CubesBlankColouredCubeUnlabelledGreen,

        width: 220.25453,
        height: 201.21687,
        aspectRatio: 1.0946126435621426
      };
    case 'Cubes_blank/Coloured_cube_unlabelled_orange':
      return {
        asset: CubesBlankColouredCubeUnlabelledOrange,

        width: 220.25453,
        height: 201.21687,
        aspectRatio: 1.0946126435621426
      };
    case 'Cubes_blank/Coloured_cube_unlabelled_purple':
      return {
        asset: CubesBlankColouredCubeUnlabelledPurple,

        width: 220.69319,
        height: 201.61674,
        aspectRatio: 1.0946173913931947
      };
    case 'Cubes_blank/Coloured_cube_unlabelled_red':
      return {
        asset: CubesBlankColouredCubeUnlabelledRed,

        width: 220.25453,
        height: 201.21687,
        aspectRatio: 1.0946126435621426
      };
    case 'Cubes_blank/Coloured_cube_unlabelled_yellow':
      return {
        asset: CubesBlankColouredCubeUnlabelledYellow,

        width: 221.64691,
        height: 202.48634,
        aspectRatio: 1.094626481964166
      };
    case 'Cubes_labelled/Coloured_cube_labelled_blue':
      return {
        asset: CubesLabelledColouredCubeLabelledBlue,

        width: 220.69319,
        height: 201.61674,
        aspectRatio: 1.0946173913931947
      };
    case 'Cubes_labelled/Coloured_cube_labelled_green':
      return {
        asset: CubesLabelledColouredCubeLabelledGreen,

        width: 220.25453,
        height: 201.21687,
        aspectRatio: 1.0946126435621426
      };
    case 'Cubes_labelled/Coloured_cube_labelled_orange':
      return {
        asset: CubesLabelledColouredCubeLabelledOrange,

        width: 220.25453,
        height: 201.21687,
        aspectRatio: 1.0946126435621426
      };
    case 'Cubes_labelled/Coloured_cube_labelled_purple':
      return {
        asset: CubesLabelledColouredCubeLabelledPurple,

        width: 220.69319,
        height: 201.61674,
        aspectRatio: 1.0946173913931947
      };
    case 'Cubes_labelled/Coloured_cube_labelled_red':
      return {
        asset: CubesLabelledColouredCubeLabelledRed,

        width: 220.25453,
        height: 201.21687,
        aspectRatio: 1.0946126435621426
      };
    case 'Cubes_labelled/Coloured_cube_labelled_yellow':
      return {
        asset: CubesLabelledColouredCubeLabelledYellow,

        width: 221.64691,
        height: 202.48634,
        aspectRatio: 1.094626481964166
      };
    case 'Cuboids/Cuboid1':
      return {
        asset: CuboidsCuboid1,

        width: 436.09177,
        height: 360.00042,
        aspectRatio: 1.2113646145190609
      };
    case 'Cuboids/Cuboid2':
      return {
        asset: CuboidsCuboid2,

        width: 403.49799,
        height: 321.1648,
        aspectRatio: 1.2563580753557053
      };
    case 'Cuboids/Cuboid3':
      return {
        asset: CuboidsCuboid3,

        width: 267.17493,
        height: 360.13931,
        aspectRatio: 0.7418655019914377
      };
    case 'Cuboids_With_3_Arrows/cuboid1_3_arrows':
      return {
        asset: CuboidsWith3ArrowsCuboid13Arrows,

        width: 745.31145,
        height: 463.85305,
        aspectRatio: 1.6067835492296538
      };
    case 'Cuboids_With_3_Arrows/cuboid2_3_arrows':
      return {
        asset: CuboidsWith3ArrowsCuboid23Arrows,

        width: 435.56039,
        height: 541.32721,
        aspectRatio: 0.8046157332457017
      };
    case 'Cuboids_With_3_Arrows/cuboid3_3_arrows':
      return {
        asset: CuboidsWith3ArrowsCuboid33Arrows,

        width: 511.4757,
        height: 539.16772,
        aspectRatio: 0.9486393213599656
      };
    case 'Cuboids_With_3_Arrows/cuboid4_3_arrows':
      return {
        asset: CuboidsWith3ArrowsCuboid43Arrows,

        width: 811.62328,
        height: 422.00226,
        aspectRatio: 1.9232676147279402
      };
    case 'Cuboids_With_3_Arrows/cuboid5_3_arrows':
      return {
        asset: CuboidsWith3ArrowsCuboid53Arrows,

        width: 1298.5899,
        height: 354.39081,
        aspectRatio: 3.6642877392898536
      };
    case 'Cuboids_With_3_Arrows/cuboid6_3_arrows':
      return {
        asset: CuboidsWith3ArrowsCuboid63Arrows,

        width: 546.45294,
        height: 1240.2152,
        aspectRatio: 0.4406113874430824
      };
    case 'Desk':
      return {
        asset: Desk,

        width: 331.71585,
        height: 306.40866,
        aspectRatio: 1.0825929332415083
      };
    case 'Dice':
      return {
        asset: Dice,

        width: 793.70135,
        height: 793.70135,
        aspectRatio: 1
      };
    case 'Dice_faces/1':
      return {
        asset: DiceFaces1,

        width: 342.38532,
        height: 342.38532,
        aspectRatio: 1
      };
    case 'Dice_faces/2':
      return {
        asset: DiceFaces2,

        width: 342.38532,
        height: 342.38532,
        aspectRatio: 1
      };
    case 'Dice_faces/3':
      return {
        asset: DiceFaces3,

        width: 342.38532,
        height: 342.38532,
        aspectRatio: 1
      };
    case 'Dice_faces/4':
      return {
        asset: DiceFaces4,

        width: 342.38532,
        height: 342.38532,
        aspectRatio: 1
      };
    case 'Dice_faces/5':
      return {
        asset: DiceFaces5,

        width: 342.38532,
        height: 342.38532,
        aspectRatio: 1
      };
    case 'Dice_faces/6':
      return {
        asset: DiceFaces6,

        width: 342.38532,
        height: 342.38532,
        aspectRatio: 1
      };
    case 'DigitalStopwatch':
      return {
        asset: DigitalStopwatch,

        width: 484,
        height: 551,
        aspectRatio: 0.8784029038112523
      };
    case 'Dodecagons/Irregular_dodecagon1_blue':
      return {
        asset: DodecagonsIrregularDodecagon1Blue,

        width: 416.88668,
        height: 416.88925,
        aspectRatio: 0.9999938352931864
      };
    case 'Dodecagons/Irregular_dodecagon1_green':
      return {
        asset: DodecagonsIrregularDodecagon1Green,

        width: 416.88668,
        height: 416.88925,
        aspectRatio: 0.9999938352931864
      };
    case 'Dodecagons/Irregular_dodecagon1_pink':
      return {
        asset: DodecagonsIrregularDodecagon1Pink,

        width: 416.88668,
        height: 416.88925,
        aspectRatio: 0.9999938352931864
      };
    case 'Dodecagons/Irregular_dodecagon1_purple':
      return {
        asset: DodecagonsIrregularDodecagon1Purple,

        width: 416.88668,
        height: 416.88925,
        aspectRatio: 0.9999938352931864
      };
    case 'Dodecagons/Irregular_dodecagon1_white':
      return {
        asset: DodecagonsIrregularDodecagon1White,

        width: 416.88668,
        height: 416.88925,
        aspectRatio: 0.9999938352931864
      };
    case 'Dodecagons/Irregular_dodecagon1_yellow':
      return {
        asset: DodecagonsIrregularDodecagon1Yellow,

        width: 416.88668,
        height: 416.88925,
        aspectRatio: 0.9999938352931864
      };
    case 'Dodecagons/Irregular_dodecagon2_blue':
      return {
        asset: DodecagonsIrregularDodecagon2Blue,

        width: 418.05965,
        height: 397.47153,
        aspectRatio: 1.0517977224683237
      };
    case 'Dodecagons/Irregular_dodecagon2_green':
      return {
        asset: DodecagonsIrregularDodecagon2Green,

        width: 418.05965,
        height: 397.47153,
        aspectRatio: 1.0517977224683237
      };
    case 'Dodecagons/Irregular_dodecagon2_pink':
      return {
        asset: DodecagonsIrregularDodecagon2Pink,

        width: 418.05965,
        height: 397.47153,
        aspectRatio: 1.0517977224683237
      };
    case 'Dodecagons/Irregular_dodecagon2_purple':
      return {
        asset: DodecagonsIrregularDodecagon2Purple,

        width: 418.05965,
        height: 397.47153,
        aspectRatio: 1.0517977224683237
      };
    case 'Dodecagons/Irregular_dodecagon2_white':
      return {
        asset: DodecagonsIrregularDodecagon2White,

        width: 418.05965,
        height: 397.47153,
        aspectRatio: 1.0517977224683237
      };
    case 'Dodecagons/Irregular_dodecagon2_yellow':
      return {
        asset: DodecagonsIrregularDodecagon2Yellow,

        width: 418.05965,
        height: 397.47153,
        aspectRatio: 1.0517977224683237
      };
    case 'Dogs/dog1':
      return {
        asset: DogsDog1,
        defaultWidth: NaN,
        defaultHeight: NaN,
        width: 176.98018,
        height: 168.17087,
        aspectRatio: 1.0523830910787342
      };
    case 'Dogs/dog2':
      return {
        asset: DogsDog2,
        defaultWidth: NaN,
        defaultHeight: NaN,
        width: 176.98018,
        height: 168.17087,
        aspectRatio: 1.0523830910787342
      };
    case 'Dogs/dog3':
      return {
        asset: DogsDog3,

        width: 540.89867,
        height: 462.48978,
        aspectRatio: 1.1695364814331681
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_0':
      return {
        asset: DonutsInABoxDonutsInABox0,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_1':
      return {
        asset: DonutsInABoxDonutsInABox1,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_10':
      return {
        asset: DonutsInABoxDonutsInABox10,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_11':
      return {
        asset: DonutsInABoxDonutsInABox11,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_12':
      return {
        asset: DonutsInABoxDonutsInABox12,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_2':
      return {
        asset: DonutsInABoxDonutsInABox2,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_3':
      return {
        asset: DonutsInABoxDonutsInABox3,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_4':
      return {
        asset: DonutsInABoxDonutsInABox4,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_5':
      return {
        asset: DonutsInABoxDonutsInABox5,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_6':
      return {
        asset: DonutsInABoxDonutsInABox6,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_7':
      return {
        asset: DonutsInABoxDonutsInABox7,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_8':
      return {
        asset: DonutsInABoxDonutsInABox8,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_in_a_box/Donuts_in_a_box_9':
      return {
        asset: DonutsInABoxDonutsInABox9,

        width: 240.39061,
        height: 242.12546,
        aspectRatio: 0.9928349129414148
      };
    case 'Donuts_individual/Donut_1':
      return {
        asset: DonutsIndividualDonut1,

        width: 232.5811,
        height: 159.76759,
        aspectRatio: 1.4557464376848894
      };
    case 'Donuts_individual/Donut_2':
      return {
        asset: DonutsIndividualDonut2,

        width: 233.00967,
        height: 160.8351,
        aspectRatio: 1.448748873846567
      };
    case 'Donuts_individual/Donut_3':
      return {
        asset: DonutsIndividualDonut3,

        width: 228.54422,
        height: 157.81711,
        aspectRatio: 1.4481586945800742
      };
    case 'Donuts_individual/Donut_4':
      return {
        asset: DonutsIndividualDonut4,

        width: 228.54422,
        height: 157.81711,
        aspectRatio: 1.4481586945800742
      };
    case 'Donuts_individual/Donut_5':
      return {
        asset: DonutsIndividualDonut5,

        width: 233.74306,
        height: 161.33082,
        aspectRatio: 1.4488431906563175
      };
    case 'Donuts_individual/Donut_6':
      return {
        asset: DonutsIndividualDonut6,

        width: 228.54422,
        height: 164.4033,
        aspectRatio: 1.3901437501558667
      };
    case 'Door':
      return {
        asset: Door,

        width: 239.88133,
        height: 477.03333,
        aspectRatio: 0.5028607330225752
      };
    case 'Dumbbell_weights':
      return {
        asset: DumbbellWeights,

        width: 391.08972,
        height: 153.72656,
        aspectRatio: 2.5440608311276853
      };
    case 'Elephant':
      return {
        asset: Elephant,

        width: 533.92175,
        height: 442.59176,
        aspectRatio: 1.2063526668458535
      };
    case 'Ellipses/Ellipse_blue':
      return {
        asset: EllipsesEllipseBlue,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_green':
      return {
        asset: EllipsesEllipseGreen,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_orange':
      return {
        asset: EllipsesEllipseOrange,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_pink':
      return {
        asset: EllipsesEllipsePink,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_purple':
      return {
        asset: EllipsesEllipsePurple,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_red':
      return {
        asset: EllipsesEllipseRed,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_turquoise':
      return {
        asset: EllipsesEllipseTurquoise,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Ellipses/Ellipse_yellow':
      return {
        asset: EllipsesEllipseYellow,

        width: 569.82971,
        height: 327.99918,
        aspectRatio: 1.737290044444623
      };
    case 'Empty_bucket':
      return {
        asset: EmptyBucket,

        width: 574.27464,
        height: 652.89331,
        aspectRatio: 0.8795842003649875
      };
    case 'Empty_bucket_blue':
      return {
        asset: EmptyBucketBlue,

        width: 699.9268,
        height: 780,
        aspectRatio: 0.8973420512820512
      };
    case 'Empty_bucket_rectangular':
      return {
        asset: EmptyBucketRectangular,

        width: 701.66105,
        height: 780,
        aspectRatio: 0.8995654487179487
      };
    case 'Empty_bucket_rectangular_blue':
      return {
        asset: EmptyBucketRectangularBlue,

        width: 701.66105,
        height: 780,
        aspectRatio: 0.8995654487179487
      };
    case 'Empty_bucket_transparent':
      return {
        asset: EmptyBucketTransparent,

        width: 699.9268,
        height: 780,
        aspectRatio: 0.8973420512820512
      };
    case 'Empty_jug':
      return {
        asset: EmptyJug,

        width: 271.66815,
        height: 360.948,
        aspectRatio: 0.7526517670135312
      };
    case 'Equal_groups/Apples0':
      return {
        asset: EqualGroupsApples0,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Apples1':
      return {
        asset: EqualGroupsApples1,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Apples2':
      return {
        asset: EqualGroupsApples2,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Apples3':
      return {
        asset: EqualGroupsApples3,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Apples4':
      return {
        asset: EqualGroupsApples4,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Apples5':
      return {
        asset: EqualGroupsApples5,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Apples6':
      return {
        asset: EqualGroupsApples6,

        width: 286.25168,
        height: 384.87314,
        aspectRatio: 0.7437559295512284
      };
    case 'Equal_groups/Cookies0':
      return {
        asset: EqualGroupsCookies0,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Cookies1':
      return {
        asset: EqualGroupsCookies1,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Cookies2':
      return {
        asset: EqualGroupsCookies2,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Cookies3':
      return {
        asset: EqualGroupsCookies3,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Cookies4':
      return {
        asset: EqualGroupsCookies4,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Cookies5':
      return {
        asset: EqualGroupsCookies5,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Cookies6':
      return {
        asset: EqualGroupsCookies6,

        width: 237.08266,
        height: 185.34132,
        aspectRatio: 1.279167861759051
      };
    case 'Equal_groups/Eggs0':
      return {
        asset: EqualGroupsEggs0,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Eggs1':
      return {
        asset: EqualGroupsEggs1,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Eggs2':
      return {
        asset: EqualGroupsEggs2,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Eggs3':
      return {
        asset: EqualGroupsEggs3,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Eggs4':
      return {
        asset: EqualGroupsEggs4,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Eggs5':
      return {
        asset: EqualGroupsEggs5,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Eggs6':
      return {
        asset: EqualGroupsEggs6,

        width: 320.11795,
        height: 252.24707,
        aspectRatio: 1.2690650876539418
      };
    case 'Equal_groups/Flowers0':
      return {
        asset: EqualGroupsFlowers0,

        width: 135.49791,
        height: 187.30826,
        aspectRatio: 0.723395273652107
      };
    case 'Equal_groups/Flowers1':
      return {
        asset: EqualGroupsFlowers1,

        width: 139.6288,
        height: 324.06919,
        aspectRatio: 0.4308610763028723
      };
    case 'Equal_groups/Flowers2':
      return {
        asset: EqualGroupsFlowers2,

        width: 216.40427,
        height: 301.51987,
        aspectRatio: 0.717711472879051
      };
    case 'Equal_groups/Flowers3':
      return {
        asset: EqualGroupsFlowers3,

        width: 216.51862,
        height: 328.66385,
        aspectRatio: 0.6587844084465023
      };
    case 'Equal_groups/Flowers4':
      return {
        asset: EqualGroupsFlowers4,

        width: 228.71968,
        height: 355.66635,
        aspectRatio: 0.6430737122024617
      };
    case 'Equal_groups/Flowers5':
      return {
        asset: EqualGroupsFlowers5,

        width: 250.52574,
        height: 367.43247,
        aspectRatio: 0.681827983248187
      };
    case 'Equal_groups/Flowers6':
      return {
        asset: EqualGroupsFlowers6,

        width: 250.52705,
        height: 367.431,
        aspectRatio: 0.6818342763675357
      };
    case 'Equal_groups/Pencils0':
      return {
        asset: EqualGroupsPencils0,

        width: 293.61133,
        height: 277.45358,
        aspectRatio: 1.0582358677801167
      };
    case 'Equal_groups/Pencils1':
      return {
        asset: EqualGroupsPencils1,

        width: 293.61133,
        height: 401.3168,
        aspectRatio: 0.7316198325113726
      };
    case 'Equal_groups/Pencils2':
      return {
        asset: EqualGroupsPencils2,

        width: 293.61133,
        height: 401.3168,
        aspectRatio: 0.7316198325113726
      };
    case 'Equal_groups/Pencils3':
      return {
        asset: EqualGroupsPencils3,

        width: 293.61065,
        height: 401.31833,
        aspectRatio: 0.7316153488428999
      };
    case 'Equal_groups/Pencils4':
      return {
        asset: EqualGroupsPencils4,

        width: 293.61133,
        height: 405.47302,
        aspectRatio: 0.7241205099170347
      };
    case 'Equal_groups/Pencils5':
      return {
        asset: EqualGroupsPencils5,

        width: 293.61133,
        height: 405.47302,
        aspectRatio: 0.7241205099170347
      };
    case 'Equal_groups/Pencils6':
      return {
        asset: EqualGroupsPencils6,

        width: 293.61133,
        height: 405.47302,
        aspectRatio: 0.7241205099170347
      };
    case 'Equal_groups/Pizza1':
      return {
        asset: EqualGroupsPizza1,

        width: 221.02733,
        height: 264.20666,
        aspectRatio: 0.8365698654227718
      };
    case 'Equal_groups/Pizza2':
      return {
        asset: EqualGroupsPizza2,

        width: 221.02733,
        height: 354.44772,
        aspectRatio: 0.6235823156091962
      };
    case 'Equal_groups/Pizza3':
      return {
        asset: EqualGroupsPizza3,

        width: 239.27133,
        height: 435.90786,
        aspectRatio: 0.5489034540464584
      };
    case 'Equal_groups/Pizza4':
      return {
        asset: EqualGroupsPizza4,

        width: 435.90799,
        height: 435.90786,
        aspectRatio: 1.0000002982281622
      };
    case 'Equal_groups/Pizza5':
      return {
        asset: EqualGroupsPizza5,

        width: 435.90799,
        height: 435.90786,
        aspectRatio: 1.0000002982281622
      };
    case 'Equal_groups/Pizza6':
      return {
        asset: EqualGroupsPizza6,

        width: 435.90799,
        height: 435.90786,
        aspectRatio: 1.0000002982281622
      };
    case 'Equal_shapes_10_parts/Circle_equal_10-10':
      return {
        asset: EqualShapes10PartsCircleEqual1010,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Equal_shapes_10_parts/Pentagon_10-10':
      return {
        asset: EqualShapes10PartsPentagon1010,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Equal_shapes_10_parts/Square_equal_10-10':
      return {
        asset: EqualShapes10PartsSquareEqual1010,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_11_parts/Circle_equal_11-11':
      return {
        asset: EqualShapes11PartsCircleEqual1111,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Equal_shapes_11_parts/Rectangle_equal_11-11':
      return {
        asset: EqualShapes11PartsRectangleEqual1111,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Equal_shapes_11_parts/Square_equal_11-11':
      return {
        asset: EqualShapes11PartsSquareEqual1111,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_12_parts/Hexagon_12-12':
      return {
        asset: EqualShapes12PartsHexagon1212,

        width: 345.34946,
        height: 299.08133,
        aspectRatio: 1.1547008300384383
      };
    case 'Equal_shapes_12_parts/Rectangle_equal_12-12':
      return {
        asset: EqualShapes12PartsRectangleEqual1212,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Equal_shapes_12_parts/Square_equal_12-12':
      return {
        asset: EqualShapes12PartsSquareEqual1212,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-0_1':
      return {
        asset: EqualShapes2PartsCircleEqual201,

        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsCircleEqual211Blue,
        defaultWidth: 308,
        defaultHeight: 308,
        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsCircleEqual211Green,
        defaultWidth: 308,
        defaultHeight: 308,
        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsCircleEqual211Yellow,
        defaultWidth: 308,
        defaultHeight: 308,
        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsCircleEqual221Blue,
        defaultWidth: 308,
        defaultHeight: 308,
        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsCircleEqual221Green,
        defaultWidth: 308,
        defaultHeight: 308,
        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Circle_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsCircleEqual221Yellow,
        defaultWidth: 308,
        defaultHeight: 308,
        width: 308,
        height: 308,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Hexagon_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsHexagonEqual211Blue,
        defaultWidth: 378,
        defaultHeight: 328,
        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Equal_shapes_2_parts/Hexagon_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsHexagonEqual211Green,
        defaultWidth: 378,
        defaultHeight: 328,
        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Equal_shapes_2_parts/Hexagon_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsHexagonEqual211Yellow,
        defaultWidth: 378,
        defaultHeight: 328,
        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Equal_shapes_2_parts/Hexagon_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsHexagonEqual221Blue,
        defaultWidth: 378,
        defaultHeight: 328,
        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Equal_shapes_2_parts/Hexagon_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsHexagonEqual221Green,
        defaultWidth: 378,
        defaultHeight: 328,
        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Equal_shapes_2_parts/Hexagon_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsHexagonEqual221Yellow,
        defaultWidth: 378,
        defaultHeight: 328,
        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Equal_shapes_2_parts/Kite_equal_1-2':
      return {
        asset: EqualShapes2PartsKiteEqual12,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsKiteEqual211Blue,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsKiteEqual211Green,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsKiteEqual211Yellow,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-2':
      return {
        asset: EqualShapes2PartsKiteEqual22,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsKiteEqual221Blue,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsKiteEqual221Green,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Kite_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsKiteEqual221Yellow,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Equal_shapes_2_parts/Octagon_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsOctagonEqual211Blue,
        defaultWidth: 334,
        defaultHeight: 334,
        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Octagon_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsOctagonEqual211Green,
        defaultWidth: 334,
        defaultHeight: 334,
        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Octagon_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsOctagonEqual211Yellow,
        defaultWidth: 334,
        defaultHeight: 334,
        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Octagon_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsOctagonEqual221Blue,
        defaultWidth: 334,
        defaultHeight: 334,
        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Octagon_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsOctagonEqual221Green,
        defaultWidth: 334,
        defaultHeight: 334,
        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Octagon_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsOctagonEqual221Yellow,
        defaultWidth: 334,
        defaultHeight: 334,
        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-0_2':
      return {
        asset: EqualShapes2PartsRectangleEqual202,

        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsRectangleEqual211Blue,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsRectangleEqual211Green,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsRectangleEqual211Yellow,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_2_blue':
      return {
        asset: EqualShapes2PartsRectangleEqual212Blue,
        defaultWidth: 548,
        defaultHeight: 289,
        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_2_green':
      return {
        asset: EqualShapes2PartsRectangleEqual212Green,
        defaultWidth: 548,
        defaultHeight: 289,
        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_2_yellow':
      return {
        asset: EqualShapes2PartsRectangleEqual212Yellow,
        defaultWidth: 548,
        defaultHeight: 289,
        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_3_blue':
      return {
        asset: EqualShapes2PartsRectangleEqual213Blue,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_3_green':
      return {
        asset: EqualShapes2PartsRectangleEqual213Green,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-1_3_yellow':
      return {
        asset: EqualShapes2PartsRectangleEqual213Yellow,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsRectangleEqual221Blue,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsRectangleEqual221Green,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsRectangleEqual221Yellow,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_2_blue':
      return {
        asset: EqualShapes2PartsRectangleEqual222Blue,
        defaultWidth: 548,
        defaultHeight: 289,
        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_2_green':
      return {
        asset: EqualShapes2PartsRectangleEqual222Green,
        defaultWidth: 548,
        defaultHeight: 289,
        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_2_yellow':
      return {
        asset: EqualShapes2PartsRectangleEqual222Yellow,
        defaultWidth: 548,
        defaultHeight: 289,
        width: 548,
        height: 289,
        aspectRatio: 1.8961937716262975
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_3_blue':
      return {
        asset: EqualShapes2PartsRectangleEqual223Blue,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_3_green':
      return {
        asset: EqualShapes2PartsRectangleEqual223Green,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Rectangle_equal_2-2_3_yellow':
      return {
        asset: EqualShapes2PartsRectangleEqual223Yellow,
        defaultWidth: 548,
        defaultHeight: 287,
        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Equal_shapes_2_parts/Square_equal_1-2':
      return {
        asset: EqualShapes2PartsSquareEqual12,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-0_1':
      return {
        asset: EqualShapes2PartsSquareEqual201,

        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsSquareEqual211Blue,
        defaultWidth: 283,
        defaultHeight: 285,
        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsSquareEqual211Green,
        defaultWidth: 283,
        defaultHeight: 285,
        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsSquareEqual211Yellow,
        defaultWidth: 283,
        defaultHeight: 285,
        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-1_2_blue':
      return {
        asset: EqualShapes2PartsSquareEqual212Blue,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-1_2_green':
      return {
        asset: EqualShapes2PartsSquareEqual212Green,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-1_2_yellow':
      return {
        asset: EqualShapes2PartsSquareEqual212Yellow,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2':
      return {
        asset: EqualShapes2PartsSquareEqual22,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsSquareEqual221Blue,
        defaultWidth: 283,
        defaultHeight: 285,
        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsSquareEqual221Green,
        defaultWidth: 283,
        defaultHeight: 285,
        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsSquareEqual221Yellow,
        defaultWidth: 283,
        defaultHeight: 285,
        width: 283,
        height: 285,
        aspectRatio: 0.9929824561403509
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2_2_blue':
      return {
        asset: EqualShapes2PartsSquareEqual222Blue,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2_2_green':
      return {
        asset: EqualShapes2PartsSquareEqual222Green,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Square_equal_2-2_2_yellow':
      return {
        asset: EqualShapes2PartsSquareEqual222Yellow,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_2_parts/Triangle_equal_1-2':
      return {
        asset: EqualShapes2PartsTriangleEqual12,

        width: 323.30908,
        height: 280.57993,
        aspectRatio: 1.1522886900713105
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-0_1':
      return {
        asset: EqualShapes2PartsTriangleEqual201,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-1_1_blue':
      return {
        asset: EqualShapes2PartsTriangleEqual211Blue,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-1_1_green':
      return {
        asset: EqualShapes2PartsTriangleEqual211Green,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-1_1_yellow':
      return {
        asset: EqualShapes2PartsTriangleEqual211Yellow,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-2':
      return {
        asset: EqualShapes2PartsTriangleEqual22,

        width: 323.30908,
        height: 280.57993,
        aspectRatio: 1.1522886900713105
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-2_1_blue':
      return {
        asset: EqualShapes2PartsTriangleEqual221Blue,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-2_1_green':
      return {
        asset: EqualShapes2PartsTriangleEqual221Green,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_2_parts/Triangle_equal_2-2_1_yellow':
      return {
        asset: EqualShapes2PartsTriangleEqual221Yellow,

        width: 379,
        height: 280,
        aspectRatio: 1.3535714285714286
      };
    case 'Equal_shapes_3_parts/Arrow_equal_3-1_1_blue':
      return {
        asset: EqualShapes3PartsArrowEqual311Blue,

        width: 820,
        height: 440,
        aspectRatio: 1.8636363636363635
      };
    case 'Equal_shapes_3_parts/Arrow_equal_3-1_1_green':
      return {
        asset: EqualShapes3PartsArrowEqual311Green,

        width: 820,
        height: 441,
        aspectRatio: 1.8594104308390023
      };
    case 'Equal_shapes_3_parts/Arrow_equal_3-1_1_yellow':
      return {
        asset: EqualShapes3PartsArrowEqual311Yellow,

        width: 820,
        height: 441,
        aspectRatio: 1.8594104308390023
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-0_1':
      return {
        asset: EqualShapes3PartsCircleEqual301,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-1_1_blue':
      return {
        asset: EqualShapes3PartsCircleEqual311Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-1_1_green':
      return {
        asset: EqualShapes3PartsCircleEqual311Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-1_1_yellow':
      return {
        asset: EqualShapes3PartsCircleEqual311Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-2_1_blue':
      return {
        asset: EqualShapes3PartsCircleEqual321Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-2_1_green':
      return {
        asset: EqualShapes3PartsCircleEqual321Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-2_1_yellow':
      return {
        asset: EqualShapes3PartsCircleEqual321Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-3':
      return {
        asset: EqualShapes3PartsCircleEqual33,

        width: 310.31466,
        height: 310.31467,
        aspectRatio: 0.9999999677746464
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-3_1_blue':
      return {
        asset: EqualShapes3PartsCircleEqual331Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-3_1_green':
      return {
        asset: EqualShapes3PartsCircleEqual331Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Circle_equal_3-3_1_yellow':
      return {
        asset: EqualShapes3PartsCircleEqual331Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-0_1':
      return {
        asset: EqualShapes3PartsRectangleEqual301,

        width: 243.44673,
        height: 120.89347,
        aspectRatio: 2.0137293602375714
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-1_1_blue':
      return {
        asset: EqualShapes3PartsRectangleEqual311Blue,

        width: 243.44673,
        height: 120.89347,
        aspectRatio: 2.0137293602375714
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-1_1_green':
      return {
        asset: EqualShapes3PartsRectangleEqual311Green,

        width: 243.44673,
        height: 120.89347,
        aspectRatio: 2.0137293602375714
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-1_1_yellow':
      return {
        asset: EqualShapes3PartsRectangleEqual311Yellow,

        width: 243.89346,
        height: 120.89347,
        aspectRatio: 2.0174245970439926
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-2_1_blue':
      return {
        asset: EqualShapes3PartsRectangleEqual321Blue,

        width: 243.44673,
        height: 120.89347,
        aspectRatio: 2.0137293602375714
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-2_1_green':
      return {
        asset: EqualShapes3PartsRectangleEqual321Green,

        width: 243.44673,
        height: 120.89347,
        aspectRatio: 2.0137293602375714
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-2_1_yellow':
      return {
        asset: EqualShapes3PartsRectangleEqual321Yellow,

        width: 243.89346,
        height: 120.89347,
        aspectRatio: 2.0174245970439926
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-3_1_blue':
      return {
        asset: EqualShapes3PartsRectangleEqual331Blue,

        width: 243,
        height: 120,
        aspectRatio: 2.025
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-3_1_green':
      return {
        asset: EqualShapes3PartsRectangleEqual331Green,

        width: 243,
        height: 120,
        aspectRatio: 2.025
      };
    case 'Equal_shapes_3_parts/Rectangle_equal_3-3_1_yellow':
      return {
        asset: EqualShapes3PartsRectangleEqual331Yellow,

        width: 243.89346,
        height: 120.89347,
        aspectRatio: 2.0174245970439926
      };
    case 'Equal_shapes_3_parts/Square_equal_1-3':
      return {
        asset: EqualShapes3PartsSquareEqual13,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-0_1':
      return {
        asset: EqualShapes3PartsSquareEqual301,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-1_1_blue':
      return {
        asset: EqualShapes3PartsSquareEqual311Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-1_1_green':
      return {
        asset: EqualShapes3PartsSquareEqual311Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-1_1_yellow':
      return {
        asset: EqualShapes3PartsSquareEqual311Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-2_1_blue':
      return {
        asset: EqualShapes3PartsSquareEqual321Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-2_1_green':
      return {
        asset: EqualShapes3PartsSquareEqual321Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-2_1_yellow':
      return {
        asset: EqualShapes3PartsSquareEqual321Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-3':
      return {
        asset: EqualShapes3PartsSquareEqual33,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-3_1_blue':
      return {
        asset: EqualShapes3PartsSquareEqual331Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-3_1_green':
      return {
        asset: EqualShapes3PartsSquareEqual331Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Square_equal_3-3_1_yellow':
      return {
        asset: EqualShapes3PartsSquareEqual331Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_3_parts/Triangle_equal_1-3':
      return {
        asset: EqualShapes3PartsTriangleEqual13,

        width: 323.30908,
        height: 280.461,
        aspectRatio: 1.152777320197817
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-0_1':
      return {
        asset: EqualShapes3PartsTriangleEqual301,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-1_1_blue':
      return {
        asset: EqualShapes3PartsTriangleEqual311Blue,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-1_1_green':
      return {
        asset: EqualShapes3PartsTriangleEqual311Green,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-1_1_yellow':
      return {
        asset: EqualShapes3PartsTriangleEqual311Yellow,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-2_1_blue':
      return {
        asset: EqualShapes3PartsTriangleEqual321Blue,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-2_1_green':
      return {
        asset: EqualShapes3PartsTriangleEqual321Green,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-2_1_yellow':
      return {
        asset: EqualShapes3PartsTriangleEqual321Yellow,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-3':
      return {
        asset: EqualShapes3PartsTriangleEqual33,

        width: 323.30908,
        height: 280.461,
        aspectRatio: 1.152777320197817
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-3_1_blue':
      return {
        asset: EqualShapes3PartsTriangleEqual331Blue,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-3_1_green':
      return {
        asset: EqualShapes3PartsTriangleEqual331Green,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_3_parts/Triangle_equal_3-3_1_yellow':
      return {
        asset: EqualShapes3PartsTriangleEqual331Yellow,

        width: 244,
        height: 211.846,
        aspectRatio: 1.1517800666521907
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-0_1':
      return {
        asset: EqualShapes4PartsCircleEqual401,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-1_1_blue':
      return {
        asset: EqualShapes4PartsCircleEqual411Blue,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-1_1_green':
      return {
        asset: EqualShapes4PartsCircleEqual411Green,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-1_1_yellow':
      return {
        asset: EqualShapes4PartsCircleEqual411Yellow,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-2_1_blue':
      return {
        asset: EqualShapes4PartsCircleEqual421Blue,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-2_1_green':
      return {
        asset: EqualShapes4PartsCircleEqual421Green,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-2_1_yellow':
      return {
        asset: EqualShapes4PartsCircleEqual421Yellow,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-3_1_blue':
      return {
        asset: EqualShapes4PartsCircleEqual431Blue,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-3_1_green':
      return {
        asset: EqualShapes4PartsCircleEqual431Green,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-3_1_yellow':
      return {
        asset: EqualShapes4PartsCircleEqual431Yellow,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-4':
      return {
        asset: EqualShapes4PartsCircleEqual44,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-4_1_blue':
      return {
        asset: EqualShapes4PartsCircleEqual441Blue,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-4_1_green':
      return {
        asset: EqualShapes4PartsCircleEqual441Green,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Circle_equal_4-4_1_yellow':
      return {
        asset: EqualShapes4PartsCircleEqual441Yellow,

        width: 312,
        height: 312,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Cross_equal_1-4':
      return {
        asset: EqualShapes4PartsCrossEqual14,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Cross_equal_3-4':
      return {
        asset: EqualShapes4PartsCrossEqual34,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Cross_equal_4-4':
      return {
        asset: EqualShapes4PartsCrossEqual44,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-1_1_blue':
      return {
        asset: EqualShapes4PartsHexagonEqual411Blue,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-1_1_green':
      return {
        asset: EqualShapes4PartsHexagonEqual411Green,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-1_1_yellow':
      return {
        asset: EqualShapes4PartsHexagonEqual411Yellow,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-2_1_blue':
      return {
        asset: EqualShapes4PartsHexagonEqual421Blue,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-2_1_green':
      return {
        asset: EqualShapes4PartsHexagonEqual421Green,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-2_1_yellow':
      return {
        asset: EqualShapes4PartsHexagonEqual421Yellow,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-3_1_blue':
      return {
        asset: EqualShapes4PartsHexagonEqual431Blue,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-3_1_green':
      return {
        asset: EqualShapes4PartsHexagonEqual431Green,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-3_1_yellow':
      return {
        asset: EqualShapes4PartsHexagonEqual431Yellow,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-4_1_blue':
      return {
        asset: EqualShapes4PartsHexagonEqual441Blue,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-4_1_green':
      return {
        asset: EqualShapes4PartsHexagonEqual441Green,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Hexagon_equal_4-4_1_yellow':
      return {
        asset: EqualShapes4PartsHexagonEqual441Yellow,

        width: 380,
        height: 330,
        aspectRatio: 1.1515151515151516
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-1_1_blue':
      return {
        asset: EqualShapes4PartsOctagonEqual411Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-1_1_green':
      return {
        asset: EqualShapes4PartsOctagonEqual411Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-1_1_yellow':
      return {
        asset: EqualShapes4PartsOctagonEqual411Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-2_1_blue':
      return {
        asset: EqualShapes4PartsOctagonEqual421Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-2_1_green':
      return {
        asset: EqualShapes4PartsOctagonEqual421Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-2_1_yellow':
      return {
        asset: EqualShapes4PartsOctagonEqual421Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-3_1_blue':
      return {
        asset: EqualShapes4PartsOctagonEqual431Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-3_1_green':
      return {
        asset: EqualShapes4PartsOctagonEqual431Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-3_1_yellow':
      return {
        asset: EqualShapes4PartsOctagonEqual431Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-4_1_blue':
      return {
        asset: EqualShapes4PartsOctagonEqual441Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-4_1_green':
      return {
        asset: EqualShapes4PartsOctagonEqual441Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Octagon_equal_4-4_1_yellow':
      return {
        asset: EqualShapes4PartsOctagonEqual441Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-0_2':
      return {
        asset: EqualShapes4PartsRectangleEqual402,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_1_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual411Blue,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_1_green':
      return {
        asset: EqualShapes4PartsRectangleEqual411Green,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_1_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual411Yellow,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_2_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual412Blue,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_2_green':
      return {
        asset: EqualShapes4PartsRectangleEqual412Green,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_2_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual412Yellow,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_3_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual413Blue,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_3_green':
      return {
        asset: EqualShapes4PartsRectangleEqual413Green,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-1_3_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual413Yellow,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-2_1_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual421Blue,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-2_1_green':
      return {
        asset: EqualShapes4PartsRectangleEqual421Green,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-2_1_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual421Yellow,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-2_2_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual422Blue,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-2_2_green':
      return {
        asset: EqualShapes4PartsRectangleEqual422Green,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-2_2_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual422Yellow,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-3_1_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual431Blue,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-3_1_green':
      return {
        asset: EqualShapes4PartsRectangleEqual431Green,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-3_1_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual431Yellow,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-3_2_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual432Blue,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-3_2_green':
      return {
        asset: EqualShapes4PartsRectangleEqual432Green,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-3_2_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual432Yellow,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-4_1_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual441Blue,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-4_1_green':
      return {
        asset: EqualShapes4PartsRectangleEqual441Green,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-4_1_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual441Yellow,

        width: 553,
        height: 291,
        aspectRatio: 1.9003436426116838
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-4_2_blue':
      return {
        asset: EqualShapes4PartsRectangleEqual442Blue,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-4_2_green':
      return {
        asset: EqualShapes4PartsRectangleEqual442Green,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Rectangle_equal_4-4_2_yellow':
      return {
        asset: EqualShapes4PartsRectangleEqual442Yellow,

        width: 552,
        height: 291,
        aspectRatio: 1.8969072164948453
      };
    case 'Equal_shapes_4_parts/Square_equal_1-4':
      return {
        asset: EqualShapes4PartsSquareEqual14,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-0_1':
      return {
        asset: EqualShapes4PartsSquareEqual401,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-1_1_blue':
      return {
        asset: EqualShapes4PartsSquareEqual411Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-1_1_green':
      return {
        asset: EqualShapes4PartsSquareEqual411Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-1_1_yellow':
      return {
        asset: EqualShapes4PartsSquareEqual411Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-2_1_blue':
      return {
        asset: EqualShapes4PartsSquareEqual421Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-2_1_green':
      return {
        asset: EqualShapes4PartsSquareEqual421Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-2_1_yellow':
      return {
        asset: EqualShapes4PartsSquareEqual421Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-3_1_blue':
      return {
        asset: EqualShapes4PartsSquareEqual431Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-3_1_green':
      return {
        asset: EqualShapes4PartsSquareEqual431Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-3_1_yellow':
      return {
        asset: EqualShapes4PartsSquareEqual431Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-4':
      return {
        asset: EqualShapes4PartsSquareEqual44,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-4_1_blue':
      return {
        asset: EqualShapes4PartsSquareEqual441Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-4_1_green':
      return {
        asset: EqualShapes4PartsSquareEqual441Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_4_parts/Square_equal_4-4_1_yellow':
      return {
        asset: EqualShapes4PartsSquareEqual441Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-1_1_blue':
      return {
        asset: EqualShapes5PartsCircleEqual511Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-1_1_green':
      return {
        asset: EqualShapes5PartsCircleEqual511Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-1_1_yellow':
      return {
        asset: EqualShapes5PartsCircleEqual511Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-2_1_blue':
      return {
        asset: EqualShapes5PartsCircleEqual521Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-2_1_green':
      return {
        asset: EqualShapes5PartsCircleEqual521Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-2_1_yellow':
      return {
        asset: EqualShapes5PartsCircleEqual521Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-3_1_blue':
      return {
        asset: EqualShapes5PartsCircleEqual531Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-3_1_green':
      return {
        asset: EqualShapes5PartsCircleEqual531Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-3_1_yellow':
      return {
        asset: EqualShapes5PartsCircleEqual531Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-4_1_blue':
      return {
        asset: EqualShapes5PartsCircleEqual541Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-4_1_green':
      return {
        asset: EqualShapes5PartsCircleEqual541Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-4_1_yellow':
      return {
        asset: EqualShapes5PartsCircleEqual541Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-5':
      return {
        asset: EqualShapes5PartsCircleEqual55,

        width: 310.31466,
        height: 310.31467,
        aspectRatio: 0.9999999677746464
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-5_1_blue':
      return {
        asset: EqualShapes5PartsCircleEqual551Blue,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-5_1_green':
      return {
        asset: EqualShapes5PartsCircleEqual551Green,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Circle_equal_5-5_1_yellow':
      return {
        asset: EqualShapes5PartsCircleEqual551Yellow,

        width: 304,
        height: 304,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Pentagon_1-5':
      return {
        asset: EqualShapes5PartsPentagon15,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Equal_shapes_5_parts/Pentagon_5-5':
      return {
        asset: EqualShapes5PartsPentagon55,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-1_1_blue':
      return {
        asset: EqualShapes5PartsPentagonEqual511Blue,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-1_1_green':
      return {
        asset: EqualShapes5PartsPentagonEqual511Green,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-1_1_yellow':
      return {
        asset: EqualShapes5PartsPentagonEqual511Yellow,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-2_1_blue':
      return {
        asset: EqualShapes5PartsPentagonEqual521Blue,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-2_1_green':
      return {
        asset: EqualShapes5PartsPentagonEqual521Green,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-2_1_yellow':
      return {
        asset: EqualShapes5PartsPentagonEqual521Yellow,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-3_1_blue':
      return {
        asset: EqualShapes5PartsPentagonEqual531Blue,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-3_1_green':
      return {
        asset: EqualShapes5PartsPentagonEqual531Green,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-3_1_yellow':
      return {
        asset: EqualShapes5PartsPentagonEqual531Yellow,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-4_1_blue':
      return {
        asset: EqualShapes5PartsPentagonEqual541Blue,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-4_1_green':
      return {
        asset: EqualShapes5PartsPentagonEqual541Green,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-4_1_yellow':
      return {
        asset: EqualShapes5PartsPentagonEqual541Yellow,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-5_1_blue':
      return {
        asset: EqualShapes5PartsPentagonEqual551Blue,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-5_1_green':
      return {
        asset: EqualShapes5PartsPentagonEqual551Green,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Pentagon_equal_5-5_1_yellow':
      return {
        asset: EqualShapes5PartsPentagonEqual551Yellow,

        width: 232,
        height: 221,
        aspectRatio: 1.0497737556561086
      };
    case 'Equal_shapes_5_parts/Square_equal_1-5':
      return {
        asset: EqualShapes5PartsSquareEqual15,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-1_1_blue':
      return {
        asset: EqualShapes5PartsSquareEqual511Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-1_1_green':
      return {
        asset: EqualShapes5PartsSquareEqual511Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-1_1_yellow':
      return {
        asset: EqualShapes5PartsSquareEqual511Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-2_1_blue':
      return {
        asset: EqualShapes5PartsSquareEqual521Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-2_1_green':
      return {
        asset: EqualShapes5PartsSquareEqual521Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-2_1_yellow':
      return {
        asset: EqualShapes5PartsSquareEqual521Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-3_1_blue':
      return {
        asset: EqualShapes5PartsSquareEqual531Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-3_1_green':
      return {
        asset: EqualShapes5PartsSquareEqual531Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-3_1_yellow':
      return {
        asset: EqualShapes5PartsSquareEqual531Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-4_1_blue':
      return {
        asset: EqualShapes5PartsSquareEqual541Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-4_1_green':
      return {
        asset: EqualShapes5PartsSquareEqual541Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-4_1_yellow':
      return {
        asset: EqualShapes5PartsSquareEqual541Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-5':
      return {
        asset: EqualShapes5PartsSquareEqual55,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-5_1_blue':
      return {
        asset: EqualShapes5PartsSquareEqual551Blue,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-5_1_green':
      return {
        asset: EqualShapes5PartsSquareEqual551Green,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_5_parts/Square_equal_5-5_1_yellow':
      return {
        asset: EqualShapes5PartsSquareEqual551Yellow,

        width: 244,
        height: 244,
        aspectRatio: 1
      };
    case 'Equal_shapes_6_parts/Circle_equal_6-6':
      return {
        asset: EqualShapes6PartsCircleEqual66,

        width: 310.31466,
        height: 310.31467,
        aspectRatio: 0.9999999677746464
      };
    case 'Equal_shapes_6_parts/Hexagon_6-6':
      return {
        asset: EqualShapes6PartsHexagon66,

        width: 345.34946,
        height: 299.08133,
        aspectRatio: 1.1547008300384383
      };
    case 'Equal_shapes_6_parts/Square_equal_6-6':
      return {
        asset: EqualShapes6PartsSquareEqual66,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_7_parts/Circle_equal_7-7':
      return {
        asset: EqualShapes7PartsCircleEqual77,

        width: 305.26932,
        height: 305.26932,
        aspectRatio: 1
      };
    case 'Equal_shapes_7_parts/Rectangle_equal_7-7':
      return {
        asset: EqualShapes7PartsRectangleEqual77,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Equal_shapes_7_parts/Square_equal_7-7':
      return {
        asset: EqualShapes7PartsSquareEqual77,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_8_parts/Circle_equal_8-8':
      return {
        asset: EqualShapes8PartsCircleEqual88,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Equal_shapes_8_parts/Cross_equal_8-8':
      return {
        asset: EqualShapes8PartsCrossEqual88,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Equal_shapes_8_parts/Square_equal_8-8':
      return {
        asset: EqualShapes8PartsSquareEqual88,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equal_shapes_9_parts/Circle_equal_9-9':
      return {
        asset: EqualShapes9PartsCircleEqual99,

        width: 305.26932,
        height: 305.26932,
        aspectRatio: 1
      };
    case 'Equal_shapes_9_parts/Rectangle_equal_9-9':
      return {
        asset: EqualShapes9PartsRectangleEqual99,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Equal_shapes_9_parts/Square_equal_9-9':
      return {
        asset: EqualShapes9PartsSquareEqual99,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Equilateral_triangles/triangle_equal_':
      return {
        asset: EquilateralTrianglesTriangleEqual,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_blue':
      return {
        asset: EquilateralTrianglesTriangleEqualBlue,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_green':
      return {
        asset: EquilateralTrianglesTriangleEqualGreen,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_orange':
      return {
        asset: EquilateralTrianglesTriangleEqualOrange,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_pink':
      return {
        asset: EquilateralTrianglesTriangleEqualPink,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_purple':
      return {
        asset: EquilateralTrianglesTriangleEqualPurple,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_red':
      return {
        asset: EquilateralTrianglesTriangleEqualRed,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_turquoise':
      return {
        asset: EquilateralTrianglesTriangleEqualTurquoise,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_white':
      return {
        asset: EquilateralTrianglesTriangleEqualWhite,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Equilateral_triangles/triangle_equal_yellow':
      return {
        asset: EquilateralTrianglesTriangleEqualYellow,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Factor_Bug':
      return {
        asset: FactorBug,

        width: 256.8136,
        height: 409.69424,
        aspectRatio: 0.6268421054687028
      };
    case 'Flags/Armenia_horizontal_flag':
      return {
        asset: FlagsArmeniaHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Australia_both_flag':
      return {
        asset: FlagsAustraliaBothFlag,

        width: 462.04396,
        height: 347.03467,
        aspectRatio: 1.3314057641560713
      };
    case 'Flags/Austria_horizontal_flag':
      return {
        asset: FlagsAustriaHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Belgium_vertical_flag':
      return {
        asset: FlagsBelgiumVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Botswana_horizontal_flag':
      return {
        asset: FlagsBotswanaHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Bulgaria_horizontal_flag':
      return {
        asset: FlagsBulgariaHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Cameroon_vertical_flag':
      return {
        asset: FlagsCameroonVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Canada_vertical_flag':
      return {
        asset: FlagsCanadaVerticalFlag,

        width: 461.54265,
        height: 347.03491,
        aspectRatio: 1.3299602913147843
      };
    case 'Flags/Chad_vertical_flag':
      return {
        asset: FlagsChadVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Columbia_horizontal_flag':
      return {
        asset: FlagsColumbiaHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Denmark_both_flag':
      return {
        asset: FlagsDenmarkBothFlag,

        width: 462.11757,
        height: 347.03479,
        aspectRatio: 1.3316174150724198
      };
    case 'Flags/Estonia_horizontal_flag':
      return {
        asset: FlagsEstoniaHorizontalFlag,

        width: 462.04531,
        height: 347.03333,
        aspectRatio: 1.3314147952301874
      };
    case 'Flags/Finland_both_flag':
      return {
        asset: FlagsFinlandBothFlag,

        width: 462.04543,
        height: 347.03534,
        aspectRatio: 1.33140742957187
      };
    case 'Flags/France_vertical_flag':
      return {
        asset: FlagsFranceVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Germany_horizontal_flag':
      return {
        asset: FlagsGermanyHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Greece_both_flag':
      return {
        asset: FlagsGreeceBothFlag,

        width: 462.04558,
        height: 347.03412,
        aspectRatio: 1.331412542374796
      };
    case 'Flags/Iceland_both_flag':
      return {
        asset: FlagsIcelandBothFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Indonesia_horizontal_flag':
      return {
        asset: FlagsIndonesiaHorizontalFlag,

        width: 462.04531,
        height: 347.03333,
        aspectRatio: 1.3314147952301874
      };
    case 'Flags/Ireland_vertical_flag':
      return {
        asset: FlagsIrelandVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Italy_vertical_flag':
      return {
        asset: FlagsItalyVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Latvia_horizontal_flag':
      return {
        asset: FlagsLatviaHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Lithuania_horizontal_flag':
      return {
        asset: FlagsLithuaniaHorizontalFlag,

        width: 462.05864,
        height: 347.04666,
        aspectRatio: 1.3314020656473111
      };
    case 'Flags/Madagascar_both_flag':
      return {
        asset: FlagsMadagascarBothFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Mali_vertical_flag':
      return {
        asset: FlagsMaliVerticalFlag,

        width: 462.04598,
        height: 347.03506,
        aspectRatio: 1.3314100886521378
      };
    case 'Flags/Mauritius_horizontal_flag':
      return {
        asset: FlagsMauritiusHorizontalFlag,

        width: 462.04531,
        height: 347.03333,
        aspectRatio: 1.3314147952301874
      };
    case 'Flags/Netherlands_horizontal_flag':
      return {
        asset: FlagsNetherlandsHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Nigeria_vertical_flag':
      return {
        asset: FlagsNigeriaVerticalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Norway_both_flag':
      return {
        asset: FlagsNorwayBothFlag,

        width: 462.04585,
        height: 347.03534,
        aspectRatio: 1.3314086398232525
      };
    case 'Flags/Poland_horizontal_flag':
      return {
        asset: FlagsPolandHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Romania_vertical_flag':
      return {
        asset: FlagsRomaniaVerticalFlag,

        width: 462.04396,
        height: 347.03467,
        aspectRatio: 1.3314057641560713
      };
    case 'Flags/Senagal_vertical_flag':
      return {
        asset: FlagsSenagalVerticalFlag,

        width: 462.04396,
        height: 347.03467,
        aspectRatio: 1.3314057641560713
      };
    case 'Flags/Sierra_Leone_horizontal_flag':
      return {
        asset: FlagsSierraLeoneHorizontalFlag,

        width: 462.04531,
        height: 347.03333,
        aspectRatio: 1.3314147952301874
      };
    case 'Flags/Sweden_both_flag':
      return {
        asset: FlagsSwedenBothFlag,

        width: 462.04531,
        height: 347.03506,
        aspectRatio: 1.33140815801147
      };
    case 'Flags/Thailand_horizontal_flag':
      return {
        asset: FlagsThailandHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/Togo_horizontal_flag':
      return {
        asset: FlagsTogoHorizontalFlag,

        width: 462.06798,
        height: 347.03467,
        aspectRatio: 1.3314749791425737
      };
    case 'Flags/UAE_both_flag':
      return {
        asset: FlagsUAEBothFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flags/UK_both_flag':
      return {
        asset: FlagsUKBothFlag,

        width: 464.05925,
        height: 348.98792,
        aspectRatio: 1.329728690895662
      };
    case 'Flags/USA_horizontal_flag':
      return {
        asset: FlagsUSAHorizontalFlag,

        width: 462.04531,
        height: 347.03467,
        aspectRatio: 1.3314096542573108
      };
    case 'Flowers/Flower_Blue':
      return {
        asset: FlowersFlowerBlue,

        width: 162.84175,
        height: 314.47415,
        aspectRatio: 0.5178223710915507
      };
    case 'Flowers/Flower_Orange':
      return {
        asset: FlowersFlowerOrange,

        width: 166.16539,
        height: 323.14328,
        aspectRatio: 0.514215830203865
      };
    case 'Flowers/Flower_Purple':
      return {
        asset: FlowersFlowerPurple,

        width: 191.02978,
        height: 335.39615,
        aspectRatio: 0.5695646178407235
      };
    case 'Flowers/Flower_Red':
      return {
        asset: FlowersFlowerRed,

        width: 190.11296,
        height: 287.31879,
        aspectRatio: 0.6616795232918807
      };
    case 'Flowers/Flower_White':
      return {
        asset: FlowersFlowerWhite,

        width: 166.16539,
        height: 314.47415,
        aspectRatio: 0.5283912525083541
      };
    case 'Flowers/Flower_Yellow':
      return {
        asset: FlowersFlowerYellow,

        width: 196.93075,
        height: 322.05252,
        aspectRatio: 0.6114864432670795
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_1_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake1Eighths,

        width: 183.09054,
        height: 257.76361,
        aspectRatio: 0.7103040650307465
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_2_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake2Eighths,

        width: 255.99986,
        height: 257.76413,
        aspectRatio: 0.9931554867622582
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_3_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake3Eighths,

        width: 255,
        height: 435,
        aspectRatio: 0.5862068965517241
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_4_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake4Eighths,

        width: 255,
        height: 505,
        aspectRatio: 0.504950495049505
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_5_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake5Eighths,

        width: 435,
        height: 505,
        aspectRatio: 0.8613861386138614
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_6_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake6Eighths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_7_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake7Eighths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Eighths/Cake_8_Eighths':
      return {
        asset: FoodFractionsCakeCakeEighthsCake8Eighths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Fifths/Cake_1_Fifths':
      return {
        asset: FoodFractionsCakeCakeFifthsCake1Fifths,

        width: 245.64792,
        height: 258.01456,
        aspectRatio: 0.952069991708995
      };
    case 'Food_fractions/Cake/Cake_Fifths/Cake_2_Fifths':
      return {
        asset: FoodFractionsCakeCakeFifthsCake2Fifths,

        width: 258.54562,
        height: 462.43853,
        aspectRatio: 0.5590918645987392
      };
    case 'Food_fractions/Cake/Cake_Fifths/Cake_3_Fifths':
      return {
        asset: FoodFractionsCakeCakeFifthsCake3Fifths,

        width: 405,
        height: 505,
        aspectRatio: 0.801980198019802
      };
    case 'Food_fractions/Cake/Cake_Fifths/Cake_4_Fifths':
      return {
        asset: FoodFractionsCakeCakeFifthsCake4Fifths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Fifths/Cake_5_Fifths':
      return {
        asset: FoodFractionsCakeCakeFifthsCake5Fifths,

        width: 511.75793,
        height: 511.62564,
        aspectRatio: 1.000258567963873
      };
    case 'Food_fractions/Cake/Cake_Half/Cake_1_half':
      return {
        asset: FoodFractionsCakeCakeHalfCake1Half,

        width: 255,
        height: 505,
        aspectRatio: 0.504950495049505
      };
    case 'Food_fractions/Cake/Cake_Half/Cake_2_halves':
      return {
        asset: FoodFractionsCakeCakeHalfCake2Halves,

        width: 509.19464,
        height: 509.19464,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Quarters/Cake_1_quarters':
      return {
        asset: FoodFractionsCakeCakeQuartersCake1Quarters,

        width: 256,
        height: 257.76398,
        aspectRatio: 0.9931566078394661
      };
    case 'Food_fractions/Cake/Cake_Quarters/Cake_2_quarters':
      return {
        asset: FoodFractionsCakeCakeQuartersCake2Quarters,

        width: 255,
        height: 505,
        aspectRatio: 0.504950495049505
      };
    case 'Food_fractions/Cake/Cake_Quarters/Cake_3_quarters':
      return {
        asset: FoodFractionsCakeCakeQuartersCake3Quarters,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Quarters/Cake_4_quarters':
      return {
        asset: FoodFractionsCakeCakeQuartersCake4Quarters,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_1_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake1Sevenths,

        width: 204.39322,
        height: 258.00503,
        aspectRatio: 0.7922063379927129
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_2_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake2Sevenths,

        width: 258.57562,
        height: 314.84393,
        aspectRatio: 0.8212818967162556
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_3_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake3Sevenths,

        width: 255,
        height: 485,
        aspectRatio: 0.5257731958762887
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_4_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake4Sevenths,

        width: 365,
        height: 505,
        aspectRatio: 0.7227722772277227
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_5_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake5Sevenths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_6_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake6Sevenths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Sevenths/Cake_7_Sevenths':
      return {
        asset: FoodFractionsCakeCakeSeventhsCake7Sevenths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Sixths/Cake_1_Sixths':
      return {
        asset: FoodFractionsCakeCakeSixthsCake1Sixths,

        width: 223.36789,
        height: 256.76114,
        aspectRatio: 0.869944299203532
      };
    case 'Food_fractions/Cake/Cake_Sixths/Cake_2_Sixths':
      return {
        asset: FoodFractionsCakeCakeSixthsCake2Sixths,

        width: 257.32852,
        height: 382.90014,
        aspectRatio: 0.6720512559749913
      };
    case 'Food_fractions/Cake/Cake_Sixths/Cake_3_Sixths':
      return {
        asset: FoodFractionsCakeCakeSixthsCake3Sixths,

        width: 255,
        height: 505,
        aspectRatio: 0.504950495049505
      };
    case 'Food_fractions/Cake/Cake_Sixths/Cake_4_Sixths':
      return {
        asset: FoodFractionsCakeCakeSixthsCake4Sixths,

        width: 475,
        height: 505,
        aspectRatio: 0.9405940594059405
      };
    case 'Food_fractions/Cake/Cake_Sixths/Cake_5_Sixths':
      return {
        asset: FoodFractionsCakeCakeSixthsCake5Sixths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Sixths/Cake_6_Sixths':
      return {
        asset: FoodFractionsCakeCakeSixthsCake6Sixths,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Thirds/Cake_1_thirds':
      return {
        asset: FoodFractionsCakeCakeThirdsCake1Thirds,

        width: 256.11066,
        height: 381.97259,
        aspectRatio: 0.6704948645660673
      };
    case 'Food_fractions/Cake/Cake_Thirds/Cake_2_thirds':
      return {
        asset: FoodFractionsCakeCakeThirdsCake2Thirds,

        width: 475,
        height: 505,
        aspectRatio: 0.9405940594059405
      };
    case 'Food_fractions/Cake/Cake_Thirds/Cake_3_thirds':
      return {
        asset: FoodFractionsCakeCakeThirdsCake3Thirds,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Cake/Cake_Whole/Cake_1_Whole':
      return {
        asset: FoodFractionsCakeCakeWholeCake1Whole,

        width: 505,
        height: 505,
        aspectRatio: 1
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_1_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie1Eighths,

        width: 183.09054,
        height: 257.76361,
        aspectRatio: 0.7103040650307465
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_2_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie2Eighths,

        width: 255.99986,
        height: 257.76413,
        aspectRatio: 0.9931554867622582
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_3_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie3Eighths,

        width: 255.99986,
        height: 435.01187,
        aspectRatio: 0.5884893669683083
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_4_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie4Eighths,

        width: 255.99986,
        height: 509.16266,
        aspectRatio: 0.5027860055566525
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_5_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie5Eighths,

        width: 434.50506,
        height: 509.17865,
        aspectRatio: 0.8533450096542736
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_6_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie6Eighths,

        width: 509.18731,
        height: 509.17865,
        aspectRatio: 1.0000170077830248
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_7_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie7Eighths,

        width: 509.19317,
        height: 509.17865,
        aspectRatio: 1.0000285165138012
      };
    case 'Food_fractions/Pie/Pie_Eighths/Pie_8_Eighths':
      return {
        asset: FoodFractionsPiePieEighthsPie8Eighths,

        width: 510.11053,
        height: 509.95398,
        aspectRatio: 1.00030698848551
      };
    case 'Food_fractions/Pie/Pie_Fifths/Pie_1_Fifths':
      return {
        asset: FoodFractionsPiePieFifthsPie1Fifths,

        width: 245.64792,
        height: 258.01456,
        aspectRatio: 0.952069991708995
      };
    case 'Food_fractions/Pie/Pie_Fifths/Pie_2_Fifths':
      return {
        asset: FoodFractionsPiePieFifthsPie2Fifths,

        width: 258.54562,
        height: 462.43853,
        aspectRatio: 0.5590918645987392
      };
    case 'Food_fractions/Pie/Pie_Fifths/Pie_3_Fifths':
      return {
        asset: FoodFractionsPiePieFifthsPie3Fifths,

        width: 408.12643,
        height: 511.62564,
        aspectRatio: 0.7977051931955561
      };
    case 'Food_fractions/Pie/Pie_Fifths/Pie_4_Fifths':
      return {
        asset: FoodFractionsPiePieFifthsPie4Fifths,

        width: 511.75793,
        height: 511.62564,
        aspectRatio: 1.000258567963873
      };
    case 'Food_fractions/Pie/Pie_Fifths/Pie_5_Fifths':
      return {
        asset: FoodFractionsPiePieFifthsPie5Fifths,

        width: 511.75793,
        height: 511.62564,
        aspectRatio: 1.000258567963873
      };
    case 'Food_fractions/Pie/Pie_Halves/Pie_1_half':
      return {
        asset: FoodFractionsPiePieHalvesPie1Half,

        width: 257.54666,
        height: 509.19333,
        aspectRatio: 0.5057934674831659
      };
    case 'Food_fractions/Pie/Pie_Halves/Pie_2_halves':
      return {
        asset: FoodFractionsPiePieHalvesPie2Halves,

        width: 509.194,
        height: 509.19464,
        aspectRatio: 0.9999987431132425
      };
    case 'Food_fractions/Pie/Pie_Quarters/Pie_1_Quarters':
      return {
        asset: FoodFractionsPiePieQuartersPie1Quarters,

        width: 256.74515,
        height: 255.9987,
        aspectRatio: 1.0029158351194754
      };
    case 'Food_fractions/Pie/Pie_Quarters/Pie_2_Quarters':
      return {
        asset: FoodFractionsPiePieQuartersPie2Quarters,

        width: 256.74515,
        height: 509.20132,
        aspectRatio: 0.5042114776921631
      };
    case 'Food_fractions/Pie/Pie_Quarters/Pie_3_Quarters':
      return {
        asset: FoodFractionsPiePieQuartersPie3Quarters,

        width: 509.19116,
        height: 509.20132,
        aspectRatio: 0.999980047184481
      };
    case 'Food_fractions/Pie/Pie_Quarters/Pie_4_Quarters':
      return {
        asset: FoodFractionsPiePieQuartersPie4Quarters,

        width: 511.44464,
        height: 512.61779,
        aspectRatio: 0.9977114528155567
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_1_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie1Sevenths,

        width: 205.10254,
        height: 258.71435,
        aspectRatio: 0.792776048178232
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_2_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie2Sevenths,

        width: 259.28247,
        height: 315.55325,
        aspectRatio: 0.821675802736939
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_3_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie3Sevenths,

        width: 259.28247,
        height: 487.71322,
        aspectRatio: 0.5316289560492127
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_4_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie4Sevenths,

        width: 370.01971,
        height: 512.46399,
        aspectRatio: 0.7220404110735663
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_5_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie5Sevenths,

        width: 506.37307,
        height: 512.46399,
        aspectRatio: 0.9881144429289559
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_6_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie6Sevenths,

        width: 512.45929,
        height: 512.46399,
        aspectRatio: 0.9999908286238806
      };
    case 'Food_fractions/Pie/Pie_Sevenths/Pie_7_Sevenths':
      return {
        asset: FoodFractionsPiePieSeventhsPie7Sevenths,

        width: 512.45929,
        height: 512.92053,
        aspectRatio: 0.9991007573824351
      };
    case 'Food_fractions/Pie/Pie_Sixths/Pie_1_sixths':
      return {
        asset: FoodFractionsPiePieSixthsPie1Sixths,

        width: 223.36789,
        height: 256.76114,
        aspectRatio: 0.869944299203532
      };
    case 'Food_fractions/Pie/Pie_Sixths/Pie_2_sixths':
      return {
        asset: FoodFractionsPiePieSixthsPie2Sixths,

        width: 257.32852,
        height: 382.90014,
        aspectRatio: 0.6720512559749913
      };
    case 'Food_fractions/Pie/Pie_Sixths/Pie_3_sixths':
      return {
        asset: FoodFractionsPiePieSixthsPie3Sixths,

        width: 257.32879,
        height: 509.19464,
        aspectRatio: 0.505364294486682
      };
    case 'Food_fractions/Pie/Pie_Sixths/Pie_4_sixths':
      return {
        asset: FoodFractionsPiePieSixthsPie4Sixths,

        width: 476.68377,
        height: 509.19464,
        aspectRatio: 0.9361523719102778
      };
    case 'Food_fractions/Pie/Pie_Sixths/Pie_5_sixths':
      return {
        asset: FoodFractionsPiePieSixthsPie5Sixths,

        width: 509.19317,
        height: 509.19464,
        aspectRatio: 0.9999971130882289
      };
    case 'Food_fractions/Pie/Pie_Sixths/Pie_6_sixths':
      return {
        asset: FoodFractionsPiePieSixthsPie6Sixths,

        width: 509.19317,
        height: 509.19464,
        aspectRatio: 0.9999971130882289
      };
    case 'Food_fractions/Pie/Pie_Thirds/Pie_1_Thirds':
      return {
        asset: FoodFractionsPiePieThirdsPie1Thirds,

        width: 256.11066,
        height: 381.97259,
        aspectRatio: 0.6704948645660673
      };
    case 'Food_fractions/Pie/Pie_Thirds/Pie_2_Thirds':
      return {
        asset: FoodFractionsPiePieThirdsPie2Thirds,

        width: 474.8186,
        height: 511.49557,
        aspectRatio: 0.9282946477913778
      };
    case 'Food_fractions/Pie/Pie_Thirds/Pie_3_Thirds':
      return {
        asset: FoodFractionsPiePieThirdsPie3Thirds,

        width: 510.2576,
        height: 514.18756,
        aspectRatio: 0.992356952393014
      };
    case 'Food_fractions/Pie/Pie_Whole/Pie_whole':
      return {
        asset: FoodFractionsPiePieWholePieWhole,

        width: 509.19333,
        height: 509.19464,
        aspectRatio: 0.9999974273099183
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_1_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza1Eighths,

        width: 156.59721,
        height: 219.25294,
        aspectRatio: 0.714230833119045
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_2_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza2Eighths,

        width: 219.25333,
        height: 219.25333,
        aspectRatio: 1
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_3_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza3Eighths,

        width: 219.25336,
        height: 370.51721,
        aspectRatio: 0.5917494628657061
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_4_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza4Eighths,

        width: 219.25336,
        height: 433.17318,
        aspectRatio: 0.5061563599112946
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_5_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza5Eighths,

        width: 370.51721,
        height: 433.17318,
        aspectRatio: 0.8553558417444034
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_6_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza6Eighths,

        width: 433.17331,
        height: 433.17318,
        aspectRatio: 1.0000003001109163
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_7_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza7Eighths,

        width: 433.17331,
        height: 433.17318,
        aspectRatio: 1.0000003001109163
      };
    case 'Food_fractions/Pizza/Pizza_Eighths/Pizza_8_Eighths':
      return {
        asset: FoodFractionsPizzaPizzaEighthsPizza8Eighths,

        width: 435.90799,
        height: 435.90664,
        aspectRatio: 1.0000030969934297
      };
    case 'Food_fractions/Pizza/Pizza_Fifths/Pizza_1_fifths':
      return {
        asset: FoodFractionsPizzaPizzaFifthsPizza1Fifths,

        width: 211.59193,
        height: 221.7879,
        aspectRatio: 0.9540282855827571
      };
    case 'Food_fractions/Pizza/Pizza_Fifths/Pizza_2_fifths':
      return {
        asset: FoodFractionsPizzaPizzaFifthsPizza2Fifths,

        width: 221.70799,
        height: 396.65774,
        aspectRatio: 0.5589402843872402
      };
    case 'Food_fractions/Pizza/Pizza_Fifths/Pizza_3_fifths':
      return {
        asset: FoodFractionsPizzaPizzaFifthsPizza3Fifths,

        width: 367.55279,
        height: 435.90759,
        aspectRatio: 0.8431896999086435
      };
    case 'Food_fractions/Pizza/Pizza_Fifths/Pizza_4_fifths':
      return {
        asset: FoodFractionsPizzaPizzaFifthsPizza4Fifths,

        width: 435.90771,
        height: 435.90732,
        aspectRatio: 1.000000894685595
      };
    case 'Food_fractions/Pizza/Pizza_Fifths/Pizza_5_fifths':
      return {
        asset: FoodFractionsPizzaPizzaFifthsPizza5Fifths,

        width: 435.90719,
        height: 435.90759,
        aspectRatio: 0.999999082374317
      };
    case 'Food_fractions/Pizza/Pizza_Halves/Pizza_1_halves':
      return {
        asset: FoodFractionsPizzaPizzaHalvesPizza1Halves,

        width: 220.168,
        height: 435.89599,
        aspectRatio: 0.5050929695407385
      };
    case 'Food_fractions/Pizza/Pizza_Halves/Pizza_2_halves':
      return {
        asset: FoodFractionsPizzaPizzaHalvesPizza2Halves,

        width: 436.82306,
        height: 435.89599,
        aspectRatio: 1.0021268147018283
      };
    case 'Food_fractions/Pizza/Pizza_Quarters/Pizza_1_Quarters':
      return {
        asset: FoodFractionsPizzaPizzaQuartersPizza1Quarters,

        width: 220.61999,
        height: 220.61999,
        aspectRatio: 1
      };
    case 'Food_fractions/Pizza/Pizza_Quarters/Pizza_2_Quarters':
      return {
        asset: FoodFractionsPizzaPizzaQuartersPizza2Quarters,

        width: 220.62039,
        height: 435.90704,
        aspectRatio: 0.5061179787323462
      };
    case 'Food_fractions/Pizza/Pizza_Quarters/Pizza_3_Quarters':
      return {
        asset: FoodFractionsPizzaPizzaQuartersPizza3Quarters,

        width: 435.90704,
        height: 435.90759,
        aspectRatio: 0.9999987382646858
      };
    case 'Food_fractions/Pizza/Pizza_Quarters/Pizza_4_Quarters':
      return {
        asset: FoodFractionsPizzaPizzaQuartersPizza4Quarters,

        width: 436.45172,
        height: 436.5877,
        aspectRatio: 0.9996885390953525
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_1_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza1Sevenths,

        width: 175.24388,
        height: 220.813,
        aspectRatio: 0.7936302663339568
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_2_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza2Sevenths,

        width: 221.29382,
        height: 269.12659,
        aspectRatio: 0.8222666515411947
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_3_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza3Sevenths,

        width: 221.29382,
        height: 415.46255,
        aspectRatio: 0.5326444465331472
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_4_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza4Sevenths,

        width: 315.42038,
        height: 436.49502,
        aspectRatio: 0.7226207987435916
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_5_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza5Sevenths,

        width: 431.32043,
        height: 436.49502,
        aspectRatio: 0.9881451339353196
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_6_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza6Sevenths,

        width: 436.49118,
        height: 436.49502,
        aspectRatio: 0.9999912026487724
      };
    case 'Food_fractions/Pizza/Pizza_Sevenths/Pizza_7_Sevenths':
      return {
        asset: FoodFractionsPizzaPizzaSeventhsPizza7Sevenths,

        width: 436.49118,
        height: 436.49502,
        aspectRatio: 0.9999912026487724
      };
    case 'Food_fractions/Pizza/Pizza_Sixths/pizza_1_sixths':
      return {
        asset: FoodFractionsPizzaPizzaSixthsPizza1Sixths,

        width: 221.02733,
        height: 264.20666,
        aspectRatio: 0.8365698654227718
      };
    case 'Food_fractions/Pizza/Pizza_Sixths/pizza_2_sixths':
      return {
        asset: FoodFractionsPizzaPizzaSixthsPizza2Sixths,

        width: 221.02733,
        height: 354.44772,
        aspectRatio: 0.6235823156091962
      };
    case 'Food_fractions/Pizza/Pizza_Sixths/pizza_3_sixths':
      return {
        asset: FoodFractionsPizzaPizzaSixthsPizza3Sixths,

        width: 239.27133,
        height: 435.90786,
        aspectRatio: 0.5489034540464584
      };
    case 'Food_fractions/Pizza/Pizza_Sixths/pizza_4_sixths':
      return {
        asset: FoodFractionsPizzaPizzaSixthsPizza4Sixths,

        width: 435.90799,
        height: 435.90786,
        aspectRatio: 1.0000002982281622
      };
    case 'Food_fractions/Pizza/Pizza_Sixths/pizza_5_sixths':
      return {
        asset: FoodFractionsPizzaPizzaSixthsPizza5Sixths,

        width: 435.90799,
        height: 435.90786,
        aspectRatio: 1.0000002982281622
      };
    case 'Food_fractions/Pizza/Pizza_Sixths/pizza_6_sixths':
      return {
        asset: FoodFractionsPizzaPizzaSixthsPizza6Sixths,

        width: 435.90799,
        height: 435.90786,
        aspectRatio: 1.0000002982281622
      };
    case 'Food_fractions/Pizza/Pizza_Thirds/Pizza_1_Thirds':
      return {
        asset: FoodFractionsPizzaPizzaThirdsPizza1Thirds,

        width: 220.61999,
        height: 324.89721,
        aspectRatio: 0.6790455048844526
      };
    case 'Food_fractions/Pizza/Pizza_Thirds/Pizza_2_Thirds':
      return {
        asset: FoodFractionsPizzaPizzaThirdsPizza2Thirds,

        width: 435.90799,
        height: 435.90811,
        aspectRatio: 0.9999997247126234
      };
    case 'Food_fractions/Pizza/Pizza_Thirds/Pizza_3_Thirds':
      return {
        asset: FoodFractionsPizzaPizzaThirdsPizza3Thirds,

        width: 407.55322,
        height: 435.90811,
        aspectRatio: 0.9349521393396419
      };
    case 'Food_fractions/Pizza/Pizza_Whole/Pizza':
      return {
        asset: FoodFractionsPizzaPizzaWholePizza,

        width: 435.90799,
        height: 435.90664,
        aspectRatio: 1.0000030969934297
      };
    case 'Food_on_a_plate/Cookies_cakes_plate_1':
      return {
        asset: FoodOnAPlateCookiesCakesPlate1,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Cookies_cakes_plate_2':
      return {
        asset: FoodOnAPlateCookiesCakesPlate2,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Cookies_cakes_plate_3':
      return {
        asset: FoodOnAPlateCookiesCakesPlate3,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Cookies_cakes_plate_4':
      return {
        asset: FoodOnAPlateCookiesCakesPlate4,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Cookies_cakes_plate_5':
      return {
        asset: FoodOnAPlateCookiesCakesPlate5,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Cookies_cakes_plate_6':
      return {
        asset: FoodOnAPlateCookiesCakesPlate6,

        width: 343.39752,
        height: 207.34599,
        aspectRatio: 1.6561570349154087
      };
    case 'Food_on_a_plate/Muffins_plate_1':
      return {
        asset: FoodOnAPlateMuffinsPlate1,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Muffins_plate_2':
      return {
        asset: FoodOnAPlateMuffinsPlate2,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Food_on_a_plate/Muffins_plate_3':
      return {
        asset: FoodOnAPlateMuffinsPlate3,

        width: 336.61465,
        height: 228.12964,
        aspectRatio: 1.4755410563923215
      };
    case 'Food_on_a_plate/Muffins_plate_4':
      return {
        asset: FoodOnAPlateMuffinsPlate4,

        width: 336.61465,
        height: 228.12964,
        aspectRatio: 1.4755410563923215
      };
    case 'Food_on_a_plate/Muffins_plate_5':
      return {
        asset: FoodOnAPlateMuffinsPlate5,

        width: 336.61465,
        height: 202.2475,
        aspectRatio: 1.664369893323774
      };
    case 'Food_on_a_plate/Muffins_plate_6':
      return {
        asset: FoodOnAPlateMuffinsPlate6,

        width: 336.61465,
        height: 243.45163,
        aspectRatio: 1.3826756879795794
      };
    case 'Football_sticker_book':
      return {
        asset: FootballStickerBook,

        width: 582.53601,
        height: 411.69198,
        aspectRatio: 1.414980223807129
      };
    case 'Fork':
      return {
        asset: Fork,

        width: 1297.3333,
        height: 205.916,
        aspectRatio: 6.300303521824433
      };
    case 'Frog':
      return {
        asset: Frog,
        defaultWidth: 793.70135,
        defaultHeight: 793.70135,
        width: 793.70135,
        height: 793.70135,
        aspectRatio: 1
      };
    case 'Full_bathtub':
      return {
        asset: FullBathtub,

        width: 524.28387,
        height: 369.19711,
        aspectRatio: 1.4200649349611647
      };
    case 'Full_bucket':
      return {
        asset: FullBucket,

        width: 574.27464,
        height: 655.44,
        aspectRatio: 0.87616660563896
      };
    case 'GamesConsole':
      return {
        asset: GamesConsole,

        width: 458.64398,
        height: 392.77243,
        aspectRatio: 1.1677091999558116
      };
    case 'Giraffe':
      return {
        asset: Giraffe,

        width: 406,
        height: 612,
        aspectRatio: 0.6633986928104575
      };
    case 'Glasses_of_orange_juice/glass1_oj_eighth_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjEighthFull,

        width: 110.44,
        height: 120.15,
        aspectRatio: 0.919184352892218
      };
    case 'Glasses_of_orange_juice/glass1_oj_empty':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjEmpty,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass1_oj_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjFull,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass1_oj_half_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjHalfFull,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass1_oj_quarter_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjQuarterFull,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass1_oj_seven_eighths_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjSevenEighthsFull,

        width: 110.44,
        height: 120.15,
        aspectRatio: 0.919184352892218
      };
    case 'Glasses_of_orange_juice/glass1_oj_third_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjThirdFull,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass1_oj_three_quarters_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjThreeQuartersFull,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass1_oj_two_thirds_full':
      return {
        asset: GlassesOfOrangeJuiceGlass1OjTwoThirdsFull,

        width: 110.4373,
        height: 120.152,
        aspectRatio: 0.9191465809974032
      };
    case 'Glasses_of_orange_juice/glass2_oj_eighth_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjEighthFull,

        width: 96.67,
        height: 120.15,
        aspectRatio: 0.8045776113191844
      };
    case 'Glasses_of_orange_juice/glass2_oj_empty':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjEmpty,

        width: 96.667998,
        height: 120.152,
        aspectRatio: 0.8045475564285238
      };
    case 'Glasses_of_orange_juice/glass2_oj_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjFull,

        width: 96.668,
        height: 120.152,
        aspectRatio: 0.8045475730741062
      };
    case 'Glasses_of_orange_juice/glass2_oj_half_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjHalfFull,

        width: 96.668001,
        height: 120.152,
        aspectRatio: 0.8045475813968973
      };
    case 'Glasses_of_orange_juice/glass2_oj_quarter_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjQuarterFull,

        width: 96.668,
        height: 120.152,
        aspectRatio: 0.8045475730741062
      };
    case 'Glasses_of_orange_juice/glass2_oj_seven_eighths_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjSevenEighthsFull,

        width: 96.67,
        height: 120.15,
        aspectRatio: 0.8045776113191844
      };
    case 'Glasses_of_orange_juice/glass2_oj_third_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjThirdFull,

        width: 96.668001,
        height: 120.152,
        aspectRatio: 0.8045475813968973
      };
    case 'Glasses_of_orange_juice/glass2_oj_three_quarters_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjThreeQuartersFull,

        width: 96.668001,
        height: 120.152,
        aspectRatio: 0.8045475813968973
      };
    case 'Glasses_of_orange_juice/glass2_oj_two_thirds_full':
      return {
        asset: GlassesOfOrangeJuiceGlass2OjTwoThirdsFull,

        width: 96.668,
        height: 120.152,
        aspectRatio: 0.8045475730741062
      };
    case 'Glasses_of_water/glass_water_full':
      return {
        asset: GlassesOfWaterGlassWaterFull,
        defaultWidth: 97,
        defaultHeight: 121,
        width: 97,
        height: 121,
        aspectRatio: 0.8016528925619835
      };
    case 'Glasses_of_water/glass_water_half_full':
      return {
        asset: GlassesOfWaterGlassWaterHalfFull,
        defaultWidth: 97,
        defaultHeight: 121,
        width: 97,
        height: 121,
        aspectRatio: 0.8016528925619835
      };
    case 'Glasses_of_water/glass_water_quarter_full':
      return {
        asset: GlassesOfWaterGlassWaterQuarterFull,
        defaultWidth: 97,
        defaultHeight: 121,
        width: 97,
        height: 121,
        aspectRatio: 0.8016528925619835
      };
    case 'Glasses_of_water/glass_water_three_quarters_full':
      return {
        asset: GlassesOfWaterGlassWaterThreeQuartersFull,
        defaultWidth: 97,
        defaultHeight: 121,
        width: 97,
        height: 121,
        aspectRatio: 0.8016528925619835
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_0':
      return {
        asset: GoldfishInATankGoldfishInATank0,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_1':
      return {
        asset: GoldfishInATankGoldfishInATank1,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_10':
      return {
        asset: GoldfishInATankGoldfishInATank10,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_2':
      return {
        asset: GoldfishInATankGoldfishInATank2,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_3':
      return {
        asset: GoldfishInATankGoldfishInATank3,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_4':
      return {
        asset: GoldfishInATankGoldfishInATank4,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_5':
      return {
        asset: GoldfishInATankGoldfishInATank5,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_6':
      return {
        asset: GoldfishInATankGoldfishInATank6,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_7':
      return {
        asset: GoldfishInATankGoldfishInATank7,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_8':
      return {
        asset: GoldfishInATankGoldfishInATank8,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Goldfish_in_a_tank/Goldfish_in_a_tank_9':
      return {
        asset: GoldfishInATankGoldfishInATank9,

        width: 394.12665,
        height: 262.67026,
        aspectRatio: 1.5004616434308171
      };
    case 'Grape_green':
      return {
        asset: GrapeGreen,
        defaultWidth: 126.80076,
        defaultHeight: 115.70895,
        width: 126.80076,
        height: 115.70895,
        aspectRatio: 1.0958595683393548
      };
    case 'Grapes_green_bunch':
      return {
        asset: GrapesGreenBunch,
        defaultWidth: 361.28049,
        defaultHeight: 278.46027,
        width: 361.28048,
        height: 278.46026,
        aspectRatio: 1.2974220450702731
      };
    case 'GreyStar':
      return {
        asset: GreyStar,

        width: 41,
        height: 40,
        aspectRatio: 1.025
      };
    case 'Gummy_bears/gummybear_green':
      return {
        asset: GummyBearsGummybearGreen,
        defaultWidth: 189.39333,
        defaultHeight: 224.64667,
        width: 189.39333,
        height: 224.64667,
        aspectRatio: 0.8430720562205529
      };
    case 'Gummy_bears/gummybear_orange':
      return {
        asset: GummyBearsGummybearOrange,
        defaultWidth: 189.39333,
        defaultHeight: 224.64667,
        width: 189.39333,
        height: 224.64667,
        aspectRatio: 0.8430720562205529
      };
    case 'Gummy_bears/gummybear_purple':
      return {
        asset: GummyBearsGummybearPurple,
        defaultWidth: 189.39333,
        defaultHeight: 224.64667,
        width: 189.39333,
        height: 224.64667,
        aspectRatio: 0.8430720562205529
      };
    case 'Gummy_bears/gummybear_red':
      return {
        asset: GummyBearsGummybearRed,
        defaultWidth: 189.39333,
        defaultHeight: 224.64667,
        width: 189.39333,
        height: 224.64667,
        aspectRatio: 0.8430720562205529
      };
    case 'Gummy_bears/gummybear_yellow':
      return {
        asset: GummyBearsGummybearYellow,
        defaultWidth: 189.39333,
        defaultHeight: 224.64667,
        width: 189.39333,
        height: 224.64667,
        aspectRatio: 0.8430720562205529
      };
    case 'Hand_open_fingers':
      return {
        asset: HandOpenFingers,
        defaultWidth: 264.16,
        defaultHeight: 355.76,
        width: 264.16,
        height: 355.76,
        aspectRatio: 0.7425230492466832
      };
    case 'Hands_open_pair':
      return {
        asset: HandsOpenPair,

        width: 583.84882,
        height: 355.76992,
        aspectRatio: 1.6410853958648333
      };
    case 'Hay_bale':
      return {
        asset: HayBale,

        width: 437.81616,
        height: 256.94,
        aspectRatio: 1.7039626371915624
      };
    case 'Headphones':
      return {
        asset: Headphones,

        width: 292.53366,
        height: 402.58819,
        aspectRatio: 0.7266324926222997
      };
    case 'Heart_purple':
      return {
        asset: HeartPurple,

        width: 291.47733,
        height: 275.17157,
        aspectRatio: 1.0592567030089628
      };
    case 'Heptagons/Heptagon_blue':
      return {
        asset: HeptagonsHeptagonBlue,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_green':
      return {
        asset: HeptagonsHeptagonGreen,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_orange':
      return {
        asset: HeptagonsHeptagonOrange,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_pink':
      return {
        asset: HeptagonsHeptagonPink,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_purple':
      return {
        asset: HeptagonsHeptagonPurple,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_red':
      return {
        asset: HeptagonsHeptagonRed,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_turquoise':
      return {
        asset: HeptagonsHeptagonTurquoise,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Heptagon_yellow':
      return {
        asset: HeptagonsHeptagonYellow,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Heptagons/Irregular_heptagon1_blue':
      return {
        asset: HeptagonsIrregularHeptagon1Blue,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_green':
      return {
        asset: HeptagonsIrregularHeptagon1Green,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_orange':
      return {
        asset: HeptagonsIrregularHeptagon1Orange,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_pink':
      return {
        asset: HeptagonsIrregularHeptagon1Pink,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_purple':
      return {
        asset: HeptagonsIrregularHeptagon1Purple,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_red':
      return {
        asset: HeptagonsIrregularHeptagon1Red,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_turquoise':
      return {
        asset: HeptagonsIrregularHeptagon1Turquoise,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon1_yellow':
      return {
        asset: HeptagonsIrregularHeptagon1Yellow,

        width: 846.95339,
        height: 496.73599,
        aspectRatio: 1.705037297579344
      };
    case 'Heptagons/Irregular_heptagon2_blue':
      return {
        asset: HeptagonsIrregularHeptagon2Blue,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_green':
      return {
        asset: HeptagonsIrregularHeptagon2Green,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_orange':
      return {
        asset: HeptagonsIrregularHeptagon2Orange,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_pink':
      return {
        asset: HeptagonsIrregularHeptagon2Pink,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_purple':
      return {
        asset: HeptagonsIrregularHeptagon2Purple,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_red':
      return {
        asset: HeptagonsIrregularHeptagon2Red,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_turquoise':
      return {
        asset: HeptagonsIrregularHeptagon2Turquoise,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon2_yellow':
      return {
        asset: HeptagonsIrregularHeptagon2Yellow,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Heptagons/Irregular_heptagon3_blue':
      return {
        asset: HeptagonsIrregularHeptagon3Blue,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_green':
      return {
        asset: HeptagonsIrregularHeptagon3Green,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_orange':
      return {
        asset: HeptagonsIrregularHeptagon3Orange,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_pink':
      return {
        asset: HeptagonsIrregularHeptagon3Pink,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_purple':
      return {
        asset: HeptagonsIrregularHeptagon3Purple,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_red':
      return {
        asset: HeptagonsIrregularHeptagon3Red,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_turquoise':
      return {
        asset: HeptagonsIrregularHeptagon3Turquoise,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Heptagons/Irregular_heptagon3_yellow':
      return {
        asset: HeptagonsIrregularHeptagon3Yellow,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon1_blue':
      return {
        asset: HexagonsIrregularHexagon1Blue,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_blue_one_interior_angle_lhs':
      return {
        asset: HexagonsIrregularHexagon1BlueOneInteriorAngleLhs,

        width: 304,
        height: 351,
        aspectRatio: 0.8660968660968661
      };
    case 'Hexagons/Irregular_hexagon1_blue_one_interior_angle_rhs':
      return {
        asset: HexagonsIrregularHexagon1BlueOneInteriorAngleRhs,

        width: 309,
        height: 356,
        aspectRatio: 0.8679775280898876
      };
    case 'Hexagons/Irregular_hexagon1_green':
      return {
        asset: HexagonsIrregularHexagon1Green,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_green_one_interior_angle_lhs':
      return {
        asset: HexagonsIrregularHexagon1GreenOneInteriorAngleLhs,

        width: 304,
        height: 351,
        aspectRatio: 0.8660968660968661
      };
    case 'Hexagons/Irregular_hexagon1_green_one_interior_angle_rhs':
      return {
        asset: HexagonsIrregularHexagon1GreenOneInteriorAngleRhs,

        width: 309,
        height: 356,
        aspectRatio: 0.8679775280898876
      };
    case 'Hexagons/Irregular_hexagon1_orange':
      return {
        asset: HexagonsIrregularHexagon1Orange,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_pink':
      return {
        asset: HexagonsIrregularHexagon1Pink,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_pink_one_interior_angle_lhs':
      return {
        asset: HexagonsIrregularHexagon1PinkOneInteriorAngleLhs,

        width: 304,
        height: 351,
        aspectRatio: 0.8660968660968661
      };
    case 'Hexagons/Irregular_hexagon1_pink_one_interior_angle_rhs':
      return {
        asset: HexagonsIrregularHexagon1PinkOneInteriorAngleRhs,

        width: 309,
        height: 356,
        aspectRatio: 0.8679775280898876
      };
    case 'Hexagons/Irregular_hexagon1_purple':
      return {
        asset: HexagonsIrregularHexagon1Purple,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_purple_one_interior_angle_lhs':
      return {
        asset: HexagonsIrregularHexagon1PurpleOneInteriorAngleLhs,

        width: 304,
        height: 351,
        aspectRatio: 0.8660968660968661
      };
    case 'Hexagons/Irregular_hexagon1_purple_one_interior_angle_rhs':
      return {
        asset: HexagonsIrregularHexagon1PurpleOneInteriorAngleRhs,

        width: 309,
        height: 356,
        aspectRatio: 0.8679775280898876
      };
    case 'Hexagons/Irregular_hexagon1_red':
      return {
        asset: HexagonsIrregularHexagon1Red,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_turquoise':
      return {
        asset: HexagonsIrregularHexagon1Turquoise,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_white':
      return {
        asset: HexagonsIrregularHexagon1White,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_white_one_interior_angle_lhs':
      return {
        asset: HexagonsIrregularHexagon1WhiteOneInteriorAngleLhs,

        width: 304,
        height: 351,
        aspectRatio: 0.8660968660968661
      };
    case 'Hexagons/Irregular_hexagon1_white_one_interior_angle_rhs':
      return {
        asset: HexagonsIrregularHexagon1WhiteOneInteriorAngleRhs,

        width: 309,
        height: 356,
        aspectRatio: 0.8679775280898876
      };
    case 'Hexagons/Irregular_hexagon1_yellow':
      return {
        asset: HexagonsIrregularHexagon1Yellow,

        width: 520.35997,
        height: 600.29065,
        aspectRatio: 0.8668467016769292
      };
    case 'Hexagons/Irregular_hexagon1_yellow_one_interior_angle_lhs':
      return {
        asset: HexagonsIrregularHexagon1YellowOneInteriorAngleLhs,

        width: 304,
        height: 351,
        aspectRatio: 0.8660968660968661
      };
    case 'Hexagons/Irregular_hexagon1_yellow_one_interior_angle_rhs':
      return {
        asset: HexagonsIrregularHexagon1YellowOneInteriorAngleRhs,

        width: 309,
        height: 356,
        aspectRatio: 0.8679775280898876
      };
    case 'Hexagons/Irregular_hexagon2_blue':
      return {
        asset: HexagonsIrregularHexagon2Blue,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_green':
      return {
        asset: HexagonsIrregularHexagon2Green,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_orange':
      return {
        asset: HexagonsIrregularHexagon2Orange,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_pink':
      return {
        asset: HexagonsIrregularHexagon2Pink,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_purple':
      return {
        asset: HexagonsIrregularHexagon2Purple,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_red':
      return {
        asset: HexagonsIrregularHexagon2Red,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_turquoise':
      return {
        asset: HexagonsIrregularHexagon2Turquoise,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_white':
      return {
        asset: HexagonsIrregularHexagon2White,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon2_yellow':
      return {
        asset: HexagonsIrregularHexagon2Yellow,

        width: 1033.6238,
        height: 494.73599,
        aspectRatio: 2.0892431941326928
      };
    case 'Hexagons/Irregular_hexagon3_blue':
      return {
        asset: HexagonsIrregularHexagon3Blue,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_green':
      return {
        asset: HexagonsIrregularHexagon3Green,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_orange':
      return {
        asset: HexagonsIrregularHexagon3Orange,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_pink':
      return {
        asset: HexagonsIrregularHexagon3Pink,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_purple':
      return {
        asset: HexagonsIrregularHexagon3Purple,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_red':
      return {
        asset: HexagonsIrregularHexagon3Red,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_turquoise':
      return {
        asset: HexagonsIrregularHexagon3Turquoise,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_white':
      return {
        asset: HexagonsIrregularHexagon3White,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon3_yellow':
      return {
        asset: HexagonsIrregularHexagon3Yellow,

        width: 730.3386,
        height: 464.39331,
        aspectRatio: 1.5726725262256687
      };
    case 'Hexagons/Irregular_hexagon4_blue':
      return {
        asset: HexagonsIrregularHexagon4Blue,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_green':
      return {
        asset: HexagonsIrregularHexagon4Green,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_orange':
      return {
        asset: HexagonsIrregularHexagon4Orange,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_pink':
      return {
        asset: HexagonsIrregularHexagon4Pink,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_purple':
      return {
        asset: HexagonsIrregularHexagon4Purple,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_red':
      return {
        asset: HexagonsIrregularHexagon4Red,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_turquoise':
      return {
        asset: HexagonsIrregularHexagon4Turquoise,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_white':
      return {
        asset: HexagonsIrregularHexagon4White,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon4_yellow':
      return {
        asset: HexagonsIrregularHexagon4Yellow,

        width: 632.50236,
        height: 496.08133,
        aspectRatio: 1.2749973073971559
      };
    case 'Hexagons/Irregular_hexagon5_blue':
      return {
        asset: HexagonsIrregularHexagon5Blue,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_green':
      return {
        asset: HexagonsIrregularHexagon5Green,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_orange':
      return {
        asset: HexagonsIrregularHexagon5Orange,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_pink':
      return {
        asset: HexagonsIrregularHexagon5Pink,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_purple':
      return {
        asset: HexagonsIrregularHexagon5Purple,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_red':
      return {
        asset: HexagonsIrregularHexagon5Red,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_turquoise':
      return {
        asset: HexagonsIrregularHexagon5Turquoise,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_white':
      return {
        asset: HexagonsIrregularHexagon5White,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon5_yellow':
      return {
        asset: HexagonsIrregularHexagon5Yellow,

        width: 738.10246,
        height: 472.51184,
        aspectRatio: 1.5620824654891186
      };
    case 'Hexagons/Irregular_hexagon6_blue':
      return {
        asset: HexagonsIrregularHexagon6Blue,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_green':
      return {
        asset: HexagonsIrregularHexagon6Green,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_orange':
      return {
        asset: HexagonsIrregularHexagon6Orange,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_pink':
      return {
        asset: HexagonsIrregularHexagon6Pink,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_purple':
      return {
        asset: HexagonsIrregularHexagon6Purple,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_red':
      return {
        asset: HexagonsIrregularHexagon6Red,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_turquoise':
      return {
        asset: HexagonsIrregularHexagon6Turquoise,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_white':
      return {
        asset: HexagonsIrregularHexagon6White,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/Irregular_hexagon6_yellow':
      return {
        asset: HexagonsIrregularHexagon6Yellow,

        width: 844.37986,
        height: 256.69992,
        aspectRatio: 3.28936549727012
      };
    case 'Hexagons/hexagon':
      return {
        asset: HexagonsHexagon,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_1_arrow':
      return {
        asset: HexagonsHexagon1Arrow,
        defaultWidth: 377,
        defaultHeight: 379,
        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_blue':
      return {
        asset: HexagonsHexagon1ArrowBlue,
        defaultWidth: 377,
        defaultHeight: 379,
        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_green':
      return {
        asset: HexagonsHexagon1ArrowGreen,
        defaultWidth: 377,
        defaultHeight: 379,
        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_orange':
      return {
        asset: HexagonsHexagon1ArrowOrange,

        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_pink':
      return {
        asset: HexagonsHexagon1ArrowPink,
        defaultWidth: 377,
        defaultHeight: 379,
        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_purple':
      return {
        asset: HexagonsHexagon1ArrowPurple,
        defaultWidth: 377,
        defaultHeight: 379,
        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_turquoise':
      return {
        asset: HexagonsHexagon1ArrowTurquoise,

        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_1_arrow_yellow':
      return {
        asset: HexagonsHexagon1ArrowYellow,
        defaultWidth: 377,
        defaultHeight: 379,
        width: 377,
        height: 379,
        aspectRatio: 0.9947229551451188
      };
    case 'Hexagons/hexagon_blue':
      return {
        asset: HexagonsHexagonBlue,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_green':
      return {
        asset: HexagonsHexagonGreen,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_orange':
      return {
        asset: HexagonsHexagonOrange,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_pink':
      return {
        asset: HexagonsHexagonPink,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_purple':
      return {
        asset: HexagonsHexagonPurple,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_red':
      return {
        asset: HexagonsHexagonRed,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_turquoise':
      return {
        asset: HexagonsHexagonTurquoise,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_white':
      return {
        asset: HexagonsHexagonWhite,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Hexagons/hexagon_yellow':
      return {
        asset: HexagonsHexagonYellow,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Horizontal_Thermoter_blank':
      return {
        asset: HorizontalThermoterBlank,

        width: 371.18305,
        height: 44.615733,
        aspectRatio: 8.319555121956641
      };
    case 'Horse':
      return {
        asset: Horse,

        width: 338.63669,
        height: 358.35193,
        aspectRatio: 0.9449835808056064
      };
    case 'House':
      return {
        asset: House,

        width: 149.2804,
        height: 172.07333,
        aspectRatio: 0.8675394379826321
      };
    case 'Hulahoop':
      return {
        asset: Hulahoop,

        width: 713.79467,
        height: 267.3873,
        aspectRatio: 2.6695159792555594
      };
    case 'Icecreamcone':
      return {
        asset: Icecreamcone,

        width: 175.75149,
        height: 292.5289,
        aspectRatio: 0.6008004337349232
      };
    case 'Individual_lolly_stick':
      return {
        asset: IndividualLollyStick,

        width: 26.285332,
        height: 224.66133,
        aspectRatio: 0.11699980588559679
      };
    case 'Info':
      return {
        asset: Info,
        defaultWidth: 25,
        defaultHeight: 26,
        width: 25,
        height: 26,
        aspectRatio: 0.9615384615384616
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrow,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_blue':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowBlue,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowBlueOneInteriorAngleLhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_blue_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowBlueOneInteriorAngleRhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_green':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowGreen,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowGreenOneInteriorAngleLhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_green_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowGreenOneInteriorAngleRhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_orange':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowOrange,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_pink':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowPink,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowPinkOneInteriorAngleLhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_pink_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowPinkOneInteriorAngleRhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_purple':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowPurple,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowPurpleOneInteriorAngleLhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_purple_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowPurpleOneInteriorAngleRhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_red':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowRed,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_turquoise':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowTurquoise,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_white':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowWhite,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowWhiteOneInteriorAngleLhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_white_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowWhiteOneInteriorAngleRhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_yellow':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowYellow,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowYellowOneInteriorAngleLhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_narrow/triangle_isos_narrow_yellow_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesNarrowTriangleIsosNarrowYellowOneInteriorAngleRhs,

        width: 273,
        height: 331,
        aspectRatio: 0.824773413897281
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWide,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_blue':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideBlue,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideBlueOneInteriorAngleLhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_blue_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideBlueOneInteriorAngleRhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_green':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideGreen,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideGreenOneInteriorAngleLhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_green_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideGreenOneInteriorAngleRhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_orange':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideOrange,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_pink':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWidePink,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWidePinkOneInteriorAngleLhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_pink_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWidePinkOneInteriorAngleRhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_purple':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWidePurple,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWidePurpleOneInteriorAngleLhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_purple_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWidePurpleOneInteriorAngleRhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_red':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideRed,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_turquoise':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideTurquoise,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_white':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideWhite,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_white_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideWhiteOneInteriorAngleLhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_white_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideWhiteOneInteriorAngleRhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_yellow':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideYellow,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_lhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideYellowOneInteriorAngleLhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Isosceles_triangles_wide/triangle_isos_wide_yellow_one_interior_angle_rhs':
      return {
        asset: IsoscelesTrianglesWideTriangleIsosWideYellowOneInteriorAngleRhs,

        width: 443,
        height: 330,
        aspectRatio: 1.3424242424242425
      };
    case 'Jack_birds_eye':
      return {
        asset: JackBirdsEye,
        defaultWidth: 274,
        defaultHeight: 216,
        width: 274,
        height: 216,
        aspectRatio: 1.2685185185185186
      };
    case 'Juice':
      return {
        asset: Juice,

        width: 400.70135,
        height: 793.70135,
        aspectRatio: 0.5048515414519579
      };
    case 'Ketchup':
      return {
        asset: Ketchup,

        width: 238.43178,
        height: 565.91571,
        aspectRatio: 0.4213203058102063
      };
    case 'Kettle':
      return {
        asset: Kettle,

        width: 264.56839,
        height: 325.04852,
        aspectRatio: 0.8139350703704173
      };
    case 'Keychain1':
      return {
        asset: Keychain1,

        width: 144.20809,
        height: 307.82196,
        aspectRatio: 0.4684788895503102
      };
    case 'Kite/kite':
      return {
        asset: KiteKite,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_blue':
      return {
        asset: KiteKiteBlue,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_green':
      return {
        asset: KiteKiteGreen,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_grey':
      return {
        asset: KiteKiteGrey,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_orange':
      return {
        asset: KiteKiteOrange,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_pink':
      return {
        asset: KiteKitePink,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_purple':
      return {
        asset: KiteKitePurple,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_red':
      return {
        asset: KiteKiteRed,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_turquoise':
      return {
        asset: KiteKiteTurquoise,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_white':
      return {
        asset: KiteKiteWhite,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kite/kite_yellow':
      return {
        asset: KiteKiteYellow,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Kiwi_fruit':
      return {
        asset: KiwiFruit,

        width: 276.11066,
        height: 190.30932,
        aspectRatio: 1.4508520129229614
      };
    case 'L_shapes/L_shape_blue':
      return {
        asset: LShapesLShapeBlue,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_green':
      return {
        asset: LShapesLShapeGreen,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_orange':
      return {
        asset: LShapesLShapeOrange,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_pink':
      return {
        asset: LShapesLShapePink,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_purple':
      return {
        asset: LShapesLShapePurple,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_red':
      return {
        asset: LShapesLShapeRed,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_turquoise':
      return {
        asset: LShapesLShapeTurquoise,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'L_shapes/L_shape_yellow':
      return {
        asset: LShapesLShapeYellow,

        width: 327.79291,
        height: 327.79291,
        aspectRatio: 1
      };
    case 'Labelled_box_A':
      return {
        asset: LabelledBoxA,

        width: 236.6664,
        height: 164.33469,
        aspectRatio: 1.4401487598266685
      };
    case 'Labelled_box_B':
      return {
        asset: LabelledBoxB,

        width: 236.6664,
        height: 164.33469,
        aspectRatio: 1.4401487598266685
      };
    case 'Labelled_box_C':
      return {
        asset: LabelledBoxC,

        width: 236.6664,
        height: 164.33469,
        aspectRatio: 1.4401487598266685
      };
    case 'Labelled_box_D':
      return {
        asset: LabelledBoxD,

        width: 236.6664,
        height: 164.33469,
        aspectRatio: 1.4401487598266685
      };
    case 'Labelled_box_bricks':
      return {
        asset: LabelledBoxBricks,

        width: 278.74265,
        height: 132.53999,
        aspectRatio: 2.103083378835324
      };
    case 'Laptop':
      return {
        asset: Laptop,
        defaultWidth: 328.09161,
        defaultHeight: 204.27519,
        width: 328.09162,
        height: 204.27519,
        aspectRatio: 1.6061256386544052
      };
    case 'Large_bin':
      return {
        asset: LargeBin,

        width: 377.89123,
        height: 510.59597,
        aspectRatio: 0.7400983403766387
      };
    case 'Layered_shapes/Layered_compound_shapes1':
      return {
        asset: LayeredShapesLayeredCompoundShapes1,

        width: 480.58061,
        height: 603.33008,
        aspectRatio: 0.7965467427050877
      };
    case 'Layered_shapes/Layered_compound_shapes2':
      return {
        asset: LayeredShapesLayeredCompoundShapes2,

        width: 538.83733,
        height: 538.83734,
        aspectRatio: 0.9999999814415236
      };
    case 'Layered_shapes/Layered_compound_shapes3':
      return {
        asset: LayeredShapesLayeredCompoundShapes3,

        width: 732.64494,
        height: 538.776,
        aspectRatio: 1.3598321751525682
      };
    case 'Layered_shapes/Layered_compound_shapes4':
      return {
        asset: LayeredShapesLayeredCompoundShapes4,

        width: 552.58128,
        height: 538.77667,
        aspectRatio: 1.025622137647497
      };
    case 'Layered_shapes/Layered_compound_shapes5':
      return {
        asset: LayeredShapesLayeredCompoundShapes5,

        width: 737.93876,
        height: 737.93878,
        aspectRatio: 0.9999999728974808
      };
    case 'Layered_shapes/Layered_compound_shapes6':
      return {
        asset: LayeredShapesLayeredCompoundShapes6,

        width: 596.35301,
        height: 777.04224,
        aspectRatio: 0.7674653697075722
      };
    case 'Lego_bricks/thick_lego_6_circles_pink':
      return {
        asset: LegoBricksThickLego6CirclesPink,

        width: 289.50253,
        height: 211.22933,
        aspectRatio: 1.3705602815669584
      };
    case 'Lego_bricks/thick_lego_6_circles_red':
      return {
        asset: LegoBricksThickLego6CirclesRed,

        width: 308.16907,
        height: 168.56217,
        aspectRatio: 1.8282220144650485
      };
    case 'Lego_bricks/thick_lego_6_circles_yellow':
      return {
        asset: LegoBricksThickLego6CirclesYellow,

        width: 289.50213,
        height: 181.89572,
        aspectRatio: 1.5915829685272418
      };
    case 'Lego_bricks/thick_lego_8_circles_white':
      return {
        asset: LegoBricksThickLego8CirclesWhite,

        width: 385.1488,
        height: 168.56226,
        aspectRatio: 2.2849052925607425
      };
    case 'Lego_bricks/thin_lego_6_circles1_purple':
      return {
        asset: LegoBricksThinLego6Circles1Purple,

        width: 289.50186,
        height: 128.56215,
        aspectRatio: 2.2518436413827865
      };
    case 'Lego_bricks/thin_lego_6_circles2_green':
      return {
        asset: LegoBricksThinLego6Circles2Green,

        width: 289.50265,
        height: 140.5623,
        aspectRatio: 2.0596038198008997
      };
    case 'Lemon':
      return {
        asset: Lemon,

        width: 1251.286,
        height: 481.49252,
        aspectRatio: 2.5987651895402237
      };
    case 'Lemonade_bottle':
      return {
        asset: LemonadeBottle,

        width: 164.3376,
        height: 487.62399,
        aspectRatio: 0.33701705283203975
      };
    case 'Lettuce':
      return {
        asset: Lettuce,

        width: 263.78616,
        height: 232.54799,
        aspectRatio: 1.134329993563909
      };
    case 'Lion':
      return {
        asset: Lion,
        defaultWidth: 568.37091,
        defaultHeight: 524.65002,
        width: 568.37091,
        height: 524.65002,
        aspectRatio: 1.0833334381651218
      };
    case 'Lion_side_profile':
      return {
        asset: LionSideProfile,

        width: 1119.869,
        height: 814.5705,
        aspectRatio: 1.374796902171144
      };
    case 'Loaf_of_bread':
      return {
        asset: LoafOfBread,

        width: 291.49066,
        height: 145.30933,
        aspectRatio: 2.006000991126998
      };
    case 'Lolly_stick_shapes/Decagon_lolly_sticks':
      return {
        asset: LollyStickShapesDecagonLollySticks,

        width: 677.22245,
        height: 701.30054,
        aspectRatio: 0.9656665172395276
      };
    case 'Lolly_stick_shapes/Heptagon_lolly_sticks':
      return {
        asset: LollyStickShapesHeptagonLollySticks,

        width: 695.35716,
        height: 524.41138,
        aspectRatio: 1.325976488153251
      };
    case 'Lolly_stick_shapes/Hexagon1_lolly_sticks':
      return {
        asset: LollyStickShapesHexagon1LollySticks,

        width: 699.90995,
        height: 297.32333,
        aspectRatio: 2.354036428961024
      };
    case 'Lolly_stick_shapes/Hexagon2_lolly_sticks':
      return {
        asset: LollyStickShapesHexagon2LollySticks,

        width: 491.28887,
        height: 297.32333,
        aspectRatio: 1.652372418942032
      };
    case 'Lolly_stick_shapes/Nonagon2_lolly_sticks':
      return {
        asset: LollyStickShapesNonagon2LollySticks,

        width: 643.9694,
        height: 496.27283,
        aspectRatio: 1.2976116383401444
      };
    case 'Lolly_stick_shapes/Nonagon_lolly_sticks':
      return {
        asset: LollyStickShapesNonagonLollySticks,

        width: 553.61235,
        height: 558.30316,
        aspectRatio: 0.9915980951997476
      };
    case 'Lolly_stick_shapes/Octagon1_lolly_sticks':
      return {
        asset: LollyStickShapesOctagon1LollySticks,

        width: 643.9694,
        height: 653.96289,
        aspectRatio: 0.9847185671345968
      };
    case 'Lolly_stick_shapes/Pentagon_lolly_sticks':
      return {
        asset: LollyStickShapesPentagonLollySticks,

        width: 504.86965,
        height: 297.32333,
        aspectRatio: 1.698049224727841
      };
    case 'Lolly_stick_shapes/Quadrilateral1_lolly_sticks':
      return {
        asset: LollyStickShapesQuadrilateral1LollySticks,

        width: 298.08172,
        height: 309.72891,
        aspectRatio: 0.9623955348565946
      };
    case 'Lolly_stick_shapes/Quadrilateral2_lolly_sticks':
      return {
        asset: LollyStickShapesQuadrilateral2LollySticks,

        width: 448.66893,
        height: 289.17047,
        aspectRatio: 1.55157243407323
      };
    case 'Lolly_stick_shapes/Rectangle10_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle10LollySticks,

        width: 558.46556,
        height: 555.58356,
        aspectRatio: 1.0051873385166399
      };
    case 'Lolly_stick_shapes/Rectangle11_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle11LollySticks,

        width: 691.83781,
        height: 555.58356,
        aspectRatio: 1.2452452876755387
      };
    case 'Lolly_stick_shapes/Rectangle12_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle12LollySticks,

        width: 823.53928,
        height: 555.58356,
        aspectRatio: 1.4822959844240169
      };
    case 'Lolly_stick_shapes/Rectangle13_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle13LollySticks,

        width: 427.22985,
        height: 688.76746,
        aspectRatio: 0.6202816985575944
      };
    case 'Lolly_stick_shapes/Rectangle14_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle14LollySticks,

        width: 558.64024,
        height: 688.76746,
        aspectRatio: 0.8110723465362314
      };
    case 'Lolly_stick_shapes/Rectangle15_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle15LollySticks,

        width: 691.01225,
        height: 688.76746,
        aspectRatio: 1.0032591406103883
      };
    case 'Lolly_stick_shapes/Rectangle16_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle16LollySticks,

        width: 823.86478,
        height: 688.76746,
        aspectRatio: 1.1961435866903467
      };
    case 'Lolly_stick_shapes/Rectangle17_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle17LollySticks,

        width: 422.57198,
        height: 822.27863,
        aspectRatio: 0.5139036387215852
      };
    case 'Lolly_stick_shapes/Rectangle18_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle18LollySticks,

        width: 552.47356,
        height: 822.27863,
        aspectRatio: 0.6718812089279275
      };
    case 'Lolly_stick_shapes/Rectangle19_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle19LollySticks,

        width: 688.8889,
        height: 822.27863,
        aspectRatio: 0.8377803762211357
      };
    case 'Lolly_stick_shapes/Rectangle1_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle1LollySticks,

        width: 415.02398,
        height: 301.30399,
        aspectRatio: 1.3774261004641857
      };
    case 'Lolly_stick_shapes/Rectangle20_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle20LollySticks,

        width: 821.06022,
        height: 822.27863,
        aspectRatio: 0.9985182516539436
      };
    case 'Lolly_stick_shapes/Rectangle2_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle2LollySticks,

        width: 546.71037,
        height: 301.30399,
        aspectRatio: 1.814481016331712
      };
    case 'Lolly_stick_shapes/Rectangle3_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle3LollySticks,

        width: 680.43675,
        height: 301.30399,
        aspectRatio: 2.2583064698213917
      };
    case 'Lolly_stick_shapes/Rectangle4_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle4LollySticks,

        width: 812.1229,
        height: 301.30399,
        aspectRatio: 2.695360589151176
      };
    case 'Lolly_stick_shapes/Rectangle5_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle5LollySticks,

        width: 425.02718,
        height: 424.33386,
        aspectRatio: 1.0016339021354552
      };
    case 'Lolly_stick_shapes/Rectangle6_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle6LollySticks,

        width: 559.04771,
        height: 423.68051,
        aspectRatio: 1.3195030141934072
      };
    case 'Lolly_stick_shapes/Rectangle7_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle7LollySticks,

        width: 692.07066,
        height: 423.68051,
        aspectRatio: 1.633472967637808
      };
    case 'Lolly_stick_shapes/Rectangle8_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle8LollySticks,

        width: 823.53928,
        height: 423.68051,
        aspectRatio: 1.9437742840707963
      };
    case 'Lolly_stick_shapes/Rectangle9_lolly_sticks':
      return {
        asset: LollyStickShapesRectangle9LollySticks,

        width: 429.02983,
        height: 555.58356,
        aspectRatio: 0.7722147681979646
      };
    case 'Lolly_stick_shapes/Rectilinear_shape1_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape1LollySticks,

        width: 274.35571,
        height: 301.30399,
        aspectRatio: 0.9105611578525727
      };
    case 'Lolly_stick_shapes/Rectilinear_shape2_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape2LollySticks,

        width: 274.35571,
        height: 301.30399,
        aspectRatio: 0.9105611578525727
      };
    case 'Lolly_stick_shapes/Rectilinear_shape3_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape3LollySticks,

        width: 274.35571,
        height: 432.99011,
        aspectRatio: 0.6336304309583422
      };
    case 'Lolly_stick_shapes/Rectilinear_shape4_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape4LollySticks,

        width: 432.9901,
        height: 274.35571,
        aspectRatio: 1.578206992666564
      };
    case 'Lolly_stick_shapes/Rectilinear_shape5_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape5LollySticks,

        width: 564.6765,
        height: 274.35571,
        aspectRatio: 2.058191170870838
      };
    case 'Lolly_stick_shapes/Rectilinear_shape6_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape6LollySticks,

        width: 564.6765,
        height: 274.35571,
        aspectRatio: 2.058191170870838
      };
    case 'Lolly_stick_shapes/Rectilinear_shape7_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape7LollySticks,

        width: 564.6765,
        height: 274.35571,
        aspectRatio: 2.058191170870838
      };
    case 'Lolly_stick_shapes/Rectilinear_shape8_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape8LollySticks,

        width: 274.35571,
        height: 696.36267,
        aspectRatio: 0.393983942304087
      };
    case 'Lolly_stick_shapes/Rectilinear_shape9_lolly_sticks':
      return {
        asset: LollyStickShapesRectilinearShape9LollySticks,

        width: 564.67662,
        height: 269.86398,
        aspectRatio: 2.0924490182053934
      };
    case 'Lolly_stick_shapes/Triangle_lolly_sticks':
      return {
        asset: LollyStickShapesTriangleLollySticks,

        width: 305.45852,
        height: 245.9166,
        aspectRatio: 1.2421224106058721
      };
    case 'Lolly_sticks/FiveLollyStickPentagon':
      return {
        asset: LollySticksFiveLollyStickPentagon,

        width: 352,
        height: 347,
        aspectRatio: 1.0144092219020173
      };
    case 'Lolly_sticks/FourLollyStickPentagon':
      return {
        asset: LollySticksFourLollyStickPentagon,

        width: 352,
        height: 344,
        aspectRatio: 1.0232558139534884
      };
    case 'Lolly_sticks/FourLollyStickSquare':
      return {
        asset: LollySticksFourLollyStickSquare,

        width: 242,
        height: 238,
        aspectRatio: 1.0168067226890756
      };
    case 'Lolly_sticks/LollyStickDiamond':
      return {
        asset: LollySticksLollyStickDiamond,

        width: 235.89822,
        height: 370.07355,
        aspectRatio: 0.6374360448078497
      };
    case 'Lolly_sticks/LollyStickHouse':
      return {
        asset: LollySticksLollyStickHouse,

        width: 244.42319,
        height: 412.05832,
        aspectRatio: 0.5931762037956181
      };
    case 'Lolly_sticks/LollyStickRectangle':
      return {
        asset: LollySticksLollyStickRectangle,

        width: 244.42319,
        height: 459.44745,
        aspectRatio: 0.5319937894964919
      };
    case 'Lolly_sticks/LollyStickTriangle':
      return {
        asset: LollySticksLollyStickTriangle,

        width: 255.22632,
        height: 224.46677,
        aspectRatio: 1.1370338692003275
      };
    case 'Lolly_sticks/LollySticksPatterns/LollySticksDiamondPattern':
      return {
        asset: LollySticksLollySticksPatternsLollySticksDiamondPattern,

        width: 1077.0462,
        height: 218.97855,
        aspectRatio: 4.918500921665615
      };
    case 'Lolly_sticks/LollySticksPatterns/LollySticksHousePatternA':
      return {
        asset: LollySticksLollySticksPatternsLollySticksHousePatternA,

        width: 1523.0408,
        height: 179.616,
        aspectRatio: 8.479427222519151
      };
    case 'Lolly_sticks/LollySticksPatterns/LollySticksHousePatternB':
      return {
        asset: LollySticksLollySticksPatternsLollySticksHousePatternB,

        width: 1241.6935,
        height: 289.14197,
        aspectRatio: 4.294407691833877
      };
    case 'Lolly_sticks/LollySticksPatterns/LollySticksRectanglePattern':
      return {
        asset: LollySticksLollySticksPatternsLollySticksRectanglePattern,

        width: 1193.3028,
        height: 331.53106,
        aspectRatio: 3.5993695432337467
      };
    case 'Lolly_sticks/LollySticksPatterns/LollySticksSquarePattern':
      return {
        asset: LollySticksLollySticksPatternsLollySticksSquarePattern,

        width: 1186.6358,
        height: 175.53026,
        aspectRatio: 6.760291929152273
      };
    case 'Lolly_sticks/LollySticksPatterns/LollySticksTrianglePattern':
      return {
        asset: LollySticksLollySticksPatternsLollySticksTrianglePattern,

        width: 959.94862,
        height: 162.66761,
        aspectRatio: 5.901289260965966
      };
    case 'Lolly_sticks/SingleLollyStickPentagon':
      return {
        asset: LollySticksSingleLollyStickPentagon,

        width: 83,
        height: 196,
        aspectRatio: 0.42346938775510207
      };
    case 'Lolly_sticks/SingleLollyStickSquare':
      return {
        asset: LollySticksSingleLollyStickSquare,

        width: 26,
        height: 202,
        aspectRatio: 0.12871287128712872
      };
    case 'Lolly_sticks/SixLollyStickHexagon':
      return {
        asset: LollySticksSixLollyStickHexagon,

        width: 425,
        height: 387,
        aspectRatio: 1.0981912144702843
      };
    case 'Lolly_sticks/ThreeLollyStickPentagon':
      return {
        asset: LollySticksThreeLollyStickPentagon,

        width: 278,
        height: 344,
        aspectRatio: 0.8081395348837209
      };
    case 'Lolly_sticks/ThreeLollyStickSquare':
      return {
        asset: LollySticksThreeLollyStickSquare,

        width: 222,
        height: 238,
        aspectRatio: 0.9327731092436975
      };
    case 'Lolly_sticks/ThreeLollyStickTriangle':
      return {
        asset: LollySticksThreeLollyStickTriangle,

        width: 237,
        height: 207,
        aspectRatio: 1.144927536231884
      };
    case 'Lolly_sticks/TwoLollyStickPentagon':
      return {
        asset: LollySticksTwoLollyStickPentagon,

        width: 278,
        height: 213,
        aspectRatio: 1.3051643192488263
      };
    case 'Lolly_sticks/TwoLollyStickSquare':
      return {
        asset: LollySticksTwoLollyStickSquare,

        width: 222,
        height: 220,
        aspectRatio: 1.009090909090909
      };
    case 'Magazine':
      return {
        asset: Magazine,

        width: 333.86719,
        height: 443.33004,
        aspectRatio: 0.7530894815970512
      };
    case 'Marble':
      return {
        asset: Marble,

        width: 108.38267,
        height: 108.38133,
        aspectRatio: 1.000012363753056
      };
    case 'Marble_bag':
      return {
        asset: MarbleBag,

        width: 183.56933,
        height: 224.91643,
        aspectRatio: 0.8161668313871068
      };
    case 'Measure/pencil':
      return {
        asset: MeasurePencil,
        defaultWidth: 12,
        defaultHeight: 112,
        width: 12,
        height: 112,
        aspectRatio: 0.10714285714285714
      };
    case 'Metre_stick':
      return {
        asset: MetreStick,
        defaultWidth: 290,
        defaultHeight: 670,
        width: 290,
        height: 670,
        aspectRatio: 0.43283582089552236
      };
    case 'Microwave':
      return {
        asset: Microwave,

        width: 421.26681,
        height: 251.27052,
        aspectRatio: 1.6765468945581041
      };
    case 'Milk_Carton':
      return {
        asset: MilkCarton,

        width: 265.39718,
        height: 513.30426,
        aspectRatio: 0.5170367765893857
      };
    case 'Milk_carton_2l':
      return {
        asset: MilkCarton2l,

        width: 42.4048,
        height: 90.677202,
        aspectRatio: 0.46764566026199184
      };
    case 'Milk_carton_5l':
      return {
        asset: MilkCarton5l,

        width: 64.211731,
        height: 107.7676,
        aspectRatio: 0.5958352139232942
      };
    case 'Milkshake':
      return {
        asset: Milkshake,

        width: 342.76666,
        height: 829.36449,
        aspectRatio: 0.4132883239310137
      };
    case 'Mo_birds_eye':
      return {
        asset: MoBirdsEye,
        defaultWidth: 274,
        defaultHeight: 216,
        width: 274,
        height: 216,
        aspectRatio: 1.2685185185185186
      };
    case 'Money/Pence1':
      return {
        asset: MoneyPence1,

        width: 369.01864,
        height: 369.01864,
        aspectRatio: 1
      };
    case 'Money/Pence10':
      return {
        asset: MoneyPence10,

        width: 643.70398,
        height: 643.70398,
        aspectRatio: 1
      };
    case 'Money/Pence2':
      return {
        asset: MoneyPence2,

        width: 513.67199,
        height: 513.67199,
        aspectRatio: 1
      };
    case 'Money/Pence20':
      return {
        asset: MoneyPence20,

        width: 543.2818,
        height: 535.18365,
        aspectRatio: 1.0151315347544718
      };
    case 'Money/Pence5':
      return {
        asset: MoneyPence5,

        width: 423.33066,
        height: 423.33066,
        aspectRatio: 1
      };
    case 'Money/Pence50':
      return {
        asset: MoneyPence50,

        width: 598.80255,
        height: 589.31164,
        aspectRatio: 1.0161050781213146
      };
    case 'Money/Pounds1':
      return {
        asset: MoneyPounds1,

        width: 576.74548,
        height: 576.752,
        aspectRatio: 0.9999886953144508
      };
    case 'Money/Pounds2':
      return {
        asset: MoneyPounds2,

        width: 710.47198,
        height: 710.47197,
        aspectRatio: 1.0000000140751506
      };
    case 'Moon_white':
      return {
        asset: MoonWhite,

        width: 216.73003,
        height: 329.33331,
        aspectRatio: 0.6580871822531404
      };
    case 'Moon_yellow':
      return {
        asset: MoonYellow,

        width: 216.73003,
        height: 329.33331,
        aspectRatio: 0.6580871822531404
      };
    case 'Muffins_donuts/Muffins_on_a_rack_0':
      return {
        asset: MuffinsDonutsMuffinsOnARack0,

        width: 390.436,
        height: 403.02533,
        aspectRatio: 0.9687629311041069
      };
    case 'Muffins_donuts/Muffins_on_a_rack_1':
      return {
        asset: MuffinsDonutsMuffinsOnARack1,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_10':
      return {
        asset: MuffinsDonutsMuffinsOnARack10,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_11':
      return {
        asset: MuffinsDonutsMuffinsOnARack11,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_12':
      return {
        asset: MuffinsDonutsMuffinsOnARack12,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_2':
      return {
        asset: MuffinsDonutsMuffinsOnARack2,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_3':
      return {
        asset: MuffinsDonutsMuffinsOnARack3,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_4':
      return {
        asset: MuffinsDonutsMuffinsOnARack4,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_5':
      return {
        asset: MuffinsDonutsMuffinsOnARack5,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_6':
      return {
        asset: MuffinsDonutsMuffinsOnARack6,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_7':
      return {
        asset: MuffinsDonutsMuffinsOnARack7,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_8':
      return {
        asset: MuffinsDonutsMuffinsOnARack8,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_donuts/Muffins_on_a_rack_9':
      return {
        asset: MuffinsDonutsMuffinsOnARack9,

        width: 390.436,
        height: 456.06238,
        aspectRatio: 0.8561021849686439
      };
    case 'Muffins_individually/Muffin_1':
      return {
        asset: MuffinsIndividuallyMuffin1,

        width: 160.90387,
        height: 145.76453,
        aspectRatio: 1.103861618460952
      };
    case 'Muffins_individually/Muffin_2':
      return {
        asset: MuffinsIndividuallyMuffin2,

        width: 158.32358,
        height: 144.8588,
        aspectRatio: 1.092951066831977
      };
    case 'Muffins_individually/Muffin_3':
      return {
        asset: MuffinsIndividuallyMuffin3,

        width: 159.48323,
        height: 144.52907,
        aspectRatio: 1.1034681811762852
      };
    case 'Muffins_individually/Muffin_4':
      return {
        asset: MuffinsIndividuallyMuffin4,

        width: 159.33731,
        height: 146.67839,
        aspectRatio: 1.086303919752596
      };
    case 'Muffins_individually/Muffin_5':
      return {
        asset: MuffinsIndividuallyMuffin5,

        width: 156.06435,
        height: 139.00414,
        aspectRatio: 1.1227316682798079
      };
    case 'Muffins_individually/Muffin_6':
      return {
        asset: MuffinsIndividuallyMuffin6,

        width: 159.48323,
        height: 144.52907,
        aspectRatio: 1.1034681811762852
      };
    case 'Muffins_individually/Muffin_7':
      return {
        asset: MuffinsIndividuallyMuffin7,

        width: 152.02534,
        height: 136.06532,
        aspectRatio: 1.1172967512956276
      };
    case 'Mug':
      return {
        asset: Mug,

        width: 333.70135,
        height: 363.70135,
        aspectRatio: 0.9175147411468227
      };
    case 'Mug_yellow':
      return {
        asset: MugYellow,

        width: 278.30932,
        height: 303.23611,
        aspectRatio: 0.9177974219495165
      };
    case 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_blue':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledBlue,

        width: 199.75351,
        height: 175.54666,
        aspectRatio: 1.1378941074697748
      };
    case 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_green':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledGreen,

        width: 199.9135,
        height: 175.62732,
        aspectRatio: 1.1382824722258473
      };
    case 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_red':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledRed,

        width: 199.9135,
        height: 175.62732,
        aspectRatio: 1.1382824722258473
      };
    case 'Multi_link_cubes/Multilink_cube_individual_horizontal_labelled_yellow':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualHorizontalLabelledYellow,

        width: 199.9135,
        height: 175.62732,
        aspectRatio: 1.1382824722258473
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_blue':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalBlue,
        defaultWidth: 176,
        defaultHeight: 197,
        width: 176,
        height: 197,
        aspectRatio: 0.8934010152284264
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_green':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalGreen,
        defaultWidth: 170,
        defaultHeight: 191,
        width: 170,
        height: 191,
        aspectRatio: 0.8900523560209425
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_blue':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalLabelledBlue,

        width: 175.16535,
        height: 196.26318,
        aspectRatio: 0.8925023532177558
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_green':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalLabelledGreen,

        width: 169.82333,
        height: 190.24206,
        aspectRatio: 0.8926697387528288
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_red':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalLabelledRed,

        width: 175.32533,
        height: 196.42283,
        aspectRatio: 0.892591405998987
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_labelled_yellow':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalLabelledYellow,

        width: 175.32533,
        height: 196.42283,
        aspectRatio: 0.892591405998987
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_red':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalRed,
        defaultWidth: 176,
        defaultHeight: 197,
        width: 176,
        height: 197,
        aspectRatio: 0.8934010152284264
      };
    case 'Multi_link_cubes/Multilink_cube_individual_vertical_yellow':
      return {
        asset: MultiLinkCubesMultilinkCubeIndividualVerticalYellow,
        defaultWidth: 176,
        defaultHeight: 197,
        width: 176,
        height: 197,
        aspectRatio: 0.8934010152284264
      };
    case 'Multi_link_cubes/column_1_blue':
      return {
        asset: MultiLinkCubesColumn1Blue,

        width: 96.1,
        height: 104.4,
        aspectRatio: 0.9204980842911876
      };
    case 'Multi_link_cubes/column_1_green':
      return {
        asset: MultiLinkCubesColumn1Green,

        width: 96.1,
        height: 104.7,
        aspectRatio: 0.9178605539637057
      };
    case 'Multi_link_cubes/column_1_orange':
      return {
        asset: MultiLinkCubesColumn1Orange,

        width: 96.1,
        height: 104.5,
        aspectRatio: 0.9196172248803827
      };
    case 'Multi_link_cubes/column_1_purple':
      return {
        asset: MultiLinkCubesColumn1Purple,

        width: 96.1,
        height: 104.6,
        aspectRatio: 0.9187380497131931
      };
    case 'Multi_link_cubes/column_1_red':
      return {
        asset: MultiLinkCubesColumn1Red,

        width: 96.1,
        height: 104.5,
        aspectRatio: 0.9196172248803827
      };
    case 'Multi_link_cubes/column_2_blue':
      return {
        asset: MultiLinkCubesColumn2Blue,

        width: 96.1,
        height: 163.7,
        aspectRatio: 0.5870494807574832
      };
    case 'Multi_link_cubes/column_2_green':
      return {
        asset: MultiLinkCubesColumn2Green,

        width: 96.1,
        height: 163.6,
        aspectRatio: 0.5874083129584352
      };
    case 'Multi_link_cubes/column_2_orange':
      return {
        asset: MultiLinkCubesColumn2Orange,

        width: 96.1,
        height: 164.6,
        aspectRatio: 0.5838396111786148
      };
    case 'Multi_link_cubes/column_2_purple':
      return {
        asset: MultiLinkCubesColumn2Purple,

        width: 96.1,
        height: 163.2,
        aspectRatio: 0.5888480392156863
      };
    case 'Multi_link_cubes/column_2_red':
      return {
        asset: MultiLinkCubesColumn2Red,

        width: 96.1,
        height: 163.2,
        aspectRatio: 0.5888480392156863
      };
    case 'Multi_link_cubes/column_3_blue':
      return {
        asset: MultiLinkCubesColumn3Blue,

        width: 96.077,
        height: 221.795,
        aspectRatio: 0.4331792871796028
      };
    case 'Multi_link_cubes/column_3_green':
      return {
        asset: MultiLinkCubesColumn3Green,

        width: 96.077,
        height: 221.795,
        aspectRatio: 0.4331792871796028
      };
    case 'Multi_link_cubes/column_3_orange':
      return {
        asset: MultiLinkCubesColumn3Orange,

        width: 96.077,
        height: 224.462,
        aspectRatio: 0.4280323618251642
      };
    case 'Multi_link_cubes/column_3_purple':
      return {
        asset: MultiLinkCubesColumn3Purple,

        width: 96.077,
        height: 221.795,
        aspectRatio: 0.4331792871796028
      };
    case 'Multi_link_cubes/column_3_red':
      return {
        asset: MultiLinkCubesColumn3Red,

        width: 96.077,
        height: 221.795,
        aspectRatio: 0.4331792871796028
      };
    case 'Multi_link_cubes/column_4_blue':
      return {
        asset: MultiLinkCubesColumn4Blue,

        width: 96.077,
        height: 280.462,
        aspectRatio: 0.34256690745983415
      };
    case 'Multi_link_cubes/column_4_green':
      return {
        asset: MultiLinkCubesColumn4Green,

        width: 96.077,
        height: 280.462,
        aspectRatio: 0.34256690745983415
      };
    case 'Multi_link_cubes/column_4_orange':
      return {
        asset: MultiLinkCubesColumn4Orange,

        width: 96.077,
        height: 284.462,
        aspectRatio: 0.33774985762597465
      };
    case 'Multi_link_cubes/column_4_purple':
      return {
        asset: MultiLinkCubesColumn4Purple,

        width: 96.077,
        height: 280.462,
        aspectRatio: 0.34256690745983415
      };
    case 'Multi_link_cubes/column_4_red':
      return {
        asset: MultiLinkCubesColumn4Red,

        width: 96.077,
        height: 280.462,
        aspectRatio: 0.34256690745983415
      };
    case 'Multi_link_cubes/column_5_blue':
      return {
        asset: MultiLinkCubesColumn5Blue,

        width: 96.077,
        height: 339.129,
        aspectRatio: 0.283305172957783
      };
    case 'Multi_link_cubes/column_5_green':
      return {
        asset: MultiLinkCubesColumn5Green,

        width: 96.077,
        height: 339.129,
        aspectRatio: 0.283305172957783
      };
    case 'Multi_link_cubes/column_5_orange':
      return {
        asset: MultiLinkCubesColumn5Orange,

        width: 96.077,
        height: 344.462,
        aspectRatio: 0.2789190099343324
      };
    case 'Multi_link_cubes/column_5_purple':
      return {
        asset: MultiLinkCubesColumn5Purple,

        width: 96.077,
        height: 339.129,
        aspectRatio: 0.283305172957783
      };
    case 'Multi_link_cubes/column_5_red':
      return {
        asset: MultiLinkCubesColumn5Red,

        width: 96.077,
        height: 339.129,
        aspectRatio: 0.283305172957783
      };
    case 'Multi_link_cubes/column_6_blue':
      return {
        asset: MultiLinkCubesColumn6Blue,

        width: 96.077,
        height: 397.795,
        aspectRatio: 0.2415239005015146
      };
    case 'Multi_link_cubes/column_6_green':
      return {
        asset: MultiLinkCubesColumn6Green,

        width: 96.077,
        height: 397.795,
        aspectRatio: 0.2415239005015146
      };
    case 'Multi_link_cubes/column_6_orange':
      return {
        asset: MultiLinkCubesColumn6Orange,

        width: 96.077,
        height: 404.462,
        aspectRatio: 0.23754271105814637
      };
    case 'Multi_link_cubes/column_6_purple':
      return {
        asset: MultiLinkCubesColumn6Purple,

        width: 96.077,
        height: 397.795,
        aspectRatio: 0.2415239005015146
      };
    case 'Multi_link_cubes/column_6_red':
      return {
        asset: MultiLinkCubesColumn6Red,

        width: 96.077,
        height: 397.795,
        aspectRatio: 0.2415239005015146
      };
    case 'Multi_link_cubes/column_7_blue':
      return {
        asset: MultiLinkCubesColumn7Blue,

        width: 96.077,
        height: 456.462,
        aspectRatio: 0.21048192401558072
      };
    case 'Multi_link_cubes/column_7_green':
      return {
        asset: MultiLinkCubesColumn7Green,

        width: 96.077,
        height: 456.462,
        aspectRatio: 0.21048192401558072
      };
    case 'Multi_link_cubes/column_7_orange':
      return {
        asset: MultiLinkCubesColumn7Orange,

        width: 96.077,
        height: 464.462,
        aspectRatio: 0.20685653508790816
      };
    case 'Multi_link_cubes/column_7_purple':
      return {
        asset: MultiLinkCubesColumn7Purple,

        width: 96.077,
        height: 456.462,
        aspectRatio: 0.21048192401558072
      };
    case 'Multi_link_cubes/column_7_red':
      return {
        asset: MultiLinkCubesColumn7Red,

        width: 96.077,
        height: 456.462,
        aspectRatio: 0.21048192401558072
      };
    case 'Multi_link_cubes/column_8_blue':
      return {
        asset: MultiLinkCubesColumn8Blue,

        width: 96.077,
        height: 515.129,
        aspectRatio: 0.18651056337344626
      };
    case 'Multi_link_cubes/column_8_green':
      return {
        asset: MultiLinkCubesColumn8Green,

        width: 96.077,
        height: 515.129,
        aspectRatio: 0.18651056337344626
      };
    case 'Multi_link_cubes/column_8_orange':
      return {
        asset: MultiLinkCubesColumn8Orange,

        width: 96.077,
        height: 524.462,
        aspectRatio: 0.1831915372324401
      };
    case 'Multi_link_cubes/column_8_purple':
      return {
        asset: MultiLinkCubesColumn8Purple,

        width: 96.077,
        height: 515.129,
        aspectRatio: 0.18651056337344626
      };
    case 'Multi_link_cubes/column_8_red':
      return {
        asset: MultiLinkCubesColumn8Red,

        width: 96.077,
        height: 515.129,
        aspectRatio: 0.18651056337344626
      };
    case 'Multi_link_cubes/front_column_blue':
      return {
        asset: MultiLinkCubesFrontColumnBlue,

        width: 108,
        height: 115,
        aspectRatio: 0.9391304347826087
      };
    case 'Multi_link_cubes/front_column_green':
      return {
        asset: MultiLinkCubesFrontColumnGreen,

        width: 108,
        height: 115,
        aspectRatio: 0.9391304347826087
      };
    case 'Multi_link_cubes/front_column_orange':
      return {
        asset: MultiLinkCubesFrontColumnOrange,

        width: 108,
        height: 115,
        aspectRatio: 0.9391304347826087
      };
    case 'Multi_link_cubes/front_column_purple':
      return {
        asset: MultiLinkCubesFrontColumnPurple,

        width: 108,
        height: 115,
        aspectRatio: 0.9391304347826087
      };
    case 'Multi_link_cubes/front_column_red':
      return {
        asset: MultiLinkCubesFrontColumnRed,

        width: 108,
        height: 115,
        aspectRatio: 0.9391304347826087
      };
    case 'Multi_link_cubes/front_column_yellow':
      return {
        asset: MultiLinkCubesFrontColumnYellow,
        defaultWidth: 108,
        defaultHeight: 115,
        width: 108,
        height: 115,
        aspectRatio: 0.9391304347826087
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids1':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids1,

        width: 328.64266,
        height: 299.95999,
        aspectRatio: 1.0956216527410871
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids10':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids10,

        width: 215.79226,
        height: 187.52946,
        aspectRatio: 1.1507112535811705
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids11':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids11,

        width: 174.03453,
        height: 151.08012,
        aspectRatio: 1.151935343975104
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids12':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids12,

        width: 775.25315,
        height: 187.20172,
        aspectRatio: 4.1412715118215795
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids13':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids13,

        width: 440.3037,
        height: 523.06293,
        aspectRatio: 0.8417795923714188
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids2':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids2,

        width: 397.54879,
        height: 150.68213,
        aspectRatio: 2.6383273849394087
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids3':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids3,

        width: 481.61745,
        height: 448.07092,
        aspectRatio: 1.0748687953237404
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids4':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids4,

        width: 286.22277,
        height: 599.71881,
        aspectRatio: 0.47726161865758393
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids5':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids5,

        width: 327.29305,
        height: 411.45667,
        aspectRatio: 0.7954496156302436
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids6':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids6,

        width: 551.45555,
        height: 187.23692,
        aspectRatio: 2.945228697417155
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids7':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids7,

        width: 174.03453,
        height: 486.3844,
        aspectRatio: 0.3578127300135448
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids8':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids8,

        width: 655,
        height: 617,
        aspectRatio: 1.0615883306320908
      };
    case 'Multilink_shapes/Multilink_cubes_and_cuboids9':
      return {
        asset: MultilinkShapesMultilinkCubesAndCuboids9,

        width: 525.44707,
        height: 372.6636,
        aspectRatio: 1.4099769067867107
      };
    case 'Multilink_shapes/Multilink_shape1_10cubes':
      return {
        asset: MultilinkShapesMultilinkShape110cubes,

        width: 397.73824,
        height: 487.00677,
        aspectRatio: 0.8166996117938976
      };
    case 'Multilink_shapes/Multilink_shape1_12cubes':
      return {
        asset: MultilinkShapesMultilinkShape112cubes,

        width: 509.46611,
        height: 487.00677,
        aspectRatio: 1.0461171001791207
      };
    case 'Multilink_shapes/Multilink_shape1_4cubes':
      return {
        asset: MultilinkShapesMultilinkShape14cubes,

        width: 285.7625,
        height: 375.03119,
        aspectRatio: 0.7619699577520472
      };
    case 'Multilink_shapes/Multilink_shape1_6cubes':
      return {
        asset: MultilinkShapesMultilinkShape16cubes,

        width: 285.7625,
        height: 487.00677,
        aspectRatio: 0.5867731571780819
      };
    case 'Multilink_shapes/Multilink_shape1_8cubes':
      return {
        asset: MultilinkShapesMultilinkShape18cubes,

        width: 285.7625,
        height: 599.38025,
        aspectRatio: 0.47676329008171353
      };
    case 'Multilink_shapes/Multilink_shape2_10cubes':
      return {
        asset: MultilinkShapesMultilinkShape210cubes,

        width: 621.44169,
        height: 375.03119,
        aspectRatio: 1.6570400184581981
      };
    case 'Multilink_shapes/Multilink_shape2_12cubes':
      return {
        asset: MultilinkShapesMultilinkShape212cubes,

        width: 621.44169,
        height: 375.03119,
        aspectRatio: 1.6570400184581981
      };
    case 'Multilink_shapes/Multilink_shape2_4cubes':
      return {
        asset: MultilinkShapesMultilinkShape24cubes,

        width: 397.73824,
        height: 262.65771,
        aspectRatio: 1.5142835137030624
      };
    case 'Multilink_shapes/Multilink_shape2_7cubes':
      return {
        asset: MultilinkShapesMultilinkShape27cubes,

        width: 509.46611,
        height: 262.65771,
        aspectRatio: 1.9396579297063086
      };
    case 'Multilink_shapes/Multilink_shape2_8cubes':
      return {
        asset: MultilinkShapesMultilinkShape28cubes,

        width: 621.44169,
        height: 262.65771,
        aspectRatio: 2.365975436243619
      };
    case 'Multilink_shapes/Multilink_shape3_10cubes':
      return {
        asset: MultilinkShapesMultilinkShape310cubes,

        width: 397.73824,
        height: 599.38025,
        aspectRatio: 0.6635824920824468
      };
    case 'Multilink_shapes/Multilink_shape3_12cubes':
      return {
        asset: MultilinkShapesMultilinkShape312cubes,

        width: 397.73824,
        height: 599.38025,
        aspectRatio: 0.6635824920824468
      };
    case 'Multilink_shapes/Multilink_shape3_4cubes':
      return {
        asset: MultilinkShapesMultilinkShape34cubes,

        width: 285.7625,
        height: 375.03119,
        aspectRatio: 0.7619699577520472
      };
    case 'Multilink_shapes/Multilink_shape3_6cubes':
      return {
        asset: MultilinkShapesMultilinkShape36cubes,

        width: 285.7625,
        height: 599.38025,
        aspectRatio: 0.47676329008171353
      };
    case 'Multilink_shapes/Multilink_shape3_8cubes':
      return {
        asset: MultilinkShapesMultilinkShape38cubes,

        width: 397.73824,
        height: 487.00677,
        aspectRatio: 0.8166996117938976
      };
    case 'Nonagons/Irregular_nonagon_blue':
      return {
        asset: NonagonsIrregularNonagonBlue,

        width: 403.70931,
        height: 404.1333,
        aspectRatio: 0.9989508659642746
      };
    case 'Nonagons/Irregular_nonagon_green':
      return {
        asset: NonagonsIrregularNonagonGreen,

        width: 403.70931,
        height: 404.1333,
        aspectRatio: 0.9989508659642746
      };
    case 'Nonagons/Irregular_nonagon_pink':
      return {
        asset: NonagonsIrregularNonagonPink,

        width: 403.70931,
        height: 404.1333,
        aspectRatio: 0.9989508659642746
      };
    case 'Nonagons/Irregular_nonagon_purple':
      return {
        asset: NonagonsIrregularNonagonPurple,

        width: 403.70931,
        height: 404.1333,
        aspectRatio: 0.9989508659642746
      };
    case 'Nonagons/Irregular_nonagon_white':
      return {
        asset: NonagonsIrregularNonagonWhite,

        width: 403.70931,
        height: 404.1333,
        aspectRatio: 0.9989508659642746
      };
    case 'Nonagons/Irregular_nonagon_yellow':
      return {
        asset: NonagonsIrregularNonagonYellow,

        width: 403.70931,
        height: 404.1333,
        aspectRatio: 0.9989508659642746
      };
    case 'Nonagons/Regular_nonagon_1_arrow':
      return {
        asset: NonagonsRegularNonagon1Arrow,
        defaultWidth: 521,
        defaultHeight: 421,
        width: 521,
        height: 421,
        aspectRatio: 1.2375296912114013
      };
    case 'Nonagons/Regular_nonagon_1_arrow_blue':
      return {
        asset: NonagonsRegularNonagon1ArrowBlue,
        defaultWidth: 521,
        defaultHeight: 421,
        width: 521,
        height: 421,
        aspectRatio: 1.2375296912114013
      };
    case 'Nonagons/Regular_nonagon_1_arrow_green':
      return {
        asset: NonagonsRegularNonagon1ArrowGreen,
        defaultWidth: 521,
        defaultHeight: 421,
        width: 521,
        height: 421,
        aspectRatio: 1.2375296912114013
      };
    case 'Nonagons/Regular_nonagon_1_arrow_pink':
      return {
        asset: NonagonsRegularNonagon1ArrowPink,
        defaultWidth: 521,
        defaultHeight: 421,
        width: 521,
        height: 421,
        aspectRatio: 1.2375296912114013
      };
    case 'Nonagons/Regular_nonagon_1_arrow_purple':
      return {
        asset: NonagonsRegularNonagon1ArrowPurple,
        defaultWidth: 521,
        defaultHeight: 421,
        width: 521,
        height: 421,
        aspectRatio: 1.2375296912114013
      };
    case 'Nonagons/Regular_nonagon_1_arrow_yellow':
      return {
        asset: NonagonsRegularNonagon1ArrowYellow,
        defaultWidth: 521,
        defaultHeight: 421,
        width: 521,
        height: 421,
        aspectRatio: 1.2375296912114013
      };
    case 'Nonagons/Regular_nonagon_blue':
      return {
        asset: NonagonsRegularNonagonBlue,

        width: 425.0533,
        height: 418.71597,
        aspectRatio: 1.0151351523563812
      };
    case 'Nonagons/Regular_nonagon_green':
      return {
        asset: NonagonsRegularNonagonGreen,

        width: 425.0533,
        height: 418.71597,
        aspectRatio: 1.0151351523563812
      };
    case 'Nonagons/Regular_nonagon_pink':
      return {
        asset: NonagonsRegularNonagonPink,

        width: 425.0533,
        height: 418.71597,
        aspectRatio: 1.0151351523563812
      };
    case 'Nonagons/Regular_nonagon_purple':
      return {
        asset: NonagonsRegularNonagonPurple,

        width: 425.0533,
        height: 418.71597,
        aspectRatio: 1.0151351523563812
      };
    case 'Nonagons/Regular_nonagon_white':
      return {
        asset: NonagonsRegularNonagonWhite,

        width: 425.0533,
        height: 418.71597,
        aspectRatio: 1.0151351523563812
      };
    case 'Nonagons/Regular_nonagon_yellow':
      return {
        asset: NonagonsRegularNonagonYellow,

        width: 425.0533,
        height: 418.71597,
        aspectRatio: 1.0151351523563812
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_1':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene1,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_10':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene10,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_11':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene11,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_12':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene12,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_13':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene13,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_14':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene14,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_15':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene15,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_16':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene16,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_17':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene17,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_18':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene18,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_19':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene19,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_2':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene2,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_20':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene20,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_21':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene21,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_22':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene22,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_23':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene23,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_24':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene24,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_3':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene3,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_4':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene4,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_5':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene5,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_6':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene6,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_7':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene7,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_8':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene8,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Ocean_Scene_depiciting_depths/Ocean_Scene_9':
      return {
        asset: OceanSceneDepicitingDepthsOceanScene9,

        width: 513.01214,
        height: 329.64276,
        aspectRatio: 1.5562669721610147
      };
    case 'Octagons/Irregular_octagon1_blue':
      return {
        asset: OctagonsIrregularOctagon1Blue,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_green':
      return {
        asset: OctagonsIrregularOctagon1Green,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_orange':
      return {
        asset: OctagonsIrregularOctagon1Orange,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_pink':
      return {
        asset: OctagonsIrregularOctagon1Pink,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_purple':
      return {
        asset: OctagonsIrregularOctagon1Purple,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_red':
      return {
        asset: OctagonsIrregularOctagon1Red,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_turquoise':
      return {
        asset: OctagonsIrregularOctagon1Turquoise,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_white':
      return {
        asset: OctagonsIrregularOctagon1White,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon1_yellow':
      return {
        asset: OctagonsIrregularOctagon1Yellow,

        width: 998.50662,
        height: 472.94,
        aspectRatio: 2.1112754683469364
      };
    case 'Octagons/Irregular_octagon2_blue':
      return {
        asset: OctagonsIrregularOctagon2Blue,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_green':
      return {
        asset: OctagonsIrregularOctagon2Green,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_orange':
      return {
        asset: OctagonsIrregularOctagon2Orange,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_pink':
      return {
        asset: OctagonsIrregularOctagon2Pink,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_purple':
      return {
        asset: OctagonsIrregularOctagon2Purple,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_red':
      return {
        asset: OctagonsIrregularOctagon2Red,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_turquoise':
      return {
        asset: OctagonsIrregularOctagon2Turquoise,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_white':
      return {
        asset: OctagonsIrregularOctagon2White,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon2_yellow':
      return {
        asset: OctagonsIrregularOctagon2Yellow,

        width: 379.47332,
        height: 460.31863,
        aspectRatio: 0.8243709797276726
      };
    case 'Octagons/Irregular_octagon3_blue':
      return {
        asset: OctagonsIrregularOctagon3Blue,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_green':
      return {
        asset: OctagonsIrregularOctagon3Green,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_orange':
      return {
        asset: OctagonsIrregularOctagon3Orange,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_pink':
      return {
        asset: OctagonsIrregularOctagon3Pink,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_purple':
      return {
        asset: OctagonsIrregularOctagon3Purple,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_red':
      return {
        asset: OctagonsIrregularOctagon3Red,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_turquoise':
      return {
        asset: OctagonsIrregularOctagon3Turquoise,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_white':
      return {
        asset: OctagonsIrregularOctagon3White,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Irregular_octagon3_yellow':
      return {
        asset: OctagonsIrregularOctagon3Yellow,

        width: 437.79332,
        height: 407.9465,
        aspectRatio: 1.0731635643399318
      };
    case 'Octagons/Octagon_blue':
      return {
        asset: OctagonsOctagonBlue,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_green':
      return {
        asset: OctagonsOctagonGreen,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_orange':
      return {
        asset: OctagonsOctagonOrange,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_pink':
      return {
        asset: OctagonsOctagonPink,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_purple':
      return {
        asset: OctagonsOctagonPurple,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_red':
      return {
        asset: OctagonsOctagonRed,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_turquoise':
      return {
        asset: OctagonsOctagonTurquoise,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Octagon_yellow':
      return {
        asset: OctagonsOctagonYellow,

        width: 420.625,
        height: 420.62531,
        aspectRatio: 0.999999263002029
      };
    case 'Octagons/Regular_octagon_1_arrow':
      return {
        asset: OctagonsRegularOctagon1Arrow,
        defaultWidth: 451,
        defaultHeight: 421,
        width: 451,
        height: 421,
        aspectRatio: 1.0712589073634204
      };
    case 'Octagons/Regular_octagon_1_arrow_blue':
      return {
        asset: OctagonsRegularOctagon1ArrowBlue,
        defaultWidth: 451,
        defaultHeight: 421,
        width: 451,
        height: 421,
        aspectRatio: 1.0712589073634204
      };
    case 'Octagons/Regular_octagon_1_arrow_green':
      return {
        asset: OctagonsRegularOctagon1ArrowGreen,
        defaultWidth: 451,
        defaultHeight: 421,
        width: 451,
        height: 421,
        aspectRatio: 1.0712589073634204
      };
    case 'Octagons/Regular_octagon_1_arrow_pink':
      return {
        asset: OctagonsRegularOctagon1ArrowPink,
        defaultWidth: 451,
        defaultHeight: 421,
        width: 451,
        height: 421,
        aspectRatio: 1.0712589073634204
      };
    case 'Octagons/Regular_octagon_1_arrow_purple':
      return {
        asset: OctagonsRegularOctagon1ArrowPurple,
        defaultWidth: 451,
        defaultHeight: 421,
        width: 451,
        height: 421,
        aspectRatio: 1.0712589073634204
      };
    case 'Octagons/Regular_octagon_1_arrow_yellow':
      return {
        asset: OctagonsRegularOctagon1ArrowYellow,
        defaultWidth: 451,
        defaultHeight: 421,
        width: 451,
        height: 421,
        aspectRatio: 1.0712589073634204
      };
    case 'Orange_juice_carton':
      return {
        asset: OrangeJuiceCarton,

        width: 275.39651,
        height: 397.56314,
        aspectRatio: 0.6927113766130331
      };
    case 'Orange_juice_carton_blank':
      return {
        asset: OrangeJuiceCartonBlank,

        width: 275.39651,
        height: 397.56314,
        aspectRatio: 0.6927113766130331
      };
    case 'Other_shapes/4_angles_around_a_point':
      return {
        asset: OtherShapes4AnglesAroundAPoint,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/5_angles_around_a_point':
      return {
        asset: OtherShapes5AnglesAroundAPoint,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/6_angles_around_a_point':
      return {
        asset: OtherShapes6AnglesAroundAPoint,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/7_angles_around_a_point':
      return {
        asset: OtherShapes7AnglesAroundAPoint,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/Angles_on_a_straight_line':
      return {
        asset: OtherShapesAnglesOnAStraightLine,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/Anti_clockwise_shaded_diagram_full_turn':
      return {
        asset: OtherShapesAntiClockwiseShadedDiagramFullTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Anti_clockwise_shaded_diagram_half_turn':
      return {
        asset: OtherShapesAntiClockwiseShadedDiagramHalfTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Anti_clockwise_shaded_diagram_quarter_turn':
      return {
        asset: OtherShapesAntiClockwiseShadedDiagramQuarterTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Anti_clockwise_shaded_diagram_three_quarter_turn':
      return {
        asset: OtherShapesAntiClockwiseShadedDiagramThreeQuarterTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Clockwise_shaded_diagram_full_turn':
      return {
        asset: OtherShapesClockwiseShadedDiagramFullTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Clockwise_shaded_diagram_half_turn':
      return {
        asset: OtherShapesClockwiseShadedDiagramHalfTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Clockwise_shaded_diagram_quarter_turn':
      return {
        asset: OtherShapesClockwiseShadedDiagramQuarterTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Clockwise_shaded_diagram_three_quarter_turn':
      return {
        asset: OtherShapesClockwiseShadedDiagramThreeQuarterTurn,

        width: 519.38396,
        height: 519.38397,
        aspectRatio: 0.9999999807464216
      };
    case 'Other_shapes/Coloured_arrow1':
      return {
        asset: OtherShapesColouredArrow1,

        width: 220.34365,
        height: 280.69995,
        aspectRatio: 0.7849792990700568
      };
    case 'Other_shapes/Coloured_arrow2':
      return {
        asset: OtherShapesColouredArrow2,

        width: 280.69994,
        height: 220.34366,
        aspectRatio: 1.2739188411411522
      };
    case 'Other_shapes/Coloured_arrow3':
      return {
        asset: OtherShapesColouredArrow3,

        width: 220.34365,
        height: 280.69995,
        aspectRatio: 0.7849792990700568
      };
    case 'Other_shapes/Coloured_arrow4':
      return {
        asset: OtherShapesColouredArrow4,

        width: 280.69994,
        height: 220.34366,
        aspectRatio: 1.2739188411411522
      };
    case 'Other_shapes/Cones_dotted_lines':
      return {
        asset: OtherShapesConesDottedLines,

        width: 590.14903,
        height: 628.5412,
        aspectRatio: 0.9389186102677121
      };
    case 'Other_shapes/Cube1':
      return {
        asset: OtherShapesCube1,

        width: 273.04266,
        height: 315.35007,
        aspectRatio: 0.8658398585419689
      };
    case 'Other_shapes/Cube3':
      return {
        asset: OtherShapesCube3,

        width: 354.78451,
        height: 323.90326,
        aspectRatio: 1.095340966929447
      };
    case 'Other_shapes/Cube4':
      return {
        asset: OtherShapesCube4,

        width: 273.04266,
        height: 315.35007,
        aspectRatio: 0.8658398585419689
      };
    case 'Other_shapes/Cube_dottedlines1':
      return {
        asset: OtherShapesCubeDottedlines1,

        width: 288.36092,
        height: 316.7305,
        aspectRatio: 0.9104299080764247
      };
    case 'Other_shapes/Cube_dottedlines2':
      return {
        asset: OtherShapesCubeDottedlines2,

        width: 469.96971,
        height: 516.33008,
        aspectRatio: 0.9102117583387744
      };
    case 'Other_shapes/Cuboid_dottedlines1':
      return {
        asset: OtherShapesCuboidDottedlines1,

        width: 259.92797,
        height: 437.60199,
        aspectRatio: 0.5939826050608226
      };
    case 'Other_shapes/Cuboid_dottedlines2':
      return {
        asset: OtherShapesCuboidDottedlines2,

        width: 469.96971,
        height: 792.33008,
        aspectRatio: 0.5931488931986528
      };
    case 'Other_shapes/Cuboid_dottedlines3':
      return {
        asset: OtherShapesCuboidDottedlines3,

        width: 567.62608,
        height: 544.10571,
        aspectRatio: 1.043227574288827
      };
    case 'Other_shapes/Cylinder1':
      return {
        asset: OtherShapesCylinder1,

        width: 82.964531,
        height: 125.63333,
        aspectRatio: 0.6603703889724167
      };
    case 'Other_shapes/Cylinder3':
      return {
        asset: OtherShapesCylinder3,

        width: 82.964531,
        height: 127.39333,
        aspectRatio: 0.651247055085223
      };
    case 'Other_shapes/Cylinder_dotted_lines1':
      return {
        asset: OtherShapesCylinderDottedLines1,

        width: 588.61461,
        height: 726.60529,
        aspectRatio: 0.8100885282572055
      };
    case 'Other_shapes/Cylinder_dotted_lines2':
      return {
        asset: OtherShapesCylinderDottedLines2,

        width: 355.68026,
        height: 776.00934,
        aspectRatio: 0.45834533383322423
      };
    case 'Other_shapes/Decagon_non_example1':
      return {
        asset: OtherShapesDecagonNonExample1,

        width: 520.09599,
        height: 520.09467,
        aspectRatio: 1.0000025379994764
      };
    case 'Other_shapes/Decagon_non_example2':
      return {
        asset: OtherShapesDecagonNonExample2,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Other_shapes/Decagon_non_example3':
      return {
        asset: OtherShapesDecagonNonExample3,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Other_shapes/Equilateraltriangle_markedlines':
      return {
        asset: OtherShapesEquilateraltriangleMarkedlines,

        width: 472.28554,
        height: 517.86932,
        aspectRatio: 0.9119782187521748
      };
    case 'Other_shapes/Heptagon_non_example1':
      return {
        asset: OtherShapesHeptagonNonExample1,

        width: 533.41875,
        height: 520.09479,
        aspectRatio: 1.0256183300740238
      };
    case 'Other_shapes/Heptagon_non_example2':
      return {
        asset: OtherShapesHeptagonNonExample2,

        width: 533.41838,
        height: 520.09497,
        aspectRatio: 1.0256172637085876
      };
    case 'Other_shapes/Hexagon_non_example1':
      return {
        asset: OtherShapesHexagonNonExample1,

        width: 597.093,
        height: 517.63385,
        aspectRatio: 1.1535045476643382
      };
    case 'Other_shapes/Hexagon_non_example2':
      return {
        asset: OtherShapesHexagonNonExample2,

        width: 582.71544,
        height: 515.6333,
        aspectRatio: 1.1300966015965221
      };
    case 'Other_shapes/Hexagon_non_example4':
      return {
        asset: OtherShapesHexagonNonExample4,

        width: 594.29722,
        height: 515.6333,
        aspectRatio: 1.1525578739774953
      };
    case 'Other_shapes/Hexagonal_prism1':
      return {
        asset: OtherShapesHexagonalPrism1,

        width: 243.9223,
        height: 339.24252,
        aspectRatio: 0.7190204223220603
      };
    case 'Other_shapes/Hexagonal_prism2':
      return {
        asset: OtherShapesHexagonalPrism2,

        width: 243.9223,
        height: 275.81558,
        aspectRatio: 0.8843673733006671
      };
    case 'Other_shapes/Hexagonalprism_dottedlines1':
      return {
        asset: OtherShapesHexagonalprismDottedlines1,

        width: 707.835,
        height: 521.51947,
        aspectRatio: 1.3572551759189357
      };
    case 'Other_shapes/Hexagonalprism_dottedlines2':
      return {
        asset: OtherShapesHexagonalprismDottedlines2,

        width: 395.55266,
        height: 682.65796,
        aspectRatio: 0.5794302317957297
      };
    case 'Other_shapes/Hexagonalpyramid_dottedlines1':
      return {
        asset: OtherShapesHexagonalpyramidDottedlines1,

        width: 395.04381,
        height: 390.67725,
        aspectRatio: 1.0111768985780463
      };
    case 'Other_shapes/Interior_angles_card':
      return {
        asset: OtherShapesInteriorAnglesCard,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/Irregular_decagon1':
      return {
        asset: OtherShapesIrregularDecagon1,

        width: 361.15679,
        height: 418.19717,
        aspectRatio: 0.8636040985164963
      };
    case 'Other_shapes/Irregular_decagon2':
      return {
        asset: OtherShapesIrregularDecagon2,

        width: 456.56594,
        height: 514.61877,
        aspectRatio: 0.8871925522654371
      };
    case 'Other_shapes/Irregular_decagon2_blue':
      return {
        asset: OtherShapesIrregularDecagon2Blue,

        width: 456.56594,
        height: 514.61877,
        aspectRatio: 0.8871925522654371
      };
    case 'Other_shapes/Irregular_decagon2_green':
      return {
        asset: OtherShapesIrregularDecagon2Green,

        width: 456.56594,
        height: 514.61877,
        aspectRatio: 0.8871925522654371
      };
    case 'Other_shapes/Irregular_decagon2_pink':
      return {
        asset: OtherShapesIrregularDecagon2Pink,

        width: 456.56594,
        height: 514.61877,
        aspectRatio: 0.8871925522654371
      };
    case 'Other_shapes/Irregular_decagon2_purple':
      return {
        asset: OtherShapesIrregularDecagon2Purple,

        width: 456.56594,
        height: 514.61877,
        aspectRatio: 0.8871925522654371
      };
    case 'Other_shapes/Irregular_decagon2_yellow':
      return {
        asset: OtherShapesIrregularDecagon2Yellow,

        width: 456.56594,
        height: 514.61877,
        aspectRatio: 0.8871925522654371
      };
    case 'Other_shapes/Irregular_heptagon':
      return {
        asset: OtherShapesIrregularHeptagon,

        width: 502.57454,
        height: 490.02399,
        aspectRatio: 1.0256121133987746
      };
    case 'Other_shapes/Irregular_heptagon_blue':
      return {
        asset: OtherShapesIrregularHeptagonBlue,

        width: 502.57454,
        height: 490.02399,
        aspectRatio: 1.0256121133987746
      };
    case 'Other_shapes/Irregular_heptagon_green':
      return {
        asset: OtherShapesIrregularHeptagonGreen,

        width: 502.57454,
        height: 490.02399,
        aspectRatio: 1.0256121133987746
      };
    case 'Other_shapes/Irregular_heptagon_pink':
      return {
        asset: OtherShapesIrregularHeptagonPink,

        width: 502.57454,
        height: 490.02399,
        aspectRatio: 1.0256121133987746
      };
    case 'Other_shapes/Irregular_heptagon_purple':
      return {
        asset: OtherShapesIrregularHeptagonPurple,

        width: 502.57454,
        height: 490.02399,
        aspectRatio: 1.0256121133987746
      };
    case 'Other_shapes/Irregular_heptagon_yellow':
      return {
        asset: OtherShapesIrregularHeptagonYellow,

        width: 502.57454,
        height: 490.02399,
        aspectRatio: 1.0256121133987746
      };
    case 'Other_shapes/Isosceles_triangle_angles_card':
      return {
        asset: OtherShapesIsoscelesTriangleAnglesCard,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/Nonagon_non_example1':
      return {
        asset: OtherShapesNonagonNonExample1,

        width: 522.8936,
        height: 518.40662,
        aspectRatio: 1.0086553292857257
      };
    case 'Other_shapes/Nonagon_non_example2':
      return {
        asset: OtherShapesNonagonNonExample2,

        width: 524.89519,
        height: 520.05414,
        aspectRatio: 1.009308742355171
      };
    case 'Other_shapes/Nonagon_non_example3':
      return {
        asset: OtherShapesNonagonNonExample3,

        width: 525.42229,
        height: 520.09564,
        aspectRatio: 1.0102416740121105
      };
    case 'Other_shapes/Octagon_non_example1':
      return {
        asset: OtherShapesOctagonNonExample1,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Other_shapes/Octagon_non_example2':
      return {
        asset: OtherShapesOctagonNonExample2,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Other_shapes/Octagon_non_example3':
      return {
        asset: OtherShapesOctagonNonExample3,

        width: 520.09465,
        height: 520.09601,
        aspectRatio: 0.999997385098186
      };
    case 'Other_shapes/Parallelogram1_marked_lines_and_angles':
      return {
        asset: OtherShapesParallelogram1MarkedLinesAndAngles,

        width: 647.73967,
        height: 357.79022,
        aspectRatio: 1.8103895349626944
      };
    case 'Other_shapes/Parallelogram2_marked_lines_and_angles':
      return {
        asset: OtherShapesParallelogram2MarkedLinesAndAngles,

        width: 671.59721,
        height: 358.94263,
        aspectRatio: 1.8710433196525027
      };
    case 'Other_shapes/Parallelogram3_marked_lines_and_angles':
      return {
        asset: OtherShapesParallelogram3MarkedLinesAndAngles,

        width: 671.59721,
        height: 358.77151,
        aspectRatio: 1.8719357342504706
      };
    case 'Other_shapes/Parallelogram4_marked_lines_and_angles':
      return {
        asset: OtherShapesParallelogram4MarkedLinesAndAngles,

        width: 642.74626,
        height: 359.8085,
        aspectRatio: 1.7863565202044978
      };
    case 'Other_shapes/Pentagon_non_example1':
      return {
        asset: OtherShapesPentagonNonExample1,

        width: 529.11473,
        height: 507.22525,
        aspectRatio: 1.0431553437057797
      };
    case 'Other_shapes/Pentagon_non_example2':
      return {
        asset: OtherShapesPentagonNonExample2,

        width: 543.15617,
        height: 516.67078,
        aspectRatio: 1.0512616370525152
      };
    case 'Other_shapes/Pentagon_non_example3':
      return {
        asset: OtherShapesPentagonNonExample3,

        width: 543.15569,
        height: 516.66998,
        aspectRatio: 1.0512623357757307
      };
    case 'Other_shapes/Pentagon_non_example4':
      return {
        asset: OtherShapesPentagonNonExample4,

        width: 543.15727,
        height: 553.80664,
        aspectRatio: 0.9807705989223965
      };
    case 'Other_shapes/Pentagon_non_example5':
      return {
        asset: OtherShapesPentagonNonExample5,

        width: 543.15593,
        height: 515.0202,
        aspectRatio: 1.0546303426545212
      };
    case 'Other_shapes/Pentagon_regular_marked_lines':
      return {
        asset: OtherShapesPentagonRegularMarkedLines,

        width: 549.48528,
        height: 547.02478,
        aspectRatio: 1.0044979680810804
      };
    case 'Other_shapes/Pentagonalprism_dottedlines1':
      return {
        asset: OtherShapesPentagonalprismDottedlines1,

        width: 664.13047,
        height: 612.82178,
        aspectRatio: 1.083725304280145
      };
    case 'Other_shapes/Pentagonalprism_dottedlines2':
      return {
        asset: OtherShapesPentagonalprismDottedlines2,

        width: 504.99782,
        height: 654.38629,
        aspectRatio: 0.7717121029537461
      };
    case 'Other_shapes/Rectangle1_marked_lines_and_angles':
      return {
        asset: OtherShapesRectangle1MarkedLinesAndAngles,

        width: 647.78984,
        height: 359.14426,
        aspectRatio: 1.803703726185127
      };
    case 'Other_shapes/Rectangle2_marked_lines_and_angles':
      return {
        asset: OtherShapesRectangle2MarkedLinesAndAngles,

        width: 429.6429,
        height: 647.78986,
        aspectRatio: 0.6632442502264546
      };
    case 'Other_shapes/Rectangle3_marked_lines_and_angles':
      return {
        asset: OtherShapesRectangle3MarkedLinesAndAngles,

        width: 701.9799,
        height: 721.88788,
        aspectRatio: 0.972422337939792
      };
    case 'Other_shapes/Rectangle4_marked_lines_and_angles':
      return {
        asset: OtherShapesRectangle4MarkedLinesAndAngles,

        width: 1174.3193,
        height: 359.14426,
        aspectRatio: 3.2697704816443403
      };
    case 'Other_shapes/Regular_decagon':
      return {
        asset: OtherShapesRegularDecagon,

        width: 447.30781,
        height: 425.41464,
        aspectRatio: 1.0514631325334738
      };
    case 'Other_shapes/Regular_decagon1_marked_lines':
      return {
        asset: OtherShapesRegularDecagon1MarkedLines,

        width: 445.08104,
        height: 463.86856,
        aspectRatio: 0.9594981819850001
      };
    case 'Other_shapes/Regular_decagon2_marked_lines':
      return {
        asset: OtherShapesRegularDecagon2MarkedLines,

        width: 438.2769,
        height: 416.92361,
        aspectRatio: 1.0512163127437182
      };
    case 'Other_shapes/Regular_decagon_1_arrow':
      return {
        asset: OtherShapesRegularDecagon1Arrow,
        defaultWidth: 447,
        defaultHeight: 462,
        width: 447,
        height: 462,
        aspectRatio: 0.9675324675324676
      };
    case 'Other_shapes/Regular_decagon_1_arrow_blue':
      return {
        asset: OtherShapesRegularDecagon1ArrowBlue,
        defaultWidth: 447,
        defaultHeight: 462,
        width: 447,
        height: 462,
        aspectRatio: 0.9675324675324676
      };
    case 'Other_shapes/Regular_decagon_1_arrow_green':
      return {
        asset: OtherShapesRegularDecagon1ArrowGreen,
        defaultWidth: 447,
        defaultHeight: 462,
        width: 447,
        height: 462,
        aspectRatio: 0.9675324675324676
      };
    case 'Other_shapes/Regular_decagon_1_arrow_pink':
      return {
        asset: OtherShapesRegularDecagon1ArrowPink,
        defaultWidth: 447,
        defaultHeight: 462,
        width: 447,
        height: 462,
        aspectRatio: 0.9675324675324676
      };
    case 'Other_shapes/Regular_decagon_1_arrow_purple':
      return {
        asset: OtherShapesRegularDecagon1ArrowPurple,
        defaultWidth: 447,
        defaultHeight: 462,
        width: 447,
        height: 462,
        aspectRatio: 0.9675324675324676
      };
    case 'Other_shapes/Regular_decagon_1_arrow_yellow':
      return {
        asset: OtherShapesRegularDecagon1ArrowYellow,
        defaultWidth: 447,
        defaultHeight: 462,
        width: 447,
        height: 462,
        aspectRatio: 0.9675324675324676
      };
    case 'Other_shapes/Regular_decagon_blue':
      return {
        asset: OtherShapesRegularDecagonBlue,

        width: 443.8029,
        height: 422.08133,
        aspectRatio: 1.0514629964798492
      };
    case 'Other_shapes/Regular_decagon_equal_sides_one_angle':
      return {
        asset: OtherShapesRegularDecagonEqualSidesOneAngle,

        width: 456.05123,
        height: 455.59372,
        aspectRatio: 1.0010042061159226
      };
    case 'Other_shapes/Regular_decagon_green':
      return {
        asset: OtherShapesRegularDecagonGreen,

        width: 443.8029,
        height: 422.08133,
        aspectRatio: 1.0514629964798492
      };
    case 'Other_shapes/Regular_decagon_pink':
      return {
        asset: OtherShapesRegularDecagonPink,

        width: 443.8029,
        height: 422.08133,
        aspectRatio: 1.0514629964798492
      };
    case 'Other_shapes/Regular_decagon_purple':
      return {
        asset: OtherShapesRegularDecagonPurple,

        width: 443.8029,
        height: 422.08133,
        aspectRatio: 1.0514629964798492
      };
    case 'Other_shapes/Regular_decagon_yellow':
      return {
        asset: OtherShapesRegularDecagonYellow,

        width: 443.8029,
        height: 422.08133,
        aspectRatio: 1.0514629964798492
      };
    case 'Other_shapes/Regular_heptagon':
      return {
        asset: OtherShapesRegularHeptagon,

        width: 505.90794,
        height: 493.3573,
        aspectRatio: 1.0254392506201895
      };
    case 'Other_shapes/Regular_heptagon_1_arrow':
      return {
        asset: OtherShapesRegularHeptagon1Arrow,
        defaultWidth: 505,
        defaultHeight: 541,
        width: 505,
        height: 541,
        aspectRatio: 0.933456561922366
      };
    case 'Other_shapes/Regular_heptagon_1_arrow_blue':
      return {
        asset: OtherShapesRegularHeptagon1ArrowBlue,
        defaultWidth: 505,
        defaultHeight: 541,
        width: 505,
        height: 541,
        aspectRatio: 0.933456561922366
      };
    case 'Other_shapes/Regular_heptagon_1_arrow_green':
      return {
        asset: OtherShapesRegularHeptagon1ArrowGreen,
        defaultWidth: 505,
        defaultHeight: 541,
        width: 505,
        height: 541,
        aspectRatio: 0.933456561922366
      };
    case 'Other_shapes/Regular_heptagon_1_arrow_pink':
      return {
        asset: OtherShapesRegularHeptagon1ArrowPink,
        defaultWidth: 505,
        defaultHeight: 541,
        width: 505,
        height: 541,
        aspectRatio: 0.933456561922366
      };
    case 'Other_shapes/Regular_heptagon_1_arrow_purple':
      return {
        asset: OtherShapesRegularHeptagon1ArrowPurple,
        defaultWidth: 505,
        defaultHeight: 541,
        width: 505,
        height: 541,
        aspectRatio: 0.933456561922366
      };
    case 'Other_shapes/Regular_heptagon_1_arrow_yellow':
      return {
        asset: OtherShapesRegularHeptagon1ArrowYellow,
        defaultWidth: 505,
        defaultHeight: 541,
        width: 505,
        height: 541,
        aspectRatio: 0.933456561922366
      };
    case 'Other_shapes/Regular_heptagon_blue':
      return {
        asset: OtherShapesRegularHeptagonBlue,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Other_shapes/Regular_heptagon_equal_sides_one_angle':
      return {
        asset: OtherShapesRegularHeptagonEqualSidesOneAngle,

        width: 437.75728,
        height: 444.42078,
        aspectRatio: 0.9850063266618632
      };
    case 'Other_shapes/Regular_heptagon_green':
      return {
        asset: OtherShapesRegularHeptagonGreen,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Other_shapes/Regular_heptagon_marked_lines':
      return {
        asset: OtherShapesRegularHeptagonMarkedLines,

        width: 502.82652,
        height: 513.38934,
        aspectRatio: 0.9794253226995326
      };
    case 'Other_shapes/Regular_heptagon_pink':
      return {
        asset: OtherShapesRegularHeptagonPink,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Other_shapes/Regular_heptagon_purple':
      return {
        asset: OtherShapesRegularHeptagonPurple,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Other_shapes/Regular_heptagon_yellow':
      return {
        asset: OtherShapesRegularHeptagonYellow,

        width: 502.57463,
        height: 490.02399,
        aspectRatio: 1.025612297063252
      };
    case 'Other_shapes/Regular_hexagon_equal_sides_one_angle':
      return {
        asset: OtherShapesRegularHexagonEqualSidesOneAngle,

        width: 492.55461,
        height: 462.00986,
        aspectRatio: 1.066112766511087
      };
    case 'Other_shapes/Regular_hexagon_marked_lines':
      return {
        asset: OtherShapesRegularHexagonMarkedLines,

        width: 541.96129,
        height: 547.85065,
        aspectRatio: 0.9892500629505505
      };
    case 'Other_shapes/Regular_nonagon_equal_sides_one_angle':
      return {
        asset: OtherShapesRegularNonagonEqualSidesOneAngle,

        width: 443.04381,
        height: 444.01065,
        aspectRatio: 0.9978224846633746
      };
    case 'Other_shapes/Regular_nonagon_marked_lines':
      return {
        asset: OtherShapesRegularNonagonMarkedLines,

        width: 448.25551,
        height: 443.6795,
        aspectRatio: 1.010313773793921
      };
    case 'Other_shapes/Regular_octagon':
      return {
        asset: OtherShapesRegularOctagon,

        width: 421.95866,
        height: 421.95865,
        aspectRatio: 1.0000000236990048
      };
    case 'Other_shapes/Regular_octagon_blue':
      return {
        asset: OtherShapesRegularOctagonBlue,

        width: 418.62532,
        height: 418.62534,
        aspectRatio: 0.9999999522245834
      };
    case 'Other_shapes/Regular_octagon_equal_sides_one_angle':
      return {
        asset: OtherShapesRegularOctagonEqualSidesOneAngle,

        width: 457.69457,
        height: 457.69458,
        aspectRatio: 0.999999978151369
      };
    case 'Other_shapes/Regular_octagon_green':
      return {
        asset: OtherShapesRegularOctagonGreen,

        width: 418.62532,
        height: 418.62534,
        aspectRatio: 0.9999999522245834
      };
    case 'Other_shapes/Regular_octagon_marked_lines':
      return {
        asset: OtherShapesRegularOctagonMarkedLines,

        width: 466.15422,
        height: 468.65225,
        aspectRatio: 0.99466975780016
      };
    case 'Other_shapes/Regular_octagon_pink':
      return {
        asset: OtherShapesRegularOctagonPink,

        width: 420.62535,
        height: 420.62534,
        aspectRatio: 1.0000000237741264
      };
    case 'Other_shapes/Regular_octagon_purple':
      return {
        asset: OtherShapesRegularOctagonPurple,

        width: 418.62532,
        height: 418.62534,
        aspectRatio: 0.9999999522245834
      };
    case 'Other_shapes/Regular_octagon_yellow':
      return {
        asset: OtherShapesRegularOctagonYellow,

        width: 418.62532,
        height: 418.62534,
        aspectRatio: 0.9999999522245834
      };
    case 'Other_shapes/Regular_pentagon_equal_sides_one_angle':
      return {
        asset: OtherShapesRegularPentagonEqualSidesOneAngle,

        width: 443.90233,
        height: 448.56128,
        aspectRatio: 0.9896135707477917
      };
    case 'Other_shapes/Rhombus1_marked_lines_and_angles':
      return {
        asset: OtherShapesRhombus1MarkedLinesAndAngles,

        width: 733.41056,
        height: 625.83026,
        aspectRatio: 1.1719001251233843
      };
    case 'Other_shapes/Rhombus2_marked_lines_and_angles':
      return {
        asset: OtherShapesRhombus2MarkedLinesAndAngles,

        width: 564.90788,
        height: 496.74149,
        aspectRatio: 1.1372270917011582
      };
    case 'Other_shapes/Rhombus_markedlines':
      return {
        asset: OtherShapesRhombusMarkedlines,

        width: 527.14342,
        height: 421.05692,
        aspectRatio: 1.2519528713600052
      };
    case 'Other_shapes/Right_angle_dotted_lines':
      return {
        asset: OtherShapesRightAngleDottedLines,

        width: 671.01274,
        height: 670.35107,
        aspectRatio: 1.0009870499647295
      };
    case 'Other_shapes/Square_based_pyramid1':
      return {
        asset: OtherShapesSquareBasedPyramid1,

        width: 175.46765,
        height: 95.099793,
        aspectRatio: 1.8450897153898114
      };
    case 'Other_shapes/Square_based_pyramid2':
      return {
        asset: OtherShapesSquareBasedPyramid2,

        width: 132.79849,
        height: 120.55827,
        aspectRatio: 1.101529492750684
      };
    case 'Other_shapes/Square_based_pyramid3':
      return {
        asset: OtherShapesSquareBasedPyramid3,

        width: 154.13179,
        height: 120.55834,
        aspectRatio: 1.2784830149452953
      };
    case 'Other_shapes/Square_equal_sides_one_angle':
      return {
        asset: OtherShapesSquareEqualSidesOneAngle,

        width: 579.59459,
        height: 579.59406,
        aspectRatio: 1.0000009144331121
      };
    case 'Other_shapes/Square_marked_lines_and_angles':
      return {
        asset: OtherShapesSquareMarkedLinesAndAngles,

        width: 647.78984,
        height: 647.78986,
        aspectRatio: 0.9999999691257904
      };
    case 'Other_shapes/Square_markedlines1':
      return {
        asset: OtherShapesSquareMarkedlines1,

        width: 567.5106,
        height: 567.51062,
        aspectRatio: 0.9999999647583686
      };
    case 'Other_shapes/Square_markedlines2':
      return {
        asset: OtherShapesSquareMarkedlines2,

        width: 668.55723,
        height: 668.55725,
        aspectRatio: 0.9999999700848358
      };
    case 'Other_shapes/Square_non_example1':
      return {
        asset: OtherShapesSquareNonExample1,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Other_shapes/Square_non_example2':
      return {
        asset: OtherShapesSquareNonExample2,

        width: 520.09465,
        height: 520.09601,
        aspectRatio: 0.999997385098186
      };
    case 'Other_shapes/Square_non_example3':
      return {
        asset: OtherShapesSquareNonExample3,

        width: 520.0949,
        height: 520.09521,
        aspectRatio: 0.9999994039552875
      };
    case 'Other_shapes/Square_non_example4':
      return {
        asset: OtherShapesSquareNonExample4,

        width: 520.09465,
        height: 526.04816,
        aspectRatio: 0.9886825761352344
      };
    case 'Other_shapes/Tetrahedron1':
      return {
        asset: OtherShapesTetrahedron1,

        width: 129.77055,
        height: 144.9436,
        aspectRatio: 0.895317558001871
      };
    case 'Other_shapes/Tetrahedron4':
      return {
        asset: OtherShapesTetrahedron4,

        width: 106.47991,
        height: 144.94353,
        aspectRatio: 0.7346303074031659
      };
    case 'Other_shapes/Tetrehedron1_dotted_lines':
      return {
        asset: OtherShapesTetrehedron1DottedLines,

        width: 578.507,
        height: 635.33331,
        aspectRatio: 0.9105566966101619
      };
    case 'Other_shapes/Tetrehedron2_dotted_lines':
      return {
        asset: OtherShapesTetrehedron2DottedLines,

        width: 952.42691,
        height: 635.33331,
        aspectRatio: 1.4990980246258456
      };
    case 'Other_shapes/Tetrehedron3_dotted_lines':
      return {
        asset: OtherShapesTetrehedron3DottedLines,

        width: 653.78123,
        height: 635.33331,
        aspectRatio: 1.0290366012762657
      };
    case 'Other_shapes/Trapezium1_marked_lines_and_angles':
      return {
        asset: OtherShapesTrapezium1MarkedLinesAndAngles,

        width: 855.84647,
        height: 300.66669,
        aspectRatio: 2.846495799052432
      };
    case 'Other_shapes/Trapezium2_marked_lines_and_angles':
      return {
        asset: OtherShapesTrapezium2MarkedLinesAndAngles,

        width: 705.84654,
        height: 300.66669,
        aspectRatio: 2.3476047180351105
      };
    case 'Other_shapes/Trapezium3_marked_lines_and_angles':
      return {
        asset: OtherShapesTrapezium3MarkedLinesAndAngles,

        width: 625.50389,
        height: 607.88708,
        aspectRatio: 1.0289803987938022
      };
    case 'Other_shapes/Trapezium4_marked_lines_and_angles':
      return {
        asset: OtherShapesTrapezium4MarkedLinesAndAngles,

        width: 705.8466,
        height: 560.06799,
        aspectRatio: 1.260287344756125
      };
    case 'Other_shapes/Triangle1_line_extension_3_angles':
      return {
        asset: OtherShapesTriangle1LineExtension3Angles,

        width: 882.24906,
        height: 486.78912,
        aspectRatio: 1.8123845085116117
      };
    case 'Other_shapes/Triangle2_line_extension_3_angles':
      return {
        asset: OtherShapesTriangle2LineExtension3Angles,

        width: 704.53672,
        height: 557.69147,
        aspectRatio: 1.263309119646388
      };
    case 'Other_shapes/Triangle3_line_extension_3_angles':
      return {
        asset: OtherShapesTriangle3LineExtension3Angles,

        width: 811.33604,
        height: 496.08063,
        aspectRatio: 1.6354922787450903
      };
    case 'Other_shapes/Triangle4_line_extension_3_angles':
      return {
        asset: OtherShapesTriangle4LineExtension3Angles,

        width: 859.71329,
        height: 282.29678,
        aspectRatio: 3.0454236495364913
      };
    case 'Other_shapes/Triangle5_line_extension_3_angles':
      return {
        asset: OtherShapesTriangle5LineExtension3Angles,

        width: 704.53672,
        height: 557.69147,
        aspectRatio: 1.263309119646388
      };
    case 'Other_shapes/Triangle_non_example1':
      return {
        asset: OtherShapesTriangleNonExample1,

        width: 570.99272,
        height: 500.06342,
        aspectRatio: 1.1418406089371622
      };
    case 'Other_shapes/Triangle_non_example2':
      return {
        asset: OtherShapesTriangleNonExample2,

        width: 596.29063,
        height: 516.67065,
        aspectRatio: 1.154101999020072
      };
    case 'Other_shapes/Triangular_prism1':
      return {
        asset: OtherShapesTriangularPrism1,

        width: 183.62399,
        height: 327.98877,
        aspectRatio: 0.5598484057853567
      };
    case 'Other_shapes/Triangular_prism2':
      return {
        asset: OtherShapesTriangularPrism2,

        width: 243.62799,
        height: 328.60355,
        aspectRatio: 0.741404011003533
      };
    case 'Other_shapes/Triangular_prism2by3':
      return {
        asset: OtherShapesTriangularPrism2by3,

        width: 187.46826,
        height: 175.23494,
        aspectRatio: 1.0698109634984894
      };
    case 'Other_shapes/Triangular_prism2by4':
      return {
        asset: OtherShapesTriangularPrism2by4,

        width: 222.71929,
        height: 195.88705,
        aspectRatio: 1.136978120809926
      };
    case 'Other_shapes/Triangular_prism3':
      return {
        asset: OtherShapesTriangularPrism3,

        width: 404.30243,
        height: 292.86917,
        aspectRatio: 1.3804881886338531
      };
    case 'Other_shapes/Triangular_prism3by3':
      return {
        asset: OtherShapesTriangularPrism3by3,

        width: 224.49965,
        height: 230.06985,
        aspectRatio: 0.9757890918779666
      };
    case 'Other_shapes/Triangular_prism3by4':
      return {
        asset: OtherShapesTriangularPrism3by4,

        width: 260.10672,
        height: 252.14625,
        aspectRatio: 1.03157084430167
      };
    case 'Other_shapes/Triangularprism_dottedlines1':
      return {
        asset: OtherShapesTriangularprismDottedlines1,

        width: 284.07345,
        height: 466.06985,
        aspectRatio: 0.6095083172618868
      };
    case 'Other_shapes/Triangularprism_dottedlines2':
      return {
        asset: OtherShapesTriangularprismDottedlines2,

        width: 427.27599,
        height: 701.66687,
        aspectRatio: 0.6089442273368272
      };
    case 'Other_shapes/Triangularprism_dottedlines3':
      return {
        asset: OtherShapesTriangularprismDottedlines3,

        width: 458.0081,
        height: 386.96051,
        aspectRatio: 1.18360423909923
      };
    case 'Other_shapes/Vertically_opposite_angles_card':
      return {
        asset: OtherShapesVerticallyOppositeAnglesCard,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Other_shapes/cube2':
      return {
        asset: OtherShapesCube2,

        width: 452.32396,
        height: 496.93665,
        aspectRatio: 0.910224593014019
      };
    case 'Other_shapes/cuboid2':
      return {
        asset: OtherShapesCuboid2,

        width: 269.64266,
        height: 447.52716,
        aspectRatio: 0.602516861769909
      };
    case 'Other_shapes/cuboid3':
      return {
        asset: OtherShapesCuboid3,

        width: 469.96932,
        height: 780.9978,
        aspectRatio: 0.6017549857374758
      };
    case 'Other_shapes/cylinder2':
      return {
        asset: OtherShapesCylinder2,

        width: 588.61461,
        height: 726.60529,
        aspectRatio: 0.8100885282572055
      };
    case 'Other_shapes/hexagonalprism2':
      return {
        asset: OtherShapesHexagonalprism2,

        width: 707.835,
        height: 521.18683,
        aspectRatio: 1.3581214245187279
      };
    case 'Other_shapes/hexagonalpyramid1':
      return {
        asset: OtherShapesHexagonalpyramid1,

        width: 394.70205,
        height: 390.67725,
        aspectRatio: 1.0103021099897678
      };
    case 'Other_shapes/pentagonalprism2':
      return {
        asset: OtherShapesPentagonalprism2,

        width: 664.13047,
        height: 612.82178,
        aspectRatio: 1.083725304280145
      };
    case 'Other_shapes/squarebasedpyramid1':
      return {
        asset: OtherShapesSquarebasedpyramid1,

        width: 857.1666,
        height: 716.5213,
        aspectRatio: 1.1962890705412386
      };
    case 'Other_shapes/squarebasedpyramid2':
      return {
        asset: OtherShapesSquarebasedpyramid2,

        width: 420.70583,
        height: 473.57611,
        aspectRatio: 0.8883594867148176
      };
    case 'Other_shapes/squarebasedpyramid3':
      return {
        asset: OtherShapesSquarebasedpyramid3,

        width: 621.71354,
        height: 700.80017,
        aspectRatio: 0.8871481010057403
      };
    case 'Other_shapes/stamp3dshape1':
      return {
        asset: OtherShapesStamp3dshape1,

        width: 516.41063,
        height: 516.41064,
        aspectRatio: 0.9999999806355656
      };
    case 'Other_shapes/stamp3dshape2':
      return {
        asset: OtherShapesStamp3dshape2,

        width: 494.87196,
        height: 494.87198,
        aspectRatio: 0.9999999595855074
      };
    case 'Other_shapes/stamp3dshape3':
      return {
        asset: OtherShapesStamp3dshape3,

        width: 532.88957,
        height: 532.88977,
        aspectRatio: 0.9999996246878601
      };
    case 'Other_shapes/stamp3dshape4':
      return {
        asset: OtherShapesStamp3dshape4,

        width: 590.00798,
        height: 299.02847,
        aspectRatio: 1.9730829643077126
      };
    case 'Other_shapes/stamp3dshape5':
      return {
        asset: OtherShapesStamp3dshape5,

        width: 486.75197,
        height: 462.92798,
        aspectRatio: 1.0514637071623971
      };
    case 'Other_shapes/stamp3dshape6':
      return {
        asset: OtherShapesStamp3dshape6,

        width: 514.94426,
        height: 445.95541,
        aspectRatio: 1.1546989866094461
      };
    case 'Other_shapes/tetrahedron2':
      return {
        asset: OtherShapesTetrahedron2,

        width: 952.42643,
        height: 635.33331,
        aspectRatio: 1.4990972691168987
      };
    case 'Other_shapes/tetrahedron3':
      return {
        asset: OtherShapesTetrahedron3,

        width: 653.78123,
        height: 635.33331,
        aspectRatio: 1.0290366012762657
      };
    case 'Other_shapes/triangularprism2':
      return {
        asset: OtherShapesTriangularprism2,

        width: 427.27599,
        height: 701.66687,
        aspectRatio: 0.6089442273368272
      };
    case 'Other_shapes/triangularprism3':
      return {
        asset: OtherShapesTriangularprism3,

        width: 458.00798,
        height: 386.96027,
        aspectRatio: 1.1836046630833703
      };
    case 'Owl_bird':
      return {
        asset: OwlBird,
        defaultWidth: 793.70135,
        defaultHeight: 793.70135,
        width: 793.70135,
        height: 793.70135,
        aspectRatio: 1
      };
    case 'Pack_of_10_crayons':
      return {
        asset: PackOf10Crayons,

        width: 298.66666,
        height: 234.51919,
        aspectRatio: 1.273527594905986
      };
    case 'Pack_of_marbles_blank':
      return {
        asset: PackOfMarblesBlank,

        width: 387.75451,
        height: 184.28966,
        aspectRatio: 2.1040491908227517
      };
    case 'Pack_of_sweets_unlabelled':
      return {
        asset: PackOfSweetsUnlabelled,

        width: 435.78494,
        height: 343.57773,
        aspectRatio: 1.2683736515751474
      };
    case 'Packaged_sandwich':
      return {
        asset: PackagedSandwich,

        width: 175.72528,
        height: 216.67714,
        aspectRatio: 0.8110005513271958
      };
    case 'Packs_of_pins_pack_of_100':
      return {
        asset: PacksOfPinsPackOf100,

        width: 246.15199,
        height: 336.75452,
        aspectRatio: 0.7309537820012038
      };
    case 'Paddle':
      return {
        asset: Paddle,

        width: 272.39212,
        height: 241.64297,
        aspectRatio: 1.127250339622957
      };
    case 'Paddling_pool':
      return {
        asset: PaddlingPool,

        width: 553.3482,
        height: 264.82763,
        aspectRatio: 2.089465513851406
      };
    case 'Paint_brush':
      return {
        asset: PaintBrush,

        width: 703.53344,
        height: 92.330154,
        aspectRatio: 7.6197581128262835
      };
    case 'Paint_palette':
      return {
        asset: PaintPalette,
        defaultWidth: 372.89584,
        defaultHeight: 243.22688,
        width: 372.89584,
        height: 243.22688,
        aspectRatio: 1.5331193657543114
      };
    case 'PairedsocksBlue':
      return {
        asset: PairedsocksBlue,

        width: 149.92381,
        height: 154.89282,
        aspectRatio: 0.9679196879493833
      };
    case 'PairedsocksGreen':
      return {
        asset: PairedsocksGreen,

        width: 147.20181,
        height: 154.89296,
        aspectRatio: 0.9503453869046082
      };
    case 'PairedsocksOrange':
      return {
        asset: PairedsocksOrange,

        width: 147.20181,
        height: 154.89296,
        aspectRatio: 0.9503453869046082
      };
    case 'PairedsocksPurple':
      return {
        asset: PairedsocksPurple,

        width: 147.20181,
        height: 154.89296,
        aspectRatio: 0.9503453869046082
      };
    case 'PairedsocksRed':
      return {
        asset: PairedsocksRed,

        width: 147.20181,
        height: 154.89282,
        aspectRatio: 0.9503462458750509
      };
    case 'PairedsocksYellow':
      return {
        asset: PairedsocksYellow,

        width: 151.96715,
        height: 154.89296,
        aspectRatio: 0.9811107619093857
      };
    case 'Paper_clip':
      return {
        asset: PaperClip,

        width: 2573.2629,
        height: 121.83279,
        aspectRatio: 21.121267107155635
      };
    case 'Parallelogram/parallelogram':
      return {
        asset: ParallelogramParallelogram,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_blue':
      return {
        asset: ParallelogramParallelogramBlue,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_green':
      return {
        asset: ParallelogramParallelogramGreen,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_orange':
      return {
        asset: ParallelogramParallelogramOrange,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_pink':
      return {
        asset: ParallelogramParallelogramPink,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_purple':
      return {
        asset: ParallelogramParallelogramPurple,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_red':
      return {
        asset: ParallelogramParallelogramRed,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_turquoise':
      return {
        asset: ParallelogramParallelogramTurquoise,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_white':
      return {
        asset: ParallelogramParallelogramWhite,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Parallelogram/parallelogram_yellow':
      return {
        asset: ParallelogramParallelogramYellow,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'ParenthesesLeftBlack':
      return {
        asset: ParenthesesLeftBlack,

        width: 48,
        height: 144,
        aspectRatio: 0.3333333333333333
      };
    case 'ParenthesesLeftBlue':
      return {
        asset: ParenthesesLeftBlue,

        width: 48,
        height: 144,
        aspectRatio: 0.3333333333333333
      };
    case 'ParenthesesRightBlack':
      return {
        asset: ParenthesesRightBlack,

        width: 48,
        height: 144,
        aspectRatio: 0.3333333333333333
      };
    case 'ParenthesesRightBlue':
      return {
        asset: ParenthesesRightBlue,

        width: 48,
        height: 144,
        aspectRatio: 0.3333333333333333
      };
    case 'Part_filled_cylinder_1000mlcap_100mlint':
      return {
        asset: PartFilledCylinder1000mlcap100mlint,

        width: 204.812,
        height: 455.57171,
        aspectRatio: 0.4495713748336129
      };
    case 'Peach':
      return {
        asset: Peach,

        width: 191.99901,
        height: 162.27627,
        aspectRatio: 1.1831613457716275
      };
    case 'Pears_in_a_transparent_bag/Pears_in_a_bag_0':
      return {
        asset: PearsInATransparentBagPearsInABag0,

        width: 190.11143,
        height: 255.44919,
        aspectRatio: 0.7442240470600044
      };
    case 'Pears_in_a_transparent_bag/Pears_in_a_bag_1':
      return {
        asset: PearsInATransparentBagPearsInABag1,

        width: 188.75941,
        height: 253.62251,
        aspectRatio: 0.7442533787714662
      };
    case 'Pears_in_a_transparent_bag/Pears_in_a_bag_2':
      return {
        asset: PearsInATransparentBagPearsInABag2,

        width: 190.11143,
        height: 255.44919,
        aspectRatio: 0.7442240470600044
      };
    case 'Pears_in_a_transparent_bag/Pears_in_a_bag_3':
      return {
        asset: PearsInATransparentBagPearsInABag3,

        width: 190.11143,
        height: 255.44919,
        aspectRatio: 0.7442240470600044
      };
    case 'Pears_in_a_transparent_bag/Pears_in_a_bag_4':
      return {
        asset: PearsInATransparentBagPearsInABag4,

        width: 190.11143,
        height: 255.44919,
        aspectRatio: 0.7442240470600044
      };
    case 'Pears_in_a_transparent_bag/Pears_in_a_bag_5':
      return {
        asset: PearsInATransparentBagPearsInABag5,

        width: 190.11143,
        height: 255.44919,
        aspectRatio: 0.7442240470600044
      };
    case 'Pen_green':
      return {
        asset: PenGreen,

        width: 78.79367,
        height: 309.76904,
        aspectRatio: 0.2543626374023692
      };
    case 'Pencil_case':
      return {
        asset: PencilCase,

        width: 428.94815,
        height: 387.79949,
        aspectRatio: 1.1061080817821602
      };
    case 'Pencil_sharpener':
      return {
        asset: PencilSharpener,

        width: 183.94546,
        height: 121.3064,
        aspectRatio: 1.5163706119380347
      };
    case 'Pencils/Pencil_green':
      return {
        asset: PencilsPencilGreen,

        width: 739.40625,
        height: 56.516705,
        aspectRatio: 13.082968124203278
      };
    case 'Pencils/Pencil_red':
      return {
        asset: PencilsPencilRed,

        width: 739.40625,
        height: 56.516705,
        aspectRatio: 13.082968124203278
      };
    case 'Pencils/Pencil_yellow':
      return {
        asset: PencilsPencilYellow,

        width: 739.40625,
        height: 56.516705,
        aspectRatio: 13.082968124203278
      };
    case 'Pentagon_houses/pentagon_house':
      return {
        asset: PentagonHousesPentagonHouse,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_blue':
      return {
        asset: PentagonHousesPentagonHouseBlue,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_green':
      return {
        asset: PentagonHousesPentagonHouseGreen,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_orange':
      return {
        asset: PentagonHousesPentagonHouseOrange,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_pink':
      return {
        asset: PentagonHousesPentagonHousePink,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_purple':
      return {
        asset: PentagonHousesPentagonHousePurple,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_red':
      return {
        asset: PentagonHousesPentagonHouseRed,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_turquoise':
      return {
        asset: PentagonHousesPentagonHouseTurquoise,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_white':
      return {
        asset: PentagonHousesPentagonHouseWhite,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagon_houses/pentagon_house_yellow':
      return {
        asset: PentagonHousesPentagonHouseYellow,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Pentagons/Irregular_pentagon1_blue':
      return {
        asset: PentagonsIrregularPentagon1Blue,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_blue_one_interior_angle_lhs':
      return {
        asset: PentagonsIrregularPentagon1BlueOneInteriorAngleLhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_blue_one_interior_angle_rhs':
      return {
        asset: PentagonsIrregularPentagon1BlueOneInteriorAngleRhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_green':
      return {
        asset: PentagonsIrregularPentagon1Green,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_green_one_interior_angle_lhs':
      return {
        asset: PentagonsIrregularPentagon1GreenOneInteriorAngleLhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_green_one_interior_angle_rhs':
      return {
        asset: PentagonsIrregularPentagon1GreenOneInteriorAngleRhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_orange':
      return {
        asset: PentagonsIrregularPentagon1Orange,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_pink':
      return {
        asset: PentagonsIrregularPentagon1Pink,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_pink_one_interior_angle_lhs':
      return {
        asset: PentagonsIrregularPentagon1PinkOneInteriorAngleLhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_pink_one_interior_angle_rhs':
      return {
        asset: PentagonsIrregularPentagon1PinkOneInteriorAngleRhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_purple':
      return {
        asset: PentagonsIrregularPentagon1Purple,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_purple_one_interior_angle_lhs':
      return {
        asset: PentagonsIrregularPentagon1PurpleOneInteriorAngleLhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_purple_one_interior_angle_rhs':
      return {
        asset: PentagonsIrregularPentagon1PurpleOneInteriorAngleRhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_red':
      return {
        asset: PentagonsIrregularPentagon1Red,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_turquoise':
      return {
        asset: PentagonsIrregularPentagon1Turquoise,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_white':
      return {
        asset: PentagonsIrregularPentagon1White,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_white_one_interior_angle_lhs':
      return {
        asset: PentagonsIrregularPentagon1WhiteOneInteriorAngleLhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_white_one_interior_angle_rhs':
      return {
        asset: PentagonsIrregularPentagon1WhiteOneInteriorAngleRhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_yellow':
      return {
        asset: PentagonsIrregularPentagon1Yellow,

        width: 482.34258,
        height: 472.93997,
        aspectRatio: 1.0198811912640837
      };
    case 'Pentagons/Irregular_pentagon1_yellow_one_interior_angle_lhs':
      return {
        asset: PentagonsIrregularPentagon1YellowOneInteriorAngleLhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon1_yellow_one_interior_angle_rhs':
      return {
        asset: PentagonsIrregularPentagon1YellowOneInteriorAngleRhs,

        width: 325,
        height: 319,
        aspectRatio: 1.0188087774294672
      };
    case 'Pentagons/Irregular_pentagon2_blue':
      return {
        asset: PentagonsIrregularPentagon2Blue,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_green':
      return {
        asset: PentagonsIrregularPentagon2Green,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_orange':
      return {
        asset: PentagonsIrregularPentagon2Orange,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_pink':
      return {
        asset: PentagonsIrregularPentagon2Pink,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_purple':
      return {
        asset: PentagonsIrregularPentagon2Purple,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_red':
      return {
        asset: PentagonsIrregularPentagon2Red,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_turquoise':
      return {
        asset: PentagonsIrregularPentagon2Turquoise,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_white':
      return {
        asset: PentagonsIrregularPentagon2White,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon2_yellow':
      return {
        asset: PentagonsIrregularPentagon2Yellow,

        width: 396.00798,
        height: 342.172,
        aspectRatio: 1.1573360181429222
      };
    case 'Pentagons/Irregular_pentagon3_blue':
      return {
        asset: PentagonsIrregularPentagon3Blue,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_green':
      return {
        asset: PentagonsIrregularPentagon3Green,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_orange':
      return {
        asset: PentagonsIrregularPentagon3Orange,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_pink':
      return {
        asset: PentagonsIrregularPentagon3Pink,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_purple':
      return {
        asset: PentagonsIrregularPentagon3Purple,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_red':
      return {
        asset: PentagonsIrregularPentagon3Red,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_turquoise':
      return {
        asset: PentagonsIrregularPentagon3Turquoise,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_white':
      return {
        asset: PentagonsIrregularPentagon3White,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon3_yellow':
      return {
        asset: PentagonsIrregularPentagon3Yellow,

        width: 419.04396,
        height: 413.18396,
        aspectRatio: 1.0141825447435084
      };
    case 'Pentagons/Irregular_pentagon4_blue':
      return {
        asset: PentagonsIrregularPentagon4Blue,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_green':
      return {
        asset: PentagonsIrregularPentagon4Green,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_orange':
      return {
        asset: PentagonsIrregularPentagon4Orange,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_pink':
      return {
        asset: PentagonsIrregularPentagon4Pink,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_purple':
      return {
        asset: PentagonsIrregularPentagon4Purple,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_red':
      return {
        asset: PentagonsIrregularPentagon4Red,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_turquoise':
      return {
        asset: PentagonsIrregularPentagon4Turquoise,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_white':
      return {
        asset: PentagonsIrregularPentagon4White,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon4_yellow':
      return {
        asset: PentagonsIrregularPentagon4Yellow,

        width: 340.46132,
        height: 674.8905,
        aspectRatio: 0.5044689768192026
      };
    case 'Pentagons/Irregular_pentagon5_blue':
      return {
        asset: PentagonsIrregularPentagon5Blue,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_green':
      return {
        asset: PentagonsIrregularPentagon5Green,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_orange':
      return {
        asset: PentagonsIrregularPentagon5Orange,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_pink':
      return {
        asset: PentagonsIrregularPentagon5Pink,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_purple':
      return {
        asset: PentagonsIrregularPentagon5Purple,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_red':
      return {
        asset: PentagonsIrregularPentagon5Red,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_turquoise':
      return {
        asset: PentagonsIrregularPentagon5Turquoise,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_white':
      return {
        asset: PentagonsIrregularPentagon5White,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon5_yellow':
      return {
        asset: PentagonsIrregularPentagon5Yellow,

        width: 845.71458,
        height: 487.90665,
        aspectRatio: 1.7333532551769892
      };
    case 'Pentagons/Irregular_pentagon6_blue':
      return {
        asset: PentagonsIrregularPentagon6Blue,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_green':
      return {
        asset: PentagonsIrregularPentagon6Green,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_orange':
      return {
        asset: PentagonsIrregularPentagon6Orange,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_pink':
      return {
        asset: PentagonsIrregularPentagon6Pink,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_purple':
      return {
        asset: PentagonsIrregularPentagon6Purple,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_red':
      return {
        asset: PentagonsIrregularPentagon6Red,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_turquoise':
      return {
        asset: PentagonsIrregularPentagon6Turquoise,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_white':
      return {
        asset: PentagonsIrregularPentagon6White,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/Irregular_pentagon6_yellow':
      return {
        asset: PentagonsIrregularPentagon6Yellow,

        width: 574.6013,
        height: 486.00131,
        aspectRatio: 1.1823040147772441
      };
    case 'Pentagons/pentagon':
      return {
        asset: PentagonsPentagon,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_1_arrow':
      return {
        asset: PentagonsPentagon1Arrow,
        defaultWidth: 342,
        defaultHeight: 380,
        width: 342,
        height: 380,
        aspectRatio: 0.9
      };
    case 'Pentagons/pentagon_1_arrow_blue':
      return {
        asset: PentagonsPentagon1ArrowBlue,
        defaultWidth: 342,
        defaultHeight: 380,
        width: 342,
        height: 380,
        aspectRatio: 0.9
      };
    case 'Pentagons/pentagon_1_arrow_green':
      return {
        asset: PentagonsPentagon1ArrowGreen,
        defaultWidth: 342,
        defaultHeight: 380,
        width: 342,
        height: 380,
        aspectRatio: 0.9
      };
    case 'Pentagons/pentagon_1_arrow_pink':
      return {
        asset: PentagonsPentagon1ArrowPink,
        defaultWidth: 342,
        defaultHeight: 380,
        width: 342,
        height: 380,
        aspectRatio: 0.9
      };
    case 'Pentagons/pentagon_1_arrow_purple':
      return {
        asset: PentagonsPentagon1ArrowPurple,
        defaultWidth: 342,
        defaultHeight: 380,
        width: 342,
        height: 380,
        aspectRatio: 0.9
      };
    case 'Pentagons/pentagon_1_arrow_yellow':
      return {
        asset: PentagonsPentagon1ArrowYellow,
        defaultWidth: 342,
        defaultHeight: 380,
        width: 342,
        height: 380,
        aspectRatio: 0.9
      };
    case 'Pentagons/pentagon_blue':
      return {
        asset: PentagonsPentagonBlue,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_green':
      return {
        asset: PentagonsPentagonGreen,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_orange':
      return {
        asset: PentagonsPentagonOrange,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_pink':
      return {
        asset: PentagonsPentagonPink,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_purple':
      return {
        asset: PentagonsPentagonPurple,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_red':
      return {
        asset: PentagonsPentagonRed,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_turquoise':
      return {
        asset: PentagonsPentagonTurquoise,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_white':
      return {
        asset: PentagonsPentagonWhite,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Pentagons/pentagon_yellow':
      return {
        asset: PentagonsPentagonYellow,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Phone':
      return {
        asset: Phone,

        width: 236.40399,
        height: 427.71197,
        aspectRatio: 0.5527177319821094
      };
    case 'Picture_book':
      return {
        asset: PictureBook,

        width: 379.33344,
        height: 260.24414,
        aspectRatio: 1.4576060771243493
      };
    case 'Place_value/0.0001':
      return {
        asset: PlaceValue00001,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'Place_value/0.001':
      return {
        asset: PlaceValue0001,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/0.01':
      return {
        asset: PlaceValue001,

        width: 104,
        height: 103,
        aspectRatio: 1.0097087378640777
      };
    case 'Place_value/0.1':
      return {
        asset: PlaceValue01,

        width: 104,
        height: 103,
        aspectRatio: 1.0097087378640777
      };
    case 'Place_value/0.1_grey':
      return {
        asset: PlaceValue01Grey,

        width: 104,
        height: 103,
        aspectRatio: 1.0097087378640777
      };
    case 'Place_value/1':
      return {
        asset: PlaceValue1,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/1-10':
      return {
        asset: PlaceValue110,

        width: 104,
        height: 103,
        aspectRatio: 1.0097087378640777
      };
    case 'Place_value/1-100':
      return {
        asset: PlaceValue1100,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/1-1000':
      return {
        asset: PlaceValue11000,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/1-10000':
      return {
        asset: PlaceValue110000,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'Place_value/10':
      return {
        asset: PlaceValue10,

        width: 104,
        height: 103,
        aspectRatio: 1.0097087378640777
      };
    case 'Place_value/100':
      return {
        asset: PlaceValue100,

        width: 104,
        height: 103,
        aspectRatio: 1.0097087378640777
      };
    case 'Place_value/1000':
      return {
        asset: PlaceValue1000,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/10000':
      return {
        asset: PlaceValue10000,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'Place_value/100000':
      return {
        asset: PlaceValue100000,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'Place_value/1000000':
      return {
        asset: PlaceValue1000000,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'Place_value/1000cube':
      return {
        asset: PlaceValue1000cube,

        width: 190,
        height: 179,
        aspectRatio: 1.0614525139664805
      };
    case 'Place_value/100cube':
      return {
        asset: PlaceValue100cube,

        width: 152,
        height: 157,
        aspectRatio: 0.9681528662420382
      };
    case 'Place_value/10cube':
      return {
        asset: PlaceValue10cube,

        width: 150,
        height: 157,
        aspectRatio: 0.9554140127388535
      };
    case 'Place_value/1_grey':
      return {
        asset: PlaceValue1Grey,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/1cube':
      return {
        asset: PlaceValue1cube,

        width: 150,
        height: 157,
        aspectRatio: 0.9554140127388535
      };
    case 'Place_value/BlackCounter':
      return {
        asset: PlaceValueBlackCounter,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Place_value/GreyCounter':
      return {
        asset: PlaceValueGreyCounter,

        width: 104,
        height: 104,
        aspectRatio: 1
      };
    case 'Plane':
      return {
        asset: Plane,

        width: 693.70135,
        height: 693.70135,
        aspectRatio: 1
      };
    case 'Plate':
      return {
        asset: Plate,

        width: 336.61465,
        height: 200.22665,
        aspectRatio: 1.681168066288878
      };
    case 'Plum':
      return {
        asset: Plum,

        width: 91.959899,
        height: 127.09458,
        aspectRatio: 0.7235548439595142
      };
    case 'Pond':
      return {
        asset: Pond,

        width: 1402,
        height: 854,
        aspectRatio: 1.6416861826697893
      };
    case 'Protractor180':
      return {
        asset: Protractor180,

        width: 261.43066,
        height: 142.71867,
        aspectRatio: 1.831790192551542
      };
    case 'Protractor360':
      return {
        asset: Protractor360,

        width: 261.43039,
        height: 261.72214,
        aspectRatio: 0.9988852681702816
      };
    case 'Rectangle/Rectangle_angles_length_arrows_blue':
      return {
        asset: RectangleRectangleAnglesLengthArrowsBlue,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectangle/Rectangle_angles_length_arrows_green':
      return {
        asset: RectangleRectangleAnglesLengthArrowsGreen,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectangle/Rectangle_angles_length_arrows_pink':
      return {
        asset: RectangleRectangleAnglesLengthArrowsPink,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectangle/Rectangle_angles_length_arrows_purple':
      return {
        asset: RectangleRectangleAnglesLengthArrowsPurple,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectangle/Rectangle_angles_length_arrows_white':
      return {
        asset: RectangleRectangleAnglesLengthArrowsWhite,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectangle/Rectangle_angles_length_arrows_yellow':
      return {
        asset: RectangleRectangleAnglesLengthArrowsYellow,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectangle/rectangle':
      return {
        asset: RectangleRectangle,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_blue':
      return {
        asset: RectangleRectangleBlue,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_green':
      return {
        asset: RectangleRectangleGreen,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_orange':
      return {
        asset: RectangleRectangleOrange,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_pink':
      return {
        asset: RectangleRectanglePink,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_purple':
      return {
        asset: RectangleRectanglePurple,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_red':
      return {
        asset: RectangleRectangleRed,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_turquoise':
      return {
        asset: RectangleRectangleTurquoise,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectangle/rectangle_white':
      return {
        asset: RectangleRectangleWhite,

        width: 578.66662,
        height: 329.33331,
        aspectRatio: 1.7570850030323382
      };
    case 'Rectangle/rectangle_yellow':
      return {
        asset: RectangleRectangleYellow,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows1':
      return {
        asset: RectilinearShapesWithArrowsRectangleAnglesLengthArrows1,

        width: 950.79447,
        height: 529.47961,
        aspectRatio: 1.7957149851341774
      };
    case 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows2':
      return {
        asset: RectilinearShapesWithArrowsRectangleAnglesLengthArrows2,

        width: 861.65848,
        height: 480.82999,
        aspectRatio: 1.792023163946159
      };
    case 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows3':
      return {
        asset: RectilinearShapesWithArrowsRectangleAnglesLengthArrows3,

        width: 715.63383,
        height: 400.22186,
        aspectRatio: 1.7880928093233088
      };
    case 'Rectilinear_shapes_with_arrows/Rectangle_angles_length_arrows4':
      return {
        asset: RectilinearShapesWithArrowsRectangleAnglesLengthArrows4,

        width: 560.25249,
        height: 316.32825,
        aspectRatio: 1.771111147992631
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape102RedArrows,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAngles,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_blue':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesBlue,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_green':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesGreen,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_pink':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesPink,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_purple':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesPurple,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape10_all_arrows_all_interior_angles_yellow':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape10AllArrowsAllInteriorAnglesYellow,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape11_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape11AllArrowsAllInteriorAngles,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape12_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape12AllArrowsAllInteriorAngles,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape13_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape13AllArrowsAllInteriorAngles,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape14_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape14AllArrowsAllInteriorAngles,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape15_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape15AllArrowsAllInteriorAngles,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape16_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape16AllArrowsAllInteriorAngles,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape17_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape17AllArrowsAllInteriorAngles,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape18_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape18AllArrowsAllInteriorAngles,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAngles,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_blue':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesBlue,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_green':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesGreen,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_pink':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesPink,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_purple':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesPurple,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape19_all_arrows_all_interior_angles_yellow':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape19AllArrowsAllInteriorAnglesYellow,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape1_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape12RedArrows,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape1_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape1AllArrowsAllInteriorAngles,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape20_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape20AllArrowsAllInteriorAngles,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAngles,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_blue':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesBlue,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_green':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesGreen,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_pink':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesPink,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_purple':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesPurple,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape21_all_arrows_all_interior_angles_yellow':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape21AllArrowsAllInteriorAnglesYellow,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAngles,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_blue':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesBlue,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_green':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesGreen,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_pink':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesPink,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_purple':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesPurple,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape22_all_arrows_all_interior_angles_yellow':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22AllArrowsAllInteriorAnglesYellow,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape2_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape22RedArrows,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape2_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape2AllArrowsAllInteriorAngles,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape3_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape32RedArrows,

        width: 842.00889,
        height: 389.11627,
        aspectRatio: 2.163900496887473
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape3_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape3AllArrowsAllInteriorAngles,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape4_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape42RedArrows,

        width: 843.3422,
        height: 623.86609,
        aspectRatio: 1.3518000313176184
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape4_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape4AllArrowsAllInteriorAngles,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape5_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape52RedArrows,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape5_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape5AllArrowsAllInteriorAngles,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape6_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape62RedArrows,

        width: 842.00877,
        height: 623.86609,
        aspectRatio: 1.349662665589021
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape6_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape6AllArrowsAllInteriorAngles,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape7_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape72RedArrows,

        width: 431.22905,
        height: 623.86627,
        aspectRatio: 0.6912203315624036
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape7_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape7AllArrowsAllInteriorAngles,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape8_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape82RedArrows,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape8_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape8AllArrowsAllInteriorAngles,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape9_2_red_arrows':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape92RedArrows,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Rectilinear_shapes_with_arrows/Rectilinear_shape9_all_arrows_all_interior_angles':
      return {
        asset: RectilinearShapesWithArrowsRectilinearShape9AllArrowsAllInteriorAngles,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Rectilinear_shapes_with_arrows/Square_angles_length_arrows':
      return {
        asset: RectilinearShapesWithArrowsSquareAnglesLengthArrows,

        width: 568.26518,
        height: 548.64893,
        aspectRatio: 1.035753737822837
      };
    case 'Red_scissors':
      return {
        asset: RedScissors,

        width: 497.67077,
        height: 247.47461,
        aspectRatio: 2.010997289782576
      };
    case 'Refrigerator':
      return {
        asset: Refrigerator,

        width: 217.99826,
        height: 413.33332,
        aspectRatio: 0.5274151621746827
      };
    case 'Rhombus/rhombus':
      return {
        asset: RhombusRhombus,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_blue':
      return {
        asset: RhombusRhombusBlue,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_green':
      return {
        asset: RhombusRhombusGreen,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_orange':
      return {
        asset: RhombusRhombusOrange,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_pink':
      return {
        asset: RhombusRhombusPink,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_purple':
      return {
        asset: RhombusRhombusPurple,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_red':
      return {
        asset: RhombusRhombusRed,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_turquoise':
      return {
        asset: RhombusRhombusTurquoise,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_white':
      return {
        asset: RhombusRhombusWhite,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Rhombus/rhombus_yellow':
      return {
        asset: RhombusRhombusYellow,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'RibbonGreen':
      return {
        asset: RibbonGreen,

        width: 1689.4472,
        height: 88.862389,
        aspectRatio: 19.011948913504906
      };
    case 'RibbonGreenStriped':
      return {
        asset: RibbonGreenStriped,
        defaultWidth: 1211,
        defaultHeight: 151,
        width: 1211,
        height: 151,
        aspectRatio: 8.019867549668874
      };
    case 'RibbonRed':
      return {
        asset: RibbonRed,
        defaultWidth: 461,
        defaultHeight: 144,
        width: 461,
        height: 144,
        aspectRatio: 3.201388888888889
      };
    case 'RibbonRedStriped':
      return {
        asset: RibbonRedStriped,
        defaultWidth: 1211,
        defaultHeight: 151,
        width: 1211,
        height: 151,
        aspectRatio: 8.019867549668874
      };
    case 'Ribbons/1-10':
      return {
        asset: Ribbons110,

        width: 1210.4703,
        height: 205.74501,
        aspectRatio: 5.883351921876501
      };
    case 'Ribbons/1-11':
      return {
        asset: Ribbons111,

        width: 1210.4703,
        height: 206.0832,
        aspectRatio: 5.873697128150184
      };
    case 'Ribbons/1-12':
      return {
        asset: Ribbons112,

        width: 1210.4703,
        height: 205.74496,
        aspectRatio: 5.8833533516446765
      };
    case 'Ribbons/1-2':
      return {
        asset: Ribbons12,

        width: 1210.4703,
        height: 205.7451,
        aspectRatio: 5.883349348295536
      };
    case 'Ribbons/1-3':
      return {
        asset: Ribbons13,

        width: 1210.4703,
        height: 205.74515,
        aspectRatio: 5.8833479185293065
      };
    case 'Ribbons/1-4':
      return {
        asset: Ribbons14,

        width: 1210.4703,
        height: 205.74487,
        aspectRatio: 5.883355925229144
      };
    case 'Ribbons/1-5':
      return {
        asset: Ribbons15,

        width: 1210.4703,
        height: 205.74515,
        aspectRatio: 5.8833479185293065
      };
    case 'Ribbons/1-6':
      return {
        asset: Ribbons16,

        width: 1210.4703,
        height: 205.74484,
        aspectRatio: 5.883356783091133
      };
    case 'Ribbons/1-7':
      return {
        asset: Ribbons17,

        width: 1210.4703,
        height: 205.74528,
        aspectRatio: 5.883344201140361
      };
    case 'Ribbons/1-8':
      return {
        asset: Ribbons18,

        width: 1210.4703,
        height: 205.74501,
        aspectRatio: 5.883351921876501
      };
    case 'Ribbons/1-9':
      return {
        asset: Ribbons19,

        width: 1210.4703,
        height: 205.74524,
        aspectRatio: 5.883345344951844
      };
    case 'RiceAndBowls':
      return {
        asset: RiceAndBowls,

        width: 546.87445,
        height: 209.62959,
        aspectRatio: 2.608765537346135
      };
    case 'Right_angled_trapeziums/trapezium_RA':
      return {
        asset: RightAngledTrapeziumsTrapeziumRA,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_blue':
      return {
        asset: RightAngledTrapeziumsTrapeziumRABlue,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_lhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRABlueOneInteriorAngleLhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_blue_one_interior_angle_rhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRABlueOneInteriorAngleRhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_green':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAGreen,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_lhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAGreenOneInteriorAngleLhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_green_one_interior_angle_rhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAGreenOneInteriorAngleRhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_pink':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAPink,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_lhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAPinkOneInteriorAngleLhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_pink_one_interior_angle_rhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAPinkOneInteriorAngleRhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_purple':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAPurple,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_lhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAPurpleOneInteriorAngleLhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_purple_one_interior_angle_rhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAPurpleOneInteriorAngleRhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_white':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAWhite,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_lhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAWhiteOneInteriorAngleLhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_white_one_interior_angle_rhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAWhiteOneInteriorAngleRhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_yellow':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAYellow,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_lhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAYellowOneInteriorAngleLhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_trapeziums/trapezium_RA_yellow_one_interior_angle_rhs':
      return {
        asset: RightAngledTrapeziumsTrapeziumRAYellowOneInteriorAngleRhs,

        width: 398,
        height: 271,
        aspectRatio: 1.4686346863468636
      };
    case 'Right_angled_triangles/triangle_RA':
      return {
        asset: RightAngledTrianglesTriangleRA,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_blue':
      return {
        asset: RightAngledTrianglesTriangleRABlue,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_green':
      return {
        asset: RightAngledTrianglesTriangleRAGreen,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_green_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRAGreenOneInteriorAngleLhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_green_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRAGreenOneInteriorAngleRhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_long_blue':
      return {
        asset: RightAngledTrianglesTriangleRALongBlue,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_blue_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRALongBlueOneInteriorAngleLhs,

        width: 430,
        height: 225,
        aspectRatio: 1.9111111111111112
      };
    case 'Right_angled_triangles/triangle_RA_long_blue_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRALongBlueOneInteriorAngleRhs,

        width: 436,
        height: 227,
        aspectRatio: 1.920704845814978
      };
    case 'Right_angled_triangles/triangle_RA_long_green':
      return {
        asset: RightAngledTrianglesTriangleRALongGreen,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_green_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRALongGreenOneInteriorAngleLhs,

        width: 430,
        height: 225,
        aspectRatio: 1.9111111111111112
      };
    case 'Right_angled_triangles/triangle_RA_long_green_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRALongGreenOneInteriorAngleRhs,

        width: 436,
        height: 227,
        aspectRatio: 1.920704845814978
      };
    case 'Right_angled_triangles/triangle_RA_long_orange':
      return {
        asset: RightAngledTrianglesTriangleRALongOrange,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_pink':
      return {
        asset: RightAngledTrianglesTriangleRALongPink,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRALongPinkOneInteriorAngleLhs,

        width: 430,
        height: 225,
        aspectRatio: 1.9111111111111112
      };
    case 'Right_angled_triangles/triangle_RA_long_pink_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRALongPinkOneInteriorAngleRhs,

        width: 436,
        height: 227,
        aspectRatio: 1.920704845814978
      };
    case 'Right_angled_triangles/triangle_RA_long_purple':
      return {
        asset: RightAngledTrianglesTriangleRALongPurple,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRALongPurpleOneInteriorAngleLhs,

        width: 430,
        height: 225,
        aspectRatio: 1.9111111111111112
      };
    case 'Right_angled_triangles/triangle_RA_long_purple_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRALongPurpleOneInteriorAngleRhs,

        width: 436,
        height: 227,
        aspectRatio: 1.920704845814978
      };
    case 'Right_angled_triangles/triangle_RA_long_red':
      return {
        asset: RightAngledTrianglesTriangleRALongRed,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_turquoise':
      return {
        asset: RightAngledTrianglesTriangleRALongTurquoise,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_white':
      return {
        asset: RightAngledTrianglesTriangleRALongWhite,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_white_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRALongWhiteOneInteriorAngleLhs,

        width: 430,
        height: 225,
        aspectRatio: 1.9111111111111112
      };
    case 'Right_angled_triangles/triangle_RA_long_white_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRALongWhiteOneInteriorAngleRhs,

        width: 436,
        height: 227,
        aspectRatio: 1.920704845814978
      };
    case 'Right_angled_triangles/triangle_RA_long_yellow':
      return {
        asset: RightAngledTrianglesTriangleRALongYellow,

        width: 633.074,
        height: 330.82843,
        aspectRatio: 1.9136021653278104
      };
    case 'Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRALongYellowOneInteriorAngleLhs,

        width: 430,
        height: 225,
        aspectRatio: 1.9111111111111112
      };
    case 'Right_angled_triangles/triangle_RA_long_yellow_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRALongYellowOneInteriorAngleRhs,

        width: 436,
        height: 227,
        aspectRatio: 1.920704845814978
      };
    case 'Right_angled_triangles/triangle_RA_orange':
      return {
        asset: RightAngledTrianglesTriangleRAOrange,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_pink':
      return {
        asset: RightAngledTrianglesTriangleRAPink,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_pink_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRAPinkOneInteriorAngleLhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_pink_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRAPinkOneInteriorAngleRhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_purple':
      return {
        asset: RightAngledTrianglesTriangleRAPurple,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_purple_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRAPurpleOneInteriorAngleLhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_purple_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRAPurpleOneInteriorAngleRhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_red':
      return {
        asset: RightAngledTrianglesTriangleRARed,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_turquoise':
      return {
        asset: RightAngledTrianglesTriangleRATurquoise,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_white':
      return {
        asset: RightAngledTrianglesTriangleRAWhite,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_white_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRAWhiteOneInteriorAngleLhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_white_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRAWhiteOneInteriorAngleRhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_yellow':
      return {
        asset: RightAngledTrianglesTriangleRAYellow,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_yellow_one_interior_angle_lhs':
      return {
        asset: RightAngledTrianglesTriangleRAYellowOneInteriorAngleLhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Right_angled_triangles/triangle_RA_yellow_one_interior_angle_rhs':
      return {
        asset: RightAngledTrianglesTriangleRAYellowOneInteriorAngleRhs,

        width: 331,
        height: 331,
        aspectRatio: 1
      };
    case 'Roller_coaster_ad_duration':
      return {
        asset: RollerCoasterAdDuration,

        width: 319.01446,
        height: 390.17798,
        aspectRatio: 0.8176126699922943
      };
    case 'Rounded_rectangles/Rounded_rectangle_blue':
      return {
        asset: RoundedRectanglesRoundedRectangleBlue,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_green':
      return {
        asset: RoundedRectanglesRoundedRectangleGreen,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_orange':
      return {
        asset: RoundedRectanglesRoundedRectangleOrange,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_pink':
      return {
        asset: RoundedRectanglesRoundedRectanglePink,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_purple':
      return {
        asset: RoundedRectanglesRoundedRectanglePurple,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_red':
      return {
        asset: RoundedRectanglesRoundedRectangleRed,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_turquoise':
      return {
        asset: RoundedRectanglesRoundedRectangleTurquoise,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_rectangles/Rounded_rectangle_yellow':
      return {
        asset: RoundedRectanglesRoundedRectangleYellow,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Rounded_squares/Rounded_square_blue':
      return {
        asset: RoundedSquaresRoundedSquareBlue,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_green':
      return {
        asset: RoundedSquaresRoundedSquareGreen,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_orange':
      return {
        asset: RoundedSquaresRoundedSquareOrange,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_pink':
      return {
        asset: RoundedSquaresRoundedSquarePink,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_purple':
      return {
        asset: RoundedSquaresRoundedSquarePurple,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_red':
      return {
        asset: RoundedSquaresRoundedSquareRed,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_turquoise':
      return {
        asset: RoundedSquaresRoundedSquareTurquoise,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rounded_squares/Rounded_square_yellow':
      return {
        asset: RoundedSquaresRoundedSquareYellow,

        width: 328.01498,
        height: 328.01498,
        aspectRatio: 1
      };
    case 'Rubber':
      return {
        asset: Rubber,

        width: 535.06884,
        height: 156.60967,
        aspectRatio: 3.4165760007028942
      };
    case 'Rubbish_bin':
      return {
        asset: RubbishBin,

        width: 296.224,
        height: 427.31371,
        aspectRatio: 0.6932237208115789
      };
    case 'Rucksack':
      return {
        asset: Rucksack,

        width: 207.08728,
        height: 250.56845,
        aspectRatio: 0.8264698927578471
      };
    case 'Rucksack_green':
      return {
        asset: RucksackGreen,

        width: 337.65903,
        height: 257.48825,
        aspectRatio: 1.311357042505823
      };
    case 'Rucksack_purple':
      return {
        asset: RucksackPurple,

        width: 337.65903,
        height: 257.48825,
        aspectRatio: 1.311357042505823
      };
    case 'Rucksack_red':
      return {
        asset: RucksackRed,

        width: 337.65903,
        height: 257.48825,
        aspectRatio: 1.311357042505823
      };
    case 'Sand_bag':
      return {
        asset: SandBag,

        width: 299.74219,
        height: 364.62903,
        aspectRatio: 0.8220469719594186
      };
    case 'Scalene_triangles/triangle_scalene':
      return {
        asset: ScaleneTrianglesTriangleScalene,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_blue':
      return {
        asset: ScaleneTrianglesTriangleScaleneBlue,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_blue_one_interior_angle_lhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneBlueOneInteriorAngleLhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_blue_one_interior_angle_rhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneBlueOneInteriorAngleRhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_green':
      return {
        asset: ScaleneTrianglesTriangleScaleneGreen,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_green_one_interior_angle_lhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneGreenOneInteriorAngleLhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_green_one_interior_angle_rhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneGreenOneInteriorAngleRhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_orange':
      return {
        asset: ScaleneTrianglesTriangleScaleneOrange,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_pink':
      return {
        asset: ScaleneTrianglesTriangleScalenePink,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_pink_one_interior_angle_lhs':
      return {
        asset: ScaleneTrianglesTriangleScalenePinkOneInteriorAngleLhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_pink_one_interior_angle_rhs':
      return {
        asset: ScaleneTrianglesTriangleScalenePinkOneInteriorAngleRhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_purple':
      return {
        asset: ScaleneTrianglesTriangleScalenePurple,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_purple_one_interior_angle_lhs':
      return {
        asset: ScaleneTrianglesTriangleScalenePurpleOneInteriorAngleLhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_purple_one_interior_angle_rhs':
      return {
        asset: ScaleneTrianglesTriangleScalenePurpleOneInteriorAngleRhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_red':
      return {
        asset: ScaleneTrianglesTriangleScaleneRed,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_turquoise':
      return {
        asset: ScaleneTrianglesTriangleScaleneTurquoise,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_white':
      return {
        asset: ScaleneTrianglesTriangleScaleneWhite,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_white_one_interior_angle_lhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneWhiteOneInteriorAngleLhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_white_one_interior_angle_rhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneWhiteOneInteriorAngleRhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_yellow':
      return {
        asset: ScaleneTrianglesTriangleScaleneYellow,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Scalene_triangles/triangle_scalene_yellow_one_interior_angle_lhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneYellowOneInteriorAngleLhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scalene_triangles/triangle_scalene_yellow_one_interior_angle_rhs':
      return {
        asset: ScaleneTrianglesTriangleScaleneYellowOneInteriorAngleRhs,

        width: 447,
        height: 331,
        aspectRatio: 1.350453172205438
      };
    case 'Scales/1000g_scale_100g':
      return {
        asset: Scales1000gScale100g,

        width: 298.10266,
        height: 340.586,
        aspectRatio: 0.8752639861885104
      };
    case 'Scales/1000g_scale_200g':
      return {
        asset: Scales1000gScale200g,

        width: 298.10266,
        height: 340.586,
        aspectRatio: 0.8752639861885104
      };
    case 'Scales/1000g_scale_20g_100g':
      return {
        asset: Scales1000gScale20g100g,

        width: 298.10266,
        height: 340.586,
        aspectRatio: 0.8752639861885104
      };
    case 'Scales/1000g_scale_250g':
      return {
        asset: Scales1000gScale250g,

        width: 298.10266,
        height: 340.586,
        aspectRatio: 0.8752639861885104
      };
    case 'Scales/1000g_scale_25g_100g':
      return {
        asset: Scales1000gScale25g100g,

        width: 298.10266,
        height: 340.586,
        aspectRatio: 0.8752639861885104
      };
    case 'Scales/1000g_scale_50g_100g':
      return {
        asset: Scales1000gScale50g100g,

        width: 298.103,
        height: 340.586,
        aspectRatio: 0.8752649844679464
      };
    case 'Scales/1000g_scale_50g_200g':
      return {
        asset: Scales1000gScale50g200g,

        width: 298.10266,
        height: 340.586,
        aspectRatio: 0.8752639861885104
      };
    case 'Scales/100g_scale_10g':
      return {
        asset: Scales100gScale10g,

        width: 297.58398,
        height: 340.01599,
        aspectRatio: 0.8752058395841913
      };
    case 'Scales/100g_scale_20g':
      return {
        asset: Scales100gScale20g,

        width: 297.58398,
        height: 340.01599,
        aspectRatio: 0.8752058395841913
      };
    case 'Scales/1_kg_scale_100g':
      return {
        asset: Scales1KgScale100g,

        width: 297.57599,
        height: 340.008,
        aspectRatio: 0.8752029069904238
      };
    case 'Scales/1_kg_scale_200_g':
      return {
        asset: Scales1KgScale200G,

        width: 298,
        height: 340,
        aspectRatio: 0.8764705882352941
      };
    case 'Scales/1_kg_scale_250_g':
      return {
        asset: Scales1KgScale250G,

        width: 297.57599,
        height: 340.008,
        aspectRatio: 0.8752029069904238
      };
    case 'Scales/1_kg_scale_500_g':
      return {
        asset: Scales1KgScale500G,

        width: 297.57599,
        height: 340.008,
        aspectRatio: 0.8752029069904238
      };
    case 'Scales/1_kg_scale_50g':
      return {
        asset: Scales1KgScale50g,

        width: 297.57599,
        height: 340.008,
        aspectRatio: 0.8752029069904238
      };
    case 'Scales/200g_scale_100g':
      return {
        asset: Scales200gScale100g,

        width: 298.97332,
        height: 341.539,
        aspectRatio: 0.8753709532439927
      };
    case 'Scales/200g_scale_10g':
      return {
        asset: Scales200gScale10g,

        width: 298.97332,
        height: 341.539,
        aspectRatio: 0.8753709532439927
      };
    case 'Scales/200g_scale_20g':
      return {
        asset: Scales200gScale20g,

        width: 296.97298,
        height: 341.539,
        aspectRatio: 0.8695141111264014
      };
    case 'Scales/3_kg_scale_100_g':
      return {
        asset: Scales3KgScale100G,

        width: 298.35299,
        height: 338.76401,
        aspectRatio: 0.8807104095857172
      };
    case 'Scales/3_kg_scale_200_g':
      return {
        asset: Scales3KgScale200G,

        width: 297.35333,
        height: 339.76401,
        aspectRatio: 0.8751760670590156
      };
    case 'Scales/3_kg_scale_250_g':
      return {
        asset: Scales3KgScale250G,

        width: 297.35333,
        height: 339.76401,
        aspectRatio: 0.8751760670590156
      };
    case 'Scales/3_kg_scale_500_g':
      return {
        asset: Scales3KgScale500G,

        width: 297.35333,
        height: 339.76401,
        aspectRatio: 0.8751760670590156
      };
    case 'Scales/4_kg_scale_100_g':
      return {
        asset: Scales4KgScale100G,
        defaultWidth: 298.561,
        defaultHeight: 340.224,
        width: 298.56101,
        height: 340.22399,
        aspectRatio: 0.8775424978115153
      };
    case 'Scales/4_kg_scale_200_g':
      return {
        asset: Scales4KgScale200G,

        width: 299,
        height: 341,
        aspectRatio: 0.8768328445747801
      };
    case 'Scales/4_kg_scale_250_g':
      return {
        asset: Scales4KgScale250G,

        width: 296.86133,
        height: 339.22401,
        aspectRatio: 0.8751188631960337
      };
    case 'Scales/4_kg_scale_500_g':
      return {
        asset: Scales4KgScale500G,

        width: 296.86133,
        height: 339.22401,
        aspectRatio: 0.8751188631960337
      };
    case 'Scales/500g_scale_10g':
      return {
        asset: Scales500gScale10g,

        width: 300.07733,
        height: 342.75101,
        aspectRatio: 0.8754965594412107
      };
    case 'Scales/500g_scale_20g':
      return {
        asset: Scales500gScale20g,

        width: 300.07733,
        height: 342.75101,
        aspectRatio: 0.8754965594412107
      };
    case 'Scales/500g_scale_25g':
      return {
        asset: Scales500gScale25g,

        width: 300.07733,
        height: 342.75101,
        aspectRatio: 0.8754965594412107
      };
    case 'Scales/5_kg_scale_100_g':
      return {
        asset: Scales5KgScale100G,

        width: 297.19467,
        height: 339.59,
        aspectRatio: 0.8751573073412056
      };
    case 'Scales/5_kg_scale_200_g':
      return {
        asset: Scales5KgScale200G,

        width: 297.19467,
        height: 339.59,
        aspectRatio: 0.8751573073412056
      };
    case 'Scales/5_kg_scale_250_g':
      return {
        asset: Scales5KgScale250G,

        width: 296.67334,
        height: 339.01701,
        aspectRatio: 0.8750986860511807
      };
    case 'Scales/5_kg_scale_500_g':
      return {
        asset: Scales5KgScale500G,

        width: 296.67334,
        height: 339.01701,
        aspectRatio: 0.8750986860511807
      };
    case 'Scales/6_kg_scale_200_g':
      return {
        asset: Scales6KgScale200G,

        width: 297.65332,
        height: 340.09202,
        aspectRatio: 0.8752140670633789
      };
    case 'Scales/6_kg_scale_250_g':
      return {
        asset: Scales6KgScale250G,

        width: 297.35333,
        height: 339.763,
        aspectRatio: 0.8751786686602133
      };
    case 'Scales/6_kg_scale_500_g':
      return {
        asset: Scales6KgScale500G,

        width: 297.35333,
        height: 339.763,
        aspectRatio: 0.8751786686602133
      };
    case 'Scales/Various_kg_scale_10kg':
      return {
        asset: ScalesVariousKgScale10kg,

        width: 296.67334,
        height: 339.017,
        aspectRatio: 0.8750987118640068
      };
    case 'Scales/Various_kg_scale_4kg':
      return {
        asset: ScalesVariousKgScale4kg,

        width: 296.86133,
        height: 339.224,
        aspectRatio: 0.8751188889937034
      };
    case 'Scales/Various_kg_scale_5kg':
      return {
        asset: ScalesVariousKgScale5kg,

        width: 296.67334,
        height: 339.017,
        aspectRatio: 0.8750987118640068
      };
    case 'Scales/Various_kg_scale_6kg':
      return {
        asset: ScalesVariousKgScale6kg,

        width: 297.65332,
        height: 340.09202,
        aspectRatio: 0.8752140670633789
      };
    case 'Scales/Various_kg_scale_7kg':
      return {
        asset: ScalesVariousKgScale7kg,

        width: 296.67334,
        height: 339.017,
        aspectRatio: 0.8750987118640068
      };
    case 'Scales/Various_kg_scale_8kg':
      return {
        asset: ScalesVariousKgScale8kg,

        width: 296.86133,
        height: 339.224,
        aspectRatio: 0.8751188889937034
      };
    case 'Scales/Various_kg_scale_9kg':
      return {
        asset: ScalesVariousKgScale9kg,

        width: 297.65332,
        height: 340.09202,
        aspectRatio: 0.8752140670633789
      };
    case 'Scales/arrow':
      return {
        asset: ScalesArrow,
        defaultWidth: 281.13699,
        defaultHeight: 281.13699,
        width: 281.13699,
        height: 281.13699,
        aspectRatio: 1
      };
    case 'Scales/blank_scale':
      return {
        asset: ScalesBlankScale,

        width: 296.86133,
        height: 339.224,
        aspectRatio: 0.8751188889937034
      };
    case 'Scales/kg_scale_2':
      return {
        asset: ScalesKgScale2,
        defaultWidth: 270,
        defaultHeight: 310,
        width: 270,
        height: 310,
        aspectRatio: 0.8709677419354839
      };
    case 'Scales/kg_scale_3':
      return {
        asset: ScalesKgScale3,
        defaultWidth: 270,
        defaultHeight: 310,
        width: 270,
        height: 310,
        aspectRatio: 0.8709677419354839
      };
    case 'Scales/kg_scale_4':
      return {
        asset: ScalesKgScale4,
        defaultWidth: 270,
        defaultHeight: 310,
        width: 270,
        height: 310,
        aspectRatio: 0.8709677419354839
      };
    case 'Scales/wooden_balance_scale_equal':
      return {
        asset: ScalesWoodenBalanceScaleEqual,

        width: 530.52667,
        height: 110.3564,
        aspectRatio: 4.807393771453219
      };
    case 'Scales/wooden_balance_scale_left':
      return {
        asset: ScalesWoodenBalanceScaleLeft,

        width: 510.03189,
        height: 191.86114,
        aspectRatio: 2.65833868181957
      };
    case 'Scales/wooden_balance_scale_right':
      return {
        asset: ScalesWoodenBalanceScaleRight,

        width: 510.03189,
        height: 191.5778,
        aspectRatio: 2.6622703152452947
      };
    case 'Scooter':
      return {
        asset: Scooter,

        width: 288.29907,
        height: 462.35824,
        aspectRatio: 0.623540460747493
      };
    case 'Semicircles/circle_semi':
      return {
        asset: SemicirclesCircleSemi,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_blue':
      return {
        asset: SemicirclesCircleSemiBlue,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_green':
      return {
        asset: SemicirclesCircleSemiGreen,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_orange':
      return {
        asset: SemicirclesCircleSemiOrange,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_pink':
      return {
        asset: SemicirclesCircleSemiPink,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_purple':
      return {
        asset: SemicirclesCircleSemiPurple,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_red':
      return {
        asset: SemicirclesCircleSemiRed,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_turquoise':
      return {
        asset: SemicirclesCircleSemiTurquoise,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_white':
      return {
        asset: SemicirclesCircleSemiWhite,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Semicircles/circle_semi_yellow':
      return {
        asset: SemicirclesCircleSemiYellow,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Shape_formulae/1_circle_2_triangles':
      return {
        asset: ShapeFormulae1Circle2Triangles,

        width: 154.02133,
        height: 330.89499,
        aspectRatio: 0.4654689090336484
      };
    case 'Shape_formulae/1_circle_4_squares':
      return {
        asset: ShapeFormulae1Circle4Squares,

        width: 323.23999,
        height: 323.23999,
        aspectRatio: 1
      };
    case 'Shape_formulae/1_square_2_circles':
      return {
        asset: ShapeFormulae1Square2Circles,

        width: 143.01066,
        height: 320.55972,
        aspectRatio: 0.44612797889890843
      };
    case 'Shape_formulae/1_square_4_triangles':
      return {
        asset: ShapeFormulae1Square4Triangles,

        width: 337.97363,
        height: 337.97363,
        aspectRatio: 1
      };
    case 'Shape_formulae/1_star_2_circles':
      return {
        asset: ShapeFormulae1Star2Circles,

        width: 255.14494,
        height: 244.45886,
        aspectRatio: 1.0437132039313282
      };
    case 'Shape_formulae/1_triangle_3_circles':
      return {
        asset: ShapeFormulae1Triangle3Circles,

        width: 244.47279,
        height: 244.17039,
        aspectRatio: 1.0012384794077611
      };
    case 'Shape_formulae/1_triangle_3_squares':
      return {
        asset: ShapeFormulae1Triangle3Squares,

        width: 274.27548,
        height: 262.61258,
        aspectRatio: 1.0444110483968438
      };
    case 'Shape_nets/Cone_net':
      return {
        asset: ShapeNetsConeNet,

        width: 549.59721,
        height: 557.07111,
        aspectRatio: 0.9865835799670172
      };
    case 'Shape_nets/Cone_net_noFill':
      return {
        asset: ShapeNetsConeNetNoFill,

        width: 549.6,
        height: 557.07,
        aspectRatio: 0.9865905541493887
      };
    case 'Shape_nets/Cone_net_without_tabs':
      return {
        asset: ShapeNetsConeNetWithoutTabs,

        width: 549.59721,
        height: 511.08435,
        aspectRatio: 1.0753551933257202
      };
    case 'Shape_nets/Cube_net':
      return {
        asset: ShapeNetsCubeNet,

        width: 803.93949,
        height: 641.51062,
        aspectRatio: 1.2531974762943128
      };
    case 'Shape_nets/Cube_net_noFill':
      return {
        asset: ShapeNetsCubeNetNoFill,

        width: 803.94,
        height: 641.5,
        aspectRatio: 1.2532190179267344
      };
    case 'Shape_nets/Cuboid_net':
      return {
        asset: ShapeNetsCuboidNet,

        width: 803.93943,
        height: 896.64905,
        aspectRatio: 0.8966043403492147
      };
    case 'Shape_nets/Cuboid_net_noFill':
      return {
        asset: ShapeNetsCuboidNetNoFill,

        width: 803.94,
        height: 896.65,
        aspectRatio: 0.8966040260971394
      };
    case 'Shape_nets/Cuboid_net_without_tabs':
      return {
        asset: ShapeNetsCuboidNetWithoutTabs,

        width: 966.36795,
        height: 773.49493,
        aspectRatio: 1.2493526622081415
      };
    case 'Shape_nets/Cylinder_net':
      return {
        asset: ShapeNetsCylinderNet,

        width: 803.94065,
        height: 637.71979,
        aspectRatio: 1.2606487404130897
      };
    case 'Shape_nets/Cylinder_net_noFill':
      return {
        asset: ShapeNetsCylinderNetNoFill,

        width: 805.91,
        height: 639.7,
        aspectRatio: 1.2598249179302796
      };
    case 'Shape_nets/Cylinder_net_without_tabs':
      return {
        asset: ShapeNetsCylinderNetWithoutTabs,

        width: 722.0391,
        height: 592.66797,
        aspectRatio: 1.2182860160301898
      };
    case 'Shape_nets/Hexagonal_prism_net':
      return {
        asset: ShapeNetsHexagonalPrismNet,

        width: 717.2537,
        height: 737.56262,
        aspectRatio: 0.9724648193261203
      };
    case 'Shape_nets/Hexagonal_prism_net_noFill':
      return {
        asset: ShapeNetsHexagonalPrismNetNoFill,

        width: 719.24,
        height: 739.55,
        aspectRatio: 0.9725373537962275
      };
    case 'Shape_nets/Hexagonal_prism_net_without_tabs':
      return {
        asset: ShapeNetsHexagonalPrismNetWithoutTabs,

        width: 517.31859,
        height: 729.52905,
        aspectRatio: 0.7091130778136936
      };
    case 'Shape_nets/Hexagonal_pyramid_net_without_tabs':
      return {
        asset: ShapeNetsHexagonalPyramidNetWithoutTabs,

        width: 601.2849,
        height: 693.99414,
        aspectRatio: 0.8664120708569671
      };
    case 'Shape_nets/Octagonal_prism_net_without_tabs':
      return {
        asset: ShapeNetsOctagonalPrismNetWithoutTabs,

        width: 966.3693,
        height: 768.41223,
        aspectRatio: 1.2576183229150322
      };
    case 'Shape_nets/Octagonal_pyramid_net_without_tabs':
      return {
        asset: ShapeNetsOctagonalPyramidNetWithoutTabs,

        width: 608.46861,
        height: 608.46997,
        aspectRatio: 0.9999977648855868
      };
    case 'Shape_nets/Pentagonal_prism_net':
      return {
        asset: ShapeNetsPentagonalPrismNet,

        width: 601.10063,
        height: 726.60901,
        aspectRatio: 0.827268340644441
      };
    case 'Shape_nets/Pentagonal_prism_net_noFill':
      return {
        asset: ShapeNetsPentagonalPrismNetNoFill,

        width: 603.09,
        height: 728.6,
        aspectRatio: 0.8277381279165523
      };
    case 'Shape_nets/Pentagonal_prism_net_without_tab':
      return {
        asset: ShapeNetsPentagonalPrismNetWithoutTab,

        width: 704.04393,
        height: 618.16821,
        aspectRatio: 1.1389196639536026
      };
    case 'Shape_nets/Pentagonal_pyramid_net_without_tabs':
      return {
        asset: ShapeNetsPentagonalPyramidNetWithoutTabs,

        width: 547.27953,
        height: 520.59161,
        aspectRatio: 1.051264598751409
      };
    case 'Shape_nets/Square-based_pyramid_net_without_tabs':
      return {
        asset: ShapeNetsSquareBasedPyramidNetWithoutTabs,

        width: 580.62028,
        height: 580.6203,
        aspectRatio: 0.9999999655540805
      };
    case 'Shape_nets/Squared_based_pyramid_net':
      return {
        asset: ShapeNetsSquaredBasedPyramidNet,

        width: 586.70451,
        height: 586.70453,
        aspectRatio: 0.999999965911291
      };
    case 'Shape_nets/Squared_based_pyramid_net_noFill':
      return {
        asset: ShapeNetsSquaredBasedPyramidNetNoFill,

        width: 588.68,
        height: 588.68,
        aspectRatio: 1
      };
    case 'Shape_nets/Tatrahedron_net_without_tabs':
      return {
        asset: ShapeNetsTatrahedronNetWithoutTabs,

        width: 449.34395,
        height: 389.4111,
        aspectRatio: 1.1539063729821775
      };
    case 'Shape_nets/Tetrahedron_net':
      return {
        asset: ShapeNetsTetrahedronNet,

        width: 469.27831,
        height: 423.93759,
        aspectRatio: 1.106951402917585
      };
    case 'Shape_nets/Tetrahedron_net_noFill':
      return {
        asset: ShapeNetsTetrahedronNetNoFill,

        width: 471.27,
        height: 425.94,
        aspectRatio: 1.1064234399211157
      };
    case 'Shape_nets/Triangular_prism_net':
      return {
        asset: ShapeNetsTriangularPrismNet,

        width: 1159.2418,
        height: 611.0661,
        aspectRatio: 1.8970808559008592
      };
    case 'Shape_nets/Triangular_prism_net_noFill':
      return {
        asset: ShapeNetsTriangularPrismNetNoFill,

        width: 1161.2,
        height: 613.05,
        aspectRatio: 1.8941358779871138
      };
    case 'Shape_nets/Triangular_prism_net_without_tabs':
      return {
        asset: ShapeNetsTriangularPrismNetWithoutTabs,

        width: 420.19918,
        height: 566.45679,
        aspectRatio: 0.7418027066106844
      };
    case 'Shape_nets/correctnetsofcubes1':
      return {
        asset: ShapeNetsCorrectnetsofcubes1,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes10':
      return {
        asset: ShapeNetsCorrectnetsofcubes10,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes11':
      return {
        asset: ShapeNetsCorrectnetsofcubes11,

        width: 262.12533,
        height: 651.31464,
        aspectRatio: 0.4024557623946546
      };
    case 'Shape_nets/correctnetsofcubes2':
      return {
        asset: ShapeNetsCorrectnetsofcubes2,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes3':
      return {
        asset: ShapeNetsCorrectnetsofcubes3,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes4':
      return {
        asset: ShapeNetsCorrectnetsofcubes4,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes5':
      return {
        asset: ShapeNetsCorrectnetsofcubes5,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes6':
      return {
        asset: ShapeNetsCorrectnetsofcubes6,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes7':
      return {
        asset: ShapeNetsCorrectnetsofcubes7,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes8':
      return {
        asset: ShapeNetsCorrectnetsofcubes8,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/correctnetsofcubes9':
      return {
        asset: ShapeNetsCorrectnetsofcubes9,

        width: 391.85598,
        height: 521.58533,
        aspectRatio: 0.7512787600832255
      };
    case 'Shape_nets/incorrectnetsofcubes1':
      return {
        asset: ShapeNetsIncorrectnetsofcubes1,

        width: 521.58531,
        height: 262.12534,
        aspectRatio: 1.989831696546393
      };
    case 'Shape_nets/incorrectnetsofcubes2':
      return {
        asset: ShapeNetsIncorrectnetsofcubes2,

        width: 521.58531,
        height: 262.12534,
        aspectRatio: 1.989831696546393
      };
    case 'Shape_nets/incorrectnetsofcubes3':
      return {
        asset: ShapeNetsIncorrectnetsofcubes3,

        width: 262.12585,
        height: 521.58521,
        aspectRatio: 0.5025561403476146
      };
    case 'Shape_nets/incorrectnetsofcubes4':
      return {
        asset: ShapeNetsIncorrectnetsofcubes4,

        width: 651.31492,
        height: 262.12585,
        aspectRatio: 2.484741279808916
      };
    case 'Shape_patterns/Linked_hexagons/3_blue_orange_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons3BlueOrangeHexagonPattern,

        width: 1047,
        height: 545,
        aspectRatio: 1.9211009174311926
      };
    case 'Shape_patterns/Linked_hexagons/3_green_yellow_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons3GreenYellowHexagonPattern,

        width: 1047,
        height: 545,
        aspectRatio: 1.9211009174311926
      };
    case 'Shape_patterns/Linked_hexagons/3_red_blue_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons3RedBlueHexagonPattern,

        width: 1047,
        height: 545,
        aspectRatio: 1.9211009174311926
      };
    case 'Shape_patterns/Linked_hexagons/4_blue_orange_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons4BlueOrangeHexagonPattern,

        width: 1359,
        height: 545,
        aspectRatio: 2.4935779816513763
      };
    case 'Shape_patterns/Linked_hexagons/4_green_yellow_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons4GreenYellowHexagonPattern,

        width: 1359,
        height: 545,
        aspectRatio: 2.4935779816513763
      };
    case 'Shape_patterns/Linked_hexagons/4_red_blue_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons4RedBlueHexagonPattern,

        width: 1359,
        height: 545,
        aspectRatio: 2.4935779816513763
      };
    case 'Shape_patterns/Linked_hexagons/5_blue_orange_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons5BlueOrangeHexagonPattern,

        width: 1672,
        height: 545,
        aspectRatio: 3.067889908256881
      };
    case 'Shape_patterns/Linked_hexagons/5_green_yellow_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons5GreenYellowHexagonPattern,

        width: 1672,
        height: 545,
        aspectRatio: 3.067889908256881
      };
    case 'Shape_patterns/Linked_hexagons/5_red_blue_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons5RedBlueHexagonPattern,

        width: 1672,
        height: 545,
        aspectRatio: 3.067889908256881
      };
    case 'Shape_patterns/Linked_hexagons/6_blue_orange_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons6BlueOrangeHexagonPattern,

        width: 1984,
        height: 547,
        aspectRatio: 3.627056672760512
      };
    case 'Shape_patterns/Linked_hexagons/6_green_yellow_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons6GreenYellowHexagonPattern,

        width: 1984,
        height: 547,
        aspectRatio: 3.627056672760512
      };
    case 'Shape_patterns/Linked_hexagons/6_red_blue_hexagon_pattern':
      return {
        asset: ShapePatternsLinkedHexagons6RedBlueHexagonPattern,

        width: 1984,
        height: 547,
        aspectRatio: 3.627056672760512
      };
    case 'Shape_patterns/Linked_octagons/3_blue_orange_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons3BlueOrangeOctagonPattern,

        width: 1011,
        height: 716,
        aspectRatio: 1.4120111731843576
      };
    case 'Shape_patterns/Linked_octagons/3_green_yellow_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons3GreenYellowOctagonPattern,

        width: 1011,
        height: 716,
        aspectRatio: 1.4120111731843576
      };
    case 'Shape_patterns/Linked_octagons/3_red_blue_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons3RedBlueOctagonPattern,

        width: 1011,
        height: 716,
        aspectRatio: 1.4120111731843576
      };
    case 'Shape_patterns/Linked_octagons/4_blue_orange_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons4BlueOrangeOctagonPattern,

        width: 1305,
        height: 717,
        aspectRatio: 1.8200836820083681
      };
    case 'Shape_patterns/Linked_octagons/4_green_yellow_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons4GreenYellowOctagonPattern,

        width: 1305,
        height: 717,
        aspectRatio: 1.8200836820083681
      };
    case 'Shape_patterns/Linked_octagons/4_red_blue_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons4RedBlueOctagonPattern,

        width: 1305,
        height: 717,
        aspectRatio: 1.8200836820083681
      };
    case 'Shape_patterns/Linked_octagons/5_blue_orange_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons5BlueOrangeOctagonPattern,

        width: 1601,
        height: 717,
        aspectRatio: 2.232914923291492
      };
    case 'Shape_patterns/Linked_octagons/5_green_yellow_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons5GreenYellowOctagonPattern,

        width: 1601,
        height: 717,
        aspectRatio: 2.232914923291492
      };
    case 'Shape_patterns/Linked_octagons/5_red_blue_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons5RedBlueOctagonPattern,

        width: 1601,
        height: 717,
        aspectRatio: 2.232914923291492
      };
    case 'Shape_patterns/Linked_octagons/6_blue_orange_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons6BlueOrangeOctagonPattern,

        width: 1896,
        height: 717,
        aspectRatio: 2.6443514644351462
      };
    case 'Shape_patterns/Linked_octagons/6_green_yellow_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons6GreenYellowOctagonPattern,

        width: 1896,
        height: 717,
        aspectRatio: 2.6443514644351462
      };
    case 'Shape_patterns/Linked_octagons/6_red_blue_octagon_pattern':
      return {
        asset: ShapePatternsLinkedOctagons6RedBlueOctagonPattern,

        width: 1896,
        height: 717,
        aspectRatio: 2.6443514644351462
      };
    case 'Shapes_made_from_cubes/Cube_cubes1':
      return {
        asset: ShapesMadeFromCubesCubeCubes1,

        width: 286.33666,
        height: 275.93918,
        aspectRatio: 1.0376803323109098
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes10':
      return {
        asset: ShapesMadeFromCubesCuboidCubes10,

        width: 1278.8748,
        height: 242.1252,
        aspectRatio: 5.281874005679707
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes11':
      return {
        asset: ShapesMadeFromCubesCuboidCubes11,

        width: 1278.8748,
        height: 345.28012,
        aspectRatio: 3.703876145548142
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes12':
      return {
        asset: ShapesMadeFromCubesCuboidCubes12,

        width: 1072.5647,
        height: 448.43506,
        aspectRatio: 2.3917949234388582
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes13':
      return {
        asset: ShapesMadeFromCubesCuboidCubes13,

        width: 1278.8748,
        height: 138.96933,
        aspectRatio: 9.202568653097773
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes2':
      return {
        asset: ShapesMadeFromCubesCuboidCubes2,

        width: 453.63372,
        height: 138.96933,
        aspectRatio: 3.2642721958866745
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes3':
      return {
        asset: ShapesMadeFromCubesCuboidCubes3,

        width: 453.63372,
        height: 242.1252,
        aspectRatio: 1.8735502128650796
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes4':
      return {
        asset: ShapesMadeFromCubesCuboidCubes4,

        width: 659.94407,
        height: 138.96933,
        aspectRatio: 4.748846885856037
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes5':
      return {
        asset: ShapesMadeFromCubesCuboidCubes5,

        width: 659.94407,
        height: 242.1252,
        aspectRatio: 2.7256314914763107
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes6':
      return {
        asset: ShapesMadeFromCubesCuboidCubes6,

        width: 866.25437,
        height: 138.96933,
        aspectRatio: 6.233421216033782
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes7':
      return {
        asset: ShapesMadeFromCubesCuboidCubes7,

        width: 866.25437,
        height: 242.1252,
        aspectRatio: 3.5777125635828075
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes8':
      return {
        asset: ShapesMadeFromCubesCuboidCubes8,

        width: 1072.5647,
        height: 138.96933,
        aspectRatio: 7.717995762086496
      };
    case 'Shapes_made_from_cubes/Cuboid_cubes9':
      return {
        asset: ShapesMadeFromCubesCuboidCubes9,

        width: 1072.5647,
        height: 242.1252,
        aspectRatio: 4.429793759592144
      };
    case 'Shapes_made_from_cubes/othershapescube1':
      return {
        asset: ShapesMadeFromCubesOthershapescube1,

        width: 350.47851,
        height: 242.1252,
        aspectRatio: 1.4475094290061505
      };
    case 'Shapes_made_from_cubes/othershapescube10':
      return {
        asset: ShapesMadeFromCubesOthershapescube10,

        width: 350.47851,
        height: 345.28012,
        aspectRatio: 1.0150555728490827
      };
    case 'Shapes_made_from_cubes/othershapescube11':
      return {
        asset: ShapesMadeFromCubesOthershapescube11,

        width: 350.47851,
        height: 448.43506,
        aspectRatio: 0.7815591180582535
      };
    case 'Shapes_made_from_cubes/othershapescube12':
      return {
        asset: ShapesMadeFromCubesOthershapescube12,

        width: 556.78917,
        height: 242.1252,
        aspectRatio: 2.2995919879467315
      };
    case 'Shapes_made_from_cubes/othershapescube13':
      return {
        asset: ShapesMadeFromCubesOthershapescube13,

        width: 1072.5647,
        height: 138.96933,
        aspectRatio: 7.717995762086496
      };
    case 'Shapes_made_from_cubes/othershapescube14':
      return {
        asset: ShapesMadeFromCubesOthershapescube14,

        width: 556.78917,
        height: 655.01904,
        aspectRatio: 0.8500350921096889
      };
    case 'Shapes_made_from_cubes/othershapescube15':
      return {
        asset: ShapesMadeFromCubesOthershapescube15,

        width: 247.32333,
        height: 551.58984,
        aspectRatio: 0.44838267869473447
      };
    case 'Shapes_made_from_cubes/othershapescube16':
      return {
        asset: ShapesMadeFromCubesOthershapescube16,

        width: 556.78917,
        height: 448.43506,
        aspectRatio: 1.2416272046168737
      };
    case 'Shapes_made_from_cubes/othershapescube17':
      return {
        asset: ShapesMadeFromCubesOthershapescube17,

        width: 453.63372,
        height: 757.90027,
        aspectRatio: 0.5985401219081239
      };
    case 'Shapes_made_from_cubes/othershapescube18':
      return {
        asset: ShapesMadeFromCubesOthershapescube18,

        width: 763.09916,
        height: 448.43506,
        aspectRatio: 1.7016937970907091
      };
    case 'Shapes_made_from_cubes/othershapescube19':
      return {
        asset: ShapesMadeFromCubesOthershapescube19,

        width: 247.32333,
        height: 861.05597,
        aspectRatio: 0.28723258256951634
      };
    case 'Shapes_made_from_cubes/othershapescube2':
      return {
        asset: ShapesMadeFromCubesOthershapescube2,

        width: 144.16839,
        height: 448.43506,
        aspectRatio: 0.3214922356873702
      };
    case 'Shapes_made_from_cubes/othershapescube20':
      return {
        asset: ShapesMadeFromCubesOthershapescube20,

        width: 763.09916,
        height: 242.1252,
        aspectRatio: 3.1516717797238782
      };
    case 'Shapes_made_from_cubes/othershapescube3':
      return {
        asset: ShapesMadeFromCubesOthershapescube3,

        width: 350.47851,
        height: 242.1252,
        aspectRatio: 1.4475094290061505
      };
    case 'Shapes_made_from_cubes/othershapescube4':
      return {
        asset: ShapesMadeFromCubesOthershapescube4,

        width: 350.47851,
        height: 242.1252,
        aspectRatio: 1.4475094290061505
      };
    case 'Shapes_made_from_cubes/othershapescube5':
      return {
        asset: ShapesMadeFromCubesOthershapescube5,

        width: 453.63372,
        height: 345.28012,
        aspectRatio: 1.3138136073400344
      };
    case 'Shapes_made_from_cubes/othershapescube6':
      return {
        asset: ShapesMadeFromCubesOthershapescube6,

        width: 350.47851,
        height: 345.28012,
        aspectRatio: 1.0150555728490827
      };
    case 'Shapes_made_from_cubes/othershapescube7':
      return {
        asset: ShapesMadeFromCubesOthershapescube7,

        width: 247.32333,
        height: 551.58984,
        aspectRatio: 0.44838267869473447
      };
    case 'Shapes_made_from_cubes/othershapescube8':
      return {
        asset: ShapesMadeFromCubesOthershapescube8,

        width: 556.78917,
        height: 242.1252,
        aspectRatio: 2.2995919879467315
      };
    case 'Shapes_made_from_cubes/othershapescube9':
      return {
        asset: ShapesMadeFromCubesOthershapescube9,

        width: 866.25437,
        height: 138.96933,
        aspectRatio: 6.233421216033782
      };
    case 'Shapes_on_grids/Compound_shape10_grid':
      return {
        asset: ShapesOnGridsCompoundShape10Grid,

        width: 836.1099,
        height: 785.91693,
        aspectRatio: 1.0638654902115419
      };
    case 'Shapes_on_grids/Compound_shape11_grid':
      return {
        asset: ShapesOnGridsCompoundShape11Grid,

        width: 837.28208,
        height: 785.91693,
        aspectRatio: 1.065356970996922
      };
    case 'Shapes_on_grids/Compound_shape12_grid':
      return {
        asset: ShapesOnGridsCompoundShape12Grid,

        width: 837.28208,
        height: 785.91693,
        aspectRatio: 1.065356970996922
      };
    case 'Shapes_on_grids/Compound_shape13_grid':
      return {
        asset: ShapesOnGridsCompoundShape13Grid,

        width: 837.28208,
        height: 785.91693,
        aspectRatio: 1.065356970996922
      };
    case 'Shapes_on_grids/Compound_shape14_grid':
      return {
        asset: ShapesOnGridsCompoundShape14Grid,

        width: 837.28208,
        height: 785.91693,
        aspectRatio: 1.065356970996922
      };
    case 'Shapes_on_grids/Compound_shape15_grid':
      return {
        asset: ShapesOnGridsCompoundShape15Grid,

        width: 837.28208,
        height: 785.91693,
        aspectRatio: 1.065356970996922
      };
    case 'Shapes_on_grids/Compound_shape16_grid':
      return {
        asset: ShapesOnGridsCompoundShape16Grid,

        width: 837.28208,
        height: 785.91693,
        aspectRatio: 1.065356970996922
      };
    case 'Shapes_on_grids/Compound_shape1_grid':
      return {
        asset: ShapesOnGridsCompoundShape1Grid,

        width: 838.1099,
        height: 785.91693,
        aspectRatio: 1.0664102884257756
      };
    case 'Shapes_on_grids/Compound_shape2_grid':
      return {
        asset: ShapesOnGridsCompoundShape2Grid,

        width: 838.1099,
        height: 785.91693,
        aspectRatio: 1.0664102884257756
      };
    case 'Shapes_on_grids/Compound_shape3_grid':
      return {
        asset: ShapesOnGridsCompoundShape3Grid,

        width: 842.1099,
        height: 785.91693,
        aspectRatio: 1.071499884854243
      };
    case 'Shapes_on_grids/Compound_shape4_grid':
      return {
        asset: ShapesOnGridsCompoundShape4Grid,

        width: 840.1099,
        height: 785.91693,
        aspectRatio: 1.0689550866400093
      };
    case 'Shapes_on_grids/Compound_shape5_grid':
      return {
        asset: ShapesOnGridsCompoundShape5Grid,

        width: 838.57529,
        height: 785.91693,
        aspectRatio: 1.0670024502462365
      };
    case 'Shapes_on_grids/Compound_shape6_grid':
      return {
        asset: ShapesOnGridsCompoundShape6Grid,

        width: 836.1099,
        height: 785.91693,
        aspectRatio: 1.0638654902115419
      };
    case 'Shapes_on_grids/Compound_shape7_grid':
      return {
        asset: ShapesOnGridsCompoundShape7Grid,

        width: 836.1099,
        height: 785.91693,
        aspectRatio: 1.0638654902115419
      };
    case 'Shapes_on_grids/Compound_shape8_grid':
      return {
        asset: ShapesOnGridsCompoundShape8Grid,

        width: 836.1099,
        height: 785.91693,
        aspectRatio: 1.0638654902115419
      };
    case 'Shapes_on_grids/Compound_shape9_grid':
      return {
        asset: ShapesOnGridsCompoundShape9Grid,

        width: 836.1099,
        height: 785.91693,
        aspectRatio: 1.0638654902115419
      };
    case 'Shapes_on_grids/Curved_shape1':
      return {
        asset: ShapesOnGridsCurvedShape1,

        width: 1185.8714,
        height: 867.94598,
        aspectRatio: 1.3662963218056499
      };
    case 'Shapes_on_grids/Curved_shape2':
      return {
        asset: ShapesOnGridsCurvedShape2,

        width: 1187.8714,
        height: 867.94598,
        aspectRatio: 1.3686006126786832
      };
    case 'Shapes_on_grids/Curved_shape3':
      return {
        asset: ShapesOnGridsCurvedShape3,

        width: 1189.8714,
        height: 867.94598,
        aspectRatio: 1.3709049035517165
      };
    case 'Shapes_on_grids/Curved_shape4':
      return {
        asset: ShapesOnGridsCurvedShape4,

        width: 1187.8714,
        height: 867.94598,
        aspectRatio: 1.3686006126786832
      };
    case 'Shapes_on_grids/Curved_shape5':
      return {
        asset: ShapesOnGridsCurvedShape5,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Curved_shape6':
      return {
        asset: ShapesOnGridsCurvedShape6,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Curved_shape7':
      return {
        asset: ShapesOnGridsCurvedShape7,

        width: 1189.8714,
        height: 867.94598,
        aspectRatio: 1.3709049035517165
      };
    case 'Shapes_on_grids/Curved_shape8':
      return {
        asset: ShapesOnGridsCurvedShape8,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Equilateral_triangle1_grid':
      return {
        asset: ShapesOnGridsEquilateralTriangle1Grid,

        width: 1152.3704,
        height: 866.52802,
        aspectRatio: 1.3298709025012256
      };
    case 'Shapes_on_grids/Equilateral_triangle2_grid':
      return {
        asset: ShapesOnGridsEquilateralTriangle2Grid,

        width: 1150.0368,
        height: 866.52802,
        aspectRatio: 1.3271778562913639
      };
    case 'Shapes_on_grids/Equilateral_triangle3_grid':
      return {
        asset: ShapesOnGridsEquilateralTriangle3Grid,

        width: 1150.0368,
        height: 866.52802,
        aspectRatio: 1.3271778562913639
      };
    case 'Shapes_on_grids/Equilateral_triangle4_grid':
      return {
        asset: ShapesOnGridsEquilateralTriangle4Grid,

        width: 1150.0368,
        height: 866.52802,
        aspectRatio: 1.3271778562913639
      };
    case 'Shapes_on_grids/Island_shape_a1':
      return {
        asset: ShapesOnGridsIslandShapeA1,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_a2':
      return {
        asset: ShapesOnGridsIslandShapeA2,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_a3':
      return {
        asset: ShapesOnGridsIslandShapeA3,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_a4':
      return {
        asset: ShapesOnGridsIslandShapeA4,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_a5':
      return {
        asset: ShapesOnGridsIslandShapeA5,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_a6':
      return {
        asset: ShapesOnGridsIslandShapeA6,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_b1':
      return {
        asset: ShapesOnGridsIslandShapeB1,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_b2':
      return {
        asset: ShapesOnGridsIslandShapeB2,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_b3':
      return {
        asset: ShapesOnGridsIslandShapeB3,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_b4':
      return {
        asset: ShapesOnGridsIslandShapeB4,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_b5':
      return {
        asset: ShapesOnGridsIslandShapeB5,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Island_shape_b6':
      return {
        asset: ShapesOnGridsIslandShapeB6,

        width: 1149.7848,
        height: 872.26501,
        aspectRatio: 1.3181599477434043
      };
    case 'Shapes_on_grids/Isosceles_triangle1_grid':
      return {
        asset: ShapesOnGridsIsoscelesTriangle1Grid,

        width: 1148.5641,
        height: 866.52869,
        aspectRatio: 1.3254772903133767
      };
    case 'Shapes_on_grids/Isosceles_triangle2_grid':
      return {
        asset: ShapesOnGridsIsoscelesTriangle2Grid,

        width: 1148.5641,
        height: 866.52802,
        aspectRatio: 1.3254783151732359
      };
    case 'Shapes_on_grids/Isosceles_triangle3_grid':
      return {
        asset: ShapesOnGridsIsoscelesTriangle3Grid,

        width: 1148.5641,
        height: 866.52802,
        aspectRatio: 1.3254783151732359
      };
    case 'Shapes_on_grids/Isosceles_triangle4_grid':
      return {
        asset: ShapesOnGridsIsoscelesTriangle4Grid,

        width: 1148.5641,
        height: 866.52802,
        aspectRatio: 1.3254783151732359
      };
    case 'Shapes_on_grids/Isosceles_triangle5_grid':
      return {
        asset: ShapesOnGridsIsoscelesTriangle5Grid,

        width: 1148.5641,
        height: 866.52802,
        aspectRatio: 1.3254783151732359
      };
    case 'Shapes_on_grids/Isosceles_triangle6_grid':
      return {
        asset: ShapesOnGridsIsoscelesTriangle6Grid,

        width: 1148.5641,
        height: 866.52802,
        aspectRatio: 1.3254783151732359
      };
    case 'Shapes_on_grids/Leaf1_ongrid':
      return {
        asset: ShapesOnGridsLeaf1Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Leaf2_ongrid':
      return {
        asset: ShapesOnGridsLeaf2Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Leaf3_ongrid':
      return {
        asset: ShapesOnGridsLeaf3Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Leaf4_ongrid':
      return {
        asset: ShapesOnGridsLeaf4Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Leaf5_ongrid':
      return {
        asset: ShapesOnGridsLeaf5Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Leaf6_ongrid':
      return {
        asset: ShapesOnGridsLeaf6Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Leaf7_ongrid':
      return {
        asset: ShapesOnGridsLeaf7Ongrid,

        width: 1193.8714,
        height: 868.10626,
        aspectRatio: 1.3752595218009371
      };
    case 'Shapes_on_grids/Leaf8_ongrid':
      return {
        asset: ShapesOnGridsLeaf8Ongrid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Opaque_rectangle1_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle1Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle2_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle2Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle3_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle3Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle4_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle4Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle5_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle5Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle6_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle6Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle7_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle7Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Opaque_rectangle8_grid':
      return {
        asset: ShapesOnGridsOpaqueRectangle8Grid,

        width: 919.54697,
        height: 466.80707,
        aspectRatio: 1.9698651307916137
      };
    case 'Shapes_on_grids/Parallelogram10_grid':
      return {
        asset: ShapesOnGridsParallelogram10Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram11_grid':
      return {
        asset: ShapesOnGridsParallelogram11Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram12_grid':
      return {
        asset: ShapesOnGridsParallelogram12Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram13_grid':
      return {
        asset: ShapesOnGridsParallelogram13Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram14_grid':
      return {
        asset: ShapesOnGridsParallelogram14Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram15_grid':
      return {
        asset: ShapesOnGridsParallelogram15Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram16_grid':
      return {
        asset: ShapesOnGridsParallelogram16Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram17_grid':
      return {
        asset: ShapesOnGridsParallelogram17Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram18_grid':
      return {
        asset: ShapesOnGridsParallelogram18Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram19_grid':
      return {
        asset: ShapesOnGridsParallelogram19Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram1_grid':
      return {
        asset: ShapesOnGridsParallelogram1Grid,

        width: 1632.2212,
        height: 822.89081,
        aspectRatio: 1.9835209971539236
      };
    case 'Shapes_on_grids/Parallelogram20_grid':
      return {
        asset: ShapesOnGridsParallelogram20Grid,

        width: 1632.3212,
        height: 822.8908,
        aspectRatio: 1.983642544065385
      };
    case 'Shapes_on_grids/Parallelogram2_grid':
      return {
        asset: ShapesOnGridsParallelogram2Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram3_grid':
      return {
        asset: ShapesOnGridsParallelogram3Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram4_grid':
      return {
        asset: ShapesOnGridsParallelogram4Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram5_grid':
      return {
        asset: ShapesOnGridsParallelogram5Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram6_grid':
      return {
        asset: ShapesOnGridsParallelogram6Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram7_grid':
      return {
        asset: ShapesOnGridsParallelogram7Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram8_grid':
      return {
        asset: ShapesOnGridsParallelogram8Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Parallelogram9_grid':
      return {
        asset: ShapesOnGridsParallelogram9Grid,

        width: 1632.3211,
        height: 822.89081,
        aspectRatio: 1.9836423984367986
      };
    case 'Shapes_on_grids/Right_angle_triangle1_grid':
      return {
        asset: ShapesOnGridsRightAngleTriangle1Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Right_angle_triangle2_grid':
      return {
        asset: ShapesOnGridsRightAngleTriangle2Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Right_angle_triangle3_grid':
      return {
        asset: ShapesOnGridsRightAngleTriangle3Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Right_angle_triangle4_grid':
      return {
        asset: ShapesOnGridsRightAngleTriangle4Grid,

        width: 1146.5641,
        height: 866.52826,
        aspectRatio: 1.3231698871540554
      };
    case 'Shapes_on_grids/Right_angle_triangle5_grid':
      return {
        asset: ShapesOnGridsRightAngleTriangle5Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Right_angle_triangle6_grid':
      return {
        asset: ShapesOnGridsRightAngleTriangle6Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Scalene_triangle1_grid':
      return {
        asset: ShapesOnGridsScaleneTriangle1Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Scalene_triangle2_grid':
      return {
        asset: ShapesOnGridsScaleneTriangle2Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Scalene_triangle3_grid':
      return {
        asset: ShapesOnGridsScaleneTriangle3Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Scalene_triangle4_grid':
      return {
        asset: ShapesOnGridsScaleneTriangle4Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Scalene_triangle5_grid':
      return {
        asset: ShapesOnGridsScaleneTriangle5Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Scalene_triangle6_grid':
      return {
        asset: ShapesOnGridsScaleneTriangle6Grid,

        width: 1146.5641,
        height: 866.52802,
        aspectRatio: 1.3231702536289596
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelA,

        width: 918.90479,
        height: 866.22266,
        aspectRatio: 1.0608182311924281
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelB,

        width: 919.38525,
        height: 866.22266,
        aspectRatio: 1.061372892276912
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelC,

        width: 869,
        height: 867,
        aspectRatio: 1.0023068050749713
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelD,

        width: 918.65118,
        height: 866.22266,
        aspectRatio: 1.060525454275232
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelE,

        width: 919.49792,
        height: 866.22266,
        aspectRatio: 1.0615029627601753
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelF,

        width: 917.8504,
        height: 866.22266,
        aspectRatio: 1.0596010037419248
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelG':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelG,

        width: 919.05316,
        height: 866.22266,
        aspectRatio: 1.060989515097654
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_missing_labelH':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridMissingLabelH,

        width: 919.09265,
        height: 866.22266,
        aspectRatio: 1.0610351038380825
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape10_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape10GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11Grid,

        width: 919.54709,
        height: 866.00549,
        aspectRatio: 1.0618259359995512
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelA,

        width: 917.5462,
        height: 866.005,
        aspectRatio: 1.059516053602462
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelB,

        width: 918.95648,
        height: 866.005,
        aspectRatio: 1.0611445430453637
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelC,

        width: 916.48175,
        height: 866.005,
        aspectRatio: 1.0582869036552907
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelD,

        width: 917.89777,
        height: 866.005,
        aspectRatio: 1.0599220212354432
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelE,

        width: 917.38934,
        height: 866.005,
        aspectRatio: 1.0593349230085276
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelF,

        width: 917.60962,
        height: 866.005,
        aspectRatio: 1.0595892864359906
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelG':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelG,

        width: 917.38971,
        height: 866.005,
        aspectRatio: 1.059335350257793
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_missing_labelH':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridMissingLabelH,

        width: 918.17419,
        height: 866.005,
        aspectRatio: 1.060241211078458
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape11_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape11GridNolabels,

        width: 919.55,
        height: 866.01,
        aspectRatio: 1.0618237664692094
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12Grid,

        width: 919.54709,
        height: 866.00549,
        aspectRatio: 1.0618259359995512
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelA,

        width: 917.80011,
        height: 866.005,
        aspectRatio: 1.0598092505239578
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelB,

        width: 918.38049,
        height: 866.005,
        aspectRatio: 1.060479431412059
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelC,

        width: 916.96338,
        height: 866.005,
        aspectRatio: 1.0588430551786654
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelD,

        width: 917.73273,
        height: 866.005,
        aspectRatio: 1.0597314449685624
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelE,

        width: 919.54645,
        height: 866.005,
        aspectRatio: 1.0618257977725303
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelF,

        width: 917.54645,
        height: 866.005,
        aspectRatio: 1.059516342284398
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelG':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelG,

        width: 921.54645,
        height: 866.005,
        aspectRatio: 1.0641352532606625
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_missing_labelH':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridMissingLabelH,

        width: 917.10956,
        height: 866.005,
        aspectRatio: 1.0590118532802928
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape12_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape12GridNolabels,

        width: 919.55,
        height: 866.01,
        aspectRatio: 1.0618237664692094
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelA,

        width: 918.66156,
        height: 866.22266,
        aspectRatio: 1.0605374373374161
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelB,

        width: 918.2829,
        height: 866.22266,
        aspectRatio: 1.0601002979995928
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelC,

        width: 917.50964,
        height: 866.22266,
        aspectRatio: 1.0592076175887617
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelD,

        width: 918.71155,
        height: 866.22266,
        aspectRatio: 1.0605951476725395
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelE,

        width: 919.18176,
        height: 866.22266,
        aspectRatio: 1.061137975771726
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridMissingLabelF,

        width: 918.17609,
        height: 866.22266,
        aspectRatio: 1.0599769925206066
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape1_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape1GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelA,

        width: 918.61816,
        height: 866.22266,
        aspectRatio: 1.0604873347460109
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelB,

        width: 918.76331,
        height: 866.22266,
        aspectRatio: 1.060654901362197
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelC,

        width: 919.43298,
        height: 866.22266,
        aspectRatio: 1.0614279935830817
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelD,

        width: 919.26526,
        height: 866.22266,
        aspectRatio: 1.0612343713104897
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelE,

        width: 919.31494,
        height: 866.22266,
        aspectRatio: 1.06129172376996
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridMissingLabelF,

        width: 918.80481,
        height: 866.22266,
        aspectRatio: 1.0607028105221814
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape2_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape2GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelA,

        width: 920.72296,
        height: 866.22266,
        aspectRatio: 1.0629171949854093
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelB,

        width: 920.6781,
        height: 866.22266,
        aspectRatio: 1.0628654069151227
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelC,

        width: 919.62262,
        height: 866.22266,
        aspectRatio: 1.0616469211276462
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelD,

        width: 918.25494,
        height: 866.22266,
        aspectRatio: 1.0600680199245769
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelE,

        width: 917.51794,
        height: 866.22266,
        aspectRatio: 1.0592171994207586
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridMissingLabelF,

        width: 918.90564,
        height: 866.22266,
        aspectRatio: 1.0608192124643794
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape3_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape3GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelA,

        width: 919.90021,
        height: 866.22266,
        aspectRatio: 1.06196738145825
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelB,

        width: 918.06561,
        height: 866.22266,
        aspectRatio: 1.0598494502556652
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelC,

        width: 919.12378,
        height: 866.22266,
        aspectRatio: 1.0610710414802587
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelD,

        width: 920.24615,
        height: 866.22266,
        aspectRatio: 1.062366747598129
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelE,

        width: 922.28473,
        height: 866.22266,
        aspectRatio: 1.0647201609803187
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridMissingLabelF,

        width: 920.15936,
        height: 866.22266,
        aspectRatio: 1.0622665539596943
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape4_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape4GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelA,

        width: 918.15662,
        height: 866.22266,
        aspectRatio: 1.0599545156207295
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelB,

        width: 918.94495,
        height: 866.22266,
        aspectRatio: 1.0608645934060417
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelC,

        width: 918.112,
        height: 866.22266,
        aspectRatio: 1.0599030046154645
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelD,

        width: 917.92078,
        height: 866.22266,
        aspectRatio: 1.0596822530595078
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelE,

        width: 919.70911,
        height: 866.22266,
        aspectRatio: 1.061746768434804
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridMissingLabelF,

        width: 921.86163,
        height: 866.22266,
        aspectRatio: 1.0642317184359966
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape5_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape5GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelA,

        width: 920.45178,
        height: 866.22266,
        aspectRatio: 1.0626041345997574
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelB,

        width: 917.41138,
        height: 866.22266,
        aspectRatio: 1.05909418255117
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelC,

        width: 918.8053,
        height: 866.22266,
        aspectRatio: 1.0607033761966005
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelD,

        width: 918.52966,
        height: 866.22266,
        aspectRatio: 1.0603851670192974
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelE,

        width: 918.04633,
        height: 866.22266,
        aspectRatio: 1.0598271926989302
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridMissingLabelF,

        width: 918.44818,
        height: 866.22266,
        aspectRatio: 1.0602911034444653
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape6_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape6GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelA,

        width: 920.06421,
        height: 866.22266,
        aspectRatio: 1.0621567092230073
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelB,

        width: 919.30237,
        height: 866.22266,
        aspectRatio: 1.0612772124894538
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelC,

        width: 919.09625,
        height: 866.22266,
        aspectRatio: 1.0610392598134064
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelD,

        width: 917.32776,
        height: 866.22266,
        aspectRatio: 1.0589976484798955
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelE,

        width: 919.80902,
        height: 866.22266,
        aspectRatio: 1.0618621082944193
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelF,

        width: 921.24457,
        height: 866.22266,
        aspectRatio: 1.0635193611767209
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelG':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelG,

        width: 920.56757,
        height: 866.22266,
        aspectRatio: 1.0627378069283018
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_missing_labelH':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridMissingLabelH,

        width: 917.22736,
        height: 866.22266,
        aspectRatio: 1.058881742945861
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape7_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape7GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelA,

        width: 918.38373,
        height: 866.22266,
        aspectRatio: 1.060216699941791
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelB,

        width: 919.96875,
        height: 866.22266,
        aspectRatio: 1.0620465066106675
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelC,

        width: 919.82355,
        height: 866.22266,
        aspectRatio: 1.0618788822726017
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelD,

        width: 918.30646,
        height: 866.22266,
        aspectRatio: 1.060127496549213
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelE,

        width: 919.19196,
        height: 866.22266,
        aspectRatio: 1.0611497510351438
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelF,

        width: 920.95465,
        height: 866.22266,
        aspectRatio: 1.0631846666306328
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelG':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelG,

        width: 919.74805,
        height: 866.22266,
        aspectRatio: 1.061791722234558
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_missing_labelH':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridMissingLabelH,

        width: 918.38562,
        height: 866.22266,
        aspectRatio: 1.060218881828836
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape8_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape8GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9Grid,

        width: 919.32975,
        height: 866.22278,
        aspectRatio: 1.0613086739649125
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelA':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelA,

        width: 918.39606,
        height: 866.22241,
        aspectRatio: 1.060231240149975
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelB':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelB,

        width: 918.80011,
        height: 866.22241,
        aspectRatio: 1.06069769079283
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelC':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelC,

        width: 920.03217,
        height: 866.22241,
        aspectRatio: 1.0621200275804454
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelD':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelD,

        width: 919.64868,
        height: 866.22241,
        aspectRatio: 1.0616773121812908
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelE':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelE,

        width: 918.32886,
        height: 866.22241,
        aspectRatio: 1.0601536619215381
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelF':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelF,

        width: 918.04492,
        height: 866.22241,
        aspectRatio: 1.0598258708176345
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelG':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelG,

        width: 918.13629,
        height: 866.22241,
        aspectRatio: 1.059931351810674
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_missing_labelH':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridMissingLabelH,

        width: 920.64667,
        height: 866.22241,
        aspectRatio: 1.0628294296842309
      };
    case 'Shapes_on_grids/Translucent_rectiliear_shape9_grid_nolabels':
      return {
        asset: ShapesOnGridsTranslucentRectiliearShape9GridNolabels,

        width: 919.33,
        height: 866.22,
        aspectRatio: 1.061312368682321
      };
    case 'Shapes_on_grids/Various_shapes1_grid':
      return {
        asset: ShapesOnGridsVariousShapes1Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes2_grid':
      return {
        asset: ShapesOnGridsVariousShapes2Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes3_grid':
      return {
        asset: ShapesOnGridsVariousShapes3Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes4_grid':
      return {
        asset: ShapesOnGridsVariousShapes4Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes5_grid':
      return {
        asset: ShapesOnGridsVariousShapes5Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes6_grid':
      return {
        asset: ShapesOnGridsVariousShapes6Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes7_grid':
      return {
        asset: ShapesOnGridsVariousShapes7Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes8_grid':
      return {
        asset: ShapesOnGridsVariousShapes8Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_on_grids/Various_shapes9_grid':
      return {
        asset: ShapesOnGridsVariousShapes9Grid,

        width: 1193.8714,
        height: 867.94598,
        aspectRatio: 1.3755134852977833
      };
    case 'Shapes_with_arrows/2_circles_with_arrows':
      return {
        asset: ShapesWithArrows2CirclesWithArrows,

        width: 652.55864,
        height: 652.56,
        aspectRatio: 0.9999979159004536
      };
    case 'Shapes_with_arrows/Compound_shape1_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape1Arrows,

        width: 756.88572,
        height: 411.28784,
        aspectRatio: 1.8402822704410613
      };
    case 'Shapes_with_arrows/Compound_shape2_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape2Arrows,

        width: 403.41558,
        height: 516.71478,
        aspectRatio: 0.7807316446415563
      };
    case 'Shapes_with_arrows/Compound_shape3_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape3Arrows,

        width: 486.19411,
        height: 431.83398,
        aspectRatio: 1.1258820114155907
      };
    case 'Shapes_with_arrows/Compound_shape4_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape4Arrows,

        width: 363.25972,
        height: 487.65506,
        aspectRatio: 0.7449112083446853
      };
    case 'Shapes_with_arrows/Compound_shape5_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape5Arrows,

        width: 763.57127,
        height: 347.14346,
        aspectRatio: 2.1995842007220876
      };
    case 'Shapes_with_arrows/Compound_shape6_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape6Arrows,

        width: 525.35771,
        height: 439.85425,
        aspectRatio: 1.1943904372868968
      };
    case 'Shapes_with_arrows/Compound_shape7_arrows':
      return {
        asset: ShapesWithArrowsCompoundShape7Arrows,

        width: 662.76597,
        height: 378.31265,
        aspectRatio: 1.751900101675162
      };
    case 'Shapes_with_arrows/Equilateral_triangle_1__arrow':
      return {
        asset: ShapesWithArrowsEquilateralTriangle1Arrow,

        width: 844.08781,
        height: 752.28174,
        aspectRatio: 1.1220368182803426
      };
    case 'Shapes_with_arrows/Irregular_heptagon1_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHeptagon1AllArrows,

        width: 547.33598,
        height: 785.87732,
        aspectRatio: 0.6964649138875771
      };
    case 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHeptagon1AllArrowsBlue,

        width: 547.33598,
        height: 785.87732,
        aspectRatio: 0.6964649138875771
      };
    case 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHeptagon1AllArrowsGreen,

        width: 547.33598,
        height: 785.87732,
        aspectRatio: 0.6964649138875771
      };
    case 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHeptagon1AllArrowsPink,

        width: 547.33598,
        height: 785.87732,
        aspectRatio: 0.6964649138875771
      };
    case 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHeptagon1AllArrowsPurple,

        width: 547.33598,
        height: 785.87732,
        aspectRatio: 0.6964649138875771
      };
    case 'Shapes_with_arrows/Irregular_heptagon1_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHeptagon1AllArrowsYellow,

        width: 547.33598,
        height: 785.87732,
        aspectRatio: 0.6964649138875771
      };
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon1AllArrows,

        width: 830.28794,
        height: 431.6228,
        aspectRatio: 1.9236424489160444
      };
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon1AllArrowsBlue,

        width: 830.28794,
        height: 431.6228,
        aspectRatio: 1.9236424489160444
      };
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon1AllArrowsGreen,

        width: 830.28794,
        height: 431.6228,
        aspectRatio: 1.9236424489160444
      };
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon1AllArrowsPink,

        width: 830.28794,
        height: 431.6228,
        aspectRatio: 1.9236424489160444
      };
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon1AllArrowsPurple,

        width: 830.28794,
        height: 431.6228,
        aspectRatio: 1.9236424489160444
      };
    case 'Shapes_with_arrows/Irregular_hexagon1_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon1AllArrowsYellow,

        width: 830.28794,
        height: 431.6228,
        aspectRatio: 1.9236424489160444
      };
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon2AllArrows,

        width: 605.57622,
        height: 312.70105,
        aspectRatio: 1.9365979743272368
      };
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon2AllArrowsBlue,

        width: 605.57622,
        height: 312.70105,
        aspectRatio: 1.9365979743272368
      };
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon2AllArrowsGreen,

        width: 605.57622,
        height: 312.70105,
        aspectRatio: 1.9365979743272368
      };
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon2AllArrowsPink,

        width: 605.57622,
        height: 312.70105,
        aspectRatio: 1.9365979743272368
      };
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon2AllArrowsPurple,

        width: 605.57622,
        height: 312.70105,
        aspectRatio: 1.9365979743272368
      };
    case 'Shapes_with_arrows/Irregular_hexagon2_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon2AllArrowsYellow,

        width: 605.57622,
        height: 312.70105,
        aspectRatio: 1.9365979743272368
      };
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon3AllArrows,

        width: 719.75584,
        height: 420.82959,
        aspectRatio: 1.7103261203662035
      };
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon3AllArrowsBlue,

        width: 719.75584,
        height: 420.82959,
        aspectRatio: 1.7103261203662035
      };
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon3AllArrowsGreen,

        width: 719.75584,
        height: 420.82959,
        aspectRatio: 1.7103261203662035
      };
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon3AllArrowsPink,

        width: 719.75584,
        height: 420.82959,
        aspectRatio: 1.7103261203662035
      };
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon3AllArrowsPurple,

        width: 719.75584,
        height: 420.82959,
        aspectRatio: 1.7103261203662035
      };
    case 'Shapes_with_arrows/Irregular_hexagon3_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon3AllArrowsYellow,

        width: 719.75584,
        height: 420.82959,
        aspectRatio: 1.7103261203662035
      };
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon4AllArrows,

        width: 558.56316,
        height: 693.86694,
        aspectRatio: 0.8050003938795528
      };
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon4AllArrowsBlue,

        width: 558.56316,
        height: 693.86694,
        aspectRatio: 0.8050003938795528
      };
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon4AllArrowsGreen,

        width: 558.56316,
        height: 693.86694,
        aspectRatio: 0.8050003938795528
      };
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon4AllArrowsPink,

        width: 558.56316,
        height: 693.86694,
        aspectRatio: 0.8050003938795528
      };
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon4AllArrowsPurple,

        width: 558.56316,
        height: 693.86694,
        aspectRatio: 0.8050003938795528
      };
    case 'Shapes_with_arrows/Irregular_hexagon4_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon4AllArrowsYellow,

        width: 558.56316,
        height: 693.86694,
        aspectRatio: 0.8050003938795528
      };
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon5AllArrows,

        width: 915.98368,
        height: 607.20905,
        aspectRatio: 1.508514538773755
      };
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon5AllArrowsBlue,

        width: 915.98368,
        height: 607.20905,
        aspectRatio: 1.508514538773755
      };
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon5AllArrowsGreen,

        width: 915.98368,
        height: 607.20905,
        aspectRatio: 1.508514538773755
      };
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon5AllArrowsPink,

        width: 915.98368,
        height: 607.20905,
        aspectRatio: 1.508514538773755
      };
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon5AllArrowsPurple,

        width: 915.98368,
        height: 607.20905,
        aspectRatio: 1.508514538773755
      };
    case 'Shapes_with_arrows/Irregular_hexagon5_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon5AllArrowsYellow,

        width: 915.98368,
        height: 607.20905,
        aspectRatio: 1.508514538773755
      };
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon6AllArrows,

        width: 869.79728,
        height: 604.35974,
        aspectRatio: 1.4392045373505522
      };
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon6AllArrowsBlue,

        width: 869.79728,
        height: 604.35974,
        aspectRatio: 1.4392045373505522
      };
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon6AllArrowsGreen,

        width: 869.79728,
        height: 604.35974,
        aspectRatio: 1.4392045373505522
      };
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon6AllArrowsPink,

        width: 869.79728,
        height: 604.35974,
        aspectRatio: 1.4392045373505522
      };
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon6AllArrowsPurple,

        width: 869.79728,
        height: 604.35974,
        aspectRatio: 1.4392045373505522
      };
    case 'Shapes_with_arrows/Irregular_hexagon6_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon6AllArrowsYellow,

        width: 869.79728,
        height: 604.35974,
        aspectRatio: 1.4392045373505522
      };
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularHexagon7AllArrows,

        width: 426.16838,
        height: 570.11584,
        aspectRatio: 0.7475119091586719
      };
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularHexagon7AllArrowsBlue,

        width: 426.16838,
        height: 570.11584,
        aspectRatio: 0.7475119091586719
      };
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularHexagon7AllArrowsGreen,

        width: 426.16838,
        height: 570.11584,
        aspectRatio: 0.7475119091586719
      };
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularHexagon7AllArrowsPink,

        width: 426.16838,
        height: 570.11584,
        aspectRatio: 0.7475119091586719
      };
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularHexagon7AllArrowsPurple,

        width: 426.16838,
        height: 570.11584,
        aspectRatio: 0.7475119091586719
      };
    case 'Shapes_with_arrows/Irregular_hexagon7_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularHexagon7AllArrowsYellow,

        width: 426.16838,
        height: 570.11584,
        aspectRatio: 0.7475119091586719
      };
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularOctagon1AllArrows,

        width: 619.22423,
        height: 695.67664,
        aspectRatio: 0.8901035256840018
      };
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularOctagon1AllArrowsBlue,

        width: 619.22423,
        height: 695.67664,
        aspectRatio: 0.8901035256840018
      };
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularOctagon1AllArrowsGreen,

        width: 619.22423,
        height: 695.67664,
        aspectRatio: 0.8901035256840018
      };
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularOctagon1AllArrowsPink,

        width: 619.22423,
        height: 695.67664,
        aspectRatio: 0.8901035256840018
      };
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularOctagon1AllArrowsPurple,

        width: 619.22423,
        height: 695.67664,
        aspectRatio: 0.8901035256840018
      };
    case 'Shapes_with_arrows/Irregular_octagon1_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularOctagon1AllArrowsYellow,

        width: 619.22423,
        height: 695.67664,
        aspectRatio: 0.8901035256840018
      };
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularPentagon1AllArrows,

        width: 817.64957,
        height: 446.3204,
        aspectRatio: 1.8319789326232905
      };
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularPentagon1AllArrowsBlue,

        width: 817.64957,
        height: 446.3204,
        aspectRatio: 1.8319789326232905
      };
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularPentagon1AllArrowsGreen,

        width: 817.64957,
        height: 446.3204,
        aspectRatio: 1.8319789326232905
      };
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularPentagon1AllArrowsPink,

        width: 817.64957,
        height: 446.3204,
        aspectRatio: 1.8319789326232905
      };
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularPentagon1AllArrowsPurple,

        width: 817.64957,
        height: 446.3204,
        aspectRatio: 1.8319789326232905
      };
    case 'Shapes_with_arrows/Irregular_pentagon1_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularPentagon1AllArrowsYellow,

        width: 817.64957,
        height: 446.3204,
        aspectRatio: 1.8319789326232905
      };
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularPentagon2AllArrows,

        width: 902.06339,
        height: 667.06573,
        aspectRatio: 1.3522856135931312
      };
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularPentagon2AllArrowsBlue,

        width: 902.06339,
        height: 667.06573,
        aspectRatio: 1.3522856135931312
      };
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularPentagon2AllArrowsGreen,

        width: 902.06339,
        height: 667.06573,
        aspectRatio: 1.3522856135931312
      };
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularPentagon2AllArrowsPink,

        width: 902.06339,
        height: 667.06573,
        aspectRatio: 1.3522856135931312
      };
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularPentagon2AllArrowsPurple,

        width: 902.06339,
        height: 667.06573,
        aspectRatio: 1.3522856135931312
      };
    case 'Shapes_with_arrows/Irregular_pentagon2_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularPentagon2AllArrowsYellow,

        width: 902.06339,
        height: 667.06573,
        aspectRatio: 1.3522856135931312
      };
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularPentagon3AllArrows,

        width: 885.99302,
        height: 387.91986,
        aspectRatio: 2.2839589084198986
      };
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularPentagon3AllArrowsBlue,

        width: 885.99302,
        height: 387.91986,
        aspectRatio: 2.2839589084198986
      };
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularPentagon3AllArrowsGreen,

        width: 885.99302,
        height: 387.91986,
        aspectRatio: 2.2839589084198986
      };
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularPentagon3AllArrowsPink,

        width: 885.99302,
        height: 387.91986,
        aspectRatio: 2.2839589084198986
      };
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularPentagon3AllArrowsPurple,

        width: 885.99302,
        height: 387.91986,
        aspectRatio: 2.2839589084198986
      };
    case 'Shapes_with_arrows/Irregular_pentagon3_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularPentagon3AllArrowsYellow,

        width: 885.99302,
        height: 387.91986,
        aspectRatio: 2.2839589084198986
      };
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularPentagon4AllArrows,

        width: 887.27286,
        height: 542.94464,
        aspectRatio: 1.6341866087857502
      };
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularPentagon4AllArrowsBlue,

        width: 887.27286,
        height: 542.94464,
        aspectRatio: 1.6341866087857502
      };
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularPentagon4AllArrowsGreen,

        width: 887.27286,
        height: 542.94464,
        aspectRatio: 1.6341866087857502
      };
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularPentagon4AllArrowsPink,

        width: 887.27286,
        height: 542.94464,
        aspectRatio: 1.6341866087857502
      };
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularPentagon4AllArrowsPurple,

        width: 887.27286,
        height: 542.94464,
        aspectRatio: 1.6341866087857502
      };
    case 'Shapes_with_arrows/Irregular_pentagon4_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularPentagon4AllArrowsYellow,

        width: 887.27286,
        height: 542.94464,
        aspectRatio: 1.6341866087857502
      };
    case 'Shapes_with_arrows/Irregular_pentagon5_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularPentagon5AllArrows,

        width: 686.08689,
        height: 792.95251,
        aspectRatio: 0.865230743767997
      };
    case 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_blue':
      return {
        asset: ShapesWithArrowsIrregularPentagon5AllArrowsBlue,

        width: 686.08689,
        height: 792.95251,
        aspectRatio: 0.865230743767997
      };
    case 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_green':
      return {
        asset: ShapesWithArrowsIrregularPentagon5AllArrowsGreen,

        width: 686.08689,
        height: 792.95251,
        aspectRatio: 0.865230743767997
      };
    case 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_pink':
      return {
        asset: ShapesWithArrowsIrregularPentagon5AllArrowsPink,

        width: 686.08689,
        height: 792.95251,
        aspectRatio: 0.865230743767997
      };
    case 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_purple':
      return {
        asset: ShapesWithArrowsIrregularPentagon5AllArrowsPurple,

        width: 686.08689,
        height: 792.95251,
        aspectRatio: 0.865230743767997
      };
    case 'Shapes_with_arrows/Irregular_pentagon5_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsIrregularPentagon5AllArrowsYellow,

        width: 686.08689,
        height: 792.95251,
        aspectRatio: 0.865230743767997
      };
    case 'Shapes_with_arrows/Irregular_triangle_all_arrows':
      return {
        asset: ShapesWithArrowsIrregularTriangleAllArrows,

        width: 1038.4592,
        height: 588.53467,
        aspectRatio: 1.764482625976818
      };
    case 'Shapes_with_arrows/Isosceles_triangle_with_arrows_equal':
      return {
        asset: ShapesWithArrowsIsoscelesTriangleWithArrowsEqual,

        width: 207.37814,
        height: 171.1082,
        aspectRatio: 1.2119707880744464
      };
    case 'Shapes_with_arrows/Isosceles_triangle_with_arrows_short':
      return {
        asset: ShapesWithArrowsIsoscelesTriangleWithArrowsShort,

        width: 208.00612,
        height: 107.79363,
        aspectRatio: 1.9296698701027142
      };
    case 'Shapes_with_arrows/Isosceles_triangle_with_arrows_tall':
      return {
        asset: ShapesWithArrowsIsoscelesTriangleWithArrowsTall,

        width: 207.27263,
        height: 222.51323,
        aspectRatio: 0.9315069939886271
      };
    case 'Shapes_with_arrows/Labelled_compound_shape1':
      return {
        asset: ShapesWithArrowsLabelledCompoundShape1,

        width: 639.47221,
        height: 463.146,
        aspectRatio: 1.3807140944756082
      };
    case 'Shapes_with_arrows/Labelled_compound_shape2':
      return {
        asset: ShapesWithArrowsLabelledCompoundShape2,

        width: 630.38981,
        height: 463.146,
        aspectRatio: 1.3611038635764965
      };
    case 'Shapes_with_arrows/Labelled_compound_shape3':
      return {
        asset: ShapesWithArrowsLabelledCompoundShape3,

        width: 526.76598,
        height: 356.3364,
        aspectRatio: 1.4782828248812077
      };
    case 'Shapes_with_arrows/Labelled_compound_shape4':
      return {
        asset: ShapesWithArrowsLabelledCompoundShape4,

        width: 639.47184,
        height: 463.67972,
        aspectRatio: 1.3791240212101579
      };
    case 'Shapes_with_arrows/Labelled_compound_shape5':
      return {
        asset: ShapesWithArrowsLabelledCompoundShape5,

        width: 639.47184,
        height: 463.67972,
        aspectRatio: 1.3791240212101579
      };
    case 'Shapes_with_arrows/Labelled_compound_shape6':
      return {
        asset: ShapesWithArrowsLabelledCompoundShape6,

        width: 639.47184,
        height: 463.67972,
        aspectRatio: 1.3791240212101579
      };
    case 'Shapes_with_arrows/Parallelogram10_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram102ArrowsRightAngle,

        width: 804.25175,
        height: 411.39853,
        aspectRatio: 1.954921302222446
      };
    case 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram103ArrowsRightAngle,

        width: 232.33799,
        height: 764.68024,
        aspectRatio: 0.3038367906564448
      };
    case 'Shapes_with_arrows/Parallelogram10_3_arrows_right_angle_filled':
      return {
        asset: ShapesWithArrowsParallelogram103ArrowsRightAngleFilled,
        defaultWidth: 829.01086,
        defaultHeight: 420.07706,
        width: 829.01089,
        height: 420.07706,
        aspectRatio: 1.973473367005568
      };
    case 'Shapes_with_arrows/Parallelogram1_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram12ArrowsRightAngle,

        width: 668.66661,
        height: 620.71173,
        aspectRatio: 1.0772578923230596
      };
    case 'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram13ArrowsRightAngle,

        width: 685.03154,
        height: 620.7113,
        aspectRatio: 1.1036234397537146
      };
    case 'Shapes_with_arrows/Parallelogram1_3_arrows_right_angle_filled':
      return {
        asset: ShapesWithArrowsParallelogram13ArrowsRightAngleFilled,
        defaultWidth: 692.99963,
        defaultHeight: 632.18549,
        width: 692.99964,
        height: 632.18547,
        aspectRatio: 1.0961967221423168
      };
    case 'Shapes_with_arrows/Parallelogram2_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram22ArrowsRightAngle,

        width: 460.83861,
        height: 699.60254,
        aspectRatio: 0.6587148897429675
      };
    case 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram23ArrowsRightAngle,

        width: 921.87375,
        height: 620.7113,
        aspectRatio: 1.4851892498171049
      };
    case 'Shapes_with_arrows/Parallelogram2_3_arrows_right_angle_filled':
      return {
        asset: ShapesWithArrowsParallelogram23ArrowsRightAngleFilled,
        defaultWidth: 493.66129,
        defaultHeight: 710.97272,
        width: 493.66127,
        height: 710.97274,
        aspectRatio: 0.6943462698724566
      };
    case 'Shapes_with_arrows/Parallelogram3_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram32ArrowsRightAngle,

        width: 772.99705,
        height: 659.409,
        aspectRatio: 1.1722573546918529
      };
    case 'Shapes_with_arrows/Parallelogram3_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram33ArrowsRightAngle,

        width: 485.5613,
        height: 747.797,
        aspectRatio: 0.6493223428283345
      };
    case 'Shapes_with_arrows/Parallelogram4_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram42ArrowsRightAngle,

        width: 843.03055,
        height: 174.35387,
        aspectRatio: 4.83516970400485
      };
    case 'Shapes_with_arrows/Parallelogram4_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram43ArrowsRightAngle,

        width: 487.15663,
        height: 749.05859,
        aspectRatio: 0.6503585120090539
      };
    case 'Shapes_with_arrows/Parallelogram5_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram52ArrowsRightAngle,

        width: 468.06471,
        height: 487.64825,
        aspectRatio: 0.9598408483984101
      };
    case 'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram53ArrowsRightAngle,

        width: 590.38475,
        height: 678.07019,
        aspectRatio: 0.8706838299439178
      };
    case 'Shapes_with_arrows/Parallelogram5_3_arrows_right_angle_filled':
      return {
        asset: ShapesWithArrowsParallelogram53ArrowsRightAngleFilled,
        defaultWidth: 493.85831,
        defaultHeight: 498.21561,
        width: 493.85831,
        height: 498.21561,
        aspectRatio: 0.9912541881214841
      };
    case 'Shapes_with_arrows/Parallelogram6_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram62ArrowsRightAngle,

        width: 568.13957,
        height: 669.06378,
        aspectRatio: 0.8491560699938056
      };
    case 'Shapes_with_arrows/Parallelogram6_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram63ArrowsRightAngle,

        width: 566.00169,
        height: 620.71118,
        aspectRatio: 0.9118599893754129
      };
    case 'Shapes_with_arrows/Parallelogram7_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram72ArrowsRightAngle,

        width: 621.65184,
        height: 667.80261,
        aspectRatio: 0.9308915998396593
      };
    case 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram73ArrowsRightAngle,

        width: 565.20421,
        height: 751.1792,
        aspectRatio: 0.7524226043532621
      };
    case 'Shapes_with_arrows/Parallelogram7_3_arrows_right_angle_filled':
      return {
        asset: ShapesWithArrowsParallelogram73ArrowsRightAngleFilled,
        defaultWidth: 631.57953,
        defaultHeight: 694.34045,
        width: 631.57951,
        height: 694.34046,
        aspectRatio: 0.9096106973227515
      };
    case 'Shapes_with_arrows/Parallelogram8_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram82ArrowsRightAngle,

        width: 400.86086,
        height: 480.81985,
        aspectRatio: 0.8337028098985515
      };
    case 'Shapes_with_arrows/Parallelogram8_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram83ArrowsRightAngle,

        width: 410.93358,
        height: 767.31396,
        aspectRatio: 0.5355481607554748
      };
    case 'Shapes_with_arrows/Parallelogram9_2_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram92ArrowsRightAngle,

        width: 529.9279,
        height: 581.1557,
        aspectRatio: 0.9118518496850327
      };
    case 'Shapes_with_arrows/Parallelogram9_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsParallelogram93ArrowsRightAngle,

        width: 566.66649,
        height: 581.15613,
        aspectRatio: 0.9750675605882364
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle1':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle1,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle10':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle10,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle11':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle11,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle12':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle12,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle13':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle13,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle14':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle14,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle15':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle15,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle16':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle16,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle17':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle17,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle18':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle18,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle19':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle19,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle2':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle2,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle20':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle20,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle3':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle3,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle4':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle4,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle5':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle5,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle6':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle6,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle7':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle7,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle8':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle8,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Parallelogram_and_rectangle9':
      return {
        asset: ShapesWithArrowsParallelogramAndRectangle9,

        width: 1268.171,
        height: 868.13867,
        aspectRatio: 1.4607931242136696
      };
    case 'Shapes_with_arrows/Quadrilateral_all_arrows':
      return {
        asset: ShapesWithArrowsQuadrilateralAllArrows,

        width: 499.86223,
        height: 633.40186,
        aspectRatio: 0.7891707643548757
      };
    case 'Shapes_with_arrows/Rectangle1_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle12Arrows,

        width: 401.03292,
        height: 487.59399,
        aspectRatio: 0.8224730579636553
      };
    case 'Shapes_with_arrows/Rectangle2_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle22Arrows,

        width: 123.57093,
        height: 487.59439,
        aspectRatio: 0.25342976156883185
      };
    case 'Shapes_with_arrows/Rectangle3_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle32Arrows,

        width: 487.59438,
        height: 92.802528,
        aspectRatio: 5.254106655370423
      };
    case 'Shapes_with_arrows/Rectangle4_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle42Arrows,

        width: 487.59438,
        height: 171.0788,
        aspectRatio: 2.850115736140305
      };
    case 'Shapes_with_arrows/Rectangle5_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle52Arrows,

        width: 365.90957,
        height: 229.28546,
        aspectRatio: 1.5958690533625637
      };
    case 'Shapes_with_arrows/Rectangle6_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle62Arrows,

        width: 837.47489,
        height: 229.28546,
        aspectRatio: 3.6525425118540005
      };
    case 'Shapes_with_arrows/Rectangle7_2_arrows':
      return {
        asset: ShapesWithArrowsRectangle72Arrows,

        width: 197.04573,
        height: 412.21906,
        aspectRatio: 0.47801217634138504
      };
    case 'Shapes_with_arrows/Rectangle8_4_arrows':
      return {
        asset: ShapesWithArrowsRectangle84Arrows,

        width: 686.01304,
        height: 323.13492,
        aspectRatio: 2.1229925877401303
      };
    case 'Shapes_with_arrows/Rectangle_all_arrows':
      return {
        asset: ShapesWithArrowsRectangleAllArrows,

        width: 689.84849,
        height: 660.34692,
        aspectRatio: 1.044675865225509
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape102RedArrows,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape10AllArrows,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape10AllArrowsBlue,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape10AllArrowsGreen,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape10AllArrowsPink,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape10AllArrowsPurple,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Shapes_with_arrows/Rectilinear_shape10_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape10AllArrowsYellow,

        width: 623.86625,
        height: 431.22891,
        aspectRatio: 1.446717127569207
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape112RedArrows,

        width: 863.61686,
        height: 361.97678,
        aspectRatio: 2.3858349698563535
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape11AllArrows,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape11AllArrowsBlue,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape11AllArrowsGreen,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape11AllArrowsPink,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape11AllArrowsPurple,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Shapes_with_arrows/Rectilinear_shape11_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape11AllArrowsYellow,

        width: 597.63383,
        height: 623.86627,
        aspectRatio: 0.9579518219505601
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape122RedArrows,

        width: 863.62504,
        height: 453.34692,
        aspectRatio: 1.9049981413792334
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape12AllArrows,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape12AllArrowsBlue,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape12AllArrowsGreen,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape12AllArrowsPink,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape12AllArrowsPurple,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape12_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape12AllArrowsYellow,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape132RedArrows,

        width: 861.49357,
        height: 595.51343,
        aspectRatio: 1.446640036312867
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape13AllArrows,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape13AllArrowsBlue,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape13AllArrowsGreen,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape13AllArrowsPink,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape13AllArrowsPurple,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Shapes_with_arrows/Rectilinear_shape13_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape13AllArrowsYellow,

        width: 623.86595,
        height: 597.63385,
        aspectRatio: 1.0438932634086906
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape142RedArrows,

        width: 1097.4862,
        height: 655.11908,
        aspectRatio: 1.675246887939823
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape14AllArrows,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape14AllArrowsBlue,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape14AllArrowsGreen,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape14AllArrowsPink,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape14AllArrowsPurple,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Shapes_with_arrows/Rectilinear_shape14_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape14AllArrowsYellow,

        width: 623.86625,
        height: 597.63385,
        aspectRatio: 1.0438937653882892
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape152RedArrows,

        width: 655.11888,
        height: 699.33588,
        aspectRatio: 0.9367728708557038
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape15AllArrows,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape15AllArrowsBlue,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape15AllArrowsGreen,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape15AllArrowsPink,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape15AllArrowsPurple,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape15_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape15AllArrowsYellow,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape162RedArrows,

        width: 595.51329,
        height: 771.41974,
        aspectRatio: 0.7719705098549849
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape16AllArrows,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape16AllArrowsBlue,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape16AllArrowsGreen,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape16AllArrowsPink,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape16AllArrowsPurple,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Shapes_with_arrows/Rectilinear_shape16_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape16AllArrowsYellow,

        width: 841.96647,
        height: 299.13345,
        aspectRatio: 2.814685117963237
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape172RedArrows,

        width: 850.95518,
        height: 617.2132,
        aspectRatio: 1.3787054132996508
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape17AllArrows,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape17AllArrowsBlue,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape17AllArrowsGreen,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape17AllArrowsPink,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape17AllArrowsPurple,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Shapes_with_arrows/Rectilinear_shape17_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape17AllArrowsYellow,

        width: 597.63408,
        height: 623.86609,
        aspectRatio: 0.9579524990691513
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape182RedArrows,

        width: 617.2133,
        height: 766.55811,
        aspectRatio: 0.8051748353428808
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape18AllArrows,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape18AllArrowsBlue,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape18AllArrowsGreen,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape18AllArrowsPink,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape18AllArrowsPurple,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Shapes_with_arrows/Rectilinear_shape18_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape18AllArrowsYellow,

        width: 786.8574,
        height: 623.86652,
        aspectRatio: 1.2612592193599361
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape192RedArrows,

        width: 491.78798,
        height: 709.00372,
        aspectRatio: 0.6936324396154084
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape19AllArrows,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape19AllArrowsBlue,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape19AllArrowsGreen,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape19AllArrowsPink,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape19AllArrowsPurple,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Shapes_with_arrows/Rectilinear_shape19_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape19AllArrowsYellow,

        width: 623.86625,
        height: 431.22919,
        aspectRatio: 1.4467161882060906
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape12RedArrows,

        width: 843.34183,
        height: 623.86652,
        aspectRatio: 1.3517985065138611
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape1AllArrows,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape1AllArrowsBlue,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape1AllArrowsGreen,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape1AllArrowsPink,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape1AllArrowsPurple,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Shapes_with_arrows/Rectilinear_shape1_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape1AllArrowsYellow,

        width: 843.34183,
        height: 623.86609,
        aspectRatio: 1.3517994382416265
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape202RedArrows,

        width: 830.37705,
        height: 709.0036,
        aspectRatio: 1.171188764062693
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape20AllArrows,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape20AllArrowsBlue,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape20AllArrowsGreen,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape20AllArrowsPink,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape20AllArrowsPurple,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape20_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape20AllArrowsYellow,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape21_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape21AllArrows,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape21AllArrowsBlue,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape21AllArrowsGreen,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape21AllArrowsPink,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape21AllArrowsPurple,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape21_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape21AllArrowsYellow,

        width: 623.86625,
        height: 786.8573,
        aspectRatio: 0.7928581840697163
      };
    case 'Shapes_with_arrows/Rectilinear_shape22_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape22AllArrows,

        width: 786.8574,
        height: 623.86639,
        aspectRatio: 1.2612594821785479
      };
    case 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape22AllArrowsBlue,

        width: 786.8574,
        height: 623.86639,
        aspectRatio: 1.2612594821785479
      };
    case 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape22AllArrowsGreen,

        width: 786.8574,
        height: 623.86639,
        aspectRatio: 1.2612594821785479
      };
    case 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape22AllArrowsPink,

        width: 786.8574,
        height: 623.86639,
        aspectRatio: 1.2612594821785479
      };
    case 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape22AllArrowsPurple,

        width: 786.8574,
        height: 623.86639,
        aspectRatio: 1.2612594821785479
      };
    case 'Shapes_with_arrows/Rectilinear_shape22_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape22AllArrowsYellow,

        width: 786.8574,
        height: 623.86639,
        aspectRatio: 1.2612594821785479
      };
    case 'Shapes_with_arrows/Rectilinear_shape23_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape23AllArrows,

        width: 843.34208,
        height: 623.86639,
        aspectRatio: 1.3517991889256928
      };
    case 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape23AllArrowsBlue,

        width: 843.34208,
        height: 623.86639,
        aspectRatio: 1.3517991889256928
      };
    case 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape23AllArrowsGreen,

        width: 843.34208,
        height: 623.86639,
        aspectRatio: 1.3517991889256928
      };
    case 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape23AllArrowsPink,

        width: 843.34208,
        height: 623.86639,
        aspectRatio: 1.3517991889256928
      };
    case 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape23AllArrowsPurple,

        width: 843.34208,
        height: 623.86639,
        aspectRatio: 1.3517991889256928
      };
    case 'Shapes_with_arrows/Rectilinear_shape23_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape23AllArrowsYellow,

        width: 843.34208,
        height: 623.86639,
        aspectRatio: 1.3517991889256928
      };
    case 'Shapes_with_arrows/Rectilinear_shape24_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape24AllArrows,

        width: 428.62078,
        height: 586.16931,
        aspectRatio: 0.731223509466915
      };
    case 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape24AllArrowsBlue,

        width: 428.62078,
        height: 586.16931,
        aspectRatio: 0.731223509466915
      };
    case 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape24AllArrowsGreen,

        width: 428.62078,
        height: 586.16931,
        aspectRatio: 0.731223509466915
      };
    case 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape24AllArrowsPink,

        width: 428.62078,
        height: 586.16931,
        aspectRatio: 0.731223509466915
      };
    case 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape24AllArrowsPurple,

        width: 428.62078,
        height: 586.16931,
        aspectRatio: 0.731223509466915
      };
    case 'Shapes_with_arrows/Rectilinear_shape24_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape24AllArrowsYellow,

        width: 428.62078,
        height: 586.16931,
        aspectRatio: 0.731223509466915
      };
    case 'Shapes_with_arrows/Rectilinear_shape25_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape25AllArrows,

        width: 478.34612,
        height: 535.01892,
        aspectRatio: 0.894073278754329
      };
    case 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape25AllArrowsBlue,

        width: 478.34612,
        height: 535.01892,
        aspectRatio: 0.894073278754329
      };
    case 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape25AllArrowsGreen,

        width: 478.34612,
        height: 535.01892,
        aspectRatio: 0.894073278754329
      };
    case 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape25AllArrowsPink,

        width: 478.34612,
        height: 535.01892,
        aspectRatio: 0.894073278754329
      };
    case 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape25AllArrowsPurple,

        width: 478.34612,
        height: 535.01892,
        aspectRatio: 0.894073278754329
      };
    case 'Shapes_with_arrows/Rectilinear_shape25_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape25AllArrowsYellow,

        width: 478.34612,
        height: 535.01892,
        aspectRatio: 0.894073278754329
      };
    case 'Shapes_with_arrows/Rectilinear_shape26_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape26AllArrows,

        width: 381.00237,
        height: 535.01892,
        aspectRatio: 0.7121287785486166
      };
    case 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape26AllArrowsBlue,

        width: 381.00237,
        height: 535.01892,
        aspectRatio: 0.7121287785486166
      };
    case 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape26AllArrowsGreen,

        width: 381.00237,
        height: 535.01892,
        aspectRatio: 0.7121287785486166
      };
    case 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape26AllArrowsPink,

        width: 381.00237,
        height: 535.01892,
        aspectRatio: 0.7121287785486166
      };
    case 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape26AllArrowsPurple,

        width: 381.00237,
        height: 535.01892,
        aspectRatio: 0.7121287785486166
      };
    case 'Shapes_with_arrows/Rectilinear_shape26_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape26AllArrowsYellow,

        width: 381.00237,
        height: 535.01892,
        aspectRatio: 0.7121287785486166
      };
    case 'Shapes_with_arrows/Rectilinear_shape27_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape27AllArrows,

        width: 381.00264,
        height: 424.18011,
        aspectRatio: 0.8982095836601107
      };
    case 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape27AllArrowsBlue,

        width: 381.00264,
        height: 424.18011,
        aspectRatio: 0.8982095836601107
      };
    case 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape27AllArrowsGreen,

        width: 381.00264,
        height: 424.18011,
        aspectRatio: 0.8982095836601107
      };
    case 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape27AllArrowsPink,

        width: 381.00264,
        height: 424.18011,
        aspectRatio: 0.8982095836601107
      };
    case 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape27AllArrowsPurple,

        width: 381.00264,
        height: 424.18011,
        aspectRatio: 0.8982095836601107
      };
    case 'Shapes_with_arrows/Rectilinear_shape27_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape27AllArrowsYellow,

        width: 381.00264,
        height: 424.18011,
        aspectRatio: 0.8982095836601107
      };
    case 'Shapes_with_arrows/Rectilinear_shape28_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape28AllArrows,

        width: 422.47078,
        height: 302.84344,
        aspectRatio: 1.3950138064737345
      };
    case 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape28AllArrowsBlue,

        width: 422.47078,
        height: 302.84344,
        aspectRatio: 1.3950138064737345
      };
    case 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape28AllArrowsGreen,

        width: 422.47078,
        height: 302.84344,
        aspectRatio: 1.3950138064737345
      };
    case 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape28AllArrowsPink,

        width: 422.47078,
        height: 302.84344,
        aspectRatio: 1.3950138064737345
      };
    case 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape28AllArrowsPurple,

        width: 422.47078,
        height: 302.84344,
        aspectRatio: 1.3950138064737345
      };
    case 'Shapes_with_arrows/Rectilinear_shape28_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape28AllArrowsYellow,

        width: 422.47078,
        height: 302.84344,
        aspectRatio: 1.3950138064737345
      };
    case 'Shapes_with_arrows/Rectilinear_shape29_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape29AllArrows,

        width: 660.81999,
        height: 422.21066,
        aspectRatio: 1.5651428365167281
      };
    case 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape29AllArrowsBlue,

        width: 660.81999,
        height: 422.21066,
        aspectRatio: 1.5651428365167281
      };
    case 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape29AllArrowsGreen,

        width: 660.81999,
        height: 422.21066,
        aspectRatio: 1.5651428365167281
      };
    case 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape29AllArrowsPink,

        width: 660.81999,
        height: 422.21066,
        aspectRatio: 1.5651428365167281
      };
    case 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape29AllArrowsPurple,

        width: 660.81999,
        height: 422.21066,
        aspectRatio: 1.5651428365167281
      };
    case 'Shapes_with_arrows/Rectilinear_shape29_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape29AllArrowsYellow,

        width: 660.81999,
        height: 422.21066,
        aspectRatio: 1.5651428365167281
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape22RedArrows,

        width: 843.34196,
        height: 623.86652,
        aspectRatio: 1.3517987148917687
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape2AllArrows,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape2AllArrowsBlue,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape2AllArrowsGreen,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape2AllArrowsPink,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape2AllArrowsPurple,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Shapes_with_arrows/Rectilinear_shape2_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape2AllArrowsYellow,

        width: 830.37705,
        height: 709.00348,
        aspectRatio: 1.1711889622883094
      };
    case 'Shapes_with_arrows/Rectilinear_shape30_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape30AllArrows,

        width: 422.21065,
        height: 660.82037,
        aspectRatio: 0.6389189394993983
      };
    case 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape30AllArrowsBlue,

        width: 422.21065,
        height: 660.82037,
        aspectRatio: 0.6389189394993983
      };
    case 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape30AllArrowsGreen,

        width: 422.21065,
        height: 660.82037,
        aspectRatio: 0.6389189394993983
      };
    case 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape30AllArrowsPink,

        width: 422.21065,
        height: 660.82037,
        aspectRatio: 0.6389189394993983
      };
    case 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape30AllArrowsPurple,

        width: 422.21065,
        height: 660.82037,
        aspectRatio: 0.6389189394993983
      };
    case 'Shapes_with_arrows/Rectilinear_shape30_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape30AllArrowsYellow,

        width: 422.21065,
        height: 660.82037,
        aspectRatio: 0.6389189394993983
      };
    case 'Shapes_with_arrows/Rectilinear_shape31_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape31AllArrows,

        width: 660.81999,
        height: 532.49384,
        aspectRatio: 1.2409908629177757
      };
    case 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape31AllArrowsBlue,

        width: 660.81999,
        height: 532.49384,
        aspectRatio: 1.2409908629177757
      };
    case 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape31AllArrowsGreen,

        width: 660.81999,
        height: 532.49384,
        aspectRatio: 1.2409908629177757
      };
    case 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape31AllArrowsPink,

        width: 660.81999,
        height: 532.49384,
        aspectRatio: 1.2409908629177757
      };
    case 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape31AllArrowsPurple,

        width: 660.81999,
        height: 532.49384,
        aspectRatio: 1.2409908629177757
      };
    case 'Shapes_with_arrows/Rectilinear_shape31_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape31AllArrowsYellow,

        width: 660.81999,
        height: 532.49384,
        aspectRatio: 1.2409908629177757
      };
    case 'Shapes_with_arrows/Rectilinear_shape32_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape32AllArrows,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape32AllArrowsBlue,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape32AllArrowsGreen,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape32AllArrowsPink,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape32AllArrowsPurple,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Shapes_with_arrows/Rectilinear_shape32_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape32AllArrowsYellow,

        width: 863.61704,
        height: 361.97626,
        aspectRatio: 2.3858388945175575
      };
    case 'Shapes_with_arrows/Rectilinear_shape33_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape33AllArrows,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape33AllArrowsBlue,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape33AllArrowsGreen,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape33AllArrowsPink,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape33AllArrowsPurple,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape33_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape33AllArrowsYellow,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape34_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape34AllArrows,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape34AllArrowsBlue,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape34AllArrowsGreen,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape34AllArrowsPink,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape34AllArrowsPurple,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape34_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape34AllArrowsYellow,

        width: 428.62117,
        height: 586.16907,
        aspectRatio: 0.7312244741947915
      };
    case 'Shapes_with_arrows/Rectilinear_shape35_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape35AllArrows,

        width: 637.83568,
        height: 586.16907,
        aspectRatio: 1.0881428458857443
      };
    case 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape35AllArrowsBlue,

        width: 637.83568,
        height: 586.16907,
        aspectRatio: 1.0881428458857443
      };
    case 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape35AllArrowsGreen,

        width: 637.83568,
        height: 586.16907,
        aspectRatio: 1.0881428458857443
      };
    case 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape35AllArrowsPink,

        width: 637.83568,
        height: 586.16907,
        aspectRatio: 1.0881428458857443
      };
    case 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape35AllArrowsPurple,

        width: 637.83568,
        height: 586.16907,
        aspectRatio: 1.0881428458857443
      };
    case 'Shapes_with_arrows/Rectilinear_shape35_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape35AllArrowsYellow,

        width: 637.83568,
        height: 586.16907,
        aspectRatio: 1.0881428458857443
      };
    case 'Shapes_with_arrows/Rectilinear_shape36_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape36AllArrows,

        width: 472.88317,
        height: 659.48663,
        aspectRatio: 0.7170473948804694
      };
    case 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape36AllArrowsBlue,

        width: 472.88317,
        height: 659.48663,
        aspectRatio: 0.7170473948804694
      };
    case 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape36AllArrowsGreen,

        width: 472.88317,
        height: 659.48663,
        aspectRatio: 0.7170473948804694
      };
    case 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape36AllArrowsPink,

        width: 472.88317,
        height: 659.48663,
        aspectRatio: 0.7170473948804694
      };
    case 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape36AllArrowsPurple,

        width: 472.88317,
        height: 659.48663,
        aspectRatio: 0.7170473948804694
      };
    case 'Shapes_with_arrows/Rectilinear_shape36_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape36AllArrowsYellow,

        width: 472.88317,
        height: 659.48663,
        aspectRatio: 0.7170473948804694
      };
    case 'Shapes_with_arrows/Rectilinear_shape37_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape37AllArrows,

        width: 414.66613,
        height: 659.48663,
        aspectRatio: 0.6287710942676731
      };
    case 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape37AllArrowsBlue,

        width: 414.66613,
        height: 659.48663,
        aspectRatio: 0.6287710942676731
      };
    case 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape37AllArrowsGreen,

        width: 414.66613,
        height: 659.48663,
        aspectRatio: 0.6287710942676731
      };
    case 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape37AllArrowsPink,

        width: 414.66613,
        height: 659.48663,
        aspectRatio: 0.6287710942676731
      };
    case 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape37AllArrowsPurple,

        width: 414.66613,
        height: 659.48663,
        aspectRatio: 0.6287710942676731
      };
    case 'Shapes_with_arrows/Rectilinear_shape37_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape37AllArrowsYellow,

        width: 414.66613,
        height: 659.48663,
        aspectRatio: 0.6287710942676731
      };
    case 'Shapes_with_arrows/Rectilinear_shape38_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape38AllArrows,

        width: 483.61983,
        height: 586.16907,
        aspectRatio: 0.8250517721789721
      };
    case 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape38AllArrowsBlue,

        width: 483.61983,
        height: 586.16907,
        aspectRatio: 0.8250517721789721
      };
    case 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape38AllArrowsGreen,

        width: 483.61983,
        height: 586.16907,
        aspectRatio: 0.8250517721789721
      };
    case 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape38AllArrowsPink,

        width: 483.61983,
        height: 586.16907,
        aspectRatio: 0.8250517721789721
      };
    case 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape38AllArrowsPurple,

        width: 483.61983,
        height: 586.16907,
        aspectRatio: 0.8250517721789721
      };
    case 'Shapes_with_arrows/Rectilinear_shape38_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape38AllArrowsYellow,

        width: 483.61983,
        height: 586.16907,
        aspectRatio: 0.8250517721789721
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape32RedArrows,

        width: 597.63383,
        height: 623.86652,
        aspectRatio: 0.9579514380736442
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape3AllArrows,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape3AllArrowsBlue,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape3AllArrowsGreen,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape3AllArrowsPink,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape3AllArrowsPurple,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Shapes_with_arrows/Rectilinear_shape3_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape3AllArrowsYellow,

        width: 861.49326,
        height: 655.11908,
        aspectRatio: 1.3150178132500734
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape42RedArrows,

        width: 841.96677,
        height: 299.13345,
        aspectRatio: 2.8146861208601046
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape4AllArrows,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape4AllArrowsBlue,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape4AllArrowsGreen,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape4AllArrowsPink,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape4AllArrowsPurple,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape4_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape4AllArrowsYellow,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape52RedArrows,

        width: 623.86638,
        height: 786.8573,
        aspectRatio: 0.7928583492839172
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape5AllArrows,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape5AllArrowsBlue,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape5AllArrowsGreen,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape5AllArrowsPink,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape5AllArrowsPurple,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Shapes_with_arrows/Rectilinear_shape5_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape5AllArrowsYellow,

        width: 842.00877,
        height: 389.11652,
        aspectRatio: 2.16389879823144
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape62RedArrows,

        width: 381.00252,
        height: 535.01917,
        aspectRatio: 0.7121287261538685
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape6AllArrows,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape6AllArrowsBlue,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape6AllArrowsGreen,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape6AllArrowsPink,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape6AllArrowsPurple,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Shapes_with_arrows/Rectilinear_shape6_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape6AllArrowsYellow,

        width: 843.34208,
        height: 623.86609,
        aspectRatio: 1.3517998389686479
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape72RedArrows,

        width: 863.62504,
        height: 453.34705,
        aspectRatio: 1.9049975951095302
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape7AllArrows,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape7AllArrowsBlue,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape7AllArrowsGreen,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape7AllArrowsPink,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape7AllArrowsPurple,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Shapes_with_arrows/Rectilinear_shape7_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape7AllArrowsYellow,

        width: 665.07145,
        height: 669.67145,
        aspectRatio: 0.9931309599655174
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape82RedArrows,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape8AllArrows,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape8AllArrowsBlue,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape8AllArrowsGreen,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape8AllArrowsPink,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape8AllArrowsPurple,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Shapes_with_arrows/Rectilinear_shape8_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape8AllArrowsYellow,

        width: 842.00877,
        height: 623.86627,
        aspectRatio: 1.3496622761797974
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_2_red_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape92RedArrows,

        width: 861.49357,
        height: 655.11908,
        aspectRatio: 1.3150182864464883
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_all_arrows':
      return {
        asset: ShapesWithArrowsRectilinearShape9AllArrows,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_blue':
      return {
        asset: ShapesWithArrowsRectilinearShape9AllArrowsBlue,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_green':
      return {
        asset: ShapesWithArrowsRectilinearShape9AllArrowsGreen,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_pink':
      return {
        asset: ShapesWithArrowsRectilinearShape9AllArrowsPink,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_purple':
      return {
        asset: ShapesWithArrowsRectilinearShape9AllArrowsPurple,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Shapes_with_arrows/Rectilinear_shape9_all_arrows_yellow':
      return {
        asset: ShapesWithArrowsRectilinearShape9AllArrowsYellow,

        width: 431.22878,
        height: 623.86627,
        aspectRatio: 0.6912198987773454
      };
    case 'Shapes_with_arrows/Regular_decagon_1__arrow':
      return {
        asset: ShapesWithArrowsRegularDecagon1Arrow,

        width: 716.78056,
        height: 731.89081,
        aspectRatio: 0.9793545023471466
      };
    case 'Shapes_with_arrows/Regular_heptagon_1__arrow':
      return {
        asset: ShapesWithArrowsRegularHeptagon1Arrow,

        width: 751.30546,
        height: 744.354,
        aspectRatio: 1.0093389166982376
      };
    case 'Shapes_with_arrows/Regular_hexagon_1__arrow':
      return {
        asset: ShapesWithArrowsRegularHexagon1Arrow,

        width: 861.83597,
        height: 732.35999,
        aspectRatio: 1.1767928092303348
      };
    case 'Shapes_with_arrows/Regular_nonagon_1__arrow':
      return {
        asset: ShapesWithArrowsRegularNonagon1Arrow,

        width: 761.88865,
        height: 732.42413,
        aspectRatio: 1.0402287674492647
      };
    case 'Shapes_with_arrows/Regular_octagon_1__arrow':
      return {
        asset: ShapesWithArrowsRegularOctagon1Arrow,

        width: 744.6934,
        height: 736.6933,
        aspectRatio: 1.0108594716417265
      };
    case 'Shapes_with_arrows/Regular_pentagon__1__arrow':
      return {
        asset: ShapesWithArrowsRegularPentagon1Arrow,

        width: 786.55326,
        height: 732.59607,
        aspectRatio: 1.073652033104682
      };
    case 'Shapes_with_arrows/Rhombus_all__sides_marked':
      return {
        asset: ShapesWithArrowsRhombusAllSidesMarked,

        width: 519.49065,
        height: 618.42407,
        aspectRatio: 0.8400233354435896
      };
    case 'Shapes_with_arrows/Right_angle_triangle10_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle103Arrows,

        width: 857.97513,
        height: 554.02826,
        aspectRatio: 1.548612574383841
      };
    case 'Shapes_with_arrows/Right_angle_triangle11_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle113Arrows,

        width: 612.05412,
        height: 648.28876,
        aspectRatio: 0.9441072524533666
      };
    case 'Shapes_with_arrows/Right_angle_triangle1_2_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle12Arrows,

        width: 849.56303,
        height: 545.77899,
        aspectRatio: 1.556606328873158
      };
    case 'Shapes_with_arrows/Right_angle_triangle1_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle13Arrows,

        width: 370.69292,
        height: 555.39532,
        aspectRatio: 0.667439761645813
      };
    case 'Shapes_with_arrows/Right_angle_triangle2_2_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle22Arrows,

        width: 635.40489,
        height: 331.80783,
        aspectRatio: 1.914978588660792
      };
    case 'Shapes_with_arrows/Right_angle_triangle2_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle23Arrows,

        width: 646.70878,
        height: 353.27921,
        aspectRatio: 1.8305882760550787
      };
    case 'Shapes_with_arrows/Right_angle_triangle3_2_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle32Arrows,

        width: 474.79469,
        height: 356.27963,
        aspectRatio: 1.3326461858063567
      };
    case 'Shapes_with_arrows/Right_angle_triangle3_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle33Arrows,

        width: 319.10732,
        height: 648.31213,
        aspectRatio: 0.4922124779618114
      };
    case 'Shapes_with_arrows/Right_angle_triangle4_2_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle42Arrows,

        width: 555.86119,
        height: 356.27939,
        aspectRatio: 1.5601833998873749
      };
    case 'Shapes_with_arrows/Right_angle_triangle4_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle43Arrows,

        width: 776.6229,
        height: 499.53839,
        aspectRatio: 1.554681112696864
      };
    case 'Shapes_with_arrows/Right_angle_triangle5_2_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle52Arrows,

        width: 186.60917,
        height: 777.05115,
        aspectRatio: 0.2401504328254324
      };
    case 'Shapes_with_arrows/Right_angle_triangle5_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle53Arrows,

        width: 702.05784,
        height: 452.43945,
        aspectRatio: 1.5517166772260906
      };
    case 'Shapes_with_arrows/Right_angle_triangle6_2_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle62Arrows,

        width: 521.00316,
        height: 518.82886,
        aspectRatio: 1.0041907846067006
      };
    case 'Shapes_with_arrows/Right_angle_triangle6_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle63Arrows,

        width: 860.75754,
        height: 687.93427,
        aspectRatio: 1.2512206144345739
      };
    case 'Shapes_with_arrows/Right_angle_triangle7_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle73Arrows,

        width: 905.31968,
        height: 581.92346,
        aspectRatio: 1.5557366943068422
      };
    case 'Shapes_with_arrows/Right_angle_triangle8_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle83Arrows,

        width: 869.10154,
        height: 222.4908,
        aspectRatio: 3.906235853347644
      };
    case 'Shapes_with_arrows/Right_angle_triangle9_3_arrows':
      return {
        asset: ShapesWithArrowsRightAngleTriangle93Arrows,

        width: 435.46892,
        height: 767.56708,
        aspectRatio: 0.567336629392704
      };
    case 'Shapes_with_arrows/Square_1_arrows':
      return {
        asset: ShapesWithArrowsSquare1Arrows,

        width: 532.71587,
        height: 516.17548,
        aspectRatio: 1.0320441218943603
      };
    case 'Shapes_with_arrows/Square_2_arrows':
      return {
        asset: ShapesWithArrowsSquare2Arrows,

        width: 532.71587,
        height: 532.71545,
        aspectRatio: 1.0000007884134015
      };
    case 'Shapes_with_arrows/Square__1__arrow':
      return {
        asset: ShapesWithArrowsSquare1Arrow,

        width: 732.35996,
        height: 751.82928,
        aspectRatio: 0.9741040678809423
      };
    case 'Shapes_with_arrows/Square_all__sides_marked':
      return {
        asset: ShapesWithArrowsSquareAllSidesMarked,

        width: 516.17558,
        height: 516.17548,
        aspectRatio: 1.0000001937325655
      };
    case 'Shapes_with_arrows/Trapezium1_all_arrows':
      return {
        asset: ShapesWithArrowsTrapezium1AllArrows,

        width: 836.96726,
        height: 512.9541,
        aspectRatio: 1.631661117437213
      };
    case 'Shapes_with_arrows/Trapezium2_all_arrows':
      return {
        asset: ShapesWithArrowsTrapezium2AllArrows,

        width: 846.72117,
        height: 512.95422,
        aspectRatio: 1.6506759024226374
      };
    case 'Shapes_with_arrows/Triangle10_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle103ArrowsRightAngle,

        width: 369.63625,
        height: 377.98306,
        aspectRatio: 0.9779175024404533
      };
    case 'Shapes_with_arrows/Triangle1_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle12Arrows,

        width: 758.75706,
        height: 499.63138,
        aspectRatio: 1.518633717521906
      };
    case 'Shapes_with_arrows/Triangle1_3_arrows_no_right_angle':
      return {
        asset: ShapesWithArrowsTriangle13ArrowsNoRightAngle,

        width: 769.48344,
        height: 504.87146,
        aspectRatio: 1.5241175248844527
      };
    case 'Shapes_with_arrows/Triangle1_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle13ArrowsRightAngle,

        width: 682.67833,
        height: 698.04773,
        aspectRatio: 0.9779823078860237
      };
    case 'Shapes_with_arrows/Triangle1_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle14ArrowsRightAngle,
        defaultWidth: 702.57227,
        defaultHeight: 698.04773,
        width: 702.57225,
        height: 698.04773,
        aspectRatio: 1.0064816771197007
      };
    case 'Shapes_with_arrows/Triangle2_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle22Arrows,

        width: 672.72117,
        height: 690.97504,
        aspectRatio: 0.9735824466249895
      };
    case 'Shapes_with_arrows/Triangle2_3_arrows_no_right_angle':
      return {
        asset: ShapesWithArrowsTriangle23ArrowsNoRightAngle,

        width: 828.52647,
        height: 699.00934,
        aspectRatio: 1.1852866944524663
      };
    case 'Shapes_with_arrows/Triangle2_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle23ArrowsRightAngle,

        width: 621.60728,
        height: 622.49036,
        aspectRatio: 0.998581375621624
      };
    case 'Shapes_with_arrows/Triangle2_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle24ArrowsRightAngle,
        defaultWidth: 639.98553,
        defaultHeight: 622.49036,
        width: 639.98551,
        height: 622.49036,
        aspectRatio: 1.0281050938684415
      };
    case 'Shapes_with_arrows/Triangle3_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle32Arrows,

        width: 632.17021,
        height: 412.42392,
        aspectRatio: 1.5328165495347603
      };
    case 'Shapes_with_arrows/Triangle3_3_arrows_no_right_angle':
      return {
        asset: ShapesWithArrowsTriangle33ArrowsNoRightAngle,

        width: 542.24706,
        height: 602.84534,
        aspectRatio: 0.8994795580571296
      };
    case 'Shapes_with_arrows/Triangle3_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle33ArrowsRightAngle,

        width: 682.67833,
        height: 698.04773,
        aspectRatio: 0.9779823078860237
      };
    case 'Shapes_with_arrows/Triangle3_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle34ArrowsRightAngle,
        defaultWidth: 700.81708,
        defaultHeight: 698.04773,
        width: 700.81706,
        height: 698.04773,
        aspectRatio: 1.0039672502050827
      };
    case 'Shapes_with_arrows/Triangle4_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle42Arrows,

        width: 631.72917,
        height: 608.27014,
        aspectRatio: 1.0385667953386633
      };
    case 'Shapes_with_arrows/Triangle4_3_arrows_no_right_angle':
      return {
        asset: ShapesWithArrowsTriangle43ArrowsNoRightAngle,

        width: 629.81053,
        height: 690.49878,
        aspectRatio: 0.9121095478257035
      };
    case 'Shapes_with_arrows/Triangle4_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle43ArrowsRightAngle,

        width: 748.70695,
        height: 758.10437,
        aspectRatio: 0.9876040550986402
      };
    case 'Shapes_with_arrows/Triangle4_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle44ArrowsRightAngle,
        defaultWidth: 767.6358,
        defaultHeight: 758.5459,
        width: 767.63578,
        height: 758.5459,
        aspectRatio: 1.0119832959350252
      };
    case 'Shapes_with_arrows/Triangle5_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle52Arrows,

        width: 610.05461,
        height: 419.69421,
        aspectRatio: 1.4535692784515661
      };
    case 'Shapes_with_arrows/Triangle5_3_arrows_no_right_angle':
      return {
        asset: ShapesWithArrowsTriangle53ArrowsNoRightAngle,

        width: 715.81394,
        height: 538.24969,
        aspectRatio: 1.3298919689113058
      };
    case 'Shapes_with_arrows/Triangle5_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle53ArrowsRightAngle,

        width: 629.45781,
        height: 497.33084,
        aspectRatio: 1.2656721831286393
      };
    case 'Shapes_with_arrows/Triangle5_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle54ArrowsRightAngle,
        defaultWidth: 629.45782,
        defaultHeight: 508.89984,
        width: 629.45781,
        height: 508.89984,
        aspectRatio: 1.236899209872025
      };
    case 'Shapes_with_arrows/Triangle6_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle62Arrows,

        width: 1051.874,
        height: 684.79193,
        aspectRatio: 1.536049059456644
      };
    case 'Shapes_with_arrows/Triangle6_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle63ArrowsRightAngle,

        width: 632.17021,
        height: 427.98141,
        aspectRatio: 1.4770973580371167
      };
    case 'Shapes_with_arrows/Triangle6_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle64ArrowsRightAngle,
        defaultWidth: 636.81989,
        defaultHeight: 435.40573,
        width: 636.81987,
        height: 435.40573,
        aspectRatio: 1.4625895483736515
      };
    case 'Shapes_with_arrows/Triangle7_2_arrows':
      return {
        asset: ShapesWithArrowsTriangle72Arrows,

        width: 1151.2608,
        height: 430.16742,
        aspectRatio: 2.676308680001847
      };
    case 'Shapes_with_arrows/Triangle7_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle73ArrowsRightAngle,

        width: 506.11318,
        height: 792.30811,
        aspectRatio: 0.6387832884860916
      };
    case 'Shapes_with_arrows/Triangle7_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle74ArrowsRightAngle,
        defaultWidth: 521.67102,
        defaultHeight: 792.30811,
        width: 521.67101,
        height: 792.30811,
        aspectRatio: 0.658419374250757
      };
    case 'Shapes_with_arrows/Triangle8_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle83ArrowsRightAngle,

        width: 481.39296,
        height: 430.92014,
        aspectRatio: 1.1171280135572221
      };
    case 'Shapes_with_arrows/Triangle9_3_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle93ArrowsRightAngle,

        width: 483.10201,
        height: 508.84293,
        aspectRatio: 0.949412837474228
      };
    case 'Shapes_with_arrows/Triangle9_4_arrows_right_angle':
      return {
        asset: ShapesWithArrowsTriangle94ArrowsRightAngle,
        defaultWidth: 495.021,
        defaultHeight: 508.84293,
        width: 495.02099,
        height: 508.84293,
        aspectRatio: 0.9728365293392206
      };
    case 'Shapes_with_dimension_arrows/Square_blue_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsSquareBlueArrows4,

        width: 549.2556,
        height: 549.25562,
        aspectRatio: 0.9999999635870817
      };
    case 'Shapes_with_dimension_arrows/Square_green_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsSquareGreenArrows4,

        width: 549.2556,
        height: 549.25562,
        aspectRatio: 0.9999999635870817
      };
    case 'Shapes_with_dimension_arrows/Square_orange_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsSquareOrangeArrows4,

        width: 549.2556,
        height: 549.25562,
        aspectRatio: 0.9999999635870817
      };
    case 'Shapes_with_dimension_arrows/Square_purple_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsSquarePurpleArrows4,

        width: 549.2556,
        height: 549.25562,
        aspectRatio: 0.9999999635870817
      };
    case 'Shapes_with_dimension_arrows/Square_red_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsSquareRedArrows4,

        width: 549.2556,
        height: 549.25562,
        aspectRatio: 0.9999999635870817
      };
    case 'Shapes_with_dimension_arrows/hexagon_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsHexagonBlueArrows,
        defaultWidth: 414,
        defaultHeight: 369,
        width: 414,
        height: 369,
        aspectRatio: 1.1219512195121952
      };
    case 'Shapes_with_dimension_arrows/hexagon_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsHexagonGreenArrows,
        defaultWidth: 414,
        defaultHeight: 369,
        width: 414,
        height: 369,
        aspectRatio: 1.1219512195121952
      };
    case 'Shapes_with_dimension_arrows/hexagon_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsHexagonOrangeArrows,
        defaultWidth: 414,
        defaultHeight: 369,
        width: 414,
        height: 369,
        aspectRatio: 1.1219512195121952
      };
    case 'Shapes_with_dimension_arrows/hexagon_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsHexagonPurpleArrows,
        defaultWidth: 414,
        defaultHeight: 369,
        width: 414,
        height: 369,
        aspectRatio: 1.1219512195121952
      };
    case 'Shapes_with_dimension_arrows/hexagon_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsHexagonRedArrows,
        defaultWidth: 414,
        defaultHeight: 369,
        width: 414,
        height: 369,
        aspectRatio: 1.1219512195121952
      };
    case 'Shapes_with_dimension_arrows/pentagon_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsPentagonBlueArrows,
        defaultWidth: 381,
        defaultHeight: 363,
        width: 381,
        height: 363,
        aspectRatio: 1.0495867768595042
      };
    case 'Shapes_with_dimension_arrows/pentagon_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsPentagonGreenArrows,
        defaultWidth: 381,
        defaultHeight: 363,
        width: 381,
        height: 363,
        aspectRatio: 1.0495867768595042
      };
    case 'Shapes_with_dimension_arrows/pentagon_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsPentagonOrangeArrows,
        defaultWidth: 381,
        defaultHeight: 363,
        width: 381,
        height: 363,
        aspectRatio: 1.0495867768595042
      };
    case 'Shapes_with_dimension_arrows/pentagon_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsPentagonPurpleArrows,
        defaultWidth: 381,
        defaultHeight: 363,
        width: 381,
        height: 363,
        aspectRatio: 1.0495867768595042
      };
    case 'Shapes_with_dimension_arrows/pentagon_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsPentagonRedArrows,
        defaultWidth: 382,
        defaultHeight: 363,
        width: 382,
        height: 363,
        aspectRatio: 1.0523415977961432
      };
    case 'Shapes_with_dimension_arrows/rectangle_blue_arrows_2':
      return {
        asset: ShapesWithDimensionArrowsRectangleBlueArrows2,

        width: 615.19946,
        height: 366.8656,
        aspectRatio: 1.6769069108687216
      };
    case 'Shapes_with_dimension_arrows/rectangle_blue_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsRectangleBlueArrows4,

        width: 652.39862,
        height: 402.39865,
        aspectRatio: 1.6212743755477312
      };
    case 'Shapes_with_dimension_arrows/rectangle_green_arrows_2':
      return {
        asset: ShapesWithDimensionArrowsRectangleGreenArrows2,

        width: 615.19946,
        height: 366.8656,
        aspectRatio: 1.6769069108687216
      };
    case 'Shapes_with_dimension_arrows/rectangle_green_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsRectangleGreenArrows4,

        width: 652.39862,
        height: 402.39865,
        aspectRatio: 1.6212743755477312
      };
    case 'Shapes_with_dimension_arrows/rectangle_orange_arrows_2':
      return {
        asset: ShapesWithDimensionArrowsRectangleOrangeArrows2,

        width: 615.19946,
        height: 366.8656,
        aspectRatio: 1.6769069108687216
      };
    case 'Shapes_with_dimension_arrows/rectangle_orange_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsRectangleOrangeArrows4,

        width: 652.39862,
        height: 402.39865,
        aspectRatio: 1.6212743755477312
      };
    case 'Shapes_with_dimension_arrows/rectangle_purple_arrows_2':
      return {
        asset: ShapesWithDimensionArrowsRectanglePurpleArrows2,

        width: 615.19946,
        height: 366.8656,
        aspectRatio: 1.6769069108687216
      };
    case 'Shapes_with_dimension_arrows/rectangle_purple_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsRectanglePurpleArrows4,

        width: 652.39862,
        height: 402.39865,
        aspectRatio: 1.6212743755477312
      };
    case 'Shapes_with_dimension_arrows/rectangle_red_arrows_2':
      return {
        asset: ShapesWithDimensionArrowsRectangleRedArrows2,

        width: 615.19946,
        height: 366.8656,
        aspectRatio: 1.6769069108687216
      };
    case 'Shapes_with_dimension_arrows/rectangle_red_arrows_4':
      return {
        asset: ShapesWithDimensionArrowsRectangleRedArrows4,

        width: 652.39862,
        height: 402.39865,
        aspectRatio: 1.6212743755477312
      };
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsTrapeziumIsoscelesBlueArrows,
        defaultWidth: 483,
        defaultHeight: 372,
        width: 483,
        height: 372,
        aspectRatio: 1.2983870967741935
      };
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsTrapeziumIsoscelesGreenArrows,
        defaultWidth: 483,
        defaultHeight: 372,
        width: 483,
        height: 372,
        aspectRatio: 1.2983870967741935
      };
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_pink_arrows':
      return {
        asset: ShapesWithDimensionArrowsTrapeziumIsoscelesPinkArrows,
        defaultWidth: 483,
        defaultHeight: 372,
        width: 483,
        height: 372,
        aspectRatio: 1.2983870967741935
      };
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsTrapeziumIsoscelesPurpleArrows,
        defaultWidth: 483,
        defaultHeight: 372,
        width: 483,
        height: 372,
        aspectRatio: 1.2983870967741935
      };
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_white_arrows':
      return {
        asset: ShapesWithDimensionArrowsTrapeziumIsoscelesWhiteArrows,
        defaultWidth: 483,
        defaultHeight: 372,
        width: 483,
        height: 372,
        aspectRatio: 1.2983870967741935
      };
    case 'Shapes_with_dimension_arrows/trapezium_isosceles_yellow_arrows':
      return {
        asset: ShapesWithDimensionArrowsTrapeziumIsoscelesYellowArrows,
        defaultWidth: 483,
        defaultHeight: 372,
        width: 483,
        height: 372,
        aspectRatio: 1.2983870967741935
      };
    case 'Shapes_with_dimension_arrows/triangle_RA_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleRABlueArrows,
        defaultWidth: 367,
        defaultHeight: 366,
        width: 367,
        height: 366,
        aspectRatio: 1.0027322404371584
      };
    case 'Shapes_with_dimension_arrows/triangle_RA_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleRAGreenArrows,
        defaultWidth: 367,
        defaultHeight: 366,
        width: 367,
        height: 366,
        aspectRatio: 1.0027322404371584
      };
    case 'Shapes_with_dimension_arrows/triangle_RA_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleRAOrangeArrows,
        defaultWidth: 367,
        defaultHeight: 366,
        width: 367,
        height: 366,
        aspectRatio: 1.0027322404371584
      };
    case 'Shapes_with_dimension_arrows/triangle_RA_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleRAPurpleArrows,
        defaultWidth: 367,
        defaultHeight: 366,
        width: 367,
        height: 366,
        aspectRatio: 1.0027322404371584
      };
    case 'Shapes_with_dimension_arrows/triangle_RA_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleRARedArrows,
        defaultWidth: 368,
        defaultHeight: 366,
        width: 368,
        height: 366,
        aspectRatio: 1.005464480874317
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1Arrow,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_blue':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowBlue,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_green':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowGreen,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_orange':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowOrange,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_pink':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowPink,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_purple':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowPurple,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_red':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowRed,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_1_arrow_yellow':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqual1ArrowYellow,
        defaultWidth: 402,
        defaultHeight: 341,
        width: 402,
        height: 341,
        aspectRatio: 1.1788856304985338
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqualBlueArrows,
        defaultWidth: 421,
        defaultHeight: 363,
        width: 421,
        height: 363,
        aspectRatio: 1.159779614325069
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqualGreenArrows,
        defaultWidth: 421,
        defaultHeight: 363,
        width: 421,
        height: 363,
        aspectRatio: 1.159779614325069
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqualOrangeArrows,
        defaultWidth: 421,
        defaultHeight: 363,
        width: 421,
        height: 363,
        aspectRatio: 1.159779614325069
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqualPurpleArrows,
        defaultWidth: 421,
        defaultHeight: 363,
        width: 421,
        height: 363,
        aspectRatio: 1.159779614325069
      };
    case 'Shapes_with_dimension_arrows/triangle_equal_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleEqualRedArrows,
        defaultWidth: 421,
        defaultHeight: 363,
        width: 421,
        height: 363,
        aspectRatio: 1.159779614325069
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_narrow_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosNarrowBlueArrows,
        defaultWidth: 313,
        defaultHeight: 360,
        width: 313,
        height: 360,
        aspectRatio: 0.8694444444444445
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_narrow_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosNarrowGreenArrows,
        defaultWidth: 313,
        defaultHeight: 360,
        width: 313,
        height: 360,
        aspectRatio: 0.8694444444444445
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_narrow_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosNarrowOrangeArrows,
        defaultWidth: 313,
        defaultHeight: 360,
        width: 313,
        height: 360,
        aspectRatio: 0.8694444444444445
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_narrow_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosNarrowPurpleArrows,
        defaultWidth: 313,
        defaultHeight: 360,
        width: 313,
        height: 360,
        aspectRatio: 0.8694444444444445
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_narrow_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosNarrowRedArrows,
        defaultWidth: 313,
        defaultHeight: 360,
        width: 313,
        height: 360,
        aspectRatio: 0.8694444444444445
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_wide_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosWideBlueArrows,
        defaultWidth: 474,
        defaultHeight: 363,
        width: 474,
        height: 363,
        aspectRatio: 1.3057851239669422
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_wide_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosWideGreenArrows,
        defaultWidth: 474,
        defaultHeight: 363,
        width: 474,
        height: 363,
        aspectRatio: 1.3057851239669422
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_wide_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosWideOrangeArrows,
        defaultWidth: 474,
        defaultHeight: 363,
        width: 474,
        height: 363,
        aspectRatio: 1.3057851239669422
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_wide_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosWidePurpleArrows,
        defaultWidth: 474,
        defaultHeight: 363,
        width: 474,
        height: 363,
        aspectRatio: 1.3057851239669422
      };
    case 'Shapes_with_dimension_arrows/triangle_isos_wide_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleIsosWideRedArrows,
        defaultWidth: 474,
        defaultHeight: 363,
        width: 474,
        height: 363,
        aspectRatio: 1.3057851239669422
      };
    case 'Shapes_with_dimension_arrows/triangle_scalene_blue_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleScaleneBlueArrows,
        defaultWidth: 559,
        defaultHeight: 195,
        width: 559,
        height: 195,
        aspectRatio: 2.8666666666666667
      };
    case 'Shapes_with_dimension_arrows/triangle_scalene_green_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleScaleneGreenArrows,
        defaultWidth: 559,
        defaultHeight: 195,
        width: 559,
        height: 195,
        aspectRatio: 2.8666666666666667
      };
    case 'Shapes_with_dimension_arrows/triangle_scalene_orange_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleScaleneOrangeArrows,
        defaultWidth: 559,
        defaultHeight: 195,
        width: 559,
        height: 195,
        aspectRatio: 2.8666666666666667
      };
    case 'Shapes_with_dimension_arrows/triangle_scalene_purple_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleScalenePurpleArrows,
        defaultWidth: 559,
        defaultHeight: 195,
        width: 559,
        height: 195,
        aspectRatio: 2.8666666666666667
      };
    case 'Shapes_with_dimension_arrows/triangle_scalene_red_arrows':
      return {
        asset: ShapesWithDimensionArrowsTriangleScaleneRedArrows,
        defaultWidth: 559,
        defaultHeight: 195,
        width: 559,
        height: 195,
        aspectRatio: 2.8666666666666667
      };
    case 'Shapes_with_interior_angles/Equilateral_triangle_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesEquilateralTriangle1InteriorAngle,

        width: 600.24516,
        height: 520.09454,
        aspectRatio: 1.1541077897106937
      };
    case 'Shapes_with_interior_angles/Equilateral_triangle_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesEquilateralTriangleInteriorAngles,

        width: 558.94383,
        height: 484.32657,
        aspectRatio: 1.1540639407827658
      };
    case 'Shapes_with_interior_angles/Hexagon1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesHexagon1InteriorAngles,

        width: 437.05071,
        height: 378.49731,
        aspectRatio: 1.1546996463462316
      };
    case 'Shapes_with_interior_angles/Hexagon2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesHexagon2InteriorAngles,

        width: 382.23171,
        height: 436.98505,
        aspectRatio: 0.8747020292799491
      };
    case 'Shapes_with_interior_angles/Hexagon3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesHexagon3InteriorAngles,

        width: 401.372,
        height: 478.89163,
        aspectRatio: 0.8381269891896002
      };
    case 'Shapes_with_interior_angles/Hexagon4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesHexagon4InteriorAngles,

        width: 329.77517,
        height: 378.49731,
        aspectRatio: 0.8712748050970296
      };
    case 'Shapes_with_interior_angles/Hexagon5_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesHexagon5InteriorAngles,

        width: 683.11668,
        height: 243.55733,
        aspectRatio: 2.804746956291564
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle1':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle1,

        width: 562.12517,
        height: 487.08255,
        aspectRatio: 1.1540655069659136
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle10':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle10,

        width: 938.40494,
        height: 418.15195,
        aspectRatio: 2.2441721005964457
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle11':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle11,

        width: 466.24105,
        height: 268.1106,
        aspectRatio: 1.7389877535614033
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle12':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle12,

        width: 491.485,
        height: 245.82661,
        aspectRatio: 1.9993156965391177
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle13':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle13,

        width: 472.87355,
        height: 472.87317,
        aspectRatio: 1.0000008035981403
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle14':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle14,

        width: 786.41837,
        height: 418.15195,
        aspectRatio: 1.8806999943441611
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle2':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle2,

        width: 1269.7999,
        height: 746.89197,
        aspectRatio: 1.7001118649059783
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle3':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle3,

        width: 877.74619,
        height: 324.15598,
        aspectRatio: 2.707789595613815
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle4':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle4,

        width: 877.74607,
        height: 278.82394,
        aspectRatio: 3.1480297925637233
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle5':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle5,

        width: 278.82391,
        height: 519.36157,
        aspectRatio: 0.5368589555056991
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle6':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle6,

        width: 443.65975,
        height: 278.82391,
        aspectRatio: 1.5911825854533062
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle7':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle7,

        width: 322.06252,
        height: 440.73309,
        aspectRatio: 0.7307427722297866
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle8':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle8,

        width: 786.41837,
        height: 418.15195,
        aspectRatio: 1.8806999943441611
      };
    case 'Shapes_with_interior_angles/Interior_angles_in_triangle9':
      return {
        asset: ShapesWithInteriorAnglesInteriorAnglesInTriangle9,

        width: 472.87355,
        height: 472.87317,
        aspectRatio: 1.0000008035981403
      };
    case 'Shapes_with_interior_angles/Irregular_decagon1_with_interior_triangles_copy_5':
      return {
        asset: ShapesWithInteriorAnglesIrregularDecagon1WithInteriorTrianglesCopy5,

        width: 486.99196,
        height: 386.48398,
        aspectRatio: 1.2600572991408339
      };
    case 'Shapes_with_interior_angles/Irregular_decagon2_with_interior_triangles_copy_5':
      return {
        asset: ShapesWithInteriorAnglesIrregularDecagon2WithInteriorTrianglesCopy5,

        width: 528.74498,
        height: 536.3493,
        aspectRatio: 0.9858220752781818
      };
    case 'Shapes_with_interior_angles/Irregular_decagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesIrregularDecagonOneInteriorAngle,

        width: 407.55196,
        height: 414.23596,
        aspectRatio: 0.9838642690509053
      };
    case 'Shapes_with_interior_angles/Irregular_heptagon1_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularHeptagon1MarkedInteriorAngles,

        width: 803.5518,
        height: 549.27991,
        aspectRatio: 1.4629186055612338
      };
    case 'Shapes_with_interior_angles/Irregular_heptagon1_with_interior_triangles_copy_2':
      return {
        asset: ShapesWithInteriorAnglesIrregularHeptagon1WithInteriorTrianglesCopy2,

        width: 537.12395,
        height: 515.64661,
        aspectRatio: 1.0416512774126452
      };
    case 'Shapes_with_interior_angles/Irregular_heptagon2_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularHeptagon2MarkedInteriorAngles,

        width: 629.6732,
        height: 549.27997,
        aspectRatio: 1.1463611170820591
      };
    case 'Shapes_with_interior_angles/Irregular_heptagon2_with_interior_triangles_copy_2':
      return {
        asset: ShapesWithInteriorAnglesIrregularHeptagon2WithInteriorTrianglesCopy2,

        width: 447.52397,
        height: 416.1973,
        aspectRatio: 1.0752687967942127
      };
    case 'Shapes_with_interior_angles/Irregular_heptagon3_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularHeptagon3MarkedInteriorAngles,

        width: 560.23051,
        height: 516.3291,
        aspectRatio: 1.0850260231313709
      };
    case 'Shapes_with_interior_angles/Irregular_heptagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesIrregularHeptagonOneInteriorAngle,

        width: 397.5013,
        height: 383.49463,
        aspectRatio: 1.0365237708804425
      };
    case 'Shapes_with_interior_angles/Irregular_hexagon1_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularHexagon1MarkedInteriorAngles,

        width: 622.92509,
        height: 417.85999,
        aspectRatio: 1.490750741653921
      };
    case 'Shapes_with_interior_angles/Irregular_hexagon1_with_interior_triangles_copy':
      return {
        asset: ShapesWithInteriorAnglesIrregularHexagon1WithInteriorTrianglesCopy,

        width: 663.29722,
        height: 353.03464,
        aspectRatio: 1.8788445802372253
      };
    case 'Shapes_with_interior_angles/Irregular_hexagon2_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularHexagon2MarkedInteriorAngles,

        width: 586.60527,
        height: 417.85864,
        aspectRatio: 1.4038366419801684
      };
    case 'Shapes_with_interior_angles/Irregular_hexagon2_with_interior_triangles_copy':
      return {
        asset: ShapesWithInteriorAnglesIrregularHexagon2WithInteriorTrianglesCopy,

        width: 503.30385,
        height: 316.09326,
        aspectRatio: 1.592263783163235
      };
    case 'Shapes_with_interior_angles/Irregular_hexagon3_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularHexagon3MarkedInteriorAngles,

        width: 630.42387,
        height: 467.76263,
        aspectRatio: 1.3477431277483625
      };
    case 'Shapes_with_interior_angles/Irregular_hexagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesIrregularHexagonOneInteriorAngle,

        width: 495.04793,
        height: 401.23065,
        aspectRatio: 1.2338238118149747
      };
    case 'Shapes_with_interior_angles/Irregular_nonagon1_with_interior_triangles_copy_4':
      return {
        asset: ShapesWithInteriorAnglesIrregularNonagon1WithInteriorTrianglesCopy4,

        width: 500.08663,
        height: 425.564,
        aspectRatio: 1.175114976830747
      };
    case 'Shapes_with_interior_angles/Irregular_nonagon2_with_interior_triangles_copy_4':
      return {
        asset: ShapesWithInteriorAnglesIrregularNonagon2WithInteriorTrianglesCopy4,

        width: 463.00264,
        height: 497.06921,
        aspectRatio: 0.9314651374201994
      };
    case 'Shapes_with_interior_angles/Irregular_nonagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesIrregularNonagonOneInteriorAngle,

        width: 388.16796,
        height: 408.91599,
        aspectRatio: 0.9492608983082319
      };
    case 'Shapes_with_interior_angles/Irregular_octagon1_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularOctagon1MarkedInteriorAngles,

        width: 650.75193,
        height: 549.27997,
        aspectRatio: 1.1847363194401572
      };
    case 'Shapes_with_interior_angles/Irregular_octagon1_with_interior_triangles_copy_3':
      return {
        asset: ShapesWithInteriorAnglesIrregularOctagon1WithInteriorTrianglesCopy3,

        width: 543.37596,
        height: 447.31326,
        aspectRatio: 1.214754867763142
      };
    case 'Shapes_with_interior_angles/Irregular_octagon2_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularOctagon2MarkedInteriorAngles,

        width: 603.47709,
        height: 668.95441,
        aspectRatio: 0.90211990679604
      };
    case 'Shapes_with_interior_angles/Irregular_octagon2_with_interior_triangles_copy_3':
      return {
        asset: ShapesWithInteriorAnglesIrregularOctagon2WithInteriorTrianglesCopy3,

        width: 509.38262,
        height: 478.06262,
        aspectRatio: 1.065514429887867
      };
    case 'Shapes_with_interior_angles/Irregular_octagon3_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularOctagon3MarkedInteriorAngles,

        width: 732.33171,
        height: 644.1239,
        aspectRatio: 1.136942302560113
      };
    case 'Shapes_with_interior_angles/Irregular_octagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesIrregularOctagonOneInteriorAngle,

        width: 429.78395,
        height: 426.83197,
        aspectRatio: 1.0069160236521177
      };
    case 'Shapes_with_interior_angles/Irregular_pentagon1_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularPentagon1MarkedInteriorAngles,

        width: 878.1345,
        height: 489.91864,
        aspectRatio: 1.792408837516368
      };
    case 'Shapes_with_interior_angles/Irregular_pentagon1_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesIrregularPentagon1WithInteriorTriangles,

        width: 461.81059,
        height: 394.92395,
        aspectRatio: 1.1693658741132311
      };
    case 'Shapes_with_interior_angles/Irregular_pentagon2_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularPentagon2MarkedInteriorAngles,

        width: 616.23723,
        height: 515.69061,
        aspectRatio: 1.1949746961652064
      };
    case 'Shapes_with_interior_angles/Irregular_pentagon2_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesIrregularPentagon2WithInteriorTriangles,

        width: 503.91463,
        height: 355.66663,
        aspectRatio: 1.4168172875819134
      };
    case 'Shapes_with_interior_angles/Irregular_pentagon3_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularPentagon3MarkedInteriorAngles,

        width: 927.79441,
        height: 410.18665,
        aspectRatio: 2.261883486456714
      };
    case 'Shapes_with_interior_angles/Irregular_pentagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesIrregularPentagonOneInteriorAngle,

        width: 524.67595,
        height: 412.17865,
        aspectRatio: 1.2729333506235705
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral1InteriorAngles,

        width: 580.73723,
        height: 458.85062,
        aspectRatio: 1.265634619824639
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral1_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral1MarkedInteriorAngles,

        width: 525.48924,
        height: 461.05997,
        aspectRatio: 1.1397416262357367
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral2InteriorAngles,

        width: 760.71055,
        height: 505.6893,
        aspectRatio: 1.5043042239572797
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral2_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral2MarkedInteriorAngles,

        width: 745.03178,
        height: 501.36658,
        aspectRatio: 1.4860020785589658
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral3InteriorAngles,

        width: 658.91056,
        height: 377.99197,
        aspectRatio: 1.7431866608171598
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral3_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral3MarkedInteriorAngles,

        width: 533.08391,
        height: 647.57318,
        aspectRatio: 0.823202576116571
      };
    case 'Shapes_with_interior_angles/Irregular_quadrilateral4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularQuadrilateral4InteriorAngles,

        width: 675.94914,
        height: 388.63461,
        aspectRatio: 1.7392921850166665
      };
    case 'Shapes_with_interior_angles/Irregular_triangle1_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularTriangle1MarkedInteriorAngles,

        width: 743.83433,
        height: 346.51862,
        aspectRatio: 2.1465926708354086
      };
    case 'Shapes_with_interior_angles/Irregular_triangle2_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularTriangle2MarkedInteriorAngles,

        width: 656.21141,
        height: 351.74911,
        aspectRatio: 1.8655666534593365
      };
    case 'Shapes_with_interior_angles/Irregular_triangle3_marked_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIrregularTriangle3MarkedInteriorAngles,

        width: 581.23308,
        height: 660.30371,
        aspectRatio: 0.8802511195946483
      };
    case 'Shapes_with_interior_angles/Isosceles_triangle1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIsoscelesTriangle1InteriorAngles,

        width: 322.21325,
        height: 484.32645,
        aspectRatio: 0.6652811342432362
      };
    case 'Shapes_with_interior_angles/Isosceles_triangle2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIsoscelesTriangle2InteriorAngles,

        width: 322.21322,
        height: 348.30917,
        aspectRatio: 0.9250781999222127
      };
    case 'Shapes_with_interior_angles/Isosceles_triangle3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesIsoscelesTriangle3InteriorAngles,

        width: 549.15965,
        height: 565.58649,
        aspectRatio: 0.9709560955036249
      };
    case 'Shapes_with_interior_angles/Kite_two_isosceles_triangles1':
      return {
        asset: ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles1,

        width: 321.46386,
        height: 422.25851,
        aspectRatio: 0.7612963442702434
      };
    case 'Shapes_with_interior_angles/Kite_two_isosceles_triangles2':
      return {
        asset: ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles2,

        width: 284.00533,
        height: 632.11993,
        aspectRatio: 0.44929026363715513
      };
    case 'Shapes_with_interior_angles/Kite_two_isosceles_triangles3':
      return {
        asset: ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles3,
        defaultWidth: 632.1012,
        defaultHeight: 323.25342,
        width: 632.10118,
        height: 323.25342,
        aspectRatio: 1.9554353980230124
      };
    case 'Shapes_with_interior_angles/Kite_two_isosceles_triangles4':
      return {
        asset: ShapesWithInteriorAnglesKiteTwoIsoscelesTriangles4,

        width: 412.73842,
        height: 361.17725,
        aspectRatio: 1.1427586316690765
      };
    case 'Shapes_with_interior_angles/Parallelogram1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesParallelogram1InteriorAngles,

        width: 596.05992,
        height: 365.84,
        aspectRatio: 1.6292912748742623
      };
    case 'Shapes_with_interior_angles/Parallelogram1_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesParallelogram1WithInteriorTriangles,

        width: 821.68656,
        height: 286.564,
        aspectRatio: 2.8673753856032156
      };
    case 'Shapes_with_interior_angles/Parallelogram2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesParallelogram2InteriorAngles,

        width: 803.03983,
        height: 366.16132,
        aspectRatio: 2.1931312406236683
      };
    case 'Shapes_with_interior_angles/Parallelogram2_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesParallelogram2WithInteriorTriangles,

        width: 286.56399,
        height: 821.68658,
        aspectRatio: 0.3487509677960178
      };
    case 'Shapes_with_interior_angles/Parallelogram3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesParallelogram3InteriorAngles,

        width: 604.14384,
        height: 613.51196,
        aspectRatio: 0.9847303384273062
      };
    case 'Shapes_with_interior_angles/Parallelogram4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesParallelogram4InteriorAngles,

        width: 569.71416,
        height: 389.81091,
        aspectRatio: 1.461514147974976
      };
    case 'Shapes_with_interior_angles/Pentagon1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesPentagon1InteriorAngles,

        width: 361.73061,
        height: 344.12531,
        aspectRatio: 1.0511595616143434
      };
    case 'Shapes_with_interior_angles/Pentagon2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesPentagon2InteriorAngles,

        width: 359.53063,
        height: 359.46664,
        aspectRatio: 1.0001780137372414
      };
    case 'Shapes_with_interior_angles/Pentagon3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesPentagon3InteriorAngles,

        width: 361.73049,
        height: 213.44519,
        aspectRatio: 1.6947230808995977
      };
    case 'Shapes_with_interior_angles/Pentagon4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesPentagon4InteriorAngles,

        width: 444.95323,
        height: 291.09598,
        aspectRatio: 1.5285447432149355
      };
    case 'Shapes_with_interior_angles/Pentagon5_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesPentagon5InteriorAngles,

        width: 315.56396,
        height: 409.66794,
        aspectRatio: 0.7702920565373019
      };
    case 'Shapes_with_interior_angles/Quadrilateral1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral1InteriorAngles,

        width: 631.12657,
        height: 451.72662,
        aspectRatio: 1.3971427453179535
      };
    case 'Shapes_with_interior_angles/Quadrilateral1_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral1WithInteriorTriangles,

        width: 753.79978,
        height: 497.25192,
        aspectRatio: 1.515931361310782
      };
    case 'Shapes_with_interior_angles/Quadrilateral2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral2InteriorAngles,

        width: 556.75371,
        height: 455.63931,
        aspectRatio: 1.2219176391957927
      };
    case 'Shapes_with_interior_angles/Quadrilateral2_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral2WithInteriorTriangles,

        width: 716.98887,
        height: 400.21048,
        aspectRatio: 1.7915294721917328
      };
    case 'Shapes_with_interior_angles/Quadrilateral3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral3InteriorAngles,

        width: 440.2546,
        height: 465.94257,
        aspectRatio: 0.9448688064711495
      };
    case 'Shapes_with_interior_angles/Quadrilateral4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral4InteriorAngles,

        width: 442.48925,
        height: 490.85059,
        aspectRatio: 0.9014744181116294
      };
    case 'Shapes_with_interior_angles/Quadrilateral5_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral5InteriorAngles,

        width: 465.02925,
        height: 593.30652,
        aspectRatio: 0.7837925833007869
      };
    case 'Shapes_with_interior_angles/Quadrilateral6_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral6InteriorAngles,

        width: 632,
        height: 452,
        aspectRatio: 1.3982300884955752
      };
    case 'Shapes_with_interior_angles/Quadrilateral7_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesQuadrilateral7InteriorAngles,

        width: 632,
        height: 452,
        aspectRatio: 1.3982300884955752
      };
    case 'Shapes_with_interior_angles/Rectangle1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRectangle1InteriorAngles,

        width: 722.99461,
        height: 283.80533,
        aspectRatio: 2.5475018739077235
      };
    case 'Shapes_with_interior_angles/Rectangle1_interior_lines_and_angles':
      return {
        asset: ShapesWithInteriorAnglesRectangle1InteriorLinesAndAngles,

        width: 597.91331,
        height: 399.276,
        aspectRatio: 1.4974937386669873
      };
    case 'Shapes_with_interior_angles/Rectangle2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRectangle2InteriorAngles,

        width: 999.98392,
        height: 147.70667,
        aspectRatio: 6.770066104665416
      };
    case 'Shapes_with_interior_angles/Rectangle2_interior_lines_and_angles':
      return {
        asset: ShapesWithInteriorAnglesRectangle2InteriorLinesAndAngles,

        width: 710.92265,
        height: 233.448,
        aspectRatio: 3.0453148024399437
      };
    case 'Shapes_with_interior_angles/Rectangle3_interior_lines_and_angles':
      return {
        asset: ShapesWithInteriorAnglesRectangle3InteriorLinesAndAngles,

        width: 710.92265,
        height: 172.53867,
        aspectRatio: 4.1203670458338415
      };
    case 'Shapes_with_interior_angles/Rectangle4_interior_lines_and_angles':
      return {
        asset: ShapesWithInteriorAnglesRectangle4InteriorLinesAndAngles,

        width: 233.44799,
        height: 710.92267,
        aspectRatio: 0.3283732533103776
      };
    case 'Shapes_with_interior_angles/Rectangle_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRectangleWithInteriorTriangles,

        width: 691.93327,
        height: 323.25333,
        aspectRatio: 2.1405294417229976
      };
    case 'Shapes_with_interior_angles/Regular_decagon_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularDecagon1InteriorAngle,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Shapes_with_interior_angles/Regular_decagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularDecagonOneInteriorAngle,

        width: 448.69463,
        height: 426.832,
        aspectRatio: 1.0512206910447202
      };
    case 'Shapes_with_interior_angles/Regular_decagon_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRegularDecagonWithInteriorTriangles,

        width: 469.82396,
        height: 446.92667,
        aspectRatio: 1.0512327671114368
      };
    case 'Shapes_with_interior_angles/Regular_heptagon_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularHeptagon1InteriorAngle,

        width: 533.41863,
        height: 520.09467,
        aspectRatio: 1.0256183359848698
      };
    case 'Shapes_with_interior_angles/Regular_heptagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularHeptagonOneInteriorAngle,

        width: 437.75728,
        height: 426.83197,
        aspectRatio: 1.0255962785542985
      };
    case 'Shapes_with_interior_angles/Regular_heptagon_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRegularHeptagonWithInteriorTriangles,

        width: 459.04262,
        height: 447.58398,
        aspectRatio: 1.0256010950168502
      };
    case 'Shapes_with_interior_angles/Regular_hexagon_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularHexagon1InteriorAngle,

        width: 595.09324,
        height: 515.6333,
        aspectRatio: 1.1541016454910884
      };
    case 'Shapes_with_interior_angles/Regular_hexagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularHexagonOneInteriorAngle,

        width: 492.55461,
        height: 426.832,
        aspectRatio: 1.153977700828429
      };
    case 'Shapes_with_interior_angles/Regular_hexagon_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRegularHexagonWithInteriorTriangles,

        width: 475.82796,
        height: 412.34665,
        aspectRatio: 1.1539513174170326
      };
    case 'Shapes_with_interior_angles/Regular_nonagon_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularNonagon1InteriorAngle,

        width: 525.42394,
        height: 520.09467,
        aspectRatio: 1.010246730657709
      };
    case 'Shapes_with_interior_angles/Regular_nonagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularNonagonOneInteriorAngle,

        width: 433.38665,
        height: 426.83197,
        aspectRatio: 1.0153565816543686
      };
    case 'Shapes_with_interior_angles/Regular_nonagon_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRegularNonagonWithInteriorTriangles,

        width: 454.18398,
        height: 447.31332,
        aspectRatio: 1.0153598377083877
      };
    case 'Shapes_with_interior_angles/Regular_octagon_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularOctagon1InteriorAngle,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Shapes_with_interior_angles/Regular_octagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularOctagonOneInteriorAngle,

        width: 426.83199,
        height: 426.832,
        aspectRatio: 0.9999999765715786
      };
    case 'Shapes_with_interior_angles/Regular_octagon_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRegularOctagonWithInteriorTriangles,

        width: 447.31331,
        height: 447.31332,
        aspectRatio: 0.9999999776443054
      };
    case 'Shapes_with_interior_angles/Regular_pentagon_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularPentagon1InteriorAngle,

        width: 543.15727,
        height: 516.67065,
        aspectRatio: 1.0512640305773127
      };
    case 'Shapes_with_interior_angles/Regular_pentagon_one_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesRegularPentagonOneInteriorAngle,

        width: 448.69594,
        height: 426.83197,
        aspectRatio: 1.05122383405348
      };
    case 'Shapes_with_interior_angles/Regular_pentagon_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRegularPentagonWithInteriorTriangles,

        width: 433.46395,
        height: 412.34662,
        aspectRatio: 1.0512125696580223
      };
    case 'Shapes_with_interior_angles/Rhombus1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRhombus1InteriorAngles,

        width: 870.38767,
        height: 424.06506,
        aspectRatio: 2.0524861680422335
      };
    case 'Shapes_with_interior_angles/Rhombus2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRhombus2InteriorAngles,

        width: 424.06505,
        height: 870.3877,
        aspectRatio: 0.4872139737268805
      };
    case 'Shapes_with_interior_angles/Rhombus3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRhombus3InteriorAngles,

        width: 1064.0101,
        height: 327.97864,
        aspectRatio: 3.244144496726982
      };
    case 'Shapes_with_interior_angles/Rhombus4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRhombus4InteriorAngles,

        width: 584.53056,
        height: 520.93463,
        aspectRatio: 1.1220804422236241
      };
    case 'Shapes_with_interior_angles/Rhombus_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesRhombusWithInteriorTriangles,

        width: 634.66252,
        height: 439.58398,
        aspectRatio: 1.443779912088698
      };
    case 'Shapes_with_interior_angles/Right_angled_triangle_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesRightAngledTriangleInteriorAngles,

        width: 484.32717,
        height: 484.32651,
        aspectRatio: 1.0000013627170647
      };
    case 'Shapes_with_interior_angles/Scalene_triangle1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesScaleneTriangle1InteriorAngles,

        width: 774.19029,
        height: 332.90131,
        aspectRatio: 2.3255849909392063
      };
    case 'Shapes_with_interior_angles/Scalene_triangle2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesScaleneTriangle2InteriorAngles,

        width: 862.47959,
        height: 332.90131,
        aspectRatio: 2.5907966237801827
      };
    case 'Shapes_with_interior_angles/Scalene_triangle3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesScaleneTriangle3InteriorAngles,

        width: 862.47971,
        height: 471.25327,
        aspectRatio: 1.830182971462458
      };
    case 'Shapes_with_interior_angles/Scalene_triangle4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesScaleneTriangle4InteriorAngles,

        width: 792.58189,
        height: 341.70911,
        aspectRatio: 2.3194637392020367
      };
    case 'Shapes_with_interior_angles/Scalene_triangle5_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesScaleneTriangle5InteriorAngles,

        width: 1084.2988,
        height: 170.40799,
        aspectRatio: 6.362957511557997
      };
    case 'Shapes_with_interior_angles/Square_1_interior_angle':
      return {
        asset: ShapesWithInteriorAnglesSquare1InteriorAngle,

        width: 520.09465,
        height: 520.09467,
        aspectRatio: 0.9999999615454626
      };
    case 'Shapes_with_interior_angles/Square_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesSquareInteriorAngles,

        width: 484.32799,
        height: 484.32666,
        aspectRatio: 1.000002746080507
      };
    case 'Shapes_with_interior_angles/Square_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesSquareWithInteriorTriangles,

        width: 392.15596,
        height: 392.15598,
        aspectRatio: 0.9999999489998852
      };
    case 'Shapes_with_interior_angles/Trapezium1_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesTrapezium1InteriorAngles,

        width: 683.04387,
        height: 567.06042,
        aspectRatio: 1.2045345538311418
      };
    case 'Shapes_with_interior_angles/Trapezium1_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesTrapezium1WithInteriorTriangles,

        width: 763.52116,
        height: 325.93732,
        aspectRatio: 2.342539847845592
      };
    case 'Shapes_with_interior_angles/Trapezium2_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesTrapezium2InteriorAngles,

        width: 683.04454,
        height: 480.34842,
        aspectRatio: 1.421977280574796
      };
    case 'Shapes_with_interior_angles/Trapezium2_with_interior_triangles':
      return {
        asset: ShapesWithInteriorAnglesTrapezium2WithInteriorTriangles,

        width: 890.59049,
        height: 464.63998,
        aspectRatio: 1.916732369866235
      };
    case 'Shapes_with_interior_angles/Trapezium3_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesTrapezium3InteriorAngles,

        width: 683.04387,
        height: 567.06042,
        aspectRatio: 1.2045345538311418
      };
    case 'Shapes_with_interior_angles/Trapezium4_interior_angles':
      return {
        asset: ShapesWithInteriorAnglesTrapezium4InteriorAngles,

        width: 683.04387,
        height: 567.06042,
        aspectRatio: 1.2045345538311418
      };
    case 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_blue':
      return {
        asset: ShapesWithMarkedSidesEquilateralTriangleAllSidesBlue,

        width: 542.27739,
        height: 494.04919,
        aspectRatio: 1.0976182148988038
      };
    case 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_green':
      return {
        asset: ShapesWithMarkedSidesEquilateralTriangleAllSidesGreen,

        width: 542.27739,
        height: 494.04919,
        aspectRatio: 1.0976182148988038
      };
    case 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesEquilateralTriangleAllSidesMarked,

        width: 542.27739,
        height: 494.04919,
        aspectRatio: 1.0976182148988038
      };
    case 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_pink':
      return {
        asset: ShapesWithMarkedSidesEquilateralTriangleAllSidesPink,

        width: 542.27739,
        height: 494.04919,
        aspectRatio: 1.0976182148988038
      };
    case 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_purple':
      return {
        asset: ShapesWithMarkedSidesEquilateralTriangleAllSidesPurple,

        width: 542.27739,
        height: 494.04919,
        aspectRatio: 1.0976182148988038
      };
    case 'Shapes_with_marked_sides/Equilateral_triangle_all__sides_yellow':
      return {
        asset: ShapesWithMarkedSidesEquilateralTriangleAllSidesYellow,

        width: 542.27739,
        height: 494.04919,
        aspectRatio: 1.0976182148988038
      };
    case 'Shapes_with_marked_sides/Isosceles1_markedlines':
      return {
        asset: ShapesWithMarkedSidesIsosceles1Markedlines,

        width: 623.70927,
        height: 623.70947,
        aspectRatio: 0.9999996793378814
      };
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_blue':
      return {
        asset: ShapesWithMarkedSidesIsosceles1MarkedlinesBlue,

        width: 623.70927,
        height: 623.70947,
        aspectRatio: 0.9999996793378814
      };
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_green':
      return {
        asset: ShapesWithMarkedSidesIsosceles1MarkedlinesGreen,

        width: 623.70927,
        height: 623.70947,
        aspectRatio: 0.9999996793378814
      };
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_pink':
      return {
        asset: ShapesWithMarkedSidesIsosceles1MarkedlinesPink,

        width: 623.70927,
        height: 623.70947,
        aspectRatio: 0.9999996793378814
      };
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_purple':
      return {
        asset: ShapesWithMarkedSidesIsosceles1MarkedlinesPurple,

        width: 623.70927,
        height: 623.70947,
        aspectRatio: 0.9999996793378814
      };
    case 'Shapes_with_marked_sides/Isosceles1_markedlines_yellow':
      return {
        asset: ShapesWithMarkedSidesIsosceles1MarkedlinesYellow,

        width: 623.70927,
        height: 623.70947,
        aspectRatio: 0.9999996793378814
      };
    case 'Shapes_with_marked_sides/Isosceles2_markedlines':
      return {
        asset: ShapesWithMarkedSidesIsosceles2Markedlines,

        width: 305.27727,
        height: 704.24762,
        aspectRatio: 0.43348001658848345
      };
    case 'Shapes_with_marked_sides/Isosceles2_markedlines_blue':
      return {
        asset: ShapesWithMarkedSidesIsosceles2MarkedlinesBlue,

        width: 305.27727,
        height: 704.24762,
        aspectRatio: 0.43348001658848345
      };
    case 'Shapes_with_marked_sides/Isosceles2_markedlines_green':
      return {
        asset: ShapesWithMarkedSidesIsosceles2MarkedlinesGreen,

        width: 305.27727,
        height: 704.24762,
        aspectRatio: 0.43348001658848345
      };
    case 'Shapes_with_marked_sides/Isosceles2_markedlines_pink':
      return {
        asset: ShapesWithMarkedSidesIsosceles2MarkedlinesPink,

        width: 305.27727,
        height: 704.24762,
        aspectRatio: 0.43348001658848345
      };
    case 'Shapes_with_marked_sides/Isosceles2_markedlines_purple':
      return {
        asset: ShapesWithMarkedSidesIsosceles2MarkedlinesPurple,

        width: 305.27727,
        height: 704.24762,
        aspectRatio: 0.43348001658848345
      };
    case 'Shapes_with_marked_sides/Isosceles2_markedlines_yellow':
      return {
        asset: ShapesWithMarkedSidesIsosceles2MarkedlinesYellow,

        width: 305.27727,
        height: 704.24762,
        aspectRatio: 0.43348001658848345
      };
    case 'Shapes_with_marked_sides/Isosceles3_markedlines':
      return {
        asset: ShapesWithMarkedSidesIsosceles3Markedlines,

        width: 534.18907,
        height: 332.48792,
        aspectRatio: 1.6066420398070405
      };
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_blue':
      return {
        asset: ShapesWithMarkedSidesIsosceles3MarkedlinesBlue,

        width: 534.18907,
        height: 332.48792,
        aspectRatio: 1.6066420398070405
      };
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_green':
      return {
        asset: ShapesWithMarkedSidesIsosceles3MarkedlinesGreen,

        width: 534.18907,
        height: 332.48792,
        aspectRatio: 1.6066420398070405
      };
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_pink':
      return {
        asset: ShapesWithMarkedSidesIsosceles3MarkedlinesPink,

        width: 534.18907,
        height: 332.48792,
        aspectRatio: 1.6066420398070405
      };
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_purple':
      return {
        asset: ShapesWithMarkedSidesIsosceles3MarkedlinesPurple,

        width: 534.18907,
        height: 332.48792,
        aspectRatio: 1.6066420398070405
      };
    case 'Shapes_with_marked_sides/Isosceles3_markedlines_yellow':
      return {
        asset: ShapesWithMarkedSidesIsosceles3MarkedlinesYellow,

        width: 534.18907,
        height: 332.48792,
        aspectRatio: 1.6066420398070405
      };
    case 'Shapes_with_marked_sides/Isosceles4_markedlines':
      return {
        asset: ShapesWithMarkedSidesIsosceles4Markedlines,

        width: 1058.8983,
        height: 530.44928,
        aspectRatio: 1.9962291210952345
      };
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_blue':
      return {
        asset: ShapesWithMarkedSidesIsosceles4MarkedlinesBlue,

        width: 1058.8983,
        height: 530.44928,
        aspectRatio: 1.9962291210952345
      };
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_green':
      return {
        asset: ShapesWithMarkedSidesIsosceles4MarkedlinesGreen,

        width: 1058.8983,
        height: 530.44928,
        aspectRatio: 1.9962291210952345
      };
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_pink':
      return {
        asset: ShapesWithMarkedSidesIsosceles4MarkedlinesPink,

        width: 1058.8983,
        height: 530.44928,
        aspectRatio: 1.9962291210952345
      };
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_purple':
      return {
        asset: ShapesWithMarkedSidesIsosceles4MarkedlinesPurple,

        width: 1058.8983,
        height: 530.44928,
        aspectRatio: 1.9962291210952345
      };
    case 'Shapes_with_marked_sides/Isosceles4_markedlines_yellow':
      return {
        asset: ShapesWithMarkedSidesIsosceles4MarkedlinesYellow,

        width: 1058.8983,
        height: 530.44928,
        aspectRatio: 1.9962291210952345
      };
    case 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesRegularDecagonAllSidesMarked,

        width: 492.74291,
        height: 516.17548,
        aspectRatio: 0.9546034809712387
      };
    case 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesRegularDecagonAllSidesMarkedBlue,

        width: 492.74291,
        height: 516.17548,
        aspectRatio: 0.9546034809712387
      };
    case 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesRegularDecagonAllSidesMarkedGreen,

        width: 492.74291,
        height: 516.17548,
        aspectRatio: 0.9546034809712387
      };
    case 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesRegularDecagonAllSidesMarkedPink,

        width: 492.74291,
        height: 516.17548,
        aspectRatio: 0.9546034809712387
      };
    case 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesRegularDecagonAllSidesMarkedPurple,

        width: 492.74291,
        height: 516.17548,
        aspectRatio: 0.9546034809712387
      };
    case 'Shapes_with_marked_sides/Regular_decagon_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesRegularDecagonAllSidesMarkedYellow,

        width: 492.74291,
        height: 516.17548,
        aspectRatio: 0.9546034809712387
      };
    case 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesRegularHeptagonAllSidesMarked,

        width: 481.55254,
        height: 492.26364,
        aspectRatio: 0.9782411311142135
      };
    case 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedBlue,

        width: 481.55254,
        height: 492.26364,
        aspectRatio: 0.9782411311142135
      };
    case 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedGreen,

        width: 481.55254,
        height: 492.26364,
        aspectRatio: 0.9782411311142135
      };
    case 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedPink,

        width: 481.55254,
        height: 492.26364,
        aspectRatio: 0.9782411311142135
      };
    case 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedPurple,

        width: 481.55254,
        height: 492.26364,
        aspectRatio: 0.9782411311142135
      };
    case 'Shapes_with_marked_sides/Regular_heptagon_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesRegularHeptagonAllSidesMarkedYellow,

        width: 481.55254,
        height: 492.26364,
        aspectRatio: 0.9782411311142135
      };
    case 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesRegularHexagonAllSidesMarked,

        width: 541.12407,
        height: 514.70184,
        aspectRatio: 1.0513350214563058
      };
    case 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesRegularHexagonAllSidesMarkedBlue,

        width: 541.12407,
        height: 514.70184,
        aspectRatio: 1.0513350214563058
      };
    case 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesRegularHexagonAllSidesMarkedGreen,

        width: 541.12407,
        height: 514.70184,
        aspectRatio: 1.0513350214563058
      };
    case 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesRegularHexagonAllSidesMarkedPink,

        width: 541.12407,
        height: 514.70184,
        aspectRatio: 1.0513350214563058
      };
    case 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesRegularHexagonAllSidesMarkedPurple,

        width: 541.12407,
        height: 514.70184,
        aspectRatio: 1.0513350214563058
      };
    case 'Shapes_with_marked_sides/Regular_hexagon_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesRegularHexagonAllSidesMarkedYellow,

        width: 541.12407,
        height: 514.70184,
        aspectRatio: 1.0513350214563058
      };
    case 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesRegularNonagonAllSidesMarked,

        width: 494.22804,
        height: 492.81778,
        aspectRatio: 1.0028616256499512
      };
    case 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesRegularNonagonAllSidesMarkedBlue,

        width: 494.22804,
        height: 492.81778,
        aspectRatio: 1.0028616256499512
      };
    case 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesRegularNonagonAllSidesMarkedGreen,

        width: 494.22804,
        height: 492.81778,
        aspectRatio: 1.0028616256499512
      };
    case 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesRegularNonagonAllSidesMarkedPink,

        width: 494.22804,
        height: 492.81778,
        aspectRatio: 1.0028616256499512
      };
    case 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesRegularNonagonAllSidesMarkedPurple,

        width: 494.22804,
        height: 492.81778,
        aspectRatio: 1.0028616256499512
      };
    case 'Shapes_with_marked_sides/Regular_nonagon_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesRegularNonagonAllSidesMarkedYellow,

        width: 494.22804,
        height: 492.81778,
        aspectRatio: 1.0028616256499512
      };
    case 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesRegularOctagonAllSidesMarked,

        width: 516.17546,
        height: 516.17548,
        aspectRatio: 0.999999961253487
      };
    case 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesRegularOctagonAllSidesMarkedBlue,

        width: 516.17546,
        height: 516.17548,
        aspectRatio: 0.999999961253487
      };
    case 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesRegularOctagonAllSidesMarkedGreen,

        width: 516.17546,
        height: 516.17548,
        aspectRatio: 0.999999961253487
      };
    case 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesRegularOctagonAllSidesMarkedPink,

        width: 516.17546,
        height: 516.17548,
        aspectRatio: 0.999999961253487
      };
    case 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesRegularOctagonAllSidesMarkedPurple,

        width: 516.17546,
        height: 516.17548,
        aspectRatio: 0.999999961253487
      };
    case 'Shapes_with_marked_sides/Regular_octagon_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesRegularOctagonAllSidesMarkedYellow,

        width: 516.17546,
        height: 516.17548,
        aspectRatio: 0.999999961253487
      };
    case 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked':
      return {
        asset: ShapesWithMarkedSidesRegularPentagonAllSidesMarked,

        width: 492.99111,
        height: 492.40738,
        aspectRatio: 1.0011854615176563
      };
    case 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesRegularPentagonAllSidesMarkedBlue,

        width: 492.99111,
        height: 492.40738,
        aspectRatio: 1.0011854615176563
      };
    case 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesRegularPentagonAllSidesMarkedGreen,

        width: 492.99111,
        height: 492.40738,
        aspectRatio: 1.0011854615176563
      };
    case 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesRegularPentagonAllSidesMarkedPink,

        width: 492.99111,
        height: 492.40738,
        aspectRatio: 1.0011854615176563
      };
    case 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesRegularPentagonAllSidesMarkedPurple,

        width: 492.99111,
        height: 492.40738,
        aspectRatio: 1.0011854615176563
      };
    case 'Shapes_with_marked_sides/Regular_pentagon_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesRegularPentagonAllSidesMarkedYellow,

        width: 492.99111,
        height: 492.40738,
        aspectRatio: 1.0011854615176563
      };
    case 'Shapes_with_marked_sides/Square_all__sides_marked_blue':
      return {
        asset: ShapesWithMarkedSidesSquareAllSidesMarkedBlue,

        width: 516.17558,
        height: 516.17548,
        aspectRatio: 1.0000001937325655
      };
    case 'Shapes_with_marked_sides/Square_all__sides_marked_green':
      return {
        asset: ShapesWithMarkedSidesSquareAllSidesMarkedGreen,

        width: 516.17558,
        height: 516.17548,
        aspectRatio: 1.0000001937325655
      };
    case 'Shapes_with_marked_sides/Square_all__sides_marked_pink':
      return {
        asset: ShapesWithMarkedSidesSquareAllSidesMarkedPink,

        width: 516.17558,
        height: 516.17548,
        aspectRatio: 1.0000001937325655
      };
    case 'Shapes_with_marked_sides/Square_all__sides_marked_purple':
      return {
        asset: ShapesWithMarkedSidesSquareAllSidesMarkedPurple,

        width: 516.17558,
        height: 516.17548,
        aspectRatio: 1.0000001937325655
      };
    case 'Shapes_with_marked_sides/Square_all__sides_marked_yellow':
      return {
        asset: ShapesWithMarkedSidesSquareAllSidesMarkedYellow,

        width: 516.17558,
        height: 516.17548,
        aspectRatio: 1.0000001937325655
      };
    case 'Shapes_with_missing_parts/Missing_part_shape1':
      return {
        asset: ShapesWithMissingPartsMissingPartShape1,

        width: 554.20262,
        height: 177.70132,
        aspectRatio: 3.118731025745898
      };
    case 'Shapes_with_missing_parts/Missing_part_shape10':
      return {
        asset: ShapesWithMissingPartsMissingPartShape10,

        width: 602.13463,
        height: 228.62399,
        aspectRatio: 2.6337333628023902
      };
    case 'Shapes_with_missing_parts/Missing_part_shape2':
      return {
        asset: ShapesWithMissingPartsMissingPartShape2,

        width: 377.76799,
        height: 419.51999,
        aspectRatio: 0.9004767329442395
      };
    case 'Shapes_with_missing_parts/Missing_part_shape3':
      return {
        asset: ShapesWithMissingPartsMissingPartShape3,

        width: 561.46666,
        height: 345.30933,
        aspectRatio: 1.6259817248494273
      };
    case 'Shapes_with_missing_parts/Missing_part_shape4':
      return {
        asset: ShapesWithMissingPartsMissingPartShape4,

        width: 454.80263,
        height: 468.32132,
        aspectRatio: 0.9711337292950917
      };
    case 'Shapes_with_missing_parts/Missing_part_shape5':
      return {
        asset: ShapesWithMissingPartsMissingPartShape5,

        width: 592.67595,
        height: 317.51599,
        aspectRatio: 1.8666018993248181
      };
    case 'Shapes_with_missing_parts/Missing_part_shape6':
      return {
        asset: ShapesWithMissingPartsMissingPartShape6,

        width: 512.80663,
        height: 229.02533,
        aspectRatio: 2.239082594051933
      };
    case 'Shapes_with_missing_parts/Missing_part_shape7':
      return {
        asset: ShapesWithMissingPartsMissingPartShape7,

        width: 602.13463,
        height: 228.62399,
        aspectRatio: 2.6337333628023902
      };
    case 'Shapes_with_missing_parts/Missing_part_shape8':
      return {
        asset: ShapesWithMissingPartsMissingPartShape8,

        width: 454.80263,
        height: 468.32132,
        aspectRatio: 0.9711337292950917
      };
    case 'Shapes_with_missing_parts/Missing_part_shape9':
      return {
        asset: ShapesWithMissingPartsMissingPartShape9,

        width: 512.80663,
        height: 229.02533,
        aspectRatio: 2.239082594051933
      };
    case 'Shed':
      return {
        asset: Shed,

        width: 442.69305,
        height: 394.23184,
        aspectRatio: 1.1229256622194699
      };
    case 'Sheep':
      return {
        asset: Sheep,

        width: 262.68674,
        height: 190.11697,
        aspectRatio: 1.381711164447866
      };
    case 'Shopping_trolley':
      return {
        asset: ShoppingTrolley,

        width: 595.28,
        height: 595.28,
        aspectRatio: 1
      };
    case 'Sink':
      return {
        asset: Sink,
        defaultWidth: NaN,
        defaultHeight: NaN,
        width: 669,
        height: 646,
        aspectRatio: 1.0356037151702786
      };
    case 'SliderArrow':
      return {
        asset: SliderArrow,

        width: 26,
        height: 111,
        aspectRatio: 0.23423423423423423
      };
    case 'Soap_dispenser':
      return {
        asset: SoapDispenser,

        width: 184.46399,
        height: 352.86825,
        aspectRatio: 0.5227559861222992
      };
    case 'Spider':
      return {
        asset: Spider,

        width: 239.09561,
        height: 242.21926,
        aspectRatio: 0.9871040395383918
      };
    case 'Spinner':
      return {
        asset: Spinner,

        width: 170,
        height: 170,
        aspectRatio: 1
      };
    case 'Sports_ball_football':
      return {
        asset: SportsBallFootball,

        width: 301.33331,
        height: 301.33331,
        aspectRatio: 1
      };
    case 'Sports_ball_rugby_ball':
      return {
        asset: SportsBallRugbyBall,

        width: 425.99466,
        height: 256.13598,
        aspectRatio: 1.6631582177560529
      };
    case 'Square':
      return {
        asset: Square,

        width: 100,
        height: 100,
        aspectRatio: 1
      };
    case 'Square/square':
      return {
        asset: SquareSquare,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_1_arrow':
      return {
        asset: SquareSquare1Arrow,
        defaultWidth: 377,
        defaultHeight: 328,
        width: 377,
        height: 328,
        aspectRatio: 1.149390243902439
      };
    case 'Square/square_1_arrow_blue':
      return {
        asset: SquareSquare1ArrowBlue,
        defaultWidth: 377,
        defaultHeight: 328,
        width: 377,
        height: 328,
        aspectRatio: 1.149390243902439
      };
    case 'Square/square_1_arrow_green':
      return {
        asset: SquareSquare1ArrowGreen,
        defaultWidth: 377,
        defaultHeight: 328,
        width: 377,
        height: 328,
        aspectRatio: 1.149390243902439
      };
    case 'Square/square_1_arrow_pink':
      return {
        asset: SquareSquare1ArrowPink,
        defaultWidth: 377,
        defaultHeight: 328,
        width: 377,
        height: 328,
        aspectRatio: 1.149390243902439
      };
    case 'Square/square_1_arrow_purple':
      return {
        asset: SquareSquare1ArrowPurple,
        defaultWidth: 377,
        defaultHeight: 328,
        width: 377,
        height: 328,
        aspectRatio: 1.149390243902439
      };
    case 'Square/square_1_arrow_yellow':
      return {
        asset: SquareSquare1ArrowYellow,
        defaultWidth: 377,
        defaultHeight: 328,
        width: 377,
        height: 328,
        aspectRatio: 1.149390243902439
      };
    case 'Square/square_blue':
      return {
        asset: SquareSquareBlue,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_green':
      return {
        asset: SquareSquareGreen,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_grey':
      return {
        asset: SquareSquareGrey,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_orange':
      return {
        asset: SquareSquareOrange,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_pink':
      return {
        asset: SquareSquarePink,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_purple':
      return {
        asset: SquareSquarePurple,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_red':
      return {
        asset: SquareSquareRed,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_turquoise':
      return {
        asset: SquareSquareTurquoise,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_white':
      return {
        asset: SquareSquareWhite,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Square/square_yellow':
      return {
        asset: SquareSquareYellow,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Stack_of_Cubes_in_3D_Shape/cube_cubes':
      return {
        asset: StackOfCubesIn3DShapeCubeCubes,

        width: 255.13641,
        height: 285.04559,
        aspectRatio: 0.8950722935232922
      };
    case 'Stack_of_Cubes_in_3D_Shape/cylinder_cubes':
      return {
        asset: StackOfCubesIn3DShapeCylinderCubes,

        width: 130.06282,
        height: 264.0452,
        aspectRatio: 0.49257786166913836
      };
    case 'Stack_of_Cubes_in_3D_Shape/hexagonal_prism_cubes':
      return {
        asset: StackOfCubesIn3DShapeHexagonalPrismCubes,

        width: 158.05989,
        height: 238.81774,
        aspectRatio: 0.661843169607082
      };
    case 'Stack_of_Cubes_in_3D_Shape/square_based_pyramid_cubes':
      return {
        asset: StackOfCubesIn3DShapeSquareBasedPyramidCubes,

        width: 255.13629,
        height: 238.83999,
        aspectRatio: 1.0682310361845184
      };
    case 'Stack_of_Cubes_in_3D_Shape/triangular_prism_cubes':
      return {
        asset: StackOfCubesIn3DShapeTriangularPrismCubes,

        width: 321.37305,
        height: 315.90973,
        aspectRatio: 1.0172939276039392
      };
    case 'Star':
      return {
        asset: Star,

        width: 47,
        height: 46,
        aspectRatio: 1.0217391304347827
      };
    case 'Star_blue':
      return {
        asset: StarBlue,

        width: 299.45816,
        height: 284.99686,
        aspectRatio: 1.0507419625605698
      };
    case 'Star_green':
      return {
        asset: StarGreen,

        width: 299.45816,
        height: 284.99686,
        aspectRatio: 1.0507419625605698
      };
    case 'Star_pink':
      return {
        asset: StarPink,

        width: 299.45816,
        height: 284.99686,
        aspectRatio: 1.0507419625605698
      };
    case 'Star_purple':
      return {
        asset: StarPurple,

        width: 299.45816,
        height: 284.99686,
        aspectRatio: 1.0507419625605698
      };
    case 'Star_red':
      return {
        asset: StarRed,

        width: 299.45816,
        height: 284.99686,
        aspectRatio: 1.0507419625605698
      };
    case 'Star_yellow':
      return {
        asset: StarYellow,

        width: 299.45816,
        height: 284.99686,
        aspectRatio: 1.0507419625605698
      };
    case 'Sticker_sheet':
      return {
        asset: StickerSheet,

        width: 1233.21,
        height: 959,
        aspectRatio: 1.2859332638164755
      };
    case 'Stopwatch':
      return {
        asset: Stopwatch,

        width: 401.12665,
        height: 507.40985,
        aspectRatio: 0.7905377674477545
      };
    case 'Suitcase':
      return {
        asset: Suitcase,

        width: 265.30399,
        height: 203.9616,
        aspectRatio: 1.3007546028271988
      };
    case 'Sunflower':
      return {
        asset: Sunflower,

        width: 212.07147,
        height: 433.31204,
        aspectRatio: 0.4894197493335288
      };
    case 'Sweets/SweetBlue':
      return {
        asset: SweetsSweetBlue,

        width: 70.184,
        height: 48.657,
        aspectRatio: 1.4424234950777894
      };
    case 'Sweets/SweetGreen':
      return {
        asset: SweetsSweetGreen,

        width: 70.184,
        height: 48.657,
        aspectRatio: 1.4424234950777894
      };
    case 'Sweets/SweetPink':
      return {
        asset: SweetsSweetPink,

        width: 70.184,
        height: 48.657,
        aspectRatio: 1.4424234950777894
      };
    case 'Sweets/SweetRed':
      return {
        asset: SweetsSweetRed,

        width: 70.184,
        height: 48.657,
        aspectRatio: 1.4424234950777894
      };
    case 'SymmetricalShapes/both11_green':
      return {
        asset: SymmetricalShapesBoth11Green,

        width: 395.42065,
        height: 352.22595,
        aspectRatio: 1.1226334970492662
      };
    case 'SymmetricalShapes/both11_pink':
      return {
        asset: SymmetricalShapesBoth11Pink,

        width: 395.42065,
        height: 352.22595,
        aspectRatio: 1.1226334970492662
      };
    case 'SymmetricalShapes/both11_purple':
      return {
        asset: SymmetricalShapesBoth11Purple,

        width: 395.42065,
        height: 352.22595,
        aspectRatio: 1.1226334970492662
      };
    case 'SymmetricalShapes/both11_white':
      return {
        asset: SymmetricalShapesBoth11White,

        width: 395.42065,
        height: 352.22595,
        aspectRatio: 1.1226334970492662
      };
    case 'SymmetricalShapes/both11_yellow':
      return {
        asset: SymmetricalShapesBoth11Yellow,

        width: 395.42065,
        height: 352.22595,
        aspectRatio: 1.1226334970492662
      };
    case 'SymmetricalShapes/both12_green':
      return {
        asset: SymmetricalShapesBoth12Green,

        width: 553.31982,
        height: 306.98931,
        aspectRatio: 1.8024074519076905
      };
    case 'SymmetricalShapes/both12_pink':
      return {
        asset: SymmetricalShapesBoth12Pink,

        width: 553.31982,
        height: 306.98931,
        aspectRatio: 1.8024074519076905
      };
    case 'SymmetricalShapes/both12_purple':
      return {
        asset: SymmetricalShapesBoth12Purple,

        width: 553.31982,
        height: 306.98931,
        aspectRatio: 1.8024074519076905
      };
    case 'SymmetricalShapes/both12_white':
      return {
        asset: SymmetricalShapesBoth12White,

        width: 553.31982,
        height: 306.98931,
        aspectRatio: 1.8024074519076905
      };
    case 'SymmetricalShapes/both12_yellow':
      return {
        asset: SymmetricalShapesBoth12Yellow,

        width: 553.31982,
        height: 306.98931,
        aspectRatio: 1.8024074519076905
      };
    case 'SymmetricalShapes/both1_green':
      return {
        asset: SymmetricalShapesBoth1Green,

        width: 457.82397,
        height: 104.36933,
        aspectRatio: 4.386575730628912
      };
    case 'SymmetricalShapes/both1_pink':
      return {
        asset: SymmetricalShapesBoth1Pink,

        width: 457.82397,
        height: 104.36933,
        aspectRatio: 4.386575730628912
      };
    case 'SymmetricalShapes/both1_purple':
      return {
        asset: SymmetricalShapesBoth1Purple,

        width: 457.82397,
        height: 104.36933,
        aspectRatio: 4.386575730628912
      };
    case 'SymmetricalShapes/both1_white':
      return {
        asset: SymmetricalShapesBoth1White,

        width: 457.82397,
        height: 104.36933,
        aspectRatio: 4.386575730628912
      };
    case 'SymmetricalShapes/both1_yellow':
      return {
        asset: SymmetricalShapesBoth1Yellow,

        width: 457.82397,
        height: 104.36933,
        aspectRatio: 4.386575730628912
      };
    case 'SymmetricalShapes/both3_green':
      return {
        asset: SymmetricalShapesBoth3Green,

        width: 498.15634,
        height: 426.42123,
        aspectRatio: 1.168225934717181
      };
    case 'SymmetricalShapes/both3_pink':
      return {
        asset: SymmetricalShapesBoth3Pink,

        width: 498.15634,
        height: 426.42123,
        aspectRatio: 1.168225934717181
      };
    case 'SymmetricalShapes/both3_purple':
      return {
        asset: SymmetricalShapesBoth3Purple,

        width: 498.15634,
        height: 426.42123,
        aspectRatio: 1.168225934717181
      };
    case 'SymmetricalShapes/both3_white':
      return {
        asset: SymmetricalShapesBoth3White,

        width: 498.15634,
        height: 426.42123,
        aspectRatio: 1.168225934717181
      };
    case 'SymmetricalShapes/both3_yellow':
      return {
        asset: SymmetricalShapesBoth3Yellow,

        width: 498.15634,
        height: 426.42123,
        aspectRatio: 1.168225934717181
      };
    case 'SymmetricalShapes/both4_green':
      return {
        asset: SymmetricalShapesBoth4Green,

        width: 170.90266,
        height: 338.74398,
        aspectRatio: 0.50451866332798
      };
    case 'SymmetricalShapes/both4_pink':
      return {
        asset: SymmetricalShapesBoth4Pink,

        width: 170.90266,
        height: 338.74398,
        aspectRatio: 0.50451866332798
      };
    case 'SymmetricalShapes/both4_purple':
      return {
        asset: SymmetricalShapesBoth4Purple,

        width: 170.90266,
        height: 338.74398,
        aspectRatio: 0.50451866332798
      };
    case 'SymmetricalShapes/both4_white':
      return {
        asset: SymmetricalShapesBoth4White,

        width: 170.90266,
        height: 338.74398,
        aspectRatio: 0.50451866332798
      };
    case 'SymmetricalShapes/both4_yellow':
      return {
        asset: SymmetricalShapesBoth4Yellow,

        width: 170.90266,
        height: 338.74398,
        aspectRatio: 0.50451866332798
      };
    case 'SymmetricalShapes/both6_green':
      return {
        asset: SymmetricalShapesBoth6Green,

        width: 339.56665,
        height: 339.56533,
        aspectRatio: 1.0000038873226544
      };
    case 'SymmetricalShapes/both6_pink':
      return {
        asset: SymmetricalShapesBoth6Pink,

        width: 339.56665,
        height: 339.56533,
        aspectRatio: 1.0000038873226544
      };
    case 'SymmetricalShapes/both6_purple':
      return {
        asset: SymmetricalShapesBoth6Purple,

        width: 339.56665,
        height: 339.56533,
        aspectRatio: 1.0000038873226544
      };
    case 'SymmetricalShapes/both6_white':
      return {
        asset: SymmetricalShapesBoth6White,

        width: 339.56665,
        height: 339.56533,
        aspectRatio: 1.0000038873226544
      };
    case 'SymmetricalShapes/both6_yellow':
      return {
        asset: SymmetricalShapesBoth6Yellow,

        width: 339.56665,
        height: 339.56533,
        aspectRatio: 1.0000038873226544
      };
    case 'SymmetricalShapes/both8_green':
      return {
        asset: SymmetricalShapesBoth8Green,

        width: 523.31097,
        height: 349.16732,
        aspectRatio: 1.4987398305202215
      };
    case 'SymmetricalShapes/both8_pink':
      return {
        asset: SymmetricalShapesBoth8Pink,

        width: 523.31097,
        height: 349.16732,
        aspectRatio: 1.4987398305202215
      };
    case 'SymmetricalShapes/both8_purple':
      return {
        asset: SymmetricalShapesBoth8Purple,

        width: 523.31097,
        height: 349.16732,
        aspectRatio: 1.4987398305202215
      };
    case 'SymmetricalShapes/both8_white':
      return {
        asset: SymmetricalShapesBoth8White,

        width: 523.31097,
        height: 349.16732,
        aspectRatio: 1.4987398305202215
      };
    case 'SymmetricalShapes/both8_yellow':
      return {
        asset: SymmetricalShapesBoth8Yellow,

        width: 523.31097,
        height: 349.16732,
        aspectRatio: 1.4987398305202215
      };
    case 'SymmetricalShapes/both9_green':
      return {
        asset: SymmetricalShapesBoth9Green,

        width: 99.263999,
        height: 345.51333,
        aspectRatio: 0.2872942673441861
      };
    case 'SymmetricalShapes/both9_pink':
      return {
        asset: SymmetricalShapesBoth9Pink,

        width: 99.263999,
        height: 345.51333,
        aspectRatio: 0.2872942673441861
      };
    case 'SymmetricalShapes/both9_purple':
      return {
        asset: SymmetricalShapesBoth9Purple,

        width: 99.263999,
        height: 345.51333,
        aspectRatio: 0.2872942673441861
      };
    case 'SymmetricalShapes/both9_white':
      return {
        asset: SymmetricalShapesBoth9White,

        width: 99.263999,
        height: 345.51333,
        aspectRatio: 0.2872942673441861
      };
    case 'SymmetricalShapes/both9_yellow':
      return {
        asset: SymmetricalShapesBoth9Yellow,

        width: 99.263999,
        height: 345.51333,
        aspectRatio: 0.2872942673441861
      };
    case 'SymmetricalShapes/horizontal1_green':
      return {
        asset: SymmetricalShapesHorizontal1Green,

        width: 350.08819,
        height: 293.92932,
        aspectRatio: 1.1910624976099695
      };
    case 'SymmetricalShapes/horizontal1_pink':
      return {
        asset: SymmetricalShapesHorizontal1Pink,

        width: 350.08819,
        height: 293.92932,
        aspectRatio: 1.1910624976099695
      };
    case 'SymmetricalShapes/horizontal1_purple':
      return {
        asset: SymmetricalShapesHorizontal1Purple,

        width: 350.08819,
        height: 293.92932,
        aspectRatio: 1.1910624976099695
      };
    case 'SymmetricalShapes/horizontal1_white':
      return {
        asset: SymmetricalShapesHorizontal1White,

        width: 350.08819,
        height: 293.92932,
        aspectRatio: 1.1910624976099695
      };
    case 'SymmetricalShapes/horizontal1_yellow':
      return {
        asset: SymmetricalShapesHorizontal1Yellow,

        width: 350.08819,
        height: 293.92932,
        aspectRatio: 1.1910624976099695
      };
    case 'SymmetricalShapes/horizontal2_green':
      return {
        asset: SymmetricalShapesHorizontal2Green,

        width: 374.29074,
        height: 433.95312,
        aspectRatio: 0.862514227343267
      };
    case 'SymmetricalShapes/horizontal2_pink':
      return {
        asset: SymmetricalShapesHorizontal2Pink,

        width: 374.29074,
        height: 433.95312,
        aspectRatio: 0.862514227343267
      };
    case 'SymmetricalShapes/horizontal2_purple':
      return {
        asset: SymmetricalShapesHorizontal2Purple,

        width: 374.29074,
        height: 433.95312,
        aspectRatio: 0.862514227343267
      };
    case 'SymmetricalShapes/horizontal2_white':
      return {
        asset: SymmetricalShapesHorizontal2White,

        width: 374.29074,
        height: 433.95312,
        aspectRatio: 0.862514227343267
      };
    case 'SymmetricalShapes/horizontal2_yellow':
      return {
        asset: SymmetricalShapesHorizontal2Yellow,

        width: 374.29074,
        height: 433.95312,
        aspectRatio: 0.862514227343267
      };
    case 'SymmetricalShapes/horizontal3_green':
      return {
        asset: SymmetricalShapesHorizontal3Green,

        width: 333.38666,
        height: 320.62133,
        aspectRatio: 1.039814350467575
      };
    case 'SymmetricalShapes/horizontal3_pink':
      return {
        asset: SymmetricalShapesHorizontal3Pink,

        width: 333.38666,
        height: 320.62133,
        aspectRatio: 1.039814350467575
      };
    case 'SymmetricalShapes/horizontal3_purple':
      return {
        asset: SymmetricalShapesHorizontal3Purple,

        width: 333.38666,
        height: 320.62133,
        aspectRatio: 1.039814350467575
      };
    case 'SymmetricalShapes/horizontal3_white':
      return {
        asset: SymmetricalShapesHorizontal3White,

        width: 333.38666,
        height: 320.62133,
        aspectRatio: 1.039814350467575
      };
    case 'SymmetricalShapes/horizontal3_yellow':
      return {
        asset: SymmetricalShapesHorizontal3Yellow,

        width: 333.38666,
        height: 320.62133,
        aspectRatio: 1.039814350467575
      };
    case 'SymmetricalShapes/horizontal4_green':
      return {
        asset: SymmetricalShapesHorizontal4Green,

        width: 407.03528,
        height: 539.14886,
        aspectRatio: 0.7549589922159902
      };
    case 'SymmetricalShapes/horizontal4_pink':
      return {
        asset: SymmetricalShapesHorizontal4Pink,

        width: 407.03528,
        height: 539.14886,
        aspectRatio: 0.7549589922159902
      };
    case 'SymmetricalShapes/horizontal4_purple':
      return {
        asset: SymmetricalShapesHorizontal4Purple,

        width: 407.03528,
        height: 539.14886,
        aspectRatio: 0.7549589922159902
      };
    case 'SymmetricalShapes/horizontal4_white':
      return {
        asset: SymmetricalShapesHorizontal4White,

        width: 407.03528,
        height: 539.14886,
        aspectRatio: 0.7549589922159902
      };
    case 'SymmetricalShapes/horizontal4_yellow':
      return {
        asset: SymmetricalShapesHorizontal4Yellow,

        width: 407.03528,
        height: 539.14886,
        aspectRatio: 0.7549589922159902
      };
    case 'SymmetricalShapes/vertical1_green':
      return {
        asset: SymmetricalShapesVertical1Green,

        width: 348.86132,
        height: 336.127,
        aspectRatio: 1.0378854421096786
      };
    case 'SymmetricalShapes/vertical1_pink':
      return {
        asset: SymmetricalShapesVertical1Pink,

        width: 348.86132,
        height: 336.127,
        aspectRatio: 1.0378854421096786
      };
    case 'SymmetricalShapes/vertical1_purple':
      return {
        asset: SymmetricalShapesVertical1Purple,

        width: 348.86132,
        height: 336.127,
        aspectRatio: 1.0378854421096786
      };
    case 'SymmetricalShapes/vertical1_white':
      return {
        asset: SymmetricalShapesVertical1White,

        width: 348.86132,
        height: 336.127,
        aspectRatio: 1.0378854421096786
      };
    case 'SymmetricalShapes/vertical1_yellow':
      return {
        asset: SymmetricalShapesVertical1Yellow,

        width: 348.86132,
        height: 336.127,
        aspectRatio: 1.0378854421096786
      };
    case 'SymmetricalShapes/vertical2_green':
      return {
        asset: SymmetricalShapesVertical2Green,

        width: 346.45199,
        height: 346.45333,
        aspectRatio: 0.9999961322351846
      };
    case 'SymmetricalShapes/vertical2_pink':
      return {
        asset: SymmetricalShapesVertical2Pink,

        width: 346.45199,
        height: 346.45333,
        aspectRatio: 0.9999961322351846
      };
    case 'SymmetricalShapes/vertical2_purple':
      return {
        asset: SymmetricalShapesVertical2Purple,

        width: 346.45199,
        height: 346.45333,
        aspectRatio: 0.9999961322351846
      };
    case 'SymmetricalShapes/vertical2_white':
      return {
        asset: SymmetricalShapesVertical2White,

        width: 346.45199,
        height: 346.45333,
        aspectRatio: 0.9999961322351846
      };
    case 'SymmetricalShapes/vertical2_yellow':
      return {
        asset: SymmetricalShapesVertical2Yellow,

        width: 346.45199,
        height: 346.45333,
        aspectRatio: 0.9999961322351846
      };
    case 'SymmetricalShapes/vertical3_green':
      return {
        asset: SymmetricalShapesVertical3Green,

        width: 367.76672,
        height: 351.45999,
        aspectRatio: 1.0463971162122891
      };
    case 'SymmetricalShapes/vertical3_pink':
      return {
        asset: SymmetricalShapesVertical3Pink,

        width: 367.76672,
        height: 351.45999,
        aspectRatio: 1.0463971162122891
      };
    case 'SymmetricalShapes/vertical3_purple':
      return {
        asset: SymmetricalShapesVertical3Purple,

        width: 367.76672,
        height: 351.45999,
        aspectRatio: 1.0463971162122891
      };
    case 'SymmetricalShapes/vertical3_white':
      return {
        asset: SymmetricalShapesVertical3White,

        width: 367.76672,
        height: 351.45999,
        aspectRatio: 1.0463971162122891
      };
    case 'SymmetricalShapes/vertical3_yellow':
      return {
        asset: SymmetricalShapesVertical3Yellow,

        width: 367.76672,
        height: 351.45999,
        aspectRatio: 1.0463971162122891
      };
    case 'Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape10IncorrectLineOfSymmetryA,

        width: 293.92798,
        height: 468.88277,
        aspectRatio: 0.626868801342391
      };
    case 'Symmetrical_shapes_with_lines/shape_10_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape10IncorrectLineOfSymmetryB,

        width: 412.84958,
        height: 350.08657,
        aspectRatio: 1.1792785424473724
      };
    case 'Symmetrical_shapes_with_lines/shape_10_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape10LineOfSymmetryB,

        width: 293.92798,
        height: 467.60812,
        aspectRatio: 0.6285775790206551
      };
    case 'Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape11IncorrectLineOfSymmetryA,

        width: 551.72223,
        height: 374.28921,
        aspectRatio: 1.4740532595102058
      };
    case 'Symmetrical_shapes_with_lines/shape_11_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape11IncorrectLineOfSymmetryB,

        width: 433.94958,
        height: 492.45638,
        aspectRatio: 0.8811939445276351
      };
    case 'Symmetrical_shapes_with_lines/shape_11_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape11LineOfSymmetryC,

        width: 433.94958,
        height: 492.62426,
        aspectRatio: 0.8808936449861402
      };
    case 'Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape12IncorrectLineOfSymmetryA,

        width: 584.67266,
        height: 320.33904,
        aspectRatio: 1.8251682966896572
      };
    case 'Symmetrical_shapes_with_lines/shape_12_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape12IncorrectLineOfSymmetryB,

        width: 466.96789,
        height: 438.97292,
        aspectRatio: 1.0637737972538261
      };
    case 'Symmetrical_shapes_with_lines/shape_12_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape12LineOfSymmetryA,

        width: 466.96789,
        height: 439.16186,
        aspectRatio: 1.063316131323426
      };
    case 'Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape13IncorrectLineOfSymmetryA,

        width: 470.20892,
        height: 367.76418,
        aspectRatio: 1.2785609517490255
      };
    case 'Symmetrical_shapes_with_lines/shape_13_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape13IncorrectLineOfSymmetryB,

        width: 351.45865,
        height: 486.23037,
        aspectRatio: 0.7228233193249529
      };
    case 'Symmetrical_shapes_with_lines/shape_13_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape13LineOfSymmetryC,

        width: 351.45865,
        height: 480.52505,
        aspectRatio: 0.7314054699125466
      };
    case 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape14IncorrectLineOfSymmetryA,

        width: 320.61999,
        height: 439.73013,
        aspectRatio: 0.7291290000983103
      };
    case 'Symmetrical_shapes_with_lines/shape_14_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape14IncorrectLineOfSymmetryB,

        width: 440.09958,
        height: 320.61999,
        aspectRatio: 1.3726517176923374
      };
    case 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape14LineOfSymmetryB,

        width: 320.61999,
        height: 439.84078,
        aspectRatio: 0.7289455743507911
      };
    case 'Symmetrical_shapes_with_lines/shape_14_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape14LineOfSymmetryC,

        width: 439.84344,
        height: 320.61999,
        aspectRatio: 1.3718528280161197
      };
    case 'Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape15IncorrectLineOfSymmetryA,

        width: 348.85998,
        height: 454.19717,
        aspectRatio: 0.7680804792332809
      };
    case 'Symmetrical_shapes_with_lines/shape_15_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape15IncorrectLineOfSymmetryB,

        width: 467.33105,
        height: 336.12582,
        aspectRatio: 1.3903455854715356
      };
    case 'Symmetrical_shapes_with_lines/shape_15_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape15LineOfSymmetryB,

        width: 348.85998,
        height: 453.93398,
        aspectRatio: 0.7685258107357373
      };
    case 'Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape16IncorrectLineOfSymmetryA,

        width: 439.58224,
        height: 333.38531,
        aspectRatio: 1.3185411198831767
      };
    case 'Symmetrical_shapes_with_lines/shape_16_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape16IncorrectLineOfSymmetryB,

        width: 320.61999,
        height: 452.08959,
        aspectRatio: 0.7091956928271672
      };
    case 'Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape16LineOfSymmetryA,

        width: 170.90134,
        height: 457.76131,
        aspectRatio: 0.3733416002326628
      };
    case 'Symmetrical_shapes_with_lines/shape_16_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape16LineOfSymmetryC,

        width: 290.06625,
        height: 338.74264,
        aspectRatio: 0.8563027376771936
      };
    case 'Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape17IncorrectLineOfSymmetryA,

        width: 539.14697,
        height: 526.13079,
        aspectRatio: 1.024739437887678
      };
    case 'Symmetrical_shapes_with_lines/shape_17_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape17IncorrectLineOfSymmetryB,

        width: 657.37811,
        height: 407.03338,
        aspectRatio: 1.6150471737723329
      };
    case 'Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape17LineOfSymmetryB,

        width: 369.50992,
        height: 436.39639,
        aspectRatio: 0.8467300107592549
      };
    case 'Symmetrical_shapes_with_lines/shape_17_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape17LineOfSymmetryC,

        width: 487.71838,
        height: 318.14666,
        aspectRatio: 1.532998586249499
      };
    case 'Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape18IncorrectLineOfSymmetryA,

        width: 346.45199,
        height: 465.16958,
        aspectRatio: 0.7447864282096865
      };
    case 'Symmetrical_shapes_with_lines/shape_18_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape18IncorrectLineOfSymmetryB,

        width: 465.67651,
        height: 346.45065,
        aspectRatio: 1.344135189239795
      };
    case 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape18LineOfSymmetryA,

        width: 339.56534,
        height: 452.07998,
        aspectRatio: 0.751117844236323
      };
    case 'Symmetrical_shapes_with_lines/shape_18_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape18LineOfSymmetryD,

        width: 458.84933,
        height: 339.56399,
        aspectRatio: 1.3512897230357084
      };
    case 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape19IncorrectLineOfSymmetryA,

        width: 395.41876,
        height: 470.89117,
        aspectRatio: 0.8397243040254928
      };
    case 'Symmetrical_shapes_with_lines/shape_19_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape19IncorrectLineOfSymmetryB,

        width: 514.16388,
        height: 352.22408,
        aspectRatio: 1.4597635686918393
      };
    case 'Symmetrical_shapes_with_lines/shape_19_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape19LineOfSymmetryD,

        width: 320.61999,
        height: 451.03652,
        aspectRatio: 0.7108515071019083
      };
    case 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape1IncorrectLineOfSymmetryA,

        width: 457.82266,
        height: 222.21572,
        aspectRatio: 2.060262253273531
      };
    case 'Symmetrical_shapes_with_lines/shape_1_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape1IncorrectLineOfSymmetryB,

        width: 576.84668,
        height: 104.36799,
        aspectRatio: 5.527045984118311
      };
    case 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape1LineOfSymmetryB,

        width: 457.82266,
        height: 216.33147,
        aspectRatio: 2.1163017105185853
      };
    case 'Symmetrical_shapes_with_lines/shape_1_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape1LineOfSymmetryD,

        width: 568.42987,
        height: 104.36799,
        aspectRatio: 5.4464004720221215
      };
    case 'Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape20IncorrectLineOfSymmetryA,

        width: 671.88067,
        height: 306.988,
        aspectRatio: 2.1886219331048773
      };
    case 'Symmetrical_shapes_with_lines/shape_20_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape20IncorrectLineOfSymmetryB,

        width: 553.31817,
        height: 425.97225,
        aspectRatio: 1.298953558594486
      };
    case 'Symmetrical_shapes_with_lines/shape_20_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape20LineOfSymmetryD,

        width: 539.14697,
        height: 526.10491,
        aspectRatio: 1.0247898465726162
      };
    case 'Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape21IncorrectLineOfSymmetryA,

        width: 385.60931,
        height: 506.86639,
        aspectRatio: 0.7607711176114873
      };
    case 'Symmetrical_shapes_with_lines/shape_21_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape21IncorrectLineOfSymmetryB,

        width: 504.20105,
        height: 389.16644,
        aspectRatio: 1.295592317775397
      };
    case 'Symmetrical_shapes_with_lines/shape_21_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape21LineOfSymmetryD,

        width: 346.45199,
        height: 465.43359,
        aspectRatio: 0.7443639596360031
      };
    case 'Symmetrical_shapes_with_lines/shape_22_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape22LineOfSymmetryD,

        width: 469.0596,
        height: 293.92797,
        aspectRatio: 1.5958317951163339
      };
    case 'Symmetrical_shapes_with_lines/shape_23_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape23LineOfSymmetryC,

        width: 492.54879,
        height: 433.94958,
        aspectRatio: 1.1350369091266317
      };
    case 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape24LineOfSymmetryB,

        width: 395.41876,
        height: 466.01159,
        aspectRatio: 0.8485170079139019
      };
    case 'Symmetrical_shapes_with_lines/shape_24_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape24LineOfSymmetryD,

        width: 509.92611,
        height: 352.22408,
        aspectRatio: 1.4477321084918442
      };
    case 'Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape25LineOfSymmetryA,

        width: 671.58502,
        height: 306.988,
        aspectRatio: 2.187658866144605
      };
    case 'Symmetrical_shapes_with_lines/shape_25_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape25LineOfSymmetryC,

        width: 553.31817,
        height: 425.50466,
        aspectRatio: 1.300380987601875
      };
    case 'Symmetrical_shapes_with_lines/shape_26_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape26LineOfSymmetryA,

        width: 385.60931,
        height: 503.97878,
        aspectRatio: 0.7651300517057484
      };
    case 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape2IncorrectLineOfSymmetryA,

        width: 414.08746,
        height: 293.92797,
        aspectRatio: 1.4088059057462274
      };
    case 'Symmetrical_shapes_with_lines/shape_2_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape2IncorrectLineOfSymmetryB,

        width: 295.74798,
        height: 413.0577,
        aspectRatio: 0.7159967723637641
      };
    case 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape2LineOfSymmetryA,

        width: 295.74798,
        height: 412.58984,
        aspectRatio: 0.7168086834130477
      };
    case 'Symmetrical_shapes_with_lines/shape_2_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape2LineOfSymmetryC,

        width: 413.31158,
        height: 293.92797,
        aspectRatio: 1.4061662114020654
      };
    case 'Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape3IncorrectLineOfSymmetryA,

        width: 613.94464,
        height: 426.41576,
        aspectRatio: 1.439779430291226
      };
    case 'Symmetrical_shapes_with_lines/shape_3_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape3IncorrectLineOfSymmetryB,

        width: 498.15207,
        height: 542.66997,
        aspectRatio: 0.917965057104597
      };
    case 'Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape3LineOfSymmetryB,

        width: 498.14901,
        height: 537.3341,
        aspectRatio: 0.9270749985902624
      };
    case 'Symmetrical_shapes_with_lines/shape_3_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape3LineOfSymmetryD,

        width: 612.61596,
        height: 426.41576,
        aspectRatio: 1.436663504181928
      };
    case 'Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape4IncorrectLineOfSymmetryA,

        width: 368.2767,
        height: 469.16851,
        aspectRatio: 0.7849561344174611
      };
    case 'Symmetrical_shapes_with_lines/shape_4_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape4IncorrectLineOfSymmetryB,

        width: 487.57904,
        height: 350.25198,
        aspectRatio: 1.3920807528340027
      };
    case 'Symmetrical_shapes_with_lines/shape_4_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape4LineOfSymmetryA,

        width: 368.2767,
        height: 468.73504,
        aspectRatio: 0.785682034780246
      };
    case 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape5IncorrectLineOfSymmetryA,

        width: 369.50992,
        height: 436.68185,
        aspectRatio: 0.846176501267456
      };
    case 'Symmetrical_shapes_with_lines/shape_5_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape5IncorrectLineOfSymmetryB,

        width: 488.05932,
        height: 318.14666,
        aspectRatio: 1.534070230377399
      };
    case 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape5LineOfSymmetryB,

        width: 369.50992,
        height: 437.01424,
        aspectRatio: 0.8455329052893106
      };
    case 'Symmetrical_shapes_with_lines/shape_5_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape5LineOfSymmetryC,

        width: 467.33093,
        height: 318.14666,
        aspectRatio: 1.4689166625228756
      };
    case 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape6IncorrectLineOfSymmetryA,

        width: 458.46319,
        height: 339.56399,
        aspectRatio: 1.3501525588741021
      };
    case 'Symmetrical_shapes_with_lines/shape_6_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape6IncorrectLineOfSymmetryB,

        width: 339.56534,
        height: 458.42025,
        aspectRatio: 0.740729363504339
      };
    case 'Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape6LineOfSymmetryA,

        width: 339.56534,
        height: 458.09371,
        aspectRatio: 0.7412573728637313
      };
    case 'Symmetrical_shapes_with_lines/shape_6_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape6LineOfSymmetryD,

        width: 457.65051,
        height: 339.56399,
        aspectRatio: 1.3477592544486239
      };
    case 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape7IncorrectLineOfSymmetryA,

        width: 399.95865,
        height: 412.46172,
        aspectRatio: 0.9696867141998049
      };
    case 'Symmetrical_shapes_with_lines/shape_7_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape7IncorrectLineOfSymmetryB,

        width: 518.46826,
        height: 293.92797,
        aspectRatio: 1.7639296457564075
      };
    case 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape7LineOfSymmetryA,

        width: 518.61615,
        height: 293.92797,
        aspectRatio: 1.76443279623916
      };
    case 'Symmetrical_shapes_with_lines/shape_7_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape7LineOfSymmetryC,

        width: 399.95865,
        height: 410.46185,
        aspectRatio: 0.9744112637995467
      };
    case 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape8IncorrectLineOfSymmetryA,

        width: 523.30853,
        height: 468.16225,
        aspectRatio: 1.117793094167674
      };
    case 'Symmetrical_shapes_with_lines/shape_8_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape8IncorrectLineOfSymmetryB,

        width: 641.28064,
        height: 349.16573,
        aspectRatio: 1.8366081917603998
      };
    case 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape8LineOfSymmetryB,

        width: 523.30853,
        height: 467.77758,
        aspectRatio: 1.1187122948474786
      };
    case 'Symmetrical_shapes_with_lines/shape_8_line_of_symmetry_c':
      return {
        asset: SymmetricalShapesWithLinesShape8LineOfSymmetryC,

        width: 637.85077,
        height: 349.16573,
        aspectRatio: 1.826785148702881
      };
    case 'Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_a':
      return {
        asset: SymmetricalShapesWithLinesShape9IncorrectLineOfSymmetryA,

        width: 552.35064,
        height: 312.02798,
        aspectRatio: 1.7701958651272234
      };
    case 'Symmetrical_shapes_with_lines/shape_9_incorrect_line_of_symmetry_b':
      return {
        asset: SymmetricalShapesWithLinesShape9IncorrectLineOfSymmetryB,

        width: 433.43567,
        height: 431.28451,
        aspectRatio: 1.0049877979619533
      };
    case 'Symmetrical_shapes_with_lines/shape_9_line_of_symmetry_d':
      return {
        asset: SymmetricalShapesWithLinesShape9LineOfSymmetryD,

        width: 433.43567,
        height: 431.13799,
        aspectRatio: 1.0053293378298676
      };
    case 'Teddy_bear_1':
      return {
        asset: TeddyBear1,

        width: 421.72018,
        height: 511.74704,
        aspectRatio: 0.8240793732778601
      };
    case 'Teddy_bear_2':
      return {
        asset: TeddyBear2,

        width: 299.05954,
        height: 325.76681,
        aspectRatio: 0.9180172160570931
      };
    case 'Television':
      return {
        asset: Television,

        width: 581.74798,
        height: 333.12533,
        aspectRatio: 1.7463336696732126
      };
    case 'Thermometers/Horizontal_Thermometer_filled':
      return {
        asset: ThermometersHorizontalThermometerFilled,
        defaultWidth: 765.72534,
        defaultHeight: 141.18423,
        width: 765.72532,
        height: 141.18424,
        aspectRatio: 5.423589205140744
      };
    case 'Thermometers/Vertical_Thermometer_blank':
      return {
        asset: ThermometersVerticalThermometerBlank,
        defaultWidth: 113.18533,
        defaultHeight: 390.62534,
        width: 113.18533,
        height: 390.62534,
        aspectRatio: 0.28975419259795077
      };
    case 'Tiny':
      return {
        asset: Tiny,

        width: 247,
        height: 173,
        aspectRatio: 1.4277456647398843
      };
    case 'Tomato_sauce_ketchup':
      return {
        asset: TomatoSauceKetchup,

        width: 238.43178,
        height: 565.91571,
        aspectRatio: 0.4213203058102063
      };
    case 'Toolbox':
      return {
        asset: Toolbox,

        width: 662.27356,
        height: 482.15731,
        aspectRatio: 1.3735632464018848
      };
    case 'Toy_car':
      return {
        asset: ToyCar,

        width: 674.20245,
        height: 219.63171,
        aspectRatio: 3.069695400541206
      };
    case 'Transparent_barrel':
      return {
        asset: TransparentBarrel,

        width: 187.18799,
        height: 236.71199,
        aspectRatio: 0.7907837283612039
      };
    case 'Transport_toys/Train_carriage':
      return {
        asset: TransportToysTrainCarriage,

        width: 418.0264,
        height: 200.06868,
        aspectRatio: 2.0894144950623956
      };
    case 'Transport_toys/Transport_toys_Car':
      return {
        asset: TransportToysTransportToysCar,

        width: 332.58392,
        height: 192.68742,
        aspectRatio: 1.7260281963399582
      };
    case 'Transport_toys/Transport_toys_Sailboat':
      return {
        asset: TransportToysTransportToysSailboat,

        width: 372.99411,
        height: 289.81317,
        aspectRatio: 1.2870157349992064
      };
    case 'Transport_toys/Transport_toys_Train':
      return {
        asset: TransportToysTransportToysTrain,

        width: 345.60868,
        height: 250.81676,
        aspectRatio: 1.3779329579091923
      };
    case 'Transport_toys/Transport_toys_plane':
      return {
        asset: TransportToysTransportToysPlane,

        width: 407.84998,
        height: 202.03567,
        aspectRatio: 2.018702835989308
      };
    case 'Trapezium/trapezium_isosceles':
      return {
        asset: TrapeziumTrapeziumIsosceles,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_blue':
      return {
        asset: TrapeziumTrapeziumIsoscelesBlue,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_blue_one_interior_angle_lhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesBlueOneInteriorAngleLhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_blue_one_interior_angle_rhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesBlueOneInteriorAngleRhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_green':
      return {
        asset: TrapeziumTrapeziumIsoscelesGreen,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_green_one_interior_angle_lhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesGreenOneInteriorAngleLhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_green_one_interior_angle_rhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesGreenOneInteriorAngleRhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_orange':
      return {
        asset: TrapeziumTrapeziumIsoscelesOrange,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_pink':
      return {
        asset: TrapeziumTrapeziumIsoscelesPink,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_pink_one_interior_angle_lhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesPinkOneInteriorAngleLhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_pink_one_interior_angle_rhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesPinkOneInteriorAngleRhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_purple':
      return {
        asset: TrapeziumTrapeziumIsoscelesPurple,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_purple_one_interior_angle_lhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesPurpleOneInteriorAngleLhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_purple_one_interior_angle_rhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesPurpleOneInteriorAngleRhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_red':
      return {
        asset: TrapeziumTrapeziumIsoscelesRed,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_turquoise':
      return {
        asset: TrapeziumTrapeziumIsoscelesTurquoise,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_white':
      return {
        asset: TrapeziumTrapeziumIsoscelesWhite,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_yellow':
      return {
        asset: TrapeziumTrapeziumIsoscelesYellow,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Trapezium/trapezium_isosceles_yellow_one_interior_angle_lhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesYellowOneInteriorAngleLhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Trapezium/trapezium_isosceles_yellow_one_interior_angle_rhs':
      return {
        asset: TrapeziumTrapeziumIsoscelesYellowOneInteriorAngleRhs,

        width: 355,
        height: 267,
        aspectRatio: 1.3295880149812733
      };
    case 'Tree_A':
      return {
        asset: TreeA,

        width: 260.82464,
        height: 255.94374,
        aspectRatio: 1.0190702066008726
      };
    case 'Tree_B':
      return {
        asset: TreeB,

        width: 252.39358,
        height: 360.27987,
        aspectRatio: 0.7005486595740139
      };
    case 'Triangle':
      return {
        asset: Triangle,

        width: 88,
        height: 75,
        aspectRatio: 1.1733333333333333
      };
    case 'TriangleMan':
      return {
        asset: TriangleMan,

        width: 168,
        height: 158,
        aspectRatio: 1.0632911392405062
      };
    case 'TriangleWhite':
      return {
        asset: TriangleWhite,

        width: 88,
        height: 75,
        aspectRatio: 1.1733333333333333
      };
    case 'True':
      return {
        asset: True,

        width: 18,
        height: 18,
        aspectRatio: 1
      };
    case 'Tube_of_crisps':
      return {
        asset: TubeOfCrisps,

        width: 106.54504,
        height: 297.83585,
        aspectRatio: 0.3577307432936633
      };
    case 'Turns_and_angles/Fullturn_antclockwise':
      return {
        asset: TurnsAndAnglesFullturnAntclockwise,

        width: 248.97999,
        height: 442.99026,
        aspectRatio: 0.5620439374897317
      };
    case 'Turns_and_angles/Fullturn_clockwise':
      return {
        asset: TurnsAndAnglesFullturnClockwise,

        width: 248.97865,
        height: 442.99026,
        aspectRatio: 0.5620409125925252
      };
    case 'Turns_and_angles/Halfturn_anticlockwise':
      return {
        asset: TurnsAndAnglesHalfturnAnticlockwise,

        width: 134.4628,
        height: 636.76038,
        aspectRatio: 0.21116703272273313
      };
    case 'Turns_and_angles/Halfturn_clockwise':
      return {
        asset: TurnsAndAnglesHalfturnClockwise,

        width: 134.46173,
        height: 636.76038,
        aspectRatio: 0.2111653523418024
      };
    case 'Turns_and_angles/Quarterturn_anticlockwise':
      return {
        asset: TurnsAndAnglesQuarterturnAnticlockwise,

        width: 328.186,
        height: 328.47253,
        aspectRatio: 0.9991276896122789
      };
    case 'Turns_and_angles/Quarterturn_clockwise':
      return {
        asset: TurnsAndAnglesQuarterturnClockwise,

        width: 328.186,
        height: 328.47253,
        aspectRatio: 0.9991276896122789
      };
    case 'Turns_and_angles/Threequarterturn_anticlockwise':
      return {
        asset: TurnsAndAnglesThreequarterturnAnticlockwise,

        width: 442.70452,
        height: 442.98959,
        aspectRatio: 0.9993564860068156
      };
    case 'Turns_and_angles/Threequarterturn_clockwise':
      return {
        asset: TurnsAndAnglesThreequarterturnClockwise,

        width: 442.70436,
        height: 442.99066,
        aspectRatio: 0.9993537109789178
      };
    case 'Unbroken_shapes/arrow_transparent':
      return {
        asset: UnbrokenShapesArrowTransparent,

        width: 431.08823,
        height: 253.75083,
        aspectRatio: 1.698864315044802
      };
    case 'Unbroken_shapes/circle_semi_transparent':
      return {
        asset: UnbrokenShapesCircleSemiTransparent,

        width: 164.66666,
        height: 329.35217,
        aspectRatio: 0.49997138321572315
      };
    case 'Unbroken_shapes/circle_transparent':
      return {
        asset: UnbrokenShapesCircleTransparent,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unbroken_shapes/cross_transparent':
      return {
        asset: UnbrokenShapesCrossTransparent,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unbroken_shapes/hexagon_transparent':
      return {
        asset: UnbrokenShapesHexagonTransparent,

        width: 376.6188,
        height: 326.16132,
        aspectRatio: 1.1547009927480059
      };
    case 'Unbroken_shapes/kite_transparent':
      return {
        asset: UnbrokenShapesKiteTransparent,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Unbroken_shapes/parallelogram_transparent':
      return {
        asset: UnbrokenShapesParallelogramTransparent,

        width: 583.19189,
        height: 328,
        aspectRatio: 1.7780240548780486
      };
    case 'Unbroken_shapes/pentagon_house_transparent':
      return {
        asset: UnbrokenShapesPentagonHouseTransparent,

        width: 328,
        height: 328.47925,
        aspectRatio: 0.9985410037315904
      };
    case 'Unbroken_shapes/pentagon_transparent':
      return {
        asset: UnbrokenShapesPentagonTransparent,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Unbroken_shapes/rectangle_transparent':
      return {
        asset: UnbrokenShapesRectangleTransparent,

        width: 577.33331,
        height: 328,
        aspectRatio: 1.7601625304878048
      };
    case 'Unbroken_shapes/rhombus_transparent':
      return {
        asset: UnbrokenShapesRhombusTransparent,

        width: 371.22794,
        height: 328,
        aspectRatio: 1.1317925
      };
    case 'Unbroken_shapes/square_transparent':
      return {
        asset: UnbrokenShapesSquareTransparent,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unbroken_shapes/trapezium_RA_transparent':
      return {
        asset: UnbrokenShapesTrapeziumRATransparent,

        width: 482.49249,
        height: 328,
        aspectRatio: 1.4710136890243901
      };
    case 'Unbroken_shapes/trapezium_isosceles_transparent':
      return {
        asset: UnbrokenShapesTrapeziumIsoscelesTransparent,

        width: 435.93967,
        height: 328,
        aspectRatio: 1.3290843597560975
      };
    case 'Unbroken_shapes/triangle_RA_transparent':
      return {
        asset: UnbrokenShapesTriangleRATransparent,

        width: 330.82843,
        height: 330.82843,
        aspectRatio: 1
      };
    case 'Unbroken_shapes/triangle_equal_transparent':
      return {
        asset: UnbrokenShapesTriangleEqualTransparent,

        width: 379.87177,
        height: 329.52768,
        aspectRatio: 1.1527765133417625
      };
    case 'Unbroken_shapes/triangle_isos_narrow_transparent':
      return {
        asset: UnbrokenShapesTriangleIsosNarrowTransparent,

        width: 272.25427,
        height: 330.77796,
        aspectRatio: 0.8230725831914557
      };
    case 'Unbroken_shapes/triangle_isos_wide_transparent':
      return {
        asset: UnbrokenShapesTriangleIsosWideTransparent,

        width: 441.78482,
        height: 329.11145,
        aspectRatio: 1.342356274751304
      };
    case 'Unbroken_shapes/triangle_scalene_transparent':
      return {
        asset: UnbrokenShapesTriangleScaleneTransparent,

        width: 446.37164,
        height: 330.20886,
        aspectRatio: 1.351785775826851
      };
    case 'Unequal_shapes_10_parts/Circle_unequal_10-10':
      return {
        asset: UnequalShapes10PartsCircleUnequal1010,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_10_parts/Pentagon_unequal_10-10':
      return {
        asset: UnequalShapes10PartsPentagonUnequal1010,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Unequal_shapes_10_parts/Square_unequal_10-10':
      return {
        asset: UnequalShapes10PartsSquareUnequal1010,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_11_parts/Circle_unequal_11-11':
      return {
        asset: UnequalShapes11PartsCircleUnequal1111,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_11_parts/Rectangle_unequal_11-11':
      return {
        asset: UnequalShapes11PartsRectangleUnequal1111,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Unequal_shapes_11_parts/Square_unequal_11-11':
      return {
        asset: UnequalShapes11PartsSquareUnequal1111,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_12_parts/Circle_unequal_12-12':
      return {
        asset: UnequalShapes12PartsCircleUnequal1212,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_12_parts/Rectangle_unequal_12-12':
      return {
        asset: UnequalShapes12PartsRectangleUnequal1212,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Unequal_shapes_12_parts/Square_unequal_12-12':
      return {
        asset: UnequalShapes12PartsSquareUnequal1212,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsArrowUnequal121Blue,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsArrowUnequal121Green,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsArrowUnequal121Yellow,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsArrowUnequal122Blue,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsArrowUnequal122Green,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsArrowUnequal122Yellow,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsArrowUnequal123Blue,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsArrowUnequal123Green,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsArrowUnequal123Yellow,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_blue':
      return {
        asset: UnequalShapes2PartsArrowUnequal124Blue,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_green':
      return {
        asset: UnequalShapes2PartsArrowUnequal124Green,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Arrow_unequal_1_2_4_yellow':
      return {
        asset: UnequalShapes2PartsArrowUnequal124Yellow,

        width: 350,
        height: 280,
        aspectRatio: 1.25
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsCircleUnequal121Blue,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsCircleUnequal121Green,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsCircleUnequal121Yellow,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsCircleUnequal122Blue,

        width: 308,
        height: 309,
        aspectRatio: 0.9967637540453075
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsCircleUnequal122Green,

        width: 308,
        height: 309,
        aspectRatio: 0.9967637540453075
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsCircleUnequal122Yellow,

        width: 308,
        height: 309,
        aspectRatio: 0.9967637540453075
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsCircleUnequal123Blue,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsCircleUnequal123Green,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsCircleUnequal123Yellow,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_4_blue':
      return {
        asset: UnequalShapes2PartsCircleUnequal124Blue,

        width: 309,
        height: 308,
        aspectRatio: 1.0032467532467533
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_4_green':
      return {
        asset: UnequalShapes2PartsCircleUnequal124Green,

        width: 309,
        height: 308,
        aspectRatio: 1.0032467532467533
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_4_yellow':
      return {
        asset: UnequalShapes2PartsCircleUnequal124Yellow,

        width: 309,
        height: 308,
        aspectRatio: 1.0032467532467533
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_5_blue':
      return {
        asset: UnequalShapes2PartsCircleUnequal125Blue,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_5_green':
      return {
        asset: UnequalShapes2PartsCircleUnequal125Green,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_5_yellow':
      return {
        asset: UnequalShapes2PartsCircleUnequal125Yellow,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_6_blue':
      return {
        asset: UnequalShapes2PartsCircleUnequal126Blue,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_6_green':
      return {
        asset: UnequalShapes2PartsCircleUnequal126Green,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Circle_unequal_1_2_6_yellow':
      return {
        asset: UnequalShapes2PartsCircleUnequal126Yellow,

        width: 309,
        height: 309,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Heart_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsHeartUnequal121Blue,

        width: 290,
        height: 290,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Heart_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsHeartUnequal121Green,

        width: 290,
        height: 290,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Heart_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsHeartUnequal121Yellow,

        width: 290,
        height: 290,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Heart_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsHeartUnequal122Blue,

        width: 290,
        height: 290,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Heart_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsHeartUnequal122Green,

        width: 290,
        height: 290,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Heart_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsHeartUnequal122Yellow,

        width: 290,
        height: 290,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsHexagonUnequal121Blue,

        width: 378,
        height: 327,
        aspectRatio: 1.1559633027522935
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsHexagonUnequal121Green,

        width: 378,
        height: 327,
        aspectRatio: 1.1559633027522935
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsHexagonUnequal121Yellow,

        width: 378,
        height: 327,
        aspectRatio: 1.1559633027522935
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsHexagonUnequal122Blue,

        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsHexagonUnequal122Green,

        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsHexagonUnequal122Yellow,

        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsHexagonUnequal123Blue,

        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsHexagonUnequal123Green,

        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Unequal_shapes_2_parts/Hexagon_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsHexagonUnequal123Yellow,

        width: 378,
        height: 328,
        aspectRatio: 1.1524390243902438
      };
    case 'Unequal_shapes_2_parts/Kite_unequal_1-2':
      return {
        asset: UnequalShapes2PartsKiteUnequal12,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Unequal_shapes_2_parts/Kite_unequal_2-2':
      return {
        asset: UnequalShapes2PartsKiteUnequal22,

        width: 241.97533,
        height: 327.06537,
        aspectRatio: 0.7398378189656705
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsOctagonUnequal121Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsOctagonUnequal121Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsOctagonUnequal121Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsOctagonUnequal122Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsOctagonUnequal122Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsOctagonUnequal122Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsOctagonUnequal123Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsOctagonUnequal123Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsOctagonUnequal123Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_blue':
      return {
        asset: UnequalShapes2PartsOctagonUnequal124Blue,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_green':
      return {
        asset: UnequalShapes2PartsOctagonUnequal124Green,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Octagon_unequal_1_2_4_yellow':
      return {
        asset: UnequalShapes2PartsOctagonUnequal124Yellow,

        width: 334,
        height: 334,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsRectangleUnequal121Blue,

        width: 544,
        height: 288,
        aspectRatio: 1.8888888888888888
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsRectangleUnequal121Green,

        width: 544,
        height: 288,
        aspectRatio: 1.8888888888888888
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsRectangleUnequal121Yellow,

        width: 544,
        height: 288,
        aspectRatio: 1.8888888888888888
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsRectangleUnequal122Blue,

        width: 544,
        height: 288,
        aspectRatio: 1.8888888888888888
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsRectangleUnequal122Green,

        width: 544,
        height: 288,
        aspectRatio: 1.8888888888888888
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsRectangleUnequal122Yellow,

        width: 544,
        height: 288,
        aspectRatio: 1.8888888888888888
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsRectangleUnequal123Blue,

        width: 548,
        height: 286,
        aspectRatio: 1.916083916083916
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsRectangleUnequal123Green,

        width: 548,
        height: 286,
        aspectRatio: 1.916083916083916
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsRectangleUnequal123Yellow,

        width: 548,
        height: 286,
        aspectRatio: 1.916083916083916
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_blue':
      return {
        asset: UnequalShapes2PartsRectangleUnequal124Blue,

        width: 548,
        height: 286,
        aspectRatio: 1.916083916083916
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_green':
      return {
        asset: UnequalShapes2PartsRectangleUnequal124Green,

        width: 548,
        height: 286,
        aspectRatio: 1.916083916083916
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_4_yellow':
      return {
        asset: UnequalShapes2PartsRectangleUnequal124Yellow,

        width: 548,
        height: 286,
        aspectRatio: 1.916083916083916
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_blue':
      return {
        asset: UnequalShapes2PartsRectangleUnequal125Blue,

        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_green':
      return {
        asset: UnequalShapes2PartsRectangleUnequal125Green,

        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_5_yellow':
      return {
        asset: UnequalShapes2PartsRectangleUnequal125Yellow,

        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_blue':
      return {
        asset: UnequalShapes2PartsRectangleUnequal126Blue,

        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_green':
      return {
        asset: UnequalShapes2PartsRectangleUnequal126Green,

        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Unequal_shapes_2_parts/Rectangle_unequal_1_2_6_yellow':
      return {
        asset: UnequalShapes2PartsRectangleUnequal126Yellow,

        width: 548,
        height: 287,
        aspectRatio: 1.9094076655052266
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1-2':
      return {
        asset: UnequalShapes2PartsSquareUnequal12,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsSquareUnequal121Blue,

        width: 287,
        height: 287,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsSquareUnequal121Green,

        width: 287,
        height: 287,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsSquareUnequal121Yellow,

        width: 287,
        height: 287,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsSquareUnequal122Blue,

        width: 287,
        height: 287,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsSquareUnequal122Green,

        width: 287,
        height: 287,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsSquareUnequal122Yellow,

        width: 287,
        height: 287,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsSquareUnequal123Blue,

        width: 294,
        height: 288,
        aspectRatio: 1.0208333333333333
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsSquareUnequal123Green,

        width: 294,
        height: 288,
        aspectRatio: 1.0208333333333333
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsSquareUnequal123Yellow,

        width: 294,
        height: 288,
        aspectRatio: 1.0208333333333333
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_4_blue':
      return {
        asset: UnequalShapes2PartsSquareUnequal124Blue,

        width: 294,
        height: 288,
        aspectRatio: 1.0208333333333333
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_4_green':
      return {
        asset: UnequalShapes2PartsSquareUnequal124Green,

        width: 294,
        height: 288,
        aspectRatio: 1.0208333333333333
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_4_yellow':
      return {
        asset: UnequalShapes2PartsSquareUnequal124Yellow,

        width: 294,
        height: 288,
        aspectRatio: 1.0208333333333333
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_5_blue':
      return {
        asset: UnequalShapes2PartsSquareUnequal125Blue,

        width: 294,
        height: 287,
        aspectRatio: 1.024390243902439
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_5_green':
      return {
        asset: UnequalShapes2PartsSquareUnequal125Green,

        width: 294,
        height: 287,
        aspectRatio: 1.024390243902439
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_5_yellow':
      return {
        asset: UnequalShapes2PartsSquareUnequal125Yellow,

        width: 294,
        height: 287,
        aspectRatio: 1.024390243902439
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_6_blue':
      return {
        asset: UnequalShapes2PartsSquareUnequal126Blue,

        width: 294,
        height: 287,
        aspectRatio: 1.024390243902439
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_6_green':
      return {
        asset: UnequalShapes2PartsSquareUnequal126Green,

        width: 294,
        height: 287,
        aspectRatio: 1.024390243902439
      };
    case 'Unequal_shapes_2_parts/Square_unequal_1_2_6_yellow':
      return {
        asset: UnequalShapes2PartsSquareUnequal126Yellow,

        width: 294,
        height: 287,
        aspectRatio: 1.024390243902439
      };
    case 'Unequal_shapes_2_parts/Square_unequal_2-2':
      return {
        asset: UnequalShapes2PartsSquareUnequal22,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Star_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsStarUnequal121Blue,
        defaultWidth: 350,
        defaultHeight: 350,
        width: 350,
        height: 350,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Star_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsStarUnequal121Green,
        defaultWidth: 350,
        defaultHeight: 350,
        width: 350,
        height: 350,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Star_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsStarUnequal121Yellow,
        defaultWidth: 350,
        defaultHeight: 350,
        width: 350,
        height: 350,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Star_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsStarUnequal122Blue,
        defaultWidth: 350,
        defaultHeight: 350,
        width: 350,
        height: 350,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Star_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsStarUnequal122Green,
        defaultWidth: 350,
        defaultHeight: 350,
        width: 350,
        height: 350,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Star_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsStarUnequal122Yellow,
        defaultWidth: 350,
        defaultHeight: 350,
        width: 350,
        height: 350,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1-2':
      return {
        asset: UnequalShapes2PartsTriangleUnequal12,

        width: 323.30908,
        height: 280.461,
        aspectRatio: 1.152777320197817
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_blue':
      return {
        asset: UnequalShapes2PartsTriangleUnequal121Blue,

        width: 410,
        height: 410,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_green':
      return {
        asset: UnequalShapes2PartsTriangleUnequal121Green,

        width: 410,
        height: 410,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_1_yellow':
      return {
        asset: UnequalShapes2PartsTriangleUnequal121Yellow,

        width: 410,
        height: 410,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_blue':
      return {
        asset: UnequalShapes2PartsTriangleUnequal122Blue,
        defaultWidth: 324,
        defaultHeight: 281,
        width: 324,
        height: 281,
        aspectRatio: 1.1530249110320285
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_green':
      return {
        asset: UnequalShapes2PartsTriangleUnequal122Green,
        defaultWidth: 324,
        defaultHeight: 281,
        width: 324,
        height: 281,
        aspectRatio: 1.1530249110320285
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_2_yellow':
      return {
        asset: UnequalShapes2PartsTriangleUnequal122Yellow,
        defaultWidth: 324,
        defaultHeight: 281,
        width: 324,
        height: 281,
        aspectRatio: 1.1530249110320285
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_blue':
      return {
        asset: UnequalShapes2PartsTriangleUnequal123Blue,

        width: 410,
        height: 410,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_green':
      return {
        asset: UnequalShapes2PartsTriangleUnequal123Green,

        width: 410,
        height: 410,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_1_2_3_yellow':
      return {
        asset: UnequalShapes2PartsTriangleUnequal123Yellow,

        width: 410,
        height: 410,
        aspectRatio: 1
      };
    case 'Unequal_shapes_2_parts/Triangle_unequal_2-2':
      return {
        asset: UnequalShapes2PartsTriangleUnequal22,

        width: 323.30908,
        height: 280.461,
        aspectRatio: 1.152777320197817
      };
    case 'Unequal_shapes_3_parts/Circle_unequal_3-1_1_blue':
      return {
        asset: UnequalShapes3PartsCircleUnequal311Blue,

        width: 316,
        height: 316,
        aspectRatio: 1
      };
    case 'Unequal_shapes_3_parts/Circle_unequal_3-1_1_green':
      return {
        asset: UnequalShapes3PartsCircleUnequal311Green,

        width: 316,
        height: 316,
        aspectRatio: 1
      };
    case 'Unequal_shapes_3_parts/Circle_unequal_3-1_1_yellow':
      return {
        asset: UnequalShapes3PartsCircleUnequal311Yellow,

        width: 316,
        height: 316,
        aspectRatio: 1
      };
    case 'Unequal_shapes_3_parts/Circle_unequal_3-3':
      return {
        asset: UnequalShapes3PartsCircleUnequal33,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_blue':
      return {
        asset: UnequalShapes3PartsHexagonUnequal311Blue,

        width: 94,
        height: 108,
        aspectRatio: 0.8703703703703703
      };
    case 'Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_green':
      return {
        asset: UnequalShapes3PartsHexagonUnequal311Green,

        width: 94,
        height: 108,
        aspectRatio: 0.8703703703703703
      };
    case 'Unequal_shapes_3_parts/Hexagon_unequal_3-1_1_yellow':
      return {
        asset: UnequalShapes3PartsHexagonUnequal311Yellow,

        width: 94,
        height: 108,
        aspectRatio: 0.8703703703703703
      };
    case 'Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_blue':
      return {
        asset: UnequalShapes3PartsRectangleUnequal311Blue,

        width: 555,
        height: 294,
        aspectRatio: 1.8877551020408163
      };
    case 'Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_green':
      return {
        asset: UnequalShapes3PartsRectangleUnequal311Green,

        width: 555,
        height: 294,
        aspectRatio: 1.8877551020408163
      };
    case 'Unequal_shapes_3_parts/Rectangle_unequal_3-1_1_yellow':
      return {
        asset: UnequalShapes3PartsRectangleUnequal311Yellow,

        width: 555,
        height: 294,
        aspectRatio: 1.8877551020408163
      };
    case 'Unequal_shapes_3_parts/Square_unequal_1-3':
      return {
        asset: UnequalShapes3PartsSquareUnequal13,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_3_parts/Square_unequal_3-1_1_blue':
      return {
        asset: UnequalShapes3PartsSquareUnequal311Blue,

        width: 292,
        height: 295,
        aspectRatio: 0.9898305084745763
      };
    case 'Unequal_shapes_3_parts/Square_unequal_3-1_1_green':
      return {
        asset: UnequalShapes3PartsSquareUnequal311Green,

        width: 292,
        height: 295,
        aspectRatio: 0.9898305084745763
      };
    case 'Unequal_shapes_3_parts/Square_unequal_3-1_1_yellow':
      return {
        asset: UnequalShapes3PartsSquareUnequal311Yellow,

        width: 292,
        height: 295,
        aspectRatio: 0.9898305084745763
      };
    case 'Unequal_shapes_3_parts/Square_unequal_3-3':
      return {
        asset: UnequalShapes3PartsSquareUnequal33,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_3_parts/Triangle_unequal_1-3':
      return {
        asset: UnequalShapes3PartsTriangleUnequal13,

        width: 323.30908,
        height: 280.461,
        aspectRatio: 1.152777320197817
      };
    case 'Unequal_shapes_3_parts/Triangle_unequal_3-1_1_blue':
      return {
        asset: UnequalShapes3PartsTriangleUnequal311Blue,

        width: 369,
        height: 321,
        aspectRatio: 1.1495327102803738
      };
    case 'Unequal_shapes_3_parts/Triangle_unequal_3-1_1_green':
      return {
        asset: UnequalShapes3PartsTriangleUnequal311Green,

        width: 369,
        height: 321,
        aspectRatio: 1.1495327102803738
      };
    case 'Unequal_shapes_3_parts/Triangle_unequal_3-1_1_yellow':
      return {
        asset: UnequalShapes3PartsTriangleUnequal311Yellow,

        width: 369,
        height: 321,
        aspectRatio: 1.1495327102803738
      };
    case 'Unequal_shapes_3_parts/Triangle_unequal_3-3':
      return {
        asset: UnequalShapes3PartsTriangleUnequal33,

        width: 323.30908,
        height: 280.461,
        aspectRatio: 1.152777320197817
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsArrowUnequal141Blue,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsArrowUnequal141Green,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsArrowUnequal141Yellow,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsArrowUnequal142Blue,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsArrowUnequal142Green,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsArrowUnequal142Yellow,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_3_4_1_blue':
      return {
        asset: UnequalShapes4PartsArrowUnequal341Blue,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_3_4_1_green':
      return {
        asset: UnequalShapes4PartsArrowUnequal341Green,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Arrow_unequal_3_4_1_yellow':
      return {
        asset: UnequalShapes4PartsArrowUnequal341Yellow,

        width: 355,
        height: 290,
        aspectRatio: 1.2241379310344827
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsCircleUnequal141Blue,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsCircleUnequal141Green,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsCircleUnequal141Yellow,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsCircleUnequal142Blue,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsCircleUnequal142Green,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsCircleUnequal142Yellow,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_3_4_1_blue':
      return {
        asset: UnequalShapes4PartsCircleUnequal341Blue,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_3_4_1_green':
      return {
        asset: UnequalShapes4PartsCircleUnequal341Green,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_3_4_1_yellow':
      return {
        asset: UnequalShapes4PartsCircleUnequal341Yellow,

        width: 312,
        height: 313,
        aspectRatio: 0.9968051118210862
      };
    case 'Unequal_shapes_4_parts/Circle_unequal_4-4':
      return {
        asset: UnequalShapes4PartsCircleUnequal44,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Cross_unequal_1-4':
      return {
        asset: UnequalShapes4PartsCrossUnequal14,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Cross_unequal_3-4':
      return {
        asset: UnequalShapes4PartsCrossUnequal34,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Cross_unequal_4-4':
      return {
        asset: UnequalShapes4PartsCrossUnequal44,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsHeartUnequal141Blue,

        width: 294,
        height: 297,
        aspectRatio: 0.98989898989899
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsHeartUnequal141Green,

        width: 294,
        height: 297,
        aspectRatio: 0.98989898989899
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsHeartUnequal141Yellow,

        width: 294,
        height: 297,
        aspectRatio: 0.98989898989899
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsHeartUnequal142Blue,

        width: 294,
        height: 297,
        aspectRatio: 0.98989898989899
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsHeartUnequal142Green,

        width: 294,
        height: 297,
        aspectRatio: 0.98989898989899
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsHeartUnequal142Yellow,

        width: 294,
        height: 297,
        aspectRatio: 0.98989898989899
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_3_blue':
      return {
        asset: UnequalShapes4PartsHeartUnequal143Blue,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_3_green':
      return {
        asset: UnequalShapes4PartsHeartUnequal143Green,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_3_yellow':
      return {
        asset: UnequalShapes4PartsHeartUnequal143Yellow,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_4_blue':
      return {
        asset: UnequalShapes4PartsHeartUnequal144Blue,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_4_green':
      return {
        asset: UnequalShapes4PartsHeartUnequal144Green,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_1_4_4_yellow':
      return {
        asset: UnequalShapes4PartsHeartUnequal144Yellow,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_3_4_3_blue':
      return {
        asset: UnequalShapes4PartsHeartUnequal343Blue,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_3_4_3_green':
      return {
        asset: UnequalShapes4PartsHeartUnequal343Green,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Heart_unequal_3_4_3_yellow':
      return {
        asset: UnequalShapes4PartsHeartUnequal343Yellow,

        width: 294,
        height: 296,
        aspectRatio: 0.9932432432432432
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsHexagonUnequal141Blue,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsHexagonUnequal141Green,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsHexagonUnequal141Yellow,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsHexagonUnequal142Blue,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsHexagonUnequal142Green,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsHexagonUnequal142Yellow,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_blue':
      return {
        asset: UnequalShapes4PartsHexagonUnequal342Blue,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_green':
      return {
        asset: UnequalShapes4PartsHexagonUnequal342Green,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Hexagon_unequal_3_4_2_yellow':
      return {
        asset: UnequalShapes4PartsHexagonUnequal342Yellow,

        width: 383,
        height: 342,
        aspectRatio: 1.1198830409356726
      };
    case 'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsOctagonUnequal141Blue,

        width: 334,
        height: 332,
        aspectRatio: 1.0060240963855422
      };
    case 'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsOctagonUnequal141Green,

        width: 334,
        height: 332,
        aspectRatio: 1.0060240963855422
      };
    case 'Unequal_shapes_4_parts/Octagon_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsOctagonUnequal141Yellow,

        width: 334,
        height: 332,
        aspectRatio: 1.0060240963855422
      };
    case 'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsOctagonUnequal142Blue,

        width: 334,
        height: 332,
        aspectRatio: 1.0060240963855422
      };
    case 'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsOctagonUnequal142Green,

        width: 334,
        height: 332,
        aspectRatio: 1.0060240963855422
      };
    case 'Unequal_shapes_4_parts/Octagon_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsOctagonUnequal142Yellow,

        width: 334,
        height: 332,
        aspectRatio: 1.0060240963855422
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsRectangleUnequal141Blue,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsRectangleUnequal141Green,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsRectangleUnequal141Yellow,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsRectangleUnequal142Blue,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsRectangleUnequal142Green,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsRectangleUnequal142Yellow,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_blue':
      return {
        asset: UnequalShapes4PartsRectangleUnequal341Blue,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_green':
      return {
        asset: UnequalShapes4PartsRectangleUnequal341Green,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Rectangle_unequal_3_4_1_yellow':
      return {
        asset: UnequalShapes4PartsRectangleUnequal341Yellow,

        width: 552,
        height: 290,
        aspectRatio: 1.903448275862069
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1-4':
      return {
        asset: UnequalShapes4PartsSquareUnequal14,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsSquareUnequal141Blue,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsSquareUnequal141Green,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsSquareUnequal141Yellow,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsSquareUnequal142Blue,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsSquareUnequal142Green,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsSquareUnequal142Yellow,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_3_4_1_blue':
      return {
        asset: UnequalShapes4PartsSquareUnequal341Blue,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_3_4_1_green':
      return {
        asset: UnequalShapes4PartsSquareUnequal341Green,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_3_4_1_yellow':
      return {
        asset: UnequalShapes4PartsSquareUnequal341Yellow,

        width: 291,
        height: 291,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Square_unequal_4-4':
      return {
        asset: UnequalShapes4PartsSquareUnequal44,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_4_parts/Star_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsStarUnequal141Blue,
        defaultWidth: 347,
        defaultHeight: 329,
        width: 347,
        height: 329,
        aspectRatio: 1.0547112462006079
      };
    case 'Unequal_shapes_4_parts/Star_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsStarUnequal141Green,
        defaultWidth: 347,
        defaultHeight: 329,
        width: 347,
        height: 329,
        aspectRatio: 1.0547112462006079
      };
    case 'Unequal_shapes_4_parts/Star_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsStarUnequal141Yellow,
        defaultWidth: 347,
        defaultHeight: 329,
        width: 347,
        height: 329,
        aspectRatio: 1.0547112462006079
      };
    case 'Unequal_shapes_4_parts/Star_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsStarUnequal142Blue,
        defaultWidth: 347,
        defaultHeight: 329,
        width: 347,
        height: 329,
        aspectRatio: 1.0547112462006079
      };
    case 'Unequal_shapes_4_parts/Star_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsStarUnequal142Green,
        defaultWidth: 347,
        defaultHeight: 329,
        width: 347,
        height: 329,
        aspectRatio: 1.0547112462006079
      };
    case 'Unequal_shapes_4_parts/Star_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsStarUnequal142Yellow,
        defaultWidth: 347,
        defaultHeight: 329,
        width: 347,
        height: 329,
        aspectRatio: 1.0547112462006079
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_blue':
      return {
        asset: UnequalShapes4PartsTriangleUnequal141Blue,
        defaultWidth: 363,
        defaultHeight: 314,
        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_green':
      return {
        asset: UnequalShapes4PartsTriangleUnequal141Green,
        defaultWidth: 363,
        defaultHeight: 314,
        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_1_4_1_yellow':
      return {
        asset: UnequalShapes4PartsTriangleUnequal141Yellow,
        defaultWidth: 363,
        defaultHeight: 314,
        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_blue':
      return {
        asset: UnequalShapes4PartsTriangleUnequal142Blue,
        defaultWidth: 363,
        defaultHeight: 314,
        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_green':
      return {
        asset: UnequalShapes4PartsTriangleUnequal142Green,
        defaultWidth: 363,
        defaultHeight: 314,
        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_1_4_2_yellow':
      return {
        asset: UnequalShapes4PartsTriangleUnequal142Yellow,
        defaultWidth: 363,
        defaultHeight: 314,
        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_3_4_1_blue':
      return {
        asset: UnequalShapes4PartsTriangleUnequal341Blue,

        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_3_4_1_green':
      return {
        asset: UnequalShapes4PartsTriangleUnequal341Green,

        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_4_parts/Triangle_unequal_3_4_1_yellow':
      return {
        asset: UnequalShapes4PartsTriangleUnequal341Yellow,

        width: 363,
        height: 314,
        aspectRatio: 1.1560509554140128
      };
    case 'Unequal_shapes_5_parts/Circle_unequal_5-5':
      return {
        asset: UnequalShapes5PartsCircleUnequal55,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_5_parts/Pentagon_unequal_1-5':
      return {
        asset: UnequalShapes5PartsPentagonUnequal15,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Unequal_shapes_5_parts/Pentagon_unequal_5-5':
      return {
        asset: UnequalShapes5PartsPentagonUnequal55,

        width: 341.99026,
        height: 325.25348,
        aspectRatio: 1.0514576508143738
      };
    case 'Unequal_shapes_5_parts/Square_unequal_1-5':
      return {
        asset: UnequalShapes5PartsSquareUnequal15,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_5_parts/Square_unequal_5-5':
      return {
        asset: UnequalShapes5PartsSquareUnequal55,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_6_parts/Circle_unequal_6-6':
      return {
        asset: UnequalShapes6PartsCircleUnequal66,

        width: 310.09598,
        height: 310.31467,
        aspectRatio: 0.9992952637398678
      };
    case 'Unequal_shapes_6_parts/Hexagon_unequal6-6':
      return {
        asset: UnequalShapes6PartsHexagonUnequal66,

        width: 345.34946,
        height: 299.08133,
        aspectRatio: 1.1547008300384383
      };
    case 'Unequal_shapes_6_parts/Square_unequal_6-6':
      return {
        asset: UnequalShapes6PartsSquareUnequal66,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_7_parts/Circle_unequal_7-7':
      return {
        asset: UnequalShapes7PartsCircleUnequal77,

        width: 305.26932,
        height: 305.26932,
        aspectRatio: 1
      };
    case 'Unequal_shapes_7_parts/Rectangle_unequal_7-7':
      return {
        asset: UnequalShapes7PartsRectangleUnequal77,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Unequal_shapes_7_parts/Square_unequal_7-7':
      return {
        asset: UnequalShapes7PartsSquareUnequal77,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Unequal_shapes_8_parts/Circle_unequal_8-8':
      return {
        asset: UnequalShapes8PartsCircleUnequal88,

        width: 310.09598,
        height: 310.09598,
        aspectRatio: 1
      };
    case 'Unequal_shapes_8_parts/Cross_unequal_8-8':
      return {
        asset: UnequalShapes8PartsCrossUnequal88,

        width: 328,
        height: 328,
        aspectRatio: 1
      };
    case 'Unequal_shapes_8_parts/Square_unequal_8-8':
      return {
        asset: UnequalShapes8PartsSquareUnequal88,

        width: 286.728,
        height: 287.05423,
        aspectRatio: 0.9988635248468556
      };
    case 'Unequal_shapes_9_parts/Circle_unequal_9-9':
      return {
        asset: UnequalShapes9PartsCircleUnequal99,

        width: 305.48801,
        height: 305.48801,
        aspectRatio: 1
      };
    case 'Unequal_shapes_9_parts/Rectangle_unequal_9-9':
      return {
        asset: UnequalShapes9PartsRectangleUnequal99,

        width: 406.944,
        height: 231.71066,
        aspectRatio: 1.756259293379079
      };
    case 'Unequal_shapes_9_parts/Square_unequal_9-9':
      return {
        asset: UnequalShapes9PartsSquareUnequal99,

        width: 286.728,
        height: 286.728,
        aspectRatio: 1
      };
    case 'Wardrobe':
      return {
        asset: Wardrobe,

        width: 305.23733,
        height: 518.66064,
        aspectRatio: 0.58851068783627
      };
    case 'Water_bottle':
      return {
        asset: WaterBottle,

        width: 126.07066,
        height: 428.35772,
        aspectRatio: 0.2943116328100729
      };
    case 'Water_bottle_glass':
      return {
        asset: WaterBottleGlass,

        width: 238.3448,
        height: 559.45825,
        aspectRatio: 0.42602785820032146
      };
    case 'Water_bottle_glass_empty':
      return {
        asset: WaterBottleGlassEmpty,

        width: 238.3448,
        height: 559.45825,
        aspectRatio: 0.42602785820032146
      };
    case 'Water_bottles_pack_of_12':
      return {
        asset: WaterBottlesPackOf12,

        width: 389.58532,
        height: 358.41998,
        aspectRatio: 1.0869520164584576
      };
    case 'Watering_can':
      return {
        asset: WateringCan,

        width: 602.56457,
        height: 363.4324,
        aspectRatio: 1.6579825299010216
      };
    case 'Watermelon':
      return {
        asset: Watermelon,

        width: 285.4928,
        height: 289.66937,
        aspectRatio: 0.9855815960106517
      };
    case 'Wavy_circles/Wavy_circle_blue':
      return {
        asset: WavyCirclesWavyCircleBlue,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_green':
      return {
        asset: WavyCirclesWavyCircleGreen,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_orange':
      return {
        asset: WavyCirclesWavyCircleOrange,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_pink':
      return {
        asset: WavyCirclesWavyCirclePink,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_purple':
      return {
        asset: WavyCirclesWavyCirclePurple,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_red':
      return {
        asset: WavyCirclesWavyCircleRed,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_turquoise':
      return {
        asset: WavyCirclesWavyCircleTurquoise,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Wavy_circles/Wavy_circle_yellow':
      return {
        asset: WavyCirclesWavyCircleYellow,

        width: 86.900938,
        height: 86.900938,
        aspectRatio: 1
      };
    case 'Weights/Weight':
      return {
        asset: WeightsWeight,
        defaultWidth: 208.61069,
        defaultHeight: 207.1144,
        width: 208.61069,
        height: 207.11439,
        aspectRatio: 1.0072245100883623
      };
    case 'Weights/Weights_100g':
      return {
        asset: WeightsWeights100g,
        defaultWidth: 208.61069,
        defaultHeight: 207.1144,
        width: 208.61069,
        height: 207.11439,
        aspectRatio: 1.0072245100883623
      };
    case 'Weights/Weights_10g':
      return {
        asset: WeightsWeights10g,
        defaultWidth: 108.02697,
        defaultHeight: 107.2644,
        width: 108.02697,
        height: 107.2644,
        aspectRatio: 1.0071092552608323
      };
    case 'Weights/Weights_1g':
      return {
        asset: WeightsWeights1g,

        width: 76.497858,
        height: 74.500879,
        aspectRatio: 1.026804770988004
      };
    case 'Weights/Weights_1kg':
      return {
        asset: WeightsWeights1kg,

        width: 372.74521,
        height: 368.91989,
        aspectRatio: 1.0103689719738342
      };
    case 'Weights/Weights_200g':
      return {
        asset: WeightsWeights200g,
        defaultWidth: 222.64899,
        defaultHeight: 221.04987,
        width: 222.64899,
        height: 221.04986,
        aspectRatio: 1.0072342502275278
      };
    case 'Weights/Weights_20g':
      return {
        asset: WeightsWeights20g,
        defaultWidth: 133.263,
        defaultHeight: 132.31627,
        width: 133.263,
        height: 132.31627,
        aspectRatio: 1.0071550535697538
      };
    case 'Weights/Weights_20kg':
      return {
        asset: WeightsWeights20kg,

        width: 472.72818,
        height: 406.43505,
        aspectRatio: 1.163108791921366
      };
    case 'Weights/Weights_25g':
      return {
        asset: WeightsWeights25g,

        width: 144.14082,
        height: 142.26286,
        aspectRatio: 1.0132006343749873
      };
    case 'Weights/Weights_2g':
      return {
        asset: WeightsWeights2g,

        width: 68.118455,
        height: 66.849427,
        aspectRatio: 1.018983378870248
      };
    case 'Weights/Weights_2kg':
      return {
        asset: WeightsWeights2kg,

        width: 402.35022,
        height: 374.38882,
        aspectRatio: 1.074685456686447
      };
    case 'Weights/Weights_500g':
      return {
        asset: WeightsWeights500g,

        width: 333.461,
        height: 331.05212,
        aspectRatio: 1.0072764373174834
      };
    case 'Weights/Weights_50g':
      return {
        asset: WeightsWeights50g,
        defaultWidth: 165.61829,
        defaultHeight: 164.43599,
        width: 165.61829,
        height: 164.43599,
        aspectRatio: 1.007190031817244
      };
    case 'Weights/Weights_50kg':
      return {
        asset: WeightsWeights50kg,

        width: 465.41001,
        height: 447.39364,
        aspectRatio: 1.0402696158130456
      };
    case 'Weights/Weights_5g':
      return {
        asset: WeightsWeights5g,

        width: 80.643106,
        height: 80.801583,
        aspectRatio: 0.9980386894152805
      };
    case 'Weights/Weights_5kg':
      return {
        asset: WeightsWeights5kg,

        width: 414.95679,
        height: 389.83636,
        aspectRatio: 1.0644383966646929
      };
    case 'WhiteRoseInfinity':
      return {
        asset: WhiteRoseInfinity,

        width: 415.4473267,
        height: 108.3203812,
        aspectRatio: 3.835356948503797
      };
    case 'WhiteRoseInfinityDark':
      return {
        asset: WhiteRoseInfinityDark,
        defaultWidth: 229,
        defaultHeight: 60,
        width: 229,
        height: 60,
        aspectRatio: 3.816666666666667
      };
    case 'White_van':
      return {
        asset: WhiteVan,

        width: 683.11743,
        height: 310.5748,
        aspectRatio: 2.1995262654922425
      };
    case 'Whole_shapes/Whole_shape_1':
      return {
        asset: WholeShapesWholeShape1,

        width: 554.20262,
        height: 177.70132,
        aspectRatio: 3.118731025745898
      };
    case 'Whole_shapes/Whole_shape_2':
      return {
        asset: WholeShapesWholeShape2,

        width: 377.76931,
        height: 419.52133,
        aspectRatio: 0.9004770031597679
      };
    case 'Whole_shapes/Whole_shape_3':
      return {
        asset: WholeShapesWholeShape3,

        width: 561.46532,
        height: 345.30798,
        aspectRatio: 1.625984201118086
      };
    case 'Whole_shapes/Whole_shape_5':
      return {
        asset: WholeShapesWholeShape5,

        width: 592.67595,
        height: 317.51599,
        aspectRatio: 1.8666018993248181
      };
    case 'Wooden_barrel':
      return {
        asset: WoodenBarrel,
        defaultWidth: 255.56409,
        defaultHeight: 305.56653,
        width: 255.56409,
        height: 305.56653,
        aspectRatio: 0.8363615282079486
      };
    case 'Wooden_crate':
      return {
        asset: WoodenCrate,

        width: 320.336,
        height: 233.46533,
        aspectRatio: 1.3720923787699013
      };
    case 'Worm':
      return {
        asset: Worm,

        width: 561.97229,
        height: 384.14496,
        aspectRatio: 1.462917253944969
      };
    case 'Wrapped_gifts_blue':
      return {
        asset: WrappedGiftsBlue,

        width: 291.87723,
        height: 234.67,
        aspectRatio: 1.243777346912686
      };
    case 'Wrapped_gifts_pink':
      return {
        asset: WrappedGiftsPink,

        width: 291.87723,
        height: 234.67,
        aspectRatio: 1.243777346912686
      };
    case 'Wrapped_gifts_red':
      return {
        asset: WrappedGiftsRed,

        width: 296.08478,
        height: 293.31207,
        aspectRatio: 1.0094531056972869
      };
    case 'WrmLogo':
      return {
        asset: WrmLogo,

        width: 178,
        height: 64,
        aspectRatio: 2.78125
      };
    case 'Yoyo':
      return {
        asset: Yoyo,

        width: 448.26037,
        height: 223.08983,
        aspectRatio: 2.009326781054968
      };
    case 'bird_blue':
      return {
        asset: BirdBlue,

        width: 446.98267,
        height: 384.45868,
        aspectRatio: 1.1626286341096526
      };
    case 'bowls/Bowl_Beans':
      return {
        asset: BowlsBowlBeans,
        defaultWidth: 239.43466,
        defaultHeight: 173.2951,
        width: 239.43466,
        height: 173.2951,
        aspectRatio: 1.3816585696883525
      };
    case 'bowls/Bowl_Cereal':
      return {
        asset: BowlsBowlCereal,
        defaultWidth: 239.43466,
        defaultHeight: 181.4095,
        width: 239.43466,
        height: 181.4095,
        aspectRatio: 1.319857339334489
      };
    case 'bowls/Bowl_Empty':
      return {
        asset: BowlsBowlEmpty,
        defaultWidth: 239.43466,
        defaultHeight: 131.20934,
        width: 239.43466,
        height: 131.20933,
        aspectRatio: 1.824829530034183
      };
    case 'bowls/Bowl_Rice':
      return {
        asset: BowlsBowlRice,
        defaultWidth: 239.43466,
        defaultHeight: 176.96982,
        width: 239.43466,
        height: 176.96982,
        aspectRatio: 1.3529688847510837
      };
    case 'cafe_birds_eye_view':
      return {
        asset: CafeBirdsEyeView,

        width: 306.89172,
        height: 336.9407,
        aspectRatio: 0.910818194418187
      };
    case 'cupOfSand':
      return {
        asset: CupOfSand,
        defaultWidth: 111,
        defaultHeight: 121,
        width: 111,
        height: 121,
        aspectRatio: 0.9173553719008265
      };
    case 'cup_red':
      return {
        asset: CupRed,

        width: 110.43697,
        height: 120.15202,
        aspectRatio: 0.9191436814795124
      };
    case 'drinking_straw':
      return {
        asset: DrinkingStraw,

        width: 1160.588,
        height: 37.921333,
        aspectRatio: 30.60514776735301
      };
    case 'fingers/Hand_1':
      return {
        asset: FingersHand1,

        width: 505,
        height: 998,
        aspectRatio: 0.5060120240480962
      };
    case 'fingers/Hand_2':
      return {
        asset: FingersHand2,

        width: 509,
        height: 1044,
        aspectRatio: 0.4875478927203065
      };
    case 'fingers/Hand_3':
      return {
        asset: FingersHand3,

        width: 509,
        height: 1070,
        aspectRatio: 0.47570093457943924
      };
    case 'fingers/Hand_4':
      return {
        asset: FingersHand4,

        width: 668,
        height: 1112,
        aspectRatio: 0.6007194244604317
      };
    case 'fingers/Hand_5':
      return {
        asset: FingersHand5,

        width: 828,
        height: 1111,
        aspectRatio: 0.7452745274527453
      };
    case 'full_swimming_pool':
      return {
        asset: FullSwimmingPool,
        defaultWidth: 551.91711,
        defaultHeight: 167.08533,
        width: 551.91711,
        height: 167.08533,
        aspectRatio: 3.3032050749159128
      };
    case 'glass_empty':
      return {
        asset: GlassEmpty,

        width: 595,
        height: 782,
        aspectRatio: 0.7608695652173914
      };
    case 'hospital_birds_eye_view':
      return {
        asset: HospitalBirdsEyeView,

        width: 325.40658,
        height: 321.77524,
        aspectRatio: 1.01128533071718
      };
    case 'icecream':
      return {
        asset: Icecream,

        width: 134.56444,
        height: 223.33408,
        aspectRatio: 0.6025253288705422
      };
    case 'lollipop_ball':
      return {
        asset: LollipopBall,

        width: 195.94972,
        height: 195.94933,
        aspectRatio: 1.0000019903104542
      };
    case 'lollipop_stick':
      return {
        asset: LollipopStick,

        width: 621.59467,
        height: 21.045332,
        aspectRatio: 29.535987838063093
      };
    case 'marshmallow':
      return {
        asset: Marshmallow,
        defaultWidth: 169.87604,
        defaultHeight: 165.16972,
        width: 169.87604,
        height: 165.16973,
        aspectRatio: 1.0284937803070817
      };
    case 'park_birds_eye_view':
      return {
        asset: ParkBirdsEyeView,

        width: 739.13464,
        height: 519.49334,
        aspectRatio: 1.4227990680304006
      };
    case 'park_birds_eye_view_with_arrows':
      return {
        asset: ParkBirdsEyeViewWithArrows,
        defaultWidth: 766,
        defaultHeight: 546,
        width: 766,
        height: 546,
        aspectRatio: 1.4029304029304028
      };
    case 'parts_of_a_whole/bicycle/Bicycle':
      return {
        asset: PartsOfAWholeBicycleBicycle,

        width: 597.49,
        height: 350.19,
        aspectRatio: 1.7061880693337903
      };
    case 'parts_of_a_whole/bicycle/Frame':
      return {
        asset: PartsOfAWholeBicycleFrame,

        width: 410.48,
        height: 231.67,
        aspectRatio: 1.771830621142142
      };
    case 'parts_of_a_whole/bicycle/Handlebar':
      return {
        asset: PartsOfAWholeBicycleHandlebar,

        width: 69.996,
        height: 68.084,
        aspectRatio: 1.0280829563480405
      };
    case 'parts_of_a_whole/bicycle/Helmet':
      return {
        asset: PartsOfAWholeBicycleHelmet,

        width: 170.71,
        height: 168.24,
        aspectRatio: 1.0146814075130766
      };
    case 'parts_of_a_whole/bicycle/Pedal':
      return {
        asset: PartsOfAWholeBicyclePedal,

        width: 133.64,
        height: 135.75,
        aspectRatio: 0.9844567219152853
      };
    case 'parts_of_a_whole/bicycle/Seat':
      return {
        asset: PartsOfAWholeBicycleSeat,

        width: 97.216,
        height: 40.192,
        aspectRatio: 2.418789808917197
      };
    case 'parts_of_a_whole/bicycle/Wheel':
      return {
        asset: PartsOfAWholeBicycleWheel,

        width: 215.45,
        height: 214.43,
        aspectRatio: 1.0047567970899594
      };
    case 'parts_of_a_whole/house/Chimney':
      return {
        asset: PartsOfAWholeHouseChimney,

        width: 9.361,
        height: 17.044,
        aspectRatio: 0.5492255339122272
      };
    case 'parts_of_a_whole/house/Door_black':
      return {
        asset: PartsOfAWholeHouseDoorBlack,

        width: 23.077,
        height: 48.232,
        aspectRatio: 0.4784582849560458
      };
    case 'parts_of_a_whole/house/Door_blue':
      return {
        asset: PartsOfAWholeHouseDoorBlue,

        width: 23.077,
        height: 48.232,
        aspectRatio: 0.4784582849560458
      };
    case 'parts_of_a_whole/house/Door_red':
      return {
        asset: PartsOfAWholeHouseDoorRed,

        width: 23.077,
        height: 48.232,
        aspectRatio: 0.4784582849560458
      };
    case 'parts_of_a_whole/house/House':
      return {
        asset: PartsOfAWholeHouseHouse,

        width: 149.28,
        height: 172.07,
        aspectRatio: 0.8675539024815483
      };
    case 'parts_of_a_whole/house/Roof_black':
      return {
        asset: PartsOfAWholeHouseRoofBlack,

        width: 149.28,
        height: 36.516,
        aspectRatio: 4.088070982582978
      };
    case 'parts_of_a_whole/house/Roof_brown':
      return {
        asset: PartsOfAWholeHouseRoofBrown,

        width: 149.28,
        height: 36.516,
        aspectRatio: 4.088070982582978
      };
    case 'parts_of_a_whole/house/Window_bottomLeft':
      return {
        asset: PartsOfAWholeHouseWindowBottomLeft,

        width: 50.628,
        height: 34.905,
        aspectRatio: 1.4504512247529007
      };
    case 'parts_of_a_whole/house/Window_topLeft':
      return {
        asset: PartsOfAWholeHouseWindowTopLeft,

        width: 38.244,
        height: 36.688,
        aspectRatio: 1.0424116877453118
      };
    case 'parts_of_a_whole/house/Window_topRight':
      return {
        asset: PartsOfAWholeHouseWindowTopRight,

        width: 38.244,
        height: 36.688,
        aspectRatio: 1.0424116877453118
      };
    case 'parts_of_a_whole/icecream/Cone':
      return {
        asset: PartsOfAWholeIcecreamCone,

        width: 104.31,
        height: 169.5,
        aspectRatio: 0.6153982300884956
      };
    case 'parts_of_a_whole/icecream/Flake':
      return {
        asset: PartsOfAWholeIcecreamFlake,

        width: 77.651,
        height: 143.91,
        aspectRatio: 0.5395802932388298
      };
    case 'parts_of_a_whole/icecream/Icecream':
      return {
        asset: PartsOfAWholeIcecreamIcecream,

        width: 162.18,
        height: 314.52,
        aspectRatio: 0.5156428843952691
      };
    case 'parts_of_a_whole/icecream/Scoop_chocolate':
      return {
        asset: PartsOfAWholeIcecreamScoopChocolate,

        width: 107.49,
        height: 106.06,
        aspectRatio: 1.0134829341881952
      };
    case 'parts_of_a_whole/icecream/Scoop_mint':
      return {
        asset: PartsOfAWholeIcecreamScoopMint,

        width: 107.94,
        height: 106.07,
        aspectRatio: 1.0176298670689168
      };
    case 'parts_of_a_whole/icecream/Scoop_strawberry':
      return {
        asset: PartsOfAWholeIcecreamScoopStrawberry,

        width: 107.94,
        height: 106.07,
        aspectRatio: 1.0176298670689168
      };
    case 'parts_of_a_whole/icecream/Scoop_vanilla':
      return {
        asset: PartsOfAWholeIcecreamScoopVanilla,

        width: 107.94,
        height: 106.07,
        aspectRatio: 1.0176298670689168
      };
    case 'parts_of_a_whole/icecream/Sprinkles':
      return {
        asset: PartsOfAWholeIcecreamSprinkles,

        width: 136.74,
        height: 82.997,
        aspectRatio: 1.6475294287745341
      };
    case 'parts_of_a_whole/icecream/Tub':
      return {
        asset: PartsOfAWholeIcecreamTub,

        width: 126.68,
        height: 119.73,
        aspectRatio: 1.0580472730309864
      };
    case 'pencil_packs/Pack_of_pencils_10':
      return {
        asset: PencilPacksPackOfPencils10,

        width: 245.75465,
        height: 427.888,
        aspectRatio: 0.5743434029465655
      };
    case 'pencil_packs/Pack_of_pencils_100':
      return {
        asset: PencilPacksPackOfPencils100,

        width: 245.75465,
        height: 427.888,
        aspectRatio: 0.5743434029465655
      };
    case 'pencil_packs/Pack_of_pencils_unlabelled':
      return {
        asset: PencilPacksPackOfPencilsUnlabelled,

        width: 245.75465,
        height: 427.888,
        aspectRatio: 0.5743434029465655
      };
    case 'pencil_rubber':
      return {
        asset: PencilRubber,

        width: 79.999999,
        height: 57.333331,
        aspectRatio: 1.3953488765548963
      };
    case 'pencil_tip':
      return {
        asset: PencilTip,

        width: 80.170669,
        height: 56.057227,
        aspectRatio: 1.4301575959153314
      };
    case 'pool_birds_eye_view':
      return {
        asset: PoolBirdsEyeView,

        width: 391.80771,
        height: 222.24266,
        aspectRatio: 1.7629725544141706
      };
    case 'ruler_15_cm':
      return {
        asset: Ruler15Cm,

        width: 1227.3839,
        height: 179.43333,
        aspectRatio: 6.840333955792939
      };
    case 'ruler_8_cm':
      return {
        asset: Ruler8Cm,

        width: 687.38395,
        height: 179.43333,
        aspectRatio: 3.830859907688276
      };
    case 'school_birds_eye_view':
      return {
        asset: SchoolBirdsEyeView,

        width: 247.20799,
        height: 268.108,
        aspectRatio: 0.9220463022364122
      };
    case 'stick':
      return {
        asset: Stick,

        width: 826.16129,
        height: 133.53635,
        aspectRatio: 6.186789514615309
      };
    case 'tablespoon':
      return {
        asset: Tablespoon,
        defaultWidth: 691.01941,
        defaultHeight: 107.19114,
        width: 691.0194,
        height: 107.19114,
        aspectRatio: 6.446609299985055
      };
    case 'teacup':
      return {
        asset: Teacup,

        width: 289,
        height: 196,
        aspectRatio: 1.4744897959183674
      };
    case 'tennis_ball':
      return {
        asset: TennisBall,

        width: 73.932,
        height: 73.930665,
        aspectRatio: 1.0000180574596482
      };
    case 'trees_birds_eye_view':
      return {
        asset: TreesBirdsEyeView,

        width: 394.09076,
        height: 257.78247,
        aspectRatio: 1.5287725344551164
      };
    case 'triangle_grey':
      return {
        asset: TriangleGrey,

        width: 88,
        height: 75,
        aspectRatio: 1.1733333333333333
      };
    case 'zest/rotate-left':
      return {
        asset: ZestRotateLeft,
        defaultWidth: 38,
        defaultHeight: 43,
        width: 38,
        height: 43,
        aspectRatio: 0.8837209302325582
      };
    case 'ArrowRightCustomizable':
      return {
        Svg: ArrowRightCustomizable,

        width: 48,
        height: 49,
        aspectRatio: 0.9795918367346939
      };
    case 'ArrowUpCustomizable':
      return {
        Svg: ArrowUpCustomizable,

        width: 48,
        height: 100,
        aspectRatio: 0.48
      };
    case 'CloseCustomizable':
      return {
        Svg: CloseCustomizable,

        width: 32,
        height: 32,
        aspectRatio: 1
      };
    case 'Coordinates/CirclePointCustomizable':
      return {
        Svg: CoordinatesCirclePointCustomizable,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/CrossPointCustomizable':
      return {
        Svg: CoordinatesCrossPointCustomizable,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/FilledSquarePointCustomizable':
      return {
        Svg: CoordinatesFilledSquarePointCustomizable,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/SquarePointCustomizable':
      return {
        Svg: CoordinatesSquarePointCustomizable,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'Coordinates/TrianglePointCustomizable':
      return {
        Svg: CoordinatesTrianglePointCustomizable,

        width: 36,
        height: 36,
        aspectRatio: 1
      };
    case 'CounterCustomizable':
      return {
        Svg: CounterCustomizable,

        width: 300,
        height: 300,
        aspectRatio: 1
      };
    case 'SliderArrowCustomizable':
      return {
        Svg: SliderArrowCustomizable,

        width: 26,
        height: 111,
        aspectRatio: 0.23423423423423423
      };
    case 'SliderArrowRightCustomizable':
      return {
        Svg: SliderArrowRightCustomizable,

        width: 111,
        height: 26,
        aspectRatio: 4.269230769230769
      };
    case 'SquareCustomizable':
      return {
        Svg: SquareCustomizable,

        width: 100,
        height: 100,
        aspectRatio: 1
      };
    case 'zest/rotate-leftCustomizable':
      return {
        Svg: ZestRotateLeftCustomizable,

        width: 38,
        height: 43,
        aspectRatio: 0.8837209302325582
      };
  }
}
