import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  rejectionSample,
  seededRandom,
  shuffle
} from '../../../../utils/random';
import { z } from 'zod';
import { numberEnum } from '../../../../utils/zod';
import { rangeAsString } from '../../../../utils/collections';
import QF19NumberLineDragArrow from '../../../../components/question/questionFormats/QF19NumberLineDragArrow';
import QF11SelectImagesUpTo4WithContent from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4WithContent';
import NumberLine from '../../../../components/question/representations/Number Line/NumberLine';
import Text from '../../../../components/typography/Text';

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bco',
  description: 'bco',
  keywords: ['Number line', 'Estimate', 'Halfway'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    number: z.number().int().min(1).max(19)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);

    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9, {
      constraint: x => x % 5 !== 0
    });

    return { startingNumber, number };
  },
  Component: props => {
    const {
      question: { startingNumber, number },
      translate
    } = props;
    const tickInterval = 5;
    const endNumber = startingNumber + 10;

    const tickArray = rangeAsString(startingNumber, endNumber, tickInterval, true);

    const testCorrect = (userAnswer: number) => {
      const acceptAbleAnswers = [];
      for (let i = number - 1; i <= number + 1; i++) {
        if (i % 5 !== 0) acceptAbleAnswers.push(i);
      }
      return (
        acceptAbleAnswers.includes(userAnswer) &&
        Math.floor(userAnswer / 10) === Math.floor(number / 10)
      );
    };

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragArrowEstimateNumberIs(number)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowEstimateNumberIs(number)}
        testCorrect={testCorrect}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickArray}
        customMarkSchemeAnswer={{
          answerToDisplay: number,
          answerText: translate.markScheme.allowMarginOfErrorOfXWithoutCrossingATick(1)
        }}
      />
    );
  }
});

const Question2 = newQuestionContent({
  uid: 'bcp',
  description: 'bcp',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    number: z.number().int().min(1).max(19)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);

    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);

    return { startingNumber, number };
  },
  Component: props => {
    const {
      question: { startingNumber, number },
      translate
    } = props;
    const endNumber = startingNumber + 10;

    const tickValues = [startingNumber.toLocaleString(), endNumber.toLocaleString()];

    const testCorrect = (userAnswer: number) => {
      const acceptAbleAnswers = [];
      for (let i = number - 1; i <= number + 1; i++) {
        if (i !== startingNumber && i !== endNumber) acceptAbleAnswers.push(i);
      }
      return (
        acceptAbleAnswers.includes(userAnswer) &&
        Math.floor(userAnswer / 10) === Math.floor(number / 10)
      );
    };

    return (
      <QF19NumberLineDragArrow
        title={translate.ks1Instructions.dragArrowEstimateNumberIs(number)}
        pdfTitle={translate.ks1PDFInstructions.drawArrowEstimateNumberIs(number)}
        testCorrect={testCorrect}
        min={startingNumber}
        max={endNumber}
        sliderStep={1}
        tickValues={tickValues}
        customMarkSchemeAnswer={{
          answerToDisplay: number,
          answerText: translate.markScheme.allowMarginOfErrorOfXWithoutCrossingATen(1)
        }}
      />
    );
  }
});

const Question3 = newQuestionContent({
  uid: 'bcq',
  description: 'bcq',
  keywords: ['Number line', 'Estimate'],
  schema: z.object({
    startingNumber: numberEnum([0, 10]),
    number: z.number().int().min(1).max(19),
    answers: z.number().int().min(0).max(20).array().length(3)
  }),
  simpleGenerator: () => {
    const startingNumber = getRandomFromArray([0, 10] as const);

    const incorrectOptions = [startingNumber, startingNumber + 10];

    const number = randomIntegerInclusive(startingNumber + 1, startingNumber + 9);

    startingNumber === 10 ? incorrectOptions.push(number - 10) : incorrectOptions.push(number + 1);
    if (number < startingNumber + 5)
      incorrectOptions.push(randomIntegerInclusive(startingNumber + 6, startingNumber + 10));
    else if (number > startingNumber + 5)
      incorrectOptions.push(randomIntegerInclusive(startingNumber, startingNumber + 4));

    if ([1, 2, 8, 9, 11, 12, 18, 19].includes(number)) incorrectOptions.push(startingNumber + 5);
    if (number === startingNumber + 5) getRandomFromArray([1, 2, 8, 9, 11, 12, 18, 19]);

    const incorrectAnswers = rejectionSample(
      () => getRandomSubArrayFromArray(incorrectOptions, 2),
      x => {
        const givenValues = [startingNumber, startingNumber + 10];

        if (x[0] === x[1] || x[0] === number || x[1] === number) return false;

        const firstIncorrectAnswerValid =
          givenValues.includes(x[0]) ||
          (x[0] !== x[1] - 1 && x[0] !== x[1] + 1 && x[0] !== number - 1 && x[0] !== number + 1);

        const secondIncorrectAnswerValid =
          givenValues.includes(x[1]) ||
          (x[1] !== x[0] - 1 && x[1] !== x[0] + 1 && x[1] !== number - 1 && x[1] !== number + 1);

        return firstIncorrectAnswerValid && secondIncorrectAnswerValid;
      }
    );

    const answers = shuffle([number, ...incorrectAnswers]);

    return { startingNumber, number, answers };
  },
  Component: props => {
    const {
      question: { startingNumber, number, answers },
      translate
    } = props;
    const endNumber = startingNumber + 10;

    const tickValues = [startingNumber.toLocaleString(), endNumber.toLocaleString()];

    const renderItems = shuffle(
      answers.map(number => ({
        component: <Text variant="WRN700">{number.toLocaleString()}</Text>,
        value: number
      })),
      { random: seededRandom(props.question) }
    );

    return (
      <QF11SelectImagesUpTo4WithContent
        title={translate.ks1Instructions.estimateTheNumberArrowPointingToSelectAnswer()}
        pdfTitle={translate.ks1PDFInstructions.estimateTheNumberArrowPointingToTickAnswer()}
        itemLayout="row"
        testCorrect={[number]}
        numItems={3}
        Content={({ dimens }) => (
          <NumberLine
            dimens={dimens}
            tickValues={tickValues}
            focusNumber={number}
            extendFocusArrow
          />
        )}
        renderItems={renderItems}
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'EstimateOnANumberLineTo20',
  questionTypes: [Question1, Question2, Question3]
});
export default SmallStep;
