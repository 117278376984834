import { newSmallStepContent } from 'common/src/SchemeOfLearning/SmallStep';
import { newQuestionContent } from '../../../Question';
import { z } from 'zod';
import {
  getRandomFromArray,
  getRandomSubArrayFromArray,
  randomIntegerInclusive,
  randomIntegerInclusiveStep,
  shuffle
} from 'common/src/utils/random';
import { getShapeSvgByShapeAndColor } from '../../../../utils/shapeImages/shapes';
import QF1ContentAndSentence from '../../../../components/question/questionFormats/QF1ContentAndSentence';
import { AssetSvg } from '../../../../assets/svg';
import QF37SentenceDrag from '../../../../components/question/questionFormats/QF37SentenceDrag';
import { numberEnum } from '../../../../utils/zod';
import { arrayHasNoDuplicates, countRange } from '../../../../utils/collections';
import QF11SelectImagesUpTo4 from '../../../../components/question/questionFormats/QF11SelectImagesUpTo4';
import { View } from 'react-native';

////
// Questions
////

const triangles = [
  'triangle',
  'right angle triangle',
  'long right angle triangle',
  'scalene triangle',
  'narrow isosceles triangle',
  'wide isosceles triangle'
] as const;

const nonTriangles = ['square', 'rectangle', 'pentagon', 'hexagon', 'heptagon', 'octagon'] as const;

const shapes2d = [...triangles, ...nonTriangles] as const;

const colors = ['blue', 'green', 'orange', 'pink', 'purple', 'red', 'turquoise', 'yellow'] as const;

const shapeToSides = (shape: (typeof shapes2d)[number]) => {
  switch (shape) {
    case 'triangle':
    case 'right angle triangle':
    case 'long right angle triangle':
    case 'scalene triangle':
    case 'narrow isosceles triangle':
    case 'wide isosceles triangle':
      return 3;
    case 'square':
    case 'rectangle':
      return 4;
    case 'pentagon':
      return 5;
    case 'hexagon':
      return 6;
    case 'heptagon':
      return 7;
    case 'octagon':
      return 8;
  }
};

////
// Questions
////

const Question1 = newQuestionContent({
  uid: 'bhC',
  description: 'bhC',
  keywords: ['Shape', '2-D shape', 'Sides'],
  schema: z.object({
    shape: z.enum(shapes2d),
    color: z.enum(colors)
  }),
  simpleGenerator: () => {
    const shape = getRandomFromArray(shapes2d);

    const color = getRandomFromArray(colors);

    return { shape, color };
  },
  Component: ({ question: { shape, color }, translate }) => {
    const sides = shapeToSides(shape);

    return (
      <QF1ContentAndSentence
        title={translate.ks1Instructions.howManySides()}
        Content={({ dimens }) => (
          <AssetSvg
            name={getShapeSvgByShapeAndColor(shape, color)}
            width={dimens.width * 0.9}
            height={dimens.height * 0.9}
          />
        )}
        sentence="<ans/>"
        testCorrect={[sides.toString()]}
        sentenceStyle={{ justifyContent: 'flex-end' }}
        pdfSentenceStyle={{ justifyContent: 'flex-end' }}
        pdfDirection="column"
      />
    );
  }
});

const quadrilaterals = [
  'rectangle',
  'square',
  'parallelogram',
  'rhombus',
  'trapezium isosceles',
  'kite'
] as const;

const pentagons = [
  'pentagon',
  'pentagon house',
  'irregular pentagon 1',
  'irregular pentagon 2',
  'irregular pentagon 3',
  'irregular pentagon 4',
  'irregular pentagon 5',
  'irregular pentagon 6'
] as const;

const hexagons = [
  'hexagon',
  'L-shape',
  'irregular hexagon 1',
  'irregular hexagon 3',
  'irregular hexagon 4',
  'irregular hexagon 5',
  'irregular hexagon 6'
] as const;

const heptagons = [
  'heptagon',
  'irregular heptagon 1',
  'irregular heptagon 2',
  'irregular heptagon 3'
] as const;

const octagons = ['octagon', 'irregular octagon 1', 'irregular octagon 3'] as const;

const q2Shapes = [
  ...triangles,
  ...quadrilaterals,
  ...pentagons,
  ...hexagons,
  ...heptagons,
  ...octagons
] as const;

type Q2Shape = (typeof q2Shapes)[number];

const q2SidesToArray = (sides: 3 | 4 | 5 | 6 | 7 | 8): readonly Q2Shape[] => {
  switch (sides) {
    case 3:
      return triangles;
    case 4:
      return quadrilaterals;
    case 5:
      return pentagons;
    case 6:
      return hexagons;
    case 7:
      return heptagons;
    case 8:
      return octagons;
  }
};

const Question2 = newQuestionContent({
  uid: 'bhD',
  description: 'bhD',
  keywords: ['Shape', '2-D shape', 'Sides'],
  schema: z
    .object({
      numberOfSides: numberEnum([3, 4, 5, 6, 7, 8]),
      shapes: z
        .array(
          z.object({
            shape: z.enum(q2Shapes),
            color: z.enum(colors),
            rotation: z.number().min(0).max(270).multipleOf(90),
            scale: numberEnum([1, 0.85])
          })
        )
        .length(8)
        .refine(
          shapes => arrayHasNoDuplicates(shapes.map(shape => shape.shape)),
          'All shapes must be different.'
        )
    })
    .refine(val => {
      const shapes = val.shapes.map(shape => shape.shape);
      const selectedShapeArray = q2SidesToArray(val.numberOfSides);

      return shapes.some(shape => selectedShapeArray.includes(shape));
    }, 'At least one shape must have the correct numberOfSides.'),
  simpleGenerator: () => {
    // Using this rather than randomIntegerInclusive makes TypeScript easier to work with
    // - it knows for sure this is going to be a value from 3 to 8, which is needed when grabbing the array.
    const [numberOfSides, numberOfIncorrectSidesA, numberOfIncorrectSidesB] =
      getRandomSubArrayFromArray([3, 4, 5, 6, 7, 8] as const, 3);

    const selectedShapeCategoryArray = q2SidesToArray(numberOfSides);
    const incorrectShapeCategoryArrayA = q2SidesToArray(numberOfIncorrectSidesA);
    const incorrectShapeCategoryArrayB = q2SidesToArray(numberOfIncorrectSidesB);

    const numberOfCorrectShape = randomIntegerInclusive(2, 3);

    const numberOfIncorrectShapeA = 3;
    const numberOfIncorrectShapeB = 8 - (numberOfCorrectShape + numberOfIncorrectShapeA);

    const correctShapes = getRandomSubArrayFromArray(
      selectedShapeCategoryArray,
      numberOfCorrectShape
    );

    const incorrectShapesA = getRandomSubArrayFromArray(
      incorrectShapeCategoryArrayA,
      numberOfIncorrectShapeA
    );

    const incorrectShapesB = getRandomSubArrayFromArray(
      incorrectShapeCategoryArrayB,
      numberOfIncorrectShapeB
    );

    const selectedColors = getRandomSubArrayFromArray(colors, 8);

    const allShapes = [...correctShapes, ...incorrectShapesA, ...incorrectShapesB];

    const shapes = shuffle(
      countRange(8).map(i => ({
        shape: allShapes[i],
        color: selectedColors[i],
        rotation: randomIntegerInclusiveStep(0, 270, 90),
        scale: getRandomFromArray([1, 0.85] as const)
      }))
    );

    return { numberOfSides, shapes };
  },
  Component: ({ question: { numberOfSides, shapes }, translate }) => {
    const selectedShapeCategoryArray = q2SidesToArray(numberOfSides);

    return (
      <QF11SelectImagesUpTo4
        title={translate.ks1Instructions.selectTheNumberSidedShapes(numberOfSides)}
        pdfTitle={translate.ks1PDFInstructions.circleTheNumberSidedShapes(numberOfSides)}
        testCorrect={shapes
          .filter(shape => [...selectedShapeCategoryArray].includes(shape.shape))
          .map(shape => shape.shape)}
        numItems={8}
        multiSelect
        renderItems={({ dimens }) =>
          shapes.map(({ shape, color, rotation, scale }) => {
            return {
              component: (
                <View style={{ transform: [{ rotate: `${rotation}deg` }, { scale: scale }] }}>
                  <AssetSvg
                    name={getShapeSvgByShapeAndColor(shape, color)}
                    width={
                      rotation === 90 || rotation === 270 ? dimens.height * 0.9 : dimens.width * 0.9
                    }
                    height={
                      rotation === 90 || rotation === 270 ? dimens.width * 0.9 : dimens.height * 0.9
                    }
                  />
                </View>
              ),
              value: shape
            };
          })
        }
        questionHeight={1000}
      />
    );
  },
  questionHeight: 1000
});

const Question3 = newQuestionContent({
  uid: 'bhE',
  description: 'bhE',
  keywords: ['Shape', '2-D shape', 'Sides'],
  schema: z
    .object({
      shape: z.enum(['triangle', ...nonTriangles]),
      firstNumberItem: z.number().int().min(3).max(7)
    })
    .refine(
      val =>
        shapeToSides(val.shape) >= val.firstNumberItem &&
        shapeToSides(val.shape) <= val.firstNumberItem + 3,
      'Total sides of the shape must be greater than or equal to firstNumberItem, and less than or equal to firstNumberItem + 3'
    ),
  simpleGenerator: () => {
    const shape = getRandomFromArray(['triangle', ...nonTriangles] as const);

    const firstNumberItem = randomIntegerInclusive(3, 7, {
      constraint: x => shapeToSides(shape) >= x && shapeToSides(shape) <= x + 3
    });

    return { shape, firstNumberItem };
  },
  Component: props => {
    const {
      question: { shape, firstNumberItem },
      translate
    } = props;

    const shapeToString = (() => {
      switch (shape) {
        case 'triangle':
          return translate.shapes.ATriangle();
        case 'square':
          return translate.shapes.ASquare();
        case 'rectangle':
          return translate.shapes.ARectangle();
        case 'pentagon':
          return translate.shapes.APentagon();
        case 'hexagon':
          return translate.shapes.AHexagon();
        case 'heptagon':
          return translate.shapes.AHeptagon();
        case 'octagon':
          return translate.shapes.AnOctagon();
      }
    })();

    return (
      <QF37SentenceDrag
        title={translate.ks1Instructions.dragACardToCompleteTheSentence()}
        pdfTitle={translate.ks1PDFInstructions.completeTheSentence()}
        // PPT specifically asks for these numbers to be in consecutive order, not shuffled:
        items={[firstNumberItem, firstNumberItem + 1, firstNumberItem + 2, firstNumberItem + 3]}
        actionPanelVariant="end"
        itemVariant="square"
        sentence={translate.ks1AnswerSentences.shapeHasAnsSides(shapeToString)}
        testCorrect={[shapeToSides(shape)]}
        pdfLayout="itemsHidden"
      />
    );
  }
});

////
// Small Step
////

const SmallStep = newSmallStepContent({
  smallStep: 'CountSidesOn2DShapes',
  questionTypes: [Question1, Question2, Question3],
  unpublishedQuestionTypes: [Question2]
});
export default SmallStep;
